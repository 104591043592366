import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import $ from 'jquery';
import 'moment-timezone';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Moment from 'react-moment';
import * as constants from "../../../util/constants";
import { parseDateForPad } from '../../../util/dates';
import Alert from '../Alert';
import FieldDate from '../FieldDate';
import FieldText from '../FieldText';
import FieldTextarea from '../FieldTextarea';
import Modal from '../Modal';
import Propertii from '../Propertii';
import Spinner from '../Spinner';

class ReceiptPaymentTransactionActionsModal extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      reversedAmount: 0,
      reversedFeeAmount: 0,
      settlementDate: '',

      confirmationId: this.props.paymentTransaction.confirmationId || null,
      processedDate: this.props.paymentTransaction.processedDate || null,
      paymentTransactionError: this.props.paymentTransaction?.error || null,

      customNsfFeeAmount: 0,

      validationList: [],
    };

    this.refundPaymentTransaction = this.refundPaymentTransaction.bind(this);
    this.savePaymentTransactionSettlementDate = this.savePaymentTransactionSettlementDate.bind(this);
    this.savePaymentTransactionStatus = this.savePaymentTransactionStatus.bind(this);
    this.pushPaymentTransaction = this.pushPaymentTransaction.bind(this);
    this.manuallyPushPaymentTransaction = this.manuallyPushPaymentTransaction.bind(this);
    this.cancelPaymentTransaction = this.cancelPaymentTransaction.bind(this);
    this.saveCustomNsfFeeAmount = this.saveCustomNsfFeeAmount.bind(this);
  }

  /**
   * Refund a transaction.
   * @param event - The event container.
   */
  refundPaymentTransaction(event) {
    // It is assumed that whatever code calls this function will verify the user type
    // that is making this call.

    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let refundFeeAmount = this.state.reversedFeeAmount;
    let refundAmount = this.state.reversedAmount;

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/processrefund`,
        {
          originalTransactionId: this.props.paymentTransaction.id,
          refundAmount: refundAmount,
          refundFeeAmount: refundFeeAmount,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        if (response.data.status === 'FAILED') {
          this.setState((prevState) => ({
            ...prevState,
            spinner: false,
            validationList: [
              {
                fields: {},
                alert: {
                  type: 'danger',
                  code: 'common.transactions.refund.failed',
                },
                values: {
                  error: response.data.error,
                },
              },
            ],
          }));
        }

        if (response.data.status !== 'FAILED') {
          this.props.getStatsTotal();
          this.setState((prevState) => ({
            ...prevState,
            spinner: false,
            validationList: [
              {
                fields: {},
                alert: {
                  type: 'primary',
                  code: 'common.transactions.payment.refunded',
                },
              },
            ],
          }));

          $('#refund-payment-transaction').modal('hide');
        }
      })
      .catch((error) => {
        if (error?.response?.data?.message && this.props.userType === 'TYPE_MANAGER') {
          const errorMsg = error.response.data.message;
          if (errorMsg.includes('Amount reversal limit reached')) {
            error.response.data.message = `Your desired refund of ${refundAmount} is greater then the refundable amount of ${(
              this.props.paymentTransaction.amount - this.props.paymentTransaction.reversedAmount
            ).toFixed(2)}`;
          }
        }
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Save an existing payment transaction's settlement date. Used by admins to manually set a settlement date for a
   * payment transaction if one does not exist.
   *
   * @param event - The event container.
   */
  savePaymentTransactionSettlementDate(event) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let paymentTransaction = this.props.paymentTransaction;

    axios
      .patch(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`,
        {
          settlementDate: this.state.settlementDate,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code: 'common.transactions.payment.settled',
              },
            },
          ],
        }));

        $('#settle-payment-transaction').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Save an existing payment transaction's status. Closes all modals and updates the payment transaction list with
   * the newly updated status. If the payment transaction is being marked as complete, a confirmation ID must be
   * provided. If the payment transaction is being marked as failed, an error must be provided.
   *
   * @param event - The event container.
   * @param status - The new status of the payment transaction.
   */
  savePaymentTransactionStatus(event, status) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let paymentTransaction = this.props.paymentTransaction;

    axios
      .patch(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`,
        {
          status: status,
          confirmationId: status === 'COMPLETE' ? this.state.confirmationId : null,
          processedDate: status === 'COMPLETE' ? this.state.processedDate : null,
          error: status === 'FAILED' ? this.state.paymentTransactionError : null,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code:
                  status === 'COMPLETE'
                    ? 'common.transactions.payment.completed'
                    : 'common.transactions.payment.failed',
              },
              values: {
                firstName: paymentTransaction.joins.c.firstName,
                lastName: paymentTransaction.joins.c.lastName,
              },
            },
          ],
        }));

        $('#complete-payment-transaction').modal('hide');
        $('#fail-payment-transaction').modal('hide');
        $('#receipt-payment-transaction').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Push a transaction to the integration service.
   */
  pushPaymentTransaction() {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/${this.props.paymentTransaction.id}/push`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));

        $('#push-payment-transaction').modal('hide');

        this.props.selectPaymentTransaction(this.props.paymentTransaction);
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * In some scenarios, a regular transaction push fails because there is no way that the client's ERP system can
   * accept the data according to their business rules. If this is the case, the transaction can be marked as manually
   * pushed.
   */
  manuallyPushPaymentTransaction() {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    axios
      .patch(
        `${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${this.props.paymentTransaction.id}/update`,
        {
          pushStatus: 'MANUAL_ENTRY',
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code: 'common.transactions.payment.manual',
              },
            },
          ],
        }));

        $('#manually-push-payment-transaction').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Cancel an unsettled transaction.
   */
  cancelPaymentTransaction() {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/cancelpayment`,
        {
          transactionId: this.props.paymentTransaction.id,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));

        $('#cancel-payment-transaction').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Update payment payout status
   */
  updatePaymentPayoutStatus(event, payoutStatus) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    const paymentTransaction = this.props.paymentTransaction;

    axios
      .patch(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`,
        {
          payoutStatus: payoutStatus,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.props.getStatsTotal();
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));

        $('#complete-payment-transaction').modal('hide');
        $('#fail-payment-transaction').modal('hide');
        $('#receipt-payment-transaction').modal('hide');
        $('#mark-HWpayment-rejected').modal('hide');
        $('#mark-HWpayment-bycheque').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Save an existing NSF transaction's custom NSF fee amount. Used by admins to manually set a custom NSF fee amount for a
   * NSF transaction if one does not exist.
   *
   * @param event - The event container.
   */
  saveCustomNsfFeeAmount(event) {
    event.preventDefault();
    this.props.markNSFTransactionValidOrInvalid('VALID', this.state.customNsfFeeAmount);
  }

  render() {
    return (
      <>
        <Alert validationList={this.state.validationList} />
        <Spinner visible={this.state.spinner} />
        <div
          className='modal fade'
          id='refund-payment-transaction'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='refund-payment-transaction-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content shadow'>
              <form onSubmit={this.refundPaymentTransaction}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='refund-payment-transaction-label'>
                    Refund Transaction
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>

                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>
                    Enter the amount you would like to refund from the transaction total
                    {this.props.userType === 'TYPE_ADMIN' ? ' and the transaction fee total.' : '.'}
                  </p>
                </div>

                <div className='modal-body mb-2'>
                  <Alert validationList={this.state.validationList} validationType='danger' customClasses='mb-3' />

                  {this.props.paymentTransaction?.amount != null && this.state.reversedAmount != null && (
                    <FieldText
                      id='reversedAmount'
                      label='Refund Amount'
                      type='number'
                      prependIconType='fas'
                      prependIconName='dollar-sign'
                      fieldColumns='12'
                      labelColumns='12'
                      fieldClass='form-control-sm mb-0'
                      help={`The maximum refundable amount is $${(
                        this.props.paymentTransaction?.amount - this.props.paymentTransaction?.reversedAmount
                      ).toFixed(2)}.`}
                      placeholder='0.00'
                      labelClass='col-form-label-sm'
                      step='0.01'
                      parent={this}
                      value={this.state.reversedAmount}
                    />
                  )}

                  {this.props.paymentTransaction?.feeAmount != null &&
                    this.props.paymentTransaction?.reversedFeeAmount != null &&
                    this.props.userType !== 'TYPE_MANAGER' && (
                      <FieldText
                        id='reversedFeeAmount'
                        label='Transaction Fee Refund Amount'
                        type='number'
                        prependIconType='fas'
                        prependIconName='dollar-sign'
                        fieldColumns='12'
                        labelColumns='12'
                        fieldClass='form-control-sm mb-0'
                        help={`The maximum refundable transaction fee amount is $${(
                          this.props.paymentTransaction?.feeAmount - this.props.paymentTransaction?.reversedFeeAmount
                        ).toFixed(2)}.`}
                        placeholder='0.00'
                        labelClass='col-form-label-sm'
                        step='0.01'
                        parent={this}
                        value={this.state.reversedFeeAmount}
                      />
                    )}
                </div>

                <div className='modal-footer d-block text-center bg-secondary rounded-bottom'>
                  <div className='row'>
                    <div className='col'>
                      <div className='float-left'>
                        <div
                          className='btn btn-outline-primary btn-lg'
                          onClick={() => {
                            $('#refund-payment-transaction').modal('hide');
                            $('#receipt-payment-transaction').modal('show');
                          }}
                        >
                          Back
                        </div>
                      </div>
                      <div className='float-right'>
                        <button type='submit' className='btn btn-primary btn-lg'>
                          Refund
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='payment-transaction-reversals'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='payment-transaction-reversals-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content shadow'>
              <div className='modal-header bg-dark text-white'>
                <h5 className='modal-title' id='payment-transaction-reversals-label'>
                  Reversal History
                </h5>
                <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                  <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                </button>
              </div>

              <div className='modal-body bg-secondary'>
                <p className='mb-0'>
                  Select a reversal from the list below to view it's corresponding transaction receipt.
                </p>
              </div>

              <div className='modal-body modal-body-table'>
                <table className='table table-hover mb-0'>
                  <thead>
                    <tr className='small'>
                      <th>Date</th>
                      <th>Charges</th>
                      <th>Transaction Fee</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.paymentTransactionReversalList?.records?.map((data, key) => {
                      return (
                        <tr key={key} className='small c-pointer' onClick={() => this.props.selectPaymentTransaction(data)}>
                          <td>
                            <div className=''>
                              <Moment format='MMM DD, YYYY'>
                                {data?.source === 'PAD' && data?.transactionType === 'PAYMENT' ? parseDateForPad(data.createDate) : data.createDate}
                              </Moment>
                            </div>
                          </td>
                          <td>
                            {data.amount != null && (
                              <div className={data.amount < 0 ? 'text-danger' : ''}>
                                <FormattedNumber value={data.amount} style={`currency`} currency={data.currency} />
                              </div>
                            )}
                          </td>
                          <td>
                            {data.feeAmount != null && (
                              <div className={data.feeAmount < 0 ? 'text-danger' : ''}>
                                <FormattedNumber value={data.feeAmount} style={`currency`} currency={data.currency} />
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className='modal-footer d-block text-center bg-secondary rounded-bottom'>
                <div className='row'>
                  <div className='col'>
                    <div className='float-left'>
                      <div
                        className='btn btn-outline-primary btn-lg'
                        onClick={() => {
                          $('#payment-transaction-reversals').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        Back
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='settle-payment-transaction'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='settle-payment-transaction-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={this.savePaymentTransactionSettlementDate}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='settle-payment-transaction-label'>
                    Mark Transaction as Settled
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>

                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>To mark this transaction as settled, enter a settlement date below.</p>
                </div>

                <div className='modal-body'>
                  <FieldDate
                    id='settlementDate'
                    label='Settlement Date'
                    placeholder='MM/DD/YYYY'
                    required={true}
                    labelColumns='0'
                    fieldColumns='12'
                    fieldClass='form-control-md'
                    labelClass='col-form-label-md d-none'
                    parent={this}
                    value={this.state.settlementDate}
                  />
                </div>

                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-lg'
                        onClick={() => {
                          $('#settle-payment-transaction').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        <FormattedMessage id='button.back' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary btn-lg'>
                        Mark as Settled
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='complete-payment-transaction'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='complete-payment-transaction-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'COMPLETE')}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='complete-payment-transaction-label'>
                    Mark Transaction as Completed
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>

                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>
                    To mark this transaction as completed, please provide the confirmation ID from the payment processor
                    below.
                  </p>
                </div>

                <div className='modal-body'>
                  <FieldText
                    id='confirmationId'
                    label='Confirmation ID'
                    placeholder='Confirmation ID'
                    required={true}
                    labelColumns='0'
                    fieldColumns='12'
                    fieldClass='form-control-md'
                    labelClass='col-form-label-md d-none'
                    parent={this}
                    value={this.state.confirmationId}
                  />

                  <FieldDate
                    id='processedDate'
                    label='Date Processed'
                    placeholder='Date Processed'
                    required={true}
                    labelColumns='0'
                    fieldColumns='12'
                    fieldClass='form-control-md'
                    labelClass='col-form-label-md d-none'
                    parent={this}
                    value={this.state.processedDate}
                  />
                </div>

                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-lg'
                        onClick={() => {
                          $('#complete-payment-transaction').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        <FormattedMessage id='button.back' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary btn-lg'>
                        Mark as Completed
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='fail-payment-transaction'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='fail-payment-transaction-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={(e) => this.savePaymentTransactionStatus(e, 'FAILED')}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='fail-payment-transaction-label'>
                    Mark Transaction as Failed
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>

                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>
                    To mark this transaction as failed, please provide the error from the payment processor below.
                  </p>
                </div>

                <div className='modal-body'>
                  <FieldTextarea
                    id='paymentTransactionError'
                    label='Error'
                    rows='8'
                    required={true}
                    labelColumns='0'
                    fieldColumns='12'
                    fieldClass='form-control-sm'
                    labelClass='col-form-label-sm d-none'
                    maxLength='200'
                    parent={this}
                    value={this.state.paymentTransactionError}
                  />
                </div>

                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-lg'
                        onClick={() => {
                          $('#fail-payment-transaction').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        <FormattedMessage id='button.back' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary btn-lg'>
                        Mark as Failed
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          id='push-payment-transaction'
          theme='primary'
          iconType='fas'
          iconName='question-circle'
          title='Push Transaction'
          body='Are you sure you want to push this transaction to the integrated system?'
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
            onClick={() => {
              $('#receipt-payment-transaction').modal('show');
            }}
          >
            <FormattedMessage id='button.back' />
          </button>
          <button
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.pushPaymentTransaction(this.props.paymentTransaction?.id)}
          >
            Push Transaction
          </button>
        </Modal>

        <Modal
          id='manually-push-payment-transaction'
          theme='primary'
          iconType='fas'
          iconName='question-circle'
          title='Mark Transaction as Manually Entered'
          body="Are you sure you want to mark this transaction's push status as manually entered? Performing this action does not push the transaction itself."
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
            onClick={() => {
              $('#receipt-payment-transaction').modal('show');
            }}
          >
            <FormattedMessage id='button.back' />
          </button>
          <button
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.manuallyPushPaymentTransaction(this.props.paymentTransaction?.id)}
          >
            Mark as Manually Entered
          </button>
        </Modal>

        <Modal
          id='cancel-payment-transaction'
          theme='primary'
          iconType='fas'
          iconName='question-circle'
          title='Cancel Transaction'
          body='Are you sure you want to cancel this transaction?'
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
            onClick={() => {
              $('#receipt-payment-transaction').modal('show');
            }}
          >
            <FormattedMessage id='button.back' />
          </button>
          <button
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.cancelPaymentTransaction(this.props.paymentTransaction?.id)}
          >
            Cancel Transaction
          </button>
        </Modal>

        <div
          className='modal fade'
          id='mark-HWpayment-rejected'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='mark-HWpayment-rejected-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={(e) => this.updatePaymentPayoutStatus(e, 'REJECTED')}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='mark-HWpayment-rejected-label'>
                    Mark Transaction as rejected by Manager
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>
                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>Are your sure you want to mark Transaction as rejected by Manager?</p>
                </div>
                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-md'
                        onClick={() => {
                          $('#mark-HWpayment-rejected').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        <FormattedMessage id='button.back' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary btn-md'>
                        Mark rejected by Manager
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='mark-HWpayment-bycheque'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='mark-HWpayment-bycheque-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={(e) => this.updatePaymentPayoutStatus(e, 'CHEQUE')}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='mark-HWpayment-bychequelabel'>
                    Mark Transaction as sent by cheque
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>
                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>Are your sure you want to mark Transaction as sent by cheque?</p>
                </div>
                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={() => {
                          $('#mark-HWpayment-bycheque').modal('hide');
                          $('#receipt-payment-transaction').modal('show');
                        }}
                      >
                        <FormattedMessage id='button.back' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary'>
                        Mark sent by cheque
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
            className='modal fade'
            id='mark-valid-nsf-transaction'
            tabIndex='-1'
            role='dialog'
            aria-labelledby='settle-payment-transaction-label'
            aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered' role='document'>
            <div className='modal-content'>
              <form onSubmit={this.saveCustomNsfFeeAmount}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='mark-valid-nsf-transaction-label'>
                    Mark NSF transaction(s) as Valid?
                  </h5>
                  <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                    <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                  </button>
                </div>

                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>Enter a custom NSF Fee amount that will apply to all selected NSF transaction(s). Existing NSF Fee amounts will be used if no custom amount is entered.</p>
                </div>

                <div className='modal-body'>
                  <FieldText
                      id='customNsfFeeAmount'
                      label='Custom Nsf Fee Amount'
                      placeholder='Custom NSF Fee Amount'
                      required={false}
                      labelColumns='0'
                      fieldColumns='12'
                      fieldClass='form-control-md'
                      labelClass='col-form-label-md d-none'
                      parent={this}
                      value={this.state.customNsfFeeAmount}
                  />
                </div>

                <div className='modal-footer d-block bg-secondary'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                          type='button'
                          className='btn btn-outline-primary btn-lg'
                          data-dismiss='modal'
                          onClick={() => {
                            $('#mark-valid-nsf-transaction').modal('hide');
                          }}
                      >
                        <FormattedMessage id='button.cancel' />
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button type='submit' className='btn btn-primary btn-lg'>
                        Mark as Valid
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          id='mark-invalid-nsf-transaction'
          theme='primary'
          iconType='fas'
          iconName='question-circle'
          title='Mark NSF transaction(s) as Invalid?'
          body='Are you sure you want to mark the selected NSF transaction(s) as Invalid?'
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
            onClick={() => {
              $('#mark-invalid-nsf-transaction').modal('hide');
            }}
          >
            <FormattedMessage id='button.cancel' />
          </button>
          <button
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.props.markNSFTransactionValidOrInvalid('INVALID', null)}
          >
            Mark as Invalid
          </button>
        </Modal>

        <Modal
            id='mark-chargeback-LETUS-processed'
            theme='primary'
            iconType='fas'
            iconName='question-circle'
            title='Mark CHARGEBACK transaction(s) as Complete?'
            body='Are you sure you want to mark this Chargeback as Complete?'
        >
          <button
              type='button'
              className='btn btn-outline-primary btn-lg'
              data-dismiss='modal'
              onClick={() => {
                $('#mark-chargeback-LETUS-processed').modal('hide');
              }}
          >
            <FormattedMessage id='button.cancel' />
          </button>
          <button
              className='btn btn-primary btn-lg'
              data-dismiss='modal'
              onClick={() => this.props.markChargebackTransactionLETUSProcessed()}
          >
            Mark as Complete
          </button>
        </Modal>
      </>
    );
  }
}

export default ReceiptPaymentTransactionActionsModal;
