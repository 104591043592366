import { useState, useCallback } from 'react';

export const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue: (value) => {
      setValue(value);
    },
    handleChange: useCallback((event, newValue) => {
      if (newValue !== null && typeof newValue !== 'undefined') {
        setValue(newValue);
      } else if(event && event.target) {
        setValue(event.target.value);
      }
    }, []),
  };
};
