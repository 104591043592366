import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import lodash from "lodash";
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import * as constants from '../../util/constants';
import Propertii from './Propertii';

class CreditReporting extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      creditReportingStatus: 'INACTIVE',
    };
    this.getCustomerCreditReport = this.getCustomerCreditReport.bind(this);
  }

  componentDidMount() {
    this.getCustomerCreditReport();
  }

  /**
   * Get details of user credit reporting
   */
  getCustomerCreditReport() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/credit_report/search`,
        {
          orderBy: 'ASC',
          conditionList: [
            {
              type: 'STRING',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              operator: 'EQUALS',
              fieldName: 'billingAccountId',
              fieldValue: this.props.propertyLeaseId,
            },
          ],
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        if (response.data.records.length) {
          this.setState((prevState) => ({
            ...prevState,
            creditReportingStatus: response.data.records[0]?.status,
          }));
        }
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The customer notes component.
   */
  render() {
    return (
      <React.Fragment>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>CreditReporting</div>
            </div>
          </div>

          <div className='col my-3'>
            <p className='mb-0'>
              Credit Reporting:
              <strong className={'float-right'}>
                <span className={this.state.creditReportingStatus === 'ACTIVE' ? 'text-primary' : 'text-danger'}>
                  {lodash.startCase(lodash.toLower(this.state.creditReportingStatus))}
                  <FontAwesomeIcon icon={['fas', 'circle']} className={'fa-fw small ml-2'} />
                </span>
              </strong>
            </p>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CreditReporting.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CreditReporting);
