import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import React, { useEffect, useState } from "react";
import { HYPER_WALLET_CONSTANTS } from "../../../../constants/HyperWalletConstants";
import { HYPER_WALLET_PAYMENT_CATEGORY } from "../../../../constants/HyperWalletPaymentTypes";
import Alert from "../../../common/Alert";
import { useValidationList } from "../../../common/hooks/useValidationList";
import styles from "../css/HyperwalletPayment.module.css";
import HyperWalletAddressFields from "../HyperWalletAddressFields";
import HyperWalletPropertyModal from "../HyperWalletPropertyModal";

const PersonalInfoStep = (props) => {
  const { validationList, setAlertMessage, clearValidationList } =
    useValidationList([]);

  /****** Actions *****/
  const validateForm = (event, paymentCategory) => {
    event.preventDefault();

    const personalInfoStepValidity = $(
      "#personalInfoStepForm"
    )[0]?.checkValidity();

    if (!personalInfoStepValidity) {
      $("#personalInfoStepForm")[0].reportValidity();
      return;
    }

    clearValidationList();
    let validSubmission = true;

    if (props.emailPI !== props.confirmEmailPI) {
      setAlertMessage("Email and Confirmation Email must match.");
      validSubmission = false;
    }

    if (
      !props.phoneNumberPI.trim() &&
      (!props.emailPI.trim() || !props.confirmEmailPI.trim())
    ) {
      setAlertMessage(
        "You must enter either a valid mobile number or a valid email address."
      );
      validSubmission = false;
    }

    if (!props.validateLandlordForm()) {
      validSubmission = false;
    }

    // submit only if both landlord and personal info forms are valid
    if (validSubmission) {
      handleSubmit(paymentCategory);
    }
  };

  const handleSubmit = (paymentCategory) => {
    const data = {
      paymentCategory: paymentCategory,
    };
    props.next(HYPER_WALLET_CONSTANTS.STEP_PERSONAL_INFO, data);
  };

  const copyFromTenant = () => {
    props.handleChangeStreet1PD(null, props.street1PI);
    props.handleChangeStreet2PD(null, props.street2PI);
    props.handleChangeCityPD(null, props.cityPI);
    props.handleChangePostalCodePD(null, props.postalCodePI);
  };

  /**
   * set the year month and date in the format yyyy-mm-dd for the dob field
   */
  const [currentYearMonthAndDate, setCurrentYearMonthAndDate] = useState(null);
  useEffect(() => {
    const currentTime = new Date();
    const year = currentTime.getFullYear();
    let month = currentTime.getMonth() + 1;
    let date = currentTime.getDate();

    if (month < 10) {
      month = "0" + month;
    }
    if (date < 10) {
      date = "0" + date;
    }

    setCurrentYearMonthAndDate(`${year}-${month}-${date}`);
  }, []);

  /*********************/

  return (
    <>
      <Alert validationList={validationList} />
      <HyperWalletPropertyModal
        street1={props.street1PD}
        street2={props.street2PD}
        city={props.cityPD}
        postalCode={props.postalCodePD}
        country={props.countryPD}
        province={props.provincePD}
        handleChangeStreet1={props.handleChangeStreet1PD}
        handleChangeStreet2={props.handleChangeStreet2PD}
        handleChangeCity={props.handleChangeCityPD}
        handleChangePostalCode={props.handleChangePostalCodePD}
        handleChangeCountry={(event) => props.handleChangeCountryPD(event)}
        handleChangeProvince={props.handleChangeProvincePD}
        copyFromTenant={copyFromTenant}
      />
      <form id="personalInfoStepForm" className="mb-5">
        <div className="form-group">
          <h3 className={`${styles.fontRoboto}`}>
            Payer Details{" "}
            <span
              tabIndex="0"
              role="button"
              data-toggle="popover"
              data-placement="top"
              data-trigger="focus"
              data-content="A payer is the person who pays the payment."
            >
              <FontAwesomeIcon icon={faInfoCircle} className='bs-stepper-info' size='xs'/>
            </span>
          </h3>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="firstNamePI">First Name*</label>
              <input
                type="input"
                className="form-control"
                id="firstNamePI"
                required={true}
                value={props.firstNamePI}
                onChange={props.handleChangeFirstNamePI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="lastNamePI">Last Name*</label>
              <input
                type="input"
                className="form-control"
                id="lastNamePI"
                required={true}
                value={props.lastNamePI}
                onChange={props.handleChangeLastNamePI}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="emailPI">Email*</label>
              <input
                type="email"
                className="form-control"
                id="emailPI"
                required={true}
                value={props.emailPI}
                onChange={props.handleChangeEmailPI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="confirmEmailPI">Confirm Email*</label>
              <input
                type="email"
                className="form-control"
                id="confirmEmailPI"
                required={true}
                value={props.confirmEmailPI}
                onChange={props.handleChangeConfirmEmailPI}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="phoneNumberPI">Mobile*</label>
              <input
                type="tel"
                className="form-control mr-2"
                id="phoneNumberPI"
                required={true}
                value={props.phoneNumberPI}
                onChange={props.handleChangePhoneNumberPI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="dobPI">Date of Birth*</label>
              <input
                type="date"
                className="form-control"
                id="dobPI"
                required={true}
                value={props.dobPI}
                max={currentYearMonthAndDate}
                onChange={props.handleChangeDobPI}
              />
            </div>
          </div>

          <HyperWalletAddressFields
            fieldIdSuffix={"PI"}
            showUnit={true}
            street1={props.street1PI}
            street2={props.street2PI}
            unit={props.unitPI}
            city={props.cityPI}
            postalCode={props.postalCodePI}
            country={props.countryPI}
            province={props.provincePI}
            selectedCountry={props.selectedCountry}
            handleChangeStreet1={props.handleChangeStreet1PI}
            handleChangeStreet2={props.handleChangeStreet2PI}
            handleChangeUnit={props.handleChangeUnitPI}
            handleChangeCity={props.handleChangeCityPI}
            handleChangePostalCode={props.handleChangePostalCodePI}
            handleChangeCountry={(event) => props.handleChangeCountryPI(event)}
            handleChangeProvince={props.handleChangeProvincePI}
          />

          {/* <div className='row'>
            <div className='col-md-4 mt-2'>
              <button type="button"
                className={`btn btn-light form-control ${styles.stepButtonHW} ${styles.addPropertyButtonHW}`}
                data-toggle='modal'
                data-target='#hyperWalletPropertyModal'
              >
                <FontAwesomeIcon
                  icon={['far', 'plus']}
                  className='fa-lg float-left'
                />
                Modify Property Details
              </button>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-6 mt-2">
              <h4>Payment</h4>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <button
                className={`btn btn-light form-control ${styles.stepButtonHW} ${styles.payNowButtonHW}`}
                type="submit"
                name={HYPER_WALLET_PAYMENT_CATEGORY.PAY_NOW}
                onClick={(event) =>
                  validateForm(event, HYPER_WALLET_PAYMENT_CATEGORY.PAY_NOW)
                }
              >
                <FontAwesomeIcon
                  icon={["far", "credit-card"]}
                  className="fa-lg float-left"
                />
                Pay Now
              </button>
            </div>
            <div className="col-md-6 mt-2">
              <button
                className={`btn btn-light form-control ${styles.stepButtonHW} ${styles.payLaterButtonHW}`}
                type="submit"
                name={HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER}
                onClick={(event) =>
                  validateForm(event, HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER)
                }
              >
                <FontAwesomeIcon
                  icon={["far", "clock"]}
                  className="fa-lg float-left"
                />
                Pay Later
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12 mt-4 d-flex justify-content-center">
              <button
                type="button"
                className={`btn btn-light ${styles.stepButtonHW}`}
                onClick={props.previous}
              >
                Back
              </button>
            </div>
          </div>
          <br />
        </div>
      </form>
    </>
  );
};

export default PersonalInfoStep;
