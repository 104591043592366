import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from "react-router-dom";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Banner from "../../common/Banner";
import FieldAddress from "../../common/FieldAddress";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldPhone from "../../common/FieldPhone";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import TermsContents from "../../common/Terms";

class Signup extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            landlord: {},
            company: {},
            billingAddress: {},

            address: {},
            addressList: [],

            showAddressForm: false,
            editAddress: false,

            landlordType: '',

            termsCompleted: false,
            landlordCompleted: false,
            addressesCompleted: false,
            companyCompleted: false,
            allStepsCompleted: false,

            confirmAllDetails: false,
            applyComplete:false,

            validationList: [],

        };

        this.saveTerms = this.saveTerms.bind(this);
        this.saveLandlord = this.saveLandlord.bind(this);
        this.saveAddresses = this.saveAddresses.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
        this.completeCompanyStep = this.completeCompanyStep.bind(this);

        this.editTerms = this.editTerms.bind(this);
        this.editLandlord = this.editLandlord.bind(this);
        this.editAddresses = this.editAddresses.bind(this);
        this.editCompany = this.editCompany.bind(this);

        this.initBillingAddress = this.initBillingAddress.bind(this);

        this.completeLandlordSignup = this.completeLandlordSignup.bind(this);

        this.handleChangeLandlordType = this.handleChangeLandlordType.bind(this);
    }

    /**
     * On mounting of the component, fetch the landlord data based on the log in session, as well as new instances of
     * address objects for the current and previous addresses, in addition to a new instance of a company object. If the
     * status of the application is 'APPLIED', pre-fill the application data and jump straight to the payment method
     * portion of the landlord application flow.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));

            if(response.data.applicationStatus === 'APPLIED') {

                let addressList = [];

                addressList.push(JSON.parse(response.data.customFields.address));

                if(response.data.customFields.prevAddresses) {
                    JSON.parse(response.data.customFields.prevAddresses).forEach((address, key) => {
                        addressList.push(address);
                    });
                }

                this.setState(prevState => ({
                    ...prevState,
                    addressList: addressList,
                    termsCompleted: true,
                    landlordCompleted: true,
                    addressesCompleted: true,
                    companyCompleted: true,
                    allStepsCompleted: true,
                    applyComplete: true,
                    validationList: [],
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                company: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Save the terms of use portion of the landlord application flow.
     *
     * @param event - The event container.
     */
    saveTerms(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: true,
            landlordCompleted: false,
            addressesCompleted: false,
            companyCompleted: false,
            validationList: [],
        }));

    }

    /**
     * Save the landlord details portion of the landlord application flow.
     *
     * @param event - The event container.
     */
    saveLandlord(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            landlordCompleted: true,
            addressesCompleted: false,
            companyCompleted: false,
            validationList: [],
        }));
    }

    /**
     * Save the address history portion of the landlord application flow. If less than 3 years of address(es) have been
     * provided, throw an error to the user prompting them to enter more address(es).
     *
     * @param event - The event container.
     */
    saveAddresses(event) {

        event.preventDefault();

        let addressList = this.state.addressList;

        if(this.state.showAddressForm) {

            if(this.state.editAddress) {
                addressList.forEach((address, key) => {
                    if(address.id === this.state.address.id) {
                        address = this.state.address;
                    }
                });
            }

            if(!this.state.editAddress) {
                addressList.push(this.state.address);
            }

            this.setState(prevState => ({
                ...prevState,
                showAddressForm: false,
                editAddress: false,
                addressList: addressList,
                validationList: [],
            }));
        }

        else {

            let monthsAtAddressTotal = 0;

            addressList.forEach((address, key) => {
                monthsAtAddressTotal = monthsAtAddressTotal + parseInt(address.monthsAtAddress);
            });

            if(monthsAtAddressTotal < 36) {

                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            code: 'landlord.signup.addresses.months',
                            message: 'Sum of ‘Months at Address’ for all addresses that you have provided is less than 3 years.'
                        }
                    }]
                }));

                window.scrollTo(0, 0);
            }

            if(monthsAtAddressTotal >= 36) {
                this.setState(prevState => ({
                    ...prevState,
                    addressesCompleted: true,
                    companyCompleted: false,
                    validationList: [],
                }));
            }
        }
    }

    /**
     * Save the company details portion of the landlord application flow. If successful, the application status of the
     * landlord will become 'APPLIED'.
     *
     * @param event - The event container.
     */
    saveCompany(event) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        event.preventDefault();

        let application = {
            landlord: this.state.landlord,
            address: this.state.addressList[0]
        };

        let previousAddresses = [];

        this.state.addressList.forEach((address, key) => {
            if(address.addressType === 'PREVIOUS') {
                previousAddresses.push(address);
            }
        });

        if(previousAddresses.length > 0) {
            application.prevAddresses = previousAddresses;
        }

        if(this.state.landlordType === 'BUSINESS') {
            let company = this.state.company;
            company['serviceCreditReporting'] = {PROPERTY_CATEGORY:{
                RESIDENTIAL: true,
                COMMERCIAL: false
            }};

            application.company = company;
            
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/apply`, application, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.props.refreshAppState();

            this.setState(prevState => ({
                ...prevState,
                spinner:false,
                landlord: response.data,
                applyComplete: true,
                validationList: [],
            }));

            window.scrollTo(0, 0);

        }).catch(error => {

            this.handleValidation(error);

            if(error.response.data.errorCode === 'RM_ERROR_UNDER_AGE') {
                this.setState(prevState => ({
                    ...prevState,
                    spinner:false,
                    termsCompleted: true,
                    landlordCompleted: false,
                    addressesCompleted: false,
                    companyCompleted: false,
                    confirmAllDetails: false,
                    allStepsCompleted: false,
                }));
    
                window.scrollTo(0, 0);

            }


        });
    }

    completeCompanyStep(event){
        event.preventDefault()

        this.setState(prevState => ({
            ...prevState,
            companyCompleted: true,
            allStepsCompleted: true
        }));
    }

    /**
     * Edit the terms of use portion of the landlord application flow.
     */
    editTerms() {

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: false,
            landlordCompleted: false,
            currentAddressCompleted: false,
            previousAddressCompleted: false,
            companyCompleted: false,
        }));
    }

    /**
     * Edit the landlord details portion of the landlord application flow.
     */
    editLandlord() {

        this.setState(prevState => ({
            ...prevState,
            landlordCompleted: false,
            currentAddressCompleted: false,
            previousAddressCompleted: false,
            companyCompleted: false,
        }));
    }

    /**
     * Edit the address history portion of the landlord application flow.
     */
    editAddresses() {

        this.setState(prevState => ({
            ...prevState,
            addressesCompleted: false,
            companyCompleted: false,
        }));
    }

    /**
     * Edit the company details portion of the landlord application flow.
     */
    editCompany() {

        this.setState(prevState => ({
            ...prevState,
            companyCompleted: false,
        }));
    }

    /**
     * Initialize a new instance of an address object.
     */
    initAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showAddressForm: true,
                editAddress: false,
                address: {
                    ...response.data,
                    addressType: prevState.addressList.length === 0 ? 'RESIDENTIAL' : 'PREVIOUS'
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Edit an existing address from the list of addresses.
     *
     * @param address - The address to edit.
     */
    editAddress(address) {

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: true,
            editAddress: true,
            address: address
        }));
    }

    /**
     * Delete an existing address from the list of addresses.
     *
     * @param address - The address object to delete.
     */
    deleteAddress(address) {

        let addressList = this.state.addressList;

        addressList.forEach((data, key) => {
            if(data.id === address.id) {
                addressList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: false,
            editAddress: false,
            address: {},
            addressList: addressList,
        }));
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.props.userSession.sessionRole.type,
                    userId: this.props.userSession.sessionRole.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Confirm and submit the landlord account setup fee payment.
     */
    completeLandlordSignup() {
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));


        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/completesignup`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('access_token', response.data.token);

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

            $('#signup-complete').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });


        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the landlord type field.
     *
     * @param event - The event container.
     */
    handleChangeLandlordType(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            landlordType: event.target.value,
        }));
    }    

    /**
     * Render the component.
     *
     * @returns {*} - The lease application component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <Banner backgroundImg="boston" heading="landlord.application.heading" subheading="landlord.application.subheading" align="left" values={{firstName: this.props.userSession.sessionRole.firstName}} />

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Terms of Use
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 1 of 4
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Please review and accept the Letus Terms of Use.
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveTerms}>

                                        {this.state.termsCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editTerms()}>
                                                    <div className="">
                                                        Letus Terms of Use
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        I have read and agree to the Letus Terms of Use
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.termsCompleted &&
                                        <React.Fragment>

                                            <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                                <TermsContents />

                                            </div>

                                            <FieldCheckbox id="acknowledgedTerms" fieldLabel="I have read and agree to the Letus Terms of Use" fieldColumns="12" parent={this} model="landlord" value={this.state.landlord['acknowledgedTerms']} />

                                            {this.state.landlord.acknowledgedTerms &&
                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>
                                            }

                                            {!this.state.landlord.acknowledgedTerms &&
                                            <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to continue with your account setup, you must indicate that you have read and agree to the Letus terms of use above.">
                                                Save & Continue
                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>

                            {this.state.termsCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Landlord
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 2 of 4
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Who is the landlord setting up this account?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveLandlord} autoComplete="off">

                                        {this.state.landlordCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editLandlord()}>
                                                    <div className="">
                                                        {this.state.landlord.firstName} {this.state.landlord.lastName}
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.landlord.email}
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editLandlord()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.landlordCompleted &&
                                        <React.Fragment>

                                            <FieldText id="firstName" label="First Name" model="landlord" parent={this} required={true} value={this.state.landlord['firstName']}/>

                                            <FieldText id="lastName" label="Last Name" model="landlord" parent={this} required={true} value={this.state.landlord['lastName']}/>

                                            <FieldText id="email" label="Email" model="landlord" type="email" disabled={true} required={true} parent={this} value={this.state.landlord['email']}/>

                                            <FieldPhone id="phone" label="Phone" required={true} model="landlord" parent={this} value={this.state.landlord['phone']} />

                                            <FieldBirthDate id="birthDate" label="Date of Birth" model="landlord" parent={this} required={true} value={this.state.landlord['birthDate']} monthFirst={true}/>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>

                            </div>
                            }

                            {this.state.landlordCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Addresses
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 3 of 4
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">

                                    <p className="mb-0">
                                        What addresses have you resided at in the last 3 years?
                                    </p>

                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveAddresses}>

                                        {!this.state.addressesCompleted &&
                                        <React.Fragment>
                                            {this.state.addressList.map((address, key) => {
                                                return(
                                                    <div key={key} className="list-group mb-2">
                                                        <div className={`list-group-item ${(this.state.editAddress && address.id === this.state.address.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editAddress && address.id === this.state.address.id) ? null : this.editAddress(address)}>

                                                            <div className="row align-items-center">
                                                                <div className="col-10">
                                                                    <div className="">
                                                                        {address.suite ? address.suite + ' - ' : ''}{address.street1 + (address.street2 ? ', ' + address.street2 : '') + ', ' + address.city + ', ' + (address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province})) + ', ' + formatMessage({id: "country." + address.country}) + ' ' + address.postalCode}
                                                                    </div>
                                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                                        {address.addressType === 'RESIDENTIAL' ? `Current address for ${address.monthsAtAddress} month(s)` : `Previous address for ${address.monthsAtAddress} month(s)`}
                                                                    </small>
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {(this.state.editAddress && address.id === this.state.address.id) &&
                                                            <React.Fragment>

                                                                <div className="mt-3">
                                                                    <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                                                </div>

                                                                <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteAddress(address)}>
                                                                    Delete Address
                                                                </div>

                                                                <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                    Save Address
                                                                </button>

                                                            </React.Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </React.Fragment>
                                        }

                                        {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                        <div className="list-group mb-2">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initAddress()}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Address
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {(!this.state.addressesCompleted && this.state.showAddressForm && !this.state.editAddress) &&
                                        <div className="list-group mb-2">
                                            <div className="list-group-item pb-3">

                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Address
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="mt-3">
                                                    <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} required={true} />
                                                </div>

                                                <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                    Save Address
                                                </button>

                                            </div>
                                        </div>
                                        }

                                        {this.state.addressesCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editAddresses()}>
                                                    <div className="">
                                                        {this.state.addressList[0].suite ? this.state.addressList[0].suite + ' - ' : ''}{this.state.addressList[0].street1 + (this.state.addressList[0].street2 ? ', ' + this.state.addressList[0].street2 : '') + ', ' + this.state.addressList[0].city + ', ' + (this.state.addressList[0].country === 'CA' ? formatMessage({id: "province." + this.state.addressList[0].province}) : formatMessage({id: "state." + this.state.addressList[0].province})) + ', ' + formatMessage({id: "country." + this.state.addressList[0].country}) + ' ' + this.state.addressList[0].postalCode}
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.addressList.length === 1 &&
                                                        <React.Fragment>
                                                            Current residential address
                                                        </React.Fragment>
                                                        }
                                                        {this.state.addressList.length > 1 &&
                                                        <React.Fragment>
                                                            {this.state.addressList.length === 2 ? '1 other address' : this.state.addressList.length - 1 + ' other addresses'}
                                                        </React.Fragment>
                                                        }
                                                    </small>
                                                </div>
                                            </div>

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAddresses()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                        <React.Fragment>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                            {this.state.addressesCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Company Details
                                        </div>
                                        <div className="col-auto align-items-center">
                                            <small className="text-muted">
                                                Step 4 of 4
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Do you operate your rental units as a registered business?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.completeCompanyStep}>

                                        {this.state.companyCompleted &&
                                        <div className="">

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="list-group">
                                                <div className={`list-group-item list-group-item-action c-pointer ${this.state.landlord.applicationStatus === 'APPLIED' ? 'disabled' : ''}`} onClick={() => this.editCompany()}>
                                                    {this.state.landlordType === 'PRIVATE' &&
                                                    <div className="">
                                                        No, I operate my rental units privately
                                                    </div>
                                                    }
                                                    {this.state.landlordType === 'BUSINESS' &&
                                                    <div className="">
                                                        Yes, I operate my rental units as a registered business
                                                    </div>
                                                    }
                                                    {this.state.landlordType === 'PRIVATE' &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        No company information provided
                                                    </small>
                                                    }
                                                    {this.state.landlordType === 'BUSINESS' &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.company.name}
                                                    </small>
                                                    }
                                                </div>
                                            </div>
                                            }

                                            {this.state.landlord.applicationStatus === 'APPLIED' &&
                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer disabled">
                                                    {!this.state.landlord.customFields.company &&
                                                    <div className="">
                                                        No, I operate my rental units privately
                                                    </div>
                                                    }
                                                    {this.state.landlord.customFields.company &&
                                                    <div className="">
                                                        Yes, I operate my rental units as a registered business
                                                    </div>
                                                    }
                                                    {!this.state.landlord.customFields.company &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        No company information provided
                                                    </small>
                                                    }
                                                    {this.state.landlord.customFields.company &&
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        Company details submitted
                                                    </small>
                                                    }
                                                </div>
                                            </div>
                                            }

                                            {this.state.landlord.applicationStatus !== 'APPLIED' &&
                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCompany()}>
                                                Edit
                                            </div>
                                            }

                                        </div>
                                        }

                                        {!this.state.companyCompleted &&
                                        <React.Fragment>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="landlordType" value="PRIVATE" id="landlordTypePrivate" defaultChecked={this.state.landlordType === 'PRIVATE' || false} onChange={this.handleChangeLandlordType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="landlordTypePrivate">

                                                        <div className="">
                                                            Private
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            I operate my rental units privately
                                                        </small>

                                                    </label>
                                                </div>
                                            </div>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="landlordType" value="BUSINESS" id="landlordTypeBusiness" defaultChecked={this.state.landlordType === 'BUSINESS' || false} onChange={this.handleChangeLandlordType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="landlordTypeBusiness">

                                                        <div className="">
                                                            Business
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            I operate my rental units as a registered business
                                                        </small>

                                                        {this.state.landlordType === 'BUSINESS' &&
                                                        <div className="mt-3">

                                                            <FieldText required id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']} />

                                                            <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']} />

                                                            <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']} />

                                                            <FieldText id="phone" label="Business Phone" model="company" type="tel" parent={this} value={this.state.company['phone']} />

                                                            <FieldText id="taxNumber" label="Tax ID" model="company" type="tel" parent={this} value={this.state.company['taxNumber']} />

                                                            <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']} monthFirst={true}/>

                                                            <FieldAddress stateProvinceRequired countryRequired postalCodeRequired model="company" parent={this} value={this.state.company} />

                                                        </div>
                                                        }

                                                    </label>
                                                </div>
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>

                                
                            </div>
                            }
                            {this.state.companyCompleted && !this.state.applyComplete &&
                            <div className="card">

                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-auto mr-auto">
                                            Confirmation
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Please ensure that all the information you have entered is correct
                                    </p>
                                </div>

                                <div className="card-body">
                                    
                                    <FieldCheckbox id="confirmAllDetails" fieldLabel="I confirm that all above information is correct and true" fieldColumns="12" parent={this} value={this.state.confirmAllDetails} />

                                    <button disabled={!this.state.confirmAllDetails} onClick={(event) => this.saveCompany(event)} className="btn btn-primary btn-md btn-block mt-3">
                                        Submit Details
                                    </button> 
                                        
                                    
                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">
                                {/* HERE */}
                                <div className="card">
                                    
                                    <div className="card-header">
                                        Signup Summary
                                    </div>
                                    <div className="card-footer">
                                        <p className="small text-muted mb-0">
                                            Please click the button below to complete signup
                                        </p>
                                    </div>
                                </div>
                                {/* TILL HERE */}
                            
                                {this.state.applyComplete &&
                                <div className="btn btn-primary btn-lg btn-block py-3" onClick={() => this.completeLandlordSignup()}>
                                    <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw" /> Proceed
                                </div>
                                }

                            </div>

                        </div>
                    </div>

                    <Modal id="signup-complete" theme="primary" iconType="far" iconName="thumbs-up" title="You're good to go!"
                        dataBackdrop="static" dataKeyboard={false}
                        body="Congratulations! Your landlord account sign up fee has been successfully processed and you may now continue to your account by clicking the button below.">
                        <Link to="/landlord/transactions" className="btn btn-primary btn-lg" onClick={() => {$('#signup-complete').modal('hide'); localStorage.setItem('status', 'PAID'); this.props.refreshAppState();}}>
                            Continue to my Account
                        </Link>
                    </Modal>

                </div>

            </div>
        )
    };
}

Signup.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Signup);