import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldFile from "../../common/FieldFile";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import ModalPhrase from "../../common/ModalPhrase";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import ServiceLevelConfigCustomReceipt from './ServiceLevelConfigCustomReceipt';
import ServiceLevelConfigNeoFinancial from './ServiceLevelConfigNeoFinancial';
import ServiceLevelConfigPayByText from "./ServiceLevelConfigPayByText";


class ServiceLevelConfigs extends Propertii {
    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);

        this.state = {
            spinnerSmall: false,
            customFields: {},
            validationList: [],
            validationListDanger: [],
            dollarAmount: 0,
            documentSigned: false,
            personCharged: 'tenant',
            editButtonDisabled: true,
            activateButtonDisabled: true,
            creditReportingRecord: {
                feeAmount: 0,
                paidByCompany: false,
                termInMonths: 12
            },

            company:{},
            
            PROP_CAT:{
                RESIDENTIAL: true,
                COMMERCIAL: false
            },
            
            
            microLendingButtonDisabled: true,
            microLendingRecordOnLoad: {
                paypalCredit: this.props.paypalCreditIntegration?.enabled,
                monevoCredit: this.props.monevoIntegration?.enabled,
            },
            microLendingRecord: {
                paypalCredit: this.props.paypalCreditIntegration?.enabled,
                monevoCredit: this.props.monevoIntegration?.enabled,
            },

            insuranceButtonDisabled: true,
            insuranceRecordOnLoad: {
                nueraInsurance: this.props.nueraInsurance?.enabled,
                manualInsurance: this.props.manualInsurance?.enabled,
                apolloInsurance: this.props.apolloInsurance?.enabled,
                melloInsurance: this.props.melloInsurance?.enabled,
            },
            insuranceRecord: {
                nueraInsurance: this.props.nueraInsurance?.enabled,
                manualInsurance: this.props.manualInsurance?.enabled,
                apolloInsurance: this.props.apolloInsurance?.enabled,
                melloInsurance: this.props.melloInsurance?.enabled,
            },

            conciergeButtonDisabled: true,
            conciergeRecordOnLoad: {
                urcsiConcierge: this.props.urcsiConcierge?.enabled,
            },
            conciergeRecord: {
                urcsiConcierge: this.props.urcsiConcierge?.enabled,
            },

            customCard: {},
            imgFile: {
                file: ""
            },

            ccInhereted: false,

            imgFileList:[],

            fileUploadValidationList:[],
            
            initialRecord: null
        };

        this.timer = null;
    }

    /**
     * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
     * on the company ID in the route.
     */
    componentDidMount() {
        this.setState(prevState => ({
            ...prevState,
            query: {
                orderBy: 'DESC',
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    }
                ],
                joins: {
                    company: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['id', 'name', 'serviceCreditReporting']
                    }
                }
            },
        }));

        process.nextTick(() => {
            this.startSpinner();
            this.getCreditReportingRecord()
                .then((response) => {
                    this.stopSpinner();
                    if (response?.data && response.data.records && response.data.records.length > 0) {
                        this.setCurrentReportingRecord(response.data.records[0], true);
                    }

                    if(this.props.property){
                        this.setPropertySettings(this.props.property);
                    } else if (this.props.company){
                        this.setCompanySettings(this.props.company);
                    }
                });
        });
        
        this.handleRefresh();
    }

    /**
     * Some of the props may take some time to get the values, we use componentDidUpdate to get the latest props value
     * @param {*} prevProps 
     */
    componentDidUpdate(prevProps) {
        if(prevProps.paypalCreditIntegration?.enabled !== this.props.paypalCreditIntegration?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                microLendingRecordOnLoad:{
                    ...prevState.microLendingRecordOnLoad,
                    paypalCredit: this.props.paypalCreditIntegration?.enabled,
                },
                microLendingRecord:{
                    ...prevState.microLendingRecord,
                    paypalCredit: this.props.paypalCreditIntegration?.enabled,
                }
            }));
        }
        if(prevProps.monevoIntegration?.enabled !== this.props.monevoIntegration?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                microLendingRecordOnLoad:{
                    ...prevState.microLendingRecordOnLoad,
                    monevoCredit: this.props.monevoIntegration?.enabled,
                },
                microLendingRecord:{
                    ...prevState.microLendingRecord,
                    monevoCredit: this.props.monevoIntegration?.enabled,
                }
            }));
        }
        if(prevProps.nueraInsurance?.enabled !== this.props.nueraInsurance?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                insuranceRecordOnLoad:{
                    ...prevState.insuranceRecordOnLoad,
                    nueraInsurance: this.props.nueraInsurance?.enabled,
                },
                insuranceRecord:{
                    ...prevState.insuranceRecord,
                    nueraInsurance: this.props.nueraInsurance?.enabled,
                }
            }));
        }
        if(prevProps.melloInsurance?.enabled !== this.props.melloInsurance?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                insuranceRecordOnLoad:{
                    ...prevState.insuranceRecordOnLoad,
                    melloInsurance: this.props.melloInsurance?.enabled,
                },
                insuranceRecord:{
                    ...prevState.insuranceRecord,
                    melloInsurance: this.props.melloInsurance?.enabled,
                }
            }));
        }

        if(prevProps.apolloInsurance?.enabled !== this.props.apolloInsurance?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                insuranceRecordOnLoad:{
                    ...prevState.insuranceRecordOnLoad,
                    apolloInsurance: this.props.apolloInsurance?.enabled,
                },
                insuranceRecord:{
                    ...prevState.insuranceRecord,
                    apolloInsurance: this.props.apolloInsurance?.enabled,
                }
            }));
        }
        if(prevProps.manualInsurance?.enabled !== this.props.manualInsurance?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                insuranceRecordOnLoad:{
                    ...prevState.insuranceRecordOnLoad,
                    manualInsurance: this.props.manualInsurance?.enabled,
                },
                insuranceRecord:{
                    ...prevState.insuranceRecord,
                    manualInsurance: this.props.manualInsurance?.enabled,
                }
            }));
        }
        if(prevProps.urcsiConcierge?.enabled !== this.props.urcsiConcierge?.enabled) {
            this.setState(prevState => ({
                ...prevState,
                conciergeRecordOnLoad:{
                    ...prevState.conciergeRecordOnLoad,
                    urcsiConcierge: this.props.urcsiConcierge?.enabled,
                },
                conciergeRecord:{
                    ...prevState.conciergeRecord,
                    urcsiConcierge: this.props.urcsiConcierge?.enabled,
                }
            }));
        }

        if(prevProps.property !== this.props.property) {
            this.setPropertySettings(this.props.property);
        }

        if(prevProps.company !== this.props.company) {
            this.setCompanySettings(this.props.company);
        }
        
        if(prevProps?.propertyId !== this.props?.propertyId) {
            this.handleRefresh();
        }
    }

    /**************************************/
    /******Credit Reporting Services*******/
    /**************************************/
    getCreditReportingRecord() {
        return axios(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/search`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: this.state.query,
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error);
            });
    }

    submitForm(event = {}, confirmation = true) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        
        this.setState(prevState => ({
            ...prevState,
            validationList: [],
        }));

        if (this.state.creditReportingRecord.paidByCompany && confirmation) {
            const modalElement = $('#paid-by-company');
            modalElement.modal('show');
            return false;
        }

        if (this.state.creditReportingRecord.id) {
            this.updateCreditReportingRecord(this.state.creditReportingRecord.id)
                .then((response) => {
                    if(response) {
                        this.setCurrentReportingRecord(response.data, false);
                        this.updateCompany();
                        this.handleSuccess("This Credit Reporting record was successfully updated");
                    }
                });
        } else {
            this.createCreditReportingRecord()
                .then((response) => {
                    if(response) {
                        this.setCurrentReportingRecord(response.data, false);
                        this.updateCompanyFromProps(this.props.companyId);
                        this.handleSuccess("This Credit Reporting record was successfully created");
                    }
                });
        }
    }

    setCurrentReportingRecord(creditReportingRecord, isLoad) {
        if(isLoad){
            this.setState(prevState => ({
                ...prevState,
                initialRecord: JSON.parse(JSON.stringify(creditReportingRecord)),
                company: JSON.parse(JSON.stringify(creditReportingRecord.joins?.company)),
                PROP_CAT: JSON.parse(JSON.stringify(creditReportingRecord.joins?.company?.serviceCreditReporting?.PROPERTY_CATEGORY)),
                creditReportingRecord,
                editButtonDisabled: true,
                documentSigned: true,
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                creditReportingRecord,
                editButtonDisabled: true,
                documentSigned: true,
            }));
        }
    }

    createCreditReportingRecord() {
        return axios(`${constants.REACT_APP_HOST_API_URL}/create`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: {
                type:"TYPE_SERVICE_FEE_PROFILE",
                service: 'CREDIT_REPORT',
                companyId: this.props.companyId,
                feeAmount: Number(this.state.creditReportingRecord.feeAmount || 0),
                paidByCompany: this.state.creditReportingRecord.paidByCompany,
                termInMonths: this.state.creditReportingRecord.termInMonths
            }
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error)
            });
    }

    deleteCreditReportingRecord() {
        axios.delete(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/${this.state.creditReportingRecord.id}/delete`, {
            headers: this.generateRequestHeaders(),
        })
            .then(response => {
                if (response) {
                    this.setState(prevState => ({
                        ...prevState,
                        creditReportingRecord: {},
                        initialRecord: {},
                    }));

                    this.handleSuccess("This Credit Reporting record was successfully deleted");
                }
            })
            .catch(error => {
                this.handleValidation(error)
            });
    }

    updateCreditReportingRecord(id) {
        return axios(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/${id}/update`, {
            method: 'PATCH',
            headers: this.generateRequestHeaders(),
            data: {
                companyId: this.props.companyId,
                feeAmount: Number(this.state.creditReportingRecord.feeAmount || 0),
                paidByCompany: this.state.creditReportingRecord.paidByCompany,
                termInMonths: this.state.creditReportingRecord.termInMonths
            }
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error)
            });
    }

    updateCompany(){
        let company = this.state.company;
        company['serviceCreditReporting'] = {PROPERTY_CATEGORY: this.state.PROP_CAT};
                
        axios.patch(`${constants.REACT_APP_HOST_API_URL}/company/${company.id}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            company['id'] = response.data.id;
            company['serviceCreditReporting'] = response.data.serviceCreditReporting;
                    
            this.setState(prevState => ({
                ...prevState,
                company: company,
                PROP_CAT: response.data.serviceCreditReporting.PROPERTY_CATEGORY
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    updateCompanyFromProps(companyId){
        let company = {};
                
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            company['id'] = response.data.id;
            company['serviceCreditReporting'] = response.data.serviceCreditReporting;
                    
            this.setState(prevState => ({
                ...prevState,
                company: company,
                PROP_CAT: response.data.serviceCreditReporting.PROPERTY_CATEGORY
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    onFormChange() {
        let fees = false;
        let paidByCompany = false;
        let PROPERTY_CATEGORY_UPDATE = false;
        let termInMonthsUpdate = false;
        if (this.state.initialRecord) {
            fees = this.state.creditReportingRecord.feeAmount === this.state.initialRecord.feeAmount;
            paidByCompany = this.state.creditReportingRecord.paidByCompany === this.state.initialRecord.paidByCompany;
            if(this.state.company?.serviceCreditReporting){
                const initialPropertyCat = this.state.company.serviceCreditReporting.PROPERTY_CATEGORY
                PROPERTY_CATEGORY_UPDATE = JSON.stringify(initialPropertyCat) === JSON.stringify(this.state.PROP_CAT)
                termInMonthsUpdate = this.state.initialRecord?.termInMonths === this.state.creditReportingRecord.termInMonths;
            }
        }

        if(!this.state.PROP_CAT.RESIDENTIAL && !this.state.PROP_CAT.COMMERCIAL){
            this.setState(prevState => ({
                ...prevState,
                editButtonDisabled: true,
                validationListDanger: [...prevState.validationListDanger, {
                    alert: {
                        type: 'danger',
                        message: 'At least one of Commercial or Residential checkboxes must be enabled so that tenant credit reporting records are created.'
                    },
                }],
            }));
            return;
        }

        process.nextTick(() => {
            this.setState(prevState => ({
                ...prevState,
                validationListDanger: [],
                editButtonDisabled: !(!fees || !paidByCompany || !PROPERTY_CATEGORY_UPDATE || !termInMonthsUpdate),
                activateButtonDisabled: !this.state.creditReportingRecord.feeAmount || this.state.documentSigned === false,
            }));
        });
    }

    handleSuccess(msg) {
        this.setState(prevState => ({
            ...prevState,
            validationList: [...prevState.validationList, {
                title: 'Success!',
                alert: {
                    type: 'primary',
                    message: msg,
                },
                showMessage: true,
                fields: {},
            }],
        }));

        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                validationList: [],
                validationListDanger: []
            }));
        }, 10000);
    }


    /**************************************/
    /************Common Services***********/
    /**************************************/

    /**
     * Refresh the parent component to get latest/updated values
     */
    handleRefresh() {
        if(this.props.propertyId && this.props.refreshProperty !== undefined) {
            this.props.refreshProperty(this.props.propertyId);
        } else if(this.props.companyId && this.props.handleRefresh !== undefined) {
            this.props.handleRefresh(this.props.companyId);
        }
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;
        this.setState(prevState => ({
            ...prevState,
            validationListDanger: [...prevState.validationListDanger, {
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        }));

        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                validationList: [],
                validationListDanger: []
            }));
        }, 10000);
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
     handleUploadValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;
        this.setState(prevState => ({
            ...prevState,
            fileUploadValidationList: [...prevState.fileUploadValidationList, {
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        }));

        setTimeout(() => {
            this.setState(prevState => ({
                ...prevState,
                fileUploadValidationList: []
            }));
        }, 10000);
    }
    
    

    /**
     * Updates the service setting for the selected microlending partner
     */
    updateServiceSettings(serviceName, serviceSetting) {
        return axios(`${constants.REACT_APP_HOST_API_URL}/company/services`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: {
                "companyId": this.props.companyId,
                "propertyId": this.props.propertyId ? this.props.propertyId : "",
                "serviceName": serviceName,
                "serviceSetting": serviceSetting
            },
        })
    }

    /**************************************/
    /********Micro Lending Services********/
    /**************************************/

    /**
     * Handles Paypal Microlending settings
     */
    updatePaypalMicroLendingSettings() {
        const serviceName = "SERVICE_PAYPAL_CREDIT";
        const serviceSetting = this.state.microLendingRecord.paypalCredit;
        this.updateServiceSettings(serviceName, serviceSetting).then(response => {
            if(response && response?.data?.result === 'SUCCESS') {
                this.handleSuccess(`${serviceName} has been updated successfully.`);
                window.scrollTo(0, 0);

                // To avoid an extra API call
                // The following is redeundant after adding handleRefresh
                // Keeping it, due to lack of time to test
                this.setState(prevState => ({
                    ...prevState,
                    microLendingRecordOnLoad:{
                        ...prevState.microLendingRecordOnLoad,
                        paypalCredit: this.state.microLendingRecord.paypalCredit,
                    },
                    microLendingButtonDisabled: true,
                }));

                this.handleRefresh();
            }
        })
        .catch(error => {
            this.handleValidation(error)
            window.scrollTo(0, 0);

            //Rollback the UI changes
            this.setState(prevState => ({
                ...prevState,
                microLendingRecord:{
                    ...prevState.microLendingRecord,
                    paypalCredit: this.state.microLendingRecordOnLoad.paypalCredit,
                },
            }));
        });;
    }

    /**
     * Handles Monevo Microlending settings
     */
    updateMonevoMicroLendingSettings() {
        const serviceName = "SERVICE_MONEVO";
        const serviceSetting = this.state.microLendingRecord.monevoCredit;
        this.updateServiceSettings(serviceName, serviceSetting).then(response => {
            if(response && response?.data?.result === 'SUCCESS') {
                this.handleSuccess(`${serviceName} has been updated successfully.`);
                window.scrollTo(0, 0);

                // To avoid an extra API call
                // The following is redundant after adding handleRefresh
                // Keeping it, due to lack of time to test
                this.setState(prevState => ({
                    ...prevState,
                    microLendingRecordOnLoad:{
                        ...prevState.microLendingRecordOnLoad,
                        monevoCredit: this.state.microLendingRecord.monevoCredit,
                    },
                    microLendingButtonDisabled: true,
                }));

                this.handleRefresh();
            }
        })
        .catch(error => {
            this.handleValidation(error)
            window.scrollTo(0, 0);

            //Rollback the UI update
            this.setState(prevState => ({
                ...prevState,
                microLendingRecord:{
                    ...prevState.microLendingRecord,
                    monevoCredit: this.state.microLendingRecordOnLoad.monevoCredit,
                },
            }));
        });
    }

    /**
     * Handles Microlending form submission
     * Make approproiate calls to toggle the servicesettings 
     */
    submitMicroLendingForm(event = {}) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }
        this.updatePaypalMicroLendingSettings();
        this.updateMonevoMicroLendingSettings();
    }

    /**
     * Handles Microlending Form changes
     */
    onMicroLendingFormChange() {
        process.nextTick(() => {
            if((this.state.microLendingRecord.paypalCredit !== this.state.microLendingRecordOnLoad.paypalCredit) || (this.state.microLendingRecord.monevoCredit !== this.state.microLendingRecordOnLoad.monevoCredit)) {
                this.setState(prevState => ({
                    ...prevState,
                    microLendingButtonDisabled: false,
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    microLendingButtonDisabled: true,
                }));
            }
        });
    }

    /**************************************/
    /**********Insurance Services**********/
    /**************************************/

    /**
     * Updates insurance config to the latest state
     */
    updateInsuranceConfigToLatestState(data) {
        this.setState((prevState) => ({
            ...prevState,
            spinnerSmall: false,
            insuranceRecordOnLoad:{
                nueraInsurance: data.insurance_nuera?.enabled,
                apolloInsurance: data.insurance_apollo?.enabled,
                manualInsurance: data.insurance_manual?.enabled,
                melloInsurance: data.insurance_mello?.enabled
            },
            insuranceRecord:{
                nueraInsurance: data.insurance_nuera?.enabled,
                apolloInsurance: data.insurance_apollo?.enabled,
                manualInsurance: data.insurance_manual?.enabled,
                melloInsurance: data.insurance_mello?.enabled
            }
        }));
    }

    /**
     * Fetch latest insurance settings
     */
    getLatestInsuranceConfigs() {
        if(this.props.propertyId) {
            this.getProperty(this.props.propertyId)
            .then((response) => {
                if(response.data) {
                    this.updateInsuranceConfigToLatestState(response.data);
                }
            });
        } else {
            this.getCompany(this.props.companyId)
            .then((response) => {
                if(response.data) {
                    this.updateInsuranceConfigToLatestState(response.data);
                }
            });
        }
    }

    /**
     * Debounce getLatestInsuranceConfigs by 5 seconds to avoid making extra API calls
     */
    debounceHandleGetLatestInsuranceConfigs() {
        if(this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setTimeout(() => {
            this.getLatestInsuranceConfigs()
        }, 5000);
    }

    /**
     * Handles the update of Insurance settings
     * @param serviceName - name of the service
     * @param serviceSetting - current state of the service
     */
    updateInsuranceSettings(serviceName, serviceSetting) {
        this.updateServiceSettings(serviceName, serviceSetting).then(response => {
            if(response && response?.data?.result === 'SUCCESS') {
                this.handleSuccess(`${serviceName} activation process has started.`);
                window.scrollTo(0, 0);

                this.setState(prevState => ({
                    ...prevState,
                    spinnerSmall: true,
                    insuranceButtonDisabled: true,
                }));
            }
            this.debounceHandleGetLatestInsuranceConfigs();
        })
        .catch(error => {
            this.debounceHandleGetLatestInsuranceConfigs();
            this.handleValidation(error)
            window.scrollTo(0, 0);
        });
    }

    /**
     * Updates Insurance service settings for a company or property with one API call
     */
    updateInsuranceSettingsBulk() {
        let companyId = null;
        let propertyId = "";
        const payload = {};
        
        if(this.props.companyId) {
            companyId = this.props.companyId;
        } else {
            return;
        }

        if(this.props.propertyId) {
            propertyId = this.props.propertyId;
        }

        payload.propertyId = propertyId;
        payload.services = {
            "SERVICE_INSURANCE_MANUAL" : this.state.insuranceRecord.manualInsurance,
            "SERVICE_INSURANCE_NUERA" : this.state.insuranceRecord.nueraInsurance,
            "SERVICE_INSURANCE_APOLLO": this.state.insuranceRecord.apolloInsurance,
            "SERVICE_INSURANCE_MELLO": this.state.insuranceRecord.melloInsurance
        } 

        return axios(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/services`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: payload,
        })
    }

    /**
     * Handles Insurance form submission
     * Make appropriate calls to toggle the service settings 
     */
    submitInsuranceForm(event = {}) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }
        
        this.updateInsuranceSettingsBulk().then(response => {
            if(response && response?.data?.result === 'SUCCESS') {
                this.handleSuccess(`Insurance settings update process has started.`);
                window.scrollTo(0, 0);

                this.setState(prevState => ({
                    ...prevState,
                    spinnerSmall: true,
                    insuranceButtonDisabled: true,
                }));
            }
            this.debounceHandleGetLatestInsuranceConfigs();
        })
        .catch(error => {
            this.debounceHandleGetLatestInsuranceConfigs();
            this.handleValidation(error)
            window.scrollTo(0, 0);
        });
    }

    /**
     * Handles Insurance Form changes
     */
    onInsuranceFormChange() {
        process.nextTick(() => {
            if((this.state.insuranceRecord.nueraInsurance !== this.state.insuranceRecordOnLoad.nueraInsurance) || (this.state.insuranceRecord.manualInsurance !== this.state.insuranceRecordOnLoad.manualInsurance) || (this.state.insuranceRecord.apolloInsurance !== this.state.insuranceRecordOnLoad.apolloInsurance) || (this.state.insuranceRecord.melloInsurance !== this.state.insuranceRecordOnLoad.melloInsurance)) {
                this.setState(prevState => ({
                    ...prevState,
                    insuranceButtonDisabled: false,
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    insuranceButtonDisabled: true,
                }));
            }
        });
    }

    /**
     * New API to handle the insurance update or save
     */
    handleInsuranceSubmitForm(event = {}) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }
    }

    /**************************************/
    /**********Concierge Services**********/
    /**************************************/

    /**
     * Handles the update of Concierge settings
     * @param serviceName - name of the service
     * @param serviceSetting - current state of the service
     */
    updateConciergeSettings(serviceName, serviceSetting) {
        this.updateServiceSettings(serviceName, serviceSetting).then(response => {
            if(response && response?.data?.result === 'SUCCESS') {
                this.handleSuccess(`${serviceName} has been updated successfully.`);
                window.scrollTo(0, 0);

                this.setState(prevState => ({
                    ...prevState,
                    conciergeButtonDisabled: true,
                }));
            }
            this.handleRefresh();
        })
        .catch(error => {
            this.handleRefresh();
            this.handleValidation(error)
            window.scrollTo(0, 0);
        });
    }

    /**
     * Handles Concierge form submission
     * Make approproiate calls to toggle the servicesettings 
     */
    submitConciergeForm(event = {}) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }
        const SERVICE_URCSI = 'SERVICE_URCSI';
        this.updateConciergeSettings(SERVICE_URCSI, this.state.conciergeRecord.urcsiConcierge);
    }

    /**
     * Handles Insurance Form changes
     */
    onConciergeFormChange() {
        process.nextTick(() => {
            if(this.state.conciergeRecord.urcsiConcierge !== this.state.conciergeRecordOnLoad.urcsiConcierge) {
                this.setState(prevState => ({
                    ...prevState,
                    conciergeButtonDisabled: false,
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    conciergeButtonDisabled: true,
                }));
            }
        });
    }

    /**
     * Update company or property's custom card
     * @param {*} event - The event container.
     */
    submitCustomCardForm(event = {}){
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        axios(`${constants.REACT_APP_HOST_API_URL}/${this.props.propertyId ? 'property' : 'company'}/${this.props.propertyId ? this.props.propertyId : this.props.companyId}/update`, {
            method: 'PATCH',
            headers: this.generateRequestHeaders(),
            data: {
                customCard: this.state.customCard
            }
        })
        .then((response) => {
            window.scrollTo(0,0);
            this.handleSuccess(`${this.props.propertyId ? 'Property' : 'Company'} custom card has been updated.`);
        })
        .catch(error => {
            this.handleValidation(error)
        });
    }
    
    /**
     * 
     */
    onCustomCardFormChange(){
        // process.nextTick(() => {
            
        // });
    }


    /**
   * Select a document and bring up the delete document confirmation modal.
   *
   * @param document - The selected document.
   */
    selectDocument(document){
        this.setState((prevState) => ({
            ...prevState,
            customCard:{
                ...prevState.customCard,
                imageUrl: document.url
            }
        }));
      
        $("#custom-card-documents").modal("hide");
    }

    /**
   * Upload image to company 
   *
   * @param event - The event container.
   */
    uploadImage(event){
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        axios
        .post(
                `${constants.REACT_APP_HOST_API_URL}/${this.props.propertyId ? 'property' : 'company'}/${this.props.propertyId ? this.props.propertyId : this.props.companyId}/upload?isCustomCard=${true}`,
                this.state.imgFile.file,    
                {
                    headers: this.generateRequestHeaders(),
                }
            )
            .then((response) => {
                this.setState((prevState) => ({
                    ...prevState,
                    fileUploadValidationList: [
                        {
                        fields: {},
                        alert: {
                            type: "primary",
                            code: "common.documents.created",
                            message: "Upload successful",
                        },
                        },
                    ]
                }));
                if(this.props.propertyId){
                    this.searchImages(this.props.propertyId, this.props.companyId)
                } else {
                    this.searchImages(this.props.companyId)
                }
            })
            .catch((error) => {
                this.handleUploadValidation(error);
            });
    }

    /**
   * Perform a search for all available images related to the custom card
   */
  searchImages(recordId, parentCompanyId=null, parentParentCompanyId = null) {

    let query = {
        orderBy: "ASC",
        orderByFields: ["createDate"],
        conditionList: [{
            type: "STRING",
            logicalOperator: "AND",
            openBrackets: null,
            closeBrackets: null,
            fieldName: "recordId",
            operator: "EQUALS",
            fieldValue: recordId
        },
        {
            type: "BOOLEAN",
            logicalOperator: "AND",
            openBrackets: null,
            closeBrackets: null,
            fieldName: "customCardImage",
            operator: "EQUALS",
            fieldValue: true
        }],
    }   

    if(parentCompanyId !== null){
        query.conditionList.push({
            type: "STRING",
            logicalOperator: "OR",
            openBrackets: null,
            closeBrackets: null,
            fieldName: "recordId",
            operator: "EQUALS",
            fieldValue: parentCompanyId
        });
    }

        if(parentParentCompanyId !== null){
            query.conditionList.push({
                type: "STRING",
                logicalOperator: "OR",
                openBrackets: null,
                closeBrackets: null,
                fieldName: "recordId",
                operator: "EQUALS",
                fieldValue: parentParentCompanyId
            });
        }

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/document/search`,
        query,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          imgFileList: response.data.records,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  setPropertySettings(property){
      let propComp = property.joins?.company;
      this.searchImages(property.id, propComp?.id, property.joins?.parentComp?.id)
      if (property?.customCard?.enabled) {
          this.setCustomCardSettings(property.customCard)
      } else{
          if (propComp?.customCard?.enabled){
              this.setCustomCardSettings(propComp.customCard, true)
          } else if (property.joins?.parentComp?.customCard?.enabled){
              this.setCustomCardSettings(property.joins.parentComp.customCard, true)
          }

      }
  }

  setCompanySettings(company){
        let parentComp = company.joins?.parentComp;
        if (company.customCard?.enabled) {
            this.setCustomCardSettings(company.customCard)
            if (parentComp) {
                this.searchImages(company.id, parentComp.id);
            }
            else{
                this.searchImages(company.id);
            }
        } else if (company?.joins?.parentComp?.customCard?.enabled) {
            this.setCustomCardSettings(parentComp.customCard, true);
            this.searchImages(company.id, parentComp.id);
        }

  }

  setCustomCardSettings(customCard, isInherented=false){
    this.setState((prevState) => ({
        ...prevState,
        customCard: customCard,
        ccInhereted:isInherented
      }));
  }


    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {
        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <Alert validationList={this.state.validationList}/>
                <Alert validationList={this.state.validationListDanger}/>

                { !this.props?.hideCreditReporting &&
                    <section name="Credit Reporting">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Credit Reporting
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">

                                <p className="">Status: <strong>{this.state.creditReportingRecord.id ? 'Active' : 'Inactive'}</strong></p>

                            <form
                                onSubmit={(event) => this.submitForm(event)}
                                onChange={(event) => this.onFormChange(event)}
                            >
                                <FieldCheckbox
                                    id="documentSigned"
                                    fieldLabel="Property Manager provided confirmation of acknowledgement."
                                    fieldColumns={12}
                                    parent={this}
                                    disabled={this.state.creditReportingRecord.id}
                                    value={this.state.documentSigned}
                                />

                                <FieldCheckbox
                                    id="paidByCompany"
                                    fieldLabel="Charge the fees to the company."
                                    fieldColumns={12}
                                    parent={this}
                                    model="creditReportingRecord"
                                    value={this.state.creditReportingRecord.paidByCompany}
                                />

                                <FieldText
                                    id="feeAmount"
                                    type="number"
                                    prependIconName="dollar-sign"
                                    prependIconType="fas"
                                    label="Dollar amount that will be charged per subscription:"
                                    labelColumns={8}
                                    fieldColumns={4}
                                    model="creditReportingRecord"
                                    step="any"
                                    parent={this}
                                    value={Number(this.state.creditReportingRecord.feeAmount) || '0'}
                                />

                                <FieldText
                                    id="termInMonths"
                                    type="number"
                                    label="Subscription term in months"
                                    labelColumns={8}
                                    fieldColumns={4}
                                    model="creditReportingRecord"
                                    required={true}
                                    min={1}
                                    step={1}
                                    parent={this}
                                    value={this.state.creditReportingRecord.termInMonths}
                                />

                                <FieldCheckbox
                                    id="COMMERCIAL"
                                    model="PROP_CAT"
                                    fieldLabel="Enable Commercial Properties"
                                    fieldColumns={12}
                                    parent={this}
                                    disabled={!this.state.creditReportingRecord?.id}
                                    value={this.state.PROP_CAT.COMMERCIAL}
                                />

                                <FieldCheckbox
                                    id="RESIDENTIAL"
                                    model="PROP_CAT"
                                    fieldLabel="Enable Residential Properties"
                                    fieldColumns={12}
                                    parent={this}
                                    disabled={!this.state.creditReportingRecord?.id}
                                    value={this.state.PROP_CAT.RESIDENTIAL}
                                />

                                {this.state.creditReportingRecord.id &&

                                    <div className="col mt-3">
                                        <p>Enabling Credit Reporting will also enable the pull of paid charges from integration for the company and its properties (if any of these have an integration enabled).</p>
                                    </div>
                                }

                                <div className="text-right">
                                    {this.state.creditReportingRecord.id ?
                                        <div>
                                            <button
                                                type="button"
                                                onClick={() => this.deleteCreditReportingRecord()}
                                                className="btn btn-danger btn-sm mt-5 mr-2 float-left"
                                            >
                                                Disable
                                            </button>

                                            <button
                                                type="submit"
                                                disabled={this.state.editButtonDisabled}
                                                className="btn btn-secondary btn-md mt-5"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Submit Edit
                                            </button>
                                        </div>
                                        :
                                        <button
                                            type="submit"
                                            disabled={this.state.activateButtonDisabled}
                                            className="btn btn-primary btn-md mt-5"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Turn on Service
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>

                    <ModalPhrase
                    id="paid-by-company"
                    title="Confirmation Required"
                    iconName="exclamation-triangle"
                    confirmPhrase="CONFIRM"
                    handleSuccess={() => this.submitForm({}, false)}
                >
                    <span className="d-block mb-2 small">
                        You've selected the Property Manager to cover the cost of the service. Once turned on, all
                        Tenants within the company will be able to activate the service while passing on the cost to
                        the PM. This could result in significant costs to the PM.
                    </span>

                    <span className="d-block mb-4 small font-weight-bold">Are you sure you wish to select the PM to cover the costs?</span>

                    <span className="d-block mb-0 small">Enter the phrase 'CONFIRM' in the field below, then click the Confirm button.</span>
                </ModalPhrase>
                </section>
                }

                <section name="Txt2Pay">
                    <ServiceLevelConfigPayByText propertyId={this.props?.propertyId}
                    propertyName={this.props?.propertyName}
                    parentCompanyId={this.props?.parentCompanyId}
                    companyId={this.props?.companyId}
                    txtToPayBusinessName={this.props?.txtToPayBusinessName}
                    refreshProperty={(propertyId) => this.props.refreshProperty(propertyId)}
                    property={this.props.property}
                    notificationProvider={this.props.company ? this.props.company?.notificationProvider : this.props.property?.joins?.company?.notificationProvider}/>
                </section>

                <section name="Micro Lending">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Micro Lending
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <form
                                onSubmit={(event) => this.submitMicroLendingForm(event)}
                                onChange={(event) => this.onMicroLendingFormChange(event)}
                            >
                                <FieldCheckbox
                                    id="paypalCredit"
                                    fieldLabel="Paypal Credit"
                                    fieldColumns={12}
                                    help="Only applicable for US."
                                    parent={this}
                                    model="microLendingRecord"
                                    value={this.state.microLendingRecord.paypalCredit}
                                />

                                <FieldCheckbox
                                    id="monevoCredit"
                                    fieldLabel="Monevo"
                                    fieldColumns={12}
                                    help="Only applicable for US."
                                    parent={this}
                                    model="microLendingRecord"
                                    value={this.state.microLendingRecord.monevoCredit}
                                />

                                <div className="text-right">
                                    {(this.state.microLendingRecordOnLoad.paypalCredit || this.state.microLendingRecordOnLoad.monevoCredit) ?
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={this.state.microLendingButtonDisabled}
                                                className="btn btn-secondary btn-md mt-5"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Submit Edit
                                            </button>
                                        </div>
                                        :
                                        <button
                                        type="submit"
                                        disabled={this.state.microLendingButtonDisabled}
                                        className="btn btn-primary btn-md mt-5"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Save
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>                    
                </section>
            
                <section name="Insurance">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Insurance
                                    {this.state.spinnerSmall && (
                                        <span
                                            className="spinner-border spinner-border-sm float-right"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <form
                                onSubmit={(event) => this.submitInsuranceForm(event)}
                                onChange={(event) => this.onInsuranceFormChange(event)}
                            >
                                <FieldCheckbox
                                    id="nueraInsurance"
                                    fieldLabel="Nuera Insurance"
                                    fieldColumns={12}
                                    help="Only applicable for CA."
                                    parent={this}
                                    model="insuranceRecord"
                                    value={this.state.insuranceRecord.nueraInsurance}
                                />

                                <FieldCheckbox
                                    id="apolloInsurance"
                                    fieldLabel="Apollo Insurance"
                                    fieldColumns={12}
                                    help="Only applicable for CA."
                                    parent={this}
                                    model="insuranceRecord"
                                    value={this.state.insuranceRecord.apolloInsurance}
                                />

                                <FieldCheckbox
                                    id="manualInsurance"
                                    fieldLabel="Manual Upload Insurance"
                                    fieldColumns={12}
                                    help="Applicable for US and CA."
                                    parent={this}
                                    model="insuranceRecord"
                                    value={this.state.insuranceRecord.manualInsurance}
                                />

                                <FieldCheckbox
                                    id="melloInsurance"
                                    fieldLabel="Mello Insurance"
                                    fieldColumns={12}
                                    help="Only applicable for CA."
                                    parent={this}
                                    model="insuranceRecord"
                                    value={this.state.insuranceRecord.melloInsurance}
                                />

                                <div className="text-right">
                                {(this.state.insuranceRecordOnLoad.nueraInsurance || this.state.insuranceRecordOnLoad.manualInsurance || this.state.insuranceRecordOnLoad.apolloInsurance || this.state.insuranceRecordOnLoad.melloInsurance) ?
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={this.state.insuranceButtonDisabled}
                                                className="btn btn-secondary btn-md mt-5"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Submit Edit
                                            </button>
                                        </div>
                                        :
                                        <button
                                        type="submit"
                                        disabled={this.state.insuranceButtonDisabled}
                                        className="btn btn-primary btn-md mt-5"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Save
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>                    
                </section>
                
                <section name='Neo Promotional Banner'>
                     <ServiceLevelConfigNeoFinancial 
                        companyId={this.props.companyId}
                        propertyId={this.props.propertyId}
                        neoBannerStatus={this.props.neoBanner?.enabled}
                        handleRefreshCompany={this.props.handleRefresh}
                        handleRefreshProperty={this.props.refreshProperty}/>               
                </section>

                <section name='Payment Receipt Promotional Configuration'>
                    <ServiceLevelConfigCustomReceipt 
                        companyId={this.props.companyId}
                        propertyId={this.props.propertyId}
                        handleRefreshCompany={this.props.handleRefresh}
                        handleRefreshProperty={this.props.refreshProperty}
                    />
                </section>

                <section name="Concierge">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Concierge
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <form
                                onSubmit={(event) => this.submitConciergeForm(event)}
                                onChange={(event) => this.onConciergeFormChange(event)}
                            >
                                <FieldCheckbox
                                    id="urcsiConcierge"
                                    fieldLabel="URCSI Concierge"
                                    fieldColumns={12}
                                    help="Applicable for US and CA."
                                    parent={this}
                                    model="conciergeRecord"
                                    value={this.state.conciergeRecord.urcsiConcierge}
                                />

                                <div className="text-right">
                                    {this.state.conciergeRecordOnLoad.urcsiConcierge ?
                                        <div>
                                            <button
                                                type="submit"
                                                disabled={this.state.conciergeButtonDisabled}
                                                className="btn btn-secondary btn-md mt-5"
                                            >
                                                <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Submit Edit
                                            </button>
                                        </div>
                                        :
                                        <button
                                        type="submit"
                                        disabled={this.state.conciergeButtonDisabled}
                                        className="btn btn-primary btn-md mt-5"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Save
                                        </button>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>                    
                </section>

                <section name="Custom Card">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Custom Card {this.state.ccInhereted ? '(DEFAULT)':''}
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                            <form
                                onSubmit={(event) => this.submitCustomCardForm(event)}
                                onChange={(event) => this.onCustomCardFormChange(event)}>
                                
                                <FieldCheckbox
                                    id="enabled"
                                    fieldLabel="Enabled"
                                    fieldColumns={12}
                                    help="Applicable for US and CA."
                                    parent={this}
                                    model="customCard"
                                    required
                                    value={this.state.customCard["enabled"]}
                                />

                                <FieldText
                                    id="cardTitle"
                                    label="Card Title"
                                    model="customCard"
                                    maxLength={20}
                                    parent={this}
                                    required
                                    value={this.state.customCard["cardTitle"]}
                                />

                                <FieldText
                                    id="cardText"
                                    label="Card Text"
                                    model="customCard"
                                    maxLength={200}
                                    parent={this}
                                    value={this.state.customCard["cardText"]}
                                />

                                <FieldText
                                    id="buttonCaption"
                                    label="Button Caption"
                                    model="customCard"
                                    maxLength={20}
                                    parent={this}
                                    required
                                    value={this.state.customCard["buttonCaption"]}
                                />

                                <FieldText
                                    id="buttonUrl"
                                    label="Button URL"
                                    model="customCard"
                                    parent={this}
                                    required
                                    value={this.state.customCard["buttonUrl"]}
                                />

                                <FieldSelect
                                    id="imageUrl"
                                    label="Logo"
                                    model="customCard"
                                    parent={this}
                                    value={this.state.customCard["imageUrl"]}
                                >
                                    <option value="">Select a file...</option>
                                    {this.state.imgFileList && (
                                        <React.Fragment>
                                            {this.state.imgFileList.map((document, key) => {
                                                return (
                                                    <option key={key} value={document.url}>
                                                        {document.name}
                                                    </option>
                                                );
                                            })}
                                        </React.Fragment>
                                    )}
                                </FieldSelect>

                                <div className="text-right">
                                    <button
                                        type="button"
                                        className="btn btn-primary btn-md mr-2 mt-2"
                                        disabled={!this.state.customCard['enabled']}
                                        onClick={() => { $("#custom-card-documents").modal("show"); }}>
                                        Upload Image...
                                    </button>
                                    <button
                                        type="submit"
                                        disabled={this.state.customCard['cardText'] === null || this.state.customCard['cardTitle'] === null}
                                        className="btn btn-primary btn-md mt-2">
                                            <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Save
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* MODAL FOR IMG UPLOAD */}
                    <div
                        className='modal fade'
                        id='custom-card-documents'
                        tabIndex='-1'
                        role='dialog'
                        aria-labelledby='custom-card-documents-label'
                        aria-hidden='true'
                        >
                        <div
                            className='modal-dialog modal-dialog-centered modal-md'
                            role='document'
                        >
                            <div className='modal-content shadow'>
                            <form onSubmit={this.uploadImage}>
                                <div className='modal-header bg-dark text-white'>
                                <h5
                                    className='modal-title'
                                    id='custom-card-documents-label'
                                >
                                    Upload Files
                                </h5>
                                <button
                                    type='button'
                                    className='close text-white'
                                    data-dismiss='modal'
                                    aria-label='Close'
                                >
                                    <FontAwesomeIcon
                                    icon={['fas', 'times']}
                                    className='fa-fw va-b mr-2'
                                    />
                                </button>
                                </div>
                                <div className='modal-body bg-secondary'>
                                <p className='mb-0'>
                                    Images uploaded below will be display in tenant UI under 'Services'. Please upload
                                    either PNG or SVG images as these have the best scaling
                                </p>
                                </div>

                                <div className='modal-body'>
                                <Alert
                                    validationList={this.state.fileUploadValidationList}
                                    validationType='danger'
                                />

                                {this.state.imgFileList.map((document, key) => {
                                    return (
                                    <div key={key} className='list-group mb-2'>
                                        <div
                                        className='list-group-item list-group-item-action c-pointer'
                                        onClick={() => this.selectDocument(document)}
                                        >
                                        <div className='row align-items-center'>
                                            <div className='col-2 text-center'>
                                            <img
                                                src={document.url}
                                                alt={document.name}
                                                className='img-fluid'
                                            />
                                            </div>
                                            <div className='col-8'>
                                            <div className=''>{document.name}</div>
                                            <small className='mb-0 ml-md-0 small text-muted'>
                                                Uploaded on{' '}
                                                <Moment format='MMM DD, YYYY HH:mm'>
                                                {document.createDate}
                                                </Moment>
                                            </small>
                                            </div>
                                            <div className='col-2 text-right'>
                                            <div className='float-right'>
                                                <FontAwesomeIcon
                                                icon={['far', 'trash-alt']}
                                                className='fa-fw'
                                                size='1x'
                                                />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    );
                                })}

                                <FieldFile
                                    id='file'
                                    label='File'
                                    model='imgFile'
                                    labelClass='d-none mb-0'
                                    fieldClass='form-control-lg mb-0'
                                    fieldColumns='12'
                                    labelColums='0'
                                    parent={this}
                                    value={this.state.imgFile['file']}
                                />
                                </div>
                                <div className='modal-footer bg-secondary rounded-bottom d-block'>
                                <div className='row'>
                                    <div className='col-4'>
                                    <button
                                        type='button'
                                        className='btn btn-outline-primary btn-lg'
                                        onClick={() => { $('#custom-card-documents').modal('hide'); }}>
                                        Back
                                    </button>
                                    </div>
                                    <div className='col-8 text-right'>
                                    <button
                                        type='button'
                                        className='btn btn-primary btn-lg ml-2'
                                        onClick={(event) => { this.uploadImage(event);}}>
                                        Upload File
                                    </button>
                                    </div>
                                </div>
                                </div>
                            </form>
                            </div>
                        </div>
                        </div>                     
                </section>
            
            </React.Fragment>
        )
    };
}

ServiceLevelConfigs.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ServiceLevelConfigs);
