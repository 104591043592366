import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";

class Settings extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            integrationId: '',

            validationList: [],

        };
    }

    /**
     * Get the property lease based on the property lease ID provided in the route on mounting of the component.
     */
    componentDidMount() {
        if(this.props.userSession.sessionRole?.roles?.includes('PRIMARY_MANAGER')) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.userSession.sessionRole.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationId: response.data.integrationId
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - The manager account navigation component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Settings" />

                    {this.props.userSession.sessionRole?.roles?.includes('PRIMARY_MANAGER') &&
                    <div className="card">
                        <div className="card-header">
                            Company Settings
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/manager/settings/billing" iconType="far" iconName="file-invoice" name="Billing" size="large" description="Add, edit, or delete payment methods and review your Letus billing details." />

                                {this.state.integrationId &&
                                <NavListItem path="/manager/settings/imports" iconType="far" iconName="file-import" name="Imports" size="large" description="Import tenant recurring and open charge data from your integrated system." />
                                }

                            </div>
                        </div>
                    </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            Account Settings
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/manager/settings/profile" iconType="far" iconName="pencil-alt" name="Edit Profile" size="large" description="Edit your basic profile information and personal details." />

                                {this.props.userSession.accountType === 'TYPE_LOCAL_SYSTEM_ACCOUNT' &&
                                <NavListItem path="/manager/settings/password" iconType="far" iconName="lock-alt" name="Change Password" size="large" description="Change your account password."/>
                                }

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Settings.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Settings);