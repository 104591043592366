import React from 'react';
import { Route } from 'react-router-dom';
import SessionExpired from "../SessionExpired";
import Unauthorized from "../Unauthorized";

/**
 * Disable the ability for users to access private components. Private components are components that require a token
 * in localStorage to access, and that the current time is before the expiry time of the token. Each private component
 * is passed the session owner data and the token prefixed with the 'Bearer' keyword as props.
 *
 * @param Component - The private component to render.
 * @param rest - The 'rest' of the attributes related to the route.
 * @returns {*} - The private component to render.
 * @constructor - No constructor is provided.
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
    let token = localStorage.getItem('access_token');
    let dateExp = Number(localStorage.getItem('expires_at') || 0);
    let dateNow = Date.now();

    let sessionRole;
    let userSession;
    let base64Url;
    let base64;
    let sessionExpired = false;

    if(token !== null) {

        base64Url = token.split('.')[1];
        base64 = base64Url.replace('-', '+').replace('_', '/');
        userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
        sessionRole = JSON.parse(userSession.sessionRole);

        userSession.sessionRole = sessionRole;

        if(dateNow >= dateExp) {
            localStorage.setItem('pathname', window.location.pathname);
            sessionExpired = true;
        }
    }

    return(
        <React.Fragment>

            <Route {...rest} render={props => (dateNow < dateExp && sessionRole?.type === rest?.type) ?
                ( <Component {...props} {...rest} userSession={userSession} token={'Bearer ' + token} /> ) :
                ( <Route path="" component={sessionExpired ? SessionExpired : Unauthorized} /> )}
            />

        </React.Fragment>
    );

};

export default PrivateRoute;
