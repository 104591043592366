import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import Moment from "react-moment";
import { Link } from "react-router-dom";
import * as constants from "../../util/constants";
import paymentTypes from "../../util/paymentTypes";
import FieldAddress from "../common/FieldAddress";
import FieldCheckbox from "../common/FieldCheckbox";
import FieldDate from "../common/FieldDate";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import CardBrandIcon from "./CardBrandIcon";
import FieldEmails from "./FieldEmails";
import FieldLineItems from "./FieldLineItems";
import FieldPhones from "./FieldPhones";
class Invoice extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            invoice: this.props.invoice,

            issuer: {
                name: 'PaySlate Inc.',
                email: 'ar@rentmoola.com',
            },

            issuerAddress: {
                addressType: 'RESIDENTIAL',
                suite: '1900',
                street1: '1040 West Georgia Street',
                city: 'Vancouver',
                province: 'BC',
                country: 'CA',
                postalCode: 'V6E4H3'
            },

            issuerPhones: [{
                type: 'Work',
                number: '18886658870'
            }],

            recipient: {},
            recipientAddress: {},
            recipientPhones: [],

            ccEmails: [],
            companyOwnerList: [],

            paymentMethod: {},
            paymentMethodList: [],

            billingProfile: {},
            billingProfileList: [],

            lineItems: [],
            lineItemsTotal: 0,

            headerCompleted: false,
            issuerCompleted: false,
            recipientCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false,

            showRecipientForm: false,

            validationList: [],

        };

        this.saveHeader = this.saveHeader.bind(this);
        this.saveIssuer = this.saveIssuer.bind(this);
        this.saveRecipient = this.saveRecipient.bind(this);
        this.saveCharges = this.saveCharges.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);

        this.initRecipient = this.initRecipient.bind(this);

        this.editHeader = this.editHeader.bind(this);
        this.editIssuer = this.editIssuer.bind(this);
        this.editRecipient = this.editRecipient.bind(this);
        this.editCharges = this.editCharges.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);

        this.searchBillingProfiles = this.searchBillingProfiles.bind(this);
        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);

        this.saveInvoice = this.saveInvoice.bind(this);

        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeBillingProfile = this.handleChangeBillingProfile.bind(this);
    }

    /**
     * On mounting of the component, determine if the user is creating a new invoice or editing an existing invoice by
     * checking to see if an invoice object was provided in the props.
     */
    componentDidMount() {

        if(this.props.invoice.id) {

            let ccEmails = [];

            if(this.props.invoice.ccEmails) {
                this.props.invoice.ccEmails.forEach((ccEmail, key) => {
                    ccEmails.push({
                        address: ccEmail
                    });
                });
            }

            this.setState(prevState => ({
                ...prevState,
                ccEmails: ccEmails,
                issuer: this.props.invoice.creator,
                issuerAddress: this.props.invoice.creator.address,
                issuerPhones: this.props.invoice.creator.phoneNumberList,
                recipient: this.props.invoice.recipient,
                recipientAddress: this.props.invoice.recipient.address,
                recipientPhones: this.props.invoice.recipient.phoneNumberList,
                lineItems: this.props.invoice.itemList,
                validationList: [],
            }));

            // Fetch the default payment method, if one is already provided
            if(this.props.invoice.rmPaymentMethodType && this.props.invoice.rmPaymentMethodId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.invoice.rmPaymentMethodType.substring(5).toLowerCase()}/${this.props.invoice.rmPaymentMethodId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethod: response.data,
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }

        else {

            // By default, auto pay is enabled for new invoices
            this.setState(prevState => ({
                ...prevState,
                invoice: {
                    autoPay: true,
                },
            }));

        }
    }

    /**
     * Save the header portion of the invoice flow.
     *
     * @param event - The event container.
     */
    saveHeader(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            headerCompleted: true,
            issuerCompleted: false,
            recipientCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false,
            validationList: [],
        }));
    }

    /**
     * Save the issuer portion of the invoice flow.
     *
     * @param event - The event container.
     */
    saveIssuer(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            issuerCompleted: true,
            recipientCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false,
            validationList: [],
        }));

        this.searchPaymentMethods();
        this.searchBillingProfiles();
    }

    /**
     * Save the recipient portion of the invoice flow.
     *
     * @param event - The event container.
     */
    saveRecipient(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            recipientCompleted: true,
            chargesCompleted: false,
            paymentMethodCompleted: false,
            validationList: [],
        }));
    }

    /**
     * Save the charges portion of the invoice flow.
     *
     * @param event - The event container.
     */
    saveCharges(event) {

        event.preventDefault();

        let lineItemsTotal = 0;

        this.state.lineItems.forEach((lineItem, key) => {
            lineItemsTotal += (parseFloat(lineItem.unitPrice) * parseFloat(lineItem.quantity));
        });

        this.setState(prevState => ({
            ...prevState,
            chargesCompleted: true,
            paymentMethodCompleted: prevState.billingProfile.id,
            lineItemsTotal: lineItemsTotal.toFixed(2),
            validationList: [],
        }));

        this.searchPaymentMethods();
    }

    /**
     * Save the payment method portion of the invoice flow.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: true,
            validationList: [],
        }));
    }

    /**
     * Initialize the create custom recipient form of the invoice flow.
     */
    initRecipient() {

        this.setState(prevState => ({
            ...prevState,
            billingProfile: {},
            showRecipientForm: true,
        }));
    }

    /**
     * Edit the header portion of the invoice flow.
     */
    editHeader() {

        this.setState(prevState => ({
            ...prevState,
            headerCompleted: false,
            issuerCompleted: false,
            recipientCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false
        }));
    }

    /**
     * Edit the issuer portion of the invoice flow.
     */
    editIssuer() {

        this.setState(prevState => ({
            ...prevState,
            issuerCompleted: false,
            recipientCompleted: false,
            chargesCompleted: false
        }));
    }

    /**
     * Edit the recipient portion of the invoice flow.
     */
    editRecipient() {

        this.setState(prevState => ({
            ...prevState,
            recipientCompleted: false,
            chargesCompleted: false,
            paymentMethodCompleted: false
        }));
    }

    /**
     * Edit the charges portion of the invoice flow.
     */
    editCharges() {

        this.setState(prevState => ({
            ...prevState,
            chargesCompleted: false,
            paymentMethodCompleted: false
        }));
    }

    /**
     * Edit the payment method portion of the invoice flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: false
        }));
    }

    /**
     * Search for a list of billing profiles related to the company for pre-filling recipient and payment method
     * information during the invoice creation flow.
     */
    searchBillingProfiles() {

        axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/profile/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'rmCompanyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.company.id
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                billingProfileList: response.data.records,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of payment methods across all company owners related to the company being invoiced.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        // If dealing with a landlord, fetch the payment methods for the landlord user
        if(this.props.company.landlordId) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.company.landlordId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        // If dealing with a company, fetch the payment methods across all company owners
        if(!this.props.company.landlordId) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/company_owner/search`, {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.company.id
                    },
                ]
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let companyOwnerList = response.data.records;

                companyOwnerList.map((companyOwner, key) => {
                    Object.keys(paymentTypes).map(key => {
                        axios.post(`${constants.REACT_APP_HOST_API_URL}/${paymentTypes[key]}/search`, {
                            orderBy: 'ASC',
                            orderByFields: ['id'],
                            conditionList: [
                                {
                                    type: 'STRING',
                                    logicalOperator: 'AND',
                                    openBrackets: null,
                                    closeBrackets: null,
                                    fieldName: 'userId',
                                    operator: 'EQUALS',
                                    fieldValue: companyOwner.id
                                }
                            ]
                        }, {
                            headers: this.generateRequestHeaders()
                        }).then(response => {
                            response.data.records.map((paymentMethod, key) => {

                                this.setState(prevState => ({
                                    ...prevState,
                                    paymentMethodList: [...prevState.paymentMethodList, paymentMethod],
                                }));

                                return null;

                            });
                        }).catch(error => {
                            this.handleValidation(error);
                        });

                        return null;
                    });

                    return null;
                });

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Save an existing or create a new invoice.
     */
    saveInvoice() {

        let ccEmails = [];

        this.state.ccEmails.forEach((ccEmail, key) => {
            ccEmails.push(ccEmail.address);
        });

        this.setState({
            spinner: true
        });

        if(!this.state.invoice.createDate) {
            axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/create`, {
                ...this.state.invoice,
                rmUserId: this.props.userId,
                rmCompanyId: this.props.company.id,
                rmPaymentMethodId: this.state.paymentMethod.id,
                rmPaymentMethodType: this.state.paymentMethod.type,
                itemList: this.state.lineItems,
                ccEmails: ccEmails,
                creator: {
                    ...this.state.issuer,
                    address: this.state.issuerAddress,
                    phoneNumberList: this.state.issuerPhones,
                    companyId: 'LETUS'
                },
                recipient: {
                    ...this.state.recipient,
                    address: this.state.recipientAddress,
                    phoneNumberList: this.state.recipientPhones,
                    companyId: this.props.company.id,
                }
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false
                });

                $('#invoice-status').modal('show');

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }

        if(this.state.invoice.createDate) {
            axios.put(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/${this.state.invoice.id}/update`, {
                ...this.state.invoice,
                rmUserId: this.props.userId,
                rmCompanyId: this.props.company.id,
                rmPaymentMethodId: this.state.paymentMethod.id,
                rmPaymentMethodType: this.state.paymentMethod.type,
                itemList: this.state.lineItems,
                ccEmails: ccEmails,
                creator: {
                    ...this.state.issuer,
                    address: this.state.issuerAddress,
                    phoneNumberList: this.state.issuerPhones
                },
                recipient: {
                    ...this.state.recipient,
                    address: this.state.recipientAddress,
                    phoneNumberList: this.state.recipientPhones,
                    companyId: this.props.company.id,
                }
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false
                });

                $('#invoice-status').modal('show');

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));
    }

    /**
     * Handle changes to the selected billing profile. Parses the value of the selected billing profile as JSON and
     * assigns the appropriate values to the recipient state.
     *
     * @param event - The event container.
     */
    handleChangeBillingProfile(event) {

        event.persist();

        let billingProfile = JSON.parse(event.target.value);

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: billingProfile,
            recipient: {
                ...prevState.recipient,
                firstName: billingProfile.firstName,
                lastName: billingProfile.lastName,
                email: billingProfile.email,
            },
            recipientAddress: {
                ...prevState.recipientAddress,
                ...billingProfile.address
            },
            recipientPhones: [
                ...billingProfile.phoneNumberList
            ],
            paymentMethod: {
                id: billingProfile.paymentMethodId,
                type: billingProfile.rmPaymentMethodType
            },
            showRecipientForm: false,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles customers accepting invitations.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <div className="">

                <Spinner visible={this.state.spinner} />

                <div className="row">
                    <div className="col-md-8">

                        <div className="card">

                            <div className="card-header">
                                Header
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">What are the invoice header details?</p>
                            </div>

                            <div className="card-body">

                                {this.state.headerCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editHeader()}>
                                            <div className="">
                                                {this.state.invoice.invoiceNumber ? this.state.invoice.invoiceNumber : this.state.invoice.description}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                Due on <Moment format="MMMM DD, YYYY">{this.state.invoice.dueDate}</Moment>
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editHeader()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.headerCompleted &&
                                <form onSubmit={this.saveHeader} autoComplete="off">

                                    {this.state.invoice.createDate &&
                                    <FieldText id="invoiceNumber" label="Invoice ID" model="invoice" parent={this} value={this.state.invoice['invoiceNumber']}/>
                                    }

                                    <FieldText id="description" label="Description" model="invoice" required={true} parent={this} value={this.state.invoice['description']} />

                                    {this.state.invoice.createDate &&
                                    <FieldDate id="issueDate" label="Issue Date" model="invoice" parent={this} value={this.state.invoice['issueDate']}/>
                                    }

                                    <FieldDate id="dueDate" label="Due Date" model="invoice" required={true} parent={this} value={this.state.invoice['dueDate']} />

                                    <FieldSelect id="currency" label="Currency" model="invoice" required={true} parent={this} value={this.state.invoice['currency']}>
                                        <option value="">Select a currency...</option>
                                        <option value="CAD">CAD</option>
                                        <option value="USD">USD</option>
                                    </FieldSelect>

                                    {this.state.invoice.createDate &&
                                    <FieldSelect id="status" label="Status" model="invoice" required={true} parent={this} value={this.state.invoice['status']}>
                                        <option value="">Select a status...</option>
                                        <option value="DRAFT">Draft</option>
                                        <option value="ISSUED">Issued</option>
                                        <option value="PAID">Paid</option>
                                        <option value="PAYMENT_FAILED">Payment Failed</option>
                                        <option value="CANCELLED">Cancelled</option>
                                        <option value="CORRECTION">Correction</option>
                                    </FieldSelect>
                                    }

                                    <FieldText id="taxNumber" label="Tax ID" model="invoice" parent={this} value={this.state.invoice['taxNumber']} />

                                    <FieldText id="taxDescription1" label="Tax Type 1" model="invoice" parent={this} value={this.state.invoice['taxDescription1']} />

                                    <FieldText id="taxDescription2" label="Tax Type 2" model="invoice" parent={this} value={this.state.invoice['taxDescription2']} />

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            CC Emails
                                        </label>
                                        <div className="col-sm-9">
                                            <FieldEmails emails={this.state.ccEmails} model="ccEmails" size="sm" parent={this} />
                                        </div>
                                    </div>

                                    <FieldCheckbox id="autoPay" label="Auto Pay" fieldLabel="Automatically bill the recipient for this invoice" model="invoice" parent={this} value={this.state.invoice['autoPay']} />

                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                        </div>

                        {this.state.headerCompleted &&
                        <div className="card">

                            <div className="card-header">
                                Issuer
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">Who is issuing this invoice?</p>
                            </div>

                            <div className="card-body">

                                {this.state.issuerCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editIssuer()}>
                                            <div className="">
                                                {this.state.issuer.name ? this.state.issuer.name : this.state.issuer.firstName + ' ' + this.state.issuer.lastName}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.issuer.email}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editIssuer()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.issuerCompleted &&
                                <form onSubmit={this.saveIssuer} autoComplete="off">

                                    <FieldText id="name" label="Company Name" model="issuer" parent={this} value={this.state.issuer['name']} />

                                    <FieldText id="firstName" label="First Name" model="issuer" parent={this} value={this.state.issuer['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="issuer" parent={this} value={this.state.issuer['lastName']} />

                                    <FieldText id="email" label="Email" model="issuer" required={true} parent={this} value={this.state.issuer['email']} />

                                    <FieldAddress model="issuerAddress" parent={this} value={this.state.issuerAddress} suite={true} required={true} />

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            Phones
                                        </label>
                                        <div className="col-sm-9">
                                            <FieldPhones phones={this.state.issuerPhones} model="issuerPhones" size="sm" parent={this} />
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                        </div>
                        }

                        {this.state.issuerCompleted &&
                        <div className="card">

                            <div className="card-header">
                                Recipient
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">Who is receiving this invoice?</p>
                            </div>

                            <div className="card-body">

                                {this.state.recipientCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editRecipient()}>
                                            <div className="">
                                                {this.state.recipient.firstName} {this.state.recipient.lastName}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.recipient.email}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editRecipient()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.recipientCompleted &&
                                <form onSubmit={this.saveRecipient} autoComplete="off">

                                    {this.state.billingProfileList.map((data, key) => {
                                        return (
                                            <div key={key} className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="billingProfile" value={JSON.stringify(data)} id={key} checked={this.state.billingProfile.id === data.id || false} onChange={this.handleChangeBillingProfile} className="custom-control-input"/>
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                        <div className="row align-items-center">
                                                            <div className="col-12">
                                                                <div className="">
                                                                    {data.firstName} {data.lastName}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {data.email}
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}

                                    {!this.state.showRecipientForm &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initRecipient()}>
                                            <div className="row align-items-center">
                                                <div className="col-12">
                                                    Create Custom Recipient
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {this.state.showRecipientForm &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center mb-3">
                                                <div className="col-12">
                                                    Create Custom Recipient
                                                </div>
                                            </div>

                                            <FieldText id="name" label="Company Name" model="recipient" parent={this} value={this.state.recipient['name']} />

                                            <FieldText id="firstName" label="First Name" model="recipient" required={true} parent={this} value={this.state.recipient['firstName']} />

                                            <FieldText id="lastName" label="Last Name" model="recipient" required={true} parent={this} value={this.state.recipient['lastName']} />

                                            <FieldText id="email" label="Email" model="recipient" required={true} parent={this} value={this.state.recipient['email']} />

                                            <FieldAddress model="recipientAddress" parent={this} value={this.state.recipientAddress} suite={true} required={true} />

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm">
                                                    Phones
                                                </label>
                                                <div className="col-sm-9">
                                                    <FieldPhones phones={this.state.recipientPhones} model="recipientPhones" size="sm" parent={this} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    }

                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                            <div className="card-footer">
                                <p className="small text-muted mb-0">To manage billing profiles for this company, <Link to={`/admin/companies/${this.props.company.id}/billing`}>click here</Link>.</p>
                            </div>

                        </div>
                        }

                        {this.state.recipientCompleted &&
                        <div className="card">

                            <div className="card-header">
                                Charges
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">What are the charges for this invoice?</p>
                            </div>

                            <div className="card-body">

                                {this.state.chargesCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCharges()}>
                                            <div className="">
                                                {this.state.lineItems.length} line item(s)
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                Totalling <FormattedNumber value={this.state.lineItemsTotal} style={`currency`} currency={this.state.invoice.currency} /> plus applicable taxes
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCharges()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.chargesCompleted &&
                                <form onSubmit={this.saveCharges} autoComplete="off">

                                    <table className="table table-bordered small">
                                        <thead>
                                        <tr className="rounded">
                                            <th className="small font-weight-bold bg-light" width="19%">
                                                Description
                                            </th>
                                            <th className="small font-weight-bold bg-light" width="19%">
                                                Unit Price
                                            </th>
                                            <th className="small font-weight-bold bg-light" width="19%">
                                                Quantity
                                            </th>
                                            <th className="small font-weight-bold bg-light" width="19%">
                                                {this.state.invoice.taxDescription1 ? this.state.invoice.taxDescription1 : 'Tax 1'} (%)
                                            </th>
                                            <th className="small font-weight-bold bg-light" width="24%">
                                                {this.state.invoice.taxDescription2 ? this.state.invoice.taxDescription2 : 'Tax 2'} (%)
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>

                                    <div className="form-group row">
                                        <div className="col-sm-12">
                                            <FieldLineItems lineItems={this.state.lineItems} model="lineItems" size="sm" parent={this} />
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>
                        </div>
                        }

                        {(this.state.chargesCompleted && !this.state.billingProfile.id) &&
                        <div className="card">

                            <div className="card-header">
                                Payment Method
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">What is the default method of payment for this invoice?</p>
                            </div>

                            <div className="card-body">

                                {this.state.paymentMethodCompleted &&
                                <div className="">
                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <div className="">
                                                        {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                        <React.Fragment>
                                                            Bank Account
                                                        </React.Fragment>
                                                        }
                                                        {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                        <React.Fragment>
                                                            <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand} />
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                        <React.Fragment>
                                                            Account number ending in {this.state.paymentMethod.last4}
                                                        </React.Fragment>
                                                        }
                                                        {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                        <React.Fragment>
                                                            Card number ending in {this.state.paymentMethod.last4}
                                                        </React.Fragment>
                                                        }
                                                    </small>
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right mr-2">
                                                        <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.paymentMethodCompleted &&
                                <React.Fragment>

                                    <form onSubmit={this.savePaymentMethod}>

                                        {this.state.paymentMethodList.map((data, key) => {

                                            if(data.type !== 'TYPE_CASH') {
                                                return (
                                                    <div key={key} className="list-group mb-2">
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                            <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Bank Account
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                            </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Account number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                Card number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right mr-2">
                                                                            <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return null;

                                        })}

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </form>

                                </React.Fragment>
                                }

                            </div>

                            <div className="card-footer">
                                <p className="small text-muted mb-0">To manage payment methods for this company, <Link to={`/admin/companies/${this.props.company.id}/methods`}>click here</Link>.</p>
                            </div>

                        </div>
                        }

                    </div>
                    <div className="col-md-4">
                        <div className="sticky-top">

                            <div className="card">

                                <div className="card-header">
                                    Invoice Summary
                                </div>

                                {!this.state.headerCompleted &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-secondary">
                                                <FontAwesomeIcon icon={['far', 'file-invoice']} className="fa-fw mt-3 mb-4" size="5x" />
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    As you complete this invoice, your invoice summary will appear here.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                                {this.state.headerCompleted &&
                                <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th colSpan="2">
                                                Header
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                Due Date
                                            </td>
                                            <td className="text-right">
                                                <Moment format="MMMM DD, YYYY">{this.state.invoice.dueDate}</Moment>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Auto Pay
                                            </td>
                                            <td className="text-right">
                                                {this.state.invoice.autoPay ? 'Enabled' : 'Disabled'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Currency
                                            </td>
                                            <td className="text-right">
                                                {this.state.invoice.currency}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                Tax ID
                                            </td>
                                            <td className="text-right">
                                                {this.state.invoice.taxNumber}
                                            </td>
                                        </tr>
                                        {this.state.invoice.taxDescription1 &&
                                        <tr>
                                            <td>
                                                Tax Type 1
                                            </td>
                                            <td className="text-right">
                                                {this.state.invoice.taxDescription1}
                                            </td>
                                        </tr>
                                        }
                                        {this.state.invoice.taxDescription2 &&
                                        <tr>
                                            <td>
                                                Tax Type 2
                                            </td>
                                            <td className="text-right">
                                                {this.state.invoice.taxDescription2}
                                            </td>
                                        </tr>
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }

                                {this.state.chargesCompleted &&
                                <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th colSpan="2">
                                                Charges
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.lineItems.map((lineItem, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>
                                                        {lineItem.description} <span className="text-muted">(x{lineItem.quantity})</span>
                                                    </td>
                                                    <td className="text-right">
                                                        <FormattedNumber value={lineItem.unitPrice} style={`currency`} currency={this.state.invoice.currency} />/ea
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                        <tr className="font-weight-bold">
                                            <td>
                                                Subtotal
                                            </td>
                                            <td className="text-right">
                                                <FormattedNumber value={this.state.lineItemsTotal} style={`currency`} currency={this.state.invoice.currency} />
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                                }

                                {this.state.chargesCompleted &&
                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Applicable taxes and grand total will be calculated after submission.
                                    </p>
                                </div>
                                }

                            </div>

                            {(this.state.headerCompleted && this.state.issuerCompleted && this.state.recipientCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                            <React.Fragment>

                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.saveInvoice()}>
                                    <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Save Invoice
                                </div>

                            </React.Fragment>
                            }

                        </div>

                    </div>
                </div>

                <div className="modal fade" id="invoice-status" tabIndex="-1" role="dialog" aria-labelledby="invoice-status-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                                <div className="">
                                    <FontAwesomeIcon icon={["fas", "check-circle"]} className="fa-fw va-b mb-3" size="4x" />
                                </div>
                                <h5 className="modal-title" id="invoice-status-label">
                                    Invoice Saved
                                </h5>
                            </div>

                            <div className="modal-body border-top-0 text-center">
                                <p className="mb-0">
                                    Your invoice has been successfully saved. Click the button below to return to the invoice list.
                                </p>
                            </div>

                            <div className="modal-footer d-block bg-secondary text-center">

                                <Link to={`/admin/${this.props.company.landlordId ? 'landlords' : 'companies'}/${this.props.company.landlordId ? this.props.company.landlordId : this.props.company.id}/invoices`} className="btn btn-primary btn-lg" onClick={() => $("#invoice-status").modal("hide")}>
                                    Return to Invoices
                                </Link>

                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )
    };
}

Invoice.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoice);