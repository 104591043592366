import React from "react";
import Propertii from "../../common/Propertii";
import ServiceLevelConfigs from "../services/ServiceLevelConfigs";

class ServiceLevelConfig extends Propertii {
  /**
   * Render the component.
   *
   * @returns {*} - The admin ServiceLevelConfig component for properties.
   */
  render() {
    return (
      // We are temporarily hiding CR (Credit Reporting) for properties.
      // Delete hideCreditReporting prop to enable CR at propety level
      <ServiceLevelConfigs
        propertyId={this.props.property?.id}
        companyId={this.props.property?.companyId}
        hideCreditReporting={true}
        monevoIntegration={this.props.property?.monevoIntegration}
        paypalCreditIntegration={this.props.property?.paypalCreditIntegration}
        manualInsurance={this.props.property?.insurance_manual}
        nueraInsurance={this.props.property?.insurance_nuera}
        apolloInsurance={this.props.property?.insurance_apollo}
        melloInsurance={this.props.property?.insurance_mello}
        neoBanner={this.props.property?.service_neofinancial}
        urcsiConcierge={this.props.property?.service_urcsi}
        customCard={this.props.property?.customCard}
        propertyName = {this.props.property?.propertyName}
        txtToPayBusinessName = {this.props.property?.txtToPayBusinessName}
        refreshProperty={(propertyId) => this.props.refreshProperty(propertyId)}
        property={this.props.property}
      />
    );
  }
}

export default ServiceLevelConfig;
