import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';

class NavListItem extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A navigation list item.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.size === 'small' &&
                <Link to={this.props.path} className={`list-group-item list-group-item-action c-pointer ${this.props.customClasses} ${window.location.href.indexOf(this.props.active) > 0 ? 'bg-secondary' : ''}`}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="">
                            <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className="fa-fw va-b mr-2" />
                            {this.props.name}
                            {this.props.comingSoon &&
                            <span className="ml-1 badge badge-light border text-muted">
                                Coming Soon
                            </span>
                            }
                        </div>
                    </div>
                </Link>
                }

                {this.props.size === 'large' &&
                <Link to={this.props.path} className={`list-group-item list-group-item-action flex-column align-items-start ${this.props.customClasses}`}>
                    <div className="media">
                        <div className="align-self-center mr-3 p-2 text-center">
                            <h5 className="mb-0">
                                {this.props.iconName && this.props.iconType && !this.props.customReportIconSrc &&
                                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className="fa-list-ol fa-fw" size="2x" />
                                }
                                {this.props.customReportIconSrc &&
                                    <img src={this.props.customReportIconSrc} alt="report icon" className="svg-inline--fa fa-users fa-w-20 fa-2x fa-list-ol fa-fw" />
                                }
                            </h5>
                            {this.props.notification &&
                            <div className="notification">
                                <span className="fa-stack fa-1x text-danger">
                                    <FontAwesomeIcon icon={['fas', 'circle']} className="fa-2x" />
                                    <FontAwesomeIcon icon={['fas', 'exclamation']} className="fa-stack-1x fa-inverse" />
                                </span>
                            </div>
                            }
                        </div>
                        <div className="media-body align-self-center">
                            <h5 className="mb-1">
                                {this.props.name}
                            </h5>
                            <p className="mb-0">
                                {this.props.description}
                            </p>
                        </div>
                        <div className="align-self-center text-right">
                            <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x" />
                        </div>
                    </div>
                </Link>
                }


            </React.Fragment>
        )
    }
}

NavListItem.propTypes = {
    intl: intlShape.isRequired,
};

NavListItem.defaultProps = {
    iconType: 'fas',
    iconName: 'circle',
    notification: false
};

export default injectIntl(NavListItem);