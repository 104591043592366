import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';
import servicesIcon from '../../media/img/icons/services.svg';

class NavItem extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
     constructor(props) {

        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

     /**
     * Handler Click on navItem
     */
      handleClick(event, path) {
          event.preventDefault();
          window.location.href = path;

      }
 
    /**
     * Render the component.
     *
     * @returns {*} - A navigation item.
     */
    render() {
        const servicesIconStyle1x = {width: '1.25em', fontSize: '1.3em'};
        const servicesIconStyle2x = {width: '1.25em', fontSize: '1.65em'};

        return(
            <li className={`nav-item ${window.location.href.indexOf(this.props.path) > 0 ? 'active' : ''} ${this.props.upperNav && (this.props.name === 'Log Out' || this.props.name === 'Help') ? 'd-md-none d-lg-block' : ''} ${this.props.upperNav ? 'd-md-block d-lg-none' : ''} ${this.props.customClasses}`}>

                {!this.props.externalPath &&
                <NavLink id={this.props.path} to={this.props.path} className={`nav-link ${this.props.disabled ? 'disabled' : ''}`} activeClassName={this.props.upperNav ? 'text-primary' : 'text-white'} onClick={ this.props.pathToRedirect ? (e) => this.handleClick(e, this.props.pathToRedirect) : null}>

                    {this.props.iconName && this.props.iconName !== "services-custom" &&
                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className={`fa-fw va-b mr-1 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`} size={this.props.upperNav ? '1x' : '2x'} />
                    }

                    {this.props.iconName === "services-custom" &&
                        <img src={servicesIcon} alt="service icon" style={this.props.upperNav ? servicesIconStyle1x: servicesIconStyle2x} className={`fa-fw va-b mr-1 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`}/>
                    }

                    {this.props.notification &&
                    <div className="notification">
                        <span className="badge badge-danger badge-pill">
                             <FontAwesomeIcon icon={['fas', 'exclamation']} className="va-b" />
                        </span>
                    </div>
                    }

                    <div className={`nav-title ${this.props.upperNav ? 'd-inline' : ''}`}>
                        {this.props.name}
                    </div>

                </NavLink>
                }

                {this.props.externalPath &&
                <a href={this.props.path} target="_blank" rel="noopener noreferrer" className="nav-link">

                    {this.props.iconName &&
                    <FontAwesomeIcon icon={[this.props.iconType, this.props.iconName]} className={`fa-fw va-b mr-1 mr-md-0 ${this.props.upperNav ? 'mr-md-1' : 'mr-md-0'}`} size={this.props.upperNav ? '1x' : '2x'} />
                    }

                    {this.props.notification &&
                    <div className="notification">
                        <span className="badge badge-danger badge-pill">
                             <FontAwesomeIcon icon={['fas', 'exclamation']} className="va-b" />
                        </span>
                    </div>
                    }

                    <div className={`nav-title ${this.props.upperNav ? 'd-inline' : ''}`}>
                        {this.props.name}
                    </div>

                </a>
                }

            </li>
        )
    }
}

NavItem.propTypes = {
    intl: intlShape.isRequired,
};

NavItem.defaultProps = {
    customClasses: '',
    upperNav: false,
    externalPath: false,
    notification: false
};

export default injectIntl(NavItem);