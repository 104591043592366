import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class FieldFile extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            fileName: 'Choose a file...',
            validationList: [],
        };

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        const fileName = event.target.files[0]?.name ? event.target.files[0].name : 'Choose a file...';
        const data = new FormData();

        data.append('file', event.target.files[0], event.target.files[0]?.name);

        this.setState(prevState => ({
            ...prevState,
            fileName: fileName
        }));

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = data;
        }

        if(this.props.model == null) {
            field = data;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic text field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(
            <div className="form-group row">

                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label}
                </label>

                <div className={`col-sm-${this.props.fieldColumns}`}>

                    <div className="input-group">

                        <div className="custom-file">

                            <input
                                type="file"
                                id={this.props.id}
                                name={this.props.id}
                                onChange={this.props.handleChange || this.handleChange}
                                accept={this.props.accept}
                                data-max-size={this.props.dataMaxSize}
                                disabled={this.props.disabled}
                                className={`custom-file-input ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}
                            />

                            <label className="custom-file-label" htmlFor="inputGroupFile04">{this.props.fileName || this.state.fileName}</label>

                        </div>

                    </div>

                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldFile.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-md',
    fieldClass: 'form-control-md',
    type: 'text',
    maxLength: '100'
};

export default FieldFile;
