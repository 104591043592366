import React from 'react';
import Banner from './Banner';

const TermsPaysafe = () => {
  return (
    <div className='content-block'>
      <Banner backgroundImg='discussion' heading='static.terms.heading' subheading='static.terms.subheading' />

      <div className='container'>
        <h2 className='mb-3'>Paysafe Merchant Services Agreement</h2>
        <h4 className='my-4'>PART I &ndash; CC PROCESSING</h4>
        <p>
          Subject to the requirements of applicable Card Association Rules, Paysafe Merchant Services Corp.
          ("Paysafe") and Merchant agree as follows:
        </p>
        <h4 className='my-4'>Article 1 &ndash; DEFINITIONS</h4>
        <p>
          1.01 "<u>Account</u>" means a commercial chequing or demand deposit account maintained by Merchant
          (and referred to in the Section hereof entitled "Deposit Account") for the crediting of collected
          funds and the debiting of fees and charges under the Merchant Agreement.
        </p>
        <p>
          1.02 "<u>ACH</u>" means the Automated Clearinghouse system governed by the National Automated
          Clearinghouse Association.
        </p>
        <p>
          1.03 "<u>Authorization</u>" means a computerized function or a direct phone call to a designated
          number to examine individual Transactions to obtain approval from the Card Issuer to charge or debit the Card,
          as the case may be, for the amount of the sale.
        </p>
        <p>
          1.04 "<u>Bank</u>" means the acquiring financial institution that sponsors Paysafe&rsquo;s
          registration with the Card Associations.
        </p>
        <p>
          1.05 "<u>Card</u>" means, (i) a valid credit or debit card bearing the "Visa",
          "MasterCard" or "Discover" branded marks; or (ii) any other valid credit or debit card
          accepted by Merchant by agreement with Paysafe.
        </p>
        <p>
          1.06 "<u>Card Association</u>" means Visa, MasterCard, Discover, or any other brand in conjunction
          with which Card Issuers provide Cards accepted by Merchant by agreement with Paysafe.
        </p>
        <p>
          1.07 "<u>Card Association Rules</u>" means the respective and collective by-laws, rules,
          regulations, operating manuals, operating letters and policies, and cardholder data security standards, as
          such may be amended from time to time, and established and imposed by Card Associations in respect of Cards.
        </p>
        <p>
          1.08 "<u>Card Issuer</u>" means the financial institution or company which has provided a Card to
          a Cardholder.
        </p>
        <p>
          1.09 "<u>Cardholder</u>" means an individual that is issued a Card.
        </p>
        <p>
          1.10 "<u>Cardholder Information</u>" means any non-public, personally identifiable information
          about a Cardholder, including any combination of Cardholder name plus the Cardholder&rsquo;s social insurance
          number, driver&rsquo;s license or other identification number or credit or debit card number, or other bank
          account number.
        </p>
        <p>
          1.11 "<u>Chargeback</u>" means the procedure by which a Sales Draft (or disputed portion thereof)
          is returned to Paysafe by a Card Issuer.
        </p>
        <p>
          1.12 "<u>Convenience Fee</u>" means a fee charged by Merchant to a Cardholder for the bona fide
          convenience of making payment through an alternative payment channel outside of the Merchant&rsquo;s customary
          payment channels and which is only imposed in a transaction where neither the Cardholder nor the Card are
          physically present.
        </p>
        <p>
          1.13 "<u>Credit Voucher</u>" means a document executed by a Merchant evidencing any refund or
          price adjustment relating to Cards to be credited to a Cardholder account.
        </p>
        <p>
          1.14 "<u>Data Privacy Requirements</u>" means Applicable Laws and guidelines pertaining to
          privacy, including but not limited to, Title V of the Gramm-Leach-Bliley Act, 15 U.S.C. &sect;&sect; 6801
          &ndash;6809 and its implementing rules under Regulation P, 12 C.F.R. Part 1016, Card Association bylaws,
          operating regulations and rules related to data security, data integrity and the safeguarding of Cardholder
          Information including the Payment Card Industry Data Security Standard ("PCI"), MasterCard&rsquo;s
          Site Data Protection Program ("SDP"), Visa&rsquo;s Customer
          InformationSecurityProgram("CISP"), Discover requirements and such other reasonable requirements
          provided by Paysafe from time to time.
        </p>
        <p>
          1.15 "<u>Imprint</u>" means (i) an impression on a Sales Draft manually obtained from a Card
          through the use of an imprinter, or (ii) the electronic equivalent obtained by swiping or dipping a Card
          through a terminal and electronically capturing Card Data and printing a Sales Draft.
        </p>
        <p>
          1.16 "<u>ISV Platform</u>" means an independent software platform (i) that Merchant uses in
          generating Transactions; (ii) with which Merchant maintains a written services agreement; and (iii) that
          Merchant has authorized to request Transfers on its behalf under this Agreement.
        </p>
        <p>
          1.17 "<u>Law(s)</u>" means all laws, statutes, codes, ordinances, decrees, rules, regulations,
          by-laws, statutory rules, principles of law, published policies and guidelines, judicial or arbitral or
          administrative or ministerial or departmental or regulatory judgments, orders, decisions, rulings or awards,
          including general principles of common and civil law, and the terms and conditions of any grant of approval,
          permission, authority or licence of any governmental authority, including without limitation, all consumer
          credit and consumer protection Laws, and anti-money laundering Laws; and the term "applicable"
          with respect to Laws and in a context that refers to one or more persons, means that the Laws apply to the
          person or persons, or its or their business, undertaking or property, and emanate from a governmental
          authority having jurisdiction over the person or persons or its or their business, undertaking or property.
        </p>
        <p>
          1.18 "<u>Merchant Agreement</u>" means the Merchant Application once approved and accepted by
          Paysafe together with these Terms and Conditions, and any supplementary documents referenced herein, and all
          schedules and amendments to the foregoing.
        </p>
        <p>
          1.19 "<u>Payout</u>" means the amount of any payment (pending or previously completed) to Merchant
          for processed Transactions.
        </p>
        <p>
          1.20 "<u>Representative(s)</u>" means any employee, service provider, subcontractor, agent,
          representative, contractor, associate, officer, director, principal of Merchant or any other third party
          engaged by Merchant.
        </p>
        <p>
          1.21 "<u>Reserve Account</u>" has the meaning set out in Section 3.04.
        </p>
        <p>
          1.22 "<u>Retrieval</u>" means responding to requests for documentation relating to a Transaction.
        </p>
        <p>
          1.23 "<u>Sales Draft</u>" means the paper form, whether electronically or manually imprinted,
          evidencing a Transaction.
        </p>
        <p>
          1.24 "<u>Schedule of Fees</u>" means pricing/fees set out on the landing page to the Merchant
          Application and confirmed to the Merchant upon Merchant application submission to Paysafe.
        </p>
        <p>
          1.25 "<u>Service Fee</u>" means a fee imposed on a Cardholder by a Merchant that is a federal,
          state, or local government agency or educational institution.
        </p>
        <p>
          1.26. "<u>Split Payment Service</u>" means the service offered by Paysafe to Merchants, to split a
          settlement amount and remit a portion of the funds to Merchant and another portion to an ISV Platform(s). For
          greater clarity, Merchant agrees and accepts that ISV Platforms, on behalf of the Merchant, may request
          Paysafe to deduct the amount of a Transfer from Merchant&rsquo;s settlement amount to remit such Transfer to
          one or more ISV Platform(s) bank accounts.
        </p>
        <p>
          1.27 "<u>Surcharge</u>" means any fee, cost, or increase in the price of goods or services charged
          by Merchant to a Cardholder that elects to make payment to Merchant by use of a Card instead of a check, cash,
          or other payment method.
        </p>
        <p>
          1.28 "<u>Transaction</u>" means any sale of products or services, or credit for such, from a
          Merchant for which the Cardholder makes payment or receives credit through the use of any Card and which is
          presented to Paysafe for processing.
        </p>
        <p>
          1.29 "<u>Transfer</u>" means a request by an ISV Platform on behalf of Merchant to Paysafe to
          transfer a portion of settlement funds from Merchant&rsquo;s Account to the ISV Platform&rsquo;s bank account
          as part of the Split Payment Service.
        </p>
        <p>
          1.30 "<u>Transfer Data</u>" means a Transfer requested by an ISV Platform on behalf of Merchant to
          Paysafe, via an API request, indicating (i) the amount of the Transfer and (ii) the corresponding ISV Platform
          account number(s), set out in the Merchant Back Office.
        </p>
        <p>
          1.31 "<u>Voice Authorization</u>" means a direct phone call to a designated number to obtain
          credit approval on a Transaction from the Card Issuer, whether by voice or voice-activated systems.
        </p>
        <h4 className='my-4'>Article 2 &ndash; CARD ACCEPTANCE</h4>
        <p>
          2.01&nbsp;<u>Honoring Cards</u>. Merchant will accept all valid Cards when properly presented by Cardholders
          in payment for goods or services, subject to applicable Card Association Rules requiring Merchant to elect
          whether to accept credit only, debit only or both debit and credit Cards. Merchant&rsquo;s election is set
          forth in the Application. Merchant may not establish minimum or maximum amounts for Card sales as a condition
          for accepting any Card. Merchant may not require any Cardholder to pay any part of any discount rate or other
          charge imposed upon Merchant by the Merchant Agreement, whether through imposition of a price increase not
          imposed on cash paying customers or through imposition of any charges not imposed on cash paying customers.
          Notwithstanding the foregoing, Merchant may impose a Surcharge or Convenience Fee on Transactions, to the
          extent permitted under applicable Law and the Card Association Rules. However, nothing herein shall prevent
          Merchant from offering discounts to customers for cash purchases. Merchant may not engage in a Transaction
          (other than a mail, internet, telephone order, or preauthorized sale to the extent permitted under the
          Merchant Agreement) if the person seeking to charge the purchase to his or her Card account does not present
          the Card to permit Merchant to compare the signature on the Card to the signature on the Sales Draft and
          obtain an Imprint or otherwise use the physical Card to complete the Transaction.
        </p>
        <p>
          2.02&nbsp;<u>Advertising</u>. Merchant&rsquo;s use of promotional materials and use of any trade name,
          trademark, service mark or logo type ("Marks") associated with a Card is limited to informing the
          public that the Card will be accepted at Merchant&rsquo;s place(s) of business. Merchant&rsquo;s use of
          promotional materials and Marks is subject to Paysafe&rsquo;s direction and to the Card Association Rules.
          Merchant may use promotional materials and Marks only during the term of the Merchant Agreement and will
          immediately cease use and return any inventory to Paysafe upon termination thereof. Merchant may not use any
          promotional materials or Marks associated with the Card Associations in any way which suggests or implies that
          they endorse any goods or services other than Card services.
        </p>
        <p>
          2.03&nbsp;<u>Card Acceptance</u>. When accepting a Card, Merchant will follow the steps provided by Paysafe
          for accepting Cards and will: (a) obtain Authorization from the Card Issuer to charge the Cardholder&rsquo;s
          account; (b) enter a description of the goods or services sold and the price thereof (including any applicable
          taxes); and (c) deliver a true and completed copy of the Sales Draft to the Cardholder at the time the goods
          are delivered or services performed.
        </p>
        <p>
          2.04&nbsp;<u>Authorization</u>. Merchant will obtain an Authorization for all Card sales. If Merchant cannot,
          for any reason, obtain an electronic Authorization through the use of a terminal, Merchant will request a
          Voice Authorization from Paysafe designated authorization center and will legibly print the Authorization
          number on the Sales Draft. Merchant will not obtain or attempt to obtain Authorization from Paysafe&rsquo;s
          authorization center unless Merchant intends to summit to Paysafe a Transaction for the authorized amount if
          Authorization for the Transaction is given. Merchant may not divide a single Transaction between two or more
          Sales Drafts on a single Card to avoid Authorization limits that may be set by the Card Issuer. Merchant
          acknowledges that an Authorization provides only that the Cardholder account has sufficient credit available
          to cover the amount of the current sale and that an Authorization is not a guarantee that the Transaction will
          not be subject to dispute or Chargeback and does not warranty the Cardholder&rsquo;s identity. Merchant may
          not attempt to obtain an authorization by successively decreasing the sale amount. Paysafe may refuse to
          purchase or process any Sales Draft presented by Merchant: (a) unless a proper authorization code or approval
          code has been recorded on the Sales Draft; (b) if Paysafe determines that the Sales Draft is or is likely to
          become uncollectible from the Cardholder to which the Transaction would otherwise be charged; or (c) if
          Peoples Trust has reason to believe that the Sales Draft was prepared in violation of any provision of the
          Merchant Agreement. Merchant will use, and may not circumvent, fraud identification tools requested by
          Paysafe, including Address Verification System processing, CVC2/CVV2 processing and acknowledges that the use
          of these tools may prevent Merchant from accepting certain Cards as payment. Merchant acknowledges that its
          use of fraud identification tools may not prevent fraudulent Card usage and agrees that any fraudulent
          Transaction may ultimately result in a Chargeback, for which Merchant retains full liability under the
          Merchant Agreement.
        </p>
        <p>
          2.05&nbsp;<u>Multiple Transaction Records: Partial Consideration</u>. Merchant may not prepare more than one
          Sales Draft for a single sale or for a single item but will include all items of goods and services purchased
          in a single Transaction in the total amount on a single Sales Draft except under the following circumstances:
          (a) for purchases in separate departments of a multiple department store; (b) for partial payment, installment
          payment, delayed delivery or an advance deposit; or (c) for delayed or amended charges governed by Card
          Association Rules for travel and entertainment merchants and Transactions.
        </p>
        <p>
          2.06&nbsp;<u>Bona Fide Purchases by Merchant to the Cardholder</u>. Merchant must estimate and obtain
          Authorization for the amount of the Transaction based upon the Cardholder&rsquo;s intended length of stay at
          hotel, car rental, restaurant(s) (and/or approximate tip/gratuity). Additional Authorization must be obtained
          and recorded for charges actually incurred in excess of the estimated amount. Regardless of the terms and
          conditions of any written preauthorization form, the Sales Draft amount for any lodging, vehicle rental or
          restaurant Transaction must include only that portion of the sale, including any applicable taxes evidencing a
          bona fide sale by Merchant to the Cardholder and may not include any consequential charges. Nothing contained
          herein is intended to restrict Merchant from enforcing the terms and conditions of its preauthorization form
          through means other than a Card Transaction.
        </p>
        <p>
          2.07&nbsp;<u>Returns and Adjustments; Credit Vouchers</u>. Merchant&rsquo;s policy for exchange or return of
          goods sold and for adjustments for services rendered will be established and posted in accordance with the
          applicable Card Association&rsquo;s operating regulations and applicable Laws. Merchant will disclose to a
          Cardholder before a Card sale is made, if applicable; (i) that if merchandise is returned, (a) no refund, or
          less than a full refund, will be given, (b) returned merchandise will only be exchanged for similar
          merchandise of comparable value, or (c) only a credit toward purchases will be given, and (ii) that special
          conditions or circumstances apply to the sale (e.g., late delivery, delivery charges, restocking fees, or
          other non-credit terms). If Merchant does not make these disclosures, a full refund in the form of a credit to
          the Cardholder&rsquo;s Card account must be given upon the Cardholder&rsquo;s request. The above disclosures
          must be made on all copies of Sales Drafts or invoices in each case in letters approximately 1/4" (64
          mm) high and, with respect to all Sales Drafts and all invoices requiring a signature, in close proximity to
          the space provided for the Cardholder&rsquo;s signature. Any change in Merchant&rsquo;s return, cancellation
          or adjustment policies must be submitted in writing to Paysafe not less than 14 days prior to the change and
          approved by Paysafe, which approval shall not be unreasonably withheld. Paysafe may refuse to process any
          Sales Draft made subject to a revised return, cancellation or adjustment policy which Paysafe had not
          approved.
        </p>
        <p>
          2.08&nbsp;<u>Cash Payments</u>. Merchant may not receive any payment from a Cardholder for charges included in
          any Transaction nor receive any payment from a Cardholder to prepare and present a Transaction for the purpose
          of affecting a credit to the Cardholder&rsquo;s Card account.
        </p>
        <p>
          2.09&nbsp;<u>Cash Advances</u>. Merchant may not present to Paysafe for collection any Transaction for the
          purpose of obtaining or providing a cash advance either on Merchant&rsquo;s Card or the Card of any other
          party. Violation of this Section is grounds for Peoples Trust&rsquo;s immediate termination of the Merchant
          Agreement.
        </p>
        <p>
          2.10&nbsp;<u>Duplicate Transactions</u>. Merchant may not present to Paysafe for collection duplicate
          Transactions. Paysafe may debit Merchant for any duplicate Transaction adjustments and Merchant is liable for
          any Chargebacks resulting therefrom.
        </p>
        <p>
          2.11&nbsp;<u>Presentment of Fraudulent Transactions</u>. Merchant may not accept or present to Paysafe for
          collection any fraudulent Transaction and may not under any circumstances present for processing or credit,
          directly or indirectly, Transactions originated with any other merchant or any other source. Merchant may
          accept only Transactions arising from bona fide purchases from Merchant for the goods and services for which
          Merchant has been approved under the Merchant Agreement. If Merchant presents to Paysafe for collection any
          prohibited Transaction, Paysafe may: (a) immediately terminate the Merchant Agreement; (b) withhold funds and
          establish a Reserve Account as provided herein; and (c) report Merchant to MATCH (as defined in Section 2.16)
          file, and any other Card Association equivalent. Merchant&rsquo;s employees&rsquo; actions are chargeable to
          Merchant under the Merchant Agreement.
        </p>
        <p>
          2.12&nbsp;<u>Collection of Pre-existing Debt</u>. Merchant may not prepare and present to Paysafe for
          collection any Transaction representing the refinancing of an existing Cardholder obligation including, but
          not limited to, obligations: (a) previously owed to Merchant; (b) arising from the dishonor of a
          Cardholder&rsquo;s personal cheque or relating to a Chargeback; or (c) representing the collection of any
          other pre-existing indebtedness, including collection of delinquent accounts on behalf of third parties.
        </p>
        <p>
          2.13&nbsp;<u>Data Security Personal/Cardholder Information</u>. Merchant may not, as a condition of sale,
          impose a requirement on Cardholders to provide any personal information as a condition for honoring Cards
          unless such information is required to provide delivery of goods or services or Merchant has reason to believe
          the identity of the person presenting the Card may be different than that of the Cardholder. Merchant will
          not, under any circumstances, release, sell or otherwise disclose any Cardholder Information to any person
          other than Paysafe or the applicable Card Association, except as expressly authorized in writing by the
          Cardholder, or as required by law.
        </p>
        <ol type='A'>
          <li>
            <p>
              <u>Safeguards</u>. Merchant will maintain appropriate administrative, technical and physical safeguards
              for all Cardholder Information. These safeguards will (a) ensure the confidentiality of Cardholder
              Information; (b) protect against any anticipated threats or hazards to the security or integrity of
              Cardholder Information; (c) protect against unauthorized access to or use of Cardholder Information that
              could result in substantial harm or inconvenience to any Cardholder; and (d) properly dispose of all
              Cardholder Information to ensure no unauthorized access to Cardholder Information. Merchant will maintain
              all such safeguards applicable to Merchant in accordance with Data Privacy Requirements and applicable
              Laws.
            </p>
          </li>
          <li>
            <p>
              <u>Compliance with Data Privacy Requirements and Card Association Data Security Rules</u>. Merchant
              represents, warrants and covenants that it is and will remain throughout the term of the Merchant
              Agreement in compliance with obligations pertaining to the collection, use, disclosure, retention of
              Cardholder Information including, data security, data integrity and the safeguarding of such information
              as set out in the Data Privacy Requirements in effect and as may be amended, supplemented or replaced.
              Merchant further represents, warrants and covenants that it has obtained all required consents from
              Cardholders in respect of their personal information to be accessed, collected, used or transferred by
              Paysafe (or its service providers) in providing the services under this Merchant Agreement; and it has
              read, understood and hereby accepts Paysafe&rsquo;s privacy policies at:
              https://www.paysafe.com/privacy-policy/. In addition, to the extent that Merchant has been introduced to
              Peoples Trust and entered into this Merchant Agreement through a referral partner of Paysafe or a software
              platform provider integrated to Paysafe (collectively referred to as the "Platform Partner"),
              Merchant acknowledges and agrees that Paysafe may share transaction information with the Platform Partner
              on a need to know basis. Merchant will maintain appropriate administrative, technical and physical
              safeguards for all Cardholder Information. These safeguards will (a) ensure the confidentiality of
              Cardholder Information; (b) protect against any anticipated threats or hazards to the security or
              integrity of Cardholder Information; (c) protect against unauthorized access to or use of Cardholder
              Information that could result in substantial harm or inconvenience to any Cardholder; and (d) properly
              dispose of all Cardholder Information to ensure no unauthorized access to Cardholder Information. Merchant
              will cause all of its Representatives to comply with the Data Privacy Requirements at all times. Merchant
              will report any non-compliance immediately to Paysafe. To help accomplish the foregoing, Merchant and its
              Representatives will encrypt, at appropriate standards required by the Data Privacy Requirements, all
              debit, credit or stored value card numbers whether in storage, transport or backup and will not store data
              security codes on its systems, network or software.
            </p>
          </li>
          <li>
            <p>
              <u>Annual Certification</u>. Merchant will provide, if requested by Paysafe, annual certification to
              Paysafe (in a form acceptable to Paysafe) certifying its and its Representatives compliance with the Data
              Privacy Requirements and other data security provisions of the Merchant Agreement.
            </p>
          </li>
          <li>
            <p>
              <u>Information Use Limitations</u>. Merchant may not sell, disclose, or otherwise make Cardholder
              Information available, in whole or in part, in a manner not provided for in the Merchant Agreement,
              without Paysafe&rsquo;s prior written consent. Merchant may, however, transfer Cardholder Information to
              its Representatives who have a need to know such information to enable Paysafe to provide the services
              described in the Merchant Agreement provided that such individuals or entities have agreed in writing to
              be bound by the confidentiality obligations and data security provisions herein, including compliance with
              Data Privacy Requirements and other applicable Laws.
            </p>
          </li>
          <li>
            <p>
              <u>Response to Unauthorized Access</u>. Merchant will immediately notify Paysafe of its knowledge or
              suspicion of any breach in security resulting in unauthorized access to Cardholder Information. Merchant
              will, provide any assistance that Paysafe, the issuing bank of any Cardholder, and their regulators and
              the Card Associations deem necessary to contain and control the incident to prevent further unauthorized
              access to or use of Cardholder Information. Such assistance may include, but not be limited to, preserving
              records and other evidence, compiling information to enable Paysafe and the issuing bank or the Card
              Associations to investigate the incident and providing assistance and cooperation to facilitate the
              ability of the issuing bank to:
              <br />
              (a) file suspicious activity reports (as applicable); (b) notify their regulators (as applicable); and (c)
              notify the affected Cardholder (as required). Unless the unauthorized access was due to Paysafe&rsquo;s
              acts or omissions, Merchant will bear all costs associated therewith, including but not limited to the
              cost of notifying the affected Cardholder(s).
            </p>
          </li>
          <li>
            <p>
              <u>Access Requests and Complaints</u>. Merchant will cooperate with Paysafe in any request for access to
              Cardholder Information by an individual and in responding to any complaints or investigations by
              individuals, Card Associations or regulators or with respect to Cardholder Information.
            </p>
          </li>
          <li>
            <p>
              <u>Prohibited Activities</u>. Merchant may not store in any system or in any manner Card read data,
              including without limitation CVV2/CVC2 data, PIN data, address verification data or any other information
              prohibited by Card Association Rules and/or Data Privacy Requirements.
            </p>
          </li>
          <li>
            <p>
              <u>Survival</u>. Merchant obligations pertaining to Data Privacy Requirements and Cardholder Information
              contained in the Merchant Agreement will survive indefinitely beyond termination of the Merchant
              Agreement.
            </p>
          </li>
        </ol>
        <p>
          2.14&nbsp;<u>Compliance with Card Association Rules</u>. Merchant will comply with and conduct its Card
          activities in accordance with all applicable Card Association Rules and regulations, as such rules and
          regulations may be amended from time to time whether or not Merchant has been informed of any such amendment.
          Failure to comply with such rules and regulations may result in Merchant being terminated for cause and listed
          on various Card Association and industry databases, including the Terminated Merchant File and the Merchant
          Alert to Control High Risk Merchants file maintained by MasterCard and Visa (collectively as
          "MATCH") and any other Card Association equivalent. With respect to the Card Associations,
          Merchant may not: (a) accept Cardholder payments, cash or otherwise, for previous Card charges incurred at the
          Merchant location;(b) establish a minimum or maximum transaction amount as a condition for honoring a Card;
          (c) require a Cardholder to complete a postcard or similar device that includes the Cardholder&rsquo;s account
          number, card expiration date, signature, or any other card account data in plain view when mailed; (d) add any
          Surcharges or Service Fees, Convenience Fees to Transactions other than in accordance with applicable Law and
          Card Association Rules,; (e) add any tax to Transactions, unless applicable Law expressly requires that
          Merchant impose such tax (and, in such cases, such tax must be included in the transaction amount and not
          collected separately); (f) deposit or submit to Peoples Trust any Sales Draft or Credit Voucher for a
          Transaction that was previously charged back to Paysafe and subsequently returned to Merchant, irrespective of
          Cardholder approval (Merchant may pursue payment from the Cardholder outside the Card Association system); (g)
          request or use an account number for any purpose other than as payment for its goods or services; (h) disburse
          funds in the form of travelers cheques, if the sole purpose is to allow the Cardholder to make a cash purchase
          of goods or services from Merchant; (i) disburse funds in the form of cash, unless Paysafe has provided
          written approval and: (i) Merchant is a lodging or cruise line merchant disbursing cash to a Cardholder; (ii)
          Merchant is dispensing funds in the form of travelers cheques or foreign currency; or (iii) Merchant is
          participating in a Card Association cash back service; (j) accept a Card for manual cash disbursement; (k)
          accept a Card to collect or refinance existing debt; or (l) enter into a Transaction that represents
          collection of a dishonored cheque. Merchant will pay all Card Association fines, fees, penalties and all other
          assessments or indebtedness levied by Card Associations to Paysafe which are attributable, at Paysafe&rsquo;s
          discretion, to Merchant&rsquo;s Transaction processing or business.
        </p>
        <p>
          2.15&nbsp;<u>Merchant&rsquo;s Business</u>. Paysafe must hold, administer, and control all settlement funds
          for the Merchant and reserve funds derived from settlement. Merchant will notify Paysafe immediately if it
          intends to, and prior to taking of any steps to (a) transfer or sell a substantial part of its assets, or
          liquidate; (b) change the basic nature of its business, including selling any products or services not related
          to its current business; (c) change ownership or transfer control of its business; (d) enter into any joint
          venture, partnership or similar business arrangement whereby any person or entity not a party to the Merchant
          Agreement assumes any interest in Merchant&rsquo;s business; (e) alter in any way Merchant&rsquo;s approved
          monthly volume, average ticket, or maximum ticket; (f) changes its return policies or fulfillment house from
          those identified in the Merchant Application; (g) any substantial change in the volume of Transactions in
          respect of domestic versus foreign purchases; or (h) adds any outlet operations to its business in respect of
          the same products and/or services being offered by Merchant. Merchant will immediately notify Paysafe in
          writing if it becomes subject to any voluntary or involuntary bankruptcy or insolvency petition or proceeding.
          Merchant&rsquo;s failure to provide notice as required above may be deemed a material breach and will be
          sufficient grounds for termination of Merchant and for Paysafe&rsquo;s exercise of all its rights and remedies
          provided by the Merchant Agreement. If any change listed above occurs, Paysafe may immediately terminate the
          Merchant Agreement. Merchant further acknowledges and agrees that Paysafe may withhold funds (settlement or
          otherwise) or temporarily suspend processing under the Merchant Agreement if Paysafe, in its sole discretion,
          determines that such withholding or suspension is required to protect Paysafe from potential losses or if
          there exist material variances from the disclosures on the Merchant application in (i) the nature of
          Merchant&rsquo;s business, (ii) the type of or composition of Card processing conducted, or (iii) the actual
          average ticket size or actual monthly volume amount. If (A) such variances exist, (B) Merchant does not swipe
          or dip credit card or debit card through POS terminals, (C) Merchant does not receive authorization for
          Transactions, (D) Paysafe receives excessive Retrieval requests against Merchant&rsquo;s prior activity, or
          (E) excessive Chargebacks are debited against Merchant&rsquo;s prior activity, then Paysafe may delay or
          withhold settlement of funds for a period not less than 180 days or until Paysafe is reasonably certain fraud
          or other activity detrimental to Paysafe has not occurred. Paysafe&rsquo;s right to withhold settlement funds
          as set forth herein survives termination of the Merchant Agreement. Merchant must immediately contact Peoples
          Trust if material variances from the average ticket size or monthly volume occur. For purposes hereof,
          Paysafe&rsquo;s determination of materiality shall be binding upon Merchant.
        </p>
        <p>
          2.16&nbsp;<u>Merchant&rsquo;s Warranties</u>. Merchant represents, warrants and covenants that (a) all
          information contained in the Merchant Application or any other documents delivered to Paysafe in connection
          therewith is true and complete and properly reflects Merchant&rsquo;s business, financial condition and
          principal partners, owners and officers; (b) Merchant has power to execute, deliver and perform the Merchant
          Agreement, and the Merchant Agreement is duly authorized, and will not violate any provisions of law, or
          conflict with any other agreement to which Merchant is subject; (c) Merchant holds all licenses, if any,
          required to conduct its business and is qualified to do business in every jurisdiction where it is required to
          do so; (d) there is no action, suit or proceeding at law or in equity now pending or, to Merchant&rsquo;s
          knowledge, threatened by or against or affecting Merchant which would substantially impair its right or
          ability to carry on its business as now conducted or adversely affect its financial condition or operations or
          the entering into of the Merchant Agreement; during the term of the Merchant Agreement, (e) each Sales Draft
          presented to Paysafe for collection will be genuine and will not be the result of any fraudulent or prohibited
          Transaction or will not be presented on behalf of any business other than Merchant as authorized by the
          Merchant Agreement; (f) each Sales Draft will be the result of a bona fide Card Transaction for the purchase
          of goods or services from Merchant by the Cardholder in the total amount stated on the Sales Draft; (g)
          Merchant has performed or will perform all of its obligations to the applicable Cardholder in connection with
          each Card Transaction; (h) Merchant has complied (and will comply) with Paysafe&rsquo;s procedures for
          accepting Cards, and each Card Transaction itself will not involve any element of credit for any purpose other
          than as set forth in the Merchant Agreement, and will not be subject to any defense, dispute, offset or
          counterclaim which may be raised by any Cardholder under the Card Associations&rsquo; Rules, under any
          applicable consumer protection legislation or any other relevant provincial or federal statutes or
          regulations; and (i) any Credit Voucher which it issues and will issue, will represent a bona fide refund or
          adjustment on a Card sale by Merchant with respect to which a Sales Draft has been accepted by Paysafe.
        </p>
        <p>
          2.17&nbsp;<u>Sponsor Agreement</u>. Merchant acknowledges and agrees that under the Card Association Rules it
          is required to enter into a direct agreement with Bank upon passing certain processing volume thresholds By
          executing this Agreement, Merchant agrees that it is also executing and agreeing to the attached Sponsor
          Agreement, which shall fulfill its obligations to enter into a direct agreement with Bank under the Card
          Association Rules.
        </p>
        <h4 className='my-4'>3 &ndash; PRESENTMENT, PAYMENT, CHARGEBACK</h4>
        <p>
          3.01&nbsp;<u>Acceptance</u>. Paysafe will accept from Merchant all Sales Drafts presented under the terms of
          the Merchant Agreement and will submit them for processing through the Card Associations. Merchant must
          transmit Sales Drafts and Credit Vouchers to Paysafe or its processing vendor on the same or next business day
          immediately following the day that such Sales Drafts and Credit Vouchers have been originated and via
          electronic data transmission in accordance with Paysafe&rsquo;s formats and procedures. All presentment and
          assignment of Sales Drafts, collection therefor and reassignment or rejection of such Sales Drafts are subject
          to the terms of the Merchant Agreement and the Card Association Rules. All creditsto
          Merchant&rsquo;sAccountfor the value of processed Sales Drafts are provisional andPaysafe reserves the right
          to adjust amounts collected to reflect the value of Chargebacks (actual and anticipated), fees, penalties,
          late submission charges, reserve deposits, negative Sales Draft batch presentments and items for which Bank
          did not receive final payment.
        </p>
        <p>
          3.02&nbsp;<u>Endorsement</u>. By presenting Sales Drafts to Paysafe for collection and payment, Merchant
          agrees to sell and assign all its right, title and interest in each such Sales Draft and constitutes an
          endorsement by Merchant to Paysafe of such Sales Drafts. Paysafe may supply such endorsement on
          Merchant&rsquo;s behalf.
        </p>
        <p>
          3.03&nbsp;<u>Prohibited Payments</u>. Paysafe may receive payment of any Sales Draft presented by Merchant
          unless and until there is a Chargeback. Unless specifically authorized in writing by Paysafe, Merchant may not
          collect or attempt to collect any Sales Draft, including Chargebacks, and will hold in trust for Paysafe and
          promptly deliver in kind to Paysafe any payment Merchant receives, in whole or in part, of the amount of any
          Transaction, together with the Cardholder&rsquo;s name and account number and any correspondence accompanying
          payment.
        </p>
        <p>
          3.04&nbsp;<u>Chargebacks</u>. Merchant will accept for chargeback any sale for which the Cardholder disputes
          the validity of the sale according to prevailing Card Association regulations, or a Card issuer, or Paysafe
          determines that Merchant has in any way failed to comply with Card Association regulations or Paysafe&rsquo;s
          procedures in accepting a Card and presenting the resulting Sales Draft to Paysafe for processing. Merchant
          shall require Cardholder to sign an imprinted copy of the Sales Draft during the time when the point of sale
          terminal printer is inoperable. Notwithstanding any other provision herein, Paysafe may chargeback the amount
          of a Card sale disputed by the Cardholder if Merchant failed to obtain the Card Imprint or the
          Cardholder&rsquo;s signature. Merchant may not initiate a Transaction in an attempt to collect a Chargeback.
          Merchant will pay the current published fees for each Chargeback as listed on the Schedule of Fees.
        </p>
        <p>
          3.05&nbsp;<u>Reserve Account</u>. Notwithstanding anything to the contrary in the Merchant Agreement, Paysafe
          may, at its own discretion, establish and Merchant shall upon request by Paysafe, be required to fund a
          reserve account without interest accruing to the Merchant (the "Reserve Account") or may demand
          other security from the Merchant, including on the happening of any of the following: (a) Merchant engages in
          any processing that creates an overcharge to a Cardholder by duplicating charges; (b) any activity designed by
          Merchant to circumvent a "call center" message when attempting to process a transaction; (c)
          Merchant breaches the Merchant Agreement, violates any representation, covenant or warranty herein, violates
          any applicable Card Association Rules or applicable Law; (d) Merchant&rsquo;s application is in any way
          inaccurate or becomes inaccurate subsequent to Paysafe&rsquo;s approval of the application; (e) Merchant
          changes its type of business without Paysafe&rsquo;s prior written approval; (f) Merchant engages in fraud,
          processes an unauthorized charge, or engages in other action that violates Peoples Trust&rsquo;s applicable
          risk management standards or is likely to cause a loss; (g) the ratio of Chargebacks to Card Transactions
          completed during any 30 calendar day period exceeds the lesser of one half of one percent (0.5%) or a
          percentage that violates a then applicable Card Association Rules or the ratio of aggregate dollars charged
          back to the aggregate Card dollar sales volume during any 30 calendar day period exceeds the lesser of one
          half of one percent (0.5%) or a percentage that violates a then applicable Card Association Rules; (h) Paysafe
          receives an excessive numbers of requests from consumers or Card Issuer to retrieve documentation; (i)
          Merchant&rsquo;s financial stability is in question or Merchant ceases doing business; (j) Merchant terminates
          the Merchant Agreement; (k) at the request of Paysafe in its sole discretion. Once the Reserve Account is
          established, collected funds will be placed in the Reserve Account until the balance is sufficient, in the
          discretion of Paysafe, to address the applicable risk. Further, Paysafe may require Merchant to deposit
          additional amounts into the Reserve Account, from time to time during the term of the Merchant Agreement based
          upon Merchant&rsquo;s processing history and/or anticipated risk of loss to Paysafe. The Reserve Account funds
          may be commingled with other Paysafe funds. If a Reserve Account is established in accordance with this
          Section 3.05, then the Merchant hereby grants a security interest in respect of the Reserve Account in favor
          of Paysafe in accordance with Section 5.09. Upon termination or expiration of the Merchant Agreement, before
          releasing funds from the Reserve Account Merchant will pay any equipment cancellation fees and any outstanding
          challenges, losses or amounts, and Chargebacks for which Merchant has liability and has provided
          indemnification under the Merchant Agreement. Once established, unless Paysafe determines otherwise at its
          sole discretion, the Reserve Account will remain in place for 180 days and a reasonable period thereafter
          during which Cardholder disputes may remain valid under applicable Card Association Rules following
          termination or expiration of the Merchant Agreement. The provisions of the Merchant Agreement relating to
          account debits and credits apply to the Reserve Account and survive the Merchant Agreement&rsquo;s termination
          until Paysafe, in its discretion, terminates the Reserve Account. Any balance remaining after Chargeback
          rights have expired and all of Paysafe&rsquo;s other expenses, losses and damages have been paid will be
          disbursed to Merchant.
        </p>
        <h4 className='my-4'>4 &ndash; TERM, TERMINATION, EFFECT OF TERMINATION AND NO EXCLUSIVITY</h4>
        <p>
          4.01&nbsp;<u>Term</u>. Subject to Section 4.02, the Merchant Agreement will be effective once Paysafe accepts
          it and, unless otherwise terminated, will continue for (3) three years with automatic six-month renewals
          thereafter until Merchant provides written notice of non-renewal given not less than 30 days before the end of
          the then current term.
        </p>
        <p>
          4.02&nbsp;<u>Termination</u>.
        </p>
        <ol type='A'>
          <li>
            <p>
              <u>Without Cause</u>. Paysafe may terminate the Merchant Agreement, without cause, upon 30 days&rsquo;
              advance written notice to Merchant.
            </p>
          </li>
          <li>
            <p>
              <u>For Cause</u>. Paysafe may terminate the Merchant Agreement in its sole discretion, effective
              immediately, upon written or verbal notice, or by closing Merchant&rsquo;s point-of-sale terminal, if
              Paysafe reasonably determines either that any of the conditions enumerated as a reason for the
              establishment of a Reserve Account exist or that any of the following conditions exist; (i) Merchant has
              violated any provision of the Merchant Agreement; (ii) there is a material adverse change in
              Merchant&rsquo;s business, operations, financial condition, assets or prospects; (iii) any case or
              proceeding is commenced by or against Merchant under any federal or provincial or other law related to
              insolvency, bankruptcy, receivership or other debt relief; (iv) any information which Merchant provided to
              Paysafe, including Application information, was false, incomplete or misleading when received; (v) any
              information which Merchant provided to Paysafe, including Application information, has thereafter become
              false, incomplete or misleading; (vi) an overdraft in the Account exists for more than three (3) days;
              (vii) Paysafe believes that Merchant or any of Merchant&rsquo;s officers or employees has been involved in
              processing Transactions arising from fraudulent, criminal, suspicious or otherwise unauthorized
              Transactions; (viii) Merchant is or will be unable or unwilling to perform its obligations under the
              Merchant Agreement or applicable Law; (ix) Merchant has failed to timely pay Paysafe any amount due; (x)
              Merchant has failed to promptly perform or discharge any obligation under the Account or the Reserve
              Account; (xi) any of Merchant&rsquo;s representations or warranties made in connection with the Merchant
              Agreement was not true or accurate when given or has thereafter become untrue; (xii) Merchant has
              defaulted on any agreement it has with Paysafe; (xiii) Paysafe is served with legal process seeking to
              attach or garnish any of Merchant&rsquo;s funds or property in Paysafe&rsquo;s possession, and Merchant
              does not satisfy or appeal the legal process within fifteen (15) days of such service; (xiv) any Card
              Association Rules are violated, amended in any way so that the continued existence of the Merchant
              Agreement would cause Paysafe to be in breach of those rules or any Card Association no longer permits
              Merchant to participate in its Card program; (xv) any guaranty supporting Merchant&rsquo;s obligations is
              revoked, withdrawn, terminated or altered in any way; (xvi) any circumstances arise regarding Merchant or
              its business that create harm or loss of goodwill to any Card Association; (xvii) termination is necessary
              to prevent loss to Paysafe, or Card Issuers; (xviii) Merchant&rsquo;s type of business indicated on the
              Application or as conducted by Merchant could endanger Paysafe&rsquo;s safety or soundness; (xix)
              Merchant&rsquo;s owner, officer, guarantor, or corporate entity has a separate relationship with Paysafe
              and that relationship is terminated, (xx) Merchant appears on any Card Association&rsquo;s security
              reporting; (xxi) Paysafe&rsquo;s security for repayment becomes impaired; (xxii) an event of default under
              any other indebtedness of Merchant shall have occurred the effect of which is to permit the holder thereof
              to accelerate the due date of all or part of such indebtedness; (xxiii) one or more judgments shall have
              been entered against Merchant which judgment or judgments shall have remained unsatisfied for a period of
              45 days from entry thereof; (xxiv) Merchant receives a civil or criminal investigative demand, subpoena,
              or written request for information concerning its or an affiliate&rsquo;s activities from a federal or
              state regulatory or law enforcement agency or Bank or Paysafe receives such a civil or criminal
              investigative demand, subpoena, or written request for information concerning the activities of Merchant
              or its affiliates. Merchant shall notify Paysafe in writing immediately upon becoming aware of the
              occurrence of an Event of Default, or an event which with the passage of time or the giving of notice, or
              both, would constitute an Event of Default.
            </p>
          </li>
          <li>
            <p>
              <u>Additional Termination Rights</u>. Merchant may terminate this Merchant Agreement in accordance with
              Clauses 5.07 (C) and (D).
            </p>
          </li>
        </ol>
        <p>
          4.03&nbsp;<u>Effect of Bankruptcy</u>. Any account or security held by Paysafe will not be subject to any
          preference, claim or stay by reason of bankruptcy or similar law. The parties expressly agree that the
          acquisition of Card Transactions hereunder is a financial accommodation and if Merchant becomes a debtor in
          any bankruptcy or similar proceeding, the Merchant Agreement may not be assumed or enforced by any other
          person and Paysafe will be excused from performance hereunder.
        </p>
        <p>
          4.04&nbsp;<u>Effect of Termination</u>. When termination becomes effective, the parties&rsquo; rights and
          obligations existing under the Merchant Agreement survive. If the Merchant Agreement is terminated, regardless
          of cause, Paysafe may withhold and discontinue the disbursement for all Transactions in the process of being
          collected and deposited and Paysafe may, without notice to Merchant, refuse to accept or revoke acceptance any
          Sales Draft or Credit Voucher or the electronic transmission thereof, if applicable, received by Paysafe on or
          any time after the occurrence of any Event of Default. If Merchant is terminated for cause, Merchant
          acknowledges that Paysafe may be required to report Merchant&rsquo;s business name and the names and other
          identification of its principals to the MATCH file maintained by Visa and MasterCard and any other Card
          Association equivalent. Merchant expressly agrees and consents to such reporting if Merchant is terminated for
          any reason requiring or permitting listing on the MATCH file and other report. Merchant waives and will hold
          harmless Paysafe from any claims that Merchant may raise as a result of Paysafe&rsquo;s MATCH file or other
          reporting. If Merchant is terminated for cause, Paysafe may, without prior notice to Merchant, debit
          Merchant&rsquo;s Account and Reserve Account in an amount equal to the amount then owed to Paysafe, increase
          the fees payable by Merchant hereunder, require Merchant to deposit, as cash collateral, such amount as
          Paysafe may require to secure Merchant&rsquo;s obligations hereunder, and report to one or more credit
          reporting agencies any outstanding indebtedness of Merchant (or any guarantor of merchant&rsquo;s obligations
          under the Merchant Agreement). Merchant will immediately cease requesting Authorizations. If Merchant obtains
          any Authorization after termination, the fact that any Authorization was requested or obtained will not
          reinstate the Merchant Agreement. Further, immediately upon termination Merchant will return all Paysafe
          property, forms, or equipment. All obligations for Transactions prior to and after termination (including
          payment for Chargebacks and Paysafe&rsquo;s expenses relating to Chargebacks) survive termination. Paysafe is
          not liable to Merchant for damages (including prospective sales or profits) due to termination. Following
          termination, Merchant will upon request, provide Paysafe with all original and electronic copies of Sales
          Drafts and Credit Vouchers that have been retained by Merchant as of the date of termination. Upon
          termination, any amounts due to Paysafe will accelerate and be immediately due and payable, without any
          notice, declaration or other act whatsoever by Paysafe.
        </p>
        <p>
          4.05&nbsp;<u>No Exclusivity</u>. During the term of the Merchant Agreement Paysafe shall be the non-exclusive
          provider of any services related to Merchant&rsquo;s ability to accept payment through Cards.
        </p>
        <h4 className='my-4'>5 &ndash; MISCELLANEOUS</h4>
        <p>
          5.01&nbsp;<u>Monitoring</u>. Merchant acknowledges that Paysafe may monitor Merchant&rsquo;s daily presentment
          activity. Paysafe may upon reasonable grounds suspend disbursement of Merchant&rsquo;s funds for any
          reasonable period of time required to investigate suspicious or unusual presentment activity. Paysafe will
          make good faith efforts to notify Merchant promptly following suspension. Paysafe is not liable to Merchant
          for any loss, either direct or indirect, attributable to any suspension of funds disbursement. In order to
          maintain quality service, telephone communications with Merchant may be monitored and recorded without further
          notice or disclosure.
        </p>
        <p>
          5.02&nbsp;<u>Forms</u>. Merchant will use only the forms or modes of transmission of Sales Drafts and Credit
          Vouchers that are provided or approved in advance by Paysafe, and Merchant may not use such forms other than
          in connection with Card Transactions.
        </p>
        <p>
          5.03&nbsp;<u>Indemnification</u>. Merchant will defend, indemnify and hold Paysafe and its officers,
          directors, members, shareholders, partners, employees, agents, affiliates, subcontractors and representatives
          (collectively "the Indemnified Parties") harmless from and against any and all fines, penalties,
          claims, damages, expenses, liabilities or fees of any nature whatsoever, including legal fees and costs
          ("Damages"), asserted against or incurred by any of the Indemnified Parties arising out of,
          relating to or resulting from, either directly or indirectly; (a) a breach of the security of any system
          safeguarding Cardholder Information resulting in unauthorized access to Cardholder Information; (b) a breach
          of any representation, warranty or term of the Merchant Agreement, including, but not limited to, the Data
          Privacy Requirements herein, by Merchant, or by any of Merchant&rsquo;s Representatives; (c) the negligence,
          gross negligence or willful misconduct of Merchant or any of its Representatives in the performance of their
          obligations under the Merchant Agreement, including, but not limited to, the data security provisions; (d) any
          violation of applicable Law and Card Association Rules by Merchant or any of its Representatives; (e) matters
          for which Merchant provides indemnification pursuant to the terms hereof; (f) all third party claims arising
          from the foregoing or asserted against any of the Indemnified Parties as a result of the parties&rsquo; entry
          into the Merchant Agreement; and (g) all ISV Platform claims in connection with (directly or indirectly)
          Section 6 (the Split Payment Service). Notwithstanding the preceding, Merchant is not liable to an Indemnified
          Party if Damages are caused by, related to or arise out of that Indemnified Party&rsquo;s gross negligence or
          willful misconduct, or that Indemnified Party&rsquo;s material breach of the Merchant Agreement. Merchant will
          promptly reimburse the Indemnified Parties for any assessments, fines, fees or penalties imposed by the Card
          Association in connection with the Merchant Agreement, including the data security provisions, and authorizes
          Paysafe to deduct any such sums from amounts to be cleared and settled with Merchant.
        </p>
        <p>
          5.04&nbsp;<u>Records</u>. In addition to any records Merchant routinely furnishes to Paysafe under the
          Merchant Agreement, Merchant will preserve a copy of actual paper Sales Drafts and Credit Vouchers and any
          written Authorization of the Cardholder for at least two years after the date Merchant presents the
          Transaction to Paysafe.
        </p>
        <p>
          5.05&nbsp;<u>Requests for Copies</u>. Within two business days following Merchant&rsquo;s receipt of a request
          by Paysafe, Merchant will provide to Paysafe either the original or a legible copy (in a size comparable to
          the actual Sales Draft) of the paper Sales Draft and any other documentary evidence available to Merchant that
          Paysafe reasonably requests to meet Paysafe&rsquo;s obligations under applicable Laws or otherwise to respond
          to questions concerning Cardholder accounts.
        </p>
        <p>
          5.06&nbsp;<u>Compliance with Law; Taxes</u>. Merchant will comply with all applicable Laws to Merchant,
          Merchant&rsquo;s business and any Card Transaction. Merchant shall be liable for all taxes, except
          Paysafe&rsquo;s income taxes, required to be paid or collected as a result of the Merchant Agreement.
        </p>
        <p>
          5.07&nbsp;<u>Fees and Charges</u>.
        </p>
        <ol type='A'>
          <li>
            <p>
              Merchant will pay to Paysafe the fees and charges set forth in the Schedule of Fees. Paysafe may adjust
              all charges set forth on the Schedule of Fees to reflect changes in, or additions to, (i) Card Association
              fees (including but limited to interchange, assessments, and all other Card Association fees, costs or
              charges) or (ii) pricing of third party vendor goods or services used in connection with the provision of
              services hereunder. All such adjustments will be effective as of the date such changes or additions are
              imposed on Paysafe. Amounts due from Merchant hereunder, including but not limited to all fees, charges,
              amounts required, in Paysafe&rsquo;s sole discretion, to fund the Reserve Account and adjustments incurred
              by Merchant will be debited through ACH from Merchant&rsquo;s Account or withheld from daily payments to
              Merchant. The fees and charges herein are based upon Merchant&rsquo;s representations regarding annual
              anticipated Transaction volume, amount and type, all as set forth on the Schedule of Fees. To the extent
              that any such representations are not as anticipated, Paysafe may upon written notice to Merchant, adjust
              the fees and charges hereunder to fees and charges appropriate for the actual, as opposed to anticipated,
              Transaction volume, amount and type. Paysafe may add fees for additional services utilized by Merchant
              upon 90 days&rsquo; written notice to Merchant.
            </p>
          </li>
          <li>
            <p>
              Merchant authorizes Paysafe and Bank to initiate ACH credits and debits to the Account to collect fees,
              charges, and all other amounts owed by Merchant to Paysafe under this Agreement and to deposit Payouts
              (the "ACH Authorization"). Such authorization shall remain in full force and effect until
              thirty (30) days after Paysafe receives written notification from Merchant of termination of the ACH
              Authorization, by email to customersupport@paysafe.com. Paysafe reserves the right to terminate or suspend
              the services at any time that Merchant fails to provide an active Account with ACH Authorization.
            </p>
          </li>
          <li>
            <p>
              Paysafe reserves the right to terminate or suspend the services at any time that Merchant fails to provide
              an active Account with ACH Authorization.
            </p>
          </li>
          <li>
            <p>
              Paysafe may increase the fees in Schedule of Fees or introduce a new fee, upon providing ninety (90)
              days&rsquo; prior written notice to Merchant. Merchant may terminate the Merchant Agreement without any
              early termination fee or other penalty within ninety (90) days of receiving such notice from Paysafe.
            </p>
          </li>
          <li>
            <p>
              Where Merchant receives a notice from Paysafe regarding a reduction in interchange fees applicable to it,
              and Paysafe does not pass through the full savings from such reduction to Merchant, Merchant may terminate
              the Merchant Agreement without any early termination fee or other penalty within ninety (90) days of
              receiving the notice of such reduction.
            </p>
          </li>
          <li>
            <p>
              In respect to any sums owed by Merchant to Paysafe in connection with this Merchant Agreement, Peoples
              Trust may, at its option and without notice, obtain the required sums by making an ACH debit from the
              Merchant&rsquo;s Account. In addition, Paysafe may: (i) require that Merchant make a wire transfer to
              Paysafe within one (1) banking business day of notice; and or (ii) make set-offs against any obligations
              owed by Merchant to Paysafe; and/or (iii) take any other action authorized by Law.
            </p>
          </li>
        </ol>
        <p>
          5.08&nbsp;<u>Merchant Statement</u>. Paysafe shall make available a Merchant Statement or similar information
          on no less than a monthly basis. All information appearing on the Merchant Statement shall be deemed accurate
          and affirmed by Merchant unless Merchant objects by written notice specifying the particular item in dispute
          within 30 days of the date of the Merchant Statement. Delivery of the Merchant Statement may be in written or
          electronic form.
        </p>
        <p>
          5.09&nbsp;<u>Security Interest</u>. To secure payment of Merchant&rsquo;s obligations under the Merchant
          Agreement, Merchant grants to Peoples Trust a security interest in all now existing or hereafter acquired: (a)
          Transactions, Sales Drafts, Credit Vouchers and other items submitted to Paysafe for processing by or for
          Merchant; (b) accounts receivable and payment rights relating to or arising from the Merchant Agreement,
          including all amounts due to Merchant (including any rights to receive credits or payments hereunder); (c)
          Accounts maintained with Paysafe including the Reserve Account, in the name of or for the benefit of, Merchant
          under the Merchant Agreement; (d) deposits, regardless of source, to Merchant&rsquo;s Account with Paysafe or
          Reserve Account; (e) all deposits and all other property and funds presented by Merchant to Paysafe or
          withheld by Paysafe, including funds and property withheld as the result of security monitoring; and (f)
          proceeds of the foregoing. If Paysafe reasonably determines that Merchant has breached any obligation under
          the Merchant Agreement, or that proceeds of Merchant&rsquo;s future Card sales are unlikely to cover
          anticipated Chargebacks, credits, fees and adjustments, as reasonably determined by Paysafe (whether because
          the Merchant Agreement has been terminated or for any other reason), Paysafe may setoff or otherwise exercise
          its security interest without notice or demand by immediately withdrawing from or freezing any account or
          otherwise exercising its rights under the Merchant Agreement or those rights available under and subject to,
          applicable Laws, or in equity. In addition to the collateral pledged above, Paysafe may require Merchant to
          furnish such other and different security as Paysafe deems appropriate in its sole discretion to secure
          Merchant&rsquo;s obligations under the Merchant Agreement. Paysafe may fully or partially prohibit withdrawal
          by Merchant of funds from Merchant&rsquo;s Accounts maintained with Peoples Trust, pending Paysafe&rsquo;s
          determination from time to time to exercise its rights as a secured party against the Accounts in partial or
          full payment of Merchant&rsquo;s obligations to Paysafe. Merchant agrees that Paysafe may file such financing
          statements and any other documents as may be required for Paysafe to perfect its security interest, and
          Merchant will execute any other documents as may be requested by Paysafe and take such actions as Paysafe may
          require in connection with the security interest, at Merchant&rsquo;s cost. Merchant represents and warrants
          that no other party has a security interest or lien in any of the collateral pledged above, and Merchant will
          obtain Paysafe&rsquo;s written consent before it grants a lien or security interest in that pledged collateral
          to any other person. To the extent applicable, in the case of any voluntary or involuntary bankruptcy or
          insolvency petition or proceeding, Merchant acknowledges and agrees that notwithstanding anything contained in
          this Merchant Agreement, the Reserve Account is separate and apart from the Merchant&rsquo;s insolvency estate
          and Paysafe has a first priority thereon.
        </p>
        <p>
          5.10&nbsp;<u>Modifications to Merchant Agreement</u>. The Merchant Agreement is subject to amendment required
          to conform with Card Association regulations, as amended from time to time, and Merchant expressly
          acknowledges that any election by Paysafe regarding means to comply with either Card Association Rules or
          applicable Law or regulation will not diminish or otherwise impact any of Paysafe&rsquo;s rights hereunder.
          From time to time Paysafe may amend any provision or provisions of the Merchant Agreement, including, without
          limitation, those relating to the discount rate or to other fees and charges payable by Merchant by emailing
          written notice to Merchant of the amendment at least 30 days (90 days in the case of discount rate and/or
          other fees and charges) prior to the effective date of the amendment, and the amendment will become effective
          unless Paysafe receives Merchant&rsquo;s written notice of termination of the Merchant Agreement before such
          effective date. Amendments required due to changes in either Card Association&rsquo;s rules and regulations or
          any law or judicial decision may become effective on such shorter period of time as Paysafe may specify if
          necessary, to comply with the applicable rule, regulation, Law or decision. If there is any conflict between a
          part of the Merchant Agreement and any present or future Association Regulation or applicable Law or
          regulation, only the part of the Merchant Agreement that is affected shall be modified and that modification
          shall be limited to the minimum necessary to bring the Merchant Agreement within the requirements of the
          Association Regulation, law or regulation.
        </p>
        <p>
          5.11&nbsp;<u>Warranty Disclaimer</u>. PEOPLES TRUST MAKES NO WARRANTIES REGARDING THE USE, OPERATION OR
          PERFORMANCE OR NON-PERFORMANCE OF SOFTWARE, SYSTEMS, OR THIRD PARTY PROCESSORS UTILIZED IN CONNECTION WITH THE
          MERCHANT AGREEMENT, WHETHER EXPRESS OR IMPLIED, AND PEOPLES TRUST EXPRESSLY DISCLAIMS ALL IMPLIED WARRANTIES,
          INCLUDING ANY WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        </p>
        <p>
          5.12&nbsp;<u>Limitation of Liability</u>. Notwithstanding any other provision herein, (i) Paysafe&rsquo;s
          liability with respect to any Card Transaction may not exceed the amount of the Sales Draft in connection with
          that Transaction less any applicable fees and charges, (ii) Merchant may not make a claim against Paysafe or
          hold Paysafe liable for the acts or omissions of others, including but not limited to Merchants,
          Representatives, Card Associations, and financial institutions; and (iii) Paysafe&rsquo;s aggregate liability
          shall not, under any circumstance, exceed the fees paid to Paysafe under the Schedule of Fees (net of
          interchange, assessments and all other Card Association and third party fees imposed on Paysafe) during the
          twelve month period immediately preceding the event upon which such liability is based. Paysafe is not liable
          for any special, incidental, indirect, punitive or consequential damages whatsoever (whether any such claim
          alleges breach of contract, tort or any other theory of liability. Merchant waives all claims against Paysafe
          for any loss, claim, demand, penalty, action, delay, cost or expense (including reasonable legal fees) of any
          kind unless Merchant provides written notice to Paysafe of the occurrence that gave rise to the alleged
          liability within 30 days after Merchant knew or should have known of the occurrence. Merchant will indemnify
          and hold Paysafe harmless from any claim relating to or arising out of (i) any Sales Draft or Credit Voucher
          presented to Paysafe as may be made by anyone by way of defense, dispute, offset, counterclaim or affirmative
          action (including but not limited to disputes concerning the quality, fitness or delivery of merchandise or
          the performance or quality of services) or (ii) damages or losses that Paysafe may incur as a result of (a)
          Merchant&rsquo;s breach of the Merchant Agreement or (b) presentment by Merchant of acceptance or rejection by
          Paysafe of any Sales Draft or Credit Voucher, provided however that, with respect to clause (b), such
          indemnification shall not apply in the event of Paysafe&rsquo;s gross negligence. Further, Merchant will
          reimburse Paysafe for all expenses and costs, including legal fees, with regard thereto.
        </p>
        <p>
          5.13&nbsp;<u>Waiver</u>. Paysafe&rsquo;s failure to enforce one or more of the provisions of the Merchant
          Agreement will not constitute a waiver of the right to enforce the same or other provision in the future.
        </p>
        <p>
          5.14&nbsp;<u>Written Notices</u>. All written notices and other written communications required or permitted
          under the Merchant Agreement must be either personally delivered, sent by prepaid, registered mail or sent by
          email, charges (if any) prepaid, addressed as follows:
        </p>
        <ol type='A'>
          <li>
            <p>
              <u>If to Paysafe Merchant Services Corp.</u>: 3500
              <br />
              de Maisonneuve Blvd. W, Suite 700 Montr&eacute;al,
              <br />
              Qu&eacute;bec, H3Z 3C1
              <br />
              Attn: Legal Department
              <br />
              email: legal.departmentNA@paysafe.com
            </p>
          </li>
          <li>
            <p>
              <u>If to Merchant</u>: At the email address or address provided as the billing address and to the contact
              listed on the Merchant Application.
            </p>
          </li>
        </ol>
        <p>
          Any written notice delivered to the party to whom it is addressed will be deemed to have been given and
          received on the day it is so delivered at that party&rsquo;s address, provided that (i) it is so delivered
          before 5:00 p.m.; and (ii) if that day is not a business day then the written notice will be deemed to have
          been given and received on the next business day. Any written notice transmitted by email will be deemed to
          have been given and received on the day on which it was transmitted (but if the written notice is transmitted
          on a day which is not a business day (or after 5:00 p.m.), the written notice will be deemed to have been
          received on the next business day). Any written notice given by registered mail will be deemed to have been
          received on the fifth business day after which it is so mailed. Merchant acknowledges and agrees that written
          notice and other written communications required or permitted to be given by Paysafe under the Merchant
          Agreement shall be properly given if contained in the on-line statement provided from time to time to Merchant
          by Paysafe. For purposes of this Section 5.14, references to a time of day shall mean that time of day in the
          jurisdiction of the receiving party (e.g., "5:00 p.m." shall mean 5:00 p.m. in the jurisdiction of
          the receiving party) and references to "business day" shall be mean a day other than a Saturday,
          Sunday or statutory holiday in the jurisdiction of the receiving party.
        </p>
        <p>
          5.15&nbsp;<u>Choice of Law; Jurisdiction</u>. The Merchant Agreement is governed by and is to be construed and
          interpreted in accordance with, the laws of the State of Alabama, without regard to principles of conflicts of
          law. The parties agree and consent to venue in the state or federal courts located in Birmingham, Alabama , to
          the exclusion of any other venue site, or any action or lawsuit brought, relating to, or arising under this
          Agreement and/or any transaction governed by this Agreement; and irrevocably agrees not to seek, and waives
          any right to, judicial review by any court which may be called upon to enforce the judgment of the courts
          referred to in this Section 5.15, of the substantive merits of any such suit, action or proceeding.
        </p>
        <p>
          5.16&nbsp;<u>Entire Agreement; Interpretation; Assignability</u>. The Merchant Agreement expresses the entire
          understanding of the parties with respect to the subject matter hereof and except as provided herein, may be
          modified only in writing executed by Paysafe and Merchant. The Merchant Agreement may not be assigned by
          Merchant, directly or by operation of law, without Paysafe&rsquo;s prior written consent. The Merchant
          Agreement will be binding upon and inure to the benefit of the parties&rsquo; respective heirs, personal
          representatives, successors and assigns. The Merchant Agreement shall not be construed more strongly against
          any party, regardless of which party was more responsible for its preparation.
        </p>
        <p>
          5.17&nbsp;<u>Deposit Account</u>. Merchant will at all times maintain an Account at a bank that is a member of
          the ACH system and will provide Paysafe with proper authorization to debit the Account. All credits for
          collected funds and debits for fees, payments and Chargebacks and other amounts for which Merchant is liable
          under the terms of the Merchant Agreement will be made to the Account. Merchant may not close or change the
          Account without written notice to Paysafe. Merchant will be solely liable for all fees and costs associated
          with the Account and for all overdrafts and Merchant shall immediately deposit into the Deposit Account an
          amount sufficient to cover any overdraft and any related service charges or fees. All credits and debits to
          the Deposit Account made hereunder are subject to review, verification and acceptance by Paysafe. In the event
          of error, Merchant authorizes Paysafe to make correcting credits or debits, as the case may be, without notice
          to Merchant. Merchant hereby grants to Paysafe a security interest in the Account to the extent of any and all
          fees, payments, Chargebacks and other amounts due which may arise under the Merchant Agreement, and Merchant
          will execute any document and obtain any consents or waivers from the bank at which the Account is maintained
          as requested by Paysafe to protect its security interests therein.
        </p>
        <p>
          5.18&nbsp;<u>Credit and Financial Inquiries; Additional Locations; Inspection</u>. Paysafe may make, at any
          time, any credit inquiries which it may consider necessary to accept or review acceptance of the Merchant
          Agreement or investigate Merchant&rsquo;s ability to perform its obligations hereunder, or its Sales Draft
          presentment and Card acceptance activities subsequent to acceptance of the Merchant Agreement. Such inquiries
          may include, but are not limited to, a credit and/or criminal background check of the business including its
          proprietor, partners, principal owners, shareholders or officers. Upon Paysafe&rsquo;s request, Merchant will
          provide the written consent of any person for which an inquiry has been or is to be made if such person has
          not executed the Merchant Agreement and will provide any financial statements, income tax and business tax
          returns and other financial information as Paysafe may consider necessary to perform initial or periodic
          reviews of Merchant&rsquo;s financial stability and business practices. Merchant may accept Cards only at
          locations approved by Paysafe. Additional locations may be added subject to Peoples Trust&rsquo;s approval.
          Paysafe may delete any location by providing notice as provided herein. Merchant will permit Paysafe, at any
          time and from time to time, to inspect locations to confirm that Merchant has or is adhering to the terms of
          the Merchant Agreement and is maintaining the proper facilities, equipment, inventory, records and license or
          permit (where necessary) to conduct its business. Paysafe, its internal and external auditors, and its
          regulators may audit compliance with (i) the Merchant Agreement, (ii) all applicable Law (iii) Data Privacy
          Requirements, (iv) Card Association Rules and regulations and (v) guidance applicable to the services, Card
          acceptance, Transaction processing, and data security provisions hereof. Merchant will make available its
          records maintained and produced under the Merchant Agreement, and Merchant&rsquo;s facilities will be made
          accessible, upon notice during normal business hours for examination and audit. Nothing in this section may be
          construed to require Merchant to give access to its facilities, personnel or records in a manner that
          unreasonably interferes with its business operations. Each party will bear its expenses of any audit.
        </p>
        <p>
          5.19&nbsp;<u>Force Majeure</u>. The parties will be released from liability hereunder if they fail to perform
          any obligation where the failure occurs by reason of any act of God, fire, flood, storm, earthquake, tidal
          wave, communications failure, sabotage, war, military operation, terrorism, national emergency, mechanical or
          electronic breakdown, civil commotion, governmental regulation or the order, requisition, request or
          recommendation of any governmental authority, or either party&rsquo;s compliance therewith, or any other
          similar cause beyond the non-performing party&rsquo;s reasonable control.
        </p>
        <h4 className='my-4'>6 &ndash; ADDITIONNAL PROVISIONS APPLICABLE TO SPLIT PAYMENT SERVICES</h4>
        <p>
          6.01&nbsp;<u>Split Payment Service Authorization</u>. For the purposes of this Agreement, Merchant hereby
          acknowledges and agrees to the Split Payment Services and authorizes Paysafe to execute Transfers based on
          payment instructions received from those ISV Platform(s) identified to Paysafe by Merchant as if such
          instructions were received by Paysafe directly from the Merchant.
        </p>
        <p>
          6.02&nbsp;<u>Approval</u>. Merchant represents that it has signed an agreement with the ISV Platform (i)
          authorizing the ISV Platform to make Transfers; and (ii) it is aware of and agrees to the amount, percentage,
          or other calculation for Transfers. Paysafe reserves the right to terminate the Split Payment Service with
          regard to any previously approved ISV Platform or to decline any particular Transfer, each in its sole
          discretion.
        </p>
        <p>
          6.03&nbsp;<u>Merchant represents and warrants that</u>: (i) in connection with the Split Payment Service and
          any Transfer, it will comply with and conduct its Card activities in accordance with all applicable Laws and
          Card Association Rules; (ii) it shall not collect the amount of any Transfer from a Cardholder on behalf of
          any ISV Platform; and (iii) it shall not request, or permit to be requested, any transfer of funds out of
          Transaction settlement proceeds to any third-party other than a Transfer to an ISV Platform.
        </p>
        <p>
          6.04&nbsp;<u>Settlement Services</u>. Paysafe will instruct Bank to direct payments to Merchant and if
          applicable, ISV Platform(s) in accordance with the Transfer Data and Transfer Data, as provided by ISV
          Platform on behalf of Merchant.
        </p>
        <p>
          6.05&nbsp;<u>Reliance on Data</u>. Paysafe will provide the Split Payment Service and the Transfer entirely in
          reliance on the Transfer Data provided through ISV Platforms on behalf of Merchant. Merchant is solely
          responsible for the accuracy and sufficiency of the Transfer Data provided to Paysafe through ISV Platform on
          behalf of Merchant.
        </p>
        <p>
          6.06&nbsp;<u>Erroneous Payments</u>. In the event that Merchant determines that it or its authorized ISV
          Platform has transmitted erroneous information to Paysafe regarding the amount of any Payout or Transfer,
          Merchant shall notify Paysafe promptly of the error and the correct amount of the Payout or Transfer,
          including such Transaction identifying information as required by Peoples Trust. If Paysafe receives an
          inquiry from an ISV Platform asserting that an error was made in a Payout, Paysafe will refer the inquiry to
          Merchant for disposition.
        </p>
        <p>
          6.07&nbsp;<u>Error Resolution</u>. If the error in a Payout resulted from an error in the Transfer Data,
          Merchant will be responsible for funding the amount necessary to correct any errors in funding and for any
          fees related to the supplemental Payout. If the error results from actions of Paysafe, Paysafe will be
          responsible to correct the amount of the underpayment or overpayment to Merchant and will process the
          corrective Payout without charge to Merchant, which shall be Merchant&rsquo;s sole remedy for Paysafe&rsquo;s
          error in connection with a Payout.
        </p>
        <p>
          6.08&nbsp;<u>Indemnification</u>. Without limiting any other indemnities provided by Merchant in this
          Agreement, Merchant shall indemnify Paysafe pursuant to Section 5.03, with respect to all claims by ISV
          Platforms arising from Merchant&rsquo;s use of the Split Payment Services.
        </p>
        <p>
          6.09&nbsp;<u>Reporting</u>. In the event Merchant wishes Paysafe to provide reports on Transfers, then Paysafe
          reserves the right to charge additional fees, to be agreed upon with Merchant, based on the type of reports
          requested.
        </p>
        <h4 className='my-4'>7 &ndash; ADDITIONAL PROVISIONS APPLICABLE TO AMERICAN EXPRESS</h4>
        <p>
          <u>
            <strong>
              Terms below are additional terms applicable specifically to the American Express OptBlue&reg; Program
              ("American Express Card Acceptance") (capitalized terms below not defined elsewhere in the
              Agreement shall have the meanings assigned in the American Express Network Rules). With respect to
              participation in an American Express acceptance program, in the event of a conflict between the terms
              below and other terms of this Agreement, the terms below shall control with respect to American Express
              Transactions only. Merchant shall be bound by American Express Network Rules, including the Merchant
              Operating Guide:&nbsp;
              <a href='https://www.americanexpress.com/merchantopguide'>www.americanexpress.com/merchantopguide</a>.
            </strong>
          </u>
        </p>
        <p>
          7.0&nbsp;<u>Third Party of Paysafe</u>. Merchant acknowledges that Paysafe has entered into an agreement with
          TSYS Acquiring Solutions, L.L.C., which will be providing certain services to Merchant hereunder.
        </p>
        <p>
          7.1&nbsp;<u>Transaction Data</u>. Merchant authorizes Paysafe and/or its affiliates to submit American Express
          Transactions to, and receive settlement on such Transactions from, American Express on behalf of Merchant.
        </p>
        <p>
          7.2&nbsp;<u>Marketing Message Opt-Out</u>. Merchant may opt-out of receiving future commercial marketing
          communications from American Express by contacting Paysafe. Note that Merchant may continue to receive
          marketing communications while American Express updates its records to reflect this choice. Opting out of
          commercial marketing communications will not preclude Merchant from receiving important transactional or
          relationship messages from American Express.
        </p>
        <p>
          7.3&nbsp;<u>Conversion to American Express Direct Merchant</u>. Merchant acknowledges that it may be converted
          from American Express Card OptBlue program to a direct relationship with American Express if and when its
          Transaction volumes exceed the eligibility thresholds for the OptBlue program. If this occurs, upon such
          conversion, (i) Merchant will be bound by American Express&rsquo; then-current Card Acceptance Agreement; and
          (ii) American Express will set pricing and other fees payable by Merchant.
        </p>
        <p>
          7.4&nbsp;<u>American Express as Third-Party Beneficiary</u>. Notwithstanding anything in the Agreement to the
          contrary, American Express shall have third-party beneficiary rights, but not obligations, to the terms of
          this Agreement applicable to American Express Card Acceptance to enforce such terms against Merchant.
        </p>
        <p>
          7.5&nbsp;<u>American Express Right to Modify or Terminate Agreement</u>. American Express has the right to
          modify the Agreement with respect to American Express Card Transactions or to terminate Merchant&rsquo;s
          acceptance of American Express Card Transactions and to require Paysafe to investigate Merchant&rsquo;s
          activities with respect to American Express Card Transactions.
        </p>
        <p>
          7.6&nbsp;<u>American Express Opt-Out</u>. Merchant may opt out of accepting American Express at any time
          without directly or indirectly affecting its rights to accept Cards bearing Marks of other Card Networks.
        </p>
        <p>
          7.7&nbsp;<u>Refund Policies</u>. Merchant&rsquo;s refund policy for American Express purchases must be at
          least as favorable as its refund policy for purchase on any other Card Network, and the refund policy must be
          disclosed to Cardholders at the time of purchase and in compliance with Law. Merchant may not bill or attempt
          to collect from any Cardholder for any American Express Transaction unless a Chargeback has been exercised,
          Merchant has fully paid for such Chargeback, and it otherwise has the right to do so.
        </p>
        <p>
          7.8&nbsp;<u>Establishment Closing</u>. If Merchant closes any of its Establishments, Merchant must follow
          these guidelines: (i) notify Paysafe immediately; (ii) policies must be conveyed to the Cardholder prior to
          completion of the Transaction and printed on the copy of a receipt or Transaction record the Cardholder signs;
          (iii) if not providing refunds or exchanges, post notices indicating that all sales are final (e.g., at the
          front doors, by the cash registers, on the Transaction record and on websites and catalogs); (iv) return and
          cancellation policies must be clearly disclosed at the time of sale; and (v) for Advance Payment Charges or
          Delayed Delivery Charges, Merchant must either deliver the goods or services for which Merchant has already
          charged the Cardholder or issue Credit for any portion of the Transaction for which Merchant has not delivered
          the goods or services.
        </p>
        <p>
          7.9 Paysafe shall have the right to terminate Merchant&rsquo;s participation in American Express Card
          Acceptance immediately upon written notice to Merchant (i) if merchant breaches any of the provisions of this
          American Express OptBlue Program Agreement or any other terms of the Agreement applicable to American Express
          Card Acceptance, or (ii) for cause or fraudulent or other activity, or upon American Express&rsquo; request.
          In the event Merchant&rsquo;s participation in American Express Card Acceptance is terminated for any reason,
          Merchant must immediately remove all American Express branding and marks from Merchant&rsquo;s website and
          wherever else they are displayed.
        </p>
        <p>
          7.10 Merchant must accept American Express as payment for goods and services (other than those goods and
          services prohibited by this American Express OptBlue Program Agreement, this Agreement, or the Rules) sold, or
          (if applicable) for charitable contributions made at all of its business locations and websites, except as
          expressly permitted by state statute. Merchant is jointly and severally liable for the obligations of
          Merchant&rsquo;s business locations and websites under this Agreement.
        </p>
        <p>
          7.11 In the event that Merchant or Paysafe is not able to resolve a Claim against American Express, or a Claim
          against Paysafe or any other entity that American Express has a right to join in resolving a Claim, the
          Merchant Operating Guide explains how Claims can be resolved through arbitration. Merchant or American Express
          may elect to resolve any Claim by individual, binding arbitration. Claims are decided by a neutral arbitrator.
        </p>
        <p>
          7.12 Merchant must ensure that it and any third parties it enlists to facilitate sales processing complies
          with the American Express Technical Specifications (available at www.americanexpress.com/merchantopguide)
          (valid and accurate data must be provided for all data elements in accordance with the American Express
          Technical Specifications). Failure to comply with the American Express Technical Specifications may impact
          Merchant&rsquo;s ability to successfully process sales. Merchants may be assessed non-compliance fees if
          Merchant fails to comply with the Technical Specifications. To ensure compliance with the Technical
          Specifications, Merchants should work with Paysafe.
        </p>
        <h4 className='my-4'>PART II &ndash; ACH ONLY</h4>
        <p>
          BETWEEN:&nbsp;<strong>PAYSAFE MERCHANT SERVICES CORP.</strong>, a legal person having a place of business at
          1209 Orange Street, Wilmington, DE, 19801 (hereinafter referred to as "Paysafe")
        </p>
        <p>
          AND:&nbsp;<strong>CLIENT</strong>, whose name and address are as stated on the attached Application
        </p>
        <p>(hereinafter referred to as the "Client")</p>
        <h4 className='my-4'>1. PREAMBLE</h4>
        <p>
          1.1 WHEREAS the Client wishes to engage the services of Paysafe to act as limited agent of the Client in order
          to process, verify, settle, confirm, report and perform related Processing Services relating to the business
          operations of the Client;
        </p>
        <p>
          1.2 WHEREAS the Client has completed an Application and Paysafe in reliance on the Information furnished
          therein has approved the opening of an account by the Client with Paysafe;
        </p>
        <p>
          1.3 WHEREAS Paysafe wishes to provide Processing Services to the Client subject to the terms and conditions
          set forth in this Agreement.
        </p>
        <p>NOW, THEREFORE, THE PARTIES AGREE AS FOLLOWS:</p>
        <h4 className='my-4'>2. DEFINITIONS</h4>
        <p>2.1 "ACH" means the Automated Clearing House.</p>
        <p>
          2.2 "Application" means an application to open an account for ACH Processing Services with Paysafe
          in the form attached hereto.
        </p>
        <p>
          2.3 "Clearing Houses" means a member or members of the National Automated Clearing House
          Association designated by Paysafe to process and settle Electronic Check Transactions.
        </p>
        <p>
          2.4 "Clearing Period" means the 6th business day following the submission of a transaction to the
          Clearing Houses.
        </p>
        <p>
          2.5 "Client Designated Account" means the Client&rsquo;s bank account at a financial institution
          as indicated by Client in the Application, or as otherwise amended by Client with the prior written consent of
          Paysafe.
        </p>
        <p>
          2.6 "Confidential Information" shall mean Paysafe's programs, procedures, proprietary or other
          software (including transaction software) and other intellectual property.
        </p>
        <p>
          2.7 "Data" means: (i) the data about transactions and related instructions and information
          provided by Client to Paysafe in the form as indicated by Paysafe in documentation provided to Client, and as
          amended from time to time; and (ii) any other information requested by Paysafe.
        </p>
        <p>
          2.8 "Effective date" means the date of this Agreement as it appears above the signatures of the
          parties below.
        </p>
        <p>
          2.9 "Electronic Check(s)" means an electronic debit to a United States bank account, initiated
          directly or indirectly via the ACH.
        </p>
        <p>
          2.10 "Electronic Check Transaction(s)" means transactions involving Electronic Checks that have
          been approved based on positive credit information and positive credit availability (following the Clearing
          Period) and where available, positive address verification, as more fully described in Section 3.
        </p>
        <p>2.11 "Fee" means the amounts specified the Application.</p>
        <p>
          2.12 "Fine" means (i) any fine imposed on Paysafe by any party as a result of any activity related
          directly or indirectly to Client; (ii) any amount which may reasonably be imposed as a fine or other penalty
          by a third party as a result of any activity related directly or indirectly to Client; (iii) any fine which
          may be imposed by Paysafe for excessive Returned Items or breach of the terms of this Agreement.
        </p>
        <p>
          2.13 "Information" means all the information supplied by the Client to Paysafe in the Application.
        </p>
        <p>2.14 "NACHA" means the National Automated Clearinghouse Association.</p>
        <p>
          2.15 "NACHA Operating Rules" means the standards, rules, regulations and procedures established by
          NACHA that enable depository financial institutions to exchange payments on a national basis.
        </p>
        <p>
          2.16 "NSF" means an approved and settled Electronic Check Transaction that returns with an error
          code of R01.
        </p>
        <p>2.17 "Obligations" has the meaning ascribed thereto in Section 4.6.</p>
        <p>
          2.18 "ODFI" means the Originating Depository Financial Institution, or Paysafe&rsquo;s bank.
        </p>
        <p>2.19 "Processing Services" has the meaning ascribed thereto in Section 3.1.</p>
        <p>
          2.20 "RDFI" or "Consumer&rsquo;s Bank" means the Receiving Depository Financial
          Institution, or the consumer&rsquo;s bank.
        </p>
        <p>2.21 "Remittances" has the meaning ascribed thereto in Section 3.1.2.</p>
        <p>2.22 "Reserve Amount" has the meaning ascribed thereto in Section 4.4.</p>
        <p>
          2.23 "Reserve Amount Expense" means all liabilities in respect of actual or potential
          post-termination Obligations, and other charges, indemnifications and expenses due or reasonably anticipated
          to be due.
        </p>
        <p>
          2.24 "Returned Items" means all approved and settled Electronic Check Transactions which are at
          any time refused or debited by the RDFI against the ODFI, including but not limited to the following return
          codes; R01 (insufficient funds), R02 (account closed), R03 (unable to locate account), R04 (invalid account
          number), R08 (stop payment), R09 (uncollected funds), R16 (account frozen) R20 (non-transaction account), and
          R29 (Corporate Customer Not Authorized).
        </p>
        <p>2.25 "Security Deposit" has the meaning ascribed thereto in Section 4.5.1.</p>
        <p>
          2.26 "Security Deposit Account" has the meaning ascribed thereto in Section 4.5.
        </p>
        <h4 className='my-4'>3. PROCESSING SERVICES</h4>
        <p>
          3.1 Subject to the terms and conditions set forth in this Agreement, Paysafe hereby agrees to provide the
          following processing services to the Client (the "Processing Services"), as limited agent of the Client, as
          more fully described in Section 3A:
        </p>
        <p>
          3.1.1 Submission of debit (withdrawal) and credits (refund) requests by consumers, based on the bank account
          number and routing number provided by the consumer except where authorization has been revoked.
          "Submission" of those requests, for purposes of this provision, shall mean the receipt and, after
          positive verification of the negative database, transmission of such information to the Clearing Houses for
          confirmations and approvals from the Clearing Houses;
        </p>
        <p>
          3.1.2 Settlement of funds into Client&rsquo;s account ("Remittances") as described in the
          Application;
        </p>
        <p>
          3.1.3 Provision of a Web-based real-time reporting tool to view Electronic Check Transactions, Returned Items,
          and all associated fees and reserves as per Section 4;
        </p>
        <p>
          3.1.4 Provision of documentation and application programming interface(s) for use by Client to submit
          transactions to Paysafe;
        </p>
        <p>3.1.5 Confirmation of receipt of the Data provided by Client;</p>
        <p>3.1.6 Verification of the submitted bank routing number;</p>
        <p>
          3.1.7 Prompt notification of Client of any rejected entry from ACH. Paysafe shall use reasonable efforts to
          provide such notification within one (1) business day of receipt of the rejected entry;
        </p>
        <p>
          3.1.8 Prompt notification of Client of any Returned Items. Paysafe shall use reasonable efforts to provide
          such notification within six (6) business days of receipt of the Returned Item;
        </p>
        <p>3.1.9 Issuance of a username and password to access Paysafe&rsquo;s Web-based interface.</p>
        <p>
          3.2 Notwithstanding the above, Paysafe maintains the right to decline to process any Data or transactions.
        </p>
        <p>
          3.3 Nothing herein grants the Client any rights whatsoever in any of Paysafe&rsquo;s transaction or other
          software other than a limited, non-exclusive, non-transferable license for use subject to the terms and
          conditions of this Agreement and as Paysafe otherwise may direct, and any use thereof by the Client beyond the
          requirements of the Processing Services shall be subject to a separate written agreement.
        </p>
        <h4 className='my-4'>3A. LIMITED AGENT</h4>
        <p>
          3A.1 The Client hereby appoints Paysafe as the Client&rsquo;s limited agent solely for purposes of collecting
          payments received through the Processing Services from consumers, and remitting refunds related to purchases
          of the Client&rsquo;s services to consumers, on behalf of the Client. Notwithstanding anything herein to the
          contrary, the Client acknowledges and agrees that payment made by a consumer to Paysafe acting on behalf of
          the Client pursuant to the authority granted herein shall be considered the same as payment made directly to
          the Client, and the Client shall have no recourse against any consumer for any amounts received from such
          consumer through the Processing Services by Paysafe in connection with such consumer&rsquo;s purchase of the
          good or service, as applicable, from the Client. The foregoing sentence shall survive the termination or
          expiration of this Agreement. Notwithstanding anything contained herein to the contrary, Paysafe may describe
          or otherwise reflect the terms of this Section 3A.1 in any terms of use, receipts, disclosures or notices that
          Paysafe may deem necessary or prudent in its sole discretion. In accepting appointment as the limited
          authorized agent of the Client, Paysafe assumes no liability for any acts or omissions of the Client.
        </p>
        <p>
          3A.2 Client shall make reasonable efforts to disclose to consumers, where appropriate, that the services that
          provide the consumers with the ability to pay for the goods or services from the Client using the Processing
          Services are provided by Paysafe on behalf of the Client as its limited authorized agent.
        </p>
        <h4 className='my-4'>4. FEES AND RESERVES</h4>
        <p>
          4.1 In consideration for Processing Services the Client agrees to pay to Paysafe the following non-refundable
          fees:
        </p>
        <p>4.1.1 All fees as indicated in the Application;</p>
        <p>
          4.1.2 For any Returned Items that exceed NACHA thresholds, a Fine of up to $35 for each such returned
          transaction may be assessed by Paysafe, its sponsoring ODFI, or the NACHA governing body;
        </p>
        <p>
          4.1.3 Should the Client be deemed to have violated the terms of Schedule B, Client shall pay Paysafe the Fine
          as indicated therein;
        </p>
        <p>
          4.1.4 Any other charges or fees imposed from time to time by the Clearing Houses or banks or other third
          parties for handling Electronic Checks; and
        </p>
        <p>
          4.1.5 For each wire transfer received from Paysafe the amount specified in the attached Application.
        </p>
        <p>4.2 The Client shall also be obliged to:</p>
        <p>4.2.1 reimburse Paysafe for all credits issued by Paysafe on behalf of the Client; and</p>
        <p>4.2.2 reimburse Paysafe for all Returned Items and pay all Fines related to Returned Items.</p>
        <p>
          4.3 Client hereby authorizes Paysafe to deduct from the Remittances the amounts owing under Sections 4.1 and
          4.2 above. In the event that the Remittances are insufficient to pay the amounts owing by the Client to
          Paysafe, the Client shall pay the balance thereof within seven (7) business days following receipt of
          Paysafe&rsquo;s notice for such amount.
        </p>
        <p>
          4.4 Paysafe is authorized to establish a reserve amount (the "Reserve Amount") in connection with
          the Processing Services provided hereunder. The Reserve Amount shall be maintained in full by Paysafe for the
          term of this Agreement and for a period of seven (7) months thereafter. Thereafter, Paysafe shall pay to the
          Client any amounts remaining out of the Reserve Amount that were not used to offset any Obligations due to
          Paysafe in connection with any account, such Obligations including but not limited to Reserve Amount Expenses.
        </p>
        <p>
          4.4.1 The Parties agree that the purpose of the Reserve Amount is to ensure Paysafe&rsquo;s recovery of any
          liabilities owed it or reasonably anticipated to be owed to it by the Client pursuant to this Agreement
          including, without limitation, all applicable fees provided for in Section 4 hereof, and Reserve Amount
          Expenses for any account, transaction or otherwise arising from this Agreement.
        </p>
        <p>
          4.4.2 The Parties agree that the Remittances shall be used to fund and replenish the Reserve Amount. Paysafe
          shall withhold amounts from the Remittances so as to maintain a balance in the Reserve Amount consistent with
          the Reserve Amount set forth in the Application.
        </p>
        <p>
          4.4.3 Paysafe, in its sole discretion, may amend the Reserve Amount at any time following the six (6) month
          anniversary of the Effective Date. Notwithstanding any provision to the contrary in this Agreement, any new
          Reserve Amount would take effect immediately upon the Client&rsquo;s receipt of written notice of such new
          rate from Paysafe.
        </p>
        <p>
          4.4.4 On the first business day of the seventh month after the Effective Date, and on that day of every month
          during the term thereafter, the reserves generated from the first month of operation (and, thereafter, from
          the first remaining month) will be forwarded to the Client on a rolling basis, net of any amounts in any
          Account offset pursuant to this Agreement, including without limitation all Reserve Amount Expenses.
        </p>
        <p>
          4.4.5 For the avoidance of doubt, Paysafe shall have the right, in its sole discretion and at any time without
          notice, to withdraw and make use of any and all amounts from the funds comprising the Reserve Amount. Interest
          that accrues on the amounts comprising the Reserve Amount (if any) shall belong to, and be retained by,
          Paysafe.
        </p>
        <p>
          4.4.6 Client&rsquo;s right in respect of the Reserve Amount is solely a contractual right in a running account
          to payment of the ultimate balance found due (if any), after any offsets under or in accordance with this
          Section 4.4 have been made.
        </p>
        <p>
          4.5 Paysafe is authorized to establish a security deposit account (the "Security Deposit Account")
          in connection with the Processing Services hereunder in the name of Paysafe. The Security Deposit Account
          shall be maintained by Paysafe for the term of this Agreement and for a period of seven (7) months thereafter.
          Thereafter, Paysafe shall pay to the Client any amounts remaining in the Security Deposit Account that were
          not used to offset amounts due to Paysafe under any other provision of this Agreement.
        </p>
        <p>
          4.5.1 As security for the payment of the Obligations by the Client, the Client agrees to provide Paysafe with
          a security deposit (the "Security Deposit") for the Security Deposit Account in the form and
          amount specified in the Application. Any existing Security Deposit and Security Deposit Account shall remain
          in effect but under the terms of this Section 4.5, 4.5.2 and 4.5.3.
        </p>
        <p>
          4.5.2 Interest that accrues on the amounts comprising the Security Deposit (if any) shall belong to, and be
          retained by, Paysafe.
        </p>
        <p>
          4.5.3 Paysafe shall have the right, in its sole discretion, to withdraw from the Security Deposit Account any
          and all amounts owed to Paysafe hereunder should the Client fail to pay such amounts within five (5) business
          days of the Client&rsquo;s receipt of written notice of default from Paysafe.
        </p>
        <p>
          4.6 As continuing and collateral security for the due and punctual payment of any and all amounts now owing or
          which may hereafter become owing to Paysafe by the Client or for the satisfaction of any amounts disbursed by
          Paysafe on behalf of the Client including, but not limited to, credits, Returned Items, Fines, penalties and
          interest, under this Agreement (the "Obligations"), as same may be amended, renewed, extended or
          supplemented, the Client hereby charges, mortgages and hypothecates in favour of Paysafe, with effect as of
          and from this date, all right, title and interest of the Client in and to the Remittances, Security Deposit
          and Reserve Amount and all funds therein comprised. The Client undertakes not to grant to any other person any
          charge, mortgage, hypothecary or other security interest of equal or superior rank to Paysafe's in the
          Remittances, Security Deposit or Reserve Amount. The Client further undertakes, upon notice by Paysafe and at
          its expense, to execute and register such documents as may be necessary or desirable to perfect Paysafe's
          first-ranking security interest therein. To the extent applicable, in the case of any voluntary or involuntary
          bankruptcy or insolvency petition or proceeding, Client acknowledges and agrees that notwithstanding anything
          contained in this Agreement, the Remittances, Security Deposit or Reserve Amount is separate and apart from
          the Client&rsquo;s insolvency estate and Paysafe has a first priority thereon. Client acknowledges and agrees
          that notwithstanding anything contained in this Agreement, the Remittances, Security Deposit or Reserve Amount
          is separate and apart from the Client&rsquo;s insolvency estate and Paysafe has a first priority thereon.
        </p>
        <p>
          4.7 Paysafe shall have the right to immediately withdraw from the Reserve Amount any and all amounts owed to
          it hereunder without notice or demand. Paysafe shall have the additional right to immediately withdraw from
          the Security Deposit any and all amounts owed to it hereunder. Paysafe&rsquo;s rights to sums owed to it by
          Client pursuant to this Agreement shall in no way be limited by the balance or existence of the Reserve Amount
          or the Security Deposit. Paysafe&rsquo;s rights with respect to the Reserve Amount and the Security Deposit
          shall survive the termination of this Agreement.
        </p>
        <p>
          4.8 All interest which may accrue in respect of the Reserve Amount and Security Deposit shall be for the sole
          account of Paysafe.
        </p>
        <p>
          4.9 As amounts become payable to either party under this Agreement, and unless otherwise agreed in writing,
          the party making the payment shall do so by facilitating a wire transfer to a pre-designated account
          stipulated by the other party.
        </p>
        <p>
          4.10 Either party may change or modify its pre-designated account(s), except that any change or modification
          by Client to its pre-designated account(s) requires prior written consent of Paysafe, which may be withheld in
          its sole discretion.
        </p>
        <p>
          4.11 The Client shall be responsible, in addition to the amount mentioned above, for the payment of any and
          all applicable sales or other taxes due upon the Electronic Check Transactions and the fees hereunder.
        </p>
        <p>
          4.12 Notwithstanding Section 3.1.1, Paysafe shall have the right to limit or cease processing credit requests
          (i) should in Paysafe's sole determination the Client's Reserve Amount and Security Deposit not be adequately
          funded; or (ii) if Paysafe has a concern about the volume of activity in the account; or (iii) if the Client
          is in overdraft with Paysafe; or (iv) for any other valid reason.
        </p>
        <p>
          4.13 Paysafe reserves the right to amend the fees charged under Section 4.1 at its sole discretion and at any
          time following the six (6) month anniversary of the execution of this Agreement. The new fees will take effect
          upon a simple notice to the Client in accordance with Section 11.
        </p>
        <p>
          4.14 Paysafe reserves the right to increase the Reserve Amount withheld from Remittances under Section 4.4
          should Paysafe in its sole discretion deem the risk of Client&rsquo;s account to have materially increased.
        </p>
        <h4 className='my-4'>5. INDEMNIFICATION AND LIMITATION OF LIABILITY</h4>
        <p>
          5.1 The Client and the Guarantor (as hereinafter defined) shall jointly and severally defend and hold harmless
          Paysafe against and in respect to any and all claims, demands, losses, costs, expenses, obligations,
          liabilities, damages, recoveries, and deficiencies, including interest, penalties and reasonable attorney fees
          that Paysafe shall incur or suffer, that arise, result from, or relate to any breach of or failure by the
          Client to perform any of its representations, warranties, covenants or agreements in this Agreement or in any
          schedule, supplemental agreement, appendix or other instrument furnished or to be furnished to Client under
          this Agreement.
        </p>
        <p>
          5.2 Paysafe&rsquo;s liability to Client with respect to any Electronic Check Transaction shall not exceed the
          amount represented by the transaction record in connection with such Electronic Check Transaction, less the
          applicable fees payable to Paysafe hereunder.
        </p>
        <p>
          5.3 ALL WARRANTIES EXPRESSED OR IMPLIED INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY
          AND FITNESS FOR ANY PARTICULAR PURPOSE OF THE PROCESSING SERVICES OR OF ANY OTHER SOFTWARE, HARDWARE OR
          SERVICES PROVIDED BY PAYSAFE HEREUNDER ARE HEREBY DISCLAIMED BY PAYSAFE, ITS AFFILIATES, AGENTS AND LICENSORS.
          IN ADDITION, PAYSAFE, ITS AFFILIATES, AGENTS AND LICENSORS SHALL NOT BE LIABLE FOR SPECIAL, INCIDENTAL,
          CONSEQUENTIAL, EXEMPLARY, PUNITIVE, LIQUIDATED OR OTHER INDIRECT OR EXTRA-CONTRACTUAL DAMAGES IN ANY WAY
          CONNECTED WITH OR ARISING OUT OF THE USE OF THE PROCESSING SERVICES OR ANY OTHER SOFTWARE, HARDWARE OR
          SERVICES PROVIDED BY PAYSAFE HEREUNDER.
        </p>
        <p>
          5.4 Paysafe shall use commercially reasonable efforts to perform the Processing Services provided however,
          Paysafe, its affiliates, agents or licensors shall not be liable for any loss resulting from the activities of
          Paysafe's service providers, the Client or the Client's consumers, nor from any erroneous statements or errors
          in transmission, nor for any loss resulting from any delay, interruption or failure to perform hereunder due
          to any circumstances beyond Paysafe's reasonable control including, without limitation, acts of god, fire,
          explosion, earthquake, riot, war, sabotage, accident, embargo, storms, strikes, lockouts, any interruption,
          failure or defects in Internet, telephone, or other interconnect services or in electronic or mechanical
          equipment. Paysafe&rsquo;s obligations hereunder shall be suspended during any of the foregoing circumstances,
          which suspension shall not be a cause for termination of this Agreement by the Client.
        </p>
        <h4 className='my-4'>6. TERM AND TERMINATION</h4>
        <p>
          6.1 This Agreement shall be effective commencing on the Effective Date until the first anniversary of the
          Effective Date, and thereafter shall be renewed automatically for additional consecutive three (3) month
          periods, unless earlier terminated in accordance with the terms of Sections 6.2 to 6.4 hereof.
        </p>
        <p>
          6.2 Notwithstanding Section 6.1, Paysafe shall have the right to terminate this Agreement immediately in the
          event:
        </p>
        <p>
          6.2.1 of breach by the Client of its representations, warranties, covenants or other obligations under this
          Agreement;
        </p>
        <p>
          6.2.2 that the Client is delinquent in any payment hereunder ten (10) days after the same has become due;
        </p>
        <p>
          6.2.3 the Client assigns this Agreement to any party without the consent required under Section 14.3; or
        </p>
        <p>
          6.2.4 the Client makes an assignment for the benefit of its creditors, files a petition in bankruptcy, is
          adjudicated insolvent or bankrupt, files a petition or applies to any tribunal for any receiver, trustee,
          liquidator or sequestrator of any substantial portion of its property, commences any proceeding under any law
          or statute of any jurisdiction respecting insolvency, bankruptcy, reorganization, arrangement or readjustment
          of debt, dissolution, winding-up, composition or liquidation, or otherwise takes advantage of any bankruptcy
          or insolvency legislation whether now or hereafter in effect, or if any receiver, trustee, liquidator or
          sequestrator of any substantial portion of its property is appointed.
        </p>
        <p>
          6.3 Paysafe may also terminate this Agreement without cause upon twenty (20) days&rsquo; written notice to
          Client.
        </p>
        <p>
          6.4 Notwithstanding Section 6.1, Client may terminate this Agreement, with or without cause, upon thirty (30)
          days' written notice to Paysafe.
        </p>
        <p>
          6.5 Upon any termination of this Agreement, the Client shall immediately discontinue the use of all of the
          Processing Services and the license granted under Section 3.3 shall terminate. All provisions regarding
          indemnification, representations, warranties, liability and limits thereon and Confidential Information shall
          survive indefinitely or until the expiration of any time period specified elsewhere in this Agreement with
          respect to the provision in question, and termination of this Agreement shall not relieve the Client of its
          obligations to pay accrued fees.
        </p>
        <p>
          6.6 Upon any termination of this Agreement, Paysafe shall retain, as security for the payment of the
          Obligations, each of the Security Deposit and the Reserve Amount as well as any other amounts in the Client's
          account for a full seven (7) month period from the date of termination.
        </p>
        <h4 className='my-4'>7. CONFIDENTIALITY</h4>
        <p>7.1 The Client agrees that it will:</p>
        <p>7.1.1 not use any of the Confidential Information for purposes other than this Agreement;</p>
        <p>7.1.2 not disclose to any third party any of the Confidential Information; and</p>
        <p>
          7.1.3 will retain in strictest confidence all Confidential Information except in the performance of its
          obligations under this Agreement.
        </p>
        <p>
          7.2 The Client agrees that it will not issue any press releases or similar publicity statement regarding this
          Agreement without the prior written consent of Paysafe or as required by law.
        </p>
        <p>
          7.3 Termination of this Agreement shall not release the Client from its obligations under Sections 7.1 and
          7.2.
        </p>
        <p>
          7.4 Paysafe agrees that it will retain in strictest confidence the names and other personal information about
          the Client's consumers unless such disclosure is required by competent authority.
        </p>
        <p>
          7.5 Termination of this Agreement shall not release Paysafe from its obligations under Section 7.4.
        </p>
        <h4 className='my-4'>8. REPRESENTATIONS, WARRANTIES AND COVENANTS OF THE CLIENT</h4>
        <p>8.1 The Client hereby covenants to Paysafe that it will:</p>
        <p>
          8.1.1 offer for sale through its Web site only products and services that are available for delivery in the
          normal course of the Client&rsquo;s business, based upon the type of product or service being offered, which
          were pre-approved by Paysafe in the Application;
        </p>
        <p>
          8.1.2 offer products or services for sale only if the Client has legitimate rights to market and sell such
          products or services;
        </p>
        <p>
          8.1.3 fully comply with the rules imposed by the Financial Institutions from time to time and as currently
          specified in Schedule A;
        </p>
        <p>
          8.1.4 submit to Paysafe in writing, for Paysafe's written consent, any changes or modifications to the
          Information, including but not limited to, changes in the products and services available through the
          Client&rsquo;s Web site or changes to any of Client&rsquo;s policies;
        </p>
        <p>
          8.1.5 only use the Processing Services for transactions for which Client has received full express and proper
          authorization from the consumer for such transaction, including full express and proper authorization and
          consent from the consumer for Paysafe and its affiliates, agents, suppliers, and subcontractors to use
          personal information provided by consumer to obtain credit reports from authorized credit reporting agencies,
          in accordance with all applicable laws and regulations and the NACHA Operating Rules; Client further
          represents, warrants and covenants that it has obtained all required consents from consumers in respect of
          their personal information to be accessed, collected, used or transferred by Paysafe in providing the services
          under this Agreement; and it has read, understood and hereby accepts Paysafe&rsquo; s privacy policy on behalf
          of itself and the consumers at:&nbsp;
          <a href='https://www.paysafe%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20.com/paysafegroup/privacy-policy/'>
            https://www.paysafe.com/paysafegroup/privacy-policy/
          </a>
          . In addition, to the extent that Client has been introduced to Paysafe and entered into this Merchant
          Agreement through a referral partner of Paysafe or a software platform provider integrated to Paysafe
          (collectively referred to as the "Platform Partner"), Client acknowledges and agrees that Paysafe
          may share transaction information with the Platform Partner on a need to know basis.
        </p>
        <p>
          8.1.6 only use the Processing Services for transactions that are in compliance with all federal, state and
          local laws and regulations, and the NACHA Operating Rules; and
        </p>
        <p>
          8.1.7 only use the Processing Services for transactions with consumers who can form legally binding contracts
          under applicable laws and regulations. Without limiting the scope of this Section 8.1.7, consumers must be at
          least 18 years of age or the age of majority in the jurisdiction in which the consumer is domiciled, whichever
          is greater.
        </p>
        <p>
          8.1.8 Once the Client has received confirmation from Paysafe of a consumer&rsquo;s payment, the Client
          covenants to deliver, perform, or allow the consumer access to the purchased service or good of the Client.
          Consumer access to the purchased good or service shall be, as the case may be, immediate upon receipt of the
          aforementioned payment confirmation, or within a longer time frame as specified in the terms of the
          agreement(s) or policies between the Client and the consumer, and shall be provided by the Client as if all
          amounts due from the consumer to the Client related thereto were received by the Client, regardless of whether
          such amounts are ultimately remitted to the Client by Paysafe. The foregoing obligation of the Client shall
          survive the termination or expiration of this Agreement.
        </p>
        <p>8.2 The Client hereby represents and warrants to Paysafe that:</p>
        <p>
          8.2.1 it will maintain the value and reputation of Paysafe to the best of its reasonable ability;
        </p>
        <p>8.2.2 it will advise Paysafe promptly in writing of any errors in the Processing Services;</p>
        <p>
          8.2.3 it will conduct its business affairs in accordance with the terms and intent of this Agreement, and in
          compliance with all applicable government laws, including but not limited to compliance with Federal Trade
          Commission Telemarketing Sales Rule (16 C.F.R. Part 310) and the Telephone Consumer Protection Act, and
          regulations and the NACHA Operating Rules or other rules or guidelines as set out by Paysafe from time to
          time;
        </p>
        <p>
          8.2.4 it will manage its business in such a way as to ensure that Returned Items do not exceed a reasonable
          amount of the total value of its business;
        </p>
        <p>
          8.2.5 it will immediately replenish its account at Paysafe should the balance fall below acceptable levels as
          determined by Paysafe;
        </p>
        <p>
          8.2.6 it will not use the Processing Services in connection with any illegal or fraudulent business
          activities;
        </p>
        <p>8.2.7 it will not permit or authorize any other person to use the Processing Services;</p>
        <p>
          8.2.8 the Information will not be changed or modified in any way without the prior written consent of Paysafe;
        </p>
        <p>
          8.2.9 In respect of every transaction, the consumer shall have accurately provided all required information
          necessary to authorize and process the transaction;
        </p>
        <p>
          8.2.10 the date of the Electronic Check shall accurately coincide with the date of the submission of the
          transaction to Paysafe for processing and the date the transaction actually occurred;
        </p>
        <p>
          8.2.11 the amount of the Electronic Check entered into the Paysafe system for processing and the Electronic
          Check amount authorized by the consumer shall agree;
        </p>
        <p>
          8.2.12 in respect of every transaction, Client has no reason to question or have notice of any fact,
          circumstance or defense which would impair the validity or collectability of the consumer&rsquo;s obligation
          or relieve the consumer from liability for the Electronic Check;
        </p>
        <p>
          8.2.13 each Electronic Check must be a first party personal or company check for which the consumer has
          authorized payment to Client drawn by electronic means on a United States financial institution for the
          purchase of goods or services from Client; and
        </p>
        <p>
          8.2.14 each Electronic Check represents the obligation of the consumer for goods or services actually sold or
          rendered by Client for the actual price of such goods or services (including tax and shipping) and does not
          involve any element of credit for any purpose.
        </p>
        <p>
          8.3 The Client acknowledges to Paysafe that the parties are independent contractors and that nothing herein
          shall be construed as creating a joint venture or partnership between them. For greater certainty, the Client
          acknowledges that Paysafe is not involved in the Client's business.
        </p>
        <p>
          8.4 The Client agrees that at any time, and from time to time, during the term of this agreement and only with
          regard to the Paysafe services, Paysafe shall have the right to post or to require posting of, without any
          charge, a banner, graphic or logo of Paysafe on the application/deposit page of internet Web site(s)
          incorporating the Processing Services, advertising Paysafe&rsquo;s and its affiliates&rsquo; businesses.
          Paysafe&rsquo;s obligation to provide Client with the Processing Services are conditional on Client
          undertaking to:
        </p>
        <p>8.4.1 Identify Client Designated Account;</p>
        <p>
          8.4.2 Provide Paysafe with the Data in a timely manner and as requested by Paysafe from time to time;
        </p>
        <p>8.4.3 Co-operate with Paysafe to permit Paysafe to provide the Processing Services;</p>
        <p>
          8.4.4 Follow procedures, written or oral, as provided to Client by Paysafe from time to time;
        </p>
        <p>8.4.5 Adhere to all applicable laws and regulations;</p>
        <p>
          8.4.6 Review all statements issued to Client by Paysafe and inform Paysafe in writing of any errors or
          discrepancies within five (5) days of Client receipt of same;
        </p>
        <p>8.5 Client hereby irrevocably pre-authorizes Paysafe to:</p>
        <p>
          8.5.1 issue debits for payment from any Client Designated Account to Paysafe for any amount due from Client to
          Paysafe in accordance with the terms hereof; and
        </p>
        <p>
          8.5.2 issue debits for payment from Client Designated Account to pay for Fees, Returned Items, penalties and
          Fines; such rights to exist during the term of this Agreement and to continue for one hundred and eighty (180)
          days thereafter.
        </p>
        <p>
          8.6 Upon Client execution of this Agreement Client authorize Paysafe to undertake the appropriate credit and
          risk assessments on Client.
        </p>
        <h4 className='my-4'>9. GUARANTORS</h4>
        <p>
          9.1 As a primary inducement to Paysafe to enter into this Agreement, the individuals listed as
          "Guarantor 1" and "Guarantor 2" on the attached Application (the
          "Guarantors"), being all the registered and beneficial shareholders of the Client or any other
          party identified as a Guarantor, by signing this Agreement, jointly and severally, unconditionally and
          irrevocably, guarantee the continuing full and faithful performance and payment by Client of each of its
          duties and obligations to Paysafe pursuant to this Agreement, whether before or after termination or
          expiration and whether or not any of the Guarantors has received notice of any amendment. If Client breaches
          this Agreement, Paysafe may proceed directly against any or all of the Guarantors or any other persons or
          entity responsible for the performance of this Agreement, without first exhausting its remedies against any
          other person or entity responsible therefore to it, or any security held by Paysafe.
        </p>
        <h4 className='my-4'>10. AMENDMENTS</h4>
        <p>
          10.1 Except as otherwise expressly stated to the contrary, Paysafe may amend this Agreement at any time by
          written notice to Client of any amendment at least thirty (30) days prior to the effective date of the
          amendment, which amendment shall not (without Client&rsquo;s written consent) retroactively affect or modify
          the fees, reserves or transactions occurring prior to the effective date of the amendment. The amendment shall
          become effective on the date specified by Paysafe unless Paysafe receives Client&rsquo;s notice of termination
          of this Agreement pursuant to this Section 10.1 before such effective date.
        </p>
        <h4 className='my-4'>11. NOTICES</h4>
        <p>
          11.1&nbsp;<u>Written Notices</u>. All written notices and other written communications required or permitted
          under this Agreement must be either personally delivered, sent by prepaid, registered mail or sent by email,
          charges (if any) prepaid, addressed as follows:
        </p>
        <ol type='A'>
          <li>
            <p>
              <u>If to Paysafe Merchant Services Corp.</u>:<br />
              3500 de Maisonneuve Blvd. W, Suite 700
              <br />
              Montr&eacute;al, Qu&eacute;bec, H3Z 3C1
              <br />
              Attn: Legal Department
              <br />
              email:&nbsp;<a href='mailto:legal.departmentNA@paysafe.com'>legal.departmentNA@paysafe.com</a>
            </p>
          </li>
          <li>
            <p>
              <u>If to Client</u>: At the email address or address provided as the billing address and to the contact
              listed on the Merchant Application.
            </p>
          </li>
        </ol>
        <p>
          Any written notice delivered to the party to whom it is addressed will be deemed to have been given and
          received on the day it is so delivered at that party&rsquo;s address, provided that (i) it is so delivered
          before 5:00 p.m.; and (ii) if that day is not a business day then the written notice will be deemed to have
          been given and received on the next business day. Any written notice transmitted by email will be deemed to
          have been given and received on the day on which it was transmitted (but if the written notice is transmitted
          on a day which is not a business day (or after 5:00 p.m.), the written notice will be deemed to have been
          received on the next business day). Any written notice given by registered mail will be deemed to have been
          received on the fifth business day after which it is so mailed. Merchant acknowledges and agrees that written
          notice and other written communications required or permitted to be given by Paysafe under the Merchant
          Agreement shall be properly given if contained in the on-line statement provided from time to time to Merchant
          by Paysafe. For purposes of this Section 11.1, references to a time of day shall mean that time of day in the
          jurisdiction of the receiving party (e.g., "5:00 p.m." shall mean 5:00 p.m. in the jurisdiction of
          the receiving party) and references to "business day" shall be mean a day other than a Saturday,
          Sunday or statutory holiday in the jurisdiction of the receiving party.
        </p>
        <h4 className='my-4'>12. RETENTION OF ELECTRONIC CHECK AUTHORIZATION RECORDS</h4>
        <p>
          12.1 Client shall cause the consumer to provide a verbal authorization for each Electronic Check Transaction
          conducted by telephone ("Telephone Transaction") and an electronic authorization for each
          transaction conducted over the Internet ("Internet Transaction") submitted to Paysafe for
          processing pursuant to this Agreement. In connection with the Telephone Transactions, Client shall either make
          a tape recording of the consumer&rsquo;s verbal telephone authorization or Client shall provide a written
          confirmation notice to the consumer of such verbal authorization in Paysafe&rsquo;s required format within
          three (3) days of the Electronic Transaction. Client shall maintain a copy of (i) each such tape recording
          authorization or, alternatively, the written confirmation notice provided to the consumer of the
          consumer&rsquo;s verbal authorization for Telephone Transactions, and (ii) said electronic authorization for
          Internet Transactions for a minimum period of two (2) years from the date of the transaction or for the period
          specified by the NACHA Operating Rules, whichever is longer (collectively the "Authorization
          Documentation").
        </p>
        <p>
          12.2 Within 7 days of Paysafe&rsquo;s request therefor, Client shall deliver to Paysafe a physical or
          electronic copy of the Authorization Documentation for any Telephone Transaction and/or Internet Transaction.
        </p>
        <p>
          12.3 Client, upon reasonable notice and during normal business hours, shall permit Paysafe to audit Client for
          its compliance with this Section 12.
        </p>
        <p>
          12.4 Notwithstanding anything to the contrary herein, if Paysafe has agreed to provide the written
          confirmation notice referred to in this Section 12 on behalf of Client to the consumer, Client shall not be
          responsible for maintaining such records nor shall Client be considered in breach of such requirement.
        </p>
        <h4 className='my-4'>13. CREDIT LAW COMPLIANCE</h4>
        <p>
          13.1 Client certifies that: (i) it has a legitimate business need in connection with a transaction initiated
          by or with the consumer for the information provided by Paysafe under this Agreement regarding such consumer;
          and (ii) the information provided by Paysafe will only be used for permissible purposes as defined in the Fair
          Credit Reporting Act and applicable state and federal laws, with the exception that the information will not
          be used for employment purposes, and will not be used by Client for any purpose other than one transaction
          between Client and the consumer occurring on the date of the submission of the transaction to Paysafe for
          processing. Neither Client, nor its agents or employees, shall disclose the results of any inquiry made to
          Paysafe except to the consumer about whom such inquiry is made and in no case to any other person outside
          Client&rsquo;s organization. If Client decides to reject any transaction, in whole or in part, because of
          information obtained from Paysafe, Client agrees to provide the consumer with all information required by law
          and Paysafe.
        </p>
        <h4 className='my-4'>14. MISCELLANEOUS</h4>
        <p>
          14.1 Subject to the payment of the minimum monthly fee as indicated in the Application, Client&rsquo;s use of
          Paysafe&rsquo;s services hereunder are completely at will and non-exclusive.
        </p>
        <p>
          14.2 This Agreement together with its Schedules and any supplemental agreements, addenda and appendixes
          constitutes the entire agreement between the parties pertaining to the subject matter contained in it and
          supersedes all prior and contemporaneous agreements, representations and understandings of the parties. No
          waiver of any of the provisions in this Agreement shall be deemed or shall constitute, a waiver of any other
          provision, whether or not similar, nor shall any waiver constitute a continuing waiver. No waiver shall be
          binding unless executed in writing by the party making the waiver.
        </p>
        <p>
          14.3 The Client may not assign this Agreement, or any rights hereunder, directly or by operation of law,
          without the prior written consent of Paysafe which consent may be withheld for any reason in Paysafe's sole
          discretion. For purposes of this Agreement, assignment shall include, but not be limited to, transfer of
          control of the Client, any ownership change which results in a new majority owner and any change in the
          jurisdiction of incorporation of the Client.
        </p>
        <p>
          14.4 The Client shall be liable for and shall indemnify and reimburse Paysafe for any and all attorneys&rsquo;
          fees and other costs and expenses paid or incurred by Paysafe in the enforcement of this Agreement, or in
          collecting any amounts due from the Client hereunder, or resulting from any breach of any of the terms or
          conditions of this Agreement.
        </p>
        <p>
          14.5 All remedies of either party hereunder are cumulative and may be exercised concurrently or separately.
          The exercise of any one remedy shall not be deemed to be an election of such remedy and shall not preclude the
          exercise of any other remedy. No failure on the part of either party to exercise and no delay in exercising
          any right or remedy hereunder shall operate as a waiver of such right or remedy.
        </p>
        <p>
          14.6 If any provision of this Agreement is held invalid or unenforceable by any court of final jurisdiction,
          it is the intent of the parties that all other provisions of this Agreement be construed to remain fully
          valid, enforceable and binding on the parties.
        </p>
        <p>
          14.7 The subject headings of the paragraphs and subparagraphs of this Agreement are included for convenience
          only and shall not affect the construction or interpretation of any of its provisions.
        </p>
        <p>
          14.8 All disputes arising between the parties hereto in connection with the Agreement, including its
          existence, validity or termination, shall be resolved through binding arbitration under the Rules of the
          American Arbitration Association or, if mutually agreed, by another recognized arbitral body, in either case
          to take place in Montreal, Canada before a single arbitrator with the proceedings to be conducted in English.
          This Agreement shall be governed by and construed under the laws of the State of New York, without regard to
          principles of conflict or choice of law of the State of New York or any other jurisdiction.
        </p>
        <p>14.9 All amounts referred to in this Agreement are in United States funds.</p>
        <p>
          14.10 The Parties hereto intend that this Agreement is not an executory contract and that each Party hereto
          has fully performed its obligations hereunder. In the event that Client files a petition under the bankruptcy
          laws of the United States, or the equivalent of another jurisdiction, or that an involuntary petition shall be
          filed against Client, the parties hereto intend that Paysafe shall be protected in the continued enjoyment of
          its rights hereunder to the maximum feasible extent including, without limitation, if it so elects, the
          protection conferred upon licensees under 11 U.S.C. &sect; 365(n), any successor provision or any similar
          provision of any applicable law.
        </p>
        <h4 className='my-4'>BBVA SPONSOR AGREEMENT</h4>
        <p>
          This BBVA Addendum ("Addendum") to the Merchant Agreement ("Agreement") provides
          additional terms applicable to the payment processing services described in the Agreement for Visa U.S.A.,
          Inc. and Visa International ("Visa"), and Mastercard International Incorporated
          ("Mastercard") products (the "Payment Processing Services"). Visa and Mastercard shall
          be referred to collectively herein as the "Networks". Any capitalized terms used but not defined
          in this Addendum will have the meaning provided in the Agreement.
        </p>
        <p>
          This Addendum constitutes a legal agreement between you, Paysafe Merchant Services Corp.
          ("Paysafe"), and BBVA USA, an Alabama banking corporation, with offices at 15 20th Street South,
          Birmingham, AL 35233 ("BBVA").
        </p>
        <p>
          You understand and agree that Paysafe or BBVA may enforce, and that BBVA shall be an express third-party
          beneficiary of, any provisions of the Agreement that relate to your use of Payment Processing Services.
          Paysafe or BBVA may jointly or individually assert or exercise any rights or remedies provided to Paysafe
          under the Agreement and any of your obligations to Paysafe shall also be deemed obligations to BBVA. Paysafe
          or BBVA may also terminate this Addendum at any time, which may limit or terminate your ability to use the
          Payment Processing Services.
        </p>
        <p>
          By executing this Addendum, you understand and agree that BBVA is the only entity approved to extend
          acceptance of Network products directly to you under this Addendum and that, during the term of this Addendum,
          BBVA shall be the "Bank" referenced in the Agreement.
        </p>
        <p>
          You must accept all of the terms and conditions of this Addendum to use the Payment Processing Services. If
          you do not accept them, you may not receive Payment Processing Services.
        </p>
        <p>1. Purpose of this Addendum</p>
        <p>
          As a Visa and Mastercard member bank, BBVA authorizes you to accept payment by Visa and Mastercard credit or
          debit card ("Payment Cards"). Paysafe will underwrite and evaluate your eligibility to receive the
          Payment Processing Services, authorize Transactions, instruct BBVA to settle funds to your account(s), and
          provide the Payment Processing Services pursuant to the terms of the Agreement. Paysafe, and not BBVA, is
          responsible for will provide customer service to you to resolve any issues you may have related to your use of
          the Payment Processing Services; however, you may contact BBVA (using the contact information in Section 16)
          in the event you are unable to resolve any matters directly with Paysafe. You are solely responsible for
          providing support to your Customers for all issues related to your products and services.
        </p>
        <p>2. Compliance with Visa and Mastercard Rules</p>
        <p>
          When you use the Payment Processing Services to accept payment by Cards, you must comply with the Network
          Rules, including, but not limited to, the guidelines, monitoring programs, and activity reporting (including
          excessive credits, chargebacks, or deposits) set forth therein. Under the Network Rules, certain activity may
          subject you to chargebacks, fees, fines, settlement delays, withholdings, audits of your processing activity,
          or termination of this Addendum and/or the Agreement. Without limiting the foregoing, you specifically agree
          to:
        </p>
        <ol type='a'>
          <li>
            <p>Only submit Transactions authorized by the Cardholder;</p>
          </li>
          <li>
            <p>
              Only accept payment for the sale of products or services, and receipt of bona fide donations, conducted by
              you pursuant to your business as indicated on your application and not for any business prohibited by Laws
              or Network Rules;
            </p>
          </li>
          <li>
            <p>
              Submit a Transaction for the full amount owed by the Cardholder for the Transaction except where you and
              the Cardholder agree on a partial shipment (such as receiving a portion of an order), or where the
              Transaction qualifies for delayed delivery or special order deposits (such as paying for a deposit on a
              custom-built product);
            </p>
          </li>
          <li>
            <p>
              Not establish minimum or maximum amounts (except as permitted by the Network Rules), or condition use of
              Payment Cards, and not discourage the use of one Network brand over another;
            </p>
          </li>
          <li>
            <p>
              Not impose surcharges or taxes (except where permitted by Law and the Network Rules) and, where so done,
              you will only collect such amounts as part of the submitted Transaction;
            </p>
          </li>
          <li>
            <p>
              Not require a Cardholder to complete a postcard or similar device that includes the Cardholder&rsquo;s
              account number, card expiration date, signature, or any other card account data in plain view when mailed;
            </p>
          </li>
          <li>
            <p>
              Not disburse funds in the form of travelers checks if the sole purpose is to allow the Cardholder to make
              a cash purchase of goods or services from you;
            </p>
          </li>
          <li>
            <p>
              Not submit any transaction receipt for a Transaction that was previously charged back and subsequently
              returned to you, irrespective of Cardholder approval;
            </p>
          </li>
          <li>
            <p>
              Not accept a Network consumer credit card or commercial card to collect or refinance an existing debt;
            </p>
          </li>
          <li>
            <p>Not submit a Transaction that represents collection of a dishonored check;</p>
          </li>
          <li>
            <p>Only use the Network logos or marks in a manner permitted by the Network Rules;</p>
          </li>
          <li>
            <p>
              Prohibit use of Cards for disbursement of cash (except as permitted by the Network Rules);
            </p>
          </li>
          <li>
            <p>
              Comply with the security obligations identified in the Agreement, including compliance with PCI-DSS and
              only use cardholder data as permitted, and certify such compliance upon request, and not permit or promote
              fraudulent use of Cards or cardholder data;
            </p>
          </li>
          <li>
            <p>
              Make clear to customers that they are transacting with you prior to, during, and after the Transaction,
              including providing clear statement descriptors;
            </p>
          </li>
          <li>
            <p>
              Use all reasonable methods to resolve disputes with customers, including those resulting in a chargeback,
              and not attempt to recharge a customer for the Transaction unless authorized by the customer; and
            </p>
          </li>
          <li>
            <p>
              Provide clear refund and exchange language that is consistent with Laws and the Network Rules.
            </p>
          </li>
        </ol>
        <p>3. Authorization for Handling of Funds</p>
        <p>
          You authorize Paysafe to instruct BBVA on how and when to make transfers of settlement funds received from
          Visa and Mastercard, including the initiation of holds, receipts, and disbursement of funds on your behalf.
          You further agree that you have no right to direct BBVA to distribute settlement funds, that you may not
          assign any interest in any funds held by BBVA, and that you are not entitled to any interest or other
          compensation associated with the settlement funds held by BBVA. Any authorizations set forth in this Addendum
          will remain in full force and effect until your account with Paysafe ("Merchant Account") is
          closed or terminated.
        </p>
        <p>
          You agree that the deposit of funds to the Account or an ISV Platform, according to Paysafe&rsquo;s
          instructions, shall discharge BBVA of its settlement obligation to you, and that any dispute regarding the
          receipt or amount of settlement shall be between you and Paysafe. Further, if a Cardholder disputes a
          Transaction, if a Transaction is charged back for any reason, or if BBVA reasonably believes a Transaction is
          unauthorized or otherwise unacceptable, the amount of such Transaction may be charged back and debited from
          you or Paysafe.
        </p>
        <p>4. Sharing of Data</p>
        <ol type='a'>
          <li>
            <p>
              You authorize Paysafe and BBVA to provide any information about your business, its operations, products,
              and services, your deposit accounts, and any other information used or received in connection with the
              Payment Processing Services ("Data") to each other to (i) provide the Payment Processing
              Services, (ii) comply with legal and regulatory obligations, and (iii) perform underwriting and risk
              review, including verification that you are legally permitted to transact and receive funds. This includes
              sharing information you previously provided to Paysafe and information about BBVA and Paysafe&rsquo;s
              experience with you, such as termination of this Addendum by BBVA and the reasons for such termination.
              Where required to comply with our obligations under Laws, the Network Rules, or any of BBVA&rsquo;s
              regulatory obligations, BBVA may provide any Data to law enforcement, the Networks, or other government
              regulators.
            </p>
          </li>
          <li>
            <p>
              To help the government fight the funding of terrorism and money laundering activities, Federal law
              requires all financial institutions to obtain, verify, and record information that identifies each person
              who opens an account. What this means for you: You authorize Paysafe to collect and provide to BBVA any
              information required to verify your identity, including, but not limited to your name, address, and
              taxpayer identification number. If you are a legal entity, including a limited liability company or
              corporation, you agree to provide Paysafe with information on the identity of (1) all beneficial owners of
              25% or more of the company; and (2) at least one individual with significant control over the company.
            </p>
          </li>
          <li>
            <p>
              BBVA may use Data that is anonymized and aggregated with other merchants&rsquo; data for internal purposes
              and to provide you, other merchants, and third parties with analytic products and services.
            </p>
          </li>
        </ol>
        <p>5. Audits</p>
        <p>
          Upon reasonable notice to you from BBVA, or Paysafe on behalf of BBVA, you shall allow auditors of BBVA, the
          Networks, or any government regulator with authority over BBVA and Paysafe to review your files and procedures
          at any or all of your offices or places of business. You agree to bear the cost of such audit if it is
          conducted (i) at the request of the Networks or government regulator with authority over BBVA; or (ii) to
          confirm, in BBVA&rsquo;s sole discretion, your compliance with the Network Rules, PCI-DSS or other applicable
          privacy and data security standards, and any other applicable Laws.
        </p>
        <p>6. Term and Termination</p>
        <p>
          This Addendum shall have the same effective date as the Agreement and shall continue so long as you use the
          Payment Processing Services. This Addendum will terminate automatically upon termination of the Agreement,
          except for those terms which are intended to survive termination. In addition, the Payment Processing Services
          and/or this Addendum may be terminated at any time by BBVA immediately upon notice to you.
        </p>
        <p>7. Information Rights</p>
        <p>
          You agree to provide to BBVA, within five (5) business days of any request in writing from BBVA, or from
          Paysafe on behalf of BBVA, any financial or credit information, information on your sales and operations, and
          any information necessary, in BBVA&rsquo;s sole discretion, to confirm your identity, the identity of your
          Representatives, principals, beneficial owners, and other individuals associated with your business, or your
          compliance with applicable Laws and Network Rules.
        </p>
        <p>8. Representations and Warranties</p>
        <ol type='a'>
          <li>
            <p>
              In addition to the representations and warranties made in the Agreement, you represent and warrant to BBVA
              and Paysafe as of each day on which you receive Payment Processing Services that:
            </p>
          </li>
          <li>
            <p>You are legally able to enter into this Addendum;</p>
          </li>
          <li>
            <p>
              You will not use the Payment Processing Services, directly or indirectly, for any fraudulent or illegal
              undertaking;
            </p>
          </li>
          <li>
            <p>
              You will only use the Payment Processing Services in a manner consistent with the Agreement, including
              this Addendum, Laws, and Network Rules;
            </p>
          </li>
          <li>
            <p>
              Each statement made on the application or other information provided to Paysafe or BBVA in support of the
              Agreement or the Addendum is true, correct, and complete;
            </p>
          </li>
          <li>
            <p>You are not doing business under a name or style not previously disclosed to Paysafe;</p>
          </li>
          <li>
            <p>
              You have not changed the nature of your business, card acceptance practices, delivery methods, return
              policies or types of products or services sold requiring a different MCC under the Network Rules, in a way
              not previously disclosed to Paysafe or BBVA;
            </p>
          </li>
          <li>
            <p>
              You will use the Payment Processing Services only for your own proper business purposes and will not
              resell, directly or indirectly, any part of the Payment Processing Services to any other party;
            </p>
          </li>
          <li>
            <p>
              You, and any Principals and Representatives, as applicable, are not subject to any economic sanctions
              imposed by the U.S. government, including by the Department of State or the Department of Treasury, Office
              of Foreign Assets Control ("OFAC"), including, but not limited to, the Specially Designated
              Nationals and Blocked Persons List ("SDN List") (available at www.treas.gov/ofac) or the U.S.
              Department of State&rsquo;s Terrorist Exclusion List (available at www.state.gov). "Principal"
              means, if you are an entity, any person owning directly, indirectly, beneficially or otherwise controlling
              at least 5% of the equity or voting power of the entity; and
            </p>
          </li>
          <li>
            <p>
              Your business is located and operated exclusively within the United States, Puerto Rico, and the U.S.
              Virgin Islands.
            </p>
          </li>
        </ol>
        <p>9. Indemnification</p>
        <p>
          Notwithstanding the foregoing or anything to the contrary in the Agreement, you agree to defend, indemnify,
          and hold harmless BBVA and Paysafe, and their respective employees, directors, agents, subcontractors and
          affiliates (collectively the "BBVA Entities") from and against any claim, suit, demand, loss,
          liability, damage, action, or proceeding arising out of or relating to (a) your breach of any provision of the
          Agreement or this Addendum; (b) your use of the Payment Processing Services; (c) your obligations to pay fees
          or fines to Paysafe, your customers, financial services providers, or third parties; (d) negligence or willful
          misconduct of your employees, contractors, or agents; and (e) all third-party indemnity obligations BBVA
          incurs as a direct or indirect result of your acts or omissions (including indemnification of any Network,
          card issuer, or intermediary bank).
        </p>
        <p>10. Disclaimer of Warranties</p>
        <p>
          ANY SERVICES DESCRIBED IN THIS ADDENDUM ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
          BASIS, WITHOUT ANY WARRANTIES, EITHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED
          WARRANTIES OF TITLE, QUALITY, SUITABILTY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. ALL DISCLAIMERS OF WARRANTIES PROVIDED IN THE AGREEMENT WILL APPLY EQUALLY TO THE BBVA
          ENTITIES AS THEY DO TO PAYSAFE. THE BBVA ENTITIES (A) ARE NOT RESPONSIBLE FOR YOUR OR PAYSAFE&rsquo;S FAILURE
          TO PERFORM OBLIGATIONS UNDER THE AGREEMENT AND (B) DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY YOU, PAYSAFE, OR ANY THIRD PARTY.
        </p>
        <p>11. Limitations on Liability</p>
        <p>
          In no event will the BBVA Entities be liable for any lost profits, lost revenue, lost business opportunity,
          loss of data, or any indirect, punitive, incidental, special, consequential, or exemplary damages arising out
          of, in connection with, or relating to the Agreement, including this Addendum, or the Payment Processing
          Services described in either, including without limitation the use of, inability to use, or unavailability of
          services provided by Paysafe. Under no circumstances will any of the BBVA Entities be responsible for any
          damage, loss, or injury resulting from hacking, tampering, or other unauthorized access, or use of the Payment
          Processing Services or the Data, or your failure to use or implement security, controls, or processes that are
          appropriate for your business.
        </p>
        <p>
          The BBVA Entities assume no liability or responsibility for any (a) personal injury or property damage, of any
          nature whatsoever, resulting from your access to or use of the Payment Processing Services; (b) any misuse of
          Payment Processing Services or Data; (c) any interruption or cessation of transmission to or from the Payment
          Processing Services; (d) any software bugs, viruses, or other harmful code that may be transmitted to,
          through, or in connection with the Payment Processing Services; (e) any errors, inaccuracies, or omissions in
          the Payment Processing Services or Data, or any loss or damage resulting therefrom, regardless of the manner
          of transmission; or (f) defamatory, offensive, or illegal conduct of any third party.
        </p>
        <p>
          Without limiting anything to the contrary, the BBVA Entities&rsquo; cumulative liability to you is limited to
          direct damages and in all events will not exceed in the aggregate the lesser of (i) $10,000 or (ii) amounts
          actually received by BBVA (including any fees paid to BBVA) for providing services to you during the three (3)
          month period immediately preceding the event that gives rise to the claim for liability.
        </p>
        <p>
          This limitation of liability section applies regardless of the legal theory on which the claim is based,
          including without limitation contract, tort (including negligence), strict liability, or any other basis. The
          limitations apply even if Paysafe or BBVA have been advised of the possibility of such damage.
        </p>
        <p>
          Without limiting anything to the contrary, you agree to provide BBVA, via a communication with Paysafe, with
          written notice of any alleged breach by BBVA of this Addendum, which notice will specifically detail such
          alleged breach, within thirty (30) days of the date on which the alleged breach first occurred. Failure to
          provide notice shall be deemed an acceptance by you and a waiver of any and all rights to dispute such breach.
        </p>
        <p>
          The foregoing will apply to the fullest extent permitted by law in the applicable jurisdiction.
        </p>
        <p>12. United States Only Services; No Illegal Activities</p>
        <p>
          You may not use the Payment Processing Services or services of any kind offered by BBVA from, or on behalf of
          persons or entities (a) in a country embargoed by the United States or (b) blocked or denied by the United
          States government. You further acknowledge and agree that you will not use the Payment Processing Services for
          illegal Transactions, for example, those prohibited by the Unlawful Internet Gambling Enforcement Act, 31
          U.S.C. Section 5361 et seq., as may be amended from time to time, or those involving any Person listed on
          OFAC&rsquo;s SDN List or the U.S. Department of State&rsquo;s Terrorist Exclusion List (available at
          www.state.gov) or the processing and acceptance of Transactions in certain jurisdictions pursuant to 31 CFR
          Part 500 et seq. and other laws enforced by OFAC or in connection with illegal activity of any kind. Unless
          otherwise explicitly stated, the Payment Processing Services are solely for use by individuals, companies, or
          other entities located (as defined under Laws and Network Rules) in the United States, Puerto Rico, and the
          U.S. Virgin Islands.
        </p>
        <p>
          Notwithstanding anything to the contrary in this Addendum, BBVA may decline to process any Transaction
          submitted by you in its sole discretion.
        </p>
        <p>13. Dispute Resolution</p>
        <p>
          You agree to settle any dispute or controversy concerning or relating to this Addendum through binding
          arbitration before a single arbitrator from the American Arbitration Association ("AAA") or JAMS
          ADR ("JAMS"), held in Birmingham, Alabama in accordance with the rules of the AAA or the rules of
          JAMS. If the total dollar amount of all disputes is more than $250,000, three arbitrators will hear the
          dispute. This arbitration provision is made pursuant to a transaction involving interstate commerce, and the
          Federal Arbitration Act shall govern the construction, interpretation and enforceability of this arbitration
          provision despite any other choice of law provision in this Agreement. Each party hereby agrees not to elect a
          trial by jury of any issue triable of right by jury, and waives any right to trial by jury fully to the extent
          that any such right shall now or hereafter exist with regard to this agreement or any action, lawsuit, claim,
          counterclaim or other action related to, or arising under this agreement and/or any transaction governed by
          this agreement. This waiver of right to trial by jury is given knowingly, voluntarily and intentionally by
          each party, and is intended to apply to each instance and each issue as to which the right to a trial by jury
          would otherwise be available. The parties are hereby authorized to file a copy of this paragraph in any
          proceeding as conclusive evidence of this waiver by the other party.
        </p>
        <p>14. Waiver; Amendment</p>
        <p>
          The failure of BBVA to assert any of its rights under this Addendum or the Agreement shall not be deemed to
          constitute a waiver by BBVA of its rights to enforce each and every provision of this Addendum or the
          Agreement in accordance with their terms. This Addendum may be amended by BBVA from time to time in the same
          manner as the Agreement may be amended by Paysafe.
        </p>
        <p>15. Miscellaneous</p>
        <p>
          This Addendum is entered into, governed by, and construed pursuant to the laws of the State of Alabama without
          regard to conflicts of law provisions. This Addendum may not be assigned by you without the prior written
          consent of BBVA. This Addendum shall be binding upon and inure to the benefit of the parties hereto and their
          respective successors, transferees, and assignees. If any provision of this Addendum is determined to be
          illegal or invalid, such illegality or invalidity of that provision will not affect any of the remaining
          provisions and this Addendum will be construed as if such provision is not contained in the Addendum.
        </p>
        <p>16. Visa and Mastercard Compliance and Disclosure</p>
        <p>Member Bank (Acquirer) Information</p>
        <p>
          BBVA USA
          <br />
          15 20th Street South,
          <br />
          Birmingham, AL 35233
          <br />
          1.800.313.8815
          <br />
          merchant.us@bbva.com
        </p>
        <p>
          a. Important Member Bank Responsibilities: BBVA discloses that (i) it is the only entity approved to extend
          acceptance of Visa and Mastercard Payment Cards directly to you under this Addendum; (ii) it must be a
          principal to this Addendum; (iii) it is responsible for educating you on the Network Rules with which you must
          comply, but this information may be provided to you by Paysafe; and (iv) subject to this Addendum, it is
          responsible for and must provide settlement funds to Paysafe for distribution to you or directly to you; and
          (v) BBVA is responsible for all funds that may be held in reserve that are derived from Transactions prior to
          funding you.
        </p>
        <p>
          b. Your Responsibilities: In addition to any other responsibilities set forth in this Addendum, you agree
          that, at all times throughout the term of this Addendum, you will (i) comply with data security and storage
          obligations in using and maintaining Data; (ii) maintain fraud and chargebacks rates acceptable under the
          Network Rules; (iii) review and understand the terms of this Addendum; and (iv) comply with all other Network
          Rules.
        </p>
        <p>
          The responsibilities listed above do not supersede terms of the Agreement and are provided to ensure you
          understand the important obligations of each party and that the member bank, BBVA, is the ultimate authority
          should you have any problems.
        </p>
      </div>
    </div>
  );
};

export default TermsPaysafe;
