import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldCheckbox from "../common/FieldCheckbox";
import FieldDate from "../common/FieldDate";
import FieldFile from "../common/FieldFile";
import FieldSelect from "../common/FieldSelect";
import FieldTextarea from "../common/FieldTextarea";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import Table from "../common/Table";
import ButtonClose from './ButtonClose';
import ButtonSave from "./ButtonSave";
import Modal from "./Modal";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

class ImportList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            enabledIntegratedBalance: false,
            import: {},
            triggeredBy: {},

            importFileKey: '',

            startDate: null,
            endDate: null,
            importType: null,

            importList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            sendWelcomeText: false,
            muteNotifications: false,

            importQuery: {
                orderBy: 'DESC',
                orderByFields: ['startDate'],
                conditionList: []
            },

            validationList: [],

        };

        this.searchImports = this.searchImports.bind(this);
        this.filterImports = this.filterImports.bind(this);

        this.viewImport = this.viewImport.bind(this);
        this.initImport = this.initImport.bind(this);
        this.saveImport = this.saveImport.bind(this);
        this.refreshImport = this.refreshImport.bind(this);

        this.downloadImportTemplate = this.downloadImportTemplate.bind(this);
        this.downloadImportFailures = this.downloadImportFailures.bind(this);
        this.downloadImportFile = this.downloadImportFile.bind(this);
        this.handleMuteNotificationsChange = this.handleMuteNotificationsChange.bind(this);

        this.downloadTransactions = this.downloadTransactions.bind(this);
        this.downloadTransactionsCSV = this.downloadTransactionsCSV.bind(this);
        this.downloadTransactionsPDF = this.downloadTransactionsPDF.bind(this);

    }

    /**
     * Load the list of imports on mounting of the component.
     */
    componentDidMount() {
        
        this.checkCompanyIntegration();

        if(!this.props.isManager) {

            const importQuery = this.state.importQuery;
            importQuery.conditionList.push({
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: this.props.companyId
            });

            this.setState((prevState)=> ({
                ...prevState,
                importQuery: importQuery,
            }), this.searchImports(1, 25, this.state.importQuery));
        } else {
            this.searchImports(1, 25, this.state.importQuery);
        }
    }

    /**
     * Makes an API call to spool company's integration data
     */
    checkCompanyIntegration() {
        if(this.props.companyId !== "LETUS"){
            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                if(response.data?.integrationId) {
                    axios.get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/integration/${response.data.integrationId}`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        this.setState(prevState => ({
                            ...prevState,
                            enabledIntegratedBalance: response.data.integratedBalance
                        }));
                    
                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Handle selecting an import record by bringing up the modal with import details shown.
     *
     * @param importId - The ID of the import record selected.
     */
    viewImport(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            const logWithoutHTMLTags = response.data?.log?.replace(/<[^>]*>/g, '')
            this.setState(prevState => ({
                ...prevState,
                import: {
                    ...response.data,
                    log: logWithoutHTMLTags,
                    file: ''
                }
            }));

            if(response.data.triggeredByType === 'TYPE_SYSTEM_ADMIN'){

                $(`#import-view-${this.props.importModel}`).modal('show');

                $(`#import-view-${this.props.importModel}`).on('shown.bs.modal', () => {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        triggeredBy: {

                            type: 'TYPE_SYSTEM_ADMIN'
                        }
                    }));
                });

            } else {

                axios.get(`${constants.REACT_APP_HOST_API_URL}/${response.data.triggeredByType.substring(5).toLowerCase()}/${response.data.triggeredById}/peek`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    $(`#import-view-${this.props.importModel}`).modal('show');

                    $(`#import-view-${this.props.importModel}`).on('shown.bs.modal', () => {
                        this.setState(prevState => ({
                            ...prevState,
                            spinner: false,
                            triggeredBy: response.data
                        }));
                    });

                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Refresh an import job record by re-fetching the record.
     *
     * @param importId - The ID of the import job record to refresh.
     */
    refreshImport(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                import: response.data
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an import object when the user clicks the appropriate button.
     */
    initImport() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                import: response.data,
                sendWelcomeText: false,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the import. Upon successful submission of the form, refresh the table and hide the
     * modal.
     *
     * @param event - The event container.
     */
    saveImport(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let params = this.state.import.file; 
        if(params && this.state.sendWelcomeText) {
            params.append("sendWelcomeText", true);
        }

        if(this.props.importModel === 'FILE_IMPORT') {
            let fileProcessPath = this.state.muteNotifications ? 'processbymutenotification' : 'process';
            axios.post(`${constants.REACT_APP_HOST_API_URL}/file_import/${this.props.companyId}/${this.state.import.importType}/${fileProcessPath}`, 
                params, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState({
                    spinner: false,
                    importFileKey: Date.now(),
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage
                        }
                    }],
                });

                this.searchImports(this.state.importList.page, this.state.importList.recordsPerPage, this.state.importQuery);

                $('#import-file').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(this.props.importModel === 'INTEGRATED_CHARGES_IMPORT') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/integrated_charges_import/${this.props.companyId}/process`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false,
                    importFileKey: Date.now(),
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage
                        }
                    }],
                });

                this.searchImports(this.state.importList.page, this.state.importList.recordsPerPage, this.state.importQuery);

                $('#import-integrated-charges').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter fields by adjusting the import search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterImports(event) {

        if (event != null) {
            event.preventDefault();
        }

        let importQuery = {
            orderBy: 'DESC',
            orderByFields: ['startDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
        };

        if(this.state.startDate !== null) {
            importQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'startDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            importQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'endDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        if(this.state.importType !== null && this.state.importType !== '') {
            importQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'importType',
                    operator: 'EQUALS',
                    fieldValue: this.state.importType
                },
            );
        }

        this.setState({
            importQuery: importQuery
        });

        this.searchImports(1, 25, importQuery);
    }

    /**
     * Download an import file template.
     *
     * @param importType - The import type to download the template file of.
     */
    downloadImportTemplate(importType) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importType}/template`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the failed records file associated with an import job.
     *
     * @param importId - The import job ID to download the failed records file of.
     */
    downloadImportFailures(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}/failedrecords`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the original import file associated with an import job.
     *
     * @param importId - The import job ID to download the original import file of.
     */
    downloadImportFile(importId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/${importId}/file`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all import jobs.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchImports(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        if(this.props.importModel === 'INTEGRATED_CHARGES_IMPORT') {
            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'type',
                    operator: 'EQUALS',
                    fieldValue: 'TYPE_INTEGRATED_CHARGES_IMPORT'
                },
            );
        }

        if(this.props.importModel === 'INTEGRATION_IMPORT') {
            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'type',
                    operator: 'EQUALS',
                    fieldValue: 'TYPE_INTEGRATION_IMPORT'
                },
            );
            // query.conditionList.push(
            //     {
            //         type: 'STRING',
            //         logicalOperator: 'AND',
            //         openBrackets: null,
            //         closeBrackets: null,
            //         fieldName: 'importType',
            //         operator: 'EQUALS',
            //         fieldValue: this.props.importType
            //     },
            // );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/${(this.props.importModel).toLowerCase()}/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                import: {},
                importList: response.data,
                importQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    handleMuteNotificationsChange(event){
        event.persist();

        this.setState(prevState =>({
            ...prevState,
            muteNotifications: !prevState.muteNotifications
        }));
    }

    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
    downloadTransactions(event) {
        event.preventDefault();
        $(`#import-view-${this.props.importModel}`).modal("hide");
        $(`#download-format-confirm-${this.props.importModel}`).modal("show");
    }

    /**
     * Download the transaction report in CSV format.
     */
     downloadTransactionsCSV() {
        const rows = this.state.import?.log?.split(/\n\s*\n/);
        if(!rows) return;
        const FileDownload = require('js-file-download');
        const data = this.getHeaderForCSV().concat(this.getBodyForExport(rows));
        let csvContent = data.map(e => e.join(",")).join("\n");
        FileDownload(csvContent, "integrated_import_info.csv");
    }

    /**
     * Get the header to be passed into CSV
     */
     getHeaderForCSV() {
        return [[
            'Property',
            'Property Name',
            'AccountID',
            'Tenant Name',
            'Unit',
            'Error'
        ]];
    }

    /**
     * Download the transaction report in PDF format.
     */
     downloadTransactionsPDF() {
        const rows = this.state.import?.log?.split(/\n\s*\n/);
        if(!rows) return;
        const dataTransformed = this.transformDataForPDF(rows);
        const doc = new jsPDF();

        doc.setFontSize(12);
        doc.text('integrated_import_info', 14, 10)
        
        doc.autoTable({
            head: [dataTransformed?.headerForPDF],
            body: dataTransformed?.bodyForPDF,
            horizontalPageBreak:false,
            theme: 'grid',
            headStyles:{
                fillColor: '#e9ecef',
                fontStyle: 'bold',
                textColor: '#212529',
                fontSize: '9',
            },
            bodyStyles:{
                textColor: '#212529',
                fontSize: '9',
                minCellHeight: '10',
                valign:'middle',
            }
        });
        doc.save('integrated_import_info.pdf')
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param rows - The data to be converted into PDF/CSV
     */
    transformDataForPDF(rows) {
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length > 0){
            headerForPDF = this.getHeaderForPDF();
            bodyForPDF = this.getBodyForExport(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
    }

    /**
     * Get the header to be passed into jsPDF
     */
    getHeaderForPDF() {
        return [
            'Property',
            'Property Name',
            'AccountID',
            'Tenant Name',
            'Unit',
            'Error'
        ];
    }

    /**
     * Get the body to be passed into jsPDF and CSV
     *
     * @param rows - The rows to be converted into PDF/CSV.
     */
    getBodyForExport(rows) {
        let body = [];
        let rowAux = [];
        
        rows.forEach((row, i) => {
            rowAux = row.split("\r\n");
            rowAux.forEach((element, i) => {
                rowAux[i] = element.split(/: (.+)/)[1];
            });
            if(rowAux.length && rowAux[0] !== '' && i+1 < rows.length) 
                body.push(rowAux);
        })
        return body;
    }

    /**
     * Render the component.
     *
     * @returns {*} - The import interface.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip collapse"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card accordion">
                    <div className="card-header">
                        <div className="row align-items-center">
                            {
                                this.props.isDropdown ? (
                                    <div className="c-pointer col collapsed" data-toggle="collapse" data-target={`#${this.props.importModel}`} aria-expanded="true" aria-controls={this.props.importModel}>
                                        <i className="fas mr-2"></i>
                                        {this.props.importModel === 'INTEGRATION_IMPORT' ? 'Automatic Imports' : 'Imports'}
                                    </div>
                                ) : (
                                    <div className="col">
                                        {this.props.importModel === 'INTEGRATION_IMPORT' ? 'Automatic Imports' : 'Imports'}
                                    </div>
                                )
                            }
                            <div className="col text-right">

                                {this.props.importModel === 'FILE_IMPORT' &&
                                <div className="btn-group" role="group">
                                    <button id="btnGroupDrop1" type="button" className="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Template
                                    </button>
                                    <div className="dropdown-menu gotham" aria-labelledby="btnGroupDrop1">

                                        {this.props.companyId === 'LETUS' &&
                                        <React.Fragment>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('BANK_INFO')}>Bank Info</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('BIN')}>BIN Mapping</div>
                                        </React.Fragment>
                                        }

                                        {this.props.companyId !== 'LETUS' &&
                                        <React.Fragment>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('TENANT')}>Tenant</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('PROPERTY')}>Property</div>
                                            <div className="dropdown-item c-pointer" onClick={() => this.downloadImportTemplate('OPEN_CHARGES')}>Open Charges</div>
                                        </React.Fragment>
                                        }

                                    </div>
                                </div>
                                }

                                {this.props.importModel === 'FILE_IMPORT' &&
                                <div data-toggle="modal" data-target="#import-file" className="btn btn-primary btn-sm ml-2" onClick={() => this.initImport()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> New Import
                                </div>
                                }

                                {this.props.importModel === 'INTEGRATED_CHARGES_IMPORT' &&
                                    <div data-toggle= {this.state.enabledIntegratedBalance && 'modal' }
                                         data-placement="bottom" 
                                         title={!this.state.enabledIntegratedBalance && "Integrated Balance is disabled. Please contact Customer Support to enable it"}
                                         data-target="#import-integrated-charges" 
                                         disabled={this.state.enabledIntegratedBalance}
                                         className={"btn btn-" + (this.state.enabledIntegratedBalance ? "primary" : "secondary") + " btn-sm ml-2"} onClick={() => this.initImport()}>
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Bulk Import Charges
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className={this.props.isDropdown ? "card-body card-body-list collapse" : ""} id={this.props.importModel} aria-labelledby={this.props.importModel}>
                        {this.props.searchFilters &&
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterImports} autoComplete="off">
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldDate id="startDate" labelColumns="0"
                                                    fieldColumns="12" parent={this} value={this.state.startDate}
                                                    selectsRange={true} startDate={this.state.startDate}
                                                    endDate={this.state.endDate}/>
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="importType" placeholder="Import Type" labelColumns="0"
                                                    fieldColumns="12" parent={this} value={this.state.importType}>

                                            <option value="">All Import Types</option>

                                            {this.props.companyId === 'LETUS' &&
                                            <React.Fragment>
                                                <option value="BANK_INFO">Bank Info</option>
                                                <option value="BIN">BIN Mapping</option>
                                            </React.Fragment>
                                            }

                                            {this.props.companyId !== 'LETUS' &&
                                            <React.Fragment>
                                                <option value="TENANT">Tenant</option>
                                                <option value="PROPERTY">Property</option>
                                                <option value="OPEN_CHARGES">Open Charges</option>
                                                <option value="INTEGRATED_CHARGES">Integrated Charges</option>
                                            </React.Fragment>
                                            }

                                        </FieldSelect>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        }
                        <Table columns={{type: 'Type', startDate: 'Start Date', endDate: 'End Date', totalRecords: 'Records', processedRecords: 'Processed', ignoredRecords: 'Ignored', failedRecords: 'Failed'}}
                            columnWidths={['14%', '14%', '14%', '14%', '14%', '14%', '14%']}
                            headerClass="c-pointer"
                            data={this.state.importList}
                            query={this.state.importQuery}
                            sortEnabled={true}
                            recordsEnabled={true}
                            paginationEnabled={true}
                            updateFunction={this.searchImports}>
                            <tbody>
                            {this.state.importList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewImport(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"enum.imports.importType." + data.importType} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.startDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {data.startDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.endDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {data.endDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.totalRecords}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success" /> {data.processedRecords}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" /> {data.ignoredRecords}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger" /> {data.failedRecords}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>
                </div>

                <div className="modal fade" id={`import-view-${this.props.importModel}`} tabIndex="-1" role="dialog" aria-labelledby="import-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveImport}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="import-label">
                                        <FormattedMessage id={"enum.imports.importType." + this.state.import.importType}/> Import
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body">
                                    <div className="progress">
                                        <div className={`progress-bar bg-danger ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.failedRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.failedRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.failedRecords / this.state.import.totalRecords) * 100).toFixed(2)}% FAILED</div>
                                        <div className={`progress-bar bg-warning ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.ignoredRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.ignoredRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.ignoredRecords / this.state.import.totalRecords) * 100).toFixed(2)}% IGNORED</div>
                                        <div className={`progress-bar bg-success ${this.state.import.endDate ? '' : 'progress-bar-striped progress-bar-animated'}`} role="progressbar" style={{width: ((this.state.import.processedRecords / this.state.import.totalRecords) * 100) + '%', ariaValuenow: (this.state.import.processedRecords % this.state.import.totalRecords), ariaValuemin: 0, ariaValuemax: 100}}>{((this.state.import.processedRecords / this.state.import.totalRecords) * 100).toFixed(2)}% PROCESSED</div>
                                    </div>
                                </div>
                                <div className="modal-body modal-body-table">
                                    <table className="table mb-0 border-bottom">
                                        <tbody>
                                        <tr className="small">
                                            <td className="">
                                                Import Type
                                            </td>
                                            <td className="text-right">
                                                <FormattedMessage id={"enum.imports.importType." + this.state.import.importType}/>
                                            </td>
                                        </tr>
                                        {this.state.import.importFile &&
                                        <tr className="small">
                                            <td className="">
                                                Import File
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.importFile.replace(/^.*[\\/]/, '')}
                                            </td>
                                        </tr>
                                        }
                                        <tr className="small">
                                            <td className="">
                                                Triggered By
                                            </td>
                                            <td className="text-right">
                                                {this.state.triggeredBy.type === 'TYPE_SYSTEM_ADMIN' &&
                                                <div>
                                                    <FormattedMessage id={"enum.record." + this.state.triggeredBy.type} />
                                                </div>
                                                }
                                                {this.state.triggeredBy.type !== 'TYPE_SYSTEM_ADMIN' &&
                                                <div>
                                                    {this.state.triggeredBy.type === 'TYPE_API_ACCOUNT' ? this.state.triggeredBy.apiUser : this.state.triggeredBy.firstName + ' ' + this.state.triggeredBy.lastName}
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Start Date
                                            </td>
                                            <td className="text-right">
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.import.startDate}
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                End Date
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.endDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.import.endDate}
                                                </Moment>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Total Records
                                            </td>
                                            <td className="text-right">
                                                {this.state.import.totalRecords}
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Processed Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success"/> {this.state.import.processedRecords} ({(this.state.import.processedRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Ignored Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning"/> {this.state.import.ignoredRecords} ({(this.state.import.ignoredRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Failed Records
                                            </td>
                                            <td className="text-right">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger"/> {this.state.import.failedRecords} ({(this.state.import.failedRecords / this.state.import.totalRecords * 100).toFixed(2)}%)
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="modal-body">

                                    <FieldTextarea id="log" label="Log" labelClass="d-none" fieldColumns="12" labelColums="0" rows="10" model="import" parent={this} value={this.state.import['log']}/>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $(`#import-view-${this.props.importModel}`).modal("hide")}>Close</button>
                                        </div>
                                        {
                                            this.state.import?.importType === "INTEGRATED_CHARGES" &&
                                                <div className="col-8 text-right">
                                                    <div className="btn btn-primary btn-lg" onClick={(event) => this.downloadTransactions(event)}>
                                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                                    </div>
                                                </div>
                                        }
                                        {((this.state.import.endDate && this.props.importModel === 'FILE_IMPORT') || (this.state.import.startDate && !this.state.import.endDate)) &&
                                            <div className={`col-${this.state.import?.importType === "INTEGRATED_CHARGES" ? '12 mt-2' : '8'} text-right`}>
                                                <div className="dropdown">

                                                    <div className="btn btn-primary btn-lg dropdown-toggle" role="button"
                                                        id="payment-transaction-actions" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false">
                                                        Actions
                                                    </div>

                                                    <div className="dropdown-menu"
                                                        aria-labelledby="payment-transaction-actions">

                                                        {(this.state.import.endDate && this.props.importModel === 'FILE_IMPORT') &&
                                                        <React.Fragment>

                                                            {this.state.import.failedRecords > 0 &&
                                                            <div className="dropdown-item c-pointer"
                                                                onClick={() => this.downloadImportFailures(this.state.import.id)}>
                                                                <FontAwesomeIcon icon={['fas', 'arrow-to-bottom']}
                                                                                className="fa-fw"/> Download Failures
                                                            </div>
                                                            }

                                                            <div className="dropdown-item c-pointer"
                                                                onClick={() => this.downloadImportFile(this.state.import.id)}>
                                                                <FontAwesomeIcon icon={['fas', 'file-download']}
                                                                                className="fa-fw"/> Download Original
                                                                Import
                                                            </div>

                                                        </React.Fragment>
                                                        }

                                                        {(this.state.import.startDate && !this.state.import.endDate) &&
                                                        <React.Fragment>

                                                            <div className="dropdown-item c-pointer"
                                                                onClick={() => this.refreshImport(this.state.import.id)}>
                                                                <FontAwesomeIcon icon={['fas', 'sync-alt']}
                                                                                className="fa-fw"/> Refresh
                                                            </div>

                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="import-file" tabIndex="-1" role="dialog" aria-labelledby="import-file-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveImport}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="import-file-label">
                                        New Import
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldSelect id="importType" label="Type" model="import" required={true} labelClass="col-form-label-md" fieldClass="form-control-md" parent={this} value={this.state.import['importType']}>

                                        <option value="" disabled>Select an import type...</option>

                                        {this.props.companyId === 'LETUS' &&
                                        <React.Fragment>
                                            <option value="BANK_INFO">Bank Info</option>
                                            <option value="BIN">BIN Mapping</option>
                                        </React.Fragment>
                                        }

                                        {this.props.companyId !== 'LETUS' &&
                                        <React.Fragment>
                                            <option value="TENANT">Tenant</option>
                                            <option value="PROPERTY">Property</option>
                                            <option value="OPEN_CHARGES">Open Charges</option>
                                        </React.Fragment>
                                        }

                                    </FieldSelect>

                                    <FieldFile key={this.state.importFileKey} id="file" required={true} label="File*" help="Accepted file types: .csv, .txt." model="import" parent={this} value={this.state.import['file']}/>
                                    
                                    {this.state.import?.importType === 'TENANT' && 
                                        <>
                                            <FieldCheckbox 
                                                id="muteNotifications" 
                                                name="muteNotifications" 
                                                fieldLabel="Send welcome email to all tenants †" 
                                                fieldColumns="abs-right"
                                                parent={this} 
                                                handleChange={this.handleMuteNotificationsChange}
                                                value={!this.state.muteNotifications} />
                                            <FieldCheckbox
                                                id="sendWelcomeText"
                                                name="sendWelcomeText"
                                                fieldLabel="Send Txt2Pay welcome to all tenants"
                                                fieldColumns="abs-right"
                                                parent={this}
                                                value={this.state.sendWelcomeText}/>
                                        </>
                                    }
                                    {this.state.import?.importType === 'TENANT' && 
                                        <figcaption className="text-right mt-1">
                                            <small className="text-muted font-italic">† all other system emails will still be sent if unchecked</small>
                                        </figcaption>
                                    }
                                    <figcaption className="text-right mt-1">
                                        <small className="text-muted font-italic">* indicates a required field</small>
                                    </figcaption>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-4">
                                            <ButtonClose />
                                        </div>
                                        <div className="col-8 text-right">
                                            <ButtonSave disabled={!this.state.import?.importType || !this.state.import?.file} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.props.importModel === 'INTEGRATED_CHARGES_IMPORT' &&
                    <Modal id="import-integrated-charges" theme="primary" iconType="fas" iconName="question-circle" title="Bulk Import Charges"
                        body="Are you sure you would like to import all tenant charges from your integrated system? If a tenant has existing charges associated with their Letus account, they may be overwritten as a result.">
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            <FormattedMessage id="button.close" />
                        </button>
                        <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.saveImport()}>
                            Import Charges
                        </button>
                    </Modal>
                }
                <Modal  id={`download-format-confirm-${this.props.importModel}`} theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadTransactionsCSV()}>
                            CSV Format
                        </button>
                        <button onClick={() => this.downloadTransactionsPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            PDF Format
                        </button>
                </Modal>   
            </React.Fragment>
        )
    };
}

export default ImportList;