import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";

const FieldFile = (props) => {
    const [fileName, setFileName] = useState('');

    const handleChange = (event) => {

        const newFileName = event.target.files[0]?.name ? event.target.files[0].name : 'Choose a file...';
        const data = new FormData();

        data.append('file', event.target.files[0], event.target.files[0]?.name);

        setFileName(newFileName)

        props.handleChange(data)
    }

    let isInvalid = props.isInvalid || false;
    let errorCode = props.errorCode || '';

    return(
        <div className="form-group row">
            <label className={`col-sm-${props.labelColumns} col-form-label ${props.labelClass}`} htmlFor={props.id}>
                {props.label}
            </label>
            <div className={`col-sm-${props.fieldColumns}`}>
                <div className="input-group">
                    <div className="custom-file">
                        <input
                            type="file"
                            id={props.id}
                            name={props.id}
                            onChange={handleChange}
                            accept={props.accept}
                            data-max-size={props.dataMaxSize}
                            disabled={props.disabled}
                            className={`form-control custom-file-input ${props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}
                        />
                        <label className="custom-file-label" htmlFor="inputGroupFile04">{fileName}</label>
                    </div>
                </div>

                {props.help &&
                <small className="form-text text-muted">
                    {props.help}
                </small>
                }

                {isInvalid &&
                <div className="invalid-feedback">
                    <FormattedMessage id={"danger." + errorCode} />
                </div>
                }

            </div>

        </div>
    )
}

FieldFile.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-md',
    fieldClass: 'form-control-md',
    type: 'text',
    maxLength: '100'
};

export default FieldFile;
