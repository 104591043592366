import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import familyMoving from "../media/img/backgrounds/couple-laptop.jpg";
import Propertii from "./common/Propertii";

class Signup extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A simple signup component that redirects the user to their respective landing page with additional
     * information.
     */
    render() {

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent">

                                    <h1 className="display-5 mb-3">
                                        Open your door to better renting with Letus
                                    </h1>

                                    <p className="mb-5">
                                        Letus gives property managers the tools they need to create a better rental experience. Select the option that best describes you below to learn more.
                                    </p>

                                    <div className="row">
                                        <div className="col text-right">
                                            <Link to="/landlords" className="btn btn-lg btn-primary btn-block mb-0">
                                                I am a Property Manager
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <div className="row">
                                        <div className="col text-right">
                                            <Link to="/tenants" className="btn btn-lg btn-primary btn-block mb-0">
                                                I am a Tenant
                                            </Link>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "725px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

}

Signup.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Signup);