/*
Checks if a JSON object is empty:
'obj = {}' -> Returns true, else false
*/

module.exports = function(obj){
    if (obj.constructor === Object && Object.keys(obj).length === 0){
        return true;
    } else {
        return false;
    }
};