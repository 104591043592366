import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/HyperWalletNavigation.module.css';
import logo from '../../../media/img/logos/logo-letus-hyperwallet.png';

const HyperWalletNavigation = () => {
  return (
    <>
      <nav className={`navbar navbar-expand-lg justify-content-between ${styles.navCustom}`}>
        <button
          className='navbar-toggler'
          type='button'
          data-toggle='collapse'
          data-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon d-flex align-items-center'>
            <i className='fas fa-bars'></i>
          </span>
        </button>
        <a className={`nav-link py-0 mt-1 ${styles.mobileLogo}`} href='https://www.staging.com'>
          <img src={logo} height='25' className='d-inline-block align-top' alt='Letus Logo' />
        </a>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <div className={`container-xl ${styles.containerCustom}`}>
            <div className={`row ${styles.rowCustom}`}>
              <div className='col-sm-6 col-md-5'>
                <ul className={`navbar-nav ${styles.navSec1}`}>
                  <li className='nav-item dropdown'>
                    <button
                      className={`nav-link btn btn-link ${styles.navText} dropdown-toggle`}
                      id='navbarDropdownSP'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Send Payment
                    </button>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <Link to='/pay/marketplace/payer' className={`dropdown-item`}>
                        <span className={`${styles.navText}`}>How it works</span>
                      </Link>
                      <Link to='/pay/marketplace/payer/faq' className={`dropdown-item`}>
                        <span className={`${styles.navText}`}>FAQ</span>
                      </Link>
                    </div>
                  </li>
                  <li className='nav-item dropdown'>
                    <button
                      className={`nav-link btn btn-link ${styles.navText} dropdown-toggle`}
                      id='navbarDropdownRP'
                      data-toggle='dropdown'
                      aria-haspopup='true'
                      aria-expanded='false'
                    >
                      Receive Payment
                    </button>
                    <div className='dropdown-menu' aria-labelledby='navbarDropdown'>
                      <Link to='/pay/marketplace/payee' className={`dropdown-item`}>
                        <span className={`${styles.navText}`}>How it works</span>
                      </Link>
                      <Link to='/pay/marketplace/payee/faq' className={`dropdown-item`}>
                        <span className={`${styles.navText}`}>FAQ</span>
                      </Link>
                    </div>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${styles.navText}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://help.rentmoola.com/hc/en-us'
                    >
                      Support
                    </a>
                  </li>
                </ul>
              </div>
              <div className={`col-sm-0 col-md-2 ${styles.navSec2Container}`}>
                <a className={`nav-link py-0 ${styles.navSec2}`} href='/pay/marketplace'>
                  <img src={logo} height='50' className='d-inline-block align-top' alt='Letus Logo' />
                </a>
              </div>
              <div className='col-sm-6 col-md-5'>
                <ul className={`navbar-nav ${styles.navSec3}`}>
                  <li className='nav-item'>
                    <a
                      className={`nav-link ${styles.navText}`}
                      target='_blank'
                      rel='noopener noreferrer'
                      href='https://help.rentmoola.com/hc/en-us'
                    >
                      Contact Us
                    </a>
                  </li>
                  <li className='nav-item'>
                    <Link
                      to='/pay/marketplace'
                      className={`btn btn-secondary btn-sm rounded text-uppercase ${styles.btnGetStarted}`}
                    >
                      GET STARTED
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default HyperWalletNavigation;
