import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'react-moment';
import Table from '../Table';

const NotesTable = ({ notesList, searchNotes, openNote }) => {
  return (
    <Table
      columns={{
        createDate: 'Date',
        createdBy: 'Created By',
        ticketNumber: 'Ticket',
        note: 'Note',
      }}
      columnWidths={['10%', '10%', '10%', '25%']}
      headerClass='c-pointer'
      data={notesList}
      recordsEnabled={true}
      paginationEnabled={true}
      updateFunction={searchNotes}
    >
      <tbody>
        {notesList.records?.map((data, key) => {
          return (
            <tr key={key} className='c-pointer' onClick={() => openNote(data)}>
              <td>{data.createDate && <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>}</td>
              <td>
                <div>{data.createdBy}</div>
              </td>
              <td>
                <div className='text-break'>
                  <a
                    href={`https://rentmoola.zendesk.com/agent/tickets/${data.ticketNumber}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {data.ticketNumber}
                  </a>
                </div>
              </td>
              <td>
                <div className='text-break'>{data.note}</div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

NotesTable.propTypes = {
  notesList: PropTypes.object,
  searchNotes: PropTypes.func,
  openNote: PropTypes.func,
};

export default NotesTable;
