import moment from 'moment';

/* o----------------------------------------------------------------------------------------------------------------o
   | Time Zone Conversions                                                                                          |
   o----------------------------------------------------------------------------------------------------------------o */

/**
 * convert local time to another timezone
 * @param {*} localDt
 * @param {*} localDtFormat
 * @param {*} timezone
 */
export function convertLocalToTimezone(localDt, localDtFormat, timezone) {
  return moment(localDt, localDtFormat).tz(timezone).format(localDtFormat);
}

/**
 * convert local time to UTC time
 * @param {*} dt
 * @param {*} dtFormat
 */
export function convertToUTC(dt, dtFormat) {
  return moment(dt, dtFormat).utc().format(dtFormat);
}

/**
 * convert utc time to local time
 * @param {*} utcDt
 * @param {*} utcDtFormat, eg = 'YYYY-MM-DD[T]HH:mm'
 */
export function convertUTCToLocal(utcDt, utcDtFormat) {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate();
  return moment(toDt).format(utcDtFormat);
}

/**
 * convert a date from particulat time zone to the local time zone
 * @param {*} dt
 * @param {*} dtFormat, eg = 'YYYY-MM-DD[T]HH:mm'
 * @param {*} currentTz
 */
export function convertToLocalTz(dt, dtFormat, currentTz) {
  const dtCurrentTz = moment.tz(dt, dtFormat, currentTz).format();
  const dtFormatWithTz = `${dtFormat}:ssZ`;
  return moment(dtCurrentTz).format(dtFormatWithTz);
}

/**
 * convert utc time to another timezone
 * @param {*} utcDt
 * @param {*} utcDtFormat, eg = 'YYYY-MM-DD[T]HH:mm'
 * @param {*} timezone, eg - 'America/Los_Angeles'
 * @param {*} resultFormat
 */
export function convertUTCToTimezone(utcDt, utcDtFormat, timezone, resultFormat) {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(resultFormat);
}

/**
 * add a timezone to the date
 * @param {*} dt
 * @param {*} dtFormat, eg = 'YYYY-MM-DD[T]HH:mm'
 * @param {*} timezone, eg - 'America/Los_Angeles'
 * @param {*} resultFormat
 */
export function addTimeZone(dt, dtFormat, timezone, resultFormat) {
  return moment.tz(dt, dtFormat, timezone).format(resultFormat);
}

/**
 * For PAD we are always going to return first of the month
 * @param {*} date
 */
export function parseDateForPad(date) {
  let padDate = date;
  const upcomingPadDay = moment(date).format('D');

  if (upcomingPadDay > 20 && upcomingPadDay <= 31) {
    //it is safe to assume that we need the next month
    const newDate = moment(date).add(1, 'M');
    padDate = moment(newDate).startOf('month');
  } else if (upcomingPadDay < 10) {
    padDate = moment(padDate).startOf('month');
  }

  return padDate;
}

export function convertDateToJavaFriendlyFormat(date) {
  const javaFormat = 'YYYY-MM-DDTHH:mm:ssZZ';
  return moment(date).format(javaFormat);
}

/* o----------------------------------------------------------------------------------------------------------------o
   | Time Zone Conversions End                                                                                      |
   o----------------------------------------------------------------------------------------------------------------o */

/**
 * Get the min year and month in the format yyyy-mm for the CC expiry date field
 */
export const minCCDateAndMonth = () => {
  const currentTime = new Date();
  const month = currentTime.getMonth() + 1;
  const year = currentTime.getFullYear();

  return `${year}-${month < 10 ? '0' + month : month}`;
};

/**
 * convert a date to the specified date format
 * @param {string} dt 
 * @param {string} dtFormat 
 */
export const formatDate = (dt, dtFormat) => {
  return moment(dt).format(dtFormat)
}