import React, { Component } from 'react';
import FieldSelect from './FieldSelect';
import 'react-day-picker/lib/style.css';
import AutoPaymentDateCollection from '../../constants/AutoPaymentDates';
import { FormattedMessage } from 'react-intl';

class FieldDateTimeChargesScheduler extends Component {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      from: undefined,
      to: undefined,
    };

    if (this.props.parent.handleChange) {
      this.handleChange = this.props.parent.handleChange.bind(this);
    } else {
      this.handleChange = this.handleChange.bind(this);
    }
  }

  /**
   * Handle change events on date fields. Convert the date to the format accepted by the server.
   *
   * @param date - The selected date value.
   */
  handleChange(event) {
    let field;

    if (this.props.model != null) {
      field = this.props.parent.state[this.props.model];
      if (this.props.arrayIndex || this.props.arrayIndex === 0) {
        field[this.props.arrayIndex][event.target.name] = event.target.value;
      } else {
        field[event.target.name] = event.target.value;
      }
    }

    if (this.props.model == null) {
      field = event.target.value;
    }

    if (this.props.arrayIndex || this.props.arrayIndex === 0) {
      this.props.parent.setState((prevState) => ({
        ...prevState,
        [this.props.model[this.props.arrayIndex]]: field,
      }));
    } else {
      this.props.parent.setState({
        [this.props.model ? this.props.model : event.target.name]: field,
      });
    }
  }

  /**
   * Filter out specific days from selection on the datepicker.
   *
   * @param date - The date object from the datepicker.
   * @returns {boolean} - True if the date is selectable from the datepicker.
   */
  filterDate = (date) => {
    const day = date.getDate();

    // Filter the available selections from the datepicker to only the first of each month
    if (this.props.filterDateType === 'FIRST_DATE') {
      return day === 1;
    }

    // Filter the available selections from the datepicker to dates before the monthly payment due day
    if (this.props.filterDateType === 'MONTHLY_PAYMENT_DUE_DAY') {
      return this.props.monthlyPaymentDueDay >= date.getDate();
    }

    return false;
  };

  /**
   * Render the component.
   *
   * @returns {*} - A generic date time field component. When clicked on, a date picker appears, which utilizes the
   * external react-datepicker library.
   */
  render() {
    let isInvalid = false;
    let errorCode = '';

    Object.entries(this.props.parent.state.validationList).forEach(
      ([key, validation]) => {
        if (validation.fields) {
          Object.entries(validation.fields).forEach(([key, fieldError]) => {
            if (key === this.props.id) {
              isInvalid = true;
              errorCode = fieldError;
            }
          });
        }
      }
    );

    return (
      <React.Fragment>
        <div className={`form-group row ${this.props.containerClass}`}>
          <label
            className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`}
            htmlFor={this.props.id}
          >
            {this.props.label}{' '}
            {this.props.optional && (
              <small className='text-muted font-italic'>(Optional)</small>
            )}{' '}
            {this.props.required && (
              <small className='text-muted font-italic'>*</small>
            )}
          </label>

          <div className={`col-sm-${this.props.fieldColumns}`}>
            {this.props.selectsRange === undefined && (
              <div style={{ maxHeight: 50 }}>
                <FieldSelect
                  id={this.props.idOpenDate}
                  label=''
                  model={this.props.model}
                  arrayIndex={this.props.arrayIndex}
                  parent={this.props.parent}
                  value={this.props.valueOpenDate}
                  disabled={!this.props.active}
                  fieldColumns={12}
                  containerClass='d-inline-block w-30 mb-0'
                >
                  <option value={null}>No date selected</option>
                  {AutoPaymentDateCollection.map((date) => (
                    <option key={date.text} value={date.value}>
                      {date.text}
                    </option>
                  ))}
                </FieldSelect>
                <FieldSelect
                  id={this.props.idCloseDate}
                  label=''
                  model={this.props.model}
                  arrayIndex={this.props.arrayIndex}
                  parent={this.props.parent}
                  value={this.props.valueCloseDate}
                  disabled={!this.props.active}
                  fieldColumns={12}
                  containerClass='d-inline-block w-30 mb-0'
                >
                  <option value={null}>No date selected</option>
                  {AutoPaymentDateCollection.map((date) => (
                    <option key={date.text} value={date.value}>
                      {date.text}
                    </option>
                  ))}
                </FieldSelect>
                <div className='DayPickerInput'>
                  <input
                    type='time'
                    id={this.props.idOpenTime}
                    name={this.props.idOpenTime}
                    className={`form-control gotham ${this.props.fieldClass} ${
                      !this.props.active ? 'DisabledInputField' : ''
                    } ${isInvalid ? 'is-invalid' : ''}`}
                    onChange={(event) => this.handleChange(event)}
                    value={
                      this.props.valueOpenTime == null
                        ? ''
                        : this.props.valueOpenTime
                    }
                  ></input>
                </div>
                <style>{`
                        .DayPickerInput:not(.DayPickerInput > .InputField):not(.DayPickerInput > .InputFromTo-to):not(.DayPickerInput > .InputFromTo){
                            width: ${
                              !!this.props.width ? this.props.width : '115px'
                            };
                            background-color: #fff;
                            `}</style>
                <input
                  type='checkbox'
                  data-toggle='toggle'
                  onClick={() => {
                    this.props.handleRemove();
                  }}
                  checked={this.props.active}
                  className='w-10 h-30 ml-1'
                />
              </div>
            )}

            {isInvalid && (
              <div className='small text-danger mt-1'>
                <FormattedMessage id={'danger.' + errorCode} />
              </div>
            )}

            {this.props.help && (
              <small className='form-text text-muted'>{this.props.help}</small>
            )}

            {this.props.infoCode && (
              <small className='form-text text-muted'>
                <FormattedMessage id={'help.' + this.props.infoCode} />
              </small>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FieldDateTimeChargesScheduler.defaultProps = {
  labelColumns: '3',
  fieldColumns: '9',
  labelClass: 'col-form-label-sm',
  fieldClass: 'form-control-sm',
  monthsShown: 1,
  dateFormat: 'MM/dd/yyyy',
};

export default FieldDateTimeChargesScheduler;
