import React from "react";
import { injectIntl, intlShape } from "react-intl";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        return(
            <React.Fragment>

                <TransactionList companyId={this.props.match.params.companyId}
                                 displayFeeAmount={true}
                                 displayAdvanced={true}
                                 history={this.props.history}
                                 textSearchPlaceholderOverride = "Search Company Transactions"
                                 textSearchPlaceholderOverridescheduledPayment = "Search Scheduled Payments"
                                 userType="TYPE_ADMIN" />

            </React.Fragment>
        )
    };
}
Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);