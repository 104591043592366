import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldPhone from "../../common/FieldPhone";
import FieldText from "../../common/FieldText";
import FieldTextarea from "../../common/FieldTextarea";
import Propertii from "../../common/Propertii";

class Landlord extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            company: null,
            validationList: [],
        };

        this.getLandlordWithCompany = this.getLandlordWithCompany.bind(this);
        this.saveLandlordAndCompany = this.saveLandlordAndCompany.bind(this);
    }

    /**
     * Retrieve the landlord based on the ID provided in the route.
     */
    componentDidMount() {

        this.getLandlordWithCompany(this.props.match.params.landlordId);
    }

    /**
     * Retrieves the landlord and landlord's company.
     *
     * @param landlordId - The ID of the landlord to fetch data from.
     */
    getLandlordWithCompany(landlordId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));

            if(response.data.companyId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${response.data.companyId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        company: response.data
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Save the landlord and landlord's company information.
     *
     * @param event - The event container.
     */
    saveLandlordAndCompany(event) {

        event.preventDefault();

        let saveListData = [];

        saveListData.push(this.state.company);
        saveListData.push(this.state.landlord);

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });

            this.getLandlordWithCompany(this.state.landlord.id);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit landlord component for admins.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveLandlordAndCompany}>

                    <div className="card">
                        <div className="card-header">
                            Landlord Personal Details
                        </div>
                        <div className="card-body">
                            <FieldText id="firstName" label="First Name" model="landlord" parent={this} value={this.state.landlord['firstName']} />

                            <FieldText id="lastName" label="Last Name" model="landlord" parent={this} value={this.state.landlord['lastName']} />

                            <FieldBirthDate id="birthDate" label="Birth Date" model="landlord" parent={this} required={true} monthFirst={true} value={this.state.landlord['birthDate']}/>

                            <FieldText id="email" label="Email" type="email" model="landlord" parent={this} value={this.state.landlord['email']} />

                            <FieldPhone id="phone" label="Phone" model="landlord" parent={this} value={this.state.landlord['phone']} />

                            <FieldText id="sin" label="Sin" model="landlord" parent={this} required={true} value={this.state.landlord['sin']} />

                        </div>
                    </div>
                    {this.state.company != null &&
                        <div className="card">
                            <div className="card-header">
                                Landlord Company Details
                            </div>
                            <div className="card-body">

                                <FieldText id="id" label="Company ID" disabled={true} model="company" parent={this} value={this.state.company['id']} />

                                <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']} />

                                <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']} />

                                <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']} />

                                <FieldPhone id="phone" label="Business Phone" model="company" parent={this} value={this.state.company['phone']} />

                                <FieldText id="taxNumber" label="Tax ID" model="company" parent={this} value={this.state.company['taxNumber']} />

                                <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} value={this.state.company['inceptionDate']} monthFirst={true}/>

                                <FieldTextarea id="description" label="Description" model="company" parent={this} value={this.state.company['description']} />

                            </div>
                        </div>
                    }

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

Landlord.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landlord);