import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from "react-chartjs-2";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import FieldDate from "../../common/FieldDate";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

ChartDataLabels.toString();

class Processing extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            startDate: '',
            endDate: '',
            processingTotals: null,
            validationList: [],
        };

        this.filterProcessing = this.filterProcessing.bind(this);
    }

    /**
     * Filter the processing KPIs by providing a start and end date.
     *
     * @param event - The event container.
     */
    filterProcessing(event) {

        event.preventDefault();

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/stats/processing_kpi`, {
          fromDate: this.state.startDate,
          toDate: this.state.endDate
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                processingTotals: response.data.processingTotals
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The admin processing key performance indicators.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Spinner visible={this.state.spinner} />

                    <Breadcrumb parentPath="/admin/dashboard" parentPage="Dashboard" childPage="Processing" />

                    <div className="card">
                        <div className="card-header">
                            Processing
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterProcessing} autoComplete="off">
                                <div className="media">
                                    <div className="media-body align-self-center">
                                        <FieldDate id="startDate" labelColumns="0"
                                                           fieldColumns="12" parent={this} value={this.state.startDate}
                                                           selectsRange={true} startDate={this.state.startDate}
                                                           endDate={this.state.endDate}/>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {!this.state.processingTotals &&
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="text-center text-secondary">
                                        <FontAwesomeIcon icon={['fas', 'chart-pie']} className="fa-fw mb-4" size="5x"/>
                                    </div>
                                    <div className="text-center text-muted">
                                        <small>
                                            To fetch processing totals, determine your date range using the above fields.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {this.state.processingTotals &&
                        <div className="card-body">

                            <div className="row">
                                <div className="col-md-6">

                                    <p className="lead mb-0">Gross Processing Volume</p>
                                    <p className="text-muted">By Payment Type</p>

                                    <div className="chart">
                                        <Doughnut
                                            options={{
                                                plugins: {
                                                    datalabels: {
                                                        formatter: (value, ctx) => {

                                                            let sum = 0;
                                                            let dataArray = ctx.chart.data.datasets[0].data;

                                                            dataArray.forEach(data => {
                                                                sum += data;
                                                            });

                                                            let percentage = (value*100 / sum).toFixed(2);

                                                            if(percentage > 1) {
                                                                return percentage + "%";
                                                            }

                                                            return '';

                                                        },
                                                        color: '#fff',
                                                    }
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 25,
                                                        right: 25,
                                                        top: 10,
                                                        bottom: 25
                                                    }
                                                },
                                                legend: {
                                                    labels: {
                                                        boxWidth: 15,
                                                    }
                                                },
                                                cutoutPercentage: 0
                                            }}
                                            data={{
                                                labels: ['Visa', 'Visa Electron', 'MasterCard', 'Maestro', 'American Express', 'Discover', "Diner's Club", 'JCB', 'UnionPay', 'Bank Account', 'Cash', 'Paypal'],
                                                datasets: [
                                                    {
                                                        data: [
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.VISA ? this.state.processingTotals.TYPE_CREDIT_CARD.VISA.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.VISA_ELECTRON ? this.state.processingTotals.TYPE_CREDIT_CARD.VISA_ELECTRON.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.MASTERCARD ? this.state.processingTotals.TYPE_CREDIT_CARD.MASTERCARD.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.MAESTRO ? this.state.processingTotals.TYPE_CREDIT_CARD.MAESTRO.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.AMERICAN_EXPRESS ? this.state.processingTotals.TYPE_CREDIT_CARD.AMERICAN_EXPRESS.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.DISCOVER ? this.state.processingTotals.TYPE_CREDIT_CARD.DISCOVER.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.DINERS_CLUB ? this.state.processingTotals.TYPE_CREDIT_CARD.DINERS_CLUB.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.JCB ? this.state.processingTotals.TYPE_CREDIT_CARD.JCB.total : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.UNION_PAY ? this.state.processingTotals.TYPE_CREDIT_CARD.UNION_PAY.total : 0,
                                                            this.state.processingTotals.TYPE_BANK_ACCOUNT ? this.state.processingTotals.TYPE_BANK_ACCOUNT.total : 0,
                                                            this.state.processingTotals.TYPE_CASH ? this.state.processingTotals.TYPE_CASH.total : 0,
                                                            this.state.processingTotals.TYPE_PAY_PAL ? this.state.processingTotals.TYPE_PAY_PAL.total : 0,
                                                        ],
                                                        backgroundColor: ['#2B3894', '#2B3894', '#CD0001', '#6968B8', '#1992CF', '#F88737', '#0079be', '#ca1034', '#007880', '#52c75d', '#c29500', '#525613']
                                                    },
                                                ]
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="col-md-6">

                                    <p className="lead mb-0">Individual Transactions</p>
                                    <p className="text-muted">By Payment Type</p>

                                    <div className="chart">
                                        <Doughnut
                                            options={{
                                                plugins: {
                                                    datalabels: {
                                                        formatter: (value, ctx) => {

                                                            let sum = 0;
                                                            let dataArray = ctx.chart.data.datasets[0].data;

                                                            dataArray.forEach(data => {
                                                                sum += data;
                                                            });

                                                            let percentage = (value*100 / sum).toFixed(2);

                                                            if(percentage > 1) {
                                                                return percentage + "%";
                                                            }

                                                            return '';

                                                        },
                                                        color: '#fff',
                                                    }
                                                },
                                                layout: {
                                                    padding: {
                                                        left: 25,
                                                        right: 25,
                                                        top: 10,
                                                        bottom: 25
                                                    }
                                                },
                                                legend: {
                                                    labels: {
                                                        boxWidth: 15,
                                                    }
                                                },
                                                cutoutPercentage: 0
                                            }}
                                            data={{
                                                labels: ['Visa', 'Visa Electron', 'MasterCard', 'Maestro', 'American Express', 'Discover', "Diner's Club", 'JCB', 'UnionPay', 'Bank Account', 'Cash', 'Paypal'],
                                                datasets: [
                                                    {
                                                        data: [
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.VISA ? this.state.processingTotals.TYPE_CREDIT_CARD.VISA.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.VISA_ELECTRON ? this.state.processingTotals.TYPE_CREDIT_CARD.VISA_ELECTRON.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.MASTERCARD ? this.state.processingTotals.TYPE_CREDIT_CARD.MASTERCARD.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.MAESTRO ? this.state.processingTotals.TYPE_CREDIT_CARD.MAESTRO.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.AMERICAN_EXPRESS ? this.state.processingTotals.TYPE_CREDIT_CARD.AMERICAN_EXPRESS.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.DISCOVER ? this.state.processingTotals.TYPE_CREDIT_CARD.DISCOVER.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.DINERS_CLUB ? this.state.processingTotals.TYPE_CREDIT_CARD.DINERS_CLUB.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.JCB ? this.state.processingTotals.TYPE_CREDIT_CARD.JCB.count : 0,
                                                            this.state.processingTotals.TYPE_CREDIT_CARD && this.state.processingTotals.TYPE_CREDIT_CARD.UNION_PAY ? this.state.processingTotals.TYPE_CREDIT_CARD.UNION_PAY.count : 0,
                                                            this.state.processingTotals.TYPE_BANK_ACCOUNT ? this.state.processingTotals.TYPE_BANK_ACCOUNT.count : 0,
                                                            this.state.processingTotals.TYPE_CASH ? this.state.processingTotals.TYPE_CASH.count : 0,
                                                            this.state.processingTotals.TYPE_PAY_PAL ? this.state.processingTotals.TYPE_PAY_PAL.count : 0,
                                                        ],
                                                        backgroundColor: ['#2B3894', '#2B3894', '#CD0001', '#6968B8', '#1992CF', '#F88737', '#0079be', '#ca1034', '#007880', '#52c75d', '#c29500', '#525613']
                                                    },
                                                ]
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>

                        </div>
                        }

                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/dashboard" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default Processing;