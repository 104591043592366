import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import microDeposit from "../../../media/img/payments/micro-deposit.png";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonBack from "../../common/ButtonBack";
import ButtonSubmit from "../../common/ButtonSubmit";
import CustomFieldList from "../../common/CustomFieldList";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class MerchantAccount extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            merchantAccount: {},
            bankInfo: {},
            customFields: {},
            paymentProviders: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            bankAccountNumber: '',
            routingNumber: '',
            microDepositAmount: '',
            microDepositTransactionStatus: '',
            validationList: [],
        };

        this.updateBankingInformation = this.updateBankingInformation.bind(this);
        this.validateMicroDeposit = this.validateMicroDeposit.bind(this);
    }

    /**
     * Get the merchant account based on the Route's ID. Use the merchant account's ID to retrieve the payment
     * provider's merchant account status. Also retrieve a list of all payment providers for populating select boxes.
     * Finally, fetch the banking info of the landlord.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.props.match.params.merchantAccountId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                merchantAccount: response.data
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    bankInfo: response.data
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

            if(this.state.merchantAccount.microDepositId !== null) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/micro_deposit/${this.state.merchantAccount.microDepositId}/transactionstatus`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        microDepositTransactionStatus: response.data.transactionStatus
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_provider/search?recordsPerPage=100&page=1`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentProviders: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the merchant account's banking details. Triggers the creation of a new micro-deposit that must be
     * validated before the merchant account can securely send funds to the new bank account.
     *
     * @param event - The event container.
     */
    updateBankingInformation(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/bankinfo`, {
            bankAccountNumber: this.state.bankAccountNumber,
            routingNumber: this.state.routingNumber
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.merchants.banking.submitted'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/micro_deposit/${this.state.merchantAccount.microDepositId}/transactionstatus`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    microDepositTransactionStatus: response.data.transactionStatus
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    bankInfo: response.data
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

            $('#update-banking-information').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Attempt to validate the micro-deposit.
     *
     * @param event - The event container.
     */
    validateMicroDeposit(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/micro_deposit/${this.state.bankInfo.microDepId}/validate`, {
            amount: this.state.microDepositAmount
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.merchants.banking.validated'
                    }
                }],
            });

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    bankInfo: response.data
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

            $('#validate-micro-deposit').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit API account interface.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $('[data-toggle="tooltip"]').tooltip();

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.state.bankInfo.microDepStatus === 'AWAITING_VALIDATION' &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account has a pending micro-deposit
                        </h4>
                        <p className="card-text">
                            The owner of the bank account associated with this pending micro-deposit must validate the amount before funds can be sent to the new bank account. The micro-deposit information is displayed below.
                        </p>
                    </div>
                </div>
                }

                {this.state.bankInfo.microDepStatus === 'VALIDATION_LIMIT_REACHED' &&
                <div className="card card-danger border-danger">
                    <div className="card-body">
                        <h4 className="card-title">
                            This merchant account has a failed micro-deposit
                        </h4>
                        <p className="card-text">
                            The micro-deposit associated with this merchant account has reached it's maximum validation attempts. To issue a new micro-deposit, click the Update Banking Details button below.
                        </p>
                    </div>
                </div>
                }


                <div className="card">
                    <div className="card-header">
                        Merchant Account Details
                    </div>
                    <div className="card-body card-body-table">
                        <table className="table table-striped table-bordered table-responsive-sm">
                            <tbody>
                            <tr>
                                <td width="25%">Account Name</td>
                                <td width="75%">{this.state.merchantAccount['name']}</td>
                            </tr>
                            <tr>
                                <td>Account Number</td>
                                <td>{this.state.merchantAccount['accountNumber']}</td>
                            </tr>
                            <tr>
                                <td>Processor</td>
                                <td>
                                    <FormattedMessage id={`enum.merchantAccount.paymentProviderId.${this.state.merchantAccount['paymentProviderId']}`} />
                                </td>
                            </tr>
                            <tr>
                                <td>Payment Type</td>
                                <td>
                                    <FormattedMessage id={`enum.paymentType.${this.state.merchantAccount['paymentType']}`} />
                                </td>
                            </tr>
                            <tr>
                                <td>Currency</td>
                                <td>{this.state.merchantAccount['currency']}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Banking Information
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#update-banking-information" className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon={['fas', 'edit']} className="fa-fw" /> Update Banking Information
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body card-body-table">
                        <table className="table table-striped table-bordered table-responsive-sm">
                            <tbody>
                            <tr>
                                <td width="25%">Account Number</td>
                                <td width="75%">*****{this.state.bankInfo['last4']}</td>
                            </tr>
                            <tr>
                                <td>Routing Number</td>
                                <td>{this.state.bankInfo['routingNumber']}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.merchantAccount.accountStatus." + this.state.bankInfo.status + ".class"})}`} />
                                        <span className="ml-1"><FormattedMessage id={"enum.merchantAccount.accountStatus." + this.state.bankInfo.status} /></span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <CustomFieldList intl={this.props.intl} token={this.props.token} parent={this} recordType="MERCHANT_ACCOUNT" recordId={this.props.match.params.merchantAccountId} createMessage="admin.landlords.merchants.fields.created" deleteMessage="admin.landlords.merchants.fields.deleted" />

                {this.state.merchantAccount.microDepositId &&
                <div className="card">
                    <div className="card-header">
                        Micro-Deposit Details
                    </div>
                    <div className="card-body card-body-table">
                        <table className="table table-striped table-bordered table-responsive-sm">
                            <tbody>
                            <tr>
                                <td width="25%">Account Number</td>
                                <td width="75%">*****{this.state.bankInfo.microDepLast4}</td>
                            </tr>
                            <tr>
                                <td>Routing Number</td>
                                <td>{this.state.bankInfo.microDepRoutingNumber}</td>
                            </tr>
                            <tr>
                                <td>Micro-Deposit Status</td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.microDeposit.status." + this.state.bankInfo.microDepStatus + ".class"})}`} />
                                        <span className="ml-1"><FormattedMessage id={"enum.microDeposit.status." + this.state.bankInfo.microDepStatus} /></span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Transaction Status</td>
                                <td>
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.microDeposit.transactionStatus." + this.state.microDepositTransactionStatus + ".class"})}`} />
                                        <span className="ml-1"><FormattedMessage id={"enum.microDeposit.transactionStatus." + this.state.microDepositTransactionStatus} /></span>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    {this.state.bankInfo.microDepStatus === 'AWAITING_VALIDATION' &&
                    <div className="card-footer">
                        <small className="text-muted">
                            A micro-deposit has been sent to this bank account. The amount must be provided by the account holder to validate the micro-deposit and allow funds to be sent to this bank account.
                        </small>
                    </div>
                    }
                </div>
                }

                <div className="row">
                    <div className="col text-right">

                        <ButtonBack path={`/admin/landlords/${this.props.match.params.landlordId}/merchants`} />

                    </div>
                </div>

                <div className="modal fade" id="update-banking-information" tabIndex="-1" role="dialog" aria-labelledby="update-banking-information-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.updateBankingInformation}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="update-banking-information-label">
                                        Update Banking Information
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Updating the bank account associated with this merchant account will trigger a new micro-deposit that must be validated by the owner of the bank account. The merchant account will continue to send funds to the existing bank account until the micro-deposit has been validated.
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="bankAccountNumber" label="Account Number" labelColumns="4" fieldColumns="8" parent={this} value={this.state['bankAccountNumber']} />

                                    <FieldText id="routingNumber" label="Routing Number" labelColumns="4" fieldColumns="8" parent={this} value={this.state['routingNumber']} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#update-banking-information").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col-6">
                                            <div className="text-right">
                                                <ButtonSubmit />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="validate-micro-deposit" tabIndex="-1" role="dialog" aria-labelledby="validate-micro-deposit-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.validateMicroDeposit}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="validate-micro-deposit-label">
                                        Validate Micro-Deposit
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <img src={microDeposit} alt="A deposit from Letus will appear in your bank account statements. Enter the deposit amount in the field below to verify your bank account with us." className="img-fluid mb-2" />

                                    <p className="mb-0">
                                        A deposit has been sent to the bank account with an account number ending in <span className="font-weight-bold">{this.state.bankInfo.microDepLast4}</span>. Enter the deposit amount below to validate your banking details.
                                    </p>

                                </div>
                                <div className="modal-body">

                                    <FieldText id="microDepositAmount" label="Deposit Amount" type="number" fieldColumns="8" labelColumns="4"
                                               min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" parent={this}
                                               value={this.state['microDepositAmount']} />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom">

                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#validate-micro-deposit").modal("hide")}>Close</button>

                                    <ButtonSubmit />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

MerchantAccount.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(MerchantAccount);