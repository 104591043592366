import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import * as constants from "../../util/constants";
import Propertii from "../common/Propertii";
import Alert from "./Alert";
import ButtonSave from "./ButtonSave";
import CardBrandIcon from "./CardBrandIcon";
import FieldDate from "./FieldDate";
import ReceiptPaymentTransaction from "./ReceiptPaymentTransaction";
import Spinner from "./Spinner";
import Table from "./Table";

class Invoices extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            invoice: {},
            paymentTransaction: {},
            parentPaymentTransaction: {},

            billingAccount: {},
            billingAccountList: [],

            companyOwnerList: [],

            paymentMethod: {},
            paymentMethodList: [],

            invoiceIssueDate: '',
            invoiceDueDate: '',
            invoiceCancelDate: '',

            invoiceList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            invoiceQuery: {
                orderBy: 'DESC',
                orderByFields: ['issueDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'rmCompanyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    },
                ]
            },

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'co',
                        returnFields: ['name', 'landlordId']
                    },
                    c: {
                        targetRecordType: this.props.userType,
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    },
                }
            },

            validationList: [],

        };

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);
        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);
        this.searchInvoices = this.searchInvoices.bind(this);
        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);

        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);

        this.viewInvoice = this.viewInvoice.bind(this);
        this.editInvoice = this.editInvoice.bind(this);
        this.issueInvoice = this.issueInvoice.bind(this);
        this.cancelInvoice = this.cancelInvoice.bind(this);
        this.payInvoice = this.payInvoice.bind(this);
        this.downloadInvoice = this.downloadInvoice.bind(this);

        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
    }

    /**
     * Search for the billing account related to the user, or a list of all billing accounts for all company owners if
     * dealing with company owners.
     */
    componentDidMount() {

        if(this.props.userType === 'TYPE_COMPANY_OWNER') {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/company_owner/search`, {
                orderBy: 'ASC',
                orderByFields: ['id'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    },
                ]
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    companyOwnerList: response.data.records
                }));

                let billingAccountConditionList = [];

                response.data.records.forEach((companyOwner, key) => {
                    billingAccountConditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'OR',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'userId',
                            operator: 'EQUALS',
                            fieldValue: companyOwner.id
                        }
                    );
                });

                this.searchBillingAccounts(billingAccountConditionList);

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(this.props.userType !== 'TYPE_COMPANY_OWNER') {

            let billingAccountConditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                }
            ];

            this.searchBillingAccounts(billingAccountConditionList);
        }
    }

    /**
     * View the detailed information of a payment transaction. If the transaction has a parent ID, search for the parent
     * transaction in order to display both transactions in a side-by-side view in the receipt modal. If the transaction
     * has an invoice ID, fetch the invoice data.
     *
     * @param paymentTransaction - The payment transaction to view the details of.
     */
    viewPaymentTransaction(paymentTransaction) {

        if(paymentTransaction.parentId) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=1&page=1`, {
                orderBy: this.state.paymentTransactionQuery.orderBy,
                orderByFields: this.state.paymentTransactionQuery.orderByFields,
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'id',
                        fieldValue: paymentTransaction.parentId
                    }
                ],
                joins: this.state.paymentTransactionQuery.joins
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    parentPaymentTransaction: response.data.records[0],
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(paymentTransaction.invoiceId) {
            axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/${paymentTransaction.invoiceId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: {
                        ...paymentTransaction,
                        invoice: response.data
                    },
                }));
            }).catch(error => {
                this.handleValidation(error);
            });
        }

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: {
                ...paymentTransaction
            },
        }));

        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * Select an invoice and bring up the invoice view modal. If a default payment method is provided with the invoice,
     * fetch the payment method details for display purposes.
     *
     * @param invoice - The invoice selected.
     */
    viewInvoice(invoice) {

        if(invoice.rmPaymentMethodId && invoice.rmPaymentMethodType) {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${invoice.rmPaymentMethodType.substring(5).toLowerCase()}/${invoice.rmPaymentMethodId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    invoice: {
                        ...invoice,
                        paymentMethod: response.data,
                    },
                    invoiceDueDate: invoice.dueDate,
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }

        else {

            this.setState(prevState => ({
                ...prevState,
                invoice: invoice,
                invoiceDueDate: invoice.dueDate,
            }));

        }

        $('#invoice').modal('show');
    }

    /**
     * Edit an existing invoice by redirecting the user to the invoice management component.
     *
     * @param invoice - The invoice selected.
     */
    editInvoice(invoice) {

        this.props.history.push({
            pathname: `/admin/${this.props.userType === 'TYPE_LANDLORD' ? 'landlords' : 'companies'}/${this.props.userType === 'TYPE_LANDLORD' ? this.props.userId : this.props.companyId}/invoice`,
            state: {
                invoice: invoice,
            }
        });

        $('#invoice').modal('hide');
    }

    /**
     * Issue the selected invoice. Sets an issue date and payment due date.
     *
     * @event - The event container.
     */
    issueInvoice(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/${this.state.invoice.id}/issue?dueDate=${this.state.invoiceDueDate}&issueDate=${this.state.invoiceIssueDate}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                invoice: response.data,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.invoices.issued'
                    },
                    values: {
                        invoiceNumber: response.data.invoiceNumber
                    }
                }],
            }));

            this.searchInvoices(this.state.invoiceList.page, this.state.invoiceList.recordsPerPage, this.state.invoiceQuery);

            $('#issue-invoice').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Cancel the selected invoice. Sets a cancel date on the invoice.
     *
     * @event - The event container.
     */
    cancelInvoice(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
        });

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/${this.state.invoice.id}/cancel?cancelDate=${this.state.invoiceCancelDate}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                invoice: response.data,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.invoices.cancelled'
                    },
                    values: {
                        invoiceNumber: response.data.invoiceNumber
                    }
                }],
            }));

            this.searchInvoices(this.state.invoiceList.page, this.state.invoiceList.recordsPerPage, this.state.invoiceQuery);

            $('#cancel-invoice').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Make a full payment for the selected invoice.
     *
     * @event - The event container.
     */
    payInvoice(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        let paymentBillingAccount;
        let paymentMethod = this.state.paymentMethod;

        // Pair the payment method with the appropriate billing account from the list of billing accounts
        this.state.billingAccountList.forEach((billingAccount, key) => {
            if(billingAccount.userId === paymentMethod.userId) {
                paymentBillingAccount = billingAccount;
            }
        });

        if(paymentBillingAccount == null) {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'common.invoices.payment.method.null'
                    },
                }],
            }));
        }

        if(paymentBillingAccount != null) {
            axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/payment/execute/invoice/${this.state.invoice.id}?billingAccountId=${paymentBillingAccount.id}&billingAccountType=${paymentBillingAccount.type}&paymentMethodId=${paymentMethod.id}&paymentMethodType=${paymentMethod.type}`, {}, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    spinner: false
                });

                $('#pay-invoice').modal('hide');

                this.viewPaymentTransaction(response.data);

                this.searchInvoices(1, 25, this.state.invoiceQuery);
                this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }
    }

    /**
     * Download the selected invoice in PDF format.
     *
     * @param invoiceId - The ID of the invoice to download as a PDF.
     */
    downloadInvoice(invoiceId) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/${invoiceId}/pdf/download`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            this.setState({
                spinner: false
            });

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     *
     * @param conditionList - The condition list to search by.
     */
    searchBillingAccounts(conditionList) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: conditionList,
            joins: {
                u: {
                    targetRecordType: this.props.userType,
                    joinField: 'userId',
                    alias: 'u',
                    returnFields: ['firstName', 'lastName']
                }
            }
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {

            let paymentTransactionConditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'invoiceId',
                    operator: 'NOT_EQUALS',
                    fieldValue: null
                },
            ];

            if(this.props.companyId) {
                paymentTransactionConditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    }
                )
            }

            response.data.records.forEach((billingAccount, key) => {
                paymentTransactionConditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'billingAccountId',
                        operator: 'EQUALS',
                        fieldValue: billingAccount.id
                    }
                );
            });

            this.setState(prevState => ({
                ...prevState,
                billingAccountList: response.data.records,
                paymentTransactionQuery: {
                    ...prevState.paymentTransactionQuery,
                    conditionList: paymentTransactionConditionList
                },
            }));

            this.searchInvoices(1, 25, this.state.invoiceQuery);
            this.searchPaymentTransactions(1, 25, this.state.paymentTransactionQuery);
            this.searchPaymentMethods();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a list of all payment methods for the user. If the user type provided is a company owner, fetch a list of
     * all payment methods for all company owners under the company ID provided in the props.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        if(this.props.userType === 'TYPE_COMPANY_OWNER') {
            this.state.companyOwnerList.map((data, key) => {

                axios.get(`${constants.REACT_APP_HOST_API_URL}/company_owner/${data.id}/paymentmethods`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    response.data.forEach((paymentMethod, key) => {
                        this.setState(prevState => ({
                            ...prevState,
                            paymentMethodList: [...prevState.paymentMethodList, paymentMethod],
                        }));
                    });

                }).catch(error => {
                    this.handleValidation(error);
                });

                return null;

            });
        }

        if(this.props.userType !== 'TYPE_COMPANY_OWNER') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.userType.substring(5).toLowerCase()}/${this.props.userId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Update the data table of invoices.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchInvoices(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                invoiceList: {
                    ...response.data,
                    records: response.data.records ? response.data.records : [{}]
                },
                invoiceQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of payment transactions.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        let conditionList = this.state.invoiceQuery.conditionList;

        if(this.props.sessionRole.type === 'TYPE_MANAGER' || this.props.sessionRole.type === 'TYPE_LANDLORD') {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'DRAFT'
                },
            );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionList: response.data,
                paymentTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic custom fields management component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card accordion" id="navigation">

                    <div className="card-header pb-0">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <ul className="nav nav-tabs border-bottom-0">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="invoices-tab" data-toggle="tab" href="#invoices" role="tab" aria-controls="invoices" aria-selected="true">
                                            Invoices
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="payments-tab" data-toggle="tab" href="#payments" role="tab" aria-controls="payments" aria-selected="true">
                                            Payments
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col text-right">

                                {this.props.sessionRole.type === 'TYPE_ADMIN' &&
                                <Link to={`/admin/${this.props.userType === 'TYPE_LANDLORD' ? 'landlords' : 'companies'}/${this.props.userType === 'TYPE_LANDLORD' ? this.props.userId : this.props.companyId}/invoice`} className="btn btn-primary btn-sm">
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Create Invoice
                                </Link>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="tab-content" id="tabs">

                        <div className="tab-pane fade-show active" id="invoices" role="tabpanel" aria-labelledby="invoices-tab">

                            <Table tableClass="table-bordered table-hover table-responsive-sm border-bottom border-dark"
                                   columns={{issueDate: 'Issued On', invoiceNumber: 'Invoice', grandTotal: 'Total', dueDate: 'Payment Due', status: 'Status'}}
                                   columnWidths={['20%', '20%', '20%', '20%', '20%']}
                                   headerClass="c-pointer"
                                   data={this.state.invoiceList}
                                   query={this.state.invoiceQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   updateFunction={this.searchInvoices}>
                                <tbody>
                                {this.state.invoiceList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewInvoice(data)} className="c-pointer">
                                            <td>
                                                {data.issueDate &&
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        {data.issueDate}
                                                    </Moment>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                <div className="">
                                                    {data.invoiceNumber}
                                                </div>
                                            </td>
                                            <td>
                                                {(data.grandTotal && data.currency) &&
                                                <div className="">
                                                    <FormattedNumber value={data.grandTotal} style={`currency`} currency={data.currency}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {data.dueDate &&
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        {data.dueDate}
                                                    </Moment>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {data.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.invoice.status." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.invoice.status." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>

                        </div>

                        <div className="tab-pane fade" id="payments" role="tabpanel" aria-labelledby="payments-tab">

                            <Table tableClass="table-bordered table-hover"
                                   columns={{createDate: 'Date', amount: 'Amount', 'co.name': 'Paid To', status: 'Status'}}
                                   columnWidths={['25%', '25%', '25%', '25%']}
                                   headerClass="c-pointer"
                                   data={this.state.paymentTransactionList}
                                   query={this.state.paymentTransactionQuery}
                                   sortEnabled={true}
                                   recordsEnabled={true}
                                   paginationEnabled={true}
                                   updateFunction={this.searchPaymentTransactions}>
                                <tbody>
                                {this.state.paymentTransactionList.records.map((data, key) => {
                                    return (
                                        <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                            <td>
                                                <div className="">
                                                    <Moment format="MMM DD, YYYY" tz="UTC">
                                                        {data.createDate}
                                                    </Moment>
                                                </div>
                                            </td>
                                            <td>
                                                {(data.amount && data.currency) &&
                                                <div className="">
                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.currency}/>
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {(data.joins && data.joins.co) &&
                                                <div className="">
                                                    {data.joins.co.name}
                                                </div>
                                                }
                                            </td>
                                            <td>
                                                {data.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + data.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </Table>

                        </div>

                    </div>

                </div>

                <div className="modal fade" id="invoice" tabIndex="-1" role="dialog" aria-labelledby="invoice-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                                <div className="">
                                    <FontAwesomeIcon icon={["fas", "file-invoice"]} className="fa-fw va-b mb-3" size="4x" />
                                </div>
                                <h5 className="modal-title" id="invoice-label">
                                    Invoice Summary
                                </h5>
                                {(this.state.invoice.periodStartDate && this.state.invoice.periodEndDate) &&
                                <p className="mb-0 small">
                                    For the period of <Moment format="MMM DD, YYYY">{this.state.invoice.periodStartDate}</Moment> - <Moment format="MMM DD, YYYY">{this.state.invoice.periodEndDate}</Moment>
                                </p>
                                }
                                {(!this.state.invoice.periodStartDate && !this.state.invoice.periodEndDate) &&
                                <p className="mb-0 small">
                                    One-time invoice
                                </p>
                                }
                            </div>

                            {this.state.invoice.status === 'PAID' &&
                            <div className="modal-body bg-success border-top-0">
                                <p className="mb-0 text-white">
                                    We have received your payment. Thank you for your business!
                                </p>
                            </div>
                            }

                            {this.state.invoice.status === 'CANCELLED' &&
                            <div className="modal-body bg-secondary border-top-0">
                                <p className="mb-0">
                                    This invoice has been cancelled, and is no longer valid or awaiting payment.
                                </p>
                            </div>
                            }

                            {(this.state.invoice.autoPay && this.state.invoice.status !== 'PAID' && this.state.invoice.rmPaymentMethodType) &&
                            <div className="modal-body bg-secondary border-top-0">
                                <p className="mb-0">
                                    {this.state.invoice.rmPaymentMethodType === 'TYPE_BANK_ACCOUNT' &&
                                    <React.Fragment>
                                        Your bank account with the account number ending in {this.state.invoice.paymentMethod.last4} will be automatically billed on <Moment format="MMMM DD, YYYY" tz="UTC">{this.state.invoice.dueDate}</Moment> for the amount listed below.
                                    </React.Fragment>
                                    }
                                    {this.state.invoice.rmPaymentMethodType === 'TYPE_CREDIT_CARD' &&
                                    <React.Fragment>
                                        Your {this.state.invoice.paymentMethod.brand ? formatMessage({id: "enum.creditCard.brand." + this.state.invoice.paymentMethod.brand}) : 'Credit Card'} ending in {this.state.invoice.paymentMethod.last4} will be automatically billed on <Moment format="MMMM DD, YYYY" tz="UTC">{this.state.invoice.dueDate}</Moment> for the amount listed below.
                                    </React.Fragment>
                                    }
                                </p>
                            </div>
                            }

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td>
                                            Subtotal
                                        </td>
                                        <td className="text-right">
                                            {(this.state.invoice.subTotal && this.state.invoice.currency) &&
                                            <FormattedNumber value={this.state.invoice.subTotal} style={`currency`} currency={this.state.invoice.currency}/>
                                            }
                                        </td>
                                    </tr>
                                    {this.state.invoice.tax1 > 0 &&
                                    <tr>
                                        <td>
                                            {this.state.invoice.tax1Description ? this.state.invoice.tax1Description : 'Tax 1'}
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.invoice.tax1} style={`currency`} currency={this.state.invoice.currency} />
                                        </td>
                                    </tr>
                                    }
                                    {this.state.invoice.tax2 > 0 &&
                                    <tr>
                                        <td>
                                            {this.state.invoice.tax2Description ? this.state.invoice.tax2Description : 'Tax 2'}
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.invoice.tax2} style={`currency`} currency={this.state.invoice.currency} />
                                        </td>
                                    </tr>
                                    }
                                    <tr>
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="font-weight-bold text-right">
                                            {(this.state.invoice.grandTotal && this.state.invoice.currency) &&
                                            <FormattedNumber value={this.state.invoice.grandTotal} style={`currency`} currency={this.state.invoice.currency}/>
                                            }
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Invoice ID
                                        </td>
                                        <td className="text-right">
                                            {this.state.invoice.invoiceNumber}
                                        </td>
                                    </tr>
                                    {this.state.invoice.issueDate &&
                                    <tr className="small">
                                        <td>
                                            Issued On
                                        </td>
                                        <td className="text-right">
                                            <Moment format="MMM DD, YYYY" tz="UTC">
                                                {this.state.invoice.issueDate}
                                            </Moment>
                                        </td>
                                    </tr>
                                    }
                                    {this.state.invoice.dueDate &&
                                    <tr className="small">
                                        <td>
                                            Payment Due
                                        </td>
                                        <td className="text-right">
                                            <Moment format="MMM DD, YYYY" tz="UTC">
                                                {this.state.invoice.dueDate}
                                            </Moment>
                                        </td>
                                    </tr>
                                    }
                                    {this.state.invoice.creator &&
                                    <tr className="small">
                                        <td>
                                            Issuer
                                        </td>
                                        <td className="text-right">
                                            {this.state.invoice.creator.name ? this.state.invoice.creator.name : `${this.state.invoice.creator.firstName} ${this.state.invoice.creator.lastName}`}
                                        </td>
                                    </tr>
                                    }
                                    {this.state.invoice.recipient &&
                                    <tr className="small">
                                        <td>
                                            Recipient
                                        </td>
                                        <td className="text-right">
                                            {this.state.invoice.recipient.name ? this.state.invoice.recipient.name : `${this.state.invoice.recipient.firstName} ${this.state.invoice.recipient.lastName}`}
                                        </td>
                                    </tr>
                                    }
                                    <tr className="small">
                                        <td>
                                            Auto Pay
                                        </td>
                                        <td className="text-right">
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.invoice.autoPay ? 'text-success' : 'text-secondary'}`} />
                                                <span className="ml-1">{this.state.invoice.autoPay ? 'Enabled' : 'Disabled'}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Status
                                        </td>
                                        <td className="text-right">
                                            {this.state.invoice.status &&
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.invoice.status." + this.state.invoice.status + ".class"})}`}/>
                                                <span className="ml-1"><FormattedMessage id={"enum.invoice.status." + this.state.invoice.status}/></span>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer d-block bg-secondary">
                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#invoice").modal("hide")}>
                                            <FormattedMessage id="button.close" />
                                        </button>
                                    </div>
                                    <div className="col-8 text-right">

                                        <div className="dropdown">

                                            <div className="btn btn-primary btn-lg dropdown-toggle" role="button" id="payment-transaction-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Actions
                                            </div>

                                            <div className="dropdown-menu" aria-labelledby="payment-transaction-actions">

                                                <div onClick={() => this.downloadInvoice(this.state.invoice.id)} className="dropdown-item c-pointer">
                                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download PDF
                                                </div>

                                                {(this.props.sessionRole.type === 'TYPE_ADMIN' && this.state.invoice.status === 'DRAFT') &&
                                                <div onClick={() => this.editInvoice(this.state.invoice)} className="dropdown-item c-pointer">
                                                    <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Edit Invoice
                                                </div>
                                                }

                                                {(this.props.sessionRole.type === 'TYPE_ADMIN' && this.state.invoice.status === 'DRAFT') &&
                                                <div data-toggle="modal" data-target="#issue-invoice" onClick={() => $("#invoice").modal("hide")} className="dropdown-item c-pointer">
                                                    <FontAwesomeIcon icon={['fas', 'share-square']} className="fa-fw"/> Issue Invoice
                                                </div>
                                                }

                                                {(this.props.sessionRole.type === 'TYPE_ADMIN' && this.state.invoice.status !== 'PAID' && this.state.invoice.status !== 'CANCELLED') &&
                                                <div data-toggle="modal" data-target="#cancel-invoice" onClick={() => $("#invoice").modal("hide")} className="dropdown-item c-pointer">
                                                    <FontAwesomeIcon icon={['fas', 'ban']} className="fa-fw"/> Cancel Invoice
                                                </div>
                                                }

                                                {(!this.state.invoice.autoPay && (this.state.invoice.status === 'ISSUED' || this.state.invoice.status === 'PAYMENT_FAILED')) &&
                                                <div data-toggle="modal" data-target="#pay-invoice" className="dropdown-item c-pointer" onClick={() => $("#invoice").modal("hide")}>
                                                    <FontAwesomeIcon icon={['fas', 'credit-card']} className="fa-fw"/> Pay Now
                                                </div>
                                                }

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <ReceiptPaymentTransaction history={this.props.history} displayAdvanced={this.props.sessionRole.type === 'TYPE_ADMIN'} paymentTransaction={this.state.paymentTransaction} parentPaymentTransaction={this.state.parentPaymentTransaction}>

                    <div className="float-left">
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            <FormattedMessage id="button.close" />
                        </button>
                    </div>

                    <div className="float-right">

                        {this.state.paymentTransaction.invoiceId &&
                        <div className="btn btn-primary btn-lg ml-2" data-dismiss="modal" onClick={() => this.viewInvoice(this.state.paymentTransaction.invoice)}>
                            View Invoice
                        </div>
                        }

                        <div className="btn btn-primary btn-lg ml-2" onClick={() => window.print()}>
                            Print
                        </div>

                    </div>

                </ReceiptPaymentTransaction>

                <div className="modal fade" id="issue-invoice" tabIndex="-1" role="dialog" aria-labelledby="issue-invoice-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.issueInvoice}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="issue-invoice-label">
                                        Issue Invoice
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To issue this invoice, please provide a payment due date and issue date. If no issue date is provided, the issue date will be set to today's date.
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldDate id="invoiceIssueDate" label="Issue Date" parent={this} value={this.state['invoiceIssueDate']} />

                                    <FieldDate id="invoiceDueDate" label="Due Date" required={true} parent={this} value={this.state['invoiceDueDate']} />

                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#issue-invoice').modal('hide'); $('#invoice').modal('show');}}>Back</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="cancel-invoice" tabIndex="-1" role="dialog" aria-labelledby="cancel-invoice-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.cancelInvoice}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="cancel-invoice-label">
                                        Cancel Invoice
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        To cancel this invoice, please provide a cancel date below. If no cancel date is provided, the cancel date will be set to today's date.
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldDate id="invoiceCancelDate" label="Cancel Date" parent={this} value={this.state['invoiceCancelDate']} />

                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#cancel-invoice').modal('hide'); $('#invoice').modal('show');}}>Back</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="pay-invoice" tabIndex="-1" role="dialog" aria-labelledby="pay-invoice-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.payInvoice}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="pay-invoice-label">
                                        Pay Invoice
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    {(this.state.invoice.grandTotal && this.state.invoice.currency) &&
                                    <p className="mb-0">
                                        By making this invoice payment, you will be charged a total of <span className="font-weight-bold"><FormattedNumber value={this.state.invoice.grandTotal} style={`currency`} currency={this.state.invoice.currency} /></span>. Select which payment method you would like to make this payment with.
                                    </p>
                                    }
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    {this.state.paymentMethodList.map((data, key) => {

                                        if(data.type !== 'TYPE_CASH') {
                                            return (
                                                <div key={key} className={`list-group ${(key + 1) === this.state.paymentMethodList.length ? '' : 'mb-2'}`}>
                                                    <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                        <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                        <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    <div className="">
                                                                        {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                        <React.Fragment>
                                                                            Bank Account
                                                                        </React.Fragment>
                                                                        }
                                                                        {data.type === 'TYPE_CREDIT_CARD' &&
                                                                        <React.Fragment>
                                                                            <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                        </React.Fragment>
                                                                        }
                                                                    </div>
                                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                                        {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                        <React.Fragment>
                                                                            Account number ending in {data.last4}
                                                                        </React.Fragment>
                                                                        }
                                                                        {data.type === 'TYPE_CREDIT_CARD' &&
                                                                        <React.Fragment>
                                                                            Card number ending in {data.last4}
                                                                        </React.Fragment>
                                                                        }
                                                                    </small>
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right mr-2">
                                                                        <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            );
                                        }

                                        return null;

                                    })}


                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#pay-invoice").modal("hide"); $("#invoice").modal('show');}}>
                                                    <FormattedMessage id="button.back" />
                                                </button>
                                            </div>
                                            <div className="float-right">
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">
                                                    Confirm Payment
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Invoices.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoices);