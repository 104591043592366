import axios from "axios";
import React from 'react';
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import ButtonBack from "../common/ButtonBack";
import ButtonSave from "../common/ButtonSave";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";

class Password extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            password: '',
            newPassword: '',
            confirmNewPassword: '',
            localSystemAccount: {},
            validationList: [],
        };

        this.changePassword = this.changePassword.bind(this);
    }

    /**
     * Handle the submission of the form and change the user's password.
     *
     * @param event - The event container.
     */
    changePassword(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/changepassword`, {
            userId: this.props.userId,
            password: this.state.password,
            newPassword: this.state.newPassword,
            confirmNewPassword: this.state.confirmNewPassword
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.props.saveMessage
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic change password component for any user type.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.changePassword}>

                    <div className="card">
                        <div className="card-header">
                            Change Password
                        </div>
                        <div className="card-body">

                            <FieldText id="password" label="Current Password" type="password" parent={this} value={this.state['password']} />

                            <FieldText id="newPassword" label="New Password" type="password" parent={this} value={this.state['newPassword']} />

                            <FieldText id="confirmNewPassword" label="Confirm Password" type="password" parent={this} value={this.state['confirmNewPassword']} />

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path={this.props.backRedirect} />
                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default Password;