import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager transactions component.
     */
    render() {

        let hasMakePaymentPermission = this.props.userSession?.sessionRole?.type !== 'TYPE_MANAGER' ||
            (this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') ||
                this.props.userSession?.sessionRole?.roles?.includes('SUPPORT_MANAGER') ||
                this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER') ||
                this.props.userSession?.sessionRole?.roles?.includes('FINANCE_MANAGER'));

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Transactions" />

                    <TransactionList global={true} makePayment={hasMakePaymentPermission}
                                     history={this.props.history} parent={this}
                                     makePaymentPath="/manager/transactions/payment"
                                     userType="TYPE_MANAGER" />

                </div>

            </div>
        )
    };
}

export default Transactions;