import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import PaymentMethodList from "../../common/PaymentMethods";
import Propertii from "../../common/Propertii";

class PaymentMethods extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            customerId: '',
            validationList: [],
        };
    }

    /**
     * Get the customer ID based on the property lease ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                customerId: response.data.userId
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view payment methods component for managers when viewing a tenant.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.customerId &&
                <PaymentMethodList userType="TYPE_CUSTOMER" 
                    parent={this}
                    userId={this.state.customerId} 
                    propertyLeaseId={this.props.match.params.propertyLeaseId} 
                    createMessage="customer.payments.methods.created"
                    deleteMessage="customer.payments.methods.deleted"/>
                }

            </React.Fragment>
        )
    };
}

PaymentMethods.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PaymentMethods);