/**
 * Format the entered postal code into a standard Canada postal code.
 * @param postalCode
 */
export const formatCanadaPostalCode = (postalCode) => {
  const postalCodeNoSpace = postalCode.replace(/\s/g, '');
  if (postalCodeNoSpace.length > 3) {
    const lastIndex = postalCodeNoSpace.length;
    const formattedPostalCode = postalCodeNoSpace.slice(0, 3) + ' ' + postalCodeNoSpace.slice(3, lastIndex);
    return formattedPostalCode;
  } else {
    return postalCode;
  }
};

/**
 * Format the entered CC number into a standard CC Format
 * @param creditCardNumb
 */
export const formatCreditCardNo = (creditCardNumb) => {
  const creditCardNumbNoSpace = creditCardNumb?.toString()?.replace(/\s/g, '');
  if (creditCardNumbNoSpace.length > 4) {
    const formattedCreditCardNumb = creditCardNumbNoSpace.match(/.{1,4}/g);
    return formattedCreditCardNumb.join(' ');
  } else {
    return creditCardNumb;
  }
};

export const CA_POSTAL_REGEX = '[A-Za-z][0-9][A-Za-z]\\s?[0-9][A-Za-z][0-9]';
export const US_POSTAL_REGEX = '(\\d{5}([\\-]\\d{4})?)';
