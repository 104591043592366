import React from 'react';
import { Link } from 'react-router-dom';
import heroHowItWorks from '../../../../media/img/hw/payer/howitworks_hero.png';
import selectService from '../../../../media/img/hw/select_service.png';
import personalInformation from '../../../../media/img/hw/payer/personal_information.png';
import landlordInformation from '../../../../media/img/hw/information.png';
import paymentMethod from '../../../../media/img/hw/payment_method.png';
import confirmPayment from '../../../../media/img/hw/confirm_payment.png';
import notification from '../../../../media/img/hw/notification.png';
import confirmEmail from '../../../../media/img/hw/payer/confirm_email.png';
import acceptReject from '../../../../media/img/hw/payer/accept_reject.png';
import payNow from '../../../../media/img/hw/payer/pay_now.png';
import payLater from '../../../../media/img/hw/payer/pay_later.png';
import HyperWalletCard from '../HyperWalletCard';
import styles from '../css/HyperWalletHowItWorks.module.css';

const HyperWalletPayerHowItWorks = () => {
  const locale = {
    section1: {
      heading: 'Pay your way',
      info: 'LetUs Flexible Pay allows you to pay for home and personal services with the payment option that works best for you. No registration is required.',
      action: 'Try Flexibile Pay Now',
      chatBubble: 'Start paying on time with LetUs Flexible Payments.',
    },
    section2: {
      heading: `Here's How It Works`,
      cards: [
        {
          img: selectService,
          heading: '1. Select Service',
          info: 'Select the service you would like to pay for.',
        },
        {
          img: landlordInformation,
          heading: '2. Payee Information',
          info: 'Enter Payee information. For Rent, enter Property details and Property Manager information.',
        },
        {
          img: personalInformation,
          heading: '3. Personal Information',
          info: 'Enter your Personal Information.',
        },
        {
          img: paymentMethod,
          heading: '4. Payment Method',
          info: 'Select either the Pay Now or Pay Later payment option and enter your payment details.',
        },
        {
          img: confirmPayment,
          heading: '5. Confirm payment',
          info: 'Confirm payment after choosing the option to send the receipt via email or SMS text.',
        },
        {
          img: notification,
          heading: '6. Notification',
          info: 'That’s it!  We’ll notify the payee to either accept or deny the payment.',
        },
      ],
    },
    section3: {
      heading: `What you need`,
      points: [
        {
          info: 'The address for which you want to pay rent, security deposit, HOA, Utility, etc.',
        },
        {
          info: 'The information of the payee who will receive the payment including Company Name or First Name and Last Name, Email Address and Phone Number.',
        },
        {
          info: 'Payment information such as Credit Card, PayPal, etc.',
        },
      ],
    },
    section4: {
      heading: `Payee accepts or rejects the payment`,
      cards: [
        {
          img: confirmEmail,
          heading: 'Confirmation Email',
          info: 'You will receive an email confirming your payment has been submitted to LetUs. This email does not confirm payment to the payee',
        },
        {
          img: acceptReject,
          heading: 'Accept or Reject',
          info: 'The payee must set up or have an active Hyperwallet account to accept or reject the payment. You will receive a second email when the payee has accepted or rejected the payment.',
        },
      ],
    },
    section5: {
      heading: 'Payment Methods',
      subSectionLeft1: {
        heading: 'Pay Now',
        info: 'Selecting Pay Now will give you the option to pay immediately. You can use a credit or debit card, cash, or PayPal to complete your payment.',
      },
      subSectionLeft2: {
        heading: 'Cash Payments',
        info: '$6.95 (Canada), $3.99 (US)',
      },
      subSectionLeft3: {
        heading: 'Debit Card (Visa/MasterCard)',
        info: '0.99% (Canada), $6.99 (US)',
      },
      subSectionLeft4: {
        heading: 'Credit Card (Visa/MasterCard)',
        info: '2.5% (Canada), 2.99% (US)',
      },
      subSectionLeft5: {
        heading: 'Paypal',
        info: '$6.99 (US)',
      },
      subSectionRight1: {
        heading: 'Pay Later',
        info: 'Choosing Pay Later gives you access to PayPal Credit and Pay in 4 as well as LetUs Split.',
      },
      subSectionRight2: {
        heading: 'Letus Split',
        info: '$6.99 (US)',
      },
      subSectionRight3: {
        heading: 'Paypal',
        info: '$6.99 (US)',
      },
    },
  };

  return (
    <div className={`container ${styles.containerCustom}`}>
      <section className={`row ${styles.customRow} ${styles.sectionHero} mx-auto`}>
        <header className='col-lg-5 my-5'>
          <h1 className={`${styles.heroHeading}`}>{locale.section1.heading}</h1>
          <p className={`mt-4 ${styles.heroInfo}`}>{locale.section1.info}</p>
          <Link
            to='/pay/marketplace'
            className={`btn btn-secondary btn-sm rounded text-uppercase mt-4 ${styles.btnGetStarted}`}
          >
            {locale.section1.action}
          </Link>
        </header>
        <div className={`col-lg-7 mt-4`}>
          <div className='talk-bubble tri-right tb-round btm-left-in'>
            <div className='tb-talktext'>
              <p>{locale.section1.chatBubble}</p>
            </div>
          </div>
          <img src={heroHowItWorks} className={`d-block mx-auto img-fluid  ${styles.payeeImg}`} alt='' loading='lazy' />
        </div>
      </section>
      <section className={`row ${styles.customRow}`}>
        <header className={`row ${styles.headingRow} my-5`}>
          <h1 className={`${styles.heading}`}>{locale.section2.heading}</h1>
        </header>
        <div className={`row mt-3 d-flex justify-content-center`}>
          {locale.section2.cards.map((card, key) => (
            <div className={`mr-5 ${styles.hwCardCol}`} key={key}>
              <HyperWalletCard src={card.img} heading={card.heading} info={card.info} />
            </div>
          ))}
        </div>
      </section>
      <section className={`row ${styles.customRow}`}>
        <div className={`${styles.whatYouNeedCard} my-5 shadow rounded`}>
          <header className={`row ${styles.headingRow} `}>
            <h1 className={`${styles.heading}`}>{locale.section3.heading}</h1>
          </header>
          <div className={`row d-flex justify-content-center mt-3`}>
            <ul className='fa-ul'>
              {locale.section3.points.map((point, key) => (
                <li className='mt-4' key={key}>
                  <i className='fa-li fa fa-check-circle bs-stepper-info'></i>
                  {point.info}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className={`row ${styles.customRow} py-5`}>
        <header className={`row ${styles.headingRow} mt-5`}>
          <h1 className={`${styles.heading}`}>{locale.section4.heading}</h1>
        </header>
        <div className={`row my-5 d-flex justify-content-center w-100`}>
          {locale.section4.cards.map((card, key) => (
            <HyperWalletCard src={card.img} key={key} heading={card.heading} info={card.info} styleCard='hwCardMd' />
          ))}
        </div>
      </section>
      <section className={`row ${styles.customRow} ${styles.sectionPaymentMethods} py-5 mx-auto`}>
        <header className={`row ${styles.headingRow} mt-5`}>
          <h1 className={`${styles.heading}`}>{locale.section5.heading}</h1>
        </header>
        <div className='row'>
          <div className='col-md-6 mt-5 pl-5'>
            <div className='row-sm-12'>
              <div className=' h1'>
                <b>{locale.section5.subSectionLeft1.heading}</b>
              </div>
              <div className=' w-75'>{locale.section5.subSectionLeft1.info}</div>
            </div>
            <div className='row-sm-12 mt-4'>
              <div className='h4'>
                <b>{locale.section5.subSectionLeft2.heading}</b>
              </div>
              <div className=''>{locale.section5.subSectionLeft2.info}</div>
            </div>
            <div className='row-sm-12 mt-3'>
              <div className='h4'>
                <b>{locale.section5.subSectionLeft3.heading}</b>
              </div>
              <div className=''>{locale.section5.subSectionLeft3.info}</div>
            </div>
            <div className='row-sm-12 mt-3'>
              <div className='h4'>
                <b>{locale.section5.subSectionLeft4.heading}</b>
              </div>
              <div className=''>{locale.section5.subSectionLeft4.info}</div>
            </div>
            <div className='row-sm-12 mt-3'>
              <div className='h4'>
                <b>{locale.section5.subSectionLeft5.heading}</b>
              </div>
              <div className=''>{locale.section5.subSectionLeft5.info}</div>
            </div>
          </div>
          <div className='col-md-6 d-flex align-items-center'>
            <img src={payNow} className={`d-block img-fluid mx-auto ${styles.payNowImg}`} alt='' />
          </div>
        </div>
        <div className='row my-5'>
          <div className='col-md-5 d-flex align-items-center'>
            <img src={payLater} className={`d-block img-fluid ${styles.payLaterImg}`} alt='' loading='lazy' />
          </div>
          <div className='col-md-7 mt-5 pl-5'>
            <div className='row-sm-12'>
              <div className={`h1 ${styles.subSectionRightRow}`}>
                <b>{locale.section5.subSectionRight1.heading}</b>
              </div>
              <div className={`${styles.subSectionRightRow}`}>{locale.section5.subSectionRight1.info}</div>
            </div>
            <div className='row-sm-12 mt-4'>
              <div className={`h4 ${styles.subSectionRightRow}`}>
                <b>{locale.section5.subSectionRight2.heading}</b>
              </div>
              <div className={`${styles.subSectionRightRow}`}>{locale.section5.subSectionRight2.info}</div>
            </div>
            <div className='row-sm-12 mt-3'>
              <div className={`h4 ${styles.subSectionRightRow}`}>
                <b>{locale.section5.subSectionRight3.heading}</b>
              </div>
              <div className={`${styles.subSectionRightRow}`}>{locale.section5.subSectionRight3.info}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HyperWalletPayerHowItWorks;
