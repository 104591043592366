import React from "react";
import { injectIntl } from "react-intl";
import Propertii from "../../common/Propertii";
import TenantReport from "../../common/ReportTenants";

class ReportTenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company tenant report component.
     */
    render() {

        return(
            <TenantReport companyId={this.props.match.params.companyId} />
        )
    };
}

export default injectIntl(ReportTenants);