import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import Spinner from "../../common/Spinner";
import Propertii from "../../common/Propertii";
import Modal from "../../common/Modal";
import Alert from "../../common/Alert";
import $ from "jquery";
import {FormattedMessage} from "react-intl";

import * as constants from "../../../util/constants";
import Switch from "react-switch";



class ReCaptcha extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            env: "",
            recaptchaEnabled: true,
            sysConfig: {},
            toggleModal:{
                bodyMessage: ""
            }
        };

        this.getEnvSysConfig = this.getEnvSysConfig.bind(this);
        this.handleToggleFromModal = this.handleToggleFromModal.bind(this);
        this.handleToggle = this.handleToggle.bind(this);
        this.getModalMessage = this.getModalMessage.bind(this);
    }

    getEnvSysConfig(){
        axios.post(`${constants.REACT_APP_HOST_API_URL}/system_configuration/search`, {
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    fieldName: 'recaptchaEnabled',
                    operator: 'NOT_EQUALS',
                    fieldValue: null
                }
            ]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response?.data?.records){
                let sysConfig = response.data.records[0]

                this.setState(prevState => ({
                    ...prevState,
                    recaptchaEnabled: sysConfig.recaptchaEnabled,
                    env: sysConfig.environment,
                    sysConfig: sysConfig
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    handleToggle(check){
        let message = this.getModalMessage(!check);
        
        this.setState(prevState => ({
            ...prevState,
            toggleModal:{
                bodyMessage: message
            },
        }));
        
        $("#recaptcha-toggle-confirm").modal("show");
    }

    getModalMessage(recaptchaEnabled){
        if(recaptchaEnabled){
            return `Are you sure you want to disable ReCaptcha for ${this.state.env}? If so, please re-enable it after your work is complete. This is an important security setting.`
        } else {
            return `Are you sure want to enable ReCaptcha for ${this.state.env}?`
        }
    }

    handleToggleFromModal(){
        let sysConfig = this.state.sysConfig;
        if(sysConfig.recaptchaEnabled !== null){
            sysConfig.recaptchaEnabled = !sysConfig.recaptchaEnabled;

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, sysConfig, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    sysConfig:response.data,
                    recaptchaEnabled: response.data.recaptchaEnabled,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                }],
                });

            }).catch(error => {
                this.handleValidation(error);
            });
    
            window.scrollTo(0, 0);
        }
    }

    /**
     * On mounting of the component, fetch the list of customer notes.
     */
    componentDidMount() {
        this.getEnvSysConfig();
    }

    /**
     * Render the component.
     *
     * @returns {*} - The customer notes component.
     */
    render() {

        return (
            <React.Fragment>
                <div className="content-block">

                    <Spinner visible={this.state.spinner} />


                    <Modal  id="recaptcha-toggle-confirm" theme="danger" iconType="fas" iconName="exclamation-triangle" 
                        body={this.state.toggleModal.bodyMessage} title="ReCaptcha Toggle">
                        
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => $("#manage-charge-confirm").modal("hide")}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => this.handleToggleFromModal()} className="btn btn-danger btn-lg" data-dismiss="modal">
                            {this.state.sysConfig.recaptchaEnabled ? 'Disable': 'Enable'} ReCaptcha
                        </button>
                    </Modal>

                        <div className="container">

                            <Alert validationList={this.state.validationList}/>

                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            ReCaptcha
                                        </div>
                                    </div>
                                </div>

                                <div className="col my-3">
                                    <p className="mb-0">
                                    ReCaptcha Setting for {this.state.env}:
                                        <strong className={'float-right'}>
                                            {this.state.sysConfig.recaptchaEnabled ?
                                                <span className={'text-primary'}>
                                                    Enabled
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={'fa-fw small ml-2'}/>
                                                </span>
                                                :
                                                <span className={'text-danger'}>
                                                    Disabled
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={'fa-fw small ml-2'}/>
                                                </span>
                                            }
                                        </strong>
                                    </p>
                                    <hr />
                                    <p className="mb-0">
                                    Click here to enable/disable ReCaptcha
                                        <strong className={'float-right'}>
                                            <Switch onChange={this.handleToggle} value={this.state.recaptchaEnabled} checked={this.state.recaptchaEnabled} />       
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        )
    };
}

export default ReCaptcha;
