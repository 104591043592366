import axios from 'axios';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../Alert';
import { useValidationList } from '../hooks/useValidationList';
import Header from './header/Header';
import MessageBar from './messageBar/MessageBar';
import MessageBubble from './messageBubble/MessageBubble';
import styles from './TextMessagingStyles.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TextMessaging = ({ companyId, leaseId }) => {
  const { validationList, addErrorToValidationList } = useValidationList([]);
  const [chatMessagesCurrentPage, setChatMessagesCurrentPage] = useState([]);
  const [chatMessageRecords, setChatMessageRecords] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  /**
   * Send text message to a tenant
   * @param {string} messageContent
   * @returns a promise
   */
  const sendMessage = (messageContent) => {
    const URL = `/company/${companyId}/notifications`;
    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}${URL}`,
      {
        leaseId: leaseId,
        body: messageContent,
      },
      { headers: generateRequestHeaders() }
    );
  };

  /**
   * Fetch chat messages between letus and a tenant
   * @param {number} page
   * @param {number} recordsPerPage
   */
  const fetchChatMessages = useCallback(
    (page = 1, recordsPerPage = 25, isLoadMoreChat = true) => {
      setShowSpinner(true);
      const textMessageBodyHeight = $('#textMessageBody').prop('scrollHeight');
      const URL = `/company/${companyId}/notifications?page=${page}&recordsPerPage=${recordsPerPage}&leaseId=${leaseId}&orderBy=DESC`;
      axios
        .get(`${constants.REACT_APP_HOST_API_URL}${URL}`, {
          headers: generateRequestHeaders(),
        })
        .then((response) => {
          setChatMessagesCurrentPage(response.data);
          if(isLoadMoreChat) {
            setChatMessageRecords((prevState) => [...response.data?.records?.reverse(), ...prevState]);
            const textMessageBodyHeightCurrent = $('#textMessageBody').prop('scrollHeight');
            $('#textMessageBody').scrollTop(textMessageBodyHeightCurrent - textMessageBodyHeight); // maintain current cursor position
          } else {
            setChatMessageRecords(response.data?.records?.reverse());
            const textMessageBodyHeightCurrent = $('#textMessageBody').prop('scrollHeight');
            $('#textMessageBody').animate({ scrollTop: textMessageBodyHeightCurrent }, 200);
          }
          setShowSpinner(false);
        })
        .catch((error) => {
          setShowSpinner(false);
          addErrorToValidationList(error);
          window.scrollTo(0, 0);
        });
    },
    [addErrorToValidationList, companyId, leaseId]
  );

  useEffect(() => {
    if (companyId && leaseId) {
      fetchChatMessages(1, 25, false);
    }
  }, [companyId, leaseId, fetchChatMessages]);

  return (
    <>
      <Alert validationList={validationList} />
      <div className='card overflow-hidden'>
        <div className='card-header'>
          <Header title={'Text Messaging'} />
          {showSpinner ? (
            <div className='spinner-border spinner-border-sm float-right' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            <button
              title='Refresh message list'
              type='button'
              className='btn btn-xs float-right text-light'
              onClick={() => fetchChatMessages(1, 25, false)}
            >
              <FontAwesomeIcon icon={['fa', 'redo']} />
            </button>
          )}
        </div>
        <div
          id='textMessageBody'
          className={`card-body px-4 py-3 overflow-auto ${styles.textMessageBody}`}
        >
          <div className='row p-2 justify-content-center'>
            {parseInt(chatMessagesCurrentPage.page) <
              parseInt(chatMessagesCurrentPage.totalPages) && (
              <button
                type='button'
                className='btn btn-outline-primary btn-xs p-2'
                onClick={() => {
                  fetchChatMessages(parseInt(chatMessagesCurrentPage.page) + 1, 25, true);
                }}
              >
                Load More
              </button>
            )}
          </div>
          {chatMessageRecords.map((chatMessage) => (
            <MessageBubble
              message={chatMessage.messageContent}
              messageSender={chatMessage.origin}
              messageSenderType={chatMessage.origin}
              messageTime={chatMessage.sentdatetime}
              messageStatus={chatMessage.status}
              key={chatMessage.id}
            />
          ))}
        </div>
        <div className='card-footer'>
          <MessageBar
            sendMessage={sendMessage}
            fetchChatMessages={fetchChatMessages}
            addErrorToValidationList={addErrorToValidationList}
          />
        </div>
      </div>
    </>
  );
};

TextMessaging.propTypes = {
  companyId: PropTypes.string,
  leaseId: PropTypes.string,
};

export default TextMessaging;
