import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios/index';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../../common/Alert';
import FieldCheckboxFC from '../../common/FieldCheckboxFC';
import FieldFileFC from '../../common/FieldFileFC';
import FieldTextareaFC from '../../common/FieldTextareaFC';
import { useInput } from '../../common/hooks/useInput';
import { useValidationList } from '../../common/hooks/useValidationList';
import Spinner from '../../common/Spinner';

/**
 * Service Level Configuration Component for Promotional Text in a Payment Receipt
 * @returns
 */
const ServiceLevelConfigCustomReceipt = ({ companyId, propertyId }) => {
  /******** STATE ******/
  /*********************/
  //en form state
  const [promotionalTextEnglishID, setPromotionalTextEnglishID] = useState(null);
  const [showPromotionalTextEnglish, setShowPromotionalTextEnglish] = useState(false);
  const [promotionalTextEnglishImgID, setPromotionalTextEnglishImgID] = useState();
  const [promotionalTextEnglishImgURL, setPromotionalTextEnglishImgURL] = useState(null);
  const { value: promotionalTextEnglish, handleChange: handleChangePromotionalTextEnglish } =
    useInput('');
  const { value: promotionalTextEnglishImg, handleChange: handleChangePromotionalTextEnglishImg } =
    useInput(null);
  // fr form state
  const [promotionalTextFrenchID, setPromotionalTextFrenchID] = useState(null);
  const [showPromotionalTextFrench, setShowPromotionalTextFrench] = useState(false);
  const [promotionalTextFrenchImgID, setPromotionalTextFrenchImgID] = useState(null);
  const [promotionalTextFrenchImgURL, setPromotionalTextFrenchImgURL] = useState(null);
  const { value: promotionalTextFrench, handleChange: handleChangePromotionalTextFrench } =
    useInput('');
  const { value: promotionalTextFrenchImg, handleChange: handleChangePromotionalTextFrenchImg } =
    useInput(null);
  // Common state
  const [showSpinner, setShowSpinner] = useState(false);
  const { validationList, addErrorToValidationList } = useValidationList([]);

  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/

  /**
   * Query to add conditions to the API calls for Custom Receipt Promotional messages
   */
  const getCustomReceiptQuery = useCallback(() => {
    const customReceiptQuery = {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'companyId',
          operator: 'EQUALS',
          fieldValue: companyId,
        },
      ],
      joins: {
        d: {
          targetRecordType: 'TYPE_DOCUMENT',
          joinField: 'labelImageId',
          targetField: 'id',
          alias: 'u',
          returnFields: ['url'],
        },
      },
    };
    if (propertyId) {
      customReceiptQuery.conditionList.push({
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'propertyId',
        operator: 'EQUALS',
        fieldValue: propertyId,
      });
    } else {
      customReceiptQuery.conditionList.push({
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'propertyId',
        operator: 'EQUALS',
        fieldValue: null,
      });
    }
    return customReceiptQuery;
  }, [companyId, propertyId]);

  /**
   * The BE has been designed to support multiple promotional messages.
   * However, the FE requirement at this time was to only have one message per language.
   * This function tries to setup first encountered en or fr message in the array as the promotional value.
   * There shouldn't be more than one Promotional text per language, but if there is one, the above comes into effect.
   * @param {Array} promotionalMessages
   */
  const handleCustomReceiptDetailsState = useCallback(
    (promotionalMessages) => {
      let englishPromotionalMessageFound = false;
      let frenchPromotionalMessageFound = false;

      promotionalMessages.some((promotionalMessage) => {
        if (promotionalMessage.languageCode === 'en' && !englishPromotionalMessageFound) {
          setPromotionalTextEnglishID(promotionalMessage.id);
          setShowPromotionalTextEnglish(promotionalMessage.includeInReceipts);
          setPromotionalTextEnglishImgURL(promotionalMessage.joins?.u?.url);
          setPromotionalTextEnglishImgID(promotionalMessage.labelImageId);
          handleChangePromotionalTextEnglish(null, promotionalMessage.labelText);
          englishPromotionalMessageFound = true;
        }
        if (promotionalMessage.languageCode === 'fr' && !frenchPromotionalMessageFound) {
          setPromotionalTextFrenchID(promotionalMessage.id);
          setShowPromotionalTextFrench(promotionalMessage.includeInReceipts);
          setPromotionalTextFrenchImgURL(promotionalMessage.joins?.u?.url);
          setPromotionalTextFrenchImgID(promotionalMessage.labelImageId);
          handleChangePromotionalTextFrench(null, promotionalMessage.labelText);
          frenchPromotionalMessageFound = true;
        }
        if (englishPromotionalMessageFound && frenchPromotionalMessageFound) {
          return true;
        }
        return false;
      });
    },
    [handleChangePromotionalTextEnglish, handleChangePromotionalTextFrench]
  );

  /**
   * Get existing promotional text and images for the payment receipts, if any.
   */
  const getCustomReceiptDetails = useCallback(
    (recordsPerPage = 25, page = 1) => {
      setShowSpinner(true);
      axios
        .post(
          `${constants.REACT_APP_HOST_API_URL}/company_promotion/search?recordsPerPage=${recordsPerPage}&page=${page}`,
          getCustomReceiptQuery(),
          {
            headers: generateRequestHeaders(),
          }
        )
        .then((resp) => {
          const promotionalMessages = resp.data?.records;
          handleCustomReceiptDetailsState(promotionalMessages);
          setShowSpinner(false);
        })
        .catch((err) => {
          setShowSpinner(false);
          addErrorToValidationList(err);
        });
    },
    [addErrorToValidationList, getCustomReceiptQuery, handleCustomReceiptDetailsState]
  );

  /**
   * Load promotional messages config for the payment receipts on page load
   */
  useEffect(() => {
    getCustomReceiptDetails();
  }, [getCustomReceiptDetails]);

  /**
   * Clear existing form state values
   */
  const clearFormState = () => {
    setPromotionalTextEnglishID(null);
    setShowPromotionalTextEnglish(false);
    handleChangePromotionalTextEnglish(null, '');
    setPromotionalTextFrenchID(null);
    setShowPromotionalTextFrench(false);
    handleChangePromotionalTextFrench(null, '');
  };

  /**
   * API call to save save/Update promotional text/img for the payment receipt.
   * @param {FormData} formData
   */
  const handleSubmitCustomReceiptPost = (formData) => {
    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}/company/${companyId}/promotion`,
      formData,
      {
        headers: generateRequestHeaders(),
      }
    );
  };

  /**
   * Save/Update promotional text/img for the payment receipts, if any.
   * @param {String} languageCode
   * @param {*} event
   */
  const handleSubmitCustomReceipt = (event, languageCode) => {
    event.preventDefault();
    setShowSpinner(true);

    const formData = new FormData();
    const params = {};

    params.type = 'TYPE_COMPANY_PROMOTION';
    params.languageCode = languageCode;

    if (languageCode === 'en') {
      params.id = promotionalTextEnglishID;
      params.includeInReceipts = showPromotionalTextEnglish;
      params.labelText = promotionalTextEnglish;
      if (promotionalTextEnglishImg) {
        formData.append('file', promotionalTextEnglishImg.get('file'));
      } else if(promotionalTextEnglishImgID) {
        params.labelImageId = promotionalTextEnglishImgID;
      }
    } else if (languageCode === 'fr') {
      params.id = promotionalTextFrenchID;
      params.includeInReceipts = showPromotionalTextFrench;
      params.labelText = promotionalTextFrench;
      if (promotionalTextFrenchImg) {
        formData.append('file', promotionalTextFrenchImg.get('file'));
      } else if(promotionalTextFrenchImgID) {
        params.labelImageId = promotionalTextFrenchImgID;
      }
    } else {
      setShowSpinner(false);
      return;
    }

    if (propertyId) {
      params.propertyId = propertyId;
    }

    formData.append('promotion', JSON.stringify(params));

    handleSubmitCustomReceiptPost(formData)
      .then(() => {
        setShowSpinner(false);
        clearFormState();
        getCustomReceiptDetails();
      })
      .catch((err) => {
        setShowSpinner(false);
        addErrorToValidationList(err);
      });
  };

  /**
   * Handle delete a image or document
   * @param {String} imgID
   * @param {String} languageCode
   */
  const handleDeletePromotionalImg = (imgID, languageCode) => {
    setShowSpinner(true);
    const formData = new FormData();
    const params = {
      id: languageCode === 'en' ? promotionalTextEnglishID : promotionalTextFrenchID,
      type: 'TYPE_COMPANY_PROMOTION',
      labelImageId: null, // Manually need to clear this id for subsequent uploads
      languageCode,
      includeInReceipts: languageCode === 'en' ? showPromotionalTextEnglish : showPromotionalTextFrench
    };
    if (propertyId) {
      params.propertyId = propertyId;
    }
    formData.append('promotion', JSON.stringify(params));
    handleSubmitCustomReceiptPost(formData)
      .then(() => {
        getCustomReceiptDetails();
      })
      .catch((err) => {
        setShowSpinner(false);
        addErrorToValidationList(err);
      });
  };
  /*********************/
  /*********************/

  return (
    <>
      <Alert validationList={validationList} />
      <Spinner visible={showSpinner} />
      <div className='card'>
        <div className='card-header'>
          <div className='row align-items-center'>
            <div className='col'>Promotional Text</div>
          </div>
        </div>
        <div className='col my-3'>
          <form onSubmit={(event) => handleSubmitCustomReceipt(event, 'en')}>
            <FieldCheckboxFC
              id='receiptPromotionalTextStatusEN'
              fieldLabel='Include English Promotional text in Receipts'
              value={showPromotionalTextEnglish}
              handleChange={setShowPromotionalTextEnglish}
            />
            <FieldTextareaFC
              id='promotionalTextEnglish'
              label='English'
              labelColumns={2}
              fieldColumns={10}
              value={promotionalTextEnglish}
              handleChange={handleChangePromotionalTextEnglish}
              disabled={!showPromotionalTextEnglish}
            />
            <FieldFileFC
              id='promotionalTextEnglishImg'
              label='EN Image'
              labelClass='col-form-label-sm'
              labelColumns='2'
              fieldColumns='10'
              disabled={!showPromotionalTextEnglish}
              accept='image/png, image/gif, image/jpeg'
              value={promotionalTextEnglishImg}
              handleChange={(data) => handleChangePromotionalTextEnglishImg(null, data)}
              help="Recommended size - H:200px, W:200px"
            />
            {promotionalTextEnglishImgURL && (
              <div className='form-group row'>
                <div className='col-sm-2'>
                  <label className='col-form-label-sm'></label>
                </div>
                <div className='col-sm-9 text-right'>
                  <img
                    src={promotionalTextEnglishImgURL + '?' + Date.now()}
                    alt='Promotional receipt Img'
                    width='100'
                    height='100'
                  />
                </div>
                <div className='col-sm-1'>
                  <button
                    type='button'
                    onClick={() => handleDeletePromotionalImg(promotionalTextEnglishImgID, 'en')}
                    disabled={!showPromotionalTextEnglish}
                    className='btn btn-danger btn-md float-right mb-4 mt-2'
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} className='fa-fw' />
                  </button>
                </div>
              </div>
            )}
            <div className='text-right'>
              <button type='submit' className={`btn btn-primary btn-md mt-2`}>
                <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Save
              </button>
            </div>
          </form>
          <form onSubmit={(event) => handleSubmitCustomReceipt(event, 'fr')}>
            <FieldCheckboxFC
              id='receiptPromotionalTextStatusFR'
              fieldLabel='Include French Promotional text in Receipts'
              value={showPromotionalTextFrench}
              handleChange={setShowPromotionalTextFrench}
            />
            <FieldTextareaFC
              id='promotionalTextFrench'
              label='French'
              labelColumns={2}
              fieldColumns={10}
              value={promotionalTextFrench}
              handleChange={handleChangePromotionalTextFrench}
              disabled={!showPromotionalTextFrench}
            />
            <FieldFileFC
              id='promotionalTextFrenchImg'
              label='FR Image'
              labelClass='col-form-label-sm'
              labelColumns='2'
              fieldColumns='10'
              disabled={!showPromotionalTextFrench}
              accept='image/png, image/gif, image/jpeg'
              value={promotionalTextFrenchImg}
              handleChange={(data) => handleChangePromotionalTextFrenchImg(null, data)}
              help="Recommended size - H:200px, W:200px"
            />
            {promotionalTextFrenchImgURL && (
              <div className='form-group row'>
                <div className='col-sm-2'>
                  <label className='col-form-label-sm'></label>
                </div>
                <div className='col-sm-9 text-right'>
                  <img
                    src={promotionalTextFrenchImgURL + '?' + Date.now()}
                    alt='Promotional receipt Img'
                    width='100'
                    height='100'
                  />
                </div>
                <div className='col-sm-1'>
                  <button
                    type='button'
                    onClick={() => handleDeletePromotionalImg(promotionalTextFrenchImgID, 'fr')}
                    disabled={!showPromotionalTextFrench}
                    className='btn btn-danger btn-md float-right mb-4 mt-2'
                  >
                    <FontAwesomeIcon icon={['fas', 'trash']} className='fa-fw' />
                  </button>
                </div>
              </div>
            )}
            <div className='text-right'>
              <button type='submit' className={`btn btn-primary btn-md mt-2`}>
                <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ServiceLevelConfigCustomReceipt.propTypes = {
  companyId: PropTypes.string,
  propertyId: PropTypes.string,
};

export default ServiceLevelConfigCustomReceipt;
