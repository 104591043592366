import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactSelect from 'react-select';
import $ from "jquery";

const HyperWalletCardDropdown = (props) => {
  const ADD_NEW_CARD_OPTION_VALUE = 0;
  const buildOptionsList = (cards) => {
    let options = []
    if (cards) {
      cards.forEach((card) => {
        const cardNumbLength = card?.cardNumber?.length;
        if(cardNumbLength > 4) {
          options.push({
            value: card.id,
            label: '****' + card?.cardNumber?.substring(cardNumbLength-4, cardNumbLength)
          });
        }
      });
    }
    options.push({
      value: 0,
      label: 'Add a New Funding Method'
    });
    return options
  }

  const listOptionSelected = (event) => {
    props.setSelectedCard(event.value);
    if (event.value === ADD_NEW_CARD_OPTION_VALUE) {
      $('#newCardModal').modal('show');
    }
  }

  // TODO: The newCardModal component state handled needs to be wired in a more parent-level component - leaving commented out as per convo w/ Arsh
  // const editCardOption = (cardId) => {
  //   props.setSelectedCard(cardId);
  //   $('#newCardModal').modal('show');
  // }

  const options = buildOptionsList(props.cards);
    
  return (
    <ReactSelect
      id='selectedCardPS'
      name='selectedCardPS'
      options={options}
      value={options.filter(function (option) {
        return option.value === props.selectedCard;
      })}
      onChange={listOptionSelected}
      placeholder='Card Select'
      formatOptionLabel= {card => (
        <div className="card-option">
          { card.value !== 0 && (props.cards && props.cards.length > 1) ? (
            <span>
              <button className='btn btn-sm btn-danger' onClick={(cardId) => props.removeCard(card.value)}>
                <FontAwesomeIcon icon={['far', 'trash-alt']} className='fa-lg'/>
              </button>
              <span>&nbsp;&nbsp;</span>
            </span>
          ) : ''}
          <span>
            {card.label}
          </span>
        </div>
      )}
    />
  );
};

export default HyperWalletCardDropdown;
