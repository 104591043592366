import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React from 'react';
import TermsContents from "../Terms";


const TermsModal = () => {
  return (
    <div
      className='modal fade'
      id='terms-modal-common'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='terms-modal-common-label'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
        <div className='modal-content shadow'>
          <div className='modal-header bg-dark text-white'>
            <h5 className='modal-title' id='billing-profile-label'>
              Letus Terms of use
            </h5>
            <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
              <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
            </button>
          </div>
          <div className='modal-body' style={{ height: '400px', overflowY: 'scroll' }}>
            <TermsContents />
          </div>
          <div className='modal-footer bg-secondary rounded-bottom d-block'>
            <div className='row'>
              <div className='col-12 text-center'>
                <button
                  type='button'
                  className='btn btn-outline-primary btn-lg'
                  onClick={() => $('#terms-modal-common').modal('hide')}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
