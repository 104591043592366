import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            validationList: [],
            userId: null,
        };
    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ["c.firstName"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "id",
                    "fieldValue": this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                "c": {
                    "targetRecordType": "TYPE_CUSTOMER",
                    "joinField": "userId",
                    "alias": "c",
                    "returnFields": [
                        "firstName",
                        "lastName",
                        "email",
                    ]
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records?.length) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    userId: response.data.records[0].userId,
                }));
            } else {
                // stop the spinner anyways
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * On mounting of the component, fetch the property lease data and all open charges associated with the property
     * lease at hand.
     */
    componentDidMount() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records?.length) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyLease: response.data.records[0],
                }));
            } else {
                // Checking if API property
                this.searchBillingAccounts();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {this.state.propertyLease?.userId ?
                    <TransactionList userId={this.state.propertyLease.userId}
                                     displayFeeAmount={true}
                                     history={this.props.history}
                                     displayAdvanced={true}
                                     textSearchPlaceholderOverride = "Search Tenant Transactions"
                                     textSearchPlaceholderOverridescheduledPayment = "Search Scheduled Payments"
                                     userType="TYPE_ADMIN" /> :
                    (this.state.userId &&
                        <TransactionList userId={this.state.userId}
                        displayFeeAmount={true}
                        history={this.props.history}
                        displayAdvanced={true}
                        textSearchPlaceholderOverride = "Search Tenant Transactions"
                        textSearchPlaceholderOverridescheduledPayment = "Search Scheduled Payments"
                        userType="TYPE_ADMIN" />
                    )
                }
            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);