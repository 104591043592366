import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The landlord payments component.
     */
    render() {

        return(
            <React.Fragment>

                <div className="content-block">

                    <div className="container">

                        <Breadcrumb parentPage="Transactions" />

                        <Alert validationList={this.state.validationList} />

                        {this.props.location.state &&
                        <Alert validationList={this.props.location.state.validationList} />
                        }

                        <TransactionList companyId={this.props.userSession.sessionRole.companyId}
                                         widthAlign = "450px"
                                         textSearchPlaceholderOverride = "Search All Transactions"
                                         userType="TYPE_LANDLORD" />

                    </div>

                </div>
            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);
