import React, { Component } from 'react';
import FieldSelect from "./FieldSelect";

class FieldSelectLanguage extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for tenant language.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value || 'en'}>
                <option value="" disabled>Select a language...</option>
                <option value="en">English</option>
                <option value="fr">French</option>
            </FieldSelect>
        )
    };
}

export default FieldSelectLanguage;
