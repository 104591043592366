import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import * as moment from "moment";
import 'moment-timezone';
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import bankAccount from "../../media/img/payments/bank-account.png";
import creditCard from "../../media/img/payments/credit-card.png";
import googlePay from "../../media/img/payments/googlepay.png";
import applePay from "../../media/img/payments/applepay.png";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import CardBrandIcon from "../common/CardBrandIcon";
import CardPreview from "../common/CardPreview";
import FeeProfiles from "../common/FeeProfiles";
import FieldAddress from "../common/FieldAddress";
import FieldCardNumber from "../common/FieldCardNumber";
import FieldCharges from "../common/FieldCharges";
import FieldOTPVerification from "../common/FieldOTPVerification";
import FieldPhone from "../common/FieldPhone";
import FieldPropertiesAsync from "../common/FieldPropertiesAsync";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import ModalFC from '../common/ModalFC';
import ModalUnionPay from "../common/ModalUnionPay";
import Propertii from "../common/Propertii";
import ReceiptPaymentTransaction from "../common/ReceiptPaymentTransaction";
import Spinner from "../common/Spinner";
import SplititPaymentScheduleTable from '../common/SplititPaymentScheduleTable';
import TermsAndConditionsHWModal from '../common/TermsAndConditionsHWModal';
class Payment extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            confirmEmail: '',

            company: {},
            charges: [{}],
            paymentMethod: {},
            billingAddress: {},

            unit: '',
            selectedProperty: '',
            propertyList: [],

            note: '',

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            customerCompleted: false,
            chargesCompleted: false,
            paymentTypeCompleted: false,
            selectedPaymentType: '',
            showSplititForm: false,
            paymentMethodCompleted: false,
            OTPCodeCompleted: false,

            showCreditCardForm: false,
            showBankAccountForm: false,
            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            amount: 0,
            feeAmount: 0,

            feeProfileList: [{}],
            feeProfileCountry: '',

            paymentTransaction: {},

            acceptedPaymentTypes: [],
            customChargeCodes: [],

            validationList: [],

            propertyQuery: {
                orderBy: "ASC",
                orderByFields: ["street1"],
            },
            paypal: false,
            unionpay: false,
            applepay: false,
            googlepay: false,
            showpaypal: true,
            showunionpay: true,
            paypalUserId: '',
            paypalHPPId: '',
            showPaypalGeneralOption:false,
            showUnionPayGeneralOption:false,
            showApplePayGeneralOption:false,
            showGooglePayGeneralOption:false,
            recaptchaEnabled: true,

            installments: 0,
            termsAndConditionsUrl: null,
            showPayLaterError: false,
            splitTermsAndConditions: false,
            disableSplititContinue: false,
            showSplititOption: false,
            shouldShowSplititModal: true,

            OTPCode: null,
            OTPPhone: null,

            showgooglepay: true,
            showapplepay: true,
            walletType:''
        };

        this.saveCustomer = this.saveCustomer.bind(this);
        this.saveCharges = this.saveCharges.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.getNoteLabel = this.getNoteLabel.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.editCharges = this.editCharges.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);

        this.createPaymentMethod = this.createPaymentMethod.bind(this);

        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);

        this.calculateFees = this.calculateFees.bind(this);
        this.getFeeProfiles = this.getFeeProfiles.bind(this);

        this.submitPayment = this.submitPayment.bind(this);

        this.getAllProperties = this.getAllProperties.bind(this);
        this.searchProperties = this.searchProperties.bind(this);
        this.handleSearchProperties = this.handleSearchProperties.bind(this);
        this.handleChangeSelectedProperties = this.handleChangeSelectedProperties.bind(this);

        this.selectPayPal = this.selectPayPal.bind(this);
        this.selectUnionPay = this.selectUnionPay.bind(this);

        this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
        this.getReCaptchaSetting = this.getReCaptchaSetting.bind(this);

        this.selectPaymentType = this.selectPaymentType.bind(this);
        this.updatePaymentTypeStatus = this.updatePaymentTypeStatus.bind(this);
        this.initiateSplitPay = this.initiateSplitPay.bind(this);
        this.showSplititForm = this.showSplititForm.bind(this);
        this.showTermsAndConditionsModal = this.showTermsAndConditionsModal.bind(this);
        this.creditCardPaymentForm = this.creditCardPaymentForm.bind(this);

        this.findSplititFee = this.findSplititFee.bind(this);
        this.showSplititModal = this.showSplititModal.bind(this);

        this.continueWithOTP = this.continueWithOTP.bind(this);
        this.editOTPCode = this.editOTPCode.bind(this);

        this.selectGooglePay = this.selectGooglePay.bind(this);
        this.selectApplePay = this.selectApplePay.bind(this);
    }

    /**
     * On mounting of the component, fetch the hosted payment page and relevant company in order to load custom charges.
     */
    componentDidMount() {

        if(this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage) {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/hosted_payment_page/${this.props.location.state.hostedPaymentPage.id}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    hostedPaymentPage: response.data,
                    firstName: this.props.location.state.firstName,
                    lastName: this.props.location.state.lastName,
                    email: this.props.location.state.email,
                    acceptedPaymentTypes: response.data.acceptedPaymentTypes,
                    customChargeCodes: response.data.chargeCodes,
                }));

                if(response.data.acceptedPaymentTypes.includes('UNION_PAY')){
                    this.setState(prevState => ({
                        ...prevState,
                        showUnionPayGeneralOption: true
                    }));
                }

                if(response.data.acceptedPaymentTypes.includes('APPLE_PAY')){
                    this.setState(prevState => ({
                        ...prevState,
                        showApplePayGeneralOption: true
                    }));
                }

                if(response.data.acceptedPaymentTypes.includes('GOOGLE_PAY')){
                    this.setState(prevState => ({
                        ...prevState,
                        showGooglePayGeneralOption: true
                    }));
                }

                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.location.state.hostedPaymentPage.companyId}/peek`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        company: response.data,
                        paymentMethod: {
                            country: response.data.country
                        }
                    }));

                    if(!this.state.customChargeCodes || this.state.customChargeCodes.length === 0){

                        this.setState(prevState => ({
                            ...prevState,
                            customChargeCodes: response.data.chargeCodes
                        }));
                    }

                }).catch(error => {
                    this.handleValidation(error);
                    this.props.history.push("");
                });

                this.getAllProperties(this.props.location.state.hostedPaymentPage.companyId);
                this.getReCaptchaSetting();

            }).catch(error => {
                this.handleValidation(error);
                this.props.history.push("");
            });
            
            if(this.props.location.state.hostedPaymentPage.paypalMerchantAccountId !== null){
                    this.setState(prevState => ({
                        ...prevState,
                        showPaypalGeneralOption: true
                    }));
            }
        } else {
            this.props.history.push({
                pathname: `/pay/${this.props?.match?.params?.pageName}`,
            });
        }
    }

    getNoteLabel(addCustom){
        const noteLabel = this.state.hostedPaymentPage?.noteLabel;
        if(noteLabel === null || noteLabel === ''){
            return addCustom ? 'Custom Note' : 'Note';
        } else {
            return noteLabel;
        }
    }

    /**
     * Save the customer portion of the hosted payment page flow.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        if(!this.state.selectedProperty && this.state.hostedPaymentPage?.propertySelectionRequired) {
            this.setCustomAlert("danger", "application.property.null");
            return;
        }

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: true,
            validationList: [],
        }));
    }

    /**
     * Save the charges portion of the hosted payment page flow. Calculates the total of all charges, and also checks to
     * see if at least one charge has been fully provided. Splices out any incomplete charges.
     *
     * @param event - The event container.
     */
    saveCharges(event) {

        event.preventDefault();

        let charges = this.state.charges;
        let amount = 0;

        // Calculate the total of all charges
        charges.forEach((charge, index) => {

            amount += parseFloat(charge.amount);

            // Splice out incomplete charges
            if(charges.length > 1) {
                if(!charge.amount || !charge.code) {
                    charges.splice(index, 1);
                }
            }
        });

        // Determine if at least one charge has been fully provided
        if(!charges[0]?.amount || !charges[0]?.code) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.charges.null',
                        message: 'Charge name or amount is incomplete.',
                    }
                }]
            });

            window.scrollTo(0, 0);

        } else {

            this.setState(prevState => ({
                ...prevState,
                chargesCompleted: true,
                charges: charges,
                amount: amount,
                validationList: [],
                showPayLaterError: false,
                showSplititForm: false
            }));
            
        }
        if(this.state.hostedPaymentPage?.splitItEnabled && (this.state.charges.some(e => e.code.toLowerCase() === 'security-deposit') || this.state.charges.some(e => e.name.toLowerCase() === 'security deposit'))){
            this.setState(prevState => ({
                ...prevState,
                showSplititOption: true,
                selectedPaymentType: 'pay_later'
            }));
            setTimeout(() => {
                this.showSplititModal();
            }, 5000)
        } else {
            this.setState(prevState => ({
                ...prevState,
                showSplititOption: false,
                selectedPaymentType: ''
            }));   
        }
    }

    showSplititModal() {
        if(this.state.shouldShowSplititModal && this.state.showSplititOption) {
            document.getElementById("popover").focus()
            this.setState((prevState) => ({
                ...prevState,
                shouldShowSplititModal: false,
            }));
        }
    }

    /**
     * Initiate the Paypal buttons container
     * Aswell as the callbacks for creation and approval of orders
     */
     initPaypalContainer() {
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));
          
          axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${this.props.location.state.hostedPaymentPage.paypalMerchantAccountId}/peek`,
          {
              headers: this.generateRequestHeaders(),
          }).then((response) => {
            
            this.initPaypalSDK(response.data.currency, process.env.REACT_APP_PAYPAL_CLIENT_ID, response.data.accountNumber)
            .then(() => {
                const FUNDING_SOURCES = [
                    window.paypal.FUNDING.PAYPAL,
                    window.paypal.FUNDING.PAYLATER
                ];
                FUNDING_SOURCES.forEach((fundingSource) => {
                const buttons = window.paypal.Buttons({
                  style: {
                    layout: 'horizontal',
                    height: 38,
                    tagline: false,
                  },
                  fundingSource: fundingSource,
                  createOrder: (data) => {
                    if (!this.state.reCaptchaId && this.state.recaptchaEnabled) {
                      return;
                    }
                    return fetch(
                      `${constants.REACT_APP_HOST_API_URL}/paypal/create_order_hpp`,
                      this.buildCreateOrderObject()
                    )
                      .then(function (res) {
                        return res.json();
                      })
                      .then((data) => {
                        this.setState((prevState) => ({
                          ...prevState,
                          paypalUserId: data.userId,
                          paypalHPPId: data.hostedPaymentPageId,
                        }));

                        return data.paypalOrderId;
                      })
                      .catch((error) => {
                        this.handleValidation(error);
                      });
                  },
                  onApprove: (data) => {
                    this.setState((prevState) => ({
                      ...prevState,
                      spinner: true,
                    }));
                    return fetch(
                      `${constants.REACT_APP_HOST_API_URL}/paypal/capture_order_hpp`,
                      this.buildCaptureOrderObject(data)
                    )
                      .then((res) => {
                        if (!res.ok) {
                            this.setState(prevState => ({
                                ...prevState,
                                spinner: false
                            }));
                            alert('Something went wrong:' + JSON.stringify(data));
                        }
                        return res.json();
                      })
                      .then((data) => {
                        if(data.createDate === null){
                            data.createDate = moment();
                        }
                        this.setState(prevState => ({
                            ...prevState,
                            paymentTransaction: data,
                            spinner: false
                        }));
                        $('#receipt-payment-transaction').modal('show');
                      })
                      .catch((error) => {
                        this.handleValidation(error);
                      });
                  },
                });
                const elementExists = !!document.getElementById('paypal-button');
                if(elementExists) {
                    buttons.render('#paypal-buttons-container');
                }
                })
            }).catch((error) => {
                this.handleValidation(error);
            }) 
            })
           
        }          

    initPaypalSDK(currency = 'USD', clientId, merchantId) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&merchant-id=${merchantId}&currency=${currency}&intent=capture&components=buttons`;
            //script.dataset.orderId = orderId;
            const currentScript = document.getElementById('paypal-script');
            if (currentScript) {
                document.body.removeChild(currentScript);
            }
            script.setAttribute('id', 'paypal-script');
            document.body.appendChild(script);

            const injectScript = () => {
                this.setState(prevState => ({
                    ...prevState,
                    paypal: window.paypal,
                    spinner: false,
                }));

                script.removeEventListener('load', injectScript);
                resolve();
            };

            script.addEventListener('load', injectScript);
            script.addEventListener('error', (error) => {
                this.setState({
                    spinner: false,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: 'Something went wrong trying to connect to PayPal. '
                        },
                        showMessage: true,
                    }],
                });
            });
        });
    }
    
    buildCreateOrderObject() {

        const headers = {
            "Authorization": this.buildTokenString(),
            "Content-Type": "application/json"
        };

        if(!this.state.reCaptchaId && this.state.recaptchaEnabled) {
            return;
        } 
   
        let createOrderObject = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            "billingAddress": {
                "type": "TYPE_ADDRESS",
                "id": "",
                "joins": {},
                "createDate": null,
                "updateDate": null,
                "userType": null,
                "userId": null,
                "addressType": "BILLING",
                "suite": null,
                "street1": "",
                "street2": null,
                "city": "",
                "province": "",
                "postalCode": "",
                "country": "",
                "monthsAtAddress": 0
            },
            "charges": this.state.charges,
            "email": this.state.email,
            "firstName": this.state.firstName,
            "hostedPaymentPageId": this.state.hostedPaymentPage.id,
            "lastName": this.state.lastName,
            "paymentMethod": {
                "type": "TYPE_PAY_PAL",
                "id": "",
                "joins": {},
                "createDate": null,
                "updateDate": null,
                "userType": null,
                "userId": null,
                "country": null,
                "token": null,
                "providerTokens": {},
                "brand": null,
                "cardType": null,
                "cardNumber": null,
                "cvv": null,
                "bin": null,
                "last4": null,
                "expiryMonth": null,
                "expiryYear": null,
                "nameOnCard": null,
                "billingAddressId": null,
                "personal": false,
                "regulated": false,
                "securityCode": null
            },
            "note": this.state.note,
            "recaptchaResponse": this.state.recaptchaEnabled ? this.state.reCaptchaId : null
          })
          }
          return createOrderObject;
        };
        

    
    
    buildCaptureOrderObject(data) {
       
        let captureOrderObject = {
          method: 'POST',
          headers: {
            "Authorization": this.buildTokenString(),
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "orderId": data.orderID,
            "userId": this.state.paypalUserId,
            "hostedPaymentPageId": this.state.paypalHPPId, 
            "amount": this.state.amount,
            "propertyId": this.state.selectedProperty?.value,
            "note": this.state.note,
            "paymentForUnit": this.state.unit
          }),
        };
        return captureOrderObject;
    }
    
    buildTokenString() {
        let tokenString = "Bearer " + window.localStorage.getItem('access_token');
        return tokenString;
    }
    

    /**
     * Save the selected payment method. Handle basic validation such as no payment method selected, selecting an
     * existing payment method, or creating a new payment method. After saving, calculate the transaction fees based on
     * the selected charges and payment method information.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#confirm-unionpay').modal('show');

            return null;
        }

        // Handle no selection
        if(Object.keys(this.state.paymentMethod).length === 0) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.method.null',
                        message: 'Select a payment method.',
                    }
                }]
            });

            window.scrollTo(0, 0);

            return null;
        }

        let paymentMethod = this.state.paymentMethod;

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if(paymentMethod.type === 'TYPE_CREDIT_CARD' || paymentMethod.type === 'TYPE_INSTALLMENT_PAY') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && this.state.company?.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

       
        if(this.state.showCreditCardForm || this.state.showBankAccountForm || this.state.paypal || this.state.unionpay || this.state.applepay || this.state.googlepay || this.state.paymentTypeCompleted) {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted: true,
                reCaptchaId: null,
                validationList: []
            }));
            let newPaymentMethod = {
                id: "",
                joins: {},
                createDate: null,
                updateDate: null,
                userType: null,
                userId: null,
                token: null,
                providerTokens: {},
                country: null,
                brand: null,
                cardType: null,
                cardNumber: null,
                cvv: null,
                bin: null,
                last4: null,
                expiryMonth: null,
                expiryYear: null,
                nameOnCard: null,
                billingAddressId: null,
                personal: false,
                regulated: false,
                securityCode: null
            }
            if(this.state.showpaypal) {
                newPaymentMethod.type = "TYPE_PAY_PAL"}
            else if(this.state.showapplepay) {
                newPaymentMethod = {
                    ...newPaymentMethod,
                    type: "TYPE_WALLET",
                    walletType: "APPLE_PAY",
                }
            } else if(this.state.showgooglepay) {
                newPaymentMethod = {
                    ...newPaymentMethod,
                    type: "TYPE_WALLET",
                    walletType: "GOOGLE_PAY",
                }
            } else if (this.state.showunionpay) {
                newPaymentMethod = {
                    ...newPaymentMethod,
                    type: "TYPE_CREDIT_CARD",
                    country: "CN",
                    brand: "UNION_PAY",
                    cardType: "CREDIT",
                }
            } else {
                newPaymentMethod = paymentMethod;
            }

            if(this.state.selectedPaymentType !== 'pay_later')
                this.calculateFees(newPaymentMethod);
            
            //Call PayPal Init
       
            if(this.state.showPaypalGeneralOption){
                setTimeout(() => {
                    this.initPaypalContainer();
                }, 50);
            }
        }
    }

    /**
     * Edit the customer portion of the hosted payment page flow.
     */
    editCustomer() {

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: false,
            chargesCompleted: false,
            paymentTypeCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the charges portion of the hosted payment page flow.
     */
    editCharges() {

        this.setState(prevState => ({
            ...prevState,
            chargesCompleted: false,
            paymentTypeCompleted: false,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Edit the payment method portion of the hosted payment page flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodCompleted: false,
        }));
    }

    /**
     * Select Paypal Method.
     */
     selectPayPal() {
        this.setState(prevState => ({
            ...prevState,
            paypal:!this.state.paypal,
            showpaypal : true
        }), () => {
           
            if(this.state.paypal){
                this.setState(prevState => ({
                    ...prevState,
                    showBankAccountForm: false,
                    showCreditCardForm: false,
                    showunionpay: false,
                    showapplepay: false,
                    showgooglepay: false,
                    applepay: false,
                    googlepay: false,
                }));
            }
            
        });
    }

    /**
     * Select UnionPay Method.
     */
    selectUnionPay() {
        this.setState(prevState => ({
            ...prevState,
            unionpay:!this.state.unionpay,
            showunionpay : true
        }), () => {

            if(this.state.unionpay){
                this.setState(prevState => ({
                    ...prevState,
                    showBankAccountForm: false,
                    showCreditCardForm: false,
                    showpaypal: false,
                    showapplepay: false,
                    showgooglepay: false,
                }));
            }

        });
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Initialize a new instance of a payment method.
     *
     * @param paymentType - The type of the payment method (i.e. credit_card, bank_account, etc.)
     */
    createPaymentMethod(paymentType) {
        this.setState(prevState => ({
            ...prevState,
            showpaypal: false,
            showunionpay: false,
            showapplepay: false,
            showgooglepay: false
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    country: prevState.paymentMethod.country
                },    
                showCreditCardForm: paymentType === 'credit_card',
                showBankAccountForm: paymentType === 'bank_account',
                applepay: false,
                googlepay: false
            }));

            if(document.getElementById("billingAddressId").options.length <= 2){
                this.setState(prevState => ({
                    ...prevState,
                    showBillingAddressForm: true
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {
            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));
        } else {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    showBillingAddressForm: true,
                    billingAddress: {
                        ...response.data,
                        addressType: 'BILLING',
                    },
                    paymentMethod: {
                        ...prevState.paymentMethod,
                        billingAddressId: response.data.id
                    },
                }));
            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Calculate the transaction fees that will be charged to the user prior to making the payment. Transaction fees can
     * only be calculated after the billing account, charge total, and payment method has been provided.
     */
    calculateFees(paymentMethod) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/calculatefee`, {
            amount: this.state.amount,
            hppId: this.state?.hostedPaymentPage?.id,
            companyId: this.state.company.id,
            paymentMethod,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeAmount: response.data
            }));

        }).catch(error => {
            this.handleValidation(error);
            this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted: false
            }));
            window.scrollTo(0, 0);
        });
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.company.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeProfileList: response.data,
                feeProfileCountry: this.state.company.country
            }));

            $("#fees").modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Confirm and submit the payment.
     */
    submitPayment() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        })); 
           
        let billingAddressPayPal = {
                type: "TYPE_ADDRESS",
                id: "",
                joins: {},
                createDate: null,
                updateDate: null,
                userType: null,
                userId: null,
                addressType: "BILLING",
                suite: null,
                street1: "",
                street2: null,
                city: "",
                province: "",
                postalCode: "",
                country: "",
                monthsAtAddress: 0
        }
        let paymentMethodPayPal = {
                type: "TYPE_PAY_PAL",
                id: "",
                joins: {},
                createDate: null,
                updateDate: null,
                userType: null,
                userId: null,
                country: null,
                token: null,
                providerTokens: {},
                brand: null,
                cardType: null,
                cardNumber: null,
                cvv: null,
                bin: null,
                last4: null,
                expiryMonth: null,
                expiryYear: null,
                nameOnCard: null,
                billingAddressId: null,
                personal: false,
                regulated: false,
                securityCode: null
        }
        let billingAddressUnionPay = {
            type: "TYPE_ADDRESS",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            addressType: "BILLING",
            suite: null,
            street1: "",
            street2: null,
            city: "",
            province: "",
            postalCode: "",
            country: "",
            monthsAtAddress: 0
        }
        let paymentMethodUnionPay = {
            type: "TYPE_CREDIT_CARD",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            country: "CN",
            token: null,
            providerTokens: {},
            brand: "UNION_PAY",
            cardType: "CREDIT",
            cardNumber: null,
            cvv: null,
            bin: null,
            last4: null,
            expiryMonth: null,
            expiryYear: null,
            nameOnCard: null,
            billingAddressId: null,
            personal: false,
            regulated: false,
            securityCode: null
        }

        let billingAddressApplePay = {
            type: "TYPE_ADDRESS",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            addressType: "BILLING",
            suite: null,
            street1: "",
            street2: null,
            city: "",
            province: "",
            postalCode: "",
            country: "",
            monthsAtAddress: 0
        }

        let paymentMethodApplePay = {
            type: "TYPE_WALLET",
            walletType: "APPLE_PAY",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            country: "CN",
            token: null,
            providerTokens: {}
        }

        let billingAddressGooglePay = {
            type: "TYPE_ADDRESS",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            addressType: "BILLING",
            suite: null,
            street1: "",
            street2: null,
            city: "",
            province: "",
            postalCode: "",
            country: "",
            monthsAtAddress: 0
        }
        let paymentMethodGooglePay = {
            type: "TYPE_WALLET",
            walletType: "GOOGLE_PAY",
            id: "",
            joins: {},
            createDate: null,
            updateDate: null,
            userType: null,
            userId: null,
            country: "CN",
            token: null,
            providerTokens: {}
        }

        let params = {}
        if(this.state.selectedPaymentType === 'pay_later'){
            params = {
                acceptedTermsAndConditions: this.state.splitTermsAndConditions,
                amount: this.state.amount,
                charges: this.state.charges,
                billingAddress: this.state.billingAddress,
                email: this.state.email,
                firstName: this.state.firstName,
                phone: this.state.phone,
                hostedPaymentPageId: this.state.hostedPaymentPage.id,
                lastName: this.state.lastName,
                paymentMethod:{
                    acceptedTermsAndConditions: this.state.splitTermsAndConditions,
                    amount: (this.state.amount/this.state.installments).toFixed(2),
                    creditCardFundingMethod: this.state.paymentMethod,
                    fundingMethodType: "TYPE_CREDIT_CARD",
                    installments: this.state.installments,
                    type: "TYPE_INSTALLMENT_PAY",
                    country: "CA",
                },
                note: this.state.note
            }
        } else {
            params = {
                billingAddress: this.state.showpaypal ? billingAddressPayPal : (this.state.showunionpay? billingAddressUnionPay : (this.state.showgooglepay? billingAddressGooglePay: (this.state.showapplepay? billingAddressApplePay : this.state.billingAddress))),
                charges: this.state.charges,
                email: this.state.email,
                firstName: this.state.firstName,
                hostedPaymentPageId: this.state.hostedPaymentPage.id,
                lastName: this.state.lastName,
                paymentMethod: this.state.showpaypal ? paymentMethodPayPal : (this.state.showunionpay? paymentMethodUnionPay : (this.state.showgooglepay? paymentMethodGooglePay : (this.state.showapplepay? paymentMethodApplePay : this.state.paymentMethod))),
                note: this.state.note
            }
        }

        // Check if the property has been selected
        if(this.state.selectedProperty?.value) {
            params.paymentForProperty = this.state.selectedProperty?.value;
        }

        // Check if the unit has been selected
        if(this.state.unit) {
            params.paymentForUnit = this.state.unit;
        }

        if(params.paymentMethod.type === 'TYPE_BANK_ACCOUNT'){
            params.paymentMethod.country = this.state.company?.country
        }

        const headers = this.generateRequestHeaders();

        if(this.state.reCaptchaId) {
            params.recaptchaResponse = this.state.reCaptchaId;
        } else if(!paymentMethodPayPal) {
            return;
        }

        const sessionId = window.ka?.sessionId;
        if(sessionId) {
            headers.blueSnapSessionId = sessionId;
        }

        params.verificationCode = this.state.OTPCode;
        params.phone = this.state.OTPPhone;

        axios.post(`${constants.REACT_APP_HOST_API_URL}/hostedpayment`, params, {
            headers: headers
        }).then(response => {
            
            if(response.data.createDate === null){
                response.data.createDate = moment();
            }
             
            this.setState(prevState => ({
                ...prevState,
                paymentTransaction: response.data,
                spinner: false
            }));

            if(this.state.showunionpay && response.data?.note){
                const noteObj = JSON.parse(response.data.note);
                if(noteObj.url) {
                    this.props.history.push(`/pay/${this.props.location.state.hostedPaymentPage.pageName}`);
                    window.open(noteObj.url);
                }

            } else{

                $('#receipt-payment-transaction').modal({backdrop:'static', keyboard:false, show: true});
            }

        }).catch(error => {
            const errorData = error?.response?.data;
            if(errorData.message?.includes('Negative amount on PAYMENT transaction')) {
                // This is not a very good catch, the BE should instead have an appropriate error code for this case
                if(errorData.fieldErrors?.[0]?.errorCode === 'RM_ERROR_INVALID_AMOUNT' && errorData.fieldErrors?.[0]?.fieldValue) {
                    const minTransactionAmount = parseFloat(this.state.amount) + parseFloat(Math.abs(errorData.fieldErrors?.[0]?.fieldValue));
                    errorData.message = `Transaction amount must be greater then ${minTransactionAmount}`;
                } 
            }
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
    }

    /**
     * Confirm and submit the payment.
     */
    submitWalletPayment() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/WALLET/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let billingAddress = {
                type: "TYPE_ADDRESS",
                id: "",
                joins: {},
                createDate: null,
                updateDate: null,
                userType: null,
                userId: null,
                addressType: "BILLING",
                suite: null,
                street1: " ",
                street2: null,
                city: "",
                province: "",
                postalCode: "",
                country: "",
                monthsAtAddress: 0
            }

            let paymentMethod = response.data;
            paymentMethod.country = this.state.company.country;

            if(this.state.showapplepay){

                paymentMethod.walletType = 'APPLE_PAY';

            } else if (this.state.showgooglepay){

                paymentMethod.walletType = 'GOOGLE_PAY';
            }

            let params = {
                billingAddress: billingAddress,
                charges: this.state.charges,
                email: this.state.email,
                firstName: this.state.firstName,
                hostedPaymentPageId: this.state.hostedPaymentPage.id,
                lastName: this.state.lastName,
                paymentMethod: paymentMethod,
                note: this.state.note

            }

            // Check if the property has been selected
            if(this.state.selectedProperty?.value) {
                params.paymentForProperty = this.state.selectedProperty?.value;
            }

            // Check if the unit has been selected
            if(this.state.unit) {
                params.paymentForUnit = this.state.unit;
            }

            const headers = this.generateRequestHeaders();

            if(this.state.reCaptchaId) {
                params.recaptchaResponse = this.state.reCaptchaId;
            } else {
                return;
            }

            const sessionId = window.ka?.sessionId;
            if(sessionId) {
                headers.blueSnapSessionId = sessionId;
            }

            params.verificationCode = this.state.OTPCode;
            params.phone = this.state.OTPPhone;

            axios.post(`${constants.REACT_APP_HOST_API_URL}/hostedpayment`, params, {
                headers: headers
            }).then(response => {

                if(response.data.createDate === null){
                    response.data.createDate = moment();
                }

                this.setState(prevState => ({
                    ...prevState,
                    paymentTransaction: response.data,
                    paymentMethod: response.data?.joins?.wallet,
                    spinner: false
                }));

                this.initBlueSnapContainer();

            }).catch(error => {
                const errorData = error?.response?.data;
                if(errorData.message?.includes('Negative amount on PAYMENT transaction')) {
                    // This is not a very good catch, the BE should instead have an appropriate error code for this case
                    if(errorData.fieldErrors?.[0]?.errorCode === 'RM_ERROR_INVALID_AMOUNT' && errorData.fieldErrors?.[0]?.fieldValue) {
                        const minTransactionAmount = parseFloat(this.state.amount) + parseFloat(Math.abs(errorData.fieldErrors?.[0]?.fieldValue));
                        errorData.message = `Transaction amount must be greater then ${minTransactionAmount}`;
                    }
                }
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    get acceptsCreditCards() {
        return this.state.acceptedPaymentTypes.find((type) =>
            type === 'MASTERCARD' ||
            type === 'DISCOVER' ||
            type === 'VISA' ||
            type === 'UNION_PAY' ||
            type === 'VISA_ELECTRON' ||
            type === 'JCB' ||
            type === 'MAESTRO' ||
            type === 'DINERS_CLUB' ||
            type === 'AMERICAN_EXPRESS'
        ) != null;
    }

    /**
     * Handle changes to the properties field.
     *
     * @param selectedOptions - The selected property
     */
    handleChangeSelectedProperties(selectedOptions) {
        if(selectedOptions) {
            this.setState((prevState) => ({
                ...prevState,
                selectedProperty: selectedOptions,
            }));
        }
    }

    /**
     * Search properties based on the provided input.
     *
     * @param {*} inputValue
     * @param {*} callback
     */
    searchProperties(inputValue, callback) {
        const { formatMessage } = this.props.intl;

        this.handleSearchProperties(inputValue)
        .then((response) => {
            const propertyList = response?.data?.records;
            const options = [];
            if(propertyList) {
                propertyList.forEach((data) => {
                options.push({
                    value: data.id,
                    label:
                    data.street1 +
                    (data.street2 ? ", " + data.street2 : "") +
                    ", " +
                    (data.city === null? '' : data.city) +
                    ", " +
                    (data.country === "CA"
                        ? formatMessage({ id: "province." + data.province })
                        : formatMessage({ id: "state." + data.province })) +
                    ", " +
                    formatMessage({ id: "country." + data.country }) +
                    " " +
                    (data.postalCode === null? '' : data.postalCode),
                });
                });
            }
            callback(options);
        })
        .catch((error) => {
            this.handleValidation(error);
        });
    }

    /**
     * Handles property search based on the provided input
     *
     * @param {*} inputValue
     */
    handleSearchProperties(inputValue) {
        if (!inputValue || !this.state?.hostedPaymentPage?.companyId) {
            return null;
        }

        let propertyQuery = this.state.propertyQuery;

        propertyQuery.joins = {
            company: {
                targetRecordType: "TYPE_COMPANY",
                joinField: "companyId",
                alias: "company",
                returnFields: ["name"],
            },
        };

        propertyQuery.conditionList = [];
        propertyQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: this.state.hostedPaymentPage?.companyId
            },
            {
                type: "STRING",
                logicalOperator: "AND",
                openBrackets: "(",
                closeBrackets: null,
                fieldName: "id",
                operator: "LIKE_IGNORE_CASE",
                fieldValue: inputValue,
            },
            {
                type: "STRING",
                logicalOperator: "OR",
                openBrackets: null,
                closeBrackets: ")",
                fieldName: "propertyName,street1,city,postalCode,propertyIdentifier",
                operator: "LIKE_IGNORE_CASE",
                fieldValue: inputValue,
            }
        );

        return axios.post(
        `${constants.REACT_APP_HOST_API_URL}/company/${this.state.hostedPaymentPage.companyId}/properties`,
            this.state.propertyQuery
        );
    }

    /**
     * Get a list of all properties linked to a company or its children
     * @param companyId
     */
    getAllProperties(companyId) {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/properties?page=1&recordsPerPage=300`, {
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: companyId
                }
            ],
        }).then(response => {
            this.setState((prevState) => ({
                ...prevState,
                propertyList: response.data.records,
            }));
        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
    }

    /**
     * Handles onReCaptchaChange
     */
    onReCaptchaChange(value) {
        this.setState((prevState) => ({
            ...prevState,
            reCaptchaId: value,
        }));

        if(this.state.reCaptchaId && (this.state.showapplepay || this.state.showgooglepay)){

            setTimeout(() => {
                this.submitWalletPayment();
            }, 50);
        }
    }


    /**
     * Determines whether recaptcha is enabled or disabled for env. Only should be disabled for QA automation testing
     */
    getReCaptchaSetting(){

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recaptcha_enabled`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data !== null){
                this.setState(prevState => ({
                    ...prevState,
                    recaptchaEnabled: response.data
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    selectPaymentType(paymentType){
        if(paymentType === "pay_later" && (this.state.amount < 80 || this.state.amount > 20000)) {
            this.setState((prevState) => ({
                ...prevState,
                showPayLaterError: true,
            }));
        } else {
            this.setState((prevState) => ({
                ...prevState,
                showPayLaterError: false,
                selectedPaymentType: paymentType,
            }));
        }

        if(this.state.shouldShowSplititModal) {
            document.getElementById("popover").focus()
            this.setState((prevState) => ({
                ...prevState,
                shouldShowSplititModal: false,
            }));
        }
    }
    
    updatePaymentTypeStatus(value){
        if(this.state.selectedPaymentType !== 'pay_later') {
            this.setState((prevState) => ({
                ...prevState,
                paymentTypeCompleted: value,
                showCreditCardForm: false,
                paymentMethod: {},
                installments: 0,
                feeAmount: 0,
                paymentMethodCompleted: false,
                shouldShowSplititModal: false
            }));
        } else {
            if(this.state.showPayLaterError) {
                return
            } else if (this.state.amount < 80 || this.state.amount > 20000) {
                this.selectPaymentType('pay_later')
                return
            }
            this.setState((prevState) => ({
                ...prevState,
                paymentTypeCompleted: value,
                paymentMethod: {},
                installments: 0,
                feeAmount: 0,
                paymentMethodCompleted: false,
                showSplititForm: false,
                shouldShowSplititModal: false
            }));
            this.showSplititForm()
        }
    }
    
    initiateSplitPay(){
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));
        axios.post(`${constants.REACT_APP_HOST_API_URL}/installment_pay/start`, {
            amount: this.state.amount,
            hppId: this.state?.hostedPaymentPage?.id
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            let paymentMethod = {
                type: "TYPE_INSTALLMENT_PAY",
                installments: response.data?.installments,
                amount: this.state.amount,
                billingAccount: {
                    companyId: this.state.company.id
                }
            }
            this.calculateFees(paymentMethod);
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                installments: response.data?.installments,
                termsAndConditionsUrl: response.data?.termsAndConditionsUrl,
                paymentMethod: {}
            }));

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                disableSplititContinue: true
            }));
        });
    }

    showSplititForm(){
        if(this.state.showSplititForm) return
        this.initiateSplitPay()
        this.createPaymentMethod('credit_card')

        this.setState((prevState) => ({
            ...prevState,
            showSplititForm: true
        }));
    }

    showTermsAndConditionsModal(){
        $('#termsAndConditionsHWModal').modal('show');
    };

    creditCardPaymentForm(isSplitit = false){
        return (
            <>
                { isSplitit &&
                    <>
                        <FieldPhone containerClass="mt-3" 
                            labelColumns="12" fieldColumns="12"
                            id="phone" label="Phone" parent={this} 
                            required={true} value={this.state['phone']} model={null}/>
                        <label><span>Credit Card (Debit Not Accepted):</span></label>
                    </>
                }
                <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} />
                <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />
                <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />
                <div className="row mb-3">
                    <div className="col">
                        <FieldSelect id="expiryMonth" label="Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                            <option value="">-</option>
                            <option value="1">01 - Jan</option>
                            <option value="2">02 - Feb</option>
                            <option value="3">03 - Mar</option>
                            <option value="4">04 - Apr</option>
                            <option value="5">05 - May</option>
                            <option value="6">06 - Jun</option>
                            <option value="7">07 - Jul</option>
                            <option value="8">08 - Aug</option>
                            <option value="9">09 - Sep</option>
                            <option value="10">10 - Oct</option>
                            <option value="11">11 - Nov</option>
                            <option value="12">12 - Dec</option>
                        </FieldSelect>
                    </div>
                    <div className="col">
                        <FieldSelect id="expiryYear" label="Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                            <option value="">-</option>
                            <option value="2022">2022</option>
                            <option value="2023">2023</option>
                            <option value="2024">2024</option>
                            <option value="2025">2025</option>
                            <option value="2026">2026</option>
                            <option value="2027">2027</option>
                            <option value="2028">2028</option>
                            <option value="2029">2029</option>
                            <option value="2030">2030</option>
                            <option value="2031">2031</option>
                            <option value="2032">2032</option>
                            <option value="2033">2033</option>
                        </FieldSelect>
                    </div>
                    <div className="col">
                        <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} model="paymentMethod" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.securityCode} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />
                    </div>
                </div>
                {!this.state.showBillingAddressForm &&
                    <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>
                        <option value=""></option>
                        <option value="NEW">Add new billing address...</option>
                    </FieldSelect>
                }
                {this.state.showBillingAddressForm &&
                    <div className="mb-2">
                        <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} isForCreditCard={true} required={true}/>
                    </div>
                }
            </>
        )
    }

    findSplititFee(){
        return this.state.feeProfileList.filter(fee => fee.paymentType === 'TYPE_INSTALLMENT_PAY')
    }

    /**
     * Edit the entered secure OTP code
     */
    editOTPCode() {
        this.setState(prevState => ({
            ...prevState,
            OTPCodeCompleted: false,
        }));
    }

    /**
     * Continue with the payment once the user has added an OTP
     * @param {string} OTPPhone
     * @param {string} OTPCode
     */
    continueWithOTP(event, OTPPhone, OTPCode) {
        event.preventDefault();
        this.setState((prevState) => ({
            ...prevState,
            OTPPhone: OTPPhone,
            OTPCode: OTPCode,
            OTPCodeCompleted: OTPCode ? true : false
        }));
    }

    /**
     * Select Apple Pay.
     */
    selectApplePay() {
        this.setState(prevState => ({
            ...prevState,
            applepay : !this.state.applepay,
            showapplepay: true
        }), () => {

            if(this.state.applepay){
                this.setState(prevState => ({
                    ...prevState,
                    showBankAccountForm: false,
                    showCreditCardForm: false,
                    showpaypal: false,
                    showunionpay: false,
                    showgooglepay: false,
                    googlepay: false,
                }));
            }

        });
    }

    /**
     * Select Google Pay.
     */
    selectGooglePay() {
        this.setState(prevState => ({
            ...prevState,
            googlepay : !this.state.googlepay,
            showgooglepay: true
        }), () => {

            if(this.state.googlepay){
                this.setState(prevState => ({
                    ...prevState,
                    showBankAccountForm: false,
                    showCreditCardForm: false,
                    showpaypal: false,
                    showunionpay: false,
                    showapplepay: false,
                    applepay: false,
                }));
            }
        });
    }

    initBluesnapWalletSDK(validationUrl, sdkRequest) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = validationUrl;
            script.type = `text/javascript`;
            const currentScript = document.getElementById('bluesnap-script');
            if (currentScript) {
                document.body.removeChild(currentScript);
            }
            script.setAttribute('id', 'bluesnap-script');
            document.body.appendChild(script);

            script.addEventListener('error', (error) => {
                this.setState({
                    spinner: false,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: 'Something went wrong trying to connect to Bluesnap. ' +
                                'Please contact customer support immediately by emailing help@let.us or calling 1-888-665-8870.'
                        },
                        showMessage: true,
                    }],
                });
            });

            const injectScript = () => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));

                window.bluesnap.walletButtonSetup(sdkRequest);

                script.removeEventListener('load', injectScript);
                resolve();
            };

            script.addEventListener('load', injectScript);
        });
    }

    initBlueSnapContainer() {

        let paymentAmount = Number((this.state?.amount + this.state?.feeAmount)).toFixed(2);
        let paymentData = {
            currencyCode: this.state.paymentTransaction.currency,
            countryCode: this.state.paymentMethod.country,
            total: {
                label: 'My Total',
                amount: paymentAmount
            }
        };

        let sdkRequest = {
            token: this.state.paymentMethod?.walletToken,
            applePay: this.state.paymentMethod?.walletType === 'APPLE_PAY',
            googlePay: this.state.paymentMethod?.walletType === 'GOOGLE_PAY',
            paymentData: paymentData,
            onEvent: {

                paymentAuthorized: (success, error) => {

                    return axios.post(`${constants.REACT_APP_HOST_API_URL}/confirmpayment`, {
                        transactionId: this.state.paymentTransaction.id,
                    },{
                        headers: this.generateRequestHeaders()
                    }).then(response => {

                        this.setState(prevState => ({
                            ...prevState,
                            paymentTransaction: response.data
                        }));

                        $('#receipt-payment-transaction').modal({backdrop:'static', keyboard:false, show: true});
                        success();

                    }).catch(error => {
                        error();
                        this.handleValidation(error);
                    });
                },
                error: event => {

                    if((event.info?.error && event.info?.error[0] && event.info?.error[0]?.includes("No payment method available")) ||
                        (event?.status && event.status?.includes("No payment method available"))){

                        this.setState({
                            spinner: false,
                            validationList: [{
                                alert: {
                                    type: 'danger',
                                    code: event.status,
                                    message: 'Cannot make payment because no payment method is linked to Wallet. Please login to wallet and add payment method.'
                                }
                            }]
                        });
                    } else {

                        this.setState({
                            spinner: false,
                            validationList: [{
                                alert: {
                                    type: 'danger',
                                    code: event.status,
                                    message: event.info?.errors[0]
                                }
                            }]
                        });
                    }
                },
            }
        };

        this.initBluesnapWalletSDK(this.state.paymentMethod?.walletValidationUrl, sdkRequest)
            .then(() => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false
                }));

            }).catch((error) => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component.
     */
    render() {

        $(function() {
            $('[data-toggle="popover"]').popover();
            $('[data-toggle="tooltip"]').tooltip();
        });

        return(
            
            <div className="content-block kaxsdc" data-event='load'>
                <Spinner visible={this.state.spinner} />
                <TermsAndConditionsHWModal termsAndConditionsUrl={this.state.termsAndConditionsUrl} />
                <ModalFC 
                    modalId='howSplitItWorksModal' 
                    modalTitle='How Letus Split Works' 
                    modalBody={<iframe src={constants.SPLITIT_HOW_IT_WORKS_URL} 
                        height="500" width="100%"
                        title="Splitit how it works"></iframe>}/>
                <ModalFC 
                    modalId='splitItPaymentScheduleModal' 
                    modalTitle='Payment Schedule' 
                    modalBody={
                        <SplititPaymentScheduleTable 
                            numberOfInstallments={Number(this.state.installments)}
                            totalPaymentAmount={Number(this.state.amount)}
                        />
                    }/>
                {this.props.location?.state?.hostedPaymentPage &&
                <div className="jumbotron jumbotron-fluid mb-0"
                     style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${this.props.location?.state?.hostedPaymentPage?.customFields?.background ? this.props.location.state?.hostedPaymentPage?.customFields?.background : 'https://dheiziex291vk.cloudfront.net/static/backgrounds/college.jpg'}') no-repeat center center scroll`}}>
                    <div className="container text-md-left">
                        <h1 className="display-5 pt-3 text-white">
                            <FormattedMessage id="hosted.payment.heading" values={{firstName: this.props.location.state.firstName}}/>
                        </h1>
                        <p className="lead text-white">
                            <FormattedMessage id='hosted.payment.subheading' values={{companyName: this.state.company.name}}/>
                            {this.state.showPaypalGeneralOption}
                        </p>
                    </div>
                </div>
                }

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    Personal 
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        Who is making this payment?
                                    </p>
                                </div>

                                <div className="card-body">

                                    {this.state.customerCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCustomer()}>
                                                <div className="">
                                                    {this.state.firstName} {this.state.lastName}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    {this.state.email}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCustomer()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.customerCompleted &&
                                    <form onSubmit={this.saveCustomer} autoComplete="off">

                                        <FieldText id="firstName" label="First Name" parent={this} required={true} value={this.state['firstName']} />

                                        <FieldText id="lastName" label="Last Name" parent={this} required={true} value={this.state['lastName']} />

                                        <FieldText id="email" label="Email" type="email" parent={this} required={true} value={this.state['email']} />

                                        {this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage &&
                                            <>
                                                {this.state.hostedPaymentPage?.propertySelectionRequired &&
                                                    <FieldPropertiesAsync
                                                    id="propertyList"
                                                    label="Property"
                                                    labelClass="col-form-label-sm align-self-center"
                                                    value={[this.state.selectedProperty]}
                                                    parent={this}
                                                    options={this.state.propertyList}
                                                    isMulti={false}
                                                    closeMenuOnSelect={true}
                                                    required={this.state.hostedPaymentPage?.propertySelectionRequired}
                                                    handleChange={this.handleChangeSelectedProperties}
                                                    loadOptions={lodash.debounce(this.searchProperties, 300)}
                                                    formattedLabel={this.state.hostedPaymentPage?.formattedPropertyLabel === 'NAME'}
                                                    />
                                                }
                                                {(this.state.hostedPaymentPage?.unitDisplayed || this.state.hostedPaymentPage?.propertySelectionRequired) &&
                                                    <FieldText 
                                                        id="unit" 
                                                        label="Unit" 
                                                        type="unit" 
                                                        parent={this} 
                                                        required={this.state.hostedPaymentPage?.unitRequired} 
                                                        value={this.state['unit']} maxLength="20"/>
                                                }
                                            </>
                                        }

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Continue
                                        </button>

                                    </form>
                                    }

                                </div>

                            </div>

                            {this.state.customerCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Charges
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        How much would you like to pay?
                                    </p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveCharges}>

                                        {this.state.chargesCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCharges()}>
                                                    <table className="table table-borderless mb-0 table-sm">

                                                        <tbody>
                                                        {this.state.charges.map((data, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <FormattedMessage id={"charge." + data.code} defaultMessage={data.name} />
                                                                    </td>
                                                                    <td className="text-right">
                                                                        ${Number(data?.amount).toFixed(2)
                                                                        // <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        {this.state.note && <tr>
                                                            <td>
                                                                {this.getNoteLabel(true)}
                                                            </td>
                                                            <td className="text-right" style={{wordBreak: 'break-all'}}>
                                                                {this.state.note}
                                                            </td>
                                                        </tr> }
                                                        </tbody>
                                                    </table>
                                                    
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCharges()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.chargesCompleted &&
                                        <React.Fragment>

                                            <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} parent={this} required={true} />
                                            
                                            {this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage &&
                                                <div style={{marginTop:10}}>
                                                    <FieldText id="note" 
                                                        label={this.getNoteLabel(false)} 
                                                        parent={this} 
                                                        optional={!this.state.hostedPaymentPage?.noteRequired} 
                                                        required={this.state.hostedPaymentPage?.noteRequired}                                                         
                                                        value={this.state['note']} 
                                                        pattern='^[^\^\*"[\]/\\;<]*$' 
                                                        oninvalid={(e)=>{e.target.setCustomValidity('Please do not use any of the following special characters ^*“[]/\\;<')}} 
                                                        oninput={(e)=>{e.target.setCustomValidity('')}}/>
                                                </div>
                                            }

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3" disabled={!this.state.charges[0]?.amount || !this.state.charges[0]?.name}>
                                                Continue
                                            </button>

                                            <figcaption className="text-right mt-1">
                                                <small className="text-muted font-italic">* indicates a required field</small>
                                            </figcaption>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                            {(this.state.chargesCompleted && this.state.showSplititOption) &&
                                <div className="card">
                                    <div className="card-header">
                                        Payment Type
                                    </div>

                                    <div className="card-body bg-secondary">
                                        <p className="mb-0">
                                            How many times would you like to pay?
                                        </p>
                                    </div>

                                    <div className="card-body">
                                        {this.state.showPayLaterError &&
                                            <div className="color-red">
                                                {
                                                    this.state.amount < 80
                                                        ? 'At least $80.00 total amount is required to pay over time'
                                                        : 'The maximum amount to pay over time is $20,000.00'
                                                }
                                                
                                            </div>
                                        }
                                        {(!this.state.paymentTypeCompleted || this.state.selectedPaymentType === 'pay_later') && (
                                            <span id="popover" tabIndex="0" role="button" data-toggle="popover" data-trigger="focus" data-html="true" title={'Pay Over Time'} 
                                                data-content={'Select Pay Over Time to spread the cost of your Security Deposit over 4 easy payments.'}>
                                                <div className="list-group mb-2">
                                                    <div id="pay-later" className="list-group-item list-group-item-action c-pointer" style={{ border: !this.state.paymentTypeCompleted && this.state.selectedPaymentType === 'pay_later' ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectPaymentType('pay_later')}>
                                                        <div className="row align-items-center">
                                                            <div className="col-8">
                                                                Pay Over Time
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </span>
                                        )}
                                        {(!this.state.paymentTypeCompleted || this.state.selectedPaymentType === 'one_time') && (
                                            <div className="list-group mb-2">
                                                <div id="onetime-payment" className="list-group-item list-group-item-action c-pointer" style={{ border: !this.state.paymentTypeCompleted && this.state.selectedPaymentType === 'one_time' ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectPaymentType('one_time')}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            One-time payment
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.state.paymentTypeCompleted
                                            ? (
                                                <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.updatePaymentTypeStatus(false)}>
                                                    Edit
                                                </div>
                                            ) : (
                                                <div className="btn btn-primary btn-md btn-block mt-3" onClick={() => this.updatePaymentTypeStatus(true)}>
                                                    Continue
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            }

                            {(this.state.paymentTypeCompleted || (!this.state.showSplititOption && this.state.chargesCompleted)) &&
                            <div className="card">
                           
                                <div className="card-header">
                                    Payment Method
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">
                                        How would you like to pay?
                                    </p>
                                </div>


                                {
                                    this.state.selectedPaymentType === 'pay_later' ? (
                                        <div className="">
                                            <form onSubmit={this.savePaymentMethod}>
                                                <div className="card-body">
                                                    <div className="list-group mb-2">
                                                        {!this.state.paymentMethodCompleted &&
                                                            <div id="letus-split" className="list-group-item list-group-item-action c-pointer" style={{ border: '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.showSplititForm()}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-12">
                                                                        Pay with Letus Split
                                                                        <button
                                                                            type='button'
                                                                            onClick={() => {$('#howSplitItWorksModal').modal('show');}}
                                                                            className={`btn btn-link p-0 float-right text-decoration-underline`}
                                                                            >
                                                                            How Letus Split Works
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                {this.state.showSplititForm &&
                                                                    <div className="mt-3">
                                                                        <FieldText id="paymentAmount" 
                                                                            label="Funded Amount" 
                                                                            disabled={true}
                                                                            required={true} 
                                                                            model="paymentMethod" 
                                                                            labelColumns="12" 
                                                                            fieldColumns="12" 
                                                                            fieldClass="form-control-md mb-0" 
                                                                            parent={this} 
                                                                            value={this.state.amount} 
                                                                        />
                                                                        {this.state.feeAmount > 0 && (
                                                                            <FieldText id="paymentTotal" 
                                                                                label="Your payment (1st installment + fee)" 
                                                                                disabled={true}
                                                                                required={true} 
                                                                                model="paymentMethod" 
                                                                                labelColumns="12" 
                                                                                fieldColumns="12" 
                                                                                fieldClass="form-control-md mb-0" 
                                                                                parent={this} 
                                                                                value={((this.state.amount/this.state.installments)+this.state.feeAmount).toFixed(2)} 
                                                                            />
                                                                        )}

                                                                        <div>
                                                                            Number of monthly Installments: {this.state.installments} 
                                                                            <button
                                                                                type='button'
                                                                                onClick={() => {$('#splitItPaymentScheduleModal').modal('show');}}
                                                                                className={`btn btn-link p-0 float-right text-decoration-underline`}
                                                                            >
                                                                                See Payment Schedule
                                                                            </button>
                                                                        </div>
                                                                        
                                                                        {this.creditCardPaymentForm(true)}

                                                                        <div className='form-check mt-4'>
                                                                            <input
                                                                                type='checkbox'
                                                                                id='acceptTermsAndConditionsPS'
                                                                                name='acceptTermsAndConditions'
                                                                                checked={this.state.splitTermsAndConditions}
                                                                                onChange={() => {
                                                                                    this.setState((prevState) => ({
                                                                                        ...prevState,
                                                                                        splitTermsAndConditions: !this.state.splitTermsAndConditions
                                                                                    }))
                                                                                }}
                                                                                className='form-check-input'
                                                                            />
                                                                            <label className='form-check-label' htmlFor='acceptTermsAndConditionsPS'>
                                                                                I have read and accept the&nbsp;
                                                                                <button
                                                                                type='button'
                                                                                onClick={() => this.showTermsAndConditionsModal()}
                                                                                className={`btn btn-link p-0`}
                                                                                >
                                                                                Terms and Conditions
                                                                                </button> and&nbsp;
                                                                                <a rel="noopener noreferrer" target="_blank" href="https://www.splitit.com/legals/splitit-privacy-policy/english/">Privacy Policy</a>.
                                                                            </label>
                                                                        </div>


                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {this.state.paymentMethodCompleted &&
                                                            <div className="list-group">
                                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8">
                                                                            <div className="">
                                                                                <React.Fragment>
                                                                                    <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand} />
                                                                                </React.Fragment>
                                                                            </div>
                                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                                <React.Fragment>
                                                                                    Card number ending in {this.state.paymentMethod.cardNumber.slice(-4)}
                                                                                </React.Fragment>
                                                                            </small>
                                                                        </div>
                                                                        <div className="col text-right">
                                                                            <div className="float-right mr-2">
                                                                                <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                
                                                    {this.state.showSplititForm && !this.state.paymentMethodCompleted ?
                                                        <button type="submit" 
                                                            disabled={!this.state.splitTermsAndConditions || this.state.disableSplititContinue || !this.state.phone} className="btn btn-primary btn-md btn-block mt-3">
                                                            Continue
                                                        </button>
                                                        :
                                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                                            Edit
                                                        </div>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                    ) : (
                                        <div className="card-body">

                                            {this.state.paymentMethodCompleted &&
                                            <div className="">
                                                {!this.state.showpaypal && !this.state.showunionpay && !this.state.showapplepay  && !this.state.showgooglepay &&
                                                <div className="list-group">
                                                    <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                                        <div className="row align-items-center">
                                                            <div className="col-8">
                                                                <div className="">
                                                                    {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                                    <React.Fragment>
                                                                        Bank Account
                                                                    </React.Fragment>
                                                                    }
                                                                    {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                                    <React.Fragment>
                                                                        <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand} />
                                                                    </React.Fragment>
                                                                    }
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                                    <React.Fragment>
                                                                        Account number ending in {this.state.paymentMethod.bankAccountNumber.slice(-4)}
                                                                    </React.Fragment>
                                                                    }
                                                                    {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                                    <React.Fragment>
                                                                        Card number ending in {this.state.paymentMethod.cardNumber.slice(-4)}
                                                                    </React.Fragment>
                                                                    }
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right mr-2">
                                                                    <CardBrandIcon paymentMethodType={this.state.paymentMethod.type} brand={this.state.paymentMethod.brand} customClasses="w-75" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {/*this.state.showpaypal &&
                                                <div className="list-group mb-2">
                                                        <div id="paypal-card" className={`list-group-item list-group-item-action c-pointer paypal-card ${this.state.paypal ? 'paypalselected' : 'paypalnoselected'}`} style={{ border: this.state.paypal ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }}>
                                                            <div className="row align-items-center">
                                                            <div className="col-8">
                                                                Pay with PayPal Checkout
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <CardBrandIcon paymentMethodType={'TYPE_PAY_PAL'} brand={'PAY_PAL'} customClasses="w-75"/>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                </div>*/
                                                }
                                                {this.state.showunionpay &&
                                                    <div className="list-group mb-2">
                                                        <div id="unionpay-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.unionpay ? 'unionpayselected' : 'unionpaynoselected'}`} style={{ border: this.state.unionpay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Pay with UnionPay Checkout
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <CardBrandIcon paymentMethodType={'TYPE_CREDIT_CARD'} brand={'UNION_PAY'} customClasses="w-75"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.showapplepay &&
                                                    <div className="list-group mb-2">
                                                        <div id="applepay-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.applepay ? 'unionpayselected' : 'unionpaynoselected'}`} style={{ border: this.state.applepay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Pay with Apple Pay
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={applePay} className="rounded border w-75" alt="Enter your ApplePay information"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.showgooglepay &&
                                                    <div className="list-group mb-2">
                                                        <div id="google-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.googlepay ? 'unionpayselected' : ''}`} style={{ border: this.state.googlepay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Pay with Google Pay
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={googlePay} className="rounded border w-75" alt="Enter your GooglePay information"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                                    Edit
                                                </div>

                                            </div>
                                            }

                                            {!this.state.paymentMethodCompleted &&
                                            <React.Fragment>
                                                <form onSubmit={this.savePaymentMethod}>
                                                    {/*this.state.showPaypalGeneralOption &&
                                                    <div className="list-group mb-2">
                                                        
                                                        <div id="paypal-card" className={`list-group-item list-group-item-action c-pointer paypal-card ${this.state.paypal ? 'paypalselected' : 'paypalnoselected'}`} style={{ border: this.state.paypal ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectPayPal()}>
                                                            <div className="row align-items-center">
                                                            <div className="col-8">
                                                                Pay with PayPal Checkout
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <CardBrandIcon paymentMethodType={'TYPE_PAY_PAL'} brand={'PAY_PAL'} customClasses="w-75"/>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>*/
                                                    }

                                                    {this.state.showUnionPayGeneralOption &&
                                                    <div className="list-group mb-2">

                                                        <div id="unionpay-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.unionpay ? 'unionpayselected' : 'unionpaynoselected'}`} style={{ border: this.state.unionpay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectUnionPay()}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Pay with UnionPay Checkout
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <CardBrandIcon paymentMethodType={'TYPE_CREDIT_CARD'} brand={'UNION_PAY'} customClasses="w-75"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    {!this.state.showCreditCardForm && this.acceptsCreditCards &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('credit_card')}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Enter your Credit or Debit Card
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={creditCard} className="rounded border w-75" alt=" Enter your Credit or Debit Card"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.state.showCreditCardForm && this.acceptsCreditCards &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item pb-3">

                                                            <div className="row align-items-center mb-3">
                                                                <div className="col-8">
                                                                    Enter your Credit or Debit Card
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={creditCard} className="rounded border w-75" alt="Enter your Credit or Debit Card" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.creditCardPaymentForm()}

                                                        </div>
                                                    </div>
                                                    }

                                                    {!this.state.showBankAccountForm && this.state.acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT') &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.createPaymentMethod('bank_account')}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Enter your Bank Account information
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={bankAccount} className="rounded border w-75" alt="Enter your Bank Account information"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    }

                                                    {this.state.showBankAccountForm && this.state.acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT') &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item pb-3">

                                                            <div className="row align-items-center mb-3">
                                                                <div className="col-8">
                                                                    Enter your Bank Account information
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={bankAccount} className="rounded border w-75" alt="Enter your Bank Account information" />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {this.state.paymentMethod &&
                                                            <React.Fragment>
                                                                <FieldText id="country" 
                                                                            label="Bank Country" 
                                                                            disabled={true}
                                                                            required={true} 
                                                                            model="paymentMethod" 
                                                                            labelColumns="12" 
                                                                            fieldColumns="12" 
                                                                            fieldClass="form-control-md mb-0" 
                                                                            parent={this} 
                                                                            value={this.state.company?.country === 'CA' ? 'Canada' : 'United States'} 
                                                                            />

                                                                {this.state.company?.country === 'CA' &&
                                                                <React.Fragment>

                                                                    <FieldText id="bankAccountInstitutionNumber"
                                                                            label="Institution Number"
                                                                            help={
                                                                                <div data-toggle="popover"
                                                                                        data-trigger="focus"
                                                                                        tabIndex="0"
                                                                                        data-html="true"
                                                                                        title="Institution Number"
                                                                                        className="btn-link d-inline c-pointer"
                                                                                        data-content="The institution number is the 3-digit number that identifies your financial institution. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/institution-number.png' class='img-fluid mt-2' />">
                                                                                    What is this?
                                                                                </div>
                                                                            }
                                                                            labelColumns="12"
                                                                            fieldColumns="12"
                                                                            fieldClass="form-control-md mb-0"
                                                                            type="tel"
                                                                            parent={this}
                                                                            required={true}
                                                                            value={this.state['bankAccountInstitutionNumber']}
                                                                    />

                                                                    <FieldText id="bankAccountTransitNumber"
                                                                            label="Transit Number"
                                                                            labelColumns="12"
                                                                            fieldColumns="12"
                                                                            fieldClass="form-control-md mb-0"
                                                                            type="tel" help={
                                                                        <div data-toggle="popover"
                                                                            data-trigger="focus"
                                                                            tabIndex="0"
                                                                            data-html="true"
                                                                            title="Transit Number"
                                                                            className="btn-link d-inline c-pointer"
                                                                            data-content="The transit number is the 5-digit number that identifies your specific bank branch. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/transit-number.png' class='img-fluid mt-2' />">
                                                                            What is this?
                                                                        </div>
                                                                    }
                                                                            parent={this}
                                                                            required={true}
                                                                            value={this.state['bankAccountTransitNumber']}
                                                                    />

                                                                </React.Fragment>
                                                                }

                                                                
                                                                { this.state.company?.country === 'US' &&

                                                                    <FieldText id="routingNumber"
                                                                            label="Routing Number"
                                                                            model="paymentMethod"
                                                                            labelColumns="12"
                                                                            fieldColumns="12"
                                                                            fieldClass="form-control-md mb-0"
                                                                            type="tel"
                                                                            help={
                                                                                <div data-toggle="popover"
                                                                                        data-trigger="focus"
                                                                                        tabIndex="0"
                                                                                        data-html="true"
                                                                                        title="Routing Number"
                                                                                        className="btn-link d-inline c-pointer"
                                                                                        data-content="The routing number is a 9-digit or 8-digit code that is used to identify where your bank account was opened. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/USD/en/routing-number.png' class='img-fluid mt-2' />">
                                                                                        What is this?
                                                                                    </div>
                                                                            }
                                                                            parent={this}
                                                                            required={true}
                                                                            value={this.state.paymentMethod['routingNumber']}
                                                                    />
                                                                }

                                                                <FieldText id="bankAccountNumber"
                                                                        label="Account Number"
                                                                        model="paymentMethod"
                                                                        labelColumns="12"
                                                                        fieldColumns="12"
                                                                        fieldClass="form-control-md mb-0"
                                                                        type="tel"
                                                                        help={
                                                                            <div data-toggle="popover"
                                                                                    data-trigger="focus"
                                                                                    tabIndex="0"
                                                                                    data-html="true"
                                                                                    title="Account Number"
                                                                                    className="btn-link d-inline c-pointer"
                                                                                    data-content="The bank account number is the 7 to 12-digit number that is specific to your personal account. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/account-number.png' class='img-fluid mt-2' />">
                                                                                What is this?
                                                                            </div>
                                                                        }
                                                                        parent={this}
                                                                        required={true}
                                                                        value={this.state.paymentMethod['bankAccountNumber']}
                                                                />

                                                                <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" labelColumns="12" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                                                {!this.state.showBillingAddressForm &&
                                                                <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                                    <option value=""></option>
                                                                    <option value="NEW">Add new billing address...</option>

                                                                </FieldSelect>
                                                                }

                                                                {this.state.showBillingAddressForm &&
                                                                <div className="mb-2">

                                                                    <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} required={true} />

                                                                </div>
                                                                }

                                                            </React.Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                    }

                                                    {this.state.showGooglePayGeneralOption  &&
                                                        <div className="list-group mb-2">
                                                            <div id="googlepay-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.googlepay ? 'unionpayselected' : 'unionpaynoselected'}`} style={{ border: this.state.googlepay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectGooglePay()}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        Pay with Google Pay
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right">
                                                                            <img src={googlePay} className="rounded border w-75" alt="Enter your Credit or Debit Card" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {this.state.showApplePayGeneralOption  &&
                                                        <div className="list-group mb-2">
                                                            <div id="applepay-card" className={`list-group-item list-group-item-action c-pointer unionpay-card ${this.state.applepay ? 'unionpayselected' : 'unionpaynoselected'}`} style={{ border: this.state.applepay ? '1px solid rgb(82,199,93)' : '1px solid rgba(0,0,0,0.125)' }} onClick={() => this.selectApplePay()}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        Pay with Apple Pay
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right">
                                                                            <img src={applePay} className="rounded border w-75" alt="Enter your Credit or Debit Card" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }

                                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                        Continue
                                                    </button>

                                                </form>

                                            </React.Fragment>
                                            }

                                        </div>
                                    )
                                }

                                <div className="card-footer">
                                    <p className="small text-muted mb-0"> 
                                        Transactions made through Letus are subject to transaction fees, dependant upon your chosen method of payment. To view a list of our transaction fees, <span className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>click here</span>.
                                    </p>
                                </div>

                            </div>
                            }

                            {this.props.location.state?.hostedPaymentPage &&
                                this.state.customerCompleted &&
                                this.state.chargesCompleted &&
                                this.state.paymentMethodCompleted &&
                                <FieldOTPVerification 
                                    validateOTP={this.continueWithOTP} 
                                    editOTPCode={this.editOTPCode}
                                    OTPCodeCompleted={this.state.OTPCodeCompleted}
                                />
                            }


                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">

                                <div className="card">
                                    <div className="card-header">
                                        Payment Summary
                                    </div>

                                    {!this.state.chargesCompleted &&
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div className="text-center text-secondary">
                                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw mt-3 mb-4" size="5x" />
                                                </div>
                                                <div className="text-center text-muted">
                                                    <small>
                                                        Once you add your charges, your payment summary will appear here.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    <div className="card-body card-body-list">

                                        {this.state.chargesCompleted &&
                                        <ul className="list-group">
                                            {this.state.charges.map((data, key) => {
                                                return (
                                                    <li key={key} className="list-group-item">
                                                        <div className="float-left">
                                                            <FormattedMessage id={`charge.${data.code}`} defaultMessage={data.name} />
                                                        </div>
                                                        <div className="float-right">
                                                            {/* <FormattedNumber value={data.amount} style={`currency`} currency={`USD`} /> */}
                                                            ${Number(this.state.installments ? data?.amount/this.state.installments : data?.amount).toFixed(2)}
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                            <li className="list-group-item">
                                                <div className="float-left font-weight-bold">
                                                    Subtotal
                                                </div>
                                                <div className="float-right font-weight-bold">
                                                    {/* <FormattedNumber value={this.state.amount} style={`currency`} currency={`USD`} /> */}
                                                    ${Number(this.state.installments ? this.state?.amount/this.state.installments : this.state?.amount).toFixed(2)}
                                                </div>
                                            </li>
                                        </ul>
                                        }

                                        {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <div className="float-left">
                                                    Service Fee
                                                </div>
                                                <div className="float-right">
                                                    {/* <FormattedNumber value={this.state.feeAmount} style={`currency`} currency={`USD`} /> */}
                                                    ${Number(this.state?.feeAmount).toFixed(2)}
                                                </div>
                                            </li>
                                            <li className="list-group-item">
                                                <div className="float-left font-weight-bold">
                                                    Total
                                                </div>
                                                <div className="float-right font-weight-bold">
                                                    {/* <FormattedNumber value={this.state.amount + this.state.feeAmount} style={`currency`} currency={`USD`} /> */}
                                                    ${Number(this.state.installments ? ((this.state?.amount/this.state.installments)+this.state?.feeAmount).toFixed(2) : (this.state?.amount + this.state?.feeAmount)).toFixed(2)}
                                                </div>
                                            </li>
                                        </ul>
                                        }

                                    </div>

                                    {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted) &&
                                    <div className="card-footer text-center">
                                        <small className="">
                                            <div className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>
                                                How are transaction fees calculated?
                                            </div>
                                        </small>
                                    </div>
                                    }

                                </div>
                                
                                {this.props.location.state?.hostedPaymentPage && 
                                                    this.state.customerCompleted && 
                                                    this.state.chargesCompleted && 
                                                    this.state.paymentMethodCompleted  &&
                                                    this.state.OTPCodeCompleted &&
                                                    this.state.recaptchaEnabled &&
                                    <div className="mb-4">
                                        <ReCAPTCHA
                                            sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                                            onChange={this.onReCaptchaChange}
                                        />
                                    </div>
                                }

                                {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted  && !this.state.showpaypal && !this.state.showapplepay && !this.state.showgooglepay) &&
                                    (this.state.reCaptchaId || !this.state.recaptchaEnabled) && this.state.OTPCodeCompleted &&
                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.submitPayment()}>

                                    <div className="">
                                        <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw" /> Confirm Payment
                                    </div>

                                </div>
                                }

                                {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted && this.state.OTPCodeCompleted && this.state.showpaypal) &&
                                <div className={this.state.reCaptchaId || !this.state.recaptchaEnabled ? "" : "d-none"}>
                                    <div id='paypal-buttons-container'>
                                    </div>
                                </div>
                                }
                                {(this.state.customerCompleted && this.state.chargesCompleted && this.state.paymentMethodCompleted  && (this.state.showapplepay || this.state.showgooglepay)) &&
                                    (this.state.reCaptchaId || !this.state.recaptchaEnabled) && this.state.OTPCodeCompleted &&
                                    <div data-bluesnap="walletButton">
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <FeeProfiles
                        feeProfileList={this.state.selectedPaymentType === 'pay_later' ? this.findSplititFee() : this.state.feeProfileList}
                        feeProfileCountry={this.state.feeProfileCountry}
                    />

                    <ModalUnionPay parent={this} />

                    <ReceiptPaymentTransaction paymentTransaction={this.state.paymentTransaction} hostedPaymentPage={this.props.location.state?.hostedPaymentPage} displayFeeAmount={true}>

                        {this.props.location && this.props.location.state && this.props.location.state.hostedPaymentPage &&
                        <div className="float-left">
                            <Link to={`/pay/${this.props.location.state.hostedPaymentPage.pageName}`} className={`btn btn-outline-primary btn-lg`} onClick={() => $('#receipt-payment-transaction').modal('hide')}>
                                <FormattedMessage id="button.close"/>
                            </Link>
                        </div>
                        }

                        <div className="float-right">
                            <div className="btn btn-primary btn-lg" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={['far', 'print']} className="fa-fw va-b" /> Print
                            </div>
                        </div>

                    </ReceiptPaymentTransaction>

                </div>
            </div>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);
