import * as moment from "moment";
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FormattedMessage } from "react-intl";


class FieldDateTime extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);
        
        this.state = {
            from: undefined,
            to: undefined,
        };
        
        if (this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on date fields. Convert the date to the format accepted by the server.
     *
     * @param date - The selected date value.
     */
    handleChange(date, time) {
        let dateField;

        let dateTime = date ? moment(date).toDate() : moment().toDate();

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }
        
        if(time) {
            const hour =  time.split(':')[0];
            const min =  time.split(':')[1];
            dateTime.setHours(hour);
            dateTime.setMinutes(min);
        }

        if (this.props.model != null) {
            dateField = this.props.parent.state[this.props.model];
            dateField[this.props.id] = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]HH:mm') : null;
        }

        if (this.props.model == null) {
            dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]HH:mm') : null;
        }
        
        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model ? this.props.model : this.props.id]: dateField,
        }));
    }

    

    /**
     * Filter out specific days from selection on the datepicker.
     *
     * @param date - The date object from the datepicker.
     * @returns {boolean} - True if the date is selectable from the datepicker.
     */
    filterDate = (date) => {

        const day = date.getDate();

        // Filter the available selections from the datepicker to only the first of each month
        if(this.props.filterDateType === 'FIRST_DATE') {

            return day === 1;
        }

        // Filter the available selections from the datepicker to dates before the monthly payment due day
        if(this.props.filterDateType === 'MONTHLY_PAYMENT_DUE_DAY') {

            return this.props.monthlyPaymentDueDay >= date.getDate();
        }

        return false;
    };

    /**
     * Render the component.
     *
     * @returns {*} - A generic date time field component. When clicked on, a date picker appears, which utilizes the
     * external react-datepicker library.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';
        
        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if (validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if (key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        let startDate = new Date(this.props.startDate);
        let endDate = new Date(this.props.endDate);
        let minDate = new Date(this.props.minDate);
        let maxDate = new Date(this.props.maxDate);
        let dataStringIndex = 10;
        const timeStringIndexStart = 11;
        const timeStringIndexEnd = 16;


        return (
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && <small className="text-muted font-italic">*</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        {this.props.selectsRange === undefined &&
                        <div>
                        {this.props.preText && (
                            <button
                                type="button"
                                className="btn btn-light btn-sm disabled mr-1 mt-0.5 mw-50"
                            >
                                {this.props.preText}
                            </button>
                        )}
                        <DayPickerInput 
                            format={this.props.dateFormat}
                            dayPickerProps = {(this.props.before !== undefined || this.props.after !== undefined) ?
                                {disabledDays:{
                                    before: this.props.before,
                                    after: this.props.after}} : {}}
                            component={props => 
                                <input {...props} readOnly={true} disabled={this.props.disabled} 
                                className={`form-control gotham ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' :''} ${isInvalid ? 'is-invalid' : ''}`}/>} 
                            onDayChange={day => this.handleChange(day, this.props.value == null ? '' : String(this.props.value)?.slice(timeStringIndexStart, timeStringIndexEnd))} 
                            placeholder={this.props.placeholder}
                            showMonthYearPicker = {!!this.props.showMonthYearPicker ? dataStringIndex = 7 : dataStringIndex = 10}
                            minDate = {this.props.minDate ? new Date(minDate.getTime() + minDate.getTimezoneOffset() * 60000) : null}
                            maxDate = {this.props.maxDate ? new Date(maxDate.getTime() + maxDate.getTimezoneOffset() * 60000) : null}
                            startDate = {this.props.startDate ? new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000) : null} 
                            endDate ={this.props.endDate ? new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000) : null}
                            value={this.props.value == null ? '' : String(this.props.value)?.slice(0, dataStringIndex)}>
                        </DayPickerInput>
                        <div className="DayPickerInput">
                            <input type="time" className={`form-control gotham ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' :''} ${isInvalid ? 'is-invalid' : ''}`} onChange={event => this.handleChange(this.props.value == null ? '' : String(this.props.value)?.slice(0, dataStringIndex), event.target.value)} value={this.props.value == null ? '' : String(this.props.value)?.slice(timeStringIndexStart, timeStringIndexEnd)}
                            ></input>
                        </div>
                        <style>{`
                            .DayPickerInput:not(.DayPickerInput > .InputField):not(.DayPickerInput > .InputFromTo-to):not(.DayPickerInput > .InputFromTo){
                                width: ${!!this.props.width ? this.props.width : '120px'};
                                background-color: #fff;
                        `}</style>
                        </div>
                        }
                            
                        {isInvalid &&
                        <div className="small text-danger mt-1">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                        {this.props.infoCode &&
                        <small className="form-text text-muted">
                            <FormattedMessage id={"help." + this.props.infoCode}/>
                        </small>
                        }
 
                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldDateTime.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    monthsShown: 1,
    dateFormat: 'MM/dd/yyyy',
};

export default FieldDateTime;
