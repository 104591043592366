
export const REACT_APP_HOST_API_URL = process.env.REACT_APP_HOST_API_URL == null ? 'http://development.let.us:8080' : process.env.REACT_APP_HOST_API_URL;
export const REACT_APP_HOST_INTEGRATION_URL = process.env.REACT_APP_HOST_INTEGRATION_URL == null ? 'http://localhost:8089' : process.env.REACT_APP_HOST_INTEGRATION_URL;
export const REACT_APP_HOST_BILLING_AND_INVOICING_URL = process.env.REACT_APP_HOST_BILLING_AND_INVOICING_URL == null ? 'http://localhost:8066' : process.env.REACT_APP_HOST_BILLING_AND_INVOICING_URL;
export const REACT_APP_HOST_ROOT_URL = process.env.REACT_APP_HOST_ROOT_URL == null ? 'http://localhost:3000' : process.env.REACT_APP_HOST_ROOT_URL;
export const REACT_APP_GOOGLE_OAUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID == null ? '945270538135-ormj6lthvq2hf9fn85tr7vdn7if3ou2q.apps.googleusercontent.com' : process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
export const REACT_APP_FLINKS_SRC = process.env.REACT_APP_FLINKS_SRC == null ? 'https://toolbox-iframe.private.fin.ag' : process.env.REACT_APP_FLINKS_SRC;
export const REACT_APP_FLINKS_DEMO = process.env.REACT_APP_FLINKS_DEMO == null ? 'true' : process.env.REACT_APP_FLINKS_DEMO;
export const REACT_APP_PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID == null? 'AclKxLHTeKAvc_IAuCc7RezS5SQKhXRs9EcwY7S_P8g5KGBa86thNpmmTE29o8wM9IOXnBVzBo8blND7' : process.env.REACT_APP_PAYPAL_CLIENT_ID;
export const REACT_APP_RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
export const SPECIAL_CHARACTER_REGEX = /[^-0-9A-Za-z @+'._():/]/;
export const AMAZON_PINPONT_PHONE_VALIDATION_COST = "0.006";

/**
 * ! Hardcoding HW URLS until the BE service is ready
 */
export const HW_US_PAGE_NAME = "USA Hyper";
export const HW_CA_PAGE_NAME = "CAN Hyper";

// SplitIt
export const SPLITIT_HOW_IT_WORKS_URL = "https://documents.production.splitit.com/LearnMore-v11?ipn=62017452628136224724";