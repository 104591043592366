import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import lodash from "lodash";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class BinMappings extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            searchFilter: '',
            newBinMapping: false,
            binMapping: {},
            binMappingList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            binMappingQuery: {
                orderBy: 'ASC',
                orderByFields: ['bin'],
                conditionList: []
            },
            validationList: [],
        };

        this.searchBinMappings = this.searchBinMappings.bind(this);
        this.viewBinMapping = this.viewBinMapping.bind(this);
        this.initBinMapping = this.initBinMapping.bind(this);
        this.saveBinMapping = this.saveBinMapping.bind(this);
        this.deleteBinMapping = this.deleteBinMapping.bind(this);
        this.filterBinMappings = this.filterBinMappings.bind(this);
    }

    /**
     * Load the list of BIN mappings on mounting of the component.
     */
    componentDidMount() {

        this.searchBinMappings(1, 25, this.state.binMappingQuery);
    }

    /**
     * Handle selecting a BIN mapping by bringing up the modal with BIN mapping values pre-filled.
     *
     * @param binMappingId - The ID of the BIN mapping selected.
     */
    viewBinMapping(binMappingId) {

        this.setState(prevState => ({
            ...prevState,
            validationList: [],
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/bin_info/${binMappingId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                binMapping: response.data,
                newBinMapping: false
            }));

            $('#bin-mapping').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new blank instance of a BIN mapping object.
     */
    initBinMapping() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/bin_info/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                binMapping: response.data,
                newBinMapping: true
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected BIN mapping record.
     */
    deleteBinMapping() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/bin_info/${this.state.binMapping.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.bins.deleted'
                    }
                }],
            });

            this.searchBinMappings(this.state.binMappingList.page, this.state.binMappingList.recordsPerPage, this.state.binMappingQuery);

            $('#bin-mapping').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle the submission of the create/update BIN mapping form. Updates or creates a new BIN mapping object
     * accordingly.
     *
     * @param event - The event container.
     */
    saveBinMapping(event) {

        event.preventDefault();

        const params = lodash.cloneDeep(this.state.binMapping);
        params.binLength = params.bin?.length;

        if(this.state.newBinMapping) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, params, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.bins.created'
                        }
                    }],
                });

                this.searchBinMappings(this.state.binMappingList.page, this.state.binMappingList.recordsPerPage, this.state.binMappingQuery);

                $('#bin-mapping').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, params, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchBinMappings(this.state.binMappingList.page, this.state.binMappingList.recordsPerPage, this.state.binMappingQuery);

                $('#bin-mapping').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of bin mapping records.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchBinMappings(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/bin_info/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                binMappingList: response.data,
                binMappingQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the BIN mapping search query and initiating a new search.
     *
     * @param event - The event container.
     * @param searchFilter - The value within the search filter field.
     */
    filterBinMappings(event, searchFilter) {

        if (event != null) {
            event.preventDefault();
        }

        let binMappingQuery = {
            orderBy: 'ASC',
            orderByFields: ['bin'],
            conditionList: []
        };

        if (searchFilter !== '') {

            binMappingQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'bin',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'category',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
            );
        }

        this.setState({
            binMappingQuery: binMappingQuery
        });

        this.searchBinMappings(1, 25, binMappingQuery);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The BIN mapping list and management interface.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="BIN Mappings" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                The BIN mapping records below have been imported to our local database using an external file. Please do not tamper with these records unless you have been instructed to do so.
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    BIN Mappings
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#bin-mapping" className="btn btn-primary btn-sm" onClick={() => this.initBinMapping()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Add BIN Mapping
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={(event) => this.filterBinMappings(event, this.state.searchFilter)}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                                   labelColums="0" placeholder="Filter by ID, BIN, or category..."
                                                   parent={this} value={this.state.searchFilter} />
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <Table columns={{bin: 'BIN', brand: 'Brand', cardType: 'Card Type', category: 'Category', country: 'Country', personal: 'Personal', regulated: 'Regulated'}}
                               columnWidths={['10%', '15%', '15%', '25%', '15%', '10%', '10%']}
                               headerClass="c-pointer"
                               data={this.state.binMappingList}
                               query={this.state.binMappingQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchBinMappings}>
                            <tbody>
                            {this.state.binMappingList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewBinMapping(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.bin}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.brand}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.cardType}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.category}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.issuingOrganizationCountryCode}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.personal ? 'Personal' : 'Corporate'}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.regulated ? 'Regulated' : 'Unregulated'}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="bin-mapping" tabIndex="-1" role="dialog" aria-labelledby="bin-mapping-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveBinMapping}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="bin-mapping-label">
                                            {this.state.newBinMapping &&
                                            <div className="">
                                                Add BIN Mapping
                                            </div>
                                            }
                                            {!this.state.newBinMapping &&
                                            <div className="">
                                                Edit BIN Mapping
                                            </div>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText required={true} id="bin" label="BIN" model="binMapping" parent={this} value={this.state.binMapping['bin']} />

                                        <FieldText required={true} id="brand" label="Brand" model="binMapping" parent={this} value={this.state.binMapping['brand']} />

                                        <FieldText id="cardType" label="Card Type" model="binMapping" parent={this} value={this.state.binMapping['cardType']} />

                                        <FieldText id="category" label="Category" model="binMapping" parent={this} value={this.state.binMapping['category']} />

                                        <FieldText id="issuingOrganizationCountryCode" fieldClass="text-uppercase" label="Country Code" model="binMapping" parent={this} value={this.state.binMapping['issuingOrganizationCountryCode']} maxLength="2" minLength="2"/>

                                        <FieldCheckbox id="personal" label="Personal" fieldLabel="This is a personal (not-corporate) BIN" model="binMapping" parent={this} value={this.state.binMapping['personal']} />

                                        <FieldCheckbox id="regulated" label="Regulated" fieldLabel="This BIN is regulated" model="binMapping" parent={this} value={this.state.binMapping['regulated']} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#bin-mapping").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {!this.state.newBinMapping &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#bin-mapping").modal("hide"); $("#delete-bin-mapping").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-bin-mapping" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete BIN Mapping"
                           body="Are you sure you would like to delete this BIN mapping?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#bin-mapping").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteBinMapping()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete BIN Mapping
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default BinMappings;