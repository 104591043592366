/* eslint-disable no-template-curly-in-string */
const CUSTOM_KEYWORDS_USERINFO = [
  '${CUSTOMERFIRSTNAME} - Tenant First Name',
  '${CUSTOMERLASTNAME} - Tenant Last Name',
  '${BUSINESSNAME} - Property Name',
];

let customKeywordsTitle = null;
let modalTitle = null;
let defaultMessage = null;
let customKeywords = [];

/**
 * Fetch an appropriate message guide based on the message guide ID
 */
export const getMessageGuideById = (messageGuideId) => {
  switch (messageGuideId) {
    case 'welcome-message-modal-everyware':
      modalTitle = 'Custom Welcome Message Guide';
      defaultMessage =
        "Default Message: Hi [CUSTOMERFIRSTNAME], your property manager has created a Letus account on your behalf to make it easy for you to pay rent. Letus simplifies rent payments through it's secure messaging platform. No more post-dated checks! Enjoy the convenience of choosing the payment method that works best for you. You will begin receiving messages for upcoming payments. Please reply STOP to discontinue.";
      customKeywordsTitle = 'Below are the keywords you can use in your custom welcome message.';
      customKeywords = [
        '[CUSTOMERFIRSTNAME] - Tenant First Name',
        '[CUSTOMERLASTNAME] - Tenant Last Name',
        '[BUSINESSNAME] - Property Name',
      ];
      break;
    case 'welcome-message-modal':
      modalTitle = 'Custom Welcome Message Guide';
      defaultMessage =
        "Default Message: Hi ${CUSTOMERFIRSTNAME}, your property manager has created a Letus account on your behalf to make it easy for you to pay rent securely. Letus simplifies rent payments through it's secure messaging platform. No more post-dated checks! Enjoy the convenience of choosing the payment method that works best for you. You will begin receiving messages for upcoming payments. Reply STOP to unsubscribe.";
      customKeywordsTitle = 'Below are the keywords you can use in your custom welcome message.';
      customKeywords = CUSTOM_KEYWORDS_USERINFO;
      break;
    case 'purchase-confirmation-modal':
      modalTitle = 'Custom Purchase Confirmation Message Guide';
      defaultMessage = 'Default Message: Thank you for purchasing with ${BUSINESSNAME} please note your bank statement will say ${STATEMENTDESCRIPTION}, and contain ${CONFCODE} please text us back if you have any questions. Reply STOP to unsubscribe. Thanks! Customer Service';
      customKeywordsTitle = 'Below are the keywords you can use in your custom purchase confirmation message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Payment Amount',
        '${PAYMENTLINK} - Payment Link',
        "${CARDLAST} - Payment method's last 4",
      ];
      break;
    case 'invoice-with-token-modal':
      modalTitle = 'Custom Invoice With Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} (${SUBTOTAL} + ${FEES} service fee) is due today. Do you want to use your card ending in ${CARDLAST} to make a payment now? Enter YES or NO Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom invoice with existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
        "${CARDLAST} - Payment method's last 4",
      ];
      break;
    case 'invoice-modal':
      modalTitle = 'Custom Invoice Without Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} is due today. Do you want to make a payment now? Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom invoice without existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
      ];
      break;
    case 'advance-reminder-with-token-modal':
      modalTitle = 'Custom Advance Reminder With Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} (${SUBTOTAL} + ${FEES} service fee) is due on ${PBTDUEDATE}. Do you want to use your card ending in ${CARDLAST}to make a payment now? Enter YES or NO. Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom advance reminder with existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
        "${CARDLAST} - Payment method's last 4",
      ];
      break;
    case 'advance-reminder-modal':
      modalTitle = 'Custom Advance Reminder Without Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} is due on ${PBTDUEDATE}. Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom advance reminder without existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
      ];
      break;
    case 'overdue-reminder-with-token-modal':
      modalTitle = 'Custom Overdue Reminder With Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} (${SUBTOTAL} + ${FEES} service fee) was due on ${PBTDUEDATE}. Do you want to use your card ending in ${CARDLAST}to make a payment now? Enter YES or NO. Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom overdue reminder with existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
        "${CARDLAST} - Payment method's last 4",
      ];
      break;
    case 'overdue-reminder-modal':
      modalTitle = 'Custom Overdue Reminder Without Existing Payment Method Message Guide';
      defaultMessage =
        'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} in the amount of ${AMOUNT} was due on ${PBTDUEDATE}. Do you want to make a payment now? Please click on the link ${PAYMENTLINK} to make a change. Reply STOP to unsubscribe.';
      customKeywordsTitle =
        'Below are the keywords you can use in your custom overdue reminder without existing payment method message.';
      customKeywords = [
        ...CUSTOM_KEYWORDS_USERINFO,
        '${AMOUNT} - Total Payment Amount',
        '${SUBTOTAL} - Payment Amount',
        '${FEES} - Payment Fees',
        '${PAYMENTLINK} - Payment Link',
      ];
      break;
      case 'advance-reminder-zero-amount-modal':
        modalTitle = 'Custom Advance Reminder With Zero Amount Message Guide';
        defaultMessage =
          'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} is due on ${PBTDUEDATE}. Please click on the link ${PAYMENTLINK} to make a payment. If you\'ve recently made a payment please disregard this message. Reply STOP to unsubscribe. Thank you from Letus.';
        customKeywordsTitle =
          'Below are the keywords you can use in your custom advance reminder with zero amount message.';
        customKeywords = [
          ...CUSTOM_KEYWORDS_USERINFO,
          '${PBTDUEDATE} - Payment Due Date',
          '${PAYMENTLINK} - Payment Link',
        ];
        break;
      case 'overdue-reminder-zero-amount-modal':
          modalTitle = 'Custom Overdue Reminder With Zero Amount Message Guide';
          defaultMessage =
            'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} was due on ${PBTDUEDATE}. Please click on the link ${PAYMENTLINK} to make a payment. If you\'ve recently made a payment please disregard this message. Reply STOP to unsubscribe. Thank you from Letus.';
          customKeywordsTitle =
            'Below are the keywords you can use in your custom overdue reminder with zero amount message.';
          customKeywords = [
            ...CUSTOM_KEYWORDS_USERINFO,
            '${PBTDUEDATE} - Payment Due Date',
            '${PAYMENTLINK} - Payment Link',
          ];
          break;
      case 'invoice-zero-amount-modal':
          modalTitle = 'Custom Invoice with Zero Amount Message Guide';
          defaultMessage =
            'Default Message: Hi ${CUSTOMERFIRSTNAME}, your rent with ${BUSINESSNAME} is due today. Please click on the link ${PAYMENTLINK} to make a payment. If you\'ve recently made a payment please disregard this message. Reply STOP to unsubscribe. Thank you from Letus.';
          customKeywordsTitle =
            'Below are the keywords you can use in your custom invoice with zero amount message.';
          customKeywords = [
            ...CUSTOM_KEYWORDS_USERINFO,
            '${PAYMENTLINK} - Payment Link',
          ];
          break;
    default:
      break;
  }

  return {
    modalTitle,
    defaultMessage,
    customKeywordsTitle,
    customKeywords,
  };
};
