import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldText from "../../common/FieldText";
import ModalPhrase from "../../common/ModalPhrase";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Reporting extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);

        this.state = {
            customFields: {},
            validationList: [],
            dollarAmount: 0,
            documentSigned: false,
            personCharged: 'tenant',
            editButtonDisabled: true,
            activateButtonDisabled: true,
            creditReportingRecord: {
                feeAmount: 0,
                paidByCompany: false,
            },
            initialRecord: null,
        };
    }

    /**
     * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
     * on the company ID in the route.
     */
    componentDidMount() {
        this.setState(prevState => ({
            ...prevState,
            company: this.props.company,
            query: {
                orderBy: 'DESC',
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.company.id
                    }
                ]
            },
        }));
        process.nextTick(() => {
            this.getCreditReportingRecord()
                .then((response) => {
                    if (response.data && response.data.records && response.data.records.length > 0) {
                        this.setCurrentReportingRecord(response.data.records[0]);
                    }
                });
        });
    }

    getCreditReportingRecord() {
        return axios(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/search`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: this.state.query,
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error);
            });
    }

    submitForm(event = {}, confirmation = true) {
        if (typeof event.preventDefault !== 'undefined') {
            event.preventDefault();
        }

        this.setState({
            validationList: [],
        });

        if (this.state.creditReportingRecord.paidByCompany && confirmation) {
            const modalElement = $('#paid-by-company');
            modalElement.modal('show');
            return false;
        }

        if (this.state.creditReportingRecord.id) {
            this.updateCreditReportingRecord(this.state.creditReportingRecord.id)
                .then((response) => {
                    if(response) {
                        this.setCurrentReportingRecord(response.data);
                        this.handleSuccess();
                    }
                });
        } else {
            this.createCreditReportingRecord()
                .then((response) => {
                    if(response) {
                        this.setCurrentReportingRecord(response.data);
                        this.handleSuccess('created');
                    }
                });
        }
    }

    setCurrentReportingRecord(creditReportingRecord) {
        this.setState(prevState => ({
            ...prevState,
            initialRecord: JSON.parse(JSON.stringify(creditReportingRecord)),
            creditReportingRecord,
            editButtonDisabled: true,
            documentSigned: true,
        }));
    }

    createCreditReportingRecord() {
        return axios(`${constants.REACT_APP_HOST_API_URL}/create`, {
            method: 'POST',
            headers: this.generateRequestHeaders(),
            data: {
                type:"TYPE_SERVICE_FEE_PROFILE",
                service: 'CREDIT_REPORT',
                companyId: this.state.company.id,
                feeAmount: Number(this.state.creditReportingRecord.feeAmount || 0),
                paidByCompany: this.state.creditReportingRecord.paidByCompany,
            }
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error)
            });
    }

    deleteCreditReportingRecord() {
        axios.delete(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/${this.state.creditReportingRecord.id}/delete`, {
            headers: this.generateRequestHeaders(),
        })
            .then(response => {
                if (response) {
                    this.setState(prevState => ({
                        ...prevState,
                        creditReportingRecord: {},
                        initialRecord: {},
                    }));

                    this.handleSuccess('deleted');
                }
            })
            .catch(error => {
                this.handleValidation(error)
            });
    }

    updateCreditReportingRecord(id) {
        return axios(`${constants.REACT_APP_HOST_API_URL}/service_fee_profile/${id}/update`, {
            method: 'PATCH',
            headers: this.generateRequestHeaders(),
            data: {
                companyId: this.state.company.id,
                feeAmount: Number(this.state.creditReportingRecord.feeAmount || 0),
                paidByCompany: this.state.creditReportingRecord.paidByCompany,
            }
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error)
            });
    }

    onFormChange() {
        let fees = false;
        let paidByCompany = false;
        if (this.state.initialRecord) {
            fees = this.state.creditReportingRecord.feeAmount === this.state.initialRecord.feeAmount;
            paidByCompany = this.state.creditReportingRecord.paidByCompany === this.state.initialRecord.paidByCompany;
        }
        process.nextTick(() => {
            this.setState(prevState => ({
                ...prevState,
                editButtonDisabled: !(!fees || !paidByCompany),
                activateButtonDisabled: !this.state.creditReportingRecord.feeAmount || this.state.documentSigned === false,
            }));
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;
        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        });
    }

    handleSuccess(action = 'updated') {
        this.setState({
            validationList: [{
                title: 'Success!',
                alert: {
                    type: 'primary',
                    message: `This Credit Reporting record was successfully ${action}!`,
                },
                showMessage: true,
                fields: {},
            }],
        });

        setTimeout(() => {
            this.setState({
                validationList: [],
            });
        }, 5000);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <Alert validationList={this.state.validationList}/>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Credit Reporting
                            </div>
                        </div>
                    </div>
                    <div className="col my-3">

                            <p className="">Status: <strong>{this.state.creditReportingRecord.id ? 'Active' : 'Inactive'}</strong></p>

                        <form
                            onSubmit={(event) => this.submitForm(event)}
                            onChange={(event) => this.onFormChange(event)}
                        >
                            <FieldCheckbox
                                id="documentSigned"
                                fieldLabel="Property Manager provided confirmation of acknowledgement."
                                fieldColumns={12}
                                parent={this}
                                disabled={this.state.creditReportingRecord.id}
                                value={this.state.documentSigned}
                            />

                            <FieldCheckbox
                                id="paidByCompany"
                                fieldLabel="Charge the fees to the company."
                                fieldColumns={12}
                                parent={this}
                                model="creditReportingRecord"
                                value={this.state.creditReportingRecord.paidByCompany}
                            />

                            <FieldText
                                id="feeAmount"
                                type="number"
                                prependIconName="dollar-sign"
                                prependIconType="fas"
                                label="Dollar amount that will be charged annually:"
                                labelColumns={8}
                                fieldColumns={4}
                                model="creditReportingRecord"
                                step="any"
                                parent={this}
                                value={Number(this.state.creditReportingRecord.feeAmount) || '0'}
                            />

                            <div className="text-right">
                                {this.state.creditReportingRecord.id ?
                                    <div>
                                        <button
                                            type="button"
                                            onClick={() => this.deleteCreditReportingRecord()}
                                            className="btn btn-danger btn-sm mt-5 mr-2 float-left"
                                        >
                                            Disable
                                        </button>

                                        <button
                                            type="submit"
                                            disabled={this.state.editButtonDisabled}
                                            className="btn btn-secondary btn-md mt-5"
                                        >
                                            <FontAwesomeIcon icon={['fas', 'pencil']} className="fa-fw"/> Submit Edit
                                        </button>
                                    </div>
                                    :
                                    <button
                                        type="submit"
                                        disabled={this.state.activateButtonDisabled}
                                        className="btn btn-primary btn-md mt-5"
                                    >
                                        <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw"/> Turn on Service
                                    </button>
                                }
                            </div>
                        </form>
                    </div>
                </div>

                <ModalPhrase
                    id="paid-by-company"
                    title="Confirmation Required"
                    iconName="exclamation-triangle"
                    confirmPhrase="CONFIRM"
                    handleSuccess={() => this.submitForm({}, false)}
                >
                    <span className="d-block mb-2 small">
                        You've selected the Property Manager to cover the cost of the service. Once turned on, all
                        Tenants within the company will be able to activate the service while passing on the cost to
                        the PM. This could result in significant costs to the PM.
                    </span>

                    <span className="d-block mb-4 small font-weight-bold">Are you sure you wish to select the PM to cover the costs?</span>

                    <span className="d-block mb-0 small">Enter the phrase 'CONFIRM' in the field below, then click the Confirm button.</span>
                </ModalPhrase>
            </React.Fragment>
        )
    };
}

Reporting.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Reporting);
