import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios/index';
import $ from 'jquery';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import Alert from './common/Alert';
import GoogleLoginCustom from './common/GoogleLoginCustom';
import Modal from './common/Modal';
import Propertii from './common/Propertii';

// Toggle between hide or show input password
const showPassword = () => {
  let passwordInput = $('[name="password"]');
  let passwordIconEyeSlash = $('[name="passwordIconEyeSlash"]');
  let passwordIconEye = $('[name="passwordIconEye"]');

  if (passwordInput.attr('type') === 'password') {
    passwordInput.attr('type', 'text');
    passwordIconEyeSlash.show();
    passwordIconEye.hide();
  } else {
    passwordInput.attr('type', 'password');
    passwordIconEyeSlash.hide();
    passwordIconEye.show();
  }
};

/**
 * Core login page component
 * This component includes the most core login functionality
 * and should be used anywhere such functionality is required
 */

class LoginCore extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      username: '',
      password: '',
      validationList: [],
    };

    this.submitLogin = this.submitLogin.bind(this);
    this.submitOAuth = this.submitOAuth.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.searchBroadcastMessages = this.searchBroadcastMessages.bind(this);
    this.handleValidation = this.handleValidation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    $('[name="passwordIconEyeSlash"]').hide();
  }

  /**
   * Handle change events on fields.
   *
   * @param event - The event container.
   */
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
   *
   * @param error - The server error response.
   */
  handleValidation(error) {
    let fields = {};

    if (error.response?.data?.fieldErrors) {
      Object.entries(error.response.data.fieldErrors).forEach(([key, value]) => {
        fields[value.fieldName] = value.errorCode;
      });
    }

    // Handle account status validation errors
    const accountStatus = error.response?.data?.accountStatus;

    // Display login-specific errors if the error code returned is the generic system account status error
    if (error.response?.data?.errorCode === 'RM_ERROR_SYSTEM_ACCOUNT_STATUS') {
      this.setState({
        validationList: [
          {
            alert: {
              type: 'danger',
              message: error.response.data.message,
              code:
                'login.' +
                error.response.data.errorCode +
                (accountStatus ? '.' + accountStatus : ''),
            },
            fields,
          },
        ],
      });
      return;
    }

    if (error.response?.data?.errorCode === 'RM_ERROR_PASSWORD_EXPIRED') {
      $('#password-expired').modal('show');

      return;
    }

    if (error.response?.data?.errorCode === 'RM_ERROR_REDIRECT_MIGRATED') {
      const response = error.response.data;
      const message = JSON.parse(response.message);
      return this.props.history.push({
        pathname: `/onboarding/${message.userId}/customer`,
        state: {
          firstName: message.firstName || '',
          lastName: message.lastName || '',
          verificationCode: message.verificationCode || '',
        },
      });
    }

    this.setState({
      validationList: [
        {
          alert: {
            type: 'danger',
            message: error.response?.data?.message,
            code: error.response?.data?.errorCode,
          },
          fields,
        },
      ],
    });
  }

  /**
   * Handle logging in via OAuth. Parse the session owner from the returned access token and redirect the user to
   * their appropriate dashboard.
   *
   * @param response - The returned OAuth response.
   */
  submitOAuth(response) {
    if (
      encodeURIComponent(response.code) !== 'undefined' &&
      encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`) !== 'undefined'
    ) {
      axios
        .get(
          `${
            constants.REACT_APP_HOST_API_URL
          }/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(
            response.code
          )}&requestUri=${encodeURIComponent(
            `${window.location.protocol}//${window.location.hostname}`
          )}`
        )
        .then((response) => {
          // Store token data in local storage
          localStorage.setItem('token_type', response.data.token_type);
          localStorage.setItem('access_token', response.data.access_token);
          localStorage.setItem('expires_in', response.data.expires_in);
          localStorage.setItem('expires_at', Date.now() + response.data.expires_in * 1000);

          // Refresh the app state
          this.props.refreshAppState();

          let token = localStorage.getItem('access_token');
          let sessionRole;

          // Decipher the token
          if (token !== null) {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));

            sessionRole = JSON.parse(userSession.sessionRole);
            sessionStorage.setItem('session_role_id', sessionRole.id);
          }

          if (sessionRole && sessionRole.type === 'TYPE_CUSTOMER') {
            let URL = '/customer/dashboard';
            if(this.props.loginFor === 'CR') {
              URL += '?login=cr';
            }
            window.location.href = URL;
          } else {
            const pathname = localStorage.getItem('pathname');
            this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
            localStorage.removeItem('pathname');
          }
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        spinner: false,
        validationList: [
          {
            fields: {},
            alert: {
              type: 'danger',
              code: 'login.cookies.DISABLED',
            },
            values: {
              errorCause: 'Google Auth',
            },
          },
        ],
      }));
      window.scrollTo(0, 0);
    }
  }

  /**
   * Handle login form submission. If successful, store token information into the localStorage and update the entire
   * App by calling refreshAppState(). Afterward, redirect the user to their appropriate dashboard, depending on the
   * user's type and user's screening status, if applicable.
   *
   * @param event - The event container.
   */
  submitLogin(event) {
    event.preventDefault();

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/authorize`, {
        auth: {
          username: this.state.username,
          password: this.state.password,
        },
      })
      .then((response) => {
        // Store token data in local storage
        localStorage.setItem('token_type', response.data.token_type);
        localStorage.setItem('access_token', response.data.access_token);
        localStorage.setItem('expires_in', response.data.expires_in);
        localStorage.setItem('expires_at', Date.now() + response.data.expires_in * 1000);

        // Fetch broadcast messages
        this.searchBroadcastMessages();

        // Refresh the app state
        this.props.refreshAppState();

        // Decipher the token
        let access_token = localStorage.getItem('access_token');
        let base64Url = access_token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
        let sessionRole = JSON.parse(userSession.sessionRole);
        sessionStorage.setItem('session_role_id', sessionRole.id);

        if (sessionRole && sessionRole.type === 'TYPE_CUSTOMER') {
          let URL = '/customer/dashboard';
            if(this.props.loginFor === 'CR') {
              URL += '?login=cr';
            }
          window.location.href = URL;
        } else {
          const pathname = localStorage.getItem('pathname');
          this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
          localStorage.removeItem('pathname');
        }
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Reset the password for a system account.
   *
   * @param email - The email of the system account to reset the password for.
   */
  resetPassword(email) {
    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/forgotpassword`, {
        username: this.state.username,
      })
      .then((response) => {
        this.setState({
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code: 'login.reset',
              },
            },
          ],
        });
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Fetch available broadcast messages for the user being logged in and store the data in the local storage.
   */
  searchBroadcastMessages() {
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/active`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        localStorage.setItem('broadcast_messages', JSON.stringify(response.data));

        this.props.refreshAppState();
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Render the credit reporting login page
   *
   */
  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div
        className={`content-block ${
          this.props.contentBlockClass ? this.props.contentBlockClass : ''
        }`}
      >
        <div className='container'>
          <div className='row'>
            <div className='col d-flex flex-column align-items-center justify-content-center'>
              {this.props.clientName && (
                <>
                  <img
                    src={require(`../media/img/clients/${this.props.clientName}.png`)}
                    alt='Company Logo'
                    height={this.props.clientLogoHeight || 200}
                  ></img>
                  <hr className='my-4' />
                </>
              )}
              <Alert validationList={this.state.validationList} />
              <form onSubmit={this.submitLogin}>
                <div className='form-group row'>
                  <div className='col-md-12'>
                    <div className='input-group input-group-lg'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={['fas', 'user']} />
                        </span>
                      </div>
                      <input
                        name='username'
                        type='email'
                        placeholder={formatMessage({
                          id: 'field.email',
                        })}
                        autoFocus={true}
                        required
                        value={this.state['username']}
                        onChange={this.handleChange}
                        className={`form-control form-control-lg mb-0 rounded-right ${
                          this.state.validationList[0]
                            ? this.state.validationList[0].fields['username']
                              ? 'is-invalid'
                              : ''
                            : ''
                        }`}
                      />
                      {this.state.validationList[0] && (
                        <div className='invalid-feedback'>
                          <FormattedMessage
                            id={'danger.' + this.state.validationList[0].fields['username']}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='form-group row'>
                  <div className='col-md-12'>
                    <div className='input-group input-group-lg'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>
                          <FontAwesomeIcon icon={['fas', 'key']} />
                        </span>
                      </div>
                      <input
                        name='password'
                        type='password'
                        autoComplete='off'
                        required
                        placeholder={formatMessage({
                          id: 'field.password',
                        })}
                        value={this.state['password']}
                        onChange={this.handleChange}
                        className={`form-control form-control-lg mb-0 rounded-right ${
                          this.state.validationList[0]
                            ? this.state.validationList[0].fields['password']
                              ? 'is-invalid'
                              : ''
                            : ''
                        }`}
                      />
                      {this.state.validationList[0] && (
                        <div className='invalid-feedback'>
                          <FormattedMessage
                            id={'danger.' + this.state.validationList[0].fields['password']}
                          />
                        </div>
                      )}
                      <div
                        className='input-group-append'
                        onClick={() => {
                          showPassword();
                        }}
                      >
                        <span className='input-group-text eye-container'>
                          <FontAwesomeIcon icon={['fal', 'eye']} name='passwordIconEye' />
                          <FontAwesomeIcon
                            icon={['fal', 'eye-slash']}
                            name='passwordIconEyeSlash'
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col text-center'>
                    <div className='small mb-3'>
                      <Link to='/forgot-password'>
                        <FormattedMessage id='static.login.forgot' />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col text-right'>
                    <button type='submit' className='btn btn-lg btn-primary btn-block mb-0'>
                      <FormattedMessage id='button.login' />
                    </button>
                  </div>
                </div>

                <div className='divider'>
                  <span className='small text-muted font-italic text-uppercase'>
                    <FormattedMessage id='label.or' />
                  </span>
                </div>

                <div className='row'>
                  <div className='col text-center d-flex flex-column align-items-center border border-primary p-2'>
                    <GoogleLoginCustom submitOAuth={this.submitOAuth}/>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Modal
          id='password-expired'
          theme='primary'
          iconType='fas'
          iconName='key'
          title='Password Expired'
          body='Your Letus account password has expired. Click the button below to receive an email with a link to reset your password.'
        >
          <button type='button' className='btn btn-outline-primary btn-lg' data-dismiss='modal'>
            <FormattedMessage id='button.close' />
          </button>
          <button
            onClick={() => {
              this.resetPassword();
            }}
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
          >
            Reset My Password
          </button>
        </Modal>
      </div>
    );
  }
}

LoginCore.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(LoginCore);
