import React, {useState, useEffect} from 'react';
import {BankCountryConstants} from "../../../constants/CountryConstants";
import ProvinceCollection from "../../../constants/ProvinceConstants";
import StateCollection from "../../../constants/StateConstants";
import { CA_POSTAL_REGEX, US_POSTAL_REGEX } from '../../../util/formatting';

const HyperWalletAddressFields = (props) => {
  const [stateProvinceList, setStateProvinceList] = useState([]);
  const [provinceLabel, setProvinceLabel] = useState(['Province']);
  const [postalLabel, setPostalLabel] = useState(['Postal Code']);
  
  const setProvinceStateConstantList = (event, props) => {
    if (event && event.target) {
      props.handleChangeCountry(event);
      let selectedCountry = event.target.value;
      if (selectedCountry === "CA") {
        setStateProvinceList(ProvinceCollection);
        setProvinceLabel('Province');
        setPostalLabel('Postal Code');
        return;
      }
      if (selectedCountry === "US") {
        setStateProvinceList(StateCollection);
        setProvinceLabel('State');
        setPostalLabel('Zip Code');
        return;
      }
      setStateProvinceList([]);
    }
  }

  useEffect(()=>{
    if (props.selectedCountry === "CA") {
      setStateProvinceList(ProvinceCollection);
      setProvinceLabel('Province');
      setPostalLabel('Postal Code');
    }
    if (props.selectedCountry === "US") {
      setStateProvinceList(StateCollection);
      setProvinceLabel('State');
      setPostalLabel('Zip Code');
    }
  }, [props.selectedCountry]);

  return (
    <>
      <div>
        <div className='row'>
          <div className='col-md-6 mt-2'>
            <label htmlFor={`street1${props.fieldIdSuffix}`}>Street 1{!props.isOptional && '*'}</label>
            <input
              type='input'
              className='form-control'
              id={`street1${props.fieldIdSuffix}`}
              name='Street 1'
              value={props.street1}
              required={!props.isOptional}
              onChange={props.handleChangeStreet1}
            />
          </div>
          <div className='col-md-6 mt-2'>
            <label htmlFor={`street2${props.fieldIdSuffix}`}>Street 2</label>
            <input
              type='input'
              className='form-control'
              id={`street2${props.fieldIdSuffix}`}
              name='Street 2'
              value={props.street2}
              onChange={props.handleChangeStreet2}
            />
          </div>
        </div>

        <div className='row'>
          <div className='col-md-6 mt-2'>
            <label htmlFor={`country${props.fieldIdSuffix}`}>Country{!props.isOptional && '*'}</label>
            <select
              id={`country${props.fieldIdSuffix}`}
              name='Country'
              className='form-control'
              value={props.country}
              required={!props.isOptional}
              onChange={(event) => setProvinceStateConstantList(event, props)}
            >
              <option value="">--Choose an Option--</option>
              {
                BankCountryConstants.map((country, index) => (
                  <option key={index} value={country.value}>{country.text}</option>
                ))
              }
            </select>
          </div>
          <div className='col-md-6 mt-2'>
            <label htmlFor={`province${props.fieldIdSuffix}`}>{provinceLabel}{!props.isOptional && '*'}</label>
            <select
              id={`province${props.fieldIdSuffix}`}
              name='Province'
              className='form-control'
              value={props.province}
              required={!props.isOptional}
              onChange={props.handleChangeProvince}
            >
              <option value="">--Choose an Option--</option>
              {
                stateProvinceList.map((stateProvince, index) => (
                  <option key={index} value={stateProvince.value}>{stateProvince.text}</option>
                ))
              }
            </select>
          </div>
        </div>
          
        <div className='row'>
          <div className='col-md-6 mt-2'>
            <label htmlFor={`city${props.fieldIdSuffix}`}>City{!props.isOptional && '*'}</label>
            <input
              type='input'
              className='form-control'
              id={`city${props.fieldIdSuffix}`}
              name='City'
              value={props.city}
              required={!props.isOptional}
              onChange={props.handleChangeCity}
            />
          </div>
          <div className={`${props.showUnit ? 'col-md-3' : 'col-md-6'} mt-2`}>
            <label htmlFor={`postalCode${props.fieldIdSuffix}`}>{postalLabel}{!props.isOptional && '*'}</label>
            <input
              type='input'
              className='form-control'
              maxLength={props.country === 'CA' ? 7 : 15}
              pattern={props.country === 'CA' ? CA_POSTAL_REGEX : (props.country === 'US' ? US_POSTAL_REGEX : undefined)}
              id={`postalCode${props.fieldIdSuffix}`}
              name='Postal Code'
              value={props.postalCode}
              required={!props.isOptional}
              onChange={props.handleChangePostalCode}
            />
            {(props.country === 'US' ||  props.country === 'CA') &&
              <small className="form-text text-muted">
                {props.country === 'US' ? `Valid format - '12345' or '12345-6789'.` : `Valid format - 'A1A 1A1'.`}
              </small>
            }
          </div>
          { props.showUnit &&
            <div className='col-md-3 mt-2'>
              <label htmlFor={`unit${props.fieldIdSuffix}`}>Unit</label>
              <input
                type='input'
                className='form-control'
                id={`unit${props.fieldIdSuffix}`}
                name='Unit'
                value={props.unit}
                onChange={props.handleChangeUnit}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default HyperWalletAddressFields;
