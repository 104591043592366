import commonMessages_en from "./en";
import commonMessages_fr from "./fr";
import promotionalMessages_en from "./promotional-en.json";
import promotionalMessages_fr from "./promotional-fr.json";

export const localeMessages_en = {
    ...commonMessages_en,
    ...promotionalMessages_en
} 

export const localeMessages_fr = {
    ...commonMessages_fr,
    ...promotionalMessages_fr
} 