import React, { Component } from 'react';
import FieldCustomTemplate from './FieldCustomTemplate';

class FieldCustomTemplateMultiple extends Component {
  /**
   * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
   * custom handleChange function appears in the parent class, utilize the default handleChange function below.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      validationList: [],
    };

    if (this.props?.parent?.handleChange) {
      this.handleClickAdd = this.props.parent.handleClickAdd.bind(this);
    } else {
      this.handleClickAdd = this.handleClickAdd.bind(this);
    }

    this.getCustomFieldDefault = this.getCustomFieldDefault.bind(this);
  }

  getCustomFieldDefault() {
    return {
      label: null,
      length: null,
      type: null,
      value: null,
    };
  }

  /**
   * Add a new custom field when the button is clicked
   * This method would add the new custom field to the attached model
   */
  handleClickAdd() {
    if (this.props.model) {
      this.props.parent.setState((prevState) => ({
        ...prevState,
        [this.props.model]: {
          ...prevState[this.props.model],
          customFields: [...prevState[this.props.model]?.customFields ?? [], this.getCustomFieldDefault()],
        },
      }));
    }
  }

  /**
   * Render the component.
   *
   * @returns {*} - The company event list component.
   */
  render() {
    return (
      <div className='form-group row'>
        <div className='col-sm-12'>
          {this.props.parent.state[this.props.model]?.customFields &&
            this.props.parent.state[this.props.model]?.customFields?.map(
              (customField, key) => {
                return (
                  <FieldCustomTemplate
                    parent={this.props.parent}
                    key={key}
                    idx={key}
                    model={this.props.model}
                    customField={customField}
                  />
                );
              }
            )}

          <input
            type='button'
            value={"+ " + this.props.btnName}
            className='btn btn-outline-primary btn-sm mt-1'
            onClick={this.props.handleClickAdd || this.handleClickAdd}
          />
        </div>
      </div>
    );
  }
}

export default FieldCustomTemplateMultiple;
