import React, { Component } from 'react';
import axios from "axios";
import * as constants from "../../util/constants";
import Propertii from "./Propertii";

class ButtonEmailOptOut extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.handleDelete = this.handleDelete.bind(this);
        this.sendWelcomeEmail = this.sendWelcomeEmail.bind(this);
    }

    /**
     * Handle clicking the delete button.
     */
    handleDelete() {

        if (this.props.model != null) {

            axios.delete(`${constants.REACT_APP_HOST_API_URL}/${this.props.model.type.replace('TYPE_', '')}/${this.props.model.id}/delete`,  {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.props.parent.setState(prevState => ({
                    ...prevState,
                    systemAccount: null,
                }));
                this.sendWelcomeEmail(this.props.model.userName);

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Sends a welcome email
     * @param {*} customerEmail
     */
    sendWelcomeEmail(customerEmail) {
        axios
            .get(
                `${constants.REACT_APP_HOST_API_URL}/CUSTOMER/${encodeURIComponent(customerEmail)}/sendwelcomeemail`,
                {
                    headers: this.generateRequestHeaders(),
                }
            )
            .then((response) => {
                response.data.alertCode = 'admin.system.emails.sent';
                this.props.parent.handleSuccessAlert(response, { email: customerEmail });
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                // Timeout because there maybe a slight delay for the data to get updated.
                setTimeout(() => {
                }, 2000);
            })
            .catch((error) => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
                this.props.parent.handleValidation(error);
            });
    }
    /**
     * Render the component.
     *
     * @returns {*} - A generic submit button component.
     */
    render() {

        return(
            <React.Fragment>

                <button type="button"
                        disabled={this.props.disabled}
                        className={`btn btn-primary btn-lg ${this.props.customClasses ? this.props.customClasses : 'ml-2'}`}
                        onClick={() => {this.handleDelete()}} className="btn btn-primary btn-lg" data-dismiss="modal"
                >
                    Opt Out
                </button>

            </React.Fragment>
        )
    };
}

export default ButtonEmailOptOut;