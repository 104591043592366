import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const MessageBar = ({ sendMessage, addErrorToValidationList, fetchChatMessages }) => {
  const [messageContent, setMessageContent] = useState('');
  const [isMessageSending, setIsMessageSending] = useState(false);

  /**
   * Handle sending text message to a tenant
   * TODO - Instead of fetching the whole list of messages again we should make an optimistic update
   * @param {*} event
   */
  const handleSendMessage = (event) => {
    event.preventDefault();
    setIsMessageSending(true);
    sendMessage(messageContent)
      .then(() => {
        setIsMessageSending(false);
        setMessageContent('');
        fetchChatMessages(1, 25, false); // ? Make an optimistic update instead?
      })
      .catch((error) => {
        addErrorToValidationList(error);
        setIsMessageSending(false);
        window.scrollTo(0, 0);
      });
  };

  return (
    <form onSubmit={handleSendMessage}>
      <div className='input-group'>
        <textarea
          className='form-control'
          placeholder='Message'
          aria-label='Message'
          aria-describedby='button-addon2'
          rows="2" 
          required
          value={messageContent}
          onChange={(event) => {
            setMessageContent(event.target.value);
          }}
        />
        <div className='input-group-append'>
          <button
            className='btn btn-primary'
            type='submit'
            id='button-addon2'
            disabled={isMessageSending}
          >
            {!isMessageSending ? (
              <FontAwesomeIcon icon={['fa', 'paper-plane']} />
            ) : (
              <>
                <span
                  className='spinner-border spinner-border-sm'
                  role='status'
                  aria-hidden='true'
                ></span>
                <span className='sr-only'>Loading...</span>
              </>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

MessageBar.propTypes = {
  sendMessage: PropTypes.func,
  addErrorToValidationList: PropTypes.func,
  fetchChatMessages: PropTypes.func,
};

export default MessageBar;
