import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from "jquery";
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import propertiiLogo from '../../media/img/logos/logo-letus-colour-white-text-new.png';
import bwLogo from '../../media/img/logos/logo-letus-white-new.png';

class Footer extends Component {

    constructor(props){
        super(props);
        this.state = {
            adminOrPmLoggedIn: false,
            logoLink: '/'
        };
        this.timer = null;

        this.fetchLoginStatus = this.fetchLoginStatus.bind(this);
    }

    componentDidUpdate(prevProps) {
        let token = localStorage.getItem('access_token');
        if (this.props.pathName !== prevProps.pathName || this.props.pathName === '/') {
            
            // Debounce on route changes
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setTimeout(() => {
                this.fetchLoginStatus(token);
            }, 500);

        }
    }

    fetchLoginStatus(token){
        let sessionRole;
        let logoLink = '/';

        // Decipher the token
        if(token !== null && token !== 'undefined'){
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
            sessionRole = JSON.parse(userSession.sessionRole);

            if (sessionRole?.type) {
                if (sessionRole.type === 'TYPE_MANAGER') {
                    logoLink = '/manager/dashboard';
                } else if (sessionRole.type === 'TYPE_ADMIN') {
                    logoLink = '/admin/dashboard';
                }

                this.setState(prevState => ({
                    ...prevState,
                    adminOrPmLoggedIn: sessionRole.type === 'TYPE_MANAGER' || sessionRole.type === 'TYPE_ADMIN',
                    logoLink: logoLink
                }));

            }
        } else {
            this.setState(prevState => ({
                ...prevState,
                adminOrPmLoggedIn: false,
                logoLink: logoLink
            }));
        }
        clearInterval(this.timer);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic website footer that links to all the static public-facing components of the web
     * application.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $('[data-toggle="tooltip"]').tooltip();

        return(
            <div>
                <footer className="navigation bg-inverse">
                    <div className="container py-4">
                        <div className="row align-items-center">

                            <div className="col-sm-12 col-md col-lg">
                                <Link to={this.state.logoLink}>
                                    <img src={propertiiLogo} className="img-fluid w-75 p-4 p-md-0" alt={formatMessage({ id: "global.company"})} />
                                </Link>
                            </div>

                            <div className="col-12 col-md-10 text-center text-md-right">

                                <ul className="list-inline text-uppercase my-2">
                                    <li className="list-inline-item">
                                        <a href="https://let.us/about" target="_blank" rel="noopener noreferrer" className="py-1">
                                            <FormattedMessage id="static.about" />
                                        </a>
                                    </li>
                                    {!this.state.adminOrPmLoggedIn &&
                                        !this.props?.pathName?.includes('/pay/') &&
                                        <React.Fragment>
                                            <li className="list-inline-item">
                                                <a href="https://let.us/landlord" target="_blank" rel="noopener noreferrer" className="py-1">
                                                    <FormattedMessage id="static.manager.PROPERTY_MANAGERS" />
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://let.us/tenants" target="_blank" rel="noopener noreferrer" className="py-1">
                                                    <FormattedMessage id="static.tenants" />
                                                </a>
                                            </li>
                                        </React.Fragment>
                                    }
                                    <li className="list-inline-item">
                                        <a href="https://let.us/terms" target="_blank" rel="noopener noreferrer" className="py-1">
                                            <FormattedMessage id="static.terms" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://let.us/privacy" target="_blank" rel="noopener noreferrer" className="py-1">
                                            <FormattedMessage id="static.privacy" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://let.us/contact" target="_blank" rel="noopener noreferrer" className="py-1">
                                            <FormattedMessage id="static.contact" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href={`https://help.rentmoola.com/hc/${this.props.intl?.locale}`} target="_blank" rel="noopener noreferrer" className="py-1">
                                            <FormattedMessage id="static.help" />
                                        </a>
                                    </li>
                                </ul>

                            </div>

                        </div>
                    </div>
                </footer>

                {/*<footer className="navigation bg-inverse">*/}
                    {/*<div className="container pt-5 pb-4">*/}
                        {/*<div className="row">*/}

                            {/*<div className="col-sm-12 col-md col-lg">*/}
                                {/*<Link to="/">*/}
                                    {/*<img src={logo} className="img-fluid mb-4 w-75 px-5 px-sm-5 px-md-0" alt={formatMessage({ id: "global.company"})} />*/}
                                {/*</Link>*/}
                            {/*</div>*/}

                            {/*<div className="col-6 col-md">*/}
                                {/*<div className="text-uppercase">*/}
                                    {/*<FormattedMessage id="navigation.company" />*/}
                                {/*</div>*/}
                                {/*<ul className="list-unstyled">*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/about" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.about" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/team" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.team" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/partners" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.partners" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/careers" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.careers" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</div>*/}

                            {/*<div className="col-6 col-md">*/}
                                {/*<div className="text-uppercase">*/}
                                    {/*<FormattedMessage id="navigation.managers" />*/}
                                {/*</div>*/}
                                {/*<ul className="list-unstyled">*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/managers" className="py-1" activeClassName="text-primary">*/}
                                            {/*Payments*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/demo" className="py-1" activeClassName="text-primary">*/}
                                            {/*Applications*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/demo" className="py-1" activeClassName="text-primary">*/}
                                            {/*Screenings*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/demo" className="py-1" activeClassName="text-primary">*/}
                                            {/*Lease Signings*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/demo" className="py-1" activeClassName="text-primary">*/}
                                            {/*Maintenance*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</div>*/}

                            {/*<div className="col-6 col-md">*/}
                                {/*<div className="text-uppercase">*/}
                                    {/*<FormattedMessage id="navigation.tenants" />*/}
                                {/*</div>*/}
                                {/*<ul className="list-unstyled">*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/moolaperks/rents-on-us" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.tenants" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/refer" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.insurance" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/giveaways" className="py-1" activeClassName="text-primary">*/}
                                            {/*MoolaPerks*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</div>*/}

                            {/*<div className="col-6 col-md">*/}
                                {/*<div className="text-uppercase">*/}
                                    {/*<FormattedMessage id="navigation.connect" />*/}
                                {/*</div>*/}
                                {/*<ul className="list-unstyled">*/}
                                    {/*<li className="py-1">*/}
                                        {/*<a href="http://blog.rentmoola.com/" target="_blank" rel="noopener noreferrer" className="py-1">*/}
                                            {/*<FormattedMessage id="static.blog" />*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/press" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.press" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<NavLink to="/contact" className="py-1" activeClassName="text-primary">*/}
                                            {/*<FormattedMessage id="static.contact" />*/}
                                        {/*</NavLink>*/}
                                    {/*</li>*/}
                                    {/*<li className="py-1">*/}
                                        {/*<a href="http://help.rentmoola.com/" target="_blank" rel="noopener noreferrer" className="py-1">*/}
                                            {/*<FormattedMessage id="static.help" />*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</div>*/}

                        {/*</div>*/}
                    {/*</div>*/}
                {/*</footer>*/}

                <footer className="copyright">
                    <div className="container bg-inverse py-4 clearfix">
                        <div className="row align-items-center">

                            <div className="col">
                                <form>
                                    <small className="text-uppercase">
                                        <FontAwesomeIcon icon={['far', 'copyright']} size="1x" className="va-b" />&nbsp;
                                        <FormattedMessage id="global.company" />,&nbsp;
                                        <span className="px-1">{(new Date().getFullYear())}</span>
                                    </small>
                                </form>
                            </div>

                            <div className="col-2 text-sm-center d-none d-sm-block">
                                <a href="https://rentmoola.com" target="_blank" rel="noopener noreferrer">
                                    <img src={bwLogo} className="img-fluid w-75 p-5 p-md-0" alt={formatMessage({ id: "global.company"})} />
                                </a>
                            </div>

                            <div className="col text-center text-md-right">
                                <a href="https://twitter.com/letus_payments" target="_blank" rel="noopener noreferrer" title={formatMessage({ id: "footer.tooltip.sec-1.twitter"})}>
                                    <span className="">
                                        <FontAwesomeIcon icon={['fab', 'twitter']} className="va-b fa-fw p-1" size="2x" />
                                    </span>
                                </a>
                                <a href="https://www.facebook.com/letuspayments" target="_blank" rel="noopener noreferrer" title={formatMessage({ id: "footer.tooltip.sec-1.facebook"})}>
                                    <span className="">
                                        <FontAwesomeIcon icon={['fab', 'facebook-square']} className="va-b fa-fw p-1" size="2x" />
                                    </span>
                                </a>
                                <a href="http://instagram.com/letuspaymentsolutions" target="_blank" rel="noopener noreferrer" title={formatMessage({ id: "footer.tooltip.sec-1.instagram"})}>
                                    <span className="">
                                        <FontAwesomeIcon icon={['fab', 'instagram']} className="va-b fa-fw p-1" size="2x" />
                                    </span>
                                </a>
                                <a href="http://youtube.com/rentmoola" target="_blank" rel="noopener noreferrer" title={formatMessage({ id: "footer.tooltip.sec-1.youtube"})}>
                                    <span className="">
                                        <FontAwesomeIcon icon={['fab', 'youtube']} className="va-b fa-fw p-1" size="2x" />
                                    </span>
                                </a>
                                <a href="https://www.linkedin.com/company/rentmoola" target="_blank" rel="noopener noreferrer" title={formatMessage({ id: "footer.tooltip.sec-1.linkedin"})}>
                                    <span className="">
                                        <FontAwesomeIcon icon={['fab', 'linkedin-in']} className="va-b fa-fw p-1" size="2x" />
                                    </span>
                                </a>
                            </div>

                        </div>
                    </div>
                </footer>
            </div>
        )
    };
}

Footer.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Footer);
