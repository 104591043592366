import React from 'react';
import $ from 'jquery';

const HyperWalletPaymentTypeInfoModal = (props) => {
  const closeModal = () => {
    $('#hyperWalletPaymentTypeInfoModal').modal('hide');
  };

  return (
    <div
      className='modal fade'
      id='hyperWalletPaymentTypeInfoModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='hyperWalletPaymentTypeInfoModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-md' role='document'>
        <div className='modal-content p-3'>
          <div className='modal-header border-0 justify-content-center'>
            <h5 className='modal-title'>
              {props && props.paymentTypeInfoData ? props.paymentTypeInfoData.name : ''}
              {props.paymentTypeInfoData?.logo && (
                <img
                  src={props.paymentTypeInfoData?.logo}
                  className='rounded img-fluid bg-light'
                  alt="payment method logo"
                  title={props.paymentTypeInfoData?.name}
                  width='40'
                />
              )}
            </h5>
          </div>
          <div className='modal-body'>
            <div className='row'>
              <div className='col-md-12 mt-2'>
                {props && props.paymentTypeInfoData ? props.paymentTypeInfoData.description : ''}
              </div>
            </div>
            <br />
            <div className='row'>
              <div className='col-md-12 mt-2'>
                <button type='button' className='btn btn-light' onClick={() => closeModal()}>
                  Back
                </button>
                <button
                  className='btn btn-primary float-right'
                  onClick={() => props.handlePaymentTypeSelection(props.paymentTypeInfoData.paymentType)}
                >
                  Select {props && props.paymentTypeInfoData ? props.paymentTypeInfoData.name : ''}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperWalletPaymentTypeInfoModal;
