import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import * as moment from "moment";
import 'moment-timezone';
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import bankAccount from "../../../media/img/payments/bank-account.png";
import creditCard from "../../../media/img/payments/credit-card.png";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonClose from "../../common/ButtonClose";
import ButtonSave from "../../common/ButtonSave";
import CardBrandIcon from "../../common/CardBrandIcon";
import CardPreview from "../../common/CardPreview";
import FieldAddress from "../../common/FieldAddress";
import FieldCardNumber from "../../common/FieldCardNumber";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldCountry from "../../common/FieldCountry";
import FieldDate from "../../common/FieldDate";
import FieldSelect from "../../common/FieldSelect";
import FieldSwitch from "../../common/FieldSwitch";
import FieldText from "../../common/FieldText";
import JumbotronHelp from "../../common/JumbotronHelp";
import ModalUnionPay from "../../common/ModalUnionPay";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";
import ReceiptPaymentTransaction from "../../common/ReceiptPaymentTransaction";
import ReceiptScheduledPayment from "../../common/ReceiptScheduledPayment";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class Payments extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            paymentTransaction: {},
            scheduledPayment: {},
            recurringSchedule: {},

            propertyLease: {},
            propertyLeaseList: [],

            paymentMethod: {},
            paymentMethodList: [],

            billingAddress: {},
            billingAddressList: [],

            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            scheduledPaymentList: [],
            scheduledRecurringPaymentList: [],

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'userId',
                        fieldValue: this.props.userSession.sessionRole.id
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: '((',
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'source',
                        fieldValue: 'PAD'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'transactionType',
                        fieldValue: 'PAYMENT'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'NOT_EQUALS',
                        fieldName: 'status',
                        fieldValue: 'PENDING'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: ')',
                        operator: 'NOT_EQUALS',
                        fieldName: 'status',
                        fieldValue: 'INPROCESS'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: '(',
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'source',
                        fieldValue: 'PAD'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: ')',
                        operator: 'NOT_EQUALS',
                        fieldName: 'transactionType',
                        fieldValue: 'PAYMENT'
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: ')',
                        operator: 'NOT_EQUALS',
                        fieldName: 'source',
                        fieldValue: 'PAD'
                    },
                ],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['propertyId', 'unit']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'pl.propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1']
                    },
                },
            },

            propertyLeaseQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'userId',
                        fieldValue: this.props.userSession.sessionRole.id
                    }
                ],
                joins: {
                    company: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['id', 'name', 'landlordId']
                    },
                    property: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'property',
                        returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                    },
                },
            },

            validationList: [],

        };

        this.searchPropertyLeases = this.searchPropertyLeases.bind(this);
        this.viewPropertyLease = this.viewPropertyLease.bind(this);

        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);
        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);

        this.viewScheduledPayment = this.viewScheduledPayment.bind(this);

        this.initRecurringSchedule = this.initRecurringSchedule.bind(this);
        this.saveRecurringSchedule = this.saveRecurringSchedule.bind(this);

        this.initPaymentMethod = this.initPaymentMethod.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.searchBillingAddresses = this.searchBillingAddresses.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);

        this.handleChangeRecurringScheduleStatus = this.handleChangeRecurringScheduleStatus.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
    }

    /**
     * Populate the list of managers on mounting of the component.
     */
    componentDidMount() {

        this.searchPaymentTransactions(1, 5, this.state.paymentTransactionQuery);
        this.searchPropertyLeases(1, 999, this.state.propertyLeaseQuery);
    }

    /**
     * Search for a list of property leases associated with the user, in addition to fetching the corresponding
     * recurring schedule details for each property lease, if an existing recurring schedule is associated with the
     * property lease. For every recurring schedule found, add the next schedule payment to the existing list of
     * upcoming scheduled payments.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPropertyLeases(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLeaseList = response.data.records;
            let postMonthFilter = new Date();

            postMonthFilter.setDate(1);
            postMonthFilter = postMonthFilter.setMonth(postMonthFilter.getMonth() + 1);

            propertyLeaseList.forEach((propertyLease, key) => {

                axios.post(`${constants.REACT_APP_HOST_API_URL}/open_charge/search`, {
                    orderBy: 'ASC',
                    orderByFields: ['postMonth'],
                    conditionList: [
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'billingAccountId',
                            operator: 'EQUALS',
                            fieldValue: propertyLease.id
                        },
                        {
                            type: 'NUMBER',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'balance',
                            operator: 'NOT_EQUALS',
                            fieldValue: 0
                        }
                    ],
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    let nextScheduledPayment = {};
                    let openCharges = response.data.records;
                    let currentAmount = 0;
                    let currentBalance = 0;

                    openCharges.forEach((openCharge, key) => {
                        currentAmount += parseFloat((openCharge.amount));
                        currentBalance += parseFloat(openCharge.balance);
                    });

                    propertyLeaseList[key].openCharges = openCharges;
                    propertyLeaseList[key].currentAmount = currentAmount;
                    propertyLeaseList[key].currentBalance = currentBalance;

                    axios.post(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/search`, {
                        orderBy: 'DESC',
                        orderByFields: ['createDate'],
                        conditionList: [
                            {
                                type: 'STRING',
                                logicalOperator: 'AND',
                                openBrackets: null,
                                closeBrackets: null,
                                operator: 'EQUALS',
                                fieldName: 'billingAccountId',
                                fieldValue: propertyLeaseList[key].id
                            }
                        ],
                    }, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {

                        let recurringSchedule = response.data.records[0];

                        propertyLeaseList[key].recurringSchedule = recurringSchedule;

                        this.setState(prevState => ({
                            ...prevState,
                            propertyLeaseList: propertyLeaseList,
                        }));

                        if(recurringSchedule.active) {

                            axios.get(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${recurringSchedule.id}/upcomingpayment`, {
                                headers: this.generateRequestHeaders()
                            }).then(response => {

                                nextScheduledPayment = response.data;

                                nextScheduledPayment.chargesTotal = 0;

                                nextScheduledPayment.charges.forEach((charge, key) => {
                                    nextScheduledPayment.chargesTotal += charge.amount;
                                });

                                // Fetch the payment method
                                axios.get(`${constants.REACT_APP_HOST_API_URL}/${recurringSchedule.paymentMethodType.substring(5).toLowerCase()}/${recurringSchedule.paymentMethodId}`, {
                                    headers: this.generateRequestHeaders()
                                }).then(response => {

                                    let paymentMethod = response.data;

                                    // Calculate the expected transaction fee
                                    axios.post(`${constants.REACT_APP_HOST_API_URL}/calculatefee`, {
                                        amount: nextScheduledPayment.chargesTotal,
                                        companyId: propertyLease.companyId,
                                        billingAccountId: propertyLease.id,
                                        billingAccountType: propertyLease.type,
                                        paymentMethod: paymentMethod,
                                    }, {
                                        headers: this.generateRequestHeaders()
                                    }).then(response => {

                                        nextScheduledPayment.feeAmount = response.data;
                                        nextScheduledPayment.paymentMethod = paymentMethod;
                                        nextScheduledPayment.joins.p = propertyLease.joins.property;
                                        nextScheduledPayment.joins.pl = propertyLease;

                                        propertyLeaseList[key].nextScheduledPayment = nextScheduledPayment;

                                        let scheduledPaymentList = this.state.scheduledPaymentList;

                                        scheduledPaymentList[key] = nextScheduledPayment;

                                        this.setState(prevState => ({
                                            ...prevState,
                                            scheduledPaymentList: scheduledPaymentList,
                                        }));

                                    }).catch(error => {
                                        this.handleValidation(error);
                                    });

                                }).catch(error => {
                                    this.handleValidation(error);
                                });

                            }).catch(error => {
                                this.handleValidation(error);
                            });

                        }

                    }).catch(error => {
                        this.handleValidation(error);
                    });

                }).catch(error => {
                    this.handleValidation(error);
                });

            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select an existing property lease by fetching the property lease data and triggering the property lease view
     * modal. If the property lease has a recurring schedule associated with it, assign it to the state.
     *
     * @param propertyLease - The property lease object to view.
     */
    viewPropertyLease(propertyLease) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${this.props.userSession.sessionRole.id}/paymentmethods`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let recurringSchedulePaymentMethod = {};

            if(propertyLease.recurringSchedule) {
                response.data.forEach(((paymentMethod, key) => {
                    if(paymentMethod.id === propertyLease.recurringSchedule.paymentMethodId) {
                        recurringSchedulePaymentMethod = paymentMethod;
                    }
                }));
            }

            this.setState(prevState => ({
                ...prevState,
                propertyLease: propertyLease,
                paymentMethod: recurringSchedulePaymentMethod,
                paymentMethodList: response.data,
                recurringSchedule: propertyLease.recurringSchedule ? propertyLease.recurringSchedule : {},
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#property-lease').modal('show');
    }

    /**
     * Search for all payment transactions for the customer.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentTransactionList: response.data,
                paymentTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * View the detailed information of a payment transaction, scheduled payment, or recurring schedule.
     *
     * @param paymentTransaction - The payment transaction to view.
     */
    viewPaymentTransaction(paymentTransaction) {

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: paymentTransaction,
        }));

        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * View the detailed information of a scheduled payment.
     *
     * @param scheduledPayment - The scheduled payment to view.
     */
    viewScheduledPayment(scheduledPayment) {
        if(scheduledPayment.chargesTotal === 0){
            return;
        }
        axios.get(`${constants.REACT_APP_HOST_API_URL}/${scheduledPayment.paymentMethodType.substring(5).toLowerCase()}/${scheduledPayment.paymentMethodId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                scheduledPayment: scheduledPayment,
                paymentMethod: response.data,
                spinner: false
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#receipt-scheduled-payment').modal('show');
    }

    /**
     * Initialize the recurring schedule create/edit flow by pulling a list of payment methods associated with the user,
     * and triggering the recurring schedule modal.
     */
    initRecurringSchedule() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${this.props.userSession.sessionRole.id}/paymentmethods`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodList: response.data,
                recurringStartDate: prevState.recurringSchedule ? prevState.recurringSchedule.startDate : '',
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#recurring-schedule').modal('show');
    }

    /**
     * Create or update an existing recurring schedule.
     *
     * @param event - The event container.
     */
    saveRecurringSchedule(event) {

        event.preventDefault();

        this.setState(prevState => ({ 
            spinner: true
        }));

        if(this.state.recurringSchedule.id) {

            axios.patch(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${this.state.recurringSchedule.id}/update`, {
                startDate: this.state.recurringSchedule.startDate,
                paymentMethodId: this.state.paymentMethod.id,
                paymentMethodType: this.state.paymentMethod.type
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }]
                }));

                this.searchPropertyLeases(1, 999, this.state.propertyLeaseQuery);

                $('#recurring-schedule').modal('hide');

            }).catch(error => {

                this.handleValidation(error);
                window.scrollTo(0, 0);

            });

        }

        if(!this.state.recurringSchedule.id) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, {
                type: 'TYPE_RECURRING_SCHEDULE',
                userType: this.state.propertyLease.userType,
                userId: this.state.propertyLease.userId,
                startDate: this.state.recurringSchedule.startDate,
                recurrenceType: 'MONTHLY',
                paymentMethodId: this.state.paymentMethod.id,
                paymentMethodType: this.state.paymentMethod.type,
                billingAccountId: this.state.propertyLease.id,
                billingAccountType: this.state.propertyLease.type,
                charges: this.state.propertyLease.charges,
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }]
                }));

                this.searchPropertyLeases(1, 999, this.state.propertyLeaseQuery);

                $('#recurring-schedule').modal('hide');

            }).catch(error => {

                this.handleValidation(error);
                window.scrollTo(0, 0);

            });

        }
    }

    /**
     * Create a new instance of a payment method, with the object dependant upon which payment method type the user
     * selects.
     *
     * @param paymentType - The type of payment method selected.
     */
    initPaymentMethod(paymentType) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    userId: this.state.propertyLease.userId,
                    userType: this.state.propertyLease.userType
                },
                showBillingAddressForm: false,
                validationList: []
            }));

            this.searchBillingAddresses();

            $('#recurring-schedule').modal('hide');
            $(`#${paymentType.replace("_", "-")}`).modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Save the selected payment method. Performs a list save, collecting at most the payment method, and billing
     * address.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#credit-card').modal('hide');
            $('#confirm-unionpay').modal('show');

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        if(this.state.showBillingAddressForm) {
            saveListData.push(billingAddress);
        }

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Add the paymentMethod state to the save list queue
        saveListData.push(paymentMethod);

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                showBillingAddressForm: false,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.method.created'
                    }
                }],
            }));

            this.searchBillingAddresses();
            this.initRecurringSchedule();

            $('#credit-card').modal("hide");
            $('#bank-account').modal("hide");

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
        window.scrollTo(0, 0);
    }

    /**
     * Search for all addresses of type 'BILLING' associated with the user.
     */
    searchBillingAddresses() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertyLease.userId
                }
            ]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                billingAddressList: response.data.records,
            }));

            if(response.data.records.length === 0) {
                this.initBillingAddress();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.state.propertyLease.userType,
                    userId: this.state.propertyLease.userId
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the active state of a recurring schedule. If the recurring schedule is disabled, wipe the list
     * of scheduled payments from the state.
     *
     * @param checked - True or false is the recurring schedule is active or not.
     */
    handleChangeRecurringScheduleStatus(checked) {

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${this.state.propertyLease.recurringSchedule.id}/update`, {
            active: checked
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.searchPropertyLeases(1, 999, this.state.propertyLeaseQuery);

            this.setState(prevState => ({
                ...prevState,
                propertyLease: {
                    ...prevState.propertyLease,
                    recurringSchedule: {
                        ...prevState.recurringSchedule,
                        active: checked,
                    }
                },
                scheduledPaymentList: !checked ? [] : prevState.scheduledPaymentList,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if(this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {

            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));

        } else {

            this.initBillingAddress();

        }
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the address of the property they are setting up auto payments for.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the customer's property
        if(event.target.checked) {

            residentialAddress = this.state.propertyLease.joins.property;
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = this.state.propertyLease.unit;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if(!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The customer payment dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        const ordinal = require('ordinal');

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <div className="content-block">

                <div className="container">

                    <Spinner visible={this.state.spinner} />

                    <Breadcrumb parentPage="Payments" />

                    <div className="row">
                        <div className="col-md-4">

                            <Link to="/customer/payments/payment" className="btn btn-primary btn-lg btn-block py-3 mb-4">
                                <FontAwesomeIcon icon={['far', 'lock-alt']} className="fa-fw" /> Make a Payment
                            </Link>

                            <div className="card">
                                <div className="card-header">
                                    Options
                                </div>
                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/customer/payments/methods`} active="methods" size="small" iconName="wallet" name="Payment Methods" />

                                    </div>
                                </div>
                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us" />

                        </div>
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            {this.props.location.state &&
                                <Alert validationList={this.props.location.state.validationList} />
                            }

                            <div className="card">

                                <div className="card-header">
                                    Leases
                                </div>

                                {this.state.propertyLeaseList.length > 0 &&
                                <div className="">
                                    <div className="card-body card-body-list">
                                        <div className="list-group">
                                            {this.state.propertyLeaseList.map((data, key) => {
                                                return (
                                                    <div key={key} className="list-group-item list-group-item-action flex-column align-items-start c-pointer" onClick={() => this.viewPropertyLease(data)}>
                                                        <div className="media">
                                                            <div className="align-self-center mr-3 text-center">
                                                                <h4 className="mt-2 line-height-none">
                                                                    <div className="fa-stack fa-1x">
                                                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                                        <FontAwesomeIcon icon={['far', 'home']} className="fa-stack-1x fa-inverse" />
                                                                    </div>
                                                                </h4>
                                                                {data.status === 'ACTIVE' &&
                                                                <div className="status-icon small">
                                                                    {(data.recurringSchedule && data.recurringSchedule.active) &&
                                                                    <div className="fa-stack fa-1x" data-toggle="tooltip" data-placement="top" title="Auto payments are enabled for this lease">
                                                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-2x text-primary" />
                                                                        <FontAwesomeIcon icon={['far', 'repeat-alt']} className="fa-stack-1x fa-inverse" />
                                                                    </div>
                                                                    }
                                                                    {((data.recurringSchedule && !data.recurringSchedule.active) || !data.recurringSchedule) &&
                                                                    <div className="fa-stack fa-1x" data-toggle="tooltip" data-placement="top" title="Auto payments are disabled for this lease">
                                                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-2x text-secondary" />
                                                                        <FontAwesomeIcon icon={['far', 'repeat-alt']} className="fa-stack-1x" />
                                                                    </div>
                                                                    }
                                                                </div>
                                                                }
                                                                {data.status === 'SUSPENDED' &&
                                                                <div className="status-icon small">
                                                                    <div className="fa-stack fa-1x" data-toggle="tooltip" data-placement="top" title="This lease is suspended or terminated">
                                                                        <FontAwesomeIcon icon={['fas', 'circle']} className="fa-2x text-danger" />
                                                                        <FontAwesomeIcon icon={['far', 'times']} className="fa-stack-1x fa-inverse" />
                                                                    </div>
                                                                </div>
                                                                }
                                                            </div>
                                                            <div className="media-body align-self-center">
                                                                {data.joins.property &&
                                                                <p className="mb-0">
                                                                    {data.unit ? data.unit + ' - ' : ''}{data.joins.property.street1}
                                                                </p>
                                                                }
                                                                <small className="mb-0">
                                                                    Current Balance: <span className="font-weight-bold"><FormattedNumber value={data.currentBalance} style={`currency`} currency="USD" /></span>
                                                                </small>
                                                            </div>
                                                            <div className="align-self-center text-right">
                                                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                }

                                {this.state.propertyLeaseList.length === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-secondary">
                                                <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x" />
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    You currently do not have any rental property leases.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>

                            <div className="card">
                                <div className="card-header">
                                    Payments
                                </div>
                                <div className="card-body card-body-table">

                                    <table className="table table-hover table-responsive-sm ">

                                        {this.state.scheduledPaymentList.length > 0 &&
                                        <React.Fragment>
                                            <thead>
                                            <tr>
                                                <th colSpan="5">
                                                    Upcoming Payments
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.scheduledPaymentList.map((data, key) => {

                                                return(
                                                    <tr key={key} onClick={() => this.viewScheduledPayment(data)} className={data.chargesTotal ? "c-pointer" : ""}>
                                                        <td width="10%">
                                                            <h4 className="text-center mb-0">
                                                                <Moment format="DD" tz="UTC">
                                                                    {data.scheduledDate}
                                                                </Moment>
                                                            </h4>
                                                            <div className="text-center">
                                                                <Moment format="MMM" tz="UTC">
                                                                    {data.scheduledDate}
                                                                </Moment>
                                                            </div>
                                                        </td>
                                                        <td width="20%">
                                                            <div className="">

                                                                <div className="font-weight-bold">
                                                                    {data.chargesTotal ?
                                                                        <FormattedNumber value={data.chargesTotal + (data.feePaidByCompany ? 0 : data.feeAmount)} style={`currency`} currency="USD"/>
                                                                        : <FormattedNumber value={0} style={`currency`} currency="USD"/>
                                                                    }
                                                                </div>

                                                                <span className="">in charges</span>

                                                            </div>
                                                        </td>
                                                        <td width="25%">
                                                            <div className="">

                                                                {data.joins &&
                                                                <React.Fragment>

                                                                    {data.joins.pl &&
                                                                    <div className="font-weight-bold">
                                                                        {data.joins.pl.unit}
                                                                    </div>
                                                                    }

                                                                    {data.joins.p &&
                                                                    <div className="">
                                                                        {data.joins.p.street1}
                                                                    </div>
                                                                    }

                                                                </React.Fragment>
                                                                }

                                                            </div>
                                                        </td>
                                                        <td width="25%">
                                                            <div className="">
                                                                <FontAwesomeIcon icon={['far', 'repeat-alt']} className="fa-fw va-b" /> Auto Payment
                                                            </div>
                                                        </td>
                                                        <td width="20%">
                                                            {data.status &&
                                                            <div className="text-nowrap">
                                                                {data.chargesTotal ?
                                                                   <div>
                                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + data.status + ".class"})}`}/>
                                                                    <span className = "ml-1">< FormattedMessage id={`enum.transactionStatus.${data.status}`}/></span>
                                                                   </div>
                                                                    :< span className = "ml-1" >---</span>
                                                                }
                                                            </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </React.Fragment>
                                        }

                                    </table>
                                </div>

                                <div className="card-body card-body-table">
                                    <table className="table table-hover">
                                        <thead>
                                        <tr>
                                            <th colSpan="5">
                                                Transaction History
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                {this.state.paymentTransactionList.totalRecordCount > 0 &&
                                <div className="card-body card-body-table">

                                    <Table tableClass="table-hover table-responsive-sm"
                                           columns={{createDate: 'Date', amount: 'Amount', property: 'Property', transactionType: 'Type', status: 'Status'}}
                                           columnWidths={['20%', '20%', '20%', '20%', '20%']}
                                           headerClass="c-pointer d-none"
                                           data={this.state.paymentTransactionList}
                                           query={this.state.paymentTransactionQuery}
                                           sortEnabled={true}
                                           recordsEnabled={true}
                                           recordsOptions={['5', '10', '25']}
                                           paginationEnabled={true}
                                           updateFunction={this.searchPaymentTransactions}>
                                        <tbody>
                                        {this.state.paymentTransactionList.records.map((data, key) => {
                                            return(
                                                <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                                    <td width="10%">
                                                        <h4 className="text-center mb-0">
                                                            <Moment format="DD">
                                                                {data.createDate}
                                                            </Moment>
                                                        </h4>
                                                        <div className="text-center">
                                                            <Moment format="MMM">
                                                                {data.createDate}
                                                            </Moment>
                                                        </div>
                                                    </td>
                                                    <td width="20%">
                                                        <div className="">

                                                            <div className="font-weight-bold">
                                                                <FormattedNumber value={data.amount + (data.feePaidByCompany ? 0 : data.feeAmount)} style={`currency`} currency={data.currency} />
                                                            </div>

                                                            <span className="">in charges</span>

                                                        </div>
                                                    </td>
                                                    <td width="30%">
                                                        <div className="">

                                                            {data.joins &&
                                                            <React.Fragment>

                                                                {data.joins.pl &&
                                                                <div className="font-weight-bold">
                                                                    {data.joins.pl.unit}
                                                                </div>
                                                                }

                                                                {data.joins.p &&
                                                                <div className="">
                                                                    {data.joins.p.street1}
                                                                </div>
                                                                }

                                                            </React.Fragment>
                                                            }

                                                        </div>
                                                    </td>
                                                    <td width="20%">
                                                        <div className="">
                                                            <FormattedMessage id={`enum.transactionType.${data.transactionType}`} />
                                                        </div>
                                                    </td>
                                                    <td width="20%">
                                                        {data.status &&
                                                        <div className="text-nowrap">
                                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + (data.status === 'TIMEDOUT' ? 'INPROCESS' : data.status) + ".class"})}`}/>
                                                            <span className="ml-1"><FormattedMessage id={`enum.transactionStatus.${data.status === 'TIMEDOUT' ? 'INPROCESS' : data.status}`}/></span>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>

                                </div>
                                }

                                {this.state.paymentTransactionList.totalRecordCount === 0 &&
                                <div className="card-body">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-secondary">
                                                <FontAwesomeIcon icon={['fas', 'receipt']} className="fa-fw mb-4" size="5x"/>
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    As you make payments, your transaction receipts will appear here.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>

                        </div>
                    </div>

                    <div className="modal fade" id="property-lease" tabIndex="-1" role="dialog" aria-labelledby="property-lease-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">

                                <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">

                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['far', 'home']} className="fa-stack-1x text-dark" />
                                    </div>

                                    <h5 className="modal-title" id="property-lease-label">
                                        {(this.state.propertyLease.joins && this.state.propertyLease.joins.property) &&
                                        <React.Fragment>
                                            {this.state.propertyLease.unit ? this.state.propertyLease.unit + ' - ' : ''}{this.state.propertyLease.joins.property.street1}
                                        </React.Fragment>
                                        }
                                    </h5>

                                    {this.state.propertyLease.monthlyPaymentDueDay > 0 &&
                                    <p className="mb-0 small">
                                        Payment due monthly on the {ordinal(this.state.propertyLease.monthlyPaymentDueDay)}
                                    </p>
                                    }

                                    {this.state.propertyLease.monthlyPaymentDueDay === 0 &&
                                    <p className="mb-0 small">
                                        Managed by {this.state.propertyLease.joins.company.name}
                                    </p>
                                    }

                                </div>

                                {(this.state.propertyLease.status === 'ACTIVE' && this.state.propertyLease.recurringSchedule) &&
                                <div className="modal-body bg-secondary border-top-0">
                                    <div className="row">
                                        <div className="col-6 align-self-center">
                                            <p className="mb-0">
                                                Auto Payments
                                            </p>
                                            <small className="btn-link c-pointer" data-dismiss="modal" onClick={() => this.initRecurringSchedule()}>
                                                Edit auto pay settings <FontAwesomeIcon icon={['far', 'angle-right']} className="va-b" />
                                            </small>
                                        </div>
                                        <div className="col-6 align-self-center text-right">

                                            <FieldSwitch id="active" labelClass="pt-0" model="recurringSchedule" parent={this} value={this.state.propertyLease.recurringSchedule && this.state.propertyLease.recurringSchedule.active} handleChange={this.handleChangeRecurringScheduleStatus} />

                                        </div>
                                    </div>
                                </div>
                                }
                                {(this.state.propertyLease.status === 'ACTIVE' && !this.state.propertyLease.recurringSchedule) &&
                                <div className="modal-body bg-secondary border-top-0">

                                        <div className="row">
                                            <div className="col-md-8 align-self-center">
                                                <p className="card-text mb-0">
                                                    Set up automatic monthly payments
                                                </p>
                                            </div>
                                            <div className="col-md-4 align-self-center text-right">
                                                <div className="btn btn-primary btn-md mt-4 mt-md-0" onClick={() => this.initRecurringSchedule()}>
                                                    Auto Pay <FontAwesomeIcon icon={['far', 'angle-double-right']} className="va-b" />
                                                </div>

                                            </div>

                                    </div>
                                </div>
                                }

                                {(this.state.propertyLease.status === 'SUSPENDED' || this.state.propertyLease.status === 'TERMINATED') &&
                                <div className="modal-body bg-danger border-top-0">
                                    <p className="mb-0 text-white">
                                        Your property manager has indicated that this lease is suspended or terminated.
                                    </p>
                                </div>
                                }

                                {this.state.propertyLease.openCharges &&
                                <React.Fragment>

                                    {this.state.propertyLease.openCharges.length > 0 &&
                                    <div className="modal-body modal-body-table">
                                        <table className="table mb-0">
                                            <tbody>

                                            {this.state.propertyLease.openCharges.map((openCharge, key) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td className="">
                                                                <p className="mb-0">
                                                                    <small>
                                                                        <Moment format="MMM YYYY" tz="UTC">{openCharge.postMonth}</Moment> <FormattedMessage id={`charge.${openCharge.code}`} defaultMessage={openCharge.name ? openCharge.name : openCharge.code} />
                                                                        {(openCharge.amount > 0 && moment(new Date()).isAfter(openCharge.dueDate)) &&
                                                                        <small className="ml-1 text-danger font-weight-bold text-uppercase">
                                                                            Overdue
                                                                        </small>
                                                                        }
                                                                    </small>
                                                                </p>
                                                            </td>
                                                            <td className="text-right">
                                                                <FormattedNumber value={openCharge.balance} style={`currency`} currency="USD"/>
                                                            </td>
                                                        </tr>
                                                    )
                                            })}

                                            <tr>
                                                <td className="font-weight-bold">
                                                    <p className="mb-0">
                                                        Current Balance
                                                    </p>
                                                </td>
                                                <td className="text-right font-weight-bold">
                                                    <FormattedNumber value={this.state.propertyLease.currentBalance} style={`currency`} currency="USD" />
                                                </td>
                                            </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    }

                                    {this.state.propertyLease.openCharges.length === 0 &&
                                    <div className="modal-body text-center">
                                        <div className="row justify-content-center py-3">
                                            <div className="col-10">
                                                <div className="text-center text-muted">
                                                    <small>
                                                        You have no outstanding charges due for your lease! Your breakdown for next month's payments will be listed here closer to your next payment due date.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                </React.Fragment>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="receipt-actions">
                                        <div className="float-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                                <FormattedMessage id="button.close" />
                                            </button>
                                        </div>
                                        {(this.state.propertyLease.openCharges && this.state.propertyLease.openCharges.length > 0) &&
                                        <div className="float-lg-right">
                                            <Link to="/customer/payments/payment" className="btn btn-primary btn-lg" onClick={() => {$('#property-lease').modal('hide');}}>
                                                Make a Payment
                                            </Link>
                                        </div>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="recurring-schedule" tabIndex="-1" role="dialog" aria-labelledby="recurring-schedule-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveRecurringSchedule} autoComplete="off">

                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="recurring-schedule-label">
                                            Auto Pay Settings
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                        </button>
                                    </div>

                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            Select the monthly payment start date and preferred payment method for your automatic monthly payments below.
                                        </p>
                                    </div>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldDate id="startDate" label="Start Date" filterDateType={this.state.propertyLease.monthlyPaymentDueDay > 0 ? 'MONTHLY_PAYMENT_DUE_DAY' : ''} monthlyPaymentDueDay={this.state.propertyLease.monthlyPaymentDueDay} labelClass="col-form-label-md d-none" placeholder="Start Date" fieldClass="form-control-md" fieldColumns="12" labelColums="0" model="recurringSchedule" parent={this} minDate={this.addDays(new Date(), 1)} help="Your will be charged every month starting on your selected date." value={this.state.recurringSchedule.startDate}>
                                            {this.state.propertyLease.monthlyPaymentDueDay > 0 &&
                                            <div className="text-center font-weight-bold py-2">
                                                Payment is due by<br/>the {ordinal(this.state.propertyLease.monthlyPaymentDueDay)} of each month
                                            </div>
                                            }
                                        </FieldDate>

                                        {this.state.paymentMethodList.map((data, key) => {

                                            if (data.type !== 'TYPE_CASH') {
                                                return (
                                                    <div key={key} className="list-group mb-2">
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                            <input type="radio" name="paymentMethod" value={JSON.stringify(data)} id={key} checked={this.state.paymentMethod.id === data.id || false} onChange={this.handleChangePaymentMethod} className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Bank Account
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                <FormattedMessage id={"enum.creditCard.brand." + data.brand}/>
                                                                            </React.Fragment>
                                                                            }
                                                                        </div>
                                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                                            {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                            <React.Fragment>
                                                                                Account number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                            {data.type === 'TYPE_CREDIT_CARD' &&
                                                                            <React.Fragment>
                                                                                Card number ending in {data.last4}
                                                                            </React.Fragment>
                                                                            }
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right mr-2">
                                                                            <CardBrandIcon paymentMethodType={data.type} brand={data.brand} customClasses="w-75"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return null;

                                        })}

                                        <div className="list-group mb-2">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initPaymentMethod('credit_card')}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Credit or Debit Card
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initPaymentMethod('bank_account')}>
                                                <div className="row align-items-center">
                                                    <div className="col-8">
                                                        Add New Bank Account
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-6 text-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#property-lease").modal('show')}}>Back</button>
                                            </div>
                                            <div className="col-6 text-right">

                                                {this.state.paymentMethodList.length > 0 &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Save</button>
                                                }

                                                {this.state.paymentMethodList.length === 0 &&
                                                <div className="btn btn-primary btn-lg ml-2 disabled" data-toggle="tooltip" data-placement="top" title="In order to set up automatic monthly payments, you must have at least one payment method on file">Save</div>
                                                }

                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="credit-card" tabIndex="-1" role="dialog" aria-labelledby="card-card-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.savePaymentMethod}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="card-card-label">
                                            Add New Credit or Debit Card
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        {!this.state.paymentMethod.createDate &&
                                        <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} columnClasses="col-8"/>
                                        }

                                        <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                        <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                        <div className="row mb-3">
                                            <div className="col">

                                                <FieldSelect id="expiryMonth" label="Expiry Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                    <option value="">-</option>
                                                    <option value="1">01 - Jan</option>
                                                    <option value="2">02 - Feb</option>
                                                    <option value="3">03 - Mar</option>
                                                    <option value="4">04 - Apr</option>
                                                    <option value="5">05 - May</option>
                                                    <option value="6">06 - Jun</option>
                                                    <option value="7">07 - Jul</option>
                                                    <option value="8">08 - Aug</option>
                                                    <option value="9">09 - Sep</option>
                                                    <option value="10">10 - Oct</option>
                                                    <option value="11">11 - Nov</option>
                                                    <option value="12">12 - Dec</option>
                                                </FieldSelect>

                                            </div>
                                            <div className="col">

                                                <FieldSelect id="expiryYear" label="Expiry Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                    <option value="">-</option>
                                                    <option value="2022">2022</option>
                                                    <option value="2023">2023</option>
                                                    <option value="2024">2024</option>
                                                    <option value="2025">2025</option>
                                                    <option value="2026">2026</option>
                                                    <option value="2027">2027</option>
                                                    <option value="2028">2028</option>
                                                    <option value="2029">2029</option>
                                                    <option value="2030">2030</option>
                                                    <option value="2031">2031</option>
                                                    <option value="2032">2032</option>
                                                    <option value="2033">2033</option>
                                                </FieldSelect>

                                            </div>
                                            <div className="col">

                                                <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['securityCode']} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />

                                            </div>
                                        </div>

                                        {!this.state.showBillingAddressForm &&
                                        <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                            <option value="">Select from your billing addresses...</option>

                                            {this.state.billingAddressList.map((data, key) => {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                    </option>
                                                );
                                            })}

                                            <option value="NEW">Add new billing address...</option>

                                        </FieldSelect>
                                        }

                                        {this.state.showBillingAddressForm &&
                                        <div className="mb-2">

                                            <div className="mt-4 mb-3">
                                                <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                            </div>

                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                        </div>
                                        }

                                    </div>
                                    <div className="modal-footer d-block bg-secondary rounded-bottom">

                                        <div className="row">
                                            <div className="col">
                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#credit-card').modal('hide'); $('#recurring-schedule').modal('show');}}>Back</button>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonSave />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="bank-account" tabIndex="-1" role="dialog" aria-labelledby="bank-account-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.savePaymentMethod}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="bank-account-label">
                                            Add New Bank Account
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            <small>
                                                Upon confirming a payment or when your scheduled payment date arrives, you will be charged immediately, but it takes up to 5 business days for the funds to be withdrawn from your bank account (your landlord or property manager is aware of this). Please ensure sufficient funds are in your account prior to the payment being debited.
                                            </small>
                                        </p>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['country']} />

                                        {this.state.paymentMethod['country'] &&
                                        <React.Fragment>

                                            {this.state.paymentMethod['country'] === 'CA' &&
                                            <React.Fragment>

                                                <FieldText id="bankAccountInstitutionNumber" label="Institution Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountInstitutionNumber']} />

                                                <FieldText id="bankAccountTransitNumber" label="Transit Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountTransitNumber']} />

                                            </React.Fragment>
                                            }

                                            {this.state.paymentMethod['country'] === 'US' &&
                                            <React.Fragment>

                                                <FieldText id="routingNumber" label="Routing Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['routingNumber']} />

                                            </React.Fragment>
                                            }

                                            <FieldText id="bankAccountNumber" label="Account Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['bankAccountNumber']} />

                                            <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                            {!this.state.showBillingAddressForm &&
                                            <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                                <option value="">Select from your billing addresses...</option>

                                                {this.state.billingAddressList.filter((data) => (data.country === "CA" || data.country === "US")).map((data, key) => {
                                                    return (
                                                        <option key={key} value={data.id}>
                                                            {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                        </option>
                                                    );
                                                })}

                                                <option value="NEW">Add new billing address...</option>

                                            </FieldSelect>
                                            }

                                            {this.state.showBillingAddressForm &&
                                            <div className="mb-2">

                                                <div className="mt-4 mb-3">
                                                    <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                                </div>

                                                <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} />

                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </div>
                                    <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                        <div className="row">
                                            <div className="col">
                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#bank-account').modal('hide'); $('#recurring-schedule').modal('show');}}>Back</button>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonSave />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <ModalUnionPay parent={this} />

                    <ReceiptPaymentTransaction paymentTransaction={this.state.paymentTransaction} displayFeeAmount={true}>

                        <div className="float-left">
                            <ButtonClose />
                        </div>

                        <div className="float-right">
                            <div className="btn btn-primary btn-lg" onClick={() => window.print()}>
                                <FontAwesomeIcon icon={['far', 'print']} className="fa-fw va-b" /> Print
                            </div>
                        </div>

                    </ReceiptPaymentTransaction>

                    <ReceiptScheduledPayment scheduledPayment={this.state.scheduledPayment}>

                        <div className="float-left">
                            <ButtonClose />
                        </div>

                        <div className="float-right">

                            {!this.state.scheduledPayment.recurringScheduleId &&
                            <div className="btn btn-primary btn-lg" onClick={() => {$("#receipt-scheduled-payment").modal("hide");$("#delete-scheduled-payment").modal("show");}}>
                                <FontAwesomeIcon icon={['far', 'hand-paper']} className="fa-fw va-b"/> Stop
                            </div>
                            }

                            {!this.state.scheduledPayment.recurringScheduleId &&
                            <div className="btn btn-primary btn-lg ml-2" data-dismiss="modal" onClick={() => $('#scheduled-payment').modal('show')}>
                                <FontAwesomeIcon icon={['far', 'pencil-alt']} className="fa-fw va-b"/> Edit
                            </div>
                            }

                        </div>

                    </ReceiptScheduledPayment>

                </div>

            </div>
        )
    };
}

Payments.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payments);
