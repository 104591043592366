import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The admin transactions list.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Transactions" />

                    <TransactionList global={true}
                                     widthAlign="450px"
                                     textSearchPlaceholderOverridescheduledPayment = "Search Scheduled Payments"
                                     history={this.props.history}
                                     displayFeeAmount={true}
                                     displayAdvanced={true}
                                     userType="TYPE_ADMIN" />

                </div>

            </div>
        )
    };
}

export default Transactions;