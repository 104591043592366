import React from 'react';

const ModalFC = (props) => {
  return (
    <div
      className='modal fade mt-4'
      id={props.modalId || 'modalFC'}
      tabIndex='-1'
      role='dialog'
      aria-labelledby='modalFCLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content shadow'>
          <div className='modal-header'>
            {props.modalTitle && (
              <h5 className='modal-title' id={props.modalId ? `${props.modalId}Label` : 'modalFCLabel'}>
                {props.modalTitle}
              </h5>
            )}
            <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body'>{props.modalBody && <>{props.modalBody}</>}</div>
          <div className='modal-footer rounded-bottom'>
            <button type='button' className='btn btn-secondary' data-dismiss='modal'>
              Close
            </button>
            {props.saveBtnText && props.saveBtnAction &&
                <button type='button' className='btn btn-primary' onClick={() => {props.saveBtnAction()}}>
                {props.saveBtnText}
                </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFC;
