/**
 * Clean and validate client name
 * If client name is not from one of the cases,
 * redirects users to the normal login page
 * @param {string} clientName
 * @returns
 */
export const validateAndCleanClientName = (clientName) => { 
  const clientNameUpperCase = clientName?.toUpperCase();
  let cleanedClientName = null;
  let clientNameSmall = null; // for logos
  switch (clientNameUpperCase) {
    case 'MIDWEST':
      cleanedClientName = 'Midwest';
      clientNameSmall = 'midwest';
      break;
    case 'DEVON':
      cleanedClientName = 'Devon Properties';
      clientNameSmall = 'devon';
      break;
    case 'KANEFF':
      cleanedClientName = 'Kaneff';
      clientNameSmall = 'kaneff';
      break;
    case 'BGO':
      cleanedClientName = 'Bentall Green Oak';
      clientNameSmall = 'bgo';
      break;
    case 'GEC':
      cleanedClientName = 'Global Education Cities';
      clientNameSmall = 'gec';
      break;
    case 'CCC':
      cleanedClientName = 'Canadian Campus Communities';
      clientNameSmall = 'ccc';
      break;
    case 'SUMMIT':
      cleanedClientName = 'Summit';
      clientNameSmall = 'summit';
      break;
    case 'LETUS':
      cleanedClientName = 'Letus';
      clientNameSmall = 'letus';
      break;
    case 'DREWLO':
      cleanedClientName = 'Drewlo Lifestyle Apartments';
      clientNameSmall = 'drewlo';
      break;
    case 'KILLAM':
      cleanedClientName = 'Killam Apartment';
      clientNameSmall = 'killam';
      break;
    case 'MAYFIELD':
      cleanedClientName = 'Mayfield Management Group';
      clientNameSmall = 'mayfield';
      break;
    case 'METCAP':
      cleanedClientName = 'MetCap Living';
      clientNameSmall = 'metcap';
      break;
    case 'OBSIDIAN':
      cleanedClientName = 'OBSIDIAN Property Management';
      clientNameSmall = 'obsidian';
      break;
    case 'CLV':
      cleanedClientName = 'CLV GROUP';
      clientNameSmall = 'clv';
      break;
    case 'CAPREIT':
      cleanedClientName = 'Canadian Apartment Properties';
      clientNameSmall = 'capreit';
      break;
    default:
      break;
  }
  if (cleanedClientName) {
    return { cleanedClientName, clientNameSmall };
  } else {
    window.location.href = '/login';
  }
};
