import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonSubmit from "../../common/ButtonSubmit";
import FieldAddress from "../../common/FieldAddress";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCompanyType from "../../common/FieldCompanyType";
import FieldSegmentCode from "../../common/FieldSegmentCode";
import FieldText from "../../common/FieldText";
import FieldTextarea from "../../common/FieldTextarea";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";
import FieldSelectLanguage from "../../common/FieldSelectLanguage";

class Companies extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},

            companyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            companySearchFilter: '',

            companyQuery: {
                orderBy: 'ASC',
                orderByFields: ['name'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'landlordId',
                        operator: 'EQUALS',
                        fieldValue: null
                    },
                ],
                joins: {
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'parentId',
                        alias: 'c',
                        returnFields: ['name']
                    }
                }
            },

            validationList: [],

        };

        this.searchCompanies = this.searchCompanies.bind(this);
        this.filterCompanies = this.filterCompanies.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.selectCompany = this.selectCompany.bind(this);
        this.initParentCompany = this.initParentCompany.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
    }

    /**
     * Initialize the parent company list by calling the update table function.
     */
    componentDidMount() {

        this.searchCompanies(1, 25, this.state.companyQuery);
    }

    /**
     * Handle clicking company by redirecting the user to the company overview component.
     *
     * @param id - The ID of the company clicked on.
     */
    selectCompany(event, id) {
        if (event.metaKey || event.ctrlKey){
            window.open(`/admin/companies/${id}/edit`, "_blank");
        } else {
            this.props.history.push('/admin/companies/' + id + "/edit");
        }
    }

    /**
     * Initialize a new instance of a parent company object when the user clicks the 'create parent company' button.
     */
    initParentCompany() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let company = response.data;

            company['serviceCreditReporting'] = {PROPERTY_CATEGORY:{
                RESIDENTIAL: true,
                COMMERCIAL: false
            }};

            this.setState(prevState => ({
                ...prevState,
                company: company
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveCompany(event) {

        event.preventDefault();

        let company = this.state.company;

        company.status = "ACTIVE";

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.company.created'
                    }
                }],
            });

            this.searchCompanies(this.state.companyList.page, this.state.companyList.recordsPerPage, this.state.companyQuery);

            $('#company').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter field by adjusting the parent company search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterCompanies(event) {

        if (event != null) {
            event.preventDefault();
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let companyQuery = this.state.companyQuery;

        companyQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'landlordId',
                operator: 'EQUALS',
                fieldValue: null
            },
        ];

        if(this.state.companySearchFilter !== '') {
            companyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.companySearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'name,legalName,companyIdentifier',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.companySearchFilter
                }
            );
        }

        this.setState({
            companyQuery: companyQuery
        });

        this.searchCompanies(1, 25, companyQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        this.setState({
            companySearchFilter: '',
        }, () => {
            this.filterCompanies();
        });
    }

    /**
     * Update the data table.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCompanies(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                companyList: response.data,
                companyQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        return (
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Companies" />

                    <Spinner visible={this.state.spinner} />

                    <Alert validationList={this.state.validationList} validationType="primary"/>

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <span className="">Companies</span>
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#company" className="btn btn-primary btn-sm" onClick={() => this.initParentCompany()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Parent Company
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterCompanies}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="companySearchFilter" label="Company Name" labelClass="d-none"
                                                   fieldColumns="12" labelColums="0" placeholder="Filter by ID, name or legal name..."
                                                   parent={this} value={this.state.companySearchFilter} />
                                    </div>
                                    <div className="align-self-center text-right">
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                            </button>
                                            <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{name: 'Name', legalName: 'Legal Name', companyIdentifier: 'Company Identifier', parentId: 'Parent Company', city: 'City', province: 'State/Province'}}
                               columnWidths={['18%', '18%', '16%', '16%', '16%', '16%']}
                               headerClass="c-pointer"
                               data={this.state.companyList}
                               query={this.state.companyQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchCompanies}>
                            <tbody>
                            {this.state.companyList.records.map((data, key) => {
                                return (
                                    <tr key={key} onClick={(event) => this.selectCompany(event, data.id)} className={`c-pointer ${data.parentId ? 'table-secondary' : ''}`}>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            <div className="">
                                                {data.name}
                                            </div>
                                        </td>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            <div className="">
                                                {data.legalName}
                                            </div>
                                        </td>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            <div className="">
                                                {data?.companyIdentifier}
                                            </div>
                                        </td>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            {data.joins && data.joins.c &&
                                            <div className="">
                                                {data.joins.c.name}
                                            </div>
                                            }
                                        </td>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            <div className="">
                                                {data.city}
                                            </div>
                                        </td>
                                        <td className={`break-word ${data.parentId ? 'border' : ''}`}>
                                            <div className="">
                                                {data.country === 'CA' &&
                                                <FormattedMessage id={"province." + data.province}/>
                                                }
                                                {data.country === 'US' &&
                                                <FormattedMessage id={"state." + data.province}/>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="company" tabIndex="-1" role="dialog" aria-labelledby="company-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveCompany}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="company-label">
                                            Create Parent Company
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger"/>

                                        <FieldText id="name" label="Company Name" model="company" parent={this} value={this.state.company['name']}/>

                                        <FieldText id="legalName" label="Legal Name" model="company" parent={this} value={this.state.company['legalName']}/>

                                        <FieldCompanyType id="companyType" label="Company Type" model="company" parent={this} value={this.state.company['companyType']}/>

                                        <FieldText id="companyIdentifier" label="Company Identifier" model="company" parent={this} value={this.state.company['companyIdentifier']}/>

                                        <FieldSegmentCode id="segmentCode" label="Segment Code" model="company" required={true} parent={this} value={this.state.company['segmentCode']} />

                                        <FieldText id="phone" label="Business Phone" model="company" parent={this} value={this.state.company['phone']}/>

                                        <FieldText id="taxNumber" label="Tax ID" model="company" parent={this} value={this.state.company['taxNumber']}/>

                                        <FieldBirthDate id="inceptionDate" label="Inception Date" model="company" parent={this} required={true} value={this.state.company['inceptionDate']} monthFirst={true}/>

                                        <FieldSelectLanguage id="languageCode" label="Tenant Language Default" model="company" parent={this} value={this.state.company['languageCode']}/>

                                        <FieldTextarea id="description" label="Description" model="company" parent={this} value={this.state.company['description']}/>

                                        <FieldAddress model="company" parent={this} value={this.state.company}/>

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col">
                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#company").modal("hide")}>Close</button>
                                                </div>
                                                <div className="float-right">
                                                    <ButtonSubmit />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Companies.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Companies);