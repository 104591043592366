import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The landlord tenants component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Tenants" />

                    <TenantList userType={this.props.userSession.sessionRole.type}
                                userId={this.props.userSession.sessionRole.id}
                                companyId={this.props.userSession.sessionRole.companyId}
                                history={this.props.history}
                                createTenant={false}
                                inviteTenant={true}
                                textSearchPlaceholderOverrideTenants = "Search All Tenants"
                                textSearchPlaceholderOverrideInvitations = "Search All Invitations"
                                inviteTenantDisabled={localStorage.getItem('status') === 'PAID'}
                                inviteTenantTooltip="Before being able to invite tenants, you must first complete your Propertii account setup"
                                parent={this} />

                </div>

            </div>
        )
    };
}

export default Tenants;