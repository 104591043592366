import axios from "axios";
import creditCardType from "credit-card-type";
import { Component } from 'react';
import { CreditCardRegexArray } from '../../constants/CreditCards';
import * as constants from "../../util/constants";

class Letus extends Component {

    /**
     * Initialize the component. This component acts as a generic base component to house all commonly used functions to
     * reduce code duplication. Most pages will extend this base component. By default, all components should have the
     * state established below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            spinner: false,
            validationList: []
        };

        this.stopSpinner = this.stopSpinner.bind(this);
        this.handleClearValidationList = this.handleClearValidationList.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleSuccessAlert = this.handleSuccessAlert.bind(this);
        this.handleLoginRedirect = this.handleLoginRedirect.bind(this);

        this.getCardBrand = this.getCardBrand.bind(this);
        this.getCardSecurityCodeLabel = this.getCardSecurityCodeLabel.bind(this);

        this.generateRequestHeaders = this.generateRequestHeaders.bind(this);

        this.addDays = this.addDays.bind(this);

        this.handleFocusPaymentMethodField = this.handleFocusPaymentMethodField.bind(this);
        this.handleBlurPaymentMethodField = this.handleBlurPaymentMethodField.bind(this);
        this.setCustomErrorCodeState = this.setCustomErrorCodeState.bind(this);
        this.setCustomAlert = this.setCustomAlert.bind(this);
        this.setErrorMessage = this.setErrorMessage.bind(this);

        this.getAllCompanies = this.getAllCompanies.bind(this);
    }

    /**
     * Sets the state in case a custom error code is passed
     */
    setCustomErrorCodeState(error) {
        this.setState({
            spinner: false,
            validationList: [{
                alert: {
                    type: 'danger',
                    code: error.response.data.customErrorCode,
                },
                values: {
                    errorCause: '',
                }
            }]
        });
    }

    /**
     * Sets a custom alert based on type and alert code
     */
    setCustomAlert(type, alertCode) {
        this.setState({
            spinner: false,
            validationList: [{
                alert: {
                    type: type,
                    code: alertCode,
                },
                values: {
                    na: '',
                }
            }]
        });
    }

    /**
     * Sets a custom error message
     */
     setErrorMessage(errorMessage) {
        this.setState({
            spinner: false,
            validationList: [{
                alert: {
                    type: 'danger',
                    message: errorMessage,
                }
            }]
        });
    }

    /**
     * Starts the fullscreen spinner
     */
     startSpinner() {
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));
    }

    /**
     * Stop the spinner to unblock users
     */
    stopSpinner() {
        this.setState(prevState => ({
            ...prevState,
            spinner: false
        }));
    }

    /**
     * Clear validation list after 10 seconds
     */
    handleClearValidationList() {
        setTimeout(() => {
        this.setState(prevState => ({
            ...prevState,
            validationList: []
        }));
        }, 20000); 
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state. You
     * may override this method by creating a method with the same handle in the corresponding child class.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {
        let validationList = [];
        let validation = {};
        let fields = {};
        let recordType = '';

        if(error.response && error.response.data && error.response.data.recordType) {
            recordType = error.response.data.recordType;
        }

        this.setState({
            spinner: false,
        });

        // Handle access token expired failures
        if(error.response && error.response.data && error.response.data.errorCode === 'RM_ERROR_ACCESS_TOKEN_EXPIRED') {

            validation = {
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: 'Your session has expired.  Please log into the application again.'
                },
                fields
            };

            validationList.push(validation);

            this.setState({
                spinner: false,
                validationList: validationList,
            });
        } else if(error.response && error.response.data && error.response.data.errorCode === 'RM_ERROR_RECORD_NOT_FOUND') {
            if(error.response.data?.customErrorCode) {
                this.setCustomErrorCodeState(error);
            } else {
                this.setState({
                    spinner: false,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            code: error.response.data.errorCode,
                            message: error.response.data.message
                        }
                    }]
                });
            }
            
        } else if(error.response && error.response.data && error.response.data.errorCode === 'RM_SAVE_LIST_FAILURE') {

            if(error.response.data.exceptionList) {
                Object.entries(error.response.data.exceptionList).forEach(([key, exception]) => {

                    if(exception.exception.fieldErrors) {

                        fields = {};

                        Object.entries(exception.exception.fieldErrors).forEach(([key, fieldError]) => {
                            fields[fieldError.fieldName] = fieldError.errorCode;
                        });
                    }

                    validation = {
                        alert: {
                            type: 'danger',
                            code: exception.exception.errorCode,
                            message: exception.exception.message
                        },
                        fields
                    };

                    validationList.push(validation);
                });
            }

            this.setState({
                spinner: false,
                validationList: validationList,
            });
        } else if(error.response && error.response.data && error.response.data.errorCode !== 'RM_SAVE_LIST_FAILURE') {
            this.setState({
                spinner: false,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode,
                        message: error.response.data.message
                    }
                }]
            });
        } else if(error.response && error.response.data && error.response.data.errorCode === 'RM_ERROR_RECORD_ALREADY_EXIST') {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode + (recordType ? ('.' + recordType) : ''),
                        message: error.response.data.message
                    },
                    fields
                }],
            });

            return;
        } else if(error.response && error.response.data && error.response.data.fieldErrors) {
             // Handle all other singular validation errors
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );

            validation = {
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: error.response.data.message
                },
                fields
            };

            validationList.push(validation);

            this.setState({
                spinner: false,
                validationList: validationList
            });
        }
    }

    /**
     * Allow to access values object in success alert handling
     * @param {*} valuesObj - Object of key-values pair to populate the coded message
     */
    handleSuccessAlert(response, valuesObj={na:""}) {
        this.setState({
            spinner: false,
            validationList: [{
                fields: {},
                alert: {
                    type: 'primary',
                    code: response.data.alertCode,
                },
                values: valuesObj
            }]
        });
    }

    /**
     * Handle redirecting the user to their appropriate page after successfully logging in.
     *
     * @param sessionRole - The session role data for the user who has successfully logged in.
     */
    handleLoginRedirect(sessionRole) {

        if(sessionRole == null) {
            return "/";
        }

        switch(sessionRole.type) {

            case "TYPE_ADMIN":
                return "/admin/dashboard";

            case "TYPE_MANAGER":
                return "/manager/dashboard";

            case "TYPE_LANDLORD":

                localStorage.setItem('status', sessionRole.applicationStatus);

                switch(sessionRole.applicationStatus) {

                    case "PENDING":
                    case "APPLIED":
                        return "/landlord/signup/application";

                    case "PAID":
                    case "COMPLETE":
                        return "/landlord/transactions";

                    default:
                        return "/login";

                }

            case "TYPE_CUSTOMER":
                return "/customer/payments";

            default:
                return "/login";

        }
    }

    /**
     * Fetch an individual list of each type of merchant account: bank, cash, PAD (all merchant accounts), and credit
     * for populating merchant account mapping select boxes.
     *
     * @param companyId - The ID of the company to search merchant accounts by.
     * @param paymentType - The payment type to search merchant accounts by. If no payment type is provided, search for
     * all merchant accounts for PAD purposes.
     * @param cardBrand - The brand of card, only applicable if the payment type is of 'TYPE_CREDIT_CARD'.
     */
    getMerchantAccounts(companyId, paymentType, cardBrand, paymentProcessor=null) {

        let conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: companyId
            }, {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'paymentType',
                operator: 'EQUALS',
                fieldValue: paymentType
            },
        ];

        // Remove the payment type condition if no payment type is provided
        if(paymentType === null) {
            conditionList.splice(1, 1);
        }

        // Apply a card type condition if the payment type is credit
        if(paymentType === "TYPE_CREDIT_CARD") {
            conditionList.push({
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'pp.supportedCards',
                operator: 'MATCH',
                fieldValue: cardBrand
            })
        }

        // Apply a card type condition if the payment type is wallet
        if(paymentType === "TYPE_WALLET") {
            conditionList.push({
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'pp.supportedWallets',
                operator: 'MATCH',
                fieldValue: cardBrand
            })
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: conditionList,
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: [
                        'creditMerchantAccountList', 
                        'bankMerchantAccountId', 
                        'cashMerchantAccountId', 
                        'padMerchantAccountId',
                        'paypalMerchantAccountId',
                        'splitItMerchantAccountId',
                        'walletMerchantAccountList'
                    ]
                },
                pp: {
                    targetRecordType: 'TYPE_PAYMENT_PROVIDER',
                    joinField: 'paymentProviderId',
                    alias: 'pp',
                    returnFields: ['supportedCards', 'supportedWallets']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let merchantAccounts = response.data.records;
            
            if(paymentProcessor && cardBrand !== 'UNION_PAY') {
                merchantAccounts = response.data.records.filter(
                    (record) =>  record.paymentProviderId === paymentProcessor
                ); 
            }

            switch(paymentType) {

                case 'TYPE_CREDIT_CARD':

                    switch(cardBrand) {

                        case 'VISA':
                            this.setState(prevState => ({
                                ...prevState,
                                visaMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'VISA_ELECTRON':
                            this.setState(prevState => ({
                                ...prevState,
                                visaElectronMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'MASTERCARD':
                            this.setState(prevState => ({
                                ...prevState,
                                mastercardMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'MAESTRO':
                            this.setState(prevState => ({
                                ...prevState,
                                maestroMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'AMERICAN_EXPRESS':
                            this.setState(prevState => ({
                                ...prevState,
                                americanExpressMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'DISCOVER':
                            this.setState(prevState => ({
                                ...prevState,
                                discoverMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'DINERS_CLUB':
                            this.setState(prevState => ({
                                ...prevState,
                                dinersClubMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'JCB':
                            this.setState(prevState => ({
                                ...prevState,
                                jcbMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'UNION_PAY':
                            this.setState(prevState => ({
                                ...prevState,
                                unionPayMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        default:
                            break;

                    }

                    break;

                case 'TYPE_BANK_ACCOUNT':
                    this.setState(prevState => ({
                        ...prevState,
                        bankMerchantAccounts: merchantAccounts,
                    }));
                    break;
                case 'TYPE_PAY_PAL':
                    this.setState(prevState => ({
                        ...prevState,
                        paypalMerchantAccounts: merchantAccounts,
                    }));
                    break;
                case 'TYPE_CASH':
                    this.setState(prevState => ({
                        ...prevState,
                        cashMerchantAccounts: merchantAccounts,
                    }));
                    break;

                case 'TYPE_WALLET':

                    switch(cardBrand) {

                        case 'APPLE_PAY':
                            this.setState(prevState => ({
                                ...prevState,
                                applePayMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        case 'GOOGLE_PAY':
                            this.setState(prevState => ({
                                ...prevState,
                                googlePayMerchantAccounts: merchantAccounts,
                            }));
                            break;

                        default:
                            break;

                    }

                    break;

                default:
                    const filteredPadMerchantAccounts = [];
                    merchantAccounts.forEach(merchantAccount => {
                         if(merchantAccount?.paymentType === "TYPE_BANK_ACCOUNT"){
                            filteredPadMerchantAccounts.push(merchantAccount);
                         }
                    });
                    this.setState(prevState => ({
                        ...prevState,
                        padMerchantAccounts: filteredPadMerchantAccounts,
                    }));
                    break;

            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Return a promise after fetching all the merchant accounts
     * @param {*} companyId 
     */
    getAllMerchantAccountsPromise(companyId) {
        let conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: companyId
            }
        ];

        return axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: conditionList,
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: [
                        'creditMerchantAccountList', 
                        'bankMerchantAccountId', 
                        'cashMerchantAccountId', 
                        'padMerchantAccountId',
                        'paypalMerchantAccountId',
                        'splitItMerchantAccountId'
                    ]
                },
                pp: {
                    targetRecordType: 'TYPE_PAYMENT_PROVIDER',
                    joinField: 'paymentProviderId',
                    alias: 'pp',
                    returnFields: ['supportedCards', 'supportedWallets']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Fetch all lists of each type of merchant account: bank, cash, PAD (all merchant accounts), and credit
     * for populating merchant account mapping select boxes.
     *
     * @param companyId - The ID of the company to search merchant accounts by.
     */
    getAllMerchantAccounts(companyId) {
        this.getAllMerchantAccountsPromise(companyId).then(response => {
            if(!response.data?.records) return
            const visaMerchantAccounts = [];
            const visaElectronMerchantAccounts = [];
            const mastercardMerchantAccounts = [];
            const maestroMerchantAccounts = [];
            const americanExpressMerchantAccounts = [];
            const discoverMerchantAccounts = [];
            const dinersClubMerchantAccounts = [];
            const jcbMerchantAccounts = [];
            const unionPayMerchantAccounts = [];
            const bankMerchantAccounts = [];
            const paypalMerchantAccounts = [];
            const cashMerchantAccounts = [];
            const padMerchantAccounts = [];

            response.data.records.forEach((i) => {
                switch(i.paymentType) {
                    case 'TYPE_CREDIT_CARD':
                        if(!i.joins?.pp?.supportedCards) break;
                        if(i.joins.pp.supportedCards.includes('VISA'))
                            visaMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('VISA_ELECTRON'))
                            visaElectronMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('MASTERCARD'))
                            mastercardMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('MAESTRO'))
                            maestroMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('AMERICAN_EXPRESS'))
                            americanExpressMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('DISCOVER'))
                            discoverMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('DINERS_CLUB'))
                            dinersClubMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('JCB'))
                            jcbMerchantAccounts.push(i);
                        if(i.joins.pp.supportedCards.includes('UNION_PAY'))
                            unionPayMerchantAccounts.push(i);
                        break;
                    case 'TYPE_BANK_ACCOUNT':
                        bankMerchantAccounts.push(i);
                        break;
                    case 'TYPE_PAY_PAL':
                        paypalMerchantAccounts.push(i);
                        break;
                    case 'TYPE_CASH':
                        cashMerchantAccounts.push(i);
                        break;
                    default:
                        break;
                }
                padMerchantAccounts.push(i);
            })
            this.setState(prevState => ({
                ...prevState,
                visaMerchantAccounts,
                visaMerchantAccountsAll: visaMerchantAccounts,
                visaElectronMerchantAccounts,
                mastercardMerchantAccounts,
                maestroMerchantAccounts,
                americanExpressMerchantAccounts,
                discoverMerchantAccounts,
                dinersClubMerchantAccounts,
                jcbMerchantAccounts,
                unionPayMerchantAccounts,
                bankMerchantAccounts,
                bankMerchantAccountsAll: bankMerchantAccounts,
                paypalMerchantAccounts,
                cashMerchantAccounts,
                padMerchantAccounts,
                padMerchantAccountsAll: padMerchantAccounts
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Return a credit/debit card brand, based on the 'creditCardType' library's validation of an inputted card number,
     * in all uppercase.
     *
     * @param cardNumber - The credit or debit card number to validate.
     * @returns {string|null} - The credit card type, if available, in uppercase (i.e: 'VISA').
     */
    getCardBrand(cardNumber) {
        const creditCard = CreditCardRegexArray
            .find((creditCard) => creditCard.regex.test(cardNumber));
        let cardBrand = '';
        if (creditCard) {
            cardBrand = creditCard.brand;
        }
        return cardBrand;
    }

    /**
     * Return a credit card security code label matching that of the credit card type. For example, Visa's security code
     * is referred to as 'CVV', whereas Mastercard's security code label is referred to as 'CVC'. Utilizes the
     * 'creditCardType' library for validation.
     *
     * @param cardNumber - The credit or debit card number to validate.
     * @returns {string|*} - The credit or debit card security code label, or 'CVV' by default.
     */
    getCardSecurityCodeLabel(cardNumber) {

        let creditCards = creditCardType(cardNumber);

        if(creditCards[0] != null) {
            return creditCards[0].code.name;
        } else {
            return 'CVV';
        }
    }

    /**
     * Generate a request header for making API calls. Returns a JSON object containing the Content-Type, defaulted to
     * 'application/json', and Authorization parameters commonly used when making calls.
     *
     * @returns - A basic request header containing the Content-Type and Authorization params.
     */
    generateRequestHeaders() {

        return {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
        };
    }

    /**
     * Add days to a provided date. Useful for establishing a min or max date range for date picker fields.
     *
     * @param date - A date object, usually set to the current date.
     * @param days - The amount of days to add to the provided date.
     * @returns {Date} - A new date object with the added days.
     */
    addDays(date, days) {

        let result = new Date(date);

        result.setDate(result.getDate() + days);

        return result;
    }

    /**
     * Handle focusing on a payment method field. Used for the card preview component, where each element of the card
     * preview becomes highlighted as the user focuses on the appropriate field. If the security code field is focused,
     * trigger a flipping animation on the card preview component, revealing the security code element of the card
     * preview.
     *
     * @param field - The name of the field focused on.
     */
    handleFocusPaymentMethodField(field) {

        this.setState(prevState => ({
            ...prevState,
            activePaymentMethodField: field,
        }));

        if(field === 'securityCode') {
            this.setState(prevState => ({
                ...prevState,
                cardPreviewFlipped: true
            }));
        }
    }

    /**
     * Handle blurring on a payment method field. Used for the card preview component, where each element of the card
     * preview becomes highlighted as the user focused on the appropriate field. In this case, if any field is blurred,
     * set the active payment method field to blank, and flip the card preview component to it's original side.
     *
     * @param field - The name of the field blurred.
     */
    handleBlurPaymentMethodField(field) {

        this.setState(prevState => ({
            ...prevState,
            activePaymentMethodField: '',
        }));

        if(field === 'securityCode') {
            this.setState(prevState => ({
                ...prevState,
                cardPreviewFlipped: false
            }));
        }
    }

    /**
     * Fetch all the companies under a main company (includes main company itself)
     * @param id - parentCompanyId
     */
    getAllCompanies(parentCompanyId, recordsPerPage=2000, page=1) {
        return axios.post(
        `${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
            orderBy: "ASC",
            orderByFields: ["createDate"],
            conditionList: [
            {
                type: "STRING",
                logicalOperator: "AND",
                openBrackets: null,
                closeBrackets: null,
                fieldName: "id",
                operator: "EQUALS",
                fieldValue: parentCompanyId,
            },
            {
                type: "STRING",
                logicalOperator: "OR",
                openBrackets: null,
                closeBrackets: null,
                fieldName: "parentId",
                operator: "EQUALS",
                fieldValue: parentCompanyId,
            },
            ],
        },
        {
            headers: this.generateRequestHeaders(),
        }
        );
    }

    /**
     * Get company details based on the company ID
     */
    getCompany(companyId) {
        return axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}`, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Get property details based on the company ID
     */
    getProperty(propertyId) {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: propertyId
                }
            ],
            joins: {
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param rows - that to be cleaned for PDF download
     */
    getBodyForPDF(rows) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < rows.length; i++){
            rowAux = rows[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - that need to be cleaned for PDF download
    */
    getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        return headers;
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param rows - The data to be converted into PDF/CSV
    */
    transformDataForPDF(rows) {
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];
        if(rows.length > 0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }
        return dataForPDF;
    }
}

export default Letus;
