import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <TransactionList propertyId={this.props.match.params.propertyId} 
                    displayAdvanced={true}
                    displayFeeAmount={true}
                    textSearchPlaceholderOverride = "Search Property Transactions"
                    history={this.props.history}
                    userType="TYPE_ADMIN" />

            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);