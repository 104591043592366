import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Banner from './common/Banner';
import Letus from './common/Propertii';

class Privacy extends Letus {
  /**
   * Render the component.
   *
   * @returns {*} - A public-facing static component that displays the privacy policy page.
   */
  render() {
    const {formatMessage} = this.props.intl;
    return (
      <div className='content-block'>
        <Banner
          backgroundImg='coffee'
          heading='static.privacy.heading'
          subheading='static.privacy.subheading'
        />

        <div className='container'>
          <p className=''>
            {formatMessage({ id: "static.privacy.body.sec-1"})} {' '}
            <a href='https://let.us/' target='_blank' rel='noopener noreferrer'>
              www.let.us
            </a>{' '}
            {formatMessage({ id: "static.privacy.body.sec-1-or"})} {' '}
            <a href='https://let.us/' target='_blank' rel='noopener noreferrer'>
              let.us
            </a>{' '}
            {formatMessage({ id: "static.privacy.body.sec-1-continued"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.body.sec-2-title"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.body.sec-2-subtitle"})}
          </p>
          <ul>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-1"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-2"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-3"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-4"})}
            </li>
          </ul>

          <p className=''>
            {formatMessage({ id: "static.privacy.body.sec-2-also"})}
          </p>
          <ul>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-5"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.body.sec-2-list-6"})}
            </li>
          </ul>

          <p className=''>
            {formatMessage({ id: "static.privacy.body.sec-3-subheading"})}
          </p>

          <p className=''>{formatMessage({ id: "static.privacy.body.sec-3-use-info"})}</p>
          <ul>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-1"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-2"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-3"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-4"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-5"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-6"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-7"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-8"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-9"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-10"})}</li>
            <li className=''>{formatMessage({ id: "static.privacy.body.sec-3-list-11"})}</li>
          </ul>

          <h5>{formatMessage({ id: "static.privacy.opt-heading"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.body.opt-1"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.body.opt-2"})}{' '}
            <a href='mailto:help@let.us'>help@let.us</a>.
          </p>

          <h5>{formatMessage({ id: "static.privacy.info-sharing.heading"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.info-sharing.body-1"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.info-sharing.body-2"})}
          </p>

          <p className=''>{formatMessage({ id: "static.privacy.info-sharing.body-3"})}</p>
          <ul>
            <li className=''>
              {formatMessage({ id: "static.privacy.info-sharing.body-list-1"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.info-sharing.body-list-2"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.info-sharing.body-list-3"})}
            </li>
            <li className=''>
              {formatMessage({ id: "static.privacy.info-sharing.body-list-4"})}
            </li>
          </ul>

          <h5>{formatMessage({ id: "static.privacy.cookies-header"})}</h5>

          <p>
            {formatMessage({ id: "static.privacy.cookies.body-1"})}
          </p>

          <p className=''>
            {formatMessage({ id: "static.privacy.cookies.body-2"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.cookies.body-3"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.links.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.links.body"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.security.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.security.body-1"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.security.body-2"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.security.body-3"})}{' '}
            <a href='mailto:help@let.us'>help@let.us</a>{formatMessage({ id: "static.privacy.security.body-3-cont"})}
          </p>
          <p className=''>
            {formatMessage({ id: "static.privacy.security.body-4"})} <a href='mailto:help@let.us'>help@let.us</a>.
          </p>

          <h5>{formatMessage({ id: "static.privacy.additional-info.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.additional-info.body"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.update-info.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.update-info.body"})} <a href='mailto:help@let.us'>help@let.us</a>
            .
          </p>

          <h5>{formatMessage({ id: "static.privacy.notification-changes.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.notification-changes.body"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.withdraw-consent.header"})}</h5>
          <p>
            {formatMessage({ id: "static.privacy.withdraw-consent.body"})}{' '}
            <a href='mailto:help@let.us'>help@let.us</a>. {formatMessage({ id: "static.privacy.withdraw-consent.body-cont"})}
          </p>

          <h5>{formatMessage({ id: "static.privacy.contact-info.header"})}</h5>

          <p className=''>
            {formatMessage({ id: "static.privacy.contact-info.subheader"})}
          </p>

          <p className=''>
            {formatMessage({ id: "static.privacy.contact-info-legalname"})}
            <br />
            {formatMessage({ id: "static.privacy.contact-info-address-1"})}
            <br />
            {formatMessage({ id: "static.privacy.contact-info-address-2"})}
            <br />
            {formatMessage({ id: "static.privacy.contact-info-attn"})}
            <br />
            {formatMessage({ id: "static.privacy.contact-info-email"})} <a href='mailto:help@let.us'>help@let.us</a>
            <br />
            {formatMessage({ id: "static.privacy.contact-info-phone"})} <a href='tel:18886658870'>1-888-665-8870</a>
          </p>

          <h5>{formatMessage({ id: "static.privacy.ccpa.header"})} </h5>
          <p>
            {formatMessage({ id: "static.privacy.ccpa.body-1"})}
          </p>
          <ul>
            <li>{formatMessage({ id: "static.privacy.ccpa.list-2"})} </li>
            <li>{formatMessage({ id: "static.privacy.ccpa.list-3"})}</li>
            <li>
              {formatMessage({ id: "static.privacy.ccpa.list-4"})}
            </li>
            <li>{formatMessage({ id: "static.privacy.ccpa.list-5"})} </li>
            <li>{formatMessage({ id: "static.privacy.ccpa.list-6"})}</li>
          </ul>
          <p>
            {formatMessage({ id: "static.privacy.ccpa.body-2"})}{' '}
            <a href='mailto:help@let.us'>help@let.us</a> {formatMessage({ id: "static.privacy.ccpa.body-2-cont"})}
          </p>
          <p>
            {formatMessage({ id: "static.privacy.ccpa.body-3"})}
          </p>
        </div>
      </div>
    );
  }
}

Privacy.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Privacy);
