import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  HYPER_WALLET_PAYMENT_CATEGORY,
  HYPER_WALLET_PAYMENT_TYPES,
} from '../../../../constants/HyperWalletPaymentTypes';
import {
  buildCaptureOrderObject,
  buildCreateOrderObject,
  initPaypalSDK,
} from '../../../../services/PaypalSdkLibraryHW';
import * as constants from '../../../../util/constants';
import generateRequestHeaders from '../../../../util/generateRequestHeaders';
import Alert from '../../../common/Alert';
import { useValidationList } from '../../../common/hooks/useValidationList';
import HyperWalletTransactionReceipt from '../HyperWalletTransactionReceipt';
import TermsAndConditionsHWModal from '../../../common/TermsAndConditionsHWModal';
import hyperWalletPaymentStyles from '../css/HyperwalletPayment.module.css';
import styles from '../css/PaymentSummaryStep.module.css';
import PaymentSummaryStepCreditCardFields from './PaymentSummaryStepCreditCardFields';
import PaymentSummaryStepLetusSplitFields from './PaymentSummaryStepLetusSplitFields';
import PaymentSummaryStepPaypalFields from './PaymentSummaryStepPaypalFields';

const PaymentSummaryStep = (props) => {
  /******* STATE *******/
  /*********************/
  const { validationList, setAlertMessage, clearValidationList, addErrorToValidationList } = useValidationList([]);
  const [reCaptchaId, setReCaptchaId] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [splitTermsAndConditions, setSplitTermsAndConditions] = useState(false);
  const [installments, setInstallments] = useState(null);
  const [termsAndConditionsUrl, setTermsAndConditionsUrl] = useState(null);
  const [sendReceiptByEmail, setSendReceiptByEmail] = useState(true);
  const [sendReceiptByTextMessage, setSendReceiptByTextMessage] = useState(false);
  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/
  const showTermsAndConditionsModal = () => {
    $('#termsAndConditionsHWModal').modal('show');
  };

  /**
   * Calculate the fees and reset captcha anytime payment amount or the payment methods changes
   */
  let captcha;
  useEffect(() => {
    if (props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT && !installments) {
      return;
    }
    calculateFees();
    captcha.reset(); // we reset so that the PaypalContainer can be reloaded with the correct amount
    setReCaptchaId(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.paymentAmount, props.selectedCard]);

  const calculateFees = (installmentsParam = installments) => {
    props.calculateFees(props.paymentAmount, installmentsParam);
  };

  const onReCaptchaChange = (value) => {
    setReCaptchaId(value);
  };

  /*********************/
  /*********************/

  /****** PROCESS PAYMENT LOGIC ******/
  /***********************************/
  /**
   * Initiate Split payment flow
   */
  useEffect(() => {
    if (props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT) {
      const params = {
        amount: Number(props.paymentAmount),
        companyId: props.companyId,
      };
      axios
        .post(`${constants.REACT_APP_HOST_API_URL}/installment_pay_marketplace/initiate`, params)
        .then((response) => {
          setInstallments(response.data?.installments);
          setTermsAndConditionsUrl(response.data?.termsAndConditionsUrl);
          calculateFees(response.data?.installments);
        })
        .catch((error) => {
          props.setShowSpinner(false);
          addErrorToValidationList(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Generate submit payment params for CC payment
   */
  const generateSubmitPaymentParamsCC = () => {
    const selectedCard = props.getCCPaymentMethod(props.selectedCard);

    return {
      paymentMethod: {
        type: selectedCard.type,
        id: selectedCard.id,
        joins: selectedCard.joins,
        createDate: selectedCard.createDate,
        updateDate: selectedCard.updateDate,
        userType: selectedCard.userType || 'TYPE_CUSTOMER',
        userId: selectedCard.userId,
        country: selectedCard.country,
        token: selectedCard.token,
        providerTokens: selectedCard.providerTokens,
        brand: selectedCard.brand,
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        cvv: selectedCard.cvv,
        bin: selectedCard.bin,
        last4: selectedCard.last4,
        expiryMonth: selectedCard.expiryMonth,
        expiryYear: selectedCard.expiryYear,
        nameOnCard: selectedCard.nameOnCard,
        billingAddressId: selectedCard.billingAddressId,
        personal: selectedCard.personal,
        regulated: selectedCard.regulated,
        securityCode: null,
      },
      billingAddress: {
        type: 'TYPE_ADDRESS',
        addressType: 'BILLING',
        suite: selectedCard.suite,
        street1: selectedCard.street,
        street2: selectedCard.street2,
        city: selectedCard.city,
        province: selectedCard.province,
        postalCode: selectedCard.postalCode,
        country: selectedCard.country,
        monthsAtAddress: 0,
      },
    };
  };

  /**
   * Generate submit payment params for Split payment
   */
  const generateSubmitPaymentParamsSplit = () => {
    return {
      paymentMethod: {
        type: 'TYPE_INSTALLMENT_PAY',
      },
      billingAddress: {
        type: 'TYPE_ADDRESS',
      },
    };
  };

  /**
   * Generate submit payment params based on the type of the payment method selected
   */
  const generateSubmitPaymentParams = (userPropertyId, securityToken, installmentPayId) => {
    let paymentMethod = null;
    let billingAddress = null;
    const charges = [
      {
        amount: Number(props.paymentAmount),
        code: props.selectedCharge,
        name: props.selectedCharge,
      },
    ];
    if (props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD) {
      paymentMethod = generateSubmitPaymentParamsCC().paymentMethod;
      billingAddress = generateSubmitPaymentParamsCC().billingAddress;
    } else if (props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT) {
      paymentMethod = generateSubmitPaymentParamsSplit().paymentMethod;
      billingAddress = generateSubmitPaymentParamsSplit().billingAddress;
      charges[0].amount = (Number(props.paymentAmount) / Number(installments)).toFixed(2);
    }
    return {
      tenantEmail: props.tenantEmail,
      managerEmail: props.landlordEmail,
      userPropertyId: userPropertyId,
      companyId: props.companyId,
      paymentMethod: paymentMethod,
      billingAddress: billingAddress,
      sendEmailToManager: props.notifyLandlord,
      acceptedTermsAndConditions: props.agreedToTerms,
      unit: props.tenantUnit,
      securityToken: securityToken,
      installmentPayId: installmentPayId,
      sendReceiptByEmail: sendReceiptByEmail,
      sendReceiptByTextMessage: sendReceiptByTextMessage,
      charges,
    };
  };

  /**
   * Submit the payment for processing
   */
  const submitPayment = (event) => {
    event.preventDefault();
    if (!props.selectedCard) {
      clearValidationList();
      setAlertMessage('Please add a Payment Method!');
      return;
    }
    props.setShowSpinner(true);
    props
      .registerUserAndLandlord(
        props.paymentAmount,
        props.selectedPaymentType,
        reCaptchaId,
        installments,
        splitTermsAndConditions
      )
      .then((response) => {
        const userPropertyId = response.data?.userPropertyId;
        const securityToken = response.data?.securityToken;
        const installmentPayId = response.data?.installmentPayId || null;
        const params = generateSubmitPaymentParams(userPropertyId, securityToken, installmentPayId);
        axios
          .post(`${constants.REACT_APP_HOST_API_URL}/payer_marketplace/process_payment`, params, {
            headers: generateRequestHeaders(),
          })
          .then((response) => {
            props.setShowSpinner(false);
            setPaymentResponse(response.data);
            $('#receiptHWTransactionModal').modal('show');
          })
          .catch((error) => {
            props.setShowSpinner(false);
            addErrorToValidationList(error);
          });
      })
      .catch((error) => {
        props.setShowSpinner(false);
        addErrorToValidationList(error);
      });
  };

  /***********************************/
  /***********************************/

  /****** PAYPAL INITIALIZATION ******/
  /***********************************/

  const initPaypalContainer = () => {
    let paypalUserId = null;
    let recipientId = null;
    let userPropertyId = null;
    let securityToken = null;
    const paypalMerchantAccountId = props?.hwPageDetails?.paypalMerchantAccountId;
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/${paypalMerchantAccountId}/peek`)
      .then((response) => {
        initPaypalSDK(response.data?.currency, process.env.REACT_APP_PAYPAL_CLIENT_ID, setAlertMessage)
          .then(() => {
            let FUNDING_SOURCES = [window.paypal.FUNDING.PAYPAL];
            if (props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER) {
              FUNDING_SOURCES.push(window.paypal.FUNDING.PAYLATER);
            }
            FUNDING_SOURCES.forEach((fundingSource) => {
              const buttons = window.paypal.Buttons({
                style: {
                  layout: 'horizontal',
                  height: 42,
                  tagline: false,
                },
                fundingSource: fundingSource,
                createOrder: () => {
                  return props
                    .registerUserAndLandlord(props.paymentAmount, props.selectedPaymentType, reCaptchaId)
                    .then((registerUserAndLandlordResp) => {
                      userPropertyId = registerUserAndLandlordResp.data?.userPropertyId;
                      securityToken = registerUserAndLandlordResp.data?.securityToken;
                      return fetch(
                        `${constants.REACT_APP_HOST_API_URL}/paypal/create_order_marketplace`,
                        buildCreateOrderObject(
                          props.paymentAmount,
                          props.tenantEmail,
                          props.landlordEmail,
                          props.companyId,
                          userPropertyId,
                          props.tenantUnit,
                          securityToken
                        )
                      )
                        .then((res) => {
                          return res.json();
                        })
                        .then((data) => {
                          paypalUserId = data.userId;
                          recipientId = data.recipientId;
                          return data.paypalOrderId;
                        })
                        .catch((error) => {
                          addErrorToValidationList(error);
                        });
                    })
                    .catch((error) => {
                      addErrorToValidationList(error);
                    });
                },
                onApprove: (data) => {
                  return fetch(
                    `${constants.REACT_APP_HOST_API_URL}/paypal/capture_order_marketplace`,
                    buildCaptureOrderObject(
                      data,
                      paypalUserId,
                      props.paymentAmount,
                      userPropertyId,
                      props.companyId,
                      recipientId,
                      props.tenantUnit,
                      props.notifyLandlord,
                      sendReceiptByEmail,
                      sendReceiptByTextMessage
                    )
                  )
                    .then((res) => {
                      if (!res.ok) {
                        setAlertMessage('Something went wrong:' + JSON.stringify(data));
                      }
                      return res.json();
                    })
                    .then((data) => {
                      setPaymentResponse(data);
                      $('#receiptHWTransactionModal').modal('show');
                    })
                    .catch((error) => {
                      addErrorToValidationList(error);
                    });
                },
              });
              const renderResp = buttons.render('#paypal-buttons-container-hw');
              /**
               * This was the only way available to figure out if paylater for a client is available or not.
               * This may break in the future, if PayPal decided to change something on their end.
               * ! Need to update the error message
               * */
              if (
                String(renderResp?.error)?.includes('paylater is not eligible') &&
                props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER
              ) {
                setAlertMessage(
                  // ! Replace the following message:
                  'It looks like the PayPal paylater is not available at your location! You can go back and select another way to make payment or choose Paypal Checkout.'
                );
              }
            });
          })
          .catch((error) => {
            addErrorToValidationList(error);
          });
      });
  };

  /**
   * Initialize PayPal container if the captcha has been completed and the payment type is PayPal
   */
  useEffect(() => {
    if (reCaptchaId && props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL) {
      initPaypalContainer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reCaptchaId]);

  /***********************************/
  /***********************************/

  return (
    <>
      <HyperWalletTransactionReceipt paymentResponse={paymentResponse} />
      <TermsAndConditionsHWModal termsAndConditionsUrl={termsAndConditionsUrl} />
      <Alert validationList={validationList} />
      <form className='mb-5' onSubmit={submitPayment}>
        <div className='form-group'>
          <h3 className={`${styles.fontRoboto}`}>Payment Summary</h3>

          {props && props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL ? (
            <PaymentSummaryStepPaypalFields
              paymentAmount={props.paymentAmount}
              handleChangePaymentAmount={props.handleChangePaymentAmount}
            />
          ) : (
            ''
          )}
          {props && props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD ? (
            <PaymentSummaryStepCreditCardFields
              cards={props.cards}
              selectedCard={props.selectedCard}
              setSelectedCard={props.setSelectedCard}
              paymentAmount={props.paymentAmount}
              handleChangePaymentAmount={props.handleChangePaymentAmount}
            />
          ) : (
            ''
          )}
          {props && props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT ? (
            <PaymentSummaryStepLetusSplitFields
              cards={props.cards}
              selectedCard={props.selectedCard}
              removeCard={props.removeCard}
              setSelectedCard={props.setSelectedCard}
              paymentAmount={props.paymentAmount}
              installments={installments}
              processingFees={props.processingFees}
              handleChangePaymentAmount={props.handleChangePaymentAmount}
            />
          ) : (
            ''
          )}

          {!Number(props.paymentAmount) && props.selectedPaymentType !== HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT && (
            <div className='alert alert-info' role='alert'>
              Please select Payment Amount greater than zero!
            </div>
          )}

          <table className={`${styles.invoice}`}>
            <tbody>
              <tr>
                <td>
                  <h4 className={`${styles.fontRoboto}`}>Total Payment</h4>
                </td>
              </tr>
              <tr>
                <td>
                  <table className={`${styles.invoiceItems}`} cellPadding='0' cellSpacing='0'>
                    <tbody>
                      <tr>
                        <td>Payment Amount</td>
                        <td className='text-center'>
                          $
                          {props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT
                            ? (Number(props.paymentAmount) / Number(installments)).toFixed(2)
                            : Number(props.paymentAmount).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td>Processing Fee</td>
                        <td className='text-center'>${Number(props.processingFees).toFixed(2)}</td>
                      </tr>
                      <tr className={`${styles.total}`}>
                        <td width='80%'>Total</td>
                        <td className='text-center'>
                          $
                          {props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT
                            ? (
                                Number(props.paymentAmount) / Number(installments) +
                                Number(props.processingFees)
                              ).toFixed(2)
                            : (Number(props.paymentAmount) + Number(props.processingFees)).toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='pb-3'>
          <div className='form-check'>
            <input
              type='checkbox'
              id='sendReceiptByEmailPS'
              name='sendReceiptByEmail'
              checked={sendReceiptByEmail}
              onChange={(event) => {
                setSendReceiptByEmail(event.target.checked);
                captcha.reset(); // we reset so that the PaypalContainer can be reloaded with the correct flag
                setReCaptchaId(null);
              }}
              className='form-check-input'
            />
            <label className='form-check-label' htmlFor='sendReceiptByEmailPS'>
              Send receipt by an email
            </label>
          </div>
          <div className='form-check mt-1'>
            <input
              type='checkbox'
              id='sendReceiptByTextPS'
              name='sendReceiptByTextMessage'
              checked={sendReceiptByTextMessage}
              onChange={(event) => {
                setSendReceiptByTextMessage(event.target.checked);
                captcha.reset(); // we reset so that the PaypalContainer can be reloaded with the correct flag
                setReCaptchaId(null);
              }}
              className='form-check-input'
            />
            <label className='form-check-label' htmlFor='sendReceiptByTextPS'>
              Send receipt by a text message
            </label>
          </div>
        </div>
        <ReCAPTCHA
          sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onReCaptchaChange}
          ref={(el) => {
            captcha = el;
          }}
        />

        {props && props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT && (
          <div className='form-check mt-4'>
            <input
              type='checkbox'
              id='acceptTermsAndConditionsPS'
              name='acceptTermsAndConditions'
              checked={splitTermsAndConditions}
              onChange={(event) => {
                setSplitTermsAndConditions(event.target.checked);
              }}
              className='form-check-input'
            />
            <label className='form-check-label' htmlFor='acceptTermsAndConditionsPS'>
              Confirm you have read SplitIt's&nbsp;
              <button
                type='button'
                onClick={() => showTermsAndConditionsModal()}
                className={`btn btn-link p-0 ${styles.termAndConditionsButton}`}
              >
                Terms and Conditions
              </button>
            </label>
          </div>
        )}

        <div className='row'>
          <div className='col-md-12 col-md-12 mt-4 d-flex justify-content-center'>
            <button
              type='button'
              className={`btn btn-light ${hyperWalletPaymentStyles.stepButtonHW} mr-2`}
              onClick={() => {
                props.clearPaymentStepState();
              }}
            >
              Back
            </button>
            {reCaptchaId &&
              props.selectedPaymentType !== HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL &&
              props.processingFees !== null &&
              props.paymentAmount !== null && (
                <button
                  className={`btn btn-primary float-right ${hyperWalletPaymentStyles.stepButtonHW}`}
                  type='submit'
                  disabled={
                    props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT
                      ? !splitTermsAndConditions
                      : false
                  }
                >
                  Confirm Payment
                </button>
              )}
            {props.selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL &&
              props.processingFees !== null &&
              props.paymentAmount !== null && (
                <div className={reCaptchaId && Number(props.paymentAmount) ? 'mw-50 float-right' : 'd-none'}>
                  <div id='paypal-buttons-container-hw'></div>
                </div>
              )}
          </div>
        </div>
      </form>
    </>
  );
};

export default PaymentSummaryStep;
