import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import * as constants from '../../../util/constants';
import NotFound from '../../NotFound';
import styles from './css/HyperwalletSplashPage.module.css';
import HyperWalletPayeeFaq from './faq/HyperWalletPayeeFaq';
import HyperWalletPayerFaq from './faq/HyperWalletPayerFaq';
import HyperWalletNavigation from './HyperWalletNavigation';
import HyperwalletPayment from './HyperwalletPayment';
import HyperwalletPaymentLanding from './HyperwalletPaymentLanding';
import HyperWalletPayerHowItWorks from './promotional/HyperWalletPayerHowItWorks';
import HyperWalletPayeeHowItWorks from './promotional/HyperWalletPayeeHowItWorks';

const HyperwalletPaymentSplash = (props) => {
  const [showPayRentNow, setShowPayRentNow] = useState(false);
  const [selectedCharge, setSelectedCharge] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const toggleShowPayRentNow = (val) => {
    setShowPayRentNow(val);
  };

  const handleHwPageCountryChange = (event) => {
    setSelectedCountry(event.value);
  };

  return (
    <div className={`${styles.root}`}>
      <div className={`${styles.container}`}>
        <div>
          <HyperWalletNavigation />
        </div>
        <div className={`${styles.bodyHW}`}>
          <Switch>
            <Route
              exact
              path={'/pay/marketplace'}
              render={() =>
                showPayRentNow && selectedCountry ? (
                  <HyperwalletPayment
                    toggleShowPayRentNow={toggleShowPayRentNow}
                    selectedCharge={selectedCharge}
                    selectedCountry={selectedCountry}
                    pageName={
                      selectedCountry === 'CA'
                        ? constants.HW_CA_PAGE_NAME
                        : selectedCountry === 'US'
                        ? constants.HW_US_PAGE_NAME
                        : null
                    }
                    history={props.history}
                    agreedToTerms={agreedToTerms}
                  />
                ) : (
                  <HyperwalletPaymentLanding
                    toggleShowPayRentNow={toggleShowPayRentNow}
                    selectedCharge={selectedCharge}
                    setSelectedCharge={setSelectedCharge}
                    selectedCountry={selectedCountry}
                    handleHwPageCountryChange={handleHwPageCountryChange}
                    agreedToTerms={agreedToTerms}
                    setAgreedToTerms={setAgreedToTerms}
                  />
                )
              }
            />
            <Route exact path={'/pay/marketplace/payer'} component={HyperWalletPayerHowItWorks} />
            <Route exact path={'/pay/marketplace/payee'} component={HyperWalletPayeeHowItWorks} />
            <Route exact path={'/pay/marketplace/payer/faq'} component={HyperWalletPayerFaq} />
            <Route exact path={'/pay/marketplace/payee/faq'} component={HyperWalletPayeeFaq} />
            <Route path='' component={NotFound} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default HyperwalletPaymentSplash;
