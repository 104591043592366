import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";

class System extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The admin system dashboard.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="System" />

                    <div className="card">
                        <div className="card-header">
                            Tools
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/admin/system/administrators" iconType="far" iconName="user-crown" name="Administrators" size="large" description="Manage system administrator accounts and privileges." />
                                <NavListItem path="/admin/system/apis" iconType="far" iconName="code" name="API Accounts" size="large" description="Manage system API accounts and keys." />
                                <NavListItem path="/admin/system/banks" iconType="far" iconName="university" name="Banks" size="large" description="Manage the local database of bank names, institution numbers, and routing numbers." />
                                <NavListItem path="/admin/system/bins" iconType="far" iconName="credit-card-front" name="BIN Mappings" size="large" description="Manage bank identification number (BIN) mappings." />
                                <NavListItem path="/admin/system/broadcasts" iconType="far" iconName="bullhorn" name="Broadcasts" size="large" description="Send highly visible messages to a subset of users on their dashboards." />
                                <NavListItem path="/admin/system/fees" iconType="far" iconName="badge-percent" name="Default Transaction Fees" size="large" description="Manage non-company-specific default transaction fees." />
                                <NavListItem path="/admin/system/services" iconType="far" iconName="tag" name="Default Service Fees" size="large" description="Manage non-company-specific default service fees." />
                                <NavListItem path="/admin/system/efts" iconType="far" iconName="piggy-bank" name="EFT Management" size="large" description="Review and manage electronic funds transfer (EFT) activity." />
                                <NavListItem path="/admin/system/merchantaccounts" iconType="far" iconName="university" name="Merchant Account Management" size="large" description="Review and manage merchant accounts." />
                                <NavListItem path="/admin/system/emails" iconType="far" iconName="inbox-out" name="Emails" size="large" description="Review transactional system emails or resend individual emails." />
                                <NavListItem path="/admin/system/events" iconType="far" iconName="history" name="Event History" size="large" description="View a system-wide history of events." />
                                <NavListItem path="/admin/system/imports" iconType="far" iconName="file-import" name="Imports" size="large" description="Create bulk system-related records by uploading import files." />
                                <NavListItem path="/admin/system/hwuploads" iconType="far" iconName="upload" name="Hyperwallet Uploads" size="large" description="Hyperwallet uploaded payment files." />
                                <NavListItem path="/admin/system/accounts" iconType="far" iconName="user-lock" name="Login Accounts" size="large" description="Manage all system user accounts across the platform." />
                                <NavListItem path="/admin/system/recToggle" iconType="far" iconName="shield-alt" name="ReCaptcha" size="large" description="Enable/disable ReCaptcha for this environment." />
                                <NavListItem path="/admin/system/roles" iconType="far" iconName="user-tag" name="Roles" size="large" description="Manage user roles and their record type access privileges." />
                                <NavListItem path="/admin/system/holidays" iconType="far" iconName="calendar-exclamation" name="Statutory Holidays" size="large" description="Flag upcoming stat holidays for pre-authorized debit (PAD) processing." />
                                <NavListItem path="/admin/system/paypal" iconType="far" iconName="file-export" name="Paypal Reports" size="large" description="Paypal Reports" />
                                <NavListItem path="/admin/system/hppcode" iconType="far" iconName="lock" name="HPP Verification Code" size="large" description="Verification code is used to confirm identity when HPP form is submitted." />
                                <NavListItem path="/admin/system/paymenttransactionaudit" iconType="far" iconName="file-export" name="Payment Transaction Audits" size="large" description="Payment Transaction Audit" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default System;