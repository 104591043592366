import React from "react";
import { injectIntl } from "react-intl";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import CustomCSVReports from "../../common/ReportCustomLibrary";

class CustomReports extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company EFT report component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/reports" parentPage="Reports" childPage="Custom CSV Reports" />

                    <CustomCSVReports 
                        isPmAdmin={this.props.userSession.sessionRole?.roles?.includes('PRIMARY_MANAGER')}
                        companyId={this.props.userSession.sessionRole.companyId} 
                        accessProperties={this.props.userSession.accessProperties}
                        userType={this.props.userSession.sessionRole.type || "TYPE_MANAGER"}
                        managerId={this.props.userSession.sessionRole.id}/>

                    <div className="row">
                        <div className="col text-right">
                            <ButtonBack path="/manager/reports" />
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default injectIntl(CustomReports);