import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../../common/Alert';
import ButtonSave from '../../common/ButtonSave';
import FieldCheckboxFC from '../../common/FieldCheckboxFC';
import FieldFileFC from '../../common/FieldFileFC';
import FieldSelectFC from '../../common/FieldSelectFC';
import FieldTextFC from '../../common/FieldTextFC';
import { useInput } from '../../common/hooks/useInput';
import FieldManagers from "../../common/FieldManagers";

const TenantOnboardingForm = (props) => {
  /****** State ********/
  /*********************/
  const { value: heroHeading, setValue: setHeroHeading, handleChange: handleChangeHeroHeading } = useInput('');
  const { value: heroPoint1, setValue: setHeroPoint1, handleChange: handleChangeHeroPoint1 } = useInput('');
  const { value: heroPoint2, setValue: setHeroPoint2, handleChange: handleChangeHeroPoint2 } = useInput('');
  const { value: heroPoint3, setValue: setHeroPoint3, handleChange: handleChangeHeroPoint3 } = useInput('');
  const { value: heroPoint4, setValue: setHeroPoint4, handleChange: handleChangeHeroPoint4 } = useInput('');
  const { value: subheading, setValue: setSubheading, handleChange: handleChangeSubheading } = useInput('');
  const { value: logo, setValue: setLogo, handleChange: handleChangeLogo } = useInput('');
  const { value: background, setValue: setBackground, handleChange: handleChangeBackground } = useInput('');
  const {
    value: pageName,
    setValue: setPageName,
    handleChange: handleChangePageName,
  } = useInput(`${props?.companyId}`);

  const [document, setDocument] = useState({});
  const [validationList, setValidationList] = useState([]);
  const [validationListModal, setValidationListModal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [selfSignUp, setSelfSignup] = useState(false);
  const [sendEmailToManager, setSendEmailToManager] = useState(false);
  const [emailExclusions, setEmailExclusions] = useState([]);


  /*********************/
  /*********************/

  /****** EFFECTS ******/
  /*********************/
  /**
   * This is not the best way to do an useEffect
   * However, due to a limited time and a massive refactoring of this file it is not possible to fix it at the moment.
   */
  useEffect(() => {
    setValidationList([]);
    setValidationListModal([]);
    const onboardingPageDetails = props.onboardingPageDetails;
    if (onboardingPageDetails) {
      setHeroHeading(onboardingPageDetails?.customFields?.heroHeading);
      setHeroPoint1(onboardingPageDetails?.customFields?.heroPoint1);
      setHeroPoint2(onboardingPageDetails?.customFields?.heroPoint2);
      setHeroPoint3(onboardingPageDetails?.customFields?.heroPoint3);
      setHeroPoint4(onboardingPageDetails?.customFields?.heroPoint4);
      setSubheading(onboardingPageDetails?.customFields?.subheading);
      setLogo(onboardingPageDetails?.customFields?.logo);
      setBackground(onboardingPageDetails?.customFields?.background);
      setSelfSignup(onboardingPageDetails?.autoapproval);
      setSendEmailToManager(onboardingPageDetails?.sendEmailToManager);

      let selectedEmailExclusions = [];

      if(onboardingPageDetails?.emailExclusions && onboardingPageDetails?.emailExclusions.length > 0) {

        let emailExclusions = onboardingPageDetails?.emailExclusions;

        emailExclusions.forEach((emailExclusion) => {

          let allPropertyManagers = props.allPropertyManagers;
          allPropertyManagers.forEach((manager)=>{

            if(manager.id === emailExclusion){
              selectedEmailExclusions.push({
                value:manager.id,
                label:manager.firstName + ' ' + manager.lastName
              });
            }
          })
        });
      }
      setEmailExclusions(selectedEmailExclusions);
      setPageName(onboardingPageDetails?.pageName);
      getDocumentList();
    } else {
      resetFormState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onboardingPageDetails]);

  /****** Actions ******/
  /*********************/
  /**
   * Reset form state
   */
   const resetFormState = () => {
    setHeroHeading(null);
      setHeroPoint1(null);
      setHeroPoint2(null);
      setHeroPoint3(null);
      setHeroPoint4(null);
      setSubheading(null);
      setLogo(null);
      setBackground(null);
      setSelfSignup(false);
      setDocumentList([]);
      setPageName(props?.companyId);
      setSendEmailToManager(false);
      setEmailExclusions([]);
  }

  /**
   * Handle network errors and set alert state
   * @param {*} error 
   */
  const handleError = (error) =>{
    setValidationList([
      {
        fields: {},
        alert: {
          type: 'danger',
          message: error.response?.data?.message,
          code: error.response?.data?.errorCode,
        },
      },
    ]);
  }

  const getDocumentList = () => {
    const payload = {
      orderBy: 'ASC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'recordId',
          operator: 'EQUALS',
          fieldValue: props.onboardingPageDetails?.id,
        },
      ],
    };
    setDocumentList([]);
    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/document/search`, payload, { headers: generateRequestHeaders() })
      .then((res) => {
        if (res.data?.records.length) {
          setDocumentList(res.data.records);
        }
      });
  };

  /**
   * Create a brand new tenant onboarding page
   */
  const createTenantOnboardingPage = () => {
    let body = {
      type: 'TYPE_ON_BOARDING_PAGE',
      companyId: props.companyId,
      pageName: pageName.split(' ').join('_'),
      onBoardingType: 'SELF_SIGN_UP',
      customFields: {
        heroHeading,
        subheading,
        heroPoint1,
        heroPoint2,
        heroPoint3,
        heroPoint4,
        logo,
        background,
      },
      autoapproval: selfSignUp,
      sendEmailToManager: sendEmailToManager,
    };

    let selectedEmailExclusions = [];

    if(emailExclusions.length > 0) {
      emailExclusions.forEach((emailExcluesion) => {

        selectedEmailExclusions.push(emailExcluesion.value);
      });
    }

    body.emailExclusions = selectedEmailExclusions;

    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/create`, body, {
        headers: generateRequestHeaders(),
      })
      .then(() => {
        resetFormState();
        props.setParentAlertMessage('A new tenant onboarding page has been created.', 'primary');
        props.closeTenantOnboardingForm();
      })
      .catch((error) => {
        props.addErrorToParentValidationList(error);
      });
  };

  /**
   * updates an existing tenant onboarding page
   */
  const updateTenantOnboardingPage = () => {
    let body = props.onboardingPageDetails;
    body.pageName = pageName.split(' ').join('_');
    body.customFields = {
      heroHeading,
      subheading,
      heroPoint1,
      heroPoint2,
      heroPoint3,
      heroPoint4,
      logo,
      background,
    };
    body.autoapproval = selfSignUp;
    body.sendEmailToManager = sendEmailToManager;

    let selectedEmailExclusions = [];

    if(emailExclusions && emailExclusions.length > 0) {
      emailExclusions.forEach((emailExcluesion) => {

        selectedEmailExclusions.push(emailExcluesion.value);
      });
    }
    body.emailExclusions = selectedEmailExclusions;

    axios
      .put(`${constants.REACT_APP_HOST_API_URL}/update`, body, {
        headers: generateRequestHeaders(),
      })
      .then(() => {
        props.setParentAlertMessage('Changes have been saved.', 'primary');
        props.closeTenantOnboardingForm();
      })
      .catch((error) => {
        props.addErrorToParentValidationList(error);
      });
  };

  /**
   * Submit tenant onboarding form
   * @param {*} event
   */
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!props.onboardingPageDetails?.id) {
      createTenantOnboardingPage();
    } else {
      updateTenantOnboardingPage();
    }
  };

  const handleChangeDocument = (document) => {
    setDocument(document);
  };

  /**
   * Upload a document to the list of documents.
   *
   * @param event - The event container.
   */
  const uploadDocument = (event) => {
    event.preventDefault();

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/ON_BOARDING_PAGE/${props.onboardingPageDetails?.id}/upload`,
        document,
        {
          headers: generateRequestHeaders(),
        }
      )
      .then((response) => {
        getDocumentList();
        setValidationList([
          {
            fields: {},
            alert: {
              type: 'primary',
              code: 'common.documents.created',
              message: 'Upload successful',
            },
          },
        ]);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  /**
   * Select a document and bring up the delete document confirmation modal.
   *
   * @param document - The selected document.
   */
  const showDeleteDocumentModal = (document) => {
    setDocument(document);
    setShowModal(false);
    setShowDeleteModal(true);
  };

  /**
   * Delete selected document
   */
  const handleDeleteDocument = (event) => {
    event.preventDefault();

    axios
      .delete(`${constants.REACT_APP_HOST_API_URL}/document/${document.id}/delete`, {
        headers: generateRequestHeaders(),
      })
      .then((response) => {
        getDocumentList();
        setShowModal(true);
        setShowDeleteModal(false);
        setValidationList([
          {
            fields: {},
            alert: {
              type: 'primary',
              code: 'saved',
              message: 'Changes have been saved',
            },
          },
        ]);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  /**
   * Delete the selected tenant onboarding page.
   */
  const deleteOnboardingPage = () => {
    axios
      .delete(`${constants.REACT_APP_HOST_API_URL}/on_boarding_page/${props.onboardingPageDetails?.id}/delete`, {
        headers: generateRequestHeaders(),
      })
      .then(() => {
        props.setParentAlertMessage('Tenant onboarding page has been deleted.', 'primary');
        props.closeTenantOnboardingForm();
      })
      .catch((error) => {
        props.addErrorToParentValidationList(error);
      });
  };

  /*********************/
  /*********************/

  return (
    <React.StrictMode>
      <Alert validationList={validationList} customClasses='mb-0' />
      <form onSubmit={handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Tenant Onboarding</div>
              <button
                  type="button"
                  className="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    icon={["fas", "times"]}
                    className="fa-fw va-b mr-2"
                  />
                </button>
            </div>
          </div>
          <div className='card-body'>
            <FieldTextFC
              id='pageName'
              required={true}
              label='Page Name'
              value={pageName}
              handleChange={handleChangePageName}
              helpLink={`selfsignup/${pageName}`}
              pattern='[a-zA-Z0-9-_\s]+'
              onInvalidMessage='Only alphabets and numbers allowed'
            />
            <FieldCheckboxFC
              id='selfSignUp'
              label='Auto Approval'
              fieldLabel='Auto Approve Tenants'
              value={selfSignUp}
              help={
                'When selected, tenants will be approved and a lease will be created upon the self signup completion'
              }
              handleChange={setSelfSignup}
            />
            <FieldCheckboxFC
                id='sendEmailToManager'
                label='Self-Signup Email'
                fieldLabel='Send Email to Property Managers'
                value={sendEmailToManager}
                help={
                  'When selected, All Property Managers who oversee the property will receive the email.'
                }
                handleChange={setSendEmailToManager}
            />
            <FieldManagers id="emailExclusions" label="Email Exclusions" labelClass="col-form-label-sm align-self-center"
                            value={emailExclusions} parent={this} options={props.allPropertyManagers}
                            handleChange={setEmailExclusions} disabled={!sendEmailToManager}/>
            <FieldTextFC id='heroHeading' label='Heading' value={heroHeading} handleChange={handleChangeHeroHeading} />
            <FieldTextFC id='heroPoint1' label='Point 1' value={heroPoint1} handleChange={handleChangeHeroPoint1} />
            <FieldTextFC id='heroPoint2' label='Point 2' value={heroPoint2} handleChange={handleChangeHeroPoint2} />
            <FieldTextFC id='heroPoint3' label='Point 3' value={heroPoint3} handleChange={handleChangeHeroPoint3} />
            <FieldTextFC id='heroPoint4' label='Point 4' value={heroPoint4} handleChange={handleChangeHeroPoint4} />
            <FieldTextFC
              id='subheading'
              label='Form Subheading'
              value={subheading}
              handleChange={handleChangeSubheading}
            />
            <FieldSelectFC
              id='logo'
              label='Logo'
              value={logo}
              handleChange={handleChangeLogo}
              help={props.onboardingPageDetails?.id ? null : 'Available after saving the form'}
            >
              <option value=''>Select a file...</option>
              {documentList.map((document, key) => {
                return (
                  <option key={key} value={document.url}>
                    {document.name}
                  </option>
                );
              })}
            </FieldSelectFC>
            <FieldSelectFC
              id='background'
              label='Background'
              value={background}
              handleChange={handleChangeBackground}
              help={props.onboardingPageDetails?.id ? null : 'Available after saving the form'}
            >
              <option value=''>Select a file...</option>
              {documentList.map((document, key) => {
                return (
                  <option key={key} value={document.url}>
                    {document.name}
                  </option>
                );
              })}
            </FieldSelectFC>
          </div>
        </div>
        <div className='row'>
          <div className='col-2'>
            <button
              type='button'
              className='btn btn-outline-primary btn-lg mx-2'
              data-dismiss='modal'
              onClick={props.closeTenantOnboardingForm}
            >
              Close
            </button>
          </div>
          <div className='col-10 text-right'>
            {props.onboardingPageDetails?.id && (
              <>
                <button type='button' className='btn btn-danger btn-lg mx-2' onClick={deleteOnboardingPage}>
                  Delete
                </button>
                <div className='btn btn-lg btn-primary mx-2' onClick={() => setShowModal(true)}>
                  Upload Files...
                </div>
              </>
            )}
            <ButtonSave customClasses='mx-2' />
          </div>
        </div>
      </form>
      {showModal && (
        <>
          <div className='modal-backdrop fade show'></div>
          <div className='modal fade show' id='page-documents' tabIndex='-1' role='dialog' style={{ display: 'block' }}>
            <div className='modal-dialog modal-dialog-centered modal-md' role='document'>
              <div className='modal-content shadow'>
                <form onSubmit={uploadDocument}>
                  <div className='modal-header bg-dark text-white'>
                    <h5 className='modal-title' id='page-documents-label'>
                      Upload Files
                    </h5>
                    <button
                      type='button'
                      className='close text-white'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={() => setShowModal(false)}
                    >
                      <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                    </button>
                  </div>
                  <div className='modal-body bg-secondary'>
                    <p className='mb-0'>
                      Files uploaded below will be made available when editing image-related fields for this sign up
                      page.
                    </p>
                  </div>

                  <div className='modal-body'>
                    <Alert validationList={validationListModal} />

                    {documentList.map((document, key) => {
                      return (
                        <div key={key} className='list-group mb-2'>
                          <div
                            className='list-group-item list-group-item-action c-pointer'
                            onClick={() => showDeleteDocumentModal(document)}
                          >
                            <div className='row align-items-center'>
                              <div className='col-2 text-center'>
                                <img src={document.url} alt={document.name} className='img-fluid' />
                              </div>
                              <div className='col-8'>
                                <div className=''>{document.name}</div>
                                <small className='mb-0 ml-md-0 small text-muted'>
                                  Uploaded on <Moment format='MMM DD, YYYY HH:mm'>{document.createDate}</Moment>
                                </small>
                              </div>
                              <div className='col-2 text-right'>
                                <div className='float-right'>
                                  <FontAwesomeIcon icon={['far', 'trash-alt']} className='fa-fw' size='1x' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <FieldFileFC
                      id='file'
                      label='File'
                      labelClass='d-none mb-0'
                      fieldClass='form-control-lg mb-0'
                      fieldColumns='12'
                      labelColumns='0'
                      value={document}
                      handleChange={handleChangeDocument}
                    />
                  </div>
                  <div className='modal-footer bg-secondary rounded-bottom d-block'>
                    <div className='row'>
                      <div className='col-4'>
                        <button
                          type='button'
                          className='btn btn-outline-primary btn-lg'
                          onClick={() => setShowModal(false)}
                        >
                          Back
                        </button>
                      </div>
                      <div className='col-8 text-right'>
                        <button type='submit' className='btn btn-primary btn-lg ml-2'>
                          Upload File
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      {showDeleteModal && (
        <>
          <div className='modal-backdrop fade show'></div>
          <div
            className='modal fade show'
            id='delete-document'
            tabIndex='-1'
            role='dialog'
            style={{ display: 'block' }}
          >
            <div className='modal-dialog modal-dialog-centered modal-md' role='document'>
              <div className='modal-content shadow'>
                <form onSubmit={handleDeleteDocument}>
                  <div className='modal-header bg-dark text-white'>
                    <h5 className='modal-title' id='delete-document-label'>
                      Delete File
                    </h5>
                    <button
                      type='button'
                      className='close text-white'
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={() => {
                        setShowModal(true);
                        setShowDeleteModal(false);
                      }}
                    >
                      <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                    </button>
                  </div>
                  <div className='modal-body bg-secondary'>
                    <p className='mb-0'>
                      {`Are you sure you would like to delete ${document.name}? Tenant Onboarding page elements currently utilizing this file may appear broken as a result, and will require manual review.`}
                    </p>
                  </div>

                  <div className='modal-footer bg-secondary rounded-bottom d-block'>
                    <div className='row'>
                      <div className='col-4'>
                        <button
                          type='button'
                          className='btn btn-outline-primary btn-lg'
                          onClick={() => {
                            setShowModal(true);
                            setShowDeleteModal(false);
                          }}
                        >
                          Back
                        </button>
                      </div>
                      <div className='col-8 text-right'>
                        <button type='submit' className='btn btn-danger btn-lg ml-2'>
                          Delete File
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </React.StrictMode>
  );
};

export default TenantOnboardingForm;
