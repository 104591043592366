import React from "react";
import Propertii from "../../common/Propertii";
import ServiceLevelConfigs from "../services/ServiceLevelConfigs";
class ServiceLevelConfig extends Propertii {
  /**
   * Render the component.
   *
   * @returns {*} - The admin ServiceLevelConfig component for a company and subcompany.
   */
  render() {
    return (
      <ServiceLevelConfigs
        companyId={this.props.company?.id}
        parentCompanyId={this.props.company?.parentId}
        monevoIntegration={this.props.company?.monevoIntegration}
        paypalCreditIntegration={this.props.company?.paypalCreditIntegration}
        manualInsurance={this.props.company?.insurance_manual}
        nueraInsurance={this.props.company?.insurance_nuera}
        apolloInsurance={this.props.company?.insurance_apollo}
        melloInsurance={this.props.company?.insurance_mello}
        neoBanner={this.props.company?.service_neofinancial}
        urcsiConcierge={this.props.company?.service_urcsi}
        customCard={this.props.company?.customCard}
        handleRefresh={(companyId) => this.props.handleRefresh(companyId)}
        company={this.props.company}
      />
    );
  }
}

export default ServiceLevelConfig;
