import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import ManagerList from "../../common/Managers";
import Propertii from "../../common/Propertii";

class Managers extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager managers component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Managers" />

                    <ManagerList userType={this.props.userSession.sessionRole.type}
                                 companyId={this.props.userSession.sessionRole.companyId}
                                 location={this.props.location}
                                 editable={this.props.userSession.sessionRole?.roles?.includes('PRIMARY_MANAGER')}
                                 history={this.props.history}
                                 roles={this.props.userSession.sessionRole?.roles}
                                 createMessage="manager.managers.created" />

                </div>

            </div>
        )
    };
}

export default Managers;