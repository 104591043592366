import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldCompanyType extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for company types.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value}>
                <option value="" disabled>Select a company type...</option>
                <option value="Corporation">Corporation</option>
                <option value="Limited">Limited (LTD)</option>
                <option value="Limited Liability Company">Limited Liability (LLC)</option>
                <option value="Limited Liability Partnership">Limited Liability Partnership (LLP)</option>
                <option value="Public Limited Company">Public Limited Company (PLC)</option>
                <option value="Partnership">Partnership</option>
                <option value="General Partnership">General Partnership</option>
                <option value="Sole Proprietorship/Sole Trader">Sole Proprietorship/Sole Trader</option>
                <option value="Trust/Associate Estate">Trust</option>
                <option value="Public Sector/Governmental Agency">Public Sector/Governmental</option>
                <option value="Community Interest/International Organization">Community Interest Company (CIC)</option>
                <option value="Non-Profit/Tax Exempt Organization">Non-Profit</option>
                <option value="Charity">Charity</option>
            </FieldSelect>
        )
    };
}

export default FieldCompanyType;
