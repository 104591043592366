import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <TenantList userType={this.props.userSession.sessionRole.type}
                            userId={this.props.userSession.sessionRole.id}
                            propertyId={this.props.match.params.propertyId}
                            context="property"
                            createTenant={false}
                            inviteTenant={true}
                            textSearchPlaceholderOverrideTenants = "Search Property Tenants"
                            textSearchPlaceholderOverrideInvitations = "Search Property Invitations"
                            inviteTenantDisabled={localStorage.getItem('status') === 'PAID'}
                            inviteTenantTooltip="Before being able to invite tenants, you must first complete your Letus account setup"
                            history={this.props.history}
                            parent={this} />

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);