import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import {FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldCheckbox from "../common/FieldCheckbox";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Table from "../common/Table";
import FieldPhone from "./FieldPhone";
import FieldProperties from "./FieldProperties";
import SelectManagerRoleComponent from "./SelectManagerRoleComponent";

class Managers extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            managerSearchFilter: '',

            manager: {},

            managerFirstName: '',
            managerLastName: '',

            localSystemAccount: {},

            managerList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            managedPropertyList: [],

            managerQuery: {
                orderBy: 'ASC',
                orderByFields: ['firstName'],
                conditionList: [],
                joins: {
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'c',
                        returnFields: ['name', 'legalName']
                    }
                }
            },

            validationList: [],

            roles: {
                PRIMARY_MANAGER: false,
                SUPPORT_MANAGER: false,
                PROPERTY_MANAGER: false,
                FINANCE_MANAGER: false,
                LEASING_MANAGER: false,
            },

        };

        this.searchManagers = this.searchManagers.bind(this);
        this.filterManagers = this.filterManagers.bind(this);

        this.viewManager = this.viewManager.bind(this);
        this.editManager = this.editManager.bind(this);
        this.initManager = this.initManager.bind(this);

        this.initManagerProperty = this.initManagerProperty.bind(this);

        this.saveManager = this.saveManager.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.handleChangeManagedProperties = this.handleChangeManagedProperties.bind(this);
        this.handleChangeMakeTenantPayments = this.handleChangeMakeTenantPayments.bind(this);
        this.handleChangeFormattedPropertyLabel = this.handleChangeFormattedPropertyLabel.bind(this);
    }

    /**
     * Populate the list of managers on mounting of the component. If a user has been redirected to this page with a
     * manager object loaded in the location state, immediately proceed to load the manager record for editing.
     */
    componentDidMount() {

        this.searchManagers(1, 25, this.state.managerQuery);

        if(this.props.history.location.state && this.props.history.location.state.manager) {
            this.editManager(this.props.history.location.state.manager.id);
        }
    }

    /**
     * View a manager by redirecting the user to a separate manager component.
     *
     * @param managerId - The ID of the manager selected.
     */
    viewManager(event, managerId) {
        $('#manager').modal('hide');

        if (event.metaKey || event.ctrlKey){
            window.open(`/${this.props.userType.substring(5).toLowerCase()}/managers/${managerId}/edit`, "_blank");
        } else {
            this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/managers/${managerId}/edit`);
        }
    }

    /**
     * Handle selecting a manager record by fetching the manager record and the system account record.
     *
     * @param managerId - The ID of the manager selected.
     */
    editManager(managerId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${managerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                manager: response.data,
                managerFirstName: response.data.firstName,
                managerLastName: response.data.lastName
            }));

            this.initManagerProperty();

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/local_system_account/search?recordsPerPage=1&page=1`, {
            conditionList: [
                {
                    operator: "EQUALS",
                    fieldName: "userId",
                    fieldValue: managerId,
                    type: "STRING"
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                localSystemAccount: response.data.records[0]
            }));

            $('#manager').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a manager object when the user clicks the 'create manager' button.
     */
    initManager() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                manager: {
                    ...response.data,
                    companyId: this.props.companyId,
                    makeTenantPayments: true
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        this.initManagerProperty();
    }

    /**
     * Fetch a list of all properties below the company, as well as a list of all properties managed specifically by the
     * manager.
     */
    initManagerProperty() {

        let endpointUrl;

        if(this.props.userType === 'TYPE_ADMIN') {
            endpointUrl = `${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId || this.state.manager.companyId}/properties`;
        }

        if(this.props.userType === 'TYPE_MANAGER') {
            endpointUrl = `${constants.REACT_APP_HOST_API_URL}/property/search`;
        }

        axios.post(endpointUrl, {
            orderBy: 'ASC',
            orderByFields: ['street1'],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let managedPropertyList = [];

                response.data.records.forEach(function(entry) {
                    managedPropertyList.push(entry.id);
                });

                this.setState(prevState => ({
                    ...prevState,
                    managedPropertyList: managedPropertyList
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form. If creating a new manager, call the /setupuser endpoint, which sends an
     * onboarding invitation to the manager to complete their account. Regardless of whether the manager is being
     * created or updated, save the manager-property mappings afterward in a second operation.
     *
     * @param event - The event container.
     */
    saveManager(event) {

        event.preventDefault();

        let managedPropertyList = [];

        this.state.managedPropertyList.forEach((managedProperty, key) => {
            managedPropertyList.push(managedProperty.value);
        });

        let rolesArr = [];
        if(this.state.roles["PRIMARY_MANAGER"]) {
        rolesArr.push("PRIMARY_MANAGER")
        } else {
        Object.keys(this.state.roles).forEach((role) => this.state.roles[role] && rolesArr.push(role))
        }

        if(!rolesArr[0]) {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'missing fields',
                        message: 'Please select a role for this manager before proceeding to the next step.'
                    }
                }],
            });
            return
        }

        if(this.state.manager.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/setupuser`, {
                user: {...this.state.manager, roles: rolesArr},
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`, managedPropertyList, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.searchManagers(this.state.managerList.page, this.state.managerList.recordsPerPage, this.state.managerQuery);
                    
                    this.setState({
                        validationList: [{
                            fields: {},
                            alert: {
                                type: 'primary',
                                code: this.props.createMessage
                            },
                            values: {
                                email: this.state.manager.email,
                            }
                        }],
                    });

                    $('#manager').modal('hide');

                }).catch(error => {
                    if(error?.response?.data?.message?.includes('Record Type: TYPE_MANAGER id:') ) {
                        error.response.data.message = `Manager could not be created. Please make sure that a manager with this email doesn't already exist.`;
                    }
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {

            let saveListData = [];

            saveListData.push(this.state.manager);

            if(this.props.userType === 'TYPE_ADMIN' && this.state.localSystemAccount) {
                saveListData.push(this.state.localSystemAccount);
            }

            axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/${this.state.manager.id}/properties`, managedPropertyList, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.searchManagers(this.state.managerList.page, this.state.managerList.recordsPerPage, this.state.managerQuery);

                    $('#manager').modal('hide');

                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of managers.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchManagers(page, recordsPerPage, query) {

        if(this.props.companyId != null) {
            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            );
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                managerList: response.data,
                managerQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the email search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterManagers(event) {

        if (event != null) {
            event.preventDefault();
        }

        let managerQuery = this.state.managerQuery;

        managerQuery.conditionList = [];

        if(this.state.managerSearchFilter !== '') {

            managerQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.managerSearchFilter
                }
            );

            if(!this.props.companyId) {
                managerQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'c.name',
                        operator: 'MATCH',
                        fieldValue: this.state.managerSearchFilter
                    }
                );
            }

            managerQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.managerSearchFilter
                }
            );
        }

        this.setState({
            managerQuery: managerQuery
        });

        this.searchManagers(1, 25, managerQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            managerSearchFilter: '',
        }, () => {
            this.filterManagers();
        });
    }

    /**
     * Handle changes to the managed properties field.
     *
     * @param selectedOptions - The list of selected managed properties.
     */
    handleChangeManagedProperties(selectedOptions) {

        const { formatMessage } = this.props.intl;
        let managedPropertyList = this.state.managedPropertyList;

        if(selectedOptions == null) {
            selectedOptions = [];
        }

        if(selectedOptions.length === 0) {
            managedPropertyList =  [];
        }

        if(selectedOptions.length > 0) {

            managedPropertyList = [];
            let propertyList = this.state.propertyList;

            selectedOptions.forEach(function (managedProperty) {
                propertyList.forEach((property, key) => {
                    if (property.id === managedProperty.value) {
                        managedPropertyList.push({
                            value: property.id,
                            label:
                                property.street1 +
                                (property.street2 ? ", " + property.street2 : "") +
                                ", " +
                                property.city +
                                ", " +
                                (property.country === "CA"
                                    ? formatMessage({
                                        id: "province." + property.province,
                                    })
                                    : formatMessage({
                                        id: "state." + property.province,
                                    })) +
                                ", " +
                                formatMessage({ id: "country." + property.country }) +
                                " " +
                                property.postalCode,
                            propertyName: property.propertyName
                        });
                    }
                });
            });
        }

        this.setState(prevState => ({
            ...prevState,
            managedPropertyList: managedPropertyList,
        }));
    }

    /**
     * Handle changes to the block from making payments on behalf of the tenant
     *
     * @param event - The event container.
     *      
    **/
     handleChangeMakeTenantPayments(event) {
        event.persist();
        
        let field = this.state.manager;
        field[event.target.name] = !event.target.checked;
    
        this.setState(prevState => ({
            ...prevState,
            manager: field,
        }));
    }

    /**
     * Handle changes to the Formatted Property Label
     *
     * @param event - The event container.
     *
     **/
    handleChangeFormattedPropertyLabel(event) {
        event.persist();

        let field = this.state.manager;

        if(event.target.checked) {

            field[event.target.name] = 'NAME';

        } else {

            field[event.target.name] = 'ADDRESS';

        }

        this.setState(prevState => ({
            ...prevState,
            manager: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The company fee profiles component.
     */
    render() {

        let columnLabels = {};
        let columnWidths = [];

        if(this.props.userType === 'TYPE_ADMIN') {

            if(this.props.companyId) {
                columnLabels = {firstName: 'Manager', email: 'Email', admin: 'Type', accessAllProperties: 'Oversight'};
                columnWidths = ['25%', '27.5%', '27.5%', '20%'];
            }

            if(!this.props.companyId) {
                columnLabels = {firstName: 'Manager', company: 'Company', email: 'Email', admin: 'Type', accessAllProperties: 'Oversight'};
                columnWidths = ['20%', '20%', '20%', '20%', '20%'];
            }

        }

        if(this.props.userType === 'TYPE_MANAGER') {
            columnLabels = {firstName: 'Manager', email: 'Email', phone: 'Phone', admin: 'Type', accessAllProperties: 'Oversight'};
            columnWidths = ['20%', '20%', '20%', '20%', '20%'];
        }

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} validationType="primary"/>
                }

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Managers
                            </div>
                            {((this.props.editable || this.props.roles?.includes('PRIMARY_MANAGER')) && this.props.companyId) &&
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#manager" className="btn btn-primary btn-sm" onClick={() => this.initManager()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Manager
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterManagers}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="managerSearchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                               labelColums="0" placeholder="Filter by first name, last name, email, or phone..."
                                               parent={this} value={this.state.managerSearchFilter} />
                                </div>
                                <div className="align-self-center text-right">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                        <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                            <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table columns={columnLabels}
                           columnWidths={columnWidths}
                           headerClass="c-pointer"
                           data={this.state.managerList}
                           query={this.state.managerQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchManagers}
                           tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                           >
                        <tbody>
                        {this.state.managerList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={(event) => this.viewManager(event, data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.firstName} {data.lastName}
                                        </div>
                                    </td>
                                    {!this.props.companyId &&
                                    <td>
                                        {(data.joins && data.joins.c) &&
                                        <div className="">
                                            {data.joins.c.name}
                                        </div>
                                        }
                                    </td>
                                    }
                                    <td>
                                        <div className="">
                                            {data.email}
                                        </div>
                                    </td>
                                    {this.props.userType === 'TYPE_MANAGER' &&
                                    <td>
                                        <div className="">
                                            {data.phone}
                                        </div>
                                    </td>
                                    }
                                    <td>
                                        <div className="text-wrap">
                                            <span className="ml-1">
                                                {data?.roles?.map(role => (
                                                    <div key={role}>
                                                        <FormattedMessage id={`static.manager.${role}`} />
                                                    </div>
                                                ))}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-wrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${data.accessAllProperties ? 'text-success' : 'text-secondary'}`} />
                                            <span className="ml-1">{data.accessAllProperties ? 'All Properties' : 'Select Properties'}</span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="manager" tabIndex="-1" role="dialog" aria-labelledby="manager-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveManager} autoComplete="off">
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="manager-label">
                                        {this.state.manager['createDate'] == null &&
                                        <span className="">
                                            Create Manager
                                        </span>
                                        }
                                        {this.state.manager['createDate'] != null &&
                                        <span className="">
                                            {this.state.managerFirstName} {this.state.managerLastName}
                                        </span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    {!this.props.editable &&
                                    <div className="card card-primary border-primary mb-4">
                                        <div className="card-body">
                                            <p className="card-text">
                                                You do not have permission to make edits to other managers.
                                            </p>
                                        </div>
                                    </div>
                                    }

                                    {(this.state.manager.createDate != null && !this.state.localSystemAccount && this.props.userType === 'TYPE_ADMIN') &&
                                    <div className="card card-primary border-primary mb-4">
                                        <div className="card-body">
                                            <p className="card-text">
                                                This manager has not yet completed their account by clicking the link in the email that was sent to them.
                                            </p>
                                        </div>
                                    </div>
                                    }

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="firstName" label="First Name*" model="manager" disabled={!this.props.editable} parent={this} value={this.state.manager['firstName']} required/>

                                    <FieldText id="lastName" label="Last Name*" model="manager" disabled={!this.props.editable} parent={this} value={this.state.manager['lastName']} required/>

                                    <FieldText id="email" label="Email*" model="manager" type="email" disabled={(this.props.userType === 'TYPE_MANAGER' && this.state.manager.createDate != null) || !this.props.editable} parent={this} value={this.state.manager['email']} required/>

                                    <FieldPhone id="phone" label="Phone" model="manager" disabled={!this.props.editable} parent={this} value={this.state.manager['phone']} optional={true} />

                                    {this.props.editable &&
                                    <SelectManagerRoleComponent
                                        roles={this.state.roles}
                                        parent={this}
                                    />
                                    }
                                    {(this.props.editable && !this.state.roles.PRIMARY_MANAGER) &&
                                    <FieldCheckbox id="accessAllProperties" label="Oversight" disabled={!this.props.editable} fieldLabel="This manager has access to all properties by default" help="If unchecked, you must select which properties this manager has access to." model="manager" parent={this} value={this.state.manager['accessAllProperties']}/>
                                    }

                                    {((this.state.propertyList.length > 0) && (!this.state.roles.PRIMARY_MANAGER && !this.state.manager.accessAllProperties)) &&
                                    <FieldProperties id="managedPropertyList" label="Managed Properties" labelClass="col-form-label-sm align-self-center" value={this.state.managedPropertyList} parent={this} options={this.state.propertyList} handleChange={this.handleChangeManagedProperties} formattedLabel={this.state.manager?.formattedPropertyLabel === 'NAME'}/>
                                    }

                                    <FieldCheckbox
                                        id="formattedPropertyLabel"
                                        fieldLabel="Show Property Names"
                                        model="manager"
                                        parent={this}
                                        value={this.state.manager?.formattedPropertyLabel === 'NAME'}
                                        handleChange={this.handleChangeFormattedPropertyLabel}
                                    />

                                    <FieldCheckbox
                                        id="makeTenantPayments"
                                        fieldLabel="Block from making payments on behalf of the tenant"
                                        model="manager"
                                        parent={this}
                                        value={!this.state.manager["makeTenantPayments"]}
                                        handleChange={this.handleChangeMakeTenantPayments}
                                    />

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#manager").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">

                                                {this.state.manager.createDate != null &&
                                                <div onClick={() => this.viewManager(this.state.manager.id)} className="btn btn-primary btn-lg">
                                                    View Manager
                                                </div>
                                                }

                                                {this.props.editable &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">
                                                    {this.state.manager.createDate == null &&
                                                    <span className="">Create Manager</span>
                                                    }
                                                    {this.state.manager.createDate != null &&
                                                    <span className="">Save</span>
                                                    }
                                                </button>
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Managers.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Managers);