import React from 'react';
import styles from './css/HyperwalletPayment.module.css';
import HyperWalletAddressFields from './HyperWalletAddressFields';
import $ from "jquery";

const HyperWalletPropertyModal = (props) => {

  const saveProperty = (props) => {
    closeModal();
  }

  const closeModal = () => {
    $('#hyperWalletPropertyModal').modal('hide');
  }

  return (
    <div
      className='modal fade'
      id='hyperWalletPropertyModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='hyperWalletPropertyModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
        <div className='modal-content p-3'>
          <div className='modal-header border-0 justify-content-center'>
            <h5 className='modal-title'>Property Information</h5>
          </div>
          <div className='modal-body'>
            <HyperWalletAddressFields
              fieldIdSuffix={'PD'}
              street1={props.street1}
              street2={props.street2}
              city={props.city}
              postalCode={props.postalCode}
              country={props.country}
              province={props.province}
              handleChangeStreet1={props.handleChangeStreet1}
              handleChangeStreet2={props.handleChangeStreet2}
              handleChangeCity={props.handleChangeCity}
              handleChangePostalCode={props.handleChangePostalCode}
              handleChangeCountry={props.handleChangeCountry}
              handleChangeProvince={props.handleChangeProvince}
            />

            <div className='row'>
              <div className='col-md-12 mt-2'>
                <button
                  type='button'
                  className={`btn btn-light`}
                  onClick={() => props.copyFromTenant()}
                >
                  Copy from Tenant
                </button>
              </div>
            </div>
            
            <div className='row'>
              <div className='col-md-12 mt-2'>
                <button
                  type='button'
                  className={`btn btn-light ${styles.stepButtonHW}`}
                  onClick={() => closeModal()}
                >
                  Back
                </button>
                <button
                  className={`btn btn-primary float-right ${styles.stepButtonHW}`}
                  onClick={() => saveProperty(props)}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperWalletPropertyModal;
