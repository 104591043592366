import React from 'react';
import PropTypes from 'prop-types';

const SplititPaymentScheduleTable = ({ numberOfInstallments, totalPaymentAmount }) => {
  const paymentScheduleTableBody = () => {
    const paymentScheduleTableBodyArr = [];
    const paymentAmountPerInstallment = (totalPaymentAmount / numberOfInstallments).toFixed(2);
    for (let i = 1; i <= numberOfInstallments; i++) {
      const amountHeldOnCard = (totalPaymentAmount - paymentAmountPerInstallment * i).toFixed(2);
      paymentScheduleTableBodyArr.push(
        <tr key={i}>
          <th scope='row'>Payment no. {i}</th>
          <td>{paymentAmountPerInstallment}</td>
          <td>{amountHeldOnCard}</td>
        </tr>
      );
    }
    return paymentScheduleTableBodyArr;
  };

  return (
    <>
      <section className='border p-2'>
        <p className='m-0'>
          <b>Purchase Price - {totalPaymentAmount}</b>
        </p>
        <p className='m-0'>
          <b>Available Credit required - {totalPaymentAmount}</b>
        </p>
      </section>
      <table className='table table-striped table-bordered'>
        <thead>
          <tr>
            <th scope='col'>Monthly Payments</th>
            <th scope='col'>Amount Paid</th>
            <th scope='col'>Amount held on card</th>
          </tr>
        </thead>
        <tbody>{paymentScheduleTableBody()}</tbody>
      </table>
    </>
  );
};

SplititPaymentScheduleTable.propTypes = {
  numberOfInstallments: PropTypes.number,
  totalPaymentAmount: PropTypes.number,
};

export default SplititPaymentScheduleTable;
