import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldAddress from "../../common/FieldAddress";
import FieldBirthDate from "../../common/FieldBirthDate";
import FieldCountry from "../../common/FieldCountry";
import FieldPhone from "../../common/FieldPhone";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import FieldCheckbox from "../../common/FieldCheckbox";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";

class CompanyOwners extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            companyOwner: {},
            address: {},
            addressList: [],

            companyOwnerFirstName: '',
            companyOwnerLastName: '',
            addressStreet: '',

            newAddress: false,
            editAddress: false,

            companyOwnerList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            companyOwnerQuery: {
                orderBy: 'ASC',
                orderByFields: ['firstName'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },

            postionTextField: false,

            signingOfficerDefaultPositions:[ "CEO", "CHAIRMAN", "DIRECTOR", "SECRETARY", "TREASURER", "TRUSTEE"],

            validationList: [],
        };

        this.searchCompanyOwners = this.searchCompanyOwners.bind(this);
        this.searchAddresses = this.searchAddresses.bind(this);

        this.initCompanyOwner = this.initCompanyOwner.bind(this);
        this.editCompanyOwner = this.editCompanyOwner.bind(this);
        this.deleteCompanyOwner = this.deleteCompanyOwner.bind(this);
        this.saveCompanyOwner = this.saveCompanyOwner.bind(this);

        this.initAddress = this.initAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);
        this.saveAddress = this.saveAddress.bind(this);

        this.togglePostionInpField = this.togglePostionInpField.bind(this);
    }

    /**
     * Retrieve the list of company owners on the mounting of the component.
     */
    componentDidMount() {

        this.searchCompanyOwners(1, 25, this.state.companyOwnerQuery);
    }

    /**
     * Search for a list of company owners.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCompanyOwners(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company_owner/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                companyOwnerList: response.data,
                companyOwnerQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for a list of all addresses associated with the company owner.
     *
     * @param companyOwnerId - The ID of the company owner.
     */
    searchAddresses(companyOwnerId) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
            orderBy: 'ASC',
            orderByFields: ['addressType'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: companyOwnerId
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                addressList: response.data.records,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a company owner object when the user indicates they want to create a new signing
     * officer/company owner.
     */
    initCompanyOwner() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company_owner/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                address: {},
                addressList: [],
                newAddress: false,
                editAddress: false,
                companyOwner: {
                    ...response.data,
                    companyId: this.props.match.params.companyId
                },
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Upon selecting a company owner from the list, fetch the company owner data and address history for displaying in
     * the company owner edit modal.
     *
     * @param companyOwnerId - The ID of the selected company owner.
     */
    editCompanyOwner(companyOwnerId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company_owner/${companyOwnerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            
            const position = response.data?.position;

            this.setState(prevState => ({
                ...prevState,
                companyOwner: response.data,
                companyOwnerFirstName: response.data.firstName,
                companyOwnerLastName: response.data.lastName,
                newAddress: false,
                editAddress: false,
                postionTextField: this.state.signingOfficerDefaultPositions?.includes(position)? false: true,
            }));

            this.searchAddresses(companyOwnerId);

            $('#company-owner').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the existing company owner.
     */
    deleteCompanyOwner() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/company_owner/${this.state.companyOwner.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.owners.deleted'
                    },
                    values: {
                        firstName: this.state.companyOwner.firstName,
                        lastName: this.state.companyOwner.lastName
                    }
                }],
            });

            this.searchCompanyOwners(this.state.companyOwnerList.page, this.state.companyOwnerList.recordsPerPage, this.state.companyOwnerQuery);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle the submission of the company owner form. Saves the company owner and the list of addresses.
     *
     * @param event - The event container.
     */
    saveCompanyOwner(event) {

        event.preventDefault();
        
        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, [
            this.state.companyOwner,
            ...this.state.addressList,
        ], {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.state.companyOwner.createDate ? 'saved' : 'admin.companies.owners.created'
                    }
                }]
            });

            this.searchCompanyOwners(this.state.companyOwnerList.page, this.state.companyOwnerList.recordsPerPage, this.state.companyOwnerQuery);

            $('#company-owner').modal('hide');

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Initialize a new instance of an address object when the user clicks the 'create address' button.
     */
    initAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                newAddress: true,
                editAddress: true,
                address: {
                    ...response.data,
                    userId: this.state.companyOwner.id,
                    userType: 'TYPE_COMPANY_OWNER'
                }
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Upon selecting an address from the list, fetch the address data for displaying in the address modal.
     *
     * @param address - The address object selected for editing.
     */
    editAddress(address) {

        this.setState(prevState => ({
            ...prevState,
            address: address,
            newAddress: false,
            editAddress: true,
        }));
    }

    /**
     * Delete the existing address.
     */
    deleteAddress() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/address/${this.state.address.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.searchAddresses(this.state.companyOwner.id);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Save an address. If an index is provided, overwrite the address in the list of addresses matching that index,
     * otherwise, append a new address to the list of addresses.
     *
     * @index - The index of the address object in the list of addresses, if available.
     */
    saveAddress(index) {
        if(this.state.address.addressType == null || this.state.address.street1 == null || this.state.address.city == null || this.state.address.country == null || this.state.address.country === '' || this.state.address.province == null || this.state.address.province === '' || this.state.address.postalCode == null || this.state.address.monthsAtAddress == null) {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'admin.companies.owners.address.null',
                        message: `You have required fields unfilled`
                    }
                }]
            });
            document.getElementById('company-owner').scrollTo(0, 0);
        }

        else {

            let addressList = this.state.addressList;

            if(index != null) {
                addressList[index] = this.state.address;
            }

            if(index == null) {
                addressList.push(this.state.address);
            }

            this.setState(prevState => ({
                ...prevState,
                address: {},
                addressList: addressList,
                newAddress: false,
                validationList: [],
            }));

        }
    }

    /**
     * Toggle between signing officers postion's input field types.
     */
    togglePostionInpField() {
        this.setState(prevState => ({
            ...prevState,
            postionTextField: !prevState.postionTextField,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col-8">
                                Signing Officers
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#company-owner" className="btn btn-primary btn-sm" onClick={() => this.initCompanyOwner()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Signing Officer
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table columns={{firstName: 'Name', email: 'Email', position: 'Position', percentOwned: 'Ownership %'}}
                           columnWidths={['25%', '25%', '25%', '25%']}
                           headerClass="c-pointer"
                           data={this.state.companyOwnerList}
                           query={this.state.companyOwnerQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchCompanyOwners}>
                        <tbody>
                        {this.state.companyOwnerList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.editCompanyOwner(data.id)} className="c-pointer">
                                    <td>{data.firstName} {data.lastName}</td>
                                    <td>{data.email}</td>
                                    <td>{data.position}</td>
                                    <td>{data.percentOwned}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="company-owner" tabIndex="-1" role="dialog" aria-labelledby="company-owner-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveCompanyOwner}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="company-owner-label">
                                        {this.state.companyOwner['createDate'] == null &&
                                        <span className="">
                                            Create Signing Officer
                                        </span>
                                        }
                                        {this.state.companyOwner['createDate'] != null &&
                                        <span className="">
                                            {this.state.companyOwnerFirstName} {this.state.companyOwnerLastName}
                                        </span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="firstName" label="First Name" model="companyOwner" parent={this} value={this.state.companyOwner['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="companyOwner" parent={this} value={this.state.companyOwner['lastName']} />

                                    <FieldPhone id="phone" label="Phone" model="companyOwner" parent={this} value={this.state.companyOwner['phone']} />

                                    <FieldText id="email" label="Email" model="companyOwner" type="email" parent={this} value={this.state.companyOwner['email']} />

                                    <FieldBirthDate id="birthDate" label="Birth Date" model="companyOwner" parent={this} required={true} key={this.state.companyOwner['birthDate']} value={this.state.companyOwner['birthDate']} monthFirst={true}/>

                                    <FieldText id="sin" label="SSN/SIN" model="companyOwner" type="tel" parent={this} value={this.state.companyOwner['sin']} />

                                    {!this.state.postionTextField ?
                                        <FieldSelect id="position" label="Position" model="companyOwner" parent={this} value={this.state.companyOwner['position']} AddnlBtn1Text="Enter a position instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.togglePostionInpField}>
                                            <option value="" disabled>Select a position...</option>
                                            {this.state.signingOfficerDefaultPositions.map((position, index) => (
                                                <option key={index} value={position}>{position}</option>
                                            ))}
                                        </FieldSelect>
                                    :
                                        <FieldText id="position" label="Position" model="companyOwner" parent={this} value={this.state.companyOwner['position']} AddnlBtn1Text="Select a position instead?" AddnlBtn1Type="link" AddnlBtn1Handler={this.togglePostionInpField}/>
                                    }
                                    <FieldText id="percentOwned" label="Ownership %" model="companyOwner" maxLength="3" parent={this} min="0.00" max="999999.99" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" value={this.state.companyOwner['percentOwned']}/>

                                    <FieldCountry id="citizenship" label="Citizenship" model="companyOwner" parent={this} value={this.state.companyOwner['citizenship']} isForCitizenship={true}/>

                                    <div className="form-group row">
                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                            Addresses
                                        </label>
                                        <div className="col-sm-9">

                                            <React.Fragment>
                                                {this.state.addressList.map((address, key) => {
                                                    return(
                                                        <div key={key} className="list-group mb-2">
                                                            <div className={`list-group-item ${(this.state.editAddress && address.id === this.state.address.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editAddress && address.id === this.state.address.id) ? null : this.editAddress(address)}>

                                                                <div className="row align-items-center">
                                                                    <div className="col-10">
                                                                        <div className="">
                                                                            {address.suite ? address.suite + ' - ' : ''}{address.street1 + (address.street2 ? ', ' + address.street2 : '') + ', ' + address.city + ', ' + (address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province})) + ', ' + formatMessage({id: "country." + address.country}) + ' ' + address.postalCode}
                                                                        </div>
                                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                                            <FormattedMessage id={`enum.address.addressType.${address.addressType}`} /> address for {address.monthsAtAddress} month(s)
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right">
                                                                            <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {(this.state.editAddress && address.id === this.state.address.id) &&
                                                                <React.Fragment>

                                                                    <div className="mt-3">
                                                                        <FieldAddress model="address" parent={this} value={this.state.address} labelColumns="4" fieldColumns="8" suite={true} addressType={true} monthsAtAddress={true} required={true} />
                                                                    </div>

                                                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteAddress(address)}>
                                                                        Delete Address
                                                                    </div>

                                                                    <div className="btn btn-primary btn-md btn-block" onClick={() => this.saveAddress(key)}>
                                                                        Save Address
                                                                    </div>

                                                                </React.Fragment>
                                                                }

                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>

                                            {!this.state.newAddress &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initAddress()}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Address
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                            {this.state.newAddress &&
                                            <div className="list-group mb-2">
                                                <div className="list-group-item pb-3">

                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            Add New Address
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right">
                                                                <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x"/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        <FieldAddress model="address" parent={this} value={this.state.address} labelColumns="4" fieldColumns="8" suite={true} addressType={true} monthsAtAddress={true} required={true} />
                                                    </div>

                                                    <div className="btn btn-primary btn-md btn-block mt-3" onClick={() => this.saveAddress()}>
                                                        Save Address
                                                    </div>

                                                </div>
                                            </div>
                                            }

                                        </div>
                                    </div>
                                    <FieldCheckbox id="isApplicant" label="Applicant" parent={this} model="companyOwner" value={this.state.companyOwner['isApplicant']} />
                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#company-owner").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-company-owner" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Signing Officer"
                       body="Are you sure you want to delete this signing officer?">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-company-owner").modal("hide"); $("#company-owner").modal("show");}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteCompanyOwner()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Signing Officer
                    </button>
                </Modal>

                <Modal id="delete-address" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Address"
                       body="Are you sure you want to delete this signing officer's address?">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-address").modal("hide"); $("#address").modal("show");}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteAddress()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Address
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

CompanyOwners.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CompanyOwners);