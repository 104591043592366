import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import FieldDate from "../../common/FieldDate";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";
import ReceiptPaymentTransaction from "../../common/ReceiptPaymentTransaction";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";
import moment from "moment";

class Disbursement extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            searchFilter: '',
            startDate: null,
            endDate: null,
            status: null,

            fundsTransfer: {},
            paymentTransaction: {},

            fundsTransferList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            fundsTransferQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [{
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'disbursmentMode',
                    operator: 'EQUALS',
                    fieldValue: 'MANUAL'
                },],
                joins: {
                    ma: {
                        targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                        joinField: 'merchantAccountId',
                        alias: 'ma',
                        returnFields: ['id','companyId', 'accountNumber', 'currency','routingNumber']
                    },
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'ma.companyId',
                        alias: 'c',
                        returnFields: ['name','legalName']
                    }
                }
            },

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['propertyId', 'companyId', 'unit']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'co',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    },
                    installmentPay:{
                        targetRecordType: 'TYPE_INSTALLMENT_PAY',
                        joinField: 'paymentMethodId',
                        alias: 'installmentPay',
                        returnFields: ['id', 'installmentDetails', 'type', 'installments', 'externalId']
                    },
                }
            },

            validationList: [],

        };

        this.searchFundsTransfers = this.searchFundsTransfers.bind(this);
        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);
        this.filterFundsTransfers = this.filterFundsTransfers.bind(this);
        this.viewFundsTransfer = this.viewFundsTransfer.bind(this);
        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);
        this.editFundsTransfer = this.editFundsTransfer.bind(this);
    }

    /**
     * On mounting of the component, initialize the list of funds transfers by performing a basic funds transfer search.
     * If a specific funds transfer was provided from an external state, load the funds transfer immediately.
     */
    componentDidMount() {

        if(this.props.location.state && this.props.location.state.fundsTransferId) {
            this.viewFundsTransfer(this.props.location.state.fundsTransferId);
        }

        this.searchFundsTransfers(1, 25, this.state.fundsTransferQuery);
    }

    /**
     * Handle selecting a funds transfer record by fetching the relevant funds transfer data and initializing the funds
     * transfer view modal.
     *
     * @param fundsTransferId - The ID of the funds transfer record selected.
     */
    viewFundsTransfer(fundsTransferId) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/search?recordsPerPage=1&page=1`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: fundsTransferId
                },
            ],
            joins: {
                ma: {
                    targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                    joinField: 'merchantAccountId',
                    alias: 'ma',
                    returnFields: ['id','companyId', 'accountNumber', 'currency','routingNumber']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'ma.companyId',
                    alias: 'c',
                    returnFields: ['name','legalName','id']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                fundsTransfer: response.data.records[0],
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        let paymentTransactionQuery = this.state.paymentTransactionQuery;

        paymentTransactionQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'fundsTransferId',
                operator: 'EQUALS',
                fieldValue: fundsTransferId
            },
        ];

        this.searchPaymentTransactions(1, 25, paymentTransactionQuery);

        $('#funds-transfer').modal('show');
    }

    /**
     * View a specific payment transaction receipt within a funds transfer.
     *
     * @param paymentTransaction - The payment transaction to view.
     */
    viewPaymentTransaction(paymentTransaction) {

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: paymentTransaction
        }));

        $('#funds-transfer').modal('hide');
        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * Edit an existing funds transfer's status to 'COMPLETE', and associated transactions' disbursement status to complete. A confirmation id
     * must be provided. Alternatively, edit funds transfer confirmation id
     *

     */
    editFundsTransfer() {

        let currentDate = new Date();
        let patchDataEFT = {
            confirmationId:this.state.fundsTransfer.confirmationId,
        }
        if (this.state.fundsTransfer.status === 'INPROCESS'){
            patchDataEFT = {
                status: 'COMPLETE',
                confirmationId: this.state.fundsTransfer.confirmationId,
                settlementDate: moment.utc(currentDate).format('YYYY-MM-DDTHH:mm:ssZZ')
            };

            axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/batch_disbursed`, {
                ids: this.state.paymentTransactionList.records.map(x=>x.id)},
                {headers: this.generateRequestHeaders()
            }).catch(error => {
                this.handleValidation(error);
            });

        }
        axios.patch(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/${this.state.fundsTransfer.id}/update`, patchDataEFT, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'primary',
                        code: 'admin.operations.efts.status.complete'
                    },
                    fields: {},
                }],
            });

            this.searchFundsTransfers(this.state.fundsTransferList.page, this.state.fundsTransferList.recordsPerPage, this.state.fundsTransferQuery);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Update the data table of funds transfers.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchFundsTransfers(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                fundsTransferList: response.data,
                fundsTransferQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of payment transactions in a funds transfer.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionList: response.data,
                paymentTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Handle clicking a merchant account by redirecting the user to the edit merchant account component.
     * @param companyId - The ID of the company belonging to the merchant account
     * @param merchantAccountId - The ID of the merchant account clicked on.
     */
    selectMerchantAccount(event, companyId, merchantAccountId) {
        if (event.metaKey || event.ctrlKey){
            window.open(`admin/companies/${this.state.fundsTransfer.joins?.c?.id}/merchants/${this.state.fundsTransfer?.joins?.ma?.id}`, "_blank");
        } else {
            window.location.href =`/admin/companies/${companyId}/merchants/${merchantAccountId}`;
        }
    }

    /**
     * Handle submitting the search filter field by adjusting the funds transfer search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterFundsTransfers(event) {

        if (event != null) {
            event.preventDefault();
        }

        let fundsTransferQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [{
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'disbursmentMode',
                operator: 'EQUALS',
                fieldValue: 'MANUAL'
            },],
            joins: {
                ma: {
                    targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                    joinField: 'merchantAccountId',
                    alias: 'ma',
                    returnFields: ['id','companyId', 'accountNumber', 'currency','routingNumber']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'ma.companyId',
                    alias: 'c',
                    returnFields: ['name', 'legalName']
                }
            }
        };

        if (this.state.searchFilter !== '') {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'last4,confirmationId,ma.accountNumber,c.name,c.legalName',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.searchFilter
                }
            );
        }

        if(this.state.status !== null && this.state.status !== '') {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.status
                },
            );
        }

        if(this.state.startDate !== null) {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        this.setState({
            fundsTransferQuery: fundsTransferQuery
        });

        this.searchFundsTransfers(1, 25, fundsTransferQuery);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A list of funds transfers (EFTs) in the system.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Disbursement" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Disbursement
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterFundsTransfers}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="status" placeholder="Status" labelColumns="0" fieldColumns="12" parent={this} value={this.state.status}>
                                            <option value="">All Statuses</option>
                                            <option value="INPROCESS">Waiting to be Paid Out</option>
                                            <option value="COMPLETE">Paid Out</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldDate id="startDate" labelColumns="0"
                                                   fieldColumns="12" parent={this}
                                                   value={this.state.startDate}
                                                   selectsRange={true} startDate={this.state.startDate}
                                                   endDate={this.state.endDate}/>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{'createDate': 'Create Date','merchantAccountId': 'Merchant Account', 'c.name': 'Company', 'c.legalName': 'Legal Name', 'ma.routingNumber': 'Routing Number', 'ma.accountNumber': 'Account Number', id: 'Batch id',  amount: 'Total Amount for Payout', confirmationId:'Confirmation Id', settlementDate: 'Settlement Date', status: 'Status'}}

                               columnWidths={['9%','9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%', '9%']}
                               headerClass="c-pointer"
                               data={this.state.fundsTransferList}
                               query={this.state.fundsTransferQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchFundsTransfers}>
                            <tbody>
                            {this.state.fundsTransferList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewFundsTransfer(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY">
                                                {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>

                                        <td>
                                            <div className="">
                                                {data.joins?.ma?.id}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.joins?.c?.name}
                                            </div>
                                        </td>
                                        <td>
                                            {data.joins &&
                                                <div className="">
                                                    {data.joins?.c?.legalName}
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.joins?.ma?.routingNumber}
                                            </div>
                                        </td>
                                        <td>
                                            {data.joins &&
                                                <div className="">
                                                    {data.joins?.ma?.accountNumber}
                                                </div>
                                            }
                                        </td>

                                        <td>
                                            {data.id}
                                        </td>

                                        <td>
                                            {data.amount &&
                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                    <FormattedNumber value={data.amount} style={`currency`} currency={data.joins ? data.joins?.ma?.currency : 'USD'}/>
                                                </div>
                                            }
                                        </td>
                                        <td>
                                            {data.confirmationId &&
                                                <div className="">
                                                    {data.confirmationId}
                                                </div>
                                            }
                                        </td>

                                        <td>
                                            <div className="">
                                                {data.settlementDate &&
                                                    <Moment format="MMM DD, YYYY">
                                                        {data.settlementDate}
                                                    </Moment>
                                                }
                                            </div>
                                        </td>

                                        <td>
                                            {data.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + data.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + data.status}/></span>
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">

                                <div className="modal-header bg-dark text-white border-bottom-0">
                                    <h5 className="modal-title" id="funds-transfer-label">
                                        EFT Details
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                {this.state.fundsTransfer.error &&
                                    <div className="modal-body bg-danger border-top-0">
                                        <p className="mb-0 text-white">
                                            This EFT was marked as failed for the following reason: {this.state.fundsTransfer.error}
                                        </p>
                                    </div>
                                }

                                <div className="modal-body modal-body-table">
                                    <table className="table mb-0 border-bottom">
                                        <tbody>
                                        <tr className="small">
                                            <td className="">
                                                Create Date
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.createDate &&
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                        {this.state.fundsTransfer.createDate}
                                                        </Moment>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Merchant Account Id
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    <button onClick={(event) => this.selectMerchantAccount(event, this.state.fundsTransfer.joins?.c?.id, this.state.fundsTransfer?.joins?.ma?.id)} > {this.state.fundsTransfer?.joins?.ma?.id} </button>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Company Name
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                    <div className="">
                                                        {this.state.fundsTransfer.joins?.c?.name}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Company Legal Name
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                    <div className="">
                                                        {this.state.fundsTransfer.joins?.c?.legalName}
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Routing Number
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.fundsTransfer.joins?.ma?.routingNumber}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Account Number
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                    <div className="">
                                                        {this.state.fundsTransfer.joins?.ma?.accountNumber}
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                        <tr className="small">
                                            <td className="">
                                                Batch Id
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                    <div className="">
                                                        {this.state.fundsTransfer.id}
                                                    </div>
                                                }
                                            </td>
                                        </tr>


                                        <tr className="small">
                                            <td className="">
                                                Total Amount for Payout
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.amount &&
                                                    <div className={this.state.fundsTransfer.amount < 0 ? 'text-danger' : ''}>
                                                        <FormattedNumber value={this.state.fundsTransfer.amount} style={`currency`} currency={this.state.fundsTransfer.joins ? this.state.fundsTransfer.joins?.ma?.currency : 'USD'} />
                                                    </div>
                                                }
                                            </td>
                                        </tr>

                                        <tr className="small">
                                            <td className="">
                                                Confirmation ID
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.fundsTransfer?.confirmationId}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Settlement Date
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.settlementDate &&
                                                    <Moment format="MMM DD, YYYY">
                                                        {this.state.fundsTransfer.settlementDate}
                                                    </Moment>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Status
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.status &&
                                                    <div className="text-nowrap">
                                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + this.state.fundsTransfer.status + ".class"})}`}/>
                                                        <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + this.state.fundsTransfer.status}/></span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <Table columns={{createDate: 'Date', id: 'Internal Id', amount: 'Amount', feeAmount: 'Fee Amount'}}
                                       columnWidths={['25%', '25%', '25%', '25%']}
                                       headerClass="c-pointer"
                                       data={this.state.paymentTransactionList}
                                       query={this.state.paymentTransactionQuery}
                                       sortEnabled={true}
                                       recordsEnabled={true}
                                       paginationEnabled={true}
                                       updateFunction={this.searchPaymentTransactions}>
                                    <tbody>
                                    {this.state.paymentTransactionList.records.map((data, key) => {
                                        return(
                                            <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                                <td>
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                            {data.createDate}
                                                        </Moment>
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.id &&
                                                        <div className="">
                                                            {data.id}
                                                        </div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                        {data.amount &&
                                                            <FormattedNumber value={data.amount} style={`currency`} currency={data.currency} />
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.feeAmount}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-3">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#funds-transfer").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col">
                                            <div className="text-right">
                                                {this.state.fundsTransfer.status === 'INPROCESS' &&
                                                    <React.Fragment>
                                                        <div className="btn btn-primary btn-lg mr-2" onClick={() => {$("#funds-transfer").modal("hide"); $("#complete-funds-transfer").modal("show")}}>
                                                            Mark As Paid Out
                                                        </div>
                                                    </React.Fragment>
                                                }
                                                {this.state.fundsTransfer.status !== 'INPROCESS' &&
                                                    <React.Fragment>
                                                        <div className="btn btn-primary btn-lg mr-2" onClick={() => {$("#funds-transfer").modal("hide"); $("#complete-funds-transfer").modal("show")}}>
                                                            Edit Confirmation Id
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <ReceiptPaymentTransaction userType="TYPE_ADMIN" history={this.props.history} displayFeeAmount={true} displayAdvanced={true} paymentTransaction={this.state.paymentTransaction}>

                        <div className="float-left">
                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $("#funds-transfer").modal('show')}>
                                <FormattedMessage id="button.back" />
                            </button>
                        </div>

                    </ReceiptPaymentTransaction>

                    <div className="modal fade" id="complete-funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="complete-funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="service-fee-label">
                                        Mark As Paid Out
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Are you sure you want to set the status of this batch to Paid Out? If so, please provide a confirmation ID for this batch.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldText id="confirmationId" label="confirmationId" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md my-2" labelClass="col-form-label-md d-none" model="fundsTransfer" parent={this} value={this.state.fundsTransfer['confirmationId']} />

                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#complete-funds-transfer").modal("hide"); $("#funds-transfer").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button onClick={() => {this.editFundsTransfer()}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                {this.state.fundsTransfer.status === 'INPROCESS' ? 'Mark As Paid Out' : 'Submit Edited Confirmation Id'}
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Disbursement.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Disbursement);