import { Component } from "react";
import React from "react";
import FieldCheckbox from "./FieldCheckbox";
import FieldText from "./FieldText";

class IntegrationForm extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - Re-usable components implementation for Integration Form
     */
    render() {
        return (
            <div className="">
                <FieldText id="ApiUrl" label="API URL" model="settings" parent={this.props.parent} value={this.props.settings['ApiUrl']} />
                { this.props.hasDatabase &&
                    <FieldText id="Database" label="Database" model="settings" parent={this.props.parent} value={this.props.settings['Database']} />
                }
                <FieldText id={this.props.userNameFormat || "UserName"} label="Username" model="settings" parent={this.props.parent} 
                    value={this.props.settings[this.props.userNameFormat || "UserName"]} />

                <FieldText id="Password" label="Password" model="settings" type="password" parent={this.props.parent} value={this.props.settings['Password']}/>

                <FieldCheckbox id="MarkReversalsPushingFailed" label="Fail to Push Reversals" model="" fieldLabel="Fail to push unchecked refunds, Returns, Chargebacks and NSFs" parent={this.props.parent}
                value={this.props.calculateBooleanMarkReversalsPushingFailed()} handleChange={() =>{this.props.handleChangeMarkReversalsPushingFailed()}}/>

                <FieldCheckbox id="pushReversalsRefund" label="Push Refunds" model="" fieldLabel="Push Refunds to Integrated system" parent={this.props.parent} value={this.props.calculateBooleanPushReversal('REFUND')} 
                handleChange={()=>{this.props.handleChangePushReversal('REFUND')}}/>

                <FieldCheckbox id="pushReversalsReturn" label="Push Returns" model="" fieldLabel="Push returns to Integrated system" parent={this.props.parent} value={this.props.calculateBooleanPushReversal('RETURN')} 
                handleChange={()=>{this.props.handleChangePushReversal('RETURN')}}/>

                <FieldCheckbox id="pushReversalsChargeback" label="Push ChargeBacks" model="" fieldLabel="Push charge backs to Integrated system" parent={this.props.parent} value={this.props.calculateBooleanPushReversal('CHARGEBACK')} 
                handleChange={()=>{this.props.handleChangePushReversal('CHARGEBACK')}}/>

                <FieldCheckbox id="pushReversalsNsf" label="Push NSF" model="" fieldLabel="Push NSF to Integrated system" parent={this.props.parent} value={this.props.calculateBooleanPushReversal('NSF')} 
                handleChange={()=>{this.props.handleChangePushReversal('NSF')}}/>
            </div>
        );
    }
}

export default IntegrationForm;