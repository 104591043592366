import React from 'react';
import ReactSelect from 'react-select';
import { HYPER_WALLET_CHARGE_TYPES } from "../../../constants/HyperWalletChargeTypes";

const HyperWalletChargeDropdown = (props) => {
  const options = HYPER_WALLET_CHARGE_TYPES;

  const listOptionSelected = (event) => {
    props.setSelectedCharge(event.value);
  }

  return (
    <ReactSelect
      id='selectedChargeHP'
      name='selectedCharge'
      options={options}
      value={options.filter(function(option) {
              return option.value === props.selectedCharge;
            })}
      onChange={listOptionSelected}
      placeholder="Select a charge"
    />
  );
};

export default HyperWalletChargeDropdown;
