import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import * as moment from "moment";
import React from 'react';
// import ProgressBar from 'react-customizable-progressbar';
import { injectIntl, intlShape } from 'react-intl';
import creditCard from "../../media/img/payments/credit-card.png";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldAddress from "../common/FieldAddress";
import FieldDate from "../common/FieldDate";
import FieldFile from "../common/FieldFile";
import FieldText from "../common/FieldText";
import FieldTextarea from "../common/FieldTextarea";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import CardPreview from "./CardPreview";
import FieldBirthDate from "./FieldBirthDate";
import FieldCardNumber from "./FieldCardNumber";
import FieldCheckbox from "./FieldCheckbox";
import FieldPhone from "./FieldPhone";
import FieldProperty from "./FieldProperty";
import FieldSelect from "./FieldSelect";
import PrivacyConsent from "./PrivacyConsent";
import TermsContents from "./Terms";
import getSessionFromToken from "../../util/getSession";
import { CreditCardRegexArray } from '../../constants/CreditCards';
import CardBrandIcon from '../common/CardBrandIcon';
import FeeProfiles from "./FeeProfiles";


class LeaseApplication extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},
            leaseApplicationPage: {},
            leaseApplication: {
                acknowledgedTerms: true,
                acknowledgedPrivacyConsent: true,
            },
            customFields: {},
            documents: {},
            document: {
                photoId: "",
                payStubs: "",
                taxReturns: "",
            },

            occupantList: [],
            occupant: {},
            showOccupantForm: false,
            editOccupant: false,

            employment: {},

            referenceList: [],
            reference: {},
            showReferenceForm: false,
            editReference: false,

            otherInfo: {},

            emergencyContact: {},


            property: {},
            propertyList: [],

            address: {},
            addressList: [],
            showAddressForm: false,
            editAddress: false,

            termsCompleted: false,
            propertyCompleted: false,
            customerCompleted: false,
            emergencyCompleted: false,
            addressesCompleted: false,
            occupantsCompleted: false,
            employmentCompleted: false,
            referencesCompleted: false,
            paymentMethodCompleted: false,
            applicationFeeCompleted: false,

            progress: 0,
            totalSections: 0,

            validationList: [],
            sections: [],

            showEmergencySection: true,
            showOccupants: true,
            showEmployement: true,
            showReferences: true,
            showAttachments: true,

            paymentMethod: {},
            billingAddress:{},

            showSubmitApplicationButton: this.props.leaseApplicationPage?.incomeVerificationEnabled ? false: true,
            showPaymentSummarySection: false,

            processingFee: 0,
            totalToPay: 0,
            recaptchaEnabled: true,

            noAttachments: 0,
            reCaptchaId:null,
            rentifyURL: '',
            showRentifyIframe:false,
            showIncomeIdentityVerification:false,
            selectPayment:'',
            sentAuthorizePayment: false,
            showIIVerificationSection: this.props.leaseApplicationPage?.customFields?.applicationFeeAmount > 0 ? false:true,
            clickedOnSaveTermsAndConditions: false,
            paymentMethodList: [],
            selectedEditCard: {},
            selectedEditAddress:{
                street: '',
                city: '',
                country: '',
                postalCode: '',
            },
            showNewCreditCardSection:false,
            feeProfileList: [{}],
            feeProfileCountry: '',
            receiveTxtMessageBool: false,
            savedProperty: true,
            savedCustomer: true
        };

        this.saveProgress = this.saveProgress.bind(this);
        this.saveTerms = this.saveTerms.bind(this);
        this.saveProperty = this.saveProperty.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.saveEmergency = this.saveEmergency.bind(this);
        this.saveAddresses = this.saveAddresses.bind(this);
        this.saveOccupants = this.saveOccupants.bind(this);
        this.saveEmployment = this.saveEmployment.bind(this);
        this.saveReferences = this.saveReferences.bind(this);
        this.saveAttachments = this.saveAttachments.bind(this);

        this.editTerms = this.editTerms.bind(this);
        this.editProperty = this.editProperty.bind(this);
        this.editCustomer = this.editCustomer.bind(this);
        this.editAddresses = this.editAddresses.bind(this);
        this.editOccupants = this.editOccupants.bind(this);
        this.editEmployment = this.editEmployment.bind(this);
        this.editReferences = this.editReferences.bind(this);
        this.editAttachments = this.editAttachments.bind(this);

        this.initAddress = this.initAddress.bind(this);
        this.editAddress = this.editAddress.bind(this);
        this.deleteAddress = this.deleteAddress.bind(this);

        this.initOccupant = this.initOccupant.bind(this);
        this.editOccupant = this.editOccupant.bind(this);
        this.deleteOccupant = this.deleteOccupant.bind(this);

        this.initReference = this.initReference.bind(this);
        this.editReference = this.editReference.bind(this);
        this.deleteReference = this.deleteReference.bind(this);

        this.handleChangeProperty = this.handleChangeProperty.bind(this);

        this.submitLeaseApplication = this.submitLeaseApplication.bind(this);

        this.createCustomFields = this.createCustomFields.bind(this);
        this.createDropdownField = this.createDropdownField.bind(this);
        this.createTextField = this.createTextField.bind(this);
        this.createDateField = this.createDateField.bind(this);
        this.createCheckboxField = this.createCheckboxField.bind(this);
        this.createField = this.createField.bind(this);
        this.showEmergencySection = this.showEmergencySection.bind(this);
        this.showOccupantsSection = this.showOccupantsSection.bind(this);
        this.showEmployementSection = this.showEmployementSection.bind(this);
        this.showReferencesSection = this.showReferencesSection.bind(this);
        this.showAttachmentsSection = this.showAttachmentsSection.bind(this);
        this.handleFileRemove = this.handleFileRemove.bind(this);

        this.openTerms = this.openTerms.bind(this);
        this.openPrivacyConsent = this.openPrivacyConsent.bind(this);

        this.getUpdatedSections = this.getUpdatedSections.bind(this);

        this.editPaymentMethod = this.editPaymentMethod.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.submitPayment = this.submitPayment.bind(this);
        this.calculateFee = this.calculateFee.bind(this);
        this.getFeeProfiles = this.getFeeProfiles.bind(this);
        this.initPaymentMethod = this.initPaymentMethod.bind(this);

        this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
        this.getReCaptchaSetting = this.getReCaptchaSetting.bind(this);

        // this.buildCustomFieldsObject = this.buildCustomFieldsObject.bind(this);
        this.generateRequestHeadersForMultipart = this.generateRequestHeadersForMultipart.bind(this);

        this.openRentify = this.openRentify.bind(this);
        this.getRentifyIframe = this.getRentifyIframe.bind(this);

        this.listenRentifyIframe =  this.listenRentifyIframe.bind(this);
        this.removeListenerRentify = this.removeListenerRentify.bind(this);

        this.authorizePayment = this.authorizePayment.bind(this);
        this.deleteBillingAddress = this.deleteBillingAddress.bind(this);
        
        this.showFailCCAuthorization = this.showFailCCAuthorization.bind(this);
        this.updateTenantAccountInfo = this.updateTenantAccountInfo.bind(this);

        this.getTotalSections = this.getTotalSections.bind(this);
        this.updateCustomFields = this.updateCustomFields.bind(this);

        this.getUserPaymentMethods = this.getUserPaymentMethods.bind(this);
        this.handleChangePaymentCard = this.handleChangePaymentCard.bind(this);
        this.handleDeletePaymentCard = this.handleDeletePaymentCard.bind(this);
        this.getAddressDetails = this.getAddressDetails.bind(this);
        this.deleteCreditCard = this.deleteCreditCard.bind(this);
        this.showNewCreditCardSection = this.showNewCreditCardSection.bind(this);
        this.hideNewCreditCardSection = this.hideNewCreditCardSection.bind(this);
        this.createPaymentMethod = this.createPaymentMethod.bind(this);
        this.preAuthorizePayment = this.preAuthorizePayment.bind(this);
        this.handleReceiveTxtMessage = this.handleReceiveTxtMessage.bind(this);
    }

    /**
     * On mounting of the component, fetch the lease application page and lease application currently in progress from
     * the props. If no lease application has been provided in the props, initialize a new instance of a lease
     * application.
     */
    componentDidMount() {
        localStorage.removeItem('leaseApplicationPage');
        if(this.props.leaseApplicationPage) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPage: this.props.leaseApplicationPage,
            }));
        }
        
        if(this.props.leaseApplication) {
            this.setState(prevState => ({
                ...prevState,
                acknowledgedTermsPrev: this.props.leaseApplication.acknowledgedTerms,
            }));

            this.getUpdatedSections();

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.leaseApplication?.companyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    company: response.data
                }));
            }).catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });

            if(this.props.leaseApplicationPage?.customFields?.applicationFeeAmount > 0){
                this.initPaymentMethod('credit_card')
                this.getReCaptchaSetting();
            }
        }

        if(!this.props.leaseApplication) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...response.data,
                        existingTenant: false,
                        applicationPageId: this.props.leaseApplicationPage?.id
                    }
                }));
            }).catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });
        }

        this.getUserData()

        if(localStorage.getItem('propertyId')){
            this.formatPropertyLabel(localStorage.getItem('propertyId'));
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    propertyId: localStorage.getItem('propertyId'), 
                }
            }));
        }
    }

    getTotalSections() {
        let sections = 0;
        // Terms & conditions
        sections ++;
        // Property
        sections ++;
        // Applicant
        sections ++;
        // Emergency contact
        if(this.state.showEmergencySection)
                sections ++;
        // Address History
        if(!this.state.leaseApplication.existingTenant)
                sections ++;
        // Additional occupants
        if(this.state.showOccupantsSection)
            sections ++;
        // Employment information
        if(this.state.showEmployementSection)
            sections ++;
        // Personal Reference
        if(this.state.showReferencesSection)
            sections ++;
        // Attachments
        if(this.state.showAttachmentsSection)
            sections ++;
        // Fee section
        if(this.props.leaseApplicationPage?.customFields?.applicationFeeAmount > 0)
            sections ++;
        // Rentify section
        if(this.props.leaseApplicationPage?.identityVerificationEnabled && this.props.leaseApplicationPage?.incomeVerificationEnabled)
            sections ++;
        this.setState(prevState => ({
            ...prevState,
            totalSections: sections
        }), () =>  this.hideCompletedSections());
    }

    getUserData() {
        const sessionData = getSessionFromToken()
        this.getCustomer(sessionData?.user.id);
        this.getUserPaymentMethods(sessionData?.user.id);
    }

    async getCustomer(userId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${userId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    lastName: response.data.lastName,
                    firstName: response.data.firstName,
                    email: response.data.email
                },
                receiveTxtMessageBool: !response.data.muteTextMessageNotification,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Get saved payment methods of the user.
     *
     */
     getUserPaymentMethods(userId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${userId}/paymentmethods`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentMethodList: response.data
            }), ()=>{
                this.getAddressDetails(this.state.paymentMethodList);
            });           
            
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Get address details of each payment method.
     *
     */
     getAddressDetails(paymentMethodList) {
        (paymentMethodList).forEach((data,index) => {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'id',
                        operator: 'EQUALS',
                        fieldValue: data.billingAddressId
                    }
                ]
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {
            
                let items = [...this.state.paymentMethodList];
                let item = {...items[index]};
                item.billingAddressList = response.data?.records[0];
                items[index] = item;
                
                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: items,
                }));  
    
            }).catch(error => {
                this.handleValidation(error);
            });
        });
        
    }


    /**
     * Handle changes to the selected payment card.
     *
     * @param event - The event container.
     */
     handleChangePaymentCard(event) {

        event.persist();
        let paymentMethod = this.state.paymentMethodList.find(data => data.id === event.target.value);
      
        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            paymentMethod: paymentMethod,      
        }));
    }

    
    /**
     * Handle click to select the delete payment card.
     *
     * @param event - The event container.
     */
     handleDeletePaymentCard(event, data) {

        event.preventDefault();
        $("#delete-credit-card").modal("show")

        this.setState(prevState => ({
            ...prevState,
            selectedDeleteCard: data,
        }));        
    }
    
    /**
     * Handle click to delete payment card.
     *
     * @param event - The event container.
     */
    deleteCreditCard(selectedDeleteCard) {
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/credit_card/${selectedDeleteCard.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            const sessionData = getSessionFromToken();
            this.getUserPaymentMethods(sessionData?.user.id);

            this.setState({
                spinner: false,
                paymentCard: 0,
            });

            this.setCustomAlert("primary", "credit.card.deleted");
            
            $("#delete-credit-card").modal("hide");
            window.scrollTo(0, 0);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Show the section to create a new debit or credit card.
     * 
     */
    showNewCreditCardSection(){
        this.setState(prevState => ({
            ...prevState,
            showNewCreditCardSection: true,
        }));
    }

    /**
     * Hide the section to create a new debit or credit card.
     * 
     */
     hideNewCreditCardSection(){
        this.setState(prevState => ({
            ...prevState,
            showNewCreditCardSection: false,
        }));
    }

     /**
     * Get updated Sections from Template.
     *
     * 
     */
      getUpdatedSections() {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/name/${this.props.leaseApplicationPage?.pageName}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                sections: response.data?.joins?.template?.sections,
                identityVerificationEnabled: response.data?.identityVerificationEnabled,
                incomeVerificationEnabled:response.data?.incomeVerificationEnabled,
                applicationFeeAmount:response.data?.customFields.applicationFeeAmount || 0,
            }), ()=>{
                if(this.state.sections[0]?.defaultFields[0]?.value === 'false'){
                    this.saveTerms()
                }
                    
                let sections = this.state.sections;
              
                this.showEmergencySection(sections[3]);
                this.showOccupantsSection(sections[5]);
                this.showEmployementSection(sections[6]);
                this.showReferencesSection(sections[7]);
                this.showAttachmentsSection(sections[8]);
                this.getSavedData();
            });

        }).catch(error => {
            this.props.history.push("");
        });
      }


    /**
     * Flag to define is Show Emergency Section.
     *
     * 
     */
     showEmergencySection(section) {
        let counterDefaultFields = 0, 
            counterCustomFields = 0;

        if(section['customFields'] !== null) {   
        section['customFields'].forEach(customField => {
            if(customField.value === 'true'){
                counterCustomFields++;
            }
        });
        }

        if(section['defaultFields'] !== null) {   
        section['defaultFields'].forEach(defaultField => {
            if(defaultField.value === 'true'){
                counterDefaultFields++;
            }
        });
        }

        if (counterDefaultFields > 0 || counterCustomFields > 0){
            this.setState(prevState => ({
                ...prevState,
                showEmergencySection: true
            }));
        } else{
            this.setState(prevState => ({
                ...prevState,
                showEmergencySection: false,
            }));
        }
    }

    /**
     * Flag to define is Show Occupants Section.
     *
     * 
     */
     showOccupantsSection(section) {
        let counterDefaultFields = 0, 
            counterCustomFields = 0;

        if(section['customFields'] !== null) {   
        section['customFields'].forEach(customField => {
            if(customField.value === 'true'){
                counterCustomFields++;
            }
        });
        }

        if(section['defaultFields'] !== null) {   
        section['defaultFields'].forEach(defaultField => {
            if(defaultField.value === 'true'){
                counterDefaultFields++;
            }
        });
        }

        if (counterDefaultFields > 0 || counterCustomFields > 0){
            this.setState(prevState => ({
                ...prevState,
                showOccupantsSection: true
            }));
        } else{
            this.setState(prevState => ({
                ...prevState,
                showOccupantsSection: false,
            }));
        }
    }

     /**
     * Flag to define is Show Occupants Section.
     *
     * 
     */
      showEmployementSection(section) {
        let counterDefaultFields = 0, 
            counterCustomFields = 0;

        if(section['customFields'] !== null) {   
        section['customFields'].forEach(customField => {
            if(customField.value === 'true'){
                counterCustomFields++;
            }
        });
        }

        if(section['defaultFields'] !== null) {   
        section['defaultFields'].forEach(defaultField => {
            if(defaultField.value === 'true'){
                counterDefaultFields++;
            }
        });
        }

        if (counterDefaultFields > 0 || counterCustomFields > 0){
            this.setState(prevState => ({
                ...prevState,
                showEmployementSection: true
            }));
        } else{
            this.setState(prevState => ({
                ...prevState,
                showEmployementSection: false,
            }));
        }
    }

    /**
     * Flag to define is Show Occupants Section.
     *
     * 
     */
     showReferencesSection(section) {
        let counterDefaultFields = 0, 
            counterCustomFields = 0;

        if(section['customFields'] !== null) {   
        section['customFields'].forEach(customField => {
            if(customField.value === 'true'){
                counterCustomFields++;
            }
        });
        }

        if(section['defaultFields'] !== null) {   
        section['defaultFields'].forEach(defaultField => {
            if(defaultField.value === 'true'){
                counterDefaultFields++;
            }
        });
        }

        if (counterDefaultFields > 0 || counterCustomFields > 0){
            this.setState(prevState => ({
                ...prevState,
                showReferencesSection: true
            }));
        } else{
            this.setState(prevState => ({
                ...prevState,
                showReferencesSection: false,
            }));
        }
    }

    /**
     * Flag to define is Show Occupants Section.
     *
     * 
     */
     showAttachmentsSection(section) {
        let counterDefaultFields = 0, 
            counterCustomFields = 0;

        if(section['customFields'] !== null) {   
        section['customFields'].forEach(customField => {
            if(customField.value === 'true'){
                counterCustomFields++;
            }
        });
        }

        if(section['defaultFields'] !== null) {   
        section['defaultFields'].forEach(defaultField => {
            if(defaultField.value === 'true'){
                counterDefaultFields++;
            }
        });
        }

        if (counterDefaultFields > 0 || counterCustomFields > 0){
            this.setState(prevState => ({
                ...prevState,
                showAttachmentsSection: true
            }));
        } else{
            this.setState(prevState => ({
                ...prevState,
                showAttachmentsSection: false,
            }));
        }
    }

     /**
     * Update the information in my Letus account with Tenant's most recent lease application
     *
     * 
     */
      updateTenantAccountInfo() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/CUSTOMER/search`, {
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'email',
                    operator: 'EQUALS',
                    fieldValue: this.state.leaseApplication.email
                }
            ]
        }, {
            headers: this.generateRequestHeaders(),
        }).then(response => {

            if(response?.data?.records[0]?.id){
                axios.patch(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.records[0].id}/update`, {
                    
                    type:"TYPE_CUSTOMER",
                    firstName: this.state.leaseApplication.firstName,
                    lastName: this.state.leaseApplication.lastName,
                    phone: this.state.leaseApplication.phone,
                    birthDate: this.state.leaseApplication.birthDate,
                    muteTextMessageNotification: !this.state.receiveTxtMessageBool,
    
                }, {
                    headers: this.generateRequestHeaders(),
                }).then(response => {
                   
        
                }).catch(error => {
                    this.handleValidation(error);
                    window.scrollTo(0, 0);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
      }


    /**
     * Save the progress of the lease application flow.
     *
     * 
     */
     saveProgress() {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/saveprogress`, this.state.leaseApplication,    
        {
            headers: this.generateRequestHeaders()
        }).then(response => {
            
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                status: response.data.status
            }));
            
        }).catch(error => {

            this.handleValidation(error);
            window.scrollTo(0, 0);

        });

     }

    /**
     * 
     * Create the object with custom fields values
     * 
     * @param section
     */
    getCustomFieldsData(section, arr = false) {
        let obj = {};
        if(arr !== false) {
            for(let i = 0; i < arr.length; i ++) {
                this.state.sections[section].customFields.forEach((field) => {
                    const fieldName = field.label.replace(/\s+/g, '') + i;
                    obj[field.label + i] = this.state[fieldName] || (field.type === 'TYPE_CHECKBOX_FIELD' ? false : "");
                })
            }
        } else {
            this.state.sections[section].customFields.forEach((field) => {
                const fieldName = field.label.replace(/\s+/g, '');
                obj[field.label] = this.state[fieldName] || (field.type === 'TYPE_CHECKBOX_FIELD' ? false : "");
            })
        }
        return obj;
    }


    /**
     * Save the terms of use of the lease application flow.
     *
     * @param event - The event container.
     */
    saveTerms(event = false) {

        if(event)
            event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
        }));

        // Save custom fields
        if (this.state.sections[0]?.customFields?.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    customFields: {
                        ...prevState.leaseApplication.customFields,
                        pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                            ...prevState.leaseApplication.customFields.pageCustomFields,
                            TCs: this.getCustomFieldsData(0)
                        } : {
                            TCs: this.getCustomFieldsData(0)
                        }
                    }
                }
            }))
        }
        
        if(this.state.leaseApplicationPage?.customFields?.propertyLevel === 'COMPANY') {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.company.id}/properties`, {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: []
            }, {
                headers: {'Content-Type': 'application/json'}
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyList: response.data.records,
                    validationList: [],
                    clickedOnSaveTermsAndConditions: true,
                }), () => {
                    this.saveProgress();
                    this.hideCompletedSections()
                });
            }).catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });
        } else {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyList: this.state.leaseApplicationPage?.propertySelectorDetails,
                validationList: [],
                clickedOnSaveTermsAndConditions: true,
            }), () => {
                this.saveProgress();
                this.hideCompletedSections()
            });
        }
    }

    /**
     * Save the property portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        if(!this.state.leaseApplication.propertyId && this.state.sections[1]?.defaultFields[0]?.value === 'true') {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'application.property.null',
                        message: 'Please select a property',
                    }
                }]
            });

            window.scrollTo(0, 0);
        } else{
            // Save custom fields
            if (this.state.sections[1]?.customFields?.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...prevState.leaseApplication,
                        customFields: {
                            ...prevState.leaseApplication.customFields,
                            pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                                ...prevState.leaseApplication.customFields.pageCustomFields,
                                Property: this.getCustomFieldsData(1)
                            } : {
                                Property: this.getCustomFieldsData(1)
                            }
                        }
                    }
                }))
            }
            this.setState(prevState => ({
                ...prevState,
                validationList: [],
                savedProperty: true,
            }), () => {
                this.hideCompletedSections()
                this.saveProgress();
            });
        }
    }

    /**
     * Save the customer portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveCustomer(event) {

        event.preventDefault();

        if(this.state.leaseApplication['birthDate'] && moment().diff(moment(this.state.leaseApplication['birthDate'], 'YYYYMMDD'), 'years') < 18) {

            this.setState(prevState => ({
                ...prevState,
                validationListApplicant: [{
                    alert: {
                        type: 'danger',
                        code: 'danger.application.dob.invalid',
                        message: 'An applicant cannot be less than 18 years old.'
                    }
                }]
            }));

            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    validationListApplicant: []
                }));
            }, 10000); 

            return;
        }

        let otherInfo = this.state.otherInfo;

        // Save custom fields
        if (this.state.sections[2]?.customFields?.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    customFields: {
                        ...prevState.leaseApplication.customFields,
                        pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                            ...prevState.leaseApplication.customFields.pageCustomFields,
                            Applicant: this.getCustomFieldsData(2)
                        } : {
                            Applicant: this.getCustomFieldsData(2)
                        }
                    }
                }
            }))
        }

        // Autopopulate the name of tenant in application fee section
        this.setState(prevState => ({
            paymentMethod: {
                ...prevState.paymentMethod,
                nameOnCard: `${this.state.leaseApplication.firstName} ${this.state.leaseApplication.middleName !== null ? this.state.leaseApplication.middleName : ''} ${this.state.leaseApplication.lastName}`
            },
            savedCustomer: true,
        }))

        // If this is an existing tenant, skip application-specific sections
        if(this.state.leaseApplication.existingTenant) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherInfo: otherInfo
                },
                customerCompleted: true,
                emergencyCompleted:false,
                addressesCompleted: false,
                occupantsCompleted: false,
                employmentCompleted: false,
                referencesCompleted: false,
                attachmentsCompleted:false,
                paymentMethodCompleted: false,
                progress: 100,
                validationList: [],
            }), () => {
                this.saveProgress();
                this.updateTenantAccountInfo();
            });
        }

        // If this is a new application, follow through with regular application flow
        if(!this.state.leaseApplication.existingTenant) {

            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherInfo: otherInfo
                },
                validationList: [],
            }), () => {
                this.hideCompletedSections();
                this.saveProgress();
                this.updateTenantAccountInfo();
            });
        }
    }
    
    /**
     * Save the emergency contact portion of the lease application flow.
     *
     * @param event - The event container.
     */
     saveEmergency(event) {
        event.preventDefault();

        let emergencyContact = this.state.emergencyContact;

        // Save custom fields
        if (this.state.sections[3]?.customFields?.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    customFields: {
                        ...prevState.leaseApplication.customFields,
                        pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                            ...prevState.leaseApplication.customFields.pageCustomFields,
                            Emergency: this.getCustomFieldsData(3)
                        } : {
                            Emergency: this.getCustomFieldsData(3)
                        }
                    }
                }
            }))
        }

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                emergencyContact: {
                    firstName: emergencyContact.emergencyFirstName,
                    middleName: emergencyContact.emergencyMiddleName,
                    lastName: emergencyContact.emergencyLastName,
                    phone: emergencyContact.emergencyPhone,
                    email: emergencyContact.emergencyEmail,
                    relationship: emergencyContact.emergencyRelationship
                }
            },
            validationList: [],
        }), () =>{
            this.hideCompletedSections()
            this.saveProgress();
        });
    }

    /**
     * Save the address history portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveAddresses(event) {

        event.preventDefault();

        let addressList = this.state.addressList;

        // Autopopulate the address of tenant in application fee section
        this.setState(prevState => ({
            billingAddress: {
                ...prevState.billingAddress,
                city: this.state.address.city !== null ? this.state.address.city : '',
                country: this.state.address.country !== null ? this.state.address.country : '',
                postalCode: this.state.address.postalCode !== null ? this.state.address.postalCode : '',
                province: this.state.address.province !== null ? this.state.address.province : '',
                street1: this.state.address.street1 !== null ? this.state.address.street1 : '',
                street2: this.state.address.street2 !== null ? this.state.address.street2 : '',
                suite: this.state.address.suite !== null ? this.state.address.suite : '',
            },
        }))

        if(this.state.showAddressForm) {

            if(this.state.editAddress) {
                addressList.forEach((address, key) => {
                    if(address.id === this.state.address.id) {
                        address = this.state.address;
                    }
                });
            }
            
            if(!this.state.editAddress) {
                this.setState(prevState => ({
                    ...prevState,
                    address: {
                        ...prevState.address,
                        customFields:{
                            monthlyRent: this.state.leaseApplication['monthlyRent'],
                            buildingManagerLandlordName: this.state.leaseApplication['buildingManagerLandlordName'],
                            buildingManagerLandlordPhone: this.state.leaseApplication['buildingManagerLandlordPhone'],
                            buildingManagerLandlordEmail: this.state.leaseApplication['buildingManagerLandlordEmail'],
                            comments:this.state.leaseApplication['comments'],
                        }
                    },
                }), () =>{
                    addressList.push(this.state.address);
                    this.setState(prevState => ({
                        ...prevState,
                        showAddressForm: false,
                        addressList: addressList,
                        validationList: [],
                    }));
                });
               
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    showAddressForm: false,
                    addressList: addressList,
                    validationList: [],
                }));
            }
        }

        else {

            let monthsAtAddressTotal = 0;

            addressList.forEach((address, key) => {
                monthsAtAddressTotal = monthsAtAddressTotal + parseInt(address.monthsAtAddress);
            });
            if((monthsAtAddressTotal < 36) && (this.state.sections[4]?.defaultFields[7]?.value === 'true')) {

                this.setState(prevState => ({
                    ...prevState,
                    validationListAddress: [{
                        alert: {
                            type: 'danger',
                            code: 'application.addresses.invalid',
                            message: 'Sum of ‘Months at Address’ for all addresses that you have provided is less than 3 years.'
                        }
                    }]
                }));

                setTimeout(() => {
                    this.setState(prevState => ({
                        ...prevState,
                        validationListAddress: []
                    }));
                }, 10000); 

                //window.scrollTo(0, 0);
            }else {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...prevState.leaseApplication,
                        addresses: addressList,
                    },
                    validationList: [],
                }), () => {
                    // Save custom fields
                    if (this.state.sections[4]?.customFields?.length > 0) {
                        this.setState(prevState => ({
                            ...prevState,
                            leaseApplication: {
                                ...prevState.leaseApplication,
                                customFields: {
                                    ...prevState.leaseApplication.customFields,
                                    pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                                        ...prevState.leaseApplication.customFields.pageCustomFields,
                                        Addresses: this.getCustomFieldsData(4, addressList)
                                    } : {
                                        Addresses: this.getCustomFieldsData(4, addressList)
                                    }
                                }
                            }
                        }), () => {
                            this.hideCompletedSections();
                            this.saveProgress();
                        })
                    } else {
                        this.hideCompletedSections();
                        this.saveProgress();
                    }
                });
            }
        }
        this.setState(prevState => ({
            ...prevState,
            editAddress:false
        }));
        
    }

    /**
     * Save the additional occupants portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveOccupants(event) {

        event.preventDefault();

        let occupantList = this.state.occupantList;

        if(this.state.showOccupantForm) {

            if(this.state.editOccupant) {
                occupantList.forEach((occupant, key) => {
                    if(occupant.id === this.state.occupant.id) {
                        occupant = this.state.occupant;
                    }
                });
            }

            if(!this.state.editOccupant) {
                occupantList.push(this.state.occupant);
            }

            this.setState(prevState => ({
                ...prevState,
                showOccupantForm: false,
                editOccupant: false,
                occupantList: occupantList,
                validationList: [],
            }));
        }

        else {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    otherOccupants: occupantList
                },
                occupantsCompleted: true,
                validationList: [],
            }), () => {
                // Save custom fields
                if (this.state.sections[5]?.customFields?.length > 0) {
                    this.setState(prevState => ({
                        ...prevState,
                        leaseApplication: {
                            ...prevState.leaseApplication,
                            customFields: {
                                ...prevState.leaseApplication.customFields,
                                pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                                    ...prevState.leaseApplication.customFields.pageCustomFields,
                                    Occupants: this.getCustomFieldsData(5, occupantList)
                                } : {
                                    Occupants: this.getCustomFieldsData(5, occupantList)
                                }
                            }
                        }
                    }), () => {
                        this.hideCompletedSections();
                        this.saveProgress();
                    })
                } else {
                    this.hideCompletedSections();
                    this.saveProgress();
                }
            }); 
        }
    }

    /**
     * Save the employment information portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveEmployment(event) {

        event.preventDefault();

        let employment = this.state.employment;

        // Save custom fields
        if (this.state.sections[6]?.customFields?.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    customFields: {
                        ...prevState.leaseApplication.customFields,
                        pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                            ...prevState.leaseApplication.customFields.pageCustomFields,
                            Employment: this.getCustomFieldsData(6)
                        } : {
                            Employment: this.getCustomFieldsData(6)
                        }
                    }
                }
            }))
        }

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                employment: employment
            },
            validationList: [],
        }), () =>{
            this.hideCompletedSections();
            this.saveProgress();
        });
    }

    /**
     * Save the references portion of the lease application flow.
     *
     * @param event - The event container.
     */
    saveReferences(event) {

        event.preventDefault();

        let referenceList = this.state.referenceList;

        if(this.state.showReferenceForm) {

            if(this.state.editReference) {
                referenceList.forEach((reference, key) => {
                    if(reference.id === this.state.reference.id) {
                        reference = this.state.reference;
                    }
                });
            }

            if(!this.state.editReference) {
                referenceList.push(this.state.reference);
            }

            this.setState(prevState => ({
                ...prevState,
                showReferenceForm: false,
                editReference: false,
                referenceList: referenceList,
                validationList: [],
            }));
        }

        else {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    referenceList: referenceList
                },
                referencesCompleted: true,
                validationList: [],
            }), () => {
                // Save custom fields
                if (this.state.sections[7]?.customFields?.length > 0) {
                    this.setState(prevState => ({
                        ...prevState,
                        leaseApplication: {
                            ...prevState.leaseApplication,
                            customFields: {
                                ...prevState.leaseApplication.customFields,
                                pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                                    ...prevState.leaseApplication.customFields.pageCustomFields,
                                    References: this.getCustomFieldsData(7, referenceList)
                                } : {
                                    References: this.getCustomFieldsData(7, referenceList)
                                }
                            }
                        }
                    }), () => {
                        this.hideCompletedSections();
                        this.saveProgress();
                    })
                } else {
                    this.hideCompletedSections();
                    this.saveProgress();
                }
            });            
        }
    }

    /**
     * Save the attachments of the lease application flow.
     *
     * @param event - The event container.
     */
     saveAttachments(event) {

        event.preventDefault();
        if((this.state.document['photoId'] && this.state.document['photoId'].get('file').size > 5242880) || 
            (this.state.document['payStubs'] && this.state.document['payStubs'].get('file').size > 5242880) || 
            (this.state.document['taxReturns'] && this.state.document['taxReturns'].get('file').size > 5242880)) {
                
            this.setState(prevState => ({
                ...prevState,
                validationListAttachment: [{
                    alert: {
                        type: 'danger',
                        code: 'danger.application.file.size.invalid',
                        message: 'File size cannot be greater than 5 MB.'
                    }
                }]
            }));

            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    validationListAttachment: []
                }));
            }, 10000); 

            return;
        }
        
        const acceptedFileTypes = "image/png,image/jpeg,application/pdf,text/plain,application/msword";

        if((this.state.document['photoId'] && !acceptedFileTypes.includes(this.state.document['photoId'].get('file').type)) || 
            (this.state.document['payStubs'] && !acceptedFileTypes.includes(this.state.document['payStubs'].get('file').type)) || 
            (this.state.document['taxReturns'] && !acceptedFileTypes.includes(this.state.document['taxReturns'].get('file').type))) {
                
            this.setState(prevState => ({
                ...prevState,
                validationListAttachment: [{
                    alert: {
                        type: 'danger',
                        code: 'danger.application.file.type.invalid',
                        message: 'Invalid File format! Accepted file types are PNG, JPEG, TXT, PDF, and DOC.'
                    }
                }]
            }));

            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    validationListAttachment: []
                }));
            }, 10000); 

            return;
        }

        let noAttachments = 0;
        if (this.state.document['photoId'] !== "")
            noAttachments++;
        if (this.state.document['payStubs'] !== "")
            noAttachments++;
        if (this.state.document['taxReturns'] !== "")
            noAttachments++;

        // Save custom fields
        if (this.state.sections[8]?.customFields?.length > 0) {
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    customFields: {
                        ...prevState.leaseApplication.customFields,
                        pageCustomFields: prevState.leaseApplication.customFields.pageCustomFields ? {
                            ...prevState.leaseApplication.customFields.pageCustomFields,
                            Attachments: this.getCustomFieldsData(8)
                        } : {
                            Attachments: this.getCustomFieldsData(8)
                        }
                    }
                }
            }))
        }
        this.setState(prevState => ({
            ...prevState,
            attachmentsCompleted: true,
            paymentMethodCompleted: false,
            validationList: [],
            noAttachments: noAttachments,
        }), () => {
            this.hideCompletedSections();
            this.saveProgress();
        });
    }

    /**
     * Remove the selected file
     */
    handleFileRemove(fileType) {

        const noAttachments = this.state.noAttachments;

        this.setState(prevState => ({
            ...prevState,
            noAttachments: noAttachments - 1,
            document: {
                ...prevState.document,
                [fileType]: "",
            }
        }));
    }

    /**
         * Save the selected payment method. Handle basic validation such as no payment method selected, selecting an
         * existing payment method, or creating a new payment method. After saving, calculate the transaction fees based on
         * the selected charges and payment method information.
         *
         * @param event - The event container.
         */
    savePaymentMethod(event) {
        if(event !== null) {
            event.preventDefault();
        }
    
        const sessionData = getSessionFromToken();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, {
            addressType: "BILLING",
            city: this.state.billingAddress.city,
            country: this.state.billingAddress.country,
            postalCode: this.state.billingAddress.postalCode,
            province: this.state.billingAddress.province,
            street1: this.state.billingAddress.street1,
            suite: this.state.billingAddress.suite,
            type: "TYPE_ADDRESS",
            userId: sessionData.user.id,
            userType: "TYPE_CUSTOMER",
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
                const creditCard = CreditCardRegexArray
                    .find((creditCard) => creditCard.regex.test(this.state.paymentMethod.cardNumber));
                let cardBrand = '';
                if (creditCard) {
                    cardBrand = creditCard.brand;
                }
                this.setState(prevState => ({
                    ...prevState,
                    addressCreated: response.data.id,
                }));
                const headers = this.generateRequestHeaders();
                const sessionId = window.ka?.sessionId;
                headers.blueSnapSessionId = sessionId;
                axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/addPaymentMethod`, {
                    paymentMethod:{
                        billingAddressId: response.data.id,
                        brand: cardBrand,
                        cardNumber: this.state.paymentMethod.cardNumber,
                        country: this.state.billingAddress.country,
                        cvv: this.state.paymentMethod.securityCode,
                        expiryMonth: this.state.paymentMethod.expiryMonth,
                        expiryYear: this.state.paymentMethod.expiryYear,
                        nameOnCard: this.state.paymentMethod.nameOnCard,
                        securityCode: this.state.paymentMethod.securityCode,
                        type: "TYPE_CREDIT_CARD",
                        userId: sessionData.user.id,
                        userType: "TYPE_CUSTOMER"
                      },
                      companyId: this.state.company.id,
                      currentRecordId: response.data.currentRecordId,
                },{
                    headers: headers
                }).then(response => {
                    this.getUserPaymentMethods(sessionData?.user.id);
                    this.hideNewCreditCardSection();
                    this.stopSpinner();      
                }).catch(error => {
                    this.deleteBillingAddress(this.state.addressCreated);
                    this.showFailCCAuthorization(error);
                    this.stopSpinner();
                });
        }).catch(error => {
            this.showFailCCAuthorization(error);
            this.stopSpinner();
        });

    }

    /**
     * Create the payment method
     *
     */
     createPaymentMethod() {

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];
        const sessionData = getSessionFromToken();
        

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        saveListData.push(billingAddress);

        // Add the paymentMethod state to the save list queue
        saveListData.push(paymentMethod);

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data[1],
            }));

            this.getUserPaymentMethods(sessionData?.user.id);
            this.hideNewCreditCardSection();
            this.stopSpinner();

        }).catch(error => {
            
            this.handleValidation(error);
            this.stopSpinner();

        });
    }

    /**
     * Create a new instance of a payment method, with the object dependant upon which payment method type the user
     * selects.
     *
     * @param paymentType - The type of payment method selected.
     */
     initPaymentMethod(paymentType) {
        const headers = this.generateRequestHeaders();
        const sessionId = window.ka?.sessionId;
        if(paymentType === 'credit_card' && sessionId) {
            headers.blueSnapSessionId = sessionId;
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: headers
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                },
            }));

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
    }

    
    /**
     * Calculate the expected transaction fee
     *
     * 
     */
    calculateFee(){
        /*if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }*/
        
        /*const creditCard = CreditCardRegexArray
                    .find((creditCard) => creditCard.regex.test(this.state.paymentMethod.cardNumber));
        let cardBrand = '';
        if (creditCard) {
            cardBrand = creditCard.brand;
        }*/
       
        this.setState(prevState => ({
            paymentMethod: {
                ...prevState.paymentMethod,
                //brand: cardBrand,
            },
        }), () => {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/calculatefee`, {
                amount: this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0,
                companyId:  this.props.leaseApplicationPage?.companyId,
                paymentMethod: this.state.paymentMethod,
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                
                this.setState(prevState => ({
                    ...prevState,
                    //paymentMethodSelected: true,
                    showPaymentSummarySection: true,
                    processingFee: response.data,
                    totalToPay: Number(response.data) + Number(this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0),
                    reCaptchaId: null,
                    applicationFeeCompleted: true,
                }));

                if(!this.state.incomeVerificationEnabled && !this.state.identityVerificationEnabled){
                    this.setState(prevState => ({
                        ...prevState,
                        progress:100,
                        //showSubmitApplicationButton: true,
                    }));
                }
                
            }).catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });
        })    
    }

    /**
     * Confirm and process the payment.
     */
    submitPayment() {
        this.setState(prevState => ({
                ...prevState,
                paymentMethodCompleted:true,
                showSubmitApplicationButton:true,
                progress: 100,
                validationList: [],
        }));
    }

    /**
     * Edit the terms of use portion of the lease application flow.
     */
    editTerms() {

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: false,
        }));
    }

    /**
     * Edit the property portion of the lease application flow.
     */
    editProperty() {

        this.setState(prevState => ({
            ...prevState,
            propertyCompleted: false,
            savedProperty: false,
        }));
    }

    /**
     * Edit the customer portion of the lease application flow.
     */
    editCustomer() {

        this.setState(prevState => ({
            ...prevState,
            customerCompleted: false,
            savedCustomer: false,
        }));
    }

    /**
     * Edit the emergency contact portion of the lease application flow.
     */
     editEmergency() {

        this.setState(prevState => ({
            ...prevState,
            emergencyCompleted: false,
        }));
    }

    /**
     * Edit the address history portion of the lease application flow.
     */
    editAddresses() {

        this.setState(prevState => ({
            ...prevState,
            addressesCompleted: false,
        }));
    }

    /**
     * Edit the additional occupants portion of the lease application flow.
     */
    editOccupants() {

        this.setState(prevState => ({
            ...prevState,
            occupantsCompleted: false,
        }));
    }

    /**
     * Edit the employment information portion of the lease application flow.
     */
    editEmployment() {

        this.setState(prevState => ({
            ...prevState,
            employmentCompleted: false,
        }));
    }

    /**
     * Edit the references portion of the lease application flow.
     */
    editReferences() {

        this.setState(prevState => ({
            ...prevState,
            referencesCompleted: false,
        }));
    }

    /**
         * Edit the attachments portion of the lease application flow.
         */
    editAttachments() {

        this.setState(prevState => ({
            ...prevState,
            attachmentsCompleted:false,
        }));
    }

    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodSelected: false,
            paymentMethodCompleted: false,
            showPaymentSummarySection: false,
            progress: 95,
        }));
    }


    /**
     * Handle changing the selected property for the lease application.
     *
     * @param selectedProperty - The selected property.
     */
    handleChangeProperty(selectedProperty) {

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                property: {
                    value: selectedProperty.value,
                    label: selectedProperty.label
                },
                propertyId: selectedProperty.value
            }
        }));
    }

    /**
     * Initialize a new instance of an address object.
     */
    initAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showAddressForm: true,
                editAddress: false,
                address: {
                    ...response.data,
                    addressType: prevState.addressList.length === 0 ? 'RESIDENTIAL' : 'PREVIOUS',
                    customFields:{
                        monthlyRent: 0
                    }
                },
                leaseApplication: {
                    ...prevState.leaseApplication,
                    monthlyRent: 0,
                    buildingManagerLandlordName: null,
                    buildingManagerLandlordPhone: null,
                    buildingManagerLandlordEmail: null,
                    comments: null,
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
    }

    /**
     * Edit an existing address from the list of addresses.
     *
     * @param address - The address to edit.
     */
    editAddress(address) {

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: true,
            editAddress: true,
            address: address
        }));
    }

    /**
     * Delete an existing address from the list of addresses.
     *
     * @param address - The address object to delete.
     */
    deleteAddress(address) {

        let addressList = this.state.addressList;

        addressList.forEach((data, key) => {
            if(data.id === address.id) {
                addressList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showAddressForm: false,
            editAddress: false,
            address: {},
            addressList: addressList,
        }));
    }

    /**
     * Handles closing of the address form
     */
    closeAddressForm() {
        this.setState(prevState => ({
            ...prevState,
            showAddressForm: false,
            address: {}
        }));
    }

    /**
     * Initialize a new instance of an additional occupant.
     */
    initOccupant() {

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: true,
            editOccupant: false,
            occupant: {
                id: prevState.occupantList.length,
                firstName: '',
                lastName: '',
                age: '',
            }
        }));
    }

    /**
     * handles closing of the occupant form
     */
    closeOccupant() {

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: false,
            occupant: {}
        }));
    }

    /**
     * Edit an existing occupant from the list of additional occupants.
     *
     * @param occupant - The occupant to edit.
     */
    editOccupant(occupant) {

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: true,
            editOccupant: true,
            occupant: occupant
        }));
    }

    /**
     * Delete an existing occupant from the list of additional occupants.
     *
     * @param occupant - The occupant to delete.
     */
    deleteOccupant(occupant) {

        let occupantList = this.state.occupantList;

        occupantList.forEach((data, key) => {
            if(data.id === occupant.id) {
                occupantList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showOccupantForm: false,
            editOccupant: false,
            occupant: {},
            occupantList: occupantList,
        }));
    }

    /**
     * Initialize a new instance of a reference.
     */
    initReference() {

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: true,
            editReference: false,
            reference: {
                id: prevState.referenceList.length,
                firstName: '',
                lastName: '',
                phone: '',
                relationship: '',
            }
        }));
    }

    /**
     * Edit a reference from the list of references.
     *
     * @param reference - The reference to edit.
     */
    editReference(reference) {

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: true,
            editReference: true,
            reference: reference
        }));
    }

    /**
     * Delete an existing reference from the list of references.
     *
     * @param reference - The reference to delete.
     */
    deleteReference(reference) {

        let referenceList = this.state.referenceList;

        referenceList.forEach((data, key) => {
            if(data.id === reference.id) {
                referenceList.splice(key, 1);
            }
        });

        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: false,
            editReference: false,
            reference: {},
            referenceList: referenceList,
        }));
    }
    
    /**
     * handles closing of the reference form
     */
    closeReferenceForm() {
        this.setState(prevState => ({
            ...prevState,
            showReferenceForm: false,
            reference: {},
        }));  
    }

     /**
     * Build object with custom fields for submit
     *      
     * 
     */
    //   buildCustomFieldsObject() {
    //     let customFieldObject = {
    //         pageCustomFields:{}
    //     };
    //     let nameState,sectionName;
        
       
    //     (this.state.sections).forEach(section => {
    //         if(section.customFields !== null){
    //             sectionName = section.sectionName === "T&Cs" ? 'TCs': section.sectionName;
    //             if((section.customFields).length > 0){
    //                 customFieldObject.pageCustomFields[sectionName] = {};
    //                 (section.customFields).forEach(customField => {
    //                     nameState = customField.label.replaceAll(" ", "");
    //                     if(customField.type === 'TYPE_CHECKBOX_FIELD'){
    //                         customFieldObject.pageCustomFields[sectionName][nameState] = this.state[nameState] || false;
    //                     }else{
    //                         customFieldObject.pageCustomFields[sectionName][nameState] = this.state[nameState] || null;
    //                     }
                        
    //                 });
    //             }
    //         }
    //     });
       
    //     return customFieldObject;

    // }

    /**
     * Submit the lease application data. Redirect the user to the lease application post-submission page, passing along
     * the lease application page, company, and lease application data in the state prop.
     */
    submitLeaseApplication() {
        const data = new FormData();
        const sessionData = getSessionFromToken()

        // let customFieldObject = this.buildCustomFieldsObject();

        this.setState(prevState => ({
            ...prevState,
            leaseApplication: {
                ...prevState.leaseApplication,
                // customFields: customFieldObject,
                userId: sessionData.user.id,
            }
        }), () => {
            data.append('leaseApplicationJson', JSON.stringify(this.state.leaseApplication));
            if(this.state.document.photoId !== ""){
                data.append('filePhotoId', this.state.document.photoId.get('file'));
            }
            if(this.state.document.payStubs !== ""){
                data.append('filePayStubs', this.state.document.payStubs.get('file'));
            }
            if(this.state.document.taxReturns !== ""){
                data.append('fileTaxReturns', this.state.document.taxReturns.get('file'));
            }
            
            

            this.setState(prevState => ({
                ...prevState,
                spinner: true
            })); 

            axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/submit`, data,    
            {
                headers: this.generateRequestHeadersForMultipart(),
            }).then(response => {
                
                        this.setState(prevState => ({
                            ...prevState,
                            spinner: false
                        }));
            
                        this.props.history.push({
                            pathname: '/apply/submitted',
                            state: {
                                leaseApplicationPage: this.state.leaseApplicationPage ? this.state.leaseApplicationPage : null,
                                company: this.state.company,
                                leaseApplication: this.state.leaseApplication
                            }
                        });
                        localStorage.removeItem('customerId')
                        localStorage.removeItem('propertyId')
                  
            }).catch(error => {

                this.handleValidation(error);

                this.setState(prevState => ({
                    ...prevState,
                    reCaptchaId: null
                }));
                window.scrollTo(0, 0);

            });

        });
        
        
    }

    /**
     * Confirm and submit the final payment.
     */
     finalPayment() {

        const charges = [{
            "code": "fee",
            "name": "Fee",
            "amount": this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0,
        }];

        const params = {
            billingAddress: this.state.billingAddress,
            email: this.state.leaseApplication.email,
            firstName: this.state.leaseApplication.firstName,
            hostedLeaseApplicationId: this.props.leaseApplication?.id,
            lastName: this.state.leaseApplication.lastName,
            paymentMethod: this.state.paymentMethod,
            charges: charges,
        }

        // Check if the property has been selected
        if(this.state.leaseApplication.property) {
            params.paymentForProperty = this.state.leaseApplication.property.value;
            params.propertyId = this.state.leaseApplication.property.value;
        }

        // Check if the unit has been selected
        if(this.state.leaseApplication.unit) {
            params.paymentForUnit = this.state.leaseApplication.unit;
        }

        const headers = this.generateRequestHeaders();

        if(this.state.reCaptchaId) {
            params.recaptchaResponse = this.state.reCaptchaId;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/hostedleaseapplicationpayment`, params, {
            headers: headers
        }).then(response => {
            
            if(response.data.createDate === null){
                response.data.createDate = moment();
            }
             
            this.setState(prevState => ({
                ...prevState,
                paymentTransaction: response.data,
                spinner: false
            }));

            //$('#receipt-payment-transaction').modal('show');

            this.props.history.push({
                pathname: '/apply/submitted',
                state: {
                    leaseApplicationPage: this.state.leaseApplicationPage ? this.state.leaseApplicationPage : null,
                    company: this.state.company,
                    leaseApplication: this.state.leaseApplication
                }
            });

            

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Create a custom field for the section according with the especifications contained in state sections
     * 
     * @param {*} section 
     */
    createCustomFields(section, index = false) {
        if(this.state.sections[section].customFields.length > 0){
            let customFields =  this.state.sections[section].customFields;
            return customFields.map(field => this.createField(field, index));
        }
    }
    
    /**
     * Create a custom field
     * 
     * @param {*} section 
     */
     createField(properties, index) {
        switch(properties.type) {
           case 'TYPE_DROPDOWN_FIELD':
               return this.createDropdownField(properties.label, properties.value, index)
           case 'TYPE_TEXT_FIELD':
               return  this.createTextField(properties.label, properties.length, index)
           case 'TYPE_DATE_FIELD':
               return this.createDateField(properties.label, index)
           case 'TYPE_CHECKBOX_FIELD':
               return this.createCheckboxField(properties.label, index)
             
           default:
             
        }
   }

  /**
     * Create a Checkbox Field
     * 
     * @param {*} label 
     */
       createCheckboxField(label, index){
        let  id =  label.replace(/\s+/g, '');
        if(index !== false)
            id = id + index 
    
        return (
            <FieldCheckbox id={id} fieldLabel={label} parent={this} value={this.state[id]}/>
        )
    }

   /**
     * Create a Custom Date Field
     * 
     * @param {*} label 
     */
    createDateField(label, index){
        let  id =  label.replace(/\s+/g, '');
        if(index !== false)
            id = id + index 
    
        return (
            <FieldDate id={id} label={label} parent={this} value={this.state[id]} />
        )
    }

   /**
     * Create a Custom Text Field
     * 
     * @param {*} label 
     * @param {*} length
     */
    createTextField(label, length, index){
        let  id =  label.replace(/\s+/g, '');
        if(index !== false)
            id = id + index 
    
        return (
            <FieldText id={id} label={label} parent={this} maxLength={length}  value={this.state[id]} />
        )
    }

    /**
     * Create a Custom DropDown Field
     * 
     * @param {*} label 
     * @param {*} value 
     */
    createDropdownField(label, value, index){
        let  id =  label.replace(/\s+/g, '');
        if(index !== false)
            id = id + index
        let values = value.split(',');
       
        return (
            <FieldSelect id={id} label={label} parent={this} value={this.state[id]} >
                <option value="" disabled>Select a value...</option>
                {values.map((value) => {
                  return <option>{value}</option>;
                })}                              
            </FieldSelect>
        )
    }

    /**
     * Open Terms Modal
     * 
     *
     */
     openTerms(e){
        e.preventDefault();

        return false;
    }

    /**
     * Open Privacy Consent Modal
     * 
     *
     */
     openPrivacyConsent(e){
        e.preventDefault();

        return false;
    }

     /**
     * Handles onReCaptchaChange
     */
      onReCaptchaChange(value) {
        this.setState((prevState) => ({
            ...prevState,
            reCaptchaId: value,
        }));
    }

    /**
     * Determines whether recaptcha is enabled or disabled for env. Only should be disabled for QA automation testing
     */
     getReCaptchaSetting(){

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recaptcha_enabled`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data !== null){
                this.setState(prevState => ({
                    ...prevState,
                    recaptchaEnabled: response.data
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });

    }

    /**
     * Add event listener for Rentify's iframe
     *
     * 
     */
     listenRentifyIframe() {
        window.top.addEventListener('message', e => {
            let iFrame = document.getElementById('rentifyIframe');
            e.data.origin = e.origin
           
            if(iFrame !== null){
                iFrame.contentWindow.postMessage(e.data,"https://sandbox.trustrentify.com/")
                
                if(e?.data?.detail === 'identity-verification-complete'){
                        this.setState(prevState => ({
                            ...prevState,
                            showRentifyIframe: false,
                            showIncomeIdentityVerification: true,
                        }));
                        this.removeListenerRentify();
                        this.goUnderReviewSection();
                    //this.authorizePayment();    
                }
            }    
        })
    }

    /**
     * Tenants should be redirected to the message regarding the lease application is currently under review
     *
     * 
     */
     goUnderReviewSection(){
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        this.submitLeaseApplication();

        this.props.history.push({
            pathname: '/apply/submittedApplication',
            state: {
                leaseApplicationPage: this.state.leaseApplicationPage ? this.state.leaseApplicationPage : null,
                company: this.state.company,
                leaseApplication: this.state.leaseApplication
            }
        });
        localStorage.removeItem('customerId')
        localStorage.removeItem('propertyId')
        this.removeListenerRentify();
    }

    /**
     * Remove event listener for Rentify's iframe
     *
     * 
     */
     removeListenerRentify(){
        window.top.removeEventListener('message', e => {})
    }

    preAuthorizePayment(){
        if(!this.state.incomeVerificationEnabled && !this.state.identityVerificationEnabled) {
            $("#payment-submit-info-modal").modal("show");
        } else  {
            this.calculateFee();
        }

        this.authorizePayment();
    }

     /**
     * Process and Authorize Payment 
     *      
     * 
     */
    authorizePayment() {
        
        axios.post(`${constants.REACT_APP_HOST_API_URL}/authorizepayment`, {
            paymentMethodType: "TYPE_CREDIT_CARD",
            paymentMethodId: this.state.paymentCard,
            leaseApplicationId: this.state.leaseApplication.id,
            charges: [{
                code: "applicationFee",
                name: "application-fee",
                amount: this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0,
            }]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(!this.state.incomeVerificationEnabled && !this.state.identityVerificationEnabled){
                this.setState(prevState => ({
                    ...prevState,
                    progress:100,
                    showSubmitApplicationButton: true,
                    sentAuthorizePayment: true,
                }));
            }else{
                this.setState(prevState => ({
                    ...prevState,
                    showIIVerificationSection: true,
                    sentAuthorizePayment: true,
                }));
            }
        }).catch(error => {
            //this.handleValidation(error);
            //window.scrollTo(0, 0);
            this.showFailCCAuthorization(error);
        });
    }

    /**
     * Delete the billing addres created if any error creating the credit card
     *      
     * 
     */
     deleteBillingAddress(addressId) {
        axios.delete(`${constants.REACT_APP_HOST_API_URL}/ADDRESS/${addressId}/delete`, {
            headers: this.generateRequestHeaders(),
        })
            .then(response => {
                
            })
            .catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });
    }

     /**
     * Open rentify page
     *      
     * 
     */
    openRentify() {
        $("#rentify-info-modal").modal("show");
    }

    /**
     * Get rentify Iframe page
     *      
     * 
     */
     getRentifyIframe() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/incomeverification/rentify/getIFrame`, {
            email: this.state.leaseApplication.email,
            sendInvite: "0",
            leaseApplicationId: this.state.leaseApplication.id
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data !== null){
                this.setState(prevState => ({
                    ...prevState,
                    rentifyURL: response.data.verificationSource + '?isIframe=1',
                    showRentifyIframe: true,
                }), () =>{
                    this.listenRentifyIframe();
                });
            }
        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
      }



    /**
     * Handles property saved data
     * 
     * @param {*} propertyId 
     */
    formatPropertyLabel(propertyId) {
        const {formatMessage} = this.props.intl;
        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.leaseApplication?.companyId}/properties`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: []
        }, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            const data = response.data.records.find(property => property.id === propertyId)
            this.setState(prevState => ({
                ...prevState,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    property: {
                        value: propertyId,
                        label: (data?.propertyName?`${data?.propertyName} - `:'') + (data?.street1) + (data?.street2 ? ', ' + data?.street2 : '') + ', ' + (data?.city) + ', ' + (data?.country === 'CA' ? formatMessage({id: "province." + data?.province}) : formatMessage({id: "state." + data?.province})) + ', ' + (formatMessage({id: "country." + data?.country})) + ' ' + (data?.postalCode)
                    },
                }
            }))
        }).catch(error => {
            this.handleValidation(error);
        });        
    }

    /**
     * Move to the last saved section
     * 
     */
    hideCompletedSections() {
        const { leaseApplication } = this.state;
        let termsCompleted = false;
        let propertyCompleted = false;
        let customerCompleted = false;
        let emergencyCompleted = false;
        let addressesCompleted = false;
        let occupantsCompleted = false;
        let employmentCompleted = false;
        let referencesCompleted = false;
        let attachmentsCompleted = false;
        let paymentMethodCompleted = false; 
        let progress = 0;
        if(leaseApplication.acknowledgedTerms && this.state.clickedOnSaveTermsAndConditions) {
            termsCompleted = true;
            progress ++;
        }
        if(leaseApplication.propertyId && this.state.savedProperty) {
            propertyCompleted = true;
            progress ++;
        }
        if(leaseApplication.userId && this.state.savedCustomer) {
            customerCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                otherInfo: leaseApplication.otherInfo
            }))
        }
        if(leaseApplication.emergencyContact) {
            emergencyCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                emergencyContact: {
                    emergencyFirstName: leaseApplication.emergencyContact?.firstName,
                    emergencyMiddleName: leaseApplication.emergencyContact?.middleName,
                    emergencyLastName: leaseApplication.emergencyContact?.lastName,
                    emergencyPhone: leaseApplication.emergencyContact?.phone,
                    emergencyEmail: leaseApplication.emergencyContact?.email,
                    emergencyRelationship: leaseApplication.emergencyContact?.relationship
                }
            }))
        }
        if(leaseApplication.addresses?.[0]) {
            addressesCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                addressList: leaseApplication.addresses,
                leaseApplication: {
                    ...prevState.leaseApplication,
                    buildingManagerLandlordEmail: leaseApplication.addresses?.[0].customFields?.buildingManagerLandlordEmail,
                    buildingManagerLandlordName: leaseApplication.addresses?.[0].customFields?.buildingManagerLandlordName,
                    buildingManagerLandlordPhone: leaseApplication.addresses?.[0].customFields?.buildingManagerLandlordPhone,
                    comments: leaseApplication.addresses?.[0].customFields?.comments,
                    monthlyRent: leaseApplication.addresses?.[0].customFields?.monthlyRent,
                }
            }))
        }
        if(leaseApplication.otherOccupants?.[0]|| this.state.occupantsCompleted) {
            occupantsCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                occupantList: leaseApplication.otherOccupants,
            }))
        }
        if(leaseApplication.employment?.employment || leaseApplication.employment !== null || this.state.employmentCompleted) {
            employmentCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                employment: leaseApplication.employment,
            }))
        }
        if(leaseApplication.referenceList?.[0] || this.state.referencesCompleted) {
            referencesCompleted = true;
            progress ++;
            this.setState(prevState => ({
                ...prevState,
                referenceList: leaseApplication.referenceList,
            }))
        }

        if(leaseApplication.joins?.d || this.state.attachmentsCompleted) {
            progress ++;
            attachmentsCompleted = true;
            paymentMethodCompleted = false;
        }

        this.setState(prevState => ({
            ...prevState,
            progress: this.state.totalSections ? Number(((progress * 100) / this.state.totalSections).toFixed(0)) : 0,
            termsCompleted,
            propertyCompleted,
            customerCompleted,
            emergencyCompleted,
            addressesCompleted,
            occupantsCompleted,
            employmentCompleted,
            referencesCompleted,
            attachmentsCompleted,
            paymentMethodCompleted,
        }));
    }

    /**
     * Update form with custom fields saved data
     *
     */
    updateCustomFields() {
        const { customFields } = this.state.leaseApplication;
        if(customFields.pageCustomFields.TCs) {
            const keys = Object.keys(customFields.pageCustomFields.TCs);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.TCs[key]
                }));
            });

        }
        if(customFields.pageCustomFields.Property) {
            const keys = Object.keys(customFields.pageCustomFields.Property);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Property[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Applicant) {
            const keys = Object.keys(customFields.pageCustomFields.Applicant);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Applicant[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Emergency) {
            const keys = Object.keys(customFields.pageCustomFields.Emergency);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Emergency[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Addresses) {
            const keys = Object.keys(customFields.pageCustomFields.Addresses);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Addresses[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Occupants) {
            const keys = Object.keys(customFields.pageCustomFields.Occupants);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Occupants[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Employment) {
            const keys = Object.keys(customFields.pageCustomFields.Employment);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Employment[key]
                }));
            });
        }
        if(customFields.pageCustomFields.References) {
            const keys = Object.keys(customFields.pageCustomFields.References);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.References[key]
                }));
            });
        }
        if(customFields.pageCustomFields.Attachments) {
            const keys = Object.keys(customFields.pageCustomFields.Attachments);
            keys.forEach(key => {
                this.setState(prevState => ({
                    ...prevState,
                    [key]: customFields.pageCustomFields.Attachments[key]
                }));
            });
        } 
    }

    /**
     * Update form with saved data
     *
     */
    getSavedData() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.leaseApplication?.id
                }
            ],
            // joins: {
            //     d: {
            //         targetRecordType: 'TYPE_DOCUMENT',
            //         joinField: 'id',
            //         targetField: 'recordId',
            //         alias: 'd',
            //         returnFields: ['id', 'name', 'url']
            //     }
            // }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records[0]) {
                this.setState(prevState => ({
                    ...prevState,
                    leaseApplication: {
                        ...prevState.leaseApplication,
                        ...response.data.records[0],
                        acknowledgedTerms:true,
                        unit: response?.data?.records[0]?.unit ==="N/A" ? "" : response?.data?.records[0]?.unit,
                        firstName: response.data.records[0].firstName || prevState.leaseApplication.firstName,
                        lastName: response.data.records[0].lastName || prevState.leaseApplication.lastName,
                        email: response.data.records[0].email || prevState.leaseApplication.email
                    }
                }), () => {
                    this.getTotalSections();
                    if(this.state.leaseApplication.customFields?.pageCustomFields) {   
                        this.updateCustomFields();
                    }
                })

                this.formatPropertyLabel(response.data.records[0]?.propertyId);
            }
            else{
               this.setState(prevState => ({
                ...prevState,
                    leaseApplication: {
                        ...this.props.leaseApplication,
                        ...prevState.leaseApplication,
                        acknowledgedTerms:true,
                        unit: response?.data?.records[0]?.unit ==="N/A" ? "" : response?.data?.records[0]?.unit,
                    }
                }));     
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }   

    /**
     * Generate a request header for making API calls. Returns a JSON object containing the Content-Type, defaulted to
     * 'application/json', and Authorization parameters commonly used when making calls.
     *
     * @returns - A basic request header containing the Content-Type and Authorization params.
     */
     generateRequestHeadersForMultipart() {
        return {
            'Content-Type': 'multipart/form-data',
            'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
        };
    }
    
    /**
     * Show error popup for fail Credit Card authorization prevents tenants from moving forward.
     *
     */
    showFailCCAuthorization(error) {
        $("#error-authorization-modal").modal("show");
        this.setState(prevState => ({
            ...prevState,
            authorizationError: `${error.response.data.errorCode} - ${error.response.data.message}`,
        }))
    }

    shouldRenderApplicantSection() {
        return (this.state.propertyCompleted || this.state.leaseApplication?.phone)
    }

    shouldRenderEmergencySection() {
        if(!this.state.showEmergencySection)
            return false
        return (this.state.customerCompleted || this.state.emergencyCompleted || this.state.emergencyContact?.firstName)
    }

    shouldRenderAddressSection() {
        if(this.state.leaseApplication.existingTenant)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted || this.state.addressesCompleted || this.state.leaseApplication.addresses?.[0])
    }

    shouldRenderOccupantSection() {
        if(this.state.leaseApplication.existingTenant || !this.state.showOccupantsSection)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted || this.state.occupantsCompleted || this.state.leaseApplication.otherOccupants?.[0])
    }

    shouldRenderEmploymentSection() {
        if(this.state.leaseApplication.existingTenant || !this.state.showEmployementSection)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderOccupantSection())
            lastSectionCompleted = this.state.occupantsCompleted
        else if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted || this.state.employmentCompleted || this.state.leaseApplication.employment?.employment)
    }

    shouldRenderReferencesSection() {
        if(this.state.leaseApplication.existingTenant || !this.state.showReferencesSection)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderEmploymentSection())
            lastSectionCompleted = this.state.employmentCompleted
        else if(this.shouldRenderOccupantSection())
            lastSectionCompleted = this.state.occupantsCompleted
        else if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted || this.state.referencesCompleted || this.state.leaseApplication.referenceList?.[0])
    }

    shouldRenderAttachmentsSection() {
        if(!this.state.showAttachmentsSection)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderReferencesSection())
            lastSectionCompleted = this.state.referencesCompleted
        else if(this.shouldRenderEmploymentSection())
            lastSectionCompleted = this.state.employmentCompleted
        else if(this.shouldRenderOccupantSection())
            lastSectionCompleted = this.state.occupantsCompleted
        else if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted || this.state.attachmentsCompleted)
    }

    shouldRenderFeeSection() {
        if(!this.props.leaseApplicationPage?.customFields?.applicationFeeAmount > 0)
            return false
        let lastSectionCompleted = false
        if(this.shouldRenderAttachmentsSection())
            lastSectionCompleted = this.state.attachmentsCompleted
        else if(this.shouldRenderReferencesSection())
            lastSectionCompleted = this.state.referencesCompleted
        else if(this.shouldRenderEmploymentSection())
            lastSectionCompleted = this.state.employmentCompleted
        else if(this.shouldRenderOccupantSection())
            lastSectionCompleted = this.state.occupantsCompleted
        else if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted)
    }

    shouldRenderRentifySection() {
        let lastSectionCompleted = false
        if(this.shouldRenderFeeSection())
            lastSectionCompleted = this.state.applicationFeeCompleted
        else if(this.shouldRenderAttachmentsSection())
            lastSectionCompleted = this.state.attachmentsCompleted
        else if(this.shouldRenderReferencesSection())
            lastSectionCompleted = this.state.referencesCompleted
        else if(this.shouldRenderEmploymentSection())
            lastSectionCompleted = this.state.employmentCompleted
        else if(this.shouldRenderOccupantSection())
            lastSectionCompleted = this.state.occupantsCompleted
        else if(this.shouldRenderAddressSection())
            lastSectionCompleted = this.state.addressesCompleted
        else if(this.shouldRenderEmergencySection())
            lastSectionCompleted = this.state.emergencyCompleted
        else
            lastSectionCompleted = this.state.customerCompleted
        return (lastSectionCompleted)
    }

    shouldShowSubmitButton() {
        if (this.props.leaseApplicationPage?.customFields?.applicationFeeAmount)
            return this.state.progress === 100 && this.state.showSubmitApplicationButton && this.state.sentAuthorizePayment
        return this.state.progress === 100 && this.state.showSubmitApplicationButton
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.company.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeProfileList: response.data,
                feeProfileCountry: this.state.company.country
            }));

            $("#fees").modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle change to receive notification checkbox
     */
    handleReceiveTxtMessage(){
        this.setState(prevState =>({
            ...prevState,
            receiveTxtMessageBool: !prevState.receiveTxtMessageBool
        }));
    }

    /**
     * Render the component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="row kaxsdc" data-event='load'>
                    <div className="col-md-8">

                        <Alert validationList={this.state.validationList} />

                        { this.state.sections[0]?.defaultFields[0]?.value === 'true' &&
                        <div className="card">

                            <div className="card-header">
                                Terms and Policies
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Please review and agree to each statement regarding our terms and policies
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveTerms}>

                                    {this.state.termsCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editTerms()}>
                                                <div className="">
                                                    Letus Terms and Policies
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    I have read and agree to the Letus Terms of Use. <br></br> 
                                                </small>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    I have read, understood and voluntarily agree to the terms and conditions outlined in the Lease Application Privacy Consent. 
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.termsCompleted &&
                                    <React.Fragment>
                                        <FieldCheckbox 
                                        id="acknowledgedTerms" 
                                        fieldLabel="I have read and agree to the" 
                                        fieldLabelHyperlink="Letus Terms of Use" 
                                        handleClickHyperlink={this.openTerms} 
                                        modalId="terms-modal" 
                                        fieldColumns="12" 
                                        parent={this} 
                                        model="leaseApplication" 
                                        value={this.state.leaseApplication['acknowledgedTerms']} />

                                        <FieldCheckbox 
                                        id="acknowledgedPrivacyConsent" 
                                        fieldLabel="I have read, understood and voluntarily agree to the terms and conditions outlined in the" 
                                        fieldLabelHyperlink="Lease Application Privacy Consent"
                                         handleClickHyperlink={this.openPrivacyConsent} 
                                         modalId="privacy-consent-modal" 
                                         fieldColumns="12" parent={this} 
                                         model="leaseApplication" 
                                         value={this.state.leaseApplication['acknowledgedPrivacyConsent']} />

                                        { this.state.sections[0]?.customFields?.length >0 &&
                                            this.createCustomFields(0)
                                        }
                                        {this.state.leaseApplication.acknowledgedTerms && this.state.leaseApplication.acknowledgedPrivacyConsent &&
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Agree & Continue
                                        </button>
                                        }

                                        {(!this.state.leaseApplication.acknowledgedTerms || !this.state.leaseApplication.acknowledgedPrivacyConsent) &&
                                        <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="We need your consents to all of our terms and policies to complete this application.">
                                            Agree & Continue
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }


                        {(this.state.termsCompleted || this.state.acknowledgedTermsPrev !== null) &&
                        <div className="card">

                            <div className="card-header">
                                Property
                            </div>

                            <div className="card-body bg-secondary">

                                {this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Which property do you currently have an existing lease with?
                                </p>
                                }

                                {!this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Which property are you applying as a tenant for?
                                </p>
                                }

                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveProperty} autoComplete="off">

                                    {this.state.propertyCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editProperty()}>
                                                <div className="">
                                                    {this.state.leaseApplication?.unit ? this.state.leaseApplication?.unit + ' - ' : ''}{this.state.leaseApplication?.property?.label}
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    Managed by {this.state.company.name}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editProperty()}>
                                            Edit
                                        </div>

                                    </div>
                                    }
                                    {!this.state.propertyCompleted &&
                                    <React.Fragment>
                                        { this.state.sections[1]?.defaultFields[0]?.value === 'true' &&
                                            <FieldProperty id="propertyId" label="Property" model="leaseApplication" value={this.state.leaseApplication.property} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} required={true} leaseApplicationPage={true} />
                                        }
                                        { this.state.sections[1]?.defaultFields[1]?.value === 'true' &&
                                            <FieldText id="unit" label="Unit" model="leaseApplication" parent={this} value={this.state.leaseApplication['unit']} maxLength="30"/>
                                        }
                                        { this.state.sections[1]?.customFields?.length >0 &&
                                            this.createCustomFields(1)
                                        }
                                        
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>

                        </div>
                        }



                        {!this.state.customerCompleted &&
                            <Alert validationList={this.state.validationListApplicant} />}

                        {this.shouldRenderApplicantSection() &&
                        <div className="card">

                            <div className="card-header">
                                {this.state.leaseApplication.existingTenant ? 'Tenant' : 'Applicant'}
                            </div>

                            <div className="card-body bg-secondary">

                                {this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Who is the current lease holder?
                                </p>
                                }

                                {!this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Who is applying for this lease?
                                </p>
                                }

                            </div>

                            <div className="card-body">
                                {this.state.customerCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCustomer()}>
                                            <div className="">
                                                {this.state.leaseApplication.firstName} {this.state.leaseApplication.lastName}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.leaseApplication.email}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCustomer()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.customerCompleted &&
                                <form onSubmit={this.saveCustomer} autoComplete="off">
                                    { this.state.sections[2]?.defaultFields[0]?.value === 'true' &&
                                        <FieldText id="firstName" label="Legal First Name" model="leaseApplication" maxLength="100" parent={this} value={this.state.leaseApplication['firstName']} required={true} leaseApplicationPage={true}/>
                                    }

                                    { this.state.sections[2]?.defaultFields[1]?.value === 'true' &&
                                        <FieldText id="middleName" label="Legal Middle Name" model="leaseApplication" maxLength="100" parent={this} value={this.state.leaseApplication['middleName']} />
                                    }

                                    { this.state.sections[2]?.defaultFields[2]?.value === 'true' &&
                                        <FieldText id="lastName" label="Legal Last Name" model="leaseApplication" maxLength="100" parent={this} value={this.state.leaseApplication['lastName']} required={true} leaseApplicationPage={true}/>
                                    }

                                    { this.state.sections[2]?.defaultFields[3]?.value === 'true' &&
                                        <FieldText id="email" label="Email" model="leaseApplication" type="email" parent={this} value={this.state.leaseApplication['email']} required={true} leaseApplicationPage={true}/>
                                    }

                                    { this.state.sections[2]?.defaultFields[4]?.value === 'true' &&
                                        <FieldPhone id="phone" label="Mobile Phone" model="leaseApplication" parent={this} value={this.state.leaseApplication['phone']} required={this.state.receiveTxtMessageBool} leaseApplicationPage={true}/>
                                    }

                                    <div>
                                        <FieldCheckbox id="receiveNotification" label="" fieldLabel="By providing your phone number, you agree to receive text messages from Letus. Message & data rates may apply. Message frequency varies. Reply STOP to opt out, reply HELP for help." parent={this} value={this.state.receiveTxtMessageBool} handleChange={this.handleReceiveTxtMessage}/>
                                        <p className="text-black text-left small"> By selecting the checkbox you acknowledge you have read and agreed to our <a href='https://let.us/terms' target='_blank'>Terms of Use</a> and <a href='https://let.us/privacy' target='_blank'>Privacy Policy</a>.</p>
                                    </div>


                                    { this.state.sections[2]?.defaultFields[5]?.value === 'true' &&
                                        <FieldBirthDate id="birthDate" label="Date of Birth" model="leaseApplication" parent={this} value={this.state.leaseApplication['birthDate']} monthFirst={true} required={true} leaseApplicationPage={true}/>
                                    }
                                    {!this.state.leaseApplication.existingTenant &&
                                    <React.Fragment>
                                        { this.state.sections[2]?.defaultFields[6]?.value === 'true' &&
                                            <FieldText id="sin" label="SIN/SSN" model="leaseApplication" parent={this} value={this.state.leaseApplication['sin']} minLength="9" maxLength="9"/>
                                        }

                                        { this.state.sections[2]?.defaultFields[7]?.value === 'true' &&
                                            <FieldSelect id="maritalStatus" label="Marital Status" model="otherInfo" parent={this} value={this.state.otherInfo['maritalStatus']} >
                                                <option value="" disabled>Select a marital status...</option>
                                                <option value="MARRIED">Married</option>
                                                <option value="WIDOWED">Widowed</option>
                                                <option value="DIVORCED_OR_SEPARATED">Divorced or Separated</option>
                                                <option value="NEVER_MARRIED_OR_SINGLE">Never Married or Single</option>
                                            </FieldSelect>
                                        }    

                                        { this.state.sections[2]?.defaultFields[8]?.value === 'true' &&
                                            <FieldCheckbox id="pets" label="Pets" fieldLabel="I currently own one or more pets" model="otherInfo" parent={this} value={this.state.otherInfo['pets']}/>
                                        }
                                        
                                        { this.state.sections[2]?.defaultFields[9]?.value === 'true' &&
                                            <FieldCheckbox id="smoking" label="Smoking" fieldLabel="I currently smoke" model="otherInfo" parent={this} value={this.state.otherInfo['smoking']}/>
                                        }

                                        { this.state.sections[2]?.defaultFields[10]?.value === 'true' &&
                                            <FieldCheckbox id="bankruptcy" label="Have you ever filed for Bankruptcy?" fieldLabel="" model="otherInfo" parent={this} value={this.state.otherInfo['bankruptcy']}/>
                                        }

                                        { this.state.sections[2]?.customFields?.length >0 &&
                                            this.createCustomFields(2)
                                        }
                                    </React.Fragment>
                                    }
                                    
                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                        </div>
                        }

                        {this.shouldRenderEmergencySection() &&
                        <div className="card">

                            <div className="card-header">
                            Emergency Contact
                            </div>

                            <div className="card-body bg-secondary">

                                {!this.state.leaseApplication.existingTenant &&
                                <p className="mb-0">
                                    Who is your emergency contact?
                                </p>
                                }

                            </div>

                            <div className="card-body">
                                {this.state.emergencyCompleted &&
                                <div className="">

                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editEmergency()}>
                                            <div className="">
                                                {this.state.emergencyContact.emergencyFirstName} {this.state.emergencyContact.emergencyLastName}
                                            </div>
                                            <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.emergencyContact.emergencyEmail}
                                            </small>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editEmergency()}>
                                        Edit
                                    </div>

                                </div>
                                }

                                {!this.state.emergencyCompleted &&
                                <form onSubmit={this.saveEmergency} autoComplete="off">
                                    { this.state.sections[3]?.defaultFields[0]?.value === 'true' &&
                                        <FieldText id="emergencyFirstName" label="Legal First Name" model="emergencyContact" parent={this} value={this.state.emergencyContact['emergencyFirstName']} />
                                    }

                                    { this.state.sections[3]?.defaultFields[1]?.value === 'true' &&
                                        <FieldText id="emergencyMiddleName" label="Legal Middle Name" model="emergencyContact" parent={this} value={this.state.emergencyContact['emergencyMiddleName']} />
                                    }

                                    { this.state.sections[3]?.defaultFields[2]?.value === 'true' &&
                                        <FieldText id="emergencyLastName" label="Legal Last Name" model="emergencyContact" parent={this} value={this.state.emergencyContact['emergencyLastName']} />
                                    }

                                    { this.state.sections[3]?.defaultFields[3]?.value === 'true' &&
                                        <FieldText id="emergencyEmail" label="Email" model="emergencyContact" type="email" parent={this} value={this.state.emergencyContact['emergencyEmail']} />
                                    }

                                    { this.state.sections[3]?.defaultFields[4]?.value === 'true' &&
                                        <FieldPhone id="emergencyPhone" label="Mobile Phone" model="emergencyContact" parent={this} value={this.state.emergencyContact['emergencyPhone']} />
                                    }

                                    { this.state.sections[3]?.defaultFields[5]?.value === 'true' &&
                                        <FieldText id="emergencyRelationship" label="Relationship" model="emergencyContact" parent={this} value={this.state.emergencyContact['emergencyRelationship']} />
                                    }
                                    { this.state.sections[3]?.customFields?.length >0 &&
                                        <div className="mt-3">
                                            {this.createCustomFields(3)}
                                        </div>   
                                    }  

                                    
                                    <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                        Save & Continue
                                    </button>

                                </form>
                                }

                            </div>

                        </div>
                        }


                        {!this.state.addressesCompleted &&
                            <Alert validationList={this.state.validationListAddress} />}

                        {this.shouldRenderAddressSection() &&
                        <div className="card">
                            <div className="card-header">
                                Address History
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    What address(es) have you resided at in the last 3 years?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveAddresses}>

                                    {!this.state.addressesCompleted &&
                                    <React.Fragment>
                                        {this.state.addressList.map((address, key) => {
                                            return(
                                                <div key={key+address.id} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editAddress && address.id === this.state.address.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editAddress && address.id === this.state.address.id) ? null : this.editAddress(address)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {address.suite ? address.suite + ' - ' : ''}{address.street1 + (address.street2 ? ', ' + address.street2 : '') + ', ' + address.city + ', ' + (address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province})) + ', ' + formatMessage({id: "country." + address.country}) + ' ' + address.postalCode}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {address.addressType === 'RESIDENTIAL' ? `Current address for ${address.monthsAtAddress} month(s)` : `Previous address for ${address.monthsAtAddress} month(s)`}
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editAddress && address.id === this.state.address.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">
                                                                <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={true} defaultFields={this.state.sections[4]?.defaultFields} required={true} key={key+address.id} leaseApplicationPage={true}/>
                                                                { this.state.sections[4]?.defaultFields[8]?.value === 'true' &&
                                                                    <FieldText id="monthlyRent" label="Monthly Rent" model="leaseApplication" parent={this} value={this.state.leaseApplication['monthlyRent']} />
                                                                }
                                                                { this.state.sections[4]?.defaultFields[9]?.value === 'true' &&
                                                                    <FieldText id="buildingManagerLandlordName" label="Building Manager/Landlord Name" model="leaseApplication" parent={this} value={this.state.leaseApplication['buildingManagerLandlordName']} />
                                                                }
                                                                { this.state.sections[4]?.defaultFields[10]?.value === 'true' &&
                                                                    <FieldPhone id="buildingManagerLandlordPhone" label="Building Manager/Landlord Phone" model="leaseApplication" parent={this} value={this.state.leaseApplication['buildingManagerLandlordPhone']} />
                                                                }
                                                                { this.state.sections[4]?.defaultFields[11]?.value === 'true' &&
                                                                    <FieldText id="buildingManagerLandlordEmail" label="Building Manager/Landlord Email" model="leaseApplication" type="email" parent={this} value={this.state.leaseApplication['buildingManagerLandlordEmail']} />
                                                                }
                                                                { this.state.sections[4]?.defaultFields[12]?.value === 'true' &&
                                                                    <FieldTextarea id="comments" label="Comments" model="leaseApplication" parent={this} value={this.state.leaseApplication['comments']} />
                                                                }
                                                            </div>

                                                            { this.state.sections[4]?.customFields?.length >0 &&
                                                             <div className="mt-3">
                                                                {this.createCustomFields(4, key)}
                                                             </div>   
                                                            }   

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteAddress(address)}>
                                                                Delete Address
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Address
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initAddress()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add New Address
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.addressesCompleted && this.state.showAddressForm && !this.state.editAddress) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add New Address
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'window-close']} className="fa-fw" size="1x" onClick={() => this.closeAddressForm()} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                <FieldAddress model="address" parent={this} value={this.state.address} suite={true} monthsAtAddress={this.state.sections[4]?.defaultFields[7]?.value === 'true' ? true : false}  defaultFields={this.state.sections[4]?.defaultFields} required={true} leaseApplicationPage={true}/>
                                                { this.state.sections[4]?.defaultFields[8]?.value === 'true' &&
                                                    <FieldText id="monthlyRent" label="Monthly Rent" type="number" model="leaseApplication" parent={this} value={this.state.leaseApplication['monthlyRent']} />
                                                }
                                                { this.state.sections[4]?.defaultFields[9]?.value === 'true' &&
                                                    <FieldText id="buildingManagerLandlordName" label="Building Manager/Landlord Name" model="leaseApplication" parent={this} value={this.state.leaseApplication['buildingManagerLandlordName']} />
                                                }
                                                { this.state.sections[4]?.defaultFields[10]?.value === 'true' &&
                                                    <FieldPhone id="buildingManagerLandlordPhone" label="Building Manager/Landlord Phone" model="leaseApplication" parent={this} value={this.state.leaseApplication['buildingManagerLandlordPhone']} />
                                                }
                                                { this.state.sections[4]?.defaultFields[11]?.value === 'true' &&
                                                    <FieldText id="buildingManagerLandlordEmail" label="Building Manager/Landlord Email" model="leaseApplication" type="email" parent={this} value={this.state.leaseApplication['buildingManagerLandlordEmail']} />
                                                }
                                                { this.state.sections[4]?.defaultFields[12]?.value === 'true' &&
                                                    <FieldTextarea id="comments" label="Comments" model="leaseApplication" parent={this} value={this.state.leaseApplication['comments']} />
                                                }
                                                { this.state.sections[4]?.customFields?.length >0 &&
                                                <React.Fragment>
                                                    {this.createCustomFields(4, this.state.addressList.length)}
                                                </React.Fragment>
                                                }  
                                            </div>
                                            
                                            
                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Address
                                            </button>

                                        </div>
                                    </div>
                                    }

                                    {this.state.addressesCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editAddresses()}>
                                                <div className="">
                                                    {this.state.addressList.length} current and/or previous address(es)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAddresses()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.addressesCompleted && !this.state.showAddressForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                        {this.shouldRenderOccupantSection() &&
                        <div className="card">

                            <div className="card-header">
                                Additional Occupants
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Do you have any additional occupants currently residing with you?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveOccupants}>

                                    {!this.state.occupantsCompleted &&
                                    <React.Fragment>
                                        {this.state.occupantList.map((occupant, key) => {
                                            return(
                                                <div key={key} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editOccupant && occupant.id === this.state.occupant.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editOccupant && occupant.id === this.state.occupant.id) ? null : this.editOccupant(occupant)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {occupant.firstName} {occupant.lastName}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {occupant.age} years old
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editOccupant && occupant.id === this.state.occupant.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">
                                                                { this.state.sections[5]?.defaultFields[0]?.value === 'true' &&
                                                                <FieldText id="firstName" label="First Name" model="occupant" parent={this} value={this.state.occupant['firstName']}/>
                                                                }

                                                                { this.state.sections[5]?.defaultFields[1]?.value === 'true' &&
                                                                <FieldText id="lastName" label="Last Name" model="occupant" parent={this} value={this.state.occupant['lastName']} />
                                                                }

                                                                { this.state.sections[5]?.defaultFields[2]?.value === 'true' &&
                                                                <FieldText id="age" label="Age" model="occupant" parent={this} value={this.state.occupant['age']} />
                                                                }

                                                                { this.state.sections[5]?.defaultFields[3]?.value === 'true' &&
                                                                <FieldPhone id="phone" label="Phone" model="occupant" parent={this} value={this.state.occupant['phone']} />
                                                                }

                                                                { this.state.sections[5]?.defaultFields[4]?.value === 'true' &&
                                                                <FieldSelect id="employment" label="Employment Status" model="occupant" parent={this} value={this.state.occupant['employment']} >
                                                                    <option value="" disabled>Select an employment status...</option>
                                                                    <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                                                    <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                                                    <option value="UNEMPLOYED">Unemployed</option>
                                                                    <option value="RETIRED">Retired</option>
                                                                    <option value="STUDENT">Student</option>
                                                                </FieldSelect>
                                                                }

                                                                { this.state.sections[5]?.defaultFields[5]?.value === 'true' &&
                                                                <FieldText id="relationship" label="Relationship" model="occupant" parent={this} value={this.state.occupant['relationship']} />
                                                                }

                                                                { this.state.sections[5]?.customFields?.length >0 &&
                                                                    this.createCustomFields(5, key)
                                                                }  
                                                            </div>

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteOccupant(occupant)}>
                                                                Delete Occupant
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Occupant
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.occupantsCompleted && !this.state.showOccupantForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initOccupant()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Additional Occupant
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.occupantsCompleted && this.state.showOccupantForm && !this.state.editOccupant) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3 list-group-item-action c-pointer">
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Additional Occupant
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'window-close']} className="fa-fw" size="1x" onClick={() => this.closeOccupant()} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                { this.state.sections[5]?.defaultFields[0]?.value === 'true' &&
                                                <FieldText id="firstName" label="First Name" model="occupant" parent={this} value={this.state.occupant['firstName']} />
                                                }

                                                { this.state.sections[5]?.defaultFields[1]?.value === 'true' &&
                                                <FieldText id="lastName" label="Last Name" model="occupant" parent={this} value={this.state.occupant['lastName']} />
                                                }

                                                { this.state.sections[5]?.defaultFields[2]?.value === 'true' &&
                                                <FieldText id="age" label="Age" type="number" model="occupant" parent={this} value={this.state.occupant['age']} />
                                                }

                                                { this.state.sections[5]?.defaultFields[3]?.value === 'true' &&
                                                <FieldPhone id="phone" label="Phone" model="occupant" parent={this} value={this.state.occupant['phone']} />
                                                }

                                                { this.state.sections[5]?.defaultFields[4]?.value === 'true' &&
                                                <FieldSelect id="employment" label="Employment Status" model="occupant" parent={this} value={this.state.occupant['employment']}>
                                                    <option value="" disabled>Select an employment status...</option>
                                                    <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                                    <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                                    <option value="UNEMPLOYED">Unemployed</option>
                                                    <option value="RETIRED">Retired</option>
                                                    <option value="STUDENT">Student</option>
                                                </FieldSelect>
                                                }

                                                { this.state.sections[5]?.defaultFields[5]?.value === 'true' &&
                                                <FieldText id="relationship" label="Relationship" model="occupant" parent={this} value={this.state.occupant['relationship']} />
                                                }

                                                { this.state.sections[5]?.customFields?.length >0 &&
                                                    this.createCustomFields(5, this.state.occupantList.length) 
                                                }  
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Occupant
                                            </button>
                                        </div>
                                    </div>
                                    }

                                    {this.state.occupantsCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editOccupants()}>
                                                <div className="">
                                                    {this.state.occupantList.length} additional occupant(s)
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editOccupants()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.occupantsCompleted && !this.state.showOccupantForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }

                        {this.shouldRenderEmploymentSection() && 
                        <div className="card">

                            <div className="card-header">
                                Employment Information
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    What is your current employment information?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveEmployment} autoComplete="off">

                                    {this.state.employmentCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editEmployment()}>
                                                <div className="">
                                                    {this.state.employment?.employment ? (this.state.employment.employment).replaceAll('_', ' ') : ''} 
                                                </div>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                    {this.state.employment.employerName}
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editEmployment()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {!this.state.employmentCompleted &&
                                    <React.Fragment>
                                        { this.state.sections[6]?.defaultFields[0]?.value === 'true' &&
                                        <FieldSelect id="employment" label="Employment Status" model="employment" parent={this} value={this.state.employment['employment']} >
                                            <option value="" disabled>Select an employment status...</option>
                                            <option value="EMPLOYED_FULL_TIME">Employed Full-Time</option>
                                            <option value="EMPLOYED_PART_TIME">Employed Part-Time</option>
                                            <option value="UNEMPLOYED">Unemployed</option>
                                            <option value="RETIRED">Retired</option>
                                            <option value="STUDENT">Student</option>
                                        </FieldSelect>
                                        }

                                        {(this.state.employment.employment === 'EMPLOYED_FULL_TIME' || this.state.employment.employment === 'EMPLOYED_PART_TIME') &&
                                        <React.Fragment>
                                            { this.state.sections[6]?.defaultFields[1]?.value === 'true' &&
                                            <FieldText id="employerName" label="Employer Name" model="employment" parent={this} value={this.state.employment['employerName']} />
                                            }

                                            { this.state.sections[6]?.defaultFields[2]?.value === 'true' &&
                                            <FieldPhone id="phone" label="Employer Phone" model="employment" parent={this} value={this.state.employment['phone']}  />
                                            }

                                            { this.state.sections[6]?.defaultFields[3]?.value === 'true' &&
                                            <FieldText id="occupation" label="Occupation" model="employment" parent={this} value={this.state.employment['occupation']}  />
                                            }

                                            { this.state.sections[6]?.defaultFields[4]?.value === 'true' &&
                                            <FieldText id="monthsEmployed" label="Months Employed" type="number" model="employment" parent={this} value={this.state.employment['monthsEmployed']} />
                                            }

                                            { this.state.sections[6]?.defaultFields[5]?.value === 'true' &&
                                            <FieldText id="monthlySalary" label="Monthly Income" type="number" model="employment" parent={this} value={this.state.employment['monthlySalary']} />
                                            }
                                        </React.Fragment>
                                        }
                                        
                                        { this.state.sections[6]?.defaultFields[6]?.value === 'true' &&
                                        <FieldText id="otherIncome" label="Other Income" type="number" model="employment" parent={this} value={this.state.employment['otherIncome']} />
                                        }

                                        { this.state.sections[6]?.customFields?.length >0 &&
                                            this.createCustomFields(6) 
                                        }  
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>

                        </div>
                        }

                        {this.shouldRenderReferencesSection() &&
                        <div className="card">

                            <div className="card-header">
                                Personal References
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">
                                    Do you have any personal references from someone who knows you in a personal, financial, educational, professional, or prior rental experience?
                                </p>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.saveReferences}>

                                    {!this.state.referencesCompleted &&
                                    <React.Fragment>
                                        {this.state.referenceList.map((reference, key) => {
                                            return(
                                                <div key={key} className="list-group mb-2">
                                                    <div className={`list-group-item ${(this.state.editReference && reference.id === this.state.reference.id) ? '' : 'list-group-item-action c-pointer'}`} onClick={() => (this.state.editReference && reference.id === this.state.reference.id) ? null : this.editReference(reference)}>

                                                        <div className="row align-items-center">
                                                            <div className="col-10">
                                                                <div className="">
                                                                    {reference.firstName} {reference.lastName}
                                                                </div>
                                                                <small className="mb-0 ml-md-0 small text-muted">
                                                                    {reference.relationship}
                                                                </small>
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <FontAwesomeIcon icon={['far', 'pencil']} className="fa-fw" size="1x" />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {(this.state.editReference && reference.id === this.state.reference.id) &&
                                                        <React.Fragment>

                                                            <div className="mt-3">
                                                                { this.state.sections[7]?.defaultFields[0]?.value === 'true' &&
                                                                <FieldText id="firstName" label="First Name" model="reference" parent={this} value={this.state.reference['firstName']} required={true}/>
                                                                }
                                                                { this.state.sections[7]?.defaultFields[1]?.value === 'true' &&
                                                                <FieldText id="lastName" label="Last Name" model="reference" parent={this} value={this.state.reference['lastName']} required={true}/>
                                                                }
                                                                { this.state.sections[7]?.defaultFields[2]?.value === 'true' &&
                                                                <FieldPhone id="phone" label="Phone" model="reference" parent={this} value={this.state.reference['phone']} required={true}/>
                                                                }
                                                                { this.state.sections[7]?.defaultFields[3]?.value === 'true' &&
                                                                <FieldText id="relationship" label="Relationship" model="reference" parent={this} value={this.state.reference['relationship']} required={true}/>
                                                                }

                                                                { this.state.sections[7]?.customFields?.length >0 &&
                                                                    this.createCustomFields(7, key)
                                                                }  
                                                            </div>

                                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.deleteReference(reference)}>
                                                                Delete Reference
                                                            </div>

                                                            <button type="submit" className="btn btn-primary btn-md btn-block">
                                                                Save Reference
                                                            </button>

                                                        </React.Fragment>
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </React.Fragment>
                                    }

                                    {(!this.state.referencesCompleted && !this.state.showReferenceForm) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.initReference()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Reference
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'plus']} className="fa-fw" size="1x" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    }

                                    {(!this.state.referencesCompleted && this.state.showReferenceForm && !this.state.editReference) &&
                                    <div className="list-group mb-2">
                                        <div className="list-group-item pb-3">

                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    Add Reference
                                                </div>
                                                <div className="col text-right">
                                                    <div className="float-right">
                                                        <FontAwesomeIcon icon={['far', 'window-close']} className="fa-fw" size="1x" onClick={() => this.closeReferenceForm()} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-3">
                                                { this.state.sections[7]?.defaultFields[0]?.value === 'true' &&
                                                <FieldText id="firstName" label="First Name" model="reference" parent={this} value={this.state.reference['firstName']} />
                                                }
                                                { this.state.sections[7]?.defaultFields[1]?.value === 'true' &&
                                                <FieldText id="lastName" label="Last Name" model="reference" parent={this} value={this.state.reference['lastName']} />
                                                }
                                                { this.state.sections[7]?.defaultFields[2]?.value === 'true' &&
                                                <FieldPhone id="phone" label="Phone" model="reference" parent={this} value={this.state.reference['phone']} />
                                                }
                                                { this.state.sections[7]?.defaultFields[3]?.value === 'true' &&
                                                <FieldText id="relationship" label="Relationship" model="reference" parent={this} value={this.state.reference['relationship']} />
                                                }
                                                { this.state.sections[7]?.customFields?.length >0 &&
                                                    this.createCustomFields(7, this.state.referenceList.length)
                                                }  
                                            </div>

                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save Reference
                                            </button>

                                        </div>
                                    </div>
                                    }

                                    {this.state.referencesCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editReferences()}>
                                                <div className="">
                                                    {this.state.referenceList.length} reference(s) provided
                                                </div>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editReferences()}>
                                            Edit
                                        </div>

                                    </div>
                                    }

                                    {(!this.state.referencesCompleted && !this.state.showReferenceForm) &&
                                    <React.Fragment>

                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>
                        </div>
                        }
                        
                        {!this.state.attachmentsCompleted && 
                            <Alert validationList={this.state.validationListAttachment}/>}

                        {this.shouldRenderAttachmentsSection() &&
                        <div className="card">

                            <div className="card-header">
                                Attachments
                            </div>

                            <div className="card-body bg-secondary"></div>

                            <div className="card-body">
                                <form onSubmit={this.saveAttachments} autoComplete="off">

                                    {this.state.attachmentsCompleted &&
                                    <div className="">

                                        <div className="list-group">
                                            <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editAttachments()}>
                                                <small className="mb-0 ml-md-0 small text-muted">
                                                {this.state.noAttachments} Files Attached
                                                </small>
                                            </div>
                                        </div>

                                        <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editAttachments()}>
                                            Edit
                                        </div>

                                    </div>
                                    }
                                    {!this.state.attachmentsCompleted &&
                                    <React.Fragment>
                                        { this.state.sections[8]?.defaultFields[0]?.value === 'true' &&
                                            <>
                                                <FieldFile id="photoId" name="photoId" label="Photo ID"  model="document" labelClass="col-form-label-sm" fieldClass="form-control-sm" fieldColumns="9" labelColums="3" parent={this} value={this.state.document['photoId']} 
                                                dataMaxSize="5120" 
                                                accept="image/png, image/jpeg, application/pdf, text/plain, application/msword"
                                                fileName={!this.state.document['photoId'] && "Choose a file..."}
                                                />
                                                {this.state.document['photoId'] &&
                                                    <div className="text-right mb-2">
                                                        <span className="text-muted text-xs">
                                                            Attached File: {this.state.document['photoId'].get('file').name}
                                                        </span>
                                                        <span className="btn btn-xs btn-outline-primary ml-2 text-xs" onClick={()=>{this.handleFileRemove('photoId')}}>
                                                            x
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                        { this.state.sections[8]?.defaultFields[1]?.value === 'true' &&
                                            <>
                                                <FieldFile id="payStubs" name="payStubs" label="Pay Stubs" model="document" labelClass="col-form-label-sm" fieldClass="form-control-sm" fieldColumns="9" labelColums="3" parent={this} value={this.state.document['payStubs']} 
                                                dataMaxSize="5120" 
                                                accept="image/png, image/jpeg, application/pdf, text/plain, application/msword"
                                                fileName={!this.state.document['payStubs'] && "Choose a file..."}
                                                />
                                                {this.state.document['payStubs'] &&
                                                    <div className="text-right mb-2">
                                                        <span className="text-muted text-xs">
                                                            Attached File: {this.state.document['payStubs'].get('file').name}
                                                        </span>
                                                        <span className="btn btn-xs btn-outline-primary ml-2 text-xs" onClick={()=>{this.handleFileRemove('payStubs')}}>
                                                            x
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                        { this.state.sections[8]?.defaultFields[2]?.value === 'true' &&
                                            <>
                                                <FieldFile id="taxReturns" name="taxReturns" label="Tax Returns" model="document" labelClass="col-form-label-sm" fieldClass="form-control-sm" fieldColumns="9" labelColums="3" parent={this} value={this.state.document['taxReturns']} 
                                                dataMaxSize="5120" 
                                                accept="image/png, image/jpeg, application/pdf, text/plain, application/msword"
                                                fileName={!this.state.document['taxReturns'] && "Choose a file..."}
                                                />
                                                {this.state.document['taxReturns'] &&
                                                    <div className="text-right mb-2">
                                                        <span className="text-muted text-xs">
                                                            Attached File: {this.state.document['taxReturns'].get('file').name}
                                                        </span>
                                                        <span className="btn btn-xs btn-outline-primary ml-2 text-xs" onClick={()=>{this.handleFileRemove('taxReturns')}}>
                                                            x
                                                        </span>
                                                    </div>
                                                }
                                            </>
                                        }
                                        { this.state.sections[8]?.customFields?.length >0 &&
                                            this.createCustomFields(8)
                                        }
                                        <figcaption className="text-right mt-1">
                                            <small className="text-muted font-italic">Accepted file types are PNG, JPEG, TXT, PDF, and DOC Under 5 MB</small>
                                        </figcaption>
                                        <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                            Save & Continue
                                        </button>

                                    </React.Fragment>
                                    }

                                </form>
                            </div>

                        </div>
                        }

                        {this.shouldRenderFeeSection() &&
                        <div className="card">

                            <div className="card-header">
                                Application Fee
                            </div>

                            <div className="card-body bg-secondary">
                                <p className="mb-0">There is a ${this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0} non-refundable application fee.  Please submit a payment method for this fee.</p>
                            </div>

                            <div className="card-body">
                            <form onSubmit={this.savePaymentMethod}>                                 
                                {this.state.paymentMethodSelected &&
                                <div className="">
                                    <div className="list-group">
                                        <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editPaymentMethod()}>
                                            <div className="row align-items-center">
                                                <div className="col-8">
                                                    <div className="">
                                                        <React.Fragment>
                                                            Credit or Debit Card
                                                        </React.Fragment>
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        <React.Fragment>
                                                            Card number ending in {this.state.paymentMethod.cardNumber.slice(-4)}
                                                             <br></br>{this.state.paymentMethod.nameOnCard} - {this.state.billingAddress.street1}, {this.state.billingAddress.city}, {this.state.billingAddress.country} {this.state.billingAddress.postalCode}
                                                        </React.Fragment>
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editPaymentMethod()}>
                                        Edit
                                    </div>
                                    { !this.state.sentAuthorizePayment &&
                                    <button type="button" className="btn btn-primary btn-md btn-block mt-3" onClick={() => this.authorizePayment()}>
                                        Continue
                                    </button>
                                    }
                                </div>
                                }

                                {!this.state.paymentMethodSelected &&
                                <React.Fragment>
                                        {!this.state.showNewCreditCardSection &&
                                        <React.Fragment>
                                        {this.state.paymentMethodList.length ?
                                        <div className="list-group mb-2">
                                            <div className="list-group-item pb-3">
                                                {this.state.paymentMethodList.filter((data) => (data.type === "TYPE_CREDIT_CARD" || data.type === "TYPE_DEBIT_CARD")).map((data, key) => {
                                                    return (
                                    
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                            <input type="radio" name="paymentCard" value={data.id} id={key} checked={this.state.paymentCard === data.id|| false} onChange={this.handleChangePaymentCard} className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>
                                                                <CardBrandIcon
                                                                paymentMethodType='TYPE_CREDIT_CARD'
                                                                brand={data.brand}
                                                                width='55'
                                                                />
                                                                <small className="ml-2 mt-2 small media-body text-muted"> Card ending in {data.last4}</small>
                                                                <span className="align-self-center" title="Delete"> 
                                                                    <FontAwesomeIcon icon={['fas', 'trash']} className="fa-fw text-muted ml-2 mt-2" size="1x" pull="right" onClick={(event) => this.handleDeletePaymentCard(event, data)} />
                                                                </span> 
                                                            </label>
                                                        </div>    
                                                        
                                                    );
                                                })}                                            
                                            </div>
                                        </div>                
                                        :
                                        <div className="row mb-2">
                                             <div className="col-12 text-muted small">
                                                No Cards Found.
                                              </div>
                                        </div>     
                                        }
                                        </React.Fragment>
                                        }

                                        {!this.state.showNewCreditCardSection &&
                                        <button className="btn btn-secondary btn-sm mt-3 small" onClick={() => this.showNewCreditCardSection()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw small mr-2" size="1x" />
                                            Add New Credit or Debit Card
                                        </button>
                                        }
                                        
                                        {this.state.showNewCreditCardSection &&
                                        <div className="list-group mb-2 mt-2">
                                            <div className="list-group-item pb-3">

                                                <div className="row align-items-center mb-3">
                                                    <div className="col-8">
                                                        Add New Credit or Debit Card
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <img src={creditCard} className="rounded border w-75" alt="Add New Credit or Debit Card" />
                                                        </div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <div className="float-right">
                                                            <button type="button" className="close small" onClick={() => this.hideNewCreditCardSection()}>
                                                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                     
                                                </div>

                                                <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} />

                                                <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                                <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                                <div className="row mb-3">

                                                    <div className="col">
                                                        <FieldSelect id="expiryMonth" label="Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                            <option value="">-</option>
                                                            <option value="1">01 - Jan</option>
                                                            <option value="2">02 - Feb</option>
                                                            <option value="3">03 - Mar</option>
                                                            <option value="4">04 - Apr</option>
                                                            <option value="5">05 - May</option>
                                                            <option value="6">06 - Jun</option>
                                                            <option value="7">07 - Jul</option>
                                                            <option value="8">08 - Aug</option>
                                                            <option value="9">09 - Sep</option>
                                                            <option value="10">10 - Oct</option>
                                                            <option value="11">11 - Nov</option>
                                                            <option value="12">12 - Dec</option>
                                                        </FieldSelect>
                                                    </div>

                                                    <div className="col">
                                                        <FieldSelect id="expiryYear" label="Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                            <option value="">-</option>
                                                            <option value="2022">2022</option>
                                                            <option value="2023">2023</option>
                                                            <option value="2024">2024</option>
                                                            <option value="2025">2025</option>
                                                            <option value="2026">2026</option>
                                                            <option value="2027">2027</option>
                                                            <option value="2028">2028</option>
                                                            <option value="2029">2029</option>
                                                            <option value="2030">2030</option>
                                                            <option value="2031">2031</option>
                                                            <option value="2032">2032</option>
                                                            <option value="2033">2033</option>
                                                        </FieldSelect>
                                                    </div>

                                                    <div className="col">
                                                        <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.securityCode} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />
                                                    </div>

                                                </div>

                                                <div className="mb-2">
                                                    <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} isForCreditCard={true} required={true} countryRequired={true}/>
                                                </div>

                                                <div className="mb-2">
                                                    <button  className="btn btn-primary btn-md btn-block mt-3" /*onClick={() => this.saveCard()}*/ type="submit">
                                                        Save Card
                                                    </button>
                                                </div>

                                            </div>
                                        </div>
                                        }

                                        {!this.state.showNewCreditCardSection &&  !this.state.applicationFeeCompleted &&
                                        <button type="button" className="btn btn-primary btn-md btn-block mt-3" disabled={this.state.paymentCard ? false:true} onClick={() => this.preAuthorizePayment()}>
                                            Continue
                                        </button>
                                        }

                                </React.Fragment>
                                }
                            </form>
                            </div>

                            <div className="card-footer">
                                <p className="small text-muted mb-0">Transactions made through Letus are subject to transaction fees, dependant upon your chosen method of payment. To view a list of our transaction fees, <span className="btn-link c-pointer" data-toggle="modal" data-target="#fees" onClick={() => this.getFeeProfiles()}>click here</span>.
                                </p>
                            </div>

                            <FeeProfiles
                                feeProfileList={this.state.feeProfileList}
                                feeProfileCountry={this.state.feeProfileCountry}
                            />

                        </div>
                        }

                        {this.shouldRenderRentifySection() &&  this.state.identityVerificationEnabled && this.state.incomeVerificationEnabled && this.state.showIIVerificationSection &&
                            <div className="card">
                                <div className="card-header">
                                    Income and Identity Verification
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">We require Income and Identity checks to verify your stated income and legal identity.</p>
                                </div>

                                <div className="card-body">
                                    {
                                        this.state.showIncomeIdentityVerification &&
                                        <>
                                         <FieldCheckbox id="incomeVerification" fieldLabel="Income Verification" parent={this} value={true} disabled={true} />
                                         <FieldCheckbox id="identityVerification" fieldLabel="Identity Verification" parent={this} value={true} disabled={true} />
                                        <button  className="btn btn-primary btn-md btn-block mt-3" onClick={() => this.submitPayment()}>
                                        Continue
                                        </button>
                                        </>
                                    }
                                    { !this.state.showRentifyIframe && !this.state.showIncomeIdentityVerification &&
                                    <button  className="btn btn-primary btn-md btn-block mt-3" onClick={() => this.openRentify()}>
                                        Authorize & Continue
                                    </button>
                                    }

                                    { this.state.showRentifyIframe ? 
                                        <iframe
                                        id='rentifyIframe'
                                        title={this.state.rentifyURL}
                                        frameBorder="0"
                                        src={this.state.rentifyURL}
                                        className="rentifyIframe"
                                        width="120%"
                                        height="1200px"
                                        scrolling="no"
                                        />
                                        : null 
                                    }
                                </div>
                            </div>    
                        }
                    </div>
                    <div className="col-md-4 application-summary-section">
                        <div className="sticky-top">

                            {this.state.leaseApplication.existingTenant &&
                            <div className="card">

                                <div className="card-header">
                                    Sign Up Summary
                                </div>
{/* 
                                <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>
                                                Current Progress
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div className="card-body text-center m-auto">
                                    <ProgressBar radius={50} progress={this.state.progress} strokeWidth={18} strokeColor="#52c75d" strokeLinecap="square" trackStrokeWidth={18}>
                                        <div className="indicator">
                                            <div>{this.state.progress}%</div>
                                        </div>
                                    </ProgressBar>
                                </div> */}

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Once your lease details have been submitted, the landlord or property manager at your selected property will review your details.
                                    </p>
                                </div>

                            </div>
                            }

                            {!this.state.leaseApplication.existingTenant &&
                            <div className="card">

                                <div className="card-header">
                                    Application Summary
                                </div>

                                {/* <div className="card-body card-body-table">
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>
                                                Current Progress
                                            </th>
                                        </tr>
                                        </thead>
                                    </table>
                                </div>

                                <div className="card-body text-center m-auto">
                                    <ProgressBar radius={50} progress={this.state.progress} strokeWidth={18} strokeColor="#52c75d" strokeLinecap="square" trackStrokeWidth={18}>
                                        <div className="indicator">
                                            <div>{this.state.progress}%</div>
                                        </div>
                                    </ProgressBar>
                                </div> */}

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">
                                        Once your lease application has been submitted, the landlord or property manager at your selected property will review your details.
                                    </p>
                                </div>

                            </div>
                            }

                            {this.state.showPaymentSummarySection &&
                            <div className="card">
                                <div className="card-header">
                                    Payment Summary
                                </div>
                                <div className="card-body card-body-list">
                                    <ul className="list-group">
                                        <li key="fee1" className="list-group-item">
                                            <div className="float-left">
                                                Application Fee
                                            </div>
                                            <div className="float-right">
                                                ${Number(this.props.leaseApplicationPage?.customFields?.applicationFeeAmount || 0).toFixed(2)}
                                            </div>
                                        </li>
                                        <li key="fee4" className="list-group-item">
                                            <div className="float-left">
                                                Processing Fee
                                            </div>
                                            <div className="float-right">
                                                ${Number(this.state.processingFee).toFixed(2)}
                                            </div>
                                        </li>
                                        <li key="feeTotal" className="list-group-item">
                                            <div className="float-left font-weight-bold">
                                                Total
                                            </div>
                                            <div className="float-right font-weight-bold">
                                                ${Number(this.state.totalToPay).toFixed(2)}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            } 

                            {/* {this.state.progress === 100 && this.state.showSubmitApplicationButton &&
                                <div className="mb-4">
                                    <ReCAPTCHA
                                        sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                                        onChange={this.onReCaptchaChange}
                                    />
                                </div>
                            } */}

                            {this.shouldShowSubmitButton() &&
                            <React.Fragment>

                                <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.submitLeaseApplication()}>
                                    <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Submit {this.state.leaseApplication.existingTenant ? 'Lease Details' : 'Application'}
                                </div>

                            </React.Fragment>
                            }

                           
                        </div>

                    </div>
                </div>
                
                <div className="modal fade" id="terms-modal" tabIndex="-1" role="dialog" aria-labelledby="terms-modal-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="billing-profile-label">
                                        Letus Terms of use
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body" style={{height: "400px", overflowY: "scroll"}}>
                                    <TermsContents />
                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#terms-modal").modal("hide")}>Close</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="privacy-consent-modal" tabIndex="-1" role="dialog" aria-labelledby="privacy-consent-modal-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="billing-profile-label">
                                        Lease Application Privacy Consent
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body" style={{height: "400px", overflowY: "scroll"}}>
                                    <PrivacyConsent />
                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#privacy-consent-modal").modal("hide")}>Close</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> 
                <div className="modal fade" id="error-authorization-modal" tabIndex="-1" role="dialog" aria-labelledby="error-authorization-modal-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="billing-profile-label">
                                        Error
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">                            
                                    <p className="mb-0">
                                    {this.state.authorizationError}
                                    </p>
                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#error-authorization-modal").modal("hide")}>Close</button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div> 
                <div className="modal fade" id="rentify-info-modal" tabIndex="-1" role="dialog" aria-labelledby="rentify-info-modal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="billing-profile-label">
                                    Information
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body">                            
                                <p className="mb-0">
                                    {'You will be directed to a new browser for Income and Identity Verification. Your application will be submitted as soon as this verification step has been completed.'}
                                </p>
                            </div>
                            <div className="modal-footer bg-secondary rounded-bottom d-block">
                                <div className="row">
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => { $("#rentify-info-modal").modal("hide"); this.getRentifyIframe();}}>Acknowledge & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div className="modal fade" id="payment-submit-info-modal" tabIndex="-1" role="dialog" aria-labelledby="payment-submit-info-modal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="billing-profile-label">
                                    Information
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body">                            
                                <p className="mb-0">
                                    The payment for the application fee will be processed as soon as the the application has been submitted.
                                </p>
                            </div>
                            <div className="modal-footer bg-secondary rounded-bottom d-block">
                                <div className="row">
                                    <div className="col">
                                        <div className="col-12 text-center">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => { $("#payment-submit-info-modal").modal("hide"); this.calculateFee();}}>Acknowledge & Continue</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>        
                <div className="modal fade" id="delete-credit-card" tabIndex="-1" role="dialog" aria-labelledby="card-card-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.deletePaymentCard}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="card-card-label">
                                        Delete Credit or Debit Card
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p> Are you sure you want to delete the card ending in {this.state.selectedDeleteCard?.last4}? </p>
                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#delete-credit-card").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <button type="button" className="btn btn-primary btn-lg" onClick={() => this.deleteCreditCard(this.state.selectedDeleteCard)}>Delete</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    };
}

LeaseApplication.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(LeaseApplication);