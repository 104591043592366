import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../../common/Alert';
import { useValidationList } from '../../common/hooks/useValidationList';
import Table from '../../common/Table';
import TenantOnboardingForm from './TenantOnboardingForm';

const TenantOnboarding = (props) => {
  /******* STATE *******/
  /*********************/
  const [tenantOnboardingPagesList, setTenantOnboardingPagesList] = useState({});
  const [propertyManagersList, setPropertyManagersList] = useState([]);
  const [selectedOnboardingPageDetails, setSelectedOnboardingPageDetails] = useState(null);
  const { validationList, addErrorToValidationList, setAlertMessage, clearValidationList } = useValidationList([]);
  const tenantOnboardingPageQuery = useMemo(() => {
    return {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'companyId',
          operator: 'EQUALS',
          fieldValue: props.match?.params?.companyId,
        },
      ],
    };
  }, [props.match.params.companyId]);
  const propertyManagerQuery = useMemo(() => {
    return {
      orderBy: 'ASC',
      orderByFields: ['firstName'],
      conditionList: [],
      joins: {
        c: {
          targetRecordType: 'TYPE_COMPANY',
          joinField: 'companyId',
          alias: 'c',
          returnFields: ['name', 'legalName']
        }
      }
    };
  }, [props.match.params.companyId]);

  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/
  const initTenantOnboardingPage = () => {
    setSelectedOnboardingPageDetails(null);
    $('#tenantOnboardingModalContainer').modal('show');
  };

  const editTenantOnboardingPage = (onboardingPageDetails) => {
    setSelectedOnboardingPageDetails(onboardingPageDetails);
    $('#tenantOnboardingModalContainer').modal('show');
  };

  /**
   * Search all the existing tenant onboarding pages
   * @param {*} page
   * @param {*} recordsPerPage
   * @param {*} query
   */
  const searchTenantOnboardingPages = useCallback((page, recordsPerPage, query) => {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/on_boarding_page/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
        },
        {
          headers: generateRequestHeaders(),
        }
      )
      .then((response) => {
        setTenantOnboardingPagesList(response.data);
      })
      .catch((error) => {
        addErrorToValidationList(error);
      });
  }, [addErrorToValidationList]);

  const closeTenantOnboardingForm = () => {
    searchTenantOnboardingPages(1, 25, tenantOnboardingPageQuery);
    $('#tenantOnboardingModalContainer').modal('hide');
    window.scrollTo(0, 0);
    setTimeout(() => {
      clearValidationList();
    }, 3000);
  };
  /*********************/
  /*********************/

  /****** EFFECTS ******/
  /*********************/
  useEffect(() => {
    searchTenantOnboardingPages(1, 25, tenantOnboardingPageQuery);
    searchPropertyManagers(1, 0, propertyManagerQuery);
  }, [tenantOnboardingPageQuery, searchTenantOnboardingPages]);

  /**
   * Update the data table of managers.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  const searchPropertyManagers = useCallback((page, recordsPerPage, query) => {

    axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${props.match.params.companyId}`, {
      headers: generateRequestHeaders()
    }).then(responseCompany => {

      let companyId = responseCompany.data.parentId? responseCompany.data.parentId : responseCompany.data.id;

      query.conditionList.push(
          {
            type: 'STRING',
            logicalOperator: 'AND',
            openBrackets: null,
            closeBrackets: null,
            fieldName: 'companyId',
            operator: 'EQUALS',
            fieldValue: companyId
          }
      );
      axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
        orderBy: query.orderBy,
        orderByFields: query.orderByFields,
        conditionList: query.conditionList,
        joins: query.joins
      }, {
        headers: generateRequestHeaders()
      }).then(response => {
        setPropertyManagersList(response.data.records);
      }).catch(error => {
        addErrorToValidationList(error);
      });

    }).catch(error => {
      addErrorToValidationList(error);
    });

  }, [addErrorToValidationList]);

  /*********************/
  /*********************/

  return (
    <>
      <Alert validationList={validationList} />
      <div className='card'>
        <div className='card-header'>
          <div className='row align-items-center'>
            <div className='col'>Tenant Onboarding</div>
            <div className='col text-right'>
              <div
                data-toggle='modal'
                data-target='#lease-application-template'
                className='btn btn-primary btn-sm'
                onClick={() => initTenantOnboardingPage()}
              >
                <FontAwesomeIcon icon={['fas', 'plus']} className='fa-fw' /> Create Tenant Onboarding Page
              </div>
            </div>
          </div>
        </div>
        <Table
          columns={{
            templateName: 'Page Name',
            dateCreated: 'Page URL',
          }}
          columnWidths={['50%', '50%']}
          headerClass='c-pointer'
          data={tenantOnboardingPagesList}
          query={tenantOnboardingPageQuery}
          sortEnabled={false}
          recordsEnabled={true}
          paginationEnabled={true}
          updateFunction={searchTenantOnboardingPages}
        >
          <tbody>
            {tenantOnboardingPagesList.records?.map((data, key) => {
              return (
                <tr key={data.id}>
                  <td onClick={() => editTenantOnboardingPage(data)} className='c-pointer'>
                    <div className='text-wrap'>{data.pageName}</div>
                  </td>
                  <td>
                    <div className='text-break'>
                      <a target='_blank' rel='noopener noreferrer' href={'/selfsignup/' + data.pageName}>
                        {window.location.host}/selfsignup/{data.pageName}
                      </a>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <div
        className='modal fade'
        id='tenantOnboardingModalContainer'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='tenantOnboardingModalContainerLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body p-0 pb-3'>
              <TenantOnboardingForm
                companyId={props.match?.params?.companyId}
                onboardingPageDetails={selectedOnboardingPageDetails}
                closeTenantOnboardingForm={closeTenantOnboardingForm}
                setParentAlertMessage={setAlertMessage}
                addErrorToParentValidationList={addErrorToValidationList}
                allPropertyManagers={propertyManagersList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantOnboarding;
