import React from 'react';
import selectService from '../../../../media/img/hw/select_service.png';
import personalInformation from '../../../../media/img/hw/payer/personal_information.png';
import information from '../../../../media/img/hw/information.png';
import paymentMethod from '../../../../media/img/hw/payment_method.png';
import confirmPayment from '../../../../media/img/hw/confirm_payment.png';
import notification from '../../../../media/img/hw/notification.png';
import payeeHero from '../../../../media/img/hw/payee/payee_hero.png';
import payeeUseFor from '../../../../media/img/hw/payee/payee_use_for.png';
import rejectPayment from '../../../../media/img/hw/payee/reject_payment.png';
import HyperWalletCard from '../HyperWalletCard';
import styles from '../css/HyperWalletHowItWorks.module.css';

const HyperWalletPayerHowItWorks = () => {
  const locale = {
    section1: {
      heading: 'Collect payments effortlessly',
      info: 'LetUs Flexible Pay allows you to collect payments for home and personal services while offering complete payment options and without registering your business with LetUs.',
      action: 'Need Help? Contact Us',
      chatBubble: 'Start offering payment freedom with LetUs Flexible Payments.',
      img: payeeHero,
    },
    section2: {
      heading: `You can use LetUs Flexible Pay for`,
      img: payeeUseFor,
      points: [
        {
          info: 'Rent',
        },
        {
          info: 'Security deposit',
        },
        {
          info: 'HOA fees',
        },
        {
          info: 'Home improvement and maintenance',
        },
        {
          info: 'Dental',
        },
        {
          info: 'And more!',
        },
      ],
    },
    section3: {
      heading: `Here's How It Works`,
      subHeading: 'Accepting a Payment For a New User',
      cards: [
        {
          img: notification,
          heading: '1. Notification',
          info: 'You will receive a notification email regarding payment which contains an account activation link for Hyperwallet.',
        },
        {
          img: selectService,
          heading: '2. Follow the link',
          info: 'Follow the link to the Hyperwallet.',
        },
        {
          img: information,
          heading: '3. Follow Instructions',
          info: 'Follow the instructions to verify and activate your account.',
        },
        {
          img: paymentMethod,
          heading: '4. Payment Method',
          info: 'Log into your Hyperwallet account to set up a Transfer Method, which is how you want to receive money. For more information, please visit Hyperwallet Support Center.',
        },
        {
          img: confirmPayment,
          heading: '5. Accept Payment',
          info: 'Accept the payment. You will receive an email when the payment is deposited into your account',
        },
      ],
    },
    section4: {
      heading: `Accepting a Payment For a Existing Users`,
      cards: [
        {
          img: selectService,
          heading: '1. Log into your Account',
          info: 'Log into your Hyperwallet account to set up a Transfer Method, which is how you will receive the transfer. For more information, please visit Hyperwallet Support Center.',
        },
        {
          img: personalInformation,
          heading: '2. Accept the payment',
          info: 'Accept the payment. You will receive an email that the payment is deposited into your default or selected Transfer Method.',
        },
      ],
    },
    section5: {
      heading: 'Rejecting a Payment',
      info: 'If the payee does not take any further action after receiving a payment notification, LetUs will refund the payment back to the payer after a few attempts to reach out to both payee and payer.',
      img: rejectPayment,
    },
  };

  return (
    <div className={`container ${styles.containerCustom}`}>
      <section className={`row ${styles.customRow} ${styles.sectionHero} mx-auto mb-3`}>
        <header className='col-lg-5 mt-5 mb-3'>
          <h1 className={`${styles.heroHeading}`}>{locale.section1.heading}</h1>
          <p className={`mt-4 ${styles.heroInfo}`}>{locale.section1.info}</p>
          <a
            className={`btn btn-secondary btn-sm rounded text-uppercase mt-4 ${styles.btnGetStarted}`}
            target='_blank'
            rel='noopener noreferrer'
            href='https://help.rentmoola.com/hc/en-us'
          >
            {locale.section1.action}
          </a>
        </header>
        <div className={`col-lg-7 d-flex align-items-center`}>
          <div>
            <div className='talk-bubble-left tri-left tb-round btm-right-in'>
              <div className='tb-talktext'>
                <p>{locale.section1.chatBubble}</p>
              </div>
            </div>
            <img src={locale.section1.img} className={`d-block mx-auto img-fluid ${styles.payeeHeroImg} mt-5`} alt='' />
          </div>
        </div>
      </section>
      <section className={`row ${styles.customRow}`}>
        <div className={`col-lg-6`}>
          <img
            src={locale.section2.img}
            className={`d-block mx-auto img-fluid ${styles.payeeUseForImg} mt-5`}
            alt=''
            loading='lazy'
          />
        </div>
        <div className={`col-lg-6 pb-5 mt-1`}>
          <header className={`row mt-5`}>
            <h1 className={`${styles.heading} pr-5`}>{locale.section2.heading}</h1>
          </header>
          <div className={`row d-flex justify-content-center mt-2`}>
            <ul className='fa-ul'>
              {locale.section2.points.map((point, key) => (
                <li className={`mt-2 ${styles.heroInfo}`} key={key}>
                  <i className='fa-li fa fa-check-circle bs-stepper-info'></i>
                  {point.info}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className={`row ${styles.customRow}`}>
        <header className={`row ${styles.headingRow} my-5`}>
          <h1 className={`${styles.heading}`}>{locale.section3.heading}</h1>
          <h2 className={`${styles.subHeading}`}>{locale.section3.subHeading}</h2>
        </header>
        <div className={`row mt-3 d-flex justify-content-center`}>
          {locale.section3.cards.map((card, key) => (
            <div className={`mr-5 ${styles.hwCardCol}`} key={key}>
              <HyperWalletCard src={card.img} heading={card.heading} info={card.info} styleCard='hwCardPayee' />
            </div>
          ))}
        </div>
        <header className={`row ${styles.headingRow} my-5`}>
          <h2 className={`${styles.subHeading}`}>{locale.section4.heading}</h2>
        </header>
        <div className={`row mt-3 d-flex justify-content-center`}>
          {locale.section4.cards.map((card, key) => (
            <div className={`mr-5 ${styles.hwCardCol}`} key={key}>
              <HyperWalletCard src={card.img} heading={card.heading} info={card.info} styleCard='hwCardPayee' />
            </div>
          ))}
        </div>
      </section>
      <section className={`row ${styles.customRow} my-5`}>
        <header className='col-lg-5 mb-3 mt-5'>
          <h1 className={`${styles.heroHeading}`}>{locale.section5.heading}</h1>
          <p className={`mt-4 ${styles.heroInfo}`}>{locale.section5.info}</p>
        </header>
        <div className={`col-lg-7 d-flex align-items-center`}>
          <img
            src={locale.section5.img}
            className={`d-block mx-auto img-fluid ${styles.payeeHeroImg} mb-3`}
            alt=''
            loading='lazy'
          />
        </div>
      </section>
    </div>
  );
};

export default HyperWalletPayerHowItWorks;
