export const HYPER_WALLET_CONSTANTS = {
  STEP_LANDLORD_INFO: 'STEP_LANDLORD_INFO',
  STEP_PERSONAL_INFO: 'STEP_PERSONAL_INFO',
  STEP_PAYMENT: 'STEP_PAYMENT',
};

export const HYPER_WALLET_PAGE_COUNTRIES = [
  {
    value: 'CA',
    label: 'Canada',
  },
  {
    value: 'US',
    label: 'United States',
  },
];

export const PAYER_FAQ = [
  {
    ques: 'Why do I need the information of the landlord?',
    ans: 'The information is used to identify and deliver payments to the right payee.',
  },
  {
    ques: 'What payment options are available?',
    ans: 'Credit, Debit, Cash, PayPal Wallet, or LetUs Split It.',
  },
  {
    ques: 'Can I choose to Pay Later? ',
    ans: 'Yes! You can select Pay Now or Pay Later at checkout. Selecting Pay Later will allow you to Pay in 4 or use LetUs Split.',
  },
  {
    ques: 'What is Pay Later?',
    ans: 'Pay Later is a payment option that allows you to spread your payment over a period of time and make the payment in installments.',
  },
  {
    ques: 'What is LetUs Split It?',
    ans: 'LetUs Split lets you split your total payment with zero interest over 4-6 months with your credit card. No credit checks or applications are needed. All you need is a sufficient amount of credit available on your card.',
  },
  {
    ques: 'What is the Service Fee for LetUs Flexible Payments?',
    ans: 'Cash Payments: $6.95 (Canada), $3.99 (US). Debit Card (Visa/MasterCard): 0.99% (Canada), $6.99 (US). Credit Card (Visa/MasterCard): 2.5% (Canada), 2.99% (US). Split It and Paypal: $6.99 (US).',
  },
  {
    ques: 'How do I know when my payment has been successfully processed?',
    ans: 'You will receive the payment confirmation and payment acceptance/rejection via email.',
  },
  {
    ques: 'What is going to happen after I make a payment?',
    ans: 'The payee will be notified and provided instructions to accept or deny the payment.',
  },
  {
    ques: 'What if the payee did not receive my payment?',
    ans: 'Ensure your payee’s information has been correctly entered. Ask your payee to check the “Spam” folder in their email inbox. If the payee still has not received the payment please contact Customer Support (help@let.us).',
  },
  {
    ques: 'What if I entered the wrong payee’s email address and/or phone number?',
    ans: 'Please contact Customer Support (help@let.us) as soon as possible.',
  },
  {
    ques: 'What if the payee has not set up the Hyperwallet account within 24 hours?',
    ans: 'Customer Support will reach out to the payee using the contact information provided by you. If the payee does not respond or the contact information is not correct, Customer Support will reach out to you.',
  },
  {
    ques: 'What if the payee rejects my payment?',
    ans: 'LetUs will refund.',
  },
];

export const PAYEE_FAQ = [
  {
    ques: 'I was sent a payment but I did not receive an email notifying me - what happened to it? ',
    ans: 'Double check your spam folder - it can sometimes pop up in your junk inbox. If it isn’t there, ensure the correct email address was entered. If the payment still has not been received please contact Customer Support (help@let.us).',
  },
  {
    ques: 'What is the Hyperwallet? ',
    ans: 'The Hyperwallet is a payout platform through PayPal that makes collecting payments fast and efficient. Find out more about the Hyperwallet here: https://www.hyperwallet.com/make-payments/',
  },
  {
    ques: 'How do flexible payments work? ',
    ans: 'Your customer, client, or tenant will select which payment method works best for them and choose either Pay Now or Pay Later. You will receive the payment in full when you activate your Hyperwallet account, regardless of the payment method chosen.  ',
  },
];

/**
 * Return an appropriate validity message based on the validity type
 * @param {*} type - validity type object
 * @returns
 */
export const getCustomValidityMessage = (type) => {
  let validityMessage = null;
  switch (true) {
    case type['rangeUnderflow']:
      validityMessage = 'Amount cannot be less than $5.';
      break;
    case type['rangeOverflow']:
      validityMessage = 'Amount cannot be greater than $3000.';
      break;
    default:
      validityMessage = 'Please enter a valid message';
      break;
  }
  return validityMessage;
};
