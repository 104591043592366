import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import ScrollRestoration from './components/common/ScrollRestoration';
import App from './components/common/App';
import * as serviceWorker from './registerServiceWorker';
import { QueryClient, QueryClientProvider} from 'react-query';
 
const queryClient = new QueryClient()

ReactDOM.render((
        <BrowserRouter>
            <ScrollRestoration>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </ScrollRestoration>
        </BrowserRouter>
), document.getElementById('root'));

serviceWorker.unregister();
