import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import americanExpress from "../../media/img/payments/american-express.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import cash from "../../media/img/payments/cash.png";
import creditCard from "../../media/img/payments/credit-card.png";
import dinersClub from "../../media/img/payments/diners-club.png";
import discover from "../../media/img/payments/discover.png";
import jcb from "../../media/img/payments/jcb.png";
import maestro from "../../media/img/payments/maestro.png";
import mastercard from "../../media/img/payments/mastercard.png";
import payPal from "../../media/img/payments/paypal.png";
import unionPay from "../../media/img/payments/unionpay.png";
import visaElectron from "../../media/img/payments/visa-electron.png";
import LetusSplitLogo from "../../media/img/logos/logo-letus-split.png";
import googlePay from "../../media/img/payments/googlepay.png";
import applePay from "../../media/img/payments/applepay.png";
import visa from "../../media/img/payments/visa.png";
import * as constants from "../../util/constants";
import ButtonClose from "./ButtonClose";
import FieldSelect from "./FieldSelect";
import Propertii from "./Propertii";

class FeeProfiles extends Propertii {

    constructor(props) {

        super(props);

        this.state = {

            billingAccount: {},

            feeProfileList: [],
            feeProfileCountry: '',

            validationList: [],

        };

        this.getFeeProfiles = this.getFeeProfiles.bind(this);
        this.handleChangeBillingAccount = this.handleChangeBillingAccount.bind(this);
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.billingAccount.companyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let company = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.state.billingAccount.type}/${this.state.billingAccount.id}/fee_profile`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    feeProfileList: response.data,
                    feeProfileCountry: company.country
                }));

                $("#fees").modal("show");

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the billing account field, fetching new fee profiles based on the selected billing account.
     *
     * @param event - The event container.
     */
    handleChangeBillingAccount(event) {

        event.persist();

        let selectedBillingAccount;

        this.props.billingAccountList.forEach((billingAccount, key) => {
            if(billingAccount.id === event.target.value) {
                selectedBillingAccount = billingAccount;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            billingAccount: selectedBillingAccount
        }), () => {
            this.getFeeProfiles();
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A fee profile breakdown component, listing out the transaction fee associated with each available
     * payment type.
     */
    render() {

        let feeProfileList = this.props.feeProfileList ? this.props.feeProfileList : this.state.feeProfileList;
        let feeProfileCountry = this.props.feeProfileCountry ? this.props.feeProfileCountry : this.state.feeProfileCountry;

        const {formatMessage} = this.props.intl;

        return(
            <div className="modal fade" id="fees" tabIndex="-1" role="dialog" aria-labelledby="fees-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header bg-dark text-white">
                            <h5 className="modal-title" id="pay-invoice-label">
                                Transaction Fees
                            </h5>
                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                            </button>
                        </div>

                        <div className="modal-body bg-secondary">

                            <small className="mb-0">
                                Transaction fees help cover costs associated with processing electronic payments, maintaining a secure payments platform, and providing customer support. Transaction fees are non-refundable and subject to Letus' Terms of Use. Rates for international or prepaid cards may vary. Please note that international fees may be used when the card is corporate or unregulated.
                            </small>

                        </div>

                        {(this.props.billingAccountList && this.props.billingAccountList.length > 0) &&
                        <div className="modal-body bg-secondary border-top">
                            <FieldSelect id="id" label="Billing Account" labelClass="d-none mb-0" fieldClass="form-control-md mb-0" fieldColumns="12" labelColums="0" parent={this} model="billingAccount" value={this.state.billingAccount['id']} handleChange={this.handleChangeBillingAccount}>
                                <option value="" disabled={true}>Select a property lease...</option>
                                {this.props.billingAccountList &&
                                <React.Fragment>
                                    {this.props.billingAccountList.map((data, key) => {
                                        return (
                                            <option key={key} value={data.id}>{data.unit ? data.unit + ' - ' : ''}{data.joins.p.street1}</option>
                                        );
                                    })}
                                </React.Fragment>
                                }
                            </FieldSelect>
                        </div>
                        }

                        <div className="modal-body modal-body-list">
                            <ul className="list-group">

                                {feeProfileList.map((data, key) => {

                                    if(data.country === feeProfileCountry && data.paymentType === 'TYPE_CREDIT_CARD' && data.cardBrand !== null) {
                                        return (
                                          <li key={key} className='list-group-item'>
                                            <div className='media'>
                                              <div className='align-self-center mr-1'>
                                                {data.cardBrand === 'VISA' && (
                                                  <img
                                                    src={visa}
                                                    className='rounded border mr-2'
                                                    alt='Visa'
                                                    title='Visa'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'VISA_ELECTRON' && (
                                                  <img
                                                    src={visaElectron}
                                                    className='rounded border mr-2'
                                                    alt='Visa Electron'
                                                    title='Visa Electron'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'MASTERCARD' && (
                                                  <img
                                                    src={mastercard}
                                                    className='rounded border mr-2'
                                                    alt='Mastercard'
                                                    title='Mastercard'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'MAESTRO' && (
                                                  <img
                                                    src={maestro}
                                                    className='rounded border mr-2'
                                                    alt='Maestro'
                                                    title='Maestro'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'DISCOVER' && (
                                                  <img
                                                    src={discover}
                                                    className='rounded border mr-2'
                                                    alt='Discover'
                                                    title='Discover'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'AMERICAN_EXPRESS' && (
                                                  <img
                                                    src={americanExpress}
                                                    className='rounded border mr-2'
                                                    alt='American Express'
                                                    title='American Express'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'DINERS_CLUB' && (
                                                  <img
                                                    src={dinersClub}
                                                    className='rounded border mr-2'
                                                    alt='Diners Club'
                                                    title='Diners Club'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'JCB' && (
                                                  <img
                                                    src={jcb}
                                                    className='rounded border mr-2'
                                                    alt='JCB'
                                                    title='JCB'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                                {data.cardBrand === 'UNION_PAY' && (
                                                  <img
                                                    src={unionPay}
                                                    className='rounded mr-2'
                                                    alt='UnionPay'
                                                    title='UnionPay'
                                                    style={{ width: '50px' }}
                                                  />
                                                )}
                                              </div>
                                              <div className='media-body align-self-center'>
                                                <span className=''>
                                                  {formatMessage({
                                                    id: `enum.creditCard.brand.${data.cardBrand}`,
                                                  })}
                                                  {data.cardType
                                                    ? ' ' +
                                                      formatMessage({
                                                        id: `enum.creditCard.cardType.${data.cardType}`,
                                                      })
                                                    : ''}
                                                </span>
                                              </div>
                                              <div className='align-self-center text-right'>
                                                {data.feeRate !== 0 && !data.paidByCompany && (
                                                  <React.Fragment>
                                                    <div className=''>
                                                      {data.feeType === 'SUBTRACT' ? '-' : ''}
                                                      {data.rateType === 'FLAT' ? '$' : ''}
                                                      {data.feeRate.toFixed(2)}
                                                      {data.rateType === 'PERCENT' ? '%' : ''}
                                                    </div>
                                                    <div className='small text-muted font-italic'>
                                                      per transaction
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                                {(data.feeRate === 0 || data.paidByCompany) && (
                                                  <React.Fragment>
                                                    <div className='pt-1'>
                                                      <span className='text-uppercase'>Free</span>
                                                    </div>
                                                  </React.Fragment>
                                                )}
                                              </div>
                                            </div>
                                              {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                  <React.Fragment>
                                                      <div className="row m-0">
                                                          <p className="text-muted small m-0">
                                                              <span className='small mr-1'>International Fee :</span>
                                                              <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                              <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                              <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                              <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                              <span className='small ml-1'>
                                                        per transaction
                                                    </span>
                                                          </p>
                                                      </div>
                                                  </React.Fragment>
                                              }
                                          </li>
                                        );
                                    }

                                    return null;

                                })}

                                {feeProfileList.map((data, key) => {

                                    if(data.country === feeProfileCountry && data.paymentType === 'TYPE_CREDIT_CARD' && data.cardBrand === null && data.cardType === 'CREDIT') {
                                        return(
                                            <li key={key} className="list-group-item">
                                                <div className="media">
                                                    <div className="align-self-center mr-1">
                                                        <img src={creditCard} className="rounded border mr-2" alt="Credit Card" title="Credit Card" style={{width: '50px'}} />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <div className="">Other Credit</div>
                                                        <div className="small text-muted">Denotes any card type not listed</div>
                                                    </div>
                                                    <div className="align-self-center text-right">
                                                        {data.feeRate !== 0 && !data.paidByCompany &&
                                                        <React.Fragment>
                                                            <div className="">
                                                                {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                            </div>
                                                            <div className="small text-muted font-italic">
                                                                per transaction
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {(data.feeRate === 0 || data.paidByCompany) &&
                                                        <React.Fragment>
                                                            <div className="pt-1">
                                                                <span className="text-uppercase">Free</span>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                    <React.Fragment>
                                                        <div className="row m-0">
                                                            <p className="text-muted small m-0">
                                                                <span className='small mr-1'>International Fee :</span>
                                                                <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                                <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                                <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                                <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                                <span className='small ml-1'>
                                                            per transaction
                                                        </span>
                                                            </p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        );
                                    }

                                    if(data.country === feeProfileCountry && data.paymentType === 'TYPE_CREDIT_CARD' && data.cardBrand === null && data.cardType === 'DEBIT') {
                                        return(
                                            <li key={key} className="list-group-item">
                                                <div className="media">
                                                    <div className="align-self-center mr-1">
                                                        <img src={creditCard} className="rounded border mr-2" alt="Credit Card" title="Credit Card" style={{width: '50px'}} />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <div className="">Other Debit</div>
                                                        <div className="small text-muted">Denotes any card type not listed</div>
                                                    </div>
                                                    <div className="align-self-center text-right">
                                                        {data.feeRate !== 0 && !data.paidByCompany &&
                                                            <React.Fragment>
                                                                <div className="">
                                                                    {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                                </div>
                                                                <div className="small text-muted font-italic">
                                                                    per transaction
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {(data.feeRate === 0 || data.paidByCompany) &&
                                                            <React.Fragment>
                                                                <div className="pt-1">
                                                                    <span className="text-uppercase">Free</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                    <React.Fragment>
                                                        <div className="row m-0">
                                                            <p className="text-muted small m-0">
                                                                <span className='small mr-1'>International Fee :</span>
                                                                <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                                <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                                <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                                <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                                <span className='small ml-1'>
                                                            per transaction
                                                        </span>
                                                            </p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        );
                                    }

                                    if(data.country === feeProfileCountry && data.paymentType === 'TYPE_CREDIT_CARD' && data.cardBrand === null &&
                                        (data.cardType === null || (data.cardType !== 'DEBIT' && data.cardType !== 'CREDIT'))) {
                                        return(
                                            <li key={key} className="list-group-item">
                                                <div className="media">
                                                    <div className="align-self-center mr-1">
                                                        <img src={creditCard} className="rounded border mr-2" alt="Credit Card" title="Credit Card" style={{width: '50px'}} />
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        <div className="">Other Credit Cards</div>
                                                        <div className="small text-muted">Denotes any card type not listed</div>
                                                    </div>
                                                    <div className="align-self-center text-right">
                                                        {data.feeRate !== 0  && !data.paidByCompany &&
                                                            <React.Fragment>
                                                                <div className="">
                                                                    {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                                </div>
                                                                <div className="small text-muted font-italic">
                                                                    per transaction
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                        {(data.feeRate === 0 || data.paidByCompany) &&
                                                            <React.Fragment>
                                                                <div className="pt-1">
                                                                    <span className="text-uppercase">Free</span>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                    <React.Fragment>
                                                        <div className="row m-0">
                                                            <p className="text-muted small m-0">
                                                                <span className='small mr-1'>International Fee :</span>
                                                                <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                                <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                                <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                                <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                                <span className='small ml-1'>
                                                            per transaction
                                                        </span>
                                                            </p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        );
                                    }

                                    return null;

                                })}

                                {feeProfileList.map((data, key) => {

                                    if(data.country === feeProfileCountry && data.paymentType === 'TYPE_WALLET') {
                                        return (
                                            <li key={key} className='list-group-item'>
                                                <div className='media'>
                                                    <div className='align-self-center mr-1'>
                                                        {data.walletType === 'APPLE_PAY' && (
                                                            <img
                                                                src={applePay}
                                                                className='rounded border mr-2'
                                                                alt='Apple Pay'
                                                                title='Apple Pay'
                                                                style={{ width: '50px' }}
                                                            />
                                                        )}
                                                        {data.walletType=== 'GOOGLE_PAY' && (
                                                            <img
                                                                src={googlePay}
                                                                className='rounded border mr-2'
                                                                alt='Google Pay'
                                                                title='Google Pay'
                                                                style={{ width: '50px' }}
                                                            />
                                                        )}
                                                    </div>
                                                    <div className='media-body align-self-center'>
                                                <span className=''>
                                                  {formatMessage({
                                                      id: `enum.wallet.walletType.${data.walletType}`,
                                                  })}
                                                </span>
                                                    </div>
                                                    <div className='align-self-center text-right'>
                                                        {data.feeRate !== 0 && !data.paidByCompany && (
                                                            <React.Fragment>
                                                                <div className=''>
                                                                    {data.feeType === 'SUBTRACT' ? '-' : ''}
                                                                    {data.rateType === 'FLAT' ? '$' : ''}
                                                                    {data.feeRate.toFixed(2)}
                                                                    {data.rateType === 'PERCENT' ? '%' : ''}
                                                                </div>
                                                                <div className='small text-muted font-italic'>
                                                                    per transaction
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                        {(data.feeRate === 0 || data.paidByCompany) && (
                                                            <React.Fragment>
                                                                <div className='pt-1'>
                                                                    <span className='text-uppercase'>Free</span>
                                                                </div>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                                {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                    <React.Fragment>
                                                        <div className="row m-0">
                                                            <p className="text-muted small m-0">
                                                                <span className='small mr-1'>International Fee :</span>
                                                                <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                                <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                                <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                                <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                                <span className='small ml-1'>
                                                        per transaction
                                                    </span>
                                                            </p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        );
                                    }

                                    return null;

                                })}

                                {feeProfileList.map((data, key) => {

                                    if(data.country === feeProfileCountry && data.paymentType !== 'TYPE_CREDIT_CARD' && data.paymentType !== 'TYPE_WALLET') {
                                        return(
                                            <li key={key} className="list-group-item">
                                                <div className="media">
                                                    <div className="align-self-center mr-1">
                                                        {data.paymentType === 'TYPE_BANK_ACCOUNT' &&
                                                        <img src={bankAccount} className="rounded border mr-2" alt="Bank Account" title="Bank Account" style={{width: '50px'}} />
                                                        }
                                                        {data.paymentType === 'TYPE_PAY_PAL' &&
                                                        <img src={payPal} className="rounded border mr-2" alt="PayPal" title="PayPal" style={{width: '50px'}} />
                                                        }
                                                        {data.paymentType === 'TYPE_CASH' &&
                                                        <img src={cash} className="rounded border mr-2" alt="Cash" title="Cash" style={{width: '50px'}} />
                                                        }
                                                        {data.paymentType === 'TYPE_INSTALLMENT_PAY' &&
                                                        <img src={LetusSplitLogo} className="rounded border mr-2" alt="LetusSplit" title="LetusSplit" style={{width: '50px'}}/>
                                                        }
                                                    </div>
                                                    <div className="media-body align-self-center">
                                                        {data.paymentType === 'TYPE_BANK_ACCOUNT' && data.processor === 'BMO' &&
                                                        <div className="">Pre-Authorized Debit</div>
                                                        }
                                                        {data.paymentType === 'TYPE_BANK_ACCOUNT' && data.processor !== 'BMO' &&
                                                            <div className="">Bank Account</div>
                                                        }
                                                        {data.paymentType === 'TYPE_PAY_PAL' &&
                                                        <div className="">PayPal</div>
                                                        }
                                                        {data.paymentType === 'TYPE_CASH' &&
                                                        <div className="">Cash</div>
                                                        }
                                                        {data.paymentType === 'TYPE_INSTALLMENT_PAY' &&
                                                        <div className="">Letus Split</div>
                                                        }
                                                    </div>
                                                    <div className="align-self-center text-right">
                                                        {data.feeRate !== 0 && !data.paidByCompany &&
                                                        <React.Fragment>
                                                            {data.paymentType === 'TYPE_BANK_ACCOUNT' &&
                                                            <div className="">
                                                                {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                            </div>
                                                            }
                                                            {data.paymentType === 'TYPE_PAY_PAL' &&
                                                            <div className="">
                                                                {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                            </div>
                                                            }
                                                            {data.paymentType === 'TYPE_CASH' &&
                                                            <div className="">
                                                                {data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''}
                                                            </div>
                                                            }
                                                            {data.paymentType === 'TYPE_INSTALLMENT_PAY' &&
                                                            <div className="">
                                                                6.99%
                                                                {/* {data.feeType === 'SUBTRACT' ? '-' : ''}{data.rateType === 'FLAT' ? '$' : ''}{(data.feeRate).toFixed(2)}{data.rateType === 'PERCENT' ? '%' : ''} */}
                                                            </div>
                                                            }
                                                            <div className="small text-muted font-italic">
                                                                per transaction
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                        {(data.feeRate === 0 || data.paidByCompany) &&
                                                        <React.Fragment>
                                                            <div className="pt-1">
                                                                <span className="text-uppercase">Free</span>
                                                            </div>
                                                        </React.Fragment>
                                                        }
                                                    </div>
                                                </div>
                                                {(data.internationalFeeRate !== 0 && !data.paidByCompany) &&
                                                    <React.Fragment>
                                                        <div className="row m-0">
                                                            <p className="text-muted small m-0">
                                                                <span className='small mr-1'>International Fee :</span>
                                                                <span className='small'>{data.feeType === 'SUBTRACT' ? '-' : ''}</span>
                                                                <span className='small'>{data.internationalRateType === 'FLAT' ? '$' : ''}</span>
                                                                <span className='small'>{data.internationalFeeRate.toFixed(2)}</span>
                                                                <span className='small'>{data.internationalRateType === 'PERCENT' ? '%' : ''}</span>
                                                                <span className='small ml-1'>
                                                            per transaction
                                                        </span>
                                                            </p>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </li>
                                        );
                                    }

                                    return null;

                                })}

                            </ul>
                        </div>

                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                            <ButtonClose />
                        </div>

                    </div>
                </div>
            </div>
        )
    };
}

FeeProfiles.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(FeeProfiles);