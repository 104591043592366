import axios from 'axios/index';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../Alert';
import { useInput } from '../hooks/useInput';
import { useValidationList } from '../hooks/useValidationList';
import Spinner from '../Spinner';
import NotesModal from './NotesModal';
import NotesTable from './NotesTable';

/**
 * Reusable component for taking Notes on a company or property level
 * @returns Common Notes component
 */
const Notes = ({ companyId, propertyId }) => {
  /***** State *****/
  /*****************/
  const [notesList, setNotesList] = useState({});
  const [noteId, setNoteId] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const {
    value: noteTicketNumber,
    setValue: setNoteTicketNumber,
    handleChange: handleChangeNoteTicketNumber,
  } = useInput(null);
  const {
    value: noteMessage,
    setValue: setNoteMessage,
    handleChange: handleChangeNoteMessage,
  } = useInput(null);

  const [showSpinner, setShowSpinner] = useState(false);
  const { validationList, addErrorToValidationList } = useValidationList([]);
  /*****************/
  /*****************/

  /**** Actions ****/
  /*****************/
  /**
   * Generate query which can be used to fetch notes data
   */
  const getNotesQuery = useCallback(() => {
    const notesQuery = {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'companyId',
          operator: 'EQUALS',
          fieldValue: companyId,
        },
      ],
    };
    if (propertyId) {
      notesQuery.conditionList.push({
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'propertyId',
        operator: 'EQUALS',
        fieldValue: propertyId,
      });
    } else {
      notesQuery.conditionList.push({
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'propertyId',
        operator: 'EQUALS',
        fieldValue: null,
      });
    }
    return notesQuery;
  }, [companyId, propertyId]);

  /**
   * Search Notes associated with a given company or property
   */
  const searchNotes = useCallback(
    (page = 1, recordsPerPage = 25) => {
      setShowSpinner(true);
      axios
        .post(
          `${constants.REACT_APP_HOST_API_URL}/COMPANY_NOTE/search?recordsPerPage=${recordsPerPage}&page=${page}`,
          getNotesQuery(),
          {
            headers: generateRequestHeaders(),
          }
        )
        .then((resp) => {
          setNotesList(resp.data);
          setShowSpinner(false);
        })
        .catch((err) => {
          setShowSpinner(false);
          addErrorToValidationList(err);
        });
    },
    [getNotesQuery, addErrorToValidationList]
  );

  /**
   * Update an existing note or save a new one
   */
  const saveNote = (event) => {
    event.preventDefault();
    setShowSpinner(true);
    const note = {
      id: noteId || undefined,
      createdBy: createdBy || undefined,
      type: 'TYPE_COMPANY_NOTE',
      note: noteMessage,
      ticketNumber: noteTicketNumber,
      companyId,
      propertyId,
    };
    const action = noteId ? 'update' : 'create';
    const method = noteId ? 'put' : 'post';
    axios[method](`${constants.REACT_APP_HOST_API_URL}/${action}`, note, {
      headers: generateRequestHeaders(),
    })
      .then(() => {
        searchNotes();
        $('#note-modal').modal('hide');
      })
      .catch((err) => {
        setShowSpinner(false);
        addErrorToValidationList(err);
      });
  };

  /**
   * Open an existing note in the edit view
   * @param {object} note - selected note
   */
  const openNote = (note) => {
    setNoteId(note.id);
    setCreatedBy(note.createdBy);
    setNoteTicketNumber(note.ticketNumber);
    setNoteMessage(note.note);
    $('#note-modal').modal('show');
  };

  /**
   * Clear the note form
   */
  const clearNoteForm = () => {
    setNoteId(null);
    setCreatedBy(null);
    setNoteTicketNumber(null);
    setNoteMessage(null);
  };
  /*****************/
  /*****************/

  useEffect(() => {
    if (companyId) {
      searchNotes();
    }
  }, [companyId, propertyId, searchNotes]);

  return (
    <>
      <Alert validationList={validationList} validationType='danger' />
      <Spinner visible={showSpinner} />
      <div className='card'>
        <div className='card-header'>
          <div className='row align-items-center'>
            <div className='col'>Notes</div>
            <div className='col text-right'>
              <div
                data-toggle='modal'
                data-target='#note-modal'
                className='btn btn-primary btn-sm'
                onClick={clearNoteForm}
              >
                Create Note
              </div>
            </div>
          </div>
        </div>
        <NotesTable notesList={notesList} searchNotes={searchNotes} openNote={openNote} />
      </div>
      <NotesModal
        saveNote={saveNote}
        noteId={noteId}
        noteTicketNumber={noteTicketNumber}
        handleChangeNoteTicketNumber={handleChangeNoteTicketNumber}
        noteMessage={noteMessage}
        handleChangeNoteMessage={handleChangeNoteMessage}
        validationList={validationList}
      />
    </>
  );
};

Notes.propTypes = {
  companyId: PropTypes.string,
  propertyId: PropTypes.string,
};

export default Notes;
