import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import React from 'react';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import Moment from 'react-moment';
import * as constants from '../../../util/constants';
import Alert from '../../common/Alert';
import ButtonSave from '../../common/ButtonSave';
import FieldFile from '../../common/FieldFile';
import FieldSelect from '../../common/FieldSelect';
import FieldText from '../../common/FieldText';
import FieldRange from '../../common/FieldRange';
import FieldCheckbox from "../../common/FieldCheckbox";
import Modal from '../../common/Modal';
import Propertii from '../../common/Propertii';
import Table from '../../common/Table';
import FieldPropertiesAsync from "../../common/FieldPropertiesAsync";
import lodash from "lodash";

class LeaseApplicationPages extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      leaseApplicationPage: {},
      customFields: {
        selectedPropertyList: [],
        selectedSubsidiaryList: [],
      },
      document: {
        file: '',
      },
      documentList: [],
      leaseApplicationTemplateList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },
      leaseApplicationPageList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },
      leaseApplicationTemplateQuery: {
        orderBy: 'DESC',
        orderByFields: ['createDate'],
        conditionList: [
          {
            type: 'STRING',
            logicalOperator: 'AND',
            openBrackets: null,
            closeBrackets: null,
            fieldName: 'id',
            operator: 'EQUALS',
            fieldValue: this.props.match.params.companyId,
          },
        ],
        joins: {
          p: {
            targetRecordType: 'TYPE_PROPERTY',
            joinField: 'propertyId',
            alias: 'p',
            returnFields: [
              'propertyName',
              'street1',
              'street2',
              'city',
              'province',
              'country',
              'postalCode',
              'propertyIdentifier',
              'companyId',
            ],
          },
          template: {
            targetRecordType: 'TYPE_TEMPLATE',
            joinField: 'companyId',
            targetField: 'companyId',
            alias: 't',
            returnFields: ['id', 'templateName', 'sections'],
          },
          document: {
            targetRecordType: 'TYPE_DOCUMENT',
            joinField: 't.id',
            targetField: 'recordId',
            alias: 'd',
            returnFields: ['id', 'name', 'url'],
          },
        },
      },
      leaseApplicationPageQuery: {
        orderBy: 'DESC',
        orderByFields: ['createDate'],
        conditionList: [
          {
            type: 'STRING',
            logicalOperator: 'AND',
            openBrackets: null,
            closeBrackets: null,
            fieldName: 'companyId',
            operator: 'EQUALS',
            fieldValue: this.props.match.params.companyId,
          },
        ],
      },
      validationList: [],
      backgroundServiceFee: {},
      creditServiceFee: {},
      incomeServiceFee: {},
     
      propertyList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },
      propertyQuery: {
        orderBy: "ASC",
        orderByFields: ["street1"],
      },
     
      subsidiaryList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },
      subsidiaryQuery: {
       conditionList: [{
          type: "STRING",
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: "parentId",
          operator: "EQUALS",
          fieldValue: this.props.match?.params?.companyId,
       }],
       orderBy: "ASC",
       orderByFields: ["name"],
      }, 
    };

    this.searchLeaseApplicationTemplates = this.searchLeaseApplicationTemplates.bind(
      this
    );
    this.searchLeaseApplicationPages = this.searchLeaseApplicationPages.bind(
      this
    );
    this.editLeaseApplicationPage = this.editLeaseApplicationPage.bind(this);
    this.initLeaseApplicationPage = this.initLeaseApplicationPage.bind(this);
    this.saveLeaseApplicationPage = this.saveLeaseApplicationPage.bind(this);
    this.deleteLeaseApplicationPage = this.deleteLeaseApplicationPage.bind(
      this
    );

    this.searchDocuments = this.searchDocuments.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.selectDocument = this.selectDocument.bind(this);

    this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(
      this
    );    
    this.handleChangeUnionPayMerchantAccount = this.handleChangeUnionPayMerchantAccount.bind(
      this
    );
    this.getScreeningFee = this.getScreeningFee.bind(this);
    this.creditReportHandleChange = this.creditReportHandleChange.bind(this);
    this.backgroundCheckHandleChange = this.backgroundCheckHandleChange.bind(this);
    this.incomeVerificationHandleChange = this.incomeVerificationHandleChange.bind(this);
    this.validateCreditReportState = this.validateCreditReportState.bind(this);
    this.validateBackGroundState = this.validateBackGroundState.bind(this);
    this.validateIncomeState = this.validateIncomeState.bind(this);
    
    this.handleChangeSelectedProperties = this.handleChangeSelectedProperties.bind(this);
    this.searchProperties = this.searchProperties.bind(this);
    this.handleSearchProperties = this.handleSearchProperties.bind(this);
    this.initPropertyList = this.initPropertyList.bind(this);

    this.handleChangeSelectedSubsidiaries = this.handleChangeSelectedSubsidiaries.bind(this);
    this.searchSubsidiaries = this.searchSubsidiaries.bind(this);
    this.handleSearchSubsidiaries = this.handleSearchSubsidiaries.bind(this);
    this.initSubsidiaryList = this.initSubsidiaryList.bind(this);
    this.validatePropertySelector = this.validatePropertySelector.bind(this);
  }
  
  /**
   * Load the list of lease application pages on mounting of the component.
   */
  componentDidMount() {
    let companyId = this.props.match.params.companyId;

    this.searchLeaseApplicationTemplates(
      1,
      25,
      this.state.leaseApplicationTemplateQuery
    );
    this.searchLeaseApplicationPages(
      1,
      25,
      this.state.leaseApplicationPageQuery
    );

    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'VISA');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'VISA_ELECTRON');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'MASTERCARD');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'MAESTRO');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'DISCOVER');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'DINERS_CLUB');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'JCB');
    this.getMerchantAccounts(companyId, 'TYPE_CREDIT_CARD', 'UNION_PAY');
    this.getScreeningFee('SCREENING_BASIC');
    this.getScreeningFee('SCREENING_CREDIT');
    this.getScreeningFee('INCOME_IDENTITY_VERIFICATION');
    this.initPropertyList();
    this.initSubsidiaryList();
  }
  
 
  

  /**
   * Init the property list.
   *
  */
  initPropertyList(){
    axios.post(
      `${constants.REACT_APP_HOST_API_URL}/company/${this.props.match?.params?.companyId}/properties?recordsPerPage=25&page=1`,
      this.state.propertyQuery,
      {
        headers: this.generateRequestHeaders(),
      }
    ).then((response) => {
      let propertyList = response.data.records;

      this.setState((prevState) => ({
        ...prevState,
        propertyList: propertyList,
      }));
    })
  }  

  /**
   * Init the subcompanies list.
   *
  */
   initSubsidiaryList(){
    axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=25&page=1`, this.state.subsidiaryQuery, {
        headers: this.generateRequestHeaders()
    }).then(response => {
      let subsidiaryList = response.data.records;

      this.setState((prevState) => ({
        ...prevState,
        subsidiaryList: subsidiaryList,
      }));
    }).catch(error => {
        this.handleValidation(error);
    });
  }  

  /**
   * Fetch the fee for a particular screening type from the invoicing service.
   *
   * @param screenType - The type of screening to fetch the fee for.
   */
  getScreeningFee(screenType) {
    let companyId = this.props.match?.params.companyId;

    axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/code/${screenType}?companyId=${companyId}`, {
        headers: this.generateRequestHeaders()
    }).then(response => {
      if(screenType === 'SCREENING_BASIC') {
        this.setState(prevState => ({
            ...prevState,
            backgroundServiceFee: response.data
        }));
      } else if (screenType === 'SCREENING_CREDIT') {
        this.setState(prevState => ({
            ...prevState,
            creditServiceFee: response.data
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          incomeServiceFee: response.data
        }));
      }
    }).catch(error => {
        this.handleValidation(error);
    });
  }

  /**
   * Handle selecting a lease application page record by bringing up the modal with lease application page values
   * pre-filled.
   *
   * @param leaseApplicationPage - The ID of the lease application page selected.
   */
  editLeaseApplicationPage(leaseApplicationPage) {
    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/lease_application_page/${leaseApplicationPage}`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          leaseApplicationPage: response.data,
          customFields: response.data.customFields,
        }));

        this.searchDocuments();

        $('#lease-application-page').modal('show');
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Initialize a new instance of a lease application page object when the user clicks the appropriate button.
   */
  initLeaseApplicationPage() {
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/new`)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          customFields: {},
          leaseApplicationPage: response.data,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Delete the selected lease application page.
   */
  deleteLeaseApplicationPage() {
    axios
      .delete(
        `${constants.REACT_APP_HOST_API_URL}/lease_application_page/${this.state.leaseApplicationPage.id}/delete`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState({
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code: 'admin.companies.leasing.deleted',
              },
            },
          ],
        });

        this.searchLeaseApplicationPages(
          this.state.leaseApplicationPageList.page,
          this.state.leaseApplicationPageList.recordsPerPage,
          this.state.leaseApplicationPageQuery
        );

        $('#lease-application-page').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to all credit/debit card brands available.
   *
   * @param event - The event container.
   */
  handleChangeCreditMerchantAccount(event) {
    event.preventDefault();

    let creditMerchantAccountList = this.state.leaseApplicationPage
      .creditMerchantAccountList;
    let creditMerchantAccountId = event.target.value;

    creditMerchantAccountList['VISA'] = creditMerchantAccountId;
    creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
    creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
    creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
    creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
    creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
    creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
    creditMerchantAccountList['JCB'] = creditMerchantAccountId;

    this.setState((prevState) => ({
      ...prevState,
      creditMerchantAccountList: creditMerchantAccountList,
    }));
  }

  /**
   * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
   * field, the same merchant account will be applies to all credit/debit card brands available.
   *
   * @param event - The event container.
   */
  handleChangeUnionPayMerchantAccount(event) {
    event.preventDefault();

    let creditMerchantAccountList = this.state.leaseApplicationPage
      .creditMerchantAccountList;

    creditMerchantAccountList['UNION_PAY'] = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      creditMerchantAccountList: creditMerchantAccountList,
    }));
  }

  /**
   * Build the property selector object to be passed into leaseApplicationPage object
   *
   */
   validatePropertySelector() {
    
    switch (this.state.customFields?.propertyLevel) {
      case 'SUB_COMPANY': {
        if(!this.props?.company?.parentId && !this.state.customFields?.selectedSubsidiaryList?.length){
          this.setErrorMessage("At least one Subsidiary must be selected from the Subsidiaries list.");
          this.handleClearValidationList();
          
          $('#lease-application-page').animate({ scrollTop: 0 }, 'fast');

          return 0;
        }

        return 1;
      }
      case 'PROPERTY': {
        if(!this.state.customFields?.selectedPropertyList?.length){
          this.setErrorMessage("At least one Property must be selected from the Properties list.");
          this.handleClearValidationList();
          
          $('#lease-application-page').animate({ scrollTop: 0 }, 'fast');

          return 0;
        }

        return 1;
      }
      default: {
        this.setState(prevState => ({
          ...prevState,
          customFields:{
            ...prevState.customFields,
            propertyLevel: 'COMPANY',
          }
        }));

        return 1;
      }
    }
   }

  /**
   * Handle the submission of the form. Use the create date field of the lease application page in the state to
   * determine if the user is editing or creating a new lease application page. Upon successful submission of the
   * form, refresh the table and hide the modal.
   *
   * @param event - The event container.
   */
  saveLeaseApplicationPage(event) {
    event.preventDefault();

    if(!this.validatePropertySelector()){
      return
    }

    let leaseApplicationPage = this.state.leaseApplicationPage;

    leaseApplicationPage.companyId = this.props.match.params.companyId;
    leaseApplicationPage.customFields = this.state.customFields;
    leaseApplicationPage.identityVerificationEnabled = leaseApplicationPage.incomeVerificationEnabled;

    if(leaseApplicationPage.customFields.propertyLevel === 'COMPANY' || !leaseApplicationPage.customFields.propertyLevel) {
      leaseApplicationPage.propertySelector = {
        level: 'COMPANY',
        ids: [leaseApplicationPage.companyId]
      };
    } else {
      let idsArr = [];
      if(leaseApplicationPage.customFields.propertyLevel === "PROPERTY") {
        idsArr = leaseApplicationPage.customFields?.selectedPropertyList.map(property => property.value) 
      } else {
        if(this.state.subsidiaryList?.length > 0) {
          idsArr = leaseApplicationPage.customFields?.selectedSubsidiaryList.map(property => property.value)
        } else {
          idsArr = [leaseApplicationPage.companyId]
        }
      }
      leaseApplicationPage.propertySelector.ids = idsArr;
      leaseApplicationPage.propertySelector = {
        level: leaseApplicationPage.customFields.propertyLevel,
        ids: idsArr
      };
    }

    leaseApplicationPage.pageName = leaseApplicationPage.pageName.replaceAll(' ', '_')

    if(this.state.customFields['applicationFeeAmount']) {
      if(!this.state.leaseApplicationPage?.creditMerchantAccountList?.VISA && !this.state.leaseApplicationPage?.creditMerchantAccountList?.UNION_PAY) {
        this.setCustomAlert("danger", "RM_ERROR_TENANT_LEASE_APPLICATION_NO_MERCHANT_ACCOUNT_SELECTED");
        return;
      }
    }
    if(Number(this.state.leaseApplicationPage['rentMin']) > Number(this.state.leaseApplicationPage['rentMax'])) {
        this.setCustomAlert("danger", "RM_ERROR_TENANT_LEASE_APPLICATION_RENTMIN_GREATER_THAN_RENTMAX");
        $('#lease-application-page').animate({ scrollTop: 0 }, 'fast');
        return;
    }

    if (this.state.leaseApplicationPage.createDate == null) {
      axios
        .post(
          `${constants.REACT_APP_HOST_API_URL}/create`,
          leaseApplicationPage,
          {
            headers: this.generateRequestHeaders(),
          }
        )
        .then((response) => {
          this.setState({
            validationList: [
              {
                fields: {},
                alert: {
                  type: 'primary',
                  code: 'admin.companies.leasing.created',
                },
              },
            ],
          });

          this.searchLeaseApplicationPages(
            this.state.leaseApplicationPageList.page,
            this.state.leaseApplicationPageList.recordsPerPage,
            this.state.leaseApplicationPageQuery
          );

          $('#lease-application-page').modal('hide');
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    } else {
      axios
        .put(
          `${constants.REACT_APP_HOST_API_URL}/update`,
          leaseApplicationPage,
          {
            headers: this.generateRequestHeaders(),
          }
        )
        .then((response) => {
          this.setState({
            validationList: [
              {
                fields: {},
                alert: {
                  type: 'primary',
                  code: 'saved',
                  message: 'Changes have been saved',
                },
              },
            ],
          });

          this.searchLeaseApplicationPages(
            this.state.leaseApplicationPageList.page,
            this.state.leaseApplicationPageList.recordsPerPage,
            this.state.leaseApplicationPageQuery
          );

          $('#lease-application-page').modal('hide');
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    }

    window.scrollTo(0, 0);
  }

  /**
   * Upload a document to the list of documents for a specific lease application page.
   *
   * @param event - The event container.
   */
  uploadDocument(event) {
    event.preventDefault();

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/lease_application_page/${this.state.leaseApplicationPage.id}/upload`,
        this.state.document.file,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState({
          validationList: [
            {
              fields: {},
              alert: {
                type: 'primary',
                code: 'common.documents.created',
                message: 'Upload successful',
              },
            },
          ],
        });
        this.searchDocuments();
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Select a document and bring up the delete document confirmation modal.
   *
   * @param document - The selected document.
   */
  selectDocument(document) {
    this.setState((prevState) => ({
      ...prevState,
      document: document,
    }));

    $('#lease-application-page-documents').modal('hide');
    $('#delete-document').modal('show');
  }

  /**
   * Delete the selected document.
   */
  deleteDocument() {
    axios
      .delete(
        `${constants.REACT_APP_HOST_API_URL}/document/${this.state.document.id}/delete`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.searchDocuments();

        $('#lease-application-page-documents').modal('show');
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Update the data table of lease application templates.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  searchLeaseApplicationTemplates(page, recordsPerPage, query) {
    const records = {
      page: 1,
      records: [],
      recordsPerPage: 100,
      totalPages: 1,
      totalRecordCount: 1,
    };
    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}/templates`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
          joins: query.joins,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        records.records = response.data;
        records.totalRecordCount = response.data.length;
        this.setState((prevState) => ({
          ...prevState,
          leaseApplicationTemplateList: records,
          leaseApplicationTemplateQuery: {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins,
          },
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Update the data table of lease application pages.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  searchLeaseApplicationPages(page, recordsPerPage, query) {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/lease_application_page/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          leaseApplicationPageList: response.data,
          leaseApplicationPageQuery: {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
          },
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Perform a search for all available documents related to the lease application page for custom fields that require
   * an image URL or document URL.
   */
  searchDocuments() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/document/search`,
        {
          orderBy: 'ASC',
          orderByFields: ['createDate'],
          conditionList: [
            {
              type: 'STRING',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'recordId',
              operator: 'EQUALS',
              fieldValue: this.state.leaseApplicationPage.id,
            },
          ],
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          documentList: response.data.records,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handler for change check box Credit Report
   * 
   */
  creditReportHandleChange(){
    let newStateCreditReportEnabled = this.state.leaseApplicationPage.creditReportEnabled;
    
    this.setState(prevState => ({
      ...prevState,
      leaseApplicationPage:{
        ...prevState.leaseApplicationPage,
        creditReportEnabled: !newStateCreditReportEnabled,
      }
    }));

    if(!newStateCreditReportEnabled){
      $('#credit-report-popup').modal('show');
    }
  }

  /**
   * Handler for change check box Background check
   * 
   */
   backgroundCheckHandleChange(){
    let newStateBackgroundCheckEnabled = this.state.leaseApplicationPage.backgroundCheckEnabled;
    
    this.setState(prevState => ({
      ...prevState,
      leaseApplicationPage:{
        ...prevState.leaseApplicationPage,
        backgroundCheckEnabled: !newStateBackgroundCheckEnabled,
      }
    }));

    if(!newStateBackgroundCheckEnabled){
      $('#background-check-popup').modal('show');
    }
  }

  incomeVerificationHandleChange(){
    let newStateIncomeVerificationEnabled = this.state.leaseApplicationPage.incomeVerificationEnabled;
    
    this.setState(prevState => ({
      ...prevState,
      leaseApplicationPage:{
        ...prevState.leaseApplicationPage,
        incomeVerificationEnabled: !newStateIncomeVerificationEnabled,
      }
    }));

    if(!newStateIncomeVerificationEnabled){
      $('#income-verification-popup').modal('show');
    }
  }

  
 
  /**
   * Validate the state of the Credit Report to keep it off if not agree
   * 
   */
  validateCreditReportState(event){
    event.preventDefault();
    if(this.state.leaseApplicationPage.creditReportEnabled){
      this.setState(prevState => ({
        ...prevState,
        leaseApplicationPage:{
          ...prevState.leaseApplicationPage,
          creditReportEnabled: false,
        }
      }));
    }
    $('#credit-report-popup').modal('hide');
  }
  
  /**
   * Validate the state of the Background check to keep it off if not agree
   * 
   */
   validateBackGroundState(event){
    event.preventDefault();
    if(this.state.leaseApplicationPage.backgroundCheckEnabled){
      this.setState(prevState => ({
        ...prevState,
        leaseApplicationPage:{
          ...prevState.leaseApplicationPage,
          backgroundCheckEnabled: false,
        }
      }));
    }
    $('#background-check-popup').modal('hide');
  }

  validateIncomeState(event){
    event.preventDefault();
    if(this.state.leaseApplicationPage.incomeVerificationEnabled){
      this.setState(prevState => ({
        ...prevState,
        leaseApplicationPage:{
          ...prevState.leaseApplicationPage,
          incomeVerificationEnabled: false,
        }
      }));
    }
    $('#income-verification-popup').modal('hide');
  }

  /**
   * Handle changes to the selected properties field.
   *
   * @param selectedOptions - The list of selected properties.
   */
   handleChangeSelectedProperties(selectedOptions) {
    let selectedPropertyList = [];

    if (selectedOptions && selectedOptions.length > 0) {
      selectedOptions.forEach((selectedProperty) => {
        selectedPropertyList.push({
          value: selectedProperty.value,
          label: selectedProperty.label,
        });
      });
    }

    this.setState(prevState => ({
      ...prevState,
      customFields:{
        ...prevState.customFields,
        selectedPropertyList: selectedPropertyList,
      }
    }));
  }

  /**
   * Handles property search based on the provided input
   *
   * @param {*} inputValue
   */
   handleSearchProperties(inputValue) {
    if (!inputValue) {
      return null;
    }

    let propertyQuery = this.state.propertyQuery;

    propertyQuery.joins = {
      company: {
        targetRecordType: "TYPE_COMPANY",
        joinField: "companyId",
        alias: "company",
        returnFields: ["name"],
      },
    };

    propertyQuery.conditionList = [];
    propertyQuery.conditionList.push(
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: null,
        fieldName: "id",
        operator: "EQUALS",
        fieldValue: inputValue,
      },
      {
        type: "STRING",
        logicalOperator: "OR",
        openBrackets: null,
        closeBrackets: ")",
        fieldName: "propertyName,street1,city,postalCode,propertyIdentifier",
        operator: "MATCH",
        fieldValue: inputValue,
      }
    );

    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}/company/${this.props.match?.params?.companyId}/properties`,
      this.state.propertyQuery,
      {
        headers: this.generateRequestHeaders(),
      }
    );
  }

  /**
   * Search properties based on the provided input.
   *
   * @param {*} inputValue
   * @param {*} callback
   */
  searchProperties(inputValue, callback) {
    const { formatMessage } = this.props.intl;

    this.handleSearchProperties(inputValue)
      .then((response) => {
        const propertyList = response?.data?.records;
        const options = [];
        if(propertyList) {
            propertyList.forEach((data) => {
            options.push({
                value: data.id,
                label:
                data.street1 +
                (data.street2 ? ", " + data.street2 : "") +
                ", " +
                data.city +
                ", " +
                (data.country === "CA"
                    ? formatMessage({ id: "province." + data.province })
                    : formatMessage({ id: "state." + data.province })) +
                ", " +
                formatMessage({ id: "country." + data.country }) +
                " " +
                data.postalCode,
            });
            });
        }
        callback(options);
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle changes to the selected subsidiaries field.
   *
   * @param selectedOptions - The list of selected subsidiaries.
   */
   handleChangeSelectedSubsidiaries(selectedOptions) {
    let selectedSubsidiaryList = [];

    if (selectedOptions && selectedOptions.length > 0) {
      selectedOptions.forEach((selectedSubsidiary) => {
        selectedSubsidiaryList.push({
          value: selectedSubsidiary.value,
          label: selectedSubsidiary.label,
        });
      });
    }

    this.setState(prevState => ({
      ...prevState,
      customFields:{
        ...prevState.customFields,
        selectedSubsidiaryList: selectedSubsidiaryList,
      }
    }));
  }

  /**
   * Handles subsidiary search based on the provided input
   *
   * @param {*} inputValue
   */
   handleSearchSubsidiaries(inputValue) {
    if (!inputValue) {
      return null;
    }

    let subsidiaryQuery = {
      conditionList: [],
      orderBy: "ASC",
      orderByFields: ["name"],
    };

    subsidiaryQuery.conditionList.push(
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: null,
        fieldName: "parentId",
        operator: "EQUALS",
        fieldValue: this.props.match?.params?.companyId,
      },
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: null,
        closeBrackets: ")",
        fieldName: "legalName, name",
        operator: "MATCH",
        fieldValue: inputValue,
      }
    );

    
    
    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=100&page=1`,
      subsidiaryQuery,
      {
        headers: this.generateRequestHeaders(),
      }
    );
  }

  /**
   * Search subsidiaries based on the provided input.
   *
   * @param {*} inputValue
   * @param {*} callback
   */
  searchSubsidiaries(inputValue, callback) {

    this.handleSearchSubsidiaries(inputValue)
      .then((response) => {
        const subsidiaryList = response?.data?.records;
        const options = [];
        if(subsidiaryList) {
          subsidiaryList.forEach((data) => {
            options.push({
                value: data.id,
                label: data.name
            });
          });
        }
        callback(options);
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The lease application page management component.
   */
  render() {
    const { formatMessage } = this.props.intl;
    return (
      <React.Fragment>
        <Alert
          validationList={this.state.validationList}
          validationType='primary'
        />

        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Lease Application Pages</div>
              <div className='col text-right'>
                <div
                  data-toggle='modal'
                  data-target='#lease-application-page'
                  className='btn btn-primary btn-sm'
                  onClick={() => this.initLeaseApplicationPage()}
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} className='fa-fw' />{' '}
                  Create Lease Application Page
                </div>
              </div>
            </div>
          </div>
          <Table
            columns={{ pageName: 'Page Name', pageUrl: 'Page URL' }}
            columnWidths={['40%', '60%']}
            headerClass='c-pointer'
            data={this.state.leaseApplicationPageList}
            query={this.state.leaseApplicationPageQuery}
            sortEnabled={false}
            recordsEnabled={true}
            paginationEnabled={true}
            updateFunction={this.searchLeaseApplicationPages}
          >
            <tbody>
              {this.state.leaseApplicationPageList.records.map((data, key) => {
                return (
                  <tr>
                    <td
                      className='c-pointer'
                      key={key}
                      onClick={() => this.editLeaseApplicationPage(data.id)}
                    >
                      <div className=''>{data.pageName}</div>
                    </td>
                    <td>
                      <div className=''>
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={'/apply/' + data.pageName}
                        >
                          {window.location.host}/apply/{data.pageName}
                        </a>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div
          className='modal fade'
          id='lease-application-page'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='lease-application-page-label'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered modal-lg'
            role='document'
          >
            <div className='modal-content shadow'>
              <form onSubmit={this.saveLeaseApplicationPage}>
                <div className='modal-header bg-dark text-white'>
                  <h5 className='modal-title' id='lease-application-page-label'>
                    {this.state.leaseApplicationPage['createDate'] == null && (
                      <span className=''>Create Lease Application Page</span>
                    )}
                    {this.state.leaseApplicationPage['createDate'] != null && (
                      <span className=''>Edit Lease Application Page</span>
                    )}
                  </h5>
                  <button
                    type='button'
                    className='close text-white'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'times']}
                      className='fa-fw va-b mr-2'
                    />
                  </button>
                </div>
                <div className='modal-body'>
                  <Alert
                    validationList={this.state.validationList}
                    validationType='danger'
                  />

                  <FieldText
                    id='pageName'
                    label='Page Name'
                    model='leaseApplicationPage'
                    parent={this}
                    value={this.state.leaseApplicationPage['pageName']}
                    pattern='[a-zA-Z0-9_\s]+'
                    onInvalidMessage='Only alphabets and numbers allowed'
                  />

                  <FieldSelect
                    id='applicationTemplate'
                    label='Page Template'
                    model='leaseApplicationPage'
                    parent={this}
                    value={this.state.leaseApplicationPage['applicationTemplate']}
                    required={true}
                    help={
                      !this.state.leaseApplicationTemplateList.records.length
                        ? 'No template available! Please create one to create lease application page.'
                        : ''
                    }
                  >
                    <option value=''>Select a template...</option>
                    {this.state.leaseApplicationTemplateList.records && (
                      <React.Fragment>
                        {this.state.leaseApplicationTemplateList.records.map(
                          (document, key) => {
                            return (
                              <option key={key} value={document.id}>
                                {document.templateName}
                              </option>
                            );
                          }
                        )}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldText
                    id='companyName'
                    label='Company Name'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['companyName']}
                  />

                  <FieldSelect
                    id='propertyLevel'
                    label='Properties'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['propertyLevel']}
                  >
                    {
                      !this.props?.company?.parentId && (
                        <option key='main-company' value='COMPANY'>
                          Main Company level
                        </option>
                      )
                    }
                    <option key='subsidiary' value='SUB_COMPANY'>
                      Subsidiary level
                    </option>
                    <option key='specific-property' value='PROPERTY'>
                      Specific Property level
                    </option>     
                  </FieldSelect>

                  { this.state.customFields?.propertyLevel === 'PROPERTY' &&
                    this.state.propertyList.length > 0 &&
                      <FieldPropertiesAsync
                        id="selectedPropertyList"
                        labelClass="col-form-label-sm align-self-center"
                        value={this.state.customFields?.selectedPropertyList}
                        parent={this}
                        options={this.state.propertyList}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        handleChange={this.handleChangeSelectedProperties}
                        loadOptions={lodash.debounce(this.searchProperties, 300)}
                      />
                  }   

                  { this.state.customFields?.propertyLevel === 'SUB_COMPANY' &&
                    this.state.subsidiaryList.length > 0 &&
                      <FieldPropertiesAsync
                        id="selectedSubsidiaryList"
                        labelClass="col-form-label-sm align-self-center"
                        value={this.state.customFields?.selectedSubsidiaryList}
                        parent={this}
                        options={this.state.subsidiaryList}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        handleChange={this.handleChangeSelectedSubsidiaries}
                        loadOptions={lodash.debounce(this.searchSubsidiaries, 300)}
                        placeholder="Search subsidiaries..."
                        formattedLabel={true}
                      />
                  }   

                  <FieldRange
                    id='rentRange'
                    label='Rent Range'
                    model='leaseApplicationPage'
                    parent={this}
                    minValue={this.state.leaseApplicationPage['rentMin']}
                    maxValue={this.state.leaseApplicationPage['rentMax']}
                    idMinValue='rentMin'
                    idMaxValue='rentMax'
                    prependChar='$'
                    min='0'
                    type='number'
                  />

                  <FieldText
                    id='applicationFeeAmount'
                    label='Application Fee Amount'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['applicationFeeAmount']}
                    required={this.state.leaseApplicationPage
                      ?.creditMerchantAccountList?.VISA || this.state.leaseApplicationPage
                      ?.creditMerchantAccountList?.UNION_PAY}
                    help={(this.state.leaseApplicationPage?.creditMerchantAccountList?.VISA || this.state.leaseApplicationPage?.creditMerchantAccountList?.UNION_PAY) && "Application fees is required if any of the merchant accounts is selected."}
                  />

                  {this.state.leaseApplicationPage
                    .creditMerchantAccountList && (
                    <React.Fragment>
                      <FieldSelect
                        id='VISA'
                        label='Credit/Debit Card Merchant Account'
                        parent={this}
                        help='Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards.'
                        value={
                          this.state.leaseApplicationPage
                            .creditMerchantAccountList['VISA']
                        }
                        handleChange={this.handleChangeCreditMerchantAccount}
                      >
                        <option value=''>-</option>
                        {this.state.visaMerchantAccounts && (
                          <React.Fragment>
                            {this.state.visaMerchantAccounts.map(
                              (data, key) => {
                                return (
                                  <option key={key} value={data.id}>
                                    {data.name} (
                                    {data.accountNumber
                                      ? data.accountNumber + ' — '
                                      : ''}
                                    {data.name ? data.name + ' — ' : ''}
                                    {data.last4
                                      ? '*****' + data.last4 + ' — '
                                      : ''}
                                    {formatMessage({
                                      id:
                                        'enum.merchantAccount.accountStatus.' +
                                        data.accountStatus,
                                    })}
                                    )
                                  </option>
                                );
                              }
                            )}
                          </React.Fragment>
                        )}
                      </FieldSelect>

                      {/*<FieldSelect
                        id='UNION_PAY'
                        label='UnionPay'
                        parent={this}
                        value={
                          this.state.leaseApplicationPage
                            .creditMerchantAccountList['UNION_PAY']
                        }
                        handleChange={this.handleChangeUnionPayMerchantAccount}
                      >
                        <option value=''>-</option>
                        {this.state.unionPayMerchantAccounts && (
                          <React.Fragment>
                            {this.state.unionPayMerchantAccounts.map(
                              (data, key) => {
                                return (
                                  <option key={key} value={data.id}>
                                    {data.accountNumber
                                      ? data.accountNumber + ' — '
                                      : ''}
                                    {data.name ? data.name + ' — ' : ''}
                                    {data.last4
                                      ? '*****' + data.last4 + ' — '
                                      : ''}
                                    {formatMessage({
                                      id:
                                        'enum.merchantAccount.accountStatus.' +
                                        data.accountStatus,
                                    })}
                                  </option>
                                );
                              }
                            )}
                          </React.Fragment>
                        )}
                      </FieldSelect> */}
                    </React.Fragment>
                  )}

                  {/* <FieldSelect
                                        id="bankMerchantAccountId"
                                        label="Bank Account"
                                        model="leaseApplicationPage"
                                        parent={this}
                                        value={
                                        this.state.leaseApplicationPage["bankMerchantAccountId"]
                                        }
                                    >
                                        <option value="">-</option>
                                        {this.state.bankMerchantAccounts && (
                                        <React.Fragment>
                                            {this.state.bankMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>
                                                {data.accountNumber
                                                    ? data.accountNumber + " — "
                                                    : ""}
                                                {data.name ? data.name + " — " : ""}
                                                {data.last4 ? "*****" + data.last4 + " — " : ""}
                                                {formatMessage({
                                                    id:
                                                    "enum.merchantAccount.accountStatus." +
                                                    data.accountStatus,
                                                })}
                                                </option>
                                            );
                                            })}
                                        </React.Fragment>
                                        )}
                                    </FieldSelect> */}

                  {/* <FieldSelect
                                        id="paypalMerchantAccountId"
                                        label="Paypal Account"
                                        model="leaseApplicationPage"
                                        parent={this}
                                        value={
                                        this.state.leaseApplicationPage["paypalMerchantAccountId"]
                                        }
                                    >
                                        <option value="">-</option>
                                        {this.state.paypalMerchantAccounts && (
                                        <React.Fragment>
                                            {this.state.paypalMerchantAccounts.map((data, key) => {
                                            return (
                                                <option key={key} value={data.id}>
                                                {data.accountNumber
                                                    ? data.accountNumber + " — "
                                                    : ""}
                                                {data.name ? data.name + " — " : ""}
                                                {data.last4 ? "*****" + data.last4 + " — " : ""}
                                                {formatMessage({
                                                    id:
                                                    "enum.merchantAccount.accountStatus." +
                                                    data.accountStatus,
                                                })}
                                                </option>
                                            );
                                            })}
                                        </React.Fragment>
                                        )}
                                    </FieldSelect> */}

                  <FieldText
                    id='heroHeading'
                    label='Heading'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['heroHeading']}
                  />

                  <FieldText
                    id='heroPoint1'
                    label='Point 1'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['heroPoint1']}
                  />

                  <FieldText
                    id='heroPoint2'
                    label='Point 2'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['heroPoint2']}
                  />

                  <FieldText
                    id='heroPoint3'
                    label='Point 3'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['heroPoint3']}
                  />

                  <FieldText
                    id='heroPoint4'
                    label='Point 4'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['heroPoint4']}
                  />

                  <FieldText
                    id='formSubheading'
                    label='Form Subheading'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['formSubheading']}
                  />

                  <FieldSelect
                    id='logo'
                    label='Logo'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['logo']}
                  >
                    <option value=''>Select a file...</option>
                    {this.state.documentList && (
                      <React.Fragment>
                        {this.state.documentList.map((document, key) => {
                          return (
                            <option key={key} value={document.url}>
                              {document.name}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <FieldSelect
                    id='background'
                    label='Background'
                    model='customFields'
                    parent={this}
                    value={this.state.customFields['background']}
                  >
                    <option value=''>Select a file...</option>
                    {this.state.documentList && (
                      <React.Fragment>
                        {this.state.documentList.map((document, key) => {
                          return (
                            <option key={key} value={document.url}>
                              {document.name}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    )}
                  </FieldSelect>

                  <h4>Screenings</h4>
                      <FieldCheckbox
                        id='creditReportEnabled'
                        label='Credit Check'
                        // fieldLabel={this.state.creditServiceFee && `(costs $${(parseFloat(this.state.creditServiceFee.feeAmount) + parseFloat((((this.state.creditServiceFee.taxRate1 / 100) * this.state.creditServiceFee.feeAmount).toFixed(2))))} each time)`}
                        model='leaseApplicationPage'
                        parent={this}
                        value={this.state.leaseApplicationPage['creditReportEnabled']}
                        handleChange={this.creditReportHandleChange}
                        />
                      <FieldCheckbox
                        id='backgroundCheckEnabled'
                        label='Background Check'
                        model='leaseApplicationPage'
                        // fieldLabel={this.state.backgroundServiceFee && `(costs $${(parseFloat(this.state.backgroundServiceFee.feeAmount) + parseFloat((((this.state.backgroundServiceFee.taxRate1 / 100) * this.state.backgroundServiceFee.feeAmount).toFixed(2))))} each time)`}
                        parent={this}
                        value={this.state.leaseApplicationPage['backgroundCheckEnabled']}
                        handleChange={this.backgroundCheckHandleChange}
                      />
                      <FieldCheckbox
                        id='incomeVerificationEnabled'
                        label='Income and Identity Verification'
                        model='leaseApplicationPage'
                        parent={this}
                        value={this.state.leaseApplicationPage['incomeVerificationEnabled']}
                        disabled={false}
                        handleChange={this.incomeVerificationHandleChange}
                      />
                </div>
                <div className='modal-footer bg-secondary rounded-bottom d-block'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-lg'
                        onClick={() =>
                          $('#lease-application-page').modal('hide')
                        }
                      >
                        Close
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      {this.state.leaseApplicationPage['createDate'] !=
                        null && (
                        <div
                          className='btn btn-lg btn-primary ml-2'
                          onClick={() => {
                            $('#lease-application-page').modal('hide');
                            $('#delete-lease-application-page').modal('show');
                          }}
                        >
                          Delete
                        </div>
                      )}

                      {this.state.leaseApplicationPage['createDate'] !=
                        null && (
                        <div
                          className='btn btn-lg btn-primary ml-2'
                          onClick={() => {
                            $('#lease-application-page').modal('hide');
                            $('#lease-application-page-documents').modal(
                              'show'
                            );
                          }}
                        >
                          Upload Files...
                        </div>
                      )}

                      <ButtonSave />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className='modal fade'
          id='lease-application-page-documents'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='lease-application-page-documents-label'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered modal-md'
            role='document'
          >
            <div className='modal-content shadow'>
              <form onSubmit={this.uploadDocument}>
                <div className='modal-header bg-dark text-white'>
                  <h5
                    className='modal-title'
                    id='lease-application-page-documents-label'
                  >
                    Upload Files
                  </h5>
                  <button
                    type='button'
                    className='close text-white'
                    data-dismiss='modal'
                    aria-label='Close'
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'times']}
                      className='fa-fw va-b mr-2'
                    />
                  </button>
                </div>
                <div className='modal-body bg-secondary'>
                  <p className='mb-0'>
                    Files uploaded below will be made available when editing
                    image-related fields for this lease application page.
                  </p>
                </div>

                <div className='modal-body'>
                  <Alert
                    validationList={this.state.validationList}
                    validationType='danger'
                  />

                  {this.state.documentList.map((document, key) => {
                    return (
                      <div key={key} className='list-group mb-2'>
                        <div
                          className='list-group-item list-group-item-action c-pointer'
                          onClick={() => this.selectDocument(document)}
                        >
                          <div className='row align-items-center'>
                            <div className='col-2 text-center'>
                              <img
                                src={document.url}
                                alt={document.name}
                                className='img-fluid'
                              />
                            </div>
                            <div className='col-8'>
                              <div className=''>{document.name}</div>
                              <small className='mb-0 ml-md-0 small text-muted'>
                                Uploaded on{' '}
                                <Moment format='MMM DD, YYYY HH:mm'>
                                  {document.createDate}
                                </Moment>
                              </small>
                            </div>
                            <div className='col-2 text-right'>
                              <div className='float-right'>
                                <FontAwesomeIcon
                                  icon={['far', 'trash-alt']}
                                  className='fa-fw'
                                  size='1x'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <FieldFile
                    id='file'
                    label='File'
                    model='document'
                    labelClass='d-none mb-0'
                    fieldClass='form-control-lg mb-0'
                    fieldColumns='12'
                    labelColums='0'
                    parent={this}
                    value={this.state.document['file']}
                  />
                </div>
                <div className='modal-footer bg-secondary rounded-bottom d-block'>
                  <div className='row'>
                    <div className='col-4'>
                      <button
                        type='button'
                        className='btn btn-outline-primary btn-lg'
                        onClick={() => {
                          $('#lease-application-page-documents').modal('hide');
                          $('#lease-application-page').modal('show');
                        }}
                      >
                        Back
                      </button>
                    </div>
                    <div className='col-8 text-right'>
                      <button
                        type='submit'
                        className='btn btn-primary btn-lg ml-2'
                      >
                        Upload File
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="modal fade" id="credit-report-popup" tabIndex="-1" role="dialog" aria-labelledby="credit-report-popup-label" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
              <div className="modal-content shadow">
                  <div className="modal-header bg-dark text-white">
                      <h5 className="modal-title" id="credit-report-popup-label">
                          Credit Check
                      </h5>
                      <button type="button" className="close text-white" onClick={(e) => this.validateCreditReportState(e)} aria-label="Close">
                          <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                      </button>
                  </div>

                  <div className="modal-body bg-secondary">
                      <p className="mb-0">
                        For each credit check, company’s payment method on file will be charged at the end of the billing cycle for the amount detailed below.
                      </p>
                  </div>

                  <div className="modal-body modal-body-table">
                  <table className="table mb-0">
                      <tbody>
                      <tr>
                          <td className="">
                              Equifax Credit Check <span className="small text-muted">(Qty: 1)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={this.state.creditServiceFee?.feeAmount} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="">
                              {this.state.creditServiceFee?.taxJurisdiction1} <span className="small text-muted">({this.state.creditServiceFee?.taxRate1}%)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={(((this.state.creditServiceFee?.taxRate1 / 100) * this.state.creditServiceFee?.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="font-weight-bold">
                              Total
                          </td>
                          <td className="text-right font-weight-bold">
                              <FormattedNumber value={(parseFloat(this.state.creditServiceFee?.feeAmount) + parseFloat((((this.state.creditServiceFee?.taxRate1 / 100) * this.state.creditServiceFee?.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      
                      </tbody>
                  </table>
                  </div>

                  <div className="modal-footer bg-secondary rounded-bottom d-block">
                      <div className="row">
                          <div className="col-12 text-center">
                              <div className="btn btn-primary btn-lg" onClick={() => $('#credit-report-popup').modal('hide')}>
                                  Agree
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>

      <div className="modal fade" id="background-check-popup" tabIndex="-1" role="dialog" aria-labelledby="background-check-popup-label" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
              <div className="modal-content shadow">
                  <div className="modal-header bg-dark text-white">
                      <h5 className="modal-title" id="background-check-popup-label">
                          Background Check
                      </h5>
                      <button type="button" className="close text-white" onClick={(e) => this.validateBackGroundState(e)} aria-label="Close">
                          <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                      </button>
                  </div>

                  <div className="modal-body bg-secondary">
                      <p className="mb-0">
                        For each background check, company’s payment method on file will be charged at the end of the billing cycle for the amount detailed below.
                      </p>
                  </div>

                  <div className="modal-body modal-body-table">
                  <table className="table mb-0">
                      <tbody>
                      <tr>
                          <td className="">
                              Certn Background Check <span className="small text-muted">(Qty: 1)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={this.state.backgroundServiceFee?.feeAmount} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="">
                              {this.state.backgroundServiceFee?.taxJurisdiction1} <span className="small text-muted">({this.state.backgroundServiceFee?.taxRate1}%)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={(((this.state.backgroundServiceFee?.taxRate1 / 100) * this.state.backgroundServiceFee?.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="font-weight-bold">
                              Total
                          </td>
                          <td className="text-right font-weight-bold">
                              <FormattedNumber value={(parseFloat(this.state.backgroundServiceFee?.feeAmount) + parseFloat((((this.state.backgroundServiceFee?.taxRate1 / 100) * this.state.backgroundServiceFee?.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      
                      </tbody>
                  </table>
                  </div>

                  <div className="modal-footer bg-secondary rounded-bottom d-block">
                      <div className="row">
                          <div className="col-12 text-center">
                              <div className="btn btn-primary btn-lg" onClick={() => $('#background-check-popup').modal('hide')}>
                                  Agree
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>

      <div className="modal fade" id="income-verification-popup" tabIndex="-1" role="dialog" aria-labelledby="background-check-popup-label" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-md" role="document">
              <div className="modal-content shadow">
                  <div className="modal-header bg-dark text-white">
                      <h5 className="modal-title" id="background-check-popup-label">
                          Income and Identity Verification
                      </h5>
                      <button type="button" className="close text-white" onClick={(e) => this.validateIncomeState(e)} aria-label="Close">
                          <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                      </button>
                  </div>

                  <div className="modal-body bg-secondary">
                      <p className="mb-0">
                        For each Income and Identity Verification, company’s payment method on file will be charged at the end of the billing cycle for the amount detailed below.
                      </p>
                  </div>

                  <div className="modal-body modal-body-table">
                  <table className="table mb-0">
                      <tbody>
                      <tr>
                          <td className="">
                              Income and Identity Verification <span className="small text-muted">(Qty: 1)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={this.state.incomeServiceFee?.feeAmount} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="">
                              {this.state.incomeServiceFee?.taxJurisdiction1} <span className="small text-muted">({this.state.incomeServiceFee?.taxRate1}%)</span>
                          </td>
                          <td className="text-right">
                              <FormattedNumber value={(((this.state.incomeServiceFee?.taxRate1 / 100) * this.state.incomeServiceFee?.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      <tr>
                          <td className="font-weight-bold">
                              Total
                          </td>
                          <td className="text-right font-weight-bold">
                              <FormattedNumber value={(parseFloat(this.state.incomeServiceFee?.feeAmount) + parseFloat((((this.state.incomeServiceFee?.taxRate1 / 100) * this.state.incomeServiceFee?.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                          </td>
                      </tr>
                      
                      </tbody>
                  </table>
                  </div>

                  <div className="modal-footer bg-secondary rounded-bottom d-block">
                      <div className="row">
                          <div className="col-12 text-center">
                              <div className="btn btn-primary btn-lg" onClick={() => $('#income-verification-popup').modal('hide')}>
                                  Agree
                              </div>
                          </div>
                      </div>

                  </div>

              </div>
          </div>
      </div>

      <Modal
        id='delete-lease-application-page'
        theme='danger'
        iconType='fas'
        iconName='exclamation-triangle'
        title='Delete Lease Application Page'
        body='Are you sure you would like to delete this lease application page?'
      >
        <button
          type='button'
          className='btn btn-outline-danger btn-lg'
          data-dismiss='modal'
          onClick={() => {
            $('#lease-application-page').modal('show');
          }}
        >
          <FormattedMessage id='button.close' />
        </button>
        <button
          onClick={() => {
            this.deleteLeaseApplicationPage();
          }}
          className='btn btn-danger btn-lg'
          data-dismiss='modal'
        >
          Delete Lease Application Page
        </button>
      </Modal>

      <Modal
        id='delete-document'
        theme='danger'
        iconType='fas'
        iconName='exclamation-triangle'
        title='Delete File'
        body={`Are you sure you would like to delete ${this.state.document.name}? Lease application page elements currently utilizing this file may appear broken as a result, and will require manual review.`}
      >
        <button
          type='button'
          className='btn btn-outline-danger btn-lg'
          data-dismiss='modal'
          onClick={() => {
            $('#lease-application-page-documents').modal('show');
          }}
        >
          <FormattedMessage id='button.close' />
        </button>
        <button
          onClick={() => {
            this.deleteDocument();
          }}
          className='btn btn-danger btn-lg'
          data-dismiss='modal'
        >
          Delete File
        </button>
      </Modal>
    </React.Fragment>
    );
  }
}
export default injectIntl(LeaseApplicationPages);
// export default LeaseApplicationPages;
