import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import * as constants from '../../util/constants';

/**
 * Google Login component based on the newer Google Identity Services library 
 * @returns 
 */
const GoogleLoginCustom = ({ submitOAuth }) => {

  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Handle credential response of the new Google Sign-In library - Google Identity Services library
   */
  const handleGoogleCredentialResponse = (response) => {
    const credential = {
      code: response.credential
    }
    submitOAuth(credential);
  }

  window.handleGoogleCredentialResponse = handleGoogleCredentialResponse;

  return (
    <div className='d-flex justify-content-center'>
      <div
        id='g_id_onload'
        data-client_id={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
        data-context='signin'
        data-ux_mode='popup'
        data-callback={'handleGoogleCredentialResponse'}
        data-auto_prompt="false"
      ></div>

      <div
        className='g_id_signin'
        data-type='standard'
        data-shape='rectangular'
        data-theme='outline'
        data-text='signin_with'
        data-size='large'
        data-logo_alignment='left'
      ></div>
    </div>
  );
};

GoogleLoginCustom.propTypes = {
  submitOAuth: PropTypes.func.isRequired,
};

export default GoogleLoginCustom;
