import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager tenants component.
     */
    render() {
        let hasPermission = this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('SUPPORT_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('LEASING_MANAGER');

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Tenants" />

                    <TenantList userType={this.props.userSession.sessionRole.type}
                                managerRoles={this.props.userSession.sessionRole?.roles}
                                userId={this.props.userSession.sessionRole.id}
                                textSearchPlaceholderOverrideTenants = "Search All Tenants"
                                textSearchPlaceholderOverrideInvitations = "Search All Invitations"
                                history={this.props.history}
                                inviteTenant={true}
                                createTenant={true}
                                showOnboardingSection={hasPermission}
                                parent={this} />

                </div>

            </div>
        )
    };
}

export default Tenants;