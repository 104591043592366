import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import ApplicationList from "../../common/LeaseApplications";
import Propertii from "../../common/Propertii";

class Applications extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager applications component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Applications" />

                    <ApplicationList userType={this.props.userSession.sessionRole.type}
                                     userId={this.props.userSession.sessionRole.id}
                                     companyId={this.props.userSession.sessionRole.companyId}
                                     newApplicant={true}
                                     history={this.props.history}
                                     parent={this} />

                </div>

            </div>
        )
    };
}

export default Applications;