import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";

class FieldSelect extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;
        
        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            if(this.props.arrayIndex || this.props.arrayIndex === 0) {
                field[this.props.arrayIndex][event.target.name] = event.target.value;
            } else {
                field[event.target.name] = event.target.value;
            }
        }

        if(this.props.model == null) {
            field = event.target.value;
        }
        
        if(this.props.arrayIndex || this.props.arrayIndex === 0) {
            this.props.parent.setState(prevState => ({
                ...prevState,
                [this.props.model[this.props.arrayIndex]]: field,
            }));
        } else {
            this.props.parent.setState(({
                [this.props.model ? this.props.model : event.target.name]: field,
            }));
        } 
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic select field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';
        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        return(
            <div className={`form-group row ${this.props.containerClass}`}>
                {!!this.props.label &&
                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label}{this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && !this.props.leaseApplicationPage && <small className="text-muted font-italic">*</small>}
                        {this.props.tooltipText &&
                            <FontAwesomeIcon icon={['fas', 'info-circle']} className="fa-fw ml-1"data-toggle="tooltip" data-html={true} data-placement="top" title={this.props.tooltipText} />
                        }
                    </label>
                }

                <div className={`col-sm-${this.props.fieldColumns}`}>

                    <select
                        id={this.props.id}
                        name={this.props.id}
                        value={this.props.value || ""}
                        onChange={this.props.handleChange || this.handleChange}
                        onFocus={this.props.handleFocus}
                        onBlur={this.props.handleBlur}
                        onClick={this.props.onClick}
                        disabled={this.props.disabled}
                        multiple={this.props.multiple}
                        required={this.props.required}
                        className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''} ${this.props.disabled ? 'DisabledInputField' : ''}`}
                        style={this.props.fieldStyle}>

                        {this.props.children}

                    </select>

                    { this.props.AddnlBtn1Text && this.props.AddnlBtn1Type && this.props.AddnlBtn1Handler &&
                            <button type="button" className={`btn btn-${this.props.AddnlBtn1Type} btn-sm float-right`} onClick={this.props.AddnlBtn1Handler}><small><u>{this.props.AddnlBtn1Text}</u></small></button>
                        }

                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldSelect.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    fieldStyle: {},
};

export default FieldSelect;
