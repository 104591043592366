import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import CustomFieldList from "../../common/CustomFieldList";
import Propertii from "../../common/Propertii";

class CustomFields extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            customer: {},
            validationList: [],
        };
    }

    /**
     * Retrieve the property lease and customer based on the property lease ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    propertyLease: propertyLease,
                    customer: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view customer custom fields component.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary" />

                {this.state.customer.id &&
                <CustomFieldList intl={this.props.intl} token={this.props.token} parent={this} recordType="CUSTOMER"
                                 recordId={this.state.customer.id}
                                 createMessage="admin.companies.fields.created"
                                 deleteMessage="admin.companies.fields.deleted"/>
                }

            </React.Fragment>
        )
    };
}

CustomFields.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CustomFields);