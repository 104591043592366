import * as constants from '../util/constants';
import axios from "axios";


const PaypalSdkLibrary = {

    /**
     * Generate a request header for making API calls. Returns a JSON object containing the Content-Type, defaulted to
     * 'application/json', and Authorization parameters commonly used when making calls.
     *
     * @returns - A basic request header containing the Content-Type and Authorization params.
     */
    generateRequestHeaders() {
        return {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
        };
    },
    
    /**
     * Append paypal sdk script, and displays PayPal button
     * @param {*} orderId 
     * @param {*} token 
     * @param {*} postCreatePayPalPaymentMethod - callback to store the paypal to DB
     */
    appendPaypalSdkScript(orderId, token, postCreatePayPalPaymentMethod) {
        const clientId = constants.REACT_APP_PAYPAL_CLIENT_ID;
        let scriptSource = `https://www.paypal.com/sdk/js?client-id=${clientId}&components=buttons&vault=true&disable-funding=credit`

        const currentScript = document.getElementById('paypal-script');
        if (currentScript) {
            document.body.removeChild(currentScript);
        }
        
        const script = document.createElement('script');
        script.src = scriptSource;
        script.dataset.orderId = orderId;

        script.setAttribute('id', 'paypal-script');
        document.body.appendChild(script);

        const injectScript = () => {
            script.removeEventListener('load', injectScript);
            const buttons = window.paypal.Buttons({
                style: {
                    layout: 'horizontal',
                    height: 38,
                    tagline: 'false', 
                },
                createBillingAgreement: () => {
                    return token;
                },
                onApprove: (onApproveData) => {
                    postCreatePayPalPaymentMethod(onApproveData.billingToken);
                },
            });
            buttons.render('#paypal-button');
        };

        script.addEventListener('load', injectScript);
        script.addEventListener('error', () => {
        });
    },

    /**
     * Initialize Paypal billing agreement
     * @param {*} userType 
     * @param {*} userId 
     * @param {*} postCreatePayPalPaymentMethod - Initialize paypal billing agreement
     */
    initializePaypalBillingAgreement(userType, userId, postCreatePayPalPaymentMethod) {
        this.postPaypalCreateAgreement(userType, userId)
        .then(response => {
            const paypalCreateAgreementResponse = response.data;
            if(paypalCreateAgreementResponse) {
                this.appendPaypalSdkScript('', paypalCreateAgreementResponse.token, postCreatePayPalPaymentMethod);
            }
        })
        .catch(() => {
        });
    },

    /**
     * Creates paypal agreement
     * @param {*} userType 
     * @param {*} userId 
     */
    postPaypalCreateAgreement(userType, userId) {
        const query = {
            userType: userType,
            userId: userId,
        };
        return axios.get(`${constants.REACT_APP_HOST_API_URL}/paypal_create_agreement`,{
            headers: this.generateRequestHeaders(),
            params: query
        });
    },

    /**
     * Create an order with Paypal
     *
     * @param {*} amount 
     * @param {*} billingAccountId 
     * @param {*} billingAccountType 
     * 
     * @returns Promise
     */
    createPaypalOrder(amount, billingAccountId, billingAccountType) {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/paypal_create_order`,  {
            "amount": amount,
            "billingAccountId": billingAccountId,
            "billingAccountType": billingAccountType,
        },{
            headers: this.generateRequestHeaders(),
        })
        .then(response => response)
        .catch(() => {
        });
    },

    /**
     * Capture an order already created by orderId
     * 
     * @param {*} amount 
     * @param {*} billingAccountId 
     * @param {*} billingAccountType 
     * @param {*} orderId
     * 
     * @returns Promise 
     */
    capturePaypalOrder(amount, billingAccountId, billingAccountType, orderId) {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/paypal_capture_order`,  {
            "amount": amount,
            "billingAccountId": billingAccountId,
            "billingAccountType": billingAccountType,
            "orderId": orderId,
        },{
            headers: this.generateRequestHeaders(),
        })
        .then(response => response)
        .catch(() => {
        });
    }
};

export default PaypalSdkLibrary;
