import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";

class PaypalReports extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            paypalReport: {},

            paypalReportList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paypalReportQuery: {
                orderBy: 'ASC',
                orderByFields: ['name'],
                conditionList: [
                    {
                        type: "STRING",
                        logicalOperator: "AND",
                        operator: "EQUALS",
                        fieldName: "recordType",
                        fieldValue: "TYPE_PAYMENT_PROVIDER"
                    },
                    {
                        type: "STRING",
                        logicalOperator: "AND",
                        operator: "EQUALS",
                        fieldName: "recordId",
                        fieldValue: "PAYPAL"
                    }
                ]
            },

            validationList: [],

        };

        this.searchPaypalReports = this.searchPaypalReports.bind(this);
    }

    /**
     * Load the list of Paypal reports on mounting of the component.
     */
    componentDidMount() {

        this.searchPaypalReports(1, 25, this.state.paypalReportQuery);
    }


    /**
     * Update the data table of Paypal reports.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaypalReports(page, recordsPerPage, query) {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/document/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paypalReportList: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download paypal report.
     *
     * @param documentId - The documentId to download.
     */
    handleDownloadReport(documentId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/document/${documentId}/download`, {
            headers: this.generateRequestHeaders(),
            responseType: 'arraybuffer'
        }).then(response => {
            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Paypal Reports" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Paypal Reports
                                </div>
                            </div>
                        </div>
                        <Table columns={{createDate: 'Create Date', updateDate: 'Update Date', name: 'Name', download: 'Download Report'}}
                               columnWidths={['25%', '25%', '25%', '25%']}
                               headerClass="c-pointer"
                               data={this.state.paypalReportList}
                               query={this.state.paypalReportQuery}
                               sortEnabled={true}
                               removeSortForColumn={'download'}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchPaypalReports}>
                            <tbody>
                            {this.state.paypalReportList.records.map((data, key) => {
                                return(
                                    <tr key={key}>
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY" tz="UTC">
                                                    {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY" tz="UTC">
                                                    {data.updateDate}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.name}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-center">
                                                <div className="btn btn-primary btn-sm c-pointer" onClick={() => this.handleDownloadReport(data.id)}>
                                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default PaypalReports;