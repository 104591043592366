import React from 'react';
import Propertii from "../../common/Propertii";
import ScreeningList from "../../common/Screenings";

class Screenings extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The screening component.
     */
    render() {

        return(
            <React.Fragment>

                <ScreeningList companyId={this.props.userSession.sessionRole.companyId} leaseApplicationId={this.props.match.params.leaseApplicationId} leaseApplicationCompanyId={this.props.leaseApplication.companyId}/>

            </React.Fragment>
        )
    };
}

export default Screenings;