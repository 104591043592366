import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedNumber, injectIntl, intlShape } from "react-intl";
import certn from "../../media/img/logos/certn.png";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import FieldText from "./FieldText";
import lodash from "lodash";

class Screenings extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            serviceFee: {},
            basicScreeningDetails: null,
            creditScreeningDetails: null,
            rentifyScreeningDetails: null,
            validationList: [],
            selectedTab: 'SUMMARY',
            paymentStatusAmount: 0,
            paymentStatusStatus: '',
            rentMax:0,
            rentMin:0,
            inputRentMin:'',
            inputRentMax:'',
            rentAffordabilityMin:null,
            rentAffordabilityMax:null,
            totalScreenings: 0,
        };

        this.initScreening = this.initScreening.bind(this);
        this.searchScreenings = this.searchScreenings.bind(this);
        this.getScreeningFee = this.getScreeningFee.bind(this);
        this.getScreeningReport = this.getScreeningReport.bind(this);
        this.selectTab = this.selectTab.bind(this);
        this.calculateRentAffordability = lodash.debounce(this.calculateRentAffordability, 400);
        this.handleChangeRent = this.handleChangeRent.bind(this);
        this.mapScreeningStatus = this.mapScreeningStatus.bind(this);
    }

    /**
     * On mounting of the component, perform a search for BASIC and CREDIT screenings.
     */
    componentDidMount() {

        this.searchScreenings('BASIC');
        this.searchScreenings('CREDIT');
        this.getRentifyData();
        this.searchPaymentStatus();
    }

    /**
     * Search for screening results by screening type.
     *
     * @param screenType - The type of screening (BASIC, CREDIT, etc.)
     */
    searchScreenings(screenType) {

        let screeningQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'leaseApplicationId',
                    operator: 'EQUALS',
                    fieldValue: this.props.leaseApplicationId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'screenType',
                    operator: 'EQUALS',
                    fieldValue: screenType
                }
            ],
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/screening/search`, {
            orderBy: screeningQuery.orderBy,
            orderByFields: screeningQuery.orderByFields,
            conditionList: screeningQuery.conditionList,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            
            if(response.data.records.length > 0) {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: true
                }));

                axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.records[0].id}/details`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    if (screenType === 'BASIC') {
                        this.setState(prevState => ({
                            ...prevState,
                            basicScreeningDetails: response.data,
                            totalScreenings: prevState.totalScreenings + 1
                        }));
                    }

                    if (screenType === 'CREDIT') {
                        this.setState(prevState => ({
                            ...prevState,
                            creditScreeningDetails: response.data,
                            totalScreenings: prevState.totalScreenings + 1
                        }));
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    getRentifyData() {
        let screeningQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'leaseApplicationId',
                    fieldValue: this.props.leaseApplicationId
                }
            ],
        };
        this.setState(prevState => ({
            ...prevState,
            spinnerRentify: true
        }));
        axios.post(`${constants.REACT_APP_HOST_API_URL}/INCOME_VERIFICATION/search`, {
            orderBy: screeningQuery.orderBy,
            orderByFields: screeningQuery.orderByFields,
            conditionList: screeningQuery.conditionList,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data.records.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    rentifyScreeningDetails: response.data.records[0],
                    totalScreenings: prevState.totalScreenings + 2
                }));
            }
            this.setState(prevState => ({
                ...prevState,
                spinnerRentify: false
            }));
        }).catch(error => {
            this.setState(prevState => ({
                ...prevState,
                spinnerRentify: false
            }));
            this.handleValidation(error);
        });
    }

    searchPaymentStatus() {
        this.setState(prevState => ({
            ...prevState,
            spinnerPaymentStatus: true,
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.leaseApplicationId
                }
            ],
            joins: {
                payment: {
                    targetRecordType: "TYPE_PAYMENT_TRANSACTION",
                    joinField: "id",
                    targetField: "leaseApplicationId",
                    alias: "payment",
                    returnFields: [
                        "amount", "status"
                    ]
                },
                leaseapplicationpage:{
                    targetRecordType: "TYPE_LEASE_APPLICATION_PAGE",
                    joinField: "applicationPageId",
                    targetField: "id",
                    alias: "leaseapplicationpage",
                    returnFields: [
                        "rentMax","rentMin", "identityVerificationEnabled", "incomeVerificationEnabled"
                    ]
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            
            if(response.data.records.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    paymentStatusAmount: response.data.records[0].joins?.payment?.amount,
                    paymentStatusStatus: response.data.records[0].joins?.payment?.status,
                    rentMax: response.data.records[0].joins?.leaseapplicationpage?.rentMax,
                    rentMin: response.data.records[0].joins?.leaseapplicationpage?.rentMin,
                    identityVerificationEnabled: response.data.records[0].joins?.leaseapplicationpage?.identityVerificationEnabled,
                    incomeVerificationEnabled: response.data.records[0].joins?.leaseapplicationpage?.incomeVerificationEnabled,
                    spinner: false,
                }));
                
                this.calculateRentAffordability(response.data?.records[0]?.joins?.leaseapplicationpage?.rentMin, response.data?.records[0]?.joins?.leaseapplicationpage?.rentMax, this.props.leaseApplicationId);
            }
            this.setState(prevState => ({
                ...prevState,
                spinnerPaymentStatus: false,
            }));
        }).catch(error => {
            this.setState(prevState => ({
                ...prevState,
                spinnerPaymentStatus: false,
            }));
            this.handleValidation(error);
        });
    }

     /**
     * Calculate the rent affordability according a rent range.
     *
     * @param rentMin - The rent min of a rent range
     * @param rentMax - The rent max of a rent range
     * @param leaseApplicationId- Lease Application id
     */
      calculateRentAffordability(rentMin, rentMax, leaseApplicationId) {
        if(leaseApplicationId && (rentMin || rentMax)){
            axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${leaseApplicationId}/summary?${rentMin !== null ? `rentMin=${rentMin}` : ''}${rentMax !== null ? `&rentMax=${rentMax}` : ''}`, { 
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    rentAffordabilityMin: response.data.rentAffordabilityMin * 100 || null,
                    rentAffordabilityMax: response.data.rentAffordabilityMax * 100 || null,
                }));
            }).catch(error => {
                this.handleValidation(error);
            });
        }
        
    }

    /**
     * Handle change events on rent
     *
     * @param event - The event container.
     */
     handleChangeRent(event) {
        let name = event.target.name;
        let value = event.target.value || '';
        
        if(name === "inputRentMin") {
            this.setState(prevState => ({
                ...prevState,
                inputRentMin: value,
            }));
            if(this.state.inputRentMax)
                this.calculateRentAffordability(value, this.state.inputRentMax, this.props.leaseApplicationId);
        } else {
            this.setState(prevState => ({
                ...prevState,
                inputRentMax: value,
            }));
            if(this.state.inputRentMin)
                this.calculateRentAffordability(this.state.inputRentMin, value, this.props.leaseApplicationId);
        }

    }


    /**
     * Fetch the fee for a particular screening type from the invoicing service.
     *
     * @param screenType - The type of screening to fetch the fee for.
     */
    getScreeningFee(screenType) {

        let companyId = this.props.companyId;
        if(this.props.leaseApplicationCompanyId) {
            companyId = this.props.leaseApplicationCompanyId;
        }

        axios.get(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/billing/fees/code/${screenType}?companyId=${companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                serviceFee: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a detailed screening report.
     *
     * @param screeningId - The ID of the screening to fetch a report for.
     */
    getScreeningReport(screeningId) {

        this.setState(prevState => ({
            spinner: true
        }));
        
        const headers = this.generateRequestHeaders();
        headers['Content-Type'] = 'application/octet-stream'
        axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${screeningId}/report`, {
            headers,
            responseType: 'arraybuffer'
        }).then(response => {
            const FileDownload = require('js-file-download');
            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = 0;
            if (responseHeader.indexOf("filename =") !== -1) {
                startIndex = responseHeader.indexOf("filename =") + 11;
            } else if (responseHeader.indexOf("filename=") !== -1) {
                startIndex = responseHeader.indexOf("filename=") + 10;
            } else {
                this.setState(prevState => ({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                        },
                    }],
                }));
                return
            }
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);
            FileDownload(response.data, filename);

            this.setState(prevState => ({
                spinner: false
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Initialize a new screening on the lease application.
     *
     * @param screenType - The type of screening to perform, such as a background or credit check.
     */
    initScreening(screenType) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/screen`, {
            leaseApplicationId: this.props.leaseApplicationId,
            screenType: screenType
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,

                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.screening.' + screenType.toLowerCase() + '.created'
                    },
                }],
            }));

            this.searchScreenings(screenType);

            $('#screening-basic').modal('hide');
            $('#screening-credit').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

        /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
    selectTab(tabId) {

        switch(tabId) {
            case "SUMMARY":
                $('#identity').tab('dispose');
                $('#background').tab('dispose');
                $('#credit').tab('dispose');
                $('#income').tab('dispose');
                $('#summary').tab('show');
                break;
            case "IDENTITY":
                $('#summary').tab('dispose');
                $('#background').tab('dispose');
                $('#credit').tab('dispose');
                $('#income').tab('dispose');
                $('#identity').tab('show');
                break;
            case "BACKGROUND":
                $('#summary').tab('dispose');
                $('#identity').tab('dispose');
                $('#credit').tab('dispose');
                $('#income').tab('dispose');
                $('#background').tab('show');
                break;
            case "CREDIT":
                $('#summary').tab('dispose');
                $('#identity').tab('dispose');
                $('#background').tab('dispose');
                $('#income').tab('dispose');
                $('#credit').tab('show');
                break;
            case "INCOME":
                $('#summary').tab('dispose');
                $('#identity').tab('dispose');
                $('#background').tab('dispose');
                $('#credit').tab('dispose');
                $('#income').tab('show');
                break;
            default:
                $('#identity').tab('dispose');
                $('#background').tab('dispose');
                $('#credit').tab('dispose');
                $('#income').tab('dispose');
                $('#summary').tab('show');
                break;
        }
    }

    getCompletedScreenings(){
        let count = 0;
        if(this.state.basicScreeningDetails?.completeDate) count ++
        if(this.state.creditScreeningDetails?.completeDate) count ++
        if(this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS") count ++
        if(this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS") count ++
        return count
    }

    /**
     * Map the screening status.
     * Note that this change cannot be done on BE side since this change
     * could impact the flow of the process.
     * 
     * @param status - The status of the screening
     */
    mapScreeningStatus(status){
        if (status === 'INPROCESS'){
            return 'In-Process';
        }else{
            return status;
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - The screening component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner || this.state.spinnerRentify || this.state.spinnerPaymentStatus} />

                <Alert validationList={this.state.validationList} />

                <div className="card accordion" id="navigation">
                    <div className="card-header pb-0">
                        <ul className="nav nav-tabs border-bottom-0">
                            <li className="nav-item">
                                <a className={`nav-link orange ${this.state.selectedTab === 'SUMMARY' ? 'active' : ''}`} id="summary-tab" data-toggle="tab" href="#summary" role="tab" aria-controls="summary" aria-selected="true" onClick={() => this.selectTab('SUMMARY')}>
                                    Summary
                                </a>
                            </li>
                            { this.state.identityVerificationEnabled &&
                            <li className="nav-item">
                                <a className={`nav-link orange ${this.state.selectedTab === 'IDENTITY' ? 'active' : ''}`} id="identity-tab" data-toggle="tab" href="#identity" role="tab" aria-controls="identity" aria-selected="true" onClick={() => this.selectTab('IDENTITY')}>
                                    Identity Check
                                </a>
                            </li>
                            }
                            <li className="nav-item">
                                <a className={`nav-link orange ${this.state.selectedTab === 'BACKGROUND' ? 'active' : ''}`} id="background-tab" data-toggle="tab" href="#background" role="tab" aria-controls="background" aria-selected="true" onClick={() => this.selectTab('BACKGROUND')}>
                                    Background Check
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link orange ${this.state.selectedTab === 'CREDIT' ? 'active' : ''}`} id="credit-tab" data-toggle="tab" href="#credit" role="tab" aria-controls="credit" aria-selected="true" onClick={() => this.selectTab('CREDIT')}>
                                    Credit Check
                                </a>
                            </li>
                            { this.state.incomeVerificationEnabled &&
                            <li className="nav-item">
                                <a className={`nav-link orange ${this.state.selectedTab === 'INCOME' ? 'active' : ''}`} id="income-tab" data-toggle="tab" href="#income" role="tab" aria-controls="income" aria-selected="true" onClick={() => this.selectTab('INCOME')}>
                                    Income Check
                                </a>
                            </li>
                            }
                        </ul>
                    </div>
                    <div className="tab-content" id="tabs">
                        <div className={`tab-pane mb-4 fade-show ${this.state.selectedTab === 'SUMMARY' ? 'active' : ''}`} id="summary" role="tabpanel" aria-labelledby="summary-tab">
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                    <p className="bg-primary text-white">
                                        Overview
                                    </p>
                                    <small className="text-muted text-center">
                                        Completed Screenings {this.getCompletedScreenings()}/{this.state.totalScreenings}
                                    </small>
                                </div>
                            </div>
                            <div className="row m-0">
                                <div className="col-7 text-center pl-2 pr-2">
                                    Screening Status
                                    { this.state.identityVerificationEnabled &&
                                    <div className="row m-0 mt-4 small">
                                        <div className="btn-link c-pointer mr-3 color-link" onClick={() => document.getElementById('identity-tab').click()}>
                                            Identity Check:
                                        </div>
                                        {
                                            this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS"
                                                ? this.state.rentifyScreeningDetails?.identityVerification
                                                : "-"
                                        }
                                    </div>
                                    }
                                    <div className="row m-0 mt-3 small">
                                        <div className="btn-link c-pointer mr-3 color-link" onClick={() => document.getElementById('background-tab').click()}>
                                            Background Check:
                                        </div>
                                        <span className={this.state.basicScreeningDetails?.status === "PASS" ? "color-green" : ""}>
                                            {this.state.basicScreeningDetails?.status || "-"}
                                        </span>
                                    </div>
                                    <div className="row m-0 mt-3 small">
                                        <div className="btn-link c-pointer mr-3 color-link" onClick={() => document.getElementById('credit-tab').click()}>
                                            Credit Score:
                                        </div>
                                        <span className={this.state.creditScreeningDetails?.screenResult?.creditScore === "PASS" ? "color-green" : ""}>
                                            {
                                                this.state.creditScreeningDetails?.status && this.state.creditScreeningDetails?.status === "PASS"
                                                    ? this.state.creditScreeningDetails?.screenResult?.creditScore
                                                    : this.mapScreeningStatus(this.state.creditScreeningDetails?.status) || "-"
                                            }
                                        </span>
                                    </div>
                                    { this.state.incomeVerificationEnabled &&
                                    <div className="row m-0 mt-3 small">
                                        <div className="btn-link c-pointer mr-3 color-link" onClick={() => document.getElementById('income-tab').click()}>
                                            Income Check:
                                        </div>
                                        {
                                            this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS"
                                                ? this.state.rentifyScreeningDetails?.incomeVerification
                                                : "-"
                                        }
                                    </div>
                                    }
                                    <ul className="pl-4">
                                        {/* <li>
                                            <div className="row m-0 mt-3">
                                                <small className="mr-3">
                                                    Rent Affordability
                                                </small>
                                                <small>
                                                    {
                                                        this.state.rentifyScreeningDetails?.monthlyIncome || "-"
                                                    }
                                                </small>
                                            </div>
                                        </li> */}
                                        {
                                        (this.state.rentAffordabilityMin !== null || this.state.rentAffordabilityMax !== null) &&
                                        <li>
                                            <div className="row m-0 mt-3">
                                                <small className="mr-3">
                                                    Rent Affordability
                                                </small>
                                                <small>    
                                                                                                 
                                                    {
                                                        this.state.rentAffordabilityMin || ''
                                                    }%
                                                    &nbsp;
                                                    —
                                                    &nbsp;
                                                    
                                                    {
                                                        this.state.rentAffordabilityMax || ''
                                                    }% 
                                                </small>
                                            </div>
                                        </li>
                                        }
                                        { this.state.rentifyScreeningDetails?.monthlyIncome &&
                                            <li>
                                                <div className="row m-0 mt-3">
                                                    <small className="mr-3">
                                                        Total Average Monthly Income
                                                    </small>
                                                    <small>
                                                        {
                                                            this.state.rentifyScreeningDetails?.monthlyIncome || "-"
                                                        }
                                                    </small>
                                                </div>
                                            </li>
                                        }
                                        {
                                        (this.state.rentMin >= 0 && this.state.rentMax > 0) ?
                                        <li>
                                            <div className="row m-0 mt-3">
                                                <small className="mr-3">
                                                    Rent
                                                </small>
                                                <small>    
                                                    $                                              
                                                    {
                                                        this.state.rentMin
                                                    }
                                                    &nbsp;
                                                    —
                                                    &nbsp;
                                                    $
                                                    {
                                                        this.state.rentMax
                                                    } 
                                                </small>
                                            </div>
                                        </li>
                                        : (this.state.identityVerificationEnabled && this.state.incomeVerificationEnabled) ?
                                        <li>
                                            <div className="row m-0 mt-3">
                                                <small className="mt-1 mr-3">
                                                    Rent Range
                                                </small>
                                                <div className="col-md-4">
                                                    <FieldText
                                                        id='inputRentMin'
                                                        name='inputRentMin'
                                                        value={this.state.inputRentMin}
                                                        handleChange={this.handleChangeRent}
                                                        parent={this}
                                                        prependIconName="dollar-sign"
                                                        prependIconType="fas"
                                                        labelColumns='0'
                                                        fieldColumns='12'
                                                    />
                                                </div>
                                                -
                                                <div className="col-md-4">
                                                    <FieldText
                                                        id='inputRentMax'
                                                        name='inputRentMax'
                                                        value={this.state.inputRentMax}
                                                        handleChange={this.handleChangeRent}
                                                        parent={this}
                                                        prependIconName="dollar-sign"
                                                        prependIconType="fas"
                                                        labelColumns='0'
                                                        fieldColumns='12'
                                                    />
                                                </div>
                                                
                                            </div>
                                        </li>
                                        :
                                        <> &nbsp; </>
                                        }
                                    </ul>
                                </div>
                                <div className="col-5 text-center pl-2 pr-2">
                                    Payment Status
                                    {
                                        this.state.paymentStatusStatus
                                        ? (
                                            <div className="row m-0 mt-3 small">
                                                Application Fee - ${this.state.paymentStatusAmount}
                                                <span className={this.state.paymentStatusStatus === "COMPLETE" ? "color-green ml-2" : "ml-2"}>
                                                    {this.state.paymentStatusStatus === "COMPLETE" ? "PROCESSED" : this.state.paymentStatusStatus }
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="mt-3">
                                                -
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'IDENTITY' ? 'active' : ''}`} id="identity" role="tabpanel" aria-labelledby="identity-tab">
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                    <p className="bg-primary text-white">
                                        Identity Analysis
                                    </p>
                                </div>
                            </div>
                            <div className="card-body text-center">
                                {
                                    this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS"
                                    ? (
                                        `Identity Check: ${this.state.rentifyScreeningDetails?.identityVerification}`
                                    ) : (
                                        <p className="pt-3 pb-1">This applicant has not undergone a identity check.</p>
                                    )
                                }
                            </div>
                            <div className="card-footer mt-5">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-muted">
                                            Identity Analysis conducted by Verifast
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'BACKGROUND' ? 'active' : ''}`} id="background" role="tabpanel" aria-labelledby="background-tab">
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                    <p className="bg-primary text-white">
                                    Background Check
                                    </p>
                                </div>
                            </div>

                            <div className="col text-right">
                                {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status !== 'INPROCESS') &&
                                    <div className="btn btn-primary btn-sm ml-3 mb-3" data-toggle="tooltip" data-placement="top" title="Download Report" onClick={() => this.getScreeningReport(this.state.basicScreeningDetails.requestId)}>
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" />
                                    </div>
                                }
                            </div>


                            {!this.state.basicScreeningDetails &&
                            <div className="card-body text-center">

                                <p className="pt-3 pb-1">This applicant has not undergone a background check.</p>

                                <div className="btn btn-primary btn-md mb-4" data-toggle="modal" data-target="#screening-basic" onClick={() => this.getScreeningFee('SCREENING_BASIC')}>
                                    Initiate Background Check
                                </div>

                            </div>
                            }

                            {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status === 'INPROCESS') &&
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                        ` <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-fw fa-spin my-4" size="3x" />`
                                        </div>
                                        <div className="text-center text-muted mb-3">
                                            <small>
                                                This background check is in progress. Check back soon for detailed results.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {(this.state.basicScreeningDetails && this.state.basicScreeningDetails.status !== 'INPROCESS') &&
                            <div className="card-body card-body-table">

                                <table className="table table-bordered table-striped">
                                    <thead>
                                    <tr>
                                        <th colSpan="2">
                                            Results
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td width="75%">
                                            Criminal Scan
                                        </td>
                                        <td width="25%">
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.criminalScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.criminalScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Damage to Property
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.damageToProperty === 'None' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.damageToProperty}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Eviction Potential
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.evictionPotential === 'None' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.evictionPotential}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Fraud Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.fraudScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.fraudScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Global Clearance Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.globalClearanceScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.globalClearanceScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Global Sanctions Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.globalSanctionsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.globalSanctionsScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Known Affiliations Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.knownAffiliationsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.knownAffiliationsScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Other Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.otherScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.otherScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Public Safety Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.publicSafetyScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.publicSafetyScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Sex Offender Scan
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.basicScreeningDetails.screenResult.sexOffenderScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                                <span className="ml-1">{this.state.basicScreeningDetails.screenResult.sexOffenderScan}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            }

                            <div className="card-footer mt-5">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-muted">
                                            Background check reports provided and conducted by Certn.
                                        </small>
                                    </div>
                                    <div className="col-2 text-right">
                                        <img src={certn} alt="Background checks powered by Certn." className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'CREDIT' ? 'active' : ''}`} id="credit" role="tabpanel" aria-labelledby="credit-tab">
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                    <p className="bg-primary text-white">
                                    Credit Check
                                    </p>
                                </div>
                            </div>
                            <div className="col text-right">
                                {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status !== 'INPROCESS') &&
                                    <div className="btn btn-primary btn-sm ml-3 mb-3" onClick={() => this.getScreeningReport(this.state.creditScreeningDetails.requestId)} data-toggle="tooltip" data-placement="top" title="Download Report">
                                        <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" />
                                    </div>
                                }
                            </div>

                            {!this.state.creditScreeningDetails &&
                            <div className="card-body text-center">

                                <p className="pt-3 pb-1">This applicant has not undergone a credit check.</p>

                                <div className="btn btn-primary btn-md mb-4" data-toggle="modal" data-target="#screening-credit" onClick={() => this.getScreeningFee('SCREENING_CREDIT')}>
                                    Initiate Credit Check
                                </div>

                            </div>
                            }

                            {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status === 'INPROCESS') &&
                            <div className="card-body">
                                <div className="row justify-content-center">
                                    <div className="col-10">
                                        <div className="text-center text-muted">
                                            <FontAwesomeIcon icon={['fas', 'spinner']} className="fa-fw fa-spin my-4" size="3x" />
                                        </div>
                                        <div className="text-center text-muted mb-3">
                                            <small>
                                                This credit check is in progress. Check back soon for detailed results.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }

                            {(this.state.creditScreeningDetails && this.state.creditScreeningDetails.status !== 'INPROCESS') &&
                            <div className="card-body card-body-table">

                                <table className="table table-bordered table-striped">
                                    <thead>
                                    <tr>
                                        <th colSpan="2">
                                            Results
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td width="75%">
                                            Credit Score
                                        </td>
                                        <td width="25%">
                                            {this.state.creditScreeningDetails.screenResult.creditScore ? this.state.creditScreeningDetails.screenResult.creditScore : 'No Credit Score'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Credit Grade
                                        </td>
                                        <td>
                                            {this.state.creditScreeningDetails.screenResult.creditScore &&
                                            <React.Fragment>
                                                {this.state.creditScreeningDetails.screenResult.creditScore >= 800 &&
                                                <span className="badge grade-a-plus text-white">A+</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore <= 799 && this.state.creditScreeningDetails.screenResult.creditScore >= 750) &&
                                                <span className="badge grade-a text-white">A</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore <= 749 && this.state.creditScreeningDetails.screenResult.creditScore >= 700) &&
                                                <span className="badge grade-b-plus text-white">B+</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore < 699 && this.state.creditScreeningDetails.screenResult.creditScore >= 650) &&
                                                <span className="badge grade-b text-white">B</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore < 649 && this.state.creditScreeningDetails.screenResult.creditScore >= 600) &&
                                                <span className="badge grade-c-plus text-white">C+</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore < 599 && this.state.creditScreeningDetails.screenResult.creditScore >= 550) &&
                                                <span className="badge grade-c text-white">C</span>
                                                }
                                                {(this.state.creditScreeningDetails.screenResult.creditScore < 549 && this.state.creditScreeningDetails.screenResult.creditScore >= 500) &&
                                                <span className="badge grade-d text-white">D</span>
                                                }
                                                {this.state.creditScreeningDetails.screenResult.creditScore < 499 &&
                                                <span className="badge grade-f text-white">F</span>
                                                }
                                            </React.Fragment>
                                            }
                                            {!this.state.creditScreeningDetails.screenResult.creditScore &&
                                            <React.Fragment>
                                                No Credit Score
                                            </React.Fragment>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Late Rent Potential
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'High' &&
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-danger" />
                                                }
                                                {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'Medium' &&
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-warning" />
                                                }
                                                {this.state.creditScreeningDetails.screenResult.lateRentPotential === 'Low' &&
                                                <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success" />
                                                }
                                                <span className="ml-1">{this.state.creditScreeningDetails.screenResult.lateRentPotential}</span>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>
                            }

                            <div className="card-footer">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-muted">
                                            Credit reports provided by Certn.
                                        </small>
                                    </div>
                                    <div className="col-2 text-right">
                                        <img src={certn} alt="Credit checks powered by Certn." className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'INCOME' ? 'active' : ''}`} id="income" role="tabpanel" aria-labelledby="income-tab">
                            <div className="mt-4 mb-3">
                                <div className="text-center">
                                    <p className="bg-primary text-white">
                                        Income Analysis
                                    </p>
                                </div>
                            </div>
                            {
                                this.state.rentifyScreeningDetails?.pdfUrl && (
                                    <div className="col text-right">
                                        <a className="btn btn-primary btn-sm" href={this.state.rentifyScreeningDetails.pdfUrl} data-toggle="tooltip" data-placement="top" title="Download Report">
                                            <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" />
                                        </a>
                                    </div>
                                )
                            }
                            {
                                this.state.rentifyScreeningDetails?.rentifyStatus === "SUCCESS"
                                ? (
                                    <div className="row m-0">
                                        <div className="col-md-7 pl-2 pr-2 small">
                                            <div className="row justify-content-between m-0 mt-4">
                                                Outstanding fund
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.closingBalance?.toFixed(2) || "0.00"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Current Balance
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.currentBalance?.toFixed(2) || "0.00"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Average Monthly Payroll income
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.monthlyPayrollIncome?.toFixed(2) || "0.00"
                                                    }
                                                </span>
                                            </div>
                                            { this.state.rentifyScreeningDetails?.monthlyIncome &&
                                                <div className="row justify-content-between m-0 mt-3">
                                                    Total Average Monthly Income
                                                    <span>
                                                        $
                                                        {
                                                            this.state.rentifyScreeningDetails?.monthlyIncome?.toFixed(2) || "0.00"
                                                        }
                                                    </span>
                                                </div>
                                            }
                                            <div className="row justify-content-between m-0 mt-3">
                                                Total Average Monthly Debt Payments
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.totalLoan?.toFixed(2) || "0.00"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Days in Overdraft
                                                <span>
                                                    {
                                                        this.state.rentifyScreeningDetails?.overdraftDays || "0"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Non-sufficient Fund Counts
                                                <span>
                                                    {
                                                        this.state.rentifyScreeningDetails?.nsf || "0"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Counts of past rent payments
                                                <span>
                                                    {
                                                        this.state.rentifyScreeningDetails?.rentTransactionsCount || "0"
                                                    }
                                                </span>
                                            </div>
                                            <div className="row justify-content-between m-0 mt-3">
                                                Counts of past debt payments
                                                <span>
                                                    {
                                                        this.state.rentifyScreeningDetails?.debtTransactionsCount || "0"
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-md-5 pl-2 pr-2">
                                            {/* <div className="row justify-content-between m-0 mt-4 small">
                                                Anticipated Rent Expenses:
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.identityVerification || "0"
                                                    }
                                                </span>
                                            </div>
                                            <hr />
                                            <div className="row justify-content-between m-0 mt-3 small">
                                                Monthly Rent:
                                                <span>
                                                    $
                                                    {
                                                        this.state.rentifyScreeningDetails?.identityVerification || "0"
                                                    }
                                                </span>
                                            </div> */}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body text-center">
                                        <p className="pt-3 pb-1">This applicant has not undergone a income check.</p>
                                    </div>
                                )
                            }
                            <div className="card-footer mt-5">
                                <div className="row">
                                    <div className="col">
                                        <small className="text-muted">
                                            Income Analysis conducted by Verifast
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

                

                <div className="modal fade" id="screening-basic" tabIndex="-1" role="dialog" aria-labelledby="screening-basic-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="screening-basic-label">
                                    Initiate Background Check
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    To perform this background check, your payment method on file will be charged at the end of your billing cycle for the amount detailed below.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td className="">
                                            Certn Background Check <span className="small text-muted">(Qty: 1)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.serviceFee.feeAmount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            {this.state.serviceFee.taxJurisdiction1} <span className="small text-muted">({this.state.serviceFee.taxRate1}%)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={(((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            <FormattedNumber value={(parseFloat(this.state.serviceFee.feeAmount) + parseFloat((((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">

                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                    </div>
                                    <div className="col-8 text-right">

                                        <div className="btn btn-primary btn-lg" onClick={() => this.initScreening('BASIC')}>
                                            Agree & Initiate
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="screening-credit" tabIndex="-1" role="dialog" aria-labelledby="screening-credit-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="screening-credit-label">
                                    Initiate Credit Check
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>

                            <div className="modal-body bg-secondary">
                                <p className="mb-0">
                                    To perform this credit check, your payment method on file will be charged at the end of your billing cycle for the amount detailed below.
                                </p>
                            </div>

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr>
                                        <td className="">
                                            Equifax Credit Check <span className="small text-muted">(Qty: 1)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.serviceFee.feeAmount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="">
                                            {this.state.serviceFee.taxJurisdiction1} <span className="small text-muted">({this.state.serviceFee.taxRate1}%)</span>
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={(((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="text-right font-weight-bold">
                                            <FormattedNumber value={(parseFloat(this.state.serviceFee.feeAmount) + parseFloat((((this.state.serviceFee.taxRate1 / 100) * this.state.serviceFee.feeAmount).toFixed(2))))} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    
                                    </tbody>
                                </table>
                            </div>

                            <div className="modal-footer bg-secondary rounded-bottom d-block">

                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                    </div>
                                    <div className="col-8 text-right">

                                        <div className="btn btn-primary btn-lg" onClick={() => this.initScreening('CREDIT')}>
                                            Agree & Initiate
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Screenings.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Screenings);