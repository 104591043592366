import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import * as constants from '../../../util/constants';
import Alert from '../../common/Alert';
import Breadcrumb from '../../common/Breadcrumb';
import FieldText from '../../common/FieldText';
import Propertii from '../../common/Propertii';
import Spinner from '../../common/Spinner';
import Table from '../../common/Table';

class AdminMerchantAccounts extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      merchantAccount: {},
      customFields: {},

      paymentProviders: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },

      merchantAccounts: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },

      searchFilter: '',

      query: {
        orderBy: 'DESC',
        orderByFields: ['accountStatus'],
        conditionList: [],
        joins: {
            c: {
                targetRecordType: 'TYPE_COMPANY',
                joinField: 'companyId',
                alias: 'c',
                returnFields: ['name']
            },
        }
      },

      validationList: [],
    };

    this.updateTable = this.updateTable.bind(this);
    this.selectRecord = this.selectRecord.bind(this);
    this.handleChangeSearchFilter = this.handleChangeSearchFilter.bind(this);
    this.handleSubmitSearchFilter = this.handleSubmitSearchFilter.bind(this);
  }

  /**
   * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
   * on the company ID in the route.
   */
  componentDidMount() {
    this.updateTable(1, 25, this.state.query);
  }

  /**
   * Handle clicking a merchant account by redirecting the user to the edit merchant account component.
   *
   * @param merchantAccountId - The ID of the merchant account clicked on.
   */
  selectRecord(merchantAccount) {
    this.props.history.push(
      `/admin/companies/${merchantAccount.companyId}/merchants/${merchantAccount.id}`
    );
  }

  /**
   * Update the data table of merchant accounts.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  updateTable(page, recordsPerPage, query) {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/merchant_account/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
          joins: query.joins
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          merchantAccounts: response.data,
          query: {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
          },
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle changes made to the search filter field.
   *
   * @param event - The event container.
   */
  handleChangeSearchFilter(event) {
    event.persist();

    this.setState((prevState) => ({
      ...prevState,
      searchFilter: event.target.value,
    }));

    if (event.target.value === '') {
      this.handleSubmitSearchFilter(null, event.target.value);
    }
  }

  /**
   * Handle submitting the search filter field by adjusting the merchant account search query and initiating a new
   * search.
   *
   * @param event - The event container.
   * @param searchFilter - The value within the search filter field.
   */
  handleSubmitSearchFilter(event, searchFilter) {
    if (event != null) {
      event.preventDefault();
    }

    let query = {
      orderBy: 'ASC',
      orderByFields: ['accountStatus'],
      conditionList: [],
      joins: {
            c: {
                targetRecordType: 'TYPE_COMPANY',
                joinField: 'companyId',
                alias: 'c',
                returnFields: ['name']
            },
        }
    };

    if (searchFilter !== '') {
      query.conditionList.push(
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: '(',
          closeBrackets: null,
          fieldName: 'id',
          operator: 'EQUALS',
          fieldValue: searchFilter,
        },
        {
          type: 'STRING',
          logicalOperator: 'OR',
          openBrackets: null,
          closeBrackets: ')',
          fieldName: 'name,accountNumber',
          operator: 'MATCH',
          fieldValue: searchFilter,
        }
      );
    }

    this.setState({
      query: query,
    });

    this.updateTable(1, 25, query);
  }

  /**
   * Render the component.
   *
   * @returns {*} - The administrator Merchant Accounts component
   */
  render() {
    const { formatMessage } = this.props.intl;

    return (
      <React.Fragment>
        <div className='content-block'>
          <Spinner visible={this.state.spinner} />

          <div className='container'>
            <Breadcrumb
              parentPath='/admin/system'
              parentPage='System'
              childPage='Merchant Account Management'
            />

            <Alert
              validationList={this.state.validationList}
              validationType='primary'
            />

            <div className='card'>
              <div className='card-header'>
                <div className='row align-items-center'>
                  <div className='col'>Merchant Accounts</div>
                </div>
              </div>
              <div className='card-header gotham border-top py-3 bg-secondary'>
                <form
                  onSubmit={(event) =>
                    this.handleSubmitSearchFilter(
                      event,
                      this.state.searchFilter
                    )
                  }
                >
                  <div className='media'>
                    <div className='media-body align-self-center mr-3'>
                      <FieldText
                        id='searchFilter'
                        label='Search'
                        labelClass='d-none'
                        fieldColumns='12'
                        labelColums='0'
                        placeholder='Filter by ID, name or account number...'
                        parent={this}
                        value={this.state.searchFilter}
                        handleChange={this.handleChangeSearchFilter}
                      />
                    </div>
                    <div className='align-self-center text-right'>
                      <button
                        type='submit'
                        className='btn btn-secondary btn-sm btn-block mb-0'
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'search']}
                          className='fa-fw'
                        />{' '}
                        Search
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <Table
                columns={{
                  companyName: 'Company Name',
                  last4: 'last4',
                  accountNumber: 'Account Number',
                  name: 'Name',
                  accountStatus: 'Status',
                }}
                columnWidths={['25%', '15%', '25%', '20%', '15%']}
                headerClass='c-pointer'
                data={this.state.merchantAccounts}
                query={this.state.query}
                sortEnabled={true}
                recordsEnabled={true}
                paginationEnabled={true}
                updateFunction={this.updateTable}
              >
                <tbody>
                  {this.state.merchantAccounts.records.map((data, key) => {
                    return (
                      <tr
                        key={key}
                        onClick={() => this.selectRecord(data)}
                        className='c-pointer'
                      >
                        <td>
                          <div className=''>{data.joins?.c?.name || "NA"}</div>
                        </td>
                        <td>
                          <div className=''>{data.last4}</div>
                        </td>
                        <td>
                          <div className=''>{data.accountNumber}</div>
                        </td>
                        <td>
                          <div className=''>{data.name}</div>
                        </td>
                        <td>
                          <div className='text-nowrap'>
                            <FontAwesomeIcon
                              icon={['fas', 'circle']}
                              className={`fa-fw small ${formatMessage({
                                id:
                                  'enum.merchantAccount.accountStatus.' +
                                  data.accountStatus +
                                  '.class',
                              })}`}
                            />
                            <span className='ml-1'>
                              <FormattedMessage
                                id={
                                  'enum.merchantAccount.accountStatus.' +
                                  data.accountStatus
                                }
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AdminMerchantAccounts.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdminMerchantAccounts);
