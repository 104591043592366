import moment from "moment";
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class FieldBirthDate extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let date = moment(this.props.value).utc();

        this.state = {

            birthDate: this.props.value || '',
            birthYear: this.props.value ? date.year() : '',
            birthMonth: this.props.value ? (date.month() + 1 < 10 ? "0" + (date.month() + 1) : date.month() + 1) : '',
            birthDay: this.props.value ? (date.date() < 10 ? "0" + date.date() : date.date()) : '',

            validationList: [],

        };

        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * When the component receives the date value prop, break down the date into individual year/month/date values and
     * assign them to their respective select fields.
     *
     * @param prevProps - The previous props of the component.
     * @param prevState - The previous state of the component.
     * @param snapshot - The snapshot of the component.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        if(this.props.value != null && prevProps.value == null) {


            let date = moment(this.props.value).utc().startOf('day');

            this.setState(prevState => ({
                ...prevState,
                birthDate: this.props.value || '',
                birthYear: this.props.value ? date.year() : '',
                birthMonth: this.props.value ?  (date.month() + 1 < 10 ? "0" + (date.month() + 1) : date.month() + 1) : '',
                birthDay: this.props.value ? (date.date() < 10 ? "0" + date.date() : date.date()) : '',
            }));

        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        if(this.props.dayDisabled){
            this.setState(({
                birthDay: 1
            }));
        }

        if(event.target.value !== '') {
            this.setState(({
                [event.target.name]: event.target.value,
            }), () => {

                // Only push the state to the parent once all fields are set
                if(this.state.birthDay && this.state.birthMonth && this.state.birthYear) {

                    let date = moment(`${this.state.birthMonth ? this.state.birthMonth : '01'}-${this.state.birthDay ? this.state.birthDay : '01'}-${this.state.birthYear ? this.state.birthYear : '1970'} 00:00:00`, 'MM-DD-YYYY hh:mm:ss');

                    let dateField;

                    let dateTime = date ? moment(date).toDate() : null;

                    if (dateTime) {
                        dateTime.setHours(0);
                        dateTime.setMinutes(0);
                        dateTime.setSeconds(0);
                        dateTime.setMilliseconds(0);
                    }

                    if (this.props.model != null) {
                        dateField = this.props.parent.state[this.props.model];
                        dateField[this.props.id] = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
                    }

                    if (this.props.model == null) {
                        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
                    }
                    
                    this.props.parent.setState(({
                        [this.props.model ? this.props.model : this.props.id]: dateField,
                    }));

                }

            });
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic birth date field that displays a series of three select boxes allowing the user to
     * provide a day, month, and year.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        let date = new Date();
        let birthYearOptions = [];
        let birthDateOptions = [];
        
        for(let i = 1; i <= moment(this.state.birthMonth, "MM").daysInMonth(); i++){
            birthDateOptions.push(i);
        }

        if(this.props.createDate) {
            const createDate = moment(this.props.createDate).utc().startOf('day');
            for(let i = createDate.year(); i <= 2050; i++) {
                birthYearOptions.push(i);
            }
        } else if(!this.props.dayDisabled){
            let year = date.getFullYear();
            if(this.props.additionalYears) {
                year += parseInt(this.props.additionalYears);
            }
            for(let i = year; i >= 1900; i--) {
                birthYearOptions.push(i);
            }
        } else{
            for(let i = date.getFullYear(); i <= 2050; i++) {
                birthYearOptions.push(i);
            }
        }

        return(
            <div className="form-group row">

                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && !this.props.leaseApplicationPage && <small className="text-muted font-italic">*</small>}
                </label>

                <div className={`col-sm-${this.props.fieldColumns}`}>

                    {!this.props.monthFirst &&
                        <div className="input-group">

                        {!this.props.dayDisabled &&
                            <select
                            id="birthDay"
                            name="birthDay"
                            value={this.state.birthDay || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Day</option>

                            {this.state.birthMonth !== '' && 
                                birthDateOptions.map((date, key) => {
                                    let day = date < 10 ? `0${date}` : date.toString();
                                    return(
                                        <option value={day} key={key}>{day}</option>
                                    )
                                }
                            )}

                        </select>}

                        <select
                            id="birthMonth"
                            name="birthMonth"
                            value={this.state.birthMonth || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>

                        </select>

                        <select
                            id="birthYear"
                            name="birthYear"
                            value={this.state.birthYear || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Year</option>

                            {birthYearOptions.map((year, key) => {
                                return(
                                    <option value={year} key={key}>{year}</option>
                                )
                            })}

                        </select>

                    </div>
                    }

                    {this.props.monthFirst &&
                        <div className="input-group">

                        <select
                            id="birthMonth"
                            name="birthMonth"
                            value={this.state.birthMonth || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Month</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>

                        </select>

                        {!this.props.dayDisabled &&
                            <select
                            id="birthDay"
                            name="birthDay"
                            value={this.state.birthDay || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled || !this.state.birthMonth}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Day</option>

                            {this.state.birthMonth !== '' && 
                                birthDateOptions.map((date, key) => {
                                    let day = date < 10 ? `0${date}` : date.toString();
                                    return(
                                        <option value={day} key={key}>{day}</option>
                                    )
                                }
                            )}

                        </select>}

                        <select
                            id="birthYear"
                            name="birthYear"
                            value={this.state.birthYear || ''}
                            onChange={this.handleChange}
                            required={this.props.required}
                            disabled={this.props.disabled}
                            className={`form-control ${this.props.fieldClass} ${isInvalid ? 'is-invalid' : ''}`}>

                            <option value="">Year</option>

                            {birthYearOptions.map((year, key) => {
                                return(
                                    <option value={year} key={key}>{year}</option>
                                )
                            })}

                        </select>

                    </div>
                    }


                    {this.props.help &&
                    <small className="form-text text-muted">
                        {this.props.help}
                    </small>
                    }

                    {isInvalid &&
                    <div className="invalid-feedback">
                        <FormattedMessage id={"danger." + errorCode} />
                    </div>
                    }

                </div>

            </div>
        )
    };
}

FieldBirthDate.defaultProps = {
    
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
};

export default FieldBirthDate;
