import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldDate from "../common/FieldDate";
import Propertii from "../common/Propertii";
import Table from "../common/Table";
import FieldSelect from "./FieldSelect";
import FieldText from "./FieldText";

class EventList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            startDate: null,
            endDate: null,
            eventType: null,
            recordType: null,
            recordId: null,

            recordTypeList: [],
            eventTypeList: [],

            eventHistory: [{}],
            currentEvent: {},
            previousEvent: {},

            userFirstName: '',
            userLastName: '',

            eventList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            eventQuery: {
                orderBy: 'DESC',
                orderByFields: ['eventDate'],
                conditionList: []
            },

            validationList: [],

        };

        this.searchEvents = this.searchEvents.bind(this);
        this.filterEvents = this.filterEvents.bind(this);
        this.viewEvent = this.viewEvent.bind(this);
        this.searchEventByRecordId = this.searchEventByRecordId.bind(this);
    }

    /**
     * Fetch the list of transactions related to the customer on mounting of the component, in addition to fetching a
     * list of all record types and event types for listing in the record type and event type filter.
     */
    componentDidMount() {

        this.filterEvents(null);

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recordtypes`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            const recordTypeList = response.data;
            recordTypeList.push('TYPE_INTEGRATION');
            recordTypeList.push('TYPE_INTEGRATION_SCHEDULER');
            recordTypeList.push('TYPE_INTEGRATION_BLACKOUT');
            recordTypeList.sort();
            this.setState(prevState => ({
                ...prevState,
                recordTypeList: recordTypeList
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/eventtypes`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                eventTypeList: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Apply filters to the event history search and re-run the search.
     *
     * @param event - The event container.
     */
    filterEvents(event) {

        if (event != null) {
            event.preventDefault();
        }

        let eventQuery = {
            orderBy: 'DESC',
            orderByFields: ['eventDate'],
            conditionList: []
        };

        if (this.props.userId != null && this.props.companyId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            );
        }

        else if (this.props.userId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                }
            );
        }

        else if (this.props.companyId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            );
        }

        if (this.state.startDate != null) {
            eventQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'eventDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                }
            );
        }

        if (this.state.endDate != null) {
            eventQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'eventDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                }
            );
        }

        if (this.props.systemUserId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'systemUserId',
                    operator: 'EQUALS',
                    fieldValue: this.props.systemUserId
                }
            );
        }

        if (this.state.recordType != null && this.state.recordType !== '') {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'recordType',
                    operator: 'EQUALS',
                    fieldValue: this.state.recordType
                }
            );
        }

        if (this.state.eventType != null && this.state.eventType !== '') {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'type',
                    operator: 'EQUALS',
                    fieldValue: this.state.eventType
                }
            );
        }

        if (this.state.recordId != null && this.state.recordId !== '') {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'currentRecordId',
                    operator: 'EQUALS',
                    fieldValue: this.state.recordId
                }
            );
        }

        this.setState({
            eventQuery: eventQuery
        });

        this.searchEvents(1, 25, eventQuery);
    }

    /**
     * View an event history record. Pulls up the current event history and compares it with the previous event history
     * to compare exactly what elements of object changed.
     *
     * @param event - The event object selected.
     */
    viewEvent(event) {

        let currentEvent;
        let currentEventIndex;
        let previousEvent;
        
        let hostUrl = constants.REACT_APP_HOST_API_URL;
        let recordType = event.recordType.substring(5).toLowerCase();
        if(event.recordType === 'TYPE_INTEGRATION' || event.recordType === 'TYPE_INTEGRATION_SCHEDULER' || event.recordType === 'TYPE_INTEGRATION_BLACKOUT') {
            hostUrl = constants.REACT_APP_HOST_INTEGRATION_URL;
            recordType = recordType.toUpperCase();
        }

        axios.get(`${hostUrl}/${recordType}/${event.currentRecordId}/history`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(event.userType) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/${event.userType.substring(5).toLowerCase()}/${event.userId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        userFirstName: response.data.firstName,
                        userLastName: response.data.lastName
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            if(event.systemUserType && event.systemUserType.substring(5)?.toLowerCase() !== 'system_admin') {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/${event.systemUserType.substring(5).toLowerCase()}/${event.systemUserId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        systemUserFirstName: response.data.firstName,
                        systemUserLastName: response.data.lastName
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            if(event.recordType === 'TYPE_INTEGRATION' || event.recordType === 'TYPE_INTEGRATION_SCHEDULER' || event.recordType === 'TYPE_INTEGRATION_BLACKOUT') {
                let currentHistoryRecord;
                Object.entries(response.data).forEach(([index, eventHistory]) => {
                    if(eventHistory.record_id  === event.recordId) {
                        currentHistoryRecord = eventHistory;
                        currentEventIndex = index;
                    }
                });
                currentEvent = { 
                    historyRecord: currentHistoryRecord,
                    event: event
                }
                const historyRecord = response.data[parseInt(currentEventIndex) + 1];
                if(historyRecord) {
                    this.searchEventByRecordId(historyRecord.record_id).then(response => {
                        this.setState(prevState => ({
                            ...prevState,
                            previousEvent: {
                                ...prevState.previousEvent,
                                historyRecord: historyRecord,
                                event: response.data.records?.[0]
                            }
                        }));
                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }
            } else {
                Object.entries(response.data).forEach(([index, eventHistory]) => {
                    if(eventHistory.event.id === event.id) {
                        currentEvent = eventHistory;
                        currentEventIndex = index;
                    }
                });
                previousEvent = response.data[parseInt(currentEventIndex) + 1];
            }

            this.setState(prevState => ({
                ...prevState,
                eventHistory: response.data,
                currentEvent: currentEvent,
                previousEvent: previousEvent,
                eventIndex: currentEventIndex,
                eventLength: response.data.length
            }));

            $('#event').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of events.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchEvents(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/events/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                eventList: response.data,
                eventQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch an event based on the event record id
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchEventByRecordId(eventRecordId) {

        let eventQuery = {
            orderBy: 'DESC',
            orderByFields: ['eventDate'],
            conditionList: []
        };

        if (this.props.userId != null && this.props.companyId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            );
        } else if (this.props.userId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                }
            );
        } else if (this.props.companyId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            );
        }

        if (this.props.systemUserId != null) {
            eventQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'systemUserId',
                    operator: 'EQUALS',
                    fieldValue: this.props.systemUserId
                }
            );
        }

        eventQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'recordId',
                operator: 'EQUALS',
                fieldValue: eventRecordId
            }
        );

        return axios.post(`${constants.REACT_APP_HOST_API_URL}/events/search?recordsPerPage=25&page=1`, {
            orderBy: eventQuery.orderBy,
            orderByFields: eventQuery.orderByFields,
            conditionList: eventQuery.conditionList,
            joins: eventQuery.joins,
        }, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic event history component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        const prettyJson = require('prettyjson');

        let currentEvent = prettyJson.render({});
        let previousEvent = prettyJson.render({});

        if(this.state.currentEvent && this.state.currentEvent.historyRecord) {
            currentEvent = prettyJson.render(this.state.currentEvent.historyRecord);
        }

        if(this.state.previousEvent && this.state.previousEvent.historyRecord) {
            previousEvent = prettyJson.render(this.state.previousEvent.historyRecord);
        }

        const reactDiffViewerStyles = {
            line :{
                wordBreak: 'break-all',
                "@media only screen and (min-width: 1200px)": {
                    'td:nth-child(3)': {
                        minWidth: 400,
                    },
                },
            }
        };

        return (
            <React.Fragment>

                <Alert validationList={this.state.validationList} validationType="primary"/>

                <div className="card">
                    <div className="card-header">
                        {this.props.cardHeaderLabel}
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterEvents} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="recordId" label="Search" labelClass="d-none" 
                                                fieldColumns="12" labelColums="0"
                                                placeholder="Record ID" parent={this} value={this.state.recordId} />
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="startDate" labelColumns="0" 
                                                fieldColumns="12" parent={this} value={this.state.startDate}
                                                selectsRange={true} startDate={this.state.startDate}
                                                endDate={this.state.endDate}/>
                                </div>
                            </div>
                            
                            <div className="media pt-2">
                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="eventType" placeholder="Event Type" labelColumns="0" fieldColumns="12" parent={this} value={this.state.eventType}>

                                        <option value="">All Event Types</option>

                                        {Object.entries(this.state.eventTypeList).map(([index, eventType]) => {
                                            return (
                                                <FormattedMessage id={"enum.record." + eventType} key={"enum.record." + eventType}>
                                                    {(eventTypeValue) => <option key={index} value={eventType}>{eventTypeValue}</option>}
                                                </FormattedMessage>
                                            )
                                        })}

                                    </FieldSelect>
                                </div>

                                <div className="media-body align-self-center mr-3">
                                    <FieldSelect id="recordType" placeholder="Record Type" labelColumns="0" fieldColumns="12" parent={this} value={this.state.recordType}>

                                        <option value="">All Record Types</option>

                                        {Object.entries(this.state.recordTypeList).map(([index, recordType]) => {
                                            return (
                                                <FormattedMessage id={"enum.record." + recordType} key={"enum.record." + recordType}>
                                                    {(recordTypeValue) => <option key={index} value={recordType}>{recordTypeValue}</option>}
                                                </FormattedMessage>
                                            )
                                        })}

                                    </FieldSelect>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table columns={{eventDate: 'Date', type: 'Event', recordType: 'Record Type', systemUserName: 'Triggered By'}}
                           columnWidths={['25%', '25%', '25%', '25%']}
                           headerClass="c-pointer"
                           data={this.state.eventList}
                           query={this.state.eventQuery}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchEvents}>
                        <tbody>
                        {this.state.eventList.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={() => this.viewEvent(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            <Moment format="MMM DD, YYYY HH:mm">
                                                {data.eventDate}
                                            </Moment>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={"enum.record." + data.type}/>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.recordType &&
                                            <FormattedMessage id={"enum.record." + data.recordType}/>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.systemUserName}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="event" tabIndex="-1" role="dialog" aria-labelledby="event-label" aria-hidden="true">
                    <div className={`modal-dialog modal-dialog-centered ${currentEvent && previousEvent ? 'modal-xl' : 'modal-lg'}`} role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="event-label">
                                    Event History
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>

                            {(!currentEvent && !previousEvent) &&
                            <div className="modal-body">

                                <p className="text-center my-3">
                                    This is a generic system event, therefore there is no additional information to display.
                                </p>

                            </div>
                            }

                            {(currentEvent || previousEvent) &&
                            <React.Fragment>

                                <div className="row no-gutters">
                                    <div className="col order-1">
                                        <div className="modal-body modal-body-table">

                                            <table className="table mb-0 border-left border-bottom">
                                                <tbody>
                                                <tr>
                                                    <td className="">
                                                        Current Event Date
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            <Moment format="MMM DD, YYYY HH:mm">
                                                                {this.state.currentEvent.event.eventDate}
                                                            </Moment>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        Event Type
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            <FormattedMessage id={"enum.record." + this.state.currentEvent.event.recordType}/> <FormattedMessage id={"enum.record." + this.state.currentEvent.event.type}/>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        Triggered By
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            {this.state.currentEvent.event.systemUserType === 'TYPE_ADMIN' &&
                                                            <React.Fragment>
                                                                {this.state.systemUserFirstName} {this.state.systemUserLastName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.currentEvent.event.systemUserType}/>)</small>
                                                            </React.Fragment>
                                                            }
                                                            {this.state.currentEvent.event.systemUserType !== 'TYPE_ADMIN' &&
                                                            <React.Fragment>
                                                                {this.state.currentEvent.event.systemUserName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.currentEvent.event.systemUserType}/>)</small>
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                {this.state.currentEvent.event.userType &&
                                                <tr>
                                                    <td className="">
                                                        User Affected
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            {this.state.userFirstName} {this.state.userLastName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.currentEvent.event.userType}/>) </small>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                }
                                                <tr className="small">
                                                    <td className="">
                                                        Event ID
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            {this.state.currentEvent.event.id}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="small">
                                                    <td className="">
                                                        Current Record ID
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.currentEvent.event &&
                                                        <div className="">
                                                            {this.state.currentEvent.event.currentRecordId}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                {this.state.currentEvent.event && this.state.currentEvent.event?.ipAddress &&
                                                <tr className="small">
                                                    <td className="">
                                                        IP Address
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="">
                                                            {this.state.currentEvent.event?.ipAddress}
                                                        </div>
                                                    </td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    {this.state.previousEvent &&
                                    <div className="col-6">
                                        <div className="modal-body modal-body-table">

                                            <table className="table mb-0 border-left border-bottom">
                                                <tbody>
                                                <tr>
                                                    <td className="">
                                                        Previous Event Date
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            <Moment format="MMM DD, YYYY HH:mm">
                                                                {this.state.previousEvent.event.eventDate}
                                                            </Moment>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        Record Type
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            <FormattedMessage id={"enum.record." + this.state.previousEvent.event.recordType}/> <FormattedMessage id={"enum.record." + this.state.previousEvent.event.type}/>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="">
                                                        Triggered By
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            {this.state.previousEvent.event?.systemUserType === 'TYPE_ADMIN' &&
                                                            <React.Fragment>
                                                                {this.state.systemUserFirstName} {this.state.systemUserLastName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.previousEvent.event?.systemUserType}/>)</small>
                                                            </React.Fragment>
                                                            }
                                                            {this.state.previousEvent.event?.systemUserType !== 'TYPE_ADMIN' &&
                                                            <React.Fragment>
                                                                {this.state.previousEvent.event?.systemUserName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.previousEvent.event?.systemUserType}/>)</small>
                                                            </React.Fragment>
                                                            }
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                {this.state.previousEvent.event?.userType &&
                                                <tr>
                                                    <td className="">
                                                        User Affected
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            {this.state.userFirstName} {this.state.userLastName} <small className="text-muted">(<FormattedMessage id={"enum.record." + this.state.previousEvent.event?.userType}/>)</small>
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                }
                                                <tr className="small">
                                                    <td className="">
                                                        Event ID
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            {this.state.previousEvent.event.id}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="small">
                                                    <td className="">
                                                        Current Record ID
                                                    </td>
                                                    <td className="text-right">
                                                        {this.state.previousEvent.event &&
                                                        <div className="">
                                                            {this.state.previousEvent.event.currentRecordId}
                                                        </div>
                                                        }
                                                    </td>
                                                </tr>
                                                {this.state.previousEvent.event && this.state.previousEvent.event?.ipAddress &&
                                                <tr className="small">
                                                    <td className="">
                                                        IP Address
                                                    </td>
                                                    <td className="text-right">
                                                        <div className="">
                                                            {this.state.previousEvent.event?.ipAddress}
                                                        </div>
                                                    </td>
                                                </tr>
                                                }
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                    }
                                </div>

                                <div className="modal-body modal-body-table">
                                    <ReactDiffViewer oldValue={previousEvent} newValue={currentEvent} styles={reactDiffViewerStyles} splitView={!this.state.currentEvent.event.type.includes('CREATED')} />
                                </div>

                            </React.Fragment>
                            }

                            <div className="modal-footer bg-secondary rounded-bottom d-block">
                                <div className="row">
                                    <div className="col-4">
                                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#event").modal("hide")}>Close</button>
                                    </div>
                                    <div className="col-8 text-right">


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

EventList.propTypes = {
    intl: intlShape.isRequired,
};

EventList.defaultProps = {
    cardHeaderLabel: 'Event History',
};


export default injectIntl(EventList);