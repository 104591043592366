import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import PaymentMethodList from "../../common/PaymentMethods";
import Propertii from "../../common/Propertii";

class PaymentMethods extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            customer: {},
            validationList: [],
        };
    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ["c.firstName"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "id",
                    "fieldValue": this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                "c": {
                    "targetRecordType": "TYPE_CUSTOMER",
                    "joinField": "userId",
                    "alias": "c",
                    "returnFields": [
                        "firstName",
                        "lastName",
                        "email",
                    ]
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records?.length) {
                const userId = response.data.records[0].userId;
                if(userId) {

                    let propertyLease = response.data;
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${userId}`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
        
                        this.setState(prevState => ({
                            ...prevState,
                            propertyLease: propertyLease,
                            customer: response.data
                        }));
        
                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Retrieve the property lease and customer based on the property lease ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let propertyLease = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    propertyLease: propertyLease,
                    customer: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
            //If the call fails, we need to check if it is an API property
            this.searchBillingAccounts();
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view customer payment methods component for admins.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.customer.id &&
                <PaymentMethodList userType="TYPE_CUSTOMER" userId={this.state.customer.id} propertyLeaseId={this.props.match.params.propertyLeaseId} createMessage="admin.tenants.methods.created" deleteMessage="admin.tenants.methods.deleted" companyId={this.props.propertyLease?.joins?.company?.id}  billingAccountType={this.props.propertyLease?.type}/>
                }

            </React.Fragment>
        )
    };
}

PaymentMethods.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PaymentMethods);