import React from "react";
import { injectIntl } from "react-intl";
import { TYPE_COMMON } from "../../../constants/CommonConstants";
import Propertii from "../../common/Propertii";
import SyncsCommon from "../../common/Syncs";

class Syncs extends Propertii {
  /**
   * Render the component.
   *
   * @returns {*} - The Property Sync component.
   */
  render() {
    return (
      <>
        <SyncsCommon propertyId={this.props.match.params.propertyId} companyId={this.props.property?.joins?.company?.id} syncFor={TYPE_COMMON.TYPE_PROPERTY}/>
        <SyncsCommon propertyId={this.props.match.params.propertyId} companyId={this.props.property?.joins?.company?.id} syncFor={TYPE_COMMON.TYPE_TENANT}/>
      </>
    );
  }
}

export default injectIntl(Syncs);
