import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import Moment from 'react-moment';
import * as constants from '../../util/constants';
import ButtonClose from './ButtonClose';
import FieldDate from './FieldDate';
import Propertii from './Propertii';
import Spinner from './Spinner';
import Table from './Table';
import Modal from './Modal';
class ReportHyperwallet extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      hyperwalletTransaction: {},

      hyperwalletTransactionsList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },

      startDate: null,
      endDate: null,

      parameters: {
        propertyId: null,
      },

      validationList: [],
    };

    this.paymentTransactionQuery = {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [],
      joins: {
        pl: {
          targetRecordType: 'TYPE_PROPERTY_LEASE',
          joinField: 'billingAccountId',
          alias: 'pl',
          returnFields: ['propertyId', 'companyId', 'unit', 'accountNumber'],
        },
        p: {
          targetRecordType: 'TYPE_PROPERTY',
          joinField: 'propertyId',
          alias: 'p',
          returnFields: ['propertyName', 'street1'],
        },
        c: {
          targetRecordType: 'TYPE_CUSTOMER',
          joinField: 'userId',
          alias: 'c',
          returnFields: ['firstName', 'lastName', 'email', 'userIdentifier'],
        },
        co: {
          targetRecordType: 'TYPE_COMPANY',
          joinField: 'companyId',
          alias: 'co',
          returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId'],
        },
        pc: {
          targetRecordType: 'TYPE_COMPANY',
          joinField: 'co.parentId',
          alias: 'pc',
          returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId'],
        },
        installmentPay: {
          targetRecordType: 'TYPE_INSTALLMENT_PAY',
          joinField: 'paymentMethodId',
          alias: 'installmentPay',
          returnFields: ['id', 'installmentDetails', 'type', 'installments', 'externalId'],
        },
        m: {
          targetRecordType: 'TYPE_MANAGER',
          joinField: 'recipientId',
          alias: 'm',
          returnFields: ['firstName', 'lastName', 'email', 'phone'],
        },
      },
    };

    this.paymentTransactionConditionList = [
      {
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'source',
        operator: 'EQUALS',
        fieldValue: 'MARKETPLACE',
      },
      {
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'companyId',
        operator: 'EQUALS',
        fieldValue: this.props.companyId,
      },
    ];

    this.searchHyperwalletTransactions = this.searchHyperwalletTransactions.bind(this);
    this.searchHyperwalletTransactionsQuery = this.searchHyperwalletTransactionsQuery.bind(this);
    this.filterHyperwalletTransactions = this.filterHyperwalletTransactions.bind(this);
    this.getCurrentFilters = this.getCurrentFilters.bind(this);
    this.viewHyperwalletTransaction = this.viewHyperwalletTransaction.bind(this);
    this.downloadHyperwalletTrasactions = this.downloadHyperwalletTrasactions.bind(this);
  }

  /**
   * Fetch the list of HW transactions upon mounting of the component.
   */
  componentDidMount() {
    this.searchHyperwalletTransactions(1, 25, this.paymentTransactionQuery);
  }

  /**
   * View the detailed information of a hyperwalletTransaction by initializing the hyperwalletTransaction view modal.
   *
   * @param hyperwalletTransaction - The hyperwalletTransaction model to view.
   */
  viewHyperwalletTransaction(hyperwalletTransaction) {
    this.setState((prevState) => ({
      ...prevState,
      hyperwalletTransaction: hyperwalletTransaction,
    }));

    $('#hyperwalletTransaction').modal('show');
  }

  /**
   * Get the record of Hyperwallet Transactions
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param filter - The filters to apply to the search.
   */
  searchHyperwalletTransactions(page, recordsPerPage, filter) {
    this.setState({
      spinner: true,
    });

    if (!filter.conditionList.length) {
      filter.conditionList = this.paymentTransactionConditionList;
    }

    this.searchHyperwalletTransactionsQuery(page, recordsPerPage, filter)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          hyperwalletTransactionsList: response.data,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Return HyperwalletTransactionsQuery Promise
   * @param {*} page 
   * @param {*} recordsPerPage 
   * @param {*} filter 
   */
  searchHyperwalletTransactionsQuery(page, recordsPerPage, filter) {
    return axios.post(
      `${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`,
      filter,
      {
        headers: this.generateRequestHeaders(),
      }
    );
  }

  /**
   * Apply the available search filters on the hyperwalletTransaction list and perform a new search.
   *
   * @param event - The event container.
   */
  filterHyperwalletTransactions(event) {
    if (event != null) {
      event.preventDefault();
    }

    const filter = this.getCurrentFilters();
    this.searchHyperwalletTransactions(1, 25, filter);
  }

  /**
   * Return the current filter set for hyperwallet transactions list
   */
  getCurrentFilters() {
    const filter = this.paymentTransactionQuery;

    filter.conditionList = [];
    filter.conditionList = [...this.paymentTransactionConditionList];

    if (this.state.startDate) {
      const startDateTemp = moment(this.state.startDate).startOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ');
      filter.conditionList.push({
        type: 'DATE',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'createDate',
        operator: 'GREATER_THAN_OR_EQUALS',
        fieldValue: startDateTemp,
      });
    }

    if (this.state.endDate) {
      const endDateTemp = moment(this.state.endDate).endOf('day').format('YYYY-MM-DD[T]HH:mm:ssZZ');
      filter.conditionList.push({
        type: 'DATE',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'createDate',
        operator: 'LESS_THAN_OR_EQUALS',
        fieldValue: endDateTemp,
      });
    }

    return filter;
  }

  /**
   * Show Modal to confirm download
   *
   * @param event - The event container.
   */
  downloadHyperwalletTrasactions(event) {
    event.preventDefault();

    $('#download-format-confirm').modal('show');
  }

  /**
   * Download the hyperwalletTransaction report in CSV format. The downloaded file is based on the filters set by the user.
   */
  downloadHyperwalletTrasactionsCSV() {
    const FileDownload = require('js-file-download');
    const csvContentHeader = [
      'Create Date, Tenant, Tenant Email,Property,Landlord Email,Landlord Phone,Payment Amount,Transaction Status, Payout Status',
    ];
    const filter = this.getCurrentFilters();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    this.searchHyperwalletTransactionsQuery(1, this.state.hyperwalletTransactionsList.totalRecordCount, filter)
      .then((response) => {
        const csvContentBody = this.getCSVContentBody(response.data.records);
        const csvContent = csvContentHeader.concat(csvContentBody).join('\n');
        FileDownload(csvContent, `hyperwallet_report.csv`);
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }
  
  /**
   * Generate the content of the CSV report
   */
  getCSVContentBody(records) {
    return records.map((e) => {
      let str = '';
      str = str.concat(`${e.createDate},`);
      str = str.concat(`${e.joins?.c?.firstName} ${e.joins?.c?.lastName},`);
      str = str.concat(`${e.joins?.c?.email},`);
      str = str.concat(`${e.joins?.p?.propertyName} ${e.joins?.p?.street1},`);
      str = str.concat(`${e.joins?.m?.email},`);
      str = str.concat(`${e.joins?.m?.phone},`);
      str = str.concat(`${e.amount},`);
      str = str.concat(`${e.status},`);
      str = str.concat(`${e.payoutStatus},`);
      return str;
    });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The hyperwalletTransaction report component.
   */
  render() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    return (
      <React.Fragment>
        <Spinner visible={this.state.spinner} />

        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Hyperwallet</div>
              <div className='col text-right'>
                <div className='btn btn-primary btn-sm' onClick={(event) => this.downloadHyperwalletTrasactions(event)}>
                  <FontAwesomeIcon icon={['fas', 'download']} className='fa-fw' /> Download Report
                </div>
              </div>
            </div>
          </div>
          <div className='card-header gotham border-top py-3 bg-secondary'>
            <form onSubmit={this.filterHyperwalletTransactions} autoComplete='off'>
              <div className='media'>
                <div className='media-body align-self-center'>
                  <FieldDate
                    id='startDate'
                    labelColumns='0'
                    fieldColumns='12'
                    parent={this}
                    value={this.state.startDate}
                    selectsRange={true}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>

                <div className='align-self-center text-right'>
                  <button type='submit' className='btn btn-secondary btn-sm btn-block mb-0'>
                    <FontAwesomeIcon icon={['fas', 'search']} className='fa-fw' /> Search
                  </button>
                </div>
              </div>
            </form>
          </div>
          <Table
            tableClass='table-bordered table-hover table-responsive'
            columns={{
              createDate: 'Create Date',
              'c.firstName': 'Tenant',
              'c.email': 'Tenant Email',
              'p.street1': 'Property',
              'm.email': 'Landlord Email',
              'm.phone': 'Landlord Phone',
              amount: 'Payment Amount',
              status: 'Transaction Status',
              payoutStatus: 'Payout Status',
            }}
            minColumnWidths={['200px', '200px', '200px', '200px', '200px', '200px', '200px', '200px', '200px']}
            headerClass=''
            data={this.state.hyperwalletTransactionsList}
            filters={this.paymentTransactionQuery}
            sortEnabled={false}
            recordsEnabled={true}
            paginationEnabled={true}
            updateFunction={this.searchHyperwalletTransactions}
          >
            <tbody>
              {this.state.hyperwalletTransactionsList.records.map((data, key) => {
                return (
                  <tr key={key} onClick={() => this.viewHyperwalletTransaction(data)} className='c-pointer'>
                    <td>
                      <div className=''>
                        <Moment format='YYYY-MM-DD HH:mm (UTC)' tz='UTC'>
                          {data.createDate}
                        </Moment>
                      </div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.c?.firstName + ' ' + data.joins?.c?.lastName}</div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.c?.email}</div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.p?.propertyName + ' ' + data.joins?.p?.street1}</div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.m?.email}</div>
                    </td>
                    <td>
                      <div className=''>{data.joins?.m?.phone}</div>
                    </td>
                    <td>
                      <div className=''>{data.amount}</div>
                    </td>
                    <td>
                      <div className=''>{data.status}</div>
                    </td>
                    <td>
                      <div className=''>{data.payoutStatus}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div
          className='modal fade'
          id='hyperwalletTransaction'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='hyperwalletTransaction-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered modal-md' role='document'>
            <div className='modal-content shadow'>
              <div className='modal-header bg-dark text-white'>
                <h5 className='modal-title'>
                  <div className=''>Hyperwallet Transaction</div>
                </h5>
                <button type='button' className='close text-white' data-dismiss='modal' aria-label='Close'>
                  <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
                </button>
              </div>
              <div className='modal-body modal-body-table'>
                <table className='table mb-0'>
                  <tbody>
                    <tr className='small'>
                      <td className=''>Create Date</td>
                      <td className='text-right'>
                        <div className=''>
                          <Moment format='YYYY-MM-DD HH:mm (UTC)' tz='UTC'>
                            {this.state.hyperwalletTransaction?.createDate}
                          </Moment>
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Tenant</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.hyperwalletTransaction?.joins?.c?.firstName +
                            ' ' +
                            this.state.hyperwalletTransaction?.joins?.c?.lastName}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Tenant Email</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.joins?.c?.email}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Property</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.hyperwalletTransaction?.joins?.p?.propertyName +
                            ' ' +
                            this.state.hyperwalletTransaction?.joins?.p?.street1}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Landlord Email</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.joins?.m?.email}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Landlord Phone</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.joins?.m?.phone}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Payment Amount</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.amount}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Transaction Status</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.status}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Payout Status</td>
                      <td className='text-right'>
                        <div className=''>{this.state.hyperwalletTransaction?.payoutStatus}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='modal-footer d-block text-center bg-secondary rounded-bottom'>
                <ButtonClose />
              </div>
            </div>
          </div>
        </div>

        <Modal
          id='download-format-confirm'
          theme='primary'
          iconType='fas'
          iconName='file-spreadsheet'
          title='Download Confirmation'
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
          >
            Close
          </button>
          <button
            type='button'
            className='btn btn-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.downloadHyperwalletTrasactionsCSV()}
          >
            Download
          </button>
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(ReportHyperwallet);
