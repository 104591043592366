import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import React from "react";
import styles from "../css/HyperwalletPayment.module.css";
import HyperWalletAddressFields from "../HyperWalletAddressFields";
import Alert from "../../../common/Alert";
import { useValidationList } from "../../../common/hooks/useValidationList";

const LandlordStep = (props) => {
  const { validationList, setAlertMessage, clearValidationList } =
    useValidationList([]);

  /****** Actions *****/
  const validateForm = (event) => {
    event.preventDefault();

    let validSubmission = true;
    clearValidationList();

    if (props.emailLI !== props.confirmEmailLI) {
      setAlertMessage("Email and Confirmation Email must match.");
      validSubmission = false;
    }

    if (
      !props.phoneNumberLI.trim() &&
      (!props.emailLI.trim() || !props.confirmEmailLI.trim())
    ) {
      setAlertMessage(
        "You must enter either a valid mobile number or a valid email address."
      );
      validSubmission = false;
    }

    if (validSubmission) {
      props.next();
    }
  };

  $(function () {
    $('[data-toggle="popover"]').popover();
  });
  $(".popover-dismiss").popover({
    trigger: "focus",
  });
  /*******************/

  return (
    <>
      <Alert validationList={validationList} />
      <form
        id="landlordStepForm"
        onSubmit={(event) => validateForm(event)}
        className="mb-5"
      >
        <div className="form-group">
          <h3 className={`${styles.fontRoboto}`}>
            Payee Details{" "}
            <span
              tabIndex="0"
              role="button"
              data-toggle="popover"
              data-placement="top"
              data-trigger="focus"
              data-content="A payee is the person who will receive payment."
            >
              <FontAwesomeIcon icon={faInfoCircle} className='bs-stepper-info' size='xs'/>
            </span>
          </h3>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="firstNameLI">First Name*</label>
              <input
                type="input"
                className="form-control"
                id="firstNameLI"
                name="Name or Contact"
                required={true}
                value={props.firstNameLI}
                onChange={props.handleFirstNameLI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="lastNameLI">Last Name*</label>
              <input
                type="input"
                className="form-control"
                id="lastNameLI"
                name="Name or Contact"
                required={true}
                value={props.lastNameLI}
                onChange={props.handleLastNameLI}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="emailLI">Email*</label>
              <input
                type="email"
                className="form-control"
                id="emailLI"
                required={true}
                name="Email"
                value={props.emailLI}
                onChange={props.handleChangeEmailLI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="confirmEmailLI">Confirm Email*</label>
              <input
                type="email"
                className="form-control"
                id="confirmEmailLI"
                required={true}
                name="Confirm Email"
                value={props.confirmEmailLI}
                onChange={props.handleChangeConfirmEmailLI}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mt-2">
              <label htmlFor="phoneNumberLI">Phone*</label>
              <input
                type="tel"
                className="form-control mr-2"
                id="phoneNumberLI"
                required={true}
                name="Mobile"
                value={props.phoneNumberLI}
                onChange={props.handleChangePhoneNumberLI}
              />
            </div>
            <div className="col-md-6 mt-2">
              <label htmlFor="companyNameLI">Company Name</label>
              <input
                type="input"
                className="form-control"
                id="companyNameLI"
                name="Company Name"
                value={props.companyNameLI}
                onChange={props.handleChangeCompanyNameLI}
              />
              {/* <label htmlFor="notifyLandlordLI">Notify Landlord or Property Manager</label>
              <div className="custom-control custom-switch">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="notifyLandlordLI"
                  name='Notify Landlord or Property Manager'
                  value={props.notifyLandlordLI}
                  onChange={(event, value) => props.handleChangeNotifyLandlordLI(null, !props.notifyLandlordLI)}
                />
                <label className="custom-control-label" for="notifyLandlordLI"></label>
              </div> */}
            </div>
          </div>

          <HyperWalletAddressFields
            fieldIdSuffix={"LI"}
            street1={props.street1LI}
            street2={props.street2LI}
            city={props.cityLI}
            postalCode={props.postalCodeLI}
            country={props.countryLI}
            province={props.provinceLI}
            selectedCountry={props.selectedCountry}
            isOptional={true}
            handleChangeStreet1={props.handleChangeStreet1LI}
            handleChangeStreet2={props.handleChangeStreet2LI}
            handleChangeCity={props.handleChangeCityLI}
            handleChangePostalCode={props.handleChangePostalCodeLI}
            handleChangeCountry={(event) => props.handleChangeCountryLI(event)}
            handleChangeProvince={props.handleChangeProvinceLI}
          />

          <div className="row">
            <div className="col-md-12 mt-4 d-flex justify-content-center">
              <button
                type="button"
                className={`btn btn-light ${styles.stepButtonHW} mr-2`}
                onClick={props.toggleShowPayRentNow}
              >
                Back
              </button>
              <button
                className={`btn btn-primary float-right ${styles.stepButtonHW}`}
                type="submit"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
        <br />
      </form>
    </>
  );
};

export default LandlordStep;
