import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import certn from "../../../media/img/logos/certn.png";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Signup extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            localSystemAccount: {},
            screeningDetails: {},
            address: {},
            bankInfo: {},
            validationList: [],
        };

        this.initScreening = this.initScreening.bind(this);
        this.overrideScreening = this.overrideScreening.bind(this);
        this.getScreeningReport = this.getScreeningReport.bind(this);
    }

    /**
     * Retrieve the landlord based on the ID provided in the route, in addition to the banking information, screening
     * information, and local system account information if applicable.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data,
                address: response.data.customFields.address ? JSON.parse(response.data.customFields.address) : {},
            }));

            if(response.data.applicationStatus === 'COMPLETE') {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${response.data.id}/bankinfo`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        bankInfo: response.data
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            if(response.data.screeningId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.screeningId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        screening: response.data
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            if(response.data.screeningId) {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.screeningId}/details`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        screeningDetails: response.data
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });
            }

            axios.post(`${constants.REACT_APP_HOST_API_URL}/local_system_account/search`, {
                conditionList: [
                    {
                        operator: "EQUALS",
                        fieldName: "userId",
                        fieldValue: this.props.match.params.landlordId,
                        type: "STRING"
                    }
                ]
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    localSystemAccount: response.data.records[0]
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new screening on the landlord. Upon completion, fetch the local and external screening data.
     *
     * @param screenType - The type of screening to perform, such as a background or credit check.
     */
    initScreening(screenType) {

        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/screen`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.application.screening'
                    },
                }],
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    landlord: response.data,
                }));

                if(response.data.screeningId) {
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.screeningId}`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        this.setState(prevState => ({
                            ...prevState,
                            screening: response.data
                        }));
                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }

                if(response.data.screeningId) {
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${response.data.screeningId}/details`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        this.setState(prevState => ({
                            ...prevState,
                            screeningDetails: response.data
                        }));
                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }

            }).catch(error => {
                this.handleValidation(error);
            });

            $('#screening-basic').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Override the screening for the landlord by setting the landlord's screening status to 'FORCED_PASS'.
     */
    overrideScreening() {

        this.setState({
            spinner: true
        });

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/screening/${this.state.landlord.screeningId}/update`, {
            status: 'FORCED_PASS',
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                screening: response.data,
                validationList: [{
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.application.overridden'
                    },
                    fields: {},
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Fetch a detailed screening report.
     *
     * @param screeningId - The ID of the screening to fetch a report for.
     */
    getScreeningReport(screeningId) {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/screening/${screeningId}/report`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                spinner: false
            }));

            window.open(response.data.report_url);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The signup status of a landlord.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $('[data-toggle="tooltip"]').tooltip();

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {this.state.landlord.applicationStatus === 'PENDING' &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The landlord must now log in to their account and complete the application form, where they are asked to provide their landlord details, residential details, and company details (if applicable).
                        </p>
                    </div>
                </div>
                }

                {this.state.landlord.applicationStatus === 'APPLIED' &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The landlord has submitted their application form and must now pay their setup fees before being able to continue with their account setup.
                        </p>
                    </div>
                </div>
                }

                {(this.state.landlord.applicationStatus === 'PAID' && this.state.screening == null) &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The landlord has successfully paid their setup fees and must now initiate the screening process from their account.
                        </p>
                    </div>
                </div>
                }

                {(this.state.landlord.applicationStatus === 'PAID' && this.state.screening != null && this.state.screening.status === 'INPROCESS') &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The landlord has completed their application form, paid their setup fees, and is now currently undergoing background screening. A response will soon determine the success of the screening.
                        </p>
                    </div>
                </div>
                }

                {(this.state.landlord.applicationStatus === 'PAID' && this.state.screening != null && (this.state.screening.status === 'FAIL' || this.state.screening.status === 'WARNING')) &&
                <div className="card card-danger border-danger">
                    <div className="card-body">
                        <p className="card-text">
                            The background screening for the landlord did not pass due to insufficient information. Please consult the third party screening provider for additional details.
                        </p>
                    </div>
                </div>
                }

                {(this.state.landlord.applicationStatus === 'PAID' && this.state.screening != null && (this.state.screening.status === 'PASS' || this.state.screening.status === 'FORCED_PASS')) &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The landlord has passed their screening and can now log in to their account. They must now set up their banking information in order to process payments and fully activate their account.
                        </p>
                    </div>
                </div>
                }

                {(this.state.landlord.applicationStatus === 'COMPLETE' && (this.state.bankInfo.microDepStatus !== null && this.state.bankInfo.microDepStatus !== 'VALIDATED')) &&
                <div className="card card-warning border-warning">
                    <div className="card-body">
                        <p className="card-text">
                            The owner of the bank account associated with this landlord must validate their banking details before funds can be sent to their specified bank account.
                        </p>
                    </div>
                </div>
                }

                <div className="card">

                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Sign Up Checklist
                            </div>
                            <div className="col text-right">
                                {this.state.landlord.applicationStatus &&
                                <div className="text-nowrap">
                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.landlord.applicationStatus." + this.state.landlord.applicationStatus + ".class"})}`} />
                                    <span className="ml-1"><FormattedMessage id={"enum.landlord.applicationStatus." + this.state.landlord.applicationStatus} /></span>
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    {this.state.landlord.applicationStatus === 'PENDING' &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', this.state.localSystemAccount.status === 'VERIFIED' ? 'check-square' : 'square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }

                    {this.state.landlord.applicationStatus === 'APPLIED' &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }

                    {(this.state.landlord.applicationStatus === 'PAID' && this.state.screening == null) &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }

                    {this.state.landlord.applicationStatus === 'PAID' && this.state.screening != null && (this.state.screening.status === 'FAIL' || this.state.screening.status === 'WARNING' || this.state.screening.status === 'INPROCESS') &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }


                    {this.state.landlord.applicationStatus === 'PAID' && this.state.screening != null && (this.state.screening.status === 'PASS' || this.state.screening.status === 'FORCED_PASS') &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', 'square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }

                    {this.state.landlord.applicationStatus === 'COMPLETE' &&
                    <div className="card-body">
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account created
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Email confirmed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Application form completed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Account setup fees paid for
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Background screening passed
                        </div>
                        <div className="mb-1">
                            <FontAwesomeIcon icon={['far', 'check-square']} className="fa-fw va-b" size="1x" /> Banking details submitted
                        </div>
                        <div className="">
                            <FontAwesomeIcon icon={['far', (this.state.bankInfo.microDepStatus !== null && this.state.bankInfo.microDepStatus !== 'VALIDATED') ? 'square' : 'check-square']} className="fa-fw va-b" size="1x" /> Banking details validated
                        </div>
                    </div>
                    }

                </div>

                {(this.state.landlord.applicationStatus === 'PAID' && this.state.landlord.screeningId === null) &&
                <div className="card">
                    <div className="card-header">
                        Screening
                    </div>
                    <div className="card-body text-center">

                        <p className="pt-3 pb-1">This landlord has not undergone a background check.</p>

                        <div className="btn btn-primary btn-md mb-4" data-toggle="modal" data-target="#screening-basic">
                            Initiate Background Check
                        </div>

                    </div>
                </div>
                }

                {this.state.screening != null &&
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Screening
                            </div>
                            <div className="col-8 text-right">
                                <div data-toggle="modal" data-target="#screening-basic" className="btn btn-primary btn-sm">
                                    Re-Screen
                                </div>
                                {this.state.landlord && this.state.landlord.applicationStatus !== 'COMPLETE' &&
                                <div data-toggle="modal" data-target="#override-screening" className="btn btn-primary btn-sm ml-2">
                                    Override
                                </div>
                                }
                                {(this.state.screeningDetails && this.state.screeningDetails.status !== 'INPROCESS') &&
                                <div className="btn btn-primary btn-sm ml-2" data-dismiss="modal" onClick={() => this.getScreeningReport(this.state.screeningDetails.requestId)}>
                                    View Detailed Report
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body card-body-table">

                        <table className="table table-bordered table-striped">
                            <thead>
                            <tr>
                                <th colSpan="2">
                                    Results
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="25%">Start Date</td>
                                <td width="75%">
                                    <div className="">
                                        <Moment format="MMM DD, YYYY HH:mm">
                                            {this.state.screeningDetails.createDate}
                                        </Moment>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Completion Date</td>
                                <td width="75%">
                                    {this.state.screeningDetails.completeDate &&
                                    <div className="">
                                        <Moment format="MMM DD, YYYY HH:mm">
                                            {this.state.screeningDetails.completeDate}
                                        </Moment>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Internal Status</td>
                                <td width="75%">
                                    {this.state.screeningDetails.status &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.screening.status." + this.state.screening.status + ".class"})}`}/>
                                        <span className="ml-1"><FormattedMessage id={"enum.screening.status." + this.state.screening.status}/></span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">External Status</td>
                                <td width="75%">
                                    {this.state.screeningDetails.status &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.screening.status." + this.state.screeningDetails.status + ".class"})}`}/>
                                        <span className="ml-1"><FormattedMessage id={"enum.screening.status." + this.state.screeningDetails.status}/></span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td width="75%">
                                    Identity Verification
                                </td>
                                <td width="25%">
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <span className="">{this.state.screeningDetails.screenResult.identity}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td width="75%">
                                    Criminal Scan
                                </td>
                                <td width="25%">
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.criminalScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.criminalScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Damage to Property
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.damageToProperty === 'None' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.damageToProperty}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Eviction Potential
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.evictionPotential === 'None' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.evictionPotential}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Fraud Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.fraudScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.fraudScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Global Clearance Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.globalClearanceScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.globalClearanceScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Global Sanctions Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.globalSanctionsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.globalSanctionsScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Known Affiliations Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.knownAffiliationsScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.knownAffiliationsScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Other Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.otherScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.otherScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Public Safety Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.publicSafetyScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.publicSafetyScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Sex Offender Scan
                                </td>
                                <td>
                                    {this.state.screeningDetails.screenResult &&
                                    <div className="text-nowrap">
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${this.state.screeningDetails.screenResult.sexOffenderScan === 'Cleared' ? 'text-success' : 'text-danger'}`}/>
                                        <span className="ml-1">{this.state.screeningDetails.screenResult.sexOffenderScan}</span>
                                    </div>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="card-footer">
                        <div className="row">
                            <div className="col">
                                <small className="text-muted">
                                    Background check reports provided and conducted by Certn.
                                </small>
                            </div>
                            <div className="col-2 text-right">
                                <img src={certn} alt="Background checks powered by Certn." className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                }

                <Modal id="override-screening" theme="primary" iconType="fas" iconName="question-circle" title="Override Screening"
                       body="This landlord has not yet passed their screening. By overriding the screening, their screening status will appear as passed, and the landlord will be granted access to begin processing payments.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.overrideScreening()}} className="btn btn-primary btn-lg" data-dismiss="modal">
                        Override Screening
                    </button>
                </Modal>

                <Modal id="screening-basic" theme="primary" iconType="fas" iconName="question-circle" title="Initiate Background Check"
                       body="Are you sure you would like to initiate a background check on this landlord? Please note that we will be charged for each screening performed.">
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                    <button onClick={() => {this.initScreening('SCREENING_BASIC')}} className="btn btn-primary btn-lg" data-dismiss="modal">
                        Initiate Background Check
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

Signup.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Signup);