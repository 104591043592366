import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Terms extends Component {
  /**
   * Render the component.
   *
   * @returns {*} - The Letus terms of use contents.
   */
  render() {
    return (
      <div class='container'>
        <div class='row'>
          <div class='col-lg-2'>
            <div className='mb-3 mt-2'>
              <a href='https://home.bluesnap.com/wp-content/uploads/2022/12/BlueSnap-Merchant-TCs.v6.2-27Dec2022Final.pdf'>
                BlueSnap Merchant Services Agreement
              </a>
            </div>
            <div className='mb-4'>
              <Link to='/termspaysafe'>Paysafe Merchant Services Agreement</Link>
            </div>
          </div>
          <div class='col-lg-10'>
            {!this.props.isMainTermsPage && <p>Effective on November 28, 2022</p>}
            <p>
              Your use of the Services offered by PaySlate Inc. (referenced here as "
              <strong>Letus</strong>", "<strong>us</strong>", "<strong>we</strong>" or "
              <strong>our</strong>") is governed by this Agreement between you and Letus. PLEASE
              READ THIS AGREEMENT CAREFULLY. IT IS A LEGAL AND ENFORCEABLE CONTRACT BETWEEN YOU AND
              LETUS. BY USING OUR SERVICES, YOU ARE AGREEING THAT YOU HAVE , AND YOU WILL BE DEEMED
              TO HAVE, READ, AGREED WITH AND ACCEPTED ALL OF THE TERMS AND CONDITIONS CONTAINED IN
              THIS AGREEMENT AND&nbsp; IN OUR PRIVACY POLICY, AVAILABLE AT:{' '}
              <a href='https://let.us/privacy'>https://let.us/privacy</a>, WHICH IS HEREBY
              INCORPORATED INTO THIS AGREEMENT, AND IN ANY OTHER DOCUMENTS INCORPORATED BY REFERENCE
              IN THIS AGREEMENT.
            </p>

            <p>
              BY USING OUR SERVICES, YOU: (1) AGREE TO BE BOUND BY THIS AGREEMENT, INCLUDING ALL
              AMENDMENTS MADE FROM TIME TO TIME; (2) REPRESENT AND WARRANT THAT, IF YOU ARE AN
              INDIVIDUAL, YOU ARE 18 YEARS OLD OR OLDER OR, IF YOU ARE AN ENTITY, THAT YOU ARE A
              CORPORATION, PARTNERSHIP, OR OTHER LEGAL ENTITY DULY FORMED AND, IF APPLICABLE, IN
              GOOD STANDING; AND (3) REPRESENT AND WARRANT THAT YOU HAVE THE POWER AND AUTHORITY TO
              ENTER INTO THIS AGREEMENT, AND TO PERFORM ALL ACTS AS REQUIRED BY YOU UNDER THIS
              AGREEMENT, INCLUDING, IF APPLICABLE, ACTING ON BEHALF OF YOUR TENANT IN THE EVENT YOU
              ARE A PROPERTY MANAGER OR LANDLORD. IF YOU ARE A THIRD PARTY ACCESSING AN ACCOUNT ON
              BEHALF OF AN AUTHORIZED USER (E.G., AS A PROPERTY MANAGER, AN ADMINISTRATOR,
              CONSULTANT, ANALYST, AGENT, PARENT, CHILD, GUARDIAN, RELATIVE, ETC.), YOU AGREE THAT
              THESE TERMS APPLY, MUTATIS MUTANDIS, TO YOUR ACTIVITIES ON BEHALF OF SUCH USER.
            </p>

            <p>
              IF YOU DO NOT AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT, YOU MUST NOT USE THE
              SERVICES.
            </p>

            <h5>1. Scope</h5>
            <p>1.1 Definitions</p>
            <p>
              When used in this Agreement, each of the following words and phrases shall have the
              meanings set out below:
            </p>

            <p>(a) "Account Profile" means a User's account created for unique identification;</p>

            <p>
              (b) "Agreement" means these Terms of Use, including related schedules, policies
              (including our privacy policy), documents incorporated by reference into this
              Agreement and other documents that appear under the "Legal" heading on the Website, as
              may be amended from time to time;
            </p>

            <p>
              (c) "Bank Account Payment" and "Pre-Authorized Debit" refer to the use of the Services
              to make a payment via checking/chequing bank account by Tenants to their Landlords or
              property managers (as applicable);
            </p>

            <p>
              (d) "Business Days" means any day, other than a Saturday or Sunday, on which the
              chartered banks in Vancouver, British Columbia are open for business;
            </p>

            <p>
              (e) "Cash Payment" refers to the use of the Services to make a payment via cash where
              available;
            </p>

            <p>
              (f) "Chargeback Support Fee" has the meaning given to such term in section 5.5 of this
              Agreement;
            </p>

            <p>
              (g) "Credit Card Payment" refers to the use of the Services to make a payment via
              credit card by Tenants to their Landlords or property managers (as applicable);
            </p>

            <p>
              (h) "Credit Reporting" refers to the optional credit reporting services offered on
              Letus's Website that facilitate the reporting of certain payments made by Tenants to
              credit bureaus;
            </p>

            <p>
              (i) "Debit Card Payment" refers to the use of the Services to make a payment of rent
              or other related fees via Visa debit card or MasterCard debit card by Tenants to their
              Landlords or property managers (as applicable);
            </p>

            <p>
              (j) "Digital Wallet" refers to the use of the Services to make a payment using a Digital
              Wallet (such as ApplePay or GooglePay) by Tenants to their Landlords or property managers
              (as applicable);
            </p>

            <p>
              (k) "Force Majeure" means any act, occurrence, condition or event beyond the control
              of a party that materially affects the performance of that party's obligations under
              this Agreement that could not reasonably have been foreseen or provided against,
              including such events as fires, telecommunications or utility or power failures,
              governmental or quasi-governmental acts, equipment failures, labour strife, riots,
              war, non-performance of vendors or suppliers or acts of God, but not including general
              economic conditions;
            </p>

            <p>
              (l) "Indemnified Parties" means Letus and its subsidiaries, parents, affiliates,
              officers, directors, agents, employees, consultants, customers, suppliers, Payment
              Processor, and other representatives, taken collectively;
            </p>

            <p>
              (m) "Landlord" means the person or entity, including but not limited to property
              management companies, with whom a Tenant has an agreement under which the Tenant is
              using the Services to facilitate Payment;
            </p>

            <p>(n) "Lease" refers to the written lease agreement between a Tenant and Landlord;</p>

            <p>
              (o) "Linked Sites" means third-party websites or applications accessed through our
              Website;
            </p>

            <p>
              (p) "Merchant Account" means a merchant account established at the Underwriting Bank
              into which one or more Payments may be made;
            </p>

            <p>
              (q) "Merchant Services Agreement" means, collectively, the merchant services
              agreements set forth at
            </p>

            <ul>
              <li>
                <a href='https://home.bluesnap.com/wp-content/uploads/2022/12/BlueSnap-Merchant-TCs.v6.2-27Dec2022Final.pdf'>
                  BlueSnap Merchant Services Agreement
                </a>
                <span>&nbsp;and</span>
              </li>
              <li>
                <Link to='/termspaysafe'>Paysafe Merchant Services Agreement</Link>
              </li>
            </ul>

            <p>
              (r) "Payment" means a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit,
              Digital Wallet or a Cash Payment as the case may be;
            </p>

            <p>
              (s) "Payment Amount" refers to the aggregate dollar value of a Payment exclusive of
              the Service Fee;
            </p>

            <p>
              (t) "Payment Processor" means any of Letus's third party payment processors,
              including, without limitation, Paysafe Merchant Services Inc., Paysafe Merchant
              Services Corp. and BlueSnap Enterprise Canada ULC;
            </p>

            <p>
              (u) "Recurring Payment" refers to an automatic recurring payment processed monthly on
              the date selected by a Tenant or Landlord;
            </p>

            <p>
              (v) "Service Fee" means the fee charged by us to you for use of the Services including but not limited to
              a fee charged for: (i) a Credit Card Payment, Debit Card Payment, Bank Account Payment, Digital Wallet or
              Pre-Authorized Debit; (ii) acceptance of a Cash Payment by Letus; and/or (iii) a monthly subscription fee;
            </p>

            <p>
              (w) "Services" means the internet Payment, Credit Reporting and other services offered
              through the Website, a Payment Processor or third parties;
            </p>

            <p>
              (x) "Tenant" means the person or entity who uses the Services to make Payments
              pursuant to an agreement entered into with a Landlord;
            </p>

            <p>
              (y) "Third Party Services" refers to the services provided by third parties which
              Users may access through links on the Website, including without limitation, tenant
              screening services, insurance services, credit services, and services provided by
              Payment Processors;
            </p>

            <p>(z) "Underwriting Bank" means Letus's third party underwriting bank;</p>

            <p>
              (aa) "User" or "you" means a Tenant or Landlord, as applicable, who is using the
              Services; and
            </p>

            <p>
              (ab) "Website" means the website located at{' '}
              <a href='https://let.us'>https://let.us</a> and all related webpages.
            </p>

            <h5>2. Eligibility and Your Account</h5>
            <p>2.1 Letus Services</p>
            <p>
              This Agreement covers the terms of your participation with respect to the Services. We
              may from time to time offer additional Services in which case this Agreement will
              apply to such additional Services.
            </p>

            <p>2.2 Your Account</p>
            <p>
              When you enroll to use our Services, you must create an account and provide an Account
              Profile. You agree to keep the information in your profile current. If we become aware
              of any changes to your personal information, we may update your profile. You may opt
              out of communications, delete your account, and remove payment method details but your
              payment history and any information linked to those transactions will remain in our
              system, as well as any information as required or permitted by law.
            </p>

            <p>2.3 Eligibility</p>
            <p>
              To be eligible to use our Services, you must be either (1) an individual at least 18
              years old who is able to form legally binding contracts or legally act on behalf of an
              individual under applicable law with a mailing address in the United States or Canada;
              or (2) a corporation, partnership, or other legal entity duly formed and, if
              applicable, in good standing, and have the power and authority to enter into this
              Agreement and perform the acts as required by you under this Agreement. You may use
              the Services on your own behalf or as the authorized representative of a corporation
              registered to conduct business in the same country in which you reside. No machines,
              scripts or automated services may be used to access or use the Services. This
              Agreement is in addition to and supplements any master services agreement or other
              agreement you have entered into, or may in the future enter into, with Letus.
            </p>

            <p>2.4 Identity Authentication</p>
            <p>
              You hereby authorize us, directly or through third parties, to make any inquiries we
              consider necessary to validate your identity, banking information and business entity
              information (if applicable). This may include asking you for further information,
              personal information, requiring you to take steps to confirm ownership of your email
              address, bank account or financial instruments, ordering a credit report and verifying
              your information against third party databases or through other sources. You represent
              and warrant that you have the power and authority to provide this information to us.
            </p>

            <p>2.5 Credit Reporting Services</p>
            <p>
              Where Tenants have a written lease agreement in place with their Landlord, Tenants may
              opt into the Credit Reporting service subject to the restrictions described in this
              section.
            </p>
            <p>
              If you are a Tenant who opts into the Credit Reporting services, you will be
              automatically deemed to:
            </p>
            <ol type='i'>
              <li>
                represent and warrant that you have entered into a written lease agreement with your
                Landlord under which there is at least one year remaining on the Lease;
              </li>
              <li>
                represent and warrant that you have provided proof of your identity to your
                Landlord;
              </li>
              <li>
                represent and warrant that you will report all rental payments made for the duration
                of the Lease;
              </li>
              <li>
                agree to promptly notify Letus if you do not make a payment through the Letus
                Services during the credit reporting period and advise whether the payment was
                missed or made by an alternate payment method;
              </li>
              <li>
                consent to Letus authenticating your identity and confirming whether Lease payments
                have been made through third party applications or by contacting your Landlord; and
              </li>
              <li>
                acknowledge and understand that through your use of the Credit Reporting service, no
                improvement in your credit score may occur, even if you make all required payments
                to your Landlord in a timely fashion.
              </li>
            </ol>

            <p>
              If you are a Landlord whose Tenants use the Credit Reporting services, you will be
              automatically deemed to:
            </p>
            <ol type='i'>
              <li>
                represent and warrant that you have entered into a written lease agreement with the
                applicable Tenant, with a term of at least one year remaining on the Lease;
              </li>
              <li>
                agree to promptly notify Letus upon learning that any information is inaccurate or
                misleading, and provide Letus with any corrections or additional information
                necessary to ensure the credit reporting information supplied is complete and
                accurate;
              </li>
              <li>
                agree to report the Tenant's rental payments for the full term of the Tenant's
                Lease, even if the Tenant requests that the Landlord or property manager cease
                reporting such rental payments before the expiration of the Lease term;
              </li>
              <li>
                agree to notify the Tenant of the reporting requirement described in paragraph (iii)
                above before using the Credit Reporting services;
              </li>
              <li>
                agree to promptly notify Letus if the Tenant does not make a payment through the
                Letus Services during the credit reporting period and advise whether the payment was
                missed or made by an alternate payment method; and
              </li>
              <li>
                represent and warrant that you or the property manager have verified the identity
                and residency of the Tenant and provided such documentation to Letus.
              </li>
            </ol>
            <p>
              You acknowledge that Letus is solely an intermediary between you and the credit
              reporting agencies actually furnishing the credit profiles and scores at issue. Letus
              expressly disclaims any liability for the inaccuracy or incompleteness of any such
              credit profiles, credit scores, offers or other information, tools or analyses
              available through our Credit Reporting services, or any inaccuracies in data reporting
              or processing by any third parties. Letus retains the right to cancel the Credit Reporting
              services provided to you at any time in its sole discretion and without notice, whereupon Letus will not
              charge you further fees in respect of Credit Reporting services.
            </p>

            <h5>3. Receiving Payments</h5>
            <p>3.1 Letus's Role and Your Responsibilities</p>
            <p>
              Letus provides hosting and data processing services for building owners, Landlords,
              property managers and certain other third parties. Letus is a Payment Service Provider
              ("PSP"), not a bank, money transmitter or money services business ("MSB"), and we do
              not offer banking or MSB services as defined by the United States Department of
              Treasury. As a PSP, Letus collects, analyzes and relays information generated in
              connection with payments between the Tenant and the Landlord. You authorize Letus to
              provide this information to the Underwriting Bank in order for the Underwriting Bank
              to facilitate payments.
            </p>
            <p>
              As a result, the Underwriting Bank &ndash; and not Letus &ndash; actually conducts the
              settlement of card transactions. The Underwriting Bank is the party with sole
              responsibility for conducting the settlement of funds between the Tenant and the
              Landlord. In order to act as a PSP, Letus must enter into agreements with third
              parties. You are not a third party beneficiary of these agreements. Some of these
              third parties may require a direct agreement with you. If you are required to enter
              into such an agreement and decline to do so, we may suspend or terminate your Account
              Profile or use of the Services.
            </p>
            <p>
              You agree to be bound by the Merchant Services Agreement. For further clarity, the
              Merchant Services Agreement is an agreement for Third Party Services. This Agreement
              expressly incorporates the terms and conditions of the Merchant Services Agreement,
              which constitutes a legal binding contract between you, on the one hand, and BLUESNAP
              ENTERPRISE CANADA, ULC and its designated Member Bank, or PAYSAFE GROUP, LTD., and its
              designated Member Bank, on the other hand. Furthermore, you must abide by the
              applicable Operating Regulations, the rules of the Associations and Laws as defined in
              the Merchant Services Agreement and are assuming the risk of compliance with all of
              those provisions.
            </p>
            <p>3.2 Schedule of Fees for Landlords or Property Managers</p>
            <p>
              Fees payable by the Landlords or property managers to utilize the Services applicable
              to Landlords or property managers hereunder include a one-time fee or recurring fees
              or a combination of both as set out below:
            </p>
            <p>One Time Account Set-Up Fee.</p>
            <p>Includes:</p>
            <ol type='a'>
              <li>
                the setup and configuration for all properties in the portfolio, including those
                purchased after the Landlord or property manager enters into this Agreement;
              </li>
              <li>training of current and future staff;</li>
              <li>electronic and physical marketing materials; and</li>
              <li>merchant account setup.</li>
            </ol>
            <p>Monthly Subscription Fee</p>
            <p>Monthly fees for:</p>
            <ol type='a'>
              <li>payment processing technology;</li>
              <li>merchant account fees; and</li>
              <li>
                Landlord or property manager support and resident support via telephone, email, live
                chat and text message.
              </li>
            </ol>
            <p>
              The fees listed above are subject to change at the sole discretion of Letus and notice
              may be given to you in accordance with this Agreement. If you have a different
              arrangement for fees in any master services agreement or other agreement with us, the
              other agreement will govern.
            </p>
            <h5>4. Third Party Services</h5>
            <p>4.1 General Third Party Liability Disclaimer</p>
            <p>
              Our Website may contain Linked Sites through which you can access the provision of
              Third Party Services. These Linked Sites are provided solely as a convenience to our
              Users. The Third Party Services found through the Linked Sites are not under Letus's
              control, and Letus is not responsible for the quality of the Third Party Services or
              the content of such Linked Sites, including any information or materials contained on
              such Linked Sites. Letus may process Payments in respect of Third Party Services
              and/or receive referral fees or revenue sharing in respect of your use of any Third
              Party Services.&nbsp; You should use your own discretion when evaluating and using the
              Linked Sites and Third Party Services.
            </p>
            <p>
              By using the Services, you agree that Letus may share information about yourself or
              other persons with third parties for the purposes of performing the Third Party
              Services. You acknowledge and agree that: (i) Letus is not providing the Third Party
              Services; (ii) the Third Party Services are not subject to this Agreement and are only
              subject to the terms and conditions offered by the provider of the Third Party
              Services ; and (iii) Letus does not makes representations about, does not endorse, and
              is not responsible for the products, services, privacy policies or practices relating
              to such Third Party Services. Please refer to the terms and conditions of the
              applicable third party provider.
            </p>
            <p>
              LETUS EXPRESSLY DISCLAIMS ANY LIABILITY TO YOU RELATING TO YOUR ACCESS OF AND USE OF
              THE LINKED SITES AND THIRD PARTY SERVICES.&nbsp; BY ACCEPTING THESE TERMS OF USE, YOU
              ARE DEEMED TO (1) ACKNOWLEDGE THAT LETUS IS NOT PROVIDING ANY THIRD PARTY SERVICES AND
              IS NOT RESPONSIBLE FOR THE LINKED SITES AND (2) RELEASE LETUS FROM ANY LIABILITY
              ARISING IN ANY WAY FROM YOUR USE OF ANY THIRD PARTY SERVICE OR LINKED SITE.
            </p>

            <p>4.2 Third Party Insurance Services</p>

            <p>
              Letus is not an insurance agent, broker or insurer, and does not carry on any activity
              in respect of insurance business, including but not limited to, soliciting
              applications for contracts of insurance, issuing or delivering contracts of insurance
              or receipts for any contracts of insurance, advertising for any insurance products or
              collecting or receiving any premium for contracts of insurance.
            </p>

            <p>
              All insurance products and services included in the content on our Website or through
              Linked Sites relating to Third Party Services, including content and links relating to
              any insurance providers or insurance platforms, are being provided by third parties
              and are not Letus products or endorsed or controlled in any way by Letus.
            </p>

            <p>4.3 Third Party Credit and Installment Payment Services</p>
            <p>
              Letus is not a consumer credit provider or micro-lender and does not carry on any
              activity or act as a lender in respect of credit or lending services in any instance,
              including but not limited to, soliciting applications for credit services, issuing or
              delivering contracts for credit, advertising for any credit products, extending credit
              or collecting or receiving payment for credit contracts. Letus may elect to disable
              the use of Third Party Services relating to credit and installment payments in
              jurisdictions where such services are prohibited or, in the view of Letus, offering
              such Third Party Services is not advisable. Notwithstanding the foregoing, it is the
              sole responsibility of the User to determine whether the Third Party Services are
              prohibited or regulated in such User's applicable jurisdiction and Letus makes no
              representation as to whether a Third Party Service is not prohibited or regulated.
            </p>
            <p>
              All credit products, installment payments solutions and services included in the
              content on our Website or through Linked Sites to Third Party Services, including
              content and links relating to any credit providers such as PayPal Credit or
              installment payment providers such as Splitit Operations CA Ltd. and its affiliates ("
              <strong>Splitit</strong>"), are being provided by third parties and are not Letus
              products or endorsed or controlled in any way by Letus. Accordingly, you agree that
              Letus will not be liable for any damages, direct or indirect, incurred by you as a
              result of your use of credit products, installment payment providers and services
              offered by PayPal Credit or Splitit, regardless of whether you access such products
              and services through our Website.
            </p>
            <p>
              In addition to the Terms and Conditions hereunder, Users of Services offered by PayPal
              are required to read, agree and comply with the following terms of conditions of
              PayPal:
            </p>
            <ul>
              <li>
                For Users in Canada:&nbsp;
                <a href='https://www.paypal.com/ca/webapps/mpp/ua/platform-seller-agreement?locale.x=en_CA'>
                  https://www.paypal.com/ca/webapps/mpp/ua/platform-seller-agreement?locale.x=en_CA
                </a>
              </li>
              <li>
                For Users in the US:&nbsp;
                <a href='https://www.paypal.com/us/webapps/mpp/ua/platform-seller-agreement?locale.x=en_US'>
                  https://www.paypal.com/us/webapps/mpp/ua/platform-seller-agreement?locale.x=en_US
                </a>
              </li>
            </ul>
            <p>
              In addition to the Terms and Conditions hereunder, Users of Services offered by
              Splitit are required to read, agree and comply with the following supplemental terms
              and conditions provided by Splitit:
            </p>
            <ul>
              <li>
                For Users in Canada:&nbsp;
                <a href='https://www.splitit.com/legals/splitit-shopper-terms-conditions/canada/'>
                  https://www.splitit.com/legals/splitit-shopper-terms-conditions/canada/
                </a>
              </li>
              <li>
                For Users in the US:&nbsp;
                <a href='https://www.splitit.com/legals/splitit-shopper-terms-conditions/united-states/'>
                  https://www.splitit.com/legals/splitit-shopper-terms-conditions/united-states/
                </a>
              </li>
            </ul>
            <p>4.4 Landlord Use of Splitit Services</p>
            <p>
              Users who are Landlords agree to the following additional terms and conditions with
              respect to use of Services offered by Splitit:
            </p>
            <p>
              Splitit will facilitate payment in full to the Landlord of payment amounts owing by
              Tenants or other payors of the Landlord (the "<strong>Payor(s)</strong>") using a
              credit card, and such Payor(s) will pay the Payment Amount in installments over time
              using the Splitit platform (collectively, the "<strong>Splitit Services</strong>").
            </p>
            <p>You acknowledge and agree that:</p>
            <ol type='a'>
              <li>
                the Splitit Services are being provided by Splitit and are not Letus products or
                endorsed or controlled in any way by Letus;
              </li>
              <li>
                Letus will charge the Payor(s) a convenience fee in connection with their use of the
                Splitit Services; and
              </li>
              <li>
                information about you and your related payments may be shared with or accessed by
                Splitit in accordance with Splitit’s privacy policy and such other third party
                services providers for the purposes of supplying the Splitit Services to you.
              </li>
            </ol>
            <p>
              By using the Splitit Services, you are deemed to have authorized and appointed Letus
              to act as agent on your behalf and to enter into any and all necessary agreements on
              your behalf with respect to the Splitit Services, including a merchant funding and
              receivables purchase agreement between you and Splitit, the terms of which can be
              found at:
            </p>
            <p>
              <a href='https://prod-splitit-general.s3.amazonaws.com/docs/RentMoola+MFA.pdf'>
                https://prod-splitit-general.s3.amazonaws.com/docs/RentMoola+MFA.pdf
              </a>
            </p>
            <p>
              You acknowledge and agree that as a result of using the Splitit Services, you may be
              deemed to offer credit to Tenants and that you are solely responsible for determining
              whether such activities are regulated or prohibited in your applicable
              jurisdiction.&nbsp;&nbsp;&nbsp;&nbsp; If you do not wish to use the Splitit Services,
              you must immediately contact Letus so that we can disable the use of the Splitit
              Services for you and your Tenants.
            </p>
            <p>
              You agree that Letus will not be liable for any damages, direct or indirect, incurred
              by you as a result of your use of &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;the Splitit Services,
              regardless of whether you accessed the Splitit Services through the Website.
            </p>
            <p>
              In consideration for Letus acting as your agent with respect to the Splitit Services,
              you agree to defend, indemnify and hold harmless the Indemnified Parties against any
              and all losses, and any and all claims brought by a third party, which the Indemnified
              Parties (or any of them) incur, become liable for or subject to, based upon, arising
              from or in connection with your use of the Splitit Services.
            </p>
            <p>
              Without limiting the foregoing, you agree to indemnify Letus and Splitit with respect
              to any chargebacks initiated by the Payor(s), whether or not such chargebacks relate
              to the Splitit Services.
            </p>

            <p>4.5 Other Third Party Services</p>
            <p>
              In addition to the terms and conditions hereunder, Users of services offered by
              Rentify Inc. are required to read, agree and comply with the following terms of
              conditions of Rentify Inc.:&nbsp;
              <a href='https://trustrentify.com/terms'>https://trustrentify.com/terms</a>
            </p>

            <h5>5. Making Payments</h5>
            <p>5.1 Payment Processor</p>
            <p>
              When you make a Payment using our Services, our Payment Processor relays the payment
              information and transaction details and responses between you, your credit card
              issuing bank (or one or more credit card agencies), the Underwriting Bank and any
              other third parties required to process the transaction. The transfer of the Payment
              Amount is effected between your credit card issuing bank and the Underwriting Bank
              (and not Letus). You accept and agree that Letus or any Third Party Services does not
              at any time receive, collect or hold any Payment.
            </p>

            <p>5.2 Service Fee</p>
            <p>
              Immediately after you make a Payment using the Services, Letus will charge you a
              non-refundable Service Fee. This Service Fee is payable in addition to (and in advance
              of) the Payment. Service Fees are never deducted from or adjusted against Payment
              Amounts. Service Fees are paid into a separate Merchant Account established by Letus
              and are subject to change at any time.
            </p>

            <p>5.3 Taxes</p>
            <p>
              It is your responsibility to determine what, if any, taxes apply to the Payments you
              make or receive, and it is your responsibility to collect, report and remit the
              correct tax to the appropriate tax authority. Letus is not responsible for determining
              whether taxes apply to your Payment transaction, or for collecting, reporting or
              remitting any taxes arising from any Payment transaction.
            </p>

            <p>
              5.4 Credit Card, Debit Card, Bank Account, Pre-Authorized Debit t, Digital Wallet or
              Cash Delays and Non-Completion
            </p>
            <p>
              Since Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit,
              Digital Wallet or Cash Payment processing times depend on administrative factors and
              institutional backlogs beyond our control, Credit Card Payments, Debit Card Payments,
              Bank Account Payments, Pre-Authorized Debit, Digital Wallet or Cash Payments may take up to
              five Business Days (and in rare cases, even longer) to complete. Furthermore, completion of
              a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, Digital
              Wallet or a Cash Payment is contingent on both the authorization of the Credit Card Payment,
              Debit Card Payment, Bank Account Payment, Pre-Authorized Debit, Digital Wallet or a Cash Payment
              by the applicable credit card issuing bank and acceptance of the Credit Card Payment or Debit Card
              Payment by the Underwriting Bank. In the event that a Credit Card Payment, Debit Card Payment,
              Bank Account Payment, Pre-Authorized Debit, Digital Wallet or a Cash Payment initiated by you is
              unable to be completed, we will attempt to notify you at least once through our Website or using
              the contact information in your Account Profile. In the case of such delay or non-completion,
              your liability to the intended recipient of the Credit Card Payment, Debit Card Payment, Bank
              Account Payment, Pre-Authorized Debit, Digital Wallet or a Cash Payment may be subject to penalties,
              late fees and interest charges assessed by such recipient, all of which remain your responsibility
              (and not Letus's). If a Credit Card Payment, Debit Card Payment, Bank Account Payment, Pre-Authorized
              Debit, Digital Wallet or a Cash Payment is not completed, you are not entitled to a return of any Service
              Fees charged by us, irrespective of the reason for non-completion.
            </p>

            <p>5.5 No Reversals and No Refunds</p>
            <p>
              A chargeback is typically caused when a Tenant or cardholder disputes a charge that
              appears on their bill. A chargeback may result in the reversal of a transaction, with
              the amount charged back to the Tenant. Tenants may be assessed chargebacks for
              disputes, unauthorized or improperly authorized transactions, transactions that do not
              comply with the payment network rules or terms of this Agreement, transactions that
              are allegedly unlawful or suspicious, or any reversals by the payment network, our
              Payment Processor or acquiring bank, or the cardholder's card issuing financial
              institution.
            </p>
            <p>
              <u>Tenants</u>
            </p>
            <p>
              If your Payment is completed, you accept and agree that no refunds, cancellations or
              charge backs are permitted through the Website. If a Payment is refunded, cancelled or
              charged back by your credit card issuing bank, bank, financial institution, or another
              person, you are not entitled to the return of any associated Service Fees,
              irrespective of the reason for such refund, cancellation or charge back. You accept
              and agree not to request a refund, cancellation or chargeback of Service Fees from
              your credit card issuing bank, bank, financial institution or any other person. Your
              Landlord may authorize a refund for a Payment by submitting a written request to us.
              The refund is subject to the funds being made available by your landlord and the
              amount will only be refunded to the original payment method used for the payment.
              There are no refunds whatsoever twelve (12) months after the original payment
              transaction date. We reserve the right to recoup any costs associated with processing
              a refund, cancellation or chargeback from you. All chargebacks are subject to an
              additional non-refundable chargeback support fee of $25.00 (the "Chargeback Support
              Fee") charged by us to the Landlord.
            </p>
            <p>
              At any point if it is determined that you are incurring excessive chargebacks, we may
              impose additional fees, penalties or fines. Excessive chargebacks may also result in
              additional controls and restrictions to your use of the Service, including without
              limitation, delays in your Payments, or suspension or termination of your account(s)
              and the Services.
            </p>
            <p>
              <u>Landlords</u>
            </p>

            <p>
              Once you receive a Payment, you accept and agree that there are no refunds,
              cancellations or chargebacks of Service Fees. Refunds for Payments to Tenants are
              permitted when the request is made in writing by you and the funds are available. The
              refund transaction will only be applied to the same payment method used for the
              payment. There are no refunds whatsoever twelve (12) months after the original
              transaction date. If a chargeback is initiated by a Tenant, you will be responsible
              for any associated fees.
            </p>
            <p>
              When a chargeback is issued, you are immediately liable for the full amount of the
              chargeback plus any associated fees, Chargeback Support Fees, fines, expenses or
              penalties (including those assessed by the payment network or our Payment Processors).
              You agree that we may recover these amounts by means of EFT/ACH debit of your merchant
              account, bank account or setting off any amounts owed to you by us. If we are unable
              to recover funds related to a chargeback for which you are liable, you will pay us the
              full amount of the chargeback immediately upon demand.
            </p>
            <p>
              You or Letus may elect to contest chargebacks assessed to your account(s). We will
              provide you with assistance including notification to help contest your chargebacks.
              We do not assume any responsibility for our role or assistance in contesting
              chargebacks. You agree to provide us with the necessary information, within two (2)
              business days of our request, but in any event no later than five (5) business days of
              our request, and at your expense, to investigate or help resolve any chargeback. If
              you fail to provide us with the necessary information within five (5) business days,
              you will forfeit the right of dispute. You also grant us permission to share records
              or other information required with the Tenant or cardholder, the cardholder's
              financial institution, and your financial institution to help resolve any disputes.
              You acknowledge that your failure to provide us with complete and accurate information
              in a timely manner may result in an irreversible chargeback being assessed. If the
              cardholder's issuing bank of the payment network does not resolve a dispute in your
              favour, we may recover the chargeback amount and any associated fees, including the
              Chargeback Support Fee, from you as described in this Agreement and all applicable
              agreements.
            </p>
            <p>
              At any point if it is determined that you are incurring excessive chargebacks, we may
              impose additional fees, penalties or fines. Excessive chargebacks may also result in
              additional controls and restrictions to your use of the Service, including without
              limitation, delays in your Payments, or suspension or termination of your account(s)
              and the Services.
            </p>
            <p>5.6 Pre-Authorized Debits and Recurring Payments</p>
            <p>
              <u>Tenants</u>
            </p>
            <p>
              If you use pre-authorized debit, you authorize Letus and/or the Landlord to debit the
              bank account you have identified as per your instructions on the regular recurring
              payments for payments arising under your account. You acknowledge that the amount you
              are debited, the date on which you are debited and the frequency by which you are
              debited will be based on the terms of your agreement and may vary from one billing
              period to the next, and you will be advised of it prior to the debit occurring. You
              may revoke this authority at any time upon 30 days’ written notice. To obtain a sample
              cancellation form, or for more information on your right to cancel a pre-authorized
              debit agreement, you may contact your financial institution or visit{' '}
              <a href='https://www.payments.ca'>www.payments.ca</a>. You waive your right to receive
              pre-notification of the amount of the pre-authorized debit and agree that you do not
              require advance notice of the amount of pre-authorized debits before the debit is
              processed. You have certain recourse rights if any debit does not comply with this
              agreement. For example, you have the right to receive reimbursement for any debit that
              is not consistent with your pre-authorized debit agreement. To obtain more information
              on your recourse rights, you may contact your financial institution or visit{' '}
              <a href='https://www.payments.ca'>www.payments.ca</a>. You authorize BlueSnap, Inc to
              process payments on behalf of Letus and/or the Landlord.
            </p>
            <p>
              Subject to your right to terminate your pre-authorization, this authorization will
              remain in effect after termination of this Agreement and until all of your obligations
              to us have been paid in full. If you change your bank account, this authorization will
              apply to the new account and you shall provide us, in writing, such information
              regarding the new account as we deem necessary. Such new account shall thereafter be
              and become the new bank account for the purpose of this Agreement. It may take us up
              to ten (10) business days after receipt of a written notice from you to reflect in our
              system any change to your bank account. If you change your bank account, you agree
              that you are responsible for all costs incurred by us in connection with your decision
              to change the account. You may revoke any pre-authorization upon thirty (30) days'
              prior written notice to us. You will receive an email notification seven days before
              your scheduled recurring payment is processed. It is your responsibility to disable
              your pre-authorized debit or recurring payment a minimum of 2 full business days
              before the scheduled recurring payment date. If you are set up with pre-authorized
              recurring credit card payments and we cannot complete the transaction with your
              primary credit card account on file, we will attempt to complete the transaction with
              the secondary credit card account on file.
            </p>
            <p>
              <u>Landlords</u>
            </p>
            <p>
              If you receive pre-authorized Debit Card Payments, you must comply with the following
              requirements:
            </p>
            <ol type='a'>
              <li>
                Authorization. You must receive the Tenant's prior authorization for the amount,
                frequency and duration of the pre-authorized Payment.
              </li>
              <li>
                Required Cancellation Procedures for Preapproved and/or Pre-Authorized Debit Card
                Payments. You must provide Tenants with the ability to cancel a preapproved Payment
                and/or pre-authorized Debit Card Payment within three (3) Business Days of the
                scheduled date of the preapproved payment and/or pre-authorized Debit Card Payment.
                In addition, if your Tenants signed up for the preapproved Payment and/or
                pre-authorized Debit Card Payment through an online method, you must provide a
                simple and easily accessible online cancellation procedure. If your Tenant cancels
                the preapproved Payment and/or pre-authorized Debit Card Payment, you may not charge
                for the preapproved Payment.
              </li>
            </ol>
            <p>5.7 Payment Limits</p>
            <p>
              We may, at our sole discretion, impose limits on the Payment you can send or receive
              through our Services.
            </p>

            <p>5.8 Insufficient Funds or Credit</p>
            <p>
              <u>Tenants</u>
            </p>
            <p>
              NOTWITHSTANDING ANY OTHER PROVISION, Letus IS NOT RESPONSIBLE FOR A TENANT'S PAYMENT OF RENT OR
              OTHER FEES TO YOUR LANDLORD OR OTHER PAYEES: YOU ARE SOLELY RESPONSIBLE. ANY FAILURE IN RELATION
              TO MAKING A PAYMENT TO YOUR LANDLORD OR PAYEES IS YOUR SOLE RESPONSIBILITY AND Letus IS NOT LIABLE,
              IN ANY WAY, FOR YOUR FAILURE OR INABILITY TO MAKE PAYMENTS TO YOUR LANDLORD OR PAYEES. THIS INCLUDES,
              BUT IS NOT LIMITED TO, ANY SITUATION WHERE (I) YOU HAVE INSUFFICIENT FUNDS OR CREDIT AVAILABLE
              TO MAKE A PAYMENT TO YOUR LANDLORD OR PAYEES AS A RESULT OF Letus CHARGING ANY APPLICABLE SERVICE
              FEE FOR YOUR USE OF THE SERVICES; OR (II) ANY PAYMENT MADE BY OR ON BEHALF OF YOU IS CHARGED BACK,
              RETURNED, OR INCORRECT INFORMATION REGARDING SUCH PAYMENT IS PROVIDED TO Letus, INCLUDING IN EACH CASE
              BY REASON OF ERROR OR FRAUD BY YOU, YOUR LANDLORD OR ANY THIRD PARTY. YOU ACKNOWLEDGE THAT EACH INSTANCE
              OF YOUR USE OF THE WEBSITE TO MAKE ANY PAYMENT IS YOUR DECISION FROM OTHER PAYMENT METHODS AVAILABLE TO
              YOU AND REGARDLESS OF THE METHOD OF PAYMENT SELECTED BY YOU, YOU ARE SOLELY RESPONSIBLE FOR ANY RENT OR
              FEES REQUIRED TO BE PAID BY YOU TO YOUR LANDLORD OR ANY OTHER PAYEE, INCLUDING FOLLOWING ANY FAILED OR
              CHARGED BACK PAYMENT MADE THROUGH Letus.
            </p>
            <p>
              <u>Landlords</u>
            </p>
            <p>
              NOTWITHSTANDING ANY OTHER PROVISIONS, Letus IS NOT RESPONSIBLE FOR THE PAYMENT OF RENT OR FEES TO YOU BY
              A USER, OTHER THAN TO THE EXTENT WE FACILITATE THE PROCESSING OF SUCH PAYMENT. ANY FAILURE IN RELATION TO
              A USER'S ABILITY TO MAKE A PAYMENT TO YOU IS THE SOLE RESPONSIBILITY OF SUCH USER, AND PAYSLATE IS NOT
              LIABLE, IN ANY WAY, FOR ANY FAILURE OR INABILITY OF A USER TO MAKE A PAYMENT TO YOU. THIS INCLUDES,
              BUT IS NOT LIMITED TO, ANY SITUATION WHERE (I) A USER HAS INSUFFICIENT FUNDS OR CREDIT AVAILABLE TO MAKE
              A PAYMENT TO YOU AS A RESULT OF Letus CHARGING ANY APPLICABLE SERVICE FEE FOR USE OF THE SERVICES;
              OR (II) ANY PAYMENT MADE BY OR ON BEHALF OF A USER IS CHARGED BACK, RETURNED, OR INCORRECT INFORMATION
              REGARDING SUCH PAYMENT IS PROVIDED TO Letus, INCLUDING IN EACH CASE BY REASON OF ERROR OR FRAUD BY YOU,
              A TENANT OR ANY THIRD PARTY.
            </p>

            <p>5.9 Insufficient Funds Fees</p>
            <p>
              If you make or attempt to make a Payment through the Website and the Payment is
              rejected for insufficient funds (an “<strong>NSF Payment</strong>”), in addition to
              any insufficient funds fee mandated by your financial institution and/or Landlord, we
              may, in our discretion, charge an NSF Payment in the amount of up to $35.00 in the local
              currency where you are resident. If you make or attempt to make an NSF Payment, each
              of we and your Landlord may refuse to allow you to initiate any kind of new Payment by
              ACH or Pre-Authorized Debit until your account is restored to good standing. For
              avoidance of doubt, if any Payment you initiate using the Website does not
              successfully complete, the intended Payment recipient reserves the right to seek
              payment from you via the Website or otherwise. The amount of the NSF Payment fee in
              this section is subject to change in our sole discretion and without notice.
            </p>

            <p>5.10 Declined Service Fees</p>
            <p>
              If any Service Fee is declined for a Payment you have made or received, your account
              will be considered in arrears. You must pay any outstanding Service Fees to put your
              account back into good standing. No additional Service Fees are applicable when paying
              the Service Fee through your account. Failure to do so may result in your account
              being suspended and/ or a third party collections agency contacting you.
            </p>

            <p>5.11 Negative Balance Recovery</p>
            <p>
              Users who are Landlords will at all times maintain a bank account at a bank that is a
              member of the ACH or ACSS system, as applicable (each, a “Bank Account”) and
              hereby authorize Letus to debit their Bank Account for the purposes of recovering any
              negative balance in the Merchant Account from time to time without notice. Each User
              who is a Landlord will do all such further things necessary to enable Letus to debit their
              Bank Account in accordance with this section, and agree to be liable for all fees,
              overdraft and/or overcharge expenses incurred by their bank as a result of such debits.
              In the event of error, Landlord authorizes Letus to make correcting credits or debits, as
              applicable, without notice to Landlord. Each Landlord covenants that it will not close,
              suspend or change the Bank Account information without reasonable written notice to
              Letus. Letus reserves the right to terminate or suspend the Services at any time that the
              Landlord fails to provide an active Bank Account with authorization set forth in this
              section or maintain sufficient balance thereunder.
            </p>

            <p>5.12 Default and Fraudulent Transactions</p>
            <p>
              If any single transaction is investigated and is determined to be fraudulent or suspicious,
              in Letus' sole discretion, Letus may terminate or limit the relevant User's account. Such
              default, fraudulent or suspicious transactions may be reported to legal authorities. We
              reserve the right not to authorize or settle any transaction you submit which we believe
              is in violation of this Agreement and all applicable agreements. We are not responsible
              for fraudulent transactions that are authorized by the payment network or bank. For the
              avoidance of doubt, in no event shall Letus be liable or otherwise responsible for any
              amount owing by a Tenant in respect of rent or other fees, which remain the sole
              responsibility of such Tenant notwithstanding any error or fraud by a User or any third
              party relating to the Payment of any such amount.
            </p>

            <p>5.13 Payments to Partners</p>
            <p>
              Subject to section 5.13 of this Agreement, Tenants may only make Payments to building
              owners, Landlords, property managers and entities who are authorized partners of Letus
              and have entered into the Terms of Use of Letus and its services providers (as
              applicable).
            </p>

            <p>5.14 Marketplace</p>
            <p>
              If you choose to use Marketplace Services offered by Letus, in addition to the above
              provisions, you agree that: (a) Letus is not responsible for the verification of the
              information provided by you about the Landlord for the use of the Marketplace
              Services; (b) Letus makes no representations or warranties that the Landlord will
              accept Payment via the Marketplace Services; (c) Letus is not responsible for any late
              or missed payments made through Marketplace, whether as a result of a failure of the
              Landlord to accept the Payment or otherwise; and (d) Letus makes no representations or
              warranties that payment via Marketplace will be error free.&nbsp;
            </p>
            <p>
              If your Landlord does not accept your payment via the Marketplace Services within 24
              hours, Letus will return the funds to you. Letus makes no representation or warranties
              that funds will be returned to you within a certain period of time and is not
              responsible for any late or missed payments as a result of the funds not being
              returned to you promptly.
            </p>
            <p>5.15 Payment by SMS</p>
            <p>
              If you choose to use payment by SMS services offered by Letus, you agree that (a) text
              messages authorizing payment sent from the mobile number contained in your account
              profile, including affirmative responses to Letus's SMS request as to whether payment
              should be authorized or submitted, constitute your irrevocable authorization to make
              the described payment; (b) you are solely responsible for confirming that any payment
              authorization made by SMS has been processed by Letus; (c) Letus is not responsible
              for any late or missed payments made by SMS, whether through a failure for an SMS to
              be sent or otherwise; (d) it is your sole responsibility to notify Letus of any change
              to your mobile number; (e) you will secure your phone with sufficient access features
              required to ensure that your authorization or submission of payment is not processed
              accidentally or by any other person; and (f) Letus makes no representations or
              warranties that payment by SMS services will be error free, or will function on all
              devices or network providers. Letus will charge such service fees for SMS payments as
              designated by Letus from time, and may amend such fees from time to time without
              notice to you.
            </p>

            <h5>6. Your Conduct</h5>
            <p>6.1 Conduct</p>
            <p>In connection with your use of our Website and our Services, you agree to:</p>

            <p>
              (a) use the Services exclusively for the remittance and acceptance of rental,
              condominium, utility or other property management fees, and not for the remittance or
              acceptance of any other types of payments unless approved by us;
            </p>

            <p>
              (b) comply in all respects with this Agreement and any other agreements that you have
              entered into with us;
            </p>

            <p>
              (c) comply with all applicable Federal, State/Provincial and Local laws and
              regulations;
            </p>

            <p>(d) not register multiple Letus accounts without our prior written consent;</p>

            <p>
              (e) not infringe our or any third party's copyright, patent, trade-mark, trade secret
              or other intellectual property rights, or rights of publicity or privacy;
            </p>

            <p>
              (f) provide, if requested, confirmation of your identity or any other information you
              have provided to us, and to cooperate with any investigations of the same by us or our
              third party delegates;
            </p>

            <p>
              (g) not make or receive Payments using the Services to provide yourself or any other
              person with a credit card cash advance;
            </p>

            <p>(h) respond in a reasonable and timely fashion to our communications;</p>

            <p>
              (i) not introduce or facilitate the use or spread of any viruses, trojan horses, worms
              or other computer programming routines that may damage, detrimentally interfere with,
              surreptitiously intercept or expropriate any system, data or information;
            </p>

            <p>
              (j) not use any robot, spider, other automatic device or manual process to monitor or
              copy our Website without our prior written consent;
            </p>

            <p>
              (k) not use any device, software or routine to bypass our robot exclusion headers or
              to interfere, or attempt to interfere, with our Website or our Services; and
            </p>

            <p>
              (l) not take any action that may cause us to lose any of our services from our
              internet service providers, our Payment Processor or other suppliers.
            </p>

            <p>6.2 Suspected Fraud</p>
            <p>
              IF LETUS HAS REASON TO BELIEVE, IN ITS SOLE DISCRETION, THAT YOU OR OTHERS MAY ENGAGE
              OR MAY HAVE ENGAGED IN ANY CRIMINAL OR CIVIL VIOLATION OF APPLICABLE LAWS, LETUS MAY
              CEASE TO PROVIDE YOU THE SERVICES, AND WILL CONTACT THE APPROPRIATE ADMINISTRATIVE AND
              LAW ENFORCEMENT AUTHORITIES. YOU AGREE TO COOPERATE FULLY WITH ANY SUCH
              INVESTIGATIONS.
            </p>

            <h5>7. Intellectual Property</h5>
            <p>7.1 Limited License</p>
            <p>
              You grant to us a limited, non-exclusive and revocable license to access and use all
              information and data that you provide in connection with your use of our Services. You
              agree not to use our Services for co-branding, framing, linking or reselling any
              portion of our Services.
            </p>

            <p>7.2 Intellectual Property</p>
            <p>
              "Letus", "Letus.com", "let.us", all related logos, marks, symbols, and words on our
              Website (including the Services) are either trademarks or registered trademarks that
              belong to us or to our licensors. In addition, all content and materials on our
              Website, Website page headers, custom graphics, button icons, and scripts are our
              copyrighted materials, service marks, trademarks or trade dress that belong to us or
              our licensors. You may not copy, imitate or use any of the foregoing without our prior
              written consent.
            </p>

            <h5>8. Security and Electronic Communication</h5>
            <p>8.1 Unsecure Electronic Channels</p>
            <p>
              You accept full responsibility for all risks associated with electronic communications
              between us, including, without limitation, the risk that such communications may be
              delivered to the wrong person, intercepted, lost or altered by a third party.
            </p>

            <p>8.2 Access to Account</p>
            <p>
              You are responsible for ensuring that the Services, your Merchant Account and your
              Letus Account will only be used by you or someone legally authorized to act on your
              behalf. You agree that any electronic communications made by you or purporting to have
              been made on your behalf will be binding on you.
            </p>

            <p>8.3 Notice of Unauthorized Use</p>
            <p>
              You agree to notify us immediately if you know or reasonably ought to know that an
              unauthorized person is accessing or may access our Services (through your Letus
              account or otherwise) or if you become aware of any actual or threatened use of our
              Services in a manner contrary to this Agreement.
            </p>

            <p>8.4 Web Links</p>
            <p>
              There may be links on our Website to websites not owned or operated by us. We make no
              representations about, do not endorse, and are not responsible for the content,
              products, services, privacy policies or practices relating to such other websites. We
              are not responsible for any losses realized by you or claims made against you relating
              to your use of such other websites.
            </p>

            <p>8.5 Evidence</p>
            <p>
              Electronic records and other information maintained by us regarding any electronic
              communications with you will be admissible in any legal, administrative or other
              proceedings as conclusive evidence of the contents of those communications in the same
              manner as an original paper document, and you waive any right to object to the
              introduction of any such record or other information into evidence on that basis.
            </p>

            <p>8.6 Help Center</p>
            <p>
              Standard message rates and data charges from your carrier apply when sending and/or
              receiving Letus text messages or calling the Letus Help Center. Please check with your
              carrier if you have questions about your plan and costs.
            </p>

            <h5>9. Privacy</h5>
            <p>9.1 Privacy</p>
            <p>
              By using the Website and our Services, you agree to the collection and use of your
              personal information, and the sharing of such information with our Indemnified Parties
              and your Tenant, Landlord, property manager, or other related party (as the case may
              be), in accordance with our Privacy Policy, available at&nbsp;
              <a href='https://let.us/privacy'>https://let.us/privacy</a>.
            </p>

            <p>9.2 Password Security and Keeping Your Email and Address Current</p>
            <p>
              You are responsible for maintaining adequate security and control of any and all IDs,
              passwords, personal identification numbers (PINs), or any other codes that you use to
              access the Services. You are also responsible for keeping your email address, street
              address, and other personal information, up to date in your Account Profile.
            </p>

            <p>9.3 Calls/SMS to You; Mobile Telephone Numbers</p>
            <p>
              By providing us with a telephone number (including a mobile telephone number), you
              consent to receiving auto-dialed and pre-recorded message calls from us at that
              number. If we determine that a telephone number you have provided to us is a mobile
              telephone number, we may categorize it as such in our systems and in your Account
              Profile, and you consent to receive SMS/text messages from us about your use of the
              Services at that number. Your call may be recorded for training and quality purposes.
            </p>

            <h5>10. Indemnification; Limitation of Liability; Warranties</h5>
            <p>10.1 Indemnification and Release</p>
            <p>
              You agree to indemnify and hold harmless each of the Indemnified Parties from any
              claim or demand made by any or all Indemnified Parties or any third parties (including
              reimbursement of reasonable legal costs incurred) due to or arising out of your use of
              our Services or our Website, your breach of this Agreement or your violation of any
              law or right of any third party.
            </p>

            <p>10.2 Limitations of Liability</p>
            <p>
              IN NO EVENT SHALL ANY INDEMNIFIED PARTY BE LIABLE TO YOU FOR LOST PROFITS, BUSINESS,
              REVENUE, OR CUSTOMERS OR ANY SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE OR CONSEQUENTIAL
              DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOST PROFITS,
              DISCLOSURE OF INFORMATION OR LOSS OF PRIVACY), HOWEVER ARISING OUT OF OR IN CONNECTION
              WITH OUR WEBSITE, OUR SERVICES OR THIS AGREEMENT INCLUDING NEGLIGENCE EVEN IF LETUS
              HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANYTHING TO THE CONTRARY
              NOTWITHSTANDING, THE CUMULATIVE LIABILITY OF ALL INDEMNIFIED PARTIES TO YOU OR ANY
              THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED IN TOTAL TO THE LESSER OF (A) THE SERVICE
              FEES YOU PAID TO LETUS IN THE SIX MONTHS PRIOR TO THE EVENT GIVING RISE TO AN ALLEGED
              LIABILITY AND (B) FIFTY DOLLARS, AS THE SOLE AND EXCLUSIVE REMEDY.
            </p>

            <p>10.3 No Warranty</p>
            <p>
              OUR SERVICES ARE PROVIDED AS IS AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS,
              IMPLIED OR STATUTORY, OF ANY KIND. WE SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF
              TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WE DO
              NOT GUARANTEE CONTINUOUS, UNINTERRUPTED, ERROR-FREE OR SECURE ACCESS TO ANY PART OF
              OUR SERVICES.
            </p>

            <p>10.4 No Jury or Class Action</p>
            <p>
              YOU HEREBY WAIVE ANY RIGHT TO A TRIAL BY JURY AND AGREE THAT ANY AND ALL DISPUTES,
              CLAIMS, ACTIONS AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE TERMS OF USE
              AND ALL ISSUES AND QUESTIONS CONCERNING THE VALIDITY, INTERPRETATION AND
              ENFORCEABILITY OF THIS TERMS OF USE AND OTHER DOCUMENTS SHALL BE RESOLVED
              INDIVIDUALLY, WITHOUT RESORT TO ANY FORM OF CLASS ACTION.
            </p>

            <h5>11. Termination</h5>
            <p>11.1 Termination by Letus</p>
            <p>
              We may, in our sole discretion and without penalty or liability whatsoever, terminate
              this Agreement or your access to our Services for any reason and at any time, upon
              notice to you.
            </p>
            <p>
              This Agreement shall be for an initial term of twelve (12) months commencing on the
              Effective Date (the "Initial Term") and shall automatically renew at the end of each
              successive term for a further twelve (12) months unless terminated in accordance with
              the terms and provisions of this Agreement, unless otherwise provided for in any
              specific agreement between you and us, in which case the terms of such agreement will
              govern.
            </p>
            <p>11.2 Post-Termination Transition</p>
            <p>
              All monetary and non-monetary obligations of the parties owing or to be performed or
              discharged prior to the date of termination or expiration shall survive termination
              until such obligations have been performed or discharged.
            </p>

            <h5>12. Miscellaneous</h5>
            <p>12.1 Currency</p>
            <p>
              Unless otherwise specified, all dollar amounts referred to in this Agreement are in
              lawful money of Canada and the United States, as applicable.
            </p>

            <p>12.2 Relationship</p>
            <p>
              Letus is an independent contractor. Nothing contained in this Agreement will create a
              partnership, joint venture, principal-and-agent relationship or any similar
              relationship between us.
            </p>

            <p>12.3 Third-Party Providers</p>
            <p>
              We may use one or more third-party service providers in the course of providing our
              Services. You agree that information about you and your Payments may be shared with or
              accessed by such Third Party Services providers whether or not you are using the Third
              Party Services.
            </p>

            <p>12.4 Features and Functionality</p>
            <p>
              You understand that the Services may change over time as we refine and add more
              features or content. We reserve the right to update, modify, or discontinue the
              features, functionality, content or other aspects of the Services, including without
              limitation, Letus, any and all MoolaPerks, contests and promotions at any time, with
              or without notice, in our sole discretion. Any new features that augment or enhance
              the current Services, including the release of new tools and resources, shall be
              subject to this Agreement. We do not guarantee the availability of the Services and/or
              any of the MoolaPerks or other benefits contained therein, and they are all subject to
              change at any time without notice.
            </p>

            <p>12.5 Entire Agreement</p>
            <p>
              This Agreement constitutes the entire agreement between us relating to the subject
              matter of this Agreement; it supersedes and replaces any previous or contemporaneous
              agreements and discussions between us.
            </p>

            <p>12.6 Representation and Warranty</p>
            <p>
              You represent and warrant that your execution of and performance under this Agreement
              (a) in no way breaches, contravenes, violates or in any manner conflicts with any of
              your other legal obligations, [including, without limitation, your corporate charter
              or similar document, if applicable,] or any agreement between you and any third party;
              and (b) has been duly authorized by all necessary actions and does not require any
              consent or other action by or in respect of any third party.
            </p>

            <p>12.7 Notices</p>
            <p>
              In order to be effective, notice pursuant to this Agreement must be given as follows:
            </p>

            <p>
              (a) notice to you may be given by electronic mail to the email address stated in your
              Account Profile, or by posting such notice on our Website, whether addressed
              specifically to you or more generally to users of our Website or our Services; and
            </p>

            <p>
              (b) except as otherwise stated in this Agreement, notice to us must be sent by postal
              mail, with an electronic mail copy to help@let.us, to:
            </p>

            <p>
              PaySlate Inc.
              <br />
              1900-1040 West Georgia Street,
              <br />
              Vancouver BC V6E 4H3
            </p>

            <p>
              and such notice is effective on the fourth Business Day following the day on which it
              is postmarked.
            </p>

            <p>12.8 Severability</p>
            <p>
              The invalidity or unenforceability of any particular provision of this Agreement will
              not affect or limit the validity or enforceability of the remaining provisions.
            </p>

            <p>12.9 Governing Law</p>
            <p>
              This Agreement is governed by, and is to be interpreted, construed and enforced in
              accordance with, the laws of the Province of British Columbia and the laws of Canada
              applicable in British Columbia, excluding any rule or principle conflicts of law that
              may provide otherwise. The United Nations Convention on Contracts for the
              International Sale of Goods does not apply to this Agreement.
            </p>

            <p>12.10 Jurisdiction</p>
            <p>
              The parties irrevocably submit to and accept generally and unconditionally the
              exclusive jurisdiction of the courts and appellate courts of the Province of British
              Columbia with respect to any legal action or proceeding which may be brought at any
              time relating in any way to this Agreement except for applications for injunctive
              relief or specific performance by Letus. Each of the parties irrevocably waives any
              objection it may now or in the future have to the venue of any such action or
              proceeding, and any claim it may now or in the future have that any such action or
              proceeding has been brought in an inconvenient forum. Jurisdiction and venue under
              this Agreement shall lie in the Province of British Columbia.
            </p>

            <p>12.11 Binding Effect; Assignment</p>
            <p>
              This Agreement enures to the benefit of and binds the parties' respective heirs,
              executors, administrators and other legal representatives, successors and permitted
              assigns. You may not assign this Agreement without our prior written consent. We may
              assign this Agreement to a third party upon notice to you.
            </p>

            <p>12.12 Equivalency</p>
            <p>This electronic Agreement and any other materials incorporated herein will be:</p>

            <p>
              (a) deemed for all purposes to be a "writing" or "in writing", and to comply with all
              statutory, contractual, and other legal requirements for a writing;
            </p>

            <p>
              (b) legally enforceable as a signed writing as against the parties subject to the
              electronic documents; and
            </p>

            <p>
              (c) deemed an "original" when printed from electronic records established and
              maintained in the ordinary course of business.
            </p>

            <p>
              Electronic documents introduced as evidence in any judicial, arbitration, mediation or
              proceeding will, if established and maintained in the ordinary course of business, be
              admissible to the same extent as business records in written form that are similarly
              established and maintained.
            </p>

            <p>12.13 Amendments</p>
            <p>
              We may amend this Agreement from time to time at our discretion. You accept
              responsibility for reviewing all communication about this Agreement on our Website and
              acknowledge you will be deemed to have agreed to the terms of amendments communicated
              on our Website if you continue to use our Services.
            </p>

            <p>12.14 Professional Advice</p>
            <p>
              Any information provided to you by us is merely guidance and you agree that it does
              not constitute professional advice. You should consult with professional accounting,
              tax, legal and other advisors before deciding to use our Services.
            </p>

            <p>12.15 Waivers</p>
            <p>
              A waiver shall not be effective unless it is in writing and signed by the party
              against whom the waiver is being enforced.
            </p>

            <p>12.16 Survival</p>
            <p>
              The provisions of Sections 1, 3, 4, 5, 6.2, 7, 8, 9, 10, 11.2 and 12 of this Agreement
              shall survive termination and expiration.
            </p>

            <p>12.17 Force Majeure</p>
            <p>
              We are not responsible for damages caused by delay or the failure to perform any of
              our obligations under this Agreement when the delay or failure is the result of Force
              Majeure.
            </p>

            <p>12.18 Language</p>
            <p>
              The parties confirm that this Agreement, as well as any other documents relating to
              this Agreement, have been and shall be prepared in the English language only. Les
              parties reconnaissent avoir convenue que la présente convention ainsi que tous
              documents, avis et procédures judiciaires qui pourront être exécutés, donnés ou
              intentées à la suite des présentes ou ayant un rapport, direct ou indirect, avec la
              présente convention soient rédigée en anglais. Las partes confirman que este Acuerdo,
              así como cualesquiera otros documentos relacionados con este Acuerdo, han sido y serán
              preparados en el idioma inglés solamente.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Terms;
