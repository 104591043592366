import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { parseDateForPad } from "../../util/dates";

class ReceiptScheduledPayment extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A scheduled payment receipt modal.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="modal fade" id="receipt-scheduled-payment" tabIndex="-1" role="dialog" aria-labelledby="receipt-scheduled-payment-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="">
                                <FontAwesomeIcon icon={["fas", "calendar-day"]} className="fa-fw va-b mb-3" size="4x" />
                            </div>
                            <h5 className="modal-title" id={`receipt-scheduled-payment-label`}>
                                Scheduled Payment
                            </h5>
                            <p className="mb-0 small">
                                Scheduled for{" "}
                                <Moment format="MMM DD, YYYY" tz="UTC">
                                    { this.props.scheduledPayment?.padSchedule 
                                    ? parseDateForPad(this.props.scheduledPayment.scheduledDate)
                                    : this.props.scheduledPayment.scheduledDate}
                                </Moment>
                            </p>
                        </div>

                        {!this.props.scheduledPayment.error &&
                        <div className="modal-body bg-secondary border-top-0">
                            <p className="mb-0">
                                Please ensure you have sufficient funds in your account on the payment date so your payment can successfully process.
                            </p>
                        </div>
                        }

                        {(this.props.displayAdvanced && this.props.scheduledPayment.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                <span className="font-weight-bold">There was an error attempting to process this transaction:</span> {this.props.scheduledPayment.error}
                            </p>
                        </div>
                        }

                        {(!this.props.displayAdvanced && this.props.scheduledPayment.error) &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white">
                                There was an error attempting to process this transaction. For additional information, please contact our support team at help@rentmoola.com.
                            </p>
                        </div>
                        }

                        <div className="modal-body modal-body-table">
                            <table className="table mb-0">
                                <tbody>
                                {this.props.scheduledPayment.charges &&
                                <React.Fragment>
                                    {this.props.scheduledPayment.charges.map((data, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <FormattedMessage id={data.name ? data.name : data.code} />
                                                </td>
                                                <td className="text-right">
                                                    <FormattedNumber value={data.amount} style={`currency`} currency="USD" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </React.Fragment>
                                }
                                {this.props.scheduledPayment.feeAmount != null &&
                                <tr>
                                    <td className="">
                                        Transaction Fee
                                    </td>
                                    <td className="text-right">
                                        <FormattedNumber value={this.props.scheduledPayment.feePaidByCompany ? 0 : this.props.scheduledPayment.feeAmount} style={`currency`} currency="USD" />
                                    </td>
                                </tr>
                                }
                                {(this.props.scheduledPayment.amount != null && this.props.scheduledPayment.feeAmount != null) &&
                                <tr>
                                    <td className="font-weight-bold">
                                        Total
                                    </td>
                                    <td className="font-weight-bold text-right">
                                        <FormattedNumber value={this.props.scheduledPayment.amount + (this.props.scheduledPayment.feePaidByCompany ? 0 : this.props.scheduledPayment.feeAmount)} style={`currency`} currency="USD" />
                                    </td>
                                </tr>
                                }
                                {(this.props.scheduledPayment.amount != null && this.props.scheduledPayment.feeAmount == null) &&
                                <tr>
                                    <td className="font-weight-bold">
                                        Total
                                    </td>
                                    <td className="font-weight-bold text-right">
                                        <FormattedNumber value={this.props.scheduledPayment.amount} style={`currency`} currency="USD" />
                                    </td>
                                </tr>
                                }
                                <tr className="small">
                                    <td className="">
                                        Transaction Type
                                    </td>
                                    <td className="text-right">
                                        Scheduled
                                    </td>
                                </tr>
                                {this.props.scheduledPayment.status &&
                                <tr className="small">
                                    <td className="">
                                        Transaction Status
                                    </td>
                                    <td className="text-right">
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + this.props.scheduledPayment.status + ".class"})}`} />
                                            <span className="ml-1"><FormattedMessage id={`enum.transactionStatus.${this.props.scheduledPayment.status}`} /></span>
                                        </div>
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.padSchedule &&
                                <tr className="small">
                                    <td className="">
                                        PAD Processing
                                    </td>
                                    <td className="text-right">
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className="fa-fw small text-success" />
                                            <span className="ml-1">Enabled</span>
                                        </div>
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.joins && this.props.scheduledPayment.joins.c &&
                                <tr className="small">
                                    <td className="">
                                        Tenant
                                    </td>
                                    <td className="text-right">
                                        {this.props.userType &&
                                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.props.scheduledPayment.billingAccountId}/edit`} onClick={() => $('#receipt-scheduled-payment').modal('hide')}>
                                            {this.props.scheduledPayment.joins.c.firstName} {this.props.scheduledPayment.joins.c.lastName}
                                        </Link>
                                        }
                                        {!this.props.userType &&
                                        <React.Fragment>
                                            {this.props.scheduledPayment.joins.c.firstName} {this.props.scheduledPayment.joins.c.lastName}
                                        </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.paymentMethod &&
                                <tr className="small">
                                    <td className="">
                                        Payment Method
                                    </td>
                                    <td className="text-right">
                                        {this.props.scheduledPayment.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                        <div className="">
                                            {this.props.scheduledPayment.paymentMethod.brand ? formatMessage({id: "enum.creditCard.brand." + this.props.scheduledPayment.paymentMethod.brand}) : 'Credit Card'} {this.props.scheduledPayment.paymentMethod.last4 ? ' ending in ' + this.props.scheduledPayment.paymentMethod.last4 : ''}
                                        </div>
                                        }
                                        {this.props.scheduledPayment.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                        <div className="">
                                            {'Bank Account'} {this.props.scheduledPayment.paymentMethod.last4 ? ' ending in ' + this.props.scheduledPayment.paymentMethod.last4 : ''}
                                        </div>
                                        }
                                        {this.props.scheduledPayment.paymentMethodType === 'TYPE_CASH' &&
                                        <div className="">
                                            {'Cash'}
                                        </div>
                                        }
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.joins && (this.props.scheduledPayment.joins.cc || this.props.scheduledPayment.joins.ba) &&
                                <tr className="small">
                                    <td className="">
                                        Payment Method
                                    </td>
                                    <td className="text-right">
                                        {this.props.scheduledPayment.joins.cc &&
                                        <div className="">
                                            {this.props.scheduledPayment.joins.cc.brand ? formatMessage({id: "enum.creditCard.brand." + this.props.scheduledPayment.joins.cc.brand}) : 'Credit Card'} {this.props.scheduledPayment.joins.cc.last4 ? ' ending in ' + this.props.scheduledPayment.joins.cc.last4 : ''}
                                        </div>
                                        }
                                        {this.props.scheduledPayment.joins.ba &&
                                        <div className="">
                                            {'Bank Account'} {this.props.scheduledPayment.joins.ba.last4 ? ' ending in ' + this.props.scheduledPayment.joins.ba.last4 : ''}
                                        </div>
                                        }
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.joins && this.props.scheduledPayment.joins.pl &&
                                <tr className="small">
                                    <td className="">
                                        Unit
                                    </td>
                                    <td className="text-right">
                                        {this.props.scheduledPayment.joins.pl.unit}
                                    </td>
                                </tr>
                                }
                                {this.props.scheduledPayment.joins && this.props.scheduledPayment.joins.p &&
                                <tr className="small">
                                    <td className="">
                                        Property
                                    </td>
                                    <td className="text-right">
                                        {(this.props.userType && this.props.userType !== 'TYPE_ADMIN') &&
                                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/properties/${this.props.scheduledPayment.joins.p.id}/edit`} onClick={() => $('#receipt-scheduled-payment').modal('hide')}>
                                            {this.props.scheduledPayment.joins.p.street1}
                                        </Link>
                                        }
                                        {(this.props.userType && this.props.userType === 'TYPE_ADMIN') &&
                                        <React.Fragment>
                                            {this.props.scheduledPayment.joins.p.street1}
                                        </React.Fragment>
                                        }
                                        {!this.props.userType &&
                                        <React.Fragment>
                                            {this.props.scheduledPayment.joins.p.street1}
                                        </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                }
                                {(this.props.displayAdvanced && this.props.scheduledPayment.joins && this.props.scheduledPayment.joins.co) &&
                                <tr className="small">
                                    <td className="">
                                        {this.props.scheduledPayment.joins.co.landlordId &&
                                        <span className="">Landlord</span>
                                        }
                                        {!this.props.scheduledPayment.joins.co.landlordId &&
                                        <span className="">Company</span>
                                        }
                                    </td>
                                    <td className="text-right">
                                        {this.props.userType &&
                                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/${this.props.scheduledPayment.joins.co.landlordId ? 'landlords' : 'companies'}/${this.props.scheduledPayment.joins.co.landlordId ? this.props.scheduledPayment.joins.co.landlordId : this.props.scheduledPayment.joins.co.id}/edit`} onClick={() => $('#receipt-scheduled-payment').modal('hide')}>
                                            {this.props.scheduledPayment.joins.co.name}
                                        </Link>
                                        }
                                        {!this.props.userType &&
                                        <React.Fragment>
                                            {this.props.scheduledPayment.joins.co.name}
                                        </React.Fragment>
                                        }
                                    </td>
                                </tr>
                                }
                                <tr className="small">
                                    <td className="">
                                        Scheduled Payment Date
                                    </td>
                                    <td className="text-right">
                                        <Moment format="MMM DD, YYYY" tz="UTC">
                                            { this.props.scheduledPayment?.padSchedule 
                                            ? parseDateForPad(this.props.scheduledPayment.scheduledDate)
                                            : this.props.scheduledPayment.scheduledDate}
                                        </Moment>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                            {this.props.children}

                        </div>

                    </div>
                </div>
            </div>
        )
    };
}

ReceiptScheduledPayment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReceiptScheduledPayment);
