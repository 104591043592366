const headings = ['Get Credit For', 'Paying Your Rent'];
const subHeading =
  'Build your credit score for less than $5 a month when you report your rent with Let.us Credit Reporting.';
let offerBubble = 'First 4 months free!';
const section1 =
  'Renters who report their rent can see a potential increase in their credit score between 36 and 125 points in a year—which can have a huge impact. For example, a 23-point bump (ie. 635 to 658) could translate to a savings of more than $4,000 on a 36-month car loan.';
const bulletPointsTitle = "Here's how:";
const bulletPoints = [
  'Log in to your Let.us account.',
  'Select your payment method and click Subscribe to Credit Reporting.',
  'Watch for impact on your credit score.',
];
const footerMessages = [
  null,
  'Your email is already registered.',
  'Please refer to your registration email from your landlord,',
  'or click "Forgot your password?" to recover your account.',
];

const headingsFR = ['Obtenez un crédit pour', 'Payer votre loyer'];
const subHeadingFR =
  'Boostez votre pointage de crédit pour moins de 5 $ par mois lorsque vous déclarez votre loyer avec Let.us Credit Reporting.';
let offerBubbleFR = '4 premiers mois gratuits !';
const section1FR =
  'Les locataires qui déclarent leur loyer peuvent voir une augmentation potentielle de leur pointage de crédit entre 36 et 125 points en un an, ce qui peut avoir un impact énorme. Par exemple, une augmentation de 23 points (c.-à-d. 635 à 658) pourrait se traduire par une économie de plus de 4 000 $ sur un prêt automobile de 36 mois.';
const bulletPointsTitleFR = 'Comment procéder:';
const bulletPointsFR = [
  'Connectez-vous à votre compte Let.us.',
  "Sélectionnez votre mode de paiement et cliquez sur S'abonner à Credit Reporting.",
  "Profitez de l'impact sur votre pointage de crédit.",
];
const footerMessagesFR = [
  null,
  'Votre email est déjà enregistré.',
  "Veuillez vous referer a l'email d'inscription de votre propriétaire,",
  'ou cliquez sur "Mot de passe oublié ?" pour récupérer votre compte.',
];

/**
 * Return page text based on client and locale
 * @param {string} client 
 * @param {string} locale 
 * @returns 
 */
export const getLoginCRText = (client, locale) => {
  if(client === 'Global Education Cities' || client === 'Canadian Campus Communities') {
    offerBubble = 'First 2 months free!';
    offerBubbleFR = '2 premier mois gratuits !';
  }
  if (locale === 'fr') {
    footerMessagesFR[0] = `Vous vivez dans un immeuble du ${client}?`;
  } else {
    footerMessages[0] = `Live in a ${client} building?`;
  }
  return {
    headings: locale === 'fr' ? headingsFR : headings,
    subHeading: locale === 'fr' ? subHeadingFR : subHeading,
    section1: locale === 'fr' ? section1FR : section1,
    offerBubble: locale === 'fr' ? offerBubbleFR : offerBubble,
    bulletPointsTitle: locale === 'fr' ? bulletPointsTitleFR : bulletPointsTitle,
    bulletPoints: locale === 'fr' ? bulletPointsFR : bulletPoints,
    footerMessages: locale === 'fr' ? footerMessagesFR : footerMessages,
  };
};

/**
 * Return tracking URL based on the client name
 * @param {string} clientName
 * @returns
 */
export const getPixelTrackingURLByClient = (clientName) => {
  let url = null;
  const clientNameUpperCase = clientName?.toUpperCase();
  switch (clientNameUpperCase) {
    case 'MIDWEST':
      url='https://tag.simpli.fi/sifitag/809abc50-74f6-013b-5a5b-0cc47a1f72a4';
      break;
    case 'DEVON':
      url='https://tag.simpli.fi/sifitag/7154c3c0-74f6-013b-5a5b-0cc47a1f72a4';
      break;
    case 'KANEFF':
      url='https://tag.simpli.fi/sifitag/790ff1f0-74f6-013b-ab7f-0cc47abd0334';
      break;
    case 'BGO':
      url='https://tag.simpli.fi/sifitag/883f9ec0-74f6-013b-ab7f-0cc47abd0334';
      break;
    case 'GEC':
      url='https://tag.simpli.fi/sifitag/90c46060-74f6-013b-608e-0cc47a8ffaac';
      break;
    case 'CCC':
      url='https://tag.simpli.fi/sifitag/9b955100-74f6-013b-5a5b-0cc47a1f72a4';
      break;
    case 'SUMMIT':
      url='https://tag.simpli.fi/sifitag/b1d5f610-74f6-013b-608e-0cc47a8ffaac';
      break;
    case 'LETUS':
      url='https://tag.simpli.fi/sifitag/a9327620-74f6-013b-ab7f-0cc47abd0334';
      break;
    default:
      break;
  }
  return url;
};