import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { injectIntl, intlShape } from "react-intl";
import charges from "../../util/charges";

class FieldCharges extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.handleChangeCharges = this.handleChangeCharges.bind(this);
    }

    /**
     * Handle changes to the selected charges. Handles both the charge code and charge amount fields for each charge.
     *
     * @param event - The event container.
     */
    handleChangeCharges(event) {

        const parentStateName = this.props.parentStateName;
        let charges = parentStateName ? this.props.parent.state[parentStateName] : this.props.parent.state.charges;

        let chargeIdSplit = (event.target.name).split("-");
        let chargeIdIndex = chargeIdSplit[chargeIdSplit.length - 1];

        // Handle code changes
        if(chargeIdSplit[1] === 'code') {
            charges[chargeIdIndex] = {
                amount: charges[chargeIdIndex].amount,
                code: event.target.value,
                name: event.target.options[event.target.selectedIndex].text
            };
        }

        // Handle amount changes
        if(chargeIdSplit[1] === 'amount') {
            charges[chargeIdIndex] = {
                amount: event.target.value,
                code: charges[chargeIdIndex].code,
                name: charges[chargeIdIndex].name
            };
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            [parentStateName || charges]: charges
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the user <to enter a list of charge types and amounts, generally for a
     * payment. Affects the parent's 'charges' state.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                {this.props.charges.map((charge, key) => {

                    return(
                        <div key={key} className="form-group row mb-2">
                            <div className="col-sm-12">
                                <div className={`input-group input-group-${this.props.size}`}>

                                    <select id={`charge-code-${key}`} name={`charge-code-${key}`} value={charge.code} className={`form-control mb-0`} onChange={this.handleChangeCharges} disabled={this.props.disabled}>

                                        <option value="">Select a charge...{this.props.required && '*'}</option>

                                        {this.props.customChargeCodes &&
                                        <React.Fragment>
                                            {this.props.customChargeCodes.filter(customChargeCode => !(customChargeCode.code === charge.code)).map((customChargeCode, key) => {
                                                return(
                                                    <option key={key} value={customChargeCode.code}>{customChargeCode.name}</option>
                                                )
                                            })}

                                        return(
                                            {
                                             charge.code && <option value={charge.code}>{charge.name}</option>
                                            }
                                        )

                                        </React.Fragment>
                                        }

                                        {!this.props.customChargeCodes &&
                                        <React.Fragment>
                                            {Object.keys(charges).filter(key => !(key === charge.code)).map(key => {
                                                return(
                                                    <option key={key} value={key}>{formatMessage({id: `charge.${key}`})}</option>
                                                )
                                            })}
                                        return(
                                            {
                                             charge.code && <option value={charge.code}>{charge.name}</option>
                                            }
                                        )

                                        </React.Fragment>
                                        }

                                    </select>

                                    <input id={`charge-amount-${key}`} name={`charge-amount-${key}`} value={charge.amount || ''} placeholder="0.00" type="number" className={`form-control mb-0`} min="0.00" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" disabled={this.props.disabled} onChange={this.handleChangeCharges} />

                                    {(!this.props.disabled && this.props.removeCharge) &&
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary" type="button" title="You must provide at least one charge" onClick={() => this.props.removeCharge(key)}>
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                        </button> {this.props.required && <span> * </span>}
                                    </div>
                                    }

                                </div>

                            </div>
                        </div>
                    );
                })}

                {(!this.props.disabled && this.props.addCharge) &&
                <div className={`btn btn-outline-primary btn-sm ${this.props.charges.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.props.addCharge()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add charge
                </div>
                }

                {(!this.props.disabled && this.props.importCharges) &&
                <div className={`btn btn-outline-primary btn-sm ml-2 ${this.props.charges.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.props.importCharges()}>
                    <FontAwesomeIcon icon={['fas', 'cloud-download-alt']} className="fa-fw"/> Import charges
                </div>
                }

            </React.Fragment>
        )
    };
}

FieldCharges.propTypes = {
    intl: intlShape.isRequired,
};

FieldCharges.defaultProps = {
    size: 'md'
};

export default injectIntl(FieldCharges);
