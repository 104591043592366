import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import React from 'react';
import { injectIntl } from 'react-intl';
import Moment from 'react-moment';
import * as constants from '../../util/constants';
import ButtonClose from './ButtonClose';
import FieldDate from './FieldDate';
import Propertii from './Propertii';
import Spinner from './Spinner';
import Table from './Table';
import Modal from './Modal';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

class ReportTenantApplications extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      tenantApplication: {},

      tenantApplicationsList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },

      startDate: moment()
        .subtract(30, 'days')
        .format('YYYY-MM-DD[T]hh:mm:ssZZ'),
      endDate: null,

      parameters: {
        propertyId: null,
      },

      propertyList: [],
      selectedProperty: null,

      validationList: [],
    };

    this.searchTenantApplications = this.searchTenantApplications.bind(this);
    this.filterTenantApplications = this.filterTenantApplications.bind(this);
    this.downloadTenants = this.downloadTenants.bind(this);
    this.viewTenantApplication = this.viewTenantApplication.bind(this);
    this.searchProperties = this.searchProperties.bind(this);
    this.transformDataForPDF = this.transformDataForPDF.bind(this);
    this.getHeaderForPDF = this.getHeaderForPDF.bind(this);
  }

  /**
   * Fetch the list of related properties and transactions upon mounting of the component.
   */
  componentDidMount() {
    let filter = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      parameters: this.state.parameters,
    };

    this.searchProperties();
    this.searchTenantApplications(1, 25, filter);
  }


  /**
   * View the detailed information of a tenantApplication by initializing the tenantApplication view modal.
   *
   * @param tenantApplication - The tenantApplication model to view.
   */
  viewTenantApplication(tenantApplication) {
    this.setState((prevState) => ({
      ...prevState,
      tenantApplication: tenantApplication,
    }));

    $('#tenantApplication').modal('show');
  }

  /**
   * Get the record of tenantApplication applications
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param filter - The filters to apply to the search.
   */
  searchTenantApplications(page, recordsPerPage, filter) {
    this.setState({
      spinner: true,
    });

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_applications?recordsPerPage=${recordsPerPage}&page=${page}`,
        filter,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          tenantApplicationsList: response.data,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Apply the available search filters on the tenantApplication list and perform a new search.
   *
   * @param event - The event container.
   */
  filterTenantApplications(event) {
    if (event != null) {
      event.preventDefault();
    }

    let filter = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      parameters: this.state.parameters,
    };

    if (this.state.fromDate != null) {
      filter.startDate = this.state.startDate;
    }

    if (this.state.endDate != null) {
      filter.endDate = this.state.endDate;
    }

    if (this.state.parameters.propertyId != null) {
      filter.parameters.propertyId = this.state.parameters.propertyId;
    }

    this.searchTenantApplications(1, 25, filter);
  }

  /**
   * Search for a list of all available properties for filtering.
   */
  searchProperties() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/property/search`,
        {
          orderBy: 'ASC',
          orderByFields: ['createDate'],
          conditionList: [
            {
              type: 'STRING',
              logicalOperator: 'OR',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'companyId',
              operator: 'EQUALS',
              fieldValue: this.props.companyId,
            },
            {
              type: 'STRING',
              logicalOperator: 'OR',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'c.parentId',
              operator: 'EQUALS',
              fieldValue: this.props.companyId,
            },
          ],
          joins: {
            c: {
              targetRecordType: 'TYPE_COMPANY',
              joinField: 'companyId',
              alias: 'c',
            },
          },
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          propertyList: response.data.records,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Show Modal to confirm download format
   *
   * @param event - The event container.
   */
  downloadTenants(event) {
    event.preventDefault();

    $('#download-format-confirm').modal('show');
  }

  /**
   * Download the tenantApplication report in CSV format. The downloaded file is based on the filters set by the user.
   *
   *
   */
  downloadTenantsCSV() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_applications/download`,
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          parameters: this.state.parameters,
        },
        {
          responseType: 'arraybuffer',
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        const FileDownload = require('js-file-download');

        let responseHeader = response.request.getResponseHeader(
          'Content-Disposition'
        );
        let startIndex = responseHeader.indexOf('filename=') + 24;
        let endIndex = responseHeader.length - 1;
        let filename = responseHeader.substring(startIndex, endIndex);

        FileDownload(response.data, filename);
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Download the tenantApplication report in PDF format. The downloaded file is based on the filters set by the user.
   *
   *
   */
  downloadTenantsPDF() {
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_applications/download`,
        {
          startDate: this.state.startDate,
          endDate: this.state.endDate,
          parameters: this.state.parameters,
        },
        {
          responseType: 'string',
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        const dataTransformed = this.transformDataForPDF(response.data);
        const doc = new jsPDF('landscape', 'mm', [297, 250]);

        doc.setFontSize(12);
        doc.text('Tenants Applications Report', 14, 10);

        doc.autoTable({
          head: [dataTransformed?.headerForPDF],
          body: dataTransformed?.bodyForPDF,
          horizontalPageBreak: false,
          theme: 'grid',

          headStyles: {
            fillColor: '#e9ecef',
            fontStyle: 'bold',
            textColor: '#212529',
            fontSize: '9',
          },
          bodyStyles: {
            textColor: '#212529',
            fontSize: '9',
            minCellHeight: '10',
            valign: 'middle',
          },
          columnStyles: {
            0: { cellWidth: 30 },
            1: { cellWidth: 30 },
            2: { cellWidth: 30 },
            3: { cellWidth: 30 },
            4: { cellWidth: 30 },
            5: { cellWidth: 30 },
            6: { cellWidth: 20 },
            7: { cellWidth: 30 },
            8: { cellWidth: 30 },
          },
        });
        doc.save('Tenant Applications Report.pdf');
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Transform data to get header and body to pass into jsPDF
   *
   * @param data - The data returned by the endpoint to be converted into PDF.
   */
  transformDataForPDF(data) {
    const rows = data.split('\n');
    let headerForPDF = '';
    let dataForPDF = {};
    let bodyForPDF = [[]];

    if (rows.length > 0) {
      headerForPDF = this.getHeaderForPDF(rows[0]);
      bodyForPDF = this.getBodyForPDF(rows);
      dataForPDF = {
        headerForPDF: headerForPDF,
        bodyForPDF: bodyForPDF,
      };
    }

    return dataForPDF;
  }

  /**
   * Get the header to be passed into jsPDF
   *
   * @param headerData - The data returned by the endpoint to be converted into PDF.
   */
  getHeaderForPDF(headerData) {
    const headersToBeCleaned = headerData.split('"');
    const headers = headersToBeCleaned.filter(
      (header) => header !== ',' && header !== '' && header !== '\r'
    );

    return headers;
  }

  /**
   * Get the body to be passed into jsPDF
   *
   * @param data - The data returned by the endpoint to be converted into PDF.
   */
  getBodyForPDF(data) {
    let bodyToBeCleaned = [];
    let rowAux = [];
    let body = [];

    for (let i = 1; i < data.length; i++) {
      rowAux = data[i].split('"');
      rowAux.shift();
      bodyToBeCleaned.push(rowAux);
      body[i - 1] = bodyToBeCleaned[i - 1].filter(
        (row) => row !== ',' && row !== '\r'
      );
    }
    return body;
  }

  /**
   * Render the component.
   *
   * @returns {*} - The tenantApplication report component.
   */
  render() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    return (
      <React.Fragment>
        <Spinner visible={this.state.spinner} />

        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Tenant Applications</div>
              <div className='col text-right'>
                <div
                  className='btn btn-primary btn-sm'
                  onClick={(event) => this.downloadTenants(event)}
                >
                  <FontAwesomeIcon
                    icon={['fas', 'download']}
                    className='fa-fw'
                  />{' '}
                  Download Report
                </div>
              </div>
            </div>
          </div>
         <div className='card-header gotham border-top py-3 bg-secondary'>
            <form onSubmit={this.filterTenantApplications} autoComplete='off'>
              <div className='media'>
                <div className='media-body align-self-center'>
                  <FieldDate
                    id='startDate'
                    labelColumns='0'
                    fieldColumns='12'
                    parent={this}
                    value={this.state.startDate}
                    selectsRange={true}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>

                <div className='align-self-center text-right'>
                  <button
                    type='submit'
                    className='btn btn-secondary btn-sm btn-block mb-0'
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'search']}
                      className='fa-fw'
                    />{' '}
                    Search
                  </button>
                </div>
              </div>
            </form>
          </div> 
          <Table
            tableClass='table-bordered table-hover table-responsive'
            columns={{
              createDate: 'Create Date',
              company:'Company',
              firstName: 'First Name',
              lastName: 'Last Name',
              property: 'Property',
              fee: 'Application Fee',
              paymentStatus: 'Payment Status',
              paymentMethod: 'Payment Method',
              applicationStatus: 'Application Status'
            }}
            minColumnWidths={[
              '200px',
              '200px',
              '200px',
              '200px',
              '200px',
              '200px',
              '200px',
              '200px',
              '200px',
            ]}
            headerClass=''
            data={this.state.tenantApplicationsList}
            filters={{
              startDate: this.state.startDate,
              endDate: this.state.endDate,
              parameters: this.state.parameters,
            }}
            sortEnabled={false}
            recordsEnabled={true}
            paginationEnabled={true}
            updateFunction={this.searchTenantApplications}
          >
            <tbody>
              {this.state.tenantApplicationsList.records.map((data, key) => {
                return (
                  <tr
                    key={key}
                    onClick={() => this.viewTenantApplication(data)}
                    className='c-pointer'
                  >
                    <td>
                      <div className=''>
                        {data.date && (
                          <Moment format='YYYY-MM-DD HH:mm (UTC)' tz='UTC'>
                            {data.date}
                          </Moment>
                        )}
                      </div>
                    </td>
                    <td>
                      <div className=''>{data.company}</div>
                    </td>
                    <td>
                      <div className=''>{data.firstName}</div>
                    </td>
                    <td>
                      <div className=''>{data.lastName}</div>
                    </td>
                    <td>
                      <div className=''>{data.property}</div>
                    </td>
                    <td>
                      <div className=''>{data.fee}</div>
                    </td>
                    <td>
                      <div className=''>{data.paymentStatus}</div>
                    </td>
                    <td>
                      <div className=''>{data.paymentMethod}</div>
                    </td>
                    <td>
                      <div className=''>{data.applicationStatus}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div
          className='modal fade'
          id='tenantApplication'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='tenantApplication-label'
          aria-hidden='true'
        >
          <div
            className='modal-dialog modal-dialog-centered modal-md'
            role='document'
          >
            <div className='modal-content shadow'>
              <div className='modal-header bg-dark text-white'>
                <h5 className='modal-title'>
                  <div className=''>Tenant Application</div>
                </h5>
                <button
                  type='button'
                  className='close text-white'
                  data-dismiss='modal'
                  aria-label='Close'
                >
                  <FontAwesomeIcon
                    icon={['fas', 'times']}
                    className='fa-fw va-b mr-2'
                  />
                </button>
              </div>
              <div className='modal-body modal-body-table'>
                <table className='table mb-0'>
                  <tbody>
                    <tr className='small'>
                      <td className=''>Create Date</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.date && (
                            <Moment format='YYYY-MM-DD HH:mm (UTC)' tz='UTC'>
                              {this.state.tenantApplication.date}
                            </Moment>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Company</td>
                      <td className='text-right'>
                        <div className=''>{this.state.tenantApplication.company}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>First Name</td>
                      <td className='text-right'>
                        <div className=''>{this.state.tenantApplication.firstName}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Last Name</td>
                      <td className='text-right'>
                        <div className=''>{this.state.tenantApplication.lastName}</div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Property</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.property}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Application Fee</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.fee}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Payment Status</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.paymentStatus}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Payment Method</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.paymentMethod}
                        </div>
                      </td>
                    </tr>
                    <tr className='small'>
                      <td className=''>Application Status</td>
                      <td className='text-right'>
                        <div className=''>
                          {this.state.tenantApplication.applicationStatus}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='modal-footer d-block text-center bg-secondary rounded-bottom'>
                <ButtonClose />
              </div>
            </div>
          </div>
        </div>

        <Modal
          id='download-format-confirm'
          theme='primary'
          iconType='fas'
          iconName='file-spreadsheet'
          body='What format do you want to download the report?'
          title='Download Format Confirmation'
        >
          <button
            type='button'
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
            onClick={() => this.downloadTenantsCSV()}
          >
            CSV Format
          </button>
          <button
            onClick={() => this.downloadTenantsPDF()}
            className='btn btn-outline-primary btn-lg'
            data-dismiss='modal'
          >
            PDF Format
          </button>
        </Modal>
      </React.Fragment>
    );
  }
}

export default injectIntl(ReportTenantApplications);
