import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import InsurancesList from "../../common/Insurances";
import Propertii from "../../common/Propertii";

class Services extends Propertii {
  /**
   * Render the component.
   *
   * @returns {*} - The admin Services component.
   */
  render() {
    return (
      <div className="content-block">
        <div className="container">
          <Breadcrumb parentPage="Services" />
          <InsurancesList
            global={true}
            widthAlign="450px"
            textSearchPlaceholderOverrideTenants="Search All Tenants"
            history={this.props.history}
            displayFeeAmount={true}
            displayAdvanced={true}
            userType="TYPE_MANAGER"
            individualInsurance={false}
            token={this.props.token}
          />
        </div>
      </div>
    );
  }
}

export default Services;
