import React from "react";
import { injectIntl } from "react-intl";
import { TYPE_COMMON } from "../../../constants/CommonConstants";
import Propertii from "../../common/Propertii";
import SyncsCommon from "../../common/Syncs";

class Syncs extends Propertii {
  /**
   * Render the component.
   *
   * @returns {*} - The company Sync component.
   */
  render() {
    return (
      <>
        <SyncsCommon companyId={this.props.match.params.companyId} syncFor={TYPE_COMMON.TYPE_COMPANY}/>
        <SyncsCommon companyId={this.props.match.params.companyId} syncFor={TYPE_COMMON.TYPE_TENANT}/>
      </>
    );
  }
}

export default injectIntl(Syncs);
