import React from 'react';

const FieldSelectFC = (props) => {
  return (
    <div className={`form-group row ${props.containerClass}`}>
      {props.label && (
        <label
          className={`col-sm-${props.labelColumns} col-form-label ${props.labelClass}`}
          htmlFor={props.id}
        >
          {props.label}
          {props.optional && (
            <small className='text-muted font-italic'>(Optional)</small>
          )}{' '}
          {props.required && !props.leaseApplicationPage && (
            <small className='text-muted font-italic'>*</small>
          )}
        </label>
      )}

      <div className={`col-sm-${props.fieldColumns}`}>
        <select
          id={props.id}
          name={props.id}
          value={props.value || ''}
          onChange={props.handleChange}
          onFocus={props.handleFocus}
          onBlur={props.handleBlur}
          onClick={props.onClick}
          disabled={props.disabled}
          multiple={props.multiple}
          required={props.required}
          className={`form-control ${props.fieldClass} ${
            props.isInvalid ? 'is-invalid' : ''
          } ${props.disabled ? 'DisabledInputField' : ''}`}
          style={props.fieldStyle}
        >
          {props.children}
        </select>

        {props.help && (
          <small className='form-text text-muted'>{props.help}</small>
        )}

      </div>
    </div>
  );
};


FieldSelectFC.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    fieldStyle: {},
};

export default FieldSelectFC;
