import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import FieldCharges from "../../common/FieldCharges";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldDate from "../../common/FieldDate";
import FieldSelect from "../../common/FieldSelect";
import JumbotronHelp from "../../common/JumbotronHelp";
import Modal from "../../common/Modal";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Application from "./Application";
import Screenings from "./Screenings";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            company: {},
            leaseApplication: {},
            startDate: null,
            monthlyPaymentDueDay: '',
            requireAutoPay: false,
            charges: [{}],
            validationList: [],
        };

        this.declineLeaseApplication = this.declineLeaseApplication.bind(this);
        this.approveLeaseApplication = this.approveLeaseApplication.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
    }

    /**
     * Fetch the application information as well as the property and company related to the lease application on
     * mounting of the component.
     */
    componentDidMount() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.leaseApplicationId
                }
            ],
            joins: {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                leaseApplication: response.data.records[0]
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Decline a pending lease application. Sends an email to the applicant letting them know their application has been
     * declined.
     */
    declineLeaseApplication() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let leaseApplication = this.state.leaseApplication;

        leaseApplication.status = 'DECLINED';

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, leaseApplication, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

            $('#decline-lease-application').modal("hide");

            this.props.history.push({
                pathname: `/landlord/applications`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.applications.declined'
                        },
                        values: {
                            firstName: this.state.leaseApplication.firstName,
                            lastName: this.state.leaseApplication.lastName
                        }
                    }],
                }
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Approve a pending lease application. Creates a pending invitation record and sends the new tenant an invitation
     * email allowing them to fully create a tenant profile.
     *
     * @param event - The event container.
     */
    approveLeaseApplication(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/${this.state.leaseApplication.id}/approve`, {
            startDate: this.state.startDate,
            monthlyPaymentDueDay: this.state.monthlyPaymentDueDay,
            recurringCharges: this.state.charges,
            requireAutoPay: this.state.requireAutoPay
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false
            }));

            $('#approve-lease-application').modal("hide");

            this.props.history.push({
                pathname: `/landlord/applications`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.applications.approved'
                        },
                        values: {
                            firstName: this.state.leaseApplication.firstName,
                            lastName: this.state.leaseApplication.lastName
                        }
                    }],
                }
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/landlord/applications" parentPage="Applications" childPage={`${this.state.leaseApplication.firstName} ${this.state.leaseApplication.lastName}`} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/landlord/applications/${this.props.match.params.leaseApplicationId}/view`} active="view" size="small" iconName="address-card" name="Application" />

                                        {!this.state.leaseApplication.existingTenant &&
                                        <NavListItem path={`/landlord/applications/${this.props.match.params.leaseApplicationId}/screening`} active="screening" size="small" iconName="search" name="Screening"/>
                                        }

                                        {this.state.leaseApplication.status === 'PENDING' &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#approve-lease-application">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'user-check']} className="fa-fw va-b mr-2" />
                                                    Approve Application
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {this.state.leaseApplication.status === 'PENDING' &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#decline-lease-application">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'user-times']} className="fa-fw va-b mr-2" />
                                                    Decline Application
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>

                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us/sections/360006583852-Landlords" />

                            <Modal id="decline-lease-application" theme="primary" iconType="fas" iconName="question-circle" title="Decline Application" body="Are you sure you want to decline this lease application? The applicant will receive a notification letting them know their application has been declined.">
                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => {$("#lease-application").modal("show")}}>
                                    <FormattedMessage id="button.back" />
                                </button>
                                <button className="btn btn-primary btn-lg" data-dismiss="modal" onClick={() => this.declineLeaseApplication(this.state.leaseApplication)}>
                                    Decline Application
                                </button>
                            </Modal>

                            <div className="modal fade" id="approve-lease-application" tabIndex="-1" role="dialog" aria-labelledby="approve-lease-application-label" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                    <div className="modal-content shadow">
                                        <form onSubmit={this.approveLeaseApplication} autoComplete="off">
                                            <div className="modal-header bg-dark text-white">
                                                <h5 className="modal-title" id="approve-lease-application-label">
                                                    Approve Application
                                                </h5>
                                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                                </button>
                                            </div>

                                            <div className="modal-body bg-secondary">
                                                <p className="mb-0">
                                                    Before approving this applicant, you may provide the following lease information prior to sending them an invitation to create their account.
                                                </p>
                                            </div>

                                            <React.Fragment>

                                                <div className="modal-body">

                                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                                    <FieldDate id="startDate" label="Start Date" parent={this} value={this.state['startDate'] || null} />

                                                    <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" parent={this} value={this.state['monthlyPaymentDueDay']}>
                                                        <option value={0}>No monthly payment due date</option>
                                                        <option value={1}>1st day of every month</option>
                                                        <option value={2}>2nd day of every month</option>
                                                        <option value={3}>3rd day of every month</option>
                                                        <option value={4}>4th day of every month</option>
                                                        <option value={5}>5th day of every month</option>
                                                        <option value={6}>6th day of every month</option>
                                                        <option value={7}>7th day of every month</option>
                                                        <option value={8}>8th day of every month</option>
                                                        <option value={9}>9th day of every month</option>
                                                        <option value={10}>10th day of every month</option>
                                                        <option value={11}>11th day of every month</option>
                                                        <option value={12}>12th day of every month</option>
                                                        <option value={13}>13th day of every month</option>
                                                        <option value={14}>14th day of every month</option>
                                                        <option value={15}>15th day of every month</option>
                                                        <option value={16}>16th day of every month</option>
                                                        <option value={17}>17th day of every month</option>
                                                        <option value={18}>18th day of every month</option>
                                                        <option value={19}>19th day of every month</option>
                                                        <option value={20}>20th day of every month</option>
                                                        <option value={21}>21st day of every month</option>
                                                        <option value={22}>22nd day of every month</option>
                                                        <option value={23}>23rd day of every month</option>
                                                        <option value={24}>24th day of every month</option>
                                                        <option value={25}>25th day of every month</option>
                                                        <option value={26}>26th day of every month</option>
                                                        <option value={27}>27th day of every month</option>
                                                        <option value={28}>28th day of every month</option>
                                                        <option value={29}>29th day of every month</option>
                                                        <option value={30}>30th day of every month</option>
                                                        <option value={31}>31st day of every month</option>
                                                    </FieldSelect>

                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label col-form-label-sm">
                                                            Charges
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <FieldCharges charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} size="sm" parent={this} />
                                                        </div>
                                                    </div>

                                                    <FieldCheckbox id="requireAutoPay" label="Recurring" fieldLabel="Require this tenant to set up automatic monthly payments" parent={this} value={this.state['requireAutoPay']} />

                                                </div>

                                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                                    <div className="row">
                                                        <div className="col-4">
                                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#lease-application').modal('show')}>Back</button>
                                                        </div>
                                                        <div className="col-8 text-right">
                                                            <button type="submit" className="btn btn-primary btn-lg ml-2">Approve Application</button>
                                                        </div>
                                                    </div>

                                                </div>

                                            </React.Fragment>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/landlord/applications/:leaseApplicationId/view" type="TYPE_LANDLORD" parent={this} component={Application} leaseApplication={this.state.leaseApplication} />
                                <PrivateRoute exact path="/landlord/applications/:leaseApplicationId/screening" type="TYPE_LANDLORD" parent={this} component={Screenings} leaseApplication={this.state.leaseApplication} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);