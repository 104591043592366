import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import axios from "axios";
import $ from "jquery";
import React, { Component, Suspense } from 'react';
import { addLocaleData, IntlProvider } from "react-intl";
import locale_en from "react-intl/locale-data/en";
import locale_fr from "react-intl/locale-data/fr";
import { Route, Switch } from 'react-router-dom';
import '../../../node_modules/bootstrap/dist/js/bootstrap.bundle';
import '../../../node_modules/jquery/dist/jquery.min';
import PrivateRoute from "../../components/common/PrivateRoute";
import '../../css/custom.css';
import { localeMessages_en, localeMessages_fr } from "../../locales/rootLocale";
import * as constants from "../../util/constants";
import About from "../About";
import AdminAccount from "../admin/account/Account";
import AdminAccountPassword from "../admin/account/Password";
import AdminAccountProfile from "../admin/account/Profile";
import AdminCompanies from "../admin/companies/Companies";
import AdminCompanyInvoice from "../admin/companies/Invoice";
import AdminCompany from "../admin/companies/Navigation";
import AdminDashboard from "../admin/dashboard/Dashboard";
import AdminEntityDetails from "../admin/dashboard/EntityDetails";
import AdminProcessing from "../admin/dashboard/Processing";
import AdminRevenue from "../admin/dashboard/Revenue";
import AdminLandlordInvoice from "../admin/landlords/Invoice";
import AdminLandlords from "../admin/landlords/Landlords";
import AdminLandlord from "../admin/landlords/Navigation";
import AdminManagers from "../admin/managers/Managers";
import AdminManager from "../admin/managers/Navigation";
import AdminProperty from "../admin/properties/Navigation";
import AdminProperties from "../admin/properties/Properties";
import AdminServices from "../admin/services/Services";
import AdminAdministrators from "../admin/system/Administrators";
import AdminMerchantAccounts from "../admin/system/AdminMerchantAccounts";
import AdminApiAccounts from "../admin/system/ApiAccounts";
import AdminBanks from "../admin/system/Banks";
import AdminBinMappings from "../admin/system/BinMappings";
import AdminBroadcasts from "../admin/system/Broadcasts";
import AdminFeeProfiles from "../admin/system/DefaultFeeProfiles";
import AdminServiceFees from "../admin/system/DefaultServiceFees";
import AdminDisbursements from "../admin/system/Disbursements";
import AdminEmails from "../admin/system/Emails";
import AdminEvents from "../admin/system/Events";
import AdminFundsTransfers from "../admin/system/FundsTransfers";
import AdminHyperwalletUploads from "../admin/system/HyperwalletUploads";
import AdminImports from "../admin/system/Imports";
import AdminPaypalReports from "../admin/system/PaypalReports";
import AdminPaymentTransactionAudits from "../admin/system/PaymentTransactionAudits";
import AdminReCaptcha from "../admin/system/ReCaptcha";
import AdminRoles from "../admin/system/Roles";
import AdminStatHolidays from "../admin/system/StatHolidays";
import AdminSystem from "../admin/system/System";
import AdminSystemAccounts from "../admin/system/SystemAccounts";
import AdminTenant from "../admin/tenants/Navigation";
import AdminTenants from "../admin/tenants/Tenants";
import AdminTransactions from "../admin/transactions/Transactions";
import ApplyApplication from "../apply/Application";
import ApplyLandingDefault from "../apply/LandingDefault";
import ApplyLanding from "../apply/LandingLAP";
import ApplySubmitted from "../apply/Submitted";
import ApplySubmittedApplication from "../apply/SubmittedApplication";
import SuspenseContainer from '../common/SuspenseContainer';
import Contact from "../Contact";
import CustomerAccount from "../customer/account/Account";
import CustomerPassword from "../customer/account/Password";
import CustomerProfile from "../customer/account/Profile";
import CustomerDocuments from "../customer/documents/Documents";
import CustomerPayment from "../customer/payments/Payment";
import CustomerMethods from "../customer/payments/PaymentMethods";
import CustomerPayments from "../customer/payments/Payments";
import ForgotPassword from "../ForgotPassword";
import ForgotPasswordLAP from '../ForgotPasswordLAP';
import Home from "../Home";
import InvitationCustomer from "../invitation/Customer";
import InvitationSplash from "../invitation/Splash";
import LandlordAccount from "../landlord/account/Account";
import LandlordBanking from "../landlord/account/Banking";
import LandlordBilling from "../landlord/account/Billing";
import LandlordPassword from "../landlord/account/Password";
import LandlordProfile from "../landlord/account/Profile";
import LandlordApplications from "../landlord/applications/Applications";
import LandlordApplication from "../landlord/applications/Navigation";
import LandlordProperty from "../landlord/properties/Navigation";
import LandlordProperties from "../landlord/properties/Properties";
import LandlordSignup from "../landlord/signup/Signup";
import LandlordTenant from "../landlord/tenants/Navigation";
import LandlordTenants from "../landlord/tenants/Tenants";
import LandlordTransactions from "../landlord/transactions/Transactions";
import Landlords from "../Landlords";
import Login from "../Login";
import LoginCreditReporting from '../LoginCreditReporting';
import LoginCR from '../loginCustom/LoginCR';
import LoginCustom from "../loginCustom/LoginCustom";
import Logout from "../Logout";
import ManagerApplications from "../manager/applications/Applications";
import ManagerApplication from "../manager/applications/Navigation";
import ManagerDashboard from "../manager/dashboard/Dashboard";
import ManagerManagers from "../manager/managers/Managers";
import ManagerManager from "../manager/managers/Navigation";
import ManagerProperty from "../manager/properties/Navigation";
import ManagerProperties from "../manager/properties/Properties";
import ManagerCustomCSVReport from "../manager/reports/CustomReports";
import ManagerEftReport from "../manager/reports/Efts";
import ManagerOverdueChargesReport from "../manager/reports/OverdueCharges";
import ManagerReports from "../manager/reports/Reports";
import ManagerTenantApplicationsReport from "../manager/reports/TenantApplications";
import ManagerTenantReport from "../manager/reports/Tenants";
import ManagerTransactionReport from "../manager/reports/Transactions";
import ManagerTxt2PayReport from "../manager/reports/Txt2Pay";
import ManagerPaymentMethodAuditReport from "../manager/reports/PaymentMethodAudit";
import ManagerServices from "../manager/services/Services";
import ManagerBilling from "../manager/settings/Billing";
import ManagerCharges from "../manager/settings/ChargeCodes";
import ManagerImports from "../manager/settings/Imports";
import ManagerPassword from "../manager/settings/Password";
import ManagerProfile from "../manager/settings/Profile";
import ManagerSettings from "../manager/settings/Settings";
import ManagerTenant from "../manager/tenants/Navigation";
import ManagerTenantPayment from "../manager/tenants/Payment";
import ManagerTenants from "../manager/tenants/Tenants";
import ManagerPayment from "../manager/transactions/Payment";
import ManagerTransactions from "../manager/transactions/Transactions";
import NotFound from "../NotFound";
import HyperwalletPaymentSplash from "../pay/hyperwallet/HyperwalletPaymentSplash";
import PayLanding from "../pay/Landing";
import PayPayment from "../pay/Payment";
import PricingServiceFee from '../PricingServiceFee';
import Privacy from "../Privacy";
import ResetPassword from "../ResetPassword";
import SelfSignUpLanding from "../selfsignup/Landing";
import OnboardingAdmin from "../setup/Admin";
import OnboardingCustomer from "../setup/Customer";
import OnboardingManager from "../setup/Manager";
import OnboardingSplash from "../setup/Splash";
import Signup from "../Signup";
import Tenants from "../Tenants";
import Terms from "../Terms";
import Verify from "../Verify";
import Footer from './Footer';
import Navigation from './Navigation';
import TermsPaysafe from './TermsPaysafe';
import HPPVerificationCode from '../admin/system/HPPVerificationCode';

const TextToPayAppFC = React.lazy(() => import('../pay/textToPay/TextToPayAppFC'));
const TextToPayErrorFC = React.lazy(() => import('../pay/textToPay/common/TextToPayErrorFC'));
library.add(fab, fas, far, fal);

class App extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        // Initialize localization
        let locale = 'en'
        try {
            locale = localStorage.getItem('locale') != null ? localStorage.getItem('locale') : 'en';
        } catch(err) {
            alert('Please enable your browser cookies to use the Letus App. If this issue continues, contact our support team at help@let.us.');
        }
        this.props = props;
        this.refreshCount = 0;
        this.refreshInterval = null;
        this.refreshingToken = false;
        this.state = {
            token_type: localStorage.getItem('token_type'),
            access_token: localStorage.getItem('access_token'),
            expires_in: localStorage.getItem('expires_in'),
            status: localStorage.getItem('status'),
            localization: {
                locale: locale,
                messages: localeMessages_en
            }
        };

        // Every 5 minutes, send a request to the server to refresh the users token
        this.refreshInterval = this.setRefreshInterval();

        // Add available localizations
        addLocaleData([...locale_en, ...locale_fr]);

        this.handleChangeLocale = this.handleChangeLocale.bind(this);
        this.refreshAppState = this.refreshAppState.bind(this);
    }

    /**
     * Handle localization configuration once the page has rendered. Grabs localization from localStorage, if it
     * already exists, otherwise defaults to English.
     */
    componentDidMount() {

        let locale = localStorage.getItem('locale') != null ? localStorage.getItem('locale') : 'en';

        const langs = navigator.languages;
        for (let lang of langs){
            const myLocale = lang.substring(0,2);
            if (myLocale === "en"){
                locale = "en";
                break;
            }
            else if (myLocale === "fr"){
                locale = "fr";
                break;
            }
        }

        this.handleChangeLocale(null, locale);
    }

    /**
     * Handle any route change, this will flag the refresh script to continue polling
     */
    componentDidUpdate() {

        //Add an event listener action on when someone presses back or forward button
        //The event listener removes modal-backdrop if one exist
        $(window).on('popstate', function() {
            if($(".modal-backdrop")[0]) {
                $(".modal-backdrop").remove();
            }
            $(window).off('popstate');
        });

        // Set the refresh count back to 0 and keep looping to refresh the users token if they have made any activity
        this.refreshCount = 0;
        clearTimeout(this.refreshInterval);

        if(!this.refreshingToken) {
            this.refreshAccessToken();
        }
    }

    /**
     * Generate a request header for making API calls. Returns a JSON object containing the Content-Type, defaulted to
     * 'application/json', and Authorization parameters commonly used when making calls.
     *
     * @returns - A basic request header containing the Content-Type and Authorization params.
     */
    generateRequestHeaders() {
        return {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
        };
    }

    /**
     * Create an interval that will refresh the users auth token
     * If the interval loops 10 times it will stop itself
     *
     * @returns number
     */
    setRefreshInterval() {
        clearTimeout(this.refreshInterval);

        // If the interval has polled 10 times without resetting, we will consider the user inactive,
        // and no longer refresh the token
        if (this.refreshCount >= 10) {
            return 0;
        }

        return setTimeout(() => {
            this.refreshCount++;

            this.refreshAccessToken();
        }, 60000);
    }

    /**
     * Send a request to the server to refresh the users current token
     */
    refreshAccessToken() {
        const token = localStorage.getItem('access_token');
        let dateExp = Number(localStorage.getItem('expires_at') || 0);
        let dateNow = Date.now();

        if(token !== null) {
            // If the expiry is 5 minutes out, then we send a request to refresh the token
            if(dateExp - dateNow <= 300000 && dateExp - dateNow >= 0) {
                this.refreshingToken = true;

                axios.get(`${constants.REACT_APP_HOST_API_URL}/refreshtoken`, {
                    headers: this.generateRequestHeaders()
                })
                    .then(response => {
                        if(response) {
                            this.setRefreshInterval();
                            this.refreshingToken = false;

                            localStorage.setItem('access_token', response.data.access_token);
                            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
                        }
                    });
            } else {
                this.setRefreshInterval();
            }
        }
    }

    /**
     * Handle changing the localization of the website. Defaults to English if no other localization is provided.
     *
     * @param event - The event container.
     * @param locale - The new locale to be set.
     */
    handleChangeLocale(event, locale) {

        if(event != null) { event.preventDefault(); }

        switch(locale) {

            case 'en':
                this.setState({ localization: { locale: 'en', messages: localeMessages_en } });
                localStorage.setItem('locale', 'en');
                break;

            case 'fr':
                this.setState({ localization: { locale: 'fr', messages: localeMessages_fr } });
                localStorage.setItem('locale', 'fr');
                break;

            default:
                this.setState({ localization: { locale: 'en', messages: localeMessages_en } });
                localStorage.setItem('locale', 'en');
                break;
        }
    }

    /**
     * Trigger a mass re-rendering of the application by refreshing the state of this App component. Pulls all potential
     * values from the local storage sets it in the state.
     */
    refreshAppState() {
        this.setState({
            token_type: localStorage.getItem('token_type'),
            access_token: localStorage.getItem('access_token'),
            expires_in: localStorage.getItem('expires_in'),
            status: localStorage.getItem('status'),
            screening: localStorage.getItem('screening'),
            broadcast_messages: localStorage.getItem('broadcast_messages')
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The core application container, wrapped with a localization provider tag. Children are all other
     * routes, found in the parent index.js file.
     */
    render() {

        return(
            <IntlProvider key={this.state.localization.locale} locale={this.state.localization.locale} messages={this.state.localization.messages}>
                <div>
                    
                    <Switch>
                        <Route path="/pay/marketplace*" render={(props) => (<HyperwalletPaymentSplash {...props}/>)} />
                        <Route path="/txt2pay/:textId" render={(props) => (<Suspense fallback={<SuspenseContainer/>}><TextToPayAppFC {...props}/></Suspense>)} />
                        <Route path="/pay/error" render={(props) => (<Suspense fallback={<SuspenseContainer/>}><TextToPayErrorFC {...props}/></Suspense>)} />
                        <>
                            <Navigation token={this.state.access_token} locale={this.state.localization.locale} broadcastMessages={this.state.broadcast_messages} handleChangeLocale={this.handleChangeLocale} refreshAppState={this.refreshAppState} />
                            <Switch>
                                <Route path="/login" render={(props) => (<Login refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route path="/creditreporting" render={(props) => (<LoginCR refreshAppState={this.refreshAppState} {...props} locale={this.state.localization.locale}/>)} />
                                <Route path="/crlogin" render={(props) => (<LoginCreditReporting refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route path="/signin" render={(props) => (<LoginCustom refreshAppState={this.refreshAppState} {...props} locale={this.state.localization.locale}/>)} />
                                <Route path="/logout" render={(props) => (<Logout refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route path="/" exact render={(props) => (<Home refreshAppState={this.refreshAppState} {...props} />)}  />
                                <Route path="/about" component={About} />
                                <Route path="/signup" component={Signup} />
                                <Route path="/privacy" component={Privacy} />
                                <Route path="/terms" component={Terms} />
                                <Route path="/termspaysafe" component={TermsPaysafe} />
                                <Route path="/landlords" render={(props) => (<Landlords refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route path="/tenants" component={Tenants} />
                                <Route path="/pricing" component={PricingServiceFee} />
                                <Route path="/verify" component={Verify} />
                                <Route path="/contact" component={Contact} />
                                <Route path="/forgot-password" component={ForgotPassword} />
                                <Route path="/forgot-password-lap" component={ForgotPasswordLAP} />
                                <Route path="/reset-password" component={ResetPassword} />

                                <Route exact path="/apply" component={ApplyApplication} />
                                <Route exact path="/apply/default" component={ApplyLandingDefault} />
                                <Route exact path="/apply/submitted" component={ApplySubmitted} />
                                <Route exact path="/apply/submittedApplication" component={ApplySubmittedApplication} />
                                <Route exact path="/apply/:pageName" component={ApplyLanding} />
                                
                                <Route exact path="/selfsignup/:pageName" component={SelfSignUpLanding} />

                                <Route exact path="/pay/:pageName" component={PayLanding} />
                                <Route exact path="/pay/:pageName/payment" component={PayPayment} />

                                <Route exact path="/invitation/:invitationId" render={(props) => (<InvitationSplash refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route exact path="/invitation/:invitationId/customer" render={(props) => (<InvitationCustomer refreshAppState={this.refreshAppState} {...props} />)} />

                                <Route exact path="/onboarding/:userId" render={(props) => (<OnboardingSplash refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route exact path="/onboarding/:userId/admin" render={(props) => (<OnboardingAdmin refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route exact path="/onboarding/:userId/manager" render={(props) => (<OnboardingManager refreshAppState={this.refreshAppState} {...props} />)} />
                                <Route exact path="/onboarding/:userId/customer" render={(props) => (<OnboardingCustomer refreshAppState={this.refreshAppState} {...props} />)} />

                                <PrivateRoute exact path="/admin/dashboard" type="TYPE_ADMIN" component={AdminDashboard} />
                                <PrivateRoute exact path="/admin/dashboard/revenue" type="TYPE_ADMIN" component={AdminRevenue} />
                                <PrivateRoute exact path="/admin/dashboard/processing" type="TYPE_ADMIN" component={AdminProcessing} />
                                <PrivateRoute exact path="/admin/dashboard/entityDetails" type="TYPE_ADMIN" component={AdminEntityDetails} />
                                <PrivateRoute exact path="/admin/tenants" type="TYPE_ADMIN" component={AdminTenants} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/*" type="TYPE_ADMIN" component={AdminTenant} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/admin/companies" type="TYPE_ADMIN" component={AdminCompanies} />
                                <PrivateRoute exact path="/admin/companies/:companyId/invoice" type="TYPE_ADMIN" component={AdminCompanyInvoice} />
                                <PrivateRoute exact path="/admin/companies/:companyId/*" type="TYPE_ADMIN" component={AdminCompany} />
                                <PrivateRoute exact path="/admin/properties" type="TYPE_ADMIN" component={AdminProperties} />
                                <PrivateRoute exact path="/admin/properties/:propertyId/*" type="TYPE_ADMIN" component={AdminProperty} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/admin/managers" type="TYPE_ADMIN" component={AdminManagers} />
                                <PrivateRoute exact path="/admin/managers/:managerId/*" type="TYPE_ADMIN" component={AdminManager} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/admin/landlords" type="TYPE_ADMIN" component={AdminLandlords} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/invoice" type="TYPE_ADMIN" component={AdminLandlordInvoice} />
                                <PrivateRoute exact path="/admin/landlords/:landlordId/*" type="TYPE_ADMIN" component={AdminLandlord} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/admin/transactions" type="TYPE_ADMIN" component={AdminTransactions} />
                                <PrivateRoute exact path="/admin/services" type="TYPE_ADMIN" component={AdminServices} />
                                <PrivateRoute exact path="/admin/system" type="TYPE_ADMIN" component={AdminSystem} />
                                <PrivateRoute exact path="/admin/system/administrators" type="TYPE_ADMIN" component={AdminAdministrators} />
                                <PrivateRoute exact path="/admin/system/apis" type="TYPE_ADMIN" component={AdminApiAccounts} />
                                <PrivateRoute exact path="/admin/system/accounts" type="TYPE_ADMIN" component={AdminSystemAccounts} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/admin/system/fees" type="TYPE_ADMIN" component={AdminFeeProfiles} />
                                <PrivateRoute exact path="/admin/system/services" type="TYPE_ADMIN" component={AdminServiceFees} />
                                <PrivateRoute exact path="/admin/system/bins" type="TYPE_ADMIN" component={AdminBinMappings} />
                                <PrivateRoute exact path="/admin/system/banks" type="TYPE_ADMIN" component={AdminBanks} />
                                <PrivateRoute exact path="/admin/system/imports" type="TYPE_ADMIN" component={AdminImports} />
                                <PrivateRoute exact path="/admin/system/hwuploads" type="TYPE_ADMIN" component={AdminHyperwalletUploads} />
                                <PrivateRoute exact path="/admin/system/emails" type="TYPE_ADMIN" component={AdminEmails} />
                                <PrivateRoute exact path="/admin/system/events" type="TYPE_ADMIN" component={AdminEvents} />
                                <PrivateRoute exact path="/admin/system/broadcasts" type="TYPE_ADMIN" component={AdminBroadcasts} />
                                <PrivateRoute exact path="/admin/system/efts" type="TYPE_ADMIN" component={AdminFundsTransfers} />
                                <PrivateRoute exact path="/admin/system/disbursements" type="TYPE_ADMIN" component={AdminDisbursements} />
                                <PrivateRoute exact path="/admin/system/merchantaccounts" type="TYPE_ADMIN" component={AdminMerchantAccounts} />
                                <PrivateRoute exact path="/admin/system/roles" type="TYPE_ADMIN" component={AdminRoles} />
                                <PrivateRoute exact path="/admin/system/recToggle" type="TYPE_ADMIN" component={AdminReCaptcha} />
                                <PrivateRoute exact path="/admin/system/holidays" type="TYPE_ADMIN" component={AdminStatHolidays} />
                                <PrivateRoute exact path="/admin/system/paypal" type="TYPE_ADMIN" component={AdminPaypalReports} />
                                <PrivateRoute exact path="/admin/system/hppcode" type="TYPE_ADMIN" component={HPPVerificationCode} />
                                <PrivateRoute exact path="/admin/system/paymenttransactionaudit" type="TYPE_ADMIN" component={AdminPaymentTransactionAudits} />
                                <PrivateRoute exact path="/admin/account" type="TYPE_ADMIN" component={AdminAccount} />
                                <PrivateRoute exact path="/admin/account/profile" type="TYPE_ADMIN" component={AdminAccountProfile} />
                                <PrivateRoute exact path="/admin/account/password" type="TYPE_ADMIN" component={AdminAccountPassword} />

                                <PrivateRoute exact path="/manager/dashboard" type="TYPE_MANAGER" component={ManagerDashboard} />
                                <PrivateRoute exact path="/manager/properties" type="TYPE_MANAGER" component={ManagerProperties} />
                                <PrivateRoute exact path="/manager/properties/:propertyId/*" type="TYPE_MANAGER" component={ManagerProperty} />
                                <PrivateRoute exact path="/manager/managers" type="TYPE_MANAGER" component={ManagerManagers} />
                                <PrivateRoute exact path="/manager/managers/:managerId/*" type="TYPE_MANAGER" component={ManagerManager} />
                                <PrivateRoute exact path="/manager/tenants" type="TYPE_MANAGER" component={ManagerTenants} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/payment" type="TYPE_MANAGER" component={ManagerTenantPayment} />
                                <PrivateRoute exact path="/manager/tenants/:propertyLeaseId/*" type="TYPE_MANAGER" component={ManagerTenant} />
                                <PrivateRoute exact path="/manager/applications" type="TYPE_MANAGER" component={ManagerApplications} />
                                <PrivateRoute exact path="/manager/applications/:leaseApplicationId/*" type="TYPE_MANAGER" component={ManagerApplication} />
                                <PrivateRoute exact path="/manager/transactions" type="TYPE_MANAGER" component={ManagerTransactions} />
                                <PrivateRoute exact path="/manager/transactions/payment" type="TYPE_MANAGER" component={ManagerPayment} />
                                <PrivateRoute exact path="/manager/reports" type="TYPE_MANAGER" component={ManagerReports} />
                                <PrivateRoute exact path="/manager/reports/overdue" type="TYPE_MANAGER" component={ManagerOverdueChargesReport} />
                                <PrivateRoute exact path="/manager/reports/transactions" type="TYPE_MANAGER" component={ManagerTransactionReport} />
                                <PrivateRoute exact path="/manager/reports/efts" type="TYPE_MANAGER" component={ManagerEftReport} />
                                <PrivateRoute exact path="/manager/reports/tenantapplications" type="TYPE_MANAGER" component={ManagerTenantApplicationsReport} />
                                <PrivateRoute exact path="/manager/reports/paymentmethodaudit" type="TYPE_MANAGER" component={ManagerPaymentMethodAuditReport} />
                                <PrivateRoute exact path="/manager/reports/tenants" type="TYPE_MANAGER" component={ManagerTenantReport} />
                                <PrivateRoute exact path="/manager/reports/customreports" type="TYPE_MANAGER" component={ManagerCustomCSVReport} />
                                <PrivateRoute exact path="/manager/reports/txt2pay" type="TYPE_MANAGER" component={ManagerTxt2PayReport} />
                                <PrivateRoute exact path="/manager/services" type="TYPE_MANAGER" component={ManagerServices} />
                                <PrivateRoute exact path="/manager/settings" type="TYPE_MANAGER" component={ManagerSettings} />
                                <PrivateRoute exact path="/manager/settings/profile" type="TYPE_MANAGER" component={ManagerProfile} />
                                <PrivateRoute exact path="/manager/settings/billing" type="TYPE_MANAGER" component={ManagerBilling} />
                                <PrivateRoute exact path="/manager/settings/charges" type="TYPE_MANAGER" component={ManagerCharges} />
                                <PrivateRoute exact path="/manager/settings/imports" type="TYPE_MANAGER" component={ManagerImports} />
                                <PrivateRoute exact path="/manager/settings/password" type="TYPE_MANAGER" component={ManagerPassword} />

                                <PrivateRoute exact path="/landlord/transactions" type="TYPE_LANDLORD" component={LandlordTransactions} />
                                <PrivateRoute exact path="/landlord/properties" type="TYPE_LANDLORD" component={LandlordProperties} />
                                <PrivateRoute exact path="/landlord/properties/:propertyId/*" type="TYPE_LANDLORD" component={LandlordProperty} />
                                <PrivateRoute exact path="/landlord/tenants" type="TYPE_LANDLORD" component={LandlordTenants} />
                                <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/*" type="TYPE_LANDLORD" component={LandlordTenant} />
                                <PrivateRoute exact path="/landlord/applications" type="TYPE_LANDLORD" component={LandlordApplications} />
                                <PrivateRoute exact path="/landlord/applications/:leaseApplicationId/*" type="TYPE_LANDLORD" component={LandlordApplication} />
                                <PrivateRoute exact path="/landlord/account" type="TYPE_LANDLORD" component={LandlordAccount} />
                                <PrivateRoute exact path="/landlord/account/profile" type="TYPE_LANDLORD" component={LandlordProfile} />
                                <PrivateRoute exact path="/landlord/account/billing" type="TYPE_LANDLORD" component={LandlordBilling} />
                                <PrivateRoute exact path="/landlord/account/banking" type="TYPE_LANDLORD" component={LandlordBanking} refreshAppState={this.refreshAppState} />
                                <PrivateRoute exact path="/landlord/account/password" type="TYPE_LANDLORD" component={LandlordPassword} />
                                <PrivateRoute exact path="/landlord/signup/application" type="TYPE_LANDLORD" component={LandlordSignup} refreshAppState={this.refreshAppState} />

                                <PrivateRoute exact path="/customer/payments" type="TYPE_CUSTOMER" component={CustomerPayments} />
                                <PrivateRoute exact path="/customer/payments/payment" type="TYPE_CUSTOMER" component={CustomerPayment} />
                                <PrivateRoute exact path="/customer/payments/methods" type="TYPE_CUSTOMER" component={CustomerMethods} />
                                <PrivateRoute exact path="/customer/documents" type="TYPE_CUSTOMER" component={CustomerDocuments} />
                                <PrivateRoute exact path="/customer/account" type="TYPE_CUSTOMER" component={CustomerAccount} />
                                <PrivateRoute exact path="/customer/account/profile" type="TYPE_CUSTOMER" component={CustomerProfile} />
                                <PrivateRoute exact path="/customer/account/password" type="TYPE_CUSTOMER" component={CustomerPassword} />

                                <Route path="" component={NotFound} />

                            </Switch>
                        </>
                    </Switch>
                    <Footer pathName={window?.location?.pathname}/>
                </div>
            </IntlProvider>
        )
    }
}

export default App;
