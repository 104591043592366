import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class FieldRadio extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = event.target.checked;
        }

        if(this.props.model == null) {
            field = event.target.checked;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic radio component.
     */
    render() {

        return(
            <div className="form-group row undefined">

                {this.props.label &&
                <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                    {this.props.label} {this.props.required && <small className="text-muted font-italic">*</small>}
                </label>
                }

                <div className={`col-sm-${this.props.fieldColumns} align-self-center`}>

                    {!this.props.isPaysafeCashMerchAccnt && !this.props.isIntegrationSettingsWhenToPush && !this.props.isServiceLevelSettingsAMVDuringTenantCreation && !this.props.isServiceLevelSettingsAMVDuringTenantUpdate && !this.props.isServiceLevelSettingsSetReminder &&
                       <div className="custom-control custom-checkbox">

                            <input
                                id={this.props.id}
                                name={this.props.id}
                                type="checkbox"
                                value={this.props.value}
                                checked={this.props.value || false}
                                onChange={this.props.handleChange || this.handleChange}
                                className={`custom-control-input  ${this.props.fieldClass} ${this.props.parent.state.validation.fields[this.props.id] ? 'is-invalid' : ''}`}
                            />

                            <label className={`custom-control-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                                {this.props.fieldLabel}
                            </label>

                            {this.props.help &&
                                <small className="form-text text-muted">
                                    {this.props.help}
                                </small>
                            }
        
                            {this.props.parent.state.validation.fields[this.props.id] &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + this.props.parent.state.validation.fields[this.props.id]} />
                                </div>
                            }
                        </div>
                    }
                    

                    {this.props.isPaysafeCashMerchAccnt &&
                    <React.Fragment>

                        <div className="row ml-2" required={this.props.required}>
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                    <input
                                        id="bankAccountTypeChequings"
                                        name="Chequing"
                                        type="radio"
                                        value="CHEQUING"
                                        checked={this.props.value === "CHEQUING"}
                                        onChange={(event)=>this.props.handleChange(event)}
                                        className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label" htmlFor="bankAccountTypeChequings">
                                    Chequing
                                </label>
                            </div>
                            
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                    <input
                                        id="bankAccountTypeSavings"
                                        name="Savings"
                                        type="radio"
                                        value="SAVINGS"
                                        checked={this.props.value === "SAVINGS"}
                                        onChange={(event)=>this.props.handleChange(event)}
                                        className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                    <label className="custom-control-label" htmlFor="bankAccountTypeSavings">
                                        Savings
                                    </label>
                            </div>
                            {this.props.widthAlign &&
                                <style>{`
                                    .RadioInputWidthAlign{
                                        flex: 0 0 ${this.props.widthAlign};
                                    }
                                `}</style>
                            }
                        </div>
                    </React.Fragment>
                    }

                    {this.props.isIntegrationSettingsWhenToPush &&
                    <React.Fragment>

                        <div className="row ml-2" required={this.props.required}>
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="integrationPushWhenRealtime"
                                    name="Push in Real Time"
                                    type="radio"
                                    value="REALTIME"
                                    checked={this.props.value === "REALTIME"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label" htmlFor="integrationPushWhenRealtime">
                                    Push in Real Time
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="integrationPushWhenSettled"
                                    name="Push when Settled"
                                    type="radio"
                                    value="SETTLED"
                                    checked={this.props.value === "SETTLED"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label" htmlFor="integrationPushWhenSettled">
                                    Push when Settled
                                </label>
                            </div>
                            {this.props.widthAlign &&
                            <style>{`
                                    .RadioInputWidthAlign{
                                        flex: 0 0 ${this.props.widthAlign};
                                    }
                                `}</style>
                            }
                        </div>
                    </React.Fragment>
                    }
                    {this.props.isServiceLevelSettingsAMVDuringTenantCreation &&
                    <React.Fragment>

                        <div className="row ml-2" required={this.props.required}>
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTCOff"
                                    name="CreationOff"
                                    type="radio"
                                    value="OFF"
                                    checked={this.props.value === "OFF"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTCOff">
                                    Off
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTCMarkAsValid"
                                    name="CreationMarkasValid"
                                    type="radio"
                                    value="VALID"
                                    checked={this.props.value === "VALID"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTCMarkAsValid">
                                    Mark as Valid
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTCValidateThroughAmazon"
                                    name="CreationValidateThroughAmazon"
                                    type="radio"
                                    value="AMAZON"
                                    checked={this.props.value === "AMAZON"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTCValidateThroughAmazon">
                                    Validate through Amazon <small>(costs $0.006 each time)</small>
                                </label>
                            </div>
                            {this.props.widthAlign &&
                            <style>{`
                                    .RadioInputWidthAlign{
                                        flex: 0 0 ${this.props.widthAlign};
                                    }
                                    .amv-label{
                                        font-size: 0.875rem;
                                        line-height: 1.8
                                    }
                                `}</style>
                            }
                        </div>
                    </React.Fragment>
                    }

                    {this.props.isServiceLevelSettingsAMVDuringTenantUpdate &&
                    <React.Fragment>

                        <div className="row ml-2" required={this.props.required}>
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTUOff"
                                    name="UpdateOff"
                                    type="radio"
                                    value="OFF"
                                    checked={this.props.value === "OFF"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTUOff">
                                    Off
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTUMarkAsValid"
                                    name="UpdateMarkasValid"
                                    type="radio"
                                    value="VALID"
                                    checked={this.props.value === "VALID"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTUMarkAsValid">
                                    Mark as Valid
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigAMVDTUValidateThroughAmazon"
                                    name="UpdateValidateThroughAmazon"
                                    type="radio"
                                    value="AMAZON"
                                    checked={this.props.value === "AMAZON"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigAMVDTUValidateThroughAmazon">
                                    Validate through Amazon <small>(costs $0.006 each time)</small>
                                </label>
                            </div>
                            {this.props.widthAlign &&
                            <style>{`
                                    .RadioInputWidthAlign{
                                        flex: 0 0 ${this.props.widthAlign};
                                    }
                                `}</style>
                            }
                        </div>
                    </React.Fragment>
                    }

                    {this.props.isServiceLevelSettingsSetReminder &&
                    <React.Fragment>

                        <div className="row ml-2" required={this.props.required}>
                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigDueDay"
                                    name="UpdateDueDay"
                                    type="radio"
                                    value="PAYMENT_DUE_DAY"
                                    checked={this.props.value === "PAYMENT_DUE_DAY"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>
                                    
                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigDueDay">
                                    Due Day
                                </label>
                            </div>

                            <div className={`col-sm-${this.props.fieldColumns} ${this.props.widthAlign? 'RadioInputWidthAlign' : ''}`}>
                                <input
                                    id="serviceLevelConfigSpecificDay"
                                    name="UpdateSpecificDay"
                                    type="radio"
                                    value="SPECIFIC_DAY"
                                    checked={this.props.value === "SPECIFIC_DAY"}
                                    onChange={(event)=>this.props.handleChange(event)}
                                    className={`custom-control-input custom-radio col-sm-${this.props.fieldColumns} ${this.props.fieldClass}`}/>

                                <label className="custom-control-label amv-label" htmlFor="serviceLevelConfigSpecificDay">
                                    Specific Day
                                </label>
                            </div>

                            {this.props.widthAlign &&
                            <style>{`
                                    .RadioInputWidthAlign{
                                        flex: 0 0 ${this.props.widthAlign};
                                    }
                                `}</style>
                            }
                        </div>
                    </React.Fragment>
                    }

                </div>
                {this.props.help && this.props.isServiceLevelSettingsAMVDuringTenantCreation &&
                    <small className="form-text text-muted pl-3">
                        {this.props.help}
                    </small>
                }
            </div>
        )
    };
}

FieldRadio.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
};

export default FieldRadio;
