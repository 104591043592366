import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import * as moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { TYPE_COMMON } from '../../../constants/CommonConstants';
import {
  NOTIFICATION_PROVIDERS,
  PayByTextAdvanceReminderDays,
  PayByTextReminderDays,
  ReminderSentOnDayOfMonth,
} from '../../../constants/ServiceLevelConfigConstants';
import * as constants from '../../../util/constants';
import Alert from '../../common/Alert';
import ButtonClose from '../../common/ButtonClose';
import FieldCheckbox from '../../common/FieldCheckbox';
import FieldFile from '../../common/FieldFile';
import FieldPhone from '../../common/FieldPhone';
import FieldRadio from '../../common/FieldRadio';
import FieldSelect from '../../common/FieldSelect';
import FieldText from '../../common/FieldText';
import Propertii from '../../common/Propertii';
import ModalsMessageGuide from '../../common/ModalsMessageGuide';
import Spinner from '../../common/Spinner';
class ServiceLevelConfigPayByText extends Propertii {
  /**
   * Initialize the component.
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      syncFor: null,
      spinnerSmall: false,
      payByTextDetails: {},
      companySync: {
        isEnabled: true,
        textPhoneNumber: null,
      },
      tenantSync: {
        isEnabled: true,
      },
      payByText: {
        isEnabled: true,
        enablePayByText: false,
        sendMessageOnPaymentDueDay: true,
        reminderReference: 'PAYMENT_DUE_DAY',
        advancePaymentReminderDay: null,
        firstOverduePaymentReminderDay: null,
        secondOverduePaymentReminderDay: null,
      },
      emailOnComplete: {
        email: null,
        ignoreRecordsWithSyncErrors: false,
      },
      scopeLabel: '',
      txtToPayBusinessName: null,
      txtToPayBusinessNameOnLoad: null,
      validationList: [],
      settings: {
        AMVDuringTenantCreation: 'OFF',
        AMVDuringTenantUpdate: 'OFF',
      },
      txt2payByLetus: {
        phoneNumber: null,
        paymentLink: {
          logoUrl: null,
          logoId: null,
          recordId: null,
        },
        customLogo: null,
      },
      txt2payByLetusTemplates: {
        welcomeTemplate: '',
        purchaseConfirmationTemplate: '',
        invoiceTemplate: '',
        invoiceWithTokenTemplate: '',
        advanceReminderTemplate: '',
        advanceReminderWithTokenTemplate: '',
        overdueReminderTemplate: '',
        overdueReminderWithTokenTemplate: '',
        advanceReminderWithZeroAmountTemplate: '',
        overdueReminderWithZeroAmountTemplate: '',
        invoiceWithZeroAmountTemplate: '',
      },
      currentMessageGuideModalId: null,
    };

    this.handleSync = this.handleSync.bind(this);
    this.getSyncConfigs = this.getSyncConfigs.bind(this);
    this.togglePayByTextService = this.togglePayByTextService.bind(this);
    this.synchronizeProcess = this.synchronizeProcess.bind(this);
    this.getSyncStatus = this.getSyncStatus.bind(this);
    this.setScopeLabel = this.setScopeLabel.bind(this);
    this.setTxtToPayBusinessNameOnLoad = this.setTxtToPayBusinessNameOnLoad.bind(this);
    this.updateTxtToPayBusinessName = this.updateTxtToPayBusinessName.bind(this);
    this.openDefaultMessageGuideModal = this.openDefaultMessageGuideModal.bind(this);
  }

  /**
   * Retrieve sync settings
   */
  componentDidMount() {
    if (this.props.notificationProvider === NOTIFICATION_PROVIDERS.EVERYWARE) {
      this.getSyncConfigs();
      this.setScopeLabel();
      this.setTxtToPayBusinessNameOnLoad();
    } else if (this.props.companyId || this.props.propertyId) {
      this.getTxt2PayConfigsForLetus();
    }
  }

  /**
   * In the case some of the props might get updated later
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.propertyId !== this.props.propertyId ||
      prevProps.notificationProvider !== this.props.notificationProvider
    ) {
      if (this.props.notificationProvider === NOTIFICATION_PROVIDERS.EVERYWARE) {
        this.getSyncConfigs();
        this.setScopeLabel();
      } else {
        this.getTxt2PayConfigsForLetus();
      }
    }

    if (
      prevProps.txtToPayBusinessName !== this.props.txtToPayBusinessName ||
      prevProps.propertyName !== this.props.propertyName
    ) {
      if (this.props.notificationProvider === NOTIFICATION_PROVIDERS.EVERYWARE) {
        this.setTxtToPayBusinessNameOnLoad();
      }
    }
  }

  /**
   * Set the correct labels based on where we are in the UI.
   */
  setScopeLabel() {
    let scopeLabel = '';

    if (this.props.propertyId) {
      scopeLabel = 'Property';
    } else if (this.props.parentCompanyId) {
      scopeLabel = 'Sub-Company';
    } else if (this.props.companyId) {
      scopeLabel = 'Company and Sub-Company';
    }

    this.setState((prevState) => ({
      ...prevState,
      scopeLabel: scopeLabel,
    }));
  }

  /**
   * Set TxtToPayBusinessName
   */
  setTxtToPayBusinessNameOnLoad() {
    const txtToPayBusinessName = this.props.txtToPayBusinessName || this.props.propertyName;
    const txtToPayBusinessNameOnLoad = this.props.txtToPayBusinessName;
    this.setState((prevState) => ({
      ...prevState,
      txtToPayBusinessName: txtToPayBusinessName,
      txtToPayBusinessNameOnLoad: txtToPayBusinessNameOnLoad,
    }));
  }

  /**
   * Get Txt2Pay service level configuration settings
   */
  getSyncConfigs() {
    this.setState((prevState) => ({
      ...prevState,
      spinnerSmall: true,
    }));

    let id = null;
    let syncFor = null;

    const headers = {
      headers: this.generateRequestHeaders(),
    };

    if (this.props.propertyId) {
      id = this.props.propertyId;
      syncFor = TYPE_COMMON.TYPE_PROPERTY;
    } else if (this.props.companyId) {
      id = this.props.companyId;
      syncFor = TYPE_COMMON.TYPE_COMPANY;
    } else {
      return;
    }

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/${syncFor}/${id}/pay-by-text/info`, headers)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinnerSmall: false,
          payByTextDetails: {
            ...response.data,
          },
          companySync: {
            ...prevState.companySync,
            textPhoneNumber: response.data?.textPhoneNumber,
          },
          payByText: {
            ...prevState.payByText,
            enablePayByText: response.data?.serviceEnabled,
            sendMessageOnPaymentDueDay: response.data?.sendMessageOnPaymentDueDay,
            reminderReference: response.data?.reminderReference || 'PAYMENT_DUE_DAY',
            advancePaymentReminderDay: response.data?.advancePaymentReminderDay,
            firstOverduePaymentReminderDay: response.data?.firstOverduePaymentReminderDay,
            secondOverduePaymentReminderDay: response.data?.secondOverduePaymentReminderDay,
          },
          settings: {
            ...prevState.settings,
            AMVDuringTenantUpdate:
              response.data?.automaticValidationOnUpdate === null
                ? 'OFF'
                : response.data?.automaticValidationOnUpdate,
            AMVDuringTenantCreation:
              response.data?.automaticValidationOnCreate === null
                ? 'OFF'
                : response.data?.automaticValidationOnCreate,
          },
        }));
      })
      .catch((error) => {
        this.setState((prevState) => ({
          ...prevState,
          spinnerSmall: false,
        }));
        if (!error.response.data?.message?.includes('service is not configured')) {
          this.handleValidation(error);
          this.handleClearValidationList();
        }
      });
  }

  /**
   * Get configs for Txt2Pay service level configuration settings when the notification provider is Letus
   */
  getTxt2PayConfigsForLetus() {
    this.setState((prevState) => ({
      ...prevState,
      spinnerSmall: true,
    }));

    let url = `company/${this.props.companyId}`;
    if (this.props.propertyId) {
      url = `company/${this.props.companyId}/property/${this.props.propertyId}`;
    }

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/${url}/notifications/config`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinnerSmall: false,
          txt2payByLetus: {
            phoneNumber: response.data?.phoneNumber,
            paymentLink: {
              logoUrl: response.data?.paymentLink?.logoUrl,
              logoId: response.data?.paymentLink?.logoId,
              recordId: response.data?.paymentLink?.recordId,
            },
          },
          txt2payByLetusTemplates: {
            welcomeTemplate: response.data?.templates?.find(
              (template) => template.name === 'WELCOME_MESSAGE'
            )?.content,
            purchaseConfirmationTemplate: response.data?.templates?.find(
              (template) => template.name === 'PURCHASE_CONFIRMATION'
            )?.content,
            invoiceWithTokenTemplate: response.data?.templates?.find(
              (template) => template.name === 'INVOICE_WITH_TOKEN_FEES'
            )?.content,
            invoiceTemplate: response.data?.templates?.find(
              (template) => template.name === 'INVOICE'
            )?.content,
            advanceReminderTemplate: response.data?.templates?.find(
              (template) => template.name === 'ADVANCE_REMINDER_INVOICE'
            )?.content,
            advanceReminderWithTokenTemplate: response.data?.templates?.find(
              (template) => template.name === 'ADVANCE_REMINDER_INVOICE_WITH_TOKEN_FEES'
            )?.content,
            overdueReminderTemplate: response.data?.templates?.find(
              (template) => template.name === 'OVERDUE_REMINDER_INVOICE'
            )?.content,
            overdueReminderWithTokenTemplate: response.data?.templates?.find(
              (template) => template.name === 'OVERDUE_REMINDER_INVOICE_WITH_TOKEN_FEES'
            )?.content,
            advanceReminderWithZeroAmountTemplate: response.data?.templates?.find(
              (template) => template.name === 'ADVANCE_REMINDER_INVOICE_WITH_ZERO_AMOUNT'
            )?.content,
            overdueReminderWithZeroAmountTemplate: response.data?.templates?.find(
              (template) => template.name === 'OVERDUE_REMINDER_INVOICE_WITH_ZERO_AMOUNT'
            )?.content,
            invoiceWithZeroAmountTemplate: response.data?.templates?.find(
              (template) => template.name === 'INVOICE_WITH_ZERO_AMOUNT'
            )?.content,
          },
          payByTextDetails: {
            ...response.data,
          },
          payByText: {
            ...prevState.payByText,
            enablePayByText: response.data?.serviceEnabled,
            sendMessageOnPaymentDueDay: response.data?.sendMessageOnPaymentDueDay,
            reminderReference: response.data?.reminderReference || 'PAYMENT_DUE_DAY',
            advancePaymentReminderDay: response.data?.advancePaymentReminderDay,
            firstOverduePaymentReminderDay: response.data?.firstOverduePaymentReminderDay,
            secondOverduePaymentReminderDay: response.data?.secondOverduePaymentReminderDay,
          },
          settings: {
            ...prevState.settings,
            AMVDuringTenantUpdate:
              response.data?.automaticValidationOnUpdate === null
                ? 'OFF'
                : response.data?.automaticValidationOnUpdate,
            AMVDuringTenantCreation:
              response.data?.automaticValidationOnCreate === null
                ? 'OFF'
                : response.data?.automaticValidationOnCreate,
          },
        }));
      })
      .catch((error) => {
        this.setState((prevState) => ({
          ...prevState,
          spinnerSmall: false,
        }));
        this.handleValidation(error);
        this.handleClearValidationList();
      });
  }

  /**
   * Handles on/off for txt2pay service when the provider is Letus
   * ! We should look into patch for updating
   */
  togglePayByTextServiceForLetus() {
    this.startSpinner();

    const notificationConfiguration = {};

    notificationConfiguration.serviceEnabled = this.state.payByText.enablePayByText ? true : false;
    notificationConfiguration.phoneNumber = this.state.txt2payByLetus.phoneNumber;

    //Validations:
    notificationConfiguration.automaticValidationOnCreate =
      this.state.settings?.AMVDuringTenantCreation || 'OFF';
    notificationConfiguration.automaticValidationOnUpdate =
      this.state.settings?.AMVDuringTenantUpdate || 'OFF';

    //Reminders
    notificationConfiguration.sendMessageOnPaymentDueDay =
      this.state.payByText?.sendMessageOnPaymentDueDay;
    notificationConfiguration.reminderReference = this.state.payByText?.reminderReference;
    notificationConfiguration.advancePaymentReminderDay =
      this.state.payByText?.advancePaymentReminderDay;
    notificationConfiguration.firstOverduePaymentReminderDay =
      this.state.payByText?.firstOverduePaymentReminderDay;
    notificationConfiguration.secondOverduePaymentReminderDay =
      this.state.payByText?.secondOverduePaymentReminderDay;

    // templates
    notificationConfiguration.templates = [
      {
        name: 'WELCOME_MESSAGE',
        content: this.state.txt2payByLetusTemplates?.welcomeTemplate,
      },
      {
        name: 'PURCHASE_CONFIRMATION',
        content: this.state.txt2payByLetusTemplates?.purchaseConfirmationTemplate,
      },
      {
        name: 'INVOICE_WITH_TOKEN_FEES',
        content: this.state.txt2payByLetusTemplates?.invoiceWithTokenTemplate,
      },
      {
        name: 'INVOICE',
        content: this.state.txt2payByLetusTemplates?.invoiceTemplate,
      },
      {
        name: 'ADVANCE_REMINDER_INVOICE',
        content: this.state.txt2payByLetusTemplates?.advanceReminderTemplate,
      },
      {
        name: 'ADVANCE_REMINDER_INVOICE_WITH_TOKEN_FEES',
        content: this.state.txt2payByLetusTemplates?.advanceReminderWithTokenTemplate,
      },
      {
        name: 'OVERDUE_REMINDER_INVOICE',
        content: this.state.txt2payByLetusTemplates?.overdueReminderTemplate,
      },
      {
        name: 'OVERDUE_REMINDER_INVOICE_WITH_TOKEN_FEES',
        content: this.state.txt2payByLetusTemplates?.overdueReminderWithTokenTemplate,
      },
      {
        name: 'ADVANCE_REMINDER_INVOICE_WITH_ZERO_AMOUNT',
        content: this.state.txt2payByLetusTemplates?.advanceReminderWithZeroAmountTemplate,
      },
      {
        name: 'OVERDUE_REMINDER_INVOICE_WITH_ZERO_AMOUNT',
        content: this.state.txt2payByLetusTemplates?.overdueReminderWithZeroAmountTemplate,
      },
      {
        name: 'INVOICE_WITH_ZERO_AMOUNT',
        content: this.state.txt2payByLetusTemplates?.invoiceWithZeroAmountTemplate,
      },
    ];

    const formData = new FormData();
    formData.append('notificationConfiguration', JSON.stringify(notificationConfiguration));

    // Check if a custom logo has been selected
    if (this.state.txt2payByLetus.customLogo) {
      formData.append('file', this.state.txt2payByLetus.customLogo.get('file'));
    }

    let url = `company/${this.props.companyId}`;
    if (this.props.propertyId) {
      url = `company/${this.props.companyId}/property/${this.props.propertyId}`;
    }

    const headers = this.generateRequestHeaders();
    headers['Content-Type'] = 'multipart/form-data';

    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/${url}/notifications/config`, formData, {
        headers: headers,
      })
      .then(() => {
        window.scrollTo(0, 200);
        const response = {
          data: {
            alertCode: 'saved',
          },
        };
        this.stopSpinner();
        this.handleSuccessAlert(response);
        this.handleClearValidationList();
        this.getTxt2PayConfigsForLetus();
      })
      .catch((error) => {
        this.handleValidation(error);
        this.handleClearValidationList();
        window.scrollTo(0, 200);
      });
  }

  /**
   * Allows CS Admin to enable or disable Txt2Pay
   */
  togglePayByTextService(event) {
    event.preventDefault();

    if (this.props.notificationProvider === NOTIFICATION_PROVIDERS.LETUS) {
      this.togglePayByTextServiceForLetus();
    } else {
      const params = {
        welcomeText: null,
      };
      let id = null;
      let enableFor = null;

      if (this.props.propertyId) {
        id = this.props.propertyId;
        enableFor = TYPE_COMMON.TYPE_PROPERTY;
      } else if (this.props.companyId) {
        id = this.props.companyId;
        enableFor = TYPE_COMMON.TYPE_COMPANY;
      }

      if (this.state.payByText.enablePayByText) {
        params.serviceEnabled = true;
      } else {
        params.serviceEnabled = false;
      }

      if (this.state.settings?.AMVDuringTenantCreation) {
        params.automaticValidationOnCreate = this.state.settings?.AMVDuringTenantCreation;
      } else {
        params.automaticValidationOnCreate = 'OFF';
      }

      if (this.state.settings?.AMVDuringTenantUpdate) {
        params.automaticValidationOnUpdate = this.state.settings?.AMVDuringTenantUpdate;
      } else {
        params.automaticValidationOnUpdate = 'OFF';
      }

      if (this.state.payByText.sendMessageOnPaymentDueDay !== null) {
        params.sendMessageOnPaymentDueDay = this.state.payByText.sendMessageOnPaymentDueDay;
      }

      if (this.state.payByText.reminderReference !== null) {
        params.reminderReference = this.state.payByText.reminderReference;
      }

      if (this.state.payByText.advancePaymentReminderDay !== null) {
        params.advancePaymentReminderDay = this.state.payByText.advancePaymentReminderDay;
      }

      if (this.state.payByText.firstOverduePaymentReminderDay !== null) {
        params.firstOverduePaymentReminderDay = this.state.payByText.firstOverduePaymentReminderDay;
      }

      if (this.state.payByText.secondOverduePaymentReminderDay !== null) {
        params.secondOverduePaymentReminderDay =
          this.state.payByText.secondOverduePaymentReminderDay;
      }

      if (this.state.payByTextDetails.welcomeText) {
        params.welcomeText = this.state.payByTextDetails.welcomeText;
      }

      // update txtToPayBusinessName
      if (
        this.props.propertyId &&
        this.state.txtToPayBusinessName !== this.state.txtToPayBusinessNameOnLoad
      ) {
        this.updateTxtToPayBusinessName();
      }

      const headers = {
        headers: this.generateRequestHeaders(),
      };

      axios
        .post(
          `${constants.REACT_APP_HOST_API_URL}/${enableFor}/${id}/pay-by-text/update-status`,
          params,
          headers
        )
        .then((response) => {
          if (response.data && response.data.result === 'SUCCESS') {
            response.data.alertCode = 'saved';
            this.handleSuccessAlert(response);
            this.handleClearValidationList();
          }
        })
        .catch((error) => {
          this.handleValidation(error);
          this.handleClearValidationList();
          window.scrollTo(0, 200);
        });
    }
  }

  /**
   * Handle sync of company, sub-company, or tenants with Everyware.
   * Also allows for a resync.
   */
  synchronizeProcess(event) {
    event.preventDefault();

    $('#email-oncomplete').modal('hide');

    const params = {};
    let syncFor = null;
    let endPoint = 'synchronize';

    if (this.props.propertyId) {
      params.propertyId = this.props.propertyId;
      syncFor = TYPE_COMMON.TYPE_PROPERTY;
    } else if (this.props.companyId) {
      params.companyId = this.props.companyId;
      syncFor = TYPE_COMMON.TYPE_COMPANY;
    }

    if (this.state.syncFor === TYPE_COMMON.TYPE_TENANT) {
      syncFor += `/${TYPE_COMMON.TYPE_TENANT}`;

      if (this.state.payByTextDetails?.tenantSyncStatus) {
        endPoint = 'resynchronization';
      }
    }

    if (this.state.syncFor === TYPE_COMMON.TYPE_COMPANY) {
      let phoneNumber = this.state.companySync.textPhoneNumber;
      if (phoneNumber?.includes('+')) {
        phoneNumber = phoneNumber.split('+')[1];
      }
      params.phoneNumber = phoneNumber;

      if (this.state.payByTextDetails?.syncStatus) {
        endPoint = 'resynchronization';
      }
    }

    if (this.state.emailOnComplete.email) {
      params.emailAddress = this.state.emailOnComplete.email;
    }

    if (this.state.emailOnComplete.ignoreRecordsWithSyncErrors) {
      params.ignoreSubSynchronizationProcess = true;
    }

    const headers = {
      headers: this.generateRequestHeaders(),
    };

    // update txtToPayBusinessName
    if (
      this.props.propertyId &&
      this.state.txtToPayBusinessName !== this.state.txtToPayBusinessNameOnLoad
    ) {
      this.updateTxtToPayBusinessName();
    }

    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/${syncFor}/${endPoint}`, params, headers)
      .then((response) => {
        if (response.data && response.data.result === 'SUCCESS') {
          response.data.alertCode = 'sync.started';
          this.handleSuccessAlert(response);
          this.handleClearValidationList();

          // get latest status
          this.getSyncConfigs();
        }
      })
      .catch((error) => {
        this.handleValidation(error);
        this.handleClearValidationList();
      });
  }

  /**
   * handle the sync on button click
   * @param {*} type - company or tenant
   */
  handleSync(event, type) {
    event.preventDefault();
    this.setState(
      (prevState) => ({
        ...prevState,
        syncFor: type,
      }),
      () => $('#email-oncomplete').modal('show')
    );
  }

  /**
   * returns the latest sync status
   * @param type - sync status for company or tenant
   */
  getSyncStatus(type) {
    let date = null;
    let status = null;

    if (
      type === TYPE_COMMON.TYPE_COMPANY &&
      this.state.payByTextDetails?.syncStartTime &&
      this.state.payByTextDetails?.syncStatus
    ) {
      date = moment(this.state.payByTextDetails.syncStartTime).format('YYYY-MM-DD');
      status = this.state.payByTextDetails.syncStatus;
    } else if (
      type === TYPE_COMMON.TYPE_TENANT &&
      this.state.payByTextDetails?.tenantSyncStartTime &&
      this.state.payByTextDetails?.tenantSyncStatus
    ) {
      date = moment(this.state.payByTextDetails.tenantSyncStartTime).format('YYYY-MM-DD');
      status = this.state.payByTextDetails.tenantSyncStatus;
    }

    return `${date}. Status - ${status}`;
  }

  /**
   * Update txtToPayBusinessName
   */
  updateTxtToPayBusinessName() {
    const property = {
      ...this.props.property,
      txtToPayBusinessName: this.state.txtToPayBusinessName,
    };

    axios
      .put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
        headers: this.generateRequestHeaders(),
      })
      .then(() => {
        this.props.refreshProperty(this.props.propertyId);
      })
      .catch((error) => {
        this.setTxtToPayBusinessNameOnLoad(); // revert to original txtToPayBusiness name
        this.handleValidation(error);
      });
  }

  /**
   * Handle change for the AMVDuringTenantCreation component
   * @param {*} event - Synthetic event
   */
  handleChangeAMVDuringTenantCreationField(event) {
    event.persist();

    let targetValue = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        AMVDuringTenantCreation: targetValue,
      },
    }));
  }

  /**
   * Handle change for the AMVDuringTenantUpdate component
   * @param {*} event - Synthetic event
   */
  handleChangeAMVDuringTenantUpdateField(event) {
    event.persist();

    let targetValue = event.target.value;

    this.setState((prevState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        AMVDuringTenantUpdate: targetValue,
      },
    }));
  }

  /**
   * Handle change for the SetReminderUpdate component
   * @param {*} event - Synthetic event
   */
  handleChangeSetReminderUpdateField(event) {
    event.persist();
    let targetValue = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      payByText: {
        ...prevState.payByText,
        reminderReference: targetValue,
        advancePaymentReminderDay: null,
        firstOverduePaymentReminderDay: null,
        secondOverduePaymentReminderDay: null,
      },
    }));
  }

  openDefaultMessageGuideModal(modalId) {
    this.setState((prevState) => ({
      ...prevState,
      currentMessageGuideModalId: modalId,
    }));
  }

  /**
   * Handle deletion of the custom logo
   */
  handleCustomLogoDelete() {
    axios
      .delete(
        `${constants.REACT_APP_HOST_API_URL}/document/${this.state.txt2payByLetus.paymentLink.logoId}/delete`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then(() => {
        const response = {
          data: {
            alertCode: 'common.documents.deleted',
          },
        };
        this.handleSuccessAlert(response);
        this.handleClearValidationList();
        this.getTxt2PayConfigsForLetus();
        window.scrollTo(0, 200);
      })
      .catch((error) => {
        this.handleValidation(error);
        this.handleClearValidationList();
        window.scrollTo(0, 200);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The admin ServiceLevelConfig component to handle the sync process with Everyware
   */
  render() {
    return (
      <React.Fragment>
        <Spinner visible={this.state.spinner} />
        <Alert validationList={this.state.validationList} validationType='primary' />
        <Alert validationList={this.state.validationList} validationType='danger' />
        <ModalsMessageGuide currentMessageGuideModalId={this.state.currentMessageGuideModalId} />
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Txt2Pay</div>
              {/* <div
                className="btn btn-outline-primary btn-sm"
                onClick={() => this.getSyncConfigs()}
              >
                <FontAwesomeIcon icon={["fas", "redo"]} className="fa-fw" />{" "}
                Refresh
                {this.state.spinnerSmall && (
                  <span
                    className="spinner-border spinner-border-sm ml-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </div> */}
            </div>
          </div>
          {this.props.notificationProvider === NOTIFICATION_PROVIDERS.EVERYWARE && (
            <div className='col mt-3'>
              <p className=''>To enable Txt2Pay, please start syncing process.</p>
              <form onSubmit={(event) => this.handleSync(event, TYPE_COMMON.TYPE_COMPANY)}>
                <div className='row mt-4'>
                  <div className='col-lg-12'>
                    <FieldPhone
                      id='textPhoneNumber'
                      label='Phone'
                      model='companySync'
                      parent={this}
                      labelColumns='3'
                      fieldColumns='9'
                      size='sm'
                      help='Phone number that calls and messages are forwarded to.'
                      labelClass='col-form-label'
                      containerClass='mb-1'
                      value={this.state.companySync.textPhoneNumber}
                      required={true}
                    />
                  </div>
                </div>
                {this.props.propertyId && (
                  <div className='row mt-4'>
                    <div className='col-lg-12'>
                      <FieldText
                        id='txtToPayBusinessName'
                        type='text'
                        label='Txt2Pay Business Name'
                        model=''
                        parent={this}
                        labelColumns='3'
                        fieldColumns='9'
                        size='sm'
                        value={this.state.txtToPayBusinessName}
                        required={true}
                      />
                    </div>
                  </div>
                )}
                {this.state.payByTextDetails?.salessiteid && (
                  <div className='row mt-4 col-form-label-sm'>
                    <div className='col-lg-3'>Txt2Pay ID:</div>
                    <div className='col-lg-9'>{this.state.payByTextDetails.salessiteid}</div>
                  </div>
                )}
                <div className='row mt-4'>
                  <div className='col-lg-10'>
                    <div className=''>{this.state.scopeLabel} Sync with Everyware.</div>
                    <div className='text-muted small'>
                      {this.state.scopeLabel} sync must be performed before Tenant sync can take
                      place.
                    </div>
                    {this.state.payByTextDetails?.syncStartTime &&
                      this.state.payByTextDetails?.syncStatus && (
                        <div className='text-warning small'>
                          <Link to={`syncs`}>
                            Last Sync performed on {this.getSyncStatus(TYPE_COMMON.TYPE_COMPANY)}
                          </Link>
                        </div>
                      )}
                  </div>
                  <div className='col-lg-2'>
                    <button
                      type='submit'
                      className='btn btn-primary btn-lg float-right'
                      disabled={!this.state.companySync.isEnabled}
                    >
                      Sync
                    </button>
                  </div>
                </div>
              </form>
              <div className='row mt-4'>
                <div className='col-lg-10'>
                  <div className=''>Tenants from {this.state.scopeLabel} Sync with Everyware.</div>
                  <div className='text-muted small'>
                    Tenant sync must be performed before Txt2Pay service can be enabled.
                  </div>
                  {this.state.payByTextDetails?.tenantSyncStartTime &&
                    this.state.payByTextDetails?.tenantSyncStatus && (
                      <div className='small'>
                        <Link to={`syncs`}>
                          Last Sync performed on {this.getSyncStatus(TYPE_COMMON.TYPE_TENANT)}
                        </Link>
                      </div>
                    )}
                </div>
                <div className='col-lg-2'>
                  <button
                    type='button'
                    className='btn btn-primary btn-lg float-right'
                    onClick={(event) => this.handleSync(event, TYPE_COMMON.TYPE_TENANT)}
                    disabled={!this.state.tenantSync.isEnabled}
                  >
                    Sync
                  </button>
                </div>
              </div>
              <hr />
            </div>
          )}

          <form onSubmit={this.togglePayByTextService}>
            <div className='col mt-3'>
              <FieldRadio
                id='AMVDuringTenantCreation'
                label='Automatic Mobile Validation During Tenant Creation'
                fieldColumns='9'
                labelColumns='3'
                widthAlign='33%'
                parent={this}
                isServiceLevelSettingsAMVDuringTenantCreation
                value={this.state.settings['AMVDuringTenantCreation']}
                handleChange={(event) => this.handleChangeAMVDuringTenantCreationField(event)}
                help='Welcome Text Message checkbox will be visible in Create Tenant Modal only when above setting is not Off.'
              />

              <FieldRadio
                id='AMVDuringTenantUpdate'
                label='Automatic Mobile Validation During Tenant Update'
                fieldColumns='9'
                labelColumns='3'
                widthAlign='33%'
                parent={this}
                isServiceLevelSettingsAMVDuringTenantUpdate
                value={this.state.settings['AMVDuringTenantUpdate']}
                handleChange={(event) => this.handleChangeAMVDuringTenantUpdateField(event)}
              />

              <hr />

              <FieldRadio
                id='reminderReference'
                label='Set Reminder By:'
                fieldColumns='9'
                labelColumns='3'
                widthAlign='33%'
                parent={this}
                isServiceLevelSettingsSetReminder
                value={this.state.payByText['reminderReference']}
                handleChange={(event) => this.handleChangeSetReminderUpdateField(event)}
              />

              <div className='font-weight-bold'>Advance Reminder</div>

              <FieldSelect
                id='advancePaymentReminderDay'
                label={
                  this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                    ? 'Advance Reminder X days before payment due date'
                    : 'Advance Reminder sent on day of month'
                }
                model='payByText'
                parent={this}
                labelColumns={6}
                fieldColumns={6}
                value={this.state.payByText['advancePaymentReminderDay']}
              >
                {this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                  ? PayByTextAdvanceReminderDays.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))
                  : ReminderSentOnDayOfMonth.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))}
              </FieldSelect>

              <div className='font-weight-bold'>Payment Day Reminder</div>

              <FieldCheckbox
                id='sendMessageOnPaymentDueDay'
                fieldLabel='Send request for payment on payment due date in tenant record'
                fieldColumns={12}
                parent={this}
                model='payByText'
                value={this.state.payByText['sendMessageOnPaymentDueDay']}
              />

              <div className='font-weight-bold'>Overdue Reminder</div>

              <FieldSelect
                id='firstOverduePaymentReminderDay'
                label={
                  this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                    ? 'Overdue Reminder is X days after payment due date'
                    : 'Overdue Reminder sent on day of month'
                }
                model='payByText'
                parent={this}
                labelColumns={6}
                fieldColumns={6}
                value={this.state.payByText['firstOverduePaymentReminderDay']}
              >
                {this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                  ? PayByTextReminderDays.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))
                  : ReminderSentOnDayOfMonth.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))}
              </FieldSelect>

              <div className='small text-muted w-50'>
                If the balance changes after the payment due date, then no reminder will be sent
              </div>

              <FieldSelect
                id='secondOverduePaymentReminderDay'
                label={
                  this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                    ? 'Overdue Reminder is X days after payment due date'
                    : 'Overdue Reminder sent on day of month'
                }
                model='payByText'
                parent={this}
                labelColumns={6}
                fieldColumns={6}
                value={this.state.payByText['secondOverduePaymentReminderDay']}
              >
                {this.state.payByText['reminderReference'] === 'PAYMENT_DUE_DAY'
                  ? PayByTextReminderDays.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))
                  : ReminderSentOnDayOfMonth.map((day, key) => (
                      <option key={key} value={day.value}>
                        {day.text}
                      </option>
                    ))}
              </FieldSelect>

              <hr />
              {this.props.notificationProvider === NOTIFICATION_PROVIDERS.EVERYWARE && (
                <FieldText
                  id='welcomeText'
                  label='Welcome Message'
                  model='payByTextDetails'
                  placeholder='Default welcome message. Click below for details.'
                  maxLength='460'
                  useTextArea
                  parent={this}
                  value={this.state.payByTextDetails['welcomeText']}
                  fieldHelpHyperlink='Please click here for help'
                  handleClickHyperlink={() =>
                    this.openDefaultMessageGuideModal('welcome-message-modal-everyware')
                  }
                  modalId='welcome-message-modal-everyware'
                />
              )}

              {this.props.notificationProvider === NOTIFICATION_PROVIDERS.LETUS && (
                <>
                  <FieldText
                    id='welcomeTemplate'
                    label='Welcome Message'
                    model='txt2payByLetusTemplates'
                    placeholder='Default welcome message. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.welcomeTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('welcome-message-modal')
                    }
                    modalId='welcome-message-modal'
                  />

                  <FieldText
                    id='purchaseConfirmationTemplate'
                    label='Purchase Confirmation'
                    model='txt2payByLetusTemplates'
                    placeholder='Default purchase confirmation. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.purchaseConfirmationTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('purchase-confirmation-modal')
                    }
                    modalId='purchase-confirmation-modal'
                  />

                  <FieldText
                    id='invoiceWithTokenTemplate'
                    label='Invoice With Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default invoice with token message. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.invoiceWithTokenTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('invoice-with-token-modal')
                    }
                    modalId='invoice-with-token-modal'
                  />

                  <FieldText
                    id='invoiceTemplate'
                    label='Invoice Without Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default invoice message. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.invoiceTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() => this.openDefaultMessageGuideModal('invoice-modal')}
                    modalId='invoice-modal'
                  />

                  <FieldText
                    id='advanceReminderWithTokenTemplate'
                    label='Advance Reminder With Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default advance reminder with existing payment method. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.advanceReminderWithTokenTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('advance-reminder-with-token-modal')
                    }
                    modalId='advance-reminder-with-token-modal'
                  />

                  <FieldText
                    id='advanceReminderTemplate'
                    label='Advance Reminder Without Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default advance reminder without existing payment method. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.advanceReminderTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('advance-reminder-modal')
                    }
                    modalId='advance-reminder-modal'
                  />

                  <FieldText
                    id='overdueReminderWithTokenTemplate'
                    label='Overdue Reminder With Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default overdue reminder with existing payment method. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.overdueReminderWithTokenTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('overdue-reminder-with-token-modal')
                    }
                    modalId='overdue-reminder-with-token-modal'
                  />

                  <FieldText
                    id='overdueReminderTemplate'
                    label='Overdue Reminder Without Existing Payment Method'
                    model='txt2payByLetusTemplates'
                    placeholder='Default overdue reminder without existing payment method. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.overdueReminderTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('overdue-reminder-modal')
                    }
                    modalId='overdue-reminder-modal'
                  />

                  <FieldText
                    id='advanceReminderWithZeroAmountTemplate'
                    label='Advance Reminder With Zero Amount'
                    model='txt2payByLetusTemplates'
                    placeholder='Default advance reminder with zero amount. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.advanceReminderWithZeroAmountTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('advance-reminder-zero-amount-modal')
                    }
                    modalId='advance-reminder-zero-amount-modal'
                  />

                  <FieldText
                    id='overdueReminderWithZeroAmountTemplate'
                    label='Overdue Reminder With Zero Amount'
                    model='txt2payByLetusTemplates'
                    placeholder='Default overdue reminder with zero amount. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.overdueReminderWithZeroAmountTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('overdue-reminder-zero-amount-modal')
                    }
                    modalId='overdue-reminder-zero-amount-modal'
                  />

                  <FieldText
                    id='invoiceWithZeroAmountTemplate'
                    label='Invoice With Zero Amount'
                    model='txt2payByLetusTemplates'
                    placeholder='Default invoice with zero amount. Click below for details.'
                    maxLength='460'
                    useTextArea
                    parent={this}
                    value={this.state.txt2payByLetusTemplates.invoiceWithZeroAmountTemplate}
                    fieldHelpHyperlink='Please click here for help'
                    handleClickHyperlink={() =>
                      this.openDefaultMessageGuideModal('invoice-zero-amount-modal')
                    }
                    modalId='invoice-zero-amount-modal'
                  />
                </>
              )}

              {this.props.notificationProvider === NOTIFICATION_PROVIDERS.LETUS && (
                <>
                  {this.state.txt2payByLetus.paymentLink.logoUrl && (
                    <div className='form-group row'>
                      <div className='col-sm-3'>
                        <label className='col-form-label-sm'>Current Logo</label>
                      </div>
                      <div className='col-sm-8'>
                        <img
                          src={this.state.txt2payByLetus.paymentLink.logoUrl+'?'+Date.now()}
                          alt='Custom txt2pay Logo'
                          width='100'
                          height='100'
                        />
                      </div>
                      <div className='col-sm-1'>
                        <button
                          type='button'
                          onClick={() => this.handleCustomLogoDelete()}
                          className='btn btn-danger btn-md float-right mb-4 mt-2'
                        >
                          <FontAwesomeIcon icon={['fas', 'trash']} className='fa-fw' />
                        </button>
                      </div>
                    </div>
                  )}
                  <FieldFile
                    id='customLogo'
                    label='Upload new logo'
                    model='txt2payByLetus'
                    fieldClass='form-control-lg mb-0'
                    labelClass='col-form-label-sm'
                    fieldColumns='9'
                    labelColums='3'
                    parent={this}
                    value={this.state.txt2payByLetus.customLogo}
                    accept='image/png, image/gif, image/jpeg'
                  />
                </>
              )}

              <FieldCheckbox
                id='enablePayByText'
                fieldLabel='Enable Txt2Pay'
                fieldColumns={12}
                help='Once Payment due date is reached a text message will be sent to the tenants after this feature is enabled.'
                parent={this}
                model='payByText'
                value={this.state.payByText.enablePayByText}
                disabled={!this.state.payByText.isEnabled}
              />
            </div>

            <div className='col mt-4 mb-3'>
              <button
                type='submit'
                className='btn btn-primary btn-md float-right mb-3'
                disabled={!this.state.payByText.isEnabled}
              >
                <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Save
              </button>
            </div>
          </form>
        </div>

        <div
          className='modal fade'
          id='email-oncomplete'
          tabIndex='-1'
          role='dialog'
          aria-labelledby='email-oncomplete-label'
          aria-hidden='true'
        >
          <div className='modal-dialog modal-dialog-centered modal-md' role='document'>
            <div className='modal-content shadow'>
              <div className='modal-header text-center d-block text-white py-4 bg-dark border-bottom-0'>
                <div className=''>
                  <FontAwesomeIcon
                    icon={['fas', 'receipt']}
                    className='fa-fw va-b mb-3'
                    size='4x'
                  />
                </div>
                <h5 className='modal-title' id={`phone-validation-label`}>
                  Email when complete
                </h5>
              </div>
              <form onSubmit={this.synchronizeProcess}>
                <div className='modal-body px-4 pt-4 pb-4'>
                  <FieldText
                    id='email'
                    type='email'
                    label='Email me when complete'
                    model='emailOnComplete'
                    labelColumns={4}
                    fieldColumns={8}
                    parent={this}
                    value={this.state.emailOnComplete.email}
                    help='optional'
                    required={this.state.emailOnComplete.email ? true : false}
                  />
                  {/* <FieldCheckbox
                    id="ignoreRecordsWithSyncErrors"
                    fieldLabel="Ignore records with sync errors when enabling service"
                    fieldColumns={12}
                    help="Companies where errors are returned during sync will need to be accessed individually and synced from the company record.  These will not have Service enabled when parent company service is enabled.  Tenants whom have had sync errors will also not have the Txt2Pay checkbox enabled"
                    parent={this}
                    model="emailOnComplete"
                    value={
                      this.state.emailOnComplete.ignoreRecordsWithSyncErrors
                    }
                  /> */}
                  <div className='float-left pb-4'>
                    <ButtonClose />
                  </div>
                  <div className='float-right pb-4'>
                    <div className='dropdown'>
                      <button type='submit' className='btn btn-primary btn-lg'>
                        Sync
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ServiceLevelConfigPayByText;
