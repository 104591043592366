import LetusSplitLogo from "../media/img/logos/logo-letus-split.png";

export const HYPER_WALLET_PAYMENT_TYPES = {
    TYPE_PAYPAL : "paypal",
    TYPE_CREDIT_CARD : "creditCard",
    TYPE_CASH : "cash",
    TYPE_PAYPAL_CREDIT : "paypalCredit",
    TYPE_PAYPAL_PI4 : "paypalPI4",
    TYPE_LETUS_SPLIT : "letusSplit"
}

export const HYPER_WALLET_PAYMENT_CATEGORY = {
    PAY_NOW : "PAY_NOW",
    PAY_LATER : "PAY_LATER"
}

export const HYPER_WALLET_PAYMENT_TYPE_INFO_DATA = [
    {
        paymentType: 'paypal',
        name: 'Paypal ',
        description: 'Palpal Test Description'
    },
    {
        paymentType: 'creditCard',
        name: 'Credit or Debit Card ',
        description: 'Credit Card Test Description'
    },
    {
        paymentType: 'cash',
        name: 'Cash ',
        description: 'Cash Test Description'
    },
    {
        paymentType: 'paypalCredit',
        name: 'Paypal ',
        description: 'Palpal Credit Test Description'
    },
    {
        paymentType: 'paypalPI4',
        name: 'Paypal ',
        description: 'Palpal PI4 Test Description'
    },
    {
        paymentType: 'letusSplit',
        name: 'Letus Split ',
        description: `
            Letus Split allows you to split the total payment with zero interest over 4 months using your credit card.
            This service involves no credit checks or applications. All you need is a credit card with a sufficient amount of available credit.
        `,
        logo: LetusSplitLogo
    }
]