import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The view landlord tenants component for admins.
     */
    render() {

        return(
            <React.Fragment>

                <TenantList userType={this.props.userSession.sessionRole.type}
                            userId={this.props.userSession.sessionRole.id}
                            propertyId={this.props.match.params.propertyId}
                            context="property"
                            textSearchPlaceholderOverrideTenants = "Search Property Tenants"
                            textSearchPlaceholderOverrideInvitations = "Search Property Invitations"
                            history={this.props.history}
                            inviteTenant={true}
                            createTenant={true}
                            parent={this} />

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);