import React from 'react';
import Moment from 'react-moment';
import CardBrandIcon from '../../common/CardBrandIcon';
import styles from './css/HyperwalletPayment.module.css';
import LetusSplitLogo from '../../../media/img/logos/logo-letus-split.png';

const HyperWalletTransactionReceipt = (props) => {

  return (
    <div
      className='modal fade'
      id='receiptHWTransactionModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='receiptHWTransactionModalLabel'
      aria-hidden='true'
      data-backdrop="static" 
      data-keyboard="false"
    >
      <div className='modal-dialog modal-lg modal-dialog-centered' role='document'>
        <div className='modal-content p-3'>
          <div className='modal-header border-0 justify-content-center'>
            <div className='modal-title text-center'>
              <h5>Payment {props.paymentResponse?.status}</h5>
              <p className='small text-muted font-italic'>Confirmation Id: {props.paymentResponse?.confirmationId}</p>
              <p className='small text-muted font-italic'>
                Date Paid: <Moment format='MMM DD, YYYY'>{props.paymentResponse?.createDate}</Moment>
              </p>
            </div>
          </div>
          <div className='modal-body'>
            <div className='bg-light m-auto w-75 p-4'>
              Payment Method
              <div className='py-2'>
                {props.paymentResponse?.paymentMethodType === 'TYPE_CREDIT_CARD' && (
                  <>
                    <CardBrandIcon
                      paymentMethodType='TYPE_CREDIT_CARD'
                      brand={props.paymentResponse?.cardBrand}
                      width='55'
                    />
                    <span> Card ending in {props.paymentResponse?.last4}</span>
                  </>
                )}
                {props.paymentResponse?.paymentMethodType === 'TYPE_PAY_PAL' && (
                  <>
                    <CardBrandIcon paymentMethodType='TYPE_PAY_PAL' brand={'PAY_PAL'} width='55' />
                    <span> PayPal</span>
                  </>
                )}
                {props.paymentResponse?.paymentMethodType === 'TYPE_INSTALLMENT_PAY' && (
                  <>
                    <div>
                      <img
                        src={LetusSplitLogo}
                        className='rounded img-fluid bg-light'
                        alt='LetusSplit'
                        title='LetusSplit'
                        width='55'
                      />{' '}
                      <span>Letus Split</span>
                    </div>
                    <div  className='mt-2'>
                      <CardBrandIcon
                        paymentMethodType='TYPE_CREDIT_CARD'
                        brand={props.paymentResponse?.cardBrand}
                        width='55'
                      />{' '}
                      <span>Card ending in {props.paymentResponse?.last4}</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <table className={`new-receipt-invoice`}>
              <tbody>
                <tr>
                  <td>
                    <table className={`new-receipt-invoiceItems`} cellPadding='0' cellSpacing='0'>
                      <tbody>
                        <tr>
                          <td>Payment Amount</td>
                          <td className='text-center'>$ {Number(props.paymentResponse?.amount).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td>Processing Fee</td>
                          <td className='text-center'>$ {Number(props.paymentResponse?.feeAmount).toFixed(2)}</td>
                        </tr>
                        <tr className={`new-receipt-total`}>
                          <td width='80%'>Total</td>
                          <td className='text-center'>
                            ${' '}
                            {Number(
                              Number(props.paymentResponse?.amount) + Number(props.paymentResponse?.feeAmount)
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='modal-footer border-0 receipt-footer'>
            <div className='row w-100 receipt-actions justify-content-center d-flex'>
              <div className={`col-sm-3 mt-2 ${styles.receiptFooterCol}`}>
                <button
                  type='button'
                  className={`btn btn-light form-control ${styles.stepButtonHW}`}
                  data-dismiss='modal'
                  onClick={() => window.location.reload()}
                >
                  Close
                </button>
              </div>
              <div className={`col-sm-3 mt-2 ${styles.receiptFooterCol}`}>
                <button
                  type='button'
                  className={`btn btn-primary form-control ${styles.stepButtonHW}`}
                  onClick={() => window.print()}
                >
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperWalletTransactionReceipt;
