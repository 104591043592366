import React from 'react';

/**
 * Stateless functional component for text area to be used with the react hooks.
 * ! Do not add any business logic or conditional rendering to this component
 * @param {*} props
 * @author Arsh
 */
const FieldTextareaFC = (props) => {
  return (
    <div className='form-group row'>
      <label
        className={`col-sm-${props.labelColumns} col-form-label ${props.labelClass}`}
        htmlFor={props.id}
      >
        {props.label}
      </label>

      <div className={`col-sm-${props.fieldColumns}`}>
        <textarea
          id={props.id}
          name={props.id}
          rows={props.rows}
          value={props.value || ''}
          onChange={props.handleChange}
          maxLength={props.maxLength}
          placeholder={props.placeholder}
          required={props.required}
          autoComplete={props.autoComplete}
          autoCorrect={props.autoCorrect}
          autoCapitalize={props.autoCapitalize}
          spellCheck={props.spellCheck}
          className={`form-control ${props.fieldClass} ${props.isInvalid ? 'is-invalid' : ''}`}
          disabled={props.disabled}
        ></textarea>
        {props.help && <small className='form-text text-muted'>{props.help}</small>}
      </div>
    </div>
  );
};

FieldTextareaFC.defaultProps = {
  labelColumns: '3',
  fieldColumns: '9',
  labelClass: 'col-form-label-sm',
  fieldClass: 'form-control-sm',
  maxLength: '500',
  rows: '3',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
  spellcheck: 'false',
  disabled: false,
};

export default FieldTextareaFC;
