import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import CountryCollection from "../../constants/CountryConstants";
import FieldSelect from "../common/FieldSelect";

class FieldCountry extends Component {

  // Populates the country select field based on the billing address country. For Tenant.
  handleIsForTenantBankAcc() {
    const optionsArr = [];
    let inArrUS = false;
    let inArrCA = false;

    if(this.props.propertyCountry) {
      const propertyCountry = this.props.propertyCountry;
      if(propertyCountry === "US" && !inArrUS) {
        optionsArr.push(<option value="US" key='US'>United States</option>);
        inArrUS = true;
      } else if(propertyCountry === "CA" && !inArrCA) {
        optionsArr.push(<option value="CA" key='CA'>Canada</option>);
        inArrCA = true;
      }
    } else if(this.props.billingAccount) {
      const billingAccount = this.props.billingAccount;
      if(billingAccount && billingAccount.joins && billingAccount.joins.p && billingAccount.joins.p.country) {
        const propertyCountry = billingAccount.joins.p.country;
        if(propertyCountry === "US" && !inArrUS) {
          optionsArr.push(<option value="US" key='US'>United States</option>);
          inArrUS = true;
        } else if(propertyCountry === "CA" && !inArrCA) {
          optionsArr.push(<option value="CA" key='CA'>Canada</option>);
          inArrCA = true;
        }
      }
    }
    return optionsArr;
  }

  /**
   * Render the component.
   *
   * @returns {*} - A select field component, specialized for countries.
   */
  render() {
    return (
      <FieldSelect
        id={this.props.id}
        label={this.props.label}
        labelClass={this.props.labelClass}
        fieldClass={this.props.fieldClass}
        fieldColumns={this.props.fieldColumns}
        labelColumns={this.props.labelColumns}
        handleChange={this.props.handleChange}
        disabled={this.props.disabled}
        model={this.props.model}
        parent={this.props.parent}
        value={this.props.value}
        required={this.props.required}
      >
        <FormattedMessage id="field.country.default">
          {(country) => <option value="">{country}</option>}
        </FormattedMessage>
        {(this.props.isForCreditCard || this.props.isForCitizenship) ? (
          CountryCollection.map((country, index) => (
            <option key={index} value={country.value}>{country.text}</option>
          ))
        ) : this.props.isForTenantBankAcc ? (
          this.handleIsForTenantBankAcc()
        ) : (
          <>
            <option value="CA">Canada</option>
            <option value="US">United States</option>
          </>
        )}
      </FieldSelect>
    );
  }
}

FieldCountry.defaultProps = {
  labelColumns: "3",
  fieldColumns: "9",
  disabled: false,
};

export default FieldCountry;
