import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Propertii from "./Propertii";

class RejectInsurance extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A Reject Insurance modal.
     */

    render() {

        return(
            <div className="modal fade" id="reject-insurance" tabIndex="-1" role="dialog" aria-labelledby="reject-insurance-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="">
                                <FontAwesomeIcon icon={["fas", "user-shield"]} className="fa-fw va-b mb-3" size="4x" />
                            </div>
                            <h5 className="modal-title" id={`reject-insurance-label`}>
                                Reject Insurance Application
                            </h5>
                        </div>
                        {/* {this.props.insurance.approvalStatus === 'APPROVED' &&
                        <div className="modal-body bg-success border-top-0">
                            <p className="mb-0 text-white text-center">
                                Insurance Approved
                            </p>
                        </div>
                        } */}

                        {this.props.children}

                    </div>
                </div>
            </div>
        )
    };
}

RejectInsurance.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(RejectInsurance);
