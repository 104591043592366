import React, { Component } from "react";
import "react-day-picker/lib/style.css";
import { FormattedMessage } from "react-intl";


class FieldTime extends Component {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    if (this.props.parent.handleChange) {
      this.handleChange = this.props.parent.handleChange.bind(this);
    } else {
      this.handleChange = this.handleChange.bind(this);
    }
  }

  /**
   * Handle change events on date fields. Convert the date to the format accepted by the server.
   *
   * @param date - The selected date value.
   */
  handleChange(time) {
    let TimeField;

    if (this.props.model != null) {
      TimeField = this.props.parent.state[this.props.model];
      TimeField[this.props.id] = time;
    }

    if (this.props.model == null) {
      TimeField = time;
    }

    this.props.parent.setState((prevState) => ({
      ...prevState,
      [this.props.model ? this.props.model : this.props.id]: TimeField,
    }));
  }

  /**
   * Render the component.
   *
   * @returns {*} - A generic time field component.
   */
  render() {
    let isInvalid = false;
    let errorCode = "";

    Object.entries(this.props.parent.state.validationList).forEach(
      ([key, validation]) => {
        if (validation.fields) {
          Object.entries(validation.fields).forEach(([key, fieldError]) => {
            if (key === this.props.id) {
              isInvalid = true;
              errorCode = fieldError;
            }
          });
        }
      }
    );

    return (
      <React.Fragment>
        <div className={`form-group row ${this.props.containerClass}`}>
          <label
            className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`}
            htmlFor={this.props.id}
          >
            {this.props.label}{" "}
            {this.props.optional && (
              <small className="text-muted font-italic">(Optional)</small>
            )}{" "}
            {this.props.required && (
              <small className="text-muted font-italic">*</small>
            )}
          </label>

          <div className={`col-sm-${this.props.fieldColumns}`}>
            {this.props.selectsRange === undefined && (
              <div>
                {this.props.preText && (
                  <button
                    type="button"
                    className="btn btn-light btn-sm disabled mr-1 mt-0.5"
                  >
                    {this.props.preText}
                  </button>
                )}
                <div className="DayPickerInput">
                  <input
                    type="time"
                    className={`form-control gotham ${this.props.fieldClass} ${
                      this.props.disabled ? "DisabledInputField" : ""
                    } ${isInvalid ? "is-invalid" : ""}`}
                    onChange={(event) =>
                      this.handleChange(
                        this.props.value == null ? "" : event.target.value
                      )
                    }
                    value={this.props.value == null ? "" : this.props.value}
                    required={this.props.required}
                    placeholder={this.props.placeholder}
                    pattern={this.props.pattern}
                    onInvalid={this.props.onInvalid}
                    onInput={this.props.onInput}

                  ></input>
                </div>
                {this.props.AddnlBtn1Text &&
                  this.props.AddnlBtn1Type &&
                  this.props.AddnlBtn1Handler && (
                    <button
                      type="button"
                      className={`btn btn-${this.props.AddnlBtn1Type} btn-sm mt-1`}
                      onClick={this.props.AddnlBtn1Handler}
                    >
                      {" "}
                      <small>
                        <u>{this.props.AddnlBtn1Text} </u>
                      </small>{" "}
                    </button>
                  )}
                <style>{`.DayPickerInput:not(.DayPickerInput > .InputField):not(.DayPickerInput > .InputFromTo-to):not(.DayPickerInput > .InputFromTo){
                            width: ${
                              !!this.props.width ? this.props.width : "120px"
                            };
                            background-color: #fff;`}</style>
              </div>
            )}

            {isInvalid && (
              <div className="small text-danger mt-1">
                <FormattedMessage id={"danger." + errorCode} />
              </div>
            )}

            {this.props.help && (
              <small className="form-text text-muted">{this.props.help}</small>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FieldTime.defaultProps = {
  labelColumns: "3",
  fieldColumns: "9",
  labelClass: "col-form-label-sm",
  fieldClass: "form-control-sm",
  monthsShown: 1,
  dateFormat: "MM/dd/yyyy",
};

export default FieldTime;
