import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import InvoiceList from "../../common/Invoices";
import Propertii from "../../common/Propertii";

class Invoice extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The company invoices component.
     */
    render() {

        return(
            <React.Fragment>

                <InvoiceList companyId={this.props.match.params.companyId} sessionRole={this.props.userSession.sessionRole} history={this.props.history} token={this.props.token} userType="TYPE_COMPANY_OWNER" />

            </React.Fragment>
        )
    };
}

Invoice.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoice);