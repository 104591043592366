import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import PropertyList from "../../common/Properties";
import Propertii from "../../common/Propertii";

class Properties extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The admin property list component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Properties" />

                    <PropertyList userSession={this.props.userSession}
                                  userType={this.props.userSession.sessionRole.type}
                                  merchantAccounts={true}
                                  history={this.props.history} />

                </div>

            </div>
        )
    };
}

export default Properties;