const AutoPaymentDateCollection = [
    { text: '1st of every Month', value: '1' },
    { text: '2nd of every Month', value: '2' },
    { text: '3rd of every Month', value: '3' },
    { text: '4th of every Month', value: '4' },
    { text: '5th of every Month', value: '5' },
    { text: '6th of every Month', value: '6' },
    { text: '7th of every Month', value: '7' },
    { text: '8th of every Month', value: '8' },
    { text: '9th of every Month', value: '9' },
    { text: '10th of every Month', value: '10' },
    { text: '11th of every Month', value: '11' },
    { text: '12th of every Month', value: '12' },
    { text: '13th of every Month', value: '13' },
    { text: '14th of every Month', value: '14' },
    { text: '15th of every Month', value: '15' },
    { text: '16th of every Month', value: '16' },
    { text: '17th of every Month', value: '17' },
    { text: '18th of every Month', value: '18' },
    { text: '19th of every Month', value: '19' },
    { text: '20th of every Month', value: '20' },
    { text: '21st of every Month', value: '21' },
    { text: '22nd of every Month', value: '22' },
    { text: '23rd of every Month', value: '23' },
    { text: '24th of every Month', value: '24' },
    { text: '25th of every Month', value: '25' },
    { text: '26th of every Month', value: '26' },
    { text: '27th of every Month', value: '27' },
    { text: '28th of every Month', value: '28' },
    { text: '29th of every Month', value: '29' },
    { text: '30th of every Month', value: '30' },
    { text: '31st of every Month', value: '31' },
];

export default AutoPaymentDateCollection;
