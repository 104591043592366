import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldCountry from "../../common/FieldCountry";
import FieldDate from "../../common/FieldDate";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";

class StatHolidays extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            statHoliday: {},

            statHolidayList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            statHolidayQuery: {
                orderBy: 'ASC',
                orderByFields: ['date'],
                conditionList: []
            },

            validationList: [],

        };

        this.searchStatHolidays = this.searchStatHolidays.bind(this);
        this.viewStatHoliday = this.viewStatHoliday.bind(this);
        this.initStatHoliday = this.initStatHoliday.bind(this);
        this.saveStatHoliday = this.saveStatHoliday.bind(this);
        this.deleteStatHoliday = this.deleteStatHoliday.bind(this);
    }

    /**
     * Load the list of stat holidays on mounting of the component.
     */
    componentDidMount() {

        this.searchStatHolidays(1, 25, this.state.statHolidayQuery);
    }

    /**
     * Handle selecting a stat holiday record by bringing up the modal with stat holiday values pre-filled.
     *
     * @param statHolidayId - The ID of the stat holiday selected.
     */
    viewStatHoliday(statHolidayId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/stat_holiday/${statHolidayId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                statHoliday: response.data
            }));

            $('#stat-holiday').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a stat holiday object when the user indicates they want to create a new stat holiday
     * record.
     */
    initStatHoliday() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/stat_holiday/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                statHoliday: {
                    ...response.data,
                    id: null
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected stat holiday.
     */
    deleteStatHoliday() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/stat_holiday/${this.state.statHoliday.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.holidays.deleted'
                    }
                }],
            });

            this.searchStatHolidays(this.state.statHolidayList.page, this.state.statHolidayList.recordsPerPage, this.state.statHolidayQuery);

            $('#stat-holiday').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle the submission of the form. Use the ID field of the stat holiday in the state to determine if the user is
     * editing or creating a new stat holiday. Upon successful submission of the form, refresh the table and hide the
     * modal.
     *
     * @param event - The event container.
     */
    saveStatHoliday(event) {

        event.preventDefault();

        if(this.state.statHoliday.id == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.statHoliday, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.holidays.created'
                        }
                    }],
                });

                this.searchStatHolidays(this.state.statHolidayList.page, this.state.statHolidayList.recordsPerPage, this.state.statHolidayQuery);

                $('#stat-holiday').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.statHoliday, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchStatHolidays(this.state.statHolidayList.page, this.state.statHolidayList.recordsPerPage, this.state.statHolidayQuery);

                $('#stat-holiday').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of stat holidays.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchStatHolidays(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/stat_holiday/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                statHolidayList: response.data,
                statHolidayQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The stat holiday management interface.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Statutory Holidays" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Statutory Holidays
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#stat-holiday" className="btn btn-primary btn-sm" onClick={() => this.initStatHoliday()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Statutory Holiday
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{date: 'Date', country: 'Country', description: 'Description'}}
                               columnWidths={['25%', '25%', '50%']}
                               headerClass="c-pointer"
                               data={this.state.statHolidayList}
                               query={this.state.statHolidayQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchStatHolidays}>
                            <tbody>
                            {this.state.statHolidayList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewStatHoliday(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY" tz="UTC">
                                                    {data.date}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"country." + data.country} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.description}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="stat-holiday" tabIndex="-1" role="dialog" aria-labelledby="stat-holiday-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveStatHoliday}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="fee-profile-label">
                                            {this.state.statHoliday.id == null &&
                                            <span className="">
                                            Create Statutory Holiday
                                        </span>
                                            }
                                            {this.state.statHoliday.id != null &&
                                            <span className="">
                                            Edit Statutory Holiday
                                        </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldDate id="date" label="Date" model="statHoliday" required={true} parent={this} value={this.state.statHoliday['date']} />

                                        <FieldCountry id="country" label="Country" model="statHoliday" parent={this} value={this.state.statHoliday['country']} />

                                        <FieldText id="description" label="Description" model="statHoliday" required={true} parent={this} value={this.state.statHoliday['description']} maxLength="200" />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#stat-holiday").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {this.state.statHoliday.id != null &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#stat-holiday").modal("hide"); $("#delete-stat-holiday").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-stat-holiday" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Statutory Holiday"
                           body="Are you sure you would like to delete this statutory holiday?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#stat-holiday").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteStatHoliday()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Statutory Holiday
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default StatHolidays;