import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';
import Alert from '../Alert';
import ButtonSave from '../ButtonSave';
import FieldTextareaFC from '../FieldTextareaFC';
import FieldTextFC from '../FieldTextFC';

const NotesModal = ({
  saveNote,
  noteId,
  noteTicketNumber,
  handleChangeNoteTicketNumber,
  noteMessage,
  handleChangeNoteMessage,
  validationList,
}) => {
  return (
    <div
      className='modal fade'
      id='note-modal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='note-modal-label'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
        <div className='modal-content shadow'>
          <form onSubmit={saveNote}>
            <div className='modal-header bg-dark text-white'>
              <h5 className='modal-title' id='note-modal-label'>
                {noteId === null ? <div>Create Note</div> : <div>Edit Note</div>}
              </h5>
              <button
                type='button'
                className='close text-white'
                data-dismiss='modal'
                aria-label='Close'
              >
                <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw va-b mr-2' />
              </button>
            </div>
            <div className='modal-body'>
              <Alert validationList={validationList} validationType='danger' />
              <FieldTextFC
                id='ticketNumber'
                label='Ticket Number'
                value={noteTicketNumber}
                handleChange={handleChangeNoteTicketNumber}
              />
              <FieldTextareaFC
                id='note'
                rows='8'
                label='Note'
                value={noteMessage}
                required
                handleChange={handleChangeNoteMessage}
              />
            </div>
            <div className='modal-footer bg-secondary rounded-bottom d-block'>
              <div className='row'>
                <div className='col'>
                  <div className='float-left'>
                    <button
                      type='button'
                      className='btn btn-outline-primary btn-lg'
                      data-dismiss='modal'
                    >
                      Close
                    </button>
                  </div>
                  <div className='float-right'>
                    <ButtonSave />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

NotesModal.propTypes = {
  saveNote: PropTypes.func,
  noteId: PropTypes.string,
  noteTicketNumber: PropTypes.string,
  handleChangeNoteTicketNumber: PropTypes.func,
  noteMessage: PropTypes.string,
  handleChangeNoteMessage: PropTypes.func,
  validationList: PropTypes.array,
};

export default NotesModal;
