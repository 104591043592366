import * as moment from "moment";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import Alert from "../../common/Alert";
import Propertii from "../../common/Propertii";
import axios from "axios";
import * as constants from "../../../util/constants";

class Application extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
     constructor(props) {

        super(props);

        this.downloadDocument = this.downloadDocument.bind(this);
        this.calculateCustomFieldDisplayValue = this.calculateCustomFieldDisplayValue.bind(this);
    }

    /***
     * Download a document from the list of documents.
     *
     * @param documentId - The ID of the document selected.
     */
    downloadDocument(documentId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/document/${documentId}/download`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);


        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Calculate customField display value, handles the case when the custom value maybe a date
     */
    calculateCustomFieldDisplayValue(value) {
        if(moment(value, "YYYY-MM-DDTHH:mm:ssZZ", true).isValid()) {
            const date = new Date(value);
            return moment(date, 'DD-MM-YYYY').format('MMMM DD, YYYY');
        }
        return value;
    }

    
    /**
     * Render the component.
     *
     * @returns {*} - The tenant account management component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                {this.props.leaseApplication.existingTenant &&
                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <p className="card-text">
                            This applicant has indicated that they are already a tenant of yours matching the information below, and would like to create a Letus account to make their payments.
                        </p>
                    </div>
                </div>
                }

                {(this.props.leaseApplication.joins && this.props.leaseApplication.joins.p) &&
                <div className="accordion" id="application">

                    <div className="card mb-0">

                        <div className="card-header">
                            Application
                            
                            {this.props.documents[0] !== undefined &&
                            <div className="float-right">
                                <div className="dropdown">
                                    <button className="btn btn-primary btn-sm mb-0" type="button" id="document-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Download Documents
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="document-actions">
                                        {this.props.documents.map((data, key) => {
                                            let nameArray = data.name.split("_");
                                            return (
                                                <div className="dropdown-item c-pointer" onClick={() => this.downloadDocument(data.id)}>{nameArray[0]}</div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            }
                    
                        </div>

                        <div className="card-header bg-secondary text-dark c-pointer" data-toggle="collapse" data-target="#applicant" aria-expanded="true" aria-controls="applicant">
                            <div className="row align-items-center">
                                <div className="col">
                                    {this.props.leaseApplication.existingTenant ? 'Tenant' : 'Applicant'}
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="applicant" className="collapse show" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                        {
                                            this.props.leaseApplication.firstName &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Name
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.firstName} {this.props.leaseApplication.middleName} {this.props.leaseApplication.lastName}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.email &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Email
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.email}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.phone &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Phone
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.phone}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.birthDate &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Date of Birth
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            <Moment format="MMMM DD, YYYY" tz="UTC">{this.props.leaseApplication.birthDate}</Moment>
                                                        </div>
                                                    </td>
                                                </tr>

                                        }
                                        {
                                            this.props.leaseApplication.sin &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            SIN/SSN
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.sin}
                                                        </div>
                                                    </td>
                                                </tr>

                                        }
                                        {
                                            (!this.props.leaseApplication.existingTenant && this.props.leaseApplication.otherInfo) &&
                                                <React.Fragment>
                                                    {
                                                        this.props.leaseApplication.otherInfo.maritalStatus &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    <div className="">
                                                                        Marital Status
                                                                    </div>
                                                                </td>
                                                                <td width="75%">
                                                                    <div className="">
                                                                        <FormattedMessage id={"enum.maritalStatus." + this.props.leaseApplication.otherInfo.maritalStatus} />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication.otherInfo?.pets !== null &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    <div className="">
                                                                        Pets
                                                                    </div>
                                                                </td>
                                                                <td width="75%">
                                                                    <div className="">
                                                                        {this.props.leaseApplication.otherInfo.pets === 'true' ? 'Yes' : 'No'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication.otherInfo?.smoking !== null &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    <div className="">
                                                                        Smoking
                                                                    </div>
                                                                </td>
                                                                <td width="75%">
                                                                    <div className="">
                                                                        {this.props.leaseApplication.otherInfo.smoking ? 'Yes' : 'No'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication.otherInfo?.bankruptcy !== null &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    <div className="">
                                                                        Have you ever filed for Bankruptcy?
                                                                    </div>
                                                                </td>
                                                                <td width="75%">
                                                                    <div className="">
                                                                        {this.props.leaseApplication.otherInfo.bankruptcy ? 'Yes' : 'No'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication?.customFields?.pageCustomFields?.Applicant &&
                                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields?.Applicant)?.map(
                                                                ([key, value]) => { return (
                                                                        <tr className="">
                                                                            <td width="25%">
                                                                                <div className="">
                                                                                    {key.replace(/([A-Z])/g, ' $1').trim()}
                                                                                </div>
                                                                            </td>
                                                                            <td width="75%">
                                                                                <div className="">
                                                                                {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>)
                                                                    }
                                                                )
                                                        
                                                    }
                                                </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#property" aria-expanded="true" aria-controls="property">
                            <div className="row align-items-center">
                                <div className="col">
                                    Property
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="property" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    {
                                        this.props.leaseApplication.unit &&
                                            <tr className="">
                                                <td width="25%">
                                                    <div className="">
                                                        Unit
                                                    </div>
                                                </td>
                                                <td width="75%">
                                                    <div className="">
                                                        {this.props.leaseApplication.unit}
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Street 1
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.joins.p.street1}
                                            </div>
                                        </td>
                                    </tr>
                                    {this.props.leaseApplication.joins.p.street2 &&
                                    <tr className="">
                                        <td width="25%">
                                            Street 2
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.street2}
                                        </td>
                                    </tr>
                                    }
                                    <tr className="">
                                        <td width="25%">
                                            City
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.city}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            {this.props.leaseApplication.joins.p.country === 'CA' ? 'Province' : 'State'}
                                        </td>
                                        <td width="75%">
                                            {(this.props.leaseApplication.joins.p.country === 'CA' ? formatMessage({id: "province." + this.props.leaseApplication.joins.p.province}) : formatMessage({id: "state." + this.props.leaseApplication.joins.p.province}))}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            Country
                                        </td>
                                        <td width="75%">
                                            {formatMessage({id: "country." + this.props.leaseApplication.joins.p.country})}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            {this.props.leaseApplication.joins.p.country === 'CA' ? 'Postal Code' : 'ZIP Code'}
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.postalCode}
                                        </td>
                                    </tr>
                                    {
                                        this.props.leaseApplication?.customFields?.pageCustomFields?.Property &&
                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields?.Property)?.map(
                                                ([key, value]) => { return (
                                                        <tr className="">
                                                            <td width="25%">
                                                                <div className="">
                                                                    {key.replace(/([A-Z])/g, ' $1').trim()}
                                                                </div>
                                                            </td>
                                                            <td width="75%">
                                                                <div className="">
                                                                {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    }
                                                )
                                        
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {this.props.leaseApplication.addresses.length > 0 &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#addresses" aria-expanded="true" aria-controls="addresses">
                            <div className="row align-items-center">
                                <div className="col">
                                    Address History
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="addresses" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.addresses.map((address, id) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        {address.addressType === 'RESIDENTIAL' ? 'Current Address' : 'Previous Address'}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                    {address.suite &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            Unit
                                                        </td>
                                                        <td width="75%">
                                                            {address.suite}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.street1 &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            Street 1
                                                        </td>
                                                        <td width="75%">
                                                            {address.street1}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.street2 &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            Street 2
                                                        </td>
                                                        <td width="75%">
                                                            {address.street2}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.city &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            City
                                                        </td>
                                                        <td width="75%">
                                                            {address.city}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.province &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            {address.country === 'CA' ? 'Province' : 'State'}
                                                        </td>
                                                        <td width="75%">
                                                            {(address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province}))}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.country &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            Country
                                                        </td>
                                                        <td width="75%">
                                                            {formatMessage({id: "country." + address.country})}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.postalCode &&
                                                    <tr className="">
                                                        <td width="25%">
                                                            {address.country === 'CA' ? 'Postal Code' : 'ZIP Code'}
                                                        </td>
                                                        <td width="75%">
                                                            {address.postalCode}
                                                        </td>
                                                    </tr>
                                                    }
                                                    {address.monthsAtAddress ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Months at Address
                                                        </td>
                                                        <td width="75%">
                                                            {address.monthsAtAddress}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {address?.customFields?.monthlyRent ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Monthly Rent
                                                        </td>
                                                        <td width="75%">
                                                            {address?.customFields?.monthlyRent}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {address?.customFields?.buildingManagerLandlordName ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Building Manager/Landlord Name
                                                        </td>
                                                        <td width="75%">
                                                            {address?.customFields?.buildingManagerLandlordName}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {address?.customFields?.buildingManagerLandlordPhone ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Building Manager/Landlord Phone
                                                        </td>
                                                        <td width="75%">
                                                            {address?.customFields?.buildingManagerLandlordPhone}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {address?.customFields?.buildingManagerLandlordEmail ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Building Manager/Landlord Email
                                                        </td>
                                                        <td width="75%">
                                                            {address?.customFields?.buildingManagerLandlordEmail}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {address?.customFields?.comments ?
                                                    <tr className="">
                                                        <td width="25%">
                                                            Comments
                                                        </td>
                                                        <td width="75%">
                                                            {address?.customFields?.comments}
                                                        </td>
                                                    </tr> : null
                                                    }
                                                    {
                                                        this.props.leaseApplication?.customFields?.pageCustomFields &&
                                                        this.props.leaseApplication?.customFields?.pageCustomFields["Addresses"] &&
                                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields["Addresses"])?.map(
                                                                ([key, value]) => {
                                                                    let fieldPosition = parseInt(key.slice(-1));
                                                                    if(fieldPosition === id) {
                                                                        return (
                                                                            <tr className="" key={key}>
                                                                                <td width="25%">
                                                                                    <div className="">
                                                                                        {key.replace(/([A-Z])/g, ' $1').trim().slice(0, -1)}
                                                                                    </div>
                                                                                </td>
                                                                                <td width="75%">
                                                                                    <div className="">
                                                                                    {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    return null
                                                                }
                                                            )
                                                        
                                                    }
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.otherOccupants.length > 0 &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#occupants" aria-expanded="true" aria-controls="occupants">
                            <div className="row align-items-center">
                                <div className="col">
                                    Additional Occupants
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="occupants" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.otherOccupants.map((occupant, id) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        Additional Occupant {id + 1}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                    {
                                                        occupant.firstName &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Name
                                                                </td>
                                                                <td width="75%">
                                                                    {occupant.firstName} {occupant.lastName}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        occupant.phone &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Phone
                                                                </td>
                                                                <td width="75%">
                                                                    {occupant.phone}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        occupant.relationship &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Relationship
                                                                </td>
                                                                <td width="75%">
                                                                    {occupant.relationship}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        occupant.age &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Age
                                                                </td>
                                                                <td width="75%">
                                                                    {occupant.age}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        occupant.employment &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Employment
                                                                </td>
                                                                <td width="75%">
                                                                    <FormattedMessage id={"enum.employment." + occupant.employment}/>
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication?.customFields?.pageCustomFields &&
                                                        this.props.leaseApplication?.customFields?.pageCustomFields["Occupants"] &&
                                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields["Occupants"])?.map(
                                                                ([key, value]) => {
                                                                    let fieldPosition = parseInt(key.slice(-1));
                                                                    if(fieldPosition === id) {
                                                                        return (
                                                                            <tr className="">
                                                                            <td width="25%">
                                                                                <div className="">
                                                                                    {key.replace(/([A-Z])/g, ' $1').trim().slice(0, -1)}
                                                                                </div>
                                                                            </td>
                                                                            <td width="75%">
                                                                                <div className="">
                                                                                {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        )
                                                                    }
                                                                    return null
                                                                }
                                                            )
                                                        
                                                    }
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.employment?.employment &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#employment" aria-expanded="true" aria-controls="employment">
                            <div className="row align-items-center">
                                <div className="col">
                                    Employment Information
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="employment" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Employment Status
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                <FormattedMessage id={"enum.employment." + this.props.leaseApplication.employment.employment}/>
                                            </div>
                                        </td>
                                    </tr>
                                    {(this.props.leaseApplication.employment.employment === 'EMPLOYED_FULL_TIME' || this.props.leaseApplication.employment.employment === 'EMPLOYED_PART_TIME') &&
                                    <React.Fragment>
                                        {
                                            this.props.leaseApplication.employment.employerName &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Employer Name
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.employment.employerName}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.employment.phone &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Phone
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.employment.phone}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.employment.occupation &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Occupation
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.employment.occupation}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.employment.monthsEmployed &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Months Employed
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.employment.monthsEmployed}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                        {
                                            this.props.leaseApplication.employment.monthlySalary &&
                                                <tr className="">
                                                    <td width="25%">
                                                        <div className="">
                                                            Monthly Income
                                                        </div>
                                                    </td>
                                                    <td width="75%">
                                                        <div className="">
                                                            {this.props.leaseApplication.employment.monthlySalary}
                                                        </div>
                                                    </td>
                                                </tr>
                                        }
                                    </React.Fragment>
                                    }
                                    {
                                        this.props.leaseApplication?.employment?.otherIncome &&
                                            <tr className="">
                                                <td width="25%">
                                                    <div className="">
                                                        Other Income
                                                    </div>
                                                </td>
                                                <td width="75%">
                                                    <div className="">
                                                        {this.props.leaseApplication?.employment?.otherIncome}
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                    {
                                        this.props.leaseApplication?.customFields?.pageCustomFields &&
                                        this.props.leaseApplication?.customFields?.pageCustomFields["Employment"] &&
                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields["Employment"])?.map(
                                                ([key, value]) => { return (
                                                        <tr className="">
                                                            <td width="25%">
                                                                <div className="">
                                                                    {key.replace(/([A-Z])/g, ' $1').trim()}
                                                                </div>
                                                            </td>
                                                            <td width="75%">
                                                                <div className="">
                                                                {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    }
                                                )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.referenceList.length > 0 &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#references" aria-expanded="true" aria-controls="references">
                            <div className="row align-items-center">
                                <div className="col">
                                    Personal References
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="references" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.referenceList.map((reference, id) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        Personal Reference {id + 1}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                    {
                                                        reference.firstName &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Name
                                                                </td>
                                                                <td width="75%">
                                                                    {reference.firstName} {reference.lastName}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        reference.phone &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Phone
                                                                </td>
                                                                <td width="75%">
                                                                    {reference.phone}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        reference.relationship &&
                                                            <tr className="">
                                                                <td width="25%">
                                                                    Relationship
                                                                </td>
                                                                <td width="75%">
                                                                    {reference.relationship}
                                                                </td>
                                                            </tr>
                                                    }
                                                    {
                                                        this.props.leaseApplication?.customFields?.pageCustomFields &&
                                                        this.props.leaseApplication?.customFields?.pageCustomFields["References"] &&
                                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields["References"])?.map(
                                                                ([key, value]) => {
                                                                    let fieldPosition = parseInt(key.slice(-1));
                                                                    if(fieldPosition === id) {
                                                                        return (
                                                                            <tr className="">
                                                                                <td width="25%">
                                                                                    <div className="">
                                                                                        {key.replace(/([A-Z])/g, ' $1').trim().slice(0, -1)}
                                                                                    </div>
                                                                                </td>
                                                                                <td width="75%">
                                                                                    <div className="">
                                                                                    {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    }
                                                                    return null
                                                                }
                                                            )
                                                    }
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.emergencyContact?.firstName &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#emergencyContact" aria-expanded="true" aria-controls="employment">
                            <div className="row align-items-center">
                                <div className="col">
                                    Emergency Contact
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="emergencyContact" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Name
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.emergencyContact.firstName} {this.props.leaseApplication.emergencyContact.middleName} {this.props.leaseApplication.emergencyContact.lastName}
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        this.props.leaseApplication.emergencyContact.email &&
                                            <tr className="">
                                                <td width="25%">
                                                    <div className="">
                                                        Email
                                                    </div>
                                                </td>
                                                <td width="75%">
                                                    <div className="">
                                                        {this.props.leaseApplication.emergencyContact.email}
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                    {
                                        this.props.leaseApplication.emergencyContact.phone &&
                                            <tr className="">
                                                <td width="25%">
                                                    <div className="">
                                                        Phone
                                                    </div>
                                                </td>
                                                <td width="75%">
                                                    <div className="">
                                                        {this.props.leaseApplication.emergencyContact.phone}
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                    {
                                        this.props.leaseApplication.emergencyContact.relationship &&
                                            <tr className="">
                                                <td width="25%">
                                                    <div className="">
                                                        Relation
                                                    </div>
                                                </td>
                                                <td width="75%">
                                                    <div className="">
                                                        {this.props.leaseApplication.emergencyContact.relationship}
                                                    </div>
                                                </td>
                                            </tr>
                                    }
                                    {
                                        this.props.leaseApplication?.customFields?.pageCustomFields &&
                                        this.props.leaseApplication?.customFields?.pageCustomFields["Emergency"] &&
                                            Object.entries(this.props?.leaseApplication.customFields?.pageCustomFields["Emergency"])?.map(
                                                ([key, value]) => { return (
                                                        <tr className="">
                                                            <td width="25%">
                                                                <div className="">
                                                                    {key.replace(/([A-Z])/g, ' $1').trim()}
                                                                </div>
                                                            </td>
                                                            <td width="75%">
                                                                <div className="">
                                                                {value === true ? 'Yes'  : value === false ? 'No' : this.calculateCustomFieldDisplayValue(value)}
                                                                </div>
                                                            </td>
                                                        </tr>)
                                                    }
                                                )
                                        
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }

                </div>
                }

            </React.Fragment>
        )
    };
}

Application.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Application);