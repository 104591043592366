import axios from "axios";
import React from 'react';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import Propertii from "../../common/Propertii";

class Permissions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            manager: {},

            validationList: [],

        };

        this.saveManager = this.saveManager.bind(this);
    }

    /**
     * Fetch the manager on mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                manager: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveManager(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.manager, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });

            this.props.refreshManager(this.props.match.params.managerId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The manager profile management interface for managers.
     */
    render() {

        return(
            <React.Fragment>
                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveManager}>

                    <div className="card">
                        <div className="card-header">
                            Permissions
                        </div>
                        <div className="card-body">

                            <FieldCheckbox id="admin" fieldColumns="12" fieldLabel="Allow this manager to have access to all manager tools and be able to oversee all other managers within the company and it's subsidiaries" model="manager" parent={this} value={this.state.manager['admin']}/>

                            <FieldCheckbox id="accessAllProperties" fieldColumns="12" fieldLabel="Allow this manager to oversee all properties within the company and it's subsidiaries without explicitly assigning individual properties" model="manager" parent={this} value={this.state.manager['accessAllProperties']}/>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default Permissions;