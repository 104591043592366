import axios from "axios";
import React from 'react';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldAddress from "../../common/FieldAddress";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";
import FieldSelect from "../../common/FieldSelect";
import $ from "jquery";

class Property extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            property: {},
            validationList: []
        };

        this.saveProperty = this.saveProperty.bind(this);
        this.handleChangePropertyIdentifier = this.handleChangePropertyIdentifier.bind(this);
    }

    /**
     * Fetch a new instance of a property object on the mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.property, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });

            this.props.refreshProperty(this.props.match.params.propertyId);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }
    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangePropertyIdentifier(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                propertyIdentifier: event.target.value
            },
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveProperty}>

                    <div className="card">
                        <div className="card-header">
                            Property
                        </div>
                        <div className="card-body">

                            <div className="form-group row">
                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="propertyIdentifier">
                                    Property ID
                                </label>
                                <div className="col-sm-9">
                                    <div className="input-group input-group-sm">

                                        <input id="propertyIdentifier" name="propertyIdentifier" value={this.state.property['propertyIdentifier'] || ""} className="form-control mb-0" onChange={this.handleChangePropertyIdentifier}/>

                                        {this.state.integrationId &&
                                            <div className="input-group-append">
                                                <div className="btn btn-secondary" onClick={() => {$('#property').modal('hide');$('#search-property').modal('show');}}>
                                                    Search...
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <FieldText id="propertyName" label="Property Name" model="property" parent={this} value={this.state.property['propertyName']}  />

                            <FieldAddress model="property" parent={this} value={this.state.property} />

                            <FieldText id="units" label="Total Units" model="property" type="number" min="0" step="1" parent={this} value={this.state.property['units']} />

                            <FieldSelect id="category" label="Property Type" model="property" required
                                         parent={this} value={this.state.property['category']}>
                                <option value="" disabled>Select an option...</option>
                                <option value="RESIDENTIAL">Residential</option>
                                <option value="COMMERCIAL">Commercial</option>
                            </FieldSelect>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default Property;