export default function getSessionFromToken() {
    const token = window.localStorage.getItem('access_token');
    let session;
    if (token) {
        const base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        session = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
        session.user = JSON.parse(session.sessionRole);
    }
    return session;
}
