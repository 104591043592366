import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Banner from "./common/Banner";
import Propertii from "./common/Propertii";
import TermsContents from "./common/Terms";

class Terms extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the terms of use page.
     */
    render() {

        return(
            <div className="content-block">

                <Banner backgroundImg="discussion" heading="static.terms.heading" subheading="static.terms.subheading" />

                <div className="container">

                    <TermsContents isMainTermsPage/>

                </div>

            </div>
        );
    };
}

Terms.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Terms);