import React from 'react';
import { HYPER_WALLET_PAGE_COUNTRIES } from '../../../constants/HyperWalletConstants';
import hero from '../../../media/img/backgrounds/hero-hyperwallet-2.png';
import styles from './css/HyperwalletPaymentLanding.module.css';
import ReactSelect from 'react-select';
import HyperWalletChargeDropdown from './HyperWalletChargeDropdown';
import HyperWalletBeginModal from './HyperWalletBeginModal';
import TermsModal from '../../common/modals/TermsModal';
import $ from "jquery";

/**
 * HyperWallet Landing Component
 * @param {*} props 
 * @returns 
 */
const HyperwalletPaymentLanding = (props) => {
  const hwPageCountries = HYPER_WALLET_PAGE_COUNTRIES;

  return (
    <div className={`container-xl ${styles.containerCustom}`}>
      <TermsModal />
      <HyperWalletBeginModal toggleShowPayRentNow = {props.toggleShowPayRentNow}/>
      <div className={`row ${styles.mainRowContainer}`}>
        <div className={`col-md-6 ${styles.colContainerLeft}`}>
          <div className={`${styles.colInnerLeft}`}>
            <div>
              <div className='row'>
                <div className='col-md-12 mt-2'>
                  <span className={styles.landingPageHeading}>Flexible Payments</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <span className={styles.landingPageHeading}>Infinite Possibilities</span>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 col-lg-0 mt-2'>
                  <img src={hero} className={`d-block d-md-none mx-auto img-fluid ${styles.mobileLogo}`} alt='HyperWallet landing page logo' />
                </div>
              </div>

              <div className='row'>
                <div className={`col-lg-12 mt-2 ${styles.landingPageText}`}>
                  <ul>
                    <li>
                      Letus offers Flexible Payment powered by Buy Now Pay Later options for Rent, Deposits, HOA, Home
                      improvements, Dental care and other services.
                    </li>
                    <li>
                      Our Smartpay options allows Landlords and merchants to receive funds on-time while you leverage
                      the power of 0% interest liquidity solutions at check-out.
                    </li>
                  </ul>
                </div>
              </div>

              <div className='row'>
                <div className={`col-lg-9 mt-4 ${styles.landingPageChargeSelectionLabel}`}>
                  <label><b>Please select a country to send payment:</b></label>
                  <ReactSelect
                    id='selectedCountryHS'
                    name='selectedCountry'
                    options={hwPageCountries}
                    value={hwPageCountries.filter(function (hwPageCountry) {
                      return hwPageCountry.value === props.selectedCountry;
                    })}
                    onChange={props.handleHwPageCountryChange}
                    placeholder='Select a country'
                  />
                </div>
              </div>

              {props.selectedCountry && (
                <>
                  <div className='row'>
                    <div className={`col-lg-9 mt-4 ${styles.landingPageChargeSelectionLabel}`}>
                      <label><b>I want to use Flexible Pay to</b></label>
                      <HyperWalletChargeDropdown
                        selectedCharge={props.selectedCharge}
                        setSelectedCharge={props.setSelectedCharge}
                      />
                    </div>
                  </div>

                  <div className='mt-4 form-check'>
                    <input
                      type='checkbox'
                      className='form-check-input'
                      checked={props.agreedToTerms}
                      id='acceptTermsAndConditionsTCL'
                      onChange={(event) => {
                        props.setAgreedToTerms(event.target.checked);
                      }}
                    />
                    <label className='form-check-label' htmlFor='acceptTermsAndConditionsTCL'>
                      I have read and agree to the&nbsp;
                      <button
                        onClick={() => {
                          $('#terms-modal-common').modal('show');
                        }}
                        className={`btn btn-link p-0`}
                      >
                        Terms and Conditions
                      </button>
                    </label>
                  </div>
                </>
              )}

              {props.selectedCharge ? (
                <div className='row'>
                  <div className='col-lg-6'>
                    <input
                      type='button'
                      className={`btn btn-secondary btn-sm text-uppercase rounded-4 mt-4`}
                      value='Continue'
                      onClick={() => $('#hwBeginModal').modal('show')}
                      disabled={!props.agreedToTerms}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <br />
            </div>
          </div>
        </div>
        <div className={`col-md-6 ${styles.colContainerRight}`}>
          <div className={`${styles.colInnerRight}`}>
            <img src={hero} className='d-inline-block align-top' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperwalletPaymentLanding;
