import React, { Component } from 'react';
import FieldText from "./FieldText";

class FieldPassword extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A text field component, specialized for password entry.
     */
    render() {

        return(
            <FieldText id={this.props.id} type="password" label={this.props.label} required={this.props.required} labelClass={this.props.labelClass}
                       fieldClass={this.props.fieldClass} fieldColumns={this.props.fieldColumns} labelColums={this.props.labelColumns}
                       dataContainer="body" dataTrigger="focus" dataToggle="popover" dataHtml={true} dataPlacement={this.props.dataPlacement}
                       placeholder={this.props.placeholder} parent={this.props.parent} value={this.props.value}
                       dataContent={`<span className="font-weight-bold">Your password must:</span>
                                        <ul>
                                            <li>Be at least 8 characters long</li>
                                            <li>Contain at least one lowercase character</li>
                                            <li>Contain at least one uppercase character</li>
                                            <li>Contain at least one number</li>
                                        </ul>`
                       }/>
        )
    };
}

FieldPassword.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    disabled: false,
    dataPlacement: 'right',
};

export default FieldPassword;
