import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";

class Account extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The customer account navigation component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Account" />

                    <div className="card">
                        <div className="card-header">
                            Options
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/customer/account/profile" iconType="far" iconName="pencil-alt" name="Edit Profile" size="large" description="Edit your basic profile information and personal details." />

                                {this.props.userSession.accountType === 'TYPE_LOCAL_SYSTEM_ACCOUNT' &&
                                <NavListItem path="/customer/account/password" iconType="far" iconName="lock-alt" name="Change Password" size="large" description="Change your account password."/>
                                }

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Account.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Account);