import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldCheckbox from "../common/FieldCheckbox";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Table from "../common/Table";
import ButtonClose from "./ButtonClose";
import FieldCharges from "./FieldCharges";
import FieldDate from "./FieldDate";
import FieldPhone from "../common/FieldPhone";
import FieldProperty from "./FieldProperty";
import FieldSelect from "./FieldSelect";
import Modal from "./Modal";
import SkeletonLoader from "./SkeletonLoader.js";
import { TYPE_COMMON } from "../../constants/CommonConstants";
import ApplicationList from "../common/LeaseApplications";
import SelfSignUpList from "../common/SelfSignUpListFC";
import bankAccount from "../../media/img/payments/bank-account.png";
import FieldAddress from "../common/FieldAddress";
import FieldCountry from "./FieldCountry";
import ButtonSave from "../common/ButtonSave";

class Tenants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.phoneValidationType = {
            MARK_AS_VALID: "MARK_AS_VALID",
            VALIDATE: "VALIDATE"
        }

        let propertyLeaseConditionList = [];

        if(this.props.userType === 'TYPE_MANAGER') {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: "(",
                    closeBrackets: ")",
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: "ACTIVE"
                }
            );
        }

        let invitationConditionList = [];

        invitationConditionList.push(
            {
                type: 'BOOLEAN',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'accepted',
                fieldValue: false
            }
        );

        if(this.props.propertyId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        if(this.props.companyId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            );
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'p.companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        if(this.props.customerId) {
            propertyLeaseConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.customerId
                }
            );
        }

        this.state = {

            skeletonForLoading: false,

            selectedTab: '',

            propertyLeaseSearchFilter: '',
            propertyLeaseStatusFilter: this.props.userType === 'TYPE_MANAGER' ? "ACTIVE" : '',
            tenantTypeFilter: '',
            tenantSyncStatusFilter: '',
            tenantMobileStatusFilter: '',
            invitationSearchFilter: '',
            invitationAcceptedFilter: false,
            
            tenantMultiSelectActions: 'MARK_PHONES_VALID',

            propertyLease: {},
            invitation: {},
            property: {},
            customer: {},
            company: {},

            charges: [],

            companyId: '',
            integrationId: '',
            customChargeCodes: [],

            activeModal: '',

            recurring: false,

            integrationSearch: {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                tenantId: '',
                propertyId: ''
            },

            integrationCustomerList: [],

            propertyLeaseList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            invitationList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },


            billingAccountQuery: {
                orderBy: 'ASC',
                orderByFields: ["c.firstName"],
                conditionList: [
                    {
                        "type": "STRING",
                        "logicalOperator": "AND",
                        "openBrackets": null,
                        "closeBrackets": null,
                        "operator": "EQUALS",
                        "fieldName": "companyId",
                        "fieldValue": this.props.companyId
                    }
                ],
                joins: {
                    "c": {
                        "targetRecordType": "TYPE_CUSTOMER",
                        "joinField": "userId",
                        "alias": "c",
                        "returnFields": [
                            "firstName",
                            "lastName",
                            "email",
                            'phone', 
                            'phoneStatus'
                        ]
                    }
                }
            },

            propertyLeaseQuery: {
                orderBy: 'ASC',
                orderByFields: ['c.firstName'],
                conditionList: propertyLeaseConditionList,
                joins: {
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'email', 'phone', 'phoneStatus']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
                    },
                    company:{
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['onBoarding','id', 'notificationProvider']
                    },
                },
            },

            invitationQuery: {
                orderBy: 'DESC',
                orderByFields: ['e.lastSentDate'],
                conditionList: invitationConditionList,
                joins: {
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'companyId']
                    },
                    e: {
                        targetRecordType: 'TYPE_EMAIL',
                        joinField: 'sentEmailId',
                        alias: 'e',
                        returnFields: ['lastSentDate']
                    },
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'p.companyId',
                        alias: 'c',
                        returnFields: ['id', 'integrationId', 'chargeCodes', 'parentId']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'c.parentId',
                        alias: 'pc',
                        returnFields: ['integrationId', 'chargeCodes']
                    },
                    m: {
                        targetRecordType: 'TYPE_MANAGER',
                        joinField: 'userId',
                        alias: 'm',
                        returnFields: ['firstName', 'lastName']
                    }

                },
            },

            inviteTenantInsteadObj: {inviteTenantInstead: false},
            tenantAlreadyExist: false,
            tenantAlreadyExistRecords: [],
            selectedExistingTenantLease: null,
            isEmailBeingSearched: false,
            searchTenantQuery: {
                orderBy: 'ASC',
                orderByFields: ['c.firstName'],
                conditionList: [],
                joins: {
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName', 'email', 'phone']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
                    },
                },
            },

            phoneValidation: {
                customerIds: [],
                companyIds: [],
                propertyLeaseIds: [],
            },
            subCompanies: null,
            
            totalNumberOfCustomers: 0,
            selectAllLabel: "Select all tenants from company",

            payByText: {
                serviceEnabled: false,
            },

            checkboxesHasBeenTouched: false,
            
            validationList: [],
            companyOnBoarding :'SELF_SIGN_UP',
            paymentMethod: {},
            recurringSchedule: {},
            validateBankAccountData: null,

            propertyCountry: '',
            enforceCustomAmount: false,
            allowManageCharges: false,
        };

        this.selectTab = this.selectTab.bind(this);

        this.searchProperties = this.searchProperties.bind(this);
        this.searchPropertyLeases = this.searchPropertyLeases.bind(this);
        this.filterPropertyLeases = this.filterPropertyLeases.bind(this);

        this.searchInvitations = this.searchInvitations.bind(this);
        this.filterInvitations = this.filterInvitations.bind(this);

        this.clearFilters  = this.clearFilters.bind(this);
        this.redirectPropertyLease = this.redirectPropertyLease.bind(this);

        this.initInvitation = this.initInvitation.bind(this);
        this.saveInvitation = this.saveInvitation.bind(this);
        this.selectInvitation = this.selectInvitation.bind(this);
        this.deleteInvitation = this.deleteInvitation.bind(this);

        this.initCustomer = this.initCustomer.bind(this);
        this.saveCustomerProcess = this.saveCustomerProcess.bind(this);
        this.saveCustomer = this.saveCustomer.bind(this);
        this.handleSubmitCustomer = this.handleSubmitCustomer.bind(this);
        this.closeWarningModal = this.closeWarningModal.bind(this);

        this.initIntegrationCustomers = this.initIntegrationCustomers.bind(this);
        this.searchIntegrationCustomers = this.searchIntegrationCustomers.bind(this);
        this.selectIntegrationCustomer = this.selectIntegrationCustomer.bind(this);

        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);
        this.importCharges = this.importCharges.bind(this);

        this.handleChangeAccountNumber = this.handleChangeAccountNumber.bind(this);
        this.handleChangeProperty = this.handleChangeProperty.bind(this);

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);

        this.handleTenantAlreadyExist = this.handleTenantAlreadyExist.bind(this);
        this.checkTenantAlreadyExist = this.checkTenantAlreadyExist.bind(this);

        this.saveInvitationProcess = this.saveInvitationProcess.bind(this);
        this.attachTenantProcess = this.attachTenantProcess.bind(this);
        
        this.debounceEmailSearch = lodash.debounce(this.debounceEmailSearch, 400);
        this.handleEmailChange = this.handleEmailChange.bind(this);

        this.handleInviteTenantInstead = this.handleInviteTenantInstead.bind(this);
        this.handleChangeExistingTenantLease = this.handleChangeExistingTenantLease.bind(this);

        this.getIntegratedPropertyTenantId = this.getIntegratedPropertyTenantId.bind(this);
        this.getInteratedPropertyName = this.getInteratedPropertyName.bind(this);
        this.orderExistingLeases = this.orderExistingLeases.bind(this);

        this.handleMuteNotificationsChange = this.handleMuteNotificationsChange.bind(this);

        this.handleSelectAllPropetyLeases = this.handleSelectAllPropetyLeases.bind(this);
        this.handleValidateTenantPhones = this.handleValidateTenantPhones.bind(this);
        this.getAllSubCompanies = this.getAllSubCompanies.bind(this);
        this.handleSelectTenantsFromAllSubCompanies = this.handleSelectTenantsFromAllSubCompanies.bind(this);
        this.clearPhoneValidationSelectAll = this.clearPhoneValidationSelectAll.bind(this);
        this.calculatePhoneValFees = this.calculatePhoneValFees.bind(this);
        this.calculatePhoneStatus = this.calculatePhoneStatus.bind(this);
        this.getTotalNumberOfCustomers = this.getTotalNumberOfCustomers.bind(this);

        this.calculateSyncStatus = this.calculateSyncStatus.bind(this);
        this.syncTenants = this.syncTenants.bind(this);
        this.clearAllCheckboxes = this.clearAllCheckboxes.bind(this);

        this.checkPayByTextIsEnabled = this.checkPayByTextIsEnabled.bind(this);

        this.handleSendWelcomeText = this.handleSendWelcomeText.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);

        this.handleTenantMultiSelectActions = this.handleTenantMultiSelectActions.bind(this);

        this.handleSendWelcomeTextMessages = this.handleSendWelcomeTextMessages.bind(this);

        this.syncTenantsAllForCompany = this.syncTenantsAllForCompany.bind(this);
        
        this.addFiltersToTenantOperations = this.addFiltersToTenantOperations.bind(this);
        this.getCompanyDetails = this.getCompanyDetails.bind(this);

        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);
        this.preSavePaymentMethod = this.preSavePaymentMethod.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.initPaymentMethod = this.initPaymentMethod.bind(this);
        this.createRecurringSchedule = this.createRecurringSchedule.bind(this);
        this.validateBankAccount = this.validateBankAccount.bind(this);
        this.removeBankAccountData = this.removeBankAccountData.bind(this);
        this.addNewBankAccount = this.addNewBankAccount.bind(this)


    }

    /**
     * Retrieve list of tenants with active property leases, a list of all invitations, and a list of all lease
     * applications on mounting of the component. If a company ID is provided in the props, fetch the company data as
     * well.
     */
    componentDidMount() {

        if(this.props.history && this.props.history.location.state && this.props.history.location.state.invitation) {
            this.selectTab('INVITATIONS', true);
            this.selectInvitation(this.props.history.location.state.invitation);
        } else {
            this.selectTab('TENANTS', true);
        }

        if(this.props.context === 'property') {
            this.setState(prevState => ({
                ...prevState,
                selectAllLabel: "Select all tenants from property",
            }));
        }

        if(this.props.userType === 'TYPE_MANAGER') {
            //  Note - since this feature is available on the parent company level, we are getting the company Id using 
            //  this.props?.parent?.props?.userSession?.sessionRole?.companyId. This is similar to the logic used at other place and assumes that
            //  the manager will always exist on the parent company level.
            this.getCompanyDetails(this.props?.parent?.props?.userSession?.sessionRole?.companyId);
        } else if (this.props.userType === 'TYPE_ADMIN') {
            if(this.props?.companyId) {
                this.getCompanyDetails(this.props?.companyId);
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    companyOnBoarding: 'SELF_SIGN_UP',
                }));
            }
        }
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
     handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {
            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));

        } else {
            this.initBillingAddress();
        }
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
     initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: 'TYPE_CUSTOMER',
                    userId: this.state.customer.id
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

     /**
     * Pre save the info introduced for the payment method and validate data
     *
     * @param event - The event container.
     */
      preSavePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }
        this.initPaymentMethod('bank_account', false);

    }

    validateBankAccount(){
        let paymentMethod = this.state.paymentMethod;
        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/bank_account/validate`, paymentMethod, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                validateBankAccountData: response.data.result,
            }));

            $('#bank-account').modal("hide"); 
            $("#create-tenant").modal("show");  

        }).catch(error => {

            $('#bank-account').scrollTop(0);
            this.handleValidation(error);
            window.scrollTo(0, 0);

        });
    }

    /**
     * Remove the data regarding to bank account
     *
     * @param event - The event container.
     */
    removeBankAccountData(event){
       
        if(event != null) {
            event.stopPropagation();
        }
        
        this.setState(prevState => ({
            ...prevState,
            paymentMethod: {
                ...prevState.paymentMethod,
                accountHolder:'',
                bankAccountNumber:'',
                country:'',
                routingNumber:'',
            },
            billingAddress: {
                ...prevState.billingAddress,
                city:'',
                country:'',
                postalCode:'',
                province:'',
                street1:'',
            },
            bankAccountInstitutionNumber:'',
            bankAccountTransitNumber:'',

        }));
        
    }

    /**
     * Save the payment method
     *
     * @param event - The event container.
     */
     savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];
        const {formatMessage} = this.props.intl;
        

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        saveListData.push(billingAddress);

        // Handle institution and transit number conversions to routing number for Canadian banks
        if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        // Add the paymentMethod state to the save list queue
        saveListData.push(paymentMethod);

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data[1],
                //showBillingAddressForm: false,
                //spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.charges.method.created'
                    }
                }],
            }));

            this.createRecurringSchedule();

        }).catch(error => {
            
            $('#create-tenant').modal('hide');

            this.props.history.push({
                pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.state.propertyLease?.id}/edit`,
                state: {
                    errorMessage: formatMessage({id: "danger.common.tenants.create.autopay.paymentmethod"}),
                    error: error,
                }
            });

        });
        window.scrollTo(0, 0);
    }

    /**
     * Create the recurring schedule.
     *
     *
     */
     createRecurringSchedule() {
         const {formatMessage} = this.props.intl;
         let params = {
            type: 'TYPE_RECURRING_SCHEDULE',
            userType: 'TYPE_CUSTOMER',
            userId: this.state.customer.id,
            startDate: this.state.recurringStartDate,
            recurrenceType: 'MONTHLY',
            paymentMethodId: this.state.paymentMethod.id,
            paymentMethodType: 'TYPE_BANK_ACCOUNT',
            billingAccountId: this.state.propertyLease.id,
            billingAccountType: this.state.propertyLease.type,
            charges: this.state.charges?.length > 0 ? this.state.charges : null,
        }

        //set custom amount if enabled at company/subcompany Level
        if(this.state.recurringSchedule?.customAmount && this.state.enforceCustomAmount) {
            params.customAmount = this.state.recurringSchedule?.customAmount;
        } else {
            params.customAmount = null;
        }


        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }]
            }));

            $('#create-tenant').modal('hide');
        
            this.props.history.push({
                pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.state.propertyLease?.id}/edit`,
            });


        }).catch(error => {
            $('#create-tenant').modal('hide');

            this.props.history.push({
                pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.state.propertyLease?.id}/edit`,
                state: {
                    errorMessage: formatMessage({id: "danger.common.tenants.create.autopay.recurringSchedule"}),
                    error: error,
                }
            });

        });
     }


    /**
     * Redirect the user to the view tenant component when clicking a property lease from the list.
     *
     * @param propertyLease - The property lease selected.
     */
    redirectPropertyLease(propertyLease, event) {

        const {target} = event;
        const {attributes} = target;
        const isCheckbox = attributes?.getNamedItem('data-checkbox-cell')?.value;
        
        if(isCheckbox) {
            if(target?.checked) {
                // const mobileStatus = attributes?.getNamedItem('data-phonestatus')?.value;
                // if(mobileStatus === "MOBILE") {
                //     return;
                // }
                this.setState(prevState => ({
                    phoneValidation: {
                        ...prevState.phoneValidation,
                        customerIds: [...prevState.phoneValidation?.customerIds, target?.value],
                        propertyLeaseIds: [...prevState.phoneValidation?.propertyLeaseIds, target?.id],
                    }
                }));
            } else if(!target?.checked) {
                const phoneValCustomerIdsArr = this.state.phoneValidation.customerIds;
                if(phoneValCustomerIdsArr.includes(target?.value)) {
                    const newPhoneValCustomerIdsArr = phoneValCustomerIdsArr.filter(function(e) { return e !== target?.value});
                    this.setState(prevState => ({
                        phoneValidation: {
                            ...prevState.phoneValidation,
                            customerIds: newPhoneValCustomerIdsArr,
                        }
                    }));
                }
                const phoneValLeaseIdArr = this.state.phoneValidation.propertyLeaseIds;
                if(phoneValLeaseIdArr.includes(target?.id)) {
                    const newPhoneValLeaseIdArr = phoneValLeaseIdArr.filter(function(e) { return e !== target?.id});
                    this.setState(prevState => ({
                        phoneValidation: {
                            ...prevState.phoneValidation,
                            propertyLeaseIds: newPhoneValLeaseIdArr,
                        }
                    }));
                }
            }
            return;
        } else {
            if (event.metaKey || event.ctrlKey){
                window.open(`/${this.props.userType.substring(5).toLowerCase()}/tenants/${propertyLease.id}/edit`, "_blank");
            } else {
                this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/tenants/${propertyLease.id}/edit`);
            }
        }
    }

    /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
    selectTab(tabId, isOnLoad=false) {

        switch(tabId) {

            case "TENANTS":

                this.searchPropertyLeases(1, 25, this.state.propertyLeaseQuery, isOnLoad);
                $('#invitations').tab('dispose');
                $('#applications').tab('dispose');
                $('#tenants').tab('show');

                break;

            case "INVITATIONS":

                this.searchInvitations(1, 25, this.state.invitationQuery, isOnLoad);
                $('#tenants').tab('dispose');
                $('#applications').tab('dispose');
                $('#invitations').tab('show');

                break;
            
            case "APPLICATIONS":
                this.setState(prevState => ({
                    ...prevState,
                    selectedTab: "APPLICATIONS"
                }));
                $('#tenants').tab('dispose');
                $('#invitations').tab('dispose');
                $('#applications').tab('show');

                break;
            
            case "SELF_SIGNUP":
                this.setState(prevState => ({
                    ...prevState,
                    selectedTab: "SELF_SIGNUP"
                }));
                $('#tenants').tab('dispose');
                $('#invitations').tab('dispose');
                $('#applications').tab('dispose');
                $('#selfsignup').tab('show');

                break;

            default:

                this.searchPropertyLeases(1, 25, this.state.propertyLeaseQuery, isOnLoad);
                $('#invitations').tab('dispose');
                $('#tenants').tab('show');

                break;

        }
    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     * 
     */
    searchBillingAccounts(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            let billingAccounts = response.data;
            billingAccounts.records.forEach((billingAccount, key) => {
                let totalCharges = 0;

                billingAccount.charges.forEach((data) => {
                    totalCharges += data.amount;
                });

                billingAccounts.records[key].totalCharges = totalCharges;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLeaseList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.propertyLeaseSearchFilter === '' && response.data.records.length === 0
                },
                phoneValidation: {
                    customerIds: [],
                    companyIds: [],
                    propertyLeaseIds: [],
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of property leases.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPropertyLeases(page, recordsPerPage, query, isOnLoad=false) {

        this.setState({
            spinner: true,
            selectedTab: 'TENANTS',
            skeletonForLoading: isOnLoad
        });
        if(this.state.tenantTypeFilter === "APIGATEWAYTENANTS") {
            this.searchBillingAccounts(page, recordsPerPage, this.state.billingAccountQuery);
        } else {

            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'NOT_EQUALS',
                    fieldName: 'status',
                    fieldValue: 'PENDING'
                }
            );

            axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
                orderBy: query.orderBy,
                orderByFields: query.orderByFields,
                conditionList: query.conditionList,
                joins: query.joins
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                let propertyLeases = response.data;
                const totalRecordCount = response.data?.totalRecordCount; 
                
                if(propertyLeases.records.length) {
                    propertyLeases.records.forEach((propertyLease, key) => {

                        let totalCharges = 0;

                        propertyLease.charges.forEach((data) => {
                            totalCharges += data.amount;
                        });

                        propertyLeases.records[key].totalCharges = totalCharges;
                    });

                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        skeletonForLoading: false,
                        invitationList: {
                            page: '',
                            recordsPerPage: '',
                            totalPages: '',
                            totalRecordCount: '',
                            records: [
                                {}
                            ]
                        },
                        propertyLeaseList: {
                            ...response.data,
                            emptyUnfilteredList: this.state.propertyLeaseSearchFilter === '' && response.data.records.length === 0
                        },
                        propertyLeaseQuery: {
                            orderBy: query.orderBy,
                            orderByFields: query.orderByFields,
                            conditionList: query.conditionList,
                            joins: query.joins
                        },
                        totalNumberOfCustomers: totalRecordCount,
                    }));

                } else {
                    this.setState(prevState => ({
                        ...prevState,
                        spinner: false,
                        skeletonForLoading: false,
                        propertyLeaseList: {
                            page: '',
                            recordsPerPage: '',
                            totalPages: '',
                            totalRecordCount: '',
                            records: [
                                {}
                            ]
                        },
                    }));
                    this.setState(prevState => ({
                        ...prevState,
                        propertyLeaseList: {
                            ...response.data,
                            emptyUnfilteredList: this.state.propertyLeaseSearchFilter === '' && response.data.records.length === 0
                        },
                    }));
                    // this.searchBillingAccounts(page, recordsPerPage, this.state.billingAccountQuery);
                }
                // check all the validate checkboxes if select all button is active
                this.handleSelectAllPropetyLeases(false)
            }).catch(error => {
                this.handleValidation(error);
            }); 
        }
    }

    /**
     * Get company details to find out if we want to show the self-signup tab or not
     */
    getCompanyDetails(companyId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}`,  {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                companyOnBoarding: response.data.onBoarding,
                companyDetails: response.data,
                propertyCountry: response?.data?.country,
            }));

        }).catch(() => {
            this.setState(prevState => ({
                ...prevState,
                companyOnBoarding: null,
            }));
        });
    }

    /**
     * Update the data table of invitations.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchInvitations(page, recordsPerPage, query, isOnLoad) {

        this.setState({
            spinner: true,
            selectedTab: 'INVITATIONS',
            skeletonForLoading: isOnLoad
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/invitation/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let invitations = response.data;

            invitations.records.forEach((invitation, key) => {

                let totalCharges = 0;

                invitation.charges.forEach((data) => {
                    totalCharges += data.amount;
                });

                invitations.records[key].totalCharges = totalCharges;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                skeletonForLoading: false,
                propertyLeaseList: {
                    page: '',
                    recordsPerPage: '',
                    totalPages: '',
                    totalRecordCount: '',
                    records: [
                        {}
                    ]
                },
                invitationList: {
                    ...invitations,
                    emptyUnfilteredList: this.state.invitationSearchFilter === '' && response.data.records.length === 0
                },
                invitationQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an invitation object when the user initiates a new invitation.
     */
    initInvitation() {

        $('#onboard-tenant').modal('hide');

        this.searchProperties();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/invitation/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                activeModal: 'invite-tenant',
                invitation: {
                    ...response.data,
                    userType: this.props.userType,
                    userId: this.props.userId,
                    propertyId: this.props.propertyId !== null ? this.props.propertyId : null,
                },
                charges: [],
                integrationSearch: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    tenantId: '',
                    propertyId: ''
                },
                integrationCustomerList: [],
                inviteTenantInsteadObj: {inviteTenantInstead: false},
                tenantAlreadyExist: false,
                tenantAlreadyExistRecords: [],
                validationList: [],
            }));

            $('#invite-tenant').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select an existing invitation.
     *
     * @param invitation - The invitation selected.
     */
    selectInvitation(invitation) {
        this.setState(prevState => ({
            ...prevState,
            activeModal: 'invite-tenant',
            invitation: {
                ...invitation,
                selectedProperty: {
                    value: invitation.propertyId,
                    label: invitation.joins?.p?.street1
                },
            },
            charges: invitation.charges && invitation.charges.length > 0 ? invitation.charges : [{}],
            recurring: !!invitation.recurringStartDate,
            inviteTenantInsteadObj: {inviteTenantInstead: false},
            tenantAlreadyExist: false,
            tenantAlreadyExistRecords: []
        }), () => {

            this.searchProperties();

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${invitation.joins?.p?.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    companyId: invitation.joins.p.companyId,
                    integrationId: response.data.integrationId,
                    customChargeCodes: response.data.chargeCodes,
                }));

            }).catch(error => {
                if(!invitation.joins?.p && error?.response?.data?.errorCode === "RM_ERROR_RECORD_NOT_FOUND") {
                    // updating the error code to add a custom message
                    error.response.data.customErrorCode = "RM_ERROR_INVITATION_PROPERTY_DELETED";
                }
                this.handleValidation(error);
            });

        });

        $('#invite-tenant').modal('show');
    }


    /**
     * Handle the change when a different existing lease is selected.
     */
    handleChangeExistingTenantLease(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: JSON.parse(event.target.value),
        }));
    }

    /**
     * If the tenant already exist in the property under current manager and handles appropriate cases.
     * @param records - All the existing tenant under this PM
     */
    handleTenantAlreadyExist (records) {
        this.setState(prevState => ({
            ...prevState,
            tenantAlreadyExist: records?.length ? true : false,
            tenantAlreadyExistRecords: records,
        }));

        if(records?.length){
            this.orderExistingLeases();
        }
    }

    /**
     * Checks if the tenant already exist in a property under current manager
     * If yes, return the records. 
     * @returns - A promise
     */
    checkTenantAlreadyExist(tenantEmail) {

        const searchTenantQuery = this.state.searchTenantQuery;
        searchTenantQuery.conditionList = [];

        searchTenantQuery.conditionList.push(
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'c.email',
                operator: 'MATCH',
                fieldValue: tenantEmail,
            }
        );

        return axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: searchTenantQuery.orderBy,
            orderByFields: searchTenantQuery.orderByFields,
            conditionList: searchTenantQuery.conditionList,
            joins: searchTenantQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        });
    }

    /**
     * Holds the business logic for the invite tenant process
     */
    saveInvitationProcess() {
        this.setState(prevState => ({
            ...prevState,
            spinner: true,
        }));

        let invitation = this.state.invitation;

        // Perform a null check on an empty first charge
        if(this.state.charges[0]) {
            invitation.charges = (this.state.charges[0].code == null || this.state.charges[0].amount == null) ? null : this.state.charges;
        }

        if(this.state.invitation.createDate == null) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, invitation, {
                headers: this.generateRequestHeaders()
            }).then(() => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));

                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.tenants.invitation.sent'
                        },
                        values: {
                            email: this.state.invitation.email
                        }
                    }],
                });

                this.selectTab('INVITATIONS');

                $('#invite-tenant').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        } else {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, invitation, {
                headers: this.generateRequestHeaders()
            }).then(() => {

                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'common.tenants.invitation.sent'
                        },
                        values: {
                            email: this.state.invitation.email
                        }
                    }],
                });

                this.selectTab('INVITATIONS');

                $('#invite-tenant').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });

        }

        window.scrollTo(0, 0);
    }

    /**
     * Get the tenant ID from the existing tenants list, for the given Account number.
     * This function helps figure out whihch tenant is using an integrated account number.
     * @param accountNumber - Integrated Account number
     */
    getIntegratedPropertyTenantId(accountNumber) {
        const existingTenants = this.state.tenantAlreadyExistRecords;
        let propertyTenantId =  null;
        
        for(const existingTenant of existingTenants) {
            if(existingTenant.accountNumber === accountNumber) {
                propertyTenantId = existingTenant.id;
                break;
            }
        }
        return propertyTenantId;
    }

    /**
     * Get the property name from the existing tenants list, for the given Account number.
     * This function helps figure out whihch property is using an integrated account number.
     * @param accountNumber - Integrated Account number
     */
    getInteratedPropertyName(accountNumber) {
        const existingTenants = this.state.tenantAlreadyExistRecords;
        let propertyName =  null;
        
        for(const existingTenant of existingTenants) {
            if(existingTenant.accountNumber === accountNumber) {
                const prop = existingTenant?.joins?.p;
                propertyName = `Unit#${existingTenant.unit}, ${prop?.street1}, ${prop?.propertyName}, ${prop?.city}, ${prop?.province}, ${prop?.country}`;
                break;
            }
        }
        return propertyName;
    }

    /**
     * Holds the business logic for attaching a tenant to a lease
     */
    attachTenantProcess(params) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
            validationList: [],
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/attachLease`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

            $('#invite-tenant').modal('hide');
            $('#create-tenant').modal('hide');

            if(response.data) {
                const URL = response.data;
                this.props.history.push({
                    pathname: `/manager/tenants/${URL}/edit`,
                });
            }

        }).catch(error => {
            if(error?.response?.data?.errorCode === "RM_ERROR_RECORD_ALREADY_EXIST" && error?.response?.data?.message?.includes("TYPE_PROPERTY_LEASE Lease with account") && error?.response?.data?.message?.includes("already exists")) {
                const propertyTenantId = this.getIntegratedPropertyTenantId(params.accountNumber);
                const propertyName = this.getInteratedPropertyName(params.accountNumber)
                error.response.data.message = `Tenant Account ID is already in use by ${propertyName}.  Please access lease and remove Account ID before trying again.link#tenants/${propertyTenantId}/edit`;
            } else if(error?.response?.data?.errorCode === "RM_ERROR_RECORD_ALREADY_EXIST") {
                error.response.data.message = "Cannot onboard a tenant with the same lease and email details. Please change the lease, unit, or email.";
            }
            $("#create-tenant").scrollTop(0);
            $("#invite-tenant").scrollTop(0);
            this.handleValidation(error);
        });

    }

    /**
     * Handle the submission of the invitation form.
     *
     * @param event - The event container.
     */
    saveInvitation(event) {
       
        event.preventDefault();

        // Make sure that a property is selected
        if(!this.state.invitation?.propertyId) {
            window.scrollTo(0, 0);
            $("#invite-tenant").scrollTop(0);

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'required',
                        message: `Please select a property ${!this.state.invitation?.startDate ? "and a start date " : ""}to continue.`
                    }
                }],
            }));

            
            this.handleClearValidationList();

            return;
        }

        // Make sure that a start date is selected
        if(!this.state.invitation?.startDate) {
            window.scrollTo(0, 0);
            $("#invite-tenant").scrollTop(0);

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'required',
                        message: 'Please select a start date to continue.'
                    }
                }],
            }));

            this.handleClearValidationList();     

            return;
        }

        // clear the checkbox
        this.setState(prevState => ({
            ...prevState,
            inviteTenantInsteadObj: {inviteTenantInstead: false},
        }));

        if(this.state.tenantAlreadyExist) {
            let invitation = this.state.invitation;

            // Perform a null check on an empty first charge
            if(this.state.charges[0]) {
                invitation.charges = (this.state.charges[0].code == null || this.state.charges[0].amount == null) ? null : this.state.charges;
            }

            invitation.customerId = this.state.tenantAlreadyExistRecords[0]?.userId;

            this.attachTenantProcess(invitation);
        } else {
            this.checkTenantAlreadyExist(this.state.invitation?.email).then(response => {
                const propertyLeases = response.data;
                if(propertyLeases?.records.length) {
                    const records = propertyLeases?.records;
                    this.handleTenantAlreadyExist(records);
                } else {
                    this.saveInvitationProcess();
                }
            }).catch(error => {
                this.handleValidation(error);
            }); 
        }
    }

    /**
     * Email search debouncing, to prevent unnecessary API calls
     */
    debounceEmailSearch(tenantEmail) {
        this.setState(prevState => ({
            ...prevState,
            isEmailBeingSearched: true,
        }));
        this.checkTenantAlreadyExist(tenantEmail).then((response) => {
            const propertyLeases = response.data;
            if (propertyLeases?.records.length) {
                const records = propertyLeases?.records;
                this.handleTenantAlreadyExist(records);
            } else {
                this.handleTenantAlreadyExist([], false);
            }
            this.setState(prevState => ({
                ...prevState,
                isEmailBeingSearched: false,
            }));
        })
        .catch((error) => {
            this.setState(prevState => ({
                ...prevState,
                isEmailBeingSearched: false,
            }));
            this.handleValidation(error);
        });
    };

    /**
     * Triggers the search API to see if the record exist, when user is typing the email 
     */
    handleEmailChange(event) { 

        this.setState(prevState => ({
            ...prevState,
            inviteTenantInsteadObj: {inviteTenantInstead: false},
        }));

        const {target} = event;
        const email = target?.value?.toLowerCase();
        if(email && email?.includes('@')) {
            this.debounceEmailSearch(email);
        } else {
            this.setState(prevState => ({
                ...prevState,
                tenantAlreadyExist: false,
                tenantAlreadyExistRecords: [],
            }));
        }
    }

    /**
     * Handles the case when the tenant check the checkbox to invite tenant instead using a new email
     */
    handleInviteTenantInstead() {
        this.setState(prevState => ({
            ...prevState,
            tenantAlreadyExist: false,
            tenantAlreadyExistRecords: [],
        }));
    }

    /**
     * Delete an existing invitation.
     *
     * @param invitation - The invitation model to delete.
     */
    deleteInvitation(invitation) {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/invitation/${invitation.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(() => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.tenants.invitation.deleted'
                    },
                    values: {
                        firstName: invitation.firstName,
                        lastName: invitation.lastName
                    }
                }],
            });

            this.searchInvitations(1, 25, this.state.invitationQuery);

            $('#invite-tenant').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the property leases search filter field by adjusting the search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterPropertyLeases(event, subCompanies = null) {
        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        if(this.state.tenantTypeFilter === "APIGATEWAYTENANTS") {
            const billingAccountQuery = this.state.billingAccountQuery;
            billingAccountQuery.conditionList = [];

            // To always fetch only TYPE_CUSTOMER records
            billingAccountQuery.conditionList.push(
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "userType",
                    "fieldValue": "TYPE_CUSTOMER"
                }
            );

            // Fetch the API tenants only for the selected company
            if (this.props.companyId) {
                billingAccountQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: (subCompanies?.length ||this.state.subCompanies?.length)? '(' : null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

            if(subCompanies?.length || this.state.subCompanies) {

                let subCompaniesArr = null;
                if(subCompanies?.length) {
                    subCompaniesArr = subCompanies;
                } else {
                    subCompaniesArr = this.state.subCompanies;
                }

                subCompaniesArr.forEach((subCompany, index) => {
                    billingAccountQuery.conditionList.push({
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: (index === subCompaniesArr?.length-1) ? ")" : null,
                        operator: 'EQUALS',
                        fieldName: 'companyId',
                        fieldValue: subCompany.id
                    }); 
                });
            } 

            if(this.state.propertyLeaseSearchFilter) {
                billingAccountQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null, 
                        closeBrackets: null,
                        fieldName: 'c.firstName, c.lastName',
                        operator: 'MATCH',
                        fieldValue: this.state.propertyLeaseSearchFilter
                    }
                );
            }

            this.setState({
                billingAccountQuery: billingAccountQuery,
            });
            this.searchBillingAccounts(1, 25, billingAccountQuery);
            
        } else {

            let propertyLeaseQuery = this.state.propertyLeaseQuery;

            propertyLeaseQuery.conditionList = [];
    
            if (this.state.propertyLeaseSearchFilter === '') {
    
                if (this.props.propertyId) {
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            operator: 'EQUALS',
                            fieldName: 'propertyId',
                            fieldValue: this.props.propertyId
                        }
                    );
                }
    
                if (this.props.companyId) {
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: (subCompanies?.length ||this.state.subCompanies?.length)? '(' : null,
                            closeBrackets: null,
                            operator: 'EQUALS',
                            fieldName: 'companyId',
                            fieldValue: this.props.companyId
                        }
                    );
                }
    
                if (this.props.customerId) {
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            operator: 'EQUALS',
                            fieldName: 'userId',
                            fieldValue: this.props.customerId
                        }
                    );
                }

                if(subCompanies?.length || this.state.subCompanies) {

                    let subCompaniesArr = null;
                    if(subCompanies?.length) {
                        subCompaniesArr = subCompanies;
                    } else {
                        subCompaniesArr = this.state.subCompanies;
                    }

                    subCompaniesArr.forEach((subCompany, index) => {
                        propertyLeaseQuery.conditionList.push({
                            type: 'STRING',
                            logicalOperator: 'OR',
                            openBrackets: null,
                            closeBrackets: (index === subCompaniesArr?.length-1) ? ")" : null,
                            operator: 'EQUALS',
                            fieldName: 'companyId',
                            fieldValue: subCompany.id
                        }); 
                    });
                } 
            }
    
            if (this.state.propertyLeaseSearchFilter !== '') {
    
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: '(',
                        closeBrackets: null,
                        fieldName: 'id',
                        operator: 'EQUALS',
                        fieldValue: this.state.propertyLeaseSearchFilter
                    }
                );
    
                // Only search by phone if the filter is at least 6 characters long
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'c.phone',
                        operator: 'EQUALS',
                        fieldValue: this.state.propertyLeaseSearchFilter.replace(/\D/g,"")
                    },
                );
    
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: ')',
                        fieldName: 'c.firstName,c.lastName,c.email,unit,accountNumber,p.street1',
                        operator: 'LIKE_IGNORE_CASE',
                        fieldValue: this.state.propertyLeaseSearchFilter
                    }
                );

                if (this.props.companyId) {
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: (subCompanies?.length || this.state.subCompanies)? '(' : null,
                            closeBrackets: null,
                            operator: 'EQUALS',
                            fieldName: 'companyId',
                            fieldValue: this.props.companyId
                        }
                    );
                }

                if(this.props.propertyId) {
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            operator: 'EQUALS',
                            fieldName: 'propertyId',
                            fieldValue: this.props.propertyId
                        }
                    );
                }

                if(subCompanies?.length || this.state.subCompanies) {

                    let subCompaniesArr = null;
                    if(subCompanies?.length) {
                        subCompaniesArr = subCompanies;
                    } else {
                        subCompaniesArr = this.state.subCompanies;
                    }

                    subCompaniesArr.forEach((subCompany, index) => {
                        propertyLeaseQuery.conditionList.push({
                            type: 'STRING',
                            logicalOperator: 'OR',
                            openBrackets: null,
                            closeBrackets: (index === subCompaniesArr?.length-1) ? ")" : null,
                            operator: 'EQUALS',
                            fieldName: 'companyId',
                            fieldValue: subCompany.id
                        }); 
                    });
                } 
            }
    
            if(this.state.propertyLeaseStatusFilter !== '') {
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'status',
                        operator: 'EQUALS',
                        fieldValue: this.state.propertyLeaseStatusFilter
                    }
                );
            }

            if(this.state.tenantSyncStatusFilter !== '') {
                if(this.state.tenantSyncStatusFilter === "COMPLETED"){
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'syncStatus',
                            operator: 'EQUALS',
                            fieldValue: "COMPLETED"
                        }
                    );
                }else{
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: "(",
                            closeBrackets: null,
                            fieldName: 'syncStatus',
                            operator: 'EQUALS',
                            fieldValue: null
                        }
                    );
                    propertyLeaseQuery.conditionList.push(
                        {
                            type: 'STRING',
                            logicalOperator: 'OR',
                            openBrackets: null,
                            closeBrackets: ")",
                            fieldName: 'syncStatus',
                            operator: 'NOT_EQUALS',
                            fieldValue: "COMPLETED"
                        }
                    );
                }    
            }

            if(this.state.tenantMobileStatusFilter !== '') {
                propertyLeaseQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'c.phoneStatus',
                        operator: 'EQUALS',
                        fieldValue: this.state.tenantMobileStatusFilter === 'UNKNOWN' ? null : this.state.tenantMobileStatusFilter
                    }
                );
            }
    
            this.setState({
                propertyLeaseQuery: propertyLeaseQuery,
            });

            this.searchPropertyLeases(1, 25, propertyLeaseQuery);
        }
    }

    /**
     * Handle submitting the invitations search filter field by adjusting the search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterInvitations(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let invitationQuery = this.state.invitationQuery;

        invitationQuery.conditionList = [];

        if(this.state.invitationSearchFilter === '') {

            if(this.props.propertyId) {
                invitationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'propertyId',
                        fieldValue: this.props.propertyId
                    }
                );
            }

            if(this.props.companyId) {
                invitationQuery.conditionList.push(
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        operator: 'EQUALS',
                        fieldName: 'p.companyId',
                        fieldValue: this.props.companyId
                    }
                );
            }

        }

        if(this.state.invitationSearchFilter !== '') {
            invitationQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.invitationSearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'firstName,lastName,email,unit,accountNumber,p.street1',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.invitationSearchFilter
                }
            );
        }

        invitationQuery.conditionList.push(
            {
                type: 'BOOLEAN',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'accepted',
                fieldValue: this.state.invitationAcceptedFilter === 'true'
            }
        );

        this.setState({
            invitationQuery: invitationQuery,
        });

        this.searchInvitations(1, 25, invitationQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter queries.
     */
    clearFilters() {

        //clear show all tenants checkbox
        const tenantsFromAllSubCompaniesCheckbox = document.getElementById('selectTenantsFromAll');
        if(tenantsFromAllSubCompaniesCheckbox) {
            tenantsFromAllSubCompaniesCheckbox.checked = false;
            this.setState(prevState => ({
                ...prevState,
                subCompanies: null,
            }));
        }

        this.setState({
            propertyLeaseSearchFilter: '',
            invitationSearchFilter: '',
            invitationAcceptedFilter: '',
            tenantSyncStatusFilter: '',
            tenantMobileStatusFilter: '',
            propertyLeaseStatusFilter: '',
        }, () => {
            if(this.state.selectedTab === 'TENANTS') {
                this.filterPropertyLeases(null, '');
            } else if(this.state.selectedTab === 'INVITATIONS') {
                this.filterInvitations(null, '');
            }

        });

        this.clearPhoneValidationSelectAll();
    }


    /**
     * Adds filter to the tenant operations
     */
    addFiltersToTenantOperations() {
        const params = {};
        if(this.state.propertyLeaseStatusFilter) {
            params.leaseStatus = this.state.propertyLeaseStatusFilter;
        }
        if(this.state.tenantSyncStatusFilter) {
            params.syncStatus = this.state.tenantSyncStatusFilter;
        }
        if(this.state.tenantMobileStatusFilter) {
            params.mobileStatus = this.state.tenantMobileStatusFilter;
        }
        if(this.props.context === 'property') {
            params.propertyId = this.props.propertyId
        }
        return params;
    }   

    /**
     * Search for all properties related to the company in order to populate the property field when creating or
     * inviting a tenant.
     */
    searchProperties() {

        let conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN') {
            conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId || this.state.invitation.joins.p.companyId
                },
            ];
        }

        let propertyQuery = {
            orderBy: 'ASC',
            orderByFields: ['street1'],
            conditionList: conditionList,
            joins: {
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['enforceCustomAmount', 'manageBalance']
                },
            }
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: propertyQuery.orderBy,
            orderByFields: propertyQuery.orderByFields,
            conditionList: propertyQuery.conditionList,
            joins:propertyQuery.joins,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the change for muteNotifications flag
     */
    handleMuteNotificationsChange() {

        this.setState(prevState =>({
            ...prevState,
            customer: {
                ...prevState.customer,
                muteNotification: !prevState.customer.muteNotification,
            }
        }));
    }

    /**
     * Handle the change for sendWelcomeText flag
     */
    handleSendWelcomeText() {

        this.setState(prevState =>({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                sendWelcomeText: !prevState.propertyLease.sendWelcomeText,
            }
        }));
    }


    /**
     * handle changes to the phone field
     */
    handlePhoneChange(value) { 

        let customerUpdate = {
            phone: value,
        }

        let checkboxesTouched = this.state.checkboxesHasBeenTouched;

        if(this.state.payByText.serviceEnabled && !this.state.checkboxesHasBeenTouched && value?.length > 9) {
            // customerUpdate.muteNotification = true;
            checkboxesTouched = true;
        }
        
        this.setState(prevState =>({
            ...prevState,
            customer: {
                ...prevState.customer,
                ...customerUpdate,
            },
            propertyLease: {
                ...prevState.propertyLease,
                // sendWelcomeText: this.state.payByText.serviceEnabled && !this.state.checkboxesHasBeenTouched ? value?.length > 9 : this.state.propertyLease?.sendWelcomeText,
            },
            checkboxesHasBeenTouched: checkboxesTouched, 
        }));
    }

    /**
     * Initialize a new instance of a customer and property lease in order to allow the user to populate information for
     * creating a tenant.
     */
    initCustomer() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/new`).then(response => {
            
            this.setState(prevState => ({
                ...prevState,
                activeModal: 'create-tenant',
                customer: {
                    ...response.data,
                    muteNotification: true,
                },
                integrationSearch: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    phone: '',
                    tenantId: '',
                    propertyId: ''
                },
                charges: [],
                integrationCustomerList: [],
                inviteTenantInsteadObj: {inviteTenantInstead: false},
                tenantAlreadyExist: false,
                tenantAlreadyExistRecords: [],
                checkboxesHasBeenTouched: false,
                payByText: {
                    ...prevState.payByText,
                    serviceEnabled: false
                },
                validationList: [],
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/new`).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: {
                    ...response.data,
                    propertyId: this.props.propertyId !== null ? this.props.propertyId : null,
                    status: 'ACTIVE'
                }
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

        $('#onboard-tenant').modal('hide');

        this.searchProperties();
    }

    /**
     * Business logic for creating a new customer without a local system account
     */
    saveCustomerProcess() {

        // Make sure that a start date is selected
        if(!this.state.propertyLease?.startDate) {
            window.scrollTo(0, 0);
            $("#create-tenant").scrollTop(0);

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'required',
                        message: 'Please select a start date to continue.'
                    }
                }],
            }));

            this.handleClearValidationList();

            return;
        }

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/setupuser`, {
            user: this.state.customer,
            billingAccount: {
                ...this.state.propertyLease,
                charges: this.state.charges
            },
        }, {
            headers: this.generateRequestHeaders()
        }).then(() => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.tenants.tenant.created'
                    },
                    values: {
                        email: this.state.customer.email,
                    }
                }],
                // spinner: false
            });

            if(this.state.propertyLease['autoPay']){
                //this.initPaymentMethod('bank_account', false);
                this.savePaymentMethod();
            }else{
                $('#create-tenant').modal('hide');
                this.props.history.push({
                    pathname: `/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.state.propertyLease?.id}/edit`,
                });
            }
            
            // this.selectTab('TENANTS');
            

        }).catch(error => {
            this.handleValidation(error);
        });

        
    }

    /**
     * Create a new instance of a payment method, with the object dependant upon which payment method type thsavePaymentMethod/setupe user
     * selects.
     *
     * @param paymentType - The type of payment method selected.
     * @param updatePaymentMethod - True if the user has indicated they are 'updating' an existing payment method
     */
     initPaymentMethod(paymentType, updatePaymentMethod) {
     
        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
           
            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    ...prevState.paymentMethod,
                    userId: this.props.userType !== 'TYPE_COMPANY_OWNER' ? this.state.customer?.id : '',
                    userType: 'TYPE_CUSTOMER',
                },
                updatePaymentMethod: updatePaymentMethod,
                updatedPaymentMethod: updatePaymentMethod ? prevState.paymentMethod : {},
                //showBillingAddressForm: false,
                validationList: []
            }));
            
            //this.savePaymentMethod();
            this.validateBankAccount();
            
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create a customer from scratch without a local system account. Sends out an email to the tenant giving them the
     * option to create a password for their newly created Letus account. Because the customer will already be in
     * an active state, the property manager can already begin creating payment methods and making payments on the
     * account.
     *
     */
    saveCustomer() {

        // clear the checkbox
        this.setState(prevState => ({
            ...prevState,
            inviteTenantInsteadObj: {inviteTenantInstead: false},
        }));

        const {formatMessage} = this.props.intl;
        let charges =  this.state.charges.filter(value => value.amount !== '' && value.code !== '' && value.name !== '');

        if(this.state.propertyLease['autoPay'] && !this.state.allowManageCharges && !charges.length){
            $("#create-tenant").scrollTop(0);
            this.setErrorMessage(formatMessage({id: "danger.common.tenants.create.autopay.monthlyCharges"}));
            this.handleClearValidationList();
            return;
        }

        if(!this.state.recurringStartDate && this.state.propertyLease['autoPay']){
            window.scrollTo(0, 0);
                $("#create-tenant").scrollTop(0);

                this.setErrorMessage('Error - Please fill out an Auto Pay Start Date to continue.');

                this.handleClearValidationList();
               

                return;
        }
        
        if(this.state.propertyLease['autoPay'] && this.state.validateBankAccountData !== 'SUCCESS'){
            window.scrollTo(0, 0);
            $("#create-tenant").scrollTop(0);

            this.setErrorMessage('Error - Please fill out and save the Bank Account data to continue.');

            this.handleClearValidationList();

            return;
        }
        

        if(this.state.tenantAlreadyExist) {

            // Make sure that a property is selected
            if(!this.state.propertyLease?.propertyId) {
                window.scrollTo(0, 0);
                $("#create-tenant").scrollTop(0);

                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'required',
                            message: `Please select a property ${!this.state.invitation?.startDate ? "and a start date " : ""}to continue.`
                        }
                    }],
                }));

                this.handleClearValidationList();

                return;
            }

            // Make sure that a start date is selected
            // Start date is only required for attach tenanat process and not create tenant process
            if(!this.state.propertyLease?.startDate) {
                window.scrollTo(0, 0);
                $("#create-tenant").scrollTop(0);

                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'required',
                            message: 'Please select a start date to continue.'
                        }
                    }],
                }));

                this.handleClearValidationList();

                return;
            }

            const attachTenantParams = {
                "type":"TYPE_INVITATION",
                "id": this.state.customer?.id ? this.state.customer?.id: null,
                "joins":{},
                "createDate":null,
                "updateDate":null,
                "userType":"TYPE_MANAGER",
                "userId": this.props.userId,
                "firstName":null,
                "lastName":null,
                "email": this.state.customer?.email ? this.state.customer?.email: null,
                "applicationId":null,
                "accountNumber": this.state.propertyLease?.accountNumber ? this.state.propertyLease?.accountNumber: null,
                "unit": this.state.propertyLease?.unit ? this.state.propertyLease?.unit: null,
                "unitId":null,
                "customerId": this.state.tenantAlreadyExistRecords[0]?.userId,
                "sentEmailId":null,
                "accepted":false,
                "startDate": this.state.propertyLease?.startDate ? this.state.propertyLease?.startDate: null,
                "monthlyPaymentDueDay": this.state.propertyLease?.monthlyPaymentDueDay ? this.state.propertyLease?.monthlyPaymentDueDay: 0,
                "propertyId": this.state.propertyLease?.propertyId ? this.state.propertyLease?.propertyId: null,
                "requireAutoPay":false,
                "charges": this.state.charges
            }

            this.attachTenantProcess(attachTenantParams);
        } else {
            if(this.state.customer?.email) {
                this.checkTenantAlreadyExist(this.state.customer?.email).then(response => {
                    const propertyLeases = response.data;
                    if(propertyLeases?.records.length) {
                        const records = propertyLeases?.records;
                        this.handleTenantAlreadyExist(records);
                    } else {
                        this.saveCustomerProcess();
                    }
                }).catch(error => {
                    this.handleValidation(error);
                }); 
            } else {
                this.saveCustomerProcess();
            }
        }
    }

    /**
     * Show warning modal when creating a tenant without email
     */
    handleSubmitCustomer(event) {
        event.preventDefault();

        if(!this.state.customer['email']) {
            $('#create-tenant-warning').modal('show');
            $('#create-tenant').modal('hide');
            return
        }

        this.saveCustomer()

    }

    /**
     * 
     * Close warning modal and save customer if the "yes" button was clicked
     * 
     * @param {boolean} saveCustomer 
     */
    closeWarningModal(saveCustomer = false) {
        $('#create-tenant-warning').modal('hide');
        $('#create-tenant').modal('show');
        if(saveCustomer)
            this.saveCustomer()
    }

    /**
     * Initialize the search integration customers flow by taking the property identifier value from the selected
     * property and pre-filling the property ID search field.
     */
    initIntegrationCustomers() {

        let propertyId;

        if(this.state.propertyLease.propertyId) {
            propertyId = this.state.propertyLease.propertyId;
        }

        if(this.state.invitation.propertyId) {
            propertyId = this.state.invitation.propertyId;
        }

        if(propertyId) {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    integrationCustomerList: [],
                    integrationSearch: {
                        ...prevState.integrationSearch,
                        propertyId: response.data.propertyIdentifier,
                        firstName: '',
                        lastName: '',
                        phone: '',
                        email: '',
                        tenantId: ''
                    },
                }));

                $('#invite-tenant').modal('hide');
                $('#create-tenant').modal('hide');
                $('#property-lease').modal('hide');
                $('#search-tenant').modal('show');

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Perform a search for customers in the integrated system for selection.
     */
    searchIntegrationCustomers(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationCustomerList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/search`, {
            email: this.state.integrationSearch.email,
            firstName: this.state.integrationSearch.firstName,
            lastName: this.state.integrationSearch.lastName,
            phone: this.state.integrationSearch.phone,
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.integrationSearch.tenantId,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationCustomerList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a customer from the integrated system. Using the data from the integrated system, the fields used to
     * invite or create a tenant will be pre-filled with property lease and customer information.
     *
     * @param data - The customer that has been selected from the integrated system.
     */
    selectIntegrationCustomer(data) {

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: data.externalId,
                unit: data.unit
            },
            customer: {
                ...prevState.customer,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.email
            },
            invitation: {
                ...prevState.invitation,
                accountNumber: data.externalId,
                unit: data.unit,
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                phone: data.email,
            },
        }));

        $('#search-tenant').modal('hide');

        // The previous modal is stored in the state
        $('#' + this.state.activeModal).modal('show');
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            charges: [...prevState.charges, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.charges;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            charges: charges,
        }));
    }

    /**
     * Import charges and amounts from the integrated system.
     */
    importCharges() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/tenant/recurringcharges`, {
            propertyId: this.state.integrationSearch.propertyId,
            tenantId: this.state.invitation.accountNumber || this.state.propertyLease.accountNumber,
            integrationId: this.state.integrationId
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    // customChargeCodes: [...prevState.customChargeCodes, ...response.data],
                    charges: response.data,
                }));
            }

            if(response.data.length === 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.tenants.charges.null'
                        }
                    }]
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangeAccountNumber(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            propertyLease: {
                ...prevState.propertyLease,
                accountNumber: event.target.value
            },
            invitation: {
                ...prevState.invitation,
                accountNumber: event.target.value
            }
        }));
    }

    /**
     * Order the currently displayed leases according to the selected property, when attaching a new lease.
     * The selected property leases should be bubbled to the top.
     */
    orderExistingLeases() {
        const tenantAlreadyExistRecordsArr = this.state.tenantAlreadyExistRecords;

        let selectedPropertyVal = null;

        if(this.state.activeModal === "invite-modal") {
            selectedPropertyVal = this.state.invitation?.selectedProperty?.label;
        } else {
            selectedPropertyVal = this.state.propertyLease?.selectedProperty?.label;
        }

        if(selectedPropertyVal) {
            const topArr = [];
            const bottomArr = [];
            tenantAlreadyExistRecordsArr.forEach(record => {
                const prop = record?.joins?.p;
                const {formatMessage} = this.props.intl;
                const province = prop.country === 'CA' ? formatMessage({id: "province." + prop.province}) : formatMessage({id: "state." + prop.province});
                const country = formatMessage({id: "country." + prop.country})
                
                if(selectedPropertyVal.includes(prop.street1) && selectedPropertyVal.includes(prop.city) && selectedPropertyVal.includes(province) && selectedPropertyVal.includes(prop.postalCode) && selectedPropertyVal.includes(country)) {
                    topArr.push(record);
                } else {
                    bottomArr.push(record);
                }
            });

            this.setState(prevState => ({
                ...prevState,
                tenantAlreadyExistRecords: [...topArr, ...bottomArr],
            }));
        }
    }

    /**
     * Handle changes to the property field.
     *
     * @param selectedOption - The selected property.
     */
    handleChangeProperty(selectedOption) {

        let companyId;

        let property = this.state.propertyList.find(property => property.id === selectedOption.value);
        companyId = property.companyId;

        this.setState(prevState => ({
            ...prevState,
            enforceCustomAmount: property.joins?.company?.enforceCustomAmount,
            allowManageCharges: property.joins?.company?.manageBalance === 'YES',
            propertyCountry: property.country,
        }));

        if(selectedOption.value) {
            this.checkPayByTextIsEnabled(TYPE_COMMON.TYPE_PROPERTY, selectedOption.value);
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/fallbackfields`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: {
                    ...prevState.propertyLease,
                    propertyId: selectedOption.value,
                    selectedProperty: {
                        value: selectedOption.value,
                        label: selectedOption.label
                    },
                    companyId: companyId,
                },
                invitation: {
                    ...prevState.invitation,
                    propertyId: selectedOption.value,
                    selectedProperty: {
                        value: selectedOption.value,
                        label: selectedOption.label
                    },
                },
                companyId: companyId,
                integrationId: response.data.integrationId,
                customChargeCodes: response.data.chargeCodes,
            }));


            if(this.state.tenantAlreadyExistRecords?.length){
                this.orderExistingLeases();
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handles the select all button for phone validation
     * actualBtnClick - determines if it was an actual btn click or a function call
     */
    handleSelectAllPropetyLeases(actualBtnClick=true) {
        const selectAllCheckbox = document.getElementById('selectAllLeases');
        const allLeasesCheckboxes = document.querySelectorAll('[data-phonevalidation]');
        
        if(allLeasesCheckboxes.length && selectAllCheckbox?.checked) {
            for (var i = 0; i < allLeasesCheckboxes.length; i++){
                allLeasesCheckboxes[i].checked = true;
                allLeasesCheckboxes[i].disabled = true;
            }
            if(this.props?.companyId) {
                const currentCompanyId = this.props?.companyId;
                
                const subCompanies = this.state.subCompanies;
                const subCompIdsArr = [];

                if(subCompanies) {
                    subCompanies.forEach((subCompany) => {
                        subCompIdsArr.push(subCompany.id);
                    });
                }
            
                this.setState(prevState => ({
                    ...prevState,
                    phoneValidation: {
                        customerIds: [],
                        companyIds: [currentCompanyId, ...subCompIdsArr],
                        propertyLeaseIds: [],
                    }
                }));
            }
        } else if(selectAllCheckbox?.checked === false && actualBtnClick) {
            for (var j = 0; j < allLeasesCheckboxes.length; j++){
                allLeasesCheckboxes[j].checked = false;
                allLeasesCheckboxes[j].disabled = false;
            }
            // the select all button has been unchecked:
            this.setState(prevState => ({
                ...prevState,
                phoneValidation: {
                    ...prevState.phoneValidation,
                    companyIds: [],
                }
            }));
        }
    }

    /** 
     * Fetch all the subcompanies under the parent company
     */
    getAllSubCompanies(parentCompanyId) {
        const query = {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'parentId',
                    operator: 'EQUALS',
                    fieldValue: parentCompanyId,
                }
            ]
        }

        return axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        })
    }

    /**
     * Clear the phone validation select all setting
     */
    clearPhoneValidationSelectAll() {
        const allLeasesCheckboxes = document.querySelectorAll('[data-phonevalidation]');
        const selectAllCheckbox = document.getElementById('selectAllLeases');
        if(selectAllCheckbox) {
            selectAllCheckbox.checked = false; 
        }
        for (var i = 0; i < allLeasesCheckboxes.length; i++){
            allLeasesCheckboxes[i].checked = false;
            allLeasesCheckboxes[i].disabled = false;
        }
        this.setState(prevState => ({
            ...prevState,
            phoneValidation: {
                customerIds: [],
                companyIds: [],
                propertyLeaseIds: [],
            }
        }));
    }

    /**
     * Shows the tenants from all subcompanies in the current tenant view
     */
    handleSelectTenantsFromAllSubCompanies(event) {
        if(this.props.companyParentId) {
            return; //not a parent company
        }

        //unselect all the existing validate checkboxes
        this.clearPhoneValidationSelectAll();

        const {target} = event;
        const parentCompanyId = this.props.companyId;
        
        if(target.checked) {
            
            this.setState(prevState => ({
                ...prevState,
                selectAllLabel: "Select all tenants from company and sub-companies",
            }));
            
            this.getAllSubCompanies(parentCompanyId).then(response => {
                const subCompanies = response.data?.records;
                
                this.setState(prevState => ({
                    ...prevState,
                    subCompanies: subCompanies,
                }));
                
                this.filterPropertyLeases(null, subCompanies);
            }).catch(error => {
                this.handleValidation(error);
            });
            
        } else if(target.checked === false) {
            
            this.setState(prevState => ({
                ...prevState,
                selectAllLabel: "Select all tenants from company",
            }));

            this.setState(prevState => ({
                ...prevState,
                subCompanies: null,
            }), () => {this.filterPropertyLeases(null, '')});
        }
    }

    /**
     * Find out the total number of customers under a company
     */
    getTotalNumberOfCustomers() {

        const conditionList = [];
        let totalRecordCount = 0;

        if (this.props.companyId) {
            conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: (this.state.subCompanies?.length)? '(' : null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        if(this.state.subCompanies) {
            let subCompaniesArr = this.state.subCompanies;
            subCompaniesArr.forEach((subCompany, index) => {
                conditionList.push({
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: (index === subCompaniesArr?.length-1) ? ")" : null,
                    operator: 'EQUALS',
                    fieldName: 'companyId',
                    fieldValue: subCompany.id
                }); 
            });
        } 

        const query = {
            orderBy: 'ASC',
            orderByFields: ['c.firstName'],
            conditionList: conditionList,
            joins: {
                c: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'c',
                    returnFields: ['firstName', 'lastName', 'email', 'phone', 'phoneStatus']
                },
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'country', 'province', 'postalCode']
                }
            }
        };

        // we just want the total number of records from this query
        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=1&page=1`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            totalRecordCount = response.data?.totalRecordCount; 
            // let auxiliar =[] 
            // if(totalRecordCount){
            //     response.data.records.forEach((user) => {
            //         if(user.joins.c.phoneStatus !== 'MOBILE'){
            //             auxiliar.push(user.userId);
            //         }
            //     });
            // }
            // let unique = auxiliar.filter((value, index, self) => self.indexOf(value) === index);
    
            this.setState(prevState=>({
                ...prevState,
                totalNumberOfCustomers: totalRecordCount,
            }))
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Calculate phone validation fees
     * $0.006 * number of records
     */
    calculatePhoneValFees() {
        let fees = 0;
        if(this.state.phoneValidation.companyIds?.length) { 
            const numberOfCustomers = this.state.totalNumberOfCustomers;
            fees = parseFloat(constants.AMAZON_PINPONT_PHONE_VALIDATION_COST) * parseInt(numberOfCustomers);
        } else if(this.state.phoneValidation.customerIds?.length) {
            const numberOfCustomers = this.state.phoneValidation.customerIds.length;
            fees = parseFloat(constants.AMAZON_PINPONT_PHONE_VALIDATION_COST) * parseInt(numberOfCustomers);
        }
        
        return parseFloat(fees).toFixed(3);
    }

    /**
     * Submit the request to validate phone numbers. The validation request need either companyIds or customerIds as a param.
     * If both companyIds and customerIds are present, companyIds are given precedence.
     * @param validationType - The  kind of validation we need. 
     */
    handleValidateTenantPhones(validationType) {
        
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let params = {};

        if(this.state.phoneValidation.companyIds?.length) {
            params.companyIds = this.state.phoneValidation.companyIds;
        } else if (this.state.phoneValidation.customerIds?.length) {
            params.customerIds = this.state.phoneValidation.customerIds
        } else {
            return;
        }

        const endpoint = "validatephonenumbers";

        if(validationType === this.phoneValidationType.MARK_AS_VALID) {
            params.manuallyMarkAsValid = true;
        }

        params = {...params, ...this.addFiltersToTenantOperations()};

        $('#phone-validation').modal('hide');
        $('#mark-phone-valid').modal('hide');

        axios.post(`${constants.REACT_APP_HOST_API_URL}/${endpoint}`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data) {
                let alertCode = 'customer.phone.validated';

                if(response.data?.Message?.length) {
                    if(response.data?.Message[0]?.includes("A batch has been started")) {
                        alertCode = 'customer.phone.batch.started';
                    }
                } 

                if(document.getElementById('selectAllLeases') && document.getElementById('selectAllLeases').checked) {
                    document.getElementById('selectAllLeases').checked = false;
                    const allLeasesCheckboxes = document.querySelectorAll('[data-phonevalidation]');
                    for (var i = 0; i < allLeasesCheckboxes.length; i++){
                        allLeasesCheckboxes[i].checked = false;
                        //allLeasesCheckboxes[i].disabled = false;
                    }
                }

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: alertCode,
                        },
                        values: {
                            email: "",
                        }
                    }],
                    phoneValidation: {
                        customerIds: [],
                        companyIds: [],
                        propertyLeaseIds: [],
                    },
                }));

                this.clearAllCheckboxes();
            }

            setTimeout(() => {
                this.setState({
                    validationList: [],
                });
            }, 8000);

            this.searchPropertyLeases(1, 25, this.state.propertyLeaseQuery);
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Calculate an appropriate status for the mobilePhone to show
     * @param phoneStatus - what kind of phone number it is
     */
    calculatePhoneStatus(phoneStatus) {
        let status = "Unknown";
        let statusIcon = "text-warning";

        if(phoneStatus === "VALID") {
            status = "Valid";
            statusIcon= "text-success";
        } else if(phoneStatus && phoneStatus !== "VALID") {
            status = "Invalid";
            statusIcon= "text-danger";
        }

        return (
          <>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className={`fa-fw small ${statusIcon}`}
            />
            <span className="ml-1 text-break text-wrap">
              {status}
            </span>
          </>
        );
    }

    /**
     * Calculate an appropriate status for the syncStatus to show
     * @param syncStatus - Current sync status
     */
    calculateSyncStatus(syncStatus, notificationProvider) {
        let status = "No";
        let statusIcon = "text-warning";

        if (notificationProvider === 'LETUS') {
            status = "N/A";
        } else if(syncStatus === "COMPLETED") {
            status = "Yes";
            statusIcon= "text-success";
        } 

        return (
          <>
            <FontAwesomeIcon
              icon={["fas", "circle"]}
              className={`fa-fw small ${statusIcon}`}
            />
            <span className="ml-1 text-break text-wrap">
              {status}
            </span>
          </>
        );
    }

    /**
     * Handle select all for a company sync
     */
    syncTenantsAllForCompany() {
        const companyIds = this.state.phoneValidation.companyIds;
        
        const params = {companyIds: companyIds, ...this.addFiltersToTenantOperations()};

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/tenant/resync`,
            params
        , {
            headers: this.generateRequestHeaders()
        }).then((response) => {

            if(response?.data?.result === 'SUCCESS') {
                this.setCustomAlert("primary", "sync.started");
                this.fetchPropertyLease();
            } else {
                this.setCustomAlert("danger", "undefined");
            }
            
            this.setState(prevState => ({
                ...prevState,
                phoneValidation: {
                    customerIds: [],
                    companyIds: [],
                    propertyLeaseIds: [],
                },
            }));
            this.clearAllCheckboxes();
        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * sync Tenants with Everyware
     */
    syncTenants() {

        if(this.state.phoneValidation.companyIds?.length) {
            this.syncTenantsAllForCompany();
        } else if (this.state.phoneValidation.propertyLeaseIds?.length) {
            const propertyLeaseIds = [...this.state.phoneValidation.propertyLeaseIds];

            const params = {propertyLeaseIds: propertyLeaseIds, ...this.addFiltersToTenantOperations()};

            axios.post(`${constants.REACT_APP_HOST_API_URL}/resync/tenants`, params
            , {
                headers: this.generateRequestHeaders()
            }).then((response) => {
                if(response?.data?.result === "One or more tenants that you have selected belongs to a property that has not had an initial tenant sync.  Please perform the full tenant sync initialized under Service Level first."){
                    this.setCustomAlert("danger", "some.no.initial.sync");
                } else{
                    window.scrollTo(0, 0);
                    this.setCustomAlert("primary", "sync.started");
                    this.fetchPropertyLease();
                }
                
                this.setState(prevState => ({
                    ...prevState,
                    phoneValidation: {
                        customerIds: [],
                        companyIds: [],
                        propertyLeaseIds: [],
                    },
                }));

                this.clearAllCheckboxes();
            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Clear all the selected checkboxes
     */
    clearAllCheckboxes() {
        const allLeasesCheckboxes = document.querySelectorAll('[data-phonevalidation]');
        for (var i = 0; i < allLeasesCheckboxes.length; i++){
            allLeasesCheckboxes[i].checked = false;
            allLeasesCheckboxes[i].disabled = false;
        }
    }

    /**
     * Check If the pay by text service is enabled or not
     */
    checkPayByTextIsEnabled(syncFor, id) {

        const headers = {
            headers: this.generateRequestHeaders()
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${syncFor}/${id}/pay-by-text/info`, headers)
        .then(response => {

            this.setState(prevState => ({
                ...prevState,
                payByText: {
                    serviceEnabled: response.data?.serviceEnabled,
                    automaticValidationOnCreate: response.data?.automaticValidationOnCreate,
                }
            }));
        }).catch(error => {
            if(!error.response.data?.message?.includes('service is not configured')) {
                this.handleValidation(error);
                this.handleClearValidationList();
            }
        });
    }

    /**
     * handle various tenant multi-select actions
     */
    handleTenantMultiSelectActions() {
        const type = this.state.tenantMultiSelectActions;
        
        if(type === "SYNC_TENANTS") {
            this.syncTenants();
        } else if(type === "MARK_PHONES_VALID") {
            $('#mark-phone-valid').modal('show');
        } else if(type === "VALIDATE_PHONES") {
            $('#phone-validation').modal('show');
        } else if(type === "SEND_WELCOME_TEXTS") {
            this.handleSendWelcomeTextMessages();
        }
    }

    /**
     * Submit the request to send welcome text messages. The validation request need either companyIds or propertyLeaseIds as a param.
     * If both companyIds and propertyLeaseIds are present, companyIds are given precedence.
     */
    handleSendWelcomeTextMessages() {
        
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let params = {};

        if(this.state.phoneValidation.companyIds?.length) {
            params.companyIds = this.state.phoneValidation.companyIds;
        } else if (this.state.phoneValidation.propertyLeaseIds?.length) {
            params.propertyLeaseIds = this.state.phoneValidation.propertyLeaseIds
        } else {
            return;
        }

        params = {...params, ...this.addFiltersToTenantOperations()};

        const endpoint = "sendwelcometextmessages";

        axios.post(`${constants.REACT_APP_HOST_API_URL}/${endpoint}`, params, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data) {
                let alertCode = 'customer.welcome.msg.sent';
                let alertType = 'primary';
                if(response.data?.Message?.length) {
                    if(response.data?.Message[0]?.includes("A batch has been started")) {
                        alertCode = 'customer.welcome.msg.batch.started';
                    }
                } else if(response.data?.Failures?.length || response.data?.Success?.length === 0 ) {
                    alertCode = 'customer.welcome.msg.error';
                }

                if(document.getElementById('selectAllLeases') && document.getElementById('selectAllLeases').checked) {
                    document.getElementById('selectAllLeases').checked = false;
                    const allLeasesCheckboxes = document.querySelectorAll('[data-phonevalidation]');
                    for (var i = 0; i < allLeasesCheckboxes.length; i++){
                        allLeasesCheckboxes[i].checked = false;
                        allLeasesCheckboxes[i].disabled = false;
                    }
                }

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: alertType,
                            code: alertCode,
                        },
                        values: {
                            email: "",
                        }
                    }],
                    phoneValidation: {
                        customerIds: [],
                        companyIds: [],
                        propertyLeaseIds: [],
                    },
                }));

                this.clearAllCheckboxes();
            }

            setTimeout(() => {
                this.setState({
                    validationList: [],
                });
            }, 8000);
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /*
    *
    * Add a New Bank Account
    */
    addNewBankAccount() {

        $('#bank-account').modal("show"); 
        $("#create-tenant").modal('hide');
    }
    
    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;
        const ordinal = require('ordinal');

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        let propertyLeaseColumnLabels = {'c.firstName': 'Tenant', 'p.propertyName': 'Property Name', unit: 'Unit', 'p.street1': 'Property Address', charges: 'Monthly Charges', monthlyPaymentDueDay: 'Payment Due', status: 'Lease'};
        let propertyLeaseColumnWidths = ['14%', '14%', '14%', '14%', '14%', '14%', '14%'];

        let invitationColumnLabels = {'e.lastSentDate': 'Sent On', firstName: 'Tenant', 'p.propertyName': 'Property Name', unit: 'Unit', 'p.street1': 'Property Address', charges: 'Monthly Charges', accepted: 'Status'};
        let invitationColumnWidths = ['14%', '14%', '14%', '14%', '14%', '14%', '14%'];

        let defaultTextSearchPlaceholder = "Filter by name, phone, email, unit, property, or confirmation ID...";

        if(this.props.context === 'property') {

            propertyLeaseColumnLabels = {'c.firstName': 'Tenant', unit: 'Unit', charges: 'Monthly Charges', monthlyPaymentDueDay: 'Payment Due', status: 'Lease'};
            propertyLeaseColumnWidths = ['20%', '20%', '20%', '20%', '20%'];

            invitationColumnLabels = {'e.lastSentDate': 'Sent On', firstName: 'Tenant', unit: 'Unit', charges: 'Monthly Charges', accepted: 'Status'};
            invitationColumnWidths = ['20%', '20%', '20%', '20%', '20%'];

        }

        if(this.props.userType === 'TYPE_ADMIN') {
            propertyLeaseColumnLabels['c.phoneStatus'] = "Mobile Status";
            //propertyLeaseColumnLabels['syncStatus'] = "Txt2Pay Everyware Sync";
            propertyLeaseColumnLabels['validation'] = "";
            propertyLeaseColumnWidths = ['11%', '12%', '8%', '12%', '10%', '11%', '10%', '10%', '10%', '6%'];

            if(this.props.context === 'property') {
                propertyLeaseColumnWidths = ['14%', '10%', '12%', '14%', '16%', '16%', '10%', '8%'];
            }
        }

        return(
            <React.Fragment>


                <Alert validationList={this.state.validationList} />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList} />
                }
                
                <div className="card accordion position-relative" id="navigation">
                <SkeletonLoader visible={this.state.spinner} skeletonForLoading={this.state.skeletonForLoading}
                displayHeader={this.state.skeletonForLoading}
                bodyRows={this.state.skeletonForLoading ? 10 : undefined} />
                {!this.state.skeletonForLoading && 
                    <>
                        <div className="card-header pb-0">
                            <div className="row align-items-center">
                                <div className="col-8 col-md-8 col-xs-12 pl-md-0 pl-lg-3">
                                    <ul className="nav nav-tabs border-bottom-0">
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.selectedTab === 'TENANTS' ? 'active' : ''}`} id="tenants-tab" data-toggle="tab" href="#tenants" role="tab" aria-controls="tenants" aria-selected="true" onClick={() => this.selectTab('TENANTS')}>
                                                Tenants
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.selectedTab === 'INVITATIONS' ? 'active' : ''}`} id="invitations-tab" data-toggle="tab" href="#invitations" role="tab" aria-controls="invitations" aria-selected="true" onClick={() => this.selectTab('INVITATIONS')}>
                                                Invitations
                                            </a>
                                        </li>
                                        {this.props.userType === 'TYPE_MANAGER' &&
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.selectedTab === 'APPLICATIONS' ? 'active' : ''}`} id="applications-tab" data-toggle="tab" href="#applications" role="tab" aria-controls="applications" aria-selected="true" onClick={() => this.selectTab('APPLICATIONS')}>
                                                Applications
                                            </a>
                                        </li>
                                        }
                                        {(this.props.userType === 'TYPE_MANAGER' || this.props.userType === 'TYPE_ADMIN') &&
                                        <li className="nav-item">
                                            <a className={`nav-link ${this.state.selectedTab === 'SELF_SIGNUP' ? 'active' : ''}`} id="selfsignup-tab" data-toggle="tab" href="#selfsignup" role="tab" aria-controls="applications" aria-selected="true" onClick={() => this.selectTab('SELF_SIGNUP')}>
                                                Self Sign-Up
                                            </a>
                                        </li>
                                        }
                                    </ul>
                                </div>
                                {this.props.userType === 'TYPE_ADMIN' &&   
                                    <div className="col-4 col-md-4 mb-2">
                                        <div className="media">
                                        <div className="media-body align-self-center mr-1">
                                            <FieldSelect id="tenantMultiSelectActions" labelColumns="0"
                                                fieldColumns="12" parent={this}
                                                disabled={(this.state.phoneValidation?.customerIds?.length || this.state.phoneValidation?.companyIds?.length) ? false : true}
                                                value={this.state.tenantMultiSelectActions}>
                                                 {/*<option value="SYNC_TENANTS" >Sync Tenant(s)</option>*/}
                                                    <option value="MARK_PHONES_VALID">Mark phone(s) as valid</option>
                                                    <option value="VALIDATE_PHONES">Validate phone(s)</option>
                                                    <option value="SEND_WELCOME_TEXTS">Send welcome text(s)</option>
                                            </FieldSelect>
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" 
                                                    onClick={() => this.handleTenantMultiSelectActions()} 
                                                    className="btn btn-secondary btn-sm mb-0"
                                                    disabled={(this.state.phoneValidation?.customerIds?.length || this.state.phoneValidation?.companyIds?.length) ? false : true}>
                                                    Go
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                        
                                    
                                    </div>
                                }
                                {(this.props?.showOnboardingSection || (this.props.userType !== "TYPE_MANAGER" && (this.props.createTenant || this.props.inviteTenant))) &&
                                <div className="col text-right">

                                    {(this.props.createTenant && this.props.inviteTenant) &&
                                    <div data-toggle="modal" data-target="#onboard-tenant" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Onboard Tenant
                                    </div>
                                    }

                                    {(this.props.createTenant && !this.props.inviteTenant && !(this.props?.managerRoles?.length === 1 && this.props?.managerRoles?.includes('LEASING_MANAGER'))) &&
                                    <div data-toggle="modal" data-target="#create-tenant" className="btn btn-primary btn-sm" onClick={() => this.initCustomer()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Tenant
                                    </div>
                                    }

                                    {(this.props.inviteTenant && !this.props.createTenant) &&
                                    <React.Fragment>

                                        {!this.props.inviteTenantDisabled &&
                                        <div data-toggle="modal" data-target="#invite-tenant" className="btn btn-primary btn-sm ml-2" onClick={() => this.initInvitation()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Invite Tenant
                                        </div>
                                        }

                                        {this.props.inviteTenantDisabled &&
                                        <div className="btn btn-primary btn-sm ml-2 disabled" data-toggle={this.props.inviteTenantTooltip ? 'tooltip' : ''} data-placement="top" title={this.props.inviteTenantTooltip}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Invite Tenant
                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                                }
                            </div>
                        </div>

                        <div className="tab-content" id="tabs">

                            <div className={`tab-pane fade-show ${this.state.selectedTab === 'TENANTS' ? 'active' : ''}`} id="tenants" role="tabpanel" aria-labelledby="tenants-tab">
                                <div className="card-header gotham border-top bg-secondary">
                                    <form onSubmit={this.filterPropertyLeases}>
                                        <div className="media">
                                            <div className="media-body align-self-center mr-3">
                                                <FieldText id="propertyLeaseSearchFilter" label="Search" labelClass="d-none"
                                                        fieldColumns="12" parent={this}
                                                        labelColums="0" placeholder={this.props.textSearchPlaceholderOverrideTenants ? this.props.textSearchPlaceholderOverrideTenants : defaultTextSearchPlaceholder} 
                                                        value={this.state.propertyLeaseSearchFilter} disabled={this.state.spinner} />
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="propertyLeaseStatusFilter" labelColumns="0"
                                                            fieldColumns="12" parent={this}
                                                            value={this.state.propertyLeaseStatusFilter}>
                                                    <option value="">Any Lease Status</option>
                                                    <option value="ACTIVE">Active</option>
                                                    <option value="SUSPENDED">Suspended</option>
                                                    <option value="TERMINATED">Terminated</option>
                                                </FieldSelect>
                                            </div>
                                            {this.props.userType === 'TYPE_ADMIN' &&
                                                <div className="media-body align-self-center mr-3">
                                                    <FieldSelect id="tenantTypeFilter" labelColumns="0"
                                                                fieldColumns="12" parent={this}
                                                                value={this.state.tenantTypeFilter}>
                                                        <option value="">Letus Tenants</option>
                                                        <option value="APIGATEWAYTENANTS">API Gateway Tenants</option>
                                                    </FieldSelect>
                                                </div>
                                            }
                                            {this.props.userType === 'TYPE_ADMIN' &&
                                                <div className="media-body align-self-center mr-3">
                                                    <FieldSelect id="tenantSyncStatusFilter" labelColumns="0"
                                                                fieldColumns="12" parent={this}
                                                                value={this.state.tenantSyncStatusFilter}>
                                                        <option value="">All Tenants</option>
                                                        {/*<option value="COMPLETED">Synced Tenants</option>*/}
                                                        {/*<option value="NOT_COMPLETED">Non-Synced Tenants</option>*/}
                                                    </FieldSelect>
                                                </div>
                                            }
                                            {this.props.userType === 'TYPE_ADMIN' &&
                                                <div className="media-body align-self-center mr-3">
                                                    <FieldSelect id="tenantMobileStatusFilter" labelColumns="0"
                                                                fieldColumns="12" parent={this}
                                                                value={this.state.tenantMobileStatusFilter}>
                                                        <option value="">All Mobile Status</option>
                                                        <option value="VALID">Valid Mobile Status</option>
                                                        <option value="INVALID">Invalid Mobile Status</option>
                                                        <option value="UNKNOWN">Unknown Mobile Status</option>
                                                    </FieldSelect>
                                                </div>
                                            }
                                            <div className="align-self-center text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    
                                                    <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                    </button>
                                                    <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                        <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {this.props.userType === 'TYPE_ADMIN' && this.props.companyId && this.props.context !== 'property' && !this.props.companyParentId &&
                                        <div className="btn btn-secondary btn-sm mb-0 mt-1 mr-2">
                                            <div className={`align-self-center`}>
                                                <div className={`custom-control custom-checkbox`} onClick={(e)=>this.handleSelectTenantsFromAllSubCompanies(e)}>
                                                    <input type="checkbox" id="selectTenantsFromAll" name="selectTenantsFromAll" value="selectTenantsFromAll" className={`custom-control-input mb-0`}/>
                                                    <label htmlFor="selectTenantsFromAll" className={`custom-control-label pt-1 mb-0 ptx-1`}>Show tenants from sub-companies</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {this.props.userType === 'TYPE_ADMIN' && (this.props.companyId || this.props.context === 'property') && this.state.tenantTypeFilter !== "APIGATEWAYTENANTS" &&
                                        <div className="btn btn-secondary btn-sm mb-0 mt-1">
                                            <div className={`align-self-center`}>
                                                <div className={`custom-control custom-checkbox`} onClick={()=>this.handleSelectAllPropetyLeases()}>
                                                    <input type="checkbox" id="selectAllLeases" name="selectAllLeases" value="selectAllLeases" className={`custom-control-input mb-0`}/>
                                                    <label htmlFor="selectAllLeases" className={`custom-control-label pt-1 mb-0 ptx-1`}>{this.state.selectAllLabel}</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                {this.state.propertyLeaseList.emptyUnfilteredList &&
                                <div className="card-body bg-secondary py-5 border-top">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <div className="fa-stack fa-2x mb-2">
                                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                    <FontAwesomeIcon icon={['far', 'user-friends']} className="fa-stack-1x fa-inverse" />
                                                </div>
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    Tenants making payments with Letus will appear here.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                
                                {!this.state.propertyLeaseList.emptyUnfilteredList &&
                                <Table
                                    columns={propertyLeaseColumnLabels}
                                    columnWidths={propertyLeaseColumnWidths}
                                    headerClass="c-pointer"
                                    data={this.state.propertyLeaseList}
                                    query={this.state.propertyLeaseQuery}
                                    sortEnabled={true}
                                    recordsEnabled={true}
                                    paginationEnabled={true}
                                    parent={this}
                                    updateFunction={this.searchPropertyLeases}
                                    removeSortForColumn={['validation', 'charges']}
                                    tableClass="table-bordered table-hover table-responsive-sm border-bottom border-dark w-100 d-block d-md-table table-layout-fixed"
                                    >
                                    <tbody>
                                    {this.state.propertyLeaseList.records.map((data, key) => {
                                        if(data?.joins?.c) {
                                            return (
                                                <tr key={key} onClick={(e) => this.redirectPropertyLease(data, e)} className="c-pointer">
                                                    <td>
                                                        {data.joins &&
                                                        <div className="">
                                                            {data.joins.c.firstName} {data.joins.c.lastName}
                                                        </div>
                                                        }
                                                    </td>
                                                    {this.props.context !== 'property' &&
                                                        <td>
                                                            {data.joins && data.joins.p &&
                                                            <div className="">
                                                                {data.joins.p?.propertyName}
                                                            </div>
                                                            }
                                                        </td>
                                                    }
                                                    <td>
                                                        <div className="">
                                                            {data.unit}
                                                        </div>
                                                    </td>
                                                    {this.props.context !== 'property' &&
                                                        <td>
                                                            {data.joins &&
                                                            <div className="">
                                                                {data.joins.p?.street1}
                                                            </div>
                                                            }
                                                        </td>
                                                    }
                                                    <td>
                                                        <div className="">
                                                            <FormattedNumber value={data.totalCharges} style={`currency`} currency="USD" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            {data.monthlyPaymentDueDay > 0 ? ordinal(data.monthlyPaymentDueDay) : 'Anytime'} <span className="text-muted text-uppercase small">(Monthly)</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data.status != null &&
                                                        <div className="text-nowrap">
                                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.propertyLease.status." + data.status + ".class"})}`}/>
                                                            <span className="ml-1 text-break text-wrap"><FormattedMessage id={"enum.propertyLease.status." + data.status}/></span>
                                                        </div>
                                                        }
                                                    </td>
                                                    { this.props.userType === 'TYPE_ADMIN' &&
                                                        <>
                                                            <td>
                                                                <div className="">
                                                                    {this.calculatePhoneStatus(data?.joins?.c?.phoneStatus)}
                                                                </div>
                                                            </td>
                                                            {/*
                                                            <td>
                                                                <div className="">
                                                                    {this.calculateSyncStatus(data?.syncStatus, data?.joins?.company?.notificationProvider)}
                                                                </div>
                                                            </td>
                                                            */}
                                                            <td data-checkbox-cell="true">
                                                                <div className={`custom-control custom-checkbox`} data-checkbox-cell="true">
                                                                    <input type="checkbox" id={data?.id} name={data?.id} value={data?.userId} className={`custom-control-input position-relative w-50`} data-phonevalidation="phoneValidation" data-phonestatus={data?.joins?.c?.phoneStatus} 
                                                                    data-checkbox-cell="true" 
                                                                    checked={(this.state.phoneValidation.customerIds.includes(data?.userId)) || (this.state.phoneValidation.companyIds.includes(data?.companyId))}
                                                                    / >
                                                                    <label htmlFor={data?.id} className={`custom-control-label pt-1 text-break`} data-checkbox-cell="true"></label>
                                                                </div>
                                                            </td>
                                                        </>
                                                    }
                                                </tr>
                                            )
                                        }
                                        return null;
                                    })}
                                    </tbody>
                                </Table>
                                }
                            </div>

                            <div className={`tab-pane fade-show ${this.state.selectedTab === 'INVITATIONS' ? 'active' : ''}`} id="invitations" role="tabpanel" aria-labelledby="invitations-tab">

                                <div className="card-header gotham border-top py-3 bg-secondary">
                                    <form onSubmit={this.filterInvitations}>
                                        <div className="media">
                                            <div className="media-body align-self-center mr-3">
                                                <FieldText id="invitationSearchFilter" label="Search" labelClass="d-none"
                                                        fieldColumns="12" parent={this}
                                                        labelColums="0" placeholder={this.props.textSearchPlaceholderOverrideInvitations ? this.props.textSearchPlaceholderOverrideInvitations : defaultTextSearchPlaceholder}
                                                        value={this.state.invitationSearchFilter} disabled={this.state.spinner}/>
                                            </div>
                                            <div className="media-body align-self-center mr-3">
                                                <FieldSelect id="invitationAcceptedFilter" labelColumns="0"
                                                            fieldColumns="12" parent={this}
                                                            value={this.state.invitationAcceptedFilter}>
                                                    <option value={false}>Pending</option>
                                                    <option value={true}>Accepted</option>
                                                </FieldSelect>
                                            </div>
                                            <div className="align-self-center text-right">
                                                <div className="btn-group" role="group" aria-label="Basic example">
                                                    <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                    </button>
                                                    <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                        <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                {this.state.invitationList.emptyUnfilteredList &&
                                <div className="card-body bg-secondary py-5 border-top">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <div className="fa-stack fa-2x mb-2">
                                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                    <FontAwesomeIcon icon={['far', 'user-plus']} className="fa-stack-1x fa-inverse" />
                                                </div>
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    Tenants that have received email invitations to create a Letus account will appear here.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {!this.state.invitationList.emptyUnfilteredList &&
                                <Table columns={invitationColumnLabels}
                                    columnWidths={invitationColumnWidths}
                                    headerClass="c-pointer"
                                    data={this.state.invitationList}
                                    query={this.state.invitationQuery}
                                    sortEnabled={true}
                                    recordsEnabled={true}
                                    paginationEnabled={true}
                                    parent={this}
                                    updateFunction={this.searchInvitations}
                                    removeSortForColumn={'charges'}
                                    tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                                    >
                                    <tbody>
                                    {this.state.invitationList.records.map((data, key) => {
                                        return (
                                            <tr key={key} onClick={() => this.selectInvitation(data)} className="c-pointer">
                                                <td>
                                                    {data.joins && data.joins.e &&
                                                    <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                            {data.joins.e.lastSentDate}
                                                        </Moment>
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.firstName} {data.lastName}
                                                    </div>
                                                </td>
                                                {this.props.context !== 'property' &&
                                                    <td>
                                                        {data.joins && data.joins.p &&
                                                        <div className="">
                                                            {data.joins.p?.propertyName}
                                                        </div>
                                                        }
                                                    </td>
                                                }
                                                <td>
                                                    <div className="">
                                                        {data.unit}
                                                    </div>
                                                </td>
                                                {this.props.context !== 'property' &&
                                                    <td>
                                                        {data.joins && data.joins.p &&
                                                        <div className="">
                                                            {data.joins.p.street1}
                                                        </div>
                                                        }
                                                    </td>
                                                }
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={data.totalCharges} style={`currency`} currency="USD" />
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.accepted != null &&
                                                    <div className="text-nowrap">
                                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${data.accepted ? 'text-success' : 'text-warning'}`}/>
                                                        <span className="ml-1">{data.accepted ? 'Accepted' : 'Pending'}</span>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                                }

                            </div>

                            {
                                this.state.selectedTab === 'APPLICATIONS' &&
                                <div className={`tab-pane fade-show ${this.state.selectedTab === 'APPLICATIONS' ? 'active' : ''}`} id="applications" role="tabpanel" aria-labelledby="applications-tab">
                                    <ApplicationList 
                                        userType={this.props.userType}
                                        userId={this.props.userId}
                                        companyId={this.props.parent.props.userSession.sessionRole.companyId}
                                        history={this.props.history}
                                        parent={this} 
                                        hiddenHeader />
                                </div>
                            }
                            { 
                                this.state.selectedTab === 'SELF_SIGNUP' &&
                                <div className={`tab-pane fade-show ${this.state.selectedTab === 'SELF_SIGNUP' ? 'active' : ''}`} id="selfsignup" role="tabpanel" aria-labelledby="selfsignup-tab">
                                    <SelfSignUpList
                                        managerRoles={this.props?.managerRoles}
                                        userType={this.props.userType}
                                        userId={this.props.userId}
                                        companyId={this.props.companyId}
                                        history={this.props.history}
                                        parent={this} 
                                    />
                                </div>
                            }
                        </div>
                    </>
                }
                </div>

                <div className="modal fade" id="invite-tenant" tabIndex="-1" role="dialog" aria-labelledby="invite-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveInvitation} autoComplete="false">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        {!this.state.invitation.createDate &&
                                        <span className="">Invite Tenants</span>
                                        }
                                        {this.state.invitation.createDate &&
                                        <span className="">Edit Invitation</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                {!this.state.invitation.createDate &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Inviting a tenant will send an email to your tenant with instructions to create their Letus account.
                                    </p>
                                </div>
                                }

                                {this.state.invitation.accepted &&
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        This invitation has already been accepted by the tenant.
                                    </p>
                                </div>
                                }

                                {this.state.propertyList.length > 0 &&
                                <React.Fragment>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger"/>

                                        {this.state.invitation.createDate && this.state.invitation?.joins?.m?.firstName && this.state.invitation?.joins?.m?.lastName &&
                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm">
                                                    Sent By
                                                </label>
                                                <label className="col-sm-9 col-form-label col-form-label-sm">
                                                    {`${lodash.upperFirst(this.state.invitation.joins.m.firstName)} ${lodash.upperFirst(this.state.invitation.joins.m.lastName)}`}
                                                </label>
                                            </div>
                                        }

                                        <FieldProperty id="propertyId" label="Property" model="invitation" value={this.state.invitation.selectedProperty} disabled={this.state.invitation.accepted} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} required={true}/>

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                                Account ID {this.state.companyDetails?.requireAccountId ? <small className= "text-muted font-italic">*</small>: ""}
                                            </label>
                                            <div className="col-sm-9">
                                                <div className="input-group input-group-sm">

                                                    <input id="accountNumber" name="accountNumber" value={this.state.invitation['accountNumber'] || ""} disabled={this.state.invitation.accepted} className="form-control mb-0" required = {this.state.companyDetails?.requireAccountId} onChange={this.handleChangeAccountNumber}/>

                                                    {(this.state.integrationId && !this.state.invitation.accepted) &&
                                                    <div className="input-group-append">
                                                        <div className={`btn btn-secondary ${this.state.invitation.propertyId ? '' : 'disabled'}`} onClick={() => this.initIntegrationCustomers()}>
                                                            Search...
                                                        </div>
                                                    </div>
                                                    }

                                                </div>

                                            </div>
                                        </div>

                                        <FieldText id="unit" label="Unit" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['unit']} />

                                        <FieldDate id="startDate" label="Lease Start Date" model="invitation" required={true} parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['startDate'] || null} />

                                        <FieldDate id="endDate" label="Lease End Date" model="propertyLease" parent={this} value={this.state.invitation['endDate'] || null} before={new Date(this.state.invitation['startDate']) || null}/>

                                        <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['monthlyPaymentDueDay']}>
                                            <option value={0}>No monthly payment due date</option>
                                            <option value={1}>1st day of every month</option>
                                            <option value={2}>2nd day of every month</option>
                                            <option value={3}>3rd day of every month</option>
                                            <option value={4}>4th day of every month</option>
                                            <option value={5}>5th day of every month</option>
                                            <option value={6}>6th day of every month</option>
                                            <option value={7}>7th day of every month</option>
                                            <option value={8}>8th day of every month</option>
                                            <option value={9}>9th day of every month</option>
                                            <option value={10}>10th day of every month</option>
                                            <option value={11}>11th day of every month</option>
                                            <option value={12}>12th day of every month</option>
                                            <option value={13}>13th day of every month</option>
                                            <option value={14}>14th day of every month</option>
                                            <option value={15}>15th day of every month</option>
                                            <option value={16}>16th day of every month</option>
                                            <option value={17}>17th day of every month</option>
                                            <option value={18}>18th day of every month</option>
                                            <option value={19}>19th day of every month</option>
                                            <option value={20}>20th day of every month</option>
                                            <option value={21}>21st day of every month</option>
                                            <option value={22}>22nd day of every month</option>
                                            <option value={23}>23rd day of every month</option>
                                            <option value={24}>24th day of every month</option>
                                            <option value={25}>25th day of every month</option>
                                            <option value={26}>26th day of every month</option>
                                            <option value={27}>27th day of every month</option>
                                            <option value={28}>28th day of every month</option>
                                            <option value={29}>29th day of every month</option>
                                            <option value={30}>30th day of every month</option>
                                            <option value={31}>31st day of every month</option>
                                        </FieldSelect>

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Monthly Charges
                                            </label>
                                            <div className="col-sm-9">
                                                <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.invitation.accountNumber ? this.importCharges : null} size="sm" disabled={this.state.invitation.accepted} parent={this} />
                                            </div>
                                        </div>

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldCheckbox id="requireAutoPay" label="Auto Pay" fieldLabel="Require this tenant to set up automatic monthly payments" model="invitation" disabled={this.state.invitation.accepted} parent={this} value={this.state.invitation['requireAutoPay']} />
                                        }

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldText id="firstName" label="First Name" required={true} model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['firstName']} />
                                        }   
                                        { !this.state.tenantAlreadyExist &&
                                            <FieldText id="lastName" label="Last Name" required={true} model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['lastName']} />
                                        }
                                        <FieldText id="email" label="Email" type="email" model="invitation" parent={this} disabled={this.state.invitation.accepted} value={this.state.invitation['email']} required={true} oninput={this.handleEmailChange} warning={this.state.inviteTenantInsteadObj.inviteTenantInstead?'This email is already in use, please enter a new email.':''}/>
                                        
                                        {this.state.isEmailBeingSearched &&
                                            <div className="loading-small"></div>
                                        }

                                        {
                                            this.state.tenantAlreadyExist && 
                                            <>
                                                <div>
                                                    <p style={{fontSize: '0.875rem'}} className="text-muted-warning">
                                                        This tenant already exists! Attach your lease to this tenant?
                                                    </p>
                                                </div>
                                                {this.state.tenantAlreadyExistRecords?.length &&
                                                    <div className="list-group mb-2">
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action">
                                                            <input type="radio" name="selectedExistingTenantLease" value={JSON.stringify(this.state.tenantAlreadyExistRecords[0])} id="tenant" checked={true} className="custom-control-input"/>
                                                            <label className="custom-control-label-2 pl-3">
                                                                <div className="row align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="">
                                                                            {`${this.state.tenantAlreadyExistRecords[0]?.joins?.c?.firstName} ${this.state.tenantAlreadyExistRecords[0]?.joins?.c?.lastName}`}
                                                                        </div>
                                                                        {this.state.tenantAlreadyExistRecords.map((data, key)=>{
                                                                            return (
                                                                                <div key={key} className={"list-group mb-2 list-group-item " + (data?.propertyId === this.state.invitation.propertyId ? "highlight-border": "")}>
                                                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                                                        <div style={{wordBreak: 'break-word'}}>{`${data?.joins?.c?.email}`}</div>
                                                                                        <div>{`Unit# ${data?.unit}, ${data?.joins?.p?.street1}, ${data?.joins?.p?.propertyName}`}</div>
                                                                                        <div>{`${data?.joins?.p?.city}, ${data?.joins?.p?.country === 'CA' ? formatMessage({id: "province." + data?.joins?.p?.province}) : formatMessage({id: "state." + data?.joins?.p?.province})}`}</div>
                                                                                        <div>{`${formatMessage({id: "country." + data?.joins?.p?.country})}, ${data?.joins?.p?.postalCode}`}</div>
                                                                                    </small>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                <div>
                                                    <FieldCheckbox id="inviteTenantInstead" fieldLabel="Create new tenant instead" model="inviteTenantInsteadObj" parent={this} value={this.state.inviteTenantInsteadObj.inviteTenantInstead} onclick={this.handleInviteTenantInstead} fieldColumns="abs-right"/>
                                                </div>
                                            </>
                                        }
                                        <figcaption className="text-right mt-1">
                                            <small className="text-muted font-italic">* indicates a required field</small>
                                        </figcaption>

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {!this.state.invitation.accepted && this.state.invitation.sentEmailId &&
                                                <div className="btn btn-primary btn-lg" onClick={() => {$("#invite-tenant").modal("hide"); $("#delete-invitation").modal("show");}}>Delete</div>
                                                }

                                                {!this.state.invitation.accepted && this.state.invitation.sentEmailId && !this.state.tenantAlreadyExist &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Resend</button>
                                                }

                                                {!this.state.invitation.accepted && !this.state.invitation.sentEmailId && !this.state.tenantAlreadyExist &&
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Invite Tenant</button>
                                                }

                                                {this.state.tenantAlreadyExist &&
                                                    <button type="submit" className="btn btn-primary btn-lg ml-2">Attach Tenant</button>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </React.Fragment>
                                }

                                {this.state.propertyList.length === 0 &&
                                <React.Fragment>

                                    <div className="modal-body text-center">

                                        <p className="pt-3 pb-1">Let's set up your first rental property.</p>

                                        <Link to="./properties" className="btn btn-primary btn-lg mb-4" onClick={() => $('#invite-tenant').modal('hide')}>
                                            Create Property
                                        </Link>

                                    </div>

                                </React.Fragment>
                                }

                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-invitation" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Invitation" body="Are you sure you would like to delete this invitation?">
                    <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#invite-tenant").modal("show")}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button className="btn btn-danger btn-lg" data-dismiss="modal" onClick={() => this.deleteInvitation(this.state.invitation)}>
                        Delete Invitation
                    </button>
                </Modal>

                <div className="modal fade" id="create-tenant" tabIndex="-1" role="dialog" aria-labelledby="create-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.handleSubmitCustomer}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Create Tenant
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Creating a tenant allows you to set up your tenant's LetUs account instantly on their behalf.
                                    </p>
                                </div>

                                {this.state.propertyList.length > 0 &&
                                <React.Fragment>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger"/>

                                        <FieldProperty id="propertyId" label="Property" model="propertyLease" value={this.state.propertyLease.selectedProperty} parent={this} options={this.state.propertyList} handleChange={this.handleChangeProperty} required={true} />

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="accountNumber">
                                                Account ID {this.state.companyDetails?.requireAccountId ? <small className= "text-muted font-italic">*</small>: ""}
                                            </label>
                                            <div className="col-sm-9">
                                                <div className="input-group input-group-sm">

                                                    <input id="accountNumber" name="accountNumber" value={this.state.propertyLease['accountNumber'] || ""} className="form-control mb-0" required = {this.state.companyDetails?.requireAccountId} onChange={this.handleChangeAccountNumber}/>

                                                    {this.state.integrationId &&
                                                    <div className="input-group-append">
                                                        <div className={`btn btn-secondary ${this.state.propertyLease.propertyId ? '' : 'disabled'}`} onClick={() => {this.initIntegrationCustomers()}}>
                                                            Search...
                                                        </div>
                                                    </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <FieldText id="unit" label="Unit" model="propertyLease" parent={this} value={this.state.propertyLease['unit']} />

                                        <FieldDate id="startDate" label="Lease Start Date" model="propertyLease" parent={this} value={this.state.propertyLease['startDate'] || null} required={true} />

                                        <FieldDate id="endDate" label="Lease End Date" model="propertyLease" parent={this} value={this.state.propertyLease['endDate'] || null} before={new Date(this.state.propertyLease['startDate']) || null}/>

                                        <FieldSelect id="monthlyPaymentDueDay" label="Payment Due" model="propertyLease" parent={this} value={this.state.propertyLease['monthlyPaymentDueDay']}>
                                            <option value={0}>No monthly payment due date</option>
                                            <option value={1}>1st day of every month</option>
                                            <option value={2}>2nd day of every month</option>
                                            <option value={3}>3rd day of every month</option>
                                            <option value={4}>4th day of every month</option>
                                            <option value={5}>5th day of every month</option>
                                            <option value={6}>6th day of every month</option>
                                            <option value={7}>7th day of every month</option>
                                            <option value={8}>8th day of every month</option>
                                            <option value={9}>9th day of every month</option>
                                            <option value={10}>10th day of every month</option>
                                            <option value={11}>11th day of every month</option>
                                            <option value={12}>12th day of every month</option>
                                            <option value={13}>13th day of every month</option>
                                            <option value={14}>14th day of every month</option>
                                            <option value={15}>15th day of every month</option>
                                            <option value={16}>16th day of every month</option>
                                            <option value={17}>17th day of every month</option>
                                            <option value={18}>18th day of every month</option>
                                            <option value={19}>19th day of every month</option>
                                            <option value={20}>20th day of every month</option>
                                            <option value={21}>21st day of every month</option>
                                            <option value={22}>22nd day of every month</option>
                                            <option value={23}>23rd day of every month</option>
                                            <option value={24}>24th day of every month</option>
                                            <option value={25}>25th day of every month</option>
                                            <option value={26}>26th day of every month</option>
                                            <option value={27}>27th day of every month</option>
                                            <option value={28}>28th day of every month</option>
                                            <option value={29}>29th day of every month</option>
                                            <option value={30}>30th day of every month</option>
                                            <option value={31}>31st day of every month</option>
                                        </FieldSelect>

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Monthly Charges
                                            </label>
                                            <div className="col-sm-9">
                                                <FieldCharges customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null} charges={this.state.charges} addCharge={this.addCharge} removeCharge={this.removeCharge} importCharges={this.state.integrationId && this.state.propertyLease.accountNumber ? this.importCharges : null} size="sm" parent={this} />
                                            </div>
                                        </div>

                                        <FieldCheckbox id="autoPay" name="autoPay" label="Auto Pay" fieldLabel="Set up automatic monthly payments for this Tenant" model="propertyLease" parent={this} value={this.state.propertyLease['autoPay']} />
                                        
                                        { this.state.propertyLease['autoPay'] &&
                                        <React.Fragment>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label col-form-label-sm">
                                                Bank Account
                                            </label>
                                            <div className="col-sm-9">
                                                <div className="list-group">
                                                    <div className={`list-group-item list-group-item-action c-pointer ${!this.state.propertyLease.selectedProperty ? 'disabled' : ''}`} onClick={() => this.addNewBankAccount()}>
                                                        <div className="row align-items-center">
                                                            <div className="col-8 col-form-label col-form-label-sm">
                                                                {typeof (this.state.paymentMethod?.bankAccountNumber) === 'undefined' || this.state.validateBankAccountData !== 'SUCCESS' || this.state.paymentMethod?.bankAccountNumber === '' ?
                                                                <React.Fragment>
                                                                Add New Bank Account
                                                                </React.Fragment>
                                                                :
                                                                <React.Fragment>
                                                                Account number ending in {(this.state.paymentMethod?.bankAccountNumber).slice(-4)}
                                                                </React.Fragment>
                                                                }
                                                            </div>
                                                            <div className="col text-right">
                                                                <div className="float-right">
                                                                    <img src={bankAccount} className="rounded border w-75" alt="Add New Bank Account"/>
                                                                </div>
                                                            </div>
                                                            {(typeof (this.state.paymentMethod?.bankAccountNumber) !== 'undefined' && this.state.validateBankAccountData === 'SUCCESS') && this.state.paymentMethod?.bankAccountNumber !== '' &&
                                                            <div className="text-right">
                                                                <div className="float-right" title="Remove Bank Account" onClick={(event) => this.removeBankAccountData(event)}>
                                                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-1" />
                                                                </div>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <FieldDate id="recurringStartDate" 
                                        placeholder="Start Date" 
                                        label="Start Date" 
                                        before={new Date()}
                                        parent={this} 
                                        help="The tenant will be charged every month starting on your selected date." 
                                        value={this.state.recurringStartDate} required>
                                        </FieldDate>
                                        
                                        {this.state.companyDetails?.manageBalance === 'YES' &&
                                        !this.state.propertyLease?.blockPartialPayments &&
                                        this.state.enforceCustomAmount &&
                                         <>
                                            <FieldText 
                                                id="customAmount"
                                                label="Custom Amount"
                                                labelClass="col-form-label col-form-label-sm"
                                                iconType="fas"
                                                iconName="dollar-sign"
                                                fieldClass="form-control-md"
                                                fieldColumns="9"
                                                labelColumns="3"
                                                type="number"
                                                min="1.00"
                                                max="999999.99"
                                                step="0.01"
                                                pattern="[0-9]+(\.[0-9][0-9]?)?"
                                                parent={this}
                                                required={this.state.enforceCustomAmount}
                                                model="recurringSchedule"
                                                value={(this.state.recurringScheduleCustomAmt?.customAmtEnabled || this.state.enforceCustomAmount) ? this.state.recurringSchedule?.customAmount : null}
                                                disabled={!this.state.recurringScheduleCustomAmt?.customAmtEnabled && !this.state.enforceCustomAmount}
                                                help="Letus will always take this amount to process the auto payment, irrespective of the balance."
                                                warning={this.state.enforceCustomAmount && "You must specify a custom monthly amount in order to save the autopayment."}
                                            />
                                        </>
                                        }
                                         </React.Fragment>
                                        }

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldText id="firstName" label="First Name" model="customer" parent={this} value={this.state.customer['firstName']} required={true} />
                                        }

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldText id="lastName" label="Last Name" model="customer" parent={this} value={this.state.customer['lastName']} required={true} />
                                        }

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldPhone id="phone" label="Mobile Phone" model="customer" parent={this} value={this.state.customer['phone']} required={this.state.payByText.serviceEnabled} handleChange={this.handlePhoneChange}/>
                                        }

                                        <FieldText id="email" label="Email" type="email" model="customer" parent={this} value={this.state.customer['email']} oninput={this.handleEmailChange} warning={this.state.inviteTenantInsteadObj.inviteTenantInstead?'This email is already in use, please enter a new email.':''} />

                                         {/* UNSAFE - ROLLBACK AFTER TESTING FOR LP-1876 IS COMPLETE */}
                                        { (this.state?.companyId === "e4cd6620-1358-4859-8c17-f185099ea9b8" || this.state?.companyId === "dd3f11d0-eaae-4d01-b6f5-88983cd8db3e") &&
                                            <FieldText id="userIdentifier" label="User Identifier" model="customer" parent={this} value={this.state.customer['userIdentifier']} />
                                        }

                                        { !this.state.tenantAlreadyExist &&
                                            <FieldCheckbox id="muteNotification" name="muteNotification" fieldLabel="Send welcome email to tenant" model="customer" parent={this} value={!this.state.customer['muteNotification']} handleChange={this.handleMuteNotificationsChange} fieldColumns="abs-left CreateTenantFormLeftPadding"/>
                                        }

                                        {this.state.payByText.serviceEnabled && (this.state.payByText.automaticValidationOnCreate !== "OFF") &&
                                            <FieldCheckbox id="sendWelcomeText" name="sendWelcomeText" fieldLabel="Send Txt2Pay welcome to tenant" model="propertyLease" parent={this} value={this.state.propertyLease['sendWelcomeText']} handleChange={this.handleSendWelcomeText} fieldColumns="abs-left CreateTenantFormLeftPadding"/>
                                        }

                                        {this.state.isEmailBeingSearched &&
                                            <div className="loading-small"></div>
                                        }

                                        {
                                            this.state.tenantAlreadyExist && 
                                            <>
                                                <div>
                                                    <p style={{fontSize: '0.875rem'}} className="text-muted-warning">
                                                        This tenant already exists! Attach your lease to this tenant?
                                                    </p>
                                                </div>
                                                {this.state.tenantAlreadyExistRecords?.length &&
                                                    <div className="list-group mb-2">
                                                        <div className="custom-control custom-radio list-group-item list-group-item-action">
                                                            <input type="radio" name="selectedExistingTenantLease" value={JSON.stringify(this.state.tenantAlreadyExistRecords[0])} id="tenant" checked={true} className="custom-control-input"/>
                                                            <label className="custom-control-label-2 pl-3">
                                                                <div className="row align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="">
                                                                            {`${this.state.tenantAlreadyExistRecords[0]?.joins?.c?.firstName} ${this.state.tenantAlreadyExistRecords[0]?.joins?.c?.lastName}`}
                                                                        </div>
                                                                        {this.state.tenantAlreadyExistRecords.map((data, key)=>{
                                                                            return (
                                                                                <div key={key} className={"list-group mb-2 list-group-item " + (data?.propertyId === this.state.propertyLease.propertyId ? "highlight-border": "")}>
                                                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                                                        <div style={{wordBreak: 'break-word'}}>{`${data?.joins?.c?.email}`}</div>
                                                                                        <div>{`Unit# ${data?.unit}, ${data?.joins?.p?.street1}, ${data?.joins?.p?.propertyName}`}</div>
                                                                                        <div>{`${data?.joins?.p?.city}, ${data?.joins?.p?.country === 'CA' ? formatMessage({id: "province." + data?.joins?.p?.province}) : formatMessage({id: "state." + data?.joins?.p?.province})}`}</div>
                                                                                        <div>{`${formatMessage({id: "country." + data?.joins?.p?.country})}, ${data?.joins?.p?.postalCode}`}</div>
                                                                                    </small>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                <div>
                                                    <FieldCheckbox id="inviteTenantInstead" fieldLabel="Create new tenant instead" model="inviteTenantInsteadObj" parent={this} value={this.state.inviteTenantInsteadObj.inviteTenantInstead} onclick={this.handleInviteTenantInstead} fieldColumns="abs-right"/>
                                                </div>
                                            </>
                                        }

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                            </div>
                                            {!this.state.tenantAlreadyExist &&
                                                <div className="col-8 text-right">
                                                    <button type="submit" className="btn btn-primary btn-lg ml-2">Create Tenant</button>
                                                </div>
                                            }
                                            {this.state.tenantAlreadyExist &&
                                                <div className="col-8 text-right">
                                                    <button type="submit" className="btn btn-primary btn-lg ml-2">Attach Tenant</button>
                                                </div>
                                            }
                                        </div>

                                    </div>

                                </React.Fragment>
                                }

                                {this.state.propertyList.length === 0 &&
                                <React.Fragment>

                                    <div className="modal-body text-center">

                                        <p className="pt-3 pb-1">Let's set up your first rental property.</p>

                                        <Link to="./properties" className="btn btn-primary btn-lg mb-4" onClick={() => $('#create-tenant').modal('hide')}>
                                            Create Property
                                        </Link>

                                    </div>

                                </React.Fragment>
                                }

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="bank-account" tabIndex="-1" role="dialog" aria-labelledby="bank-account-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.preSavePaymentMethod}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="bank-account-label">
                                        Add New Bank Account
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        <small>
                                            Upon confirming a payment or when your scheduled payment date arrives, you will be charged immediately, but it takes up to 5 business days for the funds to be withdrawn from your bank account (your property manager is aware of this). Please ensure sufficient funds are in your account prior to the payment being debited.
                                        </small>
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />
                                   
                                    <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['country']} propertyCountry={this.state.propertyCountry} isForTenantBankAcc = {true}/>

                                    {this.state.paymentMethod['country'] &&
                                    <React.Fragment>

                                        {this.state.paymentMethod['country'] === 'CA' &&
                                        <React.Fragment>

                                            <FieldText id="bankAccountInstitutionNumber" label="Institution Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountInstitutionNumber']} />

                                            <FieldText id="bankAccountTransitNumber" label="Transit Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountTransitNumber']} />

                                        </React.Fragment>
                                        }

                                        {this.state.paymentMethod['country'] === 'US' &&
                                        <React.Fragment>

                                            <FieldText id="routingNumber" label="Routing Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['routingNumber']} />

                                        </React.Fragment>
                                        }

                                        <FieldText id="bankAccountNumber" label="Account Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['bankAccountNumber']} />

                                        <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                        {!this.state.showBillingAddressForm &&
                                            <FieldSelect id="billingAddressId" label="Billing Address" required={true}
                                                        model="paymentMethod" fieldColumns="12" labelColumns="12"
                                                        fieldClass="form-control-md" parent={this}
                                                        value={this.state.paymentMethod['billingAddressId']}
                                                        handleChange={this.handleChangeBillingAddress}>

                                                <option value=""></option>
                                                <option value="NEW">Add new billing address...</option>

                                            </FieldSelect>
                                        }

                                        {this.state.showBillingAddressForm &&
                                        <div className="mb-2">

                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} parent={this} value={this.state.billingAddress} required countryRequired/>

                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                                <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#bank-account").modal("hide"); $("#create-tenant").modal('show');} }>Back</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="search-tenant" tabIndex="-1" role="dialog" aria-labelledby="search-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.searchIntegrationCustomers}>

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="search-tenant-label">
                                        Search Tenants
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Select the tenant from your integrated system using the available search fields below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="tenantId" label="Account ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['tenantId']} />

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">or</span>
                                    </div>

                                    <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} disabled={true} value={this.state.integrationSearch['propertyId']} />

                                    <FieldText id="firstName" label="First Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['firstName']} />

                                    <FieldText id="lastName" label="Last Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['lastName']} />

                                    <FieldText id="email" label="Email" type="email" model="integrationSearch" parent={this} value={this.state.integrationSearch['email']} />

                                    <FieldText id="phone" label="Phone" type="tel" model="integrationSearch" parent={this} value={this.state.integrationSearch['phone']} />

                                </div>

                                {this.state.integrationCustomerList.length > 0 &&
                                <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                    <div className="card-body card-body-table">
                                        <table className="table table-bordered table-hover border">
                                            <thead>
                                            <tr>
                                                <th width="25%">First Name</th>
                                                <th width="25%">Last Name</th>
                                                <th width="25%">Unit</th>
                                                <th width="25%">Account ID</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.integrationCustomerList.map((data, key) => {
                                                return (
                                                    <tr key={key} onClick={() => this.selectIntegrationCustomer(data)} className="c-pointer">
                                                        <td className="">
                                                            {data.firstName}
                                                        </td>
                                                        <td className="">
                                                            {data.lastName}
                                                        </td>
                                                        <td className="">
                                                            {data.unit}
                                                        </td>
                                                        <td className="">
                                                            {data.externalId}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                }

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-6 text-left">
                                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#' + this.state.activeModal).modal('show')}>Back</button>
                                        </div>
                                        <div className="col-6 text-right">
                                            <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="onboard-tenant" tabIndex="-1" role="dialog" aria-labelledby="onboard-tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title" id="onboard-tenant-label">
                                    Onboard Tenant
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div className="row justify-content-center">

                                    <div className="col-md-6 text-center mb-4 mb-sm-0">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Invite a Tenant
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Inviting a tenant will send an email to your tenant, guiding them to creating their Letus account.
                                            </p>
                                            <div data-toggle="modal" data-target="#invite-tenant" className="btn btn-primary btn-md mb-3" onClick={() => this.initInvitation()}>
                                                Invite Tenant
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 text-center mb-4 mb-sm-0 border-left">
                                        <div className="px-3">
                                            <h5 className="display-5 mb-4">
                                                Create a Tenant
                                            </h5>
                                            <FontAwesomeIcon icon={['fal', 'user-plus']} className="fa-fw mb-4 text-primary" size="3x" />
                                            <p className="mb-4">
                                                Creating a tenant allows you to set up your tenant's Letus account instantly on their behalf.
                                            </p>

                                            {this.props?.managerRoles?.length === 1 && this.props?.managerRoles?.includes('LEASING_MANAGER') &&
                                                <div data-toggle="modal" data-target="#create-tenant" className="btn btn-primary btn-sm ml-2 disabled">
                                                    Create Tenant
                                                </div>
                                            }
                                            {!(this.props?.managerRoles?.length === 1 && this.props?.managerRoles?.includes('LEASING_MANAGER')) &&
                                                <div data-toggle="modal" data-target="#create-tenant" className="btn btn-primary btn-md mb-3" onClick={() => this.initCustomer()}>
                                                    Create Tenant
                                                </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="modal fade" id="phone-validation" tabIndex="-1" role="dialog" aria-labelledby="phone-validation-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="">
                                <FontAwesomeIcon icon={["fas", "user-shield"]} className="fa-fw va-b mb-3" size="4x" />
                            </div>
                            <h5 className="modal-title" id={`phone-validation-label`}>
                                Validate Tenant Phone Number
                            </h5>
                        </div>
                        <div className="modal-body px-4 pt-4 pb-5">
                                <span className="text-wrap h5">
                                    Are you sure you want to incur fees of upto ${this.calculatePhoneValFees()} for phone number verification?
                                </span>
                        </div>
                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                            <div className="float-left">
                                <ButtonClose/>
                            </div>
                            <div className="float-right">
                                <div className="dropdown">
                                    <button type="button" className="btn btn-primary btn-lg" onClick={()=>this.handleValidateTenantPhones("VALIDATE")}>Validate</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="mark-phone-valid" tabIndex="-1" role="dialog" aria-labelledby="mark-phone-valid-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="">
                                <FontAwesomeIcon icon={["fas", "user-shield"]} className="fa-fw va-b mb-3" size="4x" />
                            </div>
                            <h5 className="modal-title" id={`mark-phone-valid-label`}>
                                Mark Tenant Mobile Number As Valid
                            </h5>
                        </div>
                        <div className="modal-body px-4 pt-4 pb-5">
                                <span className="text-wrap h5">
                                    Are you sure you want to mark the selected tenant(s) mobile number(s) as valid?
                                </span>
                        </div>
                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                            <div className="float-left">
                                <ButtonClose/>
                            </div>
                            <div className="float-right">
                                <div className="dropdown">
                                    <button type="button" className="btn btn-primary btn-lg" onClick={()=>this.handleValidateTenantPhones(this.phoneValidationType.MARK_AS_VALID)}>Mark As Valid</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>

                <div className="modal fade" id="create-tenant-warning" tabIndex="-1" role="dialog" aria-labelledby="create-tenant-warning-label" aria-hidden="false" data-keyboard="false" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                                <h5 className="modal-title" id={`create-tenant-warning-label`}>
                                    Warning!
                                </h5>
                            </div>
                            <div className="modal-body px-4 pt-4 pb-5">
                                <span className="text-wrap h5">
                                    This tenant account will be created without an email address. Do you still want to proceed?
                                </span>
                            </div>

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <div className="float-left">
                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => this.closeWarningModal(false)}>No</button>
                                </div>
                                <div className="float-right">
                                    <div className="dropdown">
                                        <button type="button" className="btn btn-primary btn-lg" onClick={() => this.closeWarningModal(true)}>Yes</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);