import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const VerificationCode = ({ code }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, []);

  return (
    <div className='card'>
      <div className='card-header'>Verification Code Is Dynamic And Changes Once Per Day</div>
      <div className='card-body'>
        <h3>
            {code}
        </h3>
        <div className='input-group-append'>
          <CopyToClipboard text={code} onCopy={handleCopy}>
            <button
              className='btn btn-primary btn-md'
              type='button'
              data-toggle='tooltip'
              data-placement='top'
            >
              <FontAwesomeIcon icon={['far', copied ? 'check' : 'copy']} className='fa-fw' />
              <span>{copied ? 'Copied' : 'Copy'}</span>
            </button>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

VerificationCode.propTypes = {
  code: PropTypes.number.isRequired,
};

export default VerificationCode;
