import React from 'react';
import Table from "./Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import FieldCheckbox from "./FieldCheckbox";

const ManagerPermissionsTable = React.memo(() => {
    const tableData = {
        page: 1,
        recordsPerPage: 11,
        totalRecordCount: 11,
        records: [
        {
          description: "Recurring Payments (add/edit recurring payments).",
          primaryManager: true,
          supportManager: true,
          propertyManager: true,
          financeManager: false,
          leasingManager: false,
        },
        {
          description: "Property (edit/add/delete property).",
          primaryManager: true,
          supportManager: false,
          propertyManager: false,
          financeManager: false,
          leasingManager: false,
        },
        {
          description: "Manager (change manager permissions).",
          primaryManager: true,
          supportManager: false,
          propertyManager: false,
          financeManager: false,
          leasingManager: false,
        },
        {
          description: "Transaction (issue refund).",
          primaryManager: true,
          supportManager: true,
          propertyManager: false,
          financeManager: true,
          leasingManager: false,
        },
        {
          description: "User (edit/approve tenants/make payments on behalf of tenants).",
          primaryManager: true,
          supportManager: true,
          propertyManager: true,
          financeManager: false,
          leasingManager: false,
        },
        {
          description: "User (invite/manage invitations on behalf of tenants).",
          primaryManager: true,
          supportManager: true,
          propertyManager: true,
          financeManager: false,
          leasingManager: true,
        },
        {
          description: "Application (share application link).",
          primaryManager: true,
          supportManager: true,
          propertyManager: true,
          financeManager: false,
          leasingManager: true,
        },
        {
          description: "Application (share application link/approve application/edit application).",
          primaryManager: true,
          supportManager: false,
          propertyManager: false,
          financeManager: false,
          leasingManager: false,
        },
    ]};
    return (
        <Table columns={{description: 'Permissions', primaryManager: 'Primary Manager', supportManager: 'Support Manager', propertyManager: 'Property Manager', financeManager: 'Finance Manager', leasingManager: 'Leasing Manager'}}
                columnWidths={['50%', '10%', '10%', '10%', '10%', '10%']}
                headerClass="c-pointer"
                data={tableData}
                hideFooter
        >
            <tbody>
            {tableData.records.map((data, key) => {
                return (
                    <tr key={key}>
                        <td>
                            <div className="">
                            {data.description}
                            </div>
                        </td>
                        <td>
                            <div className="">
                            {data.primaryManager && (
                              <FontAwesomeIcon icon={['far', 'check']} className="fa-fw va-b" />
                            )}
                            </div>
                        </td>
                        <td>
                            <div className="">
                            {data.supportManager && (
                              <FontAwesomeIcon icon={['far', 'check']} className="fa-fw va-b" />
                            )}
                            </div>
                        </td>
                        <td>
                            <div className="">
                            {data.propertyManager && (
                              <FontAwesomeIcon icon={['far', 'check']} className="fa-fw va-b" />
                            )}
                            </div>
                        </td>
                        <td>
                            <div className="">
                            {data.financeManager && (
                              <FontAwesomeIcon icon={['far', 'check']} className="fa-fw va-b" />
                            )}
                            </div>
                        </td>
                        <td>
                            <div className="">
                            {data.leasingManager && (
                              <FontAwesomeIcon icon={['far', 'check']} className="fa-fw va-b" />
                            )}
                            </div>
                        </td>
                    </tr>
                )
            })}
            </tbody>
        </Table>
    )

})

const SelecteManagerRoleComponent = ({
  roles, parent
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-sm-3">
          <label className="col-form-label-sm mr-1">Role</label>
          <FontAwesomeIcon icon={['far', 'info-circle']} className="c-pointer fa-fw va-b" onClick={() => $('#info-modal').modal('show')} />
        </div>
        <div className="col-sm-9 row pr-0">
          <div className="col-sm-6 mb-3">
            <FieldCheckbox
              id="PRIMARY_MANAGER"
              fieldLabel="Primary Manager"
              model="roles"
              parent={parent}
              value={roles["PRIMARY_MANAGER"]}
            />

            <FieldCheckbox
              id="SUPPORT_MANAGER"
              fieldLabel="Support Manager"
              model="roles"
              parent={parent}
              value={roles["PRIMARY_MANAGER"] ? false : roles["SUPPORT_MANAGER"]}
              disabled={roles["PRIMARY_MANAGER"]}
            />

            <FieldCheckbox
              id="LEASING_MANAGER"
              fieldLabel="Leasing Manager"
              model="roles"
              parent={parent}
              value={roles["PRIMARY_MANAGER"] ? false : roles["LEASING_MANAGER"]}
              disabled={roles["PRIMARY_MANAGER"]}
            />
          </div>
          <div className="col-sm-6 mb-3">
            <FieldCheckbox
              id="PROPERTY_MANAGER"
              fieldLabel="Property Manager"
              model="roles"
              parent={parent}
              value={roles["PRIMARY_MANAGER"] ? false : roles["PROPERTY_MANAGER"]}
              disabled={roles["PRIMARY_MANAGER"]}
            />

            <FieldCheckbox
              id="FINANCE_MANAGER"
              fieldLabel="Finance Manager"
              model="roles"
              parent={parent}
              value={roles["PRIMARY_MANAGER"] ? false : roles["FINANCE_MANAGER"]}
              disabled={roles["PRIMARY_MANAGER"]}
            />
          </div>
        </div>
      </div>
      <div className="modal fade" id="info-modal" tabIndex="-1" role="dialog" aria-labelledby="info-modal-label" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content shadow">
            <div className="modal-header bg-dark text-white">
              <h5 className="modal-title" id="info-modal">
                <span className="">
                  Manager Permissions
                </span>
              </h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
              </button>
            </div>
            <ManagerPermissionsTable />
            <div className="m-4">
              <button type="button" className="float-right btn btn-outline-primary btn-lg" onClick={() => $("#info-modal").modal("hide")} aria-label="Close">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelecteManagerRoleComponent;