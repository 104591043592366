import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from 'axios';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import womanChair from "../media/img/backgrounds/woman-chair.jpg";
import womanOffice from "../media/img/backgrounds/woman-office.jpg";
import * as constants from "../util/constants";
// import Alert from "./common/Alert";
// import FieldPassword from "./common/FieldPassword";
// import GoogleLogin from "react-google-login";
// import google from "../media/img/logos/google.png";
// import FieldText from "./common/FieldText";
import Letus from "./common/Propertii";
import Spinner from "./common/Spinner";

class Landlords extends Letus {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: {},
            firstName: '',
            lastName: '',
            email: '',
            birthDate: '',
            username: '',
            password: '',
            confirmPassword: '',
            validationList: [],
        };

        this.handleValidation = this.handleValidation.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleOAuth = this.handleOAuth.bind(this);
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        this.setState({
            spinner: false,
        });

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;

        // Handle duplicate email validation errors
        if(error.response.data.errorCode === 'RM_ERROR_RECORD_ALREADY_EXIST') {
            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: error.response.data.errorCode + (recordType ? ('.TYPE_LANDLORD') : ''),
                        message: error.response.data.message
                    },
                    fields
                }],
            });
            return;
        }

        this.setState({
            spinner: false,
            validationList: [{
                alert: {
                    type: 'danger',
                    code: error.response.data.errorCode,
                    message: error.response.data.message
                },
                fields
            }],
        });
    }

    /**
     * Handle landlords signing up via OAuth. Signing up via OAuth allows the user to skip the email verification step
     * and proceed directly to their application form.
     *
     * @param response - The returned OAuth response.
     */
    handleOAuth(response) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}&userType=TYPE_LANDLORD`).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));

            this.props.refreshAppState();

            let token = localStorage.getItem('access_token');
            let sessionRole;

            if(token !== null) {

                let base64Url = token.split('.')[1];
                let base64 = base64Url.replace('-', '+').replace('_', '/');
                let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
                sessionRole = JSON.parse(userSession.sessionRole);
                sessionStorage.setItem('session_role_id', sessionRole.id);
                localStorage.setItem('status', sessionRole.applicationStatus);
            }

            this.props.history.push(this.handleLoginRedirect(sessionRole));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle sign up form submission. If successful, a message will be displayed, prompting the user to check their
     * email inbox to confirm their email address.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/new`).then(response => {

            let landlord = response.data;

            landlord.firstName = this.state.firstName;
            landlord.lastName = this.state.lastName;
            landlord.email = this.state.email;

            axios.post(`${constants.REACT_APP_HOST_API_URL}/signup`, {
                user: {
                    ...landlord,
                    applicationStatus: "PENDING",
                    birthDate: this.state.birthDate,
                },
                username: this.state.email,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                }));

                this.props.history.push({
                    pathname: `/verify`,
                    state: {
                        email: this.state.email,
                    }
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The standard user sign up form.
     */
    render() {
        const language = this.props.intl.locale === 'fr' ? '-fr': '';
        // const {formatMessage} = this.props.intl;

        let ScrollLink = Scroll.Link;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.1) 100%) 0% 0% / cover, url('${womanOffice}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-8 align-self-center">

                                <h1 className="text-white mb-4 display-4">
                                    <FormattedMessage
                                        id='landlord.header.title.property-solution'
                                        defaultMessage='The all-in-one property management solution'
                                    />
                                </h1>
                                <p className="lead text-white">
                                    <FormattedMessage
                                        id='landlord.header.subtitle.simplify-portfolio'
                                        defaultMessage='No matter the size of your portfolio, Letus simplifies every aspect of your property management duties with one simple platform.'
                                    />
                                </p>

                            </div>
                            <div className="col-md-4">

                                {/* <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                    <div className="container">

                                        <form onSubmit={this.handleSubmit} autoComplete="off">

                                            <h3 className="text-center">
                                                Let's get started
                                            </h3>

                                            <hr className="my-4"/>

                                            <Alert validationList={this.state.validationList} />

                                            <div className="row">
                                                <div className="col-6">

                                                    <FieldText id="firstName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                               fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.firstname"})}
                                                               parent={this} value={this.state['firstName']} maxLength="100" autoFocus={true} />

                                                </div>
                                                <div className="col-6">

                                                    <FieldText id="lastName" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                               fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.lastname"})}
                                                               parent={this} value={this.state['lastName']} maxLength="100" />

                                                </div>
                                            </div>

                                            <FieldText id="email" type="email" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                       fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.email"})}
                                                       parent={this} value={this.state['email']} maxLength="100" />

                                            <FieldPassword id="password" type="password" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                           fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.password"})}
                                                           parent={this} value={this.state['password']} dataPlacement="right" maxLength="100"
                                                           dataHtml={true} dataContent={
                                                `<span className="font-weight-bold">Your password must:</span>
                                               <ul>
                                                    <li>Be at least 8 characters long</li>
                                                    <li>Contain at least one lowercase character</li>
                                                    <li>Contain at least one uppercase character</li>
                                                    <li>Contain at least one number</li>
                                               </ul>`
                                            } />

                                            <FieldText id="confirmPassword" type="password" required={true} labelClass="d-none mb-0" fieldClass="form-control-lg mb-0"
                                                       fieldColumns="12" labelColums="0" placeholder={formatMessage({ id: "field.password.confirm"})}
                                                       parent={this} value={this.state['confirmPassword']} maxLength="100" />

                                            <div className="row">
                                                <div className="col text-right">
                                                    <button type="submit" className="btn btn-lg btn-primary btn-block mb-0">
                                                        Create an Account
                                                    </button>
                                                </div>
                                            </div> */}

                                            {/* <div className="divider">
                                                <span className="small text-muted font-italic text-uppercase">or</span>
                                            </div>

                                            <div className="row">
                                                <div className="col text-right">
                                                    <GoogleLogin
                                                        clientId={constants.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                                        render={renderProps => (
                                                            <div className="btn btn-md btn-block btn-outline-primary btn-google" onClick={renderProps.onClick}>
                                                                <img src={google} alt="Log In with Google" style={{height: '15px'}} className="m-1" /> Create Account with Google
                                                            </div>
                                                        )}
                                                        onSuccess={this.handleOAuth}
                                                        onFailure={this.handleOAuth}
                                                        responseType="code"
                                                    />
                                                </div>
                                            </div> */}

                                        {/* </form>

                                    </div>

                                </div> */}

                                {/* <div className="row justify-content-sm-center">
                                    <div className="col-md-10 text-center">
                                        <p className="text-white text-center small">
                                            Already have an account? <Link to="/login">Log in now</Link>.
                                        </p>
                                    </div>
                                </div> */}

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            <FormattedMessage
                                id='landlord.body.sec-1.title.all-bases'
                                defaultMessage='Letus has all your bases covered'
                            />
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-1.title.applications'
                                        defaultMessage='Applications'
                                    />
                                </h3>
                                <p className="">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-1.description.applications'
                                        defaultMessage='Accept lease applications with ease using a sharable link or personalized invitations.'
                                    />
                                </p>
                                <ScrollLink to="applications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        <FormattedMessage
                                            id='common.button.learn-more'
                                            defaultMessage='Learn more'
                                        />
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'search']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-2.title.screenings'
                                        defaultMessage='Screenings'
                                    />
                                </h3>
                                <p className="">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-2.description.screenings'
                                        defaultMessage='Secure background and credit checks ensure better tenants and reduced turnover.'
                                    />
                                </p>
                                <ScrollLink to="screenings" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        <FormattedMessage
                                            id='common.button.learn-more'
                                            defaultMessage='Learn more'
                                        />
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-3.title.payments'
                                        defaultMessage='Payments'
                                    />
                                </h3>
                                <p className="">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-3.description.payments'
                                        defaultMessage='With flexible payment options and recurring schedules, accepting payments is a breeze.'
                                    />
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        <FormattedMessage
                                            id='common.button.learn-more'
                                            defaultMessage='Learn more'
                                        />
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'building']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-4.title.management'
                                        defaultMessage='Management'
                                    />
                                </h3>
                                <p className="">
                                    <FormattedMessage
                                        id='landlord.body.sec-1.subsec-4.description.management'
                                        defaultMessage='Manage your properties, tenants, business details, and more, all in one convenient location.'
                                    />
                                </p>
                                <ScrollLink to="management" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        <FormattedMessage
                                            id='common.button.learn-more'
                                            defaultMessage='Learn more'
                                        />
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="applications" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                <FormattedMessage
                                    id='landlord.body.sec-2.title.simple-lease'
                                    defaultMessage='Online lease applications made simple'
                                />
                            </h1>
                            <p className="mb-4 lead">
                                <FormattedMessage
                                    id='landlord.body.sec-2.description.letus-help'
                                    defaultMessage='Letus allows you to track inbound lease applications, send out personalized invitations, or share a unique link on your favorite listing service.'
                                />
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'clipboard-user']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-2.footer-1.secure-forms'
                                            defaultMessage='Secure Online Forms'
                                        />
                                    </span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'user-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-2.footer-2.customized-invitations'
                                            defaultMessage='Customized Invitations'
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={require(`../media/img/mockups/application${language}.png`)} alt="Streamline your tenancy application process with Letus' intuitive online lease application forms" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="screenings" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                <FormattedMessage
                                    id='landlord.body.sec-3.title.comprehensive-checks'
                                    defaultMessage='Comprehensive background and credit checks, on-demand'
                                />
                            </h1>
                            <p className="mb-4 lead">
                                <FormattedMessage
                                    id='landlord.body.sec-3.description.better-tenants'
                                    defaultMessage="Letus' tenant screening tools can perform in-depth checks on your applicants, so you can rest easy knowing you're finding better tenants."
                                />
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'clock']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-3.footer-1.faster-screenings'
                                            defaultMessage='Faster Screenings'
                                        />
                                    </span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'user-friends']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-3.footer-2.reduce-turnover'
                                            defaultMessage='Reduced Tenant Turnover'
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={require(`../media/img/mockups/tenant-screening${language}.png`)} alt="Open the door to better tenants and reduce tenant turnover with Letus' tenant screening tools" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                <FormattedMessage
                                    id='landlord.body.sec-4.title.simple-secure'
                                    defaultMessage='Simple and secure online rent payments'
                                />
                            </h1>
                            <p className="mb-4 lead">
                                <FormattedMessage
                                    id='landlord.body.sec-4.description.collect-online'
                                    defaultMessage="Letus enables you to collect rent online without the hassle of cash, cheques, or e-transfers, with payments deposited directly into your bank account."
                                />
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-4.footer-1.convenient-payment'
                                            defaultMessage='Convenient Payment Methods'
                                        />
                                    </span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'grin-beam']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-4.footer-2.tenant-satisfaction'
                                            defaultMessage='Higher Tenant Satisfaction'
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={require(`../media/img/mockups/payment-methods${language}.png`)} alt="Offer your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="management" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                <FormattedMessage
                                    id='landlord.body.sec-5.title.smarter-management'
                                    defaultMessage='Smarter property management at your fingertips'
                                />
                            </h1>
                            <p className="mb-4 lead">
                                <FormattedMessage
                                    id='landlord.body.sec-5.description.variety-tools'
                                    defaultMessage='With a variety of tools and insights to manage your properties and paying tenants alike, your Letus dashboard replaces the pen and paper of the past.'
                                />
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'window-restore']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-5.footer-1.multiple-portfolios'
                                            defaultMessage='Manage Multiple Portfolios'
                                        />
                                    </span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'shield-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">
                                        <FormattedMessage
                                            id='landlord.body.sec-5.footer-2.secure-platform'
                                            defaultMessage='Secure Web-Based Platform'
                                        />
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={require(`../media/img/mockups/edit-recurring${language}.png`)} alt="Letus is your central hub when it comes to all things property management" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            <FormattedMessage
                                id='common.title.better-rental'
                                defaultMessage='A better rental experience begins with Letus'
                            />
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    <FormattedMessage
                                        id='common.description.trusted-america'
                                        defaultMessage="Trusted by North America's biggest property managers, now available to you. Start by creating your Letus account today."
                                    />
                                </p>
                            </div>
                        </div>
                        <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            <FormattedMessage
                                id='landlord.body.sec-6.action-1.create-account'
                                defaultMessage='Create an Account'
                            />
                        </ScrollLink>
                        <Link to="/pricing" className="btn btn-lg btn-secondary mt-3 mb-4 px-4 mx-2">
                            <FormattedMessage
                                id='landlord.body.sec-6.action-2.view-pricing'
                                defaultMessage='View Pricing'
                            />
                        </Link>
                    </div>
                </div>

            </div>
        );
    }
}

Landlords.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landlords);
