import axios from "axios";
import lodash from "lodash";
import React from 'react';
import { injectIntl } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldCheckbox from "../../common/FieldCheckbox";
import FieldSelect from "../../common/FieldSelect";
import Propertii from "../../common/Propertii";

class Merchants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            property: {},

            customMerchantAccounts: false,
            creditMerchantAccountList: {},
            walletMerchantAccountList: {},
            paymentProcessor: null,

            validationList: [],

        };

        this.saveProperty = this.saveProperty.bind(this);
        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
        this.handleChangeApplePayMerchantAccount = this.handleChangeApplePayMerchantAccount.bind(this);
        this.handleChangeGooglePayMerchantAccount = this.handleChangeGooglePayMerchantAccount.bind(this);
        this.isLinkedToAnotherCompany = this.isLinkedToAnotherCompany.bind(this);
    }

    /**
     * Fetch a new instance of a property object on the mounting of the component.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.props.match.params.propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = response.data.companyId;
            let customMerchantAccounts = true;
            const paymentProviderId = response.data?.paymentProviderId;
            
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT', null, paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_PAY_PAL');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,'TYPE_WALLET', 'APPLE_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_WALLET', 'GOOGLE_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId, null, null, paymentProviderId);

            if(response.data.bankMerchantAccountId === null
                && response.data.paypalMerchantAccountId === null
                && response.data.padMerchantAccountId === null
                && response.data.cashMerchantAccountId === null
                && response.data.splitItMerchantAccountId === null
                && Object.keys(response.data.creditMerchantAccountList).length === 0
                && Object.keys(response.data.walletMerchantAccountList).length === 0) {
                customMerchantAccounts = false;
            }

            this.setState(prevState => ({
                ...prevState,
                property: response.data,
                creditMerchantAccountList: response.data.creditMerchantAccountList,
                walletMerchantAccountList: response.data.walletMerchantAccountList,
                paymentProcessor: response.data?.paymentProviderId,
                customMerchantAccounts: customMerchantAccounts
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        let property = this.state.property;

        property.creditMerchantAccountList = this.state.creditMerchantAccountList;
        property.walletMerchantAccountList = this.state.walletMerchantAccountList;

        Object.entries(property.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.creditMerchantAccountList[key];
            }
        });

        Object.entries(property.walletMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.walletMerchantAccountList[key];
            }
        });

        if(!this.state.customMerchantAccounts) {
            property.creditMerchantAccountList = null;
            property.bankMerchantAccountId = null;
            property.paypalMerchantAccountId = null;
            property.cashMerchantAccountId = null;
            property.padMerchantAccountId = null;
            property.splitItMerchantAccountId = null;
            property.walletMerchantAccountList = null;
        }
        
        const paymentProcessor = this.state.paymentProcessor;
        property.rmProviderSummary.providerMap[paymentProcessor] = {
            creditMerchantAccountList:  lodash.cloneDeep(property.creditMerchantAccountList),
            bankMerchantAccountId:  lodash.cloneDeep(property.bankMerchantAccountId),
            padMerchantAccountId:  lodash.cloneDeep(property.padMerchantAccountId),
            splitItMerchantAccountId: lodash.cloneDeep(property.splitItMerchantAccountId),
            walletMerchantAccountList:  lodash.cloneDeep(property.walletMerchantAccountList),
        }

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Apple Pay merchant account field. By selecting a merchant account from this field.
     *
     * @param event - The event container.
     */
    handleChangeApplePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let applePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['APPLE_PAY'] = applePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Google Pay merchant account field. By selecting a merchant account from this field.
     *
     * @param event - The event container.
     */
    handleChangeGooglePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let googlePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['GOOGLE_PAY'] = googlePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Google Pay merchant account field. By selecting a merchant account from this field.
     *
     * @param type - The payment types.
     * @param value - The merchant account id.
     */
    isLinkedToAnotherCompany(type) {

        if(!type){

            return false;
        }

        if(type === 'visaMerchantAccounts'){

            if(!this.state.creditMerchantAccountList['VISA']){

                return false;
            }

            if(!this.state.visaMerchantAccounts){

                return true;
            }

            Object.entries(this.state.visaMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.creditMerchantAccountList['VISA']) {
                    return false;
                }
            });

        } else if(type === 'unionPayMerchantAccounts'){

            if(!this.state.creditMerchantAccountList['UNION_PAY']){

                return false;
            }

            if(!this.state.unionPayMerchantAccounts){

                return true;
            }

            Object.entries(this.state.unionPayMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.creditMerchantAccountList['UNION_PAY']) {
                    return false;
                }
            });

        } else if(type === 'bankMerchantAccounts'){

            if(!this.state.property['bankMerchantAccountId']){

                return false;
            }

            if(!this.state.bankMerchantAccounts){

                return true;
            }

            Object.entries(this.state.bankMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.property['bankMerchantAccountId']) {
                    return false;
                }
            });

        } else if (type === 'paypalMerchantAccounts'){

            if(!this.state.property['paypalMerchantAccountId']){

                return false;
            }

            if(!this.state.paypalMerchantAccounts){

                return true;
            }

            Object.entries(this.state.paypalMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.property['paypalMerchantAccountId']) {
                    return false;
                }
            });

        } else if (type === 'cashMerchantAccounts'){

            if(!this.state.property['cashMerchantAccountId']){

                return false;
            }

            if(!this.state.cashMerchantAccounts){

                return true;
            }

            Object.entries(this.state.cashMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.property['cashMerchantAccountId']) {
                    return false;
                }
            });

        } else if (type === 'padMerchantAccounts'){

            if(!this.state.property['padMerchantAccountId']){

                return false;
            }

            if(!this.state.padMerchantAccountId){

                return true;
            }

            Object.entries(this.state.padMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.property['padMerchantAccountId']) {
                    return false;
                }
            });

        } else if (type === 'applePayMerchantAccounts'){

            if(!this.state.walletMerchantAccountList['APPLE_PAY']){

                return false;
            }

            if(!this.state.applePayMerchantAccounts){

                return true;
            }

            Object.entries(this.state.applePayMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.walletMerchantAccountList['APPLE_PAY']) {
                    return false;
                }
            });

        } else if (type === 'googlePayMerchantAccounts'){

            if(!this.state.walletMerchantAccountList['GOOGLE_PAY']){

                return false;
            }

            if(!this.state.googlePayMerchantAccounts){

                return true;
            }

            Object.entries(this.state.googlePayMerchantAccounts).forEach(([key, data]) => {
                if(data['id'] === this.state.walletMerchantAccountList['GOOGLE_PAY']) {
                    return false;
                }
            });

        } else {

            return false;
        }

        return true;
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit property address component for managers.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveProperty}>

                    <div className="card">
                        <div className="card-header">
                            Merchant Accounts
                        </div>
                        <div className="card-body">

                            <FieldCheckbox id="customMerchantAccounts" label="Merchant Accounts" fieldLabel="This property utilizes custom merchant accounts" parent={this} value={this.state.customMerchantAccounts} />

                            {this.state.customMerchantAccounts &&
                            <div className="">

                                {this.state.property.creditMerchantAccountList &&
                                    <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                        {this.state.visaMerchantAccounts &&
                                        <React.Fragment>
                                            {this.state.visaMerchantAccounts.map((data, key) => {
                                                if(data.id === data.joins.c.creditMerchantAccountList['VISA']) {
                                                    return (<option key={key} value={this.state.creditMerchantAccountList['VISA'] === undefined || this.state.creditMerchantAccountList['VISA'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                } else {
                                                    return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                }
                                            })}
                                            <option value="">-</option>
                                        </React.Fragment>
                                        }
                                        {this.isLinkedToAnotherCompany('visaMerchantAccounts') &&
                                            <React.Fragment>
                                                <option value={this.state.creditMerchantAccountList['VISA']}>[Linked to merchant account '{this.state.creditMerchantAccountList['VISA']}' of one different company!]</option>
                                            </React.Fragment>
                                        }
                                    </FieldSelect>
                                }
                                <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="property" parent={this} value={this.state.property['bankMerchantAccountId']}>
                                    {this.state.bankMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.bankMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.bankMerchantAccountId) {
                                                return (<option key={key} value={this.state.property['bankMerchantAccountId'] === undefined || this.state.property['bankMerchantAccountId'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                    {this.isLinkedToAnotherCompany('bankMerchantAccounts') &&
                                        <React.Fragment>
                                            <option value={this.state.property['bankMerchantAccountId']}>[Linked to merchant account '{this.state.property['bankMerchantAccountId']}' of one different company!]</option>
                                        </React.Fragment>
                                    }
                                </FieldSelect>

                                {this.state.property.creditMerchantAccountList &&
                                    <FieldSelect id="UNION_PAY" label="UnionPay" model="creditMerchantAccountList" parent={this} value={this.state.creditMerchantAccountList['UNION_PAY']}>
                                        {this.state.unionPayMerchantAccounts &&
                                        <React.Fragment>
                                            {this.state.unionPayMerchantAccounts.map((data, key) => {
                                                if(data.id === data.joins.c.creditMerchantAccountList['UNION_PAY']) {
                                                    return (<option key={key} value={this.state.creditMerchantAccountList['UNION_PAY'] === undefined || this.state.creditMerchantAccountList['UNION_PAY'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                } else {
                                                    return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                }
                                            })}
                                            <option value="">-</option>
                                        </React.Fragment>
                                        }
                                        {this.isLinkedToAnotherCompany('unionPayMerchantAccounts') &&
                                            <React.Fragment>
                                                <option value={this.state.creditMerchantAccountList['UNION_PAY']}>[Linked to merchant account '{this.state.creditMerchantAccountList['UNION_PAY']}' of one different company!]</option>
                                            </React.Fragment>
                                        }
                                    </FieldSelect>
                                }

                                <FieldSelect id="paypalMerchantAccountId" label="PayPal" model="property" parent={this} value={this.state.property['paypalMerchantAccountId']}>
                                    {this.state.paypalMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.paypalMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.paypalMerchantAccountId) {
                                                return (<option key={key} value={this.state.property['paypalMerchantAccountId'] === undefined || this.state.property['paypalMerchantAccountId'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                    {this.isLinkedToAnotherCompany('paypalMerchantAccounts') &&
                                        <React.Fragment>
                                            <option value={this.state.property['paypalMerchantAccountId']}>[Linked to merchant account '{this.state.property['paypalMerchantAccountId']}' of one different company!]</option>
                                        </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="cashMerchantAccountId" label="Cash" model="property" parent={this} value={this.state.property['cashMerchantAccountId']}>
                                    {this.state.cashMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.cashMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.cashMerchantAccountId) {
                                                return (<option key={key} value={this.state.property['cashMerchantAccountId'] === undefined || this.state.property['cashMerchantAccountId'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                    {this.isLinkedToAnotherCompany('cashMerchantAccounts') &&
                                        <React.Fragment>
                                            <option value={this.state.property['cashMerchantAccountId']}>[Linked to merchant account '{this.state.property['cashMerchantAccountId']}' of one different company!]</option>
                                        </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="property" parent={this} value={this.state.property['padMerchantAccountId']}>
                                    {this.state.padMerchantAccounts &&
                                    <React.Fragment>
                                        {this.state.padMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.padMerchantAccountId) {
                                                return (<option key={key} value={this.state.property['padMerchantAccountId'] === undefined || this.state.property['padMerchantAccountId'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                    </React.Fragment>
                                    }
                                    {this.isLinkedToAnotherCompany('padMerchantAccounts') &&
                                        <React.Fragment>
                                            <option value={this.state.property['padMerchantAccountId']}>[Linked to merchant account '{this.state.property['padMerchantAccountId']}' of one different company!]</option>
                                        </React.Fragment>
                                    }
                                </FieldSelect>

                                <FieldSelect id="splitItMerchantAccountId" label="SplitIt" model="property" parent={this} value={this.state.property['splitItMerchantAccountId']}>
                                        {this.state.bankMerchantAccounts &&
                                        <React.Fragment>
                                            {this.state.bankMerchantAccounts.map((data, key) => {
                                            if(data.id === data.joins.c.splitItMerchantAccountId) {
                                                return (<option key={key} value={this.state.property['splitItMerchantAccountId'] === undefined || this.state.property['splitItMerchantAccountId'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            } else {
                                                return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                            }
                                        })}
                                        <option value="">-</option>
                                        </React.Fragment>
                                        }
                                </FieldSelect>

                                {this.state.property.walletMerchantAccountList &&
                                    <FieldSelect id="applePayMerchantAccountId" label="Apple Pay" model="applePayMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList['APPLE_PAY']} handleChange={this.handleChangeApplePayMerchantAccount}>
                                        {this.state.applePayMerchantAccounts &&
                                            <React.Fragment>
                                                {this.state.applePayMerchantAccounts.map((data, key) => {
                                                    if(data.id === data.joins.c.walletMerchantAccountList['APPLE_PAY']) {
                                                        return (<option key={key} value={this.state.walletMerchantAccountList['APPLE_PAY'] === undefined || this.state.walletMerchantAccountList['APPLE_PAY'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                    } else {
                                                        return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                    }
                                                })}
                                                <option value="">-</option>
                                            </React.Fragment>
                                        }
                                        {this.isLinkedToAnotherCompany('applePayMerchantAccounts') &&
                                            <React.Fragment>
                                                <option value={this.state.walletMerchantAccountList['APPLE_PAY']}>[Linked to merchant account '{this.state.walletMerchantAccountList['APPLE_PAY']}' of one different company!]</option>
                                            </React.Fragment>
                                        }
                                    </FieldSelect>
                                }

                                {this.state.property.walletMerchantAccountList &&
                                    <FieldSelect id="googlePayMerchantAccountId" label="Google Pay" model="googlePayMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList['GOOGLE_PAY']} handleChange={this.handleChangeGooglePayMerchantAccount}>
                                        {this.state.googlePayMerchantAccounts &&
                                            <React.Fragment>
                                                {this.state.googlePayMerchantAccounts.map((data, key) => {
                                                    if(data.id === data.joins.c.walletMerchantAccountList['GOOGLE_PAY']) {
                                                        return (<option key={key} value={this.state.walletMerchantAccountList['GOOGLE_PAY'] === undefined || this.state.walletMerchantAccountList['GOOGLE_PAY'] === null ? "" : data.id}>[DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                    } else {
                                                        return (<option key={key} value={data.id}>[{data.paymentProviderId?.split('_')[0]}] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}</option>);
                                                    }
                                                })}
                                                <option value="">-</option>
                                            </React.Fragment>
                                        }
                                        {this.isLinkedToAnotherCompany('googlePayMerchantAccounts') &&
                                            <React.Fragment>
                                                <option value={this.state.walletMerchantAccountList['GOOGLE_PAY']}>[Linked to merchant account '{this.state.walletMerchantAccountList['GOOGLE_PAY']}' of one different company!]</option>
                                            </React.Fragment>
                                        }
                                    </FieldSelect>
                                }

                            </div>
                            }

                        </div>
                    </div>


                    <div className="row">
                        <div className="col text-right">

                            <ButtonSave />

                        </div>
                    </div>

                </form>

            </React.Fragment>
        )
    };
}

export default injectIntl(Merchants);