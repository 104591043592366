import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import { FormattedNumber, injectIntl } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import ButtonClose from "./ButtonClose";
import FieldDate from "./FieldDate";
import FieldSelect from "./FieldSelect";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Table from "./Table";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import {parseDateForPad} from "../../util/dates";
import ReactSelect from "react-select";
import FieldCheckbox from "./FieldCheckbox";

class ReportEfts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            eft: {},

            eftList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            eftTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            eftTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['accountNumber']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    }
                }
            },

            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDate: null,

            parameters: {
                last4: null,
                paymentType: null
            },

            dropdownId: {
                id: null
            },

            selectedPaymentType:{
                paymentType: null
            },

            bankAccountList: [],

            validationList: [],

        };

        this.searchEfts = this.searchEfts.bind(this);
        this.filterEfts = this.filterEfts.bind(this);
        this.searchEftTransactions = this.searchEftTransactions.bind(this);
        this.downloadEfts = this.downloadEfts.bind(this);
        this.viewEft = this.viewEft.bind(this);
        this.searchMerchantAccounts = this.searchMerchantAccounts.bind(this);
        this.transformDataForPDF = this.transformDataForPDF.bind(this);
        this.getHeaderForPDF = this.getHeaderForPDF.bind(this);
        this.handleChangeShowOnlyActiveMerchantAccounts = this.handleChangeShowOnlyActiveMerchantAccounts.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {
        const EFTParams = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };
        this.searchMerchantAccounts();
        this.searchEfts(1, 25, EFTParams);
    }

    /**
     * View the detailed information of an EFT by initializing the EFT view modal.
     *
     * @param eft - The EFT model to view.
     */
    viewEft(eft) {

        let eftTransactionQuery = this.state.eftTransactionQuery;

        eftTransactionQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'fundsTransferId',
                operator: 'EQUALS',
                fieldValue: eft.id
            }
        ];

        this.setState(prevState => ({
            ...prevState,
            eft: eft
        }));

        this.searchEftTransactions(1, 25, eftTransactionQuery);

        $('#eft').modal('show');
    }

    /**
     * Update the data table of EFTs.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param filter - The filters to apply to the search.
     */
    searchEfts(page, recordsPerPage, filter) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_eft?recordsPerPage=${recordsPerPage}&page=${page}`, filter, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                eftList: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Find last4 from the list of merchant accounts based on the id
     */
    findlast4() {
        const bankAccountList = this.state.bankAccountList;
        let last4 = null;
        for(let i=0; i<bankAccountList.length; i++) {
            if(bankAccountList[i].id === this.state.dropdownId.id) {
                last4 = bankAccountList[i].last4;
                break;
            }
        }
        return last4;
    }

    /**
     * Apply the available search filters on the EFT list and perform a new search.
     *
     * @param event - The event container.
     */
    filterEfts(event) {

        if(event != null) {
            event.preventDefault();
        }

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.fromDate != null) {
            filter.startDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.endDate = this.state.endDate;
        }

        // if(this.state.parameters.last4 != null) {
        //     filter.parameters.last4 = this.state.parameters.last4;
        // }


        filter.parameters.last4 = this.findlast4();
        if (this.state.selectedPaymentType.paymentType) {
            if (this.state.selectedPaymentType.paymentType === 'Bank Account') {
                filter.parameters.paymentType = 'TYPE_BANK_ACCOUNT';
            } else if (this.state.selectedPaymentType.paymentType === 'Credit Card') {
                filter.parameters.paymentType = 'TYPE_CREDIT_CARD';
            } else if (this.state.selectedPaymentType.paymentType === 'Cash Payment') {
                filter.parameters.paymentType = 'TYPE_CASH';
            } else if (this.state.selectedPaymentType.paymentType === 'PayPal Account') {
                filter.parameters.paymentType = 'TYPE_PAY_PAL';
            }
        }

        filter.activeMerchantAccount = this.state.showOnlyActiveMerchantAccounts ? 'YES' : 'NO';

        this.searchEfts(1, 25, filter);
    }

    /**
     * Search for a list of all relevant merchant accounts for populating the bank account dropdown filter.
     */
    searchMerchantAccounts() {

        let bankAccountList = [];

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search`, {
            orderBy: 'ASC',
            orderByFields: ['last4'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.parentId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                },
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            response.data.records.forEach((data, key) => {
                if (data.paymentType) {
                    if (data.paymentType === 'TYPE_BANK_ACCOUNT') {
                        data.payment = 'Bank Account';
                    } else if (data.paymentType === 'TYPE_CREDIT_CARD') {
                        data.payment = 'Credit Card';
                    } else if (data.paymentType === 'TYPE_CASH') {
                        data.payment = 'Cash Payment';
                    } else if (data.paymentType === 'TYPE_PAY_PAL') {
                        data.payment = 'PayPal Account';
                    } else if (data.paymentType === 'TYPE_WALLET') {
                        data.payment = 'Wallet Account';
                    }
                }
                
                if (data.paymentProviderId) {
                    if (data.paymentProviderId === 'PAYSAFE_SPLIT_PAY') {
                        data.provider = 'Paysafe Split-Pay';
                    } else if (data.paymentProviderId === 'PAYPAL') {
                        data.provider = 'PayPal';
                    } else if (data.paymentProviderId === 'GEOSWIFT') {
                        data.provider = 'Geoswift';
                    } else if (data.paymentProviderId === 'LOADHUB') {
                        data.provider = 'Loadhub';
                    }
                }
                bankAccountList.push(data);
            });

            this.setState(prevState => ({
                ...prevState,
                bankAccountList: bankAccountList
            }), ()=>{
                this.populateMerchantAccountsDropdown();
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Populate MA dropdown
     */
    populateMerchantAccountsDropdown() {
        const accountDropdownOptions = [{value:null , label: "All Accounts"}];
        let bankAccountList = this.state.bankAccountList;

        if (this.state.showOnlyActiveMerchantAccounts) {
          bankAccountList = bankAccountList.filter(
            (bankAccount) => bankAccount.accountStatus === 'ACTIVE'
          );
        }
        
        if (this.state.selectedPaymentType.paymentType) {
          bankAccountList.forEach((bankAccount) => {
            if (bankAccount.payment === this.state.selectedPaymentType.paymentType) {
              accountDropdownOptions.push(this.formatDropdownOptions(bankAccount));
            }
          });
        } else {
          bankAccountList.forEach((bankAccount) => {
            accountDropdownOptions.push(this.formatDropdownOptions(bankAccount));
          });
        }

        this.setState((prevState) => ({
            ...prevState,
            accountDropdownOptions: accountDropdownOptions,
        }));
    }

    /**
     * Handle change payment method dropdown
     */
    handleChangePaymentMethod(event) {
        event.persist();
        this.setState(prevState => ({
            ...prevState,
            selectedPaymentType: {
                paymentType: event.target?.value
            }
        }), ()=>{
            this.populateMerchantAccountsDropdown();
        }); 
    }

    /**
     * Search for a list of all payment transactions related to the EFT currently being viewed by the user.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchEftTransactions(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                eftTransactionList: response.data,
                eftTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
    downloadEfts(event) {

        event.preventDefault();

        $("#download-format-confirm").modal("show")
    }

    /**
     * Download the EFT report in CSV format. The downloaded file is based on the filters set by the user.
     *
     * @param event - The event container.
     */
    downloadEftsCSV() {

        const params = this.state.parameters;
        if(this.state.dropdownId.id != null) {
            params.last4 = this.findlast4()
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_eft/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: params,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the EFT report in PDF format. The downloaded file is based on the filters set by the user.
     *
     * 
     */
    downloadEftsPDF() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_eft/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape', 'mm', [297, 500]);

            doc.setFontSize(12);
            doc.text('EFTs Report', 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak: false,
                theme: 'grid',
            
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 30 },
                    1: { cellWidth : 30 },
                    2: { cellWidth : 15 },
                    3: { cellWidth : 25 },
                    4: { cellWidth : 20 },
                    5: { cellWidth : 35 },
                    6: { cellWidth : 35 },
                    7: { cellWidth : 25 },
                    8: { cellWidth : 25 },
                    9: { cellWidth : 25 },
                    10: { cellWidth : 25 },
                    11: { cellWidth : 20 },
                    12: { cellWidth : 20 },
                    13: { cellWidth : 20 },
                    14: { cellWidth : 40 },
                    15: { cellWidth : 30 },
                    16: { cellWidth : 25 },
                    17: { cellWidth : 25 },
                }    
            });
            doc.save('EFTs.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }
    
    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
    transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
        
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - The data returned by the endpoint to be converted into PDF.
     */
    getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
        
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
    getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }

    handleSelectAccountNumber(event){
        this.setState(prevState => ({
            ...prevState,
            dropdownId: {id: event.value}
        }));

    }

    formatDropdownOptions(data){
        return {value: data.id, 
            label: (data.payment ? data.payment : "")
            + (data.accountNumber ? " - " + data.accountNumber : "") 
            + (data.provider ? " - " + data.provider : "") 
            + (data.name ? " - " + data.name : "") 
            + (data.accountStatus ? " - " + data.accountStatus : "") 
            + (data.last4 ? " - " + data.last4 : "")}
    }

    /**
     * Toggle between showing only active and all merchant accounts
     * showOnlyActiveMerchantAccounts: true - only display active merchant accounts
     * showOnlyActiveMerchantAccounts: false - display all merchant accounts
     */
    handleChangeShowOnlyActiveMerchantAccounts() {
        const showOnlyActiveMerchantAccounts = this.state.showOnlyActiveMerchantAccounts || false;
        const showOnlyActiveMerchantAccountsNewStatus = !showOnlyActiveMerchantAccounts;
        
        const EFTParams = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };
        EFTParams.activeMerchantAccount = showOnlyActiveMerchantAccountsNewStatus ? 'YES' : 'NO';
        
        this.setState(prevState => ({
            ...prevState,
            showOnlyActiveMerchantAccounts: showOnlyActiveMerchantAccountsNewStatus
        }), () => {
            this.populateMerchantAccountsDropdown();
            this.searchEfts(1, 25, EFTParams);
        });   
    }


    /**
     * Render the component.
     *
     * @returns {*} - The EFT report component.
     */
    render() {
        const customStyles = {

            control: (base, state) => ({
                ...base,
                height: 'auto',
                minHeight: 'auto',
                fontSize: '0.875rem',
                borderRadius: '0.2rem',
                boxShadow: 'none',
                color: '#495057',
                border: state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                borderBottomLeftRadius: state.isFocused ? '0' : '0.2rem',
                borderBottomRightRadius: state.isFocused ? '0' : '0.2rem',
                '&:hover': {
                    border: state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                },
                marginTop: '6px',
                marginBottom: '6px',
                transition: 'all 0.1s ease-in-out',
            }),

            option: (provided, {isSelected} ) => ({
                ...provided,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
                background: isSelected ? '#e9ecef' : 'none',
                color: '#495057',
                '&:hover': {
                    background: '#52c75d',
                    color: '#fff'
                }
            }),

            placeholder: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                color: '#495057',
                textAlign: "left"
            }),

            menu: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                marginTop: '0 ',
                zIndex: '999',
                boxShadow: 'none',
                border: '1px solid #52c75d',
                borderTop: '0',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
                textAlign: "left"
            }),



            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none'
            }),

            dropdownIndicator: (provided, state) => ({
                ...provided,
                position: 'relative',
                top: '-3px',
                color: provided.color,
            }),


        };

        const paymentMethodTypes = new Set();
        this.state.bankAccountList.forEach(bankAccount => {
            paymentMethodTypes.add(bankAccount.payment)
        });

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                EFTs
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadEfts(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterEfts} autoComplete="off">
                            <div className="media">
                                <div className="media-body text-center align-self-center mr-3">
                                    <FieldSelect 
                                        id="paymentType" 
                                        fieldColumns="12" 
                                        labelColumns="0" 
                                        parent={this}
                                        value={this.state.selectedPaymentType.paymentType} 
                                        model="selectedPaymentType"
                                        handleChange={this.handleChangePaymentMethod}>
                                        <option value="">All Payment Methods</option>
                                        {
                                            Array.from(paymentMethodTypes).map((paymentMethodType, key) =>
                                                <option key={paymentMethodType+key} value={paymentMethodType.toString()}> {paymentMethodType}</option>)
                                        }
                                    </FieldSelect>
                                </div>
                                <div className="media-body text-center align-self-center mr-3">
                                    <ReactSelect
                                        options={this.state.accountDropdownOptions}
                                        onChange = {(event)=>this.handleSelectAccountNumber(event)}
                                        styles={customStyles}
                                        placeholder="Search…"
                                         />
                                </div>
                                <div className="media-body align-self-center mr-3">
                                    <FieldDate id="startDate" labelColumns="0"
                                               fieldColumns="12" parent={this}
                                               value={this.state.startDate}
                                               selectsRange={true} startDate={this.state.startDate}
                                               endDate={this.state.endDate}/>
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                            <FieldCheckbox 
                                id="showOnlyActiveMerchantAccounts" 
                                labelClass='text-dark col-form-label-sm'
                                fieldLabel="Display Only Active Merchant Accounts"
                                parent={this} 
                                value={this.state.showOnlyActiveMerchantAccounts}
                                handleChange={this.handleChangeShowOnlyActiveMerchantAccounts}/>
                        </form>
                    </div>
                    <Table tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table"
                           columns={{
                               eftDate: 'EFT Date',
                               amount: 'Amount',
                               last4: 'Bank Account',
                               confirmationId: 'Confirmation ID',
                               eftType: 'EFT Type',
                           }}
                           headerClass=""
                           data={this.state.eftList}
                           filters={{
                               startDate: this.state.startDate,
                               endDate: this.state.endDate,
                               parameters: this.state.parameters
                           }}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchEfts}>
                        <tbody>
                        {this.state.eftList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewEft(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.eftDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.eftDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.amount}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            **** {data.last4}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.confirmationId}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.eftType}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="eft" tabIndex="-1" role="dialog" aria-labelledby="eft-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        EFT
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0 border-bottom">
                                    <tbody>
                                    <tr className="small">
                                        <td className="">
                                            EFT Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.eftDate &&
                                                <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                    {this.state.eft.eftDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Amount
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.amount}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Bank Account
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                **** {this.state.eft.last4}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Confirmation ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.eft.confirmationId}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-body modal-body-table">
                                <Table tableClass="table-bordered table-responsive"
                                       columns={{createDate:'Transaction Date', customerName: 'Name', accountNumber: 'Account ID', confirmationId: 'Confirmation ID', compressedConfirmationId: 'Compressed ID', transactionType: 'Transaction Type', paymentStatus: 'Payment Status', amount: 'Transaction Amount'}}
                                       minColumnWidths={['12%', '12%', '12%', '16%', '12%', '12%', '12%', '12%']}
                                       data={this.state.eftTransactionList}
                                       query={this.state.eftTransactionQuery}
                                       headerClass=""
                                       sortEnabled={false}
                                       recordsEnabled={true}
                                       paginationEnabled={true}
                                       updateFunction={this.searchEftTransactions}>
                                    <tbody>
                                    {this.state.eftTransactionList.records.map((data, key) => {
                                        return(
                                            <tr key={key}>
                                                <td>
                                                    <div className="">
                                                        {data.createDate && (
                                                            <Moment format="YYYY-MM-DD HH:mm">
                                                                {data?.source === 'PAD' && data?.transactionType ==='PAYMENT' ? parseDateForPad(data.createDate) : data.createDate}
                                                            </Moment>
                                                        )}
                                                    </div>
                                                </td>
                                                <td>
                                                    {data.joins && data.joins.c &&
                                                    <div className="">
                                                        {data.joins.c.firstName} {data.joins.c.lastName}
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    {data.joins && data.joins.pl &&
                                                    <div className="">
                                                        {data.joins.pl.accountNumber}
                                                    </div>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.confirmationId}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.compressedConfirmationId}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.transactionType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.status}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        <FormattedNumber value={data.amount} style={`currency`} currency="USD"/>
                                                    </div>
                                                </td>

                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>

                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadEftsCSV()}>
                            CSV Format
                        </button>
                        <button onClick={() => this.downloadEftsPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            PDF Format
                        </button>
                </Modal>

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportEfts);
