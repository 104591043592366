import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class Button extends Component {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text,
      text_id: this.props.id,
    };
  }

  render_text() {
    const text = this.props.text;
    const text_id = this.state.text_id;

    if (text_id != null) {
      return <FormattedMessage id={text_id} />;
    }
    return text;
  }

  /**
   * Render the component.
   *
   * @returns {*} - A generic alert bar that appears above a form in response to the submission of the form.
   */
  render() {
    let self = this;
    // const id = this.state.text_id;
    const type = this.props.type;

    return (
      <button
        type={type}
        disabled={this.props.disabled}
        className={`btn btn-block ${this.props.btnType} ${this.props.btnSize} ${this.props.btnStyles}`}
      >
        {self.render_text()}
        { this.props.spinner &&
          <span className='spinner-border spinner-border-sm ml-2' role='status'>
            <span className='sr-only'>Loading...</span>
          </span>
        }
        
      </button>
    );
  }
}

Button.defaultProps = {
  type: 'submit',
  btnType: 'btn-primary',
  btnSize: 'btn-lg',
  disabled: false,
  spinner: false
};

export default Button;
