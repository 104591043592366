import axios from "axios";
import React from 'react';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldPhone from "../../common/FieldPhone";
import FieldText from "../../common/FieldText";
import Propertii from "../../common/Propertii";

class Profile extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            admin: {},
            validationList: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Fetch the admin's data based on the ID provided in the session.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/${this.props.userSession.sessionRole.id}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                admin: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle the submission of the form.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.admin, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit profile component for administrators.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/account" parentPage="Account" childPage="Edit Profile" />

                    <Alert validationList={this.state.validationList} />

                    <form onSubmit={this.handleSubmit}>
                        <div className="card">
                            <div className="card-header">
                                Contact Details
                            </div>
                            <div className="card-body">

                                <FieldText id="firstName" label="First Name" model="admin" parent={this} disabled={true} value={this.state.admin['firstName']} />

                                <FieldText id="lastName" label="Last Name" model="admin" parent={this} disabled={true} value={this.state.admin['lastName']} />

                                <FieldText id="email" label="Email" model="admin" disabled={true} type="email" parent={this} value={this.state.admin['email']} />

                                <FieldPhone id="phone" label="Phone" model="admin" parent={this} value={this.state.admin['phone']} optional={true} />

                            </div>
                        </div>

                        <div className="row">
                            <div className="col text-right">

                                <ButtonBack path="/admin/account" />
                                <ButtonSave />

                            </div>
                        </div>

                    </form>

                </div>
            </div>
        )
    };
}

export default Profile;