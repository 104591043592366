import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import React from 'react';
import { Line } from "react-chartjs-2";
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from 'react-router-dom';
import { PAYMENT_TRANSACTION_QUERY } from "../../../constants/QueryConstants";
import { getPaymentTransactionById } from "../../../services/LetusApiService";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import JumbotronHelp from "../../common/JumbotronHelp";
import Propertii from "../../common/Propertii";
import ReceiptPaymentTransaction from "../../common/ReceiptPaymentTransaction";
import ReceiptPaymentTransactionActions from "../../common/ReceiptPaymentTransactionActions";
import ReceiptPaymentTransactionActionsModal from "../../common/modals/ReceiptPaymentTransactionActionsModal";
import SkeletonLoader from "../../common/SkeletonLoader";

class Dashboard extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let invitationConditionList = [];

        invitationConditionList.push(
            {
                type: 'BOOLEAN',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'accepted',
                fieldValue: false
            }
        );

        if(this.props.propertyId) {
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'propertyId',
                    fieldValue: this.props.propertyId
                }
            );
        }

        if(this.props.companyId) {
            invitationConditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'p.companyId',
                    fieldValue: this.props.companyId
                }
            );
        }

        this.state = {
            skeletonForLoading: false,
            accountBalance: 0,
            accountPaymentDueDate: '',
            accountLastPayment: 0,

            processingStats: {},

            manageBalance: false,
            showIntegrationAlerts: false,

            paymentTransaction: {},
            parentPaymentTransaction: {},

            currentProcessingAlert: null,

            invitationQuery: {
              orderBy: 'DESC',
              orderByFields: ['e.lastSentDate'],
              conditionList: invitationConditionList,
              joins: {
                  p: {
                      targetRecordType: 'TYPE_PROPERTY',
                      joinField: 'propertyId',
                      alias: 'p',
                      returnFields: ['propertyName', 'street1', 'companyId']
                  },
                  e: {
                      targetRecordType: 'TYPE_EMAIL',
                      joinField: 'sentEmailId',
                      alias: 'e',
                      returnFields: ['lastSentDate']
                  },
                  c: {
                      targetRecordType: 'TYPE_COMPANY',
                      joinField: 'p.companyId',
                      alias: 'c',
                      returnFields: ['id', 'integrationId', 'chargeCodes', 'parentId']
                  },
                  pc: {
                      targetRecordType: 'TYPE_COMPANY',
                      joinField: 'c.parentId',
                      alias: 'pc',
                      returnFields: ['integrationId', 'chargeCodes']
                  },
                  m: {
                      targetRecordType: 'TYPE_MANAGER',
                      joinField: 'userId',
                      alias: 'm',
                      returnFields: ['firstName', 'lastName']
                  }

              },
          },
        };

      this.getAlerts = this.getAlerts.bind(this);
      this.getBillingDetails = this.getBillingDetails.bind(this);

      this.selectOpenCharge = this.selectOpenCharge.bind(this);
      this.selectPaymentTransaction = this.selectPaymentTransaction.bind(this);
      this.selectIntegrationTransaction = this.selectIntegrationTransaction.bind(this);
      this.selectPropertyLease = this.selectPropertyLease.bind(this);
      this.selectProperty = this.selectProperty.bind(this);
      this.searchInvitations = this.searchInvitations.bind(this);
      this.setParentPaymentTransaction = this.setParentPaymentTransaction.bind(this);
      this.getStatsTotal = this.getStatsTotal.bind(this);
      this.getProcessingStats = this.getProcessingStats.bind(this);
    }

    componentDidMount() {
      this.getStatsTotal(true);
      this.getProcessingStats();
    }

    /**
     * Populate the list of transaction statistic totals
     */
    getStatsTotal(isOnPageLoad=false) {
      
      this.setState(prevState => ({
        ...prevState,
        spinner: true,
        skeletonForLoading: isOnPageLoad ? true : false,
      }));

      axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/stats/totals`, {
          headers: this.generateRequestHeaders()
      }).then(response => {

        this.setState(prevState => ({
          ...prevState,
          spinner: false,
          skeletonForLoading: false,
        }));

        // the following need to be optimized as we should not be setting up state in a loop
        Object.entries(response.data).forEach(([stat, count]) => {
            this.setState(prevState => ({
                ...prevState,
                [stat]: {
                    ...prevState[stat],
                    records: [],
                    totalRecordCount: count,
                    recordsShown: 9
                },
            }));
        });

      }).catch(error => {
          this.handleValidation(error);
      });

      if(!isOnPageLoad) {
        this.getAlerts(this.state.currentProcessingAlert, true);
      }
    }

    /**
     * Populate the list of processing stats
     */
    getProcessingStats() {
        
      axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/processing_stats/${new Date().getFullYear()}`, {
          headers: this.generateRequestHeaders()
      }).then(response => {

          this.setState(prevState => ({
              ...prevState,
              processingStats: {
                  ...prevState.processingStats,
                  ...response.data,
              }
          }));

      }).catch(error => {
          this.handleValidation(error);
      });

      axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/processing_stats/${new Date().getFullYear() - 1}`, {
          headers: this.generateRequestHeaders()
      }).then(response => {

          this.setState(prevState => ({
              ...prevState,
              processingStats: {
                  ...prevState.processingStats,
                  ...response.data,
              }
          }));

      }).catch(error => {
          this.handleValidation(error);
      });

      this.getBillingDetails();

      axios.post(
        `${constants.REACT_APP_HOST_API_URL}/company/search`,
        {
          orderBy: "ASC",
          orderByFields: ["createDate"],
          joins: {
            pc: {
                targetRecordType: 'TYPE_COMPANY',
                joinField: 'parentId',
                alias: 'pc',
                returnFields: ['name', 'chargeCodes', 'integrationId']
            },
          }
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        
        this.setState((prevState) => ({
          ...prevState,
          company: response.data?.records[0],
        }));

        if(response.data.records.length > 0) {
        
          const propertiesArr = response.data.records;
          let showIntegrationAlerts = false;
          
          propertiesArr.forEach(property => {
            if(property.manageBalance !== undefined && property.manageBalance === "YES") {
              this.setState((prevState) => ({
                ...prevState,
                manageBalance: true,
              }));
            }

            // Sets the flag to show or not show integration alerts
            if(!showIntegrationAlerts && (property?.integrationId || property?.joins?.pc?.integrationId)) {
              showIntegrationAlerts = true;
            }
          });

          // Update the state
          if(showIntegrationAlerts) {
            this.setState((prevState) => ({
              ...prevState,
              showIntegrationAlerts: showIntegrationAlerts,
            }));
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            manageBalance: false,
          }));
        }
      })
      .catch((error) => {
        this.handleValidation(error);
      });
    }

    /**
     * Fetch billing-related information for the admin property manager such as current account balance, next payment
     * due date, and previous payment amount for display on the dashboard.
     */
    getBillingDetails() {

        axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/search`, {
            orderBy: 'ASC',
            orderByFields: ['dueDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: 'ISSUED'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'rmCompanyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userSession.sessionRole.companyId
                },
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let accountBalance = 0.00;

            if(response.data.records.length > 0) {

                Object.entries(response.data.records).forEach(([key, invoice]) => {
                    accountBalance += parseFloat((invoice.grandTotal));
                });

                this.setState(prevState => ({
                    ...prevState,
                    accountBalance: accountBalance,
                    accountPaymentDueDate: response.data.records[0].dueDate
                }));

            }

        }).catch(error => {
            this.handleValidation(error);
        });

        axios.post(`${constants.REACT_APP_HOST_BILLING_AND_INVOICING_URL}/invoice/search`, {
            orderBy: 'ASC',
            orderByFields: ['updateDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: 'PAID'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'rmCompanyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userSession.sessionRole.companyId
                },
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.records.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    accountLastPayment: response.data.records[0].grandTotal
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of invitations.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchInvitations(page, recordsPerPage, query) {
      const alertType = 'PENDING_INVITATIONS';

      axios.post(`${constants.REACT_APP_HOST_API_URL}/invitation/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
          joins: query.joins
      }, {
          headers: this.generateRequestHeaders()
      }).then(response => {

          let alertRecords = response.data?.records;
          let pendingInvitationAlertRecords = response.data?.records;

          pendingInvitationAlertRecords.forEach((pendingInvitation, key) => {

              let totalCharges = 0;

              pendingInvitation.charges.forEach((data, key) => {
                  totalCharges += data.amount;
              });

              pendingInvitationAlertRecords[key].totalCharges = totalCharges;
          });
          alertRecords = lodash.orderBy(alertRecords, ['updateDate'], ['desc']);
          this.setState(prevState => ({
            spinner: false,
            [alertType]: {
                ...prevState[alertType],
                records: alertRecords,
            },
        }));
          
      }).catch(error => {
          this.handleValidation(error);
      });
  }

    /**
     * Get a list of all alerts of o a particular type, only if the records haven't already been loaded.
     *
     * @param alertType - The alert type to fetch records for.
     */
    getAlerts(alertType, refresh=false) {

      this.setState(prevState => ({
          ...prevState,
          currentProcessingAlert: alertType,
      }));

      if((this.state[alertType].records.length === 0 && this.state[alertType].totalRecordCount !== 0) || refresh) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true,
        }));

          if(alertType === 'PENDING_INVITATIONS') {
            this.searchInvitations(1, 100, this.state.invitationQuery);
            return;
          }

          axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/stats/${alertType}`, {
              headers: this.generateRequestHeaders()
          }).then(response => {

              let alertRecords = response.data[alertType];

              if(alertType === 'PENDING_INVITATIONS') {

                  let pendingInvitationAlertRecords = response.data.PENDING_INVITATIONS;

                  pendingInvitationAlertRecords.forEach((pendingInvitation, key) => {

                      let totalCharges = 0;

                      pendingInvitation.charges.forEach((data, key) => {
                          totalCharges += data.amount;
                      });

                      pendingInvitationAlertRecords[key].totalCharges = totalCharges;
                  });
                  alertRecords = lodash.orderBy(alertRecords, ['updateDate'], ['desc']);
              }

              if(alertType === 'NON_INTEGRATED_ACCOUNTS') {

                  let nonIntegratedAccountAlertRecords = response.data.NON_INTEGRATED_ACCOUNTS;

                  nonIntegratedAccountAlertRecords.forEach((nonIntegratedAccount, key) => {

                      let totalCharges = 0;

                      nonIntegratedAccount.charges.forEach((data, key) => {
                          totalCharges += data.amount;
                      });

                      nonIntegratedAccountAlertRecords[key].totalCharges = totalCharges;
                  });

              }

              this.setState(prevState => ({
                  spinner: false,
                  [alertType]: {
                      ...prevState[alertType],
                      records: alertRecords,
                  },
              }));

          }).catch(error => {
              this.handleValidation(error);
          });

      }
    }

    /**
     * Select an open charge record type from the overdue charges list. Redirects the user to the Statement page for a
     * specific tenant's open charges, where the overdue charge can be found.
     *
     * @param openCharge - The open charge record selected.
     */
    selectOpenCharge(openCharge) {

        this.props.history.push({
            pathname: `/manager/tenants/${openCharge.billingAccountId}/statement`,
        });
    }
  
    /**
     * Sets parent payment transaction of the selected transaction
     * @param {*} paymentTransaction 
     */
    setParentPaymentTransaction(paymentTransaction) {
      getPaymentTransactionById(paymentTransaction.parentId, PAYMENT_TRANSACTION_QUERY).then(response => {
          this.setState(prevState => ({
              ...prevState,
              parentPaymentTransaction: response.data?.records[0]
          }));

      }).catch(error => {
          this.handleValidation(error);
      });
    }

    /**
     * Select a payment transaction record type from the chargebacks or chargeback reversals lists. Redirects the user
     * to the Transactions page with the transaction receipt pre-populated.
     *
     * @param paymentTransaction - The payment transaction record selected.
     */
    selectPaymentTransaction(paymentTransaction) {

      //reset validation list in the case a new payment is viewed
      this.setState(prevState => ({
        ...prevState,
        validationList: [],
      }));

      if(paymentTransaction.parentId) {
          this.setParentPaymentTransaction(paymentTransaction);
      }

      this.setState(prevState => ({
          ...prevState,
          paymentTransaction: {
              ...paymentTransaction
          }
      }));

      $('#payment-transaction-reversals').modal('hide');
      $('#receipt-payment-transaction').modal('show');
    }

    /**
     * Select an integration transaction record type from the failed transaction pushes list. Redirects the user to the
     * Transactions page with the transaction receipt pre-populated.
     *
     * @param integrationTransaction - The integration transaction record selected.
     */
    selectIntegrationTransaction(integrationTransaction) {

        this.setState({
            spinner: true,
            validationList: [],
        });

        let paymentTransaction = {};

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: integrationTransaction.id
                },
            ],
            joins: {
                pl: {
                    targetRecordType: 'TYPE_PROPERTY_LEASE',
                    joinField: 'billingAccountId',
                    alias: 'pl',
                    returnFields: ['propertyId', 'companyId', 'unit']
                },
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1']
                },
                c: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'c',
                    returnFields: ['firstName', 'lastName', 'email']
                },
                co: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'co',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                },
                pc: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'co.parentId',
                    alias: 'pc',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            paymentTransaction = response.data?.records[0];
            paymentTransaction.integration = integrationTransaction;

            if(paymentTransaction.parentId) {
                this.setParentPaymentTransaction(paymentTransaction);
            }

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransaction: {
                    ...paymentTransaction
                }
            }));

            $('#payment-transaction-reversals').modal('hide');
            $('#receipt-payment-transaction').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a lease application record type from the overdue charges list. Redirects the user to the Lease Application
     * page, where the overview of a lease application can be found.
     *
     * @param leaseApplication - The lease application record selected.
     */
    selectLeaseApplication(leaseApplication) {

        this.props.history.push({
            pathname: `/manager/applications/${leaseApplication.id}/view`,
        });
    }

    /**
     * Select an invitation record type from the pending invitations list. Redirects the user to the Tenants page with
     * the invitation modal pre-populated.
     *
     * @param invitation - The invitation record selected.
     */
    selectInvitation(invitation) {

        this.props.history.push({
            pathname: '/manager/tenants',
            state: {
                invitation: invitation
            }
        });
    }

    /**
     * Select a property lease record type from the non-integrated tenants list. Redirects the user to the Tenants page
     * with the tenant's information displayed.
     *
     * @param propertyLease - The property lease record selected.
     */
    selectPropertyLease(propertyLease) {

        this.props.history.push({
            pathname: `/manager/tenants/${propertyLease.id}/edit`,
        });
    }

    /**
     * Select a property record type from the non-integrated properties list. Redirects the user to the Properties page
     * with the property's information displayed.
     *
     * @param property - The property record selected.
     */
    selectProperty(property) {

        this.props.history.push({
            pathname: `/manager/properties/${property.id}/edit`,
        });
    }

    /**
     * Select a Tenant from the Self Sign up list. Redirects the user to the Tenant details page
     * with the tenant's information displayed.
     *
     * @param tenantId - The tenant selected.
     */
    selectTenant(tenantId) {

        this.props.history.push({
            pathname: `/manager/tenants/${tenantId}/edit`,
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The manager dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;
        const ordinal = require('ordinal');

        const currentYear = new Date().getFullYear();
        const previousYear = new Date().getFullYear() - 1;

        return (
          <div className="content-block">
            <div className="container">
              <Breadcrumb parentPage="Dashboard" />

              <ReceiptPaymentTransactionActionsModal 
                  userType={this.props.type} 
                  validationList={this.state.validationList}
                  paymentTransaction={this.state.paymentTransaction} 
                  getStatsTotal={this.getStatsTotal}
                  selectPaymentTransaction={this.selectPaymentTransaction}
              />

              <ReceiptPaymentTransaction 
                history={this.props.history} 
                userType={this.props.type} 
                paymentTransaction={this.state.paymentTransaction} 
                parentPaymentTransaction={this.state.parentPaymentTransaction}
              >
                <ReceiptPaymentTransactionActions 
                  userType={this.props.type} 
                  paymentTransaction={this.state.paymentTransaction} 
                  handleValidation={this.handleValidation}
                  userSession={this.props.userSession}
                />
              </ReceiptPaymentTransaction>

              <div className="row">
                <div className="col-md-4">
                  <div className="card">
                    <div className="card-header">Processing</div>
                    <div className="card-body">
                      <div className="">
                        <Line
                          borderColor={"rgba(0, 0, 0, 1)"}
                          options={{
                            plugins: {
                              datalabels: {
                                formatter: (value, ctx) => {
                                  return "";
                                },
                                color: "#fff",
                              },
                            },
                            legend: {
                              labels: {
                                boxWidth: 15,
                              },
                            },
                            tooltips: {
                              mode: "x",
                              titleSpacing: 10,
                              bodySpacing: 5,
                              xPadding: 10,
                              yPadding: 10,
                            },
                          }}
                          data={{
                            labels: [
                              "Jan",
                              "Feb",
                              "Mar",
                              "Apr",
                              "May",
                              "Jun",
                              "Jul",
                              "Aug",
                              "Sep",
                              "Oct",
                              "Nov",
                              "Dec",
                            ],
                            datasets: [
                              {
                                label: currentYear,
                                backgroundColor: "rgba(0, 187, 194, 0.5)",
                                borderColor: "rgba(0, 187, 194, 1)",
                                pointBackgroundColor: "rgba(0, 187, 194, 1)",
                                pointBorderColor: "rgba(0, 187, 194, 1)",
                                pointBorderWidth: 3,
                                pointHitRadius: 5,
                                pointHoverRadius: 5,
                                borderWidth: 2,
                                data: [
                                  this.state.processingStats[
                                    `${currentYear}-01`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-02`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-03`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-04`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-05`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-06`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-07`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-08`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-09`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-10`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-11`
                                  ],
                                  this.state.processingStats[
                                    `${currentYear}-12`
                                  ],
                                ],
                              },
                              {
                                label: previousYear,
                                backgroundColor: "rgba(0, 187, 194, 0.15)",
                                borderColor: "rgba(0, 187, 194, 0.25)",
                                pointBackgroundColor: "rgba(0, 187, 194, 0.25)",
                                pointBorderColor: "rgba(0, 187, 194, 0.25)",
                                pointBorderWidth: 3,
                                pointHitRadius: 5,
                                pointHoverRadius: 5,
                                borderWidth: 2,
                                data: [
                                  this.state.processingStats[
                                    `${previousYear}-01`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-02`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-03`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-04`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-05`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-06`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-07`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-08`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-09`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-10`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-11`
                                  ],
                                  this.state.processingStats[
                                    `${previousYear}-12`
                                  ],
                                ],
                              },
                            ],
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <JumbotronHelp
                    icon="question-circle"
                    heading="Need a hand?"
                    body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                    buttonText="Visit the Help Center"
                    buttonIcon="external-link-square-alt"
                    buttonUrl="https://help.rentmoola.com/hc/en-us"
                  />
                </div>
                <div className="col-md-8">
                  <div className="accordion border-custom-light mb-4 rounded" id="alerts">
                    <div className="card-header bg-dark text-white">Alerts</div>
                    <div className="position-relative">
                      <SkeletonLoader visible={this.state.spinner} skeletonForLoading={this.state.skeletonForLoading}/>
                      {this.state.OVERDUE_CHARGES && (
                        <div className="card mb-0">

                          {/* ***************************
                              This following check will only display overdue charges if the managers manage their 
                              charges and one time payments are used.
                              Bugfix: LP-841, @author Arsh Uppal 
                          *************************** */}

                          {this.state.manageBalance && (
                            <>
                              <div
                                className="card-header bg-secondary text-dark collapsed c-pointer"
                                data-toggle="collapse"
                                data-target="#overdue-charges"
                                aria-expanded="true"
                                aria-controls="overdue-charges"
                                onClick={() => this.getAlerts("OVERDUE_CHARGES")}
                              >
                                <div className="row align-items-center">
                                  <div className="col">
                                    <span
                                      className={`badge badge-pill badge-${
                                        this.state.OVERDUE_CHARGES
                                          .totalRecordCount === 0
                                          ? "dark"
                                          : "danger"
                                      } va-b mr-1`}
                                    >
                                      {
                                        this.state.OVERDUE_CHARGES
                                          .totalRecordCount
                                      }
                                    </span>{" "}
                                    Overdue Charges{" "}
                                    <small className="text-muted text-uppercase">
                                      / All Time
                                    </small>
                                  </div>
                                  <div className="col">
                                    <div className="float-right">
                                      <i className="fas"> </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="overdue-charges"
                                className="collapse"
                                data-parent="#alerts"
                              >
                                {this.state.OVERDUE_CHARGES.totalRecordCount >
                                  0 && (
                                  <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="16%">Tenant</th>
                                          <th width="16%">Property</th>
                                          <th width="16%">Charge</th>
                                          <th width="16%">Amount</th>
                                          <th width="16%">Balance</th>
                                          <th width="16%">Billing Period</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.OVERDUE_CHARGES.records && (
                                          <React.Fragment>
                                            {this.state.OVERDUE_CHARGES.records.map(
                                              (data, key) => {
                                                if (
                                                  key <=
                                                  this.state.OVERDUE_CHARGES
                                                    .recordsShown
                                                ) {
                                                  return (
                                                    <tr
                                                      key={key}
                                                      onClick={() =>
                                                        this.selectOpenCharge(
                                                          data
                                                        )
                                                      }
                                                      className="c-pointer"
                                                    >
                                                      <td>
                                                        {data.joins &&
                                                          data.joins.c && (
                                                            <div className="">
                                                              {
                                                                data.joins.c
                                                                  .firstName
                                                              }{" "}
                                                              {
                                                                data.joins.c
                                                                  .lastName
                                                              }
                                                            </div>
                                                          )}
                                                      </td>
                                                      <td>
                                                        {data.joins &&
                                                          data.joins.p && (
                                                            <div className="">
                                                              {data.joins.pl.unit
                                                                ? data.joins.pl
                                                                    .unit + " - "
                                                                : ""}
                                                              {
                                                                data.joins.p
                                                                  .street1
                                                              }
                                                            </div>
                                                          )}
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.name
                                                            ? data.name
                                                            : data.code}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <FormattedNumber
                                                            value={data.amount}
                                                            style={`currency`}
                                                            currency="USD"
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <FormattedNumber
                                                            value={data.balance}
                                                            style={`currency`}
                                                            currency="USD"
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <Moment
                                                            format="MMMM YYYY"
                                                            tz="UTC"
                                                          >
                                                            {data.postMonth}
                                                          </Moment>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }

                                                return null;
                                              }
                                            )}

                                            {this.state.OVERDUE_CHARGES
                                              .recordsShown <
                                              this.state.OVERDUE_CHARGES
                                                .totalRecordCount && (
                                              <tr className="c-pointer">
                                                <td
                                                  colSpan="6"
                                                  className="text-center"
                                                  onClick={() =>
                                                    this.setState(
                                                      (prevState) => ({
                                                        OVERDUE_CHARGES: {
                                                          ...prevState.OVERDUE_CHARGES,
                                                          recordsShown:
                                                            prevState
                                                              .OVERDUE_CHARGES
                                                              .recordsShown + 10,
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <div className="btn-link">
                                                    Load more records{" "}
                                                    <FontAwesomeIcon
                                                      icon={["fas", "caret-down"]}
                                                      className="fa-fw"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {this.state.OVERDUE_CHARGES.totalRecordCount ===
                                  0 && (
                                  <div className="card-body">
                                    <div className="row justify-content-center">
                                      <div className="col-10">
                                        <div className="text-center text-muted">
                                          <FontAwesomeIcon
                                            icon={["fal", "thumbs-up"]}
                                            className="fa-fw mb-4"
                                            size="5x"
                                          />
                                        </div>
                                        <p className="text-center text-muted mb-0">
                                          <small>
                                            You currently do not have any tenants
                                            with overdue charges!
                                          </small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {(
                        <React.Fragment>
                          {this.state.FAILED_PUSHES && (
                            <div className="card mb-0">
                              <div
                                className="card-header bg-secondary text-dark collapsed c-pointer"
                                data-toggle="collapse"
                                data-target="#failed-pushes"
                                aria-expanded="true"
                                aria-controls="failed-pushes"
                                onClick={() => this.getAlerts("FAILED_PUSHES")}
                              >
                                <div className="row">
                                  <div className="col">
                                    <span
                                      className={`badge badge-pill badge-${
                                        this.state.FAILED_PUSHES
                                          .totalRecordCount === 0
                                          ? "dark"
                                          : "danger"
                                      } va-b mr-1`}
                                    >
                                      {this.state.FAILED_PUSHES.totalRecordCount}
                                    </span>{" "}
                                    Failed Transaction Pushes{" "}
                                    <small className="text-muted text-uppercase">
                                      / All Time
                                    </small>
                                  </div>
                                  <div className="col">
                                    <div className="float-right">
                                      <i className="fas"> </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="failed-pushes"
                                className="collapse"
                                data-parent="#alerts"
                              >
                                {this.state.FAILED_PUSHES.totalRecordCount >
                                  0 && (
                                  <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="16%">Date</th>
                                          <th width="16%">Amount</th>
                                          <th width="16%">Property ID</th>
                                          <th width="16%">Account ID</th>
                                          <th width="16%">Payment Type</th>
                                          <th width="16%">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.FAILED_PUSHES.records && (
                                          <React.Fragment>
                                            {this.state.FAILED_PUSHES.records.map(
                                              (data, key) => {
                                                if (
                                                  key <=
                                                  this.state.FAILED_PUSHES
                                                    .recordsShown
                                                ) {
                                                  return (
                                                    <tr
                                                      key={key}
                                                      onClick={() =>
                                                        this.selectIntegrationTransaction(
                                                          data
                                                        )
                                                      }
                                                      className="c-pointer"
                                                    >
                                                      <td>
                                                        <div className="">
                                                          <Moment format="MMM DD, YYYY">
                                                            {data.createDate}
                                                          </Moment>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div
                                                          className={
                                                            data.amount < 0
                                                              ? "text-danger"
                                                              : ""
                                                          }
                                                        >
                                                          <FormattedNumber
                                                            value={data.amount}
                                                            style={`currency`}
                                                            currency={
                                                              data.currency
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {
                                                            data.externalPropertyId
                                                          }
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.externalUserId}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <FormattedMessage
                                                            id={`enum.paymentType.${data.paymentMethod}`}
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {data.status && (
                                                          <div className="text-nowrap">
                                                            <FontAwesomeIcon
                                                              icon={[
                                                                "fas",
                                                                "circle",
                                                              ]}
                                                              className={`fa-fw small ${formatMessage(
                                                                {
                                                                  id:
                                                                    "enum.transactionStatus." +
                                                                    data.status +
                                                                    ".class",
                                                                }
                                                              )}`}
                                                            />
                                                            <span className="ml-1">
                                                              <FormattedMessage
                                                                id={
                                                                  "enum.transactionStatus." +
                                                                  data.status
                                                                }
                                                              />
                                                            </span>
                                                          </div>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }

                                                return null;
                                              }
                                            )}

                                            {this.state.FAILED_PUSHES
                                              .recordsShown <
                                              this.state.FAILED_PUSHES
                                                .totalRecordCount && (
                                              <tr className="c-pointer">
                                                <td
                                                  colSpan="6"
                                                  className="text-center"
                                                  onClick={() =>
                                                    this.setState(
                                                      (prevState) => ({
                                                        FAILED_PUSHES: {
                                                          ...prevState.FAILED_PUSHES,
                                                          recordsShown:
                                                            prevState
                                                              .FAILED_PUSHES
                                                              .recordsShown + 10,
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <div className="btn-link">
                                                    Load more records{" "}
                                                    <FontAwesomeIcon
                                                      icon={["fas", "caret-down"]}
                                                      className="fa-fw"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {this.state.FAILED_PUSHES.totalRecordCount ===
                                  0 && (
                                  <div className="card-body">
                                    <div className="row justify-content-center">
                                      <div className="col-10">
                                        <div className="text-center text-muted">
                                          <FontAwesomeIcon
                                            icon={["fal", "thumbs-up"]}
                                            className="fa-fw mb-4"
                                            size="5x"
                                          />
                                        </div>
                                        <p className="text-center text-muted mb-0">
                                          <small>
                                            You currently do not have any
                                            transactions that have failed to push
                                            to your integrated system!
                                          </small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}

                      {this.state.PENDING_APPLICATIONS && (
                        <div className="card mb-0">
                          <div
                            className="card-header bg-secondary text-dark collapsed c-pointer"
                            data-toggle="collapse"
                            data-target="#pending-applications"
                            aria-expanded="true"
                            aria-controls="pending-applications"
                            onClick={() => this.getAlerts("PENDING_APPLICATIONS")}
                          >
                            <div className="row align-items-center">
                              <div className="col">
                                <span
                                  className={`badge badge-pill badge-${
                                    this.state.PENDING_APPLICATIONS
                                      .totalRecordCount === 0
                                      ? "dark"
                                      : "warning"
                                  } va-b mr-1`}
                                >
                                  {
                                    this.state.PENDING_APPLICATIONS
                                      .totalRecordCount
                                  }
                                </span>{" "}
                                Pending Applications{" "}
                                <small className="text-muted text-uppercase">
                                  / All Time
                                </small>
                              </div>
                              <div className="col">
                                <div className="float-right">
                                  <i className="fas"> </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="pending-applications"
                            className="collapse"
                            data-parent="#alerts"
                          >
                            {this.state.PENDING_APPLICATIONS.totalRecordCount >
                              0 && (
                              <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="16%">Applicant</th>
                                      <th width="16%">Unit</th>
                                      <th width="16%">Property</th>
                                      <th width="16%">Applied On</th>
                                      <th width="16%">Type</th>
                                      <th width="16%">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.PENDING_APPLICATIONS.records && (
                                      <React.Fragment>
                                        {this.state.PENDING_APPLICATIONS.records.map(
                                          (data, key) => {
                                            if (
                                              key <=
                                              this.state.PENDING_APPLICATIONS
                                                .recordsShown
                                            ) {
                                              return (
                                                <tr
                                                  key={key}
                                                  onClick={() =>
                                                    this.selectLeaseApplication(
                                                      data
                                                    )
                                                  }
                                                  className="c-pointer"
                                                >
                                                  <td>
                                                    <div className="">
                                                      {data.firstName}{" "}
                                                      {data.lastName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      {data.unit}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins &&
                                                      data.joins.p && (
                                                        <div className="">
                                                          {data.joins.p.street1}
                                                        </div>
                                                      )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      <Moment format="MMM DD, YYYY">
                                                        {data.createDate}
                                                      </Moment>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      {data.existingTenant && (
                                                        <React.Fragment>
                                                          Existing Tenant{" "}
                                                          <FontAwesomeIcon
                                                            icon={[
                                                              "fas",
                                                              "question-circle",
                                                            ]}
                                                            className="fa-fw"
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="This applicant has indicated that they already have a lease with one of your properties, and would like to make their payments with Propertii"
                                                          />
                                                        </React.Fragment>
                                                      )}
                                                      {!data.existingTenant && (
                                                        <React.Fragment>
                                                          New Applicant
                                                        </React.Fragment>
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="text-nowrap">
                                                      <FontAwesomeIcon
                                                        icon={["fas", "circle"]}
                                                        className={`fa-fw small ${formatMessage(
                                                          {
                                                            id:
                                                              "enum.leaseApplication.status." +
                                                              data.status +
                                                              ".class",
                                                          }
                                                        )}`}
                                                      />
                                                      <span className="ml-1">
                                                        <FormattedMessage
                                                          id={
                                                            "enum.leaseApplication.status." +
                                                            data.status
                                                          }
                                                        />
                                                      </span>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            }

                                            return null;
                                          }
                                        )}

                                        {this.state.PENDING_APPLICATIONS
                                          .recordsShown <
                                          this.state.PENDING_APPLICATIONS
                                            .totalRecordCount && (
                                          <tr className="c-pointer">
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                              onClick={() =>
                                                this.setState((prevState) => ({
                                                  PENDING_APPLICATIONS: {
                                                    ...prevState.PENDING_APPLICATIONS,
                                                    recordsShown:
                                                      prevState
                                                        .PENDING_APPLICATIONS
                                                        .recordsShown + 10,
                                                  },
                                                }))
                                              }
                                            >
                                              <div className="btn-link">
                                                Load more records{" "}
                                                <FontAwesomeIcon
                                                  icon={["fas", "caret-down"]}
                                                  className="fa-fw"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {this.state.PENDING_APPLICATIONS.totalRecordCount ===
                              0 && (
                              <div className="card-body">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <div className="text-center text-muted">
                                      <FontAwesomeIcon
                                        icon={["fal", "thumbs-up"]}
                                        className="fa-fw mb-4"
                                        size="5x"
                                      />
                                    </div>
                                    <p className="text-center text-muted mb-0">
                                      <small>
                                        You currently do not have any applications
                                        awaiting approval!
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {this.state.PENDING_INVITATIONS && (
                        <div className="card mb-0">
                          <div
                            className="card-header bg-secondary text-dark collapsed c-pointer"
                            data-toggle="collapse"
                            data-target="#pending-invitations"
                            aria-expanded="true"
                            aria-controls="pending-invitations"
                            onClick={() => this.getAlerts("PENDING_INVITATIONS")}
                          >
                            <div className="row align-items-center">
                              <div className="col">
                                <span
                                  className={`badge badge-pill badge-${
                                    this.state.PENDING_INVITATIONS
                                      .totalRecordCount === 0
                                      ? "dark"
                                      : "warning"
                                  } va-b mr-1`}
                                >
                                  {
                                    this.state.PENDING_INVITATIONS
                                      .totalRecordCount
                                  }
                                </span>{" "}
                                Pending Invitations{" "}
                                <small className="text-muted text-uppercase">
                                  / All Time
                                </small>
                              </div>
                              <div className="col">
                                <div className="float-right">
                                  <i className="fas"> </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="pending-invitations"
                            className="collapse"
                            data-parent="#alerts"
                          >
                            {this.state.PENDING_INVITATIONS.totalRecordCount >
                              0 && (
                              <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="16%">Sent On</th>
                                      <th width="16%">Tenant</th>
                                      <th width="16%">Unit</th>
                                      <th width="16%">Property</th>
                                      <th width="16%">Charges</th>
                                      <th width="16%">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.PENDING_INVITATIONS.records && (
                                      <React.Fragment>
                                        {this.state.PENDING_INVITATIONS.records.map(
                                          (data, key) => {
                                            if (
                                              key <=
                                              this.state.PENDING_INVITATIONS
                                                .recordsShown
                                            ) {
                                              return (
                                                <tr
                                                  key={key}
                                                  onClick={() =>
                                                    this.selectInvitation(data)
                                                  }
                                                  className="c-pointer"
                                                >
                                                  <td>
                                                    {data.joins && data.joins.e && (
                                                      <div className="">
                                                        <Moment format="MMM DD, YYYY">
                                                          {
                                                            data.joins.e
                                                              .lastSentDate
                                                          }
                                                        </Moment>
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      {data.firstName}{" "}
                                                      {data.lastName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      {data.unit}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins &&
                                                      data.joins.p && (
                                                        <div className="">
                                                          {data.joins.p.street1}
                                                        </div>
                                                      )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      <FormattedNumber
                                                        value={data.totalCharges}
                                                        style={`currency`}
                                                        currency="USD"
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.accepted != null && (
                                                      <div className="text-nowrap">
                                                        <FontAwesomeIcon
                                                          icon={["fas", "circle"]}
                                                          className={`fa-fw small ${
                                                            data.accepted
                                                              ? "text-success"
                                                              : "text-warning"
                                                          }`}
                                                        />
                                                        <span className="ml-1">
                                                          {data.accepted
                                                            ? "Accepted"
                                                            : "Pending"}
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }

                                            return null;
                                          }
                                        )}

                                        {this.state.PENDING_INVITATIONS
                                          .recordsShown <
                                          this.state.PENDING_INVITATIONS
                                            .totalRecordCount && (
                                          <tr className="c-pointer">
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                              onClick={() =>
                                                this.setState((prevState) => ({
                                                  PENDING_INVITATIONS: {
                                                    ...prevState.PENDING_INVITATIONS,
                                                    recordsShown:
                                                      prevState
                                                        .PENDING_INVITATIONS
                                                        .recordsShown + 10,
                                                  },
                                                }))
                                              }
                                            >
                                              <div className="btn-link">
                                                Load more records{" "}
                                                <FontAwesomeIcon
                                                  icon={["fas", "caret-down"]}
                                                  className="fa-fw"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {this.state.PENDING_INVITATIONS.totalRecordCount ===
                              0 && (
                              <div className="card-body">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <div className="text-center text-muted">
                                      <FontAwesomeIcon
                                        icon={["fal", "thumbs-up"]}
                                        className="fa-fw mb-4"
                                        size="5x"
                                      />
                                    </div>
                                    <p className="text-center text-muted mb-0">
                                      <small>
                                        You currently do not have any outstanding
                                        tenant invitations!
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {/* {this.state.TENANTS_MISSING_ACCOUNTS && (
                        <div
                          className="card mb-0"
                        >
                          <div
                            className="card-header bg-secondary text-dark collapsed c-pointer"
                            data-toggle="collapse"
                            data-target="#tenants-missing-account"
                            aria-expanded="true"
                            aria-controls="tenants-missing-account"
                            onClick={() =>
                              this.getAlerts("TENANTS_MISSING_ACCOUNTS")
                            }
                          >
                            <div className="row">
                              <div className="col">
                                <span
                                  className={`badge badge-pill badge-${
                                    this.state.TENANTS_MISSING_ACCOUNTS
                                      .totalRecordCount === 0
                                      ? "dark"
                                      : "warning"
                                  } va-b mr-1`}
                                >
                                  {
                                    this.state.TENANTS_MISSING_ACCOUNTS
                                      .totalRecordCount
                                  }
                                </span>{" "}
                                Tenants missing Account IDs{" "}
                                <small className="text-muted text-uppercase">
                                  / ALL TIME
                                </small>
                              </div>
                              <div className="col">
                                <div className="float-right">
                                  <i className="fas"> </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="tenants-missing-account"
                            className="collapse"
                            data-parent="#alerts"
                          >
                            {this.state.TENANTS_MISSING_ACCOUNTS.totalRecordCount >
                              0 && (
                              <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="16%">First Name</th>
                                      <th width="16%">Last Name</th>
                                      <th width="16%">Property Name</th>
                                      <th width="16%">Unit</th>
                                      <th width="16%">Property Address</th>
                                      <th width="16%">Account ID</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.TENANTS_MISSING_ACCOUNTS.records && (
                                      <React.Fragment>
                                        {this.state.TENANTS_MISSING_ACCOUNTS.records.map(
                                          (data, key) => {
                                            if (
                                              key <=
                                              this.state.TENANTS_MISSING_ACCOUNTS
                                                .recordsShown
                                            ) {
                                              return (
                                                <tr key={key}>
                                                  <td>
                                                    <div className="">
                                                      {data.firstName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div>
                                                      {data.lastName}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins?.property && (
                                                      <div className="">
                                                        {data.joins.property?.propertyName
                                                          ? data.joins.property.propertyName
                                                          : ""}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      {data.joins?.propertyLease?.unit
                                                        ? data.joins.propertyLease.unit
                                                        : ""}
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins?.property && (
                                                      <div className="">
                                                        {data.joins.property?.street1
                                                          ? data.joins.property.street1
                                                          : ""}
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }

                                            return null;
                                          }
                                        )}

                                        {this.state.TENANTS_MISSING_ACCOUNTS
                                          .recordsShown <
                                          this.state.TENANTS_MISSING_ACCOUNTS
                                            .totalRecordCount && (
                                          <tr className="c-pointer">
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                              onClick={() =>
                                                this.setState((prevState) => ({
                                                  TENANTS_MISSING_ACCOUNTS: {
                                                    ...prevState.TENANTS_MISSING_ACCOUNTS,
                                                    recordsShown:
                                                      prevState
                                                        .TENANTS_MISSING_ACCOUNTS
                                                        .recordsShown + 10,
                                                  },
                                                }))
                                              }
                                            >
                                              <div className="btn-link">
                                                Load more tenants{" "}
                                                <FontAwesomeIcon
                                                  icon={["fas", "caret-down"]}
                                                  className="fa-fw"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {this.state.TENANTS_MISSING_ACCOUNTS.totalRecordCount ===
                              0 && (
                              <div className="card-body">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <div className="text-center text-muted">
                                      <FontAwesomeIcon
                                        icon={["fal", "thumbs-up"]}
                                        className="fa-fw mb-4"
                                        size="5x"
                                      />
                                    </div>
                                    <p className="text-center text-muted mb-0">
                                      <small>
                                        Your tenants currently do not have any missing account!
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}*/}
                      
                      {this.state.CHARGE_BACKS && (
                        <div className="card mb-0">
                          <div
                            className="card-header bg-secondary text-dark collapsed c-pointer"
                            data-toggle="collapse"
                            data-target="#charge-backs"
                            aria-expanded="true"
                            aria-controls="charge-backs"
                            onClick={() => this.getAlerts("CHARGE_BACKS")}
                          >
                            <div className="row">
                              <div className="col">
                                <span
                                  className={`badge badge-pill badge-${
                                    this.state.CHARGE_BACKS.totalRecordCount === 0
                                      ? "dark"
                                      : "warning"
                                  } va-b mr-1`}
                                >
                                  {this.state.CHARGE_BACKS.totalRecordCount}
                                </span>{" "}
                                Chargebacks{" "}
                                <small className="text-muted text-uppercase">
                                  / Last 7 Days
                                </small>
                              </div>
                              <div className="col">
                                <div className="float-right">
                                  <i className="fas"> </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="charge-backs"
                            className="collapse"
                            data-parent="#alerts"
                          >
                            {this.state.CHARGE_BACKS.totalRecordCount > 0 && (
                              <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="16%">Date</th>
                                      <th width="16%">Amount</th>
                                      <th width="16%">Property</th>
                                      <th width="16%">Tenant</th>
                                      <th width="16%">Processor</th>
                                      <th width="16%">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.CHARGE_BACKS.records && (
                                      <React.Fragment>
                                        {this.state.CHARGE_BACKS.records.map(
                                          (data, key) => {
                                            if (
                                              key <=
                                              this.state.CHARGE_BACKS.recordsShown
                                            ) {
                                              return (
                                                <tr
                                                  key={key}
                                                  onClick={() =>
                                                    this.selectPaymentTransaction(
                                                      data
                                                    )
                                                  }
                                                  className="c-pointer"
                                                >
                                                  <td>
                                                    <div className="">
                                                      <Moment format="MMM DD, YYYY">
                                                        {data.createDate}
                                                      </Moment>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div
                                                      className={
                                                        data.amount < 0
                                                          ? "text-danger"
                                                          : ""
                                                      }
                                                    >
                                                      <FormattedNumber
                                                        value={data.amount}
                                                        style={`currency`}
                                                        currency={data.currency}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins.p && (
                                                      <div className="">
                                                        {data.joins.pl.unit
                                                          ? data.joins.pl.unit +
                                                            " - "
                                                          : ""}
                                                        {data.joins.p.street1}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins.c && (
                                                      <div className="">
                                                        {data.joins.c.firstName}{" "}
                                                        {data.joins.c.lastName}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      <FormattedMessage
                                                        id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.status && (
                                                      <div className="text-nowrap">
                                                        <FontAwesomeIcon
                                                          icon={["fas", "circle"]}
                                                          className={`fa-fw small ${formatMessage(
                                                            {
                                                              id:
                                                                "enum.transactionStatus." +
                                                                data.status +
                                                                ".class",
                                                            }
                                                          )}`}
                                                        />
                                                        <span className="ml-1">
                                                          <FormattedMessage
                                                            id={
                                                              "enum.transactionStatus." +
                                                              data.status
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }

                                            return null;
                                          }
                                        )}

                                        {this.state.CHARGE_BACKS.recordsShown <
                                          this.state.CHARGE_BACKS
                                            .totalRecordCount && (
                                          <tr className="c-pointer">
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                              onClick={() =>
                                                this.setState((prevState) => ({
                                                  CHARGE_BACKS: {
                                                    ...prevState.CHARGE_BACKS,
                                                    recordsShown:
                                                      prevState.CHARGE_BACKS
                                                        .recordsShown + 10,
                                                  },
                                                }))
                                              }
                                            >
                                              <div className="btn-link">
                                                Load more records{" "}
                                                <FontAwesomeIcon
                                                  icon={["fas", "caret-down"]}
                                                  className="fa-fw"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {this.state.CHARGE_BACKS.totalRecordCount === 0 && (
                              <div className="card-body">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <div className="text-center text-muted">
                                      <FontAwesomeIcon
                                        icon={["fal", "thumbs-up"]}
                                        className="fa-fw mb-4"
                                        size="5x"
                                      />
                                    </div>
                                    <p className="text-center text-muted mb-0">
                                      <small>
                                        Your tenants currently do not have any
                                        issued chargebacks in the last 7 days!
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      {this.state.DISPUTE_LOST && (
                            <div className="card mb-0">
                                <div
                                    className="card-header bg-secondary text-dark collapsed c-pointer"
                                    data-toggle="collapse"
                                    data-target="#disbute-lost"
                                    aria-expanded="true"
                                    aria-controls="disbute-lost"
                                    onClick={() => this.getAlerts("DISPUTE_LOST")}
                                >
                                    <div className="row">
                                        <div className="col">
                                            <span
                                                className={`badge badge-pill badge-${
                                                    this.state.DISPUTE_LOST.totalRecordCount === 0 ? "dark" : "warning"
                                                } va-b mr-1`}
                                            >
                                                {this.state.DISPUTE_LOST.totalRecordCount}
                                            </span>{" "}Chargebacks from lost disputes{" "}
                                            <small className="text-muted text-uppercase">
                                                / Last 7 Days
                                            </small>
                                        </div>
                                        <div className="col">
                                            <div className="float-right">
                                                <i className="fas"> </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="disbute-lost"
                                    className="collapse"
                                    data-parent="#alerts"
                                >
                                    {this.state.DISPUTE_LOST.totalRecordCount > 0 && (
                                        <div className="card-body card-body-table">
                                            <table className="table table-hover table-bordered">
                                                <thead>
                                                <tr>
                                                    <th width="16%">Date</th>
                                                    <th width="16%">Amount</th>
                                                    <th width="16%">Property</th>
                                                    <th width="16%">Tenant</th>
                                                    <th width="16%">Processor</th>
                                                    <th width="16%">Status</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.state.DISPUTE_LOST.records && (
                                                    <React.Fragment>
                                                        {this.state.DISPUTE_LOST.records.map(
                                                            (data, key) => {
                                                                if (
                                                                    key <=
                                                                    this.state.DISPUTE_LOST.recordsShown
                                                                ) {
                                                                    return (
                                                                        <tr
                                                                            key={key}
                                                                            onClick={() =>
                                                                                this.selectPaymentTransaction(
                                                                                    data
                                                                                )
                                                                            }
                                                                            className="c-pointer"
                                                                        >
                                                                            <td>
                                                                                <div className="">
                                                                                    <Moment format="MMM DD, YYYY">
                                                                                        {data.createDate}
                                                                                    </Moment>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div
                                                                                    className={
                                                                                        data.amount < 0
                                                                                            ? "text-danger"
                                                                                            : ""
                                                                                    }
                                                                                >
                                                                                    <FormattedNumber
                                                                                        value={data.amount}
                                                                                        style={`currency`}
                                                                                        currency={data.currency}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {data.joins && data.joins.p && (
                                                                                    <div className="">
                                                                                        {data.joins.pl.unit
                                                                                            ? data.joins.pl.unit +
                                                                                            " - "
                                                                                            : ""}
                                                                                        {data.joins.p.street1}
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                {data.joins && data.joins.c && (
                                                                                    <div className="">
                                                                                        {data.joins.c.firstName}{" "}
                                                                                        {data.joins.c.lastName}
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                            <td>
                                                                                <div className="">
                                                                                    <FormattedMessage
                                                                                        id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                {data.status && (
                                                                                    <div className="text-nowrap">
                                                                                        <FontAwesomeIcon
                                                                                            icon={["fas", "circle"]}
                                                                                            className={`fa-fw small ${formatMessage(
                                                                                                {
                                                                                                    id: "enum.transactionStatus." + 
                                                                                                        data.status + ".class",
                                                                                                }
                                                                                                )}`}
                                                                                        />
                                                                                        <span className="ml-1">
                                                                                            <FormattedMessage
                                                                                                id={"enum.transactionStatus." + data.status}
                                                                                            />
                                                                                        </span>
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                }

                                                                return null;
                                                            }
                                                        )}

                                                        {this.state.DISPUTE_LOST.recordsShown <
                                                            this.state.DISPUTE_LOST
                                                                .totalRecordCount && (
                                                                <tr className="c-pointer">
                                                                    <td
                                                                        colSpan="6"
                                                                        className="text-center"
                                                                        onClick={() =>
                                                                            this.setState((prevState) => ({
                                                                                DISPUTE_LOST: {
                                                                                    ...prevState.DISPUTE_LOST,
                                                                                    recordsShown:
                                                                                        prevState.DISPUTE_LOST
                                                                                            .recordsShown + 10,
                                                                                },
                                                                            }))
                                                                        }
                                                                    >
                                                                        <div className="btn-link">
                                                                            Load more records{" "}
                                                                            <FontAwesomeIcon
                                                                                icon={["fas", "caret-down"]}
                                                                                className="fa-fw"
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                    </React.Fragment>
                                                )}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                    {this.state.DISPUTE_LOST.totalRecordCount === 0 && (
                                        <div className="card-body">
                                            <div className="row justify-content-center">
                                                <div className="col-10">
                                                    <div className="text-center text-muted">
                                                        <FontAwesomeIcon
                                                            icon={["fal", "thumbs-up"]}
                                                            className="fa-fw mb-4"
                                                            size="5x"
                                                        />
                                                    </div>
                                                    <p className="text-center text-muted mb-0">
                                                        <small>
                                                            Your tenants currently do not have any
                                                            issued chargebacks in the last 7 days!
                                                        </small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                      {this.state.CHARGE_BACK_REVERSALS && (
                        <div
                          className={`card mb-0`}
                        >
                          <div
                            className="card-header bg-secondary text-dark collapsed c-pointer"
                            data-toggle="collapse"
                            data-target="#charge-back-reversals"
                            aria-expanded="true"
                            aria-controls="charge-back-reversals"
                            onClick={() =>
                              this.getAlerts("CHARGE_BACK_REVERSALS")
                            }
                          >
                            <div className="row">
                              <div className="col">
                                <span
                                  className={`badge badge-pill badge-${
                                    this.state.CHARGE_BACK_REVERSALS
                                      .totalRecordCount === 0
                                      ? "dark"
                                      : "warning"
                                  } va-b mr-1`}
                                >
                                  {
                                    this.state.CHARGE_BACK_REVERSALS
                                      .totalRecordCount
                                  }
                                </span>{" "}
                                Chargeback Reversals{" "}
                                <small className="text-muted text-uppercase">
                                  / Last 7 Days
                                </small>
                              </div>
                              <div className="col">
                                <div className="float-right">
                                  <i className="fas"> </i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            id="charge-back-reversals"
                            className="collapse"
                            data-parent="#alerts"
                          >
                            {this.state.CHARGE_BACK_REVERSALS.totalRecordCount >
                              0 && (
                              <div className="card-body card-body-table">
                                <table className="table table-hover table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="16%">Date</th>
                                      <th width="16%">Amount</th>
                                      <th width="16%">Property</th>
                                      <th width="16%">Tenant</th>
                                      <th width="16%">Processor</th>
                                      <th width="16%">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.CHARGE_BACK_REVERSALS.records && (
                                      <React.Fragment>
                                        {this.state.CHARGE_BACK_REVERSALS.records.map(
                                          (data, key) => {
                                            if (
                                              key <=
                                              this.state.CHARGE_BACK_REVERSALS
                                                .recordsShown
                                            ) {
                                              return (
                                                <tr
                                                  key={key}
                                                  onClick={() =>
                                                    this.selectPaymentTransaction(
                                                      data
                                                    )
                                                  }
                                                  className="c-pointer"
                                                >
                                                  <td>
                                                    <div className="">
                                                      <Moment format="MMM DD, YYYY">
                                                        {data.createDate}
                                                      </Moment>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div
                                                      className={
                                                        data.amount < 0
                                                          ? "text-danger"
                                                          : ""
                                                      }
                                                    >
                                                      <FormattedNumber
                                                        value={data.amount}
                                                        style={`currency`}
                                                        currency={data.currency}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins.p && (
                                                      <div className="">
                                                        {data.joins.pl.unit
                                                          ? data.joins.pl.unit +
                                                            " - "
                                                          : ""}
                                                        {data.joins.p.street1}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    {data.joins && data.joins.c && (
                                                      <div className="">
                                                        {data.joins.c.firstName}{" "}
                                                        {data.joins.c.lastName}
                                                      </div>
                                                    )}
                                                  </td>
                                                  <td>
                                                    <div className="">
                                                      <FormattedMessage
                                                        id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {data.status && (
                                                      <div className="text-nowrap">
                                                        <FontAwesomeIcon
                                                          icon={["fas", "circle"]}
                                                          className={`fa-fw small ${formatMessage(
                                                            {
                                                              id:
                                                                "enum.transactionStatus." +
                                                                data.status +
                                                                ".class",
                                                            }
                                                          )}`}
                                                        />
                                                        <span className="ml-1">
                                                          <FormattedMessage
                                                            id={
                                                              "enum.transactionStatus." +
                                                              data.status
                                                            }
                                                          />
                                                        </span>
                                                      </div>
                                                    )}
                                                  </td>
                                                </tr>
                                              );
                                            }

                                            return null;
                                          }
                                        )}

                                        {this.state.CHARGE_BACK_REVERSALS
                                          .recordsShown <
                                          this.state.CHARGE_BACK_REVERSALS
                                            .totalRecordCount && (
                                          <tr className="c-pointer">
                                            <td
                                              colSpan="6"
                                              className="text-center"
                                              onClick={() =>
                                                this.setState((prevState) => ({
                                                  CHARGE_BACK_REVERSALS: {
                                                    ...prevState.CHARGE_BACK_REVERSALS,
                                                    recordsShown:
                                                      prevState
                                                        .CHARGE_BACK_REVERSALS
                                                        .recordsShown + 10,
                                                  },
                                                }))
                                              }
                                            >
                                              <div className="btn-link">
                                                Load more records{" "}
                                                <FontAwesomeIcon
                                                  icon={["fas", "caret-down"]}
                                                  className="fa-fw"
                                                />
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                            {this.state.CHARGE_BACK_REVERSALS.totalRecordCount ===
                              0 && (
                              <div className="card-body">
                                <div className="row justify-content-center">
                                  <div className="col-10">
                                    <div className="text-center text-muted">
                                      <FontAwesomeIcon
                                        icon={["fal", "thumbs-up"]}
                                        className="fa-fw mb-4"
                                        size="5x"
                                      />
                                    </div>
                                    <p className="text-center text-muted mb-0">
                                      <small>
                                        Your tenants currently do not have any
                                        chargeback reversals in the last 7 days!
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      )}

                      {(this.state.showIntegrationAlerts) && (
                        <React.Fragment>
                          {this.state.NON_INTEGRATED_ACCOUNTS && (
                            <div className="card mb-0">
                              <div
                                className="card-header bg-secondary text-dark collapsed c-pointer"
                                data-toggle="collapse"
                                data-target="#non-integrated-accounts"
                                aria-expanded="true"
                                aria-controls="non-integrated-accounts"
                                onClick={() =>
                                  this.getAlerts("NON_INTEGRATED_ACCOUNTS")
                                }
                              >
                                <div className="row align-items-center">
                                  <div className="col">
                                    <span
                                      className={`badge badge-pill badge-${
                                        this.state.NON_INTEGRATED_ACCOUNTS
                                          .totalRecordCount === 0
                                          ? "dark"
                                          : "danger"
                                      } va-b mr-1`}
                                    >
                                      {
                                        this.state.NON_INTEGRATED_ACCOUNTS
                                          .totalRecordCount
                                      }
                                    </span>{" "}
                                    Non-Integrated Tenants{" "}
                                    <small className="text-muted text-uppercase">
                                      / All Time
                                    </small>
                                  </div>
                                  <div className="col">
                                    <div className="float-right">
                                      <i className="fas"> </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="non-integrated-accounts"
                                className="collapse"
                                data-parent="#alerts"
                              >
                                {this.state.NON_INTEGRATED_ACCOUNTS
                                  .totalRecordCount > 0 && (
                                  <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="16%">Tenant</th>
                                          <th width="16%">Unit</th>
                                          <th width="16%">Property</th>
                                          <th width="16%">Charges</th>
                                          <th width="16%">Payment Due</th>
                                          <th width="16%">Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.NON_INTEGRATED_ACCOUNTS
                                          .records && (
                                          <React.Fragment>
                                            {this.state.NON_INTEGRATED_ACCOUNTS.records.map(
                                              (data, key) => {
                                                if (
                                                  key <=
                                                  this.state
                                                    .NON_INTEGRATED_ACCOUNTS
                                                    .recordsShown
                                                ) {
                                                  return (
                                                    <tr
                                                      key={key}
                                                      onClick={() =>
                                                        this.selectPropertyLease(
                                                          data
                                                        )
                                                      }
                                                      className="c-pointer"
                                                    >
                                                      <td>
                                                        {data.joins &&
                                                          data.joins.c && (
                                                            <div className="">
                                                              {
                                                                data.joins.c
                                                                  .firstName
                                                              }{" "}
                                                              {
                                                                data.joins.c
                                                                  .lastName
                                                              }
                                                            </div>
                                                          )}
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.unit}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {data.joins && (
                                                          <div className="">
                                                            {data.joins.p.street1}
                                                          </div>
                                                        )}
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <FormattedNumber
                                                            value={
                                                              data.totalCharges
                                                            }
                                                            style={`currency`}
                                                            currency="USD"
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.monthlyPaymentDueDay >
                                                          0
                                                            ? ordinal(
                                                                data.monthlyPaymentDueDay
                                                              )
                                                            : "Anytime"}{" "}
                                                          <span className="text-muted text-uppercase small">
                                                            (Monthly)
                                                          </span>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        {data.status != null && (
                                                          <div className="text-nowrap">
                                                            <FontAwesomeIcon
                                                              icon={[
                                                                "fas",
                                                                "circle",
                                                              ]}
                                                              className={`fa-fw small ${formatMessage(
                                                                {
                                                                  id:
                                                                    "enum.propertyLease.status." +
                                                                    data.status +
                                                                    ".class",
                                                                }
                                                              )}`}
                                                            />
                                                            <span className="ml-1">
                                                              <FormattedMessage
                                                                id={
                                                                  "enum.propertyLease.status." +
                                                                  data.status
                                                                }
                                                              />
                                                            </span>
                                                          </div>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }

                                                return null;
                                              }
                                            )}

                                            {this.state.NON_INTEGRATED_ACCOUNTS
                                              .recordsShown <
                                              this.state.NON_INTEGRATED_ACCOUNTS
                                                .totalRecordCount && (
                                              <tr className="c-pointer">
                                                <td
                                                  colSpan="6"
                                                  className="text-center"
                                                  onClick={() =>
                                                    this.setState(
                                                      (prevState) => ({
                                                        NON_INTEGRATED_ACCOUNTS: {
                                                          ...prevState.NON_INTEGRATED_ACCOUNTS,
                                                          recordsShown:
                                                            prevState
                                                              .NON_INTEGRATED_ACCOUNTS
                                                              .recordsShown + 10,
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <div className="btn-link">
                                                    Load more records{" "}
                                                    <FontAwesomeIcon
                                                      icon={["fas", "caret-down"]}
                                                      className="fa-fw"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {this.state.NON_INTEGRATED_ACCOUNTS
                                  .totalRecordCount === 0 && (
                                  <div className="card-body">
                                    <div className="row justify-content-center">
                                      <div className="col-10">
                                        <div className="text-center text-muted">
                                          <FontAwesomeIcon
                                            icon={["fal", "thumbs-up"]}
                                            className="fa-fw mb-4"
                                            size="5x"
                                          />
                                        </div>
                                        <p className="text-center text-muted mb-0">
                                          <small>
                                            You currently do not have any tenants
                                            that are not linked up with your
                                            integrated system!
                                          </small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}

                          {this.state.NON_INTEGRATED_PROPERTIES && (
                            <div className="card mb-0">
                              <div
                                className="card-header bg-secondary text-dark collapsed c-pointer"
                                data-toggle="collapse"
                                data-target="#non-integrated-properties"
                                aria-expanded="true"
                                aria-controls="non-integrated-properties"
                                onClick={() =>
                                  this.getAlerts("NON_INTEGRATED_PROPERTIES")
                                }
                              >
                                <div className="row align-items-center">
                                  <div className="col">
                                    <span
                                      className={`badge badge-pill badge-${
                                        this.state.NON_INTEGRATED_PROPERTIES
                                          .totalRecordCount === 0
                                          ? "dark"
                                          : "danger"
                                      } va-b mr-1`}
                                    >
                                      {
                                        this.state.NON_INTEGRATED_PROPERTIES
                                          .totalRecordCount
                                      }
                                    </span>{" "}
                                    Non-Integrated Properties{" "}
                                    <small className="text-muted text-uppercase">
                                      / All Time
                                    </small>
                                  </div>
                                  <div className="col">
                                    <div className="float-right">
                                      <i className="fas"> </i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="non-integrated-properties"
                                className="collapse"
                                data-parent="#alerts"
                              >
                                {this.state.NON_INTEGRATED_PROPERTIES
                                  .totalRecordCount > 0 && (
                                  <div className="card-body card-body-table">
                                    <table className="table table-hover table-bordered">
                                      <thead>
                                        <tr>
                                          <th width="16%">Property</th>
                                          <th width="16%">Street</th>
                                          <th width="16%">City</th>
                                          <th width="16%">State/Province</th>
                                          <th width="16%">Country</th>
                                          <th width="16%">ZIP/Postal Code</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.NON_INTEGRATED_PROPERTIES
                                          .records && (
                                          <React.Fragment>
                                            {this.state.NON_INTEGRATED_PROPERTIES.records.map(
                                              (data, key) => {
                                                if (
                                                  key <=
                                                  this.state
                                                    .NON_INTEGRATED_PROPERTIES
                                                    .recordsShown
                                                ) {
                                                  return (
                                                    <tr
                                                      key={key}
                                                      onClick={() =>
                                                        this.selectProperty(data)
                                                      }
                                                      className="c-pointer"
                                                    >
                                                      <td>
                                                        <div className="">
                                                          {data.propertyName}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.street1}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.city}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.country ===
                                                            "CA" && (
                                                            <FormattedMessage
                                                              id={
                                                                "province." +
                                                                data.province
                                                              }
                                                            />
                                                          )}
                                                          {data.country ===
                                                            "US" && (
                                                            <FormattedMessage
                                                              id={
                                                                "state." +
                                                                data.province
                                                              }
                                                            />
                                                          )}
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          <FormattedMessage
                                                            id={
                                                              "country." +
                                                              data.country
                                                            }
                                                          />
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <div className="">
                                                          {data.postalCode}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                }

                                                return null;
                                              }
                                            )}

                                            {this.state.NON_INTEGRATED_PROPERTIES
                                              .recordsShown <
                                              this.state.NON_INTEGRATED_PROPERTIES
                                                .totalRecordCount && (
                                              <tr className="c-pointer">
                                                <td
                                                  colSpan="6"
                                                  className="text-center"
                                                  onClick={() =>
                                                    this.setState(
                                                      (prevState) => ({
                                                        NON_INTEGRATED_PROPERTIES: {
                                                          ...prevState.NON_INTEGRATED_PROPERTIES,
                                                          recordsShown:
                                                            prevState
                                                              .NON_INTEGRATED_PROPERTIES
                                                              .recordsShown + 10,
                                                        },
                                                      })
                                                    )
                                                  }
                                                >
                                                  <div className="btn-link">
                                                    Load more records{" "}
                                                    <FontAwesomeIcon
                                                      icon={["fas", "caret-down"]}
                                                      className="fa-fw"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )}
                                          </React.Fragment>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                                {this.state.NON_INTEGRATED_PROPERTIES
                                  .totalRecordCount === 0 && (
                                  <div className="card-body">
                                    <div className="row justify-content-center">
                                      <div className="col-10">
                                        <div className="text-center text-muted">
                                          <FontAwesomeIcon
                                            icon={["fal", "thumbs-up"]}
                                            className="fa-fw mb-4"
                                            size="5x"
                                          />
                                        </div>
                                        <p className="text-center text-muted mb-0">
                                          <small>
                                            You currently do not have any
                                            properties that are not linked up with
                                            your integrated system!
                                          </small>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>

                  {this.props.userSession.sessionRole?.roles?.includes('PRIMARY_MANAGER') && (
                    <div className="card">
                      <div className="card-header">
                        <div className="row align-items-center">
                          <div className="col">Billing</div>
                          <div className="col text-right">
                            <Link
                              to="/manager/settings/billing"
                              className="btn btn-primary btn-sm"
                            >
                              View Billing Details
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4">
                            <p className="mb-0">Current Balance Due:</p>
                            <p className="font-weight-bold mb-0">
                              <FormattedNumber
                                value={this.state.accountBalance}
                                style={`currency`}
                                currency="USD"
                              />
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="mb-0">Next Payment Due:</p>
                            <p className="font-weight-bold mb-0">
                              {this.state.accountPaymentDueDate && (
                                <Moment format="MMMM DD, YYYY" tz="UTC">
                                  {this.state.accountPaymentDueDate}
                                </Moment>
                              )}
                            </p>
                          </div>
                          <div className="col-4">
                            <p className="mb-0">Last Payment Made:</p>
                            <p className="font-weight-bold mb-0">
                              <FormattedNumber
                                value={this.state.accountLastPayment}
                                style={`currency`}
                                currency="USD"
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
    };
}

Dashboard.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Dashboard);