export const ReminderSentOnDayOfMonth = [
  { text: "Don't Send Reminder", value: '' },
  { text: "1st day of every month", value: '1' },
  { text: "2nd day of every month", value: '2' },
  { text: "3rd day of every month", value: '3' },
  { text: "4th day of every month", value: '4' },
  { text: "5th day of every month", value: '5' },
  { text: "6th day of every month", value: '6' },
  { text: "7th day of every month", value: '7' },
  { text: "8th day of every month", value: '8' },
  { text: "9th day of every month", value: '9' },
  { text: "10th day of every month", value: '10' },
  { text: "11th day of every month", value: '11' },
  { text: "12th day of every month", value: '12' },
  { text: "13th day of every month", value: '13' },
  { text: "14th day of every month", value: '14' },
  { text: "15th day of every month", value: '15' },
  { text: "16th day of every month", value: '16' },
  { text: "17th day of every month", value: '17' },
  { text: "18th day of every month", value: '18' },
  { text: "19th day of every month", value: '19' },
  { text: "20th day of every month", value: '20' },
  { text: "21st day of every month", value: '21' },
  { text: "22nd day of every month", value: '22' },
  { text: "23rd day of every month", value: '23' },
  { text: "24th day of every month", value: '24' },
  { text: "25th day of every month", value: '25' },
  { text: "26th day of every month", value: '26' },
  { text: "27th day of every month", value: '27' },
  { text: "28th day of every month", value: '28' },
  { text: "29th day of every month", value: '29' },
  { text: "30th day of every month", value: '30' },
  { text: "31st day of every month", value: '31' },
  { text: "Last day of every month", value: '32' }
];

export const PayByTextAdvanceReminderDays = [
  { text: "Don't Send Reminder", value: '' },
  { text: '1 day in advance', value: '1' },
  { text: '2 days in advance', value: '2' },
  { text: '3 days in advance', value: '3' },
  { text: '4 days in advance', value: '4' },
  { text: '5 days in advance', value: '5' },
  { text: '6 days in advance', value: '6' },
  { text: '7 days in advance', value: '7' },
  { text: '8 days in advance', value: '8' },
  { text: '9 days in advance', value: '9' },
  { text: '10 days in advance', value: '10' },
  { text: '11 days in advance', value: '11' },
  { text: '12 days in advance', value: '12' },
  { text: '13 days in advance', value: '13' },
  { text: '14 days in advance', value: '14' },
];

export const PayByTextReminderDays = [
  { text: "Don't Send Reminder", value: '' },
  { text: 'After 1 day', value: '1' },
  { text: 'After 2 days ', value: '2' },
  { text: 'After 3 days ', value: '3' },
  { text: 'After 4 days ', value: '4' },
  { text: 'After 5 days ', value: '5' },
  { text: 'After 6 days ', value: '6' },
  { text: 'After 7 days ', value: '7' },
  { text: 'After 8 days ', value: '8' },
  { text: 'After 9 days ', value: '9' },
  { text: 'After 10 days ', value: '10' },
  { text: 'After 11 days ', value: '11' },
  { text: 'After 12 days ', value: '12' },
  { text: 'After 13 days ', value: '13' },
  { text: 'After 14 days ', value: '14' },
];

export const NOTIFICATION_PROVIDERS = {
  EVERYWARE: 'EVERYWARE',
  LETUS: 'LETUS'
}