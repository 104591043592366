import React, { useEffect } from 'react';
import LoginCore from '../LoginCore';
import { getCustomLoginTextByClient, getPixelTrackingURLByClient } from './loginCustomConfig';
import { validateAndCleanClientName } from './loginUtils';
import styles from './styles.module.scss';

/**
 * Custom Login component for the clients of Letus
 * @param {*} props 
 * @returns 
 */
const LoginCustom = (props) => {

  const clientName = new URLSearchParams(props.location?.search)?.get('client');
  
  /**
   * Add a pixel tracking script on page load
   */
  useEffect(() => {
    if(window?.location?.hostname === 'let.us') {
      const script = document.createElement('script');
      script.src = getPixelTrackingURLByClient(clientName);
      script.async = true;
      script.id = clientName;
      document.body.appendChild(script);
    }

    return () => {
      const scriptElem = document.getElementById(clientName); 
      if(scriptElem) {
        scriptElem.remove()
      }
    }
  }, [clientName])
  
  
  // Get all the text to be displayed on the login page for a client
  const textByClient = getCustomLoginTextByClient(
    validateAndCleanClientName(clientName)?.cleanedClientName, props.locale
  );
  return (
    <div className={`${styles.containerCustom}`}>
      <div className='row m-0'>
        <div className={`col-md-6 ${styles.colInfo}`}>
          <section>
            <h1>
              {textByClient?.headings?.map((heading, key) => (
                <span key={key}>
                  {heading}
                  <br />
                </span>
              ))}
            </h1>
            <h4>{textByClient.subHeading}</h4>
            <ul>
              {textByClient?.bulletPoints?.map((bulletPoint, key) => (
                <li key={key}>{bulletPoint}</li>
              ))}
            </ul>
            <p className='mt-5'>
              {textByClient?.footerMessages?.map((footerMessage, key) => (
                <span key={key}>
                  {footerMessage}
                  <br />
                </span>
              ))}
            </p>
          </section>
        </div>
        <div className={`col-md-6 ${styles.colLogin}`}>
          <LoginCore
            {...props}
            contentBlockClass='w-100'
            clientLogoHeight={validateAndCleanClientName(clientName)?.clientNameSmall === 'letus' ? 80 : undefined}
            clientName={validateAndCleanClientName(clientName)?.clientNameSmall}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginCustom;
