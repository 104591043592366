import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { injectIntl, intlShape } from "react-intl";
import phoneTypes from "../../util/phoneTypes";

class FieldPhones extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.addPhone = this.addPhone.bind(this);
        this.removePhone = this.removePhone.bind(this);

        this.handleChangePhones = this.handleChangePhones.bind(this);
    }

    /**
     * Add a phone number to the list of phone numbers.
     */
    addPhone() {

        let phones = this.props.parent.state[this.props.model];

        phones.push({
            type: '',
            number: '',
        });

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: phones
        }));
    }

    /**
     * Remove a phone number from the list of phone numbers.
     *
     * @param index - The index of the phone number to remove from the list of phone numbers.
     */
    removePhone(index) {

        let phones = this.props.parent.state[this.props.model];

        phones.splice(index, 1);

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: phones
        }));
    }

    /**
     * Handle changes to the selected phone numbers. Handles both the phone number type and phone number fields for each
     * phone number, passing the updated list back to the parent component's state.
     *
     * @param event - The event container.
     */
    handleChangePhones(event) {

        let phones = this.props.parent.state[this.props.model];

        let phoneIdSplit = (event.target.name).split("-");
        let phoneIdIndex = phoneIdSplit[phoneIdSplit.length - 1];

        // Handle type changes
        if(phoneIdSplit[1] === 'type') {
            phones[phoneIdIndex] = {
                number: phones[phoneIdIndex].number,
                type: event.target.value,
            };
        }

        // Handle number changes
        if(phoneIdSplit[1] === 'number') {
            phones[phoneIdIndex] = {
                number: event.target.value,
                type: phones[phoneIdIndex].type,
            };
        }

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.model]: phones
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A component that allows the user to enter a list of phone numbers, passing the list of phone
     * numbers to the parent component's state.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.phones.map((phone, key) => {

                    return(
                        <div key={key} className="form-group row mb-2">
                            <div className="col-sm-12">
                                <div className={`input-group input-group-${this.props.size}`}>

                                    <select id={`phone-type-${key}`} name={`phone-type-${key}`} value={phone.type} className={`form-control mb-0`} onChange={this.handleChangePhones} disabled={this.props.disabled}>

                                        <option value="">Select a type...</option>

                                        <React.Fragment>
                                            {Object.keys(phoneTypes).map(key => {
                                                return(
                                                    <option key={key} value={key}>{key}</option>
                                                )
                                            })}
                                        </React.Fragment>

                                    </select>

                                    <input id={`phone-number-${key}`} name={`phone-number-${key}`} value={phone.number || ''} type="tel" className={`form-control mb-0`} disabled={this.props.disabled} onChange={this.handleChangePhones} />

                                    {!this.props.disabled &&
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-primary" type="button" onClick={() => this.removePhone(key)}>
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw"/>
                                        </button>
                                    </div>
                                    }

                                </div>

                            </div>
                        </div>
                    );
                })}

                {!this.props.disabled &&
                <div className={`btn btn-outline-primary btn-sm ${this.props.phones.length > 0 ? 'mt-2' : 'mt-0'}`} onClick={() => this.addPhone()}>
                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw"/> Add phone
                </div>
                }

            </React.Fragment>
        )
    };
}

FieldPhones.propTypes = {
    intl: intlShape.isRequired,
};

FieldPhones.defaultProps = {
    size: 'md'
};

export default injectIntl(FieldPhones);
