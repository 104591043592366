import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import PaymentFlow from "../../common/Payment";
import Propertii from "../../common/Propertii";
import $ from "jquery";

class Payment extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            companyOwnerList: [],
            saasCompanyId:'',
            acceptedPaymentTypes: [],
            validationList: [],
        };
    }

    componentDidMount() {

        let billingAccountQuery = {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId', 'manageBalance','displayChargeCodes']
                },
                pc: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'c.parentId',
                    alias: 'pc',
                    returnFields: ['name', 'chargeCodes', 'integrationId']
                },
            }
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/search`, {
            orderBy: billingAccountQuery.orderBy,
            orderByFields: billingAccountQuery.orderByFields,
            conditionList: billingAccountQuery.conditionList,
            joins: billingAccountQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                companyOwnerList: response.data.records,
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}/saas`, {
                headers: this.generateRequestHeaders()
            }).then(response2 => {

                this.setState(prevState => ({
                    ...prevState,
                    saasCompanyId: response2.data?.companyId,
                    acceptedPaymentTypes: response2.data?.acceptedPaymentTypes
                }));


            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component when administrator collect SaaS fee.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    {!this.state.companyOwnerList &&
                        <Breadcrumb parentPath="/manager/tenants" parentPage="Tenants" childPage="Make a Payment" />
                    }

                    {this.state.companyOwnerList !== null && this.state.saasCompanyId &&
                        <PaymentFlow parent={this}
                                     userId={this.props.userSession.sessionRole.id}
                                     userType={this.props.userSession.sessionRole.type}
                                     companyOwnerList={this.state.companyOwnerList}
                                     source={'SASSPRICING'}
                                     saasCompanyId={this.state.saasCompanyId}
                                     acceptedPaymentTypes={this.state.acceptedPaymentTypes}
                                     userSession={this.props.userSession}
                                     redirectPath={`/admin/transactions`}/>
                    }

                </div>

            </div>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);