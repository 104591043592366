import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import InvoiceFlow from "../../common/Invoice";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Invoice extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            company: {},
            invoice: {},

            validationList: [],

        };
    }

    /**
     * On mounting of the component, fetch the company data based on the company ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                company: response.data,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles customers accepting invitations.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/companies" parentPage="Companies" childPath={`/admin/companies/${this.props.match.params.companyId}/invoices`} childPage={this.state.company.name} subChildPage="Invoice" />

                    <InvoiceFlow company={this.state.company} invoice={(this.props.location && this.props.location.state && this.props.location.state.invoice) ? this.props.location.state.invoice : {}} userId={this.props.userSession.sessionRole.id} history={this.props.history} />

                </div>

            </div>
        )
    };
}

Invoice.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Invoice);