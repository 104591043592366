import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import FieldDate from "../../common/FieldDate";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import FieldTextarea from "../../common/FieldTextarea";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import ReceiptPaymentTransaction from "../../common/ReceiptPaymentTransaction";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class FundsTransfers extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            searchFilter: '',
            startDate: null,
            endDate: null,
            status: null,

            fundsTransfer: {},
            paymentTransaction: {},

            fundsTransferList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            paymentTransactionList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            fundsTransferQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [{
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'disbursmentMode',
                    operator: 'NOT_EQUALS',
                    fieldValue: 'MANUAL'
                },],
                joins: {
                    ma: {
                        targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                        joinField: 'merchantAccountId',
                        alias: 'ma',
                        returnFields: ['companyId', 'accountNumber', 'currency']
                    },
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'ma.companyId',
                        alias: 'c',
                        returnFields: ['name']
                    }
                }
            },

            paymentTransactionQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [],
                joins: {
                    pl: {
                        targetRecordType: 'TYPE_PROPERTY_LEASE',
                        joinField: 'billingAccountId',
                        alias: 'pl',
                        returnFields: ['propertyId', 'companyId', 'unit']
                    },
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1']
                    },
                    c: {
                        targetRecordType: 'TYPE_CUSTOMER',
                        joinField: 'userId',
                        alias: 'c',
                        returnFields: ['firstName', 'lastName']
                    },
                    co: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'co',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
                    }
                }
            },

            validationList: [],

        };

        this.searchFundsTransfers = this.searchFundsTransfers.bind(this);
        this.searchPaymentTransactions = this.searchPaymentTransactions.bind(this);
        this.filterFundsTransfers = this.filterFundsTransfers.bind(this);
        this.viewFundsTransfer = this.viewFundsTransfer.bind(this);
        this.viewPaymentTransaction = this.viewPaymentTransaction.bind(this);
        this.editFundsTransfer = this.editFundsTransfer.bind(this);
        this.batchFailFundsTransfer = this.batchFailFundsTransfer.bind(this);
    }

    /**
     * On mounting of the component, initialize the list of funds transfers by performing a basic funds transfer search.
     * If a specific funds transfer was provided from an external state, load the funds transfer immediately.
     */
    componentDidMount() {

        if(this.props.location.state && this.props.location.state.fundsTransferId) {
            this.viewFundsTransfer(this.props.location.state.fundsTransferId);
        }

        this.searchFundsTransfers(1, 25, this.state.fundsTransferQuery);
    }

    /**
     * Handle selecting a funds transfer record by fetching the relevant funds transfer data and initializing the funds
     * transfer view modal.
     *
     * @param fundsTransferId - The ID of the funds transfer record selected.
     */
    viewFundsTransfer(fundsTransferId) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/search?recordsPerPage=1&page=1`, {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: fundsTransferId
                },
            ],
            joins: {
                ma: {
                    targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                    joinField: 'merchantAccountId',
                    alias: 'ma',
                    returnFields: ['companyId', 'accountNumber', 'currency']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'ma.companyId',
                    alias: 'c',
                    returnFields: ['name']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                fundsTransfer: response.data.records[0],
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        let paymentTransactionQuery = this.state.paymentTransactionQuery;

        paymentTransactionQuery.conditionList = [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'fundsTransferId',
                operator: 'EQUALS',
                fieldValue: fundsTransferId
            },
        ];

        this.searchPaymentTransactions(1, 25, paymentTransactionQuery);

        $('#funds-transfer').modal('show');
    }

    /**
     * View a specific payment transaction receipt within a funds transfer.
     *
     * @param paymentTransaction - The payment transaction to view.
     */
    viewPaymentTransaction(paymentTransaction) {

        this.setState(prevState => ({
            ...prevState,
            paymentTransaction: paymentTransaction
        }));

        $('#funds-transfer').modal('hide');
        $('#receipt-payment-transaction').modal('show');
    }

    /**
     * Edit an existing funds transfer's status. If the status of the funds transfer is being set to 'FAILED', a reason
     * for the failure must be provided. If the status of the funds transfer is being set to 'COMPLETE', a settlement
     * date must be provided.
     *
     * @param status - The new status of the funds transfer.
     */
    editFundsTransfer(status) {

        let patchData = {
            status: status
        };

        if(status === 'FAILED') {
            patchData.error = this.state.fundsTransfer.error;
        }

        if(status === 'COMPLETE') {
            patchData.settlementDate = this.state.fundsTransfer.settlementDate;
        }

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/${this.state.fundsTransfer.id}/update`, patchData, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'primary',
                        code: 'admin.operations.efts.status.' + status
                    },
                    fields: {},
                }],
            });

            this.searchFundsTransfers(this.state.fundsTransferList.page, this.state.fundsTransferList.recordsPerPage, this.state.fundsTransferQuery);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Fail multiple funds transfers in bulk with a matching filename.
     *
     * @param event - The event container.
     */
    batchFailFundsTransfer(event) {

        event.preventDefault();

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/failbatch`, {
            file: this.state.fundsTransfer.file,
            error: this.state.fundsTransfer.error
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                spinner: false,
                validationList: [{
                    alert: {
                        type: 'primary',
                        code: 'admin.operations.efts.fail'
                    },
                    values: {
                        file: this.state.fundsTransfer.file,
                        count: response.data
                    }
                }],
            });
        }).catch(error => {
            this.handleValidation(error);
        });

        $('#bulk-fail-funds-transfer').modal('hide');

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of funds transfers.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchFundsTransfers(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/funds_transfer/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                fundsTransferList: response.data,
                fundsTransferQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of payment transactions in a funds transfer.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchPaymentTransactions(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                paymentTransactionList: response.data,
                paymentTransactionQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the funds transfer search query and initiating a new
     * search.
     *
     * @param event - The event container.
     */
    filterFundsTransfers(event) {

        if (event != null) {
            event.preventDefault();
        }

        let fundsTransferQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [{
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'disbursmentMode',
                operator: 'NOT_EQUALS',
                fieldValue: 'MANUAL'
            },],
            joins: {
                ma: {
                    targetRecordType: 'TYPE_MERCHANT_ACCOUNT',
                    joinField: 'merchantAccountId',
                    alias: 'ma',
                    returnFields: ['last4', 'companyId', 'accountNumber', 'currency']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'ma.companyId',
                    alias: 'c',
                    returnFields: ['name', 'legalName']
                }
            }
        };

        if (this.state.searchFilter !== '') {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'last4,confirmationId,ma.accountNumber,c.name,c.legalName',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.searchFilter
                }
            );
        }

        if(this.state.status !== null && this.state.status !== '') {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: this.state.status
                },
            );
        }

        if(this.state.startDate !== null) {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'GREATER_THAN_OR_EQUALS',
                    fieldValue: this.state.startDate
                },
            );
        }

        if(this.state.endDate !== null) {
            fundsTransferQuery.conditionList.push(
                {
                    type: 'DATE',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'createDate',
                    operator: 'LESS_THAN_OR_EQUALS',
                    fieldValue: this.state.endDate
                },
            );
        }

        this.setState({
            fundsTransferQuery: fundsTransferQuery
        });

        this.searchFundsTransfers(1, 25, fundsTransferQuery);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A list of funds transfers (EFTs) in the system.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="EFT Management" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    EFT Management
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#bulk-fail-funds-transfer" className="btn btn-primary btn-sm">
                                        <FontAwesomeIcon icon={['fas', 'skull-crossbones']} className="fa-fw" /> Bulk-Fail EFTs
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={this.filterFundsTransfers}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                                   labelColums="0" placeholder="Filter EFTs..." formGroup={false}
                                                   parent={this} value={this.state.searchFilter} />
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldSelect id="status" placeholder="Status" labelColumns="0" fieldColumns="12" parent={this} value={this.state.status}>
                                            <option value="">All Statuses</option>
                                            <option value="INPROCESS">Processing</option>
                                            <option value="COMPLETE">Complete</option>
                                            <option value="FAILED">Failed</option>
                                            <option value="RESEND">Resend</option>
                                        </FieldSelect>
                                    </div>
                                    <div className="media-body align-self-center mr-3">
                                        <FieldDate id="startDate" labelColumns="0"
                                                    fieldColumns="12" parent={this} 
                                                    value={this.state.startDate}
                                                    selectsRange={true} startDate={this.state.startDate}
                                                    endDate={this.state.endDate}/>
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{createDate: 'Date', settlementDate: 'Settlement', 'c.name': 'Company', last4: 'Bank Account', 'ma.accountNumber': 'Merchant Account', amount: 'Amount', status: 'Status'}}
                               columnWidths={['14%', '14%', '14%', '14%', '14%', '14%', '14%']}
                               headerClass="c-pointer"
                               data={this.state.fundsTransferList}
                               query={this.state.fundsTransferQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchFundsTransfers}>
                            <tbody>
                            {this.state.fundsTransferList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewFundsTransfer(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY">
                                                    {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.settlementDate &&
                                                <Moment format="MMM DD, YYYY">
                                                    {data.settlementDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            {data.joins &&
                                            <div className="">
                                                {data.joins?.c?.name}
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            <div className="">
                                                ******{data.last4}
                                            </div>
                                        </td>
                                        <td>
                                            {data.joins &&
                                            <div className="">
                                                {data.joins?.ma?.accountNumber}
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            {data.amount &&
                                            <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                <FormattedNumber value={data.amount} style={`currency`} currency={data.joins ? data.joins?.ma?.currency : 'USD'}/>
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            {data.status &&
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + data.status + ".class"})}`}/>
                                                <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + data.status}/></span>
                                            </div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">

                                <div className="modal-header bg-dark text-white border-bottom-0">
                                    <h5 className="modal-title" id="funds-transfer-label">
                                        EFT Details
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                {this.state.fundsTransfer.error &&
                                <div className="modal-body bg-danger border-top-0">
                                    <p className="mb-0 text-white">
                                        This EFT was marked as failed for the following reason: {this.state.fundsTransfer.error}
                                    </p>
                                </div>
                                }

                                <div className="modal-body modal-body-table">
                                    <table className="table mb-0 border-bottom">
                                        <tbody>
                                        <tr className="small">
                                            <td className="">
                                                Create Date
                                            </td>
                                            <td className="text-right">
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.fundsTransfer.createDate}
                                                </Moment>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Settlement Date
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.settlementDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {this.state.fundsTransfer.settlementDate}
                                                </Moment>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Company Name
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                <div className="">
                                                    {this.state.fundsTransfer.joins?.c?.name}
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Bank Account
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    ******{this.state.fundsTransfer.last4}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Merchant Account Number
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.joins &&
                                                <div className="">
                                                    {this.state.fundsTransfer.joins?.ma?.accountNumber}
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Amount
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.amount &&
                                                <div className={this.state.fundsTransfer.amount < 0 ? 'text-danger' : ''}>
                                                    <FormattedNumber value={this.state.fundsTransfer.amount} style={`currency`} currency={this.state.fundsTransfer.joins ? this.state.fundsTransfer.joins?.ma?.currency : 'USD'} />
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Status
                                            </td>
                                            <td className="text-right">
                                                {this.state.fundsTransfer.status &&
                                                <div className="text-nowrap">
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.fundsTransfer.eftStatus." + this.state.fundsTransfer.status + ".class"})}`}/>
                                                    <span className="ml-1"><FormattedMessage id={"enum.fundsTransfer.eftStatus." + this.state.fundsTransfer.status}/></span>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Filename
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.fundsTransfer?.file}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Confirmation ID
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.fundsTransfer?.confirmationId}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Internal ID
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.fundsTransfer?.id}
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                    <Table columns={{createDate: 'Date', settlementDate: 'Settled', amount: 'Amount', 'c.firstName': 'Tenant', paymentProviderId: 'Processor'}}
                                           columnWidths={['20%', '20%', '20%', '20%', '20%']}
                                           headerClass="c-pointer"
                                           data={this.state.paymentTransactionList}
                                           query={this.state.paymentTransactionQuery}
                                           sortEnabled={true}
                                           recordsEnabled={true}
                                           paginationEnabled={true}
                                           updateFunction={this.searchPaymentTransactions}>
                                        <tbody>
                                        {this.state.paymentTransactionList.records.map((data, key) => {
                                            return(
                                                <tr key={key} onClick={() => this.viewPaymentTransaction(data)} className="c-pointer">
                                                    <td>
                                                        <div className="">
                                                            <Moment format="MMM DD, YYYY">
                                                                {data.createDate}
                                                            </Moment>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data.settlementDate &&
                                                        <div className="">
                                                            <Moment format="MMM DD, YYYY">
                                                                {data.settlementDate}
                                                            </Moment>
                                                        </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                            {data.amount &&
                                                            <FormattedNumber value={data.amount} style={`currency`} currency={data.currency} />
                                                            }
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {data.joins && data.joins.c &&
                                                        <div className="">
                                                            {data.joins?.c?.firstName} {data.joins?.c?.lastName}
                                                        </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="">
                                                            <FormattedMessage id={`enum.paymentProviderId.${data.paymentProviderId}`} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                        </tbody>
                                    </Table>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-3">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#funds-transfer").modal("hide")}>Close</button>
                                        </div>
                                        <div className="col">
                                            <div className="text-right">
                                                {this.state.fundsTransfer.status === 'INPROCESS' &&
                                                <React.Fragment>
                                                    <div className="btn btn-primary btn-lg mr-2" onClick={() => {$("#funds-transfer").modal("hide"); $("#complete-funds-transfer").modal("show")}}>
                                                        Mark as Completed
                                                    </div>
                                                    <div className="btn btn-primary btn-lg" onClick={() => {$("#funds-transfer").modal("hide"); $("#fail-funds-transfer").modal("show")}}>
                                                        Mark as Failed
                                                    </div>
                                                </React.Fragment>
                                                }
                                                {this.state.fundsTransfer.status === 'COMPLETE' && this.state.fundsTransfer.processor === 'PAYSAFE_CASH' &&
                                                    <React.Fragment>
                                                    <div className="btn btn-primary btn-lg" onClick={() => {$("#funds-transfer").modal("hide"); $("#fail-funds-transfer").modal("show")}}>
                                                    Mark as Failed
                                                    </div>
                                                    </React.Fragment>
                                                }
                                                {this.state.fundsTransfer.status === 'FAILED' &&
                                                <div className="btn btn-primary btn-lg" onClick={() => {$("#funds-transfer").modal("hide"); $("#resend-funds-transfer").modal("show")}}>
                                                    Resend
                                                </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <ReceiptPaymentTransaction userType="TYPE_ADMIN" history={this.props.history} displayFeeAmount={true} displayAdvanced={true} paymentTransaction={this.state.paymentTransaction}>

                        <div className="float-left">
                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $("#funds-transfer").modal('show')}>
                                <FormattedMessage id="button.back" />
                            </button>
                        </div>

                    </ReceiptPaymentTransaction>

                    <Modal id="resend-funds-transfer" theme="primary" iconType="fas" iconName="question-circle" title="Resend EFT" body="Are you sure you want to resend this EFT? All transactions within the EFT will be sent again on the next disbursement, and a new EFT record will be created.">
                        <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#resend-funds-transfer").modal("hide"); $("#funds-transfer").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.editFundsTransfer('RESEND')}} className="btn btn-primary btn-lg" data-dismiss="modal">
                            Resend EFT
                        </button>
                    </Modal>

                    <div className="modal fade" id="complete-funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="complete-funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="service-fee-label">
                                        Mark EFT as Complete
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Are you sure you want to set the status of this EFT to complete? If so, please provide a settlement date for this EFT below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldDate id="settlementDate" label="Settlement Date" required={true} labelColumns="0" fieldColumns="12" fieldClass="form-control-md my-2" labelClass="col-form-label-md d-none" model="fundsTransfer" parent={this} value={this.state.fundsTransfer['settlementDate']} />

                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#complete-funds-transfer").modal("hide"); $("#funds-transfer").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button onClick={() => {this.editFundsTransfer('COMPLETE')}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                Mark EFT as Complete
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="fail-funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="fail-funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">

                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="service-fee-label">
                                        Mark EFT as Failed
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>

                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Are you sure you want to set the status of this EFT to failed? If so, please provide a reason for this change below.
                                    </p>
                                </div>

                                <div className="modal-body">

                                    <FieldTextarea id="error" label="Failure Reason" model="fundsTransfer" required={true}
                                                   labelColumns="0" fieldColumns="12" fieldClass="form-control-sm my-2"
                                                   labelClass="col-form-label-sm d-none" maxLength="200" parent={this}
                                                   value={this.state.fundsTransfer.error} />

                                </div>

                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col-4">
                                            <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$("#fail-funds-transfer").modal("hide"); $("#funds-transfer").modal("show")}}>
                                                <FormattedMessage id="button.back" />
                                            </button>
                                        </div>
                                        <div className="col-8 text-right">
                                            <button onClick={() => {this.editFundsTransfer('FAILED')}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                Mark EFT as Failed
                                            </button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="bulk-fail-funds-transfer" tabIndex="-1" role="dialog" aria-labelledby="bulk-fail-funds-transfer-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <form onSubmit={this.batchFailFundsTransfer}>

                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="service-fee-label">
                                            Bulk-Fail EFTs
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>

                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            Enter the filename associated with EFT(s) that you would like to marked as failed in bulk, in addition to providing a reason for the failure(s). This tool will only bulk-fail EFTs within a 6 month period.
                                        </p>
                                    </div>

                                    <div className="modal-body">

                                        <FieldText id="file" label="Filename" model="fundsTransfer" required={true} parent={this} value={this.state.fundsTransfer.file} />

                                        <FieldTextarea id="error" label="Failure Reason" model="fundsTransfer" required={true} maxLength="200" parent={this} value={this.state.fundsTransfer.error} />

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#bulk-fail-funds-transfer").modal("hide")}>
                                                    <FormattedMessage id="button.close" />
                                                </button>
                                            </div>
                                            <div className="col-8 text-right">
                                                <button type="submit" className="btn btn-primary btn-lg">
                                                    Mark EFT(s) as Failed
                                                </button>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

FundsTransfers.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(FundsTransfers);