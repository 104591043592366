import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import CountryCollection from '../../constants/CountryConstants';
import * as constants from '../../util/constants';
import { CA_POSTAL_REGEX, formatCanadaPostalCode, formatCreditCardNo, US_POSTAL_REGEX } from '../../util/formatting';
import generateRequestHeaders from '../../util/generateRequestHeaders';
import { getCardBrandByPeek } from '../../util/getCardBrand';
import provinces from '../../util/provinces';
import states from '../../util/states.json';
import Alert from './Alert';
import { useInput } from './hooks/useInput';
import { useValidationList } from './hooks/useValidationList';

const NewCardModalFC = (props) => {
  /****** Form State ******/
  /*********************/

  const { value: cardNo, setValue: setCardNo } = useInput('');
  const { value: expirationDate, setValue: setExpirationDate, handleChange: handleChangeExpirationDate } = useInput('');
  const { value: fullName, setValue: setFullName, handleChange: handleChangeFullName } = useInput('');
  const { value: cvv, setValue: setCvv, handleChange: handleChangeCvv } = useInput('');
  const { value: country, setValue: setCountry, handleChange: handleChangeCountry } = useInput(props.selectedCountry || '');
  const { value: street, setValue: setStreet, handleChange: handleChangeStreet } = useInput('');
  const { value: suite, setValue: setSuite, handleChange: handleChangeSuite } = useInput('');
  const { value: city, setValue: setCity, handleChange: handleChangeCity } = useInput('');
  const { value: province, setValue: setProvince, handleChange: handleChangeProvince } = useInput('');
  const { value: postalCode, setValue: setPostalCode } = useInput('');
  const { validationList, addErrorToValidationList, setAlertMessage, clearValidationList} = useValidationList([]);
  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/
  // TODO: The state handled by this method needs to be wired in a more parent-level component - leaving commented out as per convo w/ Arsh
  // const setEditCardValues = () => {
  //   let editCardValues = (props.cards && props.selectedCard ? 
  //     props.cards.filter(card => {
  //       return card.id === props.selectedCard
  //     })[0] : null
  //   );
  //   if (editCardValues) {
  //     setCardNo(editCardValues.cardNumber);
  //     setExpirationDate(editCardValues.expiryYear + '-' + editCardValues.expiryMonth);
  //     setFullName(editCardValues.nameOnCard);
  //     setCvv(editCardValues.cvv);
  //     setCountry(editCardValues.country);
  //     setStreet(editCardValues.street);
  //     setSuite(editCardValues.suite);
  //     setCity(editCardValues.city);
  //     setProvince(editCardValues.province);
  //     setPostalCode(editCardValues.postalCode);
  //   }
  // }
  
  const addCardButtonPress = (event, props) => {
    event.preventDefault();

    const cardNumb = cardNo?.split(' ')?.join('');
    
    getCardBrandByPeek(cardNumb).then(response => {
      
      const cardBrand = response?.data?.brand;
      
      if(!cardBrand) {
        /**
         * !Review the following message with the product team
         */
        setAlertMessage("CC number you have entered appears to be invalid.");
        return;
      }
  
      // ! We need to find a way to tokenize this card here instead of passing all the card details to the next step
      if (props && props.addCard && props.callbackFunction) {
        props.setShowSpinner(true);
        createCCPaymentMethod()
          .then((response) => {
            props.setShowSpinner(false);
            props.addCard({
              ...response.data,
              cardNumber: cardNumb,
              expiryYear: expirationDate?.split('-')?.[0],
              expiryMonth: expirationDate?.split('-')?.[1],
              brand: cardBrand,
              nameOnCard: fullName,
              cvv: cvv,
              country: country,
              street: street,
              suite: suite,
              city: city,
              province: province,
              postalCode: postalCode,
            });
            $('#newCardModal').modal('hide');
            clearCardState();
            props.callbackFunction();
          })
          .catch((error) => {
            props.setShowSpinner(false);
            addErrorToValidationList(error);
          });
      }
    }).catch(error => {
      addErrorToValidationList(error);
    });
  };

  /**
   * Initialize a new instance of a credit_card payment method.
   */
  const createCCPaymentMethod = () => {
    return axios.get(`${constants.REACT_APP_HOST_API_URL}/credit_card/new`, {
      headers: generateRequestHeaders(),
    });
  };

  /**
   * Reset Card state
   */
  const clearCardState = () => {
    setCardNo('');
    setExpirationDate('');
    setFullName('');
    setCvv('');
    setCountry('');
    setStreet('');
    setSuite('');
    setCity('');
    setProvince('');
    setPostalCode('');
    clearValidationList();
  }

  /**
   * Reset Card state when the modal is closed
   */
  $('#newCardModal').on('hidden.bs.modal', function () {
    clearCardState();
  });

  /**
   * set the year and month in the format yyyy-mm for the CC expiry date
   */
  const [currentYearAndMonth, setCurrentYearAndMonth] = useState(null);
  useEffect(()=>{
    const currentTime = new Date();
    const month = currentTime.getMonth() + 1;
    const year = currentTime.getFullYear();

    setCurrentYearAndMonth(`${year}-${month < 10 ? '0' + month : month}`);
  }, []);
  /*********************/
  /*********************/

  /*** FORMAT POSTAL ***/
  /*********************/
  const handleChangePostalCode = (event) => {
    if (country === 'CA') {
      setPostalCode(formatCanadaPostalCode(String(event.target.value)).toUpperCase());
    } else {
      setPostalCode(event.target.value);
    }
  };
  /*********************/
  /*********************/

  /*** FORMAT CC NO ****/
  /*********************/
  const handleChangeCardNo = (event) => {
    setCardNo(formatCreditCardNo(event.target.value))
  };
  /*********************/
  /*********************/
  return (
    <div
      className='modal fade'
      id='newCardModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='newCardModalLabel'
      aria-hidden='true'
    >
      <Alert validationList={validationList} />
      <div className='modal-dialog modal-dialog-centered modal-lg' role='document'>
        <div className='modal-content p-3'>
          <form onSubmit={(event) => addCardButtonPress(event, props)}>
            <div className='modal-header border-0 justify-content-center'>
              <h5 className='modal-title'>Add A New Credit or Debit Card</h5>
            </div>
            <div className='modal-body'>
              <div className='row'>
                <div className='col-sm'>
                  <label htmlFor='cardNumbNC'>Card Number*</label>
                  <input
                    type="tel" inputMode="numeric"
                    placeholder="xxxx xxxx xxxx xxxx"
                    className='form-control'
                    id='cardNumbNC'
                    value={cardNo}
                    required
                    maxLength={24}
                    onChange={handleChangeCardNo}
                  />

                  <label htmlFor='fullNameNC'>Full Name on Card*</label>
                  <input
                    type='input'
                    className='form-control'
                    id='fullNameNC'
                    value={fullName}
                    required
                    onChange={handleChangeFullName}
                  />

                  <label htmlFor='countryNC'>Country*</label>
                  <select
                    name='Country'
                    id='countryNC'
                    required
                    className='form-control'
                    value={country}
                    onChange={handleChangeCountry}
                  >
                    <option value=''></option>
                    {CountryCollection.map((country, index) => (
                      <option key={index} value={country.value}>
                        {country.text}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='col-sm'>
                  <label htmlFor='expiryNC'>Expiry Date (MM/YYYY)*</label>
                  <input
                    type='month'
                    className='form-control'
                    id='expiryNC'
                    required
                    value={expirationDate}
                    min={currentYearAndMonth}
                    onChange={handleChangeExpirationDate}
                  />

                  <label htmlFor='cvvNC'>CVV*</label>
                  <input
                    type="tel" inputMode="numeric"
                    placeholder="---"
                    className='form-control'
                    id='cvvNC'
                    required
                    value={cvv}
                    onChange={handleChangeCvv}
                    maxLength={4}
                  />

                  <label htmlFor='streetNC'>Street*</label>
                  <input
                    type='input'
                    className='form-control'
                    id='streetNC'
                    required
                    value={street}
                    onChange={handleChangeStreet}
                  />
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-sm'>
                  <label htmlFor='suiteNC'>Suite</label>
                  <input
                    type='input'
                    className='form-control'
                    id='suiteNC'
                    value={suite}
                    onChange={handleChangeSuite}
                  />
                </div>
                <div className='col-sm'>
                  <label htmlFor='cityNC'>City*</label>
                  <input type='input' className='form-control' id='cityNC' required value={city} onChange={handleChangeCity} />
                </div>
                {/* Displaying Province drop down if CA, States DD if US, otherwise a State text field for other countries: */}
                <div className='col-sm'>
                  <label htmlFor='provinceNC'>{country === 'CA' ? 'Province' : 'State'}*</label>
                  {country === 'US' || country === 'CA' ? (
                    <select
                      name='provinceNC'
                      id='provinceNC'
                      className='form-control'
                      required
                      value={province}
                      onChange={handleChangeProvince}
                    >
                      <option value=''></option>
                      {country === 'CA'
                        ? Object.keys(provinces).map((province, index) => (
                            <option key={index} value={province}>
                              {provinces[province]}
                            </option>
                          ))
                        : Object.keys(states).map((state, index) => (
                            <option key={index} value={state}>
                              {states[state]}
                            </option>
                          ))}
                    </select>
                  ) : (
                    <input
                      type='input'
                      className='form-control'
                      id='provinceNC'
                      required
                      value={province}
                      onChange={handleChangeProvince}
                    />
                  )}
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-sm'>
                  <label htmlFor='postalNC'>{country === 'US' ? 'ZIP Code' : 'Postal Code'}*</label>
                  <input
                    type='input'
                    className='form-control'
                    maxLength={country === 'CA' ? 7 : 15}
                    pattern={country === 'CA' ? CA_POSTAL_REGEX : (country === 'US' ? US_POSTAL_REGEX : undefined)}
                    id='postalNC'
                    required
                    value={postalCode}
                    onChange={handleChangePostalCode}
                  />
                  {(country === 'US' ||  country === 'CA') &&
                    <small className="form-text text-muted">
                      {country === 'US' ? `Valid Zip code format - '12345' or '12345-6789'.` : `Valid Postal Code format - 'A1A 1A1'.`}
                    </small>
                  }
                </div>
              </div>
            </div>
            <div className='modal-footer border-0 justify-content-between'>
              <button type='button' className='btn btn-light float-left' data-dismiss='modal'>
                Back
              </button>
              <button type='submit' className='btn btn-primary'>
                Add My Card
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewCardModalFC;
