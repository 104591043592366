import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {getCustomValidityMessage} from '../../../../constants/HyperWalletConstants';

const PaymentSummaryStepPaypalFields = (props) => {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='paymentAmountPS'>Payment Amount</label>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='dollar-addon'>
                $
              </span>
            </div>
            <input
              type="number" min={5} max={3000} step="any"
              className='form-control'
              id='paymentAmountPS'
              value={props.paymentAmount}
              onChange={props.handleChangePaymentAmount}
              aria-describedby='dollar-addon'
              onInvalid={(e)=>{e.target.setCustomValidity(getCustomValidityMessage(e.target.validity))}}
              onInput={(e)=>{e.target.setCustomValidity('')}}
            />
          </div>
        </div>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='paymentMethodPS'>Payment Method</label>
          <div className='form-control' id='paymentMethodPS'>
            <div className='media'>
              <div className='align-self-center mr-3 text-center'>
                <FontAwesomeIcon icon={['fab', 'paypal']} className='fa-lg  ' />
              </div>
              <div className='media-body align-self-center'>
                <p className='mb-0'>Paypal</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummaryStepPaypalFields;
