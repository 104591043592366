import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios/index";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import * as constants from '../util/constants';
import Alert from "./common/Alert";
import Propertii from "./common/Propertii";
import * as Scroll from 'react-scroll';
import modernTower from "../media/img/backgrounds/modern-building.jpg";
import womanChair from "../media/img/backgrounds/woman-chair.jpg";
import creditCards from "../media/img/mockups/credit-cards.png";
import paymentFlow from "../media/img/mockups/payment-flow.png";
import recurringPayment from "../media/img/mockups/recurring-payment.png";
import transactions from "../media/img/mockups/transactions.png";
import americanExpress from "../media/img/payments/american-express.png";
import bankAccount from "../media/img/payments/bank-account.png";
import discover from "../media/img/payments/discover.png";
import mastercard from "../media/img/payments/mastercard.png";
import unionPay from "../media/img/payments/unionpay.png";
import visa from "../media/img/payments/visa.png";
import Spinner from "./common/Spinner";

class ForgotPassword extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            username: '',
            submitted: false,
            verificationUrl: '',
            validationList: [],
        };

        this.renderForm = this.renderForm.bind(this);
        this.renderSuccess = this.renderSuccess.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    /**
     * Handle change events on fields.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        this.setState({
            [event.target.name]: event.target.value
        });
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        // Handle email not found validation errors by pulling the record type
        const recordType = error.response.data.recordType;

        this.setState({
            validationList: [{
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        });
    }

    /**
     * Handle the forgot password form submission. Upon success, the user is redirected to a confirmation component,
     * letting them know that an email has been sent to the email address provided with a link to reset their password.
     *
     * @param event - The event container.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/forgotpassword`, {
            username: this.state.username
        }).then(response => {
            this.setState({
                submitted: true,
                verificationUrl: encodeURIComponent(response.data.verificationCode)
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - A standard forgot password flow where the user is asked to enter their email to reset their
     * account password. A message will be displayed upon successful submission of their email.
     */
    render() {
        let renderFragment;
        let ScrollLink = Scroll.Link;

        // Render the form or success message fragment based on form submission state
        if(!this.state.submitted) {
            renderFragment = this.renderForm();
        } else {
            renderFragment = this.renderSuccess();
        }

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${modernTower}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container">

                        <div className="row align-items-center">
                            <div className="col-md-6">

                                <h1 className="text-white mb-4">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroHeading : '{HERO_HEADING}'}
                                </h1>
                                <ul className="fa-ul benefit-list text-white lead mb-5">
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint1 : '{HERO_POINT_1}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint2 : '{HERO_POINT_2}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint3 : '{HERO_POINT_3}'}</li>
                                    <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" /> {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroPoint4 : '{HERO_POINT_4}'}</li>
                                </ul>
                                <div className="mb-5">
                                    <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                    <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                    <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                    <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                    <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                    <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="jumbotron jumbotron-fluid rounded shadow px-4">
                                    {renderFragment}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            Letus is made for renters, by renters
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Payments
                                </h3>
                                <p className="">
                                    With flexible payment options and recurring schedules, making rent payments is a breeze.
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'bell']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Notifications
                                </h3>
                                <p className="">
                                    Receive real-time payment updates and detailed payment receipts, direct to your inbox.
                                </p>
                                <ScrollLink to="notifications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'home-lg']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Leases
                                </h3>
                                <p className="">
                                    Letus has you covered with support from multiple leases to multiple roommates.
                                </p>
                                <ScrollLink to="leases" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'gift']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Rewards
                                </h3>
                                <p className="">
                                    Take advantage of one of your largest monthly expenses with your favorite rewards card.
                                </p>
                                <ScrollLink to="rewards" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Make paying your rent simple and rewarding
                            </h1>
                            <p className="mb-4 lead">
                                Despise those late fees? With Letus, you'll never miss another payment again with monthly auto payments, using a bank account or rewards credit card that suits you best.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Multiple Payment Methods</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'calendar-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Setup Scheduled Payments</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={paymentFlow} alt="Letus offers your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="notifications" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Stay in the loop with real time notifications
                            </h1>
                            <p className="mb-4 lead">
                                Never lose track of your payment history again. Reviewing the details of all your past and upcoming rent payments can be done anytime, anywhere from your Letus dashboard.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'bell']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Real-Time Payment Updates</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Detailed Payment Receipts</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={recurringPayment} alt="Receive email notifications from all of your tenants' payments, even if a payment was failed or charged back, all while funds are deposited directly into your bank account" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="leases" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Supported for multiple leases and roommates
                            </h1>
                            <p className="mb-4 lead">
                                Whether you're paying for your son or daughter's college dorm, or splitting payments for your dorm with your roommate, Letus has you covered no matter the scenario.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'stopwatch']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Track Upcoming Payments</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'home-heart']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Review Lease Details</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={transactions} alt="Letus's intuitive payments platform and user interface will save you time and make paying rent easier than ever before" className="img-sm-fluid my-3" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="rewards" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Maximize your rewards potential and build your credit
                            </h1>
                            <p className="mb-4 lead">
                                Make your rent payments with your favorite rewards credit card and enjoy the cash-back or points redemption from one of your largest monthly payments, all while building your credit.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'mobile-alt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Make Payments Anytime</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'gift']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Earn Credit Card Rewards</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={creditCards} alt="Making rent payments with Letus allows tenants to build their credit history and earn points from their favorite rewards credit card" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-4 py-4">
                                Next steps
                            </h3>
                        </div>

                        <div className="row justify-content-center my-5">

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Create your account
                                    </h3>
                                    <p className="">
                                        Set up your Letus tenant account with your rental lease information and payment details.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0 border-left border-right">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Get verified
                                    </h3>
                                    <p className="">
                                        Your property manager will verify your information and invite you to complete your account.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Make your payments
                                    </h3>
                                    <p className="">
                                        Start making one time payments, or never miss a payment again with monthly auto payments.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.companyName ? this.state.leaseApplicationPage.customFields.companyName : 'Your property manager'} is now accepting online payments with LetUs! Sign up today and enjoy simpler rent payments.
                                </p>
                            </div>
                        </div>
                        <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Get Started
                        </ScrollLink>
                    </div>
                </div>

            </div>
        );
    }

    /**
     * Render the forgot password email entry form fragment.
     *
     * @returns {*} - A simple form where the user is required to enter the email address associated with their account.
     */
    renderForm() {
        const client = new URLSearchParams(this.props.location.search).get('client');
        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <h1 className="display-5 mb-3">
                    <FormattedMessage id="static.forgot.heading" />
                </h1>

                <p className="mb-0">
                    <FormattedMessage id="static.forgot.body" />
                </p>

                <hr className="my-4" />

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.handleSubmit}>

                    <div className="form-group row mb-4">
                        <div className="col-sm-12">
                            <div className="input-group input-group-lg">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">
                                        <FontAwesomeIcon icon={['fas', 'user']} />
                                    </span>
                                </div>
                                <input name="username" type="email" placeholder={formatMessage({ id: "field.email"})} value={this.state['username']} onChange={this.handleChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['username'] ? 'is-invalid' : '') : ''}`} />
                                {this.state.validationList[0] &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + this.state.validationList[0].fields['username']} />
                                </div>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {!client &&
                        <div className="col-lg-6">
                            <button className="btn btn-lg btn-outline-primary btn-block" onClick={() => window.history.back()}>
                                <FormattedMessage id="button.cancel" />
                            </button>
                        </div>}
                        <div className="col-lg-6">
                            <button type="submit" className="btn btn-lg btn-primary btn-block">
                                <FormattedMessage id="button.continue" />
                            </button>
                        </div>
                    </div>

                </form>

            </React.Fragment>
        );
    }

    /**
     * Render the success message fragment.
     *
     * @returns {*} - A message that instructs the user to look for a password reset email in their inbox. A button
     * redirects the user back to the log in component.
     */
    renderSuccess() {
        const client = new URLSearchParams(this.props.location.search).get('client');

        return(
            <React.Fragment>

                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <h4 className="card-title">
                            <FormattedMessage id="static.forgot.confirm.heading" />
                        </h4>
                        <p className="card-text">
                            <FormattedMessage id="static.forgot.confirm.body" />
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        {!client &&
                        <Link to="/login" className="btn btn-lg btn-primary btn-block">
                            <FormattedMessage id="button.ok" />
                        </Link>
                        }
                        {client &&
                        <Link to={`/signin?client=${client}`} className="btn btn-lg btn-primary btn-block">
                            <FormattedMessage id="button.ok" />
                        </Link>
                        }
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

ForgotPassword.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ForgotPassword);