import React from 'react';
import Breadcrumb from "../../common/Breadcrumb";
import ChangePassword from "../../common/Password";
import Propertii from "../../common/Propertii";

class Password extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The change password interface for customers.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/customer/account" parentPage="Account" childPage="Change Password" />

                    <ChangePassword backRedirect="/customer/account" userId={this.props.userSession.sessionRole.id} saveMessage="customer.account.password.saved" />

                </div>
            </div>
        )
    };
}

export default Password;