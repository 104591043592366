const headings = ['Pay your rent', 'on your terms.'];
const subHeading = 'Online payment with bank-level security.';
const bulletPoints = [
  'Choose your payment method.',
  "Pay when it's convenient for you.",
  'Accessible from any mobile or desktop device.',
  'Roommates can pay separately.',
  'Instant confirmation of payment.',
];
const footerMessages = [
  null,
  'Your email is already registered.',
  'Please refer to your registration email from your landlord,',
  'or click "Forgot your password?" to recover your account.',
];

//FR translation
const headingsFR = ['Payez votre loyer', 'à votre guise.'];
const subHeadingFR = 'Paiement en ligne sécurisé.';
const bulletPointsFR = [
  'Choisissez votre méthode de paiement.',
  'Payez quand cela vous convient.',
  "Accessible depuis n'importe quel appareil mobile ou de bureau",
  'Les colocataires peuvent payer séparément.',
  'Confirmation instantanée du paiement',
];
const footerMessagesFR = [
  null,
  'Votre email est déjà enregistré.',
  "Veuillez vous referer a l'email d'inscription de votre propriétaire,",
  'ou cliquez sur "Mot de passe oublié ?" pour récupérer votre compte.',
];

/**
 * This function returns appropriate login page text based on the client and locale
 * NOTE - currently since the text remains same for all of the clients,
 * we don't have the above logic completely in place
 * @param {string} client - the company name for whom the login page is
 * @param {string} locale - current locale
 * @returns {object} - all the text required to render login page
 */
export const getCustomLoginTextByClient = (client, locale) => {
  if (locale === 'fr') {
    footerMessagesFR[0] = `Vous vivez dans un immeuble du ${client}?`;
  } else {
    footerMessages[0] = `Live in a ${client} building?`;
  }

  return {
    headings: locale === 'fr' ? headingsFR : headings,
    subHeading: locale === 'fr' ? subHeadingFR : subHeading,
    bulletPoints: locale === 'fr' ? bulletPointsFR : bulletPoints,
    footerMessages: locale === 'fr' ? footerMessagesFR : footerMessages,
  };
};

/**
 * Return tracking URL based on the client name
 * @param {string} clientName
 * @returns
 */
export const getPixelTrackingURLByClient = (clientName) => {
  let url = null;
  const clientNameUpperCase = clientName?.toUpperCase();
  switch (clientNameUpperCase) {
    case 'MIDWEST':
      url='https://tag.simpli.fi/sifitag/3da217f0-5d37-013b-58ea-0cc47a1f72a4';
      break;
    case 'DEVON':
      url='https://tag.simpli.fi/sifitag/13649130-5d31-013b-5efe-0cc47a8ffaac';
      break;
    case 'KANEFF':
      url='https://tag.simpli.fi/sifitag/7dd47f00-5d36-013b-a9db-0cc47abd0334';
      break;
    case 'BGO':
      url='https://tag.simpli.fi/sifitag/cac54d00-5d36-013b-a9db-0cc47abd0334';
      break;
    case 'GEC':
      url='https://tag.simpli.fi/sifitag/efe9d2a0-5d36-013b-58ea-0cc47a1f72a4';
      break;
    case 'CCC':
      url='https://tag.simpli.fi/sifitag/234340e0-5d37-013b-58ea-0cc47a1f72a4';
      break;    
    case 'DREWLO':
      url='https://tag.simpli.fi/sifitag/44ceac20-8e0c-013b-5c90-0cc47a1f72a4';
      break;
    case 'CAPREIT':
      url='https://tag.simpli.fi/sifitag/2027dd20-8e0c-013b-5c90-0cc47a1f72a4';
      break;
    case 'KILLAM':
      url='https://tag.simpli.fi/sifitag/2f5ac960-8e0c-013b-62d7-0cc47a8ffaac';
      break;
    case 'MAYFIELD':
      url='https://tag.simpli.fi/sifitag/39c13270-8e0c-013b-5c90-0cc47a1f72a4';
      break;
    case 'METCAP':
      url='https://tag.simpli.fi/sifitag/3cb62e60-8e0c-013b-add9-0cc47abd0334';
      break;
    case 'OBSIDIAN':
      url='https://tag.simpli.fi/sifitag/342668e0-8e0c-013b-add9-0cc47abd0334';
      break;
    case 'CLV':
      url='https://tag.simpli.fi/sifitag/2aad4230-8e0c-013b-add9-0cc47abd0334';
      break;
    default:
      break;
  }
  return url;
};
