import React, { Component } from 'react';
import bmo from "../../media/img/logos/bmo.png";
import geoswift from "../../media/img/logos/geoswift.png";
import loadhub from "../../media/img/logos/loadhub.png";
import paypal from "../../media/img/logos/paypal.png";
import paysafe from "../../media/img/logos/paysafe.png";
import rentmoola from "../../media/img/logos/logo-letus-dark-new.png";
import bluesnap from "../../media/img/logos/bluesnap.png";


class LogoPaymentProvider extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - Renders a payment provider logo based on the payment provider ID in the props.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.paymentProviderId === 'PAYSAFE' &&
                <div className={'position-relative'}>
                    <p className="small text-danger position-absolute mb-0" style={{left:0, bottom:'-10px'}}>OLD - NO PROCESSING</p>
                    <img src={paysafe} className="img-fluid w-75" alt="Paysafe" style={{filter:'grayscale(100)'}} />
                </div>
                }
                {(this.props.paymentProviderId === 'PAYSAFE_SPLIT_PAY' || this.props.paymentProviderId === 'PAYSAFE_CASH') &&
                <img src={paysafe} className="img-fluid w-75" alt="Paysafe Split-Pay" />
                }
                {this.props.paymentProviderId === 'PAYPAL' &&
                <img src={paypal} className="img-fluid w-75" alt="PayPal" />
                }
                {this.props.paymentProviderId === 'RENTMOOLA_PP' &&
                <img src={rentmoola} className="img-fluid w-75" alt="Letus" />
                }
                {this.props.paymentProviderId === 'GEOSWIFT' &&
                <img src={geoswift} className="img-fluid w-75" alt="Geoswift" />
                }
                {this.props.paymentProviderId === 'LOADHUB' &&
                <img src={loadhub} className="img-fluid w-75" alt="Loadhub" />
                }
                {this.props.paymentProviderId === 'BMO' &&
                <img src={bmo} className="img-fluid w-75" alt="BMO Bank of Montreal" />
                }
                {this.props.paymentProviderId === 'BLUESNAP' &&
                <img src={bluesnap} className="img-fluid w-75" alt="BlueSnap" />
                }

            </React.Fragment>
        )
    };
}

export default LogoPaymentProvider;