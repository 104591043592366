import React from 'react';
import styles from './css/HyperwalletPayment.module.css';

const HyperWalletCard = (props) => {
  return (
    <div className={`card ${props.styleCard ? styles[props.styleCard] : styles.hwCard} py-3 px-2`}>
      <img className={`card-img-top mt-3 ${styles.hwCardImg}`} src={props.src} alt='Card cap' loading='lazy' />
      <div className='card-body'>
        <h5 className='card-title'>{props.heading}</h5>
        <p className='card-text'>{props.info}</p>
      </div>
    </div>
  );
};

export default HyperWalletCard;
