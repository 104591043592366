import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import ButtonClose from "./ButtonClose";
import FieldDate from "./FieldDate";
import FieldProperty from "./FieldProperty";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Table from "./Table";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

class ReportTenants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            tenant: {},

            tenantList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            startDate: moment().subtract(30, 'days').format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDate: null,

            parameters: {
                propertyId: null
            },

            propertyList: [],
            selectedProperty: null,

            validationList: [],

        };

        this.searchTenants = this.searchTenants.bind(this);
        this.filterTenants = this.filterTenants.bind(this);
        this.downloadTenants = this.downloadTenants.bind(this);
        this.viewTenant = this.viewTenant.bind(this);
        this.searchProperties = this.searchProperties.bind(this);
        this.transformDataForPDF = this.transformDataForPDF.bind(this);
        this.getHeaderForPDF = this.getHeaderForPDF.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        this.searchProperties();
        this.searchTenants(1, 25, filter);
    }

    /**
     * View the detailed information of a tenant by initializing the tenant view modal.
     *
     * @param tenant - The tenant model to view.
     */
    viewTenant(tenant) {

        this.setState(prevState => ({
            ...prevState,
            tenant: tenant
        }));

        $('#tenant').modal('show');
    }

    /**
     * Update the data table of tenants.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param filter - The filters to apply to the search.
     */
    searchTenants(page, recordsPerPage, filter) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_tenants?recordsPerPage=${recordsPerPage}&page=${page}`, filter, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                tenantList: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Apply the available search filters on the tenant list and perform a new search.
     *
     * @param event - The event container.
     */
    filterTenants(event) {

        if(event != null) {
            event.preventDefault();
        }

        let filter = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.fromDate != null) {
            filter.startDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.endDate = this.state.endDate;
        }

        if(this.state.parameters.propertyId != null) {
            filter.parameters.propertyId = this.state.parameters.propertyId;
        }

        this.searchTenants(1, 25, filter);
    }

    /**
     * Search for a list of all available properties for filtering.
     */
    searchProperties() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'c.parentId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ],
            joins: {
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                },
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                propertyList: response.data.records
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
    downloadTenants(event) {

        event.preventDefault();

        $("#download-format-confirm").modal("show")
    }

    /**
     * Download the tenant report in CSV format. The downloaded file is based on the filters set by the user.
     *
     * 
     */
     downloadTenantsCSV() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_tenants/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the tenant report in PDF format. The downloaded file is based on the filters set by the user.
     *
     * 
     */
     downloadTenantsPDF() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_tenants/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape', 'mm', [297, 1100]);

            doc.setFontSize(12);
            doc.text('Tenants Report', 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak: false,
                theme: 'grid',
            
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 30 },
                    1: { cellWidth : 20 },
                    2: { cellWidth : 40 },
                    3: { cellWidth : 20 },
                    4: { cellWidth : 30 },
                    5: { cellWidth : 30 },
                    6: { cellWidth : 30 },
                    7: { cellWidth : 20 },

                    8: { cellWidth : 30 },
                    9: { cellWidth : 30 },
                    10: { cellWidth : 30 },
                    11: { cellWidth : 20 },
                    12: { cellWidth : 30 },
                    13: { cellWidth : 30 },
                    14: { cellWidth : 20 },
                    15: { cellWidth : 20 },
                    16: { cellWidth : 20 },
                    17: { cellWidth : 20 },

                    18: { cellWidth : 20 },
                    19: { cellWidth : 20 },
                    20: { cellWidth : 20 },
                    21: { cellWidth : 20 },
                    22: { cellWidth : 20 },
                    23: { cellWidth : 20 },
                    24: { cellWidth : 20 },
                    25: { cellWidth : 20 },
                    26: { cellWidth : 20 },
                    27: { cellWidth : 20 },
                    28: { cellWidth : 20 },

                    29: { cellWidth : 20 },
                    30: { cellWidth : 20 },
                    31: { cellWidth : 20 },
                    32: { cellWidth : 20 },
                    33: { cellWidth : 40 },
                    34: { cellWidth : 40 },
                    35: { cellWidth : 20 },
                    36: { cellWidth : 40 },
                    
                    37: { cellWidth : 30 },
                    38: { cellWidth : 30 },
                    39: { cellWidth : 30 },
                    40: { cellWidth : 30 },
                    41: { cellWidth : 30 },


                }    
              });
            doc.save('Tenants Report.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
      
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - The data returned by the endpoint to be converted into PDF.
     */
     getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
      
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }

    /**
     * Render the component.
     *
     * @returns {*} - The tenant report component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Tenants
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadTenants(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={this.filterTenants} autoComplete="off">
                            <div className="media">
                                <div className="media-body align-self-center">
                                    <FieldDate id="startDate" labelColumns="0"
                                                fieldColumns="12" parent={this} 
                                                value={this.state.startDate}
                                                selectsRange={true} startDate={this.state.startDate}
                                                endDate={this.state.endDate}/>
                                </div>
                                
                                <div className="media-body align-self-center mr-3">
                                    <FieldProperty id="propertyId" label="Property" model="parameters" fieldColumns="12"
                                                   labelClass="d-none" labelColumns="0"
                                                   value={this.state.selectedProperty} parent={this}
                                                   options={this.state.propertyList} allPropertiesOption={true} />
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table tableClass="table-bordered table-hover table-responsive"
                           columns={{
                               createDate: 'Create Date',
                               firstName: 'First Name',
                               lastName: 'Last Name',
                               phone: 'Mobile Phone',
                               phoneStatus: 'Phone Status',
                               txt2PayEligible: 'Txt2Pay Eligibility',
                               alternatePhone: 'Alternate Phone',
                               email: 'Email',
                               accountNumber: 'Account ID',
                               propertyName: 'Property Name',
                               unit: 'Unit',
                               street1: 'Street 1',
                               street2: 'Street 2',
                               city: 'City',
                               province: 'Province',
                               postalCode: 'Postal Code',
                               origin: 'Origin'
                           }}
                           minColumnWidths={[
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                               '200px',
                           ]}
                           headerClass=""
                           data={this.state.tenantList}
                           filters={{
                               startDate: this.state.startDate,
                               endDate: this.state.endDate,
                               parameters: this.state.parameters
                           }}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.searchTenants}>
                        <tbody>
                        {this.state.tenantList.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.viewTenant(data)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.createDate &&
                                            <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                {data.createDate}
                                            </Moment>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.firstName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.phone}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.phoneStatus}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.txt2PayEligible}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.alternatePhone}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.email}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.accountNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.propertyName}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.unit}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.street1}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.street2}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.city}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.province}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.postalCode}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.origin}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="tenant" tabIndex="-1" role="dialog" aria-labelledby="tenant-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        Tenant
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                    <tr className="small">
                                        <td className="">
                                            Create Date
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.createDate &&
                                                <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                    {this.state.tenant.createDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            First Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.firstName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Last Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.lastName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Mobile Phone
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.phone}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Phone Status
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.phoneStatus}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Txt2Pay Eligibility
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.txt2PayEligible}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Alternate Phone
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.alternatePhone}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Email
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.email}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Account ID
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.accountNumber}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Property Name
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.propertyName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Unit
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.unit}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Street 1
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.street1}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Street 2
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.street2}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            City
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.city}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Province
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.province}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Postal Code
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.postalCode}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Origin
                                        </td>
                                        <td className="text-right">
                                            <div className="">
                                                {this.state.tenant.origin}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>

                <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadTenantsCSV()}>
                            CSV Format
                        </button>
                        <button onClick={() => this.downloadTenantsPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            PDF Format
                        </button>
                </Modal>

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportTenants);