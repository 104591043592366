import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import processingIcon from '../../media/img/icons/processing-icon.svg';
import * as constants from '../../util/constants';
import generateRequestHeaders from '../../util/generateRequestHeaders';
import ButtonClose from './ButtonClose';

const ReceiptPaymentTransactionActions = (props) => {
  const [manager, setManager] = useState({});

  useEffect(() => {
    if (props.userType === 'TYPE_MANAGER') {
      searchManager();
    }
    // eslint-disable-next-line
  }, [props.userType]);

  /**
   * Load the record for the manager to determine if they are an admin
   */
  const searchManager = () => {
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/manager/${props.userSession?.sessionRole?.id}`, {
        headers: generateRequestHeaders(),
      })
      .then((response) => {
        setManager(response.data);
      })
      .catch((error) => {
        props.handleValidation(error);
      });
  };

  return (
    <>
      <div className='float-left'>
        <ButtonClose />
      </div>

      <div className='float-right'>
        <div className='dropdown'>
          <div
            className='btn btn-primary btn-lg dropdown-toggle'
            role='button'
            id='payment-transaction-actions'
            data-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            Actions
          </div>

          <div className='dropdown-menu' aria-labelledby='payment-transaction-actions'>
            {(props.userType === 'TYPE_MANAGER'
              ? props.paymentTransaction.paymentMethodType === 'TYPE_CREDIT_CARD' ||
                props.paymentTransaction.paymentMethodType === 'TYPE_PAY_PAL'
              : true) &&
              props.paymentTransaction.paymentProviderId !== 'PAYSAFE_CASH' &&
              (props.displayAdvanced || manager?.roles?.includes('PRIMARY_MANAGER')) &&
              props.paymentTransaction.status === 'COMPLETE' &&
              props.paymentTransaction.transactionType === 'PAYMENT' && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#refund-payment-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'undo']} className='fa-fw' /> Refund Transaction
                </div>
              )}

            {props.displayAdvanced &&
              props.userType === 'TYPE_ADMIN' &&
              props.paymentTransaction.paymentProviderId === 'PAYSAFE_SPLIT_PAY' &&
              ((props.paymentTransaction.status === 'COMPLETE' &&
                !props.paymentTransaction.settlementDate) ||
                props.paymentTransaction.status === 'PENDING') &&
              (props.paymentTransaction.transactionType === 'PAYMENT' ||
                props.paymentTransaction.transactionType === 'REFUND') && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#cancel-payment-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'undo']} className='fa-fw' /> Cancel Transaction
                </div>
              )}

            {props.userType !== 'TYPE_CUSTOMER' &&
              props.userType !== 'TYPE_LANDLORD' &&
              props.paymentTransaction.status === 'COMPLETE' &&
              props.paymentTransaction.joins &&
              ((props.paymentTransaction.joins.co &&
                props.paymentTransaction.joins.co.integrationId) ||
                (props.paymentTransaction.joins.pc &&
                  props.paymentTransaction.joins.pc.integrationId)) && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#push-payment-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'forward']} className='fa-fw' /> Push Transaction
                </div>
              )}

            {props.userType !== 'TYPE_CUSTOMER' &&
              props.userType !== 'TYPE_LANDLORD' &&
              props.paymentTransaction.status === 'COMPLETE' &&
              props.paymentTransaction.joins &&
              ((props.paymentTransaction.joins.co &&
                props.paymentTransaction.joins.co.integrationId) ||
                (props.paymentTransaction.joins.pc &&
                  props.paymentTransaction.joins.pc.integrationId)) &&
              props.paymentTransaction.integration != null &&
              (props.paymentTransaction.integration.pushStatus === 'FAILED' ||
                props.paymentTransaction.integration.pushStatus === 'TIMEDOUT') && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#manually-push-payment-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'keyboard']} className='fa-fw' /> Mark as Manually
                  Entered
                </div>
              )}

            {props.displayAdvanced &&
              (props.paymentTransaction.reversedAmount > 0 ||
                props.paymentTransaction.reversedFeeAmount > 0) && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => props.searchPaymentTransactionReversals()}
                >
                  <FontAwesomeIcon icon={['fas', 'history']} className='fa-fw' /> Reversal History
                </div>
              )}

            {props.displayAdvanced && !props.paymentTransaction.settlementDate && (
              <div
                className='dropdown-item c-pointer'
                onClick={() => {
                  $('#receipt-payment-transaction').modal('hide');
                  $('#settle-payment-transaction').modal('show');
                }}
              >
                <FontAwesomeIcon icon={['fas', 'calendar-check']} className='fa-fw' /> Mark as
                Settled
              </div>
            )}

            {props.displayAdvanced &&
              props.paymentTransaction?.source === 'MARKETPLACE' &&
              props.paymentTransaction?.payoutStatus !== 'REJECTED' && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#mark-HWpayment-rejected').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fa', 'ban']} className='fa-fw' /> Payment rejected by
                  Manager
                </div>
              )}

            {props.displayAdvanced &&
              props.paymentTransaction?.source === 'MARKETPLACE' &&
              props.paymentTransaction?.payoutStatus !== 'CHEQUE' && (
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#mark-HWpayment-bycheque').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fa', 'money-check']} className='fa-fw' /> Payment sent by
                  cheque
                </div>
              )}

            {props.displayAdvanced && props.paymentTransaction.status === 'ONHOLD' && (
              <div
                className='dropdown-item c-pointer'
                onClick={(e) => props.updateTransactionStatus(e, 'INPROCESS')}
              >
                <img
                  src={processingIcon}
                  alt='processing icon'
                  style={{ width: '1.25em' }}
                  className={`fa-fw va-b mr-1 ${props.upperNav ? 'mr-md-1' : 'mr-md-0'}`}
                />{' '}
                Mark as Processing
              </div>
            )}

            {/* {(props.displayAdvanced && (props.paymentTransaction?.status === 'INPROCESS' || props.paymentTransaction?.status === 'COMPLETE') && props.paymentTransaction.paymentProviderId === 'PAYSAFE_CASH') &&
            <div className="dropdown-item c-pointer" onClick={(e) => props.updateTransactionStatus(e, 'ONHOLD')}>
                <FontAwesomeIcon icon={['far', 'hand-paper']} className="fa-fw" /> Mark as On Hold
            </div>
            } */}

            {props.displayAdvanced && props.paymentTransaction.status === 'TIMEDOUT' && (
              <div
                className='dropdown-item c-pointer'
                onClick={() => {
                  $('#receipt-payment-transaction').modal('hide');
                  $('#complete-payment-transaction').modal('show');
                }}
              >
                <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Mark as Completed
              </div>
            )}

            {props.displayAdvanced && props.paymentTransaction.status === 'TIMEDOUT' && (
              <div
                className='dropdown-item c-pointer'
                onClick={() => {
                  $('#receipt-payment-transaction').modal('hide');
                  $('#fail-payment-transaction').modal('show');
                }}
              >
                <FontAwesomeIcon icon={['fas', 'times']} className='fa-fw' /> Mark as Failed
              </div>
            )}

            {props.displayAdvanced && (props.paymentTransaction.transactionType === 'NSF' ||
                (props.paymentTransaction.source === 'NSF_FEE' && props.paymentTransaction.status === 'FAILED')) && (
              <>
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#mark-valid-nsf-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Mark as valid NSF
                </div>
                <div
                  className='dropdown-item c-pointer'
                  onClick={() => {
                    $('#receipt-payment-transaction').modal('hide');
                    $('#mark-invalid-nsf-transaction').modal('show');
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'ban']} className='fa-fw' /> Mark as invalid NSF
                </div>
              </>
            )}

            {props.displayAdvanced && props.paymentTransaction.transactionType === 'CHARGEBACK' && (
                <div
                    className='dropdown-item c-pointer'
                    onClick={() => {
                      $('#receipt-payment-transaction').modal('hide');
                      $('#mark-chargeback-LETUS-processed').modal('show');
                    }}
                >
                  <FontAwesomeIcon icon={['fas', 'calendar-check']} className='fa-fw' /> Complete
                </div>
            )}

            <div className='dropdown-item c-pointer' onClick={() => window.print()}>
              <FontAwesomeIcon icon={['fas', 'print']} className='fa-fw' /> Print Receipt
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiptPaymentTransactionActions;
