import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Table from "../common/Table";
import FieldAddress from "./FieldAddress";
import FieldCheckbox from "./FieldCheckbox";
import FieldSelect from "./FieldSelect";
import Spinner from "./Spinner";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

class Properties extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        let conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN' && this.props.companyId) {
            conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                }
            ]
        }

        this.state = {

            propertySearchFilter: '',
            propertyStreet: '',

            company: {},

            customMerchantAccounts: false,
            creditMerchantAccountList: {},
            walletMerchantAccountList: {},

            integrationId: '',
            paymentProviderId: '',

            integrationSearch: {
                propertyId: '',
                propertyName: '',
                street: ''
            },

            integrationPropertyList: [],

            property: {},

            propertyList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            propertyQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: conditionList,
                joins: {
                    company: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'company',
                        returnFields: ['name']
                    }
                }
            },

            validationList: [],

            subcompanies: [],
            allCompanies: [],
            companiesWithIntegration: [],
            showIntegrationSearch: false,
            forCreateProperty: false,
            companyIdDropdown: '',
        };

        this.searchProperties = this.searchProperties.bind(this);
        this.filterProperties = this.filterProperties.bind(this);
        this.clearFilters = this.clearFilters.bind(this);

        this.editProperty = this.editProperty.bind(this);
        this.redirectProperty = this.redirectProperty.bind(this);
        this.initProperty = this.initProperty.bind(this);
        this.saveProperty = this.saveProperty.bind(this);

        this.initIntegrationProperties = this.initIntegrationProperties.bind(this);
        this.searchIntegrationProperties = this.searchIntegrationProperties.bind(this);
        this.selectIntegrationProperty = this.selectIntegrationProperty.bind(this);

        this.handleChangePropertyIdentifier = this.handleChangePropertyIdentifier.bind(this);
        this.handleChangeCreditMerchantAccount = this.handleChangeCreditMerchantAccount.bind(this);
        this.handleChangeApplePayMerchantAccount = this.handleChangeApplePayMerchantAccount.bind(this);
        this.handleChangeGooglePayMerchantAccount = this.handleChangeGooglePayMerchantAccount.bind(this);

        this.getAllSubcompanies = this.getAllSubcompanies.bind(this);
        this.handleChangeCompany = this.handleChangeCompany.bind(this);
        this.handleChangeCompanyFilter = this.handleChangeCompanyFilter.bind(this);
        
        this.showFormatDownloadPage = this.showFormatDownloadPage.bind(this);
        this.downloadPropReportCSV = this.downloadPropReportCSV.bind(this);
        this.downloadPropReportPDF = this.downloadPropReportPDF.bind(this);

    }

    /**
     * Populate the list of properties on mounting of the component.
     */
    componentDidMount() {

        if(this.props.companyId != null) {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}/fallbackfields`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationId: response.data.integrationId,
                    paymentProviderId: response.data?.paymentProviderId,
                }));

            }).catch(() => {
                // this.handleValidation(error);
            });

            this.getAllSubcompanies(this.props.companyId);
        }

        this.searchProperties(1, 25, this.state.propertyQuery);

        if(this.props.history.location.state && this.props.history.location.state.property) {
            this.editProperty(this.props.history.location.state.property.id);
        }
    }

    /**
     * View a property by redirecting the user to a separate property component.
     *
     * @param propertyId - The ID of the property selected.
     */
    redirectProperty(event, propertyId) {
        if (event.metaKey || event.ctrlKey){
            window.open(`/${this.props.userType.substring(5).toLowerCase()}/properties/${propertyId}/edit`, "_blank");
        } else {
            this.props.history.push(`/${this.props.userType.substring(5).toLowerCase()}/properties/${propertyId}/edit`);
        }
    }

    /**
     * Edit a property record. Fetch the property data using the ID selected, then load the appropriate merchant account
     * mapping data.
     *
     * @param propertyId - The ID of the property selected.
     */
    editProperty(propertyId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = this.props.companyId;
            let customMerchantAccounts = true;
            const paymentProviderId = response.data?.paymentProviderId;

            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT', null, paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_PAY_PAL');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,'TYPE_WALLET', 'APPLE_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId, null, null, paymentProviderId);

            if(response.data.bankMerchantAccountId === null
                && response.data.paypalMerchantAccountId === null
                && response.data.padMerchantAccountId === null
                && response.data.cashMerchantAccountId === null
                && Object.keys(response.data.creditMerchantAccountList).length === 0
                && Object.keys(response.data.walletMerchantAccountList).length === 0) {
                customMerchantAccounts = false;
            }

            this.setState(prevState => ({
                ...prevState,
                property: {
                    ...response.data
                },
                propertyStreet: response.data.street1,
                creditMerchantAccountList: response.data.creditMerchantAccountList,
                walletMerchantAccountList: response.data.walletMerchantAccountList,
                customMerchantAccounts: customMerchantAccounts
            }));

            $('#property').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a property when the user indicates they want to create a new property.
     */
    initProperty() {
        // get all the subcompanies in the dropdown
        this.getAllSubcompanies(this.props.companyId);

        let paymentProviderId = this.state?.paymentProviderId;

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let companyId = this.props.companyId;
            let property = response.data;
            if(property.category === null){
                property.category = 'RESIDENTIAL';
            }
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY', paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT', null, paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_PAY_PAL');
            this.getMerchantAccounts(companyId,'TYPE_CASH');
            this.getMerchantAccounts(companyId,null, null, paymentProviderId);
            this.getMerchantAccounts(companyId,'TYPE_WALLET', 'APPLE_PAY', paymentProviderId);

            this.setState(prevState => ({
                
                ...prevState,
                property: {
                    ...property,
                    companyId: companyId,
                }
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle saving an existing property or creating a new property.
     *
     * @param event - The event container.
     */
    saveProperty(event) {

        event.preventDefault();

        let property = this.state.property;

        property.creditMerchantAccountList = this.state.creditMerchantAccountList;
        property.walletMerchantAccountList = this.state.walletMerchantAccountList;

        Object.entries(property.creditMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.creditMerchantAccountList[key];
            }
        });

        Object.entries(property.walletMerchantAccountList).forEach(([key, value]) => {
            if(value === '' || !this.state.customMerchantAccounts) {
                delete property.walletMerchantAccountList[key];
            }
        });

        if(!this.state.customMerchantAccounts) {
            property.bankMerchantAccountId = null;
            property.paypalMerchantAccountId = null;
            property.cashMerchantAccountId = null;
            property.padMerchantAccountId = null;
        }

        if(this.state.property.createDate == null) {

            property.integrationSettings = {
                pushTransactions : true,
                pullCharges : true,
                pullTenants : true
            }
            
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, property, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        alert: {
                            type: 'primary',
                            code: this.props.createMessage,
                            message: 'Property has been saved'
                        }
                    }],
                });

                this.searchProperties(this.state.propertyList.page, this.state.propertyList.recordsPerPage, this.state.propertyQuery);

                $('#property').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, property, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchProperties(this.state.propertyList.page, this.state.propertyList.recordsPerPage, this.state.propertyQuery);

                $('#property').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting the search filter field by adjusting the email search query and initiating a new search.
     *
     * @param event - The event container.
     */
    filterProperties(event) {

        if(event != null) {
            event.preventDefault();
        }

        this.setState({
            spinner: true
        });

        let propertyQuery = this.state.propertyQuery;

        propertyQuery.conditionList = [];

        if(this.props.userType === 'TYPE_ADMIN' && this.props.companyId) {
            propertyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.props.companyId
                },
            )
        } else if(this.props.userType === 'TYPE_MANAGER' && this.state.companyIdDropdown) {
            propertyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'companyId',
                    operator: 'EQUALS',
                    fieldValue: this.state.companyIdDropdown
                },
            )
        }

        if(this.state.propertySearchFilter !== '') {
            propertyQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.state.propertySearchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'propertyName,street1,city,postalCode,propertyIdentifier',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: this.state.propertySearchFilter
                }
            );
        }

        this.setState({
            propertyQuery: propertyQuery
        });

        this.searchProperties(1, 25, propertyQuery);
    }

    /**
     * Clear all applicable filters and re-run the filter query.
     */
    clearFilters() {

        this.setState({
            propertySearchFilter: '',
            companyIdDropdown: '',
        }, () => {

            this.filterProperties();

        });
    }

    /**
     * Update the data table of properties.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchProperties(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyList: {
                    ...response.data,
                    emptyUnfilteredList: this.state.propertySearchFilter === '' && response.data.records.length === 0
                },
                propertyQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize the search integration properties process by clearing out the search fields and revealing the search
     * integration properties modal.
     */
    initIntegrationProperties() {

        this.setState(prevState => ({
            ...prevState,
            integrationPropertyList: [],
            integrationSearch: {
                propertyId: '',
                propertyName: '',
                street: ''
            }
        }));

        $('#property').modal('hide');
        $('#search-property').modal('show');
    }

    /**
     * Perform a search for properties in the integrated system for selection.
     */
    searchIntegrationProperties(event) {

        event.preventDefault();

        this.setState({
            spinner: true,
            integrationPropertyList: [],
            validationList: [{
                fields: {},
                alert: {
                    type: '',
                    code: ''
                }
            }]
        });

        axios.post(`${constants.REACT_APP_HOST_INTEGRATION_URL}/property/search`, {
            integrationId: this.state.integrationId,
            propertyId: this.state.integrationSearch.propertyId,
            propertyName: this.state.integrationSearch.propertyName,
            street: this.state.integrationSearch.street,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            if(response.data.length > 0) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    integrationPropertyList: response.data
                }));
            }

            if(response.data.length === 0) {
                this.setState({
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'common.properties.integration.null'
                        }
                    }]
                });
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Select a property from the integrated system. Using the data from the integrated system, the fields used to
     * create a property will be pre-filled.
     *
     * @param data - The property that has been selected from the integrated system.
     */
    selectIntegrationProperty(data) {

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                city: data.city,
                country: data.country,
                propertyIdentifier: data.externalId,
                propertyName: data.name,
                postalCode: data.postalCode,
                province: data.province,
                street1: data.street1,
                street2: data.street2,
            },
        }));

        $('#search-property').modal('hide');
        $('#property').modal('show');
    }

    /**
     * Handle changes to the account number field.
     *
     * @param event - The event container.
     */
    handleChangePropertyIdentifier(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            property: {
                ...prevState.property,
                propertyIdentifier: event.target.value
            },
        }));
    }

    /**
     * Handle changes to the default credit/debit merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all credit/debit card brands available.
     *
     * @param event - The event container.
     */
    handleChangeCreditMerchantAccount(event) {

        event.preventDefault();

        let creditMerchantAccountList = this.state.creditMerchantAccountList;
        let creditMerchantAccountId = event.target.value;

        creditMerchantAccountList['VISA'] = creditMerchantAccountId;
        creditMerchantAccountList['VISA_ELECTRON'] = creditMerchantAccountId;
        creditMerchantAccountList['MASTERCARD'] = creditMerchantAccountId;
        creditMerchantAccountList['MAESTRO'] = creditMerchantAccountId;
        creditMerchantAccountList['AMERICAN_EXPRESS'] = creditMerchantAccountId;
        creditMerchantAccountList['DISCOVER'] = creditMerchantAccountId;
        creditMerchantAccountList['DINERS_CLUB'] = creditMerchantAccountId;
        creditMerchantAccountList['JCB'] = creditMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            creditMerchantAccountList: creditMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Apple Pay merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all Apple Pay wallets available.
     *
     * @param event - The event container.
     */
    handleChangeApplePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let applePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['APPLE_PAY'] = applePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Handle changes to the default Google Pay merchant account field. By selecting a merchant account from this
     * field, the same merchant account will be applies to all Google Pay wallet available.
     *
     * @param event - The event container.
     */
    handleChangeGooglePayMerchantAccount(event) {

        event.preventDefault();

        let walletMerchantAccountList = this.state.walletMerchantAccountList;
        let googlePayMerchantAccountId = event.target.value;

        walletMerchantAccountList['GOOGLE_PAY'] = googlePayMerchantAccountId;

        this.setState(prevState => ({
            ...prevState,
            walletMerchantAccountList: walletMerchantAccountList,
        }));
    }

    /**
     * Fetch all the subcompanies under a particular company
     * (Also fetches the details of the parent company)
     * @param id - parentCompanyId 
     * @author Arsh - LP-1199
     */
    getAllSubcompanies(parentCompanyId) {
        try {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search`, {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'id',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'OR',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'parentId',
                        operator: 'EQUALS',
                        fieldValue: parentCompanyId,
                    }
                ]
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                const subCompanies = response.data.records; // also includes the parent company

                const companiesWithIntegrationObj = {}; // company id and integration id map 
                subCompanies.forEach(subCompany => {
                    if(subCompany.id && subCompany.integrationId) {
                        companiesWithIntegrationObj[subCompany.id] = subCompany.integrationId;
                    }
                    if(!subCompany.legalName) {
                        subCompany.legalName = subCompany.name;
                    }                  
                });

                // handles the show/no-show search option for the first item in the dropdown
                let showIntegrationSearchOptn = false; 
                if(subCompanies.length>0 && companiesWithIntegrationObj[subCompanies[0].id]) {
                    showIntegrationSearchOptn = true;
                }

                this.setState(prevState => ({
                    ...prevState,
                    subcompanies: subCompanies,
                    allCompanies: subCompanies, 
                    companiesWithIntegration: companiesWithIntegrationObj,
                    showIntegrationSearch: showIntegrationSearchOptn,
                    forCreateProperty: true,
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        } catch(err) {
            this.handleValidation(err);
        }

    }

    /**
     * Handle changes on the company selection dropdown
     *
     * @param event - Change company event.
     */
    handleChangeCompany(event) {
        event.preventDefault();
        const companyId = event.target.value;
        
        let showIntegrationSearchOptn = false;
        const companiesWithIntegration = this.state.companiesWithIntegration;
        if(companiesWithIntegration[companyId]) {
            showIntegrationSearchOptn = true;
        }

        this.setState(prevState => ({
            ...prevState,
            property: {...prevState.property, companyId: companyId},
            showIntegrationSearch: showIntegrationSearchOptn,
            integrationId: companiesWithIntegration[companyId] ? companiesWithIntegration[companyId] : null,
        }));

        // also switch corresponsing merchant accounts
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'VISA_ELECTRON');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MASTERCARD');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'MAESTRO');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'AMERICAN_EXPRESS');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DISCOVER');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'DINERS_CLUB');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'JCB');
        this.getMerchantAccounts(companyId,'TYPE_CREDIT_CARD', 'UNION_PAY');
        this.getMerchantAccounts(companyId,'TYPE_BANK_ACCOUNT');
        this.getMerchantAccounts(companyId,'TYPE_PAY_PAL');
        this.getMerchantAccounts(companyId,'TYPE_CASH');
        this.getMerchantAccounts(companyId,null);
    }

    /**
     * Handle changes on the company selection filter dropdown
     *
     * @param event - Change company event.
     */
    handleChangeCompanyFilter(event) {
        event.preventDefault();
        const companyId = event.target?.value;

        this.setState(prevState => ({
            ...prevState,
            companyIdDropdown: companyId,
        }));
    }

    /**
     * Show donwload modal for CSV or PDF  
     * @param event - Event object
     */
    showFormatDownloadPage(event){
        event.preventDefault();

        $("#download-format-confirm").modal("show")
    }

    /**
     * Download the properties report in CSV
     */
    downloadPropReportCSV(){
        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_properties/download`, {}, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the properties report in PDF
     */
    downloadPropReportPDF(){
        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_properties/download`, {}, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape', 'mm', [297, 475]);

            doc.setFontSize(12);
            doc.text('Properties Report', 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak: false,
                theme: 'grid',
            
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 40 },
                    1: { cellWidth : 30 },
                    2: { cellWidth : 15 },
                    3: { cellWidth : 25 },
                    4: { cellWidth : 20 },
                    5: { cellWidth : 35 },
                    6: { cellWidth : 35 },
                    7: { cellWidth : 25 },
                    8: { cellWidth : 25 },
                    9: { cellWidth : 20 },
                }    
            });
            doc.save('Properties.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
    transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
      
    }

    /**
         * Get the header to be passed into jsPDF
         *
         * @param headerData - The data returned by the endpoint to be converted into PDF.
         */
     getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
      
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord property list component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        let columnLabels = {};
        let columnWidths = [];

        if(this.props.hideRegion) {

            columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', postalCode: 'ZIP/Postal Code'};
            columnWidths = ['25%', '25%', '25%', '25%'];

        } else {

            if(this.props.userType === 'TYPE_ADMIN' && !this.props.companyId) {

                columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', province: 'State/Province', 'company.name': 'Company', postalCode: 'ZIP/Postal Code'};
                columnWidths = ['14%', '14%', '14%', '14%', '14%', '14%'];

            } else {

                columnLabels = {propertyName: 'Property', street1: 'Street', city: 'City', province: 'State/Province', country: 'Country', postalCode: 'ZIP/Postal Code'};
                columnWidths = ['14%', '14%', '14%', '14%', '14%', '14%'];

            }

        }

        return(
            <React.Fragment>

            <div className="">

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                {this.props.history.location.state &&
                <Alert validationList={this.props.history.location.state.validationList}/>
                }

                <div className="row">
                    <div className="col-12">

                        <div className="card">

                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        Properties
                                    </div>
                                    {this.props.companyId && this.props.userType === 'TYPE_ADMIN' &&
                                        <div className='col text-right align-download-btn'>
                                            <div className='btn btn-primary btn-sm' onClick={(event) => this.showFormatDownloadPage(event)}>
                                                <FontAwesomeIcon icon={['fas', 'download']} className='fa-fw'/>
                                                {' '}Download Report
                                            </div>
                                        </div>
                                    }
                                    {(this.props.companyId && (this.props.userType === 'TYPE_ADMIN' || (this.props.userType === 'TYPE_MANAGER' && (this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') || (this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER') && this.props.userSession?.accessProperties?.includes("*")))))) &&
                                    <div className="col text-right align-create-property-btn">
                                        <div data-toggle="modal" data-target="#property" className="btn btn-primary btn-sm" onClick={() => this.initProperty()}>
                                            <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Property
                                        </div>
                                    </div>
                                    }
                                </div>
                                <style>
                                    {`
                                    .align-create-property-btn{
                                        max-width: 175px;
                                        padding-left: 0px;
                                    }
                                    .align-download-btn{
                                        padding-right:0px;
                                    }
                                    `}
                                </style>
                            </div>

                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterProperties}>
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="propertySearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12" labelColums="0" placeholder="Filter by building name, street, city, or ZIP/postal code..."
                                                       parent={this} value={this.state.propertySearchFilter}/>
                                        </div>
                                        {(this.props.companyId && this.props.userType === 'TYPE_MANAGER') &&
                                        <div className="media-body align-self-center mr-3">
                                            <FieldSelect id="companyIdDropdown" parent={this} value={this.state.companyIdDropdown} handleChange={this.handleChangeCompanyFilter}>
                                                <React.Fragment>
                                                    <option value="">All Companies</option>
                                                    {this.state.allCompanies?.map((data) => {
                                                        return (
                                                            <option key={data.id} value={data.id}>{data.legalName}</option>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            </FieldSelect>
                                        </div>
                                        }
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {this.state.propertyList.emptyUnfilteredList &&
                                <div className="card-body bg-secondary py-5">
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <div className="text-center text-muted">
                                                <div className="fa-stack fa-2x mb-2">
                                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                    <FontAwesomeIcon icon={['far', 'building']} className="fa-stack-1x fa-inverse" />
                                                </div>
                                            </div>
                                            <div className="text-center text-muted">
                                                <small>
                                                    Properties you add will appear here.
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>

                            {!this.state.propertyList.emptyUnfilteredList &&
                                <Table columns={columnLabels}
                                    columnWidths={columnWidths}
                                    headerClass="c-pointer"
                                    data={this.state.propertyList}
                                    query={this.state.propertyQuery}
                                    sortEnabled={true}
                                    recordsEnabled={true}
                                    updateFunction={this.searchProperties}
                                    tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed">
                                    <tbody>
                                    {this.state.propertyList.records.map((data, key) => {
                                        return (
                                            <tr key={key} onClick={(event) => this.redirectProperty(event, data.id)} className="c-pointer">
                                                <td>
                                                    <div className="">
                                                        {data.propertyName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.street1}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="">
                                                        {data.city}
                                                    </div>
                                                </td>
                                                {!this.props.hideRegion &&
                                                <React.Fragment>

                                                    <td>
                                                        <div className="">
                                                            {data.country === 'CA' &&
                                                            <FormattedMessage id={"province." + data.province}/>
                                                            }
                                                            {data.country === 'US' &&
                                                            <FormattedMessage id={"state." + data.province}/>
                                                            }
                                                        </div>
                                                    </td>

                                                    {(this.props.userType !== 'TYPE_ADMIN' || this.props.companyId) &&
                                                    <td>
                                                        <div className="">
                                                            <FormattedMessage id={"country." + data.country}/>
                                                        </div>
                                                    </td>
                                                    }

                                                    {(this.props.userType === 'TYPE_ADMIN' && !this.props.companyId) &&
                                                    <td>
                                                        {data.joins &&
                                                        <div className="">
                                                            {data.joins.company.name}
                                                        </div>
                                                        }
                                                    </td>
                                                    }

                                                </React.Fragment>
                                                }
                                                <td>
                                                    <div className="">
                                                        {data.postalCode}
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </Table>
                           
                            }

                        </div>

                        <div className="modal fade" id="property" tabIndex="-1" role="dialog" aria-labelledby="property-label" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                <div className="modal-content">
                                    <form onSubmit={this.saveProperty}>
                                        <div className="modal-header bg-dark text-white">
                                            <h5 className="modal-title" id="property-label">
                                                {this.state.property['createDate'] == null &&
                                                <div className="">
                                                    Create Property
                                                </div>
                                                }
                                                {this.state.property['createDate'] != null &&
                                                <div className="">
                                                    {this.state.propertyStreet}
                                                </div>
                                                }
                                            </h5>
                                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <Alert validationList={this.state.validationList} validationType="danger" />

                                            <FieldSelect id="company" label="Company" parent={this} value={this.state.property.companyId} handleChange={this.handleChangeCompany}>
                                                <React.Fragment>
                                                    {this.state.subcompanies.map((data) => {
                                                        return (
                                                            <option key={data.id} value={data.id}>{data.legalName}</option>
                                                        );
                                                    })}
                                                </React.Fragment>
                                            </FieldSelect>

                                            <div className="form-group row">
                                                <label className="col-sm-3 col-form-label col-form-label-sm" htmlFor="propertyIdentifier">
                                                    Property ID
                                                </label>
                                                <div className="col-sm-9">
                                                    <div className="input-group input-group-sm">

                                                        <input id="propertyIdentifier" name="propertyIdentifier" value={this.state.property['propertyIdentifier'] || ""} className="form-control mb-0" onChange={this.handleChangePropertyIdentifier}/>

                                                        {(this.state.forCreateProperty ? this.state.showIntegrationSearch : this.state.integrationId) &&
                                                        <div className="input-group-append">
                                                            <div className="btn btn-secondary" onClick={() => this.initIntegrationProperties()}>
                                                                Search...
                                                            </div>
                                                        </div>
                                                        }

                                                    </div>
                                                </div>
                                            </div>

                                            <FieldText id="propertyName" label="Property Name" model="property" parent={this} value={this.state.property['propertyName']}  />

                                            <FieldAddress required={true} model="property" parent={this} value={this.state.property} />

                                            <FieldText id="units" label="Total Units" model="property" type="number" min="0" step="1" parent={this} value={this.state.property['units']} />
                                                    
                                            <FieldSelect id="category" label="Property Type" model="property" required
                                                parent={this} value={this.state.property['category']}>
                                                    <option value="RESIDENTIAL"selected>Residential</option>
                                                    <option value="COMMERCIAL">Commercial</option>
                                            </FieldSelect>
                                            
                                            {this.props.merchantAccounts &&
                                            <FieldCheckbox id="customMerchantAccounts" label="Merchant Accounts" fieldLabel="This property utilizes custom merchant accounts" parent={this} value={this.state.customMerchantAccounts}/>
                                            }

                                            {this.state.customMerchantAccounts &&
                                            <div className="">

                                                {this.state.property.creditMerchantAccountList &&
                                                <React.Fragment>

                                                    <FieldSelect id="VISA" label="Credit/Debit Card" model="creditMerchantAccountList" parent={this} help="Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards." value={this.state.creditMerchantAccountList['VISA']} handleChange={this.handleChangeCreditMerchantAccount}>
                                                        {this.state.visaMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.visaMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.creditMerchantAccountList['VISA']) {
                                                                    return (
                                                                        <option key={key} value={this.state.creditMerchantAccountList['VISA'] === undefined || this.state.creditMerchantAccountList['VISA'] === null ? "" : data.id}>
                                                                            [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                        }
                                                    </FieldSelect>

                                                    <FieldSelect id="UNION_PAY" label="UnionPay" model="creditMerchantAccountList" parent={this} value={this.state.creditMerchantAccountList['UNION_PAY']}>
                                                        {this.state.unionPayMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.unionPayMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.creditMerchantAccountList['UNION_PAY']) {
                                                                    return (
                                                                        <option key={key} value={this.state.creditMerchantAccountList['UNION_PAY'] === undefined || this.state.creditMerchantAccountList['UNION_PAY'] === null ? "" : data.id}>
                                                                            [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                        }
                                                    </FieldSelect>

                                                </React.Fragment>
                                                }

                                                <FieldSelect id="bankMerchantAccountId" label="Bank Account" model="property" parent={this} value={this.state.property['bankMerchantAccountId']}>
                                                    {this.state.bankMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.bankMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.bankMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value={this.state.property['bankMerchantAccountId'] === undefined || this.state.property['bankMerchantAccountId'] === null ? "" : data.id}>
                                                                        [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                                <FieldSelect id="paypalMerchantAccountId" label="PayPal" model="property" parent={this} value={this.state.property['paypalMerchantAccountId']}>
                                                    {this.state.paypalMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.paypalMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.paypalMerchantAccounts) {
                                                                return (
                                                                    <option key={key} value={this.state.property['paypalMerchantAccountId'] === undefined || this.state.property['paypalMerchantAccountId'] === null ? "" : data.id}>
                                                                        [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                                <FieldSelect id="cashMerchantAccountId" label="Cash" model="property" parent={this} value={this.state.property['cashMerchantAccountId']}>
                                                    {this.state.cashMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.cashMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.cashMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value={this.state.property['cashMerchantAccountId'] === undefined || this.state.property['cashMerchantAccountId'] === null ? "" : data.id}>
                                                                        [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>

                                                <FieldSelect id="padMerchantAccountId" label="Pre-Authorized Debit" model="property" parent={this} value={this.state.property['padMerchantAccountId']}>
                                                    {this.state.padMerchantAccounts &&
                                                    <React.Fragment>
                                                        {this.state.padMerchantAccounts.map((data, key) => {
                                                            if(data.id === data.joins.c.padMerchantAccountId) {
                                                                return (
                                                                    <option key={key} value={this.state.property['padMerchantAccountId'] === undefined || this.state.property['padMerchantAccountId'] === null ? "" : data.id}>
                                                                        [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            } else {
                                                                return (
                                                                    <option key={key} value={data.id}>
                                                                        {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                    </option>);
                                                            }
                                                        })}
                                                        <option value="">-</option>
                                                    </React.Fragment>
                                                    }
                                                </FieldSelect>
                                                <FieldSelect id="ApplePay" label="Apple Pay" model="walletMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList['APPLE_PAY']} handleChange={this.handleChangeApplePayMerchantAccount}>
                                                    {this.state.applePayMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.applePayMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.walletMerchantAccountList['APPLE_PAY']) {
                                                                    return (
                                                                        <option key={key} value={this.state.walletMerchantAccountList['APPLE_PAY'] === undefined || this.state.walletMerchantAccountList['APPLE_PAY'] === null ? "" : data.id}>
                                                                            [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                    }
                                                </FieldSelect>
                                                <FieldSelect id="GooglePay" label="Google Pay" model="walletMerchantAccountList" parent={this} value={this.state.walletMerchantAccountList['GOOGLE_PAY']} handleChange={this.handleChangeGooglePayMerchantAccount}>
                                                    {this.state.googlePayMerchantAccounts &&
                                                        <React.Fragment>
                                                            {this.state.googlePayMerchantAccounts.map((data, key) => {
                                                                if(data.id === data.joins.c.walletMerchantAccountList['GOOGLE_PAY']) {
                                                                    return (
                                                                        <option key={key} value={this.state.walletMerchantAccountList['GOOGLE_PAY'] === undefined || this.state.walletMerchantAccountList['GOOGLE_PAY'] === null ? "" : data.id}>
                                                                            [DEFAULT] {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                } else {
                                                                    return (
                                                                        <option key={key} value={data.id}>
                                                                            {data.accountNumber ? data.accountNumber + ' — ' : ''}{data.name ? data.name + ' — ' : ''}{data.last4 ? '*****' + data.last4 + ' — ' : ''}{formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus})}
                                                                        </option>);
                                                                }
                                                            })}
                                                            <option value="">-</option>
                                                        </React.Fragment>
                                                    }
                                                </FieldSelect>
                                            </div>
                                            }

                                        </div>
                                        <div className="modal-footer bg-secondary d-block">

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="">
                                                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">Close</button>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="text-right">
                                                        <button type="submit" className="btn btn-primary btn-lg">
                                                            {this.state.property.createDate == null &&
                                                            <span className="">Create Property</span>
                                                            }
                                                            {this.state.property.createDate != null &&
                                                            <span className="">Save</span>
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <div className="modal fade" id="search-property" tabIndex="-1" role="dialog" aria-labelledby="search-property-label" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                                <div className="modal-content shadow">
                                    <form onSubmit={this.searchIntegrationProperties}>

                                        <div className="modal-header bg-dark text-white">
                                            <h5 className="modal-title" id="search-property-label">
                                                Search Properties
                                            </h5>
                                            <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                                <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                            </button>
                                        </div>

                                        <div className="modal-body bg-secondary">
                                            <p className="mb-0">
                                                Select the property from your integrated system using the available search fields below.
                                            </p>
                                        </div>

                                        <div className="modal-body">

                                            <Alert validationList={this.state.validationList} validationType="danger"/>

                                            <FieldText id="propertyId" label="Property ID" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyId']} />

                                            <div className="divider">
                                                <span className="small text-muted font-italic text-uppercase">or</span>
                                            </div>

                                            <FieldText id="propertyName" label="Name" model="integrationSearch" parent={this} value={this.state.integrationSearch['propertyName']} />

                                            <FieldText id="street" label="Street" model="integrationSearch" parent={this} value={this.state.integrationSearch['street']} />

                                        </div>

                                        {this.state.integrationPropertyList.length > 0 &&
                                        <div className="modal-body modal-body-table overflow-auto border-top" style={{maxHeight: '400px'}}>
                                            <div className="card-body card-body-table">
                                                <table className="table table-bordered table-hover border">
                                                    <thead>
                                                    <tr>
                                                        <th width="25%">Name</th>
                                                        <th width="25%">Street</th>
                                                        <th width="25%">City</th>
                                                        <th width="25%">Property ID</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.integrationPropertyList.map((data, key) => {
                                                        return (
                                                            <tr key={key} onClick={() => this.selectIntegrationProperty(data)} className="c-pointer">
                                                                <td className="">
                                                                    {data.name}
                                                                </td>
                                                                <td className="">
                                                                    {data.street1}
                                                                </td>
                                                                <td className="">
                                                                    {data.city}
                                                                </td>
                                                                <td className="">
                                                                    {data.externalId}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        }

                                        <div className="modal-footer bg-secondary rounded-bottom d-block">

                                            <div className="row">
                                                <div className="col-6 text-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => $('#create-tenant').modal('show')}>Back</button>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <button type="submit" className="btn btn-primary btn-lg">Search</button>
                                                </div>
                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                body="What format do you want to download the report?" title="Download Format Confirmation">

                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadPropReportCSV()}>
                CSV Format
                </button>
                <button onClick={() => this.downloadPropReportPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                PDF Format
                </button>
            </Modal>
            </React.Fragment>
        )
    };
}

Properties.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Properties);