import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import LetusSplitLogo from '../../../../media/img/logos/logo-letus-split.png';
import {getCustomValidityMessage} from '../../../../constants/HyperWalletConstants';
import HyperWalletCardDropdown from '../HyperWalletCardDropdown';

const PaymentSummaryStepLetusSplitFields = (props) => {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='fundedAmountPS'>
            Funded Amount{' '}
            <span
              tabIndex='0'
              role='button'
              id='fundedAmountPopover'
              data-toggle='popover'
              data-placement='top'
              data-trigger='focus'
              data-content='Total Amount that will be paid to the Property Manager or Landlord on your behalf.'
            >
              <FontAwesomeIcon icon={faInfoCircle} className='bs-stepper-info' size='sm' />
            </span>
          </label>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='dollar-addon'>
                $
              </span>
            </div>
            <input
              type='number'
              min={5}
              max={3000}
              step='any'
              className='form-control'
              id='paymentAmountPS'
              value={props.paymentAmount}
              onChange={props.handleChangePaymentAmount}
              aria-describedby='dollar-addon'
              onInvalid={(e)=>{e.target.setCustomValidity(getCustomValidityMessage(e.target.validity))}}
              onInput={(e)=>{e.target.setCustomValidity('')}}
            />
          </div>
        </div>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='firstInstallmentPS' className="mr-1">Your Payment (1st Installment + Fee)</label>
          <span
            tabIndex='0'
            role='button'
            id='firstInstallmentPopover'
            data-toggle='popover'
            data-placement='top'
            data-trigger='focus'
            data-content='Our installment plan model requires that the 1st installment and fees are collected at the time of the transaction. Future installments will take place on a monthly basis.'
          >
            <FontAwesomeIcon icon={faInfoCircle} className='bs-stepper-info' size='sm' />
          </span>
          <div className='input-group mb-3'>
            <div className='input-group-prepend'>
              <span className='input-group-text' id='dollar-addon'>
                $
              </span>
            </div>
            <input
              type='input'
              className='form-control h-auto'
              id='firstInstallmentPS'
              readOnly
              value={
                isNaN(
                  (Number(props.paymentAmount) / Number(props.installments) + Number(props.processingFees)).toFixed(2)
                )
                  ? 0
                  : (Number(props.paymentAmount) / Number(props.installments) + Number(props.processingFees)).toFixed(2)
              }
              aria-describedby='dollar-addon'
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='paymentMethodPS'>Payment Method</label>
          <div className='form-control' id='paymentMethodPS'>
            <div className='media'>
              <div className='align-self-center mr-1 text-center'>
                <img
                  src={LetusSplitLogo}
                  className='rounded img-fluid bg-light'
                  alt='LetusSplit'
                  title='LetusSplit'
                  width='30'
                />
              </div>
              <div className='media-body align-self-center'>
                <p className='mb-0'>Letus Split</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-6 mt-2'>
          <label htmlFor='selectedCardPS'>Credit Card</label>
          <HyperWalletCardDropdown
            cards={props.cards}
            selectedCard={props.selectedCard}
            setSelectedCard={props.setSelectedCard}
            removeCard={props.removeCard}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 mt-2'>
          <p>Number of Installments: <span className="badge badge-secondary">{props.installments}</span></p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummaryStepLetusSplitFields;
