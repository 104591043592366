import axios from 'axios';
import { useQuery } from 'react-query';
import * as constants from '../../../util/constants';
import { useState } from 'react';

const useMerchantAccounts = (companyId) => {
  const conditionList = [
    {
      type: 'STRING',
      logicalOperator: 'AND',
      openBrackets: null,
      closeBrackets: null,
      fieldName: 'companyId',
      operator: 'EQUALS',
      fieldValue: companyId,
    },
  ];

  const merchantAccountsQuery = {
    orderBy: 'ASC',
    orderByFields: ['name'],
    conditionList: conditionList,
    joins: {
      c: {
        targetRecordType: 'TYPE_COMPANY',
        joinField: 'companyId',
        alias: 'c',
        returnFields: [
          'creditMerchantAccountList',
          'bankMerchantAccountId',
          'cashMerchantAccountId',
          'padMerchantAccountId',
          'paypalMerchantAccountId',
          'splitItMerchantAccountId',
        ],
      },
      pp: {
        targetRecordType: 'TYPE_PAYMENT_PROVIDER',
        joinField: 'paymentProviderId',
        alias: 'pp',
        returnFields: ['supportedCards'],
      },
    },
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      localStorage.getItem('token_type') +
      ' ' +
      localStorage.getItem('access_token'),
  };

  const [merchantAccounts, setMerchantAccounts] = useState({
    visaMerchantAccounts: [],
    visaElectronMerchantAccounts: [],
    mastercardMerchantAccounts: [],
    maestroMerchantAccounts: [],
    americanExpressMerchantAccounts: [],
    discoverMerchantAccounts: [],
    dinersClubMerchantAccounts: [],
    jcbMerchantAccounts: [],
    unionPayMerchantAccounts: [],
    bankMerchantAccounts: [],
    paypalMerchantAccounts: [],
    cashMerchantAccounts: [],
    padMerchantAccounts: [],
  });

  return {
    merchantAccounts,
    merchantAccountsQuery: useQuery(
      'merchantAccounts',
      () =>
        axios
          .post(
            `${constants.REACT_APP_HOST_API_URL}/merchant_account/search`,
            merchantAccountsQuery,
            {
              headers: headers,
            }
          )
          .then((res) => res.data),
      {
        onSuccess: (data) => {
          const visaMerchantAccounts = [];
          const visaElectronMerchantAccounts = [];
          const mastercardMerchantAccounts = [];
          const maestroMerchantAccounts = [];
          const americanExpressMerchantAccounts = [];
          const discoverMerchantAccounts = [];
          const dinersClubMerchantAccounts = [];
          const jcbMerchantAccounts = [];
          const unionPayMerchantAccounts = [];
          const bankMerchantAccounts = [];
          const paypalMerchantAccounts = [];
          const cashMerchantAccounts = [];
          const padMerchantAccounts = [];

          const merchantAccountsData = data?.records;
          merchantAccountsData.forEach((i) => {
            switch (i.paymentType) {
              case 'TYPE_CREDIT_CARD':
                if (!i.joins?.pp?.supportedCards) break;
                if (i.joins.pp.supportedCards.includes('VISA'))
                  visaMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('VISA_ELECTRON'))
                  visaElectronMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('MASTERCARD'))
                  mastercardMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('MAESTRO'))
                  maestroMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('AMERICAN_EXPRESS'))
                  americanExpressMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('DISCOVER'))
                  discoverMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('DINERS_CLUB'))
                  dinersClubMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('JCB'))
                  jcbMerchantAccounts.push(i);
                if (i.joins.pp.supportedCards.includes('UNION_PAY'))
                  unionPayMerchantAccounts.push(i);
                break;
              case 'TYPE_BANK_ACCOUNT':
                bankMerchantAccounts.push(i);
                break;
              case 'TYPE_PAY_PAL':
                paypalMerchantAccounts.push(i);
                break;
              case 'TYPE_CASH':
                cashMerchantAccounts.push(i);
                break;
              default:
                break;
            }
            padMerchantAccounts.push(i);
          });

          setMerchantAccounts({
            visaMerchantAccounts: visaMerchantAccounts,
            visaElectronMerchantAccounts: visaElectronMerchantAccounts,
            mastercardMerchantAccounts: mastercardMerchantAccounts,
            maestroMerchantAccounts: maestroMerchantAccounts,
            americanExpressMerchantAccounts: americanExpressMerchantAccounts,
            discoverMerchantAccounts: discoverMerchantAccounts,
            dinersClubMerchantAccounts: dinersClubMerchantAccounts,
            jcbMerchantAccounts: jcbMerchantAccounts,
            unionPayMerchantAccounts: unionPayMerchantAccounts,
            bankMerchantAccounts: bankMerchantAccounts,
            paypalMerchantAccounts: paypalMerchantAccounts,
            cashMerchantAccounts: cashMerchantAccounts,
            padMerchantAccounts: padMerchantAccounts,
          });
        },
      }
    ),
  };
};

export default useMerchantAccounts;
