import $ from 'jquery';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from './Modal';
import { getMessageGuideById } from '../../constants/MessageGuides';
import PropTypes from 'prop-types';

const ModalsMessageGuide = ({ currentMessageGuideModalId }) => {
  const {modalTitle, defaultMessage, customKeywordsTitle, customKeywords} =
    getMessageGuideById(currentMessageGuideModalId);

  useEffect(() => {
    if (currentMessageGuideModalId) {
      $(`#${currentMessageGuideModalId}`).modal('show');
    }
  }, [currentMessageGuideModalId]);

  return (
    <>
      <Modal
        id={currentMessageGuideModalId}
        theme='danger'
        iconName='check-circle'
        body={defaultMessage}
        defaultWelcomeMessage
        title={modalTitle}
      >
        <>
          <p className='py-3 px-2'>{customKeywordsTitle}</p>
        </>
        <ul>
          {customKeywords.map((customKeyword) => (
            <li className='text-left' key={customKeyword}>
              {customKeyword}
            </li>
          ))}
        </ul>
        <button
          type='button'
          className='btn btn-outline-danger btn-lg mt-2'
          data-dismiss='modal'
          onClick={() => $(currentMessageGuideModalId).modal('hide')}
        >
          <FormattedMessage id='button.back' />
        </button>
      </Modal>
    </>
  );
};

ModalsMessageGuide.propTypes = {
  currentMessageGuideModalId: PropTypes.string,
};

export default ModalsMessageGuide;
