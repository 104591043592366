import axios from "axios";
import moment from "moment";
import React from 'react';
import $ from "jquery";
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../util/constants";
import Alert from "./Alert";
import FieldBirthDate from "./FieldBirthDate";
import FieldProperty from "./FieldProperty";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import FieldCheckbox from "./FieldCheckbox";
import FieldDate from "./FieldDate";



class ReportCustomLibrary extends Propertii {
    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);

        this.state = {
            spinner:false,
            customFields: {},
            validationList: [],
            validationListDanger: [],
            dollarAmount: 0,
            documentSigned: false,
            personCharged: 'tenant',
            editButtonDisabled: true,
            activateButtonDisabled: true,

            selectedPropertyBilling: null,
            selectedPropertyDeclines: null,
            selectedPropertyYardiGenesisIntegration: null,
            selectedPropertyCustom: null,
            
            startDateRecurring: moment().format('YYYY-MM-DD[T]hh:mm:ssZZ'),
            endDateRecurring:'',
            parametersRecurring:{
                propertyId:''
            },

            startDateDeclines:'',
            endDateDeclines:'',
            parametersDeclines:{
                propertyId:''
            },

            startDateYardiGenesisIntegration:'',
            endDateYardiGenesisIntegration:'',
            parametersYardiGenesisIntegration:{
                propertyId:''
            },

            startDateCustom:'',
            endDateCustom:'',
            parametersCustom:{
                propertyId:'',
                designId:'',
                fileName:'Custom Payments',
                fileExtName: ''
            },

            propertyList:[],
            customExportDesignList:[],

            adminPropertyQuery: {
                orderBy: 'DESC',
                orderByFields: ['propertyName'],
                conditionList: []
            },

            reportType:'',
            groupDownloadCustom:false,
            excludeReversals:false,
        };

        this.handleChangeProperty = this.handleChangeProperty.bind(this);
        this.submitRecurringBillingReportForm = this.submitRecurringBillingReportForm.bind(this);
        this.submitDeclinesReportForm = this.submitDeclinesReportForm.bind(this);
        this.submitYardiGenesisIntegrationReportForm = this.submitYardiGenesisIntegrationReportForm.bind(this);
        this.submitCustomReportForm = this.submitCustomReportForm.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.handleCustomError = this.handleCustomError.bind(this);  
        this.transformDataForPDF = this.transformDataForPDF.bind(this);
        this.getHeaderForPDF = this.getHeaderForPDF.bind(this);      
    }   

    componentDidMount() {        
        if (this.props.userType === "TYPE_MANAGER" && !this.props.isPmAdmin && !this.props.accessProperties.includes("*")){

            axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.managerId}/properties`,{
                headers: this.generateRequestHeaders()
            }).then(responseManagedPropertyList =>{
                this.setState(prevState =>({
                    ...prevState,
                    propertyList: responseManagedPropertyList.data.records
                }));
            }).catch(error => {
                this.handleValidation(error);
            });

        } else if (this.props.userType === "TYPE_MANAGER" && (this.props.isPmAdmin || this.props.accessProperties.includes("*")) ) {
            
            axios.post(`${constants.REACT_APP_HOST_API_URL}/property/search`, this.state.adminPropertyQuery ,{
                headers : this.generateRequestHeaders()
            }).then(responseAdminPropertyList =>{

                this.setState(prevState =>({
                    ...prevState,
                    propertyList: responseAdminPropertyList.data.records
                }));
            })
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/CUSTOM_EXPORT_DESIGN/search`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: []
        } ,{
            headers : this.generateRequestHeaders()
        }).then(responseCustomExportDesignList =>{

            if(responseCustomExportDesignList.data.records &&
                responseCustomExportDesignList.data.records[0]){

                this.setState(prevState =>({
                    ...prevState,
                    customExportDesignList: responseCustomExportDesignList.data.records,
                    parametersCustom:{
                        designId:  responseCustomExportDesignList.data.records[0].id,
                        fileName: responseCustomExportDesignList.data.records[0]?.fileName,
                        fileExtName: responseCustomExportDesignList.data.records[0]?.fileExtName
                    }
                }));
            }
        });

        const defaultStartDate = moment().set('D', 1).set('hour', 0).set('minute', 0).format('YYYY-MM-DD[T]HH:mm:ssZZ');
        const defaultEndDate = moment().set('hour', 0).set('minute', 0).format('YYYY-MM-DD[T]HH:mm:ssZZ');
        this.setState(prevState => ({
            ...prevState,
            startDateRecurring: defaultStartDate,
            endDateRecurring: defaultEndDate,
            startDateDeclines: defaultStartDate,
            endDateDeclines: defaultEndDate,
            startDateYardiGenesisIntegration: defaultStartDate,
            endDateYardiGenesisIntegration: defaultEndDate,
            startDateCustom: defaultStartDate,
            endDateCustom: defaultEndDate
        }));
    }

    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
     submitDownloadReport(type) {
        switch(type) {
            case 'recurring_billing_date':
                this.setState(prevState =>({
                    ...prevState,
                    reportType: 'recurring_billing_date'
                }));
                break;
            case 'declines':
                this.setState(prevState =>({
                    ...prevState,
                    reportType: 'declines'
                }));
                break;
            case 'yardi_genesis_integration':
                this.setState(prevState =>({
                    ...prevState,
                    reportType: 'yardi_genesis_integration'
                }));
                break;
            case 'custom':
                this.setState(prevState =>({
                    ...prevState,
                    reportType: 'custom'
                }));
                break;
            default:
        }

        $("#download-format-confirm").modal("show")
    }

    /**
     * Ensures Recurring Billing Report Form is complete before download
     * @param {*} event 
     */
    submitRecurringBillingReportForm(){
        if(this.isRecurringBillingFormComplete){
            this.downloadReportCSV('export_recurring_billing_date');
        }   
    }

    /**
     * 
     * Ensures Recurring Billing Reprot Form is complete before download
     * @param {*} event 
     */
    submitDeclinesReportForm(){
        if(this.isDeclinesFormComplete){
            this.downloadReportCSV('export_declines');
        } 

    }

    /**
     * 
     * Ensures Yardi-Genesis Integration Reprot Form is complete before download
     * @param {*} event 
     */
     submitYardiGenesisIntegrationReportForm(){
        if(this.isYardiGenesisIntegrationFormComplete){
            this.downloadReportCSV('export_yardi_payments');
        } 

    }

    /**
     *
     * Ensures Custom Reprot Form is complete before download
     * @param {*} event
     */
    submitCustomReportForm(){
        if(this.isCustomFormComplete){
            this.downloadReportCSV('export_custom');
        }

    }

     /**
     * Ensures Recurring Billing Report Form is complete before download
     * @param {*} event 
     */
      submitRecurringBillingReportPDF(){
        if(this.isRecurringBillingFormComplete){
            this.downloadReportPDF('export_recurring_billing_date');
        }   
    }

    /**
     * 
     * Ensures Recurring Billing Reprot Form is complete before download
     * @param {*} event 
     */
    submitDeclinesReportPDF(){
        if(this.isDeclinesFormComplete){
            this.downloadReportPDF('export_declines');
        } 

    }

    /**
     * 
     * Ensures Yardi-Genesis Integration Reprot Form is complete before download
     * @param {*} event 
     */
     submitYardiGenesisIntegrationReportPDF(){
        if(this.isYardiGenesisIntegrationFormComplete){
            this.downloadReportPDF('export_yardi_payments');
        } 

    }

    /**
     *
     * Ensures Custom Reprot Form is complete before download
     * @param {*} event
     */
    submitCustomReportPDF(){
        if(this.isCustomFormComplete){
            this.downloadReportPDF('export_custom');
        }

    }


    /**
     * Download CSV
     * @param {*} exportType - type of the report to be downloaded
     */
    downloadReportCSV(exportType){
        let requestBody = {};

        if(exportType === 'export_recurring_billing_date' && moment(this.state.endDateRecurring).isBefore(moment(this.state.startDateRecurring))){
            this.handleCustomError(5000, "Start date must be before end date");
            return;
        }

        if(exportType === 'export_declines' && moment(this.state.endDateDeclines).isBefore(moment(this.state.startDateDeclines))){
            this.handleCustomError(5000, "Start date must be before end date");
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.endDateYardiGenesisIntegration).isBefore(moment(this.state.startDateYardiGenesisIntegration))){
            this.handleCustomError(5000, "Start date must be before end date");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.startDateYardiGenesisIntegration).isAfter(moment())){
            this.handleCustomError(5000, "Start date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.endDateYardiGenesisIntegration).isAfter(moment())){
            this.handleCustomError(5000, "End date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_custom' && moment(this.state.endDateCustom).isBefore(moment(this.state.startDateCustom))){
            this.handleCustomError(5000, "Start date must be before end date");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_custom' && moment(this.state.startDateCustom).isAfter(moment())){
            this.handleCustomError(5000, "Start date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_custom' && moment(this.state.endDateCustom).isAfter(moment())){
            this.handleCustomError(5000, "End date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_recurring_billing_date'){
            requestBody = {
                startDate:this.state.startDateRecurring,
                endDate: this.state.endDateRecurring,
                parameters: this.state.parametersRecurring
            }
        } else if (exportType === 'export_declines'){
            requestBody = {
                startDate: this.state.startDateDeclines,
                endDate: this.state.endDateDeclines,
                parameters: this.state.parametersDeclines
            }
        } else if (exportType === 'export_yardi_payments'){
            requestBody = {
                startDate: this.state.startDateYardiGenesisIntegration,
                endDate: this.state.endDateYardiGenesisIntegration,
                parameters: this.state.parametersYardiGenesisIntegration
            }
        } else if (exportType === 'export_custom'){
            let parameters = this.state.parametersCustom;

            if(this.state.groupDownloadCustom){

                parameters.groupKey = 'propertyId';
            }

            if(this.state.excludeReversals){

                parameters.transactionType = 'PAYMENT';
            }

            requestBody = {
                startDate: this.state.startDateCustom,
                endDate: this.state.endDateCustom,
                parameters: parameters
            }
        }

        
        this.setState(prevState =>({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/${exportType}/download`, requestBody, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);
            
            this.setState(prevState =>({
                ...prevState,
                spinner: false
            }));
            
            this.handleSuccess(`Downloaded ${filename} successfully `, 30000);
        }).catch(error => {
            
            this.setState(prevState =>({
                ...prevState,
                spinner: false
            }));

            this.handleValidation(error);
        });
        

    }

     /**
     * Download PDF
     * @param {*} exportType - type of the report to be downloaded
     */
      downloadReportPDF(exportType){
        let requestBody = {};

        if(exportType === 'export_recurring_billing_date' && moment(this.state.endDateRecurring).isBefore(moment(this.state.startDateRecurring))){
            this.handleCustomError(5000, "Start date must be before end date");
            return;
        }

        if(exportType === 'export_declines' && moment(this.state.endDateDeclines).isBefore(moment(this.state.startDateDeclines))){
            this.handleCustomError(5000, "Start date must be before end date");
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.endDateYardiGenesisIntegration).isBefore(moment(this.state.startDateYardiGenesisIntegration))){
            this.handleCustomError(5000, "Start date must be before end date");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.startDateYardiGenesisIntegration).isAfter(moment())){
            this.handleCustomError(5000, "Start date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

        if(exportType === 'export_yardi_payments' && moment(this.state.endDateYardiGenesisIntegration).isAfter(moment())){
            this.handleCustomError(5000, "End date can't be in the future");
            window.scrollTo(0,0);
            return;
        }

         if(exportType === 'export_custom' && moment(this.state.endDateCustom).isBefore(moment(this.state.startDateCustom))){
             this.handleCustomError(5000, "Start date must be before end date");
             window.scrollTo(0,0);
             return;
         }

         if(exportType === 'export_custom' && moment(this.state.startDateCustom).isAfter(moment())){
             this.handleCustomError(5000, "Start date can't be in the future");
             window.scrollTo(0,0);
             return;
         }

         if(exportType === 'export_custom' && moment(this.state.endDateCustom).isAfter(moment())){
             this.handleCustomError(5000, "End date can't be in the future");
             window.scrollTo(0,0);
             return;
         }

        if(exportType === 'export_recurring_billing_date'){
            requestBody = {
                startDate:this.state.startDateRecurring,
                endDate: this.state.endDateRecurring,
                parameters: this.state.parametersRecurring
            }
        } else if (exportType === 'export_declines'){
            requestBody = {
                startDate: this.state.startDateDeclines,
                endDate: this.state.endDateDeclines,
                parameters: this.state.parametersDeclines
            }
        } else if (exportType === 'export_yardi_payments'){
            requestBody = {
                startDate: this.state.startDateYardiGenesisIntegration,
                endDate: this.state.endDateYardiGenesisIntegration,
                parameters: this.state.parametersYardiGenesisIntegration
            }
        } else if (exportType === 'export_custom'){
            requestBody = {
                startDate: this.state.startDateCustom,
                endDate: this.state.endDateCustom,
                parameters: this.state.parametersCustom
            }
        }

        this.setState(prevState =>({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/${exportType}/download`, requestBody, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {
            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape');
            let titleReport;
            let columnStyles = {};

            doc.setFontSize(12);
            
            switch(exportType) {
                case 'export_recurring_billing_date':
                    titleReport = 'Recurring Billing Date Report';
                    columnStyles = {
                        0: { cellWidth : 25 },
                        1: { cellWidth : 25 },
                        2: { cellWidth : 35 },
                        3: { cellWidth : 15 },
                        4: { cellWidth : 45 },
                        5: { cellWidth : 25 },
                        6: { cellWidth : 25 },
                        7: { cellWidth : 25 },
                        8: { cellWidth : 20 },
                        9: { cellWidth : 20 },
                    }
                    break;
                case 'export_declines':
                    titleReport = 'Declines Report';
                    columnStyles = {
                        0: { cellWidth : 22 },
                        1: { cellWidth : 30 },
                        2: { cellWidth : 12 },
                        3: { cellWidth : 18 },
                        4: { cellWidth : 30 },
                        5: { cellWidth : 22 },
                        6: { cellWidth : 25 },
                        7: { cellWidth : 25 },  
                        8: { cellWidth : 22 },
                        9: { cellWidth : 22 },
                        10: { cellWidth : 20 },
                        11: { cellWidth : 30}
                    }
                    break;
                case 'export_yardi_payments':
                    titleReport = 'Yardi-Genesis Integration Payments Report';
                    columnStyles = {
                        0: { cellWidth : 20 },
                        1: { cellWidth : 20 },
                        2: { cellWidth : 30 },
                        3: { cellWidth : 40 },
                        4: { cellWidth : 30 },
                        5: { cellWidth : 40 },
                        6: { cellWidth : 30 },
                        7: { cellWidth : 35 },  
                        8: { cellWidth : 20 },
                    }
                    
                    break;
                case 'export_custom':
                    titleReport = 'Custom Payments Report';
                    columnStyles = {
                        0: { cellWidth : 20 },
                        1: { cellWidth : 20 },
                        2: { cellWidth : 30 },
                        3: { cellWidth : 40 },
                        4: { cellWidth : 30 },
                        5: { cellWidth : 40 },
                        6: { cellWidth : 30 },
                        7: { cellWidth : 35 },
                        8: { cellWidth : 20 },
                    }

                    break;
                default:
            }

            doc.text(`${titleReport}`, 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak:false,
                theme: 'grid',
               
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles: columnStyles
            });
            doc.save(`${exportType}`)
            
            this.setState(prevState =>({
                ...prevState,
                spinner: false
            }));
            
            this.handleSuccess(`Downloaded successfully `, 30000);
        }).catch(error => {
            
            this.setState(prevState =>({
                ...prevState,
                spinner: false
            }));

            this.handleValidation(error);
        });
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
      
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - The data returned by the endpoint to be converted into PDF.
     */
     getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
      
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }


    /**
     * Change propertyId for either report form
     * @param {*} selectedOption 
     * @param {*} reportType 
     */
    handleChangeProperty(selectedOption, reportType){
        if(reportType === "TYPE_RECURRING_BILLING"){
            this.setState(prevState => ({
                ...prevState,
                selectedPropertyBilling: selectedOption,
                parametersRecurring:{
                    propertyId: selectedOption.value
                }
            }));
        } else if(reportType === "TYPE_DECLINES"){
            this.setState(prevState => ({
                ...prevState,
                selectedPropertyDeclines: selectedOption,
                parametersDeclines:{
                    propertyId: selectedOption.value
                }
            }));
        } else if(reportType ===  "TYPE_YARDI_GENESIS_INTEGRATION"){
            this.setState(prevState => ({
                ...prevState,
                selectedPropertyYardiGenesisIntegration: selectedOption,
                parametersYardiGenesisIntegration:{
                    propertyId: selectedOption.value
                }
            }));
        } else if(reportType ===  "TYPE_EXPORT_CUSTOM"){
            this.setState(prevState => ({
                ...prevState,
                selectedPropertyCustom: selectedOption,
                parametersCustom:{
                    propertyId: selectedOption.value,
                    designId:  this.state.parametersCustom.designId,
                    fileName:  this.state.parametersCustom.fileName,
                    fileExtName:  this.state.parametersCustom.fileExtName
                }
            }));
            if (selectedOption?.value){
                this.setState(prevState => ({
                    ...prevState,
                    groupDownloadCustom: false
                }));
            }
        }
    }

    /**
     * Checks for completion of the Recurring Billing Report Form which populates the request body for the download endpoint
     */
    get isRecurringBillingFormComplete(){
        let state = this.state;
        
        return state.parametersRecurring.propertyId !== ""
            && state.startDateRecurring !== ""
            && state.endDateRecurring !== "";
        
        // For expansion into pmUI
        // else if(this.props.userType === "TYPE_ADMIN"){}
    }

    /**
     * Checks for completion of the Declines Report Form which populates the request body for the download endpoint
     */
    get isDeclinesFormComplete(){
        let state = this.state;
      
        return state.parametersDeclines.propertyId !== ""
            && state.startDateDeclines !== ""
            && state.endDateDeclines !== "";
        
        // For expansion into pmUI
        // else if(this.props.userType === "TYPE_ADMIN"){}
    }

    /**
     * Checks for completion of the Yardi Genesis Integration Report Form which populates the request body for the download endpoint
     */
     get isYardiGenesisIntegrationFormComplete(){
        let state = this.state;
      
        return state.parametersYardiGenesisIntegration.propertyId !== ""
            && state.startDateYardiGenesisIntegration !== ""
            && state.endDateYardiGenesisIntegration !== "";
        
        // For expansion into pmUI
        // else if(this.props.userType === "TYPE_ADMIN"){}
    }

    /**
     * Checks for completion of the Custom Report Form which populates the request body for the download endpoint
     */
    get isCustomFormComplete(){
        let state = this.state;

        return state.parametersCustom.propertyId !== ""
            && state.startDateCustom !== ""
            && state.endDateCustom !== ""
            && state.parametersCustom?.designId !== "";

        // For expansion into pmUI
        // else if(this.props.userType === "TYPE_ADMIN"){}
    }

    /**
     * Handle validation if errors were returned from the server response. Map the errors to the appropriate state.
     *
     * @param error - The server error response.
     */
    handleValidation(error) {

        let fields = {};

        if(error.response.data.fieldErrors) {
            Object.entries(error.response.data.fieldErrors).forEach(
                ([key, value]) => {
                    fields[value.fieldName] = value.errorCode;
                }
            );
        }

        const recordType = error.response.data.recordType;
        this.setState(prevState => ({
            ...prevState,
            validationListDanger: [...prevState.validationListDanger, {
                alert: {
                    type: 'danger',
                    message: error.response.data.message,
                    code: error.response.data.errorCode + (recordType ? ('.' + recordType) : '')
                },
                fields
            }],
        }));

    }
    /**
     * Display a custom error message 
     * @param {*} setTimeoutTimer 
     * @param {*} customMessage 
     */
    handleCustomError(setTimeoutTimer, customMessage = ""){
        
        if(customMessage !== ""){
            this.setState(prevState => ({
                ...prevState,
                validationList: [...prevState.validationList, {
                    title: 'Error',
                    alert: {
                        type: 'danger',
                        message: customMessage,
                    },
                    showMessage: true,
                    fields: {},
                }],
            }));
        }

        if(setTimeoutTimer > 0){
            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [],
                }));
            }, setTimeoutTimer);
        }
    }

    handleSuccess(msg, setTimeoutTimer = 0) {
        this.setState(prevState => ({
            ...prevState,
            validationList: [...prevState.validationList, {
                title: 'Success!',
                alert: {
                    type: 'primary',
                    message: msg,
                },
                showMessage: true,
                fields: {},
            }],
        }));

        if(setTimeoutTimer > 0){
            setTimeout(() => {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [],
                }));
            }, setTimeoutTimer);
        }
    }
   

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {
        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <Alert validationList={this.state.validationList}/>
                <Alert validationList={this.state.validationListDanger}/>

                
                    <section name="Recurring Billing Date Report">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Create Recurring Billing Date Report
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                                <FieldProperty 
                                    id="propertyIdBillingReport" label="Property" 
                                    handleChange={(selectedOption) =>{this.handleChangeProperty(selectedOption, "TYPE_RECURRING_BILLING")}}
                                    parent={this} options={this.state.propertyList} 
                                    value={this.state.selectedPropertyBilling}
                                    allPropertiesOption={true} 
                                    />
                                <FieldDate
                                    id="dateRecurring"
                                    label="Date"
                                    required={true}
                                    parent={this}
                                    value={this.state.startDateRecurring}
                                    selectsRange={true}
                                    startDate={this.state.startDateRecurring}
                                    startDateModel="startDateRecurring"
                                    endDate={this.state.endDateRecurring}
                                    endDateModel="endDateRecurring"/>
                                <div>
                                    <button
                                        type="button"
                                        disabled={!this.isRecurringBillingFormComplete}
                                        onClick={() => this.submitDownloadReport('recurring_billing_date')}
                                        className="btn btn-primary btn-md mt-2 mr-0 float-right">
                                            Download
                                    </button>
                                </div>    
                        </div>
                    </div>
                    </section>

                    <section name="Declines Report">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Create Declines Report
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                                <FieldProperty 
                                    id="propertyIdDeclines" label="Property" 
                                    handleChange={(selectedOption) =>{this.handleChangeProperty(selectedOption, "TYPE_DECLINES")}}
                                    parent={this} options={this.state.propertyList}
                                    value={this.state.selectedPropertyDeclines} 
                                    allPropertiesOption={true} 
                                    />

                                <FieldDate
                                    id="dateDeclines"
                                    label="Date"
                                    required={true}
                                    parent={this}
                                    value={this.state.startDateDeclines}
                                    selectsRange={true}
                                    startDate={this.state.startDateDeclines}
                                    startDateModel="startDateDeclines"
                                    endDate={this.state.endDateDeclines}
                                    endDateModel="endDateDeclines"/>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={!this.isDeclinesFormComplete}
                                        onClick={() => this.submitDownloadReport('declines')}
                                        className="btn btn-primary btn-md mt-2 mr-0 float-right">
                                            Download
                                    </button>
                                </div>    
                        </div>
                    </div>
                    </section>

                    <section name="Yardi-Genesis Integration Payments Report">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Create Yardi-Genesis Integration Payments Report
                                </div>
                            </div>
                        </div>
                        <div className="col my-3">
                                <FieldProperty 
                                    id="propertyIdYardiGenesisIntegration" label="Property" 
                                    handleChange={(selectedOption) =>{this.handleChangeProperty(selectedOption, "TYPE_YARDI_GENESIS_INTEGRATION")}}
                                    parent={this} options={this.state.propertyList}
                                    value={this.state.selectedPropertyYardiGenesisIntegration} 
                                    allPropertiesOption={true} 
                                    />

                                <FieldDate
                                    id="dateYardiGenesisIntegration"
                                    label="Date"
                                    required={true}
                                    parent={this}
                                    value={this.state.startDateYardiGenesisIntegration}
                                    selectsRange={true}
                                    startDate={this.state.startDateYardiGenesisIntegration}
                                    startDateModel="startDateYardiGenesisIntegration"
                                    endDate={this.state.endDateYardiGenesisIntegration}
                                    endDateModel="endDateYardiGenesisIntegration"/>

                                <div>
                                    <button
                                        type="submit"
                                        disabled={!this.isYardiGenesisIntegrationFormComplete}
                                        onClick={() => this.submitDownloadReport('yardi_genesis_integration')}
                                        className="btn btn-primary btn-md mt-2 mr-0 float-right">
                                            Download
                                    </button>
                                </div>    
                        </div>
                    </div>
                    </section>

                    {this.state.customExportDesignList?.length >0 && this.state.parametersCustom?.designId !== "" &&

                        <section name="Custom Report">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            Create {this.state.parametersCustom?.fileName} Report ({this.state.parametersCustom?.fileExtName})
                                        </div>
                                    </div>
                                </div>
                                <div className="col my-3">
                                    <FieldProperty
                                        id="propertyIdCustom" label="Property"
                                        handleChange={(selectedOption) =>{this.handleChangeProperty(selectedOption, "TYPE_EXPORT_CUSTOM")}}
                                        parent={this} options={this.state.propertyList}
                                        value={this.state.selectedPropertyCustom}
                                        allPropertiesOption={true}
                                    />
                                    <FieldDate
                                        id="dateCustom"
                                        label="Date"
                                        required={true}
                                        parent={this}
                                        value={this.state.startDateCustom}
                                        selectsRange={true}
                                        startDate={this.state.startDateCustom}
                                        startDateModel="startDateCustom"
                                        endDate={this.state.endDateCustom}
                                        endDateModel="endDateCustom"/>

                                    <FieldCheckbox
                                        id="groupDownloadCustom"
                                        label=""
                                        fieldLabel="Separate files by property"
                                        parent={this}
                                        value={this.state.groupDownloadCustom}
                                        disabled={this.state.selectedPropertyCustom?.value}/>
                                    <FieldCheckbox
                                        id="excludeReversals"
                                        label=""
                                        fieldLabel="Exclude Reversals"
                                        parent={this}
                                        value={this.state.excludeReversals}/>
                                    <div>
                                        <button
                                            type="submit"
                                            disabled={!this.isCustomFormComplete}
                                            onClick={() => this.submitDownloadReport('custom')}
                                            className="btn btn-primary btn-md mt-2 mr-0 float-right">
                                            Download
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>

                    }
                    <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        {this.state.reportType === 'recurring_billing_date' &&
                            <>
                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.submitRecurringBillingReportForm()}>
                                CSV Format
                            </button>
                            <button onClick={() => this.submitRecurringBillingReportPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                PDF Format
                            </button>
                            </>
                        }
                        {this.state.reportType === 'declines' &&
                            <>
                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.submitDeclinesReportForm()}>
                                CSV Format
                            </button>
                            <button onClick={() => this.submitDeclinesReportPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                PDF Format
                            </button>
                            </>
                        }
                        {this.state.reportType === 'yardi_genesis_integration' &&
                            <>
                            <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.submitYardiGenesisIntegrationReportForm()}>
                                CSV Format
                            </button>
                            <button onClick={() => this.submitYardiGenesisIntegrationReportPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                PDF Format
                            </button>
                            </>
                        }
                        {this.state.reportType === 'custom' && this.state.parametersCustom?.designId !== "" &&
                            <>
                                <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.submitCustomReportForm()}>
                                    CSV/TXT Format
                                </button>
                            </>
                        }
                    </Modal>

            </React.Fragment>
        )
    };
}

ReportCustomLibrary.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(ReportCustomLibrary);