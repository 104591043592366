import axios from "axios";
import React from 'react';
import * as constants from "../../../util/constants";
import PropertyList from "../../common/Properties";
import Propertii from "../../common/Propertii";

class Properties extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: null,
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the landlord data based on the landlord ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                landlord: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The manager property list component.
     */
    render() {

        return(
            <div className="">

                {this.state.landlord &&
                <PropertyList userType={this.props.userSession.sessionRole.type} hideRegion={true} companyId={this.state.landlord.companyId} merchantAccounts={false}
                              history={this.props.history} createMessage="admin.landlords.properties.created"/>
                }

            </div>
        )
    };
}

export default Properties;