import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldCountry from "../../common/FieldCountry";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";

class DefaultFeeProfiles extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            feeProfile: {},

            feeProfileList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            isSplititFeeProfile: false,

            feeProfileQuery: {
                orderBy: 'ASC',
                orderByFields: ['paymentType'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: null
                    }
                ]
            },

            validationList: [],

        };

        this.searchFeeProfiles = this.searchFeeProfiles.bind(this);
        this.viewFeeProfile = this.viewFeeProfile.bind(this);
        this.initFeeProfile = this.initFeeProfile.bind(this);
        this.saveFeeProfile = this.saveFeeProfile.bind(this);
        this.deleteFeeProfile = this.deleteFeeProfile.bind(this);
    }

    /**
     * Load the list of fee profiles on mount.
     */
    componentDidMount() {

        this.searchFeeProfiles(1, 25, this.state.feeProfileQuery);
    }

    /**
     * Handle selecting a fee profile record by bringing up the modal with fee profile values pre-filled.
     *
     * @param feeProfileId - The ID of the fee profile selected.
     */
    viewFeeProfile(feeProfileId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${feeProfileId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {


            this.setState(prevState => ({
                ...prevState,
                feeProfile: response.data,
                isSplititFeeProfile: response?.data?.paymentType === 'TYPE_INSTALLMENT_PAY'
            }));

            let feeProfile = this.state.feeProfile;
            if(feeProfile?.walletType){

                feeProfile.paymentType = feeProfile.walletType;

                this.setState(prevState => ({
                    ...prevState,
                    feeProfile: feeProfile
                }));

            }

            $('#fee-profile').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a fee profile object when the user clicks the 'create fee profile' button.
     */
    initFeeProfile() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                feeProfile: {
                    ...response.data,
                    feeType: 'ADD'
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected fee profile.
     */
    deleteFeeProfile() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${this.state.feeProfile.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.fees.deleted'
                    }
                }],
            });

            this.searchFeeProfiles(this.state.feeProfileList.page, this.state.feeProfileList.recordsPerPage, this.state.feeProfileQuery);

            $('#fee-profile').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle the submission of the form. Use the create date field of the fee profile in the state to determine if
     * the user is editing or creating a new property unit. Upon successful submission of the form, refresh the table
     * and hide the modal.
     *
     * @param event - The event container.
     */
    saveFeeProfile(event) {

        event.preventDefault();

        let feeProfile = this.state.feeProfile;
        if(feeProfile.paymentType === 'APPLE_PAY' ||
            feeProfile.paymentType === 'GOOGLE_PAY'){

            feeProfile.walletType = feeProfile.paymentType;
            feeProfile.paymentType = 'TYPE_WALLET';
        }

        if(this.state.feeProfile.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, feeProfile, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.fees.created'
                        }
                    }],
                });

                this.searchFeeProfiles(this.state.feeProfileList.page, this.state.feeProfileList.recordsPerPage, this.state.feeProfileQuery);

                $('#fee-profile').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.feeProfile, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchFeeProfiles(this.state.feeProfileList.page, this.state.feeProfileList.recordsPerPage, this.state.feeProfileQuery);

                $('#fee-profile').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of default fee profiles.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchFeeProfiles(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/fee_profile/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                feeProfileList: response.data,
                feeProfileQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The default fee profile list interface.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Default Transaction Fees" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card card-primary border-primary">
                        <div className="card-body">
                            <p className="card-text">
                                The transaction fee listed below are <span className="font-weight-bold">default, non-company-specific</span> fees. Companies that do not have any custom transaction fees assigned to them will utilize these fees. Changes made to these transaction fees can affect a large quantity of companies that rely on this information.
                            </p>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Transaction Fees
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#fee-profile" className="btn btn-primary btn-sm" onClick={() => this.initFeeProfile()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Transaction Fees
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{paymentType: 'Payment Type', cardBrand: 'Card Brand', cardType: 'Card Type', country: 'Country', feeRate: 'Domestic', internationalFeeRate: 'International', processor: 'Processor'}}
                               columnWidths={['16%', '14%', '14%', '14%', '14%', '14%', '14%']}
                               headerClass="c-pointer"
                               data={this.state.feeProfileList}
                               query={this.state.feeProfileQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchFeeProfiles}>
                            <tbody>
                            {this.state.feeProfileList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewFeeProfile(data.id)} className="c-pointer">

                                        {data?.walletType &&
                                            <td>
                                                <div className="">
                                                    <FormattedMessage id={"enum.feeProfile.walletType." + data.walletType} />
                                                </div>
                                            </td>
                                        }

                                        {!data?.walletType &&
                                            <td>
                                                <div className="">
                                                    <FormattedMessage id={"enum.feeProfile.paymentType." + data.paymentType} />
                                                </div>
                                            </td>
                                        }

                                        <td>
                                            <div className="">
                                                {data.cardBrand &&
                                                <FormattedMessage id={"enum.feeProfile.cardBrand." + data.cardBrand} />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.cardType &&
                                                <FormattedMessage id={"enum.feeProfile.cardType." + data.cardType} />
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"country." + data.country} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {`${data.feeType === 'SUBTRACT' ? '-' : ''}${data.rateType === 'FLAT' ? '$' : ''}${data.feeRate}${data.rateType === 'PERCENT' ? '%' : ''}`}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {`${data.feeType === 'SUBTRACT' ? '-' : ''}${data.internationalRateType === 'FLAT' ? '$' : ''}${data.internationalFeeRate}${data.internationalRateType === 'PERCENT' ? '%' : ''}`}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.processor &&
                                                <FormattedMessage id={data.processor} />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="fee-profile" tabIndex="-1" role="dialog" aria-labelledby="fee-profile-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveFeeProfile}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="fee-profile-label">
                                            {this.state.feeProfile['createDate'] == null &&
                                            <span className="">
                                            Create Transaction Fee
                                        </span>
                                            }
                                            {this.state.feeProfile['createDate'] != null &&
                                            <span className="">
                                            Edit Transaction Fee
                                        </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    {/* TODO: Remove disabled fields when Splitit fee profile can be modified at company level */}
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldCountry id="country" label="Country" model="feeProfile" parent={this} value={this.state.feeProfile['country']} disabled={this.state.isSplititFeeProfile}/>

                                        <FieldSelect id="paymentType" label="Payment Type" model="feeProfile" parent={this} 
                                            disabled={this.state.isSplititFeeProfile} value={this.state.feeProfile['paymentType']}>
                                            <option value="" disabled>Select a payment type...</option>
                                            <option value="TYPE_CREDIT_CARD">Credit Card</option>
                                            <option value="TYPE_BANK_ACCOUNT">Bank Account</option>
                                            <option value="TYPE_PAY_PAL">PayPal</option>
                                            <option value="TYPE_CASH">Cash</option>
                                            <option value="TYPE_INSTALLMENT_PAY">Letus Split</option>
                                            <option value="APPLE_PAY">Apple Pay</option>
                                            <option value="GOOGLE_PAY">Google Pay</option>
                                        </FieldSelect>

                                        {this.state.feeProfile['paymentType'] === 'TYPE_CREDIT_CARD' &&
                                        <FieldSelect id="cardBrand" label="Card Brand" model="feeProfile" parent={this} value={this.state.feeProfile['cardBrand']}>
                                            <option disabled>Select a card type...</option>
                                            <option value="">Any card brand</option>
                                            <option value="VISA">Visa</option>
                                            <option value="VISA_ELECTRON">Visa Electron</option>
                                            <option value="MASTERCARD">Mastercard</option>
                                            <option value="MAESTRO">Maestro</option>
                                            <option value="DISCOVER">Discover</option>
                                            <option value="AMERICAN_EXPRESS">American Express</option>
                                            <option value="DINERS_CLUB">Diners Club</option>
                                            <option value="JCB">JCB</option>
                                            <option value="UNION_PAY">UnionPay</option>
                                        </FieldSelect>
                                        }

                                        {this.state.feeProfile['paymentType'] === 'TYPE_CREDIT_CARD' &&
                                        <FieldSelect id="cardType" label="Card Type" model="feeProfile" parent={this} value={this.state.feeProfile['cardType']}>
                                            <option disabled>Select a card type...</option>
                                            <option value="">Any card type</option>
                                            <option value="CREDIT">Credit</option>
                                            <option value="DEBIT">Debit</option>
                                            <option value="PREPAID">Prepaid</option>
                                        </FieldSelect>
                                        }

                                        <FieldSelect id="rateType" label="Domestic Rate Type" model="feeProfile" parent={this} disabled={this.state.isSplititFeeProfile} value={this.state.feeProfile['rateType']}>
                                            <option value="" disabled>Select a rate type...</option>
                                            <option value="FLAT">Flat</option>
                                            <option value="PERCENT">Percent</option>
                                        </FieldSelect>

                                        <FieldText id="feeRate" label="Domestic Rate" type="number" min="0.00" step="0.01" 
                                                pattern="[0-9]+(\.[0-9][0-9]?)?" disabled={this.state.isSplititFeeProfile}
                                                prependIconType={this.state.feeProfile.rateType ? 'fas' : null} 
                                                prependIconName={this.state.feeProfile.rateType === 'FLAT' ? 'dollar-sign' : 'percentage'}
                                                model="feeProfile" parent={this} value={this.state.feeProfile['feeRate']} />

                                        <FieldSelect id="internationalRateType" label="International Rate Type" model="feeProfile" parent={this} disabled={this.state.isSplititFeeProfile} value={this.state.feeProfile['internationalRateType']}>
                                            <option value="" disabled>Select a rate type...</option>
                                            <option value="FLAT">Flat</option>
                                            <option value="PERCENT">Percent</option>
                                        </FieldSelect>

                                        <FieldText id="internationalFeeRate" label="International Rate" type="number" min="0.00" step="0.01" 
                                                pattern="[0-9]+(\.[0-9][0-9]?)?" disabled={this.state.isSplititFeeProfile}
                                                prependIconType={this.state.feeProfile.internationalRateType ? 'fas' : null} 
                                                prependIconName={this.state.feeProfile.internationalRateType === 'FLAT' ? 'dollar-sign' : 'percentage'}
                                                model="feeProfile" parent={this} value={this.state.feeProfile['internationalFeeRate']} />

                                        <FieldSelect id="processor" label="Processor" model="feeProfile" 
                                                disabled = {this.state.isSplititFeeProfile} parent={this} value={this.state.feeProfile['processor']}>
                                                    <option value="" disabled>Select the processor...</option>
                                                    <option value="PAYSAFE">PaySafe</option>
                                                    {this.state.isSplititFeeProfile && 
                                                        <option value="SPLITIT">SplitIt</option>
                                                    }
                                                    <option value="BMO">BMO</option>
                                                    <option value="BLUESNAP">BlueSnap</option>
                                        </FieldSelect>


                                        {this.state.isSplititFeeProfile &&
                                            <FieldText id="installments" label="Installments" type="number" min="2" step="1" disabled
                                                model="feeProfile" parent={this} value={this.state.feeProfile['installments']} />
                                        }
                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                                        <div className="row">
                                            <div className="col-4">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#fee-profile").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-8 text-right">

                                                {this.state.feeProfile['createDate'] != null &&
                                                <div className="btn btn-lg btn-primary ml-2" onClick={() => {$("#fee-profile").modal("hide"); $("#delete-fee-profile").modal("show");}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-fee-profile" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Transaction Fee"
                           body="Are you sure you would like to delete this default transaction fee?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => {$("#fee-profile").modal("show")}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteFeeProfile()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Transaction Fee
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default DefaultFeeProfiles;