import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import careers from '../media/img/about/careers.png';
import partners from '../media/img/about/partners.png';
import team from '../media/img/about/team.png';
import livingRoom from "../media/img/backgrounds/living-room.jpg";
import rentmoola from '../media/img/logos/logo-letus-white-new.png';
import Letus from "./common/Propertii";

class Home extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the about us page.
     */
    render() {
        const {formatMessage} = this.props.intl;
        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${livingRoom}') no-repeat center center scroll`}}>
                    <div className="container text-md-center">
                        <div className="row justify-content-md-center">
                            <div className="col-8">
                                <h1 className="display-5 pt-3 text-white">
                                    <FormattedMessage
                                        id='about.header.title.evolution'
                                        defaultMessage='The evolution of'
                                    />&nbsp;
                                    <img src={rentmoola} className="img-fluid pl-1" width="217" alt="Letus" />
                                </h1>
                                <p className="lead text-white">
                                    <FormattedMessage
                                        id='about.header.subtitle.industry-leading'
                                        defaultMessage='Letus is the industry-leading rent payment platform with a suite of property management tools to create a better rental experience.'
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage
                                    id='about.body.sec-1.title.dedicated-individuals'
                                    defaultMessage='Built by a team of dedicated individuals, renters, and property managers alike.'
                                />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage
                                    id='about.body.sec-1.description.individual-experiences'
                                    defaultMessage='We partner with property managers closely - and pull upon our individual experiences in property management and tenancy - to ensure we deliver a service that performs to meet the needs of property managers.'
                                />
                            </p>
                            <a href="https://rentmoola.com/team" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage
                                    id='about.body.sec-1.action.leadership-team'
                                    defaultMessage='Meet the Leadership Team'
                                />
                                <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <img src={team} alt={formatMessage({id: "static.about.team.alt"})} className="img-sm-fluid" width="140%" />
                        </div>
                    </div>

                    <hr className="my-5" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage
                                    id='about.body.sec-2.title.quality-service'
                                    defaultMessage='Partnered with the best in the industry to deliver nothing short of a quality service'
                                />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage
                                    id='about.body.sec-2.description.consult-industry'
                                    defaultMessage="We've partnered with property management associations, payment providers, and consult industry experts to ensure our service is not only an industry standard, but an industry leader."
                                />
                            </p>
                            <a href="https://rentmoola.com/partners" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage
                                    id='about.body.sec-2.action.our-partners'
                                    defaultMessage='Our Partners'
                                /> 
                                <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={partners} alt={formatMessage({id: "static.about.partners.alt"})} className="img-sm-fluid float-right" width="140%" />
                        </div>
                    </div>

                    <hr className="my-5" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage
                                    id='about.body.sec-3.title.creating-learning'
                                    defaultMessage="We're always creating, learning, and inspiring each other to succeed at our craft"
                                />
                            </h3>
                            <p className="lead mb-4">
                                <FormattedMessage
                                    id='about.body.sec-3.description.come-together'
                                    defaultMessage="When we come together, great things can happen. We're always looking for talented and hard-working individuals to join the team to help push the boundaries of technology, rent collection, and payments."
                                />
                            </p>
                            <a href="https://rentmoola.com/careers" target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-md">
                                <FormattedMessage
                                    id='about.body.sec-3.action.careers-letus'
                                    defaultMessage='Careers at Letus'
                                /> 
                                <FontAwesomeIcon icon={['far', 'angle-double-right']} className="fa-fw va-b" />
                            </a>
                        </div>
                        <div className="col-md-6">
                            <img src={careers} alt={formatMessage({id: "static.about.careers.alt"})} className="img-sm-fluid" />
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage
                                    id='about.body.sec-4.title.learn-more'
                                    defaultMessage="Learn more about Letus"
                                />
                            </h3>
                        </div>

                        <div className="row justify-content-sm-center">
                            <div className="col-md-6 text-center">

                                <p className="">
                                    <FormattedMessage
                                        id='about.body.sec-4.description-1.letus-support-together'
                                        defaultMessage="The world-class Letus support team is here to assist you with any questions, suggestions, concerns and feedback you may have."
                                    />
                                </p>

                                <p className="">
                                    <FormattedMessage
                                        id='about.body.sec-4.description-2.leave-message'
                                        defaultMessage=" Whether you leave us a message, give us a call, or chat with us live, we'll be happy to assist you."
                                    />
                                </p>

                                <div className="text-center">
                                    <Link to="/contact" className="btn btn-lg btn-primary mt-3">
                                        <FormattedMessage
                                            id='common.label.contact-us'
                                            defaultMessage=' Contact Us'
                                        /> 
                                    </Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        )
    };
}

Home.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Home);
