import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import JumbotronHelp from "../../common/JumbotronHelp";
import ModalPhrase from "../../common/ModalPhrase";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Manager from "./Manager";
import Permissions from "./Permissions";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            manager: {},

            managerFirstName: '',
            managerLastName: '',

            validationList: [],

        };

        this.deleteManager = this.deleteManager.bind(this);
        this.refreshManager = this.refreshManager.bind(this);
    }

    /**
     * Get the manager based on the manager ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                manager: response.data,
                managerFirstName: response.data.firstName,
                managerLastName: response.data.lastName,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the manager currently being viewed.
     */
    deleteManager() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/manager/managers`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'manager.managers.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Force a refresh of this component if required by a child component. Because this component displays the
     * managers's first and last name in the breadcrumbs, this component should update if a child component makes an
     * update to said values.
     *
     * @param managerId - The ID of the manager to fetch data from.
     */
    refreshManager(managerId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${managerId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                manager: response.data,
                managerFirstName: response.data.firstName,
                managerLastName: response.data.lastName,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/manager/managers" parentPage="Managers" childPage={`${this.state.managerFirstName} ${this.state.managerLastName}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/manager/managers/${this.props.match.params.managerId}/edit`} active="edit" size="small" iconName="user" name="Manager" />

                                        {(this.props.userSession.sessionRole.id !== this.state.manager.id && (this.props.userSession?.sessionRole?.roles?.includes('PRIMARY_MANAGER') || this.props.userSession?.sessionRole?.roles?.includes('PROPERTY_MANAGER'))) &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#delete-manager">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'trash']} className="fa-fw va-b mr-2"/>
                                                    Delete Manager
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>

                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us" />

                            <ModalPhrase id="delete-manager" title="Delete Manager" iconName="exclamation-triangle" confirmPhrase="DELETE" handleSuccess={() => this.deleteManager()}>

                                <p className="">Deleting this manager will have the following effects:</p>

                                <ul className="small">
                                    <li>The manager's Propertii account will be deleted</li>
                                </ul>

                                <p className="mb-0">To delete this manager, enter the phrase 'DELETE' in the field below, then click the Confirm button.</p>

                            </ModalPhrase>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/manager/managers/:managerId/edit" type="TYPE_MANAGER" parent={this} component={Manager} refreshManager={(managerId) => this.refreshManager(managerId)} />
                                <PrivateRoute exact path="/manager/managers/:managerId/permissions" type="TYPE_MANAGER" parent={this} component={Permissions} refreshManager={(managerId) => this.refreshManager(managerId)} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);