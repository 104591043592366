import generateRequestHeaders from '../util/generateRequestHeaders';

/**
 * * use &buyer-country=US to test the paylater button
 * @param {*} currency
 * @param {*} clientId
 * @param {*} setAlertMessage
 */
export const initPaypalSDK = (currency = 'USD', clientId, setAlertMessage) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}&intent=capture&components=buttons`;
    const currentScript = document.getElementById('paypal-script');
    if (currentScript) {
      document.body.removeChild(currentScript);
    }
    script.setAttribute('id', 'paypal-script');
    document.body.appendChild(script);

    const injectScript = () => {
      script.removeEventListener('load', injectScript);
      resolve();
    };

    script.addEventListener('load', injectScript);
    script.addEventListener('error', () => {
      setAlertMessage('Something went wrong trying to connect to PayPal. ');
    });
  });
};

export const buildCreateOrderObject = (
  paymentAmount,
  tenantEmail,
  landlordEmail,
  companyId,
  userPropertyId,
  tenantUnit,
  securityToken
) => {
  const createOrderObject = {
    method: 'POST',
    headers: generateRequestHeaders(),
    body: JSON.stringify({
      tenantEmail: tenantEmail,
      managerEmail: landlordEmail,
      paymentMethod: {
        type: 'TYPE_PAY_PAL',
      },
      billingAddress: {
        type: 'TYPE_ADDRESS',
        addressType: 'BILLING',
      },
      charges: [
        {
          amount: paymentAmount,
          code: 'payment',
          name: 'Payment',
        },
      ],
      companyId: companyId,
      userPropertyId: userPropertyId,
      unit: tenantUnit,
      securityToken: securityToken,
    }),
  };
  return createOrderObject;
};

export const buildCaptureOrderObject = (
  data,
  paypalUserId,
  paymentAmount,
  userPropertyId,
  companyId,
  recipientId,
  tenantUnit,
  notifyLandlord,
  sendReceiptByEmail,
  sendReceiptByTextMessage
) => {
  const captureOrderObject = {
    method: 'POST',
    headers: generateRequestHeaders(),
    body: JSON.stringify({
      orderId: data.orderID,
      userId: paypalUserId,
      amount: paymentAmount,
      propertyId: userPropertyId,
      companyId: companyId,
      recipientId: recipientId,
      unit: tenantUnit,
      sendEmailToManager: notifyLandlord,
      sendReceiptByEmail: sendReceiptByEmail,
      sendReceiptByTextMessage: sendReceiptByTextMessage,
    }),
  };
  return captureOrderObject;
};
