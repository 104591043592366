import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from "react";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import ButtonClose from "./ButtonClose";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Table from "./Table";
import Modal from "./Modal";
import jsPDF from 'jspdf'
import 'jspdf-autotable'

class ReportOverdueCharges extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            chargeList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            validationList: [],

            overdueCharge: {},

        };

        this.getCharges = this.getCharges.bind(this);
        this.downloadCharges = this.downloadCharges.bind(this);
        this.transformDataForPDF = this.transformDataForPDF.bind(this);
        this.getHeaderForPDF = this.getHeaderForPDF.bind(this);
    }

    /**
     * Fetch the list of related properties and transactions upon mounting of the component.
     */
    componentDidMount() {

        this.getCharges(1,25);
    }

    /**
     * Update the data table of charges.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     */
    getCharges(page, recordsPerPage) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_overdue_charges?recordsPerPage=${recordsPerPage}&page=${page}`,{}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                chargeList: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }


    /**
     * Show Modal to confirm download format
     *
     * @param event - The event container.
     */
     downloadCharges(event) {

        event.preventDefault();

        $("#download-format-confirm").modal("show")
    }

    /**
     * Download the overdue charges report in CSV format.
     *
     * @param event - The event container.
     */
    downloadChargesCSV() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_overdue_charges/download`, {
            parameters: this.state.parameters,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

     /**
     * Download the overdue charges report in PDF format. The downloaded file is based on the filters set by the user.
     *
     * 
     */
      downloadChargesPDF() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_overdue_charges/download`, {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            parameters: this.state.parameters,
        }, {
            responseType: 'string',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const dataTransformed = this.transformDataForPDF(response.data);
            const doc = new jsPDF('landscape', 'mm', [297, 300]);

            doc.setFontSize(12);
            doc.text('Overdue Charges Report', 14, 10)
            
            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak:false,
                theme: 'grid',
                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 20 },
                    1: { cellWidth : 50 },
                    2: { cellWidth : 20 },
                    3: { cellWidth : 60 },
                    4: { cellWidth : 30 },
                    5: { cellWidth : 20 },
                    6: { cellWidth : 20 },
                    7: { cellWidth : 20 },
                    8: { cellWidth : 20 },  
                }
              });
            doc.save('Overdue Charges Report.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Transform data to get header and body to pass into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     transformDataForPDF(data) {
        const rows = data.split('\n');
        let headerForPDF = '';
        let dataForPDF = {};
        let bodyForPDF = [[]];

        if(rows.length >0){
            headerForPDF = this.getHeaderForPDF(rows[0]);
            bodyForPDF = this.getBodyForPDF(rows);
            dataForPDF ={
                headerForPDF : headerForPDF,
                bodyForPDF : bodyForPDF,
            }
        }

        return dataForPDF;
      
    }

    /**
     * Get the header to be passed into jsPDF
     *
     * @param headerData - The data returned by the endpoint to be converted into PDF.
     */
     getHeaderForPDF(headerData) {
        const headersToBeCleaned = headerData.split("\"");
        const headers = headersToBeCleaned.filter( header => header !== ',' && header !== '' && header !== '\r');
        
        return headers;
      
    }

    /**
     * Get the body to be passed into jsPDF
     *
     * @param data - The data returned by the endpoint to be converted into PDF.
     */
     getBodyForPDF(data) {
        let bodyToBeCleaned = [];
        let rowAux = [];
        let body = [];
        
        for (let i=1; i < data.length; i++){
            rowAux = data[i].split("\"");
            rowAux.shift();
            bodyToBeCleaned.push(rowAux);
            body[i-1] = bodyToBeCleaned[i-1].filter( row => row !== ',' && row !== '\r'); 
        }
        return body;
    }


     /**
     * View the detailed information of a overdueCharge by initializing the overdueCharges view modal.
     *
     * @param overdueCharges - The overdueCharges model to view.
     */
    viewOverdueCharge(overdueCharge) {

        this.setState(prevState => ({
            ...prevState,
            overdueCharge: overdueCharge
        }));

        $('#overdueCharge').modal('show');
    }

    /**
     * Render the component.
     *
     * @returns {*} - The overdue charges report component.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Overdue Charges
                            </div>
                            <div className="col text-right">
                                <div className="btn btn-primary btn-sm" onClick={(event) => this.downloadCharges(event)}>
                                    <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table tableClass="table-bordered table-hover table-responsive"
                           columns={{
                               AccountId: 'Account Id',
                               Tenant: 'Tenant',
                               Unit: 'Unit',
                               Property: 'Property',
                               Charge: 'Charge',
                               Amount: 'Amount',
                               Balance: 'Balance',
                               Period: 'Billing Period',
                               DueDate: 'Due Date',
                           }}
                           minColumnWidths={[
                               '200px',
                               '200px',
                               '100px',
                               '200px',
                               '200px',
                               '100px',
                               '100px',
                               '200px',
                               '200px',
                           ]}
                           headerClass=""
                           data={this.state.chargeList}
                           sortEnabled={false}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.getCharges}>
                        <tbody>
                        {this.state.chargeList.records.map((data, key) => {
                            return (
                              <tr
                                key={key}
                                onClick={() => this.viewOverdueCharge(data)}
                                className="c-pointer"
                              >
                                <td>
                                  <div className="">{data.AccountId}</div>
                                </td>
                                <td>
                                  <div className="">{data.Tenant}</div>
                                </td>
                                <td>
                                  <div className="">{data.Unit}</div>
                                </td>
                                <td>
                                  <div className="">{data.Property}</div>
                                </td>
                                <td>
                                  <div className="">{data.Charge}</div>
                                </td>
                                <td>
                                  <div className="">{data.Amount}</div>
                                </td>
                                <td>
                                  <div className="">{data.Balance}</div>
                                </td>
                                <td>
                                  <div className="">{data.Period}</div>
                                </td>
                                <td>
                                  <div className="">{data.DueDate}</div>
                                </td>
                              </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </div>
                {/* Overdue Charge Modal */}
                <div className="modal fade" id="overdueCharge" tabIndex="-1" role="dialog" aria-labelledby="overdueCharge-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <div className="modal-header bg-dark text-white">
                                <h5 className="modal-title">
                                    <div className="">
                                        Overdue Charge
                                    </div>
                                </h5>
                                <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                    <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                </button>
                            </div>
                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    <tbody>
                                        <tr className="small">
                                            <td className="">
                                                Account Id
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.AccountId}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Tenant
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Tenant}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Unit
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Unit}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Property
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Property}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Charge
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Charge}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Amount
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Amount}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Balance
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Balance}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Billing Period
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.Period}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="small">
                                            <td className="">
                                                Due Date
                                            </td>
                                            <td className="text-right">
                                                <div className="">
                                                    {this.state.overdueCharge.DueDate &&
                                                    <Moment format="YYYY-MM-DD HH:mm (UTC)" tz="UTC">
                                                        {this.state.overdueCharge.DueDate}
                                                    </Moment>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                                <ButtonClose />
                            </div>
                        </div>
                    </div>
                </div>
                <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet" 
                        body="What format do you want to download the report?" title="Download Format Confirmation">
                        
                        <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadChargesCSV()}>
                            CSV Format
                        </button>
                        <button onClick={() => this.downloadChargesPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                            PDF Format
                        </button>
                </Modal>                                 

            </React.Fragment>
        );
    }
}

export default injectIntl(ReportOverdueCharges);