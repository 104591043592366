import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React from 'react';
import {FormattedMessage, injectIntl, intlShape } from 'react-intl';
import familyMoving from "../media/img/backgrounds/couple-moving.jpg";
import Letus from "./common/Propertii";

/* eslint no-script-url: 0 */


class Contact extends Letus {

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing static component that displays the contact page.
     */

    componentDidMount() {
        const contactButton = document.getElementById("zopim");
        if(contactButton) {
            $('#zopim').attr('onClick', '$zopim.livechat.window.show();');
        }
    }
    render() {

        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`}}>
                    <div className="container text-md-center">
                        <h1 className="display-5 pt-3 text-white">
                            <FormattedMessage
                                id='common.label.contact-us'
                                defaultMessage='Contact Us'
                            />
                        </h1>
                        <p className="lead text-white">
                            <FormattedMessage
                                id='contact.header.subtitle.world-class'
                                defaultMessage="Letus' world-class support team is here to assist you"
                            />
                        </p>
                    </div>
                </div>

                <div className="container">

                    <div className="row justify-content-center">

                        <div className="col-md-4 text-center mb-5 mb-sm-0">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-1.title.write-us'
                                        defaultMessage="Write to us"
                                    />
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'envelope-open-text']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-1.description.write-us'
                                        defaultMessage="Our inbox is open 24 hours a day, 7 days a week"
                                    />
                                </p>
                                <a href="mailto:support@let.us" className="btn btn-primary btn-lg">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-1.action.send-message'
                                        defaultMessage="Send a Message"
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 text-center mb-5 mb-sm-0 border-left border-right">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-2.title.call-us'
                                        defaultMessage="Call or text us"
                                    />
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'phone']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-2.description.call-us'
                                        defaultMessage="Available 7:00 AM – 4:00 PM Monday-Friday (Pacific Time)"
                                    />
                                </p>
                                <a href="tel:18886658870" className="btn btn-primary btn-lg">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-2.action.phone-no'
                                        defaultMessage="1-888-665-8870"
                                    />
                                </a>
                            </div>
                        </div>

                        <div className="col-md-4 text-center">
                            <div className="px-3">
                                <h3 className="display-5 mb-5">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-3.title.chat-live'
                                        defaultMessage="Chat with us live"
                                    />
                                </h3>
                                <FontAwesomeIcon icon={['fal', 'comments']} className="fa-fw mb-5 text-primary" size="6x" />
                                <p className="mb-4">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-3.description.chat-live'
                                        defaultMessage="Available 7:00 AM – 4:00 PM Monday-Friday (Pacific Time)"
                                    />
                                </p>
                                <button id="zopim" className="btn btn-primary btn-lg">
                                    <FormattedMessage
                                        id='contact.body.sec-1.subsec-2.action.start-chat'
                                        defaultMessage="Start a Chat"
                                    />
                                </button>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-5 mb-4">
                                <FormattedMessage
                                    id='contact.body.sec-2.title.burning-questions'
                                    defaultMessage="Your burning questions, answered instantly"
                                />
                            </h3>
                        </div>

                        <div className="row justify-content-sm-center">
                            <div className="col-md-6 text-center">

                                <p className="">
                                    <FormattedMessage
                                        id='contact.body.sec-2.description.need-hand'
                                        defaultMessage="Need a hand? From step-by-step guides to tips on making your rent payment experience easier than ever before, the Letus Help Center has you covered with articles about Letus."
                                    />
                                </p>

                                <div className="text-center">
                                    <a href="https://help.rentmoola.com/hc/en-us" target="_blank" rel="noopener noreferrer" className="btn btn-lg btn-primary mt-3">
                                        <FormattedMessage
                                            id='contact.body.sec-2.action.visit-help'
                                            defaultMessage="Visit our Help Center"
                                        />
                                    </a>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>
        );
    };
}

Contact.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Contact);