import React, { useEffect } from 'react';
import Alert from '../../common/Alert';
import ButtonSave from '../../common/ButtonSave';
import FieldTextFC from '../../common/FieldTextFC';
import FieldSelectFC from '../../common/FieldSelectFC';
import { useInput } from '../../common/hooks/useInput';
import { useValidationList } from '../../common/hooks/useValidationList';
import useMerchantAccounts from '../../common/hooks/useMerchantAccounts';
import useHyperwallet from './hooks/useHyperwallet';
import useCreateUpdateHyperwallet from './hooks/useCreateUpdateHyperwallet';
import { useIsFetching } from 'react-query';

/**
 * This component uses some advanced React concepts such as:
 * React custom hooks - https://reactjs.org/docs/hooks-intro.html
 * React Query - https://react-query.tanstack.com/
 * Unfamiliarity with the above topics might make the debugging harder.
 * Please refer to the above docs to learn more about these concepts.
 * @author Arsh Uppal
 * @param {*} props
 */
const Hyperwallet = (props) => {
  /****** Form State ******/
  const {
    value: pageName,
    setValue: setPageName,
    handleChange: handleChangePageName,
  } = useInput('');
  const {
    value: merchantAccountVisa,
    setValue: setMerchantAccountVisa,
    handleChange: handleChangeMerchantAccountVisa,
  } = useInput('');
  const {
    value: merchantAccountUnionPay,
    setValue: setMerchantAccountUnionPay,
    handleChange: handleChangeMerchantAccountUnionPay,
  } = useInput('');
  const {
    value: merchantAccountPaypal,
    setValue: setMerchantAccountPaypal,
    handleChange: handleChangeMerchantAccountPaypal,
  } = useInput('');
  const {
    value: merchantAccountSplitIt,
    setValue: setMerchantAccountSplitIt,
    handleChange: handleChangeMerchantAccountSplitIt,
  } = useInput('');

  const { validationList, addErrorToValidationList } = useValidationList([]);
  /*********************/

  /****** Actions ******/
  const isFetching = useIsFetching();

  const { merchantAccounts, merchantAccountsQuery } = useMerchantAccounts(
    props.match?.params?.companyId
  );

  const { hyperwallet, hyperwalletQuery } = useHyperwallet(
    props.match?.params?.companyId
  );

  const { createUpdateHyperwalletQuery } = useCreateUpdateHyperwallet();

  // Error handling
  useEffect(() => {
    if (merchantAccountsQuery.isError) {
      addErrorToValidationList(merchantAccountsQuery.error);
    }
    if (hyperwalletQuery.isError) {
      addErrorToValidationList(hyperwalletQuery.error);
    }
    if (createUpdateHyperwalletQuery.isError) {
      addErrorToValidationList(createUpdateHyperwalletQuery.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    merchantAccountsQuery.isError,
    hyperwalletQuery.isError,
    createUpdateHyperwalletQuery.isError,
  ]);

  useEffect(() => {
    //If record exist
    if (hyperwallet.id) {
      setPageName(hyperwallet?.pageName);
      setMerchantAccountVisa(hyperwallet?.creditMerchantAccountList?.VISA);
      setMerchantAccountUnionPay(hyperwallet?.creditMerchantAccountList?.JCB);
      setMerchantAccountPaypal(hyperwallet?.paypalMerchantAccountId);
      setMerchantAccountSplitIt(hyperwallet?.splitItMerchantAccountId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hyperwallet]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const params = {
      type: 'TYPE_HYPER_WALLET_PAGE',
      id: hyperwallet.id || null,
      companyId: props.match?.params?.companyId,
      pageName: pageName,
      bankMerchantAccountId: null,
      paypalMerchantAccountId: merchantAccountPaypal,
      splitItMerchantAccountId: merchantAccountSplitIt,
      creditMerchantAccountList: {
        VISA: merchantAccountVisa,
        VISA_ELECTRON: merchantAccountVisa,
        MASTERCARD: merchantAccountVisa,
        MAESTRO: merchantAccountVisa,
        AMERICAN_EXPRESS: merchantAccountVisa,
        DISCOVER: merchantAccountVisa,
        DINERS_CLUB: merchantAccountVisa,
        JCB: merchantAccountUnionPay,
      },
    };

    const payload = { params: params };

    if (hyperwallet.id) {
      payload.method = 'put';
      payload.action = 'update';
      createUpdateHyperwalletQuery.mutate(payload);
    } else {
      payload.method = 'post';
      payload.action = 'create';
      createUpdateHyperwalletQuery.mutate(payload);
    }
  };
  /*********************/

  return (
    <React.StrictMode>
      <Alert validationList={validationList} />
      <form onSubmit={handleSubmit}>
        <div className='card'>
          <div className='card-header'>
            <div className='row align-items-center'>
              <div className='col'>Hyperwallet</div>
              {Boolean(isFetching) && (
                <span
                  className='spinner-border spinner-border-sm ml-1'
                  role='status'
                  aria-hidden='true'
                ></span>
              )}
            </div>
          </div>
          <div className='card-body'>
            <FieldTextFC
              id='pageName'
              label='Page Name'
              value={pageName}
              required={true}
              handleChange={handleChangePageName}
            />
            <FieldSelectFC
              id='visa'
              label='Credit/Debit Card'
              help='Applies to Visa, Visa Electron, Mastercard, Maestro, American Express, Discover, Diners Club, and JCB cards.'
              value={merchantAccountVisa}
              handleChange={handleChangeMerchantAccountVisa}
            >
              <option value=''>-</option>
              {merchantAccounts.visaMerchantAccounts &&
                merchantAccounts.visaMerchantAccounts.map((data, key) => {
                  return (
                    <option key={key} value={data.id}>
                      {data.accountNumber ? data.accountNumber + ' — ' : ''}
                      {data.name ? data.name + ' — ' : ''}
                      {data.last4 ? '*****' + data.last4 + ' — ' : ''}
                      {data.accountStatus}
                    </option>
                  );
                })}
            </FieldSelectFC>
            <FieldSelectFC
              id='unionpay'
              label='UnionPay'
              value={merchantAccountUnionPay}
              handleChange={handleChangeMerchantAccountUnionPay}
            >
              <option value=''>-</option>
              {merchantAccounts.unionPayMerchantAccounts &&
                merchantAccounts.unionPayMerchantAccounts.map((data, key) => {
                  return (
                    <option key={key} value={data.id}>
                      {data.accountNumber ? data.accountNumber + ' — ' : ''}
                      {data.name ? data.name + ' — ' : ''}
                      {data.last4 ? '*****' + data.last4 + ' — ' : ''}
                      {data.accountStatus}
                    </option>
                  );
                })}
            </FieldSelectFC>
            <FieldSelectFC
              id='paypal'
              label='Paypal'
              value={merchantAccountPaypal}
              handleChange={handleChangeMerchantAccountPaypal}
            >
              <option value=''>-</option>
              {merchantAccounts.paypalMerchantAccounts &&
                merchantAccounts.paypalMerchantAccounts.map((data, key) => {
                  return (
                    <option key={key} value={data.id}>
                      {data.accountNumber ? data.accountNumber + ' — ' : ''}
                      {data.name ? data.name + ' — ' : ''}
                      {data.last4 ? '*****' + data.last4 + ' — ' : ''}
                      {data.accountStatus}
                    </option>
                  );
                })}
            </FieldSelectFC>
            <FieldSelectFC
              id='splitIt'
              label='SplitIt'
              value={merchantAccountSplitIt}
              handleChange={handleChangeMerchantAccountSplitIt}
            >
              <option value=''>-</option>
              {merchantAccounts.bankMerchantAccounts &&
                merchantAccounts.bankMerchantAccounts.map((data, key) => {
                  return (
                    <option key={key} value={data.id}>
                      {data.accountNumber ? data.accountNumber + ' — ' : ''}
                      {data.name ? data.name + ' — ' : ''}
                      {data.last4 ? '*****' + data.last4 + ' — ' : ''}
                      {data.accountStatus}
                    </option>
                  );
                })}
            </FieldSelectFC>
            {hyperwallet.pageName && (
              <div className='form-group row'>
                <label className='col-sm-3 col-form-label-sm'>URL</label>
                <div className='col-sm-9'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={'/pay/marketplace'}
                  >
                    {window.location.host}/pay/marketplace
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='row'>
          <div className='col text-right'>
            <ButtonSave />
          </div>
        </div>
      </form>
    </React.StrictMode>
  );
};

export default Hyperwallet;
