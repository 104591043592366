import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Moment from 'react-moment';
import FieldTextFC from '../../common/FieldTextFC';

/**
 * Component for not validated NSF transactions
 */
const NSFTransactionsNotValidated = ({
  formatMessage,
  transactions,
  selectAllTransactions,
  selectedTransactionIds,
  searchQueryFilter,
  handleChangeSearchQueryFilter,
  getProcessingAlerts,
  selectPaymentTransaction,
  handleSelectUnSelectAllTransactions,
  sortTransactions,
  selectedNsfTransactions,
  handleNsfFeeAmountChange,
  handleNsfFeePaymentMethodChange
}) => {
  const [transactionsShown, setTransactionsShown] = useState(5);
  const [colSortDetails, setColSortDetails] = useState(null);

  /**
   * Sort table ASC or DESC by the column
   * Default sort will ASC
   * @param {*} colName
   */
  const handleColSort = (colName) => {
    let sort = 'sort-up';
    if (colSortDetails?.colName === colName) {
      sort = colSortDetails?.sort === 'sort-up' ? 'sort-down' : 'sort-up';
    }
    setColSortDetails({
      colName: colName,
      sort: sort,
    });

    let sortBy = null;
    if(colName === 'COMPANY') {
      sortBy = 'joins.co.name';
    } else if(colName === 'TENANT') {
      sortBy = 'joins.c.firstName';
    }
    const sortDirection = sort  === 'sort-up' ? 'ASC' : 'DESC';
    sortTransactions('NOT_VALIDATED_NSF_TRANSACTIONS', sortBy, sortDirection, 'STRING');
  };

  return (
    <div className='card mb-0'>
      <div
        className='card-header bg-secondary text-dark collapsed c-pointer'
        data-toggle='collapse'
        data-target='#nsf-transactions'
        aria-expanded='true'
        aria-controls='nsf-transactions'
        onClick={() => getProcessingAlerts('NOT_VALIDATED_NSF_TRANSACTIONS')}
      >
        <div className='row align-items-center'>
          <div className='col'>
            <span
              className={`badge badge-pill badge-${
                transactions?.totalRecordCount === 0 ? 'dark' : 'danger'
              } va-b mr-1`}
            >
              {transactions?.totalRecordCount}
            </span>{' '}
            NSF
            <small className='text-muted text-uppercase'>/ All Time</small>
          </div>
          <div className='col'>
            <div className='float-right'>
              <i className='fas'> </i>
            </div>
          </div>
        </div>
      </div>
      <div id='nsf-transactions' className='collapse'>
        {transactions?.totalRecordCount ? (
          <div className='card-body card-body-table'>
            <div className='col-12 px-0'>
              <FieldTextFC
                id='searchQueryFilter'
                value={searchQueryFilter}
                handleChange={(event) =>
                  handleChangeSearchQueryFilter(event, 'NOT_VALIDATED_NSF_TRANSACTIONS')
                }
                placeholder={'Search by tenant name, merchant account or a company name...'}
                fieldColumns={12}
                labelClass={'d-none'}
              />
            </div>
            <table className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th width='14%'>Date</th>
                  <th width='10%'>Amount</th>
                  <th width='14%' className='c-pointer' onClick={() => handleColSort('COMPANY')}>
                    Company
                    <span className='float-right'>
                      <FontAwesomeIcon
                        icon={[
                          'fas',
                          colSortDetails?.colName === 'COMPANY' ? colSortDetails.sort : 'sort',
                        ]}
                      />
                    </span>
                  </th>
                  <th width='14%' className='c-pointer' onClick={() => handleColSort('TENANT')}>
                    Tenant
                    <span className='float-right'>
                      <FontAwesomeIcon
                        icon={[
                          'fas',
                          colSortDetails?.colName === 'TENANT' ? colSortDetails.sort : 'sort',
                        ]}
                      />
                    </span>
                  </th>
                  <th width='11%'>Processor</th>
                  <th width='5%'>NSF Fee</th>
                  <th width='15%'>Payment Method</th>
                  <th width='5%'>Previous Attempts</th>
                  <th width='11%'>Status</th>
                  <th role='checkbox' aria-checked='false'>
                    <div className={`custom-control custom-checkbox`}>
                      <input
                        id='selectAllNSF'
                        type='checkbox'
                        className={`custom-control-input position-relative w-50`}
                        checked={selectAllTransactions}
                        onChange={() =>
                          handleSelectUnSelectAllTransactions('NOT_VALIDATED_NSF_TRANSACTIONS')
                        }
                      />
                      <label
                        htmlFor='selectAllNSF'
                        className={`custom-control-label pt-1 text-break`}
                      ></label>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {transactions?.records && (
                  <>
                    {transactions?.records?.map((data, key) => {
                        if (key <= transactionsShown) {
                          return (
                            <tr
                              key={key}
                              onClick={(event) => selectPaymentTransaction(data, event)}
                              className='c-pointer'
                            >
                              <td>
                                <div className=''>
                                  <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                </div>
                              </td>
                              <td>
                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                  {data.amount && (
                                    <FormattedNumber
                                      value={data.amount + data.feeAmount}
                                      style={`currency`}
                                      currency={data.currency}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>{<div>{data.joins?.co?.name}</div>}</td>
                              <td>
                                {data.joins && data.joins.c && (
                                  <div className=''>
                                    {data.joins.c.firstName} {data.joins.c.lastName}
                                  </div>
                                )}
                              </td>
                              <td>
                                <div className=''>
                                  <FormattedMessage
                                    id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                  />
                                </div>
                              </td>
                              <td>

                                {selectedTransactionIds.includes(data?.id) && (
                                      <input
                                          type='text'
                                          id={`${data?.id}-nsfFeeAmount`}
                                          name={`${data?.id}`}
                                          value={selectedNsfTransactions[data?.id].nsfFeeAmount}
                                          data-input-cell='true'
                                          onChange={handleNsfFeeAmountChange}
                                      />
                                  )}

                                {!selectedTransactionIds.includes(data?.id) && selectedNsfTransactions[data?.id] && selectedNsfTransactions[data?.id]?.nsfFeeAmount && (
                                    <div className=''>
                                      {selectedNsfTransactions[data?.id].nsfFeeAmount}
                                    </div>
                                )}

                                {!selectedTransactionIds.includes(data?.id) && !selectedNsfTransactions[data?.id] && (
                                   <div className=''>
                                      {data?.joins?.co?.nsfFeeAmount}
                                   </div>
                                )}
                              </td>
                              <td>

                                {selectedTransactionIds.includes(data?.id) && (

                                    <select
                                        name={`${data?.id}`}
                                        id={`${data?.id}-nsfFeePaymentMethod`}
                                        value={selectedNsfTransactions[data?.id].nsfFeePaymentMethodId}
                                        data-select-cell='true'
                                        onChange={handleNsfFeePaymentMethodChange}
                                    >

                                      {Object.keys(data?.nsfFeePaymentMethodIds).map((nsfFeePaymentMethodId) => {

                                        return(
                                                <React.Fragment>
                                                  <option value={nsfFeePaymentMethodId}>BANK ACCOUNT ......{data?.nsfFeePaymentMethodIds[nsfFeePaymentMethodId]}</option>
                                                </React.Fragment>
                                            )

                                      })}
                                    </select>
                                )}

                                {!selectedTransactionIds.includes(data?.id) && selectedNsfTransactions[data?.id] && selectedNsfTransactions[data?.id]?.nsfFeePaymentMethodId && (
                                    <div className=''>
                                      BANK ACCOUNT ......{data?.nsfFeePaymentMethodIds[selectedNsfTransactions[data?.id]?.nsfFeePaymentMethodId]}
                                    </div>
                                )}

                                {!selectedTransactionIds.includes(data?.id) && (!selectedNsfTransactions[data?.id] || !selectedNsfTransactions[data?.id]?.nsfFeePaymentMethodId) && (
                                    <div className=''>
                                      BANK ACCOUNT ......{data?.nsfFeePaymentMethodIds[data?.paymentMethodId]}
                                    </div>
                                )}
                              </td>
                              <td>
                                <div className=''>
                                  {data?.nsfFeeChargingAttempts}
                                </div>
                              </td>
                              <td>
                                {data.status && (
                                  <div className='break-word'>
                                    <FontAwesomeIcon
                                      icon={['fas', 'circle']}
                                      className={`fa-fw small ${formatMessage({
                                        id: 'enum.transactionStatus.' + data.status + '.class',
                                      })}`}
                                    />
                                    <span className='ml-1'>
                                      <FormattedMessage
                                        id={'enum.transactionStatus.' + data.status}
                                      />
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td data-checkbox-cell='true' role='checkbox' aria-checked='false'>
                                <div
                                  className={`custom-control custom-checkbox`}
                                  data-checkbox-cell='true'
                                >
                                  <input
                                    type='checkbox'
                                    id={data?.id}
                                    name={data?.id}
                                    value={data?.id}
                                    className={`custom-control-input position-relative w-50`}
                                    data-checkbox-cell='true'
                                    checked={selectedTransactionIds.includes(data?.id)}
                                    onChange={() => {}}
                                  />
                                  <label
                                    htmlFor={data?.id}
                                    className={`custom-control-label pt-1 text-break`}
                                    data-checkbox-cell='true'
                                  ></label>
                                </div>
                              </td>
                            </tr>
                          );
                        }

                        return null;
                      })}
                    {transactionsShown < transactions?.records?.length && (
                      <tr className='c-pointer'>
                        <td
                          colSpan='12'
                          className='text-center'
                          onClick={() => setTransactionsShown(transactionsShown + 10)}
                        >
                          <div className='btn-link'>
                            Load more records{' '}
                            <FontAwesomeIcon icon={['fas', 'caret-down']} className='fa-fw' />
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
            {!!selectedTransactionIds?.length && (
              <div className='container'>
                <p className='row small row p-2 m-0 text-muted justify-content-center'>
                  {selectedTransactionIds?.length} transaction(s) selected
                </p>
                <div className='row px-2 pb-2 pt-0 m-0 justify-content-center'>
                  <button
                    type='button'
                    className='btn btn-primary btn-md mr-2'
                    onClick={() => {
                      $('#receipt-payment-transaction').modal('hide');
                      $('#mark-valid-nsf-transaction').modal('show');
                    }}
                  >
                    Mark Valid
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger btn-md'
                    onClick={() => {
                      $('#receipt-payment-transaction').modal('hide');
                      $('#mark-invalid-nsf-transaction').modal('show');
                    }}
                  >
                    Mark Invalid
                  </button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='card-body'>
            <div className='row justify-content-center'>
              <div className='col-10'>
                <div className='text-center text-muted'>
                  <FontAwesomeIcon icon={['fal', 'smile-beam']} className='fa-fw mb-4' size='5x' />
                </div>
                <p className='text-center text-muted mb-0'>
                  <small>We have no NSF transactions at this time!</small>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NSFTransactionsNotValidated.propTypes = {
  formatMessage: PropTypes.func,
  transactions: PropTypes.object,
  selectAllTransactions: PropTypes.bool,
  selectedTransactionIds: PropTypes.array,
  searchQueryFilter: PropTypes.string,
  handleChangeSearchQueryFilter: PropTypes.func,
  selectPaymentTransaction: PropTypes.func,
  handleSelectUnSelectAllTransactions: PropTypes.func,
  getProcessingAlerts: PropTypes.func,
  sortTransactions: PropTypes.func,
  selectedNsfTransactions: PropTypes.object,
  handleNsfFeeAmountChange: PropTypes.func,
  handleNsfFeePaymentMethodChange: PropTypes.func,
};

export default NSFTransactionsNotValidated;
