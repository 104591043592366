import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import FieldCountry from "../../common/FieldCountry";
import FieldPaymentProvider from "../../common/FieldPaymentProvider";
import FieldPhone from "../../common/FieldPhone";
import FieldProvince from "../../common/FieldProvince";
import FieldRadio from '../../common/FieldRadio';
import FieldSelect from "../../common/FieldSelect";
import FieldState from "../../common/FieldState";
import FieldText from "../../common/FieldText";
import LogoPaymentProvider from "../../common/LogoPaymentProvider";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class MerchantAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            merchantAccount: {
                minimumPayoutAmount: 10,
                refundReserve: 0,
                descriptor: 'Letus Rent'
            },
            customFields: {},

            paymentProviders: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            merchantAccounts: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            searchFilter: '',

            query: {
                orderBy: 'DESC',
                orderByFields: ['accountStatus'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },

            validationList: [],

        };

        this.updateTable = this.updateTable.bind(this);
        this.selectRecord = this.selectRecord.bind(this);
        this.initMerchantAccount = this.initMerchantAccount.bind(this);
        this.createMerchantAccount = this.createMerchantAccount.bind(this);
        this.clearMerchantAccount = this.clearMerchantAccount.bind(this);
        this.handleChangeSearchFilter = this.handleChangeSearchFilter.bind(this);
        this.handleSubmitSearchFilter = this.handleSubmitSearchFilter.bind(this);
        this.handleChangeBankAccountType = this.handleChangeBankAccountType.bind(this);

        this.showFormatDownloadPage = this.showFormatDownloadPage.bind(this);
        this.downloadPropReportCSV = this.downloadMerchantAccountReportCSV.bind(this);
        this.downloadPropReportPDF = this.downloadMerchantAccountReportPDF.bind(this);
    }

    /**
     * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
     * on the company ID in the route.
     */
    componentDidMount() {

        this.updateTable(1, 25, this.state.query);
    }

    /**
     * Initialize a new instance of a merchant account when the user clicks the 'create merchant account' button.
     */
    initMerchantAccount() {

        let merchantAccount = this.state.merchantAccount;

        axios.get(`${constants.REACT_APP_HOST_API_URL}/merchant_account/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                validationList: [],
                merchantAccount: {
                    ...response.data,
                    ...merchantAccount,
                    id: null,
                    companyId: this.props.match.params.companyId,
                    accountStatus: 'PENDING',
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_provider/search?recordsPerPage=100&page=1`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentProviders: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting a new merchant account.
     *
     * @event - The event container.
     */
    createMerchantAccount(event) {

        event.preventDefault();

        let merchantAccount = this.state.merchantAccount;

        merchantAccount.customFields = this.state.customFields;

        // LP-949 @author Arsh Uppal
        // The library which we are using for phone numbers enforce + by default
        // that is the reason why I am manually removing '+'
        if(merchantAccount.customFields) {
            if (merchantAccount.customFields.phone && merchantAccount.customFields.phone !== undefined) {
                const phoneWith1 = merchantAccount.customFields.phone;
                if(phoneWith1.charAt(0) === "+") {
                    merchantAccount.customFields.phone = phoneWith1.substring(1); // removes + from the phone number
                }
            }
        }

        if(merchantAccount?.paymentProviderId === "PAYSAFE_CASH") {
            merchantAccount.disbursementDelay = 0;
            if(merchantAccount.customFields.bankAccountType === undefined || merchantAccount.customFields.bankAccountType ===null){
                merchantAccount.customFields.bankAccountType = "CHEQUING";
            }
        }

        if(!merchantAccount?.paymentType && merchantAccount?.paymentProviderId === "BLUESNAP") {
            merchantAccount.paymentType = "TYPE_WALLET";
        }

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, merchantAccount, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.merchants.created'
                    }
                }],
            });

            this.updateTable(this.state.merchantAccounts.page, this.state.merchantAccounts.recordsPerPage, this.state.query);

            $('#createMerchantAccount').modal('hide');

            this.clearMerchantAccount();

        }).catch(error => {

            this.handleValidation(error);

            window.scrollTo(0, 0);

        });
    }

    /**
     * Clear all merchant account fields in the creation form.
     */
    clearMerchantAccount() {

        this.setState(prevState => ({
            ...prevState,
            validationList: [],
            merchantAccount: {
                id: null,
                customFields: [],
                companyId: this.props.match.params.companyId,
                accountStatus: 'PENDING',
            },
            customFields: {},
        }));
    }

    /**
     * Handle clicking a merchant account by redirecting the user to the edit merchant account component.
     *
     * @param merchantAccountId - The ID of the merchant account clicked on.
     */
    selectRecord(event, merchantAccountId) {
        if (event.metaKey || event.ctrlKey){
            window.open(`/admin/companies/${this.props.match.params.companyId}/merchants/${merchantAccountId}`, "_blank");
        } else {
            this.props.history.push(`/admin/companies/${this.props.match.params.companyId}/merchants/${merchantAccountId}`);
        }
    }

    /**
     * Update the data table of merchant accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    updateTable(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                merchantAccounts: response.data,
                query: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes made to the search filter field.
     *
     * @param event - The event container.
     */
    handleChangeSearchFilter(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            searchFilter: event.target.value
        }));

        if (event.target.value === '') {
            this.handleSubmitSearchFilter(null, event.target.value);
        }
    }

    /**
     * Handle submitting the search filter field by adjusting the merchant account search query and initiating a new
     * search.
     *
     * @param event - The event container.
     * @param searchFilter - The value within the search filter field.
     */
    handleSubmitSearchFilter(event, searchFilter) {

        if (event != null) {
            event.preventDefault();
        }

        let query = {
            orderBy: 'ASC',
            orderByFields: ['accountStatus'],
            conditionList: [
                {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                fieldName: 'companyId',
                operator: 'EQUALS',
                fieldValue: this.props.match.params.companyId
            }]
        };

        if (searchFilter !== '') {

            query.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'name,accountNumber',
                    operator: 'MATCH',
                    fieldValue: searchFilter
                }
            );
        }

        this.setState({
            query: query
        });

        this.updateTable(1, 25, query);
    }
    /**
     * Handle change for the FieldRadio component
     * @param {*} event - Synthetic event
     */
    handleChangeBankAccountType(event){

        event.persist();

        let bnkAcntType = event.target.value;

        if(bnkAcntType === "SAVINGS" ||  bnkAcntType === "CHEQUING"){
            this.setState(prevState=>({
                ...prevState,
                customFields:{
                    ...prevState.customFields,
                    bankAccountType: bnkAcntType
                }
            }));
        }
    }

    /**
     * Show donwload modal for CSV or PDF
     * @param event - Event object
     */
     showFormatDownloadPage(event){
        event.preventDefault();

        $("#download-format-confirm").modal("show")
     }

    /**
     * Download the merchant account report in CSV format
     */
    downloadMerchantAccountReportCSV(){
        this.startSpinner();
        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_merchant_accounts/download`, {}, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.stopSpinner();

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download the merchant account report in PDF format
     */
    downloadMerchantAccountReportPDF(){
        this.startSpinner();
        axios.post(`${constants.REACT_APP_HOST_API_URL}/export/${this.props.companyId}/export_merchant_accounts/download`, {}, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.stopSpinner();
            const dataTransformed = this.transformDataForPDF(response.data?.split(/\r?\n/));
            const doc = new jsPDF('landscape', 'mm', [297, 475]);

            doc.setFontSize(12);
            doc.text('Merchant Accounts Report', 14, 10)

            doc.autoTable({
                head: [dataTransformed?.headerForPDF],
                body: dataTransformed?.bodyForPDF,
                horizontalPageBreak: false,
                theme: 'grid',

                headStyles:{
                    fillColor: '#e9ecef',
                    fontStyle: 'bold',
                    textColor: '#212529',
                    fontSize: '9',
                },
                bodyStyles:{
                    textColor: '#212529',
                    fontSize: '9',
                    minCellHeight: '10',
                    valign:'middle',
                },
                columnStyles:{
                    0: { cellWidth : 40 },
                    1: { cellWidth : 30 },
                    2: { cellWidth : 30 },
                    3: { cellWidth : 40 },
                    4: { cellWidth : 20 },
                    5: { cellWidth : 30 },
                    6: { cellWidth : 30 },
                    7: { cellWidth : 100 },
                }
            });
            doc.save('MerchantAccounts.pdf')

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <Alert validationList={this.state.validationList} validationType="primary"/>

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col-3">
                                Merchant Accounts
                            </div>
                            <div className='col-9 text-right'>
                                <div className='btn btn-primary btn-sm m-1' onClick={(event) => this.showFormatDownloadPage(event)}>
                                        <FontAwesomeIcon icon={['fas', 'download']} className='fa-fw'/>
                                        &nbsp;Download Report
                                    </div>
                                <div className="btn btn-primary btn-sm m-1" data-toggle="modal" data-target="#createMerchantAccount"  onClick={() => this.initMerchantAccount()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" />&nbsp;Create Merchant Account
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="card-header gotham border-top py-3 bg-secondary">
                        <form onSubmit={(event) => this.handleSubmitSearchFilter(event, this.state.searchFilter)}>
                            <div className="media">
                                <div className="media-body align-self-center mr-3">
                                    <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                               labelColums="0" placeholder="Filter by ID, name or account number..."
                                               parent={this} value={this.state.searchFilter}
                                               handleChange={this.handleChangeSearchFilter} />
                                </div>
                                <div className="align-self-center text-right">
                                    <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                        <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <Table columns={{name: 'Name', accountNumber: 'Number', paymentProviderId: 'Processor', currency: 'Currency', paymentType: 'Type', accountStatus: 'Status'}}
                           columnWidths={['25%', '15%', '15%', '15%', '15%', '15%']}
                           headerClass="c-pointer"
                           data={this.state.merchantAccounts}
                           query={this.state.query}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.updateTable}>
                        <tbody>
                        {this.state.merchantAccounts.records.map((data, key) => {
                            return (
                                <tr key={key} onClick={(event) => this.selectRecord(event, data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.accountNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <LogoPaymentProvider paymentProviderId={data.paymentProviderId} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.currency}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={`enum.paymentType.${data.paymentType}`} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-nowrap">
                                            {data.accountStatus &&
                                                <>
                                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus + ".class"})}`} />
                                                    <span className="ml-1"><FormattedMessage id={"enum.merchantAccount.accountStatus." + data.accountStatus} /></span>
                                                </>
                                            }

                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="createMerchantAccount" tabIndex="-1" role="dialog"
                     aria-labelledby="createMerchantAccount-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.createMerchantAccount}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="merchantAccount-label">
                                        Create Merchant Account
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="name" label="Name" model="merchantAccount" parent={this} required={true}
                                               value={this.state.merchantAccount['name']}/>

                                    <FieldText id="descriptor" label="Descriptor" model="merchantAccount" parent={this}
                                               value={this.state.merchantAccount['descriptor']} minLength={this.state?.merchantAccount?.paymentProviderId==='PAYPAL'?5:0} required={this.state?.merchantAccount?.paymentProviderId === 'PAYPAL'}/>

                                    <FieldText id="bankAccountNumber" label="Bank Account Number"
                                               model="merchantAccount" parent={this}
                                               required={true}
                                               value={this.state.merchantAccount['bankAccountNumber']}/>

                                    {this.state.merchantAccount['paymentProviderId'] &&
                                        this.state.merchantAccount['paymentProviderId'] === 'PAYSAFE_CASH' &&
                                     <FieldRadio id="bankAccountType" label="Bank Account Type" isPaysafeCashMerchAccnt
                                        fieldColumns="9" widthAlign="20%" parent={this} required
                                        value={this.state.customFields['bankAccountType'] || "CHEQUING"} handleChange={(event)=>this.handleChangeBankAccountType(event)}/>
                                    }

                                    <FieldText id="routingNumber" label="Routing Number" model="merchantAccount"
                                               parent={this}  required={true} value={this.state.merchantAccount['routingNumber']}/>

                                    {this.state.merchantAccount['paymentProviderId'] === 'PAYSAFE_CASH' &&
                                        <FieldText  id="bankAccountHolder" label="Bank Account Holder" model="customFields" type="name"
                                                    parent={this} required={true} value={this.state.customFields['bankAccountHolder']}/>
                                    }

                                    <FieldPaymentProvider id="paymentProviderId" label="Processor"
                                                          model="merchantAccount" parent={this}
                                                          required={true}
                                                          value={this.state.merchantAccount['paymentProviderId']} />

                                    <FieldSelect id="paymentType"
                                        label="Payment Type"
                                        model="merchantAccount"
                                        parent={this}
                                        value={this.state.merchantAccount['paymentType']}>
                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                                this.state.merchantAccount['paymentProviderId'] !== 'BLUESNAP') &&
                                            <option value="" disabled>Select a payment type...</option>
                                        }
                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'PAYSAFE_CASH' &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'LOADHUB' &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'BLUESNAP') &&
                                            <option value="TYPE_CREDIT_CARD">Credit Card</option>
                                        }
                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'LOADHUB' &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'GEOSWIFT' &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'PAYSAFE_CASH' &&
                                            this.state.merchantAccount['paymentProviderId'] !== 'BLUESNAP') &&
                                            <option value="TYPE_BANK_ACCOUNT">Bank Account</option>
                                        }

                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                            this.state.merchantAccount['paymentProviderId'] === 'PAYPAL') &&
                                            <option value="TYPE_PAY_PAL">PayPal</option>
                                        }

                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                            (this.state.merchantAccount['paymentProviderId'] === 'LOADHUB' ||
                                            this.state.merchantAccount['paymentProviderId'] === 'PAYSAFE_CASH')) &&
                                            <option value="TYPE_CASH">Cash</option>
                                        }

                                        {/* BlueSnap requires us to pass value="TYPE_CREDIT_CARD" or value="TYPE_BANK_ACCOUNT",
                                         as it will create the other one on its own */}
                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                                this.state.merchantAccount['paymentProviderId'] === 'BLUESNAP') &&
                                            <option value="TYPE_WALLET">Credit Card + Bank Account + Wallet</option>
                                        }
                                        {(this.state.merchantAccount['paymentProviderId'] &&
                                            this.state.merchantAccount['paymentProviderId'] === 'BLUESNAP') &&
                                            <option value="TYPE_CREDIT_CARD">Credit Card + Bank Account</option>
                                        }
                                    </FieldSelect>

                                    <FieldSelect id="currency" label="Currency" model="merchantAccount" parent={this}
                                                 value={this.state.merchantAccount['currency']}>
                                        <option value="" disabled>Select a currency...</option>
                                        {this.state.merchantAccount['paymentProviderId'] !== 'PAYSAFE_CASH' &&
                                            <option value="CAD">CAD</option>
                                        }
                                        <option value="USD">USD</option>
                                    </FieldSelect>
                                    { this.state.merchantAccount['paymentProviderId'] !== 'BLUESNAP' &&
                                        <>
                                            <FieldSelect id="yearlyVolumeRange" label="Yearly Volume" model="merchantAccount"
                                                        parent={this} value={this.state.merchantAccount['yearlyVolumeRange']}>
                                                <option value="" disabled>Select a yearly volume range...</option>
                                                <option value="0-50K">Low ($0-$50,000)</option>
                                                <option value="50K-100K">Medium ($50,000-$100,000)</option>
                                                <option value="100K-250K">High ($100,000-$250,000)</option>
                                                <option value="250K+">Very High ($250,000+)</option>
                                            </FieldSelect>

                                            <FieldText id="averageTransAmount" label="Average Transaction"
                                                    model="merchantAccount" type="number"
                                                    min="0.00" max="999999.99" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" parent={this}
                                                    value={this.state.merchantAccount['averageTransAmount']}/>

                                            <FieldText id="disbursementDelay" label="Disbursement Delay" model="merchantAccount"
                                                    type="number" max="99" parent={this} value={this.state.merchantAccount['paymentProviderId']==='PAYSAFE_CASH'?'0':this.state.merchantAccount['disbursementDelay']} disabled = {this.state.merchantAccount['paymentProviderId']==='PAYSAFE_CASH'}/>
                                        </>
                                    }
                                    {this.state.merchantAccount['paymentProviderId'] === 'PAYSAFE_CASH' &&
                                        <FieldText  id="reportEmail" label="Payout Report Email" model="customFields" type="email"
                                                    parent={this} required={true} value={this.state.customFields['reportEmail']}/>
                                    }

                                    {this.state.merchantAccount['customFields'] &&
                                        this.state.merchantAccount['paymentProviderId'] &&
                                        this.state.merchantAccount['paymentProviderId'] === 'PAYSAFE_SPLIT_PAY' &&
                                    <React.Fragment>

                                        <FieldPhone id="phone" label="Phone" model="customFields"
                                                    parent={this} value={this.state.customFields['phone']} />

                                        <FieldText id="url" label="Company URL" model="customFields" parent={this}
                                                   value={this.state.customFields['url']}/>

                                    </React.Fragment>
                                    }

                                    {this.state.merchantAccount['paymentProviderId'] === 'BLUESNAP' &&
                                        <div>
                                            <FieldSelect id="accountType" label="Account Type" model="merchantAccount"
                                                parent={this} required={true} value={this.state.merchantAccount['accountType']}>
                                                <option value="" disabled>Select an account type</option>
                                                <option value="Checking">Checking</option>
                                                <option value="Savings">Savings</option>
                                            </FieldSelect>

                                            <FieldCountry
                                                id="country"
                                                label="Country"
                                                parent={this}
                                                model="merchantAccount"
                                                required={true}
                                                value={this.state.merchantAccount['country']}/>

                                            {this.state.merchantAccount['country'] === 'US' &&
                                                <FieldState id="bankState"
                                                    label="State"
                                                    model="merchantAccount"
                                                    parent={this}
                                                    required={true}
                                                    value={this.state.merchantAccount['bankState']}/>
                                            }

                                            {this.state.merchantAccount['country'] === 'CA' &&
                                                <FieldProvince id="bankState"
                                                    label="Province"
                                                    model="merchantAccount"
                                                    parent={this}
                                                    required={true}
                                                    value={this.state.merchantAccount['bankState']}/>
                                            }

                                            <FieldSelect
                                                id="payoutCurrency"
                                                label="Payout Currency"
                                                model="merchantAccount"
                                                parent={this}
                                                required={true}
                                                value={this.state.merchantAccount['payoutCurrency']}>
                                                    <option value="">Select a currency...</option>
                                                    { this.state.merchantAccount['country'] === 'US' &&
                                                        <option value="USD">USD</option>
                                                    }
                                                    { this.state.merchantAccount['country'] === 'CA' &&
                                                        <option value="CAD">CAD</option>
                                                    }
                                            </FieldSelect>

                                            <FieldText id="minimumPayoutAmount"
                                                label="Minimal Payout"
                                                model="merchantAccount"
                                                parent={this}
                                                required={this.state.merchantAccount['country'] === 'CA' && true}
                                                type='number'
                                                min={this.state.merchantAccount['country'] === 'US' ? 10 : this.state.merchantAccount['country'] === 'CA' ? 10 : null}
                                                prependIconType="fas"
                                                prependIconName="dollar-sign"
                                                value={this.state.merchantAccount['minimumPayoutAmount']}/>

                                            <FieldText id="refundReserve"
                                                label="Refund Reserve"
                                                model="merchantAccount"
                                                parent={this}
                                                required={this.state.merchantAccount['country'] === 'CA' && true}
                                                type='number'
                                                min={this.state.merchantAccount['country'] === 'US' ? 0 : this.state.merchantAccount['country'] === 'CA' ? 0 : null}
                                                prependIconType="fas"
                                                prependIconName="dollar-sign"
                                                value={this.state.merchantAccount['refundReserve']}/>
                                        </div>
                                    }
                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#createMerchantAccount").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <div className="btn btn-outline-primary btn-lg ml-2" onClick={() => this.clearMerchantAccount()}>Clear Fields</div>
                                                <button type="submit" className="btn btn-primary btn-lg ml-2">Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal  id="download-format-confirm" theme="primary" iconType="fas" iconName="file-spreadsheet"
                    body="What format do you want to download the report?" title="Download Format Confirmation">

                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal" onClick={() => this.downloadMerchantAccountReportCSV()}>
                        CSV Format
                    </button>
                    <button onClick={() => this.downloadMerchantAccountReportPDF()} className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        PDF Format
                    </button>
                </Modal>
            </React.Fragment>
        )
    };
}

MerchantAccounts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(MerchantAccounts);