import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class ButtonClose extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A generic close modal button component.
     */
    render() {

        return(
            <React.Fragment>

                <button type="button" className={`btn btn-outline-${this.props.theme} btn-lg`} data-dismiss="modal">
                    <FormattedMessage id="button.close" />
                </button>

            </React.Fragment>
        )
    };
}

ButtonClose.defaultProps = {
    theme: 'primary'
};

export default ButtonClose;