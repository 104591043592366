export const CreditCardRegexArray = [
  {
    brand: 'MASTERCARD',
    name: 'Mastercard',
    regex: /^(5[1-5]|2[2-7]2[0-1])/,
  },
  {
    brand: 'VISA',
    name: 'Visa',
    regex: /^(41|44|47)/,
  },
  {
    brand: 'VISA_ELECTRON',
    name: 'Visa Electron',
    regex: /^(42|45|48|49)/,
  },
  {
    brand: 'AMERICAN_EXPRESS',
    name: 'American Express',
    regex: /^(34|37)/,
  },
  {
    brand: 'DISCOVER',
    name: 'Discover',
    regex: /^(6011|65|64[4-9]|622(1(2[6-9]|[3-9]\d)|[2-8]\d{2}|9([01]\d|2[0-5])))/,
  },
  {
    brand: 'JCB',
    name: 'JCB',
    regex: /^35/,
  },
  {
    brand: 'MAESTRO',
    name: 'Maestro',
    regex: /^((50|[5-6][6-9])|67)/,
  },
  {
    brand: 'DINERS_CLUB',
    name: 'Diners Club',
    regex: /^5[4-5]/,
  },
  {
    brand: 'UNION_PAY',
    name: 'China Union Pay',
    regex: /^(62|81)/,
  },
];

export const CREDIT_CARD_TYPES = [
  "VISA",
  "VISA_ELECTRON",
  "MASTERCARD",
  "MAESTRO",
  "DISCOVER",
  "AMERICAN_EXPRESS",
  "DINERS_CLUB",
  "JCB",
  "UNION_PAY",
];
