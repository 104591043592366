import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import Mimic from "../../common/Mimic";
import ModalPhrase from "../../common/ModalPhrase";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Manager from "./Manager";
import SystemAccount from "./SystemAccount";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            manager: {},

            firstName: '',
            lastName: '',

            validationList: [],

        };

        this.deleteManager = this.deleteManager.bind(this);
        this.refreshManager = this.refreshManager.bind(this);
        this.viewCompany = this.viewCompany.bind(this);
    }

    /**
     * Get the manager based on the manager ID provided in the route.
     */
    componentDidMount() {

        this.refreshManager(this.props.match.params.managerId)
    }

    /**
     * Delete the manager currently being viewed.
     */
    deleteManager() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.match.params.managerId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/admin/managers`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.managers.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Force a refresh of this component if required by a child component. Because this component displays the
     * managers's first and last name in the breadcrumbs, this component should update if a child component makes an
     * update to said values.
     *
     * @param managerId - The ID of the manager to fetch data from.
     */
    refreshManager(managerId) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/manager/search`, {

            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: managerId
                }
            ],
            joins: {
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                manager: response.data.records[0],
                firstName: response.data.records[0].firstName,
                lastName: response.data.records[0].lastName,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Redirect the user to the relevant company view component.
     */
    viewCompany() {

        this.props.history.push({
            pathname: `/admin/companies/${this.state.manager.companyId}/edit`,
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/managers" parentPage="Managers" childPage={`${this.state.manager.firstName} ${this.state.manager.lastName}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/admin/managers/${this.props.match.params.managerId}/edit`} active="edit" size="small" iconName="user" name="Manager" />
                                        <NavListItem path={`/admin/managers/${this.props.match.params.managerId}/account`} active="account" size="small" iconName="lock-alt" name="Login Account" />

                                        <Mimic userType="manager" userId={this.state.manager.id} parent={this} label="Mimic Manager" displayType="list" />

                                        {this.props.userSession.sessionRole.id !== this.state.manager.id &&
                                        <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#delete-manager">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="">
                                                    <FontAwesomeIcon icon={['fas', 'trash']} className="fa-fw va-b mr-2"/>
                                                    Delete Manager
                                                </div>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </div>

                            </div>

                            {this.state.manager.joins &&
                            <div className="jumbotron border py-4">
                                <div className="container">

                                    <p className="mb-0">
                                        {this.state.firstName} {this.state.lastName} is operating under <span className="btn-link c-pointer font-weight-bold" onClick={() => this.viewCompany()}>{this.state.manager.joins?.company?.name}</span>.
                                    </p>

                                </div>
                            </div>
                            }

                            <ModalPhrase id="delete-manager" title="Delete Manager" iconName="exclamation-triangle" confirmPhrase="DELETE" handleSuccess={() => this.deleteManager()}>

                                <p className="">Deleting this manager will have the following effects:</p>

                                <ul className="small">
                                    <li>The manager's Letus account will be deleted</li>
                                </ul>

                                <p className="mb-0">To delete this manager, enter the phrase 'DELETE' in the field below, then click the Confirm button.</p>

                            </ModalPhrase>

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/admin/managers/:managerId/edit" type="TYPE_ADMIN" parent={this} component={Manager} refreshManager={(managerId) => this.refreshManager(managerId)} />
                                <PrivateRoute exact path="/admin/managers/:managerId/account" type="TYPE_ADMIN" component={SystemAccount} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);