import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import Modal from "../../common/Modal";
import ModalPhrase from "../../common/ModalPhrase";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import ReportTxt2Pay from "../../common/ReportTxt2Pay";
import Events from "../companies/Events";
import BillingProfiles from "./BillingProfiles";
import ChargeCodes from "./ChargeCodes";
import Company from "./Company";
import CompanyOwners from "./CompanyOwners";
import CustomFields from "./CustomFields";
import Documents from "./Documents";
import FeeProfiles from "./FeeProfiles";
import HostedPaymentPages from "./HostedPaymentPages";
import Hyperwallet from "./Hyperwallet";
import TenantOnboarding from "./TenantOnboarding";
import Imports from "./Imports";
import Integrations from "./Integrations";
import Invoices from "./Invoices";
import LeaseApplicationPages from "./LeaseApplicationPages";
import LeaseApplicationTemplates from "./LeaseApplicationTemplates";
import Managers from "./Managers";
import MerchantAccount from "./MerchantAccount";
import MerchantAccounts from "./MerchantAccounts";
import PaymentMethods from "./PaymentMethods";
import Properties from "./Properties";
import ReportEfts from "./ReportEfts";
import Reporting from "./Reporting";
import ReportOverdueCharges from "./ReportOverdueCharges";
import ReportTenants from "./ReportTenants";
import ReportTransactions from "./ReportTransactions";
import ReportTenantApplications from "./ReportTenantApplications";
import ReportHyperwallet from "./ReportHyperwallet";
import ReportPaymentMethodAudit from "./ReportPaymentMethodAudit";
import ServiceFees from "./ServiceFees";
import ServiceLevelConfig from "./ServiceLevelConfig";
import Subsidiaries from "./Subsidiaries";
import Syncs from './Syncs';
import Tenants from "./Tenants";
import Transactions from "./Transactions";
import Notes from "../companies/Notes";
import Payment from "./Payment";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            parentCompany: {},
            company: {},
            validationList: [],
        };

        this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    /**
     * Get the company based on the Route's ID. If the company has a parent company ID, retrieve the parent company as
     * well.
     */
    componentDidMount() {

        this.handleRefresh(this.props.match.params.companyId);
    }

    /**
     * Handle changes in the route's ID.
     *
     * @param prevProps - The previous props.
     * @param prevState - The previous state.
     * @param snapshot - A snapshot of the data.
     */
    componentDidUpdate(prevProps, prevState, snapshot) {

        const oldId = prevProps.match.params.companyId;
        const newId = this.props.match.params.companyId;

        if (oldId !== newId) {
            this.setState(prevState => ({
                ...prevState,
                company: prevState.parentCompany,
                parentCompany: {}
            }));
        }
    }

    /**
     * Force a refresh of this component if required by a child component. Retrieves the company (and parent company if
     * applicable).
     *
     * @param companyId
     */
    handleRefresh(companyId) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: companyId
                }
            ],
            joins: {
                parentComp: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'parentId',
                    alias: 'parentComp',
                    returnFields: ['id', 'name', 'customCard']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            const company = response.data.records[0];

            if(company.joins.parentComp){
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    company: company,
                    parentCompany: company.joins.parentComp
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    company: company
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle updating the company's status.
     *
     * @param status - The new status of the company.
     */
    handleUpdateStatus(status) {

        let company = Object.assign({}, this.state.company);

        company['status'] = status;

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, company, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.company.status.' + status
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle submitting updates to the company.
     */
    handleSubmit(event) {

        event.preventDefault();

        axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.company, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            })
        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit API account interface.
     */
    render() {

        return (
            <div className="content-block">

                <div className="container">

                    {this.state.parentCompany.id == null &&
                    <Breadcrumb parentPath="/admin/companies" parentPage="Companies" childPage={`${this.state.company.name}`}/>
                    }

                    {this.state.parentCompany.id != null &&
                    <Breadcrumb parentPath="/admin/companies" parentPage="Companies" childPath={`/admin/companies/${this.state.parentCompany.id}/edit`} childPage={this.state.parentCompany.name} subChildPage={this.state.company.name} />
                    }

                    <Alert validationList={this.state.validationList}/>

                    <div className="row">
                        <div className="col-md-4">

                            <div className="pb-4">

                                <div className="card accordion" id="navigation">

                                    <div className="card-header">
                                        Options
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="c-pointer" data-toggle="collapse" data-target="#navigation-tools" aria-expanded="true" aria-controls="navigation-tools">
                                                    <div className="float-left">
                                                        Tools
                                                    </div>
                                                    <div className="float-right">
                                                        <i className="fas"> </i>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list collapse show" id="navigation-tools" aria-labelledby="navigation-tools" data-parent="#navigation">
                                        <div className="list-group">

                                            <NavListItem path={`/admin/companies/${this.state.company.id}/edit`} active="edit" size="small" iconName="briefcase" name="Company" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/subsidiaries`} active="subsidiaries" size="small" iconName="network-wired" name="Subsidiary Companies" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/owners`} active="owners" size="small" iconName="user-crown" name="Signing Officers" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/methods`} active="methods" size="small" iconName="wallet" name="Payment Methods" customClasses={this.state.company.parentId ? 'disabled' : ''}/>
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/billing`} active="billing" size="small" iconName="envelope-open-dollar" name="Billing Profiles" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/merchants`} active="merchants" size="small" iconName="university" name="Merchant Accounts" />
                                            {/* <NavListItem path={`/admin/companies/${this.state.company.id}/reporting`} active="reporting" size="small" iconName="file-invoice-dollar" name="Credit Reporting" customClasses={this.state.company.manageBalance === 'NO' ? 'disabled' : ''}/> */}
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/servicelevelconfig`} active="ServiceLevelConfig" size="small" iconName="file-invoice-dollar" name="Service Level Configuration"/>
                                            {/*<NavListItem path={`/admin/companies/${this.state.company.id}/syncs`} active="Syncs" size="small" iconName="sync" name="Syncs"/>*/}
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/properties`} active="properties" size="small" iconName="building" name="Properties" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/managers`} active="managers" size="small" iconName="key" name="Managers" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/tenants`} active="tenants" size="small" iconName="users" name="Tenants" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/fees`} active="fees" size="small" iconName="badge-percent" name="Transaction Fees" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/services`} active="services" size="small" iconName="tag" name="Service Fees" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/invoices`} active="invoices" size="small" iconName="file-invoice" name="Invoices" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/integrations`} active="integrations" size="small" iconName="link" name="Integrations" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/imports`} active="imports" size="small" iconName="file-import" name="Imports" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/documents`} active="documents" size="small" iconName="cloud-upload-alt" name="Documents" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/notes`} active="notes" size="small" iconName="sticky-note" name="Notes" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/fields`} active="fields" size="small" iconName="tools" name="Custom Fields" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/charges`} active="charges" size="small" iconName="receipt" name="Charge Codes" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/events`} active="events" size="small" iconName="history" name="Event History" customClasses="rounded-0" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/payment`} active="payment" size="small" iconName="link" name="Make a Payment" />

                                        </div>
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="c-pointer collapsed" data-toggle="collapse" data-target="#navigation-portals" aria-expanded="true" aria-controls="navigation-portals">
                                                    <div className="float-left">
                                                        Portals
                                                    </div>
                                                    <div className="float-right">
                                                        <i className="fas"> </i>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list collapse" id="navigation-portals" aria-labelledby="navigation-portals" data-parent="#navigation">
                                        <div className="list-group">
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/portals/leases`} active="leases" size="small" iconName="address-card" name="Lease Application Pages" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/portals/templates`} active="templates" size="small" iconName="copy" name="Lease Application Templates" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/portals/hosted`} active="hosted" size="small" iconName="shopping-cart" name="Hosted Payment Pages" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/portals/tenantonboarding`} active="tenantonboarding" size="small" iconName="users" name="Tenant Onboarding" />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/portals/hyperwallet`} active="hyperwallet" size="small" iconName="wallet" name="Hyperwallet" />
                                        </div>
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="c-pointer collapsed" data-toggle="collapse" data-target="#navigation-reports" aria-expanded="true" aria-controls="navigation-reports">
                                                    <div className="float-left">
                                                        Reports
                                                    </div>
                                                    <div className="float-right">
                                                        <i className="fas"> </i>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list collapse" id="navigation-reports" aria-labelledby="navigation-reports" data-parent="#navigation">
                                        <div className="list-group">
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/overdue`} active="reports/overdue" size="small" iconName="envelope-open-dollar" name="Overdue Charges" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/transactions`} active="reports/transactions" size="small" iconName="credit-card-front" name="Completed Transactions" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/efts`} active="reports/efts" size="small" iconName="piggy-bank" name="EFTs" customClasses={this.state.company.parentId ? 'disabled' : ''} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/tenants`} active="reports/tenants" size="small" iconName="users" name="Tenants" customClasses={`rounded-0 ${this.state.company.parentId ? 'disabled' : ''}`} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/txt2pay`} active="reports/txt2pay" size="small" iconName="comment-lines" name="Txt2Pay" customClasses={`rounded-0 ${this.state.company.parentId ? 'disabled' : ''}`} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/tenantapplications`} active="reports/tenantapplications" size="small" iconName="envelope" name="Tenant Applications" customClasses={`rounded-0 ${this.state.company.parentId ? 'disabled' : ''}`} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/paymentmethodaudit`} active="reports/paymentmethodaudit" size="small" iconName="file-chart-pie" name="Payment Methods" customClasses={`rounded-0 ${this.state.company.parentId ? 'disabled' : ''}`} />
                                            <NavListItem path={`/admin/companies/${this.state.company.id}/reports/hyperwallet`} active="reports/hyperwallet" size="small" iconName="wallet" name="Hyperwallet" customClasses={`rounded-0 ${this.state.company.parentId ? 'disabled' : ''}`}/>
                                        </div>
                                    </div>

                                    <div className="card-body card-body-table">
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th className="c-pointer collapsed" data-toggle="collapse" data-target="#navigation-actions" aria-expanded="true" aria-controls="navigation-actions">
                                                    <div className="float-left">
                                                        Actions
                                                    </div>
                                                    <div className="float-right">
                                                        <i className="fas"> </i>
                                                    </div>
                                                </th>
                                            </tr>
                                            </thead>
                                        </table>
                                    </div>

                                    <div className="card-body card-body-list collapse" id="navigation-actions" aria-labelledby="navigation-actions" data-parent="#navigation">
                                        <div className="list-group">

                                            {this.state.company.status != null && this.state.company.status !== "RETIRED" &&
                                            <div className="list-group-item list-group-item-action c-pointer" data-toggle="modal" data-target="#retire-company">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="">
                                                        <FontAwesomeIcon icon={['fas', 'power-off']} className="fa-fw va-b mr-2"/>
                                                        Retire Company
                                                    </div>
                                                </div>
                                            </div>
                                            }

                                        </div>
                                    </div>

                                </div>

                            </div>

                            <ModalPhrase id="retire-company" title="Retire Company" iconName="power-off" confirmPhrase="RETIRE" handleSuccess={() => this.handleUpdateStatus("RETIRED")}>

                                <p className="">By retiring this company, you understand that within the context of this company:</p>

                                <ul className="small">
                                    <li>All subsidiary companies will be retired</li>
                                    <li>All merchant accounts will be cancelled</li>
                                    <li>All properties will be deleted</li>
                                    <li>All property manager system accounts will be suspended</li>
                                    <li>All property manager mappings to properties will be deleted</li>
                                    <li>All billing account for customers will be deleted</li>
                                    <li>All scheduled payments for customers will be deleted</li>
                                    <li>All recurring schedules for customers will be deleted</li>
                                </ul>

                                <p className="mb-0">If you are absolutely sure you would like to retire this company, enter the phrase 'RETIRE' in the field below, then click the Confirm button.</p>

                            </ModalPhrase>

                            <Modal id="activate" theme="primary" iconType="fas" iconName="power-off" title="Activate Company" body="Are you sure you want to activate this company?">
                                <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">
                                    <FormattedMessage id="button.close"/>
                                </button>
                                <button onClick={() => {this.handleUpdateStatus("ACTIVE")}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                    Activate Company
                                </button>
                            </Modal>

                            <Modal id="retire" theme="danger" iconType="fas" iconName="power-off" title="Retire Company" body="Are you sure you want to retire this company?">
                                <button type="button" className="btn btn-secondary btn-lg" data-dismiss="modal">
                                    <FormattedMessage id="button.close"/>
                                </button>
                                <button onClick={() => {this.handleUpdateStatus("RETIRED")}} className="btn btn-danger btn-lg" data-dismiss="modal">
                                    Retire Company
                                </button>
                            </Modal>

                        </div>


                        <div className="col-md-8">

                            {this.state.company.status != null && this.state.company.status !== "ACTIVE" &&
                            <div className="card card-danger border-danger">
                                <div className="card-body">
                                    <h4 className="card-title">
                                        Company is {this.state.company.status}
                                    </h4>
                                </div>
                            </div>
                            }

                            {this.state.company.id != null &&
                                <Switch>
                                    <PrivateRoute exact path="/admin/companies/:companyId/edit" type="TYPE_ADMIN"
                                                  component={Company}
                                                  handleRefresh={(companyId) => this.handleRefresh(companyId)}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/subsidiaries" type="TYPE_ADMIN"
                                                  component={Subsidiaries}
                                                  handleRefresh={(companyId) => this.handleRefresh(companyId)}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/owners" type="TYPE_ADMIN"
                                                  component={CompanyOwners}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/methods" type="TYPE_ADMIN"
                                                  component={PaymentMethods}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/merchants" type="TYPE_ADMIN"
                                                  component={MerchantAccounts} companyId={this.state.company?.id}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reporting" type="TYPE_ADMIN"
                                                  component={Reporting} company={this.state.company}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/servicelevelconfig" type="TYPE_ADMIN"
                                    component={ServiceLevelConfig} company={this.state.company} handleRefresh={(companyId) => this.handleRefresh(companyId)}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/syncs" type="TYPE_ADMIN"
                                                  component={Syncs} company={this.state.company}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/transactions" type="TYPE_ADMIN"
                                                  component={Transactions}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/merchants/:merchantAccountId"
                                                  type="TYPE_ADMIN" component={MerchantAccount}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/fees" type="TYPE_ADMIN"
                                                  component={FeeProfiles}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/services" type="TYPE_ADMIN"
                                                  component={ServiceFees}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/imports" type="TYPE_ADMIN"
                                                  component={Imports}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/documents" type="TYPE_ADMIN"
                                                  component={Documents}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/notes" type="TYPE_ADMIN"
                                                  component={Notes}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/fields" type="TYPE_ADMIN"
                                                  component={CustomFields}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/charges" type="TYPE_ADMIN"
                                                  component={ChargeCodes}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/properties" type="TYPE_ADMIN"
                                                  component={Properties}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/managers" type="TYPE_ADMIN"
                                                  component={Managers}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/tenants" type="TYPE_ADMIN"
                                                  component={Tenants}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/invoices" type="TYPE_ADMIN"
                                                  component={Invoices}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/billing" type="TYPE_ADMIN"
                                                  component={BillingProfiles}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/integrations" type="TYPE_ADMIN"
                                                  component={Integrations}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/portals/leases" type="TYPE_ADMIN"
                                                  component={LeaseApplicationPages} company={this.state.company}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/portals/templates" type="TYPE_ADMIN"
                                                  component={LeaseApplicationTemplates}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/portals/hosted" type="TYPE_ADMIN"
                                                  component={HostedPaymentPages} company={this.state.company} handleRefresh={(companyId) => this.handleRefresh(companyId)}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/portals/tenantonboarding" type="TYPE_ADMIN"
                                                  component={TenantOnboarding}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/portals/hyperwallet" type="TYPE_ADMIN"
                                                  component={Hyperwallet}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/overdue" type="TYPE_ADMIN"
                                                  component={ReportOverdueCharges}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/transactions"
                                                  type="TYPE_ADMIN" component={ReportTransactions}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/efts" type="TYPE_ADMIN"
                                                  component={ReportEfts}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/tenants" type="TYPE_ADMIN"
                                                  component={ReportTenants}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/txt2pay" type="TYPE_ADMIN"
                                                  component={ReportTxt2Pay} companyId={this.state.company.id}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/tenantapplications" type="TYPE_ADMIN"
                                                  component={ReportTenantApplications} companyId={this.state.company.id}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/paymentmethodaudit" type="TYPE_ADMIN"
                                                  component={ReportPaymentMethodAudit} companyId={this.state.company.id}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/reports/hyperwallet" type="TYPE_ADMIN"
                                                  component={ReportHyperwallet} companyId={this.state.company.id}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/events" type="TYPE_ADMIN"
                                                  component={Events}/>
                                    <PrivateRoute exact path="/admin/companies/:companyId/payment" type="TYPE_ADMIN"
                                                  component={Payment}/>
                                </Switch>
                            }
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);
