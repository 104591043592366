export const HYPER_WALLET_CHARGE_TYPES = [
    {
        value: 'TYPE_RENT',
        label: "Pay Rent"
    },
    {
        value: 'TYPE_DEPOSIT',
        label: "Pay Deposit"
    },
    {
        value: 'TYPE_HOA_AND_FEES',
        label: "Pay HOA and Fees"
    },
    {
        value: 'TYPE_HOME_IMPROVEMENT',
        label: "Pay Home Improvement"
    },
    {
        value: 'TYPE_HOME_MAINTENANCE',
        label: "Pay Home Maintenance"
    },
    {
        value: 'TYPE_DENTIST',
        label: "Pay Dentist"
    },
    {
        value: 'TYPE_OTHER_PAYMENTS',
        label: "Other Payments"
    }
]