import axios from "axios";
import React from 'react';
import { injectIntl } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import InsurancesList from "../../common/Insurances";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Insurances extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the property lease data and all open charges associated with the property
     * lease at hand.
     */
    componentDidMount() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLease: response.data.records[0],
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {
      return(
          <React.Fragment>

              <Spinner visible={this.state.spinner} />

              <Alert validationList={this.state.validationList} />

              {this.state.propertyLease.userId &&
                <InsurancesList
                  global={true}
                  widthAlign="450px"
                  history={this.props.history}
                  token={this.props.token}
                  displayAdvanced={true}
                  userType="TYPE_ADMIN"
                  individualInsurance={true}
                  propertyLease={this.state.propertyLease && this.state.propertyLease}
              />
              }
          </React.Fragment>
      )
    };
}

export default injectIntl(Insurances);