import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSave from "../../common/ButtonSave";
import FieldFile from "../../common/FieldFile";
import FieldText from "../../common/FieldText";
import FieldCheckbox from "../../common/FieldCheckbox";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Table from "../../common/Table";
import FieldCustomTemplateMultiple from "../../common/FieldCustomTemplateMultiple"

class LeaseApplicationTemplates extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplicationTemplate: {},
            customFields: {},
            templateObject: {},
            templateName: {
                templateName: '',
            },
            termsAndConditions: {
                termsAndConditions: '',
            },
            property: {
                property: '',
                unit: '',
            },
            applicant: {
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phone: '',
                dateOfBirth: '',
                sinSsn: '',
                maritalStatus: '',
                pets: '',
                smoking: '',
                bankruptcy: '',
            },
            emergencyContact: {
              firstNameEC: '',
              middleNameEC: '',
              lastNameEC: '',
              emailEC: '',
              phoneEC: '',
              relationEC: '',
            },
            addressHistory: {
                suite: '',
                street1: '',
                street2: '',
                city: '',
                country: '',
                state: '',
                zip: '',
                months: '',
                monthlyRent: '',
                managerName: '',
                managerPhone: '',
                managerEmail: '',
                comments: '',
            },
            additionalOccupants: {
                occupantFirstName: '',
                occupantLastName: '',
                occupantAge: '',
                occupantPhone: '',
                occupantEmploymentStatus: '',
                occupantRelationship: '',

            },
            employmentInformation: {
                employmentStatus: '',
                employerName: '',
                employerPhone: '',
                occupation: '',
                monthsEmployed: '',
                monthlyIncome: '',
                otherIncome: '',
            },
            personalReference: {
                referenceFirstName: '',
                referenceLastName: '',
                referencePhone: '',
                referenceRelationship: '',
            },
            attachments: {
                photoId: '',
                payStubs: '',
                taxReturns: '',
            },
            privacyConsent: {
                privacyConsent: '',
            },
            document: {
                file: '',
            },
            documentList: [],
            leaseApplicationTemplateList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            leaseApplicationTemplateQueryOld: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.match.params.companyId
                    }
                ]
            },
            leaseApplicationTemplateQuery: {
                "orderBy": "DESC",
                "orderByFields": [ "createDate" ],
                "conditionList": [
                    {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "fieldName": "id",
                    "operator": "EQUALS",
                    "fieldValue": this.props.match.params.companyId
                    }
                ],
                "joins": {
                    "p": {
                    "targetRecordType": "TYPE_PROPERTY",
                    "joinField": "propertyId",
                    "alias": "p",
                    "returnFields": [
                        "propertyName",
                        "street1",
                        "street2",
                        "city",
                        "province",
                        "country",
                        "postalCode",
                        "propertyIdentifier",
                        "companyId"
                    ]
                    },
                    "template": {
                    "targetRecordType": "TYPE_TEMPLATE",
                    "joinField": "companyId",
                    "targetField": "companyId",
                    "alias": "t",
                    "returnFields": [
                        "id",
                        "templateName",
                        "sections"
                    ]
                    },
                    "document": {
                    "targetRecordType": "TYPE_DOCUMENT",
                    "joinField": "t.id",
                    "targetField": "recordId",
                    "alias": "d",
                    "returnFields": [
                        "id",
                        "name",
                        "url"
                    ]
                    }
                }
            },
            validationList: [],
            isModalError: null
        };

        this.searchLeaseApplicationTemplates = this.searchLeaseApplicationTemplates.bind(this);
        this.editLeaseApplicationTemplate = this.editLeaseApplicationTemplate.bind(this);
        this.initLeaseApplicationTemplate = this.initLeaseApplicationTemplate.bind(this);
        this.saveLeaseApplicationTemplate = this.saveLeaseApplicationTemplate.bind(this);
        this.deleteLeaseApplicationTemplate = this.deleteLeaseApplicationTemplate.bind(this);

        this.searchDocuments = this.searchDocuments.bind(this);
        this.uploadDocument = this.uploadDocument.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.selectDocument = this.selectDocument.bind(this);
    }

    /**
     * Load the list of lease application templates on mounting of the component.
     */
    componentDidMount() {

        this.searchLeaseApplicationTemplates(1, 25, this.state.leaseApplicationTemplateQuery);
    }

    /**
     * Handle selecting a lease application template record by bringing up the modal with lease application template values
     * pre-filled.
     *
     * @param leaseApplicationTemplate - The ID of the lease application template selected.
     */
    editLeaseApplicationTemplate(leaseApplicationTemplate) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/template/${leaseApplicationTemplate}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                leaseApplicationTemplate: response.data,
                templateName: {
                    templateName: response.data.templateName,
                },
                termsAndConditions: {
                    termsAndConditions: (response.data.sections[0].defaultFields[0]?.value === 'true') ? true : false,
                    customFields: response.data.sections[0]?.customFields,
                },
                property: {
                    property: (response.data.sections[1].defaultFields[0]?.value === 'true') ? true : false,
                    unit: (response.data.sections[1].defaultFields[1]?.value === 'true') ? true : false,
                    customFields: response.data.sections[1]?.customFields,
                },
                applicant: {
                    firstName: (response.data.sections[2].defaultFields[0]?.value === 'true') ? true : false,
                    middleName: (response.data.sections[2].defaultFields[1]?.value === 'true') ? true : false,
                    lastName: (response.data.sections[2].defaultFields[2]?.value === 'true') ? true : false,
                    email: (response.data.sections[2].defaultFields[3]?.value === 'true') ? true : false,
                    phone: (response.data.sections[2].defaultFields[4]?.value === 'true') ? true : false,
                    dateOfBirth: (response.data.sections[2].defaultFields[5]?.value === 'true') ? true : false,
                    sinSsn: (response.data.sections[2].defaultFields[6]?.value === 'true') ? true : false,
                    maritalStatus: (response.data.sections[2].defaultFields[7]?.value === 'true') ? true : false,
                    pets: (response.data.sections[2].defaultFields[8]?.value === 'true') ? true : false,
                    smoking: (response.data.sections[2].defaultFields[9]?.value === 'true') ? true : false,
                    bankruptcy: (response.data.sections[2].defaultFields[10]?.value === 'true') ? true : false,
                    customFields: response.data.sections[2]?.customFields,
                },
                emergencyContact: {
                  firstNameEC: (response.data.sections[3].defaultFields[0]?.value === 'true') ? true : false,
                  middleNameEC: (response.data.sections[3].defaultFields[1]?.value === 'true') ? true : false,
                  lastNameEC: (response.data.sections[3].defaultFields[2]?.value === 'true') ? true : false,
                  emailEC: (response.data.sections[3].defaultFields[3]?.value === 'true') ? true : false,
                  phoneEC: (response.data.sections[3].defaultFields[4]?.value === 'true') ? true : false,
                  relationEC: (response.data.sections[3].defaultFields[5]?.value === 'true') ? true : false,
                  customFields: response.data.sections[3]?.customFields,
                },
                addressHistory: {
                    suite: (response.data.sections[4].defaultFields[0]?.value === 'true') ? true : false,
                    street1: (response.data.sections[4].defaultFields[1]?.value === 'true') ? true : false,
                    street2: (response.data.sections[4].defaultFields[2]?.value === 'true') ? true : false,
                    city: (response.data.sections[4].defaultFields[3]?.value === 'true') ? true : false,
                    country: (response.data.sections[4].defaultFields[4]?.value === 'true') ? true : false,
                    state: (response.data.sections[4].defaultFields[5]?.value === 'true') ? true : false,
                    zip: (response.data.sections[4].defaultFields[6]?.value === 'true') ? true : false,
                    months: (response.data.sections[4].defaultFields[7]?.value === 'true') ? true : false,
                    monthlyRent: (response.data.sections[4].defaultFields[8]?.value === 'true') ? true : false,
                    managerName: (response.data.sections[4].defaultFields[9]?.value === 'true') ? true : false,
                    managerPhone: (response.data.sections[4].defaultFields[10]?.value === 'true') ? true : false,
                    managerEmail: (response.data.sections[4].defaultFields[11]?.value === 'true') ? true : false,
                    comments: (response.data.sections[4].defaultFields[12]?.value === 'true') ? true : false,
                    customFields: response.data.sections[4]?.customFields,
                },
                additionalOccupants: {
                    occupantFirstName: (response.data.sections[5].defaultFields[0]?.value === 'true') ? true : false,
                    occupantLastName: (response.data.sections[5].defaultFields[1]?.value === 'true') ? true : false,
                    occupantAge: (response.data.sections[5].defaultFields[2]?.value === 'true') ? true : false,
                    occupantPhone: (response.data.sections[5].defaultFields[3]?.value === 'true') ? true : false,
                    occupantEmploymentStatus: (response.data.sections[5].defaultFields[4]?.value === 'true') ? true : false,
                    occupantRelationship: (response.data.sections[5].defaultFields[5]?.value === 'true') ? true : false,
                    customFields: response.data.sections[5]?.customFields,
                },
                employmentInformation: {
                    employmentStatus: (response.data.sections[6].defaultFields[0]?.value === 'true') ? true : false,
                    employerName: (response.data.sections[6].defaultFields[1]?.value === 'true') ? true : false,
                    employerPhone: (response.data.sections[6].defaultFields[2]?.value === 'true') ? true : false,
                    occupation: (response.data.sections[6].defaultFields[3]?.value === 'true') ? true : false,
                    monthsEmployed: (response.data.sections[6].defaultFields[4]?.value === 'true') ? true : false,
                    monthlyIncome: (response.data.sections[6].defaultFields[5]?.value === 'true') ? true : false,
                    otherIncome: (response.data.sections[6].defaultFields[6]?.value === 'true') ? true : false,
                    customFields: response.data.sections[6]?.customFields,
                },
                personalReference: {
                    referenceFirstName: (response.data.sections[7].defaultFields[0]?.value === 'true') ? true : false,
                    referenceLastName: (response.data.sections[7].defaultFields[1]?.value === 'true') ? true : false,
                    referencePhone: (response.data.sections[7].defaultFields[2]?.value === 'true') ? true : false,
                    referenceRelationship: (response.data.sections[7].defaultFields[3]?.value === 'true') ? true : false,
                    customFields: response.data.sections[7]?.customFields,
                },
                attachments: {
                    photoId: (response.data.sections[8].defaultFields[0]?.value === 'true') ? true : false,
                    payStubs: (response.data.sections[8].defaultFields[1]?.value === 'true') ? true : false,
                    taxReturns: (response.data.sections[8].defaultFields[2]?.value === 'true') ? true : false,
                    customFields: response.data.sections[8]?.customFields,
                },
                privacyConsent: {
                    privacyConsent: (response.data.sections[9]?.defaultFields[0]?.value === 'true') ? true : false,
                    customFields: response.data.sections[9]?.customFields,
                },
                validationList: []
            }));

            this.searchDocuments();

            $('#lease-application-template').modal('show');

        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a lease application template object when the user clicks the appropriate button.
     */
    initLeaseApplicationTemplate() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/template/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationTemplate: response.data,
                templateName: {
                    templateName: '',
                },
                termsAndConditions: {
                    termsAndConditions: true,
                },
                property: {
                    property: true,
                    unit: '',
                },
                applicant: {
                    firstName: true,
                    middleName: '',
                    lastName: true,
                    email: true,
                    phone: true,
                    dateOfBirth: true,
                    sinSsn: true,
                    maritalStatus: '',
                    pets: '',
                    smoking: '',
                    bankruptcy: '',
                },
                emergencyContact: {
                  firstNameEC: '',
                  middleNameEC: '',
                  lastNameEC: '',
                  emailEC: '',
                  phoneEC: '',
                  relationEC: '',
                },
                addressHistory: {
                    suite: true,
                    street1: true,
                    street2: true,
                    city: true,
                    country: true,
                    state: true,
                    zip: true,
                    months: true,
                    monthlyRent: '',
                    managerName: '',
                    managerPhone: '',
                    managerEmail: '',
                    comments: '',
                },
                additionalOccupants: {
                    occupantFirstName: true,
                    occupantLastName: true,
                    occupantAge: true,
                    occupantPhone: true,
                    occupantEmploymentStatus: true,
                    occupantRelationship: true,
    
                },
                employmentInformation: {
                    employmentStatus: true,
                    employerName: true,
                    employerPhone: true,
                    occupation: true,
                    monthsEmployed: true,
                    monthlyIncome: true,
                    otherIncome: true,
                },
                personalReference: {
                    referenceFirstName: '',
                    referenceLastName: '',
                    referencePhone: '',
                    referenceRelationship: '',
                },
                attachments: {
                    photoId: '',
                    payStubs: '',
                    taxReturns: '',
                },
                privacyConsent: {
                    privacyConsent: true,
                },
                validationList: []
            }));
        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });
    }

    /**
     * Delete the selected lease application template.
     */
    deleteLeaseApplicationTemplate() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/template/${this.state.leaseApplicationTemplate.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.companies.leasing.deleted'
                    }
                }],
            });

            this.searchLeaseApplicationTemplates(this.state.leaseApplicationTemplateList.page, this.state.leaseApplicationTemplateList.recordsPerPage, this.state.leaseApplicationTemplateQuery);

            $('#lease-application-template').modal('hide');

        }).catch(error => {
            this.setState({
              isModalError: false
            })
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle the submission of the form. Use the create date field of the lease application template in the state to
     * determine if the user is editing or creating a new lease application template. Upon successful submission of the
     * form, refresh the table and hide the modal.
     *
     * @param event - The event container.
     */
    saveLeaseApplicationTemplate(event) {

        event.preventDefault();

        let leaseApplicationTemplate = this.state.leaseApplicationTemplate;

        leaseApplicationTemplate.companyId = this.props.match.params.companyId;
        leaseApplicationTemplate.templateName = this.state.templateName.templateName;
        leaseApplicationTemplate.sections = [
            {
                "sectionName": "T&Cs",
                "defaultFields": [
                  {
                    "label": "T&Cs",
                    "type": null,
                    "length": null,
                    "value": this.state.termsAndConditions.termsAndConditions
                  }
                ],
                "customFields": this.state.termsAndConditions.customFields
              },
              {
                "sectionName": "Property",
                "defaultFields": [
                  {
                    "label": "Property",
                    "type": null,
                    "length": null,
                    "value": this.state.property.property
                  },
                  {
                    "label": "Unit",
                    "type": null,
                    "length": null,
                    "value": this.state.property.unit
                  }
                ],
                "customFields": this.state.property.customFields
              },
              {
                "sectionName": "Applicant",
                "defaultFields": [
                  {
                    "label": "First name",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.firstName
                  },
                  {
                    "label": "Middle name",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.middleName
                  },
                  {
                    "label": "Last name",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.lastName
                  },
                  {
                    "label": "email",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.email
                  },
                  {
                    "label": "Mobile Phone",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.phone
                  },
                  {
                    "label": "DOB",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.dateOfBirth
                  },
                  {
                    "label": "SIN/SSN",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.sinSsn
                  },
                  {
                    "label": "Marital status",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.maritalStatus
                  },
                  {
                    "label": "Pets",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.pets
                  },
                  {
                    "label": "Smoking",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.smoking
                  },
                  {
                    "label": "Have you ever failed for Bankruptcy",
                    "type": null,
                    "length": null,
                    "value": this.state.applicant.bankruptcy
                  }
                ],
                "customFields": this.state.applicant.customFields
              },
              {
                "sectionName": "Emergency Contact",
                "defaultFields": [
                  {
                    "label": "First name",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.firstNameEC
                  },
                  {
                    "label": "Middle name",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.middleNameEC
                  },
                  {
                    "label": "Last name",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.lastNameEC
                  },
                  {
                    "label": "email",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.emailEC
                  },
                  {
                    "label": "Mobile Phone",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.phoneEC
                  },
                  {
                    "label": "Relation",
                    "type": null,
                    "length": null,
                    "value": this.state.emergencyContact.relationEC
                  }
                ],
                "customFields": this.state.emergencyContact.customFields
              },
              {
                "sectionName": "Address History",
                "defaultFields": [
                  {
                    "label": "Suite",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.suite
                  },
                  {
                    "label": "Street1",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.street1
                  },
                  {
                    "label": "Street2",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.street2
                  },
                  {
                    "label": "City",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.city
                  },
                  {
                    "label": "Country",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.country
                  },
                  {
                    "label": "State",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.state
                  },
                  {
                    "label": "Zip",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.zip
                  },
                  {
                    "label": "Months at this address",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.months
                  },
                  {
                    "label": "Monthly Rent",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.monthlyRent
                  },
                  {
                    "label": "Building Manager/Landlord Name",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.managerName
                  },
                  {
                    "label": "Building Manager/Landlord Phone #",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.managerPhone
                  },
                  {
                    "label": "Building Manager/Landlord Email Address",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.managerEmail
                  },
                  {
                    "label": "Comments",
                    "type": null,
                    "length": null,
                    "value": this.state.addressHistory.comments
                  }
                ],
                "customFields": this.state.addressHistory.customFields
              },
              {
                "sectionName": "Additional Occupants",
                "defaultFields": [
                  {
                    "label": "First name",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantFirstName
                  },
                  {
                    "label": "Last name",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantLastName
                  },
                  {
                    "label": "Age",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantAge
                  },
                  {
                    "label": "Phone",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantPhone
                  },
                  {
                    "label": "Employment status",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantEmploymentStatus
                  },
                  {
                    "label": "Relationship",
                    "type": null,
                    "length": null,
                    "value": this.state.additionalOccupants.occupantRelationship
                  }
                ],
                "customFields": this.state.additionalOccupants.customFields
              },
              {
                "sectionName": "Employment information",
                "defaultFields": [
                  {
                    "label": "Employment status",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.employmentStatus
                  },
                  {
                    "label": "Employer name",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.employerName
                  },
                  {
                    "label": "Employer Phone",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.employerPhone
                  },
                  {
                    "label": "Occupation",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.occupation
                  },
                  {
                    "label": "Months employed",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.monthsEmployed
                  },
                  {
                    "label": "Monthly Income",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.monthlyIncome
                  },
                  {
                    "label": "Other income",
                    "type": null,
                    "length": null,
                    "value": this.state.employmentInformation.otherIncome
                  }
                ],
                "customFields": this.state.employmentInformation.customFields
              },
              {
                "sectionName": "Personal Reference",
                "defaultFields": [
                  {
                    "label": "First name",
                    "type": null,
                    "length": null,
                    "value": this.state.personalReference.referenceFirstName
                  },
                  {
                    "label": "Last name",
                    "type": null,
                    "length": null,
                    "value": this.state.personalReference.referenceLastName
                  },
                  {
                    "label": "phone",
                    "type": null,
                    "length": null,
                    "value": this.state.personalReference.referencePhone
                  },
                  {
                    "label": "relationship",
                    "type": null,
                    "length": null,
                    "value": this.state.personalReference.referenceRelationship
                  }
                ],
                "customFields": this.state.personalReference.customFields
              },
              {
                "sectionName": "Attachments",
                "defaultFields": [
                  {
                    "label": "Photo ID",
                    "type": null,
                    "length": null,
                    "value": this.state.attachments.photoId
                  },
                  {
                    "label": "Pay Stubs",
                    "type": null,
                    "length": null,
                    "value": this.state.attachments.payStubs
                  },
                  {
                    "label": "Tax Returns",
                    "type": null,
                    "length": null,
                    "value": this.state.attachments.taxReturns
                  },
                ],
                "customFields": this.state.attachments.customFields
              },
              {
                "sectionName": "Lease application privacy consent",
                "defaultFields": [
                  {
                    "label": "Lease application privacy consent",
                    "type": null,
                    "length": null,
                    "value": this.state.privacyConsent.privacyConsent
                  }
                ],
                "customFields": this.state.privacyConsent.customFields
              }
        ];

        if(this.state.leaseApplicationTemplate.createDate == null) {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, leaseApplicationTemplate, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.companies.leasing.created'
                        }
                    }],
                });

                this.searchLeaseApplicationTemplates(this.state.leaseApplicationTemplateList.page, this.state.leaseApplicationTemplateList.recordsPerPage, this.state.leaseApplicationTemplateQuery);

                $('#lease-application-template').modal('hide');

            }).catch(error => {
                this.setState({
                  isModalError: true
                })
                this.handleValidation(error);
            });

        } else {

            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, leaseApplicationTemplate, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchLeaseApplicationTemplates(this.state.leaseApplicationTemplateList.page, this.state.leaseApplicationTemplateList.recordsPerPage, this.state.leaseApplicationTemplateQuery);

                $('#lease-application-template').modal('hide');

            }).catch(error => {
                this.setState({
                  isModalError: true
                })
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Upload a document to the list of documents for a specific lease application template.
     *
     * @param event - The event container.
     */
    uploadDocument(event) {

        event.preventDefault();

        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/${this.state.leaseApplicationTemplate.id}/upload`, this.state.document.file, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'common.documents.created',
                        message: 'Upload successful'
                    }
                }],
            });
            this.searchDocuments();

        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Select a document and bring up the delete document confirmation modal.
     *
     * @param document - The selected document.
     */
    selectDocument(document) {

        this.setState(prevState => ({
            ...prevState,
            document: document
        }));

        $('#lease-application-template-documents').modal('hide');
        $('#delete-document').modal('show');
    }

    /**
     * Delete the selected document.
     */
    deleteDocument() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/document/${this.state.document.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.searchDocuments();

            $('#lease-application-template-documents').modal('show');

        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });
    }

    /**
     * Update the data table of lease application templates.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchLeaseApplicationTemplates(page, recordsPerPage, query) {
        const records = {
            page: 1,
            records: [],
            recordsPerPage: 100,
            totalPages: 1,
            totalRecordCount: 1,
        }
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}/templates`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            records.records = response.data;
            records.totalRecordCount = response.data.length;
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationTemplateList: records,
                leaseApplicationTemplateQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList,
                    joins: query.joins,
                }
            }));
        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });
    }

    /**
     * Perform a search for all available documents related to the lease application template for custom fields that require
     * an image URL or document URL.
     */
    searchDocuments() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/document/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'recordId',
                    operator: 'EQUALS',
                    fieldValue: this.state.leaseApplicationTemplate.id
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                documentList: response.data.records,
            }));
        }).catch(error => {
            this.setState({
              isModalError: true
            })
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The lease application template management component.
     */
    render() {

        return (
          <React.Fragment>
            <Alert
              validationList={this.state.validationList}
              validationType='primary'
            />
            {
              !this.state.isModalError &&
                <Alert
                  validationList={this.state.validationList}
                  validationType='danger'
                />
            }

            <div className='card'>
              <div className='card-header'>
                <div className='row align-items-center'>
                  <div className='col'>Lease Application Templates</div>
                  <div className='col text-right'>
                    <div
                      data-toggle='modal'
                      data-target='#lease-application-template'
                      className='btn btn-primary btn-sm'
                      onClick={() => this.initLeaseApplicationTemplate()}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'plus']}
                        className='fa-fw'
                      />{' '}
                      Create Lease Application Template
                    </div>
                  </div>
                </div>
              </div>
              <Table
                columns={{
                  templateName: 'Template Name',
                  dateCreated: 'Date Created',
                  lastModified: 'Last Modified',
                }}
                columnWidths={['40%', '30%', '30%']}
                headerClass='c-pointer'
                data={this.state.leaseApplicationTemplateList}
                query={this.state.leaseApplicationTemplateQuery}
                sortEnabled={false}
                recordsEnabled={true}
                paginationEnabled={true}
                updateFunction={this.searchLeaseApplicationTemplates}
              >
                <tbody>
                  {this.state.leaseApplicationTemplateList.records.map(
                    (data, key) => {
                      return (
                        <tr key={key}>
                          <td
                            className='c-pointer'
                            onClick={() =>
                              this.editLeaseApplicationTemplate(data.id)
                            }
                          >
                            <div className=''>{data.templateName}</div>
                          </td>
                          <td>
                            <div className=''>
                              <Moment format='MMM DD, YYYY'>
                                {data.createDate}
                              </Moment>
                            </div>
                          </td>
                          <td>
                            <div className=''>
                              <Moment format='MMM DD, YYYY'>
                                {data.updateDate}
                              </Moment>
                            </div>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>

            <div
              className='modal fade'
              id='lease-application-template'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='lease-application-template-label'
              aria-hidden='true'
            >
              <div
                className='modal-dialog modal-dialog-centered modal-lg'
                role='document'
              >
                <div className='modal-content shadow'>
                  <form onSubmit={this.saveLeaseApplicationTemplate}>
                    <div className='modal-header bg-dark text-white'>
                      <h5
                        className='modal-title'
                        id='lease-application-template-label'
                      >
                        {this.state.leaseApplicationTemplate['createDate'] ==
                          null && (
                          <span className=''>
                            Create Lease Application Template
                          </span>
                        )}
                        {this.state.leaseApplicationTemplate['createDate'] !=
                          null && (
                          <span className=''>
                            Edit Lease Application Template
                          </span>
                        )}
                      </h5>
                      <button
                        type='button'
                        className='close text-white'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'times']}
                          className='fa-fw va-b mr-2'
                        />
                      </button>
                    </div>
                    <div className='modal-body'>
                      {
                        this.state.isModalError &&
                          <Alert
                            validationList={this.state.validationList}
                            validationType='danger'
                          />
                      }

                      <FieldText
                        id='templateName'
                        label='Template Name'
                        model='templateName'
                        parent={this}
                        value={this.state.templateName['templateName']}
                      />

                      <h4>Terms and Policies</h4>
                      <FieldCheckbox
                        id='termsAndConditions'
                        label='Letus Terms of Use'
                        fieldLabel=''
                        model='termsAndConditions'
                        parent={this}
                        value={
                          this.state.termsAndConditions['termsAndConditions']
                        }
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='privacyConsent'
                        label='Lease Application Privacy Consent'
                        model='privacyConsent'
                        parent={this}
                        value={this.state.privacyConsent['privacyConsent']}
                        disabled={true}
                      />
                      {/* <FieldCustomTemplateMultiple
                        model='termsAndConditions'
                        parent={this}
                        btnName='Add Custom Field'
                      /> */}
                      <h4>Property</h4>
                      {/* <div className={`form-group row`}> */}
                      {/* <div> */}
                      {/* labelColumns="3" fieldColumns="9" fieldClass="form-control-sm" labelClass="col-form-label-sm px-2"  */}
                      <FieldCheckbox
                        id='property'
                        label='Property'
                        model='property'
                        parent={this}
                        value={this.state.property['property']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='unit'
                        label='Unit'
                        model='property'
                        parent={this}
                        value={this.state.property['unit']}
                      />
                      {/* </div> */}
                      <FieldCustomTemplateMultiple
                        model='property'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Applicant</h4>
                      <FieldCheckbox
                        id='firstName'
                        label='First Name'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['firstName']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='middleName'
                        label='Middle Name'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['middleName']}
                      />
                      <FieldCheckbox
                        id='lastName'
                        label='Last Name'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['lastName']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='email'
                        label='Email'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['email']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='phone'
                        label='Mobile Phone'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['phone']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='dateOfBirth'
                        label='Date of Birth'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['dateOfBirth']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='sinSsn'
                        label='SIN/SSN'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['sinSsn']}
                      />
                      <FieldCheckbox
                        id='maritalStatus'
                        label='Marital Status'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['maritalStatus']}
                      />
                      <FieldCheckbox
                        id='pets'
                        label='Pets'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['pets']}
                      />
                      <FieldCheckbox
                        id='smoking'
                        label='Smoking'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['smoking']}
                      />
                      <FieldCheckbox
                        id='bankruptcy'
                        label='Have you ever filed for Bankruptcy?'
                        model='applicant'
                        parent={this}
                        value={this.state.applicant['bankruptcy']}
                      />
                      <FieldCustomTemplateMultiple
                        model='applicant'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Emergency Contact</h4>
                      <FieldCheckbox
                        id='firstNameEC'
                        label='First Name'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['firstNameEC']}
                      />
                      <FieldCheckbox
                        id='middleNameEC'
                        label='Middle Name'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['middleNameEC']}
                      />
                      <FieldCheckbox
                        id='lastNameEC'
                        label='Last Name'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['lastNameEC']}
                      />
                      <FieldCheckbox
                        id='emailEC'
                        label='Email'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['emailEC']}
                      />
                      <FieldCheckbox
                        id='phoneEC'
                        label='Mobile Phone'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['phoneEC']}
                      />
                      <FieldCheckbox
                        id='relationEC'
                        label='Relation'
                        model='emergencyContact'
                        parent={this}
                        value={this.state.emergencyContact['relationEC']}
                      />
                      <FieldCustomTemplateMultiple
                        model='emergencyContact'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Address History</h4>
                      <FieldCheckbox
                        id='suite'
                        label='Suite'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['suite']}
                      />
                      <FieldCheckbox
                        id='street1'
                        label='Street 1'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['street1']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='street2'
                        label='Street 2'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['street2']}
                      />
                      <FieldCheckbox
                        id='city'
                        label='City'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['city']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='country'
                        label='Country'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['country']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='state'
                        label='State'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['state']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='zip'
                        label='Zip'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['zip']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='months'
                        label='Months at this address'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['months']}
                        disabled={true}
                      />
                      <FieldCheckbox
                        id='monthlyRent'
                        label='Monthly Rent'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['monthlyRent']}
                      />
                      <FieldCheckbox
                        id='managerName'
                        label='Building Manager/Landlord Name'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['managerName']}
                      />
                      <FieldCheckbox
                        id='managerPhone'
                        label='Building Manager/Landlord Phone'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['managerPhone']}
                      />
                      <FieldCheckbox
                        id='managerEmail'
                        label='Building Manager/Landlord Email'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['managerEmail']}
                      />
                      <FieldCheckbox
                        id='comments'
                        label='Comments'
                        model='addressHistory'
                        parent={this}
                        value={this.state.addressHistory['comments']}
                      />
                      <FieldCustomTemplateMultiple
                        model='addressHistory'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Additional Occupants</h4>
                      <FieldCheckbox
                        id='occupantFirstName'
                        label='First Name'
                        model='additionalOccupants'
                        parent={this}
                        value={
                          this.state.additionalOccupants['occupantFirstName']
                        }
                      />
                      <FieldCheckbox
                        id='occupantLastName'
                        label='Last Name'
                        model='additionalOccupants'
                        parent={this}
                        value={
                          this.state.additionalOccupants['occupantLastName']
                        }
                      />
                      <FieldCheckbox
                        id='occupantAge'
                        label='Age'
                        model='additionalOccupants'
                        parent={this}
                        value={this.state.additionalOccupants['occupantAge']}
                      />
                      <FieldCheckbox
                        id='occupantPhone'
                        label='Phone'
                        model='additionalOccupants'
                        parent={this}
                        value={this.state.additionalOccupants['occupantPhone']}
                      />
                      <FieldCheckbox
                        id='occupantEmploymentStatus'
                        label='Employment Status'
                        model='additionalOccupants'
                        parent={this}
                        value={
                          this.state.additionalOccupants[
                            'occupantEmploymentStatus'
                          ]
                        }
                      />
                      <FieldCheckbox
                        id='occupantRelationship'
                        label='Relationship'
                        model='additionalOccupants'
                        parent={this}
                        value={
                          this.state.additionalOccupants['occupantRelationship']
                        }
                      />
                      <FieldCustomTemplateMultiple
                        model='additionalOccupants'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Employment Information</h4>
                      <FieldCheckbox
                        id='employmentStatus'
                        label='Employment Status'
                        model='employmentInformation'
                        parent={this}
                        value={
                          this.state.employmentInformation['employmentStatus']
                        }
                      />
                      <FieldCheckbox
                        id='employerName'
                        label='Employer Name'
                        model='employmentInformation'
                        parent={this}
                        value={this.state.employmentInformation['employerName']}
                      />
                      <FieldCheckbox
                        id='employerPhone'
                        label='Employer Phone'
                        model='employmentInformation'
                        parent={this}
                        value={
                          this.state.employmentInformation['employerPhone']
                        }
                      />
                      <FieldCheckbox
                        id='occupation'
                        label='Occupation'
                        model='employmentInformation'
                        parent={this}
                        value={this.state.employmentInformation['occupation']}
                      />
                      <FieldCheckbox
                        id='monthsEmployed'
                        label='Months Employed'
                        model='employmentInformation'
                        parent={this}
                        value={
                          this.state.employmentInformation['monthsEmployed']
                        }
                      />
                      <FieldCheckbox
                        id='monthlyIncome'
                        label='Monthly Income'
                        model='employmentInformation'
                        parent={this}
                        value={
                          this.state.employmentInformation['monthlyIncome']
                        }
                      />
                      <FieldCheckbox
                        id='otherIncome'
                        label='Other Income'
                        model='employmentInformation'
                        parent={this}
                        value={this.state.employmentInformation['otherIncome']}
                      />
                      <FieldCustomTemplateMultiple
                        model='employmentInformation'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Personal Reference</h4>
                      <FieldCheckbox
                        id='referenceFirstName'
                        label='First Name'
                        model='personalReference'
                        parent={this}
                        value={
                          this.state.personalReference['referenceFirstName']
                        }
                      />
                      <FieldCheckbox
                        id='referenceLastName'
                        label='Last Name'
                        model='personalReference'
                        parent={this}
                        value={
                          this.state.personalReference['referenceLastName']
                        }
                      />
                      <FieldCheckbox
                        id='referencePhone'
                        label='Phone'
                        model='personalReference'
                        parent={this}
                        value={this.state.personalReference['referencePhone']}
                      />
                      <FieldCheckbox
                        id='referenceRelationship'
                        label='Relationship'
                        model='personalReference'
                        parent={this}
                        value={
                          this.state.personalReference['referenceRelationship']
                        }
                      />
                      <FieldCustomTemplateMultiple
                        model='personalReference'
                        parent={this}
                        btnName='Add Custom Field'
                      />
                      <h4>Attachments</h4>
                      <FieldCheckbox
                        id='photoId'
                        label='Photo ID'
                        model='attachments'
                        parent={this}
                        value={this.state.attachments['photoId']}
                      />
                      <FieldCheckbox
                        id='payStubs'
                        label='Pay Stubs'
                        model='attachments'
                        parent={this}
                        value={this.state.attachments['payStubs']}
                      />
                      <FieldCheckbox
                        id='taxReturns'
                        label='Tax Returns'
                        model='attachments'
                        parent={this}
                        value={this.state.attachments['taxReturns']}
                      />
                      {/* <FieldCustomTemplateMultiple
                        model='attachments'
                        parent={this}
                        btnName='Add Custom Field'
                      /> */}
                    </div>
                    <div className='modal-footer bg-secondary rounded-bottom d-block'>
                      <div className='row'>
                        <div className='col-4'>
                          <button
                            type='button'
                            className='btn btn-outline-primary btn-lg'
                            onClick={() =>
                              $('#lease-application-template').modal('hide')
                            }
                          >
                            Close
                          </button>
                        </div>
                        <div className='col-8 text-right'>
                          {this.state.leaseApplicationTemplate['createDate'] !=
                            null && (
                            <div
                              className='btn btn-lg btn-primary ml-2'
                              onClick={() => {
                                $('#lease-application-template').modal('hide');
                                $('#delete-lease-application-template').modal(
                                  'show'
                                );
                              }}
                            >
                              Delete
                            </div>
                          )}

                          {/* {this.state.leaseApplicationTemplate['createDate'] ===
                            null && (
                            <div
                              className='btn btn-lg btn-primary ml-2'
                              onClick={() => {
                                $('#lease-application-template').modal('hide');
                                $(
                                  '#lease-application-template-documents'
                                ).modal('show');
                              }}
                            >
                              Upload Files...
                            </div>
                          )} */}

                          <ButtonSave />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              className='modal fade'
              id='lease-application-template-documents'
              tabIndex='-1'
              role='dialog'
              aria-labelledby='lease-application-template-documents-label'
              aria-hidden='true'
            >
              <div
                className='modal-dialog modal-dialog-centered modal-md'
                role='document'
              >
                <div className='modal-content shadow'>
                  <form onSubmit={this.uploadDocument}>
                    <div className='modal-header bg-dark text-white'>
                      <h5
                        className='modal-title'
                        id='lease-application-template-documents-label'
                      >
                        Upload Files
                      </h5>
                      <button
                        type='button'
                        className='close text-white'
                        data-dismiss='modal'
                        aria-label='Close'
                      >
                        <FontAwesomeIcon
                          icon={['fas', 'times']}
                          className='fa-fw va-b mr-2'
                        />
                      </button>
                    </div>
                    <div className='modal-body bg-secondary'>
                      <p className='mb-0'>
                        Files uploaded below will be made available when editing
                        image-related fields for this lease application
                        template.
                      </p>
                    </div>

                    <div className='modal-body'>
                      {
                        this.state.isModalError &&
                          <Alert
                            validationList={this.state.validationList}
                            validationType='danger'
                          />
                      }
                      {this.state.documentList.map((document, key) => {
                        return (
                          <div key={key} className='list-group mb-2'>
                            <div
                              className='list-group-item list-group-item-action c-pointer'
                              onClick={() => this.selectDocument(document)}
                            >
                              <div className='row align-items-center'>
                                <div className='col-2 text-center'>
                                  <img
                                    src={document.url}
                                    alt={document.name}
                                    className='img-fluid'
                                  />
                                </div>
                                <div className='col-8'>
                                  <div className=''>{document.name}</div>
                                  <small className='mb-0 ml-md-0 small text-muted'>
                                    Uploaded on{' '}
                                    <Moment format='MMM DD, YYYY HH:mm'>
                                      {document.createDate}
                                    </Moment>
                                  </small>
                                </div>
                                <div className='col-2 text-right'>
                                  <div className='float-right'>
                                    <FontAwesomeIcon
                                      icon={['far', 'trash-alt']}
                                      className='fa-fw'
                                      size='1x'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <FieldFile
                        id='file'
                        label='File'
                        model='document'
                        labelClass='d-none mb-0'
                        fieldClass='form-control-lg mb-0'
                        fieldColumns='12'
                        labelColums='0'
                        parent={this}
                        value={this.state.document['file']}
                      />
                    </div>
                    <div className='modal-footer bg-secondary rounded-bottom d-block'>
                      <div className='row'>
                        <div className='col-4'>
                          <button
                            type='button'
                            className='btn btn-outline-primary btn-lg'
                            onClick={() => {
                              $('#lease-application-template-documents').modal(
                                'hide'
                              );
                              $('#lease-application-template').modal('show');
                            }}
                          >
                            Back
                          </button>
                        </div>
                        <div className='col-8 text-right'>
                          <button
                            type='submit'
                            className='btn btn-primary btn-lg ml-2'
                          >
                            Upload File
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <Modal
              id='delete-lease-application-template'
              theme='danger'
              iconType='fas'
              iconName='exclamation-triangle'
              title='Delete Lease Application Template'
              body='Are you sure you would like to delete this lease application template?'
            >
              <button
                type='button'
                className='btn btn-outline-danger btn-lg'
                data-dismiss='modal'
                onClick={() => {
                  $('#lease-application-template').modal('show');
                }}
              >
                <FormattedMessage id='button.close' />
              </button>
              <button
                onClick={() => {
                  this.deleteLeaseApplicationTemplate();
                }}
                className='btn btn-danger btn-lg'
                data-dismiss='modal'
              >
                Delete Lease Application Template
              </button>
            </Modal>

            <Modal
              id='delete-document'
              theme='danger'
              iconType='fas'
              iconName='exclamation-triangle'
              title='Delete File'
              body={`Are you sure you would like to delete ${this.state.document.name}? Lease application template elements currently utilizing this file may appear broken as a result, and will require manual review.`}
            >
              <button
                type='button'
                className='btn btn-outline-danger btn-lg'
                data-dismiss='modal'
                onClick={() => {
                  $('#lease-application-template-documents').modal('show');
                }}
              >
                <FormattedMessage id='button.close' />
              </button>
              <button
                onClick={() => {
                  this.deleteDocument();
                }}
                className='btn btn-danger btn-lg'
                data-dismiss='modal'
              >
                Delete File
              </button>
            </Modal>
          </React.Fragment>
        );
    };
}

export default LeaseApplicationTemplates;