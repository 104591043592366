import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import Stepper from 'bs-stepper';
import 'bs-stepper/dist/css/bs-stepper.min.css';
import $ from "jquery";
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { HYPER_WALLET_CONSTANTS } from '../../../constants/HyperWalletConstants';
import { HYPER_WALLET_PAYMENT_TYPES } from '../../../constants/HyperWalletPaymentTypes';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../../common/Alert';
import { useInput } from '../../common/hooks/useInput';
import { useValidationList } from '../../common/hooks/useValidationList';
import Spinner from "../../common/Spinner";
import styles from './css/HyperwalletPayment.module.css';
import LandlordStep from './stepper/LandlordStep';
import PersonalInfoStep from './stepper/PersonalInfoStep';
import SendPaymentStep from './stepper/SendPaymentStep';

const HyperwalletPayment = (props) => {

  /***** SHARED STATE ***/
  /*********************/
  const [stepper, setStepper] = useState('');
  const [processingFees, setProcessingFees] = useState(null);
  const { validationList, addErrorToValidationList, setAlertMessage, clearValidationList } = useValidationList([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isLandlordStepValid, setIsLandlordStepValid] = useState(false);
  const [isPersonalInfoStepValid, setIsPersonalInfoStepValid] = useState(false);

  /*********************/
  /*********************/

  /****** LANDLORD STEP STATE ******/
  /*********************************/
  const { value: firstNameLI, handleChange: handleFirstNameLI } = useInput('');
  const { value: lastNameLI, handleChange: handleLastNameLI } = useInput('');
  const { value: companyNameLI, handleChange: handleChangeCompanyNameLI } = useInput('');
  const { value: emailLI, handleChange: handleChangeEmailLI } = useInput('');
  const { value: confirmEmailLI, handleChange: handleChangeConfirmEmailLI } = useInput('');
  const { value: phoneNumberLI, handleChange: handleChangePhoneNumberLI } = useInput('');
  const { value: notifyLandlordLI, handleChange: handleChangeNotifyLandlordLI } = useInput(true);

  const { value: street1LI, handleChange: handleChangeStreet1LI } = useInput('');
  const { value: street2LI, handleChange: handleChangeStreet2LI } = useInput('');
  const { value: cityLI, handleChange: handleChangeCityLI } = useInput('');
  const { value: postalCodeLI, handleChange: handleChangePostalCodeLI } = useInput('');
  const { value: countryLI, handleChange: handleChangeCountryLI } = useInput(props.selectedCountry);
  const { value: provinceLI, handleChange: handleChangeProvinceLI } = useInput('');
  /*********************************/
  /*********************************/

  /****** PERSONAL STEP STATE ******/
  /*********************************/
  const [paymentCategory, setPaymentCategory] = useState(null);

  const { value: firstNamePI, handleChange: handleChangeFirstNamePI } = useInput('');
  const { value: lastNamePI, handleChange: handleChangeLastNamePI } = useInput('');
  const { value: emailPI, handleChange: handleChangeEmailPI } = useInput('');
  const { value: confirmEmailPI, handleChange: handleChangeConfirmEmailPI } = useInput('');
  const { value: phoneNumberPI, handleChange: handleChangePhoneNumberPI } = useInput('');
  const { value: dobPI, handleChange: handleChangeDobPI } = useInput('');

  const { value: street1PI, handleChange: handleChangeStreet1PI } = useInput('');
  const { value: street2PI, handleChange: handleChangeStreet2PI } = useInput('');
  const { value: unitPI, handleChange: handleChangeUnitPI } = useInput('');
  const { value: cityPI, handleChange: handleChangeCityPI } = useInput('');
  const { value: postalCodePI, handleChange: handleChangePostalCodePI } = useInput('');
  const { value: countryPI, handleChange: handleChangeCountryPI } = useInput(props.selectedCountry);
  const { value: provincePI, handleChange: handleChangeProvincePI } = useInput('');

  const { value: street1PD, handleChange: handleChangeStreet1PD } = useInput('');
  const { value: street2PD, handleChange: handleChangeStreet2PD } = useInput('');
  const { value: cityPD, handleChange: handleChangeCityPD } = useInput('');
  const { value: postalCodePD, handleChange: handleChangePostalCodePD } = useInput('');
  const { value: countryPD, handleChange: handleChangeCountryPD } = useInput('');
  const { value: provincePD, handleChange: handleChangeProvincePD } = useInput('');
  /**************************************/
  /**************************************/


  /*** PAYMENT STEP STATE ***/
  /**************************/
  const [showSendPaymentStep, setShowSendPaymentStep] = useState(true);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [selectedCard, setSelectedCard] = useState(0);
  const [cards, setCards] = useState([]);
  const [hwPageDetails, setHwPageDetails] = useState(null);
  /****************************/
  /****************************/

  /***** SHARED ACTIONS ******/
  /***************************/
  useEffect(() => {
    const initStepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true,
    });
    setStepper(initStepper);
    getHwPageDetails();
    registerStepperValidityEL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStep = (stepName=null, data=null) => {
    clearPaymentStepState();
    switch(stepName) {
      case HYPER_WALLET_CONSTANTS.STEP_PERSONAL_INFO:
        handlePersonalInfoStepSubmit(data);
        break;
      default:
        stepper.next();
    }
    
  };

  const previousStep = () => {
    clearPaymentStepState();
    stepper.previous();
  };

  const validateLandlordForm = () => {
    clearValidationList();
    let isFormValid = true;

    // html errors
    let landlordStepFormFieldsArray = Array.from(document.querySelectorAll("#landlordStepForm .form-control"));
    if (landlordStepFormFieldsArray) {
      landlordStepFormFieldsArray.forEach((field) => {
        if (!field.checkValidity()) {
          setAlertMessage(`Please ensure the Landlord ${field.name} field is valid.`);
          isFormValid = false;
        }    
      });
    }

    // custom errors
    if (emailLI !== confirmEmailLI) {
      setAlertMessage("Landlord Email and Confirmation Email must match.");
      isFormValid = false;
    }

    if (!phoneNumberLI.trim() && (!emailLI.trim() || !confirmEmailLI.trim())) {
      setAlertMessage("You must enter either a valid Landlord mobile number or a valid Landlord email address.");
      isFormValid = false;
    }
    
    return isFormValid;
  }

  const clearPaymentStepState = () => {
    setShowSendPaymentStep(true);
    setSelectedPaymentType(null);
    setSelectedCard(0);
    setCards([]);
  }

  /**
   * Checks if the main forms in the stepper are valid or not, this helps us mark the step as checked in the stepper header
   */
  const checkStepsValidity = () => {
    const landlordStepValidity = $('#landlordStepForm')[0]?.checkValidity();
    const personalInfoStepValidity = $('#personalInfoStepForm')[0]?.checkValidity();
    setIsLandlordStepValid(landlordStepValidity || false);
    setIsPersonalInfoStepValid(personalInfoStepValidity || false);
  }

  const registerStepperValidityEL = () => {
    const stepperEl = document.getElementById('stepper1');

    stepperEl.addEventListener('show.bs-stepper', function (event) {
      const indexStep = event.detail.indexStep;
      
      if(indexStep === 0) {
        setIsLandlordStepValid(false);
        const personalInfoStepValidity = $('#personalInfoStepForm')[0]?.checkValidity();
        setIsPersonalInfoStepValid(personalInfoStepValidity || false);
      } else if(indexStep === 1) {
        setIsPersonalInfoStepValid(false);
        const landlordStepValidity = $('#landlordStepForm')[0]?.checkValidity();
        setIsLandlordStepValid(landlordStepValidity || false);
      } else {
        checkStepsValidity();
      }
    })
  }

  /***************************/
  /***************************/

  /***** HW PAGE DETAILS *****/
  /***************************/
  /**
   * Get the details of the HW page which further helps us extract company ID and other details
   */
  const getHwPageDetails = () => {
    setShowSpinner(true);
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/hyperwallet_page/name/${props.pageName}`, {
        headers: generateRequestHeaders(),
      })
      .then((response) => {
        setShowSpinner(false);
        setHwPageDetails(response.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        if(props.history) { // take the user away from HW if there are problems fetching HW page details
          props.history.push('/'); 
        }
        addErrorToValidationList(error);
      });
  };
  /***************************/
  /***************************/

  /***** Card State Management ******/
  /***********************************/
  const addCard = (newCard) => {
    const allCards = [...cards];
    allCards.push(newCard);
    setCards(allCards);
    setSelectedCard(newCard.id);
  };

  const removeCard = (cardId) => {
    if (cardId) {
      const allCards = [...cards];
      const updatedCards = allCards.filter((card) => card.id !== cardId);
      setCards(updatedCards);
    }
    setSelectedCard(0);
  };

  // const handleCardChange = (index, field, value) => {
  //   const allCards = [...cards];
  //   const cardToUpdate = allCards[index];
  //   cardToUpdate[field] = value;
  //   allCards[index] = cardToUpdate;
  //   setCards(allCards);
  // }
  /***********************************/

  /*** PERSONAL INFO STEP ACTIONS ***/
  /***********************************/
    const handlePersonalInfoStepSubmit = (data) => {
      if(data.paymentCategory !== paymentCategory) {
        setPaymentCategory(data.paymentCategory);
      } else {
        stepper.next();
      }
    }

    // move to the payment step once the  payment category has been set
    useEffect(() => {
      if(paymentCategory) { 
        stepper.next();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentCategory]);
  /***********************************/

  /****** PROCESSING FEES LOGIC ******/
  /***********************************/
  /**
   * Get PayPal payment method object
   */
  const getPayPalPaymentMethod = () => {
    return {
      type: 'TYPE_PAY_PAL',
    };
  };

  /**
   * Get Letus Split payment method object
   */
  const getLetusSplitPaymentMethod = (companyId, paymentAmount, installments) => {
    return {
      type: 'TYPE_INSTALLMENT_PAY',
      amount: Number(paymentAmount),
      installments: installments,
      billingAccount: {
        companyId: companyId
      },
    };
  }

  /**
   * Get CC payment method object based on the payment method id.
   * @param {*} paymentMethodId
   */
  const getCCPaymentMethod = (paymentMethodId) => {
    const allCards = [...cards];
    const paymentMethod = allCards.find(({ id }) => id === paymentMethodId);

    /**
     * The frontend pulls the brand from database, BRAND_AMEX in RmBinMapping.java. Parts of backend require it to
     * be "AMERICAN_EXPRESS, while other parts require it to be "AMERICAN EXPRESS", this is the simplest fix to LP-4168
     * that doesn't break anything else
     * */
    if (paymentMethod.brand === "AMERICAN EXPRESS") {
      paymentMethod.brand = "AMERICAN_EXPRESS";
    }

    return paymentMethod;
  };

  /**
   * Calculate the transaction fees that will be charged to the user prior to making the payment.
   * This endpoint requires amount, companyId, and paymentMethod to be present
   */
  const debounceCalculateFees = (paymentAmount, installments) => {
    const companyId = hwPageDetails?.companyId; 
    let paymentMethod = null;
    
    switch (selectedPaymentType) {
      case HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL:
        paymentMethod = getPayPalPaymentMethod();
        break;
      case HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD:
        const paymentMethodCC = getCCPaymentMethod(selectedCard);
        paymentMethod = {
          id: paymentMethodCC.id,
          type: paymentMethodCC.type,
          brand: paymentMethodCC.brand,
          cardNumber: paymentMethodCC.cardNumber,
        };
        break;
      case HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT:
        paymentMethod = getLetusSplitPaymentMethod(companyId, paymentAmount, installments);
        break;
      default:
        break;
    }

    if(!paymentMethod) {
      setAlertMessage(`There was a problem calculating fees!`);
      return;
    }

    clearValidationList();
    setShowSpinner(true);
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/calculatefee`,
        {
          amount: Number(paymentAmount),
          companyId: companyId,
          paymentMethod: paymentMethod,
        },
        {
          headers: generateRequestHeaders(),
        }
      )
      .then((response) => {
        setShowSpinner(false);
        setProcessingFees(response.data);
      })
      .catch((error) => {
        setShowSpinner(false);
        setProcessingFees('NA');
        addErrorToValidationList(error);
      });
  };

  /**
   * Debounce calculateFees to avoid unnecessary API calls
   */
  let timer = null
  const calculateFees = (paymentAmount, installments) => {
    if(timer) {
      clearInterval(timer);
    }
    timer = setTimeout(() => {
      debounceCalculateFees(paymentAmount, installments)
    }, 500);
  }
  /***********************************/
  /***********************************/

  /**** REGISTER USER & LANDLORD *****/
  /***********************************/

  const getBillingAddressForRegisterUserAndLandlord = (selectedPaymentType) => {
    if(selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL || selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD) {
      return null;
    } else if(selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT) {
      const selectedCardObj = getCCPaymentMethod(selectedCard);
      return  {
        type: 'TYPE_ADDRESS',
        addressType: 'BILLING',
        suite: selectedCardObj.suite,
        street1: selectedCardObj.street,
        street2: selectedCardObj.street2,
        city: selectedCardObj.city,
        province: selectedCardObj.province,
        postalCode: selectedCardObj.postalCode,
        country: selectedCardObj.country,
      };
    }
    return null;
  }

  const getPaymentMethodForRegisterUserAndLandlord = (selectedPaymentType, paymentAmount, installments, termsAndConditions) => {
    if (selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL) {
      return {
        type: 'TYPE_PAY_PAL',
      };
    } else if (selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD) {
      return {
        type: 'TYPE_CREDIT_CARD',
      };
    } else if(selectedPaymentType === HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT) {
      if(!paymentAmount || !installments) {
        return null;
      }
      const selectedCardObj = getCCPaymentMethod(selectedCard);
      return {
        type: 'TYPE_INSTALLMENT_PAY',
        amount: (Number(paymentAmount) / Number(installments)).toFixed(2),
        installments: installments,
        acceptedTermsAndConditions: termsAndConditions,
        fundingMethodType: selectedCardObj.type,
        creditCardFundingMethod: {
            type: selectedCardObj.type,
            brand: selectedCardObj.brand,
            cardNumber: selectedCardObj.cardNumber,
            cvv: selectedCardObj.cvv,
            expiryMonth: selectedCardObj.expiryMonth,
            expiryYear: selectedCardObj.expiryYear,
            nameOnCard: selectedCardObj.nameOnCard,
            securityCode: null,
            userType: 'TYPE_CUSTOMER'
        }
      }
    }
    return null;
  };

  const generateRegisterUserAndLandlordParams = (paymentAmount, selectedPaymentType, reCaptchaId, installments, termsAndConditions) => {
    return {
      user: {
        userIdentifier: emailPI?.toLowerCase(),
        email: emailPI?.toLowerCase(),
        firstName: firstNamePI,
        lastName: lastNamePI,
        phone: phoneNumberPI,
        birthDate: moment(dobPI).format('YYYY-MM-DD[T]hh:mm:ssZZ'),
        type: 'TYPE_CUSTOMER',
      },
      userPropertyAddress: {
        propertyName: 'tenant property',
        street1: street1PI,
        street2: street2PI,
        city: cityPI,
        country: countryPI,
        province: provincePI,
        postalCode: postalCodePI,
        companyId: hwPageDetails?.companyId,
        category: 'RESIDENTIAL',
        type: 'TYPE_PROPERTY',
      },
      manager: {
        userIdentifier: emailLI?.toLowerCase(),
        email: emailLI?.toLowerCase(),
        firstName: firstNameLI,
        lastName: lastNameLI,
        phone: phoneNumberLI,
        companyId: hwPageDetails?.companyId,
        type: 'TYPE_MANAGER',
      },
      managerPropertyAddress: {
        propertyName: companyNameLI,
        street1: street1LI,
        street2: street2LI,
        city: cityLI,
        country: countryLI,
        province: provinceLI,
        postalCode: postalCodeLI,
        addressType: 'RESIDENTIAL',
        type: 'TYPE_ADDRESS',
      },
      amount: paymentAmount,
      recaptchaResponse: reCaptchaId,
      companyId: hwPageDetails?.companyId,
      acceptedTermsAndConditions: props.agreedToTerms,
      billingAddress: getBillingAddressForRegisterUserAndLandlord(selectedPaymentType),
      paymentMethod: getPaymentMethodForRegisterUserAndLandlord(selectedPaymentType, paymentAmount, installments, termsAndConditions),
    };
  };

  /**
   * Saves the landlord and tenant information.
   * Payment will only process if this information has been saved successfully. 
   */
  const registerUserAndLandlord = (paymentAmount, selectedPaymentType, reCaptchaId, installments=0, termsAndConditions=null) => {
    const params = generateRegisterUserAndLandlordParams(paymentAmount, selectedPaymentType, reCaptchaId, installments, termsAndConditions);
    return axios.post(`${constants.REACT_APP_HOST_API_URL}/payer_marketplace/register_recipient`, params, {
      headers: generateRequestHeaders(),
    });
  };
  /***********************************/
  /***********************************/

  return (
    <div className={`container-xl ${styles.containerCustom}`}>
      <Spinner visible={showSpinner}/>
      <div id='stepper1' className='bs-stepper'>
        <div className={`bs-stepper-header ${styles.stepper}`}>
          <div className='step' data-target='#test-l-1' onClick={() => clearPaymentStepState()}>
            <button className='step-trigger'>
              <span className={`${isLandlordStepValid && 'bs-stepper-circle-valid'} bs-stepper-circle`}>{isLandlordStepValid ? <FontAwesomeIcon icon={["far", "check"]} /> : '1'}</span>
              <span className={`${styles.stepperLabel}`}>&nbsp;Payee
                <span> Information</span>
              </span>
            </button>
          </div>

          <div className='line'></div>
          <div className='step' data-target='#test-l-2' onClick={() => clearPaymentStepState()}>
            <button className='step-trigger'>
              <span className={`${isPersonalInfoStepValid && 'bs-stepper-circle-valid'} bs-stepper-circle`}>{isPersonalInfoStepValid ? <FontAwesomeIcon icon={["far", "check"]} /> : '2'}</span>
              <span className={`${styles.stepperLabel}`}>&nbsp;Payer
                <span> Information</span>
              </span>
            </button>
          </div>

          <div className='line'></div>
          <div className='step' data-target='#test-l-3'>
            <button className={`step-trigger ${selectedPaymentType || 'disabled'}`}>
              <span className='bs-stepper-circle'>3</span>
              <span className={`${styles.stepperLabel}`}>&nbsp;Payment
                <span> &nbsp;</span>
              </span>
            </button>
          </div>
        </div>
        <div className={`bs-stepper-content ${styles.containerFormHW}`}>
          <Alert validationList={validationList} />
          <div>
            <div id='test-l-1' className='content text-left'>
              <LandlordStep
                next={nextStep}
                toggleShowPayRentNow={() => props.toggleShowPayRentNow(false)}
                firstNameLI={firstNameLI}
                lastNameLI={lastNameLI}
                companyNameLI={companyNameLI}
                emailLI={emailLI}
                confirmEmailLI={confirmEmailLI}
                phoneNumberLI={phoneNumberLI}
                notifyLandlordLI={notifyLandlordLI}
                street1LI={street1LI}
                street2LI={street2LI}
                cityLI={cityLI}
                postalCodeLI={postalCodeLI}
                countryLI={countryLI}
                provinceLI={provinceLI}
                selectedCountry={props.selectedCountry}
                handleFirstNameLI={handleFirstNameLI}
                handleLastNameLI={handleLastNameLI}
                handleChangeCompanyNameLI={handleChangeCompanyNameLI}
                handleChangeEmailLI={handleChangeEmailLI}
                handleChangeConfirmEmailLI={handleChangeConfirmEmailLI}
                handleChangePhoneNumberLI={handleChangePhoneNumberLI}
                handleChangeNotifyLandlordLI={handleChangeNotifyLandlordLI}
                handleChangeStreet1LI={handleChangeStreet1LI}
                handleChangeStreet2LI={handleChangeStreet2LI}
                handleChangeCityLI={handleChangeCityLI}
                handleChangePostalCodeLI={handleChangePostalCodeLI}
                handleChangeCountryLI={handleChangeCountryLI}
                handleChangeProvinceLI={handleChangeProvinceLI}
              />
            </div>
            <div id='test-l-2' className='content text-left'>
              <PersonalInfoStep
                next={nextStep}
                previous={previousStep}
                firstNamePI={firstNamePI}
                lastNamePI={lastNamePI}
                emailPI={emailPI}
                confirmEmailPI={confirmEmailPI}
                phoneNumberPI={phoneNumberPI}
                dobPI={dobPI}
                street1PI={street1PI}
                street2PI={street2PI}
                unitPI={unitPI}
                cityPI={cityPI}
                postalCodePI={postalCodePI}
                countryPI={countryPI}
                provincePI={provincePI}
                street1PD={street1PD}
                street2PD={street2PD}
                cityPD={cityPD}
                postalCodePD={postalCodePD}
                countryPD={countryPD}
                provincePD={provincePD}
                selectedCountry={props.selectedCountry}
                handleChangeFirstNamePI={handleChangeFirstNamePI}
                handleChangeLastNamePI={handleChangeLastNamePI}
                handleChangeEmailPI={handleChangeEmailPI}
                handleChangeConfirmEmailPI={handleChangeConfirmEmailPI}
                handleChangePhoneNumberPI={handleChangePhoneNumberPI}
                handleChangeDobPI={handleChangeDobPI}
                handleChangeStreet1PI={handleChangeStreet1PI}
                handleChangeStreet2PI={handleChangeStreet2PI}
                handleChangeUnitPI={handleChangeUnitPI}
                handleChangeCityPI={handleChangeCityPI}
                handleChangePostalCodePI={handleChangePostalCodePI}
                handleChangeCountryPI={handleChangeCountryPI}
                handleChangeProvincePI={handleChangeProvincePI}
                handleChangeStreet1PD={handleChangeStreet1PD}
                handleChangeStreet2PD={handleChangeStreet2PD}
                handleChangeCityPD={handleChangeCityPD}
                handleChangePostalCodePD={handleChangePostalCodePD}
                handleChangeCountryPD={handleChangeCountryPD}
                handleChangeProvincePD={handleChangeProvincePD}
                validateLandlordForm={() => validateLandlordForm()}
              />
            </div>
            <div id='test-l-3' className='content text-left'>
              <SendPaymentStep
                next={nextStep}
                previous={previousStep}
                showSendPaymentStep={showSendPaymentStep}
                setShowSendPaymentStep={setShowSendPaymentStep}
                selectedPaymentType={selectedPaymentType}
                setSelectedPaymentType={setSelectedPaymentType}
                cards={cards}
                setCards={setCards}
                addCard={addCard}
                removeCard={removeCard}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                clearPaymentStepState={clearPaymentStepState}
                paymentCategory={paymentCategory}
                getCCPaymentMethod={getCCPaymentMethod}
                calculateFees={calculateFees}
                processingFees={processingFees}
                hwPageDetails={hwPageDetails}
                tenantEmail={emailPI?.toLowerCase()}
                landlordEmail={emailLI?.toLowerCase()}
                notifyLandlord={notifyLandlordLI}
                selectedCharge={props.selectedCharge}
                registerUserAndLandlord={registerUserAndLandlord}
                setShowSpinner={setShowSpinner}
                agreedToTerms={props.agreedToTerms}
                selectedCountry={props.selectedCountry}
                tenantUnit={unitPI}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperwalletPayment;
