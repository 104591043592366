import $ from "jquery";
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import makeAnimated from 'react-select/animated';
import AsyncSelect from 'react-select/async';

class FieldPropertiesAsync extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
        this.state = {
            showSelectedValue: false
        }
    }

    componentDidMount() {
        this.handleFirstRenderOptionsInput()
    }

    handleFirstRenderOptionsInput() {
        setTimeout(() => {
            this.setState({
                showSelectedValue: true
            })
        }, 300);
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param selectedOption - The selected option.
     */
    handleChange(selectedOption) {

        let field;

        if(this.props.model != null) {
            field = this.props.parent.state[this.props.model];
            field[this.props.id] = selectedOption ? selectedOption.value : null;
        }

        if(this.props.model == null) {
            field = selectedOption ? selectedOption.value : null;
        }

        this.props.parent.setState(({
            [this.props.model ? this.props.model : this.props.id]: field,
            selectedProperty: {
                value: selectedOption ? selectedOption.value : null,
                label: selectedOption ? selectedOption.label : null
            }
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A multi-select properties component.
     */
    render() {

        const animatedComponents = makeAnimated();
        const {formatMessage} = this.props.intl;

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        let options = [];

        if(this.props.allPropertiesOption) {
            options.push({
                value: null,
                label: 'All Properties'
            });
        }

        this.props.options.forEach((data, key) => {
            if(this.props.formattedLabel){

                if(data?.name){

                    options.push({
                        value: data.id,
                        label: data.name,
                    });

                } else{

                    options.push({
                        value: data.id,
                        label: data.propertyName,
                    });
                }

            } else {
                options.push({
                    value: data.id,
                    label: (data.street1) + (data.street2 ? ', ' + data.street2 : '') + ', ' + (data.city === null? '' : data.city) + ', ' + (data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})) + ', ' + (formatMessage({id: "country." + data.country})) + ' ' + (data.postalCode === null? '' : data.postalCode)
                });
            }
            
        });

        let values = [];

        if(this.state.showSelectedValue && this.props?.value){

            this.props.value.forEach((data, key) => {

                if(this.props.formattedLabel && data?.name){

                    values.push({
                        value: data.value,
                        label: data.name,
                    });

                } else if(this.props.formattedLabel && data?.propertyName){

                    values.push({
                        value: data.value,
                        label: data.propertyName,
                    });

                } else {

                    values.push({
                        value: data.value,
                        label: data.label
                    });
                }
            });
        }

        const customStyles = {

            control: (base, state) => ({
                ...base,
                height: 'auto',
                minHeight: 'auto',
                fontSize: '0.875rem',
                borderRadius: '0.2rem',
                boxShadow: 'none',
                color: '#495057',
                border: isInvalid ? '1px solid #dc3545' : state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                borderBottomLeftRadius: state.isFocused ? '0' : '0.2rem',
                borderBottomRightRadius: state.isFocused ? '0' : '0.2rem',
                '&:hover': {
                    border: isInvalid ? '1px solid #dc3545' : state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                },
                marginTop: '6px',
                marginBottom: '6px',
                transition: 'all 0.1s ease-in-out',
            }),

            option: (provided, { data, isDisabled, isFocused, isSelected, isMulti} ) => ({
                ...provided,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
                background: isSelected ? '#e9ecef' : 'none',
                color: '#495057',
                '&:hover': {
                    background: '#52c75d',
                    color: '#fff'
                }
            }),

            placeholder: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
            }),

            menu: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                marginTop: '0 ',
                zIndex: '999',
                boxShadow: 'none',
                border: '1px solid #52c75d',
                borderTop: '0',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
            }),

            multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: '#e9ecef',
            }),

            multiValueRemove: (provided, state) => ({
                ...provided,
                '&:hover': {
                    backgroundColor: '#52c75d',
                    color: '#ffffff'
                },
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                transition: 'all 0.1s ease-in-out',
                cursor: 'pointer'
            }),

            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none'
            }),

            dropdownIndicator: (provided, state) => ({
                ...provided,
                position: 'relative',
                top: '-3px',
                color: isInvalid ? '#dc3545' : provided.color,
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                marginTop: '6px',
                marginBottom: '6px',
                padding: '0 0.5rem',
                transition: 'all 0.1s ease-in-out',
            }),

            clearIndicator: (provided, state) => ({
                ...provided,
                cursor: 'pointer'
            })

        };

        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && <small className="text-muted font-italic">*</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <AsyncSelect
                            id={this.props.id}
                            name={this.props.id}
                            defaultOptions={options}
                            value={this.state.showSelectedValue ? values : ''}
                            onChange={this.props.handleChange || this.handleChange}
                            isDisabled={this.props.disabled}
                            closeMenuOnSelect={this.props.closeMenuOnSelect}
                            isMulti={this.props.isMulti}
                            components={animatedComponents}
                            styles={customStyles}
                            placeholder={this.props.placeholder || "Search properties..."}
                            className={`basic-multi-select ${isInvalid ? 'is-invalid' : ''}`} 
                            loadOptions={this.props.loadOptions}
                            key={this.props.value?.length || 0}
                        />

                        {isInvalid &&
                        <div className="form-text small text-danger">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldPropertiesAsync.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

FieldPropertiesAsync.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(FieldPropertiesAsync);
