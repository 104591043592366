import * as moment from "moment";
import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { FormattedMessage } from "react-intl";

class FieldDate extends Component {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);
        
        /*this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);*/
        
        this.state = {
            from: undefined,
            to: undefined,
        };
        
        if (this.props.parent?.handleChange || this.props.handleChange) {
            this.handleChange = this.props.parent?.handleChange.bind(this) || this.props.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }

        if (this.props.handleFromChange) {
            this.handleFromChange = this.props.handleFromChange.bind(this);
        } else {
            this.handleFromChange = this.handleFromChange.bind(this);
        }

        if (this.props.handleToChange) {
            this.handleToChange = this.props.handleToChange.bind(this);
        } else {
            this.handleToChange = this.handleToChange.bind(this);
        }
    }

    showFromMonth() {
        const { from, to } = this.state;
        if (!from) {
          return;
        }
        if (moment(to).diff(moment(from), 'months') < 2) {
          this.to.getDayPicker().showMonth(from);
        }
      }
    
      handleFromChange(from) {
        // Change the from date and focus the "to" input field
        this.setState({ from });

        let dateField;

        let dateTime = from ? moment(from).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }
        
        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.startDateModel ? this.props.startDateModel : 'startDate']: dateField
        }));     
    }
    
    handleToChange(to) {
        this.setState({ to }, this.showFromMonth);

        let dateField;

        let dateTime = to ? moment(to).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);
        }
        
        dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;

        this.props.parent.setState(prevState => ({
            ...prevState,
            [this.props.endDateModel ? this.props.endDateModel : 'endDate']: dateField
        }));
    }

    /**
     * Handle change events on date fields. Convert the date to the format accepted by the server.
     *
     * @param date - The selected date value.
     */
    handleChange(date) {

        let dateField;

        let dateTime = date ? moment(date).toDate() : null;

        if (dateTime) {
            dateTime.setHours(0);
            dateTime.setMinutes(0);
            dateTime.setSeconds(0);
            dateTime.setMilliseconds(0);

            if (this.props.model != null) {
                dateField = this.props.parent.state[this.props.model];

                if(this.props.modelKey != null){

                    dateField[Number(this.props.modelKey)][this.props.id] = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;

                } else {

                    dateField[this.props.id] = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
                }

            }

            if (this.props.model == null) {
                dateField = dateTime ? moment(dateTime).format('YYYY-MM-DD[T]hh:mm:ssZZ') : null;
            }

            this.props.parent.setState(prevState => ({
                ...prevState,
                [this.props.model ? this.props.model : this.props.id]: dateField,
            }));
        }
    }

    

    /**
     * Filter out specific days from selection on the datepicker.
     *
     * @param date - The date object from the datepicker.
     * @returns {boolean} - True if the date is selectable from the datepicker.
     */
    filterDate = (date) => {

        const day = date.getDate();

        // Filter the available selections from the datepicker to only the first of each month
        if(this.props.filterDateType === 'FIRST_DATE') {

            return day === 1;
        }

        // Filter the available selections from the datepicker to dates before the monthly payment due day
        if(this.props.filterDateType === 'MONTHLY_PAYMENT_DUE_DAY') {

            return this.props.monthlyPaymentDueDay >= date.getDate();
        }

        return false;
    };

    /**
     * Render the component.
     *
     * @returns {*} - A generic date field component. When clicked on, a date picker appears, which utilizes the
     * external react-datepicker library.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        
        let validationList = this.props.parent?.state.validationList || this.state.validationList || {};
        Object.entries(validationList).forEach(([key, validation]) => {
            if (validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if (key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        let startDate = new Date(this.props.startDate);
        let endDate = new Date(this.props.endDate);
        let minDate = new Date(this.props.minDate);
        let maxDate = new Date(this.props.maxDate);
        let dataStringIndex = 10;


        return (
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && <small className="text-muted font-italic">*</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        {this.props.selectsRange === undefined &&
                        <div>
                        <DayPickerInput 
                            format={this.props.dateFormat}
                            dayPickerProps = {(this.props.before !== undefined || this.props.after !== undefined) &&
                                {disabledDays:{
                                    before: this.props.before,
                                    after: this.props.after}}}
                            inputProps={{disabled: `${this.props.disabled? 'true' : ''}`, required:`${this.props.required? 'true' : ''}`, className:`form-control gotham ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' :''} ${isInvalid ? 'is-invalid' : ''}`, pattern:'^((19|2[0-9])[0-9]{2})-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$'}}
                            onDayChange={day => this.handleChange(day)} 
                            placeholder={this.props.placeholder}
                            showMonthYearPicker = {!!this.props.showMonthYearPicker ? dataStringIndex = 7 : dataStringIndex = 10}
                            minDate = {this.props.minDate ? new Date(minDate.getTime() + minDate.getTimezoneOffset() * 60000) : null}
                            maxDate = {this.props.maxDate ? new Date(maxDate.getTime() + maxDate.getTimezoneOffset() * 60000) : null}
                            startDate = {this.props.startDate ? new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000) : null} 
                            endDate ={this.props.endDate ? new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000) : null}
                            value={this.props.value == null ? '' : String(this.props.value).slice(0, dataStringIndex)}>
                        </DayPickerInput>
                        <style>{`
                            .DayPickerInput:not(.DayPickerInput > .InputField):not(.DayPickerInput > .InputFromTo-to):not(.DayPickerInput > .InputFromTo){
                                width: ${!!this.props.width ? this.props.width : '110px'};
                                background-color: #fff;
                        `}</style>
                        </div>
                        }

                       
                        {this.props.selectsRange &&
                        <span className="InputFromTo">
                            <DayPickerInput
                                id={this.props.id}
                                value={this.props.value == null ? '' : String(this.props.startDate).slice(0,10)}
                                placeholder= {this.props.startDatePlaceHolder || "Start Date"}
                                format={this.props.dateFormat}
                                inputProps={{readOnly:true, className:`form-control ${this.props.fieldClass} text-center gotham py-0 px-0 InputField`}}
                                dayPickerProps={{
                                    selectedDays: [from, { from, to }],
                                    disabledDays: { after: to },
                                    toMonth: to,
                                    modifiers,
                                    numberOfMonths: 1,}}
                                onDayChange={this.handleFromChange}/>
                                <span className="InputFromTo-to">
                                    <DayPickerInput
                                        id={this.props.id}
                                        ref={el => (this.to = el)}
                                        value={this.props.endDate == null ? '' : String(this.props.endDate).slice(0,10)}
                                        placeholder= {this.props.endDatePlaceHolder || "End Date"}
                                        format={this.props.dateFormat}
                                        inputProps={{readOnly:true, className:`form-control ${this.props.fieldClass} text-center gotham py-0 px-0 InputField`}}
                                        dayPickerProps={{
                                            selectedDays: [from, { from, to }],
                                            disabledDays: { before: from },
                                            modifiers,
                                            month: from,
                                            fromMonth: from,
                                            numberOfMonths: 1,}}
                                        onDayChange={this.handleToChange}/>
                                </span>
                        </span>
                        }                            
                        
                        {isInvalid &&
                        <div className="small text-danger mt-1">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldDate.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    monthsShown: 1,
    dateFormat: 'MM/dd/yyyy',
    required: false,
};

export default FieldDate;
