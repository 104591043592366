import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import lodash from "lodash";
import * as moment from 'moment';
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from 'react-intl';
import Moment from 'react-moment';
import { PAYMENT_TRANSACTION_QUERY } from '../../../constants/QueryConstants';
import { getPaymentTransactionById } from '../../../services/LetusApiService';
import * as constants from '../../../util/constants';
import Alert from '../../common/Alert';
import Breadcrumb from '../../common/Breadcrumb';
import FieldSelect from '../../common/FieldSelect';
import ReceiptPaymentTransactionActionsModal from '../../common/modals/ReceiptPaymentTransactionActionsModal';
import NavListItem from '../../common/NavListItem';
import Propertii from '../../common/Propertii';
import ReceiptPaymentTransaction from '../../common/ReceiptPaymentTransaction';
import ReceiptPaymentTransactionActions from '../../common/ReceiptPaymentTransactionActions';
import SkeletonLoader from '../../common/SkeletonLoader';
import NSFTransactionsNotValidated from './NSFTransactionsNotValidated';

class Dashboard extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      skeletonForLoading: false,
      showOnHoldTransactions: false,
      transactionProcessorFilter: '',
      paymentTransaction: {},
      scheduledPayment: {},
      parentPaymentTransaction: {},
      currentProcessingAlert: null,
      paymentTransactionReversalList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },
      selectAllTransactions: false,
      selectedTransactionIds: [],
      searchQueryFilter: null,
      filteredTransactions: [],
      validationList: [],
      selectedNsfTransactions: {}
    };

    this.getStatsTotal = this.getStatsTotal.bind(this);
    this.getProcessingAlerts = this.getProcessingAlerts.bind(this);
    this.selectPaymentTransaction = this.selectPaymentTransaction.bind(this);
    this.selectScheduledPayment = this.selectScheduledPayment.bind(this);
    this.selectFundsTransfer = this.selectFundsTransfer.bind(this);
    this.setPaymentTransactionFeeProfile = this.setPaymentTransactionFeeProfile.bind(this);
    this.setParentPaymentTransaction = this.setParentPaymentTransaction.bind(this);
    this.searchPaymentTransactionReversals = this.searchPaymentTransactionReversals.bind(this);
    this.updateTransactionStatus = this.updateTransactionStatus.bind(this);
    this.markNSFTransactionValidOrInvalid = this.markNSFTransactionValidOrInvalid.bind(this);
    this.markChargebackTransactionLETUSProcessed = this.markChargebackTransactionLETUSProcessed.bind(this);
    this.handleSelectUnSelectAllTransactions = this.handleSelectUnSelectAllTransactions.bind(this);
    this.handleChangeSearchQueryFilter = this.handleChangeSearchQueryFilter.bind(this);
    this.sortTransactions = this.sortTransactions.bind(this);
    this.handleNsfFeeAmountChange = this.handleNsfFeeAmountChange.bind(this);
    this.handleNsfFeePaymentMethodChange = this.handleNsfFeePaymentMethodChange.bind(this);
  }

  /**
   * Populate the list of transaction statistic totals on mounting of the component.
   */
  componentDidMount() {
    this.getStatsTotal(true);
  }

  /**
   * Get the total stats for different type of transactions
   */
  getStatsTotal(isOnPageLoad = false) {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
      skeletonForLoading: isOnPageLoad ? true : false,
    }));

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/totals`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          skeletonForLoading: false,
        }));

        // the following need to be optimized as we should not be setting up state in a loop
        Object.entries(response.data).forEach(([processingAlertType, count]) => {
          this.setState((prevState) => ({
            ...prevState,
            [processingAlertType]: {
              ...prevState[processingAlertType],
              records: [],
              totalRecordCount: count,
              recordsShown: 9,
            },
          }));
        });

        if (isOnPageLoad) {
          // To remove the paysafe cash records and recalculate total number of failed PM payouts on pageload.
          this.getProcessingAlerts('ON_HOLD_TRANSACTIONS');
        } else {
          this.getProcessingAlerts(this.state.currentProcessingAlert, true);
        }
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Get a list of all processing alerts of o a particular type, only if the records haven't already been loaded.
   *
   * @param processingAlertType - The processing alert type to fetch records for.
   */
  getProcessingAlerts(processingAlertType, refresh = false) {
    this.setState((prevState) => ({
      ...prevState,
      currentProcessingAlert: processingAlertType,
    }));

    if (
      (this.state[processingAlertType]?.records.length === 0 &&
        this.state[processingAlertType]?.totalRecordCount !== 0) ||
      refresh
    ) {
      this.setState((prevState) => ({
        ...prevState,
        spinner: true,
        currentProcessingAlert: processingAlertType,
      }));

      axios
        .get(`${constants.REACT_APP_HOST_API_URL}/admin/stats/${processingAlertType}`, {
          headers: this.generateRequestHeaders(),
        })
        .then((response) => {
          let processingAlertRecords = response.data[processingAlertType];

          if (processingAlertType === 'FAILED_SCHEDULED_PAYMENTS') {
            let processingAlertRecords = response.data.FAILED_SCHEDULED_PAYMENTS;

            processingAlertRecords.forEach((failedScheduledPayment, key) => {
              let amount = 0;

              failedScheduledPayment.charges.forEach((data, key) => {
                amount += data.amount;
              });

              processingAlertRecords[key].amount = amount;
            });
          } else if (processingAlertType === 'ON_HOLD_TRANSACTIONS') {
            const processingAlertRecordsWithoutPaysafeCash = [];
            processingAlertRecords.forEach((processingAlertRecord) => {
              if (processingAlertRecord.paymentProviderId !== 'PAYSAFE_CASH') {
                processingAlertRecordsWithoutPaysafeCash.push(processingAlertRecord);
              }
            });
            processingAlertRecords = processingAlertRecordsWithoutPaysafeCash;
          }

          this.setState(
            (prevState) => ({
              ...prevState,
              spinner: false,
              [processingAlertType]: {
                ...prevState[processingAlertType],
                records: processingAlertRecords,
                totalRecordCount: processingAlertRecords.length,
              },
            }),
            () => {
              if (processingAlertType === 'NOT_VALIDATED_NSF_TRANSACTIONS') {
                //Default sort by create date
                this.sortTransactions(processingAlertType, 'createDate', null, 'DATE');
              }
            }
          );
        })
        .catch((error) => {
          this.handleValidation(error);
        });
    }
  }

  /**
   * Sets Fee profile for the selected payment transaction
   * @param {*} paymentTransaction
   */
  setPaymentTransactionFeeProfile(paymentTransaction) {
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/fee_profile/${paymentTransaction.feeProfileId}`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        paymentTransaction.feeProfile = response.data;

        this.setState((prevState) => ({
          ...prevState,
          paymentTransaction: {
            ...paymentTransaction,
            feeProfile: response.data,
          },
        }));
      })
      .catch((error) => {
        if (
          error.response?.data?.errorCode === 'RM_ERROR_RECORD_NOT_FOUND' &&
          error.response?.data?.message?.includes('TYPE_FEE_PROFILE')
        )
          error.response.data.message = 'Associated TYPE_FEE_PROFILE id not found';
        this.handleValidation(error);
      });
  }

  /**
   * Sets parent payment transaction of the selected transaction
   * @param {*} paymentTransaction
   */
  setParentPaymentTransaction(paymentTransaction) {
    getPaymentTransactionById(paymentTransaction.parentId, PAYMENT_TRANSACTION_QUERY)
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          parentPaymentTransaction: response.data?.records[0],
          validationList: [],
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Set payment transaction integration of the selected payment
   * @param {*} paymentTransaction
   */
  setIntegrationPaymentTransaction(paymentTransaction) {
    axios
      .get(`${constants.REACT_APP_HOST_INTEGRATION_URL}/transaction/${paymentTransaction.id}`, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        paymentTransaction.integration = response.data;

        this.setState((prevState) => ({
          ...prevState,
          paymentTransaction: {
            ...paymentTransaction,
            integration: response.data,
          },
        }));
      })
      .catch((error) => {
        if (
          error.response?.data?.errorCode === 'RM_ERROR_RECORD_NOT_FOUND' &&
          error.response?.data?.message?.includes('INTEGRATION_TRANSACTION')
        )
          error.response.data.message = 'Associated INTEGRATION_TRANSACTION id not found.';
        this.handleValidation(error);
      });
  }

  /**
   * Select a payment transaction record type from the time out transactions, chargebacks, chargeback reversals,
   * outstanding settlements, or outstanding disbursements lists.
   * @param {*} paymentTransaction
   */
  selectPaymentTransactionProcess(paymentTransaction) {
    //reset validation list in the case a new payment is viewed
    this.setState((prevState) => ({
      ...prevState,
      validationList: [],
    }));

    if (paymentTransaction.parentId) {
      this.setParentPaymentTransaction(paymentTransaction);
    }

    if (
      paymentTransaction.joins &&
      this.props.type !== 'TYPE_MANAGER' &&
      ((paymentTransaction.joins.co && paymentTransaction.joins.co.integrationId) ||
        (paymentTransaction.joins.pc && paymentTransaction.joins.pc.integrationId)) &&
      paymentTransaction.status === 'COMPLETE' &&
      (paymentTransaction.transactionType === 'PAYMENT' ||
        paymentTransaction.transactionType === 'NSF')
    ) {
      this.setIntegrationPaymentTransaction(paymentTransaction);
    }

    if (paymentTransaction.feeProfileId && this.props.type !== 'TYPE_MANAGER') {
      this.setPaymentTransactionFeeProfile(paymentTransaction);
    }

    this.setState((prevState) => ({
      ...prevState,
      paymentTransaction: {
        ...paymentTransaction,
      },
    }));

    $('#payment-transaction-reversals').modal('hide');
    $('#receipt-payment-transaction').modal('show');
  }

  /**
   * Open up a payment transaction as a modal or select a checkbox depending on what cell in the table was clicked
   * If a user clicked on the checkbox, add the id to selectedTransactionIds array
   * @param paymentTransaction - The payment transaction record selected.
   */
  selectPaymentTransaction(paymentTransaction, event = null) {
    if (event) {
      const { target } = event;
      const { attributes } = target;
      const isCheckbox = attributes?.getNamedItem('data-checkbox-cell')?.value;
      const isInputbox = attributes?.getNamedItem('data-input-cell')?.value;
      const isSelectbox = attributes?.getNamedItem('data-select-cell')?.value;
      if (isCheckbox) {
        if (target?.checked) {
          this.setState((prevState) => ({
            ...prevState,
            selectedTransactionIds: [...prevState.selectedTransactionIds, paymentTransaction.id],
          }));

          if(!this.state.selectedNsfTransactions[paymentTransaction.id]){

            this.state.selectedNsfTransactions[paymentTransaction.id] = {
              nsfFeeAmount: paymentTransaction?.joins?.co?.nsfFeeAmount,
              nsfFeePaymentMethodId: paymentTransaction?.paymentMethodId,
              nsfFeePaymentMethodType: paymentTransaction?.paymentMethodType};
          }

        } else if (target?.checked === false) {
          //remove transaction ID from the list
          const selectedTransactionIdsFiltered = this.state.selectedTransactionIds.filter(
            (transactionId) => transactionId !== paymentTransaction.id
          );
          this.setState((prevState) => ({
            ...prevState,
            selectedTransactionIds: selectedTransactionIdsFiltered,
          }));

          if(this.state.selectedNsfTransactions &&
              this.state.selectedNsfTransactions[paymentTransaction.id]){

            delete this.state.selectedNsfTransactions[paymentTransaction.id];
          }
        }

      } else if (isInputbox || isSelectbox) {

        //this.handleNsfFeeAmountChange(event)
        //continue
      } else {

        if(paymentTransaction?.transactionType === 'NSF' &&
            paymentTransaction?.nsfFeeChargingAttempts &&
            paymentTransaction.nsfFeeChargingAttempts > 0){

          this.selectNsfFeeTransactionProcess(paymentTransaction);

        } else {

          this.selectPaymentTransactionProcess(paymentTransaction);

        }
      }
    } else {
      this.selectPaymentTransactionProcess(paymentTransaction);
    }
  }

  /**
   * Select a scheduled payment record type from the failed scheduled payments list. Redirects the user to the
   * Transactions page with the scheduled payment receipt pre-populated.
   *
   * @param scheduledPayment - The scheduled payment record selected.
   */
  selectScheduledPayment(scheduledPayment) {
    this.props.history.push({
      pathname: '/admin/transactions',
      state: {
        scheduledPayment: scheduledPayment,
      },
    });
  }

  /**
   * Select a funds transfer record type from the failed EFTs or outstanding EFT settlements list. Redirects the user
   * to the EFT Management page with the EFT details pre-populated.
   *
   * @param fundsTransfer - The funds transfer record selected.
   */
  selectFundsTransfer(fundsTransfer) {
    this.props.history.push({
      pathname: '/admin/system/efts',
      state: {
        fundsTransferId: fundsTransfer.id,
      },
    });
  }

  /**
   * Search for a list of reversal payment transactions related to the transaction currently being viewed.
   */
  searchPaymentTransactionReversals() {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let paymentTransactionReversalQuery = {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          operator: 'EQUALS',
          fieldName: 'parentId',
          fieldValue: this.state.paymentTransaction.id,
        },
      ],
      joins: PAYMENT_TRANSACTION_QUERY.joins,
    };

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/search`,
        {
          orderBy: paymentTransactionReversalQuery.orderBy,
          orderByFields: paymentTransactionReversalQuery.orderByFields,
          conditionList: paymentTransactionReversalQuery.conditionList,
          joins: paymentTransactionReversalQuery.joins,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          paymentTransactionReversalList: response.data,
        }));

        $('#receipt-payment-transaction').modal('hide');
        $('#payment-transaction-reversals').modal('show');
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * @param - status - new status of the transaction
   */
  updateTransactionStatus(event, status) {
    event.preventDefault();

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let paymentTransaction = this.state.paymentTransaction;

    const params = {
      status: status,
      error: null,
    };

    if (
      this.state.paymentTransaction?.paymentProviderId === 'PAYSAFE_CASH' &&
      this.state.paymentTransaction?.status === 'COMPLETE' &&
      status === 'ONHOLD'
    ) {
      params.disbursementStatus = 'NOT_DISBURSED';
      params.settlementDate = null;
    }

    axios
      .patch(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`,
        params,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then(() => {
        this.getStatsTotal();
        $('#complete-payment-transaction').modal('hide');
        $('#fail-payment-transaction').modal('hide');
        $('#receipt-payment-transaction').modal('hide');
        // update the list here
      })
      .catch((error) => {
        this.handleValidation(error);
      });

    window.scrollTo(0, 0);
  }

  /**
   * Mark NSF transaction as invalid or valid
   * @param markAs - VALID or INVALID
   */
  markNSFTransactionValidOrInvalid(markAs, customNsfFeeAmount) {
    let transactionIds = {};
    if (this.state.selectedTransactionIds?.length) {
      transactionIds = this.state.selectedNsfTransactions;
    } else {

      if(this.state.paymentTransaction.source === 'NSF_FEE' && this.state.paymentTransaction?.nsfFeeAmount){

        transactionIds[this.state.paymentTransaction.parentId] = {
          nsfFeeAmount: this.state.paymentTransaction?.nsfFeeAmount,
          nsfFeePaymentMethodId: this.state.paymentTransaction?.paymentMethodId,
          nsfFeePaymentMethodType: this.state.paymentTransaction?.paymentMethodType};

      } else {

        transactionIds[this.state.paymentTransaction.id] = {
          nsfFeeAmount: this.state.paymentTransaction?.nsfFeeAmount,
          nsfFeePaymentMethodId: this.state.paymentTransaction?.paymentMethodId,
          nsfFeePaymentMethodType: this.state.paymentTransaction?.paymentMethodType};
      };
    }

    if(markAs === 'VALID' && customNsfFeeAmount > 0){

      Object.keys(transactionIds).forEach((selectedNsfTransactionKey) => {

        transactionIds[selectedNsfTransactionKey].nsfFeeAmount = customNsfFeeAmount;

      });
    }

    $('#mark-valid-nsf-transaction').modal('hide');
    $('#mark-invalid-nsf-transaction').modal('hide');
    $('#receipt-payment-transaction').modal('hide');

    this.startSpinner();
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/payment_transaction/batch_nsf_valid`,
        {
          transactionIds: transactionIds,
          valid: markAs === 'VALID',
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then(() => {
        this.stopSpinner();
        this.setCustomAlert('primary', 'saved');
        this.getProcessingAlerts('NOT_VALIDATED_NSF_TRANSACTIONS', true);

        if (this.state.selectedTransactionIds?.length) {
          this.setState((prevState) => ({
            ...prevState,
            selectedTransactionIds: [],
            selectAllTransactions: false,
            selectedNsfTransactions: {}
          }));
        }

        window.scrollTo(0, 0);
      })
      .catch((error) => {
        this.handleValidation(error);
        window.scrollTo(0, 0);
      });
  }

  /**
   * Mark NSF transaction as invalid or valid
   * @param markAs - VALID or INVALID
   */
  markChargebackTransactionLETUSProcessed() {

    let paymentTransaction = this.state.paymentTransaction;

    const params = {
      chargebackStatus: 'LETUS_PROCESSED'
    };

    this.startSpinner();
    axios
        .patch(
            `${constants.REACT_APP_HOST_API_URL}/payment_transaction/${paymentTransaction.id}/update`,
            params,
            {
              headers: this.generateRequestHeaders(),
            }
        )
        .then(() => {
          this.stopSpinner();
          this.setCustomAlert('primary', 'saved');
          this.getProcessingAlerts('CHARGE_BACKS', true);
          $('#mark-chargeback-LETUS-processed').modal('hide');

        })
        .catch((error) => {
          this.handleValidation(error);
        });

    window.scrollTo(0, 0);
  }

  /**
   * Handle select/unselect all transactions checkbox
   * If select all checkbox is currently unchecked, then mark it checked and select all the transactions and vice versa
   * @param {string} processingAlertType
   */
  handleSelectUnSelectAllTransactions(processingAlertType) {
    const newSelectAllStatus = !this.state.selectAllTransactions;

    let transactionIds = [];
    const transactions = this.state.searchQueryFilter
      ? this.state.filteredTransactions.records
      : this.state[processingAlertType]?.records;

    if (newSelectAllStatus) {
      transactions.forEach((transaction) => {
        transactionIds.push(transaction.id);

        if(!this.state.selectedNsfTransactions[transaction.id]){

          this.state.selectedNsfTransactions[transaction.id] = {
            nsfFeeAmount: transaction?.joins?.co?.nsfFeeAmount,
            nsfFeePaymentMethodId: transaction?.paymentMethodId,
            nsfFeePaymentMethodType: transaction?.paymentMethodType};
        }
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      selectedTransactionIds: transactionIds,
      selectAllTransactions: newSelectAllStatus,
    }));

    if(this.state.selectedNsfTransactions){

      Object.keys(this.state.selectedNsfTransactions).forEach((selectedNsfTransactionKey) => {

        if(!transactionIds.includes(selectedNsfTransactionKey)){

          delete this.state.selectedNsfTransactions[selectedNsfTransactionKey]
        }
      });
    }
  }

  /**
   * Update search query filter
   * @param {*} event
   */
  handleChangeSearchQueryFilter(event, processingAlertType) {
    event.persist();
    const searchQueryFilterValue = event?.target?.value;

    // filter transactions based on the search query
    const filteredTransactions = this.state[processingAlertType]?.records.filter((data) =>
      searchQueryFilterValue
        ? (data.joins?.c?.firstName + ' ' + data.joins?.c?.lastName)
            ?.toLowerCase()
            .includes(searchQueryFilterValue?.toLowerCase()?.trim()) ||
          data.joins?.co?.name
            ?.toLowerCase()
            .includes(searchQueryFilterValue?.toLowerCase()?.trim()) ||
          data.paymentProviderId
            ?.toLowerCase()
            .includes(searchQueryFilterValue?.toLowerCase()?.trim())
        : true
    );

    this.setState(
      (prevState) => ({
        ...prevState,
        searchQueryFilter: searchQueryFilterValue,
        filteredTransactions: {
          ...prevState[processingAlertType],
          records: filteredTransactions,
          count: filteredTransactions.count,
        },
      })
    );
  }

  /**
   * Sort transaction records when a user click on the column
   * @param {string} processingAlertType
   * @param {string} sortBy - name of the clicked column
   * @param {string} sortDirection
   * @param {string} colType
   * @returns
   */
  sortTransactions(processingAlertType, sortBy, sortDirection, colType) {
    const isQueryFilter = this.state.searchQueryFilter;
    const transactions = isQueryFilter
      ? this.state.filteredTransactions
      : this.state[processingAlertType];
    let sortedTransactionRecords = [];
    switch (colType) {
      case 'DATE':
        sortedTransactionRecords = transactions?.records?.sort((a, b) =>
          moment(a?.[sortBy]).diff(moment(b?.[sortBy]))
        );
        break;
      case 'STRING':
        sortedTransactionRecords = transactions?.records?.sort((a, b) => {
          const recordA = lodash.get(a, sortBy)?.toUpperCase();
          const recordB = lodash.get(b, sortBy)?.toUpperCase();
          if (recordA < recordB) {
            return sortDirection === 'ASC' ? -1 : 1;
          }
          if (recordA > recordB) {
            return sortDirection === 'ASC' ? 1 : -1;
          }
          return 0;
        });
        break;
      default:
        break;
    }

    if (isQueryFilter) {
      this.setState((prevState) => ({
        ...prevState,
        filteredTransactions: {
          ...prevState[processingAlertType],
          records: sortedTransactionRecords,
          count: sortedTransactionRecords.count,
        },
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [processingAlertType]: {
          ...prevState[processingAlertType],
          records: sortedTransactionRecords,
          count: sortedTransactionRecords.count,
        },
      }));
    }
  }

  /**
   * Handle change events on fields.
   *
   * @param event - The event container.
   */
  handleNsfFeeAmountChange(event){
    let selectedNsfTransactions = this.state.selectedNsfTransactions;
    selectedNsfTransactions[event.target.name].nsfFeeAmount = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      selectedNsfTransactions: selectedNsfTransactions
    }));
  }

  /**
   * Handle change events on fields.
   *
   * @param event - The event container.
   */
  handleNsfFeePaymentMethodChange(event){
    let selectedNsfTransactions = this.state.selectedNsfTransactions;
    selectedNsfTransactions[event.target.name].nsfFeePaymentMethodId = event.target.value;
    selectedNsfTransactions[event.target.name].nsfFeePaymentMethodType = 'TYPE_BANK_ACCOUNT';
    this.setState(prevState => ({
      ...prevState,
      selectedNsfTransactions: selectedNsfTransactions
    }));
  }

  selectNsfFeeTransactionProcess(paymentTransaction) {

    let nsfFeeTransactionQuery = {
      orderBy: 'DESC',
      orderByFields: ['createDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'source',
          operator: 'EQUALS',
          fieldValue: 'NSF_FEE'
        },
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: null,
          closeBrackets: null,
          fieldName: 'parentId',
          operator: 'EQUALS',
          fieldValue: paymentTransaction.id
        }
      ],
      joins: {}
    };

    axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search`, {

      orderBy: nsfFeeTransactionQuery.orderBy,
      orderByFields: nsfFeeTransactionQuery.orderByFields,
      conditionList: nsfFeeTransactionQuery.conditionList

    }, {
      headers: this.generateRequestHeaders()

    }).then(response => {

      if(response && response?.data && response?.data.records && response?.data.records.length > 0){

        let nsfFeeTransaction = response.data.records[0];
        nsfFeeTransaction['nsfFeeAmount'] = paymentTransaction?.joins?.co?.nsfFeeAmount;
        this.selectPaymentTransactionProcess(nsfFeeTransaction)

      } else {

        this.selectPaymentTransactionProcess(paymentTransaction);
      }

    }).catch(error => {
      this.selectPaymentTransactionProcess(paymentTransaction);
    });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The administrator dashboard component.
   */
  render() {
    const { formatMessage } = this.props.intl;

    return (
      <div className='content-block'>
        {/* <Spinner visible={this.state.spinner} /> */}

        <div className='container'>
          <Breadcrumb parentPage='Dashboard' />
          <ReceiptPaymentTransactionActionsModal
            userType={this.props.type}
            validationList={this.state.validationList}
            paymentTransaction={this.state.paymentTransaction}
            paymentTransactionReversalList={this.state.paymentTransactionReversalList}
            getStatsTotal={this.getStatsTotal}
            selectPaymentTransaction={this.selectPaymentTransaction}
            markNSFTransactionValidOrInvalid={this.markNSFTransactionValidOrInvalid}
            markChargebackTransactionLETUSProcessed={this.markChargebackTransactionLETUSProcessed}
          />
          <ReceiptPaymentTransaction
            history={this.props.history}
            userType={this.props.type}
            displayFeeAmount={true}
            displayAdvanced={true}
            paymentTransaction={this.state.paymentTransaction}
            parentPaymentTransaction={this.state.parentPaymentTransaction}
          >
            <ReceiptPaymentTransactionActions
              userType={this.props.type}
              paymentTransaction={this.state.paymentTransaction}
              displayAdvanced={true}
              handleValidation={this.handleValidation}
              userSession={this.props.userSession}
              searchPaymentTransactionReversals={this.searchPaymentTransactionReversals}
              updateTransactionStatus={this.updateTransactionStatus}
            />
          </ReceiptPaymentTransaction>
          <Alert validationList={this.state.validationList} />
          <div className='accordion border-custom-light mb-4 rounded' id='processing-alerts'>
            <div className='card-header bg-dark text-white'>Processing Alerts</div>
            <div className='position-relative'>
              <SkeletonLoader
                visible={this.state.spinner}
                skeletonForLoading={this.state.skeletonForLoading}
              />
              {this.state.NOT_VALIDATED_NSF_TRANSACTIONS && (
                <NSFTransactionsNotValidated
                  formatMessage={formatMessage}
                  transactions={
                    this.state.searchQueryFilter
                      ? this.state.filteredTransactions
                      : this.state.NOT_VALIDATED_NSF_TRANSACTIONS
                  }
                  selectAllTransactions={this.state.selectAllTransactions}
                  selectedTransactionIds={this.state.selectedTransactionIds}
                  searchQueryFilter={this.state.searchQueryFilter}
                  handleChangeSearchQueryFilter={this.handleChangeSearchQueryFilter}
                  selectPaymentTransaction={this.selectPaymentTransaction}
                  handleSelectUnSelectAllTransactions={this.handleSelectUnSelectAllTransactions}
                  getProcessingAlerts={this.getProcessingAlerts}
                  sortTransactions={this.sortTransactions}
                  selectedNsfTransactions={this.state.selectedNsfTransactions}
                  handleNsfFeeAmountChange={this.handleNsfFeeAmountChange}
                  handleNsfFeePaymentMethodChange={this.handleNsfFeePaymentMethodChange}
                />
              )}
              {this.state.TIMED_OUT_TRANSACTIONS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#timed-out-transactions'
                    aria-expanded='true'
                    aria-controls='timed-out-transactions'
                    onClick={() => this.getProcessingAlerts('TIMED_OUT_TRANSACTIONS')}
                  >
                    <div className='row align-items-center'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount}
                        </span>{' '}
                        Timed Out Transactions{' '}
                        <small className='text-muted text-uppercase'>/ All Time</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='timed-out-transactions'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.props.type === 'TYPE_ADMIN' && (
                      <div className='col-4 float-right px-0'>
                        <FieldSelect
                          id='transactionProcessorFilter'
                          labelColumns='0'
                          fieldColumns='12'
                          parent={this}
                          value={this.state.transactionProcessorFilter}
                          className='float-right'
                        >
                          <option value=''>All Processors</option>
                          <option value='PAYSAFE_CASH'>Paysafe Cash</option>
                          <option value='PAYSAFE_SPLIT_PAY'>Paysafe Split-Pay</option>
                          <option value='PAYPAL'>PayPal</option>
                          <option value='GEOSWIFT'>Geoswift</option>
                          <option value='LOADHUB'>Loadhub</option>
                          <option value='RENTMOOLA_PP'>Letus</option>
                          <option value='BMO'>BMO</option>
                        </FieldSelect>
                      </div>
                    )}
                    {this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.TIMED_OUT_TRANSACTIONS.records && (
                              <React.Fragment>
                                {this.state.TIMED_OUT_TRANSACTIONS.records
                                  .filter((record) => {
                                    if (this.state.transactionProcessorFilter) {
                                      return (
                                        record.paymentProviderId ===
                                        this.state.transactionProcessorFilter
                                      );
                                    } else {
                                      return true;
                                    }
                                  })
                                  .map((data, key) => {
                                    if (key <= this.state.TIMED_OUT_TRANSACTIONS.recordsShown) {
                                      return (
                                        <tr
                                          key={key}
                                          onClick={() => this.selectPaymentTransaction(data)}
                                          className='c-pointer'
                                        >
                                          <td>
                                            <div className=''>
                                              <Moment format='MMM DD, YYYY'>
                                                {data.createDate}
                                              </Moment>
                                            </div>
                                          </td>
                                          <td>
                                            <div className={data.amount < 0 ? 'text-danger' : ''}>
                                              {data.amount && (
                                                <FormattedNumber
                                                  value={data.amount + data.feeAmount}
                                                  style={`currency`}
                                                  currency={data.currency}
                                                />
                                              )}
                                            </div>
                                          </td>
                                          <td>
                                            {data.joins && data.joins.p && data.joins.pl && (
                                              <div className=''>
                                                {data.joins.pl.unit
                                                  ? data.joins.pl.unit + ' - '
                                                  : ''}
                                                {data.joins.p.street1}
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            {data.joins && data.joins.c && (
                                              <div className=''>
                                                {data.joins.c.firstName} {data.joins.c.lastName}
                                              </div>
                                            )}
                                          </td>
                                          <td>
                                            <div className=''>
                                              <FormattedMessage
                                                id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            {data.status && (
                                              <div className='text-nowrap'>
                                                <FontAwesomeIcon
                                                  icon={['fas', 'circle']}
                                                  className={`fa-fw small ${formatMessage({
                                                    id:
                                                      'enum.transactionStatus.' +
                                                      data.status +
                                                      '.class',
                                                  })}`}
                                                />
                                                <span className='ml-1'>
                                                  <FormattedMessage
                                                    id={'enum.transactionStatus.' + data.status}
                                                  />
                                                </span>
                                              </div>
                                            )}
                                          </td>
                                        </tr>
                                      );
                                    }

                                    return null;
                                  })}

                                {this.state.TIMED_OUT_TRANSACTIONS.recordsShown <
                                  this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          TIMED_OUT_TRANSACTIONS: {
                                            ...prevState.TIMED_OUT_TRANSACTIONS,
                                            recordsShown:
                                              prevState.TIMED_OUT_TRANSACTIONS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.TIMED_OUT_TRANSACTIONS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Awesome! We have no timed out transactions. Treat yourself to a
                                beer!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.CHARGE_BACKS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#charge-backs'
                    aria-expanded='true'
                    aria-controls='charge-backs'
                    onClick={() => this.getProcessingAlerts('CHARGE_BACKS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.CHARGE_BACKS.totalRecordCount === 0 ? 'dark' : 'warning'
                          } va-b mr-1`}
                        >
                          {this.state.CHARGE_BACKS.totalRecordCount}
                        </span>{' '}
                        Chargebacks{' '}
                        <small className='text-muted text-uppercase'>/ Last 7 Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='charge-backs' className='collapse' data-parent='#processing-alerts'>
                    {this.state.CHARGE_BACKS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.CHARGE_BACKS.records && (
                              <React.Fragment>
                                {this.state.CHARGE_BACKS.records.map((data, key) => {
                                  if (key <= this.state.CHARGE_BACKS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.CHARGE_BACKS.recordsShown <
                                  this.state.CHARGE_BACKS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          CHARGE_BACKS: {
                                            ...prevState.CHARGE_BACKS,
                                            recordsShown: prevState.CHARGE_BACKS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.CHARGE_BACKS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Nice! No chargebacks in the last 7 days. Next round's on me!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.DISPUTE_LOST && (
                  <div className='card mb-0'>
                    <div
                        className='card-header bg-secondary text-dark collapsed c-pointer'
                        data-toggle='collapse'
                        data-target='#dispute-lost'
                        aria-expanded='true'
                        aria-controls='dispute-lost'
                        onClick={() => this.getProcessingAlerts('DISPUTE_LOST')}
                    >
                      <div className='row'>
                        <div className='col'>
                        <span
                            className={`badge badge-pill badge-${
                                this.state.DISPUTE_LOST.totalRecordCount === 0 ? 'dark' : 'warning'
                            } va-b mr-1`}
                        >
                          {this.state.DISPUTE_LOST.totalRecordCount}
                        </span>{' '}
                          Chargebacks from lost disputes{' '}
                          <small className='text-muted text-uppercase'>/ Last 7 Days</small>
                        </div>
                        <div className='col'>
                          <div className='float-right'>
                            <i className='fas'> </i>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id='dispute-lost' className='collapse' data-parent='#processing-alerts'>
                      {this.state.DISPUTE_LOST.totalRecordCount > 0 && (
                          <div className='card-body card-body-table'>
                            <table className='table table-hover table-bordered'>
                              <thead>
                              <tr>
                                <th width='16%'>Date</th>
                                <th width='16%'>Amount</th>
                                <th width='16%'>Property</th>
                                <th width='16%'>Tenant</th>
                                <th width='16%'>Processor</th>
                                <th width='16%'>Status</th>
                              </tr>
                              </thead>
                              <tbody>
                              {this.state.DISPUTE_LOST.records && (
                                  <React.Fragment>
                                    {this.state.DISPUTE_LOST.records.map((data, key) => {
                                      if (key <= this.state.DISPUTE_LOST.recordsShown) {
                                        return (
                                            <tr
                                                key={key}
                                                onClick={() => this.selectPaymentTransaction(data)}
                                                className='c-pointer'
                                            >
                                              <td>
                                                <div className=''>
                                                  <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                                </div>
                                              </td>
                                              <td>
                                                <div className={data.amount < 0 ? 'text-danger' : ''}>
                                                  {data.amount && (
                                                      <FormattedNumber
                                                          value={data.amount + data.feeAmount}
                                                          style={`currency`}
                                                          currency={data.currency}
                                                      />
                                                  )}
                                                </div>
                                              </td>
                                              <td>
                                                {data.joins && data.joins.p && data.joins.pl && (
                                                    <div className=''>
                                                      {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                                      {data.joins.p.street1}
                                                    </div>
                                                )}
                                              </td>
                                              <td>
                                                {data.joins && data.joins.c && (
                                                    <div className=''>
                                                      {data.joins.c.firstName} {data.joins.c.lastName}
                                                    </div>
                                                )}
                                              </td>
                                              <td>
                                                <div className=''>
                                                  <FormattedMessage
                                                      id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                                  />
                                                </div>
                                              </td>
                                              <td>
                                                {data.status && (
                                                    <div className='text-nowrap'>
                                                      <FontAwesomeIcon
                                                          icon={['fas', 'circle']}
                                                          className={`fa-fw small ${formatMessage({
                                                            id:
                                                                'enum.transactionStatus.' +
                                                                data.status +
                                                                '.class',
                                                          })}`}
                                                      />
                                                      <span className='ml-1'>
                                                <FormattedMessage
                                                    id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                                    </div>
                                                )}
                                              </td>
                                            </tr>
                                        );
                                      }

                                      return null;
                                    })}

                                    {this.state.DISPUTE_LOST.recordsShown <
                                        this.state.DISPUTE_LOST.totalRecordCount && (
                                            <tr className='c-pointer'>
                                              <td
                                                  colSpan='6'
                                                  className='text-center'
                                                  onClick={() =>
                                                      this.setState((prevState) => ({
                                                        DISPUTE_LOST: {
                                                          ...prevState.DISPUTE_LOST,
                                                          recordsShown: prevState.DISPUTE_LOST.recordsShown + 10,
                                                        },
                                                      }))
                                                  }
                                              >
                                                <div className='btn-link'>
                                                  Load more records{' '}
                                                  <FontAwesomeIcon
                                                      icon={['fas', 'caret-down']}
                                                      className='fa-fw'
                                                  />
                                                </div>
                                              </td>
                                            </tr>
                                        )}
                                  </React.Fragment>
                              )}
                              </tbody>
                            </table>
                          </div>
                      )}
                      {this.state.DISPUTE_LOST.totalRecordCount === 0 && (
                          <div className='card-body'>
                            <div className='row justify-content-center'>
                              <div className='col-10'>
                                <div className='text-center text-muted'>
                                  <FontAwesomeIcon
                                      icon={['fal', 'smile-beam']}
                                      className='fa-fw mb-4'
                                      size='5x'
                                  />
                                </div>
                                <p className='text-center text-muted mb-0'>
                                  <small>
                                    Nice! No chargebacks in the last 7 days. Next round's on me!
                                  </small>
                                </p>
                              </div>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
              )}

              {this.state.CHARGE_BACK_REVERSALS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#charge-back-reversals'
                    aria-expanded='true'
                    aria-controls='charge-back-reversals'
                    onClick={() => this.getProcessingAlerts('CHARGE_BACK_REVERSALS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.CHARGE_BACK_REVERSALS.totalRecordCount === 0
                              ? 'dark'
                              : 'warning'
                          } va-b mr-1`}
                        >
                          {this.state.CHARGE_BACK_REVERSALS.totalRecordCount}
                        </span>{' '}
                        Chargeback Reversals{' '}
                        <small className='text-muted text-uppercase'>/ Last 7 Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='charge-back-reversals'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.CHARGE_BACK_REVERSALS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.CHARGE_BACK_REVERSALS.records && (
                              <React.Fragment>
                                {this.state.CHARGE_BACK_REVERSALS.records.map((data, key) => {
                                  if (key <= this.state.CHARGE_BACK_REVERSALS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.CHARGE_BACK_REVERSALS.recordsShown <
                                  this.state.CHARGE_BACK_REVERSALS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          CHARGE_BACK_REVERSALS: {
                                            ...prevState.CHARGE_BACK_REVERSALS,
                                            recordsShown:
                                              prevState.CHARGE_BACK_REVERSALS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.CHARGE_BACK_REVERSALS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Tremendous! No chargeback reversals in the last 7 days. Breathe a
                                sigh of relief!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.NOT_SETTLED_TRANSACTIONS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#not-settled-transactions'
                    aria-expanded='true'
                    aria-controls='not-settled-transactions'
                    onClick={() => this.getProcessingAlerts('NOT_SETTLED_TRANSACTIONS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount}
                        </span>{' '}
                        Outstanding Settlements{' '}
                        <small className='text-muted text-uppercase'>/ Over 5 Business Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='not-settled-transactions'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.NOT_SETTLED_TRANSACTIONS.records && (
                              <React.Fragment>
                                {this.state.NOT_SETTLED_TRANSACTIONS.records.map((data, key) => {
                                  if (key <= this.state.NOT_SETTLED_TRANSACTIONS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.NOT_SETTLED_TRANSACTIONS.recordsShown <
                                  this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          NOT_SETTLED_TRANSACTIONS: {
                                            ...prevState.NOT_SETTLED_TRANSACTIONS,
                                            recordsShown:
                                              prevState.NOT_SETTLED_TRANSACTIONS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.NOT_SETTLED_TRANSACTIONS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Woot! We have no outstanding settlements over 5 business days. Time
                                to party!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.NOT_DISBURSED_TRANSACTIONS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#not-disbursed-transactions'
                    aria-expanded='true'
                    aria-controls='not-disbursed-transactions'
                    onClick={() => this.getProcessingAlerts('NOT_DISBURSED_TRANSACTIONS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount}
                        </span>{' '}
                        Outstanding Disbursements{' '}
                        <small className='text-muted text-uppercase'>/ Over 5 Business Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='not-disbursed-transactions'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.NOT_DISBURSED_TRANSACTIONS.records && (
                              <React.Fragment>
                                {this.state.NOT_DISBURSED_TRANSACTIONS.records.map((data, key) => {
                                  if (key <= this.state.NOT_DISBURSED_TRANSACTIONS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.NOT_DISBURSED_TRANSACTIONS.recordsShown <
                                  this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          NOT_DISBURSED_TRANSACTIONS: {
                                            ...prevState.NOT_DISBURSED_TRANSACTIONS,
                                            recordsShown:
                                              prevState.NOT_DISBURSED_TRANSACTIONS.recordsShown +
                                              10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.NOT_DISBURSED_TRANSACTIONS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Outstanding! We have no outstanding disbursements. Treat yourself to
                                an ice cream bar!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.FAILED_SCHEDULED_PAYMENTS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#failed-scheduled-payments'
                    aria-expanded='true'
                    aria-controls='failed-scheduled-payments'
                    onClick={() => this.getProcessingAlerts('FAILED_SCHEDULED_PAYMENTS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount}
                        </span>{' '}
                        Failed Scheduled Payments{' '}
                        <small className='text-muted text-uppercase'>/ Last 2 Business Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='failed-scheduled-payments'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.FAILED_SCHEDULED_PAYMENTS.records && (
                              <React.Fragment>
                                {this.state.FAILED_SCHEDULED_PAYMENTS.records.map((data, key) => {
                                  if (key <= this.state.FAILED_SCHEDULED_PAYMENTS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectScheduledPayment(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount}
                                                style={`currency`}
                                                currency='USD'
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.FAILED_SCHEDULED_PAYMENTS.recordsShown <
                                  this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          FAILED_SCHEDULED_PAYMENTS: {
                                            ...prevState.FAILED_SCHEDULED_PAYMENTS,
                                            recordsShown:
                                              prevState.FAILED_SCHEDULED_PAYMENTS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.FAILED_SCHEDULED_PAYMENTS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Incredible! We have no failed scheduled payments in the last 2
                                business days. Rejoice!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.FAILED_SAAS_PAYMENTS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#failed-saas-payments'
                    aria-expanded='true'
                    aria-controls='failed-saas-payments'
                    onClick={() => this.getProcessingAlerts('FAILED_SAAS_PAYMENTS')}
                  >
                    <div className='row align-items-center'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.FAILED_SAAS_PAYMENTS.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.FAILED_SAAS_PAYMENTS.totalRecordCount}
                        </span>{' '}
                        Failed Receivable Payments{' '}
                        <small className='text-muted text-uppercase'>/ Last 7 Days</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='failed-saas-payments'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.FAILED_SAAS_PAYMENTS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.FAILED_SAAS_PAYMENTS.records && (
                              <React.Fragment>
                                {this.state.FAILED_SAAS_PAYMENTS.records.map((data, key) => {
                                  if (key <= this.state.FAILED_SAAS_PAYMENTS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.FAILED_SAAS_PAYMENTS.recordsShown <
                                  this.state.FAILED_SAAS_PAYMENTS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          FAILED_SAAS_PAYMENTS: {
                                            ...prevState.FAILED_SAAS_PAYMENTS,
                                            recordsShown:
                                              prevState.FAILED_SAAS_PAYMENTS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.FAILED_SAAS_PAYMENTS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Amazing! There are no failed payments to us in the last 7 days.
                                Lunch is on me!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.FAILED_EFTS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#failed-efts'
                    aria-expanded='true'
                    aria-controls='failed-efts'
                    onClick={() => this.getProcessingAlerts('FAILED_EFTS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.FAILED_EFTS.totalRecordCount === 0 ? 'dark' : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.FAILED_EFTS.totalRecordCount}
                        </span>{' '}
                        Failed EFTs <small className='text-muted text-uppercase'>/ All Time</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='failed-efts' className='collapse' data-parent='#processing-alerts'>
                    {this.state.FAILED_EFTS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='14%'>Date</th>
                              <th width='14%'>Settlement</th>
                              <th width='14%'>Company</th>
                              <th width='14%'>Bank Account</th>
                              <th width='14%'>Merchant Account</th>
                              <th width='14%'>Amount</th>
                              <th width='14%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.FAILED_EFTS.records && (
                              <React.Fragment>
                                {this.state.FAILED_EFTS.records.map((data, key) => {
                                  if (key <= this.state.FAILED_EFTS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectFundsTransfer(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className=''>
                                            {data.settlementDate && (
                                              <Moment format='MMM DD, YYYY'>
                                                {data.settlementDate}
                                              </Moment>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && (
                                            <div className=''>{data.joins.c.name}</div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>******{data.last4}</div>
                                        </td>
                                        <td>
                                          {data.joins && (
                                            <div className=''>{data.joins.ma.accountNumber}</div>
                                          )}
                                        </td>
                                        <td>
                                          {data.amount && (
                                            <div className={data.amount < 0 ? 'text-danger' : ''}>
                                              <FormattedNumber
                                                value={data.amount}
                                                style={`currency`}
                                                currency={
                                                  data.joins ? data.joins.ma.currency : 'USD'
                                                }
                                              />
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.fundsTransfer.eftStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.fundsTransfer.eftStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.FAILED_EFTS.recordsShown <
                                  this.state.FAILED_EFTS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          FAILED_EFTS: {
                                            ...prevState.FAILED_EFTS,
                                            recordsShown: prevState.FAILED_EFTS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.FAILED_EFTS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>Nice! We have no failed EFTs. Let's celebrate!</small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.NOT_SETTLED_EFTS && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#not-settled-efts'
                    aria-expanded='true'
                    aria-controls='not-settled-efts'
                    onClick={() => this.getProcessingAlerts('NOT_SETTLED_EFTS')}
                  >
                    <div className='row'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.NOT_SETTLED_EFTS.totalRecordCount === 0 ? 'dark' : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.NOT_SETTLED_EFTS.totalRecordCount}
                        </span>{' '}
                        Outstanding EFT Settlements{' '}
                        <small className='text-muted text-uppercase'>/ OVER 3 BUSINESS DAYS</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id='not-settled-efts' className='collapse' data-parent='#processing-alerts'>
                    {this.state.NOT_SETTLED_EFTS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='14%'>Date</th>
                              <th width='14%'>Settlement</th>
                              <th width='14%'>Company</th>
                              <th width='14%'>Bank Account</th>
                              <th width='14%'>Merchant Account</th>
                              <th width='14%'>Amount</th>
                              <th width='14%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.NOT_SETTLED_EFTS.records && (
                              <React.Fragment>
                                {this.state.NOT_SETTLED_EFTS.records.map((data, key) => {
                                  if (key <= this.state.NOT_SETTLED_EFTS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectFundsTransfer(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className=''>
                                            {data.settlementDate && (
                                              <Moment format='MMM DD, YYYY'>
                                                {data.settlementDate}
                                              </Moment>
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && (
                                            <div className=''>{data.joins.c.name}</div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>******{data.last4}</div>
                                        </td>
                                        <td>
                                          {data.joins && (
                                            <div className=''>{data.joins.ma.accountNumber}</div>
                                          )}
                                        </td>
                                        <td>
                                          {data.amount && (
                                            <div className={data.amount < 0 ? 'text-danger' : ''}>
                                              <FormattedNumber
                                                value={data.amount}
                                                style={`currency`}
                                                currency={
                                                  data.joins ? data.joins.ma.currency : 'USD'
                                                }
                                              />
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.fundsTransfer.eftStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.fundsTransfer.eftStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.NOT_SETTLED_EFTS.recordsShown <
                                  this.state.NOT_SETTLED_EFTS.totalRecordCount && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          NOT_SETTLED_EFTS: {
                                            ...prevState.NOT_SETTLED_EFTS,
                                            recordsShown:
                                              prevState.NOT_SETTLED_EFTS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.NOT_SETTLED_EFTS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Booya! We have no outstanding EFT settlements over 3 business days.
                                Let's get crackin'!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.ON_HOLD_TRANSACTIONS && this.state.showOnHoldTransactions && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#on-hold-transactions'
                    aria-expanded='true'
                    aria-controls='on-hold-transactions'
                    onClick={() => this.getProcessingAlerts('ON_HOLD_TRANSACTIONS')}
                  >
                    <div className='row align-items-center'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.ON_HOLD_TRANSACTIONS?.records.length === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.ON_HOLD_TRANSACTIONS?.records.length}
                        </span>{' '}
                        Failed PM Payouts{' '}
                        <small className='text-muted text-uppercase'>/ All Time</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='on-hold-transactions'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.ON_HOLD_TRANSACTIONS.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='16%'>Date</th>
                              <th width='16%'>Amount</th>
                              <th width='16%'>Property</th>
                              <th width='16%'>Tenant</th>
                              <th width='16%'>Processor</th>
                              <th width='16%'>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.ON_HOLD_TRANSACTIONS.records && (
                              <React.Fragment>
                                {this.state.ON_HOLD_TRANSACTIONS.records.map((data, key) => {
                                  if (key <= this.state.ON_HOLD_TRANSACTIONS.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && data.joins.pl && (
                                            <div className=''>
                                              {data.joins.pl.unit ? data.joins.pl.unit + ' - ' : ''}
                                              {data.joins.p.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins.c.firstName} {data.joins.c.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <FormattedMessage
                                              id={`enum.paymentProviderId.${data.paymentProviderId}`}
                                            />
                                          </div>
                                        </td>
                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.status +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={'enum.transactionStatus.' + data.status}
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.ON_HOLD_TRANSACTIONS.recordsShown <
                                  this.state.ON_HOLD_TRANSACTIONS?.records.length && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          ON_HOLD_TRANSACTIONS: {
                                            ...prevState.ON_HOLD_TRANSACTIONS,
                                            recordsShown:
                                              prevState.ON_HOLD_TRANSACTIONS.recordsShown + 10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.ON_HOLD_TRANSACTIONS.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                Awesome! We have no failed PM payouts. Treat yourself to a beer!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {this.state.TENANT_MARKETPLACE_EXPIRED && (
                <div className='card mb-0'>
                  <div
                    className='card-header bg-secondary text-dark collapsed c-pointer'
                    data-toggle='collapse'
                    data-target='#tenant-marketplace-transactions'
                    aria-expanded='true'
                    aria-controls='tenant-marketplace-transactions'
                    onClick={() => this.getProcessingAlerts('TENANT_MARKETPLACE_EXPIRED')}
                  >
                    <div className='row align-items-center'>
                      <div className='col'>
                        <span
                          className={`badge badge-pill badge-${
                            this.state.TENANT_MARKETPLACE_EXPIRED?.totalRecordCount === 0
                              ? 'dark'
                              : 'danger'
                          } va-b mr-1`}
                        >
                          {this.state.TENANT_MARKETPLACE_EXPIRED?.totalRecordCount}
                        </span>{' '}
                        Tenant Market Place Expired{' '}
                        <small className='text-muted text-uppercase'>/ All Time</small>
                      </div>
                      <div className='col'>
                        <div className='float-right'>
                          <i className='fas'> </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id='tenant-marketplace-transactions'
                    className='collapse'
                    data-parent='#processing-alerts'
                  >
                    {this.state.TENANT_MARKETPLACE_EXPIRED.totalRecordCount > 0 && (
                      <div className='card-body card-body-table'>
                        <table className='table table-hover table-bordered'>
                          <thead>
                            <tr>
                              <th width='9%'>Create Date</th>
                              <th width='9%'>Amount</th>
                              <th width='9%'>Tenant</th>
                              <th width='10%'>Tenant Email</th>
                              <th width='9%'>Property Address</th>
                              <th width='9%'>Company</th>
                              <th width='9%'>Property Manager</th>
                              <th width='9%'>PM Email</th>
                              <th width='9%'>PM Phone</th>
                              <th width='9%'>Payout Status</th>
                              <th width='9%'>Update Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.TENANT_MARKETPLACE_EXPIRED.records && (
                              <React.Fragment>
                                {this.state.TENANT_MARKETPLACE_EXPIRED.records.map((data, key) => {
                                  if (key <= this.state.TENANT_MARKETPLACE_EXPIRED.recordsShown) {
                                    return (
                                      <tr
                                        key={key}
                                        onClick={() => this.selectPaymentTransaction(data)}
                                        className='c-pointer'
                                      >
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.createDate}</Moment>
                                          </div>
                                        </td>
                                        <td>
                                          <div className={data.amount < 0 ? 'text-danger' : ''}>
                                            {data.amount && (
                                              <FormattedNumber
                                                value={data.amount + data.feeAmount}
                                                style={`currency`}
                                                currency={data.currency}
                                              />
                                            )}
                                          </div>
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className=''>
                                              {data.joins?.c?.firstName} {data.joins?.c?.lastName}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.c && (
                                            <div className='text-break'>{data.joins?.c?.email}</div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.p && (
                                            <div className=''>
                                              {data.joins?.p?.propertyName +
                                                ' ' +
                                                data.joins?.p?.street1}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.co && (
                                            <div className=''>{data.joins?.co?.name}</div>
                                          )}
                                        </td>

                                        <td>
                                          {data.joins && data.joins.pm && (
                                            <div className=''>
                                              {`${data.joins?.pm?.firstName} ${data.joins?.pm?.lastName}`}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.pm && (
                                            <div className='text-break'>
                                              {data.joins?.pm?.email}
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          {data.joins && data.joins.pm && (
                                            <div className=''>{data.joins?.pm?.phone}</div>
                                          )}
                                        </td>

                                        <td>
                                          {data.status && (
                                            <div className='text-nowrap'>
                                              <FontAwesomeIcon
                                                icon={['fas', 'circle']}
                                                className={`fa-fw small ${formatMessage({
                                                  id:
                                                    'enum.transactionStatus.' +
                                                    data.payoutStatus +
                                                    '.class',
                                                })}`}
                                              />
                                              <span className='ml-1'>
                                                <FormattedMessage
                                                  id={
                                                    'enum.transactionStatus.' +
                                                    (data.payoutStatus || 'NA')
                                                  }
                                                />
                                              </span>
                                            </div>
                                          )}
                                        </td>
                                        <td>
                                          <div className=''>
                                            <Moment format='MMM DD, YYYY'>{data.updateDate}</Moment>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }

                                  return null;
                                })}

                                {this.state.TENANT_MARKETPLACE_EXPIRED.recordsShown <
                                  this.state.TENANT_MARKETPLACE_EXPIRED?.records.length && (
                                  <tr className='c-pointer'>
                                    <td
                                      colSpan='6'
                                      className='text-center'
                                      onClick={() =>
                                        this.setState((prevState) => ({
                                          TENANT_MARKETPLACE_EXPIRED: {
                                            ...prevState.TENANT_MARKETPLACE_EXPIRED,
                                            recordsShown:
                                              prevState.TENANT_MARKETPLACE_EXPIRED.recordsShown +
                                              10,
                                          },
                                        }))
                                      }
                                    >
                                      <div className='btn-link'>
                                        Load more records{' '}
                                        <FontAwesomeIcon
                                          icon={['fas', 'caret-down']}
                                          className='fa-fw'
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {this.state.TENANT_MARKETPLACE_EXPIRED.totalRecordCount === 0 && (
                      <div className='card-body'>
                        <div className='row justify-content-center'>
                          <div className='col-10'>
                            <div className='text-center text-muted'>
                              <FontAwesomeIcon
                                icon={['fal', 'smile-beam']}
                                className='fa-fw mb-4'
                                size='5x'
                              />
                            </div>
                            <p className='text-center text-muted mb-0'>
                              <small>
                                We have no expired Tenant Marketplace transactions at this time!
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='card'>
            <div className='card-header'>Reports</div>
            <div className='card-body card-body-list'>
              <div className='list-group'>
                <NavListItem
                  path='/admin/dashboard/revenue'
                  iconType='far'
                  iconName='chart-bar'
                  name='Revenue'
                  size='large'
                  description='View revenue processing totals on a year-by-year basis.'
                />
                <NavListItem
                  path='/admin/dashboard/processing'
                  iconType='far'
                  iconName='chart-pie'
                  name='Processing'
                  size='large'
                  description='View all processing totals by payment type.'
                />
                <NavListItem
                  path='/admin/dashboard/entityDetails'
                  iconType='far'
                  iconName='chart-bar'
                  name='Entity Details'
                  size='large'
                  description='View entity details.'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Dashboard);
