import React, { Component } from 'react';

class SkeletonLoader extends Component {

    /**
     * 
     * @param {*} skeltonRowFor 
     * @returns skeltonRowFor body by default
     */
    renderSkeltonRows(skeltonRowFor) {
        const rows = [];
        const length = skeltonRowFor === 'title' ? this.props.titleRows : this.props.bodyRows;
        for (let i = 0; i < length; i++) {
            rows.push(
                <div className={`skeleton-loader-bar mt-4 ${skeltonRowFor === 'title' ? 'w-25' : 'w-100'}`} role="status" key={i}>
                    <span className="sr-only">Loading...</span>
                </div> 
            )
        }
        return rows;
    }

    /**
     * Render the component.
     *
     * @returns {*} - A standard loading spinner that disables any user clicks on the site until it is hidden. Used for
     * endpoint calls that make take several seconds to return a response.
     */
    render() {

        return(
            <>
                { this.props.visible &&
                    (   !this.props.skeletonForLoading ?
                        <div className={`skeleton-loader skeleton-spinner-container`}>
                            <div className="spinner-border skeleton-spinner text-light" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> :
                        <div className="skeleton-loader-container" style={{zIndex:1}}>
                            {this.props.displayHeader ? <div className="card-header bg-dark p-4"></div> : null}
                            <div className={`skeleton-loader`}>
                                {this.renderSkeltonRows('title')}
                                {this.renderSkeltonRows('body')}
                            </div>
                        </div>
                        
                    )
                }
            </>
        )
    };
}

SkeletonLoader.defaultProps = {
    visible: false,
    titleRows: 0,
    bodyRows: 6,
};

export default SkeletonLoader;