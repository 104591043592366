const ProvinceCollection = [
  { value: 'BC', text: 'British Columbia' },
  { value: 'AB', text: 'Alberta' },
  { value: 'MB', text: 'Manitoba' },
  { value: 'NB', text: 'New Brunswick' },
  { value: 'NL', text: 'Newfoundland' },
  { value: 'NS', text: 'Nova Scotia' },
  { value: 'NT', text: 'Northwest Territories' },
  { value: 'NU', text: 'Nunavut' },
  { value: 'ON', text: 'Ontario' },
  { value: 'PE', text: 'Prince Edward Island' },
  { value: 'QC', text: 'Quebec' },
  { value: 'SK', text: 'Saskatchewan' },
  { value: 'YT', text: 'Yukon' },
];

export default ProvinceCollection;
