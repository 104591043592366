import React from 'react';
import { PAYEE_FAQ } from '../../../../constants/HyperWalletConstants';
import heroPayee from '../../../../media/img/backgrounds/hero-payee-faq.png';
import styles from '../css/HyperWalletFaq.module.css';
import HyperWalletFaq from './HyperWalletFaq';

const HyperWalletPayeeFaq = () => {
  return (
    <div className={`container ${styles.containerCustom}`}>
      <div className={`row ${styles.payeeHero}`}>
        <div className={`col-md`}>
          <h4 className={`row ${styles.payeeHeading}`}>For Payee</h4>
        </div>
        <div className={`col-md`}>
          <img src={heroPayee} className={`d-block mx-auto img-fluid  ${styles.payeeImg}`} alt='' />
        </div>
      </div>
      <div className={`row ${styles.faqAccordionRoot}`}>
        <HyperWalletFaq faqs={PAYEE_FAQ} showExternalFaq/>
      </div>
    </div>
  );
};

export default HyperWalletPayeeFaq;
