import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from 'lodash';
import moment from "moment";
import QRCode from "qrcode.react";
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl, intlShape } from "react-intl";
import CountryConstants from '../../constants/CountryConstants';
import backgroundCash from "../../media/img/backgrounds/loadhub-cash.jpg";
import backgroundQrCode from "../../media/img/backgrounds/loadhub-code.jpg";
import backgroundLaptop from "../../media/img/backgrounds/loadhub-laptop.jpg";
import PaypalSdkLibrary from "../../services/PaypalSdkLibrary";
import * as constants from "../../util/constants";
import paymentTypes from "../../util/paymentTypes";
import Alert from "../common/Alert";
import ButtonSave from "../common/ButtonSave";
import FieldAddress from "../common/FieldAddress";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";
import CardPreview from "./CardPreview";
import FeeProfiles from "./FeeProfiles";
import FieldCardNumber from "./FieldCardNumber";
import FieldCheckbox from "./FieldCheckbox";
import FieldCountry from "./FieldCountry";
import Modal from "./Modal";
import ModalUnionPay from "./ModalUnionPay";
import getSessionFromToken from "../../util/getSession";
class PaymentMethods extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            paymentMethod: {},
            paymentMethodList: [],

            billingAddress: {},
            billingAccount: {},

            billingAccountList: [],
            billingAddressList: [],

            companyOwner: {},
            companyOwnerList: [],

            blockPayments: {
                blockBankPayment: false,
                blockCreditPayment: false,
                blockPaypalPayment: false,
                blockCashPayment: false,
            },
            showBillingAddressForm: false,
            showCashPaymentMethod: false,
            showCreditCardPaymentMethod: false,
            showBankPaymentMethod: false,
            showPaypalPaymentMethod: false,
            populateBillingAddressForm: false,
            isPaysafeCashEnabled: false,

            creditCardSecurityCodeLabel: 'CVV',
            creditCardIssuerCountry: '',

            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            updatePaymentMethod: false,
            updatedPaymentMethod: {},

            feeAmount: 0,

            validationList: [],

            propertyCountry: '',
        };

        this.initPaymentMethod = this.initPaymentMethod.bind(this);
        this.initPaymentSlip = this.initPaymentSlip.bind(this);

        this.viewPaymentMethod = this.viewPaymentMethod.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);
        this.deletePaymentMethod = this.deletePaymentMethod.bind(this);
        this.savePaymentSlip = this.savePaymentSlip.bind(this);

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);
        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);
        this.searchBillingAddresses = this.searchBillingAddresses.bind(this);

        this.calculateFeeAmount = this.calculateFeeAmount.bind(this);

        this.handleChangeBillingAccount = this.handleChangeBillingAccount.bind(this);
        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangeSigningOfficer = this.handleChangeSigningOfficer.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);

        this.getPropertyDetails = this.getPropertyDetails.bind(this);
        this.getCompanyDetails = this.getCompanyDetails.bind(this);

        this.postCreatePayPalPaymentMethod = this.postCreatePayPalPaymentMethod.bind(this);

        this.searchMerchantAccounts = this.searchMerchantAccounts.bind(this);
    }

    /**
     * Search for a list of all payment methods and all billing accounts related to the user on mounting of the
     * component. If the user type is a company owner, first fetch a list of all company owners based on the company ID
     * provided in the props.
     */
    componentDidMount() {

        if(this.props.userType === 'TYPE_COMPANY_OWNER') {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/company_owner/search`, {
                orderBy: 'ASC',
                orderByFields: ['id'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: this.props.companyId
                    },
                ]
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    companyOwnerList: response.data.records
                }));

                this.searchPaymentMethods();

            }).catch(error => {
                this.handleValidation(error);
            });

            this.searchMerchantAccounts(this.props.companyId);
        }

        this.searchBillingAccounts();

        if(this.props.userType !== 'TYPE_COMPANY_OWNER'){

            this.searchPaymentMethods();
        }

        this.searchBillingAddresses();
        this.getPropertyDetails();
    }

    /**
     * Create a new instance of a payment method, with the object dependant upon which payment method type the user
     * selects.
     *
     * @param paymentType - The type of payment method selected.
     * @param updatePaymentMethod - True if the user has indicated they are 'updating' an existing payment method
     */
    initPaymentMethod(paymentType, updatePaymentMethod) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    userId: this.props.userType !== 'TYPE_COMPANY_OWNER' ? this.props.userId : '',
                    userType: this.props.userType
                },
                updatePaymentMethod: updatePaymentMethod,
                updatedPaymentMethod: updatePaymentMethod ? prevState.paymentMethod : {},
                showBillingAddressForm: false,
                validationList: []
            }));

            $(`#${paymentType.replace("_", "-")}`).modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a cash payment slip.
     */
    initPaymentSlip() {

        $('input[type="radio"]').prop('checked', false); 

        axios.get(`${constants.REACT_APP_HOST_API_URL}/cash/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    billingAccountType: 'TYPE_PROPERTY_LEASE',
                    userType: this.props.userType
                }
            }));

            $('#cash').modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Trigger a modal showing additional information for the payment method when a user selects an existing payment
     * method.
     *
     * @param paymentType - The type of the payment method selected.
     * @param paymentMethodId - The ID of the payment method selected.
     */
    viewPaymentMethod(paymentType, paymentMethodId) {

        if(paymentType === 'CASH' && this.state.blockPayments.blockCashPayment) {
            return;
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/${paymentMethodId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            
            this.setState(prevState => ({
                ...prevState,
                paymentMethod: response.data,
                creditCardIssuerCountry: String(lodash.result(lodash.find(CountryConstants, {'value':response.data.country}), 'text'))
            }));

            if(paymentType === 'CASH') {

                this.state.billingAccountList.forEach((data, key) => {
                   if(data.id === response.data.billingAccountId) {
                       this.setState(prevState => ({
                           ...prevState,
                           billingAccount: data
                       }));
                   }
                });

                this.calculateFeeAmount();

            }

            $("#payment-method").modal("show");

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Calls the public peek API to fetch public company details, we are using it to get the accepted paymnet methods
     * @param companyId - The Id of the main company
     */
    getCompanyDetails(companyId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            const acceptedPaymentTypes = [];
            response.data.acceptedPaymentTypes.forEach((data, index) => {
                if(!acceptedPaymentTypes.includes(data)) {
                    acceptedPaymentTypes.push(data);
                }
            });
            let paymentMethodCount = 0;
            if(acceptedPaymentTypes.includes('TYPE_CASH')) {
                this.setState(prevState => ({
                    ...prevState,
                    showCashPaymentMethod: true,
                }));
                paymentMethodCount +=1;
            }
            if(acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT')) {
                this.setState(prevState => ({
                    ...prevState,
                    showBankPaymentMethod: true,
                }));
                paymentMethodCount +=1;
            }
            if(acceptedPaymentTypes.includes('TYPE_PAY_PAL')) {
                this.setState(prevState => ({
                    ...prevState,
                    showPaypalPaymentMethod: true,
                }));
                if(this.props.userType === "TYPE_CUSTOMER") {
                    PaypalSdkLibrary.initializePaypalBillingAgreement('TYPE_CUSTOMER', this.props.userId, this.postCreatePayPalPaymentMethod);
                }
                paymentMethodCount +=1;
            }

            if(acceptedPaymentTypes.length > paymentMethodCount) {
                this.setState(prevState => ({
                    ...prevState,
                    showCreditCardPaymentMethod: true,
                }));
            }

            //Update company country
            if(response?.data?.country) {
                this.setState(prevState => ({
                    ...prevState,
                    propertyCountry: response?.data?.country,
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all billing accounts available to the user at hand to determine if the cash payment slip payment
     * method should appear in the list of available payment methods. Using the peek endpoint, we are able to determine
     * which payment methods are accepted for the property and company within each billing account.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search?recordsPerPage=999&page=1`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                },
            ],
            joins: {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name', 'chargeCodes']
                }
            }
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                billingAccountList: response.data.records,
            }));
            if(response.data?.records?.length > 0){
                let paypalScriptLoaded = false;
                response.data.records.forEach((data, key) => {
                    let acceptedPaymentTypes = [];
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${data.propertyId}/peek`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {

                        acceptedPaymentTypes = response.data.acceptedPaymentTypes;

                        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${data.companyId}/peek`, {
                            headers: this.generateRequestHeaders()
                        }).then(response => {
                            response.data.acceptedPaymentTypes.forEach((data, index) => {
                                if(!acceptedPaymentTypes.includes(data)) {
                                    acceptedPaymentTypes.push(data);
                                }
                            });
                            let paymentMethodCount = 0;
                            if(acceptedPaymentTypes.includes('TYPE_CASH')) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    showCashPaymentMethod: true,
                                }));
                                paymentMethodCount +=1;
                            }
                            if(acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT')) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    showBankPaymentMethod: true,
                                }));
                                paymentMethodCount +=1;
                            }
                            if(acceptedPaymentTypes.includes('TYPE_PAY_PAL')) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    showPaypalPaymentMethod: true,
                                }));
                                if(!paypalScriptLoaded && this.props.userType === "TYPE_CUSTOMER"){
                                    PaypalSdkLibrary.initializePaypalBillingAgreement('TYPE_CUSTOMER', this.props.userId, this.postCreatePayPalPaymentMethod);
                                    paypalScriptLoaded = true;
                                }
                                paymentMethodCount +=1;
                            }

                            if(acceptedPaymentTypes.length > paymentMethodCount) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    showCreditCardPaymentMethod: true,
                                }));
                            }

                        }).catch(error => {
                            this.handleValidation(error);
                        });

                    }).catch(error => {
                        this.handleValidation(error);
                    });

                });
            } else {
                // Check if API Company
                if(this.props.companyId) {
                    this.getCompanyDetails(this.props.companyId);
                }
            }
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a list of all payment methods for the user. If the user type provided is a company owner, fetch a list of
     * all payment methods for all company owners under the company ID provided in the props.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        if(this.props.userType === 'TYPE_COMPANY_OWNER') {
            this.state.companyOwnerList.map((data, key) => {
                Object.keys(paymentTypes).map(key => {
                    axios.post(`${constants.REACT_APP_HOST_API_URL}/${paymentTypes[key]}/search`, {
                        orderBy: 'ASC',
                        orderByFields: ['id'],
                        conditionList: [
                            {
                                type: 'STRING',
                                logicalOperator: 'AND',
                                openBrackets: null,
                                closeBrackets: null,
                                fieldName: 'userId',
                                operator: 'EQUALS',
                                fieldValue: data.id
                            }
                        ]
                    }, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        response.data.records.map((data, key) => {

                            this.setState(prevState => ({
                                ...prevState,
                                paymentMethodList: [...prevState.paymentMethodList, data],
                            }));

                            return null;
                        });
                    }).catch(error => {
                        this.handleValidation(error);
                    });

                    return null;
                });

                return null;
            });

            Object.keys(paymentTypes).map(key => {
                axios.post(`${constants.REACT_APP_HOST_API_URL}/${paymentTypes[key]}/search`, {
                    orderBy: 'ASC',
                    orderByFields: ['id'],
                    conditionList: [
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'userId',
                            operator: 'EQUALS',
                            fieldValue: this.props.companyId
                        },
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'userType',
                            operator: 'EQUALS',
                            fieldValue: 'TYPE_CORPORATE_USER'
                        }
                    ]
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    response.data.records.map((data, key) => {

                        this.setState(prevState => ({
                            ...prevState,
                            paymentMethodList: [...prevState.paymentMethodList, data],
                        }));

                        return null;
                    });
                }).catch(error => {
                    this.handleValidation(error);
                });

                return null;
            });

        } else if (this.props.userType === 'TYPE_ADMIN') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.companyId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        } else if (this.props.userType === 'TYPE_MANAGER') {
            let effectiveUserId = this.props.userId;
            if (!effectiveUserId) { effectiveUserId = sessionStorage.getItem('session_role_id'); }
            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.userType.substring(5).toLowerCase()}/${effectiveUserId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.userType.substring(5).toLowerCase()}/${this.props.userId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: response.data
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Search for all addresses associated with the user.
     */
    searchBillingAddresses() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search?recordsPerPage=9999&page=1`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                }
            ]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            const billingAddressRecords = response.data.records;
            const billingAddressObj = {}

            // Remove the duplicate addresses:
            billingAddressRecords.forEach((record)=>{
                let addressStr = record.city + record.country + (record.postalCode.split(' ').join('')?.toUpperCase()) + record.province + record.street1 + record.street2;
                
                if(record.suite && record.suite?.toUpperCase() !== "N/A") {
                    addressStr += record.suite
                } 

                //Object keys are always unique, so this would remove the duplicate objects automatically  
                billingAddressObj[addressStr?.toUpperCase()] = record;
            });
            const billingAddressArr = Object.values(billingAddressObj); // unique addresses
            
            this.setState(prevState => ({
                ...prevState,
                billingAddressList: billingAddressArr,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Save the selected payment method. Performs a list save, collecting at most the payment method, billing address,
     * and billing account (only used if creating a company owner's first payment method).
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if(event != null) {
            event.preventDefault();
        }

        if(this.state.possibleUnionPayBin) {

            $('#credit-card').modal('hide');
            $('#confirm-unionpay').modal('show');

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let billingAccount = {};
        let saveListData = [];
        if(this.props.userType === 'TYPE_COMPANY_OWNER') {

            axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search?recordsPerPage=1&page=1`, {
                orderBy: 'ASC',
                orderByFields: ['id'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'userId',
                        operator: 'EQUALS',
                        fieldValue: this.state.paymentMethod.userId
                    },
                ]
            },{
                headers: this.generateRequestHeaders()
            }).then(response => {

                axios.get(`${constants.REACT_APP_HOST_API_URL}/billing_account/new`).then(response => {

                    billingAccount = response.data;
                    billingAccount.userType = 'TYPE_COMPANY_OWNER';
                    billingAccount.userId = this.state.paymentMethod.userId;
                    billingAccount.companyId = 'LETUS';
                    billingAccount.status = 'ACTIVE';

                    // Add the billingAddress state to the save list queue if the new billing address form is shown
                    if(this.state.showBillingAddressForm) {
                        saveListData.push(billingAddress);
                    }

                    // Handle 'securityCode' to 'cvv' conversions for credit cards
                    if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
                        paymentMethod.cvv = this.state.paymentMethod.securityCode;
                    }

                    // Handle institution and transit number conversions to routing number for Canadian banks
                    if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
                        paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
                    }

                    // Add the paymentMethod state to the save list queue
                    saveListData.push(paymentMethod);

                    this.setState(prevState => ({
                        ...prevState,
                        spinner: true
                    }));

                    const headers = this.generateRequestHeaders();
                    const sessionId = window.ka?.sessionId;
                    if(paymentMethod.type === 'TYPE_CREDIT_CARD' && sessionId) {
                        headers.blueSnapSessionId = sessionId;
                    }
                    axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
                        headers: headers
                    }).then(response => {

                        this.setState(prevState => ({
                            ...prevState,
                            paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                            showBillingAddressForm: false,
                            spinner: false,
                            validationList: [{
                                fields: {},
                                alert: {
                                    type: 'primary',
                                    code: this.state.updatePaymentMethod ? this.props.updateMessage : this.props.createMessage,
                                    message: 'Payment Method Saved'
                                }
                            }],
                        }));

                        this.searchBillingAddresses();
                        this.searchPaymentMethods();

                        $('#credit-card').modal("hide");
                        $('#bank-account').modal("hide");

                    }).catch(error => {
                      // LP-1174, this is to fix Bug#2, @author: Arsh Uppal
                      // Showing appropriate postal code error based on the country.
                      if(billingAddress && billingAddress.country && billingAddress.country !== "US" && billingAddress.country !== "CA") {
                        if (error.response.data !== undefined && error.response.data.exceptionList !== undefined) {
                            const exceptionList = error.response.data.exceptionList;
                            exceptionList.forEach((excp)=>{ 
                                const excp_ = excp.exception;
                                if(excp_.errorCode === "RM_ERROR_RECORD_VALIDATION" && excp_.message) {
                                    if(excp_.message.includes("postalCode")) {
                                        const postalErr = {
                                            errorCode: "RM_ERROR_CREDIT_CARD_POSTAL_ERR",
                                            errorMessage: "Please enter a postal code. If your country does not use a postal code then enter NA.",
                                            fieldName: "postalCode",                             
                                        };
                                        if(excp_.fieldErrors) {
                                            excp_.fieldErrors.push(postalErr);
                                        } else {
                                            excp_.fieldErrors = [postalErr];
                                        }
                                    }
                                }
                            });
                        }
                      }

                      // LP-1110, this is to fix comment#2, @author: Arsh Uppal
                      // The existing exception was not very useful for the user.
                      if (error.response.data !== undefined && error.response.data.exceptionList !== undefined) {
                        const exceptionList = error.response.data.exceptionList;
                        exceptionList.forEach((excp)=>{ 
                            const excp_ = excp.exception;
                            if(excp_.errorCode === "paysafe.5068" && excp_.message) {
                                if(excp_.message.includes("country:invalid value")) {
                                    excp_.message = "The country you selected may not be supported yet!"
                                    const countryErr = {
                                        errorCode: "RM_ERROR_CREDIT_CARD_UNSUPPORTED_COUNTRY",
                                        errorMessage: "The country you selected may not be supported yet!",
                                        fieldName: "country",                             
                                    };
                                    if(excp_.fieldErrors) {
                                        excp_.fieldErrors.push(countryErr);
                                    } else {
                                        excp_.fieldErrors = [countryErr];
                                    }
                                }

                            }
                        });
                      }

                      // LP-809, @author: Arsh Uppal
                      // This is a hack to display errors on correct fields in the form
                      if (paymentMethod.type === "TYPE_CREDIT_CARD") {
                        const monthErr = {
                                errorCode: "RM_ERROR_CREDIT_CARD_DATE_EXPIRED",
                                errorMessage: "Expiry month is invalid.",
                                fieldName: "expiryMonth",                             
                        };

                        const cvvErr = { 
                                errorCode: "RM_ERROR_CREDIT_CARD_WRONG_CVV",
                                errorMessage: "CVV is invalid.",
                                fieldName: "securityCode", 
                        };

                        if (
                            paymentMethod.securityCode.length > 4 ||
                            paymentMethod.securityCode.length < 3
                          ) {
                            if (error.response.data !== undefined) {
                              if (
                                  error.response.data.exceptionList !== undefined &&
                                  error.response.data.exceptionList[0] !== undefined &&
                                  error.response.data.exceptionList[0].exception !== undefined 
                              ) {
                                  if(error.response.data.exceptionList[0].exception.fieldErrors === undefined) {
                                      error.response.data.exceptionList[0].exception.fieldErrors = [cvvErr];
                                  }
                                  else {
                                      error.response.data.exceptionList[0].exception.fieldErrors.push(cvvErr);
                                  }
                              }
                            }
                          }

                        if (
                          parseInt(new Date().getFullYear()) ===
                          parseInt(paymentMethod.expiryYear)
                        ) {
                          if (
                            parseInt(new Date().getMonth() + 1) >
                            parseInt(paymentMethod.expiryMonth)
                          ) {
                            if (error.response.data !== undefined) {
                              if (
                                error.response.data.exceptionList !== undefined &&
                                error.response.data.exceptionList[0] !== undefined &&
                                error.response.data.exceptionList[0].exception !== undefined
                              ) {
                                if(error.response.data.exceptionList[0].exception.fieldErrors === undefined) {
                                    error.response.data.exceptionList[0].exception.fieldErrors = [monthErr];
                                }
                                else {
                                    error.response.data.exceptionList[0].exception.fieldErrors.push(monthErr);
                                }

                              }
                            }
                          }
                        }
                      }
                      this.handleValidation(error);

                      window.scrollTo(0, 0);
                    });

                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        if(this.props.userType !== 'TYPE_COMPANY_OWNER') {

            // Add the billingAddress state to the save list queue if the new billing address form is shown
            if(this.state.showBillingAddressForm) {
                saveListData.push(billingAddress);
            }

            // Handle 'securityCode' to 'cvv' conversions for credit cards
            if(paymentMethod.type === 'TYPE_CREDIT_CARD') {
                paymentMethod.cvv = this.state.paymentMethod.securityCode;
            }

            // Handle institution and transit number conversions to routing number for Canadian banks
            if(paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
                paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
            }

            // Add the paymentMethod state to the save list queue
            saveListData.push(paymentMethod);

            this.setState(prevState => ({
                ...prevState,
                spinner: true
            }));

            const headers = this.generateRequestHeaders();
            const sessionId = window.ka?.sessionId;
            if(paymentMethod.type === 'TYPE_CREDIT_CARD' && sessionId) {
                headers.blueSnapSessionId = sessionId;
            }
            axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
                headers: headers
            }).then(response => {

                if(this.state.updatePaymentMethod) {

                    this.deletePaymentMethod(this.state.updatedPaymentMethod.type.substring(5).toLowerCase(), this.state.updatedPaymentMethod.id);

                }

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                    showBillingAddressForm: false,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.state.updatePaymentMethod ? this.props.updateMessage : this.props.createMessage
                        }
                    }],
                }));

                this.searchBillingAddresses();
                this.searchPaymentMethods();

                $('#credit-card').modal("hide");
                $('#bank-account').modal("hide");

            }).catch(error => {
                // LP-1174, this is to fix Bug#2, @author: Arsh Uppal
                // Showing appropriate postal code error based on the country.
                if(billingAddress && billingAddress.country && billingAddress.country !== "US" && billingAddress.country !== "CA") {
                    if (error.response.data !== undefined && error.response.data.exceptionList !== undefined) {
                        const exceptionList = error.response.data.exceptionList;
                        exceptionList.forEach((excp)=>{ 
                            const excp_ = excp.exception;
                            if(excp_.errorCode === "RM_ERROR_RECORD_VALIDATION" && excp_.message) {
                                if(excp_.message.includes("postalCode")) {
                                    const postalErr = {
                                        errorCode: "RM_ERROR_CREDIT_CARD_POSTAL_ERR",
                                        errorMessage: "Please enter a postal code. If your country does not use a postal code then enter NA.",
                                        fieldName: "postalCode",                             
                                    };
                                    if(excp_.fieldErrors) {
                                        excp_.fieldErrors.push(postalErr);
                                    } else {
                                        excp_.fieldErrors = [postalErr];
                                    }
                                }
                            }
                        });
                    }
                }

                // LP-1110, this is to fix comment#2, @author: Arsh Uppal
                // The existing exception was not very useful for the user.
                if (error.response.data !== undefined && error.response.data.exceptionList !== undefined) {
                    const exceptionList = error.response.data.exceptionList;
                    exceptionList.forEach((excp)=>{
                        const excp_ = excp.exception;
                        if(excp_.errorCode === "paysafe.5068" && excp_.message) {
                            if(excp_.message.includes("country:invalid value")) {
                                excp_.message = "The country you selected may not be supported yet!"
                                const countryErr = {
                                    errorCode: "RM_ERROR_CREDIT_CARD_UNSUPPORTED_COUNTRY",
                                    errorMessage: "The country you selected may not be supported yet!",
                                    fieldName: "country",                             
                                };
                                if(excp_.fieldErrors) {
                                    excp_.fieldErrors.push(countryErr);
                                } else {
                                    excp_.fieldErrors = [countryErr];
                                }
                            }

                        }
                    });
                }
                
              // LP-809, @author: Arsh Uppal
              // This is a hack to display errors on correct fields in a form
              if (paymentMethod.type === "TYPE_CREDIT_CARD") {
                const monthErr = {
                        errorCode: "RM_ERROR_CREDIT_CARD_DATE_EXPIRED",
                        errorMessage: "Expiry month is invalid.",
                        fieldName: "expiryMonth",                             
                };

                const cvvErr = { 
                        errorCode: "RM_ERROR_CREDIT_CARD_WRONG_CVV",
                        errorMessage: "CVV is invalid.",
                        fieldName: "securityCode", 
                };

                if (
                    paymentMethod.securityCode.length > 4 ||
                    paymentMethod.securityCode.length < 3
                  ) {
                    if (error.response.data !== undefined) {
                      if (
                          error.response.data.exceptionList !== undefined &&
                          error.response.data.exceptionList[0] !== undefined &&
                          error.response.data.exceptionList[0].exception !== undefined 
                      ) {
                          if(error.response.data.exceptionList[0].exception.fieldErrors === undefined) {
                              error.response.data.exceptionList[0].exception.fieldErrors = [cvvErr];
                          }
                          else {
                              error.response.data.exceptionList[0].exception.fieldErrors.push(cvvErr);
                          }
                      }
                    }
                  }

                if (
                  parseInt(new Date().getFullYear()) ===
                  parseInt(paymentMethod.expiryYear)
                ) {
                  if (
                    parseInt(new Date().getMonth() + 1) >
                    parseInt(paymentMethod.expiryMonth)
                  ) {
                    if (error.response.data !== undefined) {
                      if (
                        error.response.data.exceptionList !== undefined &&
                        error.response.data.exceptionList[0] !== undefined &&
                        error.response.data.exceptionList[0].exception !== undefined
                      ) {
                        if(error.response.data.exceptionList[0].exception.fieldErrors === undefined) {
                            error.response.data.exceptionList[0].exception.fieldErrors = [monthErr];
                        }
                        else {
                            error.response.data.exceptionList[0].exception.fieldErrors.push(monthErr);
                        }

                      }
                    }
                  }
                }
              }
              this.handleValidation(error);

              window.scrollTo(0, 0);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Calculate the expected fee amount for the payment method at hand. Used primarily for cash payment methods, where
     * a payment amount has already been established.
     */
    calculateFeeAmount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.billingAccount.companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            response.data.forEach((data, key) => {
                if(data.paymentType === 'TYPE_CASH' && data.country === this.state.paymentMethod.country) {
                    this.setState(prevState => ({
                        ...prevState,
                        feeAmount: data.feeRate,
                    }));
                }
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create a new cash payment slip or save an existing one.
     *
     * @param event - The event container.
     */
    savePaymentSlip(event) {

        event.preventDefault();

        const params = this.state.paymentMethod;
        if(!params.userId) {
            params.userId = this.props.userId;
        }

        if(this.state.paymentMethod.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, params, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.searchBillingAddresses();
                this.searchPaymentMethods();

                $('#cash').modal("hide");

                this.viewPaymentMethod("CASH", response.data.id);

            }).catch(error => {
                this.handleValidation(error);
                window.scrollTo(0, 0);
            });
        }

        if(this.state.paymentMethod.createDate != null) {
            axios.patch(`${constants.REACT_APP_HOST_API_URL}/cash/${this.state.paymentMethod.id}/update`, {
                amount: parseFloat(this.state.paymentMethod.amount)
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethod: {
                        ...response.data,
                        amount: (response.data.amount).toFixed(2)
                    },
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                }));

                this.searchBillingAddresses();
                this.searchPaymentMethods();

                $('#edit-payment-method').modal("hide");
                $('#payment-method').modal("show");

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }
    }

    /**
     * Delete a payment method for a user.
     *
     * @param paymentType - The type of the payment method to be deleted.
     * @param paymentMethodId - The ID of the payment method to be deleted.
     */
    deletePaymentMethod(paymentType, paymentMethodId) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/${paymentMethodId}/delete_with_bluesnap_check`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: this.props.deleteMessage,
                        message: 'Payment Method Deleted'
                    }
                }],
            });

            this.searchPaymentMethods();

            $("#payment-method").modal("hide");

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Handle changes to the billing account selection when creating a new cash payment method. Match the ID of the
     * selected billing account with the existing list of fetched billing accounts when the component mounted. If there
     * is a match, use the country of the property as the country of the new cash payment method.
     *
     * @param event - The event container.
     */
    handleChangeBillingAccount(event) {

        event.persist();

        let country;

        this.state.billingAccountList.forEach((data, key) => {
            if((data.id === event.target.value) && data.joins.p != null) {
                country = data.joins.p.country;
            }
        });

        this.setState(prevState => ({
            ...prevState,
            paymentMethod: {
                ...prevState.paymentMethod,
                billingAccountId: event.target.value,
                country: country,
            },
        }));
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if(event.target.value !== 'NEW') {
            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));
        } else {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                this.setState(prevState => ({
                    ...prevState,
                    showBillingAddressForm: true,
                    billingAddress: {
                        ...response.data,
                        addressType: 'BILLING',
                        userType: this.props.userType,
                        userId: this.props.userType !== 'TYPE_COMPANY_OWNER' ? this.props.userId : this.state.companyOwner.id
                    },
                    paymentMethod: {
                        ...prevState.paymentMethod,
                        billingAddressId: response.data.id
                    }
                }));
            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Handle changes to the signing officer field. Based on the selected signing officer, populate the list of
     * available billing addresses.
     *
     * @param event - The event container.
     */
    handleChangeSigningOfficer(event) {

        let signingOfficerId = event.target.value;

        this.state.companyOwnerList.map((data, key) =>{
            if(data.id === signingOfficerId){
                this.setState(prevState => ({
                    ...prevState,
                    companyOwner: data,
                    paymentMethod:{
                        ...prevState.paymentMethod,
                        userId: signingOfficerId
                    }
                }));
            }
            return null;
        })

        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search?recordsPerPage=9999&page=1`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: signingOfficerId
                }
            ]
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...prevState.paymentMethod,
                    userType: this.props.userType
                },
                billingAddressList: response.data.records,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the address of the property lease they are assigned to. Currently this function
     * can only be utilized if the user has one billing account associated with them, as the first billing address from
     * the list is pulled.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the customer's new property
        if(event.target.checked) {

            residentialAddress = this.state.billingAccountList[0].joins.p;
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = this.state.billingAccount.unit;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if(!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * This method will fetch the details associated with the currect property.
     */
    getPropertyDetails() {
        if(this.props.propertyLeaseId) {
            const propertyLeaseId = this.props.propertyLeaseId;

            let billingAccountType = 'property_lease';

            if(this.props.billingAccountType){

                billingAccountType = this.props.billingAccountType.replace('TYPE_', '');
            }

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${billingAccountType}/${propertyLeaseId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                // Search merch. accounts if the company has Paysafe enabled
                if(response.data.companyId){
                    this.searchMerchantAccounts(response.data.companyId);
                }

                if (response?.data?.blockPayments) {
                    this.setState(prevState => ({
                        ...prevState,
                        blockPayments: {
                            blockBankPayment: response.data.blockPayments['TYPE_BANK_ACCOUNT']?.blocked,
                            blockCreditPayment: response.data.blockPayments['TYPE_CREDIT_CARD']?.blocked,
                            blockPaypalPayment: response.data.blockPayments['TYPE_PAY_PAL']?.blocked,
                            blockCashPayment: response.data.blockPayments['TYPE_CASH']?.blocked,
                        }
                    }));
                }

                if(response.data && response.data.propertyId) {
                    const propertyId = response.data.propertyId;
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${propertyId}/peek`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {
                        if(response.data && response.data.country) {
                            const country = response.data.country;
                            this.setState(prevState => ({
                                ...prevState,
                                propertyCountry: country,
                            }));
                        }
                    });
                }
            }).catch(error => {
                this.handleValidation(error);
            });
        }  
    }

    /**
     * Adds Paypal methods to the DB
     * @param token - returned by paypal
     */
    postCreatePayPalPaymentMethod(token) {
        const data = {
            "type": "TYPE_PAY_PAL",
            "userType": "TYPE_CUSTOMER",
            "userId": this.props.userId,
            token: token, 
        };
        axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, data, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'Success!!'
                    }
                }],
            }));
            this.searchPaymentMethods();

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);

        });
    }
    
    /**
     * Searches merchant accounts to determine if Paysafe Cash is set up
     * Searches for merchant accounts of a SINGLE COMPANY
     * @param {*} companyId 
     */
    searchMerchantAccounts(companyId){
        
        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
        
            this.setState(prevState =>({
                ...prevState,
                isPaysafeCashEnabled: response?.data?.cashMerchantAccountId !== null ? true : false
            }));

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit customer component for admins.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <Spinner visible={this.state.spinner} />

                <div className="card kaxsdc" data-event='load'>

                    <div className="card-header">
                        {this.props.cardHeader}
                    </div>

                    {(!this.props.singlePaymentMethod && this.state.paymentMethodList.length > 0) &&
                    <div className="card-body card-body-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Existing Payment Methods
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    }

                    <div className="card-body card-body-list">
                        <div className="list-group">
                            {this.state.paymentMethodList.map((data, key) => {
                                return(
                                    <div key={key} 
                                        className={`list-group-item list-group-item-action flex-column align-items-start c-pointer ${((this.state.blockPayments?.blockCashPayment || !this.state.showCashPaymentMethod)  && data.type === 'TYPE_CASH') ? 'disabled' : ''}`} 
                                        onClick={() => this.viewPaymentMethod((data.type).substring(5), data.id)}>
                                        <div className="media">
                                            <div className="align-self-center mr-3 text-center">

                                                    {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                    <div className="fa-stack fa-1x">
                                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                        <FontAwesomeIcon icon={['far', 'university']} className="fa-stack-1x fa-inverse" />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_CASH' &&
                                                    <div className="fa-stack fa-1x">
                                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                        <FontAwesomeIcon icon={['far', 'usd-circle']} className="fa-stack-1x fa-inverse" />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_CREDIT_CARD' &&
                                                    <div className="fa-stack fa-1x">
                                                        <FontAwesomeIcon icon={['fas', 'square']} className={`fa-2x ${formatMessage({id: "enum.creditCard.brand." + data.brand + ".class"})}`} />
                                                        <FontAwesomeIcon icon={[data.brand === 'UNION_PAY' ? 'far' : 'fab', formatMessage({id: "enum.creditCard.brand." + data.brand + ".icon"})]} className="fa-stack-1x fa-inverse" />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_PAY_PAL' &&
                                                    <div className="fa-stack fa-1x">
                                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                        <FontAwesomeIcon icon={['fab', 'paypal']} className="fa-stack-1x fa-inverse" />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_WALLET' && data.walletType === 'GOOGLE_PAY' &&
                                                    <div className="fa-stack fa-1x">
                                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                        <FontAwesomeIcon icon={['fab', 'google-pay']} className="fa-stack-1x fa-inverse" />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_WALLET' && data.walletType === 'APPLE_PAY' &&
                                                        <div className="fa-stack fa-1x">
                                                            <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                                            <FontAwesomeIcon icon={['fab', 'apple-pay']} className="fa-stack-1x fa-inverse" />
                                                        </div>
                                                    }
                                            </div>
                                            <div className="media-body align-self-center">
                                                <div className="mb-0">
                                                    {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                    <div className="">
                                                        Bank Account
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_CASH' &&
                                                    <div className="">
                                                        Cash Payment Slip
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_CREDIT_CARD' &&
                                                    <div className="">
                                                        <FormattedMessage id={"enum.creditCard.brand." + data.brand} /> <FormattedMessage id={"enum.creditCard.cardType." + data.cardType} />
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_PAY_PAL' &&
                                                    <div className="">
                                                        PayPal
                                                    </div>
                                                    }
                                                    {data.type === 'TYPE_WALLET' && data.walletType === 'APPLE_PAY' &&
                                                        <div className="">
                                                            Apple Pay
                                                        </div>
                                                    }
                                                    {data.type === 'TYPE_WALLET' && data.walletType === 'GOOGLE_PAY' &&
                                                        <div className="">
                                                            Google Pay
                                                        </div>
                                                    }
                                                </div>
                                                <small className="text-muted">
                                                    {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                    <span className="">Account number ending in {data.last4}</span>
                                                    }
                                                    {data.type === 'TYPE_CASH' &&
                                                    <span className=""><FormattedNumber value={data.amount} style={`currency`} currency="USD" /> plus applicable transaction fees</span>
                                                    }
                                                    {data.type === 'TYPE_CREDIT_CARD' &&
                                                    <span className="">Card number ending in {data.last4}</span>
                                                    }
                                                    {/* {data.type === 'TYPE_PAY_PAL' &&
                                                    <span className="">Email {data.email}</span>
                                                    } */}
                                                </small>
                                            </div>
                                            <div className="align-self-center text-right">
                                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {!this.props.singlePaymentMethod &&
                    <div className="card-body card-body-table">
                        <table className="table">
                            <thead>
                            <tr>
                                <th>
                                    Add New Payment Method
                                </th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    }

                    {(!this.props.singlePaymentMethod && (this.props.userType === "TYPE_CUSTOMER" ? this.state.showCreditCardPaymentMethod : true)) &&
                    <div className={'list-group-item list-group-item-action flex-column align-items-start c-pointer ' + (this.state.blockPayments.blockCreditPayment ? 'disabled' : '')} onClick={() => this.initPaymentMethod('credit_card', false)}>
                        <div className="media">
                            <div className="align-self-center mr-3 text-center">
                                <div className="fa-stack fa-1x">
                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-stack-1x fa-inverse" />
                                </div>
                            </div>
                            <div className="media-body align-self-center">
                                <p className="mb-0">
                                    Add New Credit or Debit Card
                                </p>
                            </div>
                            <div className="align-self-center text-right">
                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x"/>
                            </div>
                        </div>
                    </div>
                    }

                    {(!this.props.singlePaymentMethod && (this.props.userType === "TYPE_CUSTOMER" ? this.state.showBankPaymentMethod : true)) &&
                    <div className={'list-group-item list-group-item-action flex-column align-items-start c-pointer ' + (this.state.blockPayments.blockBankPayment ? 'disabled' : '')} onClick={() => this.initPaymentMethod('bank_account', false)}>
                        <div className="media">
                            <div className="align-self-center mr-3 text-center">
                                <div className="fa-stack fa-1x">
                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                    <FontAwesomeIcon icon={['far', 'university']} className="fa-stack-1x fa-inverse" />
                                </div>
                            </div>
                            <div className="media-body align-self-center">
                                <p className="mb-0">
                                    Add New Bank Account
                                </p>
                            </div>
                            <div className="align-self-center text-right">
                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x"/>
                            </div>
                        </div>
                    </div>
                    }

                    {!this.props.singlePaymentMethod && this.state.showCashPaymentMethod && this.state.propertyCountry === 'CA' && this.props.userType !== 'TYPE_COMPANY_OWNER' &&
                    <div className={'list-group-item list-group-item-action flex-column align-items-start c-pointer ' + (this.state.blockPayments.blockCashPayment ? 'disabled' : '')} onClick={() => this.initPaymentSlip()}>
                        <div className="media">
                            <div className="align-self-center mr-3 text-center">
                                <div className="fa-stack fa-1x">
                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                    <FontAwesomeIcon icon={['far', 'usd-circle']} className="fa-stack-1x fa-inverse" />
                                </div>
                            </div>
                            <div className="media-body align-self-center">
                                <p className="mb-0">
                                    Add New Cash Payment Slip
                                </p>
                            </div>
                            <div className="align-self-center text-right">
                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x"/>
                            </div>
                        </div>
                    </div>
                    }

                    {(!this.props.singlePaymentMethod && (this.props.userType === "TYPE_CUSTOMER" ? this.state.showPaypalPaymentMethod : false)) &&
                    <div className={'d-none list-group-item list-group-item-action flex-column align-items-start c-pointer ' + (this.state.blockPayments.blockPaypalPayment ? 'disabled' : '')} onClick={() => this.initPaymentSlip()}>
                        <div className="media">
                            <div className="align-self-center mr-3 text-center">
                                <div className="fa-stack fa-1x">
                                    <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                    <FontAwesomeIcon icon={['fab', 'paypal']} className="fa-stack-1x fa-inverse" />
                                </div>
                            </div>
                            <div className="media-body align-self-center">
                                <p className="mb-0">
                                    <div id="paypal-button" v-show="hasAcceptedPaymentMethod('TYPE_PAY_PAL')"></div>
                                </p>
                            </div>
                            <div className="align-self-center text-right">
                                <FontAwesomeIcon icon={['fas', 'angle-right']} className="text-muted fa-fw" size="2x"/>
                            </div>
                        </div>
                    </div>
                    }
                    {(this.props.userType === "TYPE_CUSTOMER") &&
                        <div className="card-footer">
                            <p className="small text-muted mb-0">
                                Transactions made through Letus are subject to transaction fees, dependant upon your
                                chosen method of payment. To view a list of our transaction fees, <span
                                className="btn-link c-pointer" data-toggle="modal" data-target="#fees">click here</span>.
                            </p>
                        </div>
                    }
                </div>

                <div className="modal fade" id="credit-card" tabIndex="-1" role="dialog" aria-labelledby="card-card-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.savePaymentMethod}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="card-card-label">
                                        Add New Credit or Debit Card
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    {!this.state.paymentMethod.createDate &&
                                    <CardPreview paymentMethod={this.state.paymentMethod} cardPreviewFlipped={this.state.cardPreviewFlipped} activePaymentMethodField={this.state.activePaymentMethodField} columnClasses="col-8"/>
                                    }

                                    <FieldCardNumber id="cardNumber" label="Card Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state.paymentMethod.cardNumber} brand={this.state.paymentMethod.brand} handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')} handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')} />

                                    <FieldText id="nameOnCard" label="Card Holder Name" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['nameOnCard']} handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')} handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')} />

                                    <div className="row mb-3">
                                        <div className="col">

                                            <FieldSelect id="expiryMonth" label="Expiry Month" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryMonth']} handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')} handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                <option value="">-</option>
                                                <option value="1">01 - Jan</option>
                                                <option value="2">02 - Feb</option>
                                                <option value="3">03 - Mar</option>
                                                <option value="4">04 - Apr</option>
                                                <option value="5">05 - May</option>
                                                <option value="6">06 - Jun</option>
                                                <option value="7">07 - Jul</option>
                                                <option value="8">08 - Aug</option>
                                                <option value="9">09 - Sep</option>
                                                <option value="10">10 - Oct</option>
                                                <option value="11">11 - Nov</option>
                                                <option value="12">12 - Dec</option>
                                            </FieldSelect>

                                        </div>
                                        <div className="col">

                                            <FieldSelect id="expiryYear" label="Expiry Year" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['expiryYear']} handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')} handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                <option value="">-</option>
                                                <option value="2022">2022</option>
                                                <option value="2023">2023</option>
                                                <option value="2024">2024</option>
                                                <option value="2025">2025</option>
                                                <option value="2026">2026</option>
                                                <option value="2027">2027</option>
                                                <option value="2028">2028</option>
                                                <option value="2029">2029</option>
                                                <option value="2030">2030</option>
                                                <option value="2031">2031</option>
                                                <option value="2032">2032</option>
                                                <option value="2033">2033</option>
                                            </FieldSelect>

                                        </div>
                                        <div className="col">

                                            <FieldText id="securityCode" label={this.state.creditCardSecurityCodeLabel} required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['securityCode']} handleFocus={() => this.handleFocusPaymentMethodField('securityCode')} handleBlur={() => this.handleBlurPaymentMethodField('securityCode')} />

                                        </div>
                                    </div>

                                    {!this.state.showBillingAddressForm &&
                                    <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                        <option value="">Select from your billing addresses...</option>

                                        {this.state.billingAddressList.map((data, key) => { 
                                            return (
                                                <option key={key} value={data.id}>
                                                    {data.suite && data.suite !== "N/A" ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                </option>
                                            );
                                        })}

                                        <option value="NEW">Add new billing address...</option>

                                    </FieldSelect>
                                    }

                                    {this.state.showBillingAddressForm &&
                                    <div className="mb-2">

                                        {this.state.billingAccountList.length === 1 &&
                                        <div className="mt-4 mb-3">
                                            <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                        </div>
                                        }

                                        <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} monthsAtAddress={this.props.userType === 'TYPE_COMPANY_OWNER'} parent={this} value={this.state.billingAddress} isForCreditCard={true}/>

                                    </div>
                                    }

                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#credit-card").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="bank-account" tabIndex="-1" role="dialog" aria-labelledby="bank-account-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.savePaymentMethod}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="bank-account-label">
                                        Add New Bank Account
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        <small>
                                            Upon confirming a payment or when your scheduled payment date arrives, you will be charged immediately, but it can take up to 5 business days for the funds to be withdrawn from your bank account
                                            {!((this.state.userType !== 'TYPE_MANAGER') && (this.state.userType !== 'TYPE_ADMIN')) &&
                                                <i>
                                                    &nbsp;(your property manager is aware of this)
                                                </i>
                                            }
                                            . Please ensure sufficient funds are in your account prior to the payment being debited.
                                        </small>
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldCountry id="country" label="Bank Country" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['country']} propertyCountry={this.state.propertyCountry} isForTenantBankAcc = {this.props.userType === "TYPE_CUSTOMER" ? true : false}/>

                                    {(this.state.paymentMethod['country'] && this.state.companyOwner) &&
                                    <React.Fragment>

                                        {this.state.paymentMethod['country'] === 'CA' &&
                                        <React.Fragment>

                                            <FieldText id="bankAccountInstitutionNumber" label="Institution Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountInstitutionNumber']} />

                                            <FieldText id="bankAccountTransitNumber" label="Transit Number" required={true} fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state['bankAccountTransitNumber']} />

                                        </React.Fragment>
                                        }

                                        {this.state.paymentMethod['country'] === 'US' &&
                                        <React.Fragment>

                                            <FieldText id="routingNumber" label="Routing Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['routingNumber']} />

                                        </React.Fragment>
                                        }

                                        <FieldText id="bankAccountNumber" label="Account Number" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" type="tel" parent={this} value={this.state.paymentMethod['bankAccountNumber']} />

                                        <FieldText id="accountHolder" label="Account Holder" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['accountHolder']} />

                                        {!this.state.showBillingAddressForm &&
                                        <FieldSelect id="billingAddressId" label="Billing Address" required={true} model="paymentMethod" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" parent={this} value={this.state.paymentMethod['billingAddressId']} handleChange={this.handleChangeBillingAddress}>

                                            <option value="">Select from your billing addresses...</option>
                                            {this.state.billingAddressList.filter((data) => (data.country === "CA" || data.country === "US")).map((data, key) => {
                                                return (
                                                    <option key={key} value={data.id}>
                                                        {data.suite && data.suite !== "N/A" ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                    </option>
                                                );
                                            })}

                                            <option value="NEW">Add new billing address...</option>

                                        </FieldSelect>
                                        }

                                        {this.state.showBillingAddressForm &&
                                        <div className="mb-2">

                                            {this.state.billingAccountList.length === 1 &&
                                            <div className="mt-4 mb-3">
                                                <FieldCheckbox id="populateBillingAddressForm" fieldLabel="Billing address same as property lease" fieldClass="form-control-sm" fieldColumns="12" labelClass="col-form-label-sm px-2" parent={this} value={this.state.populateBillingAddressForm} handleChange={this.handleChangePopulateBillingAddress}/>
                                            </div>
                                            }

                                            <FieldAddress model="billingAddress" fieldColumns="12" labelColumns="12" fieldClass="form-control-md" suite={true} monthsAtAddress={this.props.userType === 'TYPE_COMPANY_OWNER'} parent={this} value={this.state.billingAddress} />

                                        </div>
                                        }

                                    </React.Fragment>
                                    }

                                </div>
                                <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#bank-account").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="cash" tabIndex="-1" role="dialog" aria-labelledby="cash-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.savePaymentSlip}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="cash-label">
                                        Add New Cash Payment Slip
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Make your payments with cash, in-store at participating vendors. Start by selecting which lease you would like to make cash payments for below, in addition to your regular payment amount.
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="amount" type="number" iconType="fas" iconName="dollar-sign" model="paymentMethod" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" labelClass="col-form-label-lg d-none" placeholder="0.00" fieldClass="form-control-lg" fieldColumns="12" labelColums="0" required={true} parent={this} value={this.state.paymentMethod['amount']} min={10} max={1993}/>

                                    {this.state.billingAccountList.map((data, key) => {
                                        return (
                                            <div key={key} className={`list-group small ${key + 1 < this.state.billingAccountList.length ? 'mb-2' : ''}`}>
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="billingAccount" value={data.id} id={key} disabled={data.paymentsBlocked} onChange={this.handleChangeBillingAccount} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor={key}>

                                                        <div className="">
                                                            {data.unit} - {data.joins.p.street1}
                                                        </div>

                                                        <p className="mb-0 ml-md-0 small">
                                                            {data.joins &&
                                                            <small className="text-muted">
                                                                Managed by {data.joins.c.name}
                                                            </small>
                                                            }
                                                            {(data.paymentsBlocked) &&
                                                            <small className="text-danger ml-1">
                                                                (Payments Blocked)
                                                            </small>
                                                            }
                                                        </p>

                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#cash").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="payment-method" tabIndex="-1" role="dialog" aria-labelledby="payment-method-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">

                            <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">

                                <div className="">
                                    {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['far', 'university']} className="fa-stack-1x text-dark" />
                                    </div>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_CASH' &&
                                    <div className="p-2">
                                        {this.state.paymentMethod.token &&
                                        <QRCode value={this.state.paymentMethod.token} className="border rounded" size={190} />
                                        }
                                    </div>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={[this.state.paymentMethod.brand === 'UNION_PAY' ? 'far' : 'fab', formatMessage({id: "enum.creditCard.brand." + this.state.paymentMethod.brand + ".icon"})]} className={`fa-stack-1x ${formatMessage({id: "enum.creditCard.brand." + this.state.paymentMethod.brand + ".class"})}`} />
                                    </div>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_PAY_PAL' &&
                                    <div className="fa-stack fa-2x mb-2">
                                        <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                        <FontAwesomeIcon icon={['fab', 'paypal']} className="fa-stack-1x text-dark" />
                                    </div>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_WALLET' && this.state.paymentMethod.walletType === 'GOOGLE_PAY' &&
                                        <div className="fa-stack fa-2x mb-2">
                                            <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                            <FontAwesomeIcon icon={['fab', 'google-pay']} className="fa-stack-1x text-dark" />
                                        </div>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_WALLET' && this.state.paymentMethod.walletType === 'APPLE_PAY' &&
                                        <div className="fa-stack fa-2x mb-2">
                                            <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                            <FontAwesomeIcon icon={['fab', 'apple-pay']} className="fa-stack-1x text-dark" />
                                        </div>
                                    }
                                </div>

                                <h5 className="modal-title" id="payment-method-label">
                                    {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                    <span className="">
                                        Bank Account
                                    </span>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_CASH' &&
                                    <span className="">
                                        Cash Payment Slip
                                    </span>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                    <span className="">
                                        <FormattedMessage id={"enum.creditCard.brand." + this.state.paymentMethod.brand} /> <FormattedMessage id={"enum.creditCard.cardType." + this.state.paymentMethod.cardType} />
                                    </span>
                                    }
                                     {this.state.paymentMethod.type === 'TYPE_PAY_PAL' &&
                                    <span className="">
                                        PayPal
                                    </span>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_WALLET' && this.state.paymentMethod.walletType === 'APPLE_PAY' &&
                                        <span className="">
                                        Apple Pay
                                    </span>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_WALLET' && this.state.paymentMethod.walletType === 'GOOGLE_PAY' &&
                                        <span className="">
                                        Google Pay
                                    </span>
                                    }
                                </h5>

                            </div>

                            {this.state.paymentMethod.type === 'TYPE_CASH' &&
                            <div className="modal-body modal-body-table">

                                <div className="receipt-instructions">
                                    <div id="cash-instructions" className="carousel slide" data-ride="carousel">
                                        <ol className="carousel-indicators">
                                            <li data-target="#cash-instructions" data-slide-to="0" className="active"></li>
                                            <li data-target="#cash-instructions" data-slide-to="1"></li>
                                            <li data-target="#cash-instructions" data-slide-to="2"></li>
                                        </ol>
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <img src={backgroundLaptop} className="d-block w-100 h-75" alt="..."/>
                                                <div className="carousel-caption">
                                                    <h5>Review your payment</h5>
                                                    <p>Make sure the payment amount on your payment slip below is correct.</p>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={backgroundQrCode} className="d-block w-100 h-75" alt="..."/>
                                                <div className="carousel-caption">
                                                    <h5>Visit a Canada Post</h5>
                                                    <p>Present your QR code above on your phone (or printed) to the clerk.</p>
                                                </div>
                                            </div>
                                            <div className="carousel-item">
                                                <img src={backgroundCash} className="d-block w-100" alt="..."/>
                                                <div className="carousel-caption">
                                                    <h5>Make your payment</h5>
                                                    <p>Pay with debit card or with cash and get a receipt for your transaction.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#cash-instructions" role="button" data-slide="prev">
                                            <span className="carousel-control-prev-icon" aria-hidden="true"> </span>
                                            <span className="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#cash-instructions" role="button" data-slide="next">
                                            <span className="carousel-control-next-icon" aria-hidden="true"> </span>
                                            <span className="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            }

                            <div className="modal-body modal-body-table">
                                <table className="table mb-0">
                                    {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                    <tbody>
                                    <tr>
                                        <td>
                                            Card Number
                                        </td>
                                        <td className="text-right">
                                            *****{this.state.paymentMethod.last4}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Card Holder
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.nameOnCard}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Expiry Month
                                        </td>
                                        <td className="text-right">
                                            {String("0" + this.state.paymentMethod.expiryMonth).slice(-2)} - {moment(String("0" + this.state.paymentMethod.expiryMonth).slice(-2), 'MM').format('MMM')}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Expiry Year
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.expiryYear}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Issuer Country
                                        </td>
                                        <td className="text-right">
                                            {this.state.creditCardIssuerCountry}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Personal
                                        </td>
                                        {!this.state.paymentMethod.personal &&
                                        <td className="text-right">
                                            No
                                        </td>}
                                        {this.state.paymentMethod.personal &&
                                        <td className="text-right">
                                            Yes
                                        </td>}
                                    </tr>
                                    <tr className="small">
                                        <td>
                                            Regulated
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod?.regulated ? 'Yes' : 'No' }
                                        </td>
                                    </tr>
                                    </tbody>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                    <tbody>
                                    <tr>
                                        <td className="font-weight-bold">
                                            Bank Account Number
                                        </td>
                                        <td className="font-weight-bold text-right">
                                            ************{this.state.paymentMethod.last4}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Routing Number
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.routingNumber}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Account Holder
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.accountHolder}
                                        </td>
                                    </tr>
                                    </tbody>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_CASH' &&
                                    <tbody>
                                    <tr className="">
                                        <td className="">
                                            Amount
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.paymentMethod.amount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td className="">
                                            Transaction Fee
                                        </td>
                                        <td className="text-right">
                                            <FormattedNumber value={this.state.feeAmount} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td className="font-weight-bold">
                                            Total
                                        </td>
                                        <td className="font-weight-bold text-right">
                                            <FormattedNumber value={parseFloat(this.state.paymentMethod.amount) + parseFloat(this.state.feeAmount)} style={`currency`} currency="USD" />
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Property
                                        </td>
                                        <td className="text-right">
                                            {(this.state.billingAccount.joins && this.state.billingAccount.joins.p) &&
                                            <React.Fragment>
                                                {this.state.billingAccount.unit ? this.state.billingAccount.unit + ' - ' : ''}{this.state.billingAccount.joins.p.street1}
                                            </React.Fragment>
                                            }
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Managed By
                                        </td>
                                        <td className="text-right">
                                            {(this.state.billingAccount.joins && this.state.billingAccount.joins.c) &&
                                            <React.Fragment>
                                                {this.state.billingAccount.joins.c.name}
                                            </React.Fragment>
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                    }
                                    {this.state.paymentMethod.type === 'TYPE_PAY_PAL' &&
                                    <tbody>
                                    {/* <tr>
                                        <td className="font-weight-bold">
                                            Paypal Account 
                                        </td>
                                        <td className="font-weight-bold text-right">
                                            {this.state.paymentMethod.email}
                                        </td>
                                    </tr> */}
                                    <tr className="small">
                                        <td className="">
                                            Name
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.firstName +" "+ this.state.paymentMethod.lastName}
                                        </td>
                                    </tr>
                                    <tr className="small">
                                        <td className="">
                                            Country
                                        </td>
                                        <td className="text-right">
                                            {this.state.paymentMethod.country}
                                        </td>
                                    </tr>
                                    </tbody>
                                    }
                                </table>
                            </div>

                            <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                                <div className="receipt-actions">

                                    <button type="button" className="btn btn-outline-primary btn-lg mx-1" data-dismiss="modal">
                                        <FormattedMessage id="button.close" />
                                    </button>

                                    {this.state.paymentMethod.type === 'TYPE_CASH' &&
                                    <div onClick={() => {$('#payment-method').modal('hide'); $('#edit-payment-method').modal('show');}} className="btn btn-primary btn-lg mx-1" data-dismiss="modal">
                                        Edit
                                    </div>
                                    }

                                    {this.state.paymentMethod.type === 'TYPE_CASH' &&
                                    <div className="btn btn-primary btn-lg mx-1" onClick={() => document.execCommand('print', false, null)}>
                                        Print
                                    </div>
                                    }

                                    {!this.props.singlePaymentMethod &&
                                    <div onClick={() => {$('#payment-method').modal('hide');$('#delete-payment-method').modal('show');}} className="btn btn-primary btn-lg mx-1" data-dismiss="modal">
                                        Delete
                                    </div>
                                    }

                                    {this.props.singlePaymentMethod &&
                                    <div onClick={() => this.initPaymentMethod('credit_card', true)} className="btn btn-primary btn-lg mx-1" data-dismiss="modal">
                                        Update
                                    </div>
                                    }

                                </div>

                                <div className="receipt-disclaimer d-none">
                                    <p className="my-2">Making your cash payment is easy!</p>
                                    <p className="my-2">1. Make sure the payment amount on your payment slip above is correct.</p>
                                    <p className="my-2">2. Visit your nearby Canada Post and present your QR code above on your phone (or printed) to the clerk.</p>
                                    <p className="my-2">3. Make your payment with debit card or cash and get a receipt for your transaction.</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="modal fade" id="edit-payment-method" tabIndex="-1" role="dialog" aria-labelledby="edit-payment-method-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.savePaymentSlip}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="edit-payment-method-label">
                                        Edit Cash Payment Slip
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">
                                    <p className="mb-0">
                                        Enter your new payment amount below to update your cash payment slip. Upon submission, a transaction fee of ${this.state.feeAmount} will automatically be added to your amount.
                                    </p>
                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger"/>

                                    <FieldText id="amount" type="number" iconType="fas" iconName="dollar-sign" model="paymentMethod" step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?" labelClass="col-form-label-lg d-none" placeholder="0.00" fieldClass="form-control-lg" fieldColumns="12" labelColums="0" required={true} parent={this} value={this.state.paymentMethod['amount']} min={10} max={1993}/>

                                </div>
                                <div className="modal-footer d-block bg-secondary rounded-bottom">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => {$('#edit-payment-method').modal('hide'); $('#payment-method').modal('show');}}>Back</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSave/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <FeeProfiles billingAccountList={this.state.billingAccountList} />

                <ModalUnionPay parent={this} />

                <Modal id="delete-payment-method" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Payment Method"
                       body="Are you sure you want to delete this payment method?">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$('#delete-payment-method').modal('hide'); $('#payment-method').modal('show');}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deletePaymentMethod((this.state.paymentMethod.type).substring(5), this.state.paymentMethod.id)}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Payment Method
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

PaymentMethods.defaultProps = {
    cardHeader: 'Payment Methods'
};

PaymentMethods.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(PaymentMethods);