import axios from 'axios';
import { useQuery } from 'react-query';
import * as constants from '../../../../util/constants';
import { useState } from 'react';

const useHyperwallet = (companyId) => {
  const [hyperwallet, setHyperwallet] = useState({});

  const payload = {
    orderBy: 'DESC',
    orderByFields: ['createDate'],
    conditionList: [
      {
        type: 'STRING',
        logicalOperator: 'AND',
        openBrackets: null,
        closeBrackets: null,
        fieldName: 'companyId',
        operator: 'EQUALS',
        fieldValue: companyId,
      },
    ],
  };

  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      localStorage.getItem('token_type') +
      ' ' +
      localStorage.getItem('access_token'),
  };

  return {
    hyperwallet,
    hyperwalletQuery: useQuery(
      'hyperwalletDetails',
      () =>
        axios
          .post(
            `${constants.REACT_APP_HOST_API_URL}/hyper_wallet_page/search`,
            payload,
            {
              headers: headers,
            }
          )
          .then((res) => res.data),
      {
        onSuccess: (data) => {
            if(data?.records.length) {
                setHyperwallet(data?.records[0]);
            }
        },
      }
    ),
  };
};

export default useHyperwallet;
