import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage, injectIntl, intlShape } from "react-intl";
import modernHouse from "../media/img/backgrounds/modern-apartment.jpg";

class SessionExpired extends Component {

    /**
     * Clear the localStorage, as the user has logged out because the session has expired.
     */
    componentDidUpdate() {

        localStorage.removeItem('token_type');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('status');
        localStorage.removeItem('screening');
        localStorage.removeItem('broadcast_messages');
        sessionStorage.removeItem('session_role_id');

    }

    /**
     * Render the component.
     *
     * @returns {*} - An error page that lets the user know that their log in session has expired.
     */
    render() {

        return(
            <div className="content-block">

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${modernHouse}') no-repeat center center scroll`}}>
                    <div className={`container text-md-center`}>
                        <h1 className="display-5 pt-3 text-white">
                            <FormattedMessage id="static.expired.heading" />
                        </h1>
                        <p className="lead text-white">
                            <FormattedMessage id="static.expired.subheading" />
                        </p>
                    </div>
                </div>

                <div className="container">

                    <div className="row">
                        <div className="col">
                            <h5 className="mb-5">
                                <FormattedHTMLMessage id="static.expired.body" />
                            </h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col align-self-center">
                                            <FormattedMessage id="static.expired.help.heading" />
                                        </div>
                                        <div className="col align-self-center">
                                            <div className="float-right">
                                                <a href="http://help.rentmoola.com/" target="_blank" className="btn btn-primary btn-sm" rel="noopener noreferrer">
                                                    <FormattedMessage id="static.expired.help.button" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4 align-self-center text-center">
                                            <FontAwesomeIcon icon={['fal', 'question-circle']} className={`fa-fw text-primary py-3`} size="7x" />
                                        </div>
                                        <div className="col-8 align-self-center">
                                            <p className="card-text mb-0">
                                                <FormattedMessage id="static.expired.help.body" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col align-self-center">
                                            <FormattedMessage id="static.expired.review.heading" />
                                        </div>
                                        <div className="col align-self-center">
                                            <div className="float-right">
                                                <a href="http://bit.ly/2oYIvoJ" target="_blank" className="btn btn-primary btn-sm" rel="noopener noreferrer">
                                                    <FormattedMessage id="static.expired.review.button" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-4 align-self-center text-center">
                                            <FontAwesomeIcon icon={['fal', 'smile']} className={`fa-fw text-primary py-3`} size="7x" />
                                        </div>
                                        <div className="col-8 align-self-center">
                                            <p className="card-text mb-0">
                                                <FormattedMessage id="static.expired.review.body" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };

}

SessionExpired.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SessionExpired);