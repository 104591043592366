import React from 'react';
import familyMoving from "../../media/img/backgrounds/couple-laptop.jpg";

const SubmittedApplication = () => {

    return(
        <div className="content-block">
            <div className="content-header mb-0">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-6 col-12 align-self-center pr-md-5">
                            <div className="jumbotron jumbotron-fluid bg-transparent mb-0">
                                <h1 className="display-5 mb-2">
                                    Thanks!
                                </h1>
                                <h3 className="mb-4">
                                    Your application is under review
                                </h3>
                                <div className="">
                                    <p className="mb-4">
                                        Thanks for completing Income and Identity Verification. 
                                        Your lease application will be submitted as soon as your information has been verified. 
                                        A confirmation email will be sent to you shortly.
                                    </p>
                                </div>
                                <button onClick={()=> window.location.href = '/customer/dashboard'} className="btn btn-lg btn-primary btn-block">
                                    OK
                                </button>
                            </div>
                        </div>
                        <div className="col-md-6 d-none d-md-block align-self-center">
                            <div style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`, backgroundSize: "cover", height: "675px", width: "998px"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubmittedApplication;
