import axios from 'axios';
import * as constants from '../../../../util/constants';

import { useMutation, useQueryClient } from 'react-query';

const useCreateUpdateHyperwallet = () => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization:
      localStorage.getItem('token_type') +
      ' ' +
      localStorage.getItem('access_token'),
  };

  const queryClient = useQueryClient();
  
  return {
    createUpdateHyperwalletQuery: useMutation(
      (payload) =>
        axios
          [payload.method](`${constants.REACT_APP_HOST_API_URL}/${payload.action}`, payload.params, {
            headers: headers,
          })
          .then((res) => res.data),
      {
        onSuccess: () => {
            queryClient.invalidateQueries('hyperwalletDetails');
        }
      }
    ),
  };
};

export default useCreateUpdateHyperwallet;
