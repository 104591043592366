import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import JumbotronHelp from "../../common/JumbotronHelp";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import Documents from "./Documents";
import OpenCharges from "./OpenCharges";
import Tenant from "./Tenant";
import Transactions from "./Transactions";

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            customerFirstName: '',
            customerLastName: '',
            validationList: [],
        };

        this.refreshPropertyLease = this.refreshPropertyLease.bind(this);
    }

    /**
     * Get the property based on the ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: response.data
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    customerFirstName: response.data.firstName,
                    customerLastName: response.data.lastName
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Force a refresh of this component if required by a child component. Because this component displays the
     * customer's first and last name in the breadcrumbs, this component should update if a child component makes an
     * update to said values.
     *
     * @param propertyLeaseId - The ID of the property lease to fetch data from.
     */
    refreshPropertyLease(propertyLeaseId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/property_lease/${propertyLeaseId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                propertyLease: response.data
            }));

            axios.get(`${constants.REACT_APP_HOST_API_URL}/customer/${response.data.userId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    customerFirstName: response.data.firstName,
                    customerLastName: response.data.lastName
                }));

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view property component for landlords.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/landlord/tenants" parentPage="Tenants" childPage={`${this.state.customerFirstName} ${this.state.customerLastName}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">
                                <div className="card-header">
                                    Options
                                </div>
                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/landlord/tenants/${this.props.match.params.propertyLeaseId}/edit`} active="edit" size="small" iconName="user" name="Tenant" />
                                        <NavListItem path={`/landlord/tenants/${this.props.match.params.propertyLeaseId}/statement`} active="statement" size="small" iconName="usd-circle" name="Statement" />
                                        <NavListItem path={`/landlord/tenants/${this.props.match.params.propertyLeaseId}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                        <NavListItem path={`/landlord/tenants/${this.props.match.params.propertyLeaseId}/documents`} active="documents" size="small" iconName="file-alt" name="Documents" />

                                    </div>
                                </div>
                            </div>

                            <JumbotronHelp icon="question-circle"
                                           heading="Need a hand?"
                                           body="Whether you're troubleshooting an issue or learning something new, our Help Center has you covered."
                                           buttonText="Visit the Help Center"
                                           buttonIcon="external-link-square-alt"
                                           buttonUrl="https://help.rentmoola.com/hc/en-us/sections/360006583852-Landlords" />

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/edit" type="TYPE_LANDLORD" parent={this} component={Tenant} refreshPropertyLease={(propertyLeaseId) => this.refreshPropertyLease(propertyLeaseId)} />
                                <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/statement" type="TYPE_LANDLORD" parent={this} component={OpenCharges} />
                                <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/transactions" type="TYPE_LANDLORD" parent={this} component={Transactions} />
                                <PrivateRoute exact path="/landlord/tenants/:propertyLeaseId/documents" type="TYPE_LANDLORD" parent={this} component={Documents} />
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);