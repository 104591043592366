import React, { Component } from 'react';
import americanExpress from "../../media/img/payments/american-express.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import creditCard from "../../media/img/payments/credit-card.png";
import dinersClub from "../../media/img/payments/diners-club.png";
import discover from "../../media/img/payments/discover.png";
import jcb from "../../media/img/payments/jcb.png";
import maestro from "../../media/img/payments/maestro.png";
import mastercard from "../../media/img/payments/mastercard.png";
import paypal from "../../media/img/payments/paypal.png";
import unionPay from "../../media/img/payments/unionpay.png";
import visaElectron from "../../media/img/payments/visa-electron.png";
import visa from "../../media/img/payments/visa.png";

class CardBrandIcon extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - An icon that displays the card brand based on the brand value provided in the props.
     */
    render() {

        return(
            <React.Fragment>

                {this.props.paymentMethodType === 'TYPE_BANK_ACCOUNT' &&
                <React.Fragment>

                    <img src={bankAccount} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Bank Account" title="Bank Account" />

                </React.Fragment>
                }

                {this.props.paymentMethodType === 'TYPE_CREDIT_CARD' &&
                <React.Fragment>

                    {(this.props.brand === '' || this.props.brand == null) &&
                    <img src={creditCard} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Credit Card or Debit Card" title="Credit Card or Debit Card" />
                    }

                    {this.props.brand === 'VISA' &&
                    <img src={visa} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Visa" title="Visa" />
                    }

                    {this.props.brand === 'VISA_ELECTRON' &&
                    <img src={visaElectron} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Visa Electron" title="Visa Electron" />
                    }

                    {this.props.brand === 'MASTERCARD' &&
                    <img src={mastercard} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Mastercard" title="Mastercard" />
                    }

                    {this.props.brand === 'MAESTRO' &&
                    <img src={maestro} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Maestro" title="Maestro" />
                    }

                    {this.props.brand === 'AMERICAN_EXPRESS' &&
                    <img src={americanExpress} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="American Express" title="American Express" />
                    }

                    {this.props.brand === 'DISCOVER' &&
                    <img src={discover} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Discover" title="Discover" />
                    }

                    {this.props.brand === 'DINERS_CLUB' &&
                    <img src={dinersClub} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="Diners Club" title="Diners Club" />
                    }

                    {this.props.brand === 'JCB' &&
                    <img src={jcb} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="JCB" title="JCB" />
                    }

                    {this.props.brand === 'UNION_PAY' &&
                    <img src={unionPay} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="UnionPay" title="UnionPay" />
                    }
                </React.Fragment>
                }

                {this.props.paymentMethodType === 'TYPE_PAY_PAL' &&
                <React.Fragment>
                    <img src={paypal} className={`rounded border ${this.props.customClasses}`} width={this.props.width} alt="PayPal" title="PayPal" />
                </React.Fragment>
                }

            </React.Fragment>
        )
    };
}

export default CardBrandIcon;
