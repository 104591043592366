import React from 'react';
import { PAYER_FAQ } from '../../../../constants/HyperWalletConstants';
import heroPayer from '../../../../media/img/backgrounds/hero-payer-faq.png';
import styles from '../css/HyperWalletFaq.module.css';
import HyperWalletFaq from './HyperWalletFaq';

const HyperWalletPayerFaq = () => {
  return (
    <div className={`container ${styles.containerCustom}`}>
      <div className={`row ${styles.payerHero}`}>
        <div className={`col-md`}>
          <h4 className={`row ${styles.payerHeading}`}>For Payer</h4>
        </div>
        <div className={`col-md`}>
          <img src={heroPayer} className={`d-block mx-auto img-fluid  ${styles.payerImg}`} alt='' />
        </div>
      </div>
      <div className={`row ${styles.faqAccordionRoot}`}>
        <HyperWalletFaq faqs={PAYER_FAQ} showExternalFaq/>
      </div>
    </div>
  );
};

export default HyperWalletPayerFaq;
