import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import * as moment from "moment";
import React from "react";
import { injectIntl } from "react-intl";
import Moment from "react-moment";
import { TYPE_COMMON } from "../../constants/CommonConstants";
import * as constants from "../../util/constants";
import Table from "../common/Table";
import Alert from "./Alert";
import FieldDate from "./FieldDate";
import FieldSelect from "./FieldSelect";
import Propertii from "./Propertii";
import Spinner from "./Spinner";


class SyncsCommon extends Propertii {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: "SYNCS",
      selectedId:'',
      selectedName:'',
      startDate: null,
      endDate: null,
      syncStatusFilter: null,
      syncForFilter:
        this.props.syncFor === TYPE_COMMON.TYPE_TENANT
          ? TYPE_COMMON.TYPE_PROPERTIES
          : TYPE_COMMON.TYPE_COMPANIES,
      syncFor:
          this.props.syncFor === TYPE_COMMON.TYPE_TENANT
            ? TYPE_COMMON.TYPE_PROPERTIES
            : TYPE_COMMON.TYPE_COMPANIES,

      syncsList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },

      historySyncsList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },

      syncQuery: {
        orderBy: "ASC",
        orderByFields: [
          this.props.syncFor === TYPE_COMMON.TYPE_TENANT
            ? "tenantSyncStatus"
            : "syncStatus",
        ],
      },

      historySyncQuery: {
        orderBy: "DESC",
        orderByFields: ["syncStartTime"],
      },

      validationList: [],
    };
    this.selectTab = this.selectTab.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.fetchSyncLogs = this.fetchSyncLogs.bind(this);
    this.initializeComponent = this.initializeComponent.bind(this);
    this.filterSyncs = this.filterSyncs.bind(this);
    this.setAdditionalFilter = this.setAdditionalFilter.bind(this);
    this.showHistorycSync = this.showHistorycSync.bind(this);
    this.fetchHistorySyncLogs = this.fetchHistorySyncLogs.bind(this);
  }

  /**
   * Populate the list of syncs on mounting of the component.
   * * The tabs have been redesigned and instead of showing success and failure we are just showing one tab.
   * * The Failures tab has been renamed to Syncs, and the Success tab is now hidden.
   */
  componentDidMount() {
    this.selectTab("SYNCS");

    this.initializeComponent();
  }

  /**
   * In the case some of the props might get updated later
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.companyId !== this.props.companyId ||
      prevProps.propertyId !== this.props.propertyId
    ) {
      this.initializeComponent();
    }
  }

  /**
   * Initialize component based on certain settings
   */
  initializeComponent() {
    this.filterSyncs(null); //fetch syncs with default filters
  }

  /**
   * Sets basic query for company
   */
  setCompanyQuery() {
    const query = this.state.syncQuery;

    query.conditionList = [
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: null,
        fieldName: "parentId",
        operator: "EQUALS",
        fieldValue: this.props.companyId,
      },
      {
        type: "STRING",
        logicalOperator: "OR",
        openBrackets: null,
        closeBrackets: ")",
        fieldName: "id",
        operator: "EQUALS",
        fieldValue: this.props.companyId,
      },
    ];

    query.joins = {
      c: {
        targetRecordType: "TYPE_COMPANY",
        joinField: "companyId",
        alias: "c",
        returnFields: ["name"],
      },
    };

    return query;
  }

  /**
   * Sets basic query for company
   */
   setHistoryCompanyQuery() {
    const query = this.state.historySyncQuery;

    query.conditionList = [
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: null,
        fieldName: "parentId",
        operator: "EQUALS",
        fieldValue: this.props.companyId,
      },
      {
        type: "STRING",
        logicalOperator: "OR",
        openBrackets: null,
        closeBrackets: ")",
        fieldName: "id",
        operator: "EQUALS",
        fieldValue: this.props.companyId,
      },
    ];

    query.joins = {
      c: {
        targetRecordType: "TYPE_COMPANY",
        joinField: "companyId",
        alias: "c",
        returnFields: ["name"],
      },
    };

    return query;
  }

   /**
   * Sets basic query for history property
   */
    setHistoryPropertyQuery() {
      const query = this.state.historySyncQuery;
  
      query.conditionList = [
        {
          type: "STRING",
          logicalOperator: "AND",
          openBrackets: "(",
          closeBrackets: ")",
          fieldName: "propertyId",
          operator: "EQUALS",
          fieldValue: this.props.propertyId,
        },
      ];
  
      query.joins = {
        p: {
          targetRecordType: "TYPE_PROPERTY",
          joinField: "propertyId",
          alias: "p",
          returnFields: ["propertyName"],
        },
      };
      return query;
    }

  /**
   * Sets basic query for property
   */
  setPropertyQuery() {
    const query = this.state.syncQuery;

    query.conditionList = [
      {
        type: "STRING",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: ")",
        fieldName: "propertyId",
        operator: "EQUALS",
        fieldValue: this.props.propertyId,
      },
    ];

    query.joins = {
      p: {
        targetRecordType: "TYPE_PROPERTY",
        joinField: "propertyId",
        alias: "p",
        returnFields: ["propertyName"],
      },
    };
    return query;
  }

  /**
   * Clear all applicable filters and re-run the original queries.
   */
  clearFilters() {
    this.setState(
      (prevState) => ({
        ...prevState,
        startDate: null,
        endDate: null,
        syncStatusFilter: null,
        syncForFilter:
          this.props.syncFor === TYPE_COMMON.TYPE_TENANT
            ? TYPE_COMMON.TYPE_PROPERTIES
            : TYPE_COMMON.TYPE_COMPANIES,
      }),
      () => {
        this.filterSyncs(null);
      }
    );
  }

  /**
   * Select a tab from the list of navigation tabs.
   *
   * @param tabId - The ID of the tab selected.
   */
  selectTab(tabId) {
    const syncModal = $("#failed-sync");
    const successSyncModal = $("#success-sync");

    switch (tabId) {
      case "SYNCS":
        successSyncModal.tab("dispose");
        syncModal.tab("show");
        break;

      case "SUCCESS_SYNC":
        successSyncModal.tab("show");
        syncModal.tab("dispose");
        break;

      default:
        successSyncModal.tab("dispose");
        syncModal.tab("show");
        break;
    }
  }

  /**
   * Add additional filters to the current query
   */
  setAdditionalFilter(query) {
    if (this.state.startDate) {
      query.conditionList.push({
        type: "DATE",
        logicalOperator: "AND",
        openBrackets: "(",
        closeBrackets: this.state.endDate ? null : ")",
        fieldName:
          this.props.syncFor === TYPE_COMMON.TYPE_TENANT
            ? "tenantSyncStartTime"
            : "syncStartTime",
        operator: "GREATER_THAN_OR_EQUALS",
        fieldValue: moment(this.state.startDate).format(
          "YYYY-MM-DD[T]hh:mm:ssZZ"
        ),
      });
    }

    if (this.state.endDate) {
      query.conditionList.push({
        type: "DATE",
        logicalOperator: "AND",
        openBrackets: this.state.startDate ? null : "(",
        closeBrackets: ")",
        fieldName:
          this.props.syncFor === TYPE_COMMON.TYPE_TENANT
            ? "tenantSyncEndTime"
            : "syncEndTime",
        operator: "LESS_THAN_OR_EQUALS",
        fieldValue: moment(this.state.endDate).format(
          "YYYY-MM-DD[T]hh:mm:ssZZ"
        ),
      });
    }

    /**
     * !This is temporary until we get more details fo tenant sync
     */
    if(this.props.syncFor === TYPE_COMMON.TYPE_COMPANY || this.props.syncFor === TYPE_COMMON.TYPE_PROPERTY) {
      if (this.state.syncStatusFilter) {
        query.conditionList.push({
          type: "STRING",
          logicalOperator: "AND",
          openBrackets: "(",
          closeBrackets: null,
          fieldName:
            this.props.syncFor === TYPE_COMMON.TYPE_TENANT
              ? "tenantSyncStatus"
              : "syncStatus",
          operator: "EQUALS",
          fieldValue: this.state.syncStatusFilter,
        });
        query.conditionList.push({
          type: "STRING",
          logicalOperator: "OR",
          openBrackets: null,
          closeBrackets: null,
          fieldName: "merchantBankAccountSyncStatus",
          operator: "EQUALS",
          fieldValue: this.state.syncStatusFilter === "COMPLETED" ? "true" : "false",
        });
        query.conditionList.push({
          type: "STRING",
          logicalOperator: "OR",
          openBrackets: null,
          closeBrackets: ")",
          fieldName: "merchantCreditAccountSyncStatus",
          operator: "EQUALS",
          fieldValue: this.state.syncStatusFilter === "COMPLETED" ? "true" : "false",
        });
      }
    }

    /**
     * !This is temporary until we get more details fo tenant sync
     */
    if(this.props.syncFor === TYPE_COMMON.TYPE_TENANT) {
      if (this.state.syncStatusFilter) {
        query.conditionList.push({
          type: "STRING",
          logicalOperator: "AND",
          openBrackets: "(",
          closeBrackets: ")",
          fieldName:
            this.props.syncFor === TYPE_COMMON.TYPE_TENANT
              ? "tenantSyncStatus"
              : "syncStatus",
          operator: "EQUALS",
          fieldValue: this.state.syncStatusFilter,
        });
      }
    }
    return query;
  }

  /**
   * Filter the syncs based on selected filters
   */
  filterSyncs(event) {
    if (event != null) {
      event.preventDefault();
    }

    if (!this.props.companyId) {
      return;
    }

    this.state.syncFor = this.state.syncForFilter;

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    let query = {};

    if (
      (this.props.propertyId &&
        this.props.syncFor === TYPE_COMMON.TYPE_PROPERTY) ||
      this.state.syncForFilter === TYPE_COMMON.TYPE_PROPERTIES
    ) {
      query = this.setPropertyQuery();
    } else if (
      this.props.companyId &&
      this.props.syncFor === TYPE_COMMON.TYPE_COMPANY
    ) {
      query = this.setCompanyQuery();
    }

    query = this.setAdditionalFilter(query);

    if (
      this.state.syncForFilter === TYPE_COMMON.TYPE_PROPERTIES && !this.props.propertyId
    ) {
      this.getAllCompanies(this.props.companyId)
        .then((response) => {
          const companies = response.data?.records;

          if (companies.length) {
            query.conditionList = [];
            companies.forEach((company, key) => {
              query.conditionList.push({
                type: "STRING",
                logicalOperator: key === 0 ? "AND" : "OR",
                openBrackets: key === 0 ? "(" : "null",
                closeBrackets: key === companies.length - 1 ? ")" : "null",
                fieldName: "companyId",
                operator: "EQUALS",
                fieldValue: company.id,
              });
            });
          }
          query = this.setAdditionalFilter(query);
          this.fetchSyncLogs(1, 5, query);
        })
        .catch((error) => {
          this.stopSpinner();
          this.handleValidation(error);
          this.handleClearValidationList();
        });
    } else {
      this.fetchSyncLogs(1, 5, query);
    }
  }

  /**
   * Get all the sync logs for a company, or the tenant
   */
  fetchSyncLogs(page, recordsPerPage, query) {
    let endpoint = null;

    if (
      (this.props.propertyId &&
        this.props.syncFor === TYPE_COMMON.TYPE_PROPERTY) ||
      this.state.syncForFilter === TYPE_COMMON.TYPE_PROPERTIES
    ) {
      endpoint = "PROPERTY_PAY_BY_TEXT_SERVICE_INFO";
    } else if (
      this.props.companyId &&
      this.props.syncFor === TYPE_COMMON.TYPE_COMPANY
    ) {
      endpoint = "COMPANY_PAY_BY_TEXT_SERVICE_INFO";
    }

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/${endpoint}/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
          joins: query.joins,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          syncsList: {
            ...response.data,
          },
          spinner: false,
        }));
      })
      .catch((error) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));
        this.handleValidation(error);
        this.handleClearValidationList();
      });
  }

  /**
   * Get all the historyc sync logs for a company, or the tenant
   */
   fetchHistorySyncLogs(page, recordsPerPage) {
    let endpoint;

    if(this.state.syncFor === 'properties' || this.props.syncFor === 'property'){
      endpoint = 'property'
    }else{
      endpoint = 'company'
    }

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/${endpoint}/${this.state.selectedId}/history?recordsPerPage=${recordsPerPage}&page=${page}&orderBy=DESC&fieldName=syncStartTime`,
        {},
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          historySyncsList: {
            ...response.data,
          },
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
        this.handleClearValidationList();
      });
  }
  /**
   * Show all the hystoric sync for company, or property
   */
  showHistorycSync(id, recordsPerPage, page, name){
    let endpoint;

    if(this.props.syncFor === TYPE_COMMON.TYPE_TENANT ) return;

    this.setState((prevState) => ({
      ...prevState,
      selectedId: id,
      selectedName: name,
    }));

    if(this.state.syncFor === 'properties' || this.props.syncFor === 'property'){
      //query = this.setHistoryCompanyQuery();
      endpoint = 'property'
    }else{
      //query = this.setHistoryPropertyQuery();
      endpoint = 'company'
    }
    
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/${endpoint}/${id}/history?recordsPerPage=${recordsPerPage}&page=${page}&orderBy=DESC&fieldName=syncStartTime`,
        {},
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          historySyncsList: {
            ...response.data,
          },
          spinner: false,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
        this.handleClearValidationList();
      });

    $("#history-sync").modal("show");
  }

  /**
   * Render the component.
   *
   * @returns {*} - The common sync component.
   */
  render() {
    let columnLabels = {
      "c.name": lodash.capitalize(this.props?.syncFor),
      syncStatus: "Status Information",
      syncStartTime: "Date",
      merchantBankAccountSyncStatus: "Merchant Bank Account Status",
      merchantCreditAccountSyncStatus: "Merchant Credit Account Status",
    };
    let columnWidths = ["15%", "20%", "15%", "25%", "25%"];

    let columnLabelsHistory = {
      syncStatus: "Status Information",
      syncStartTime: "Date",
      merchantBankAccountSyncStatus: "Merchant Bank Account Status",
      merchantCreditAccountSyncStatus: "Merchant Credit Account Status",
    };
    let columnWidthsHistory = ["25%", "25%", "25%", "25%"];

    if (this.props.syncFor === TYPE_COMMON.TYPE_TENANT) {
      columnLabels = {
        "p.propertyName": lodash.capitalize(TYPE_COMMON.TYPE_PROPERTY),
        tenantSyncStatus: "Status Information",
        tenantSyncStartTime: "Date",
      };
      columnWidths = ["33%", "33%", "33%"];
    }

    $(function() {
      $('[data-toggle="popover"]').popover()
    });

    const popoverFailedReason = (data) => {
      if((data?.syncStatus !== 'COMPLETED' && data?.syncStatus !== undefined) || (data?.tenantSyncStatus !== 'COMPLETED' && data?.tenantSyncStatus !== undefined)){
        return(
          <div data-toggle="popover"
              data-trigger="focus"
              tabIndex="0"
              data-html="true"
              title="Sync Failed Reason"
              className="btn-link d-inline c-pointer"
              data-content={data?.syncFailedReason}>
               <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 fa-fw small btn-link c-pointer" />
          </div>)
      }
    }

    return (
      <React.Fragment>
        <Spinner visible={this.state.spinner} />
        <Alert validationList={this.state.validationList} />

        <div className="card accordion" id="navigation">
          <div className={`card-header pb-0`}>
            <div className="row align-items-center">
              <div className="col">
                <ul className="nav nav-tabs border-bottom-0">
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.selectedTab === "SYNCS" ? "active" : ""
                      }`}
                      id="failed-sync-tab"
                      data-toggle="tab"
                      href="#failed-sync"
                      role="tab"
                      aria-controls="failed-sync"
                      aria-selected="true"
                      onClick={() => this.selectTab("SYNCS")}
                    >
                      Syncs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.selectedTab === "SUCCESS_SYNC"
                          ? "active"
                          : ""
                      } hidden`}
                      id="success-sync-tab"
                      data-toggle="tab"
                      href="#success-sync"
                      role="tab"
                      aria-controls="success-sync"
                      aria-selected="true"
                      onClick={() => this.selectTab("SUCCESS_SYNC")}
                    >
                      Success
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col text-right">
                {lodash.capitalize(this.props.syncFor)}
              </div>
            </div>
          </div>
          <div className="card-header gotham border-top py-3 bg-secondary">
            <form onSubmit={this.filterSyncs}>
              <div className="media">
                <div className="media-body align-self-center mr-3">
                  <FieldDate
                    id="startDate"
                    labelColumns="0"
                    fieldColumns="12"
                    parent={this}
                    value={this.state.startDate}
                    selectsRange={true}
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                  />
                </div>
                <div className="media-body align-self-center mr-3">
                  <FieldSelect
                    id="syncStatusFilter"
                    labelColumns="0"
                    fieldColumns="12"
                    parent={this}
                    value={this.state.syncStatusFilter}
                  >
                    <option value="">All Types</option>
                    <option value="COMPLETED">COMPLETED</option>
                    <option value="FAILED">FAILED</option>
                  </FieldSelect>
                </div>{" "}
                {!this.props.propertyId && (
                  <div className="media-body align-self-center mr-3">
                    <FieldSelect
                      id="syncForFilter"
                      labelColumns="0"
                      fieldColumns="12"
                      parent={this}
                      value={this.state.syncForFilter}
                    >
                      {this.props.syncFor !== TYPE_COMMON.TYPE_TENANT && (
                        <option value={TYPE_COMMON.TYPE_COMPANIES}>
                          {lodash.capitalize(TYPE_COMMON.TYPE_COMPANIES)}
                        </option>
                      )}
                      <option value={TYPE_COMMON.TYPE_PROPERTIES}>
                        {lodash.capitalize(TYPE_COMMON.TYPE_PROPERTIES)}
                      </option>
                    </FieldSelect>
                  </div>
                )}
                <div className="align-self-center text-right">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="submit"
                      className="btn btn-secondary btn-sm mb-0"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "search"]}
                        className="fa-fw"
                      />
                      Search
                    </button>
                    <div
                      className="btn btn-secondary btn-sm mb-0"
                      onClick={() => this.clearFilters()}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "eraser"]}
                        className="fa-fw"
                      />
                      Clear
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Table
            columns={columnLabels}
            columnWidths={columnWidths}
            headerClass="c-pointer"
            data={this.state.syncsList}
            query={this.state.syncQuery}
            sortEnabled={true}
            recordsEnabled={true}
            updateFunction={this.fetchSyncLogs}
            recordsOptions={["5", "25", "50", "100"]}
            tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
          >
            <tbody>
              {this.state.syncsList.records.map((data, key) => {
                if(this.props.syncFor === TYPE_COMMON.TYPE_TENANT && !data?.tenantSyncStatus) {
                  return null;
                }
                return (
                  <tr key={key} onClick={() => {this.showHistorycSync(data.id, 5, 1, data.joins?.c?.name || data.joins?.p?.propertyName)}}  style={{cursor: this.props.syncFor !== TYPE_COMMON.TYPE_TENANT ? 'pointer' : 'normal'}} > 
                    <td>
                      <div>
                        {data.joins?.c?.name || data.joins?.p?.propertyName}
                      </div>
                    </td>
                    <td>
                      <div>
                        {this.props.syncFor === TYPE_COMMON.TYPE_TENANT
                          ? data?.tenantSyncStatus
                          : data?.syncStatus} 

                        {popoverFailedReason(data)}
                      </div>
                    </td>
                    <td>
                      <div>
                        <Moment format="MMM DD, YYYY">
                          {this.props.syncFor === TYPE_COMMON.TYPE_TENANT
                            ? data?.tenantSyncStartTime
                            : data?.syncStartTime}
                        </Moment>
                      </div>
                    </td>
                    {this.props.syncFor !== TYPE_COMMON.TYPE_TENANT && (
                      <>
                        <td>
                          <div>{data?.merchantBankAccountSyncStatus}</div>
                        </td>
                        <td>
                          <div>{data?.merchantCreditAccountSyncStatus}</div>
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="modal fade" id="history-sync" tabIndex="-1" role="dialog" aria-labelledby="history-sync-label" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content shadow">
                    <div className="modal-header bg-dark text-white">
                        <h5 className="modal-title" id="history-sync-label">
                            Hystoric Syncs for {this.state.selectedName}
                        </h5>
                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                        </button>
                    </div>
                    <div className="card modal-body" style={{padding: '0', margin:'1rem'}}>
                      <Table
                        columns={columnLabelsHistory}
                        columnWidths={columnWidthsHistory}
                        headerClass="c-pointer"
                        data={this.state.historySyncsList}
                        query={this.state.historySyncQuery}
                        sortEnabled={false}
                        recordsEnabled={false}
                        updateFunction={this.fetchHistorySyncLogs}
                        recordsOptions={["5", "25", "50", "100"]}
                        tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                      >
                        <tbody>
                          {this.state.historySyncsList.records.map((data, key) => {
                            return (
                              <tr key={key} >
                                <td>
                                  <div>
                                    {this.props.syncFor === TYPE_COMMON.TYPE_TENANT
                                      ? data?.tenantSyncStatus
                                      : data?.syncStatus} 
                                  </div>
                                </td>
                                <td>
                                  <div>
                                    <Moment format="MMM DD, YYYY">
                                       {data?.syncStartTime}
                                    </Moment>
                                  </div>
                                </td>
                                    <td>
                                      <div>{data?.merchantBankAccountSyncStatus}</div>
                                    </td>
                                    <td>
                                      <div>{data?.merchantCreditAccountSyncStatus}</div>
                                    </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="modal-footer bg-secondary rounded-bottom d-block">
                        <div className="row">
                            <div className="col-12 text-right">
                              <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#history-sync").modal("hide")}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default injectIntl(SyncsCommon);
