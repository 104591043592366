import React, { Component } from 'react';


class Terms extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - The Letus terms of use contents.
     */
    render() {

        return(
            <React.Fragment>

            <h5>Definitions</h5>

            <p>When used in this Agreement, each of the following words and phrases shall have the meanings set out below:</p>

            <p>(a) "<span className="font-weight-bold">Agreement</span>" means these Terms of Use, including related schedules, policies and other documents that appear under "Legal" on the Website, as may be amended from time to time;</p>
            <p>(b) "<span className="font-weight-bold">Information</span>" means Credit Information, Personal Information, and information about the services you use that are provided by the Landlord as listed in this rental application and information relating to your tenancy at the premises applied for in this rental application including information regarding the duration of your tenancy, monthly rent, emergency contacts and any matters relating to your lease/tenancy agreement, including misrepresentations relating to, defaults under and/or breaches of your lease/tenancy agreement or any other matter experienced by the Landlord;</p>
            <p>(c) "<span className="font-weight-bold">Credit Information</span>" means information about you, including your name, age, date of birth, occupation, place of residence, previous places of residence, occupancy length, marital status, co-occupant's/spouse's/same-sex partner's name and age, number of dependants, particulars of education or professional qualifications, field of employment, places of employment, previous places of employment, employment durations, estimated income, paying habits, outstanding debt obligations, cost of living obligations, involvement in bankruptcy proceedings or landlord and tenant disputes, assets, and banking information (including account and credit card information);</p>
            <p>(c) "<span className="font-weight-bold">Personal Information</span>" means information about you other than credit information that is relevant to your suitability as a tenant, including information gathered from references (provided by you to the Landlord) concerning your character, reputation, physical or personal characteristics or mode of living or about any other matter or experience concerning you that is relevant to your suitability as a tenant.</p>
            <p>(d) "<span className="font-weight-bold">Landlord</span>" means the person or entity, including but not limited to property management companies, as listed in this rental application and information relating to your tenancy at the premises applied for in this rental application;</p>

            <h5>Collection, Use and Disclosure of Information</h5>

            <p>In considering for the property manager/landlord accepting you as a tenant and entering into a lease/tenancy agreement with you, you expressly consent to and authorize the following:</p>

            <p className="mb-0">
                <ol>
                    <li className="mb-3">The property manager/landlord may obtain information about you through a tenancy report conducted by Certn and/or credit report conducted by Equifax.</li>
                    <li className="mb-3">The property manager/landlord may use information about you to determine your suitability as a tenant and as permitted or required by law.</li>
                    <li className="mb-3">
                        The property manager/landlord may disclose credit information about you Equifax. as permitted or required by law, for inclusion within a database of rent-roll information and within a tenancy file on you, for the purposes of:
                        <ul>
                            <li className="mb-3">tenant reporting and credit reporting in accordance with provincial or state credit and consumer reporting acts;</li>
                            <li className="mb-3">establishing a credit history and or rental history</li>
                            <li className="mb-3">maintaining aggregate statistical data for purposes of tenancy and credit scoring; and</li>
                            <li className="mb-3">supporting the credit approval process in accordance with governing legislation.</li>
                        </ul>
                    </li>
                    <li className="mb-3">You expressly authorize Equifax to retain positive Credit Information regarding you for the purposes outlined in section 3 above, for up to 20 (twenty) years. Negative Credit Information shall be maintained on record in accordance with provincial or state credit and consumer reporting acts.</li>
                    <li className="mb-3">You agree that all statements on this lease application are true and you expressly authorize all references given to release information about you to the Landlord for verification subject to sections 1 to 5.</li>
                    <li className="mb-0">You agree to abide by the privacy terms of our third-party Rentify Inc. You authorize them to verify your identity, income, and other personal information provided by you. <br></br> <a href="https://trustrentify.com/privacy" target="_blank" rel="noopener noreferrer">https://trustrentify.com/privacy</a></li>
                </ol>
            </p>
            </React.Fragment>
        )
    };
}

export default Terms;