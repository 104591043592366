import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Breadcrumb from "../../common/Breadcrumb";
import PaymentFlow from "../../common/Payment";
import Propertii from "../../common/Propertii";

class Payment extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    {this.props.location.state &&
                    <Breadcrumb parentPath="/manager/transactions" parentPage="Transactions" />
                    }

                    {!this.props.location.state &&
                    <Breadcrumb parentPath="/manager/transactions" parentPage="Transactions" childPage="Make a Payment" />
                    }

                    <PaymentFlow parent={this}
                                 userId={this.props.userSession.sessionRole.id}
                                 userType={this.props.userSession.sessionRole.type}
                                 userSession={this.props.userSession}
                                 redirectPath="/manager/transactions" />

                </div>

            </div>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);