import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Breadcrumb from "../../common/Breadcrumb";
import InvoiceList from "../../common/Invoices";
import PaymentMethodList from "../../common/PaymentMethods";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Billing extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The landlord subscription component.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/landlord/account" parentPage="Account" childPage="Billing" />

                    <PaymentMethodList userType="TYPE_LANDLORD" cardHeader="Payment Method" singlePaymentMethod={true} userId={this.props.userSession.sessionRole.id} createMessage="landlord.account.subscription.methods.created" deleteMessage="landlord.account.subscription.methods.created" updateMessage="landlord.account.subscription.methods.updated" />

                    <InvoiceList companyId={this.props.userSession.sessionRole.companyId} sessionRole={this.props.userSession.sessionRole} token={this.props.token} userType="TYPE_LANDLORD" userId={this.props.userSession.sessionRole.id}/>

                </div>

            </div>
        )
    };
}

Billing.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Billing);