import { useState, useCallback } from 'react';

export const useValidationList = (initialValue) => {
  const [validationList, setValidationList] = useState(initialValue);

  return {
    validationList,
    setValidationList: (error) => {
      setValidationList((prevState) => [...prevState, error]);
    },
    setAlertMessage: (message, type = 'danger') => {
      const error = {
        alert: {
          type,
          message,
        },
      };
      setValidationList((prevState) => [...prevState, error]);
    },
    addErrorToValidationList: useCallback((data) => {
      const error = {
        alert: {
          type: 'danger',
          code: data?.response?.data?.errorCode,
          message: data?.response?.data?.message,
        },
      };
      setValidationList((prevState) => [...prevState, error]);
    }, []),
    clearValidationList: useCallback(() => {
      setValidationList([]);
    },[])
  };
};
