import React from 'react';
import $ from 'jquery';
import styles from './css/HyperwalletPaymentLanding.module.css';

const HyperWalletBeginModal = (props) => {
  return (
    <div
      className='modal fade'
      id='hwBeginModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='hwBeginModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-lg' role='document'>
        <div className='modal-content px-3 py-5'>
          <div className='modal-header border-0 justify-content-center'>
            <h3 className='modal-title' id='hwBeginModalLabel'>
              Make sure you have what you need
            </h3>
          </div>
          <div className='modal-body'>
            <div className={`${styles.beginList}`}>
              <ul className='fa-ul'>
                <li>
                  <i className='fa-li fa fa-check-circle bs-stepper-info'></i>The address for which you want to pay rent, security deposit,
                  HOA, Utility, etc.
                </li>
                <li className='mt-4'>
                  <i className='fa-li fa fa-check-circle bs-stepper-info'></i>The information of the payee who will receive the payment including Company Name or
                  First Name and Last Name, Email Address, Phone Number.
                </li>
                <li className='mt-4'>
                  <i className='fa-li fa fa-check-circle bs-stepper-info'></i>Payment information such as Credit Card, Paypal, etc.
                </li>
              </ul>
            </div>
          </div>
          <div className='modal-footer border-0 justify-content-center'>
            <button
              type='button'
              className={`btn btn-secondary btn-sm text-uppercase rounded-4 ${styles.beginBtn}`}
              onClick={() => {
                $('#hwBeginModal').modal('hide');
                props.toggleShowPayRentNow(true);
              }}
            >
              Begin
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperWalletBeginModal;
