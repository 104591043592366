import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Propertii from "../../common/Propertii";
import TenantList from "../../common/Tenants";

class Tenants extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            landlord: null,
            validationList: [],
        };
    }

    /**
     * On mounting of the component, fetch the landlord data based on the landlord ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                landlord: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view customer billing accounts component for admins.
     */
    render() {

        return(
            <React.Fragment>

                {this.state.landlord &&
                <TenantList userType={this.props.userSession.sessionRole.type}
                            userId={this.props.userSession.sessionRole.id}
                            companyId={this.state.landlord.companyId}
                            createTenant={false}
                            textSearchPlaceholderOverrideTenants = "Search Landlord Tenants"
                            textSearchPlaceholderOverrideInvitations = "Search Landlord Invitations"
                            inviteTenant={false}
                            parent={this}
                            history={this.props.history} />
                }

            </React.Fragment>
        )
    };
}

Tenants.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Tenants);