import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Propertii from "../../common/Propertii";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        return(
            <React.Fragment>

                <TransactionList propertyId={this.props.match.params.propertyId} 
                                 userType="TYPE_MANAGER" parent={this}
                                 textSearchPlaceholderOverride = "Search Property Transactions" />

            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);