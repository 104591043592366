import React from 'react';

const TermsAndConditionsHWModal = (props) => {
  return (
    <div
      className='modal fade'
      id='termsAndConditionsHWModal'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='termsAndConditionsHWModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content p-3'>
          <div className='modal-header border-0 justify-content-center'>
            <div className='modal-title text-center'>
              Terms and Conditions
            </div>
          </div>
          <div className='modal-body'>
          <iframe src={props.termsAndConditionsUrl} height="500" width="100%" title="SplitIt Terms and Conditions iframe"/>
          </div>
          <div className='modal-footer border-0 justify-content-center'>
            <button
              type='button'
              className='btn btn-light'
              data-dismiss='modal'
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsHWModal;
