import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import { Switch } from 'react-router-dom';
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import Mimic from "../../common/Mimic";
import NavListItem from "../../common/NavListItem";
import PrivateRoute from "../../common/PrivateRoute";
import Propertii from "../../common/Propertii";
import CustomFields from "./CustomFields";
import Documents from "./Documents";
import Events from "./Events";
import Insurances from './Insurances';
import Notes from "./Notes";
import OpenCharges from "./OpenCharges";
import PaymentMethods from "./PaymentMethods";
import Services from "./Services";
import SystemAccount from "./SystemAccount";
import Tenant from "./Tenant";
import TextMessaging from "./TextMessaging";
import Transactions from "./Transactions";
import {NOTIFICATION_PROVIDERS} from '../../../constants/ServiceLevelConfigConstants'

class Navigation extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            propertyLease: {},
            firstName: '',
            lastName: '',
            validationList: [],
        };

        this.getPropertyLease = this.getPropertyLease.bind(this);
        this.deletePropertyLease = this.deletePropertyLease.bind(this);
        this.viewCompany = this.viewCompany.bind(this);
        this.viewProperty = this.viewProperty.bind(this);

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);
    }

    /**
     * On mounting of the component, fetch the property lease data and all open charges associated with the property
     * lease at hand.
     */
    componentDidMount() {

        this.getPropertyLease();

    }

    /**
     * Search for a list of billing accounts based on a certain condition.
     */
    searchBillingAccounts() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
            orderBy: 'ASC',
            orderByFields: ["c.firstName"],
            conditionList: [
                {
                    "type": "STRING",
                    "logicalOperator": "AND",
                    "openBrackets": null,
                    "closeBrackets": null,
                    "operator": "EQUALS",
                    "fieldName": "id",
                    "fieldValue": this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                "c": {
                    "targetRecordType": "TYPE_CUSTOMER",
                    "joinField": "userId",
                    "alias": "c",
                    "returnFields": [
                        "firstName",
                        "lastName",
                        "email",
                    ]
                }, 
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'id', 'manageBalance', 'overpaymentAllowed']
                }
            }
        },{
            headers: this.generateRequestHeaders()
        }).then(response => {
            const resp = response.data?.records[0];
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                propertyLease: resp,
                firstName: resp?.joins?.c?.firstName,
                lastName: resp?.joins?.c?.lastName,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Get the property lease data based on the property lease ID provided in the route.
     */
    getPropertyLease() {

        this.setState(prevState => ({
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: this.props.match.params.propertyLeaseId
                }
            ],
            joins: {
                property: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'property',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                },
                customer: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'customer',
                    returnFields: ['firstName', 'lastName']
                },
                company: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'company',
                    returnFields: ['name', 'landlordId', 'notificationProvider']
                }
            }
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data?.records.length) {
                this.setState(prevState => ({
                    ...prevState,
                    spinner: false,
                    propertyLease: response.data?.records[0],
                    firstName: response.data?.records[0]?.joins?.customer?.firstName,
                    lastName: response.data?.records[0]?.joins?.customer?.lastName,
                }));
            } else {
                this.searchBillingAccounts();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Delete the property lease currently being viewed.
     */
    deletePropertyLease() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/property_lease/${this.props.match.params.propertyLeaseId}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.props.history.push({
                pathname: `/admin/tenants`,
                state: {
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'manager.tenants.deleted'
                        }
                    }],
                }
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Redirect the user to the relevant company view (or landlord view, if applicable) component.
     */
    viewCompany(event) {

        if (event.metaKey || event.ctrlKey){
            window.open(`/admin/${this.state.propertyLease.joins && this.state.propertyLease.joins.company.landlordId ? 'landlords' : 'companies'}/${this.state.propertyLease.joins && this.state.propertyLease.joins.company.landlordId ? this.state.propertyLease.joins.company.landlordId : this.state.propertyLease.companyId}/edit`, "_blank");
        } else {
            this.props.history.push({
                pathname: `/admin/${this.state.propertyLease.joins && this.state.propertyLease.joins.company.landlordId ? 'landlords' : 'companies'}/${this.state.propertyLease.joins && this.state.propertyLease.joins.company.landlordId ? this.state.propertyLease.joins.company.landlordId : this.state.propertyLease.companyId}/edit`,
            });
        }
    }

    /**
     * Redirect the user to the relevant property view component.
     */
    viewProperty() {
        
        this.props.history.push({
            pathname: `/admin/properties/${this.state.propertyLease.propertyId}/edit`,
            state: {
                property: {
                    id: this.state.propertyLease.propertyId
                }
            }
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The view customer component for admins.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPath="/admin/tenants" parentPage="Tenants" childPage={`${this.state.firstName} ${this.state.lastName}`} />

                    <Alert validationList={this.state.validationList} />

                    <div className="row">
                        <div className="col-md-4">

                            <div className="card">

                                <div className="card-header">
                                    Options
                                </div>

                                <div className="card-body card-body-list">
                                    <div className="list-group">

                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/edit`} active="edit" size="small" iconName="user" name="Tenant" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/statement`} active="statement" size="small" iconName="usd-circle" name="Billing" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/services`} active="services" size="small" iconName="money-check-alt" name="Services" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/transactions`} active="transactions" size="small" iconName="credit-card" name="Transactions" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/methods`} active="methods" size="small" iconName="wallet" name="Payment Methods" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/documents`} active="documents" size="small" iconName="file-alt" name="Documents" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/notes`} active="notes" size="small" iconName="sticky-note" name="Notes" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/fields`} active="fields" size="small" iconName="tools" name="Custom Fields" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/account`} active="account" size="small" iconName="lock-alt" name="Login Account" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/events`} active="events" size="small" iconName="history" name="Event History" customClasses="rounded-0" />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/text-messaging`} active="text-messaging" size="small" iconName="comments" name="Text Messaging" customClasses={`rounded-0 ${this.state.propertyLease.joins?.company?.notificationProvider !== NOTIFICATION_PROVIDERS.LETUS ? 'hidden' : ''}`} />
                                        <NavListItem path={`/admin/tenants/${this.state.propertyLease.id}/insurances`} active="insurances" size="small" iconName="user-shield" name="Insurance Details" />

                                        <Mimic userType="customer" userId={this.state.propertyLease.userId} propertyLeaseId={this.props.match.params.propertyLeaseId} parent={this} label="Mimic Tenant" displayType="list" />

                                    </div>
                                </div>

                            </div>

                            {this.state.propertyLease.joins &&
                            <div className="jumbotron border py-4">
                                <div className="container">

                                    <p className="mb-0">
                                        {this.state.firstName} {this.state.lastName} resides at <span className="btn-link c-pointer font-weight-bold" onClick={() => this.viewProperty()}>{this.state.propertyLease.joins?.property?.propertyName?`${this.state.propertyLease.joins?.property?.propertyName} - `:""}{this.state.propertyLease.joins.property?.street1}</span>, which is managed by <span className="btn-link c-pointer font-weight-bold" onClick={(event) => this.viewCompany(event)}>{this.state.propertyLease.joins.company?.name}</span>.
                                    </p>

                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-8">

                            <Switch>
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/edit" type="TYPE_ADMIN" component={Tenant} refreshPropertyLease={(propertyLeaseId) => this.getPropertyLease(propertyLeaseId)} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/methods" type="TYPE_ADMIN" propertyLease ={this.state.propertyLease} component={PaymentMethods} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/services" type="TYPE_ADMIN" component={Services} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/statement" type="TYPE_ADMIN" component={OpenCharges} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/transactions" type="TYPE_ADMIN" component={Transactions} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/documents" type="TYPE_ADMIN" component={Documents} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/notes" type="TYPE_ADMIN" component={Notes} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/fields" type="TYPE_ADMIN" component={CustomFields} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/account" type="TYPE_ADMIN" component={SystemAccount} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/text-messaging" type="TYPE_ADMIN" propertyLease ={this.state.propertyLease} component={TextMessaging} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/events" type="TYPE_ADMIN" component={Events} />
                                <PrivateRoute exact path="/admin/tenants/:propertyLeaseId/insurances" type="TYPE_ADMIN" component={Insurances}/>
                            </Switch>

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Navigation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Navigation);
