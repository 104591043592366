import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Breadcrumb from "../../common/Breadcrumb";
import InvoiceList from "../../common/Invoices";
import PaymentMethodList from "../../common/PaymentMethods";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";

class Billing extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The landlord subscription component.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/manager/settings" parentPage="Settings" childPage="Billing" />

                    <PaymentMethodList userType="TYPE_MANAGER" companyId={this.props.userSession.sessionRole.companyId} createMessage="manager.settings.methods.created" deleteMessage="manager.settings.methods.deleted" />

                    {(false) &&
                    <InvoiceList companyId={this.props.userSession.sessionRole.companyId} sessionRole={this.props.userSession.sessionRole} token={this.props.token} userType="TYPE_MANAGER" />
                    }

                </div>

            </div>
        )
    };
}

Billing.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Billing);