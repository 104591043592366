import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React from 'react';
import Moment from 'react-moment';
import * as constants from '../../../util/constants';
import Alert from '../../common/Alert';
import Breadcrumb from '../../common/Breadcrumb';
import ButtonBack from '../../common/ButtonBack';
import Propertii from '../../common/Propertii';
import Table from '../../common/Table';

class HyperwalletUploads extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      hyperwalletUploadsList: {
        page: '',
        recordsPerPage: '',
        totalPages: '',
        totalRecordCount: '',
        records: [{}],
      },
      hyperwalletUploadsQuery: {
        orderBy: 'DESC',
        orderByFields: ['createDate'],
        conditionList: [
          {
            type: 'STRING',
            logicalOperator: 'AND',
            openBrackets: null,
            closeBrackets: null,
            fieldName: 'recordType',
            operator: 'EQUALS',
            fieldValue: 'TYPE_HYPERWALLET_RECORDS',
          },
        ],
      },
      validationList: [],
    };

    this.searchHyperwalletUploads = this.searchHyperwalletUploads.bind(this);
    this.downloadHyperwalletUpload = this.downloadHyperwalletUpload.bind(this);
  }

  /**
   * Load the list of first 25 hyperwallet uploads on mounting of the component.
   */
  componentDidMount() {
    this.searchHyperwalletUploads(1, 25, this.state.hyperwalletUploadsQuery);
  }

  /**
   * Get the list of hyperwallet uploads
   * @param {*} page
   * @param {*} recordsPerPage
   * @param {*} query
   */
  searchHyperwalletUploads(page, recordsPerPage, query) {
    this.setState({
      spinner: true,
    });

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/document/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        query,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          hyperwalletUploadsList: response.data,
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Download the selected hyprwallet upload
   * @param {*} recordId
   */
  downloadHyperwalletUpload(recordId) {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/document/${recordId}/download`, {
        responseType: 'arraybuffer',
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        const FileDownload = require('js-file-download');

        const responseHeader = response.request.getResponseHeader('Content-Disposition');
        const startIndex = responseHeader.indexOf('filename=') + 24;
        const endIndex = responseHeader.length - 1;
        const filename = responseHeader.substring(startIndex, endIndex);

        FileDownload(response.data, filename);

        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
        }));
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        this.handleValidation(error);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - The hyperwallet payment files (uploaded) interface.
   */
  render() {
    return (
      <div className='content-block'>
        <div className='container'>
          <Breadcrumb parentPath='/admin/system' parentPage='System' childPage='Hyperwallet Uploads' />

          <Alert validationList={this.state.validationList} />

          <div className='card'>
            <div className='card-header'>
              <div className='row align-items-center'>
                <div className='col'>Hyperwallet Uploads</div>
              </div>
            </div>
            <Table
              columns={{ createDate: 'Create Date', name: 'Name', download: 'Download' }}
              columnWidths={['25%', '50%', '25%']}
              headerClass='c-pointer'
              data={this.state.hyperwalletUploadsList}
              query={this.state.hyperwalletUploadsQuery}
              sortEnabled={true}
              recordsEnabled={true}
              paginationEnabled={true}
              updateFunction={this.searchHyperwalletUploads}
            >
              <tbody>
                {this.state.hyperwalletUploadsList?.records.map((data, key) => {
                  return (
                    <tr key={key} className='c-pointer'>
                      <td>
                        <div className=''>
                          <Moment format='MMM DD, YYYY' tz='UTC'>
                            {data.createDate}
                          </Moment>
                        </div>
                      </td>
                      <td>
                        <div className=''>{data.name}</div>
                      </td>
                      <td onClick={() => this.downloadHyperwalletUpload(data.id)} className="text-center">
                        <div className=''>
                          <FontAwesomeIcon icon={['far', 'download']} className='fa-list-ol fa-fw' />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          <div className='row'>
            <div className='col text-right'>
              <ButtonBack path='/admin/system' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HyperwalletUploads;
