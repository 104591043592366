import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import familyMoving from '../media/img/backgrounds/couple-moving.jpg';
import Letus from './common/Propertii';

class PricingServiceFee extends Letus {
  /**
   * Render the component.
   *
   * @returns {*} - A public-facing static component that displays the Pricing Service Fee page.
   */
  render() {

    return (
      <div className='content-block'>
        <div
          className='jumbotron jumbotron-fluid mb-0'
          style={{
            background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${familyMoving}') no-repeat center center scroll`,
          }}
        >
          <div className='container text-md-center'>
            <h1 className='display-5 pt-3 text-white'>Letus - Service Fees</h1>
          </div>
        </div>

        <div className='container'>
          <div className='card-group my-5'>
            <p>
              We charge transaction/service fees to cover the bank and payment networks' costs
              associated with payment processing. Our fees also allow us to maintain a secure,
              online rent payment platform and provide first-class customer support by email, chat,
              text message and telephone. Tenants enjoy the benefits of paying their rent from
              anywhere, at any time.
            </p>
            <p className='mt-4'>
              <b>Your transaction fee will be shown as a separate transaction on your receipt</b>,
              so please check the full amount you will be charged before confirming the payment.
            </p>
            <p>
              Service fees vary depending on the payment method you use. Typically bank account
              payments (from chequing accounts) have lower service fees than credit/debit card
              payments.
              <b>
                Please contact us and/or your Property Manager to check what the service fees are
                for each payment method.
              </b>
            </p>
            <p className='mt-4'>
              Notes: <br />
              Bank Account (chequing/checking account) transactions are domestic only. <br />
              Our service/transaction fees are non-refundable as per section 4.2 of our Terms of
              Use.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

PricingServiceFee.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(PricingServiceFee);
