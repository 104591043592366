import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class FieldRange extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {
        let field;

        if(this.props.model) {
            field = this.props.parent.state[this.props.model];
            field[event.target.name] = String(event.target.value || '');
        } else {
            field = String(event.target.value || '');
        }
        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A custom range field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        const styleObj = {
            display:'inline-flex'
        }

        const styleMarginLeft ={
            'margin-left':'7px',
            'margin-right':'2px'
        }

        const styleMarginRight ={
            'margin-right':'7px',
            'margin-left':'2px'
        }

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });


        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && !this.props.leaseApplicationPage && <small className="text-muted font-italic">*</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`} style={styleObj}>

                            <label for={this.props.idMinValue} className='text-muted'>{this.props.prependChar ? this.props.prependChar : null}</label>
                            <input
                                id={this.props.idMinValue}
                                name={this.props.idMinValue}
                                type={this.props.type}
                                value={this.props.minValue || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                title={this.props.onInvalidMessage}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                className={`form-control ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' : ''} ${isInvalid ? 'is-invalid' : ''}`}
                                style={styleMarginRight}
                                min={this.props.min}
                                /> 
                               
                           -
                           <label for={this.props.idMaxValue} style={styleMarginLeft} className='text-muted'>{this.props.prependChar ? this.props.prependChar : null}</label>
                           <input
                                id={this.props.idMaxValue}
                                name={this.props.idMaxValue}
                                type={this.props.type}
                                value={this.props.maxValue || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                title={this.props.onInvalidMessage}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                className={`form-control ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' : ''} ${isInvalid ? 'is-invalid' : ''}`}
                                min={this.props.min}
                                /> 

                            {isInvalid &&
                            <div className="invalid-feedback">
                                <FormattedMessage id={"danger." + errorCode}/>
                            </div>
                            }
      
                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldRange.defaultProps = {
    labelColumns: '3',
    fieldColumns: '4',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'number',
    maxLength: '30'
};

export default FieldRange;
