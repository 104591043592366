import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Propertii from '../../common/Propertii';
import CreditReporting from '../../common/CreditReporting';
class Services extends Propertii {

  /**
   * Render the component.
   *
   * @returns {*} - The customer services component.
   */
  render() {
    return (
        <CreditReporting propertyLeaseId={this.props.match.params?.propertyLeaseId}/>
    );
  }
}

Services.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Services);
