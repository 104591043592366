import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import FieldText from './FieldText';
import FieldSelect from './FieldSelect';

class FieldCustomTemplate extends Component {
  /**
   * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
   * custom handleChange function appears in the parent class, utilize the default handleChange function below.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    if (this.props?.parent?.handleChange) {
      this.handleChange = this.props.parent.handleChange.bind(this);
    } else {
      // this.handleChange = this.handleChange.bind(this);
    }

    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  /**
   * Handle change events for a field.
   */
  handleChangeField(event) {
    if (this.props.model) {
      const index = this.props.idx;
      let value = event.target.value;

      const customFields = this.props.parent.state[this.props.model]
        .customFields;
      customFields[index] = {
        ...this.props.parent.state[this.props.model].customFields[index],
        [event.target.name]: value,
      };

      if (event.target.name === 'type') {
        customFields[index] = {
          ...this.props.parent.state[this.props.model].customFields[index],
          length: null,
          value: null,
        };
      }

      this.props.parent.setState((prevState) => ({
        ...prevState,
        [this.props.model]: {
          ...prevState[this.props.model],
          customFields: customFields,
        },
      }));
    }
  }

  /**
   * Handle the removal of the field based on its index
   */
  handleRemove() {
    const index = this.props.idx;
    let customFields = this.props.parent.state[this.props.model].customFields;
    customFields.splice(index, 1);
    this.props.parent.setState((prevState) => ({
      ...prevState,
      [this.props.model]: {
        ...prevState[this.props.model],
        customFields: customFields,
      },
    }));
  }

  /**
   * Render the component.
   *
   * @returns {*} - The company event list component.
   */
  render() {
    return (
      <div className='form-group row'>
        <div className='col-sm-9 border border-secondary p-2 ml-3'>
          <FieldText
            id='label'
            name='label'
            required={true}
            label='Label Name'
            model={this.props.model}
            parent={this.props.parent}
            value={this.props.customField.label}
            maxLength='100'
            autoFocus={true}
            handleChange={this.handleChangeField}
          />
          <FieldSelect
            id='type'
            name='type'
            label='Field Type'
            model={this.props.model}
            required={true}
            parent={this.props.parent}
            value={this.props.customField.type}
            handleChange={this.handleChangeField}
          >
            <option value='' disabled>
              Select a type...
            </option>
            <option value='TYPE_TEXT_FIELD'>Text Field</option>
            <option value='TYPE_DATE_FIELD'>Date Field</option>
            <option value='TYPE_CHECKBOX_FIELD'>Checkbox</option>
            <option value='TYPE_DROPDOWN_FIELD'>Dropdown List</option>
          </FieldSelect>
          {this.props.customField.type === 'TYPE_TEXT_FIELD' && (
            <FieldText
              id='length'
              name='length'
              required={true}
              label='Length'
              model={this.props.model}
              parent={this.props.parent}
              value={this.props.customField.length}
              type='number'
              min={1}
              max={200}
              autoFocus={true}
              handleChange={this.handleChangeField}
            />
          )}
          {this.props.customField.type === 'TYPE_DROPDOWN_FIELD' && (
            <FieldText
              id='value'
              name='value'
              required={true}
              label='Options'
              model={this.props.model}
              parent={this.props.parent}
              value={this.props.customField.value}
              autoFocus={true}
              placeholder='Enter comma separated values.'
              help='Please enter options in this format - One, Two, Three...'
              handleChange={this.handleChangeField}
            />
          )}
          <div
            className='btn btn-outline-danger btn-xs float-right'
            onClick={() => this.handleRemove()}
          >
            <FontAwesomeIcon icon={['fas', 'trash-alt']} className='fa-fw' />
          </div>
        </div>
      </div>
    );
  }
}

export default FieldCustomTemplate;
