import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import NavListItem from "../../common/NavListItem";
import Propertii from "../../common/Propertii";

class Account extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            notifications: {
                banking: false
            },
            validationList: [],
        };
    }

    /**
     * On the mounting of the component, determine if the landlord has a micro-deposit awaiting validation. If so,
     * display a notification icon on the banking details option.
     */
    componentDidMount() {

        if(this.props.userSession.sessionRole.applicationStatus === 'COMPLETE') {
            axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.userSession.sessionRole.id}/bankinfo`, {
                headers: this.generateRequestHeaders()
            }).then(response => {
                if(response.data.microDepStatus === 'AWAITING_VALIDATION') {
                    this.setState(prevState => ({
                        ...prevState,
                        notifications: {
                            banking: true
                        }
                    }));
                }
            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - The landlord account navigation component.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Breadcrumb parentPage="Account" />

                    <div className="card">
                        <div className="card-header">
                            Options
                        </div>
                        <div className="card-body card-body-list">
                            <div className="list-group">

                                <NavListItem path="/landlord/account/profile" iconType="far" iconName="pencil-alt" name="Edit Profile" size="large" description="Edit your basic profile information and personal details." />

                                {(localStorage.getItem('status') === 'PAID' || localStorage.getItem('status') === 'COMPLETE') &&
                                <NavListItem path="/landlord/account/banking" iconType="far" iconName="university" name="Banking Information" size="large" notification={localStorage.getItem('status') === 'PAID'}
                                             description="Select which bank account you would like payments from your tenants to be deposited into."/>
                                }

                                {localStorage.getItem('status') === 'COMPLETE' &&
                                <NavListItem path="/landlord/account/billing" iconType="far" iconName="file-invoice" name="Billing" size="large" description="Add, edit, or delete payment methods and review your Letus billing details." />
                                }

                                {this.props.userSession.accountType === 'TYPE_LOCAL_SYSTEM_ACCOUNT' &&
                                <NavListItem path="/landlord/account/password" iconType="far" iconName="lock-alt" name="Change Password" size="large" description="Change your account password."/>
                                }

                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Account.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Account);