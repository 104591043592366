import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';

/**
 * Stateless functional component for text field to be used with the react hooks.
 * ! Do not add any business logic or conditional rendering to this component
 * @param {*} props 
 * @author Arsh
 */

const FieldTextFC = (props) => {
  return (
    <div className={`form-group row ${props.containerClass}`}>
      <label
        className={`col-sm-${props.labelColumns} col-form-label ${props.labelClass}`}
        htmlFor={props.id}
      >
        {props.label}{' '}
        {props.optional && (
          <small className='text-muted font-italic'>(Optional)</small>
        )}{' '}
        {props.required && (
          <small className='text-muted font-italic'>*</small>
        )}
      </label>

      <div className={`col-sm-${props.fieldColumns}`}>
        <div
          className={
            props.prependIconName && props.prependIconType
              ? 'input-group'
              : ''
          }
        >
          {props.prependIconName && props.prependIconType && (
            <div className={`input-group-prepend ${props.prependClass}`}>
              <span
                className={`input-group-text ${props.prependTextClass}`}
              >
                <FontAwesomeIcon
                  icon={[
                    props.prependIconType,
                    props.prependIconName,
                  ]}
                  className='fa-fw small'
                />
              </span>
            </div>
          )}

          <input
            id={props.id}
            name={props.id}
            type={props.type}
            maxLength={props.maxLength}
            minLength={props.minLength}
            value={props.value || ''}
            onChange={props.handleChange}
            onFocus={props.handleFocus}
            onBlur={props.handleBlur}
            placeholder={props.placeholder}
            min={props.min}
            max={props.max}
            step={props.step}
            pattern={props.pattern}
            title={props.onInvalidMessage}
            disabled={props.disabled}
            required={props.required}
            autoFocus={props.autoFocus}
            className={`form-control ${props.fieldClass} ${
              props.disabled ? 'DisabledInputField' : ''
            } ${props.isInvalid ? 'is-invalid' : ''}`}
            data-container={props.dataContainer}
            data-trigger={props.dataTrigger}
            data-toggle={props.dataToggle}
            data-placement={props.dataPlacement}
            data-content={props.dataContent}
            data-html={props.dataHtml}
            onInvalid={props.oninvalid}
            onInput={props.oninput}
            warning={props.warning}
          />
        </div>
        {props.help && (
          <small className='form-text text-muted'>{props.help}</small>
        )}
        {props.helpLink && (
          <small className='form-text text-muted'> URL: &nbsp;
            <a
              target='_blank'
              rel='noopener noreferrer'
              href={props.helpLink}
            >
              {window.location.host}/{props.helpLink}
            </a>
          </small>
        )}
        {props.warning && (
          <small className='form-text text-muted-warning'>
            {props.warning}
          </small>
        )}
      </div>
    </div>
  );
};

FieldTextFC.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

export default FieldTextFC;
