import React, { Component } from 'react';
import FieldCountry from "../common/FieldCountry";
import FieldProvince from "../common/FieldProvince";
import FieldState from "../common/FieldState";
import FieldText from "../common/FieldText";
import FieldSelect from "./FieldSelect";

class FieldAddress extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A series of address-related fields. The state/province and ZIP/postal code fields' labels display
     * appropriately based on the country selected.
     */
    render() {

        let postalCodeLabel = "ZIP/Postal Code";

        if(this.props.parent.state[this.props.model]?.country === 'CA') {
            postalCodeLabel = "Postal Code";
        }

        if(this.props.parent.state[this.props.model]?.country === 'US') {
            postalCodeLabel = "ZIP Code";
        }

        return(
            <React.Fragment>
                {this.props.defaultFields?.length > 0 ? (
                    <React.Fragment>
                    {this.props.addressType === true && (
                    <FieldSelect id="addressType" label="Address Type" model={this.props.model} labelColumns={this.props.labelColumns}
                                fieldColumns={this.props.fieldColumns} parent={this.props.parent} value={this.props.value['addressType']}
                                disabled={this.props.disabled}>
                        <option value="" disabled>Select an address type...</option>
                        <option value="RESIDENTIAL">Residential</option>
                        <option value="BILLING">Billing</option>
                        <option value="PREVIOUS">Previous</option>
                    </FieldSelect>
                    )}
                   
                    {this.props.suite === true && (
                    <>
                    {this.props.defaultFields[0].value === 'true' &&
                    <FieldText id="suite" label="Suite" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['suite']}/>
                    }

                    {this.props.defaultFields[1].value === 'true' &&
                    <FieldText id="street1" label="Street 1" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street1']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.defaultFields[2].value === 'true' &&
                    <FieldText id="street2" label="Street 2" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street2']} />
                    }

                    {this.props.defaultFields[3].value === 'true' &&
                    <FieldText id="city" label="City" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['city']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }
                    
                    {this.props.defaultFields[4].value === 'true' &&
                    <FieldCountry id="country" label="Country" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.countryRequired}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value?.['country']} isForCreditCard={this.props.isForCreditCard}/>
                    }
                    </>
                    )}
                    

                    {this.props.parent.state[this.props.model]?.country === 'CA' && this.props.defaultFields[4].value === 'true' &&
                    <FieldProvince id="province" label="Province" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.stateProvinceRequired ? this.props.stateProvinceRequired : this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.parent.state[this.props.model]?.country === 'US' && this.props.defaultFields[5].value === 'true' &&
                    <FieldState id="province" label="State" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.stateProvinceRequired ? this.props.stateProvinceRequired : this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }
                    
                    {this.props.parent.state[this.props.model]?.country && this.props.parent.state[this.props.model]?.country !== 'US' && this.props.parent.state[this.props.model]?.country !== 'CA' && this.props.isForCreditCard && this.props.defaultFields[5].value === 'true' && 
                    <FieldText id="province" label="State" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.parent.state[this.props.model]?.country && this.props.parent.state[this.props.model]?.country !== 'US' && this.props.parent.state[this.props.model]?.country !== 'CA' && this.props.isForCreditCard && this.props.defaultFields[6].value === 'true' &&
                    <FieldText id="postalCode" label="Postal Code" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.parent.state[this.props.model]?.country === 'US' && this.props.defaultFields[6].value === 'true' && 
                    <FieldText id="postalCode" label={postalCodeLabel} labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} pattern="(\d{5}([\-]\d{4})?)" oninvalid={(e)=>{e.target.setCustomValidity("Please enter a valid ZIP code. Zip code format should be either '12345' or '12345-6789'.")}} oninput={(e)=>{e.target.setCustomValidity('')}} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.parent.state[this.props.model]?.country === 'CA' && this.props.defaultFields[6].value === 'true' &&
                    <FieldText id="postalCode" label={postalCodeLabel} labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} country = {this.props.parent.state[this.props.model]?.country} maxLength={7} pattern="[A-Za-z][0-9][A-Za-z]\s?[0-9][A-Za-z][0-9]" oninvalid={(e)=>{e.target.setCustomValidity("Please enter a valid postal code. Postal code format should be 'A1A 1A1'.")}} oninput={(e)=>{e.target.setCustomValidity('')}} leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }

                    {this.props.monthsAtAddress && this.props.defaultFields[7].value === 'true' &&
                    <FieldText id="monthsAtAddress" label="Months at Address" labelColumns={this.props.labelColumns} type="number" maxLength="3"
                            fieldColumns={this.props.fieldColumns} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                            required={this.props.required} disabled={this.props.disabled} model={this.props.model} parent={this.props.parent}
                            value={this.props.value['monthsAtAddress']} min="1" leaseApplicationPage={this.props.leaseApplicationPage}/>
                    }
                    </React.Fragment>
                ): (
                    <React.Fragment>
                    {this.props.addressType &&
                    <FieldSelect id="addressType" label="Address Type" model={this.props.model} labelColumns={this.props.labelColumns}
                                fieldColumns={this.props.fieldColumns} parent={this.props.parent} value={this.props.value['addressType']}
                                disabled={this.props.disabled}>
                        <option value="" disabled>Select an address type...</option>
                        <option value="RESIDENTIAL">Residential</option>
                        <option value="BILLING">Billing</option>
                        <option value="PREVIOUS">Previous</option>
                    </FieldSelect>
                    }

                    {this.props.suite &&
                    <FieldText id="suite" label="Suite" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['suite']}/>
                    }

                    <FieldText id="street1" label="Street 1" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street1']} />

                    <FieldText id="street2" label="Street 2" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} optional={true}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['street2']} />

                    <FieldText id="city" label="City" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['city']} />

                    <FieldCountry id="country" label="Country" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.countryRequired}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value?.['country']} isForCreditCard={this.props.isForCreditCard}/>

                    {this.props.parent.state[this.props.model]?.country === 'CA' &&
                    <FieldProvince id="province" label="Province" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.stateProvinceRequired ? this.props.stateProvinceRequired : this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} />
                    }

                    {this.props.parent.state[this.props.model]?.country === 'US' &&
                    <FieldState id="province" label="State" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.stateProvinceRequired ? this.props.stateProvinceRequired : this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']}/>
                    }
                    
                    {this.props.parent.state[this.props.model]?.country && this.props.parent.state[this.props.model]?.country !== 'US' && this.props.parent.state[this.props.model]?.country !== 'CA' && this.props.isForCreditCard &&
                    <FieldText id="province" label="State" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['province']} />
                    }

                    {this.props.parent.state[this.props.model]?.country && this.props.parent.state[this.props.model]?.country !== 'US' && this.props.parent.state[this.props.model]?.country !== 'CA' && this.props.isForCreditCard &&
                    <FieldText id="postalCode" label="Postal Code" labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                                labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.required}
                                disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} />
                    }

                    {this.props.parent.state[this.props.model]?.country === 'US' &&
                    <FieldText id="postalCode" label={postalCodeLabel} labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.postalCodeRequired ? this.props.postalCodeRequired : this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} pattern="(\d{5}([\-]\d{4})?)" oninvalid={(e)=>{e.target.setCustomValidity("Please enter a valid ZIP code. Zip code format should be be either '12345' or '12345-6789'.")}} oninput={(e)=>{e.target.setCustomValidity('')}}/>
                    }

                    {this.props.parent.state[this.props.model]?.country === 'CA' &&
                    <FieldText id="postalCode" label={postalCodeLabel} labelColumns={this.props.labelColumns} fieldColumns={this.props.fieldColumns}
                            labelClass={this.props.labelClass} fieldClass={this.props.fieldClass} required={this.props.postalCodeRequired ? this.props.postalCodeRequired : this.props.required}
                            disabled={this.props.disabled} model={this.props.model} parent={this.props.parent} value={this.props.value['postalCode']} country = {this.props.parent.state[this.props.model]?.country} maxLength={7} pattern="[A-Za-z][0-9][A-Za-z]\s?[0-9][A-Za-z][0-9]" oninvalid={(e)=>{e.target.setCustomValidity("Please enter a valid postal code. Postal code format should be 'A1A 1A1'.")}} oninput={(e)=>{e.target.setCustomValidity('')}}/>
                    }

                    {this.props.monthsAtAddress &&
                    <FieldText id="monthsAtAddress" label="Months at Address" labelColumns={this.props.labelColumns} type="number" maxLength="3"
                            fieldColumns={this.props.fieldColumns} labelClass={this.props.labelClass} fieldClass={this.props.fieldClass}
                            required={this.props.required} disabled={this.props.disabled} model={this.props.model} parent={this.props.parent}
                            value={this.props.value['monthsAtAddress']}/>
                    }
                    </React.Fragment>
                )
                }    

            </React.Fragment>
        )
    };
}

FieldAddress.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    disabled: false,
    required: false
};

export default FieldAddress;
