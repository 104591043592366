import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import * as constants from "../../../util/constants";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import FieldDate from "../../common/FieldDate";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

ChartDataLabels.toString();

class EntityDetails extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            startDate: '',
            endDate: '',
            entityDetail: {},

            entityDetails: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            validationList: [],
        };

        this.filterEntityDetails = this.filterEntityDetails.bind(this);
        this.searchEntityDetails  = this.searchEntityDetails.bind(this);
    }

    /**
     * Filter the processing KPIs by providing a start and end date.
     *
     * @param event - The event container.
     */
    filterEntityDetails(event) {

        event.preventDefault();

        this.setState(prevState => ({
            spinner: true
        }));

        let filter = {
            fromDate: this.state.startDate,
            toDate: this.state.endDate,
            parameters: this.state.parameters
        };

        if(this.state.startDate != null) {
            filter.fromDate = this.state.startDate;
        }

        if(this.state.endDate != null) {
            filter.toDate = this.state.endDate;
        }

        this.searchEntityDetails(1, 25, filter);
    }

    /**
     * Filter the processing KPIs by providing a start and end date.
     *
     * @param event - The event container.
     */
    searchEntityDetails(page, recordsPerPage, filter) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/stats/entity_details?recordsPerPage=${recordsPerPage}&page=${page}`, filter, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                entityDetails: response.data,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Download entity details data.
     */
    downloadEntityDetails() {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/stats/entity_details/download`, {
            fromDate: this.state.startDate,
            toDate: this.state.endDate,
        }, {
            responseType: 'arraybuffer',
            headers: this.generateRequestHeaders()
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 10;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The admin processing key performance indicators.
     */
    render() {

        return(
            <div className="content-block">

                <div className="container">

                    <Spinner visible={this.state.spinner} />

                        <Breadcrumb parentPath="/admin/dashboard" parentPage="Dashboard" childPage="Entity Details" />

                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col">
                                        Entity Details
                                    </div>
                                    <div className="col text-right">
                                        <div className="btn btn-primary btn-sm" onClick={() => this.downloadEntityDetails()}>
                                            <FontAwesomeIcon icon={['fas', 'download']} className="fa-fw" /> Download Report
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterEntityDetails} autoComplete="off">
                                    <div className="media">
                                        <div className="media-body align-self-center">
                                            <FieldDate id="startDate" labelColumns="0"
                                                fieldColumns="12" parent={this} value={this.state.startDate}
                                                selectsRange={true} startDate={this.state.startDate}
                                                endDate={this.state.endDate}/>
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card-body border-bottom">
                                <div className="row justify-content-center">
                                    <div className="table">

                                        <Table tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table"
                                            columns={{
                                                    entity: 'Entity',
                                                    residents: 'Residents/Units',
                                                    processing: 'Processing(Month/Total)',
                                                    }}
                                            headerClass=""
                                            data={this.state.entityDetails}
                                            filters={{
                                                    fromDate: this.state.startDate,
                                                    toDate: this.state.endDate
                                                    }}
                                            sortEnabled={false}
                                            recordsEnabled={true}
                                            paginationEnabled={true}
                                            updateFunction={this.searchEntityDetails}>
                                            <tbody>
                                                {this.state.entityDetails.records.map((data, key) => {
                                                    return(
                                                        <tr key={key} className="c-pointer">
                                                            <td>
                                                                <div className="">
                                                                    {data.name}<br/>
                                                                    Properties: {data.properties}<br/>
                                                                    Segment: {data.segment}<br/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    Total Residents:{data.residents}<br/>
                                                                    Active Residents: {data.monthActiveResidents}<br/>
                                                                    Units (Active): {data.activeUnits}<br/>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="">
                                                                    Month: {data.monthProcessing}<br/>
                                                                    Total: {data.totalProcessing}<br/>
                                                                    Month Count: {data.monthProcessingCount}<br/>
                                                                    Total Count: {data.totalProcessingCount}<br/>
                                                                    Month Revenue: {data.monthRevenue}<br/>
                                                                    Total Revenue: {data.totalRevenue}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                                )
                                                })}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </div>
                        <div className="row">
                            <div className="col text-right">
                                <ButtonBack path="/admin/dashboard" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default EntityDetails;