import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import Alert from "../../common/Alert";
import Propertii from "../../common/Propertii";

class Application extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The tenant account management component for landlords.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                {this.props.leaseApplication.existingTenant &&
                <div className="card card-primary border-primary">
                    <div className="card-body">
                        <p className="card-text">
                            This applicant has indicated that they are already a tenant of yours matching the information below, and would like to create a Letus account to make their payments.
                        </p>
                    </div>
                </div>
                }

                {(this.props.leaseApplication.joins && this.props.leaseApplication.joins.p) &&
                <div className="accordion" id="application">

                    <div className="card mb-0">

                        <div className="card-header">
                            Application
                        </div>

                        <div className="card-header bg-secondary text-dark c-pointer" data-toggle="collapse" data-target="#applicant" aria-expanded="true" aria-controls="applicant">
                            <div className="row align-items-center">
                                <div className="col">
                                    {this.props.leaseApplication.existingTenant ? 'Tenant' : 'Applicant'}
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="applicant" className="collapse show" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Name
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.firstName} {this.props.leaseApplication.lastName}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Email
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.email}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Phone
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.phone}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Date of Birth
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                <Moment format="MMMM DD, YYYY" tz="UTC">{this.props.leaseApplication.birthDate}</Moment>
                                            </div>
                                        </td>
                                    </tr>
                                    {(!this.props.leaseApplication.existingTenant && this.props.leaseApplication.otherInfo) &&
                                    <React.Fragment>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Marital Status
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    <FormattedMessage id={"enum.maritalStatus." + this.props.leaseApplication.otherInfo.maritalStatus} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Pets
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.otherInfo.pets === 'true' ? 'Yes' : 'No'}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Smoking
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.otherInfo.smoking ? 'Yes' : 'No'}
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#property" aria-expanded="true" aria-controls="property">
                            <div className="row align-items-center">
                                <div className="col">
                                    Property
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="property" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    {this.props.leaseApplication.unit &&
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Unit
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.unit}
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Street 1
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.joins.p.street1}
                                            </div>
                                        </td>
                                    </tr>
                                    {this.props.leaseApplication.joins.p.street2 &&
                                    <tr className="">
                                        <td width="25%">
                                            Street 2
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.street2}
                                        </td>
                                    </tr>
                                    }
                                    <tr className="">
                                        <td width="25%">
                                            City
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.city}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            {this.props.leaseApplication.joins.p.country === 'CA' ? 'Province' : 'State'}
                                        </td>
                                        <td width="75%">
                                            {(this.props.leaseApplication.joins.p.country === 'CA' ? formatMessage({id: "province." + this.props.leaseApplication.joins.p.province}) : formatMessage({id: "state." + this.props.leaseApplication.joins.p.province}))}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            Country
                                        </td>
                                        <td width="75%">
                                            {formatMessage({id: "country." + this.props.leaseApplication.joins.p.country})}
                                        </td>
                                    </tr>
                                    <tr className="">
                                        <td width="25%">
                                            {this.props.leaseApplication.joins.p.country === 'CA' ? 'Postal Code' : 'ZIP Code'}
                                        </td>
                                        <td width="75%">
                                            {this.props.leaseApplication.joins.p.postalCode}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#addresses" aria-expanded="true" aria-controls="addresses">
                            <div className="row align-items-center">
                                <div className="col">
                                    Address History
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="addresses" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.addresses.map((address, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        {address.addressType === 'RESIDENTIAL' ? 'Current Address' : 'Previous Address'}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                {address.suite &&
                                                <tr className="">
                                                    <td width="25%">
                                                        Unit
                                                    </td>
                                                    <td width="75%">
                                                        {address.suite}
                                                    </td>
                                                </tr>
                                                }
                                                <tr className="">
                                                    <td width="25%">
                                                        Street 1
                                                    </td>
                                                    <td width="75%">
                                                        {address.street1}
                                                    </td>
                                                </tr>
                                                {address.street2 &&
                                                <tr className="">
                                                    <td width="25%">
                                                        Street 2
                                                    </td>
                                                    <td width="75%">
                                                        {address.street2}
                                                    </td>
                                                </tr>
                                                }
                                                <tr className="">
                                                    <td width="25%">
                                                        City
                                                    </td>
                                                    <td width="75%">
                                                        {address.city}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        {address.country === 'CA' ? 'Province' : 'State'}
                                                    </td>
                                                    <td width="75%">
                                                        {(address.country === 'CA' ? formatMessage({id: "province." + address.province}) : formatMessage({id: "state." + address.province}))}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Country
                                                    </td>
                                                    <td width="75%">
                                                        {formatMessage({id: "country." + address.country})}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        {address.country === 'CA' ? 'Postal Code' : 'ZIP Code'}
                                                    </td>
                                                    <td width="75%">
                                                        {address.postalCode}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Months at Address
                                                    </td>
                                                    <td width="75%">
                                                        {address.monthsAtAddress}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {this.props.leaseApplication.otherOccupants.length > 0 &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#occupants" aria-expanded="true" aria-controls="occupants">
                            <div className="row align-items-center">
                                <div className="col">
                                    Additional Occupants
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="occupants" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.otherOccupants.map((occupant, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        Additional Occupant {key + 1}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                <tr className="">
                                                    <td width="25%">
                                                        Name
                                                    </td>
                                                    <td width="75%">
                                                        {occupant.firstName} {occupant.lastName}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Phone
                                                    </td>
                                                    <td width="75%">
                                                        {occupant.phone}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Relationship
                                                    </td>
                                                    <td width="75%">
                                                        {occupant.relationship}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Age
                                                    </td>
                                                    <td width="75%">
                                                        {occupant.age}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Employment
                                                    </td>
                                                    <td width="75%">
                                                        <FormattedMessage id={"enum.employment." + occupant.employment}/>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.employment &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#employment" aria-expanded="true" aria-controls="employment">
                            <div className="row align-items-center">
                                <div className="col">
                                    Employment Information
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="employment" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                <table className="table table-bordered">
                                    <tbody>
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Employment Status
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                <FormattedMessage id={"enum.employment." + this.props.leaseApplication.employment.employment}/>
                                            </div>
                                        </td>
                                    </tr>
                                    {(this.props.leaseApplication.employment.employment === 'EMPLOYED_FULL_TIME' || this.props.leaseApplication.employment.employment === 'EMPLOYED_PART_TIME') &&
                                    <React.Fragment>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Employer Name
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.employment.employerName}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Phone
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.employment.phone}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Occupation
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.employment.occupation}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Months Employed
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.employment.monthsEmployed}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="">
                                            <td width="25%">
                                                <div className="">
                                                    Monthly Income
                                                </div>
                                            </td>
                                            <td width="75%">
                                                <div className="">
                                                    {this.props.leaseApplication.employment.monthlySalary}
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                    }
                                    <tr className="">
                                        <td width="25%">
                                            <div className="">
                                                Other Income
                                            </div>
                                        </td>
                                        <td width="75%">
                                            <div className="">
                                                {this.props.leaseApplication.employment.otherIncome}
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    }

                    {this.props.leaseApplication.referenceList.length > 0 &&
                    <div className="card mb-0">
                        <div className="card-header bg-secondary text-dark collapsed c-pointer" data-toggle="collapse" data-target="#references" aria-expanded="true" aria-controls="references">
                            <div className="row align-items-center">
                                <div className="col">
                                    Personal References
                                </div>
                                <div className="col">
                                    <div className="float-right">
                                        <i className="fas"> </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="references" className="collapse" data-parent="#application">
                            <div className="card-body card-body-table">
                                {this.props.leaseApplication.referenceList.map((reference, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <table className="table table-striped table-bordered table-responsive-sm small">
                                                <thead>
                                                <tr className="">
                                                    <th colSpan="2">
                                                        Personal Reference {key + 1}
                                                    </th>
                                                </tr>
                                                </thead>
                                            </table>
                                            <table className="table table-striped table-bordered">
                                                <tbody>
                                                <tr className="">
                                                    <td width="25%">
                                                        Name
                                                    </td>
                                                    <td width="75%">
                                                        {reference.firstName} {reference.lastName}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Phone
                                                    </td>
                                                    <td width="75%">
                                                        {reference.phone}
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <td width="25%">
                                                        Relationship
                                                    </td>
                                                    <td width="75%">
                                                        {reference.relationship}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    }

                </div>
                }

            </React.Fragment>
        )
    };
}

Application.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Application);