import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import * as constants from '../../util/constants';
import Alert from './Alert';
import Button from './Button';
import FieldPhone from './FieldPhone';
import FieldText from './FieldText';
import Propertii from './Propertii';

class FieldOTPVerification extends Propertii {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: '+1',
      OTPCode: null,
      OTPSent: false,
      OTPSpinner: false,
      validationList: [],
    };

    this.sendOTP = this.sendOTP.bind(this);
    this.reSendOTP = this.reSendOTP.bind(this);
  }

  /**
   * Start/stop OTP spinner and form submitting
   * @param {boolean} state
   */
  setOTPSpinner(state) {
    this.setState((prevState) => ({
      ...prevState,
      OTPSpinner: state,
    }));
  }

  /**
   * Sends an OTP to the entered phone number, and sets state based on the result
   * @param {Event} event - The submit event
   */
  sendOTP(event) {
    event.preventDefault();
    this.handleClearValidationList();
    
    let phoneNumber = this.state.phoneNumber;
    const phoneNumberWithoutCode = phoneNumber.split('+')[1];

    // Check if the phone number is valid
    if (phoneNumberWithoutCode.length < 10) {
      this.setErrorMessage('Please enter a valid phone number.');
      return;
    } 
    
    // Check if the phone number is in the correct format
    if(phoneNumberWithoutCode.length === 10) {
      // If the phone number is missing a country code, prepend a '+1' to it.
      // We currently only allow phone numbers from the US and CA
      phoneNumber = `+1${phoneNumberWithoutCode}`;
      
      // Update the phone number with the correct formatting
      this.setState((prevState) => ({
        ...prevState,
        phoneNumber: phoneNumber,
      }));
    }

    // Show the loading spinner
    this.setOTPSpinner(true);

    // Send a POST request to the verification endpoint
    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/verification`,
        {
          to: phoneNumber,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then(() => {
        // If the request is successful, assume that the message was sent
        this.setState((prevState) => ({
          ...prevState,
          OTPSpinner: false,
          OTPSent: true,
        }));
      })
      .catch((error) => {
        // If there's an error, stop the loading spinner and handle the error
        // this.setOTPSpinner(false);
        // this.handleValidation(error);
        // ! Due to an intermittent issue on the BE, the Verification code is received on the mobile number but an error is returned by the API
        // ! To fix this we will show the next screen regardless of the error. Tenants would always have a way to return back to the previous screen
        this.setState((prevState) => ({
          ...prevState,
          OTPSpinner: false,
          OTPSent: true,
        })); 
      });
  }

  /**
   * Resets the OTPSent state to false, allowing the user to resend the OTP
   */
  reSendOTP() {
    // Reset the OTPSent state to false
    this.setState((prevState) => ({
      ...prevState,
      OTPSent: false,
    }));
  }

  /**
   * Render the component.
   *
   * @returns {*} - An OTP verification component.
   */
  render() {
    return (
      <>
        <Alert validationList={this.state.validationList} />
        <div className='card'>
          <div className='card-header'>Verification</div>
          <div className='card-body bg-secondary'>
            <p className='mb-0'>
              {this.state.OTPSent
                ? 'Please add the code you received on your mobile device to the "Enter Code" field.'
                : 'Please provide your phone number and click the <Send Code> button.'}
            </p>
          </div>
          {this.props.OTPCodeCompleted ? (
            <div className='card-body'>
              <div className='list-group'>
                <div
                  className='list-group-item list-group-item-action c-pointer'
                  onClick={() => this.props.editOTPCode()}
                >
                  <div className='row align-items-center'>
                    <div className='col-8'>Secure Code</div>
                    <div className='col text-right'>
                      <div className='float-right mr-2'>{this.state.OTPCode}</div>
                    </div>
                  </div>
                </div>
                <div
                  className='btn btn-outline-primary btn-md btn-block mt-3'
                  onClick={() => this.props.editOTPCode()}
                >
                  Edit
                </div>
              </div>
            </div>
          ) : (
            <div className='card-body'>
              {this.state.OTPSent ? (
                <form
                  onSubmit={(event) =>
                    this.props.validateOTP(event, this.state.phoneNumber, this.state.OTPCode)
                  }
                >
                  <FieldText
                    id='OTPCode'
                    label='Enter Code'
                    model={null}
                    type='Enter Code'
                    parent={this}
                    value={this.state.OTPCode}
                    AddnlBtn1Text='Resend Code?'
                    AddnlBtn1Type='link'
                    AddnlBtn1Handler={this.reSendOTP}
                  />
                  <Button
                    btnSize='btn-md'
                    btnStyles='mt-3'
                    text='Continue'
                    disabled={this.state.OTPSpinner}
                    spinner={this.state.OTPSpinner}
                  />
                </form>
              ) : (
                <form onSubmit={this.sendOTP}>
                  <FieldPhone
                    containerClass='mt-3'
                    labelColumns='3'
                    fieldColumns='9'
                    id='phoneNumber'
                    label='Phone Number'
                    parent={this}
                    required={true}
                    value={this.state.phoneNumber}
                    model={null}
                  />
                  <Button
                    btnSize='btn-md'
                    btnStyles='mt-3'
                    text='Send Code'
                    disabled={this.state.OTPSpinner}
                    spinner={this.state.OTPSpinner}
                  />
                </form>
              )}
            </div>
          )}
          <div className="card-footer">
              <p className="small text-muted mb-0">
                Please contact <a href='mailto:help@let.us'>help@let.us</a> if you are experiencing difficulties.
              </p>
          </div>
        </div>
      </>
    );
  }
}

FieldOTPVerification.propTypes = {
  validateOTP: PropTypes.func,
  editOTPCode: PropTypes.func,
  OTPCodeCompleted: PropTypes.bool,
};

export default FieldOTPVerification;
