//Message sender types
const MESSAGE_SENDER = {
  TYPE_TENANT: 'USER',
  TYPE_MANAGER: 'TYPE_MANAGER',
  TYPE_ADMIN: 'ADMIN',
  TYPE_SYSTEM: 'SYSTEM',
};

const MESSAGE_STATUS = {
  TYPE_SUCCESS: 'SUCCESS'
}

export { MESSAGE_SENDER, MESSAGE_STATUS };
