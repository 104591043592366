import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import * as constants from "../../util/constants";
import Propertii from "../common/Propertii";

class InsuranceInformation extends Propertii {
    constructor(props) {
        super(props);
        this.downloadDocument = this.downloadDocument.bind(this);
    }
    /**
     * Render the component.
     *
     * @returns {*} - An Insurance Information modal.
     */

    downloadDocument(documentId) {
        this.setState({
            spinner: true
        });

        axios.get(`${constants.REACT_APP_HOST_API_URL}/document/${documentId}/download`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/octet-stream',
                'Authorization': this.props.token
            }
        }).then(response => {

            const FileDownload = require('js-file-download');

            let responseHeader = response.request.getResponseHeader('Content-Disposition');
            let startIndex = responseHeader.indexOf("filename=") + 24;
            let endIndex = responseHeader.length - 1;
            let filename = responseHeader.substring(startIndex, endIndex);

            FileDownload(response.data, filename);

            this.setState({
                spinner: false
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    formatApprovalStatus(status) {
        switch(status) {
            case "APPROVED":
                return "APPROVED";
            case "REJECTED":
                return "REJECTED";
            case "PENDING":
                return "PENDING";
            case "TERMINATED":
                return "TERMINATED";
            default:
                return "PENDING";
        }
    }

    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="modal fade" id="selected-insurance" tabIndex="-1" role="dialog" aria-labelledby="selected-insurance-label" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div className="modal-content shadow">

                        <div className="modal-header text-center d-block text-white py-4 bg-dark border-bottom-0">
                            <div className="">
                                <FontAwesomeIcon icon={["fas", "user-shield"]} className="fa-fw va-b mb-3" size="4x" />
                            </div>
                            <h5 className="modal-title" id={`selected-insurance-label`}>
                                Insurance Policy Information
                            </h5>
                        </div>
                        {this.props.insurance.approvalStatus === 'APPROVED' &&
                        <div className="modal-body bg-success border-top-0">
                            <p className="mb-0 text-white text-center">
                                Insurance Approved
                            </p>
                        </div>
                        }
                        {this.props.insurance.approvalStatus === 'REJECTED' &&
                        <div className="modal-body bg-danger border-top-0">
                            <p className="mb-0 text-white text-center">
                                Insurance Rejected
                            </p>
                        </div>
                        }
                        <div className="modal-body modal-body-table">
                            <table className="table mb-0">
                                <tbody>
                                {this.props.insurance.joins?.customer && <tr className="small">
                                    <td className="">
                                        Name
                                    </td>
                                    <td className="text-right">
                                        <Link to={`/${this.props.userType.substring(5).toLowerCase()}/tenants/${this.props.insurance.leaseId}/edit`} onClick={() => $('#selected-insurance').modal('hide')}>
                                            {this.props.insurance.joins?.customer?.firstName} {this.props.insurance.joins?.customer?.lastName}
                                        </Link>
                                    </td>
                                </tr>}
                                {(this.props.insurance.firstName || this.props.insurance.lastName ) && <tr className="small">
                                    <td className="">
                                        Name on Insurance
                                    </td>
                                    <td className="text-right">
                                            {this.props.insurance.firstName && this.props.insurance.firstName} {this.props.insurance.lastName && this.props.insurance.lastName}
                                    </td>
                                </tr>}
                                {this.props.insurance.joins && <tr className="small">
                                    <td className="">
                                        Lease
                                    </td>
                                    <td className="text-right">
                                        {this.props.insurance.joins?.p?.street1} {this.props.insurance.joins?.p?.street2} {this.props.insurance.joins?.p?.city} (Unit {this.props.insurance.joins?.pl?.unit})
                                    </td>
                                </tr>}
                                {this.props.insurance.carrierName && <tr className="small">
                                    <td className="">
                                        Insurance Carrier
                                    </td>
                                    <td className="text-right">
                                        {this.props.insurance.carrierName}
                                    </td>
                                </tr>}
                                {this.props.insurance.startDate && <tr className="small">
                                    <td className="">
                                        Start Date
                                    </td>
                                    <td className="text-right">
                                        <Moment format="MMM DD, YYYY" tz="UTC">
                                            {this.props.insurance.startDate }
                                        </Moment>
                                    </td>
                                </tr>}
                                {this.props.insurance.endDate && <tr className="small">
                                    <td className="">
                                        End Date
                                    </td>
                                    <td className="text-right">
                                        <Moment format="MMM DD, YYYY" tz="UTC">
                                            {this.props.insurance.endDate }
                                        </Moment>
                                    </td>
                                </tr>}
                                {this.props.insurance.policyIdentifier && <tr className="small">
                                    <td className="">
                                        Insurance Policy #
                                    </td>
                                    <td className="text-right">
                                        {this.props.insurance.policyIdentifier }
                                    </td>
                                </tr>}
                                {this.props.insurance.effectiveDate && <tr className="small">
                                    <td className="">
                                        Effective Date
                                    </td>
                                    <td className="text-right">
                                        <Moment format="MMM DD, YYYY" tz="UTC">
                                            {this.props.insurance.effectiveDate }
                                        </Moment>
                                    </td>
                                </tr>}
                                {this.props.document.id && <tr className="small">
                                    <td className="">
                                        Proof of Insurance
                                    </td>
                                    <td className="text-right">
                                        {this.props.document.name }
                                        <div className="align-self-center text-right" style={{display:'inline-block', marginLeft:'10px'}}>
                                            <div className="text-right">
                                                <div className="dropdown">
                                                    <button className="btn btn-secondary btn-sm" type="button" id="document-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <FontAwesomeIcon icon={['fas', 'cog']} className="fa-fw"/>
                                                    </button>
                                                    <div className="dropdown-menu" aria-labelledby="document-actions">
                                                        <div className="dropdown-item c-pointer" onClick={() => this.downloadDocument(this.props.document.id)}>Download</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>}
                                </tbody>
                            </table>
                            <h5 style={{paddingLeft: '12px', paddingTop: '8px'}}>Approval History</h5>
                            {this.props.insurance.approvalHistory && <table className="table mb-0">
                                <tbody>
                                    {this.props.insurance.approvalHistory.map((record, key) => {
                                        return (
                                            record.historyRecord.approvalStatus && <tr className="small" key={key}>
                                                <td>
                                                    <div className="">
                                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + this.formatApprovalStatus(record.historyRecord.approvalStatus) + ".class"})}`}/>
                                                        <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + this.formatApprovalStatus(record.historyRecord.approvalStatus)}/></span>
                                                    </div>
                                                    {(record.historyRecord.approvalStatus === 'REJECTED') && <div style={{paddingTop: '8px'}}>{record.historyRecord.approvalStatusComment && record.historyRecord.approvalStatusComment}</div>}
                                                </td>
                                                <td className="text-right">
                                                    <Moment format="MMM DD, YYYY, hh:mm a">
                                                        {record.historyRecord.updateDate}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>}
                        </div>

                        <div className="modal-footer d-block text-center bg-secondary rounded-bottom">

                            {this.props.children}

                        </div>

                    </div>
                </div>
            </div>
        )
    };
}

InsuranceInformation.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(InsuranceInformation);
