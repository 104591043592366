import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class Banner extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A banner component used to display the main heading and subheadings of many public-facing pages
     * and internal flows.
     */
    render() {

        return(
            <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('https://dheiziex291vk.cloudfront.net/static/backgrounds/${this.props.backgroundImg}.jpg') no-repeat center center scroll`}}>
                <div className={`container text-md-${this.props.align}`}>
                    <h1 className="display-5 pt-3 text-white">
                        <FormattedMessage id={this.props.heading} values={this.props.values} />
                    </h1>
                    {this.props.subheading &&
                    <p className="lead text-white">
                        <FormattedMessage id={this.props.subheading} values={this.props.values} />
                    </p>
                    }
                </div>
            </div>
        )
    };
}

Banner.defaultProps = {
    align: 'center'
};


export default Banner;