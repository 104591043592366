import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import $ from 'jquery';
import React, { useState } from 'react';
import {
  HYPER_WALLET_PAYMENT_CATEGORY,
  HYPER_WALLET_PAYMENT_TYPES,
  HYPER_WALLET_PAYMENT_TYPE_INFO_DATA,
} from '../../../../constants/HyperWalletPaymentTypes';
import { getCustomValidityMessage } from '../../../../constants/HyperWalletConstants';
import LetusSplitLogo from '../../../../media/img/logos/logo-letus-split.png';
import * as constants from '../../../../util/constants';
import generateRequestHeaders from '../../../../util/generateRequestHeaders';
import Alert from '../../../common/Alert';
import FeeProfiles from '../../../common/FeeProfiles';
import { useInput } from '../../../common/hooks/useInput';
import { useValidationList } from '../../../common/hooks/useValidationList';
import NewCardModalFC from '../../../common/NewCardModalFC';
import styles from '../css/HyperwalletPayment.module.css';
import HyperWalletPaymentTypeInfoModal from '../HyperWalletPaymentTypeInfoModal';
import PaymentSummaryStep from './PaymentSummaryStep';

const SendPaymentStep = (props) => {
  /******* STATE *******/
  /*********************/
  const [feeProfileList, setFeeProfileList] = useState(null);
  const [feeProfileCountry, setFeeProfileCountry] = useState(null);
  const [paymentTypeInfoData, setPaymentTypeInfoData] = useState(null);

  const { value: paymentAmount, handleChange: handleChangePaymentAmount } = useInput(5);

  const { validationList, addErrorToValidationList } = useValidationList([]);
  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/

  const handleChangeShowSendPaymentStep = (val) => {
    props.setShowSendPaymentStep(val);
  };

  const handleAddCreditCard = () => {
    if (!props.selectedPaymentType) {
      // to prevent credit card dropdown on payment summary from redirecting when the callback method is fired
      handlePaymentTypeSelection(HYPER_WALLET_PAYMENT_TYPES.TYPE_CREDIT_CARD);
    }
  };

  const openCreditCardModal = () => {
    const sendPaymentStepValidity = $('#sendPaymentStep')[0]?.checkValidity();
    if (!sendPaymentStepValidity) {
      $('#sendPaymentStep')[0].reportValidity();
      return;
    }
    $('#newCardModal').modal('show');
  };

  // construct paymentTypeInfoData json object, for selected paymentType, to pass into info modal component + open modal
  const showPaymentTypeSelectionInfoModal = (paymentType) => {
    const SELECTED_HYPER_WALLET_PAYMENT_TYPE_INFO_DATA = HYPER_WALLET_PAYMENT_TYPE_INFO_DATA.find(
      (val) => val.paymentType === paymentType
    );
    let paymentTypeInfoData = {
      paymentType: paymentType,
      name: SELECTED_HYPER_WALLET_PAYMENT_TYPE_INFO_DATA.name,
      description: SELECTED_HYPER_WALLET_PAYMENT_TYPE_INFO_DATA.description,
      logo: SELECTED_HYPER_WALLET_PAYMENT_TYPE_INFO_DATA.logo,
    };
    setPaymentTypeInfoData(paymentTypeInfoData);
    $('#hyperWalletPaymentTypeInfoModal').modal('show');
  };

  // obtain selected payment type div and rerender component to show summary view
  const handlePaymentTypeSelection = (val) => {
    const sendPaymentStepValidity = $('#sendPaymentStep')[0]?.checkValidity();
    if (!sendPaymentStepValidity) {
      $('#sendPaymentStep')[0].reportValidity();
      return;
    }
    $('#hyperWalletPaymentTypeInfoModal').modal('hide');
    props.setSelectedPaymentType(val);
    props.setShowSendPaymentStep(false);
  };

  /**
   * As per the requirements from the ticket LP-4299, this function filter out paymentType TYPE_CASH and TYPE_BANK_ACCOUNT
   * from the fee profile list
   */
  const filterFeeProfileList = (feeProfileList) => {
    const filteredFeeProfileList = feeProfileList.filter(
      (feeProfile) => feeProfile.paymentType !== 'TYPE_BANK_ACCOUNT' && feeProfile.paymentType !== 'TYPE_CASH'
    );

    return filteredFeeProfileList;
  };

  /**
   * Fetch a list of all fee profiles related to the company at hand.
   */
  const getFeeProfiles = () => {
    const companyId = props?.hwPageDetails?.companyId;
    props.setShowSpinner(true);
    axios
      .get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/peek`, {
        headers: generateRequestHeaders(),
      })
      .then((response) => {
        let company = response.data;

        axios
          .get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${companyId}`, {
            headers: generateRequestHeaders(),
          })
          .then((response) => {
            props.setShowSpinner(false);
            setFeeProfileList(filterFeeProfileList(response.data));
            setFeeProfileCountry(company.country);

            $('#fees').modal('show');
          })
          .catch((error) => {
            props.setShowSpinner(false);
            addErrorToValidationList(error);
          });
      })
      .catch((error) => {
        props.setShowSpinner(false);
        addErrorToValidationList(error);
      });
  };
  /*********************/
  /*********************/

  return (
    <>
      <Alert validationList={validationList} />

      <FeeProfiles feeProfileList={feeProfileList} feeProfileCountry={feeProfileCountry} />

      <NewCardModalFC
        addCard={(newCardInfo) => props.addCard(newCardInfo)}
        editCard={(existingCardInfo) => props.editCard(existingCardInfo)}
        callbackFunction={() => handleAddCreditCard()}
        cards={props.cards}
        selectedCard={props.selectedCard}
        setShowSpinner={props.setShowSpinner}
        selectedCountry={props.selectedCountry}
      />

      <HyperWalletPaymentTypeInfoModal
        paymentCategory={props.paymentCategory}
        paymentTypeInfoData={paymentTypeInfoData}
        handlePaymentTypeSelection={(val) => handlePaymentTypeSelection(val)}
      />

      {props.showSendPaymentStep ? (
        <>
          <form
            id='sendPaymentStep'
            onSubmit={() => {
              props.setShowSendPaymentStep(false);
            }}
            className='mb-5'
          >
            <div className='form-group'>
              <div className='row'>
                <div className='col-lg-3 mt-2'>
                  <label htmlFor='paymentAmountSP'>
                    <h3 className={`${styles.fontRoboto}`}>Amount</h3>
                  </label>
                  <div className='input-group mb-3'>
                    <div className='input-group-prepend'>
                      <span className='input-group-text' id='dollar-addon'>
                        $
                      </span>
                    </div>
                    <input
                      type='number'
                      min={5}
                      max={3000}
                      step='any'
                      className={`form-control ${styles.amountInput}`}
                      id='paymentAmountSP'
                      value={paymentAmount}
                      onChange={handleChangePaymentAmount}
                      aria-describedby='dollar-addon'
                      onInvalid={(e) => {
                        e.target.setCustomValidity(getCustomValidityMessage(e.target.validity));
                      }}
                      onInput={(e) => {
                        e.target.setCustomValidity('');
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-lg-6 mt-4'>
                  <h3 className={`${styles.fontRoboto}`}>
                    {props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_NOW ? 'Pay Now' : 'Pay Later'}
                  </h3>
                </div>
              </div>
              {props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_NOW && (
                <div
                  className={
                    'list-group-item border list-group-item-action flex-column align-items-start c-pointer mb-3'
                  }
                  onClick={() => {
                    openCreditCardModal();
                  }}
                >
                  <div className='media'>
                    <div className='media-body align-self-center'>
                      <p className='mb-0'>Credit Card or Debit Card</p>
                    </div>
                    <div className='align-self-center mr-3 text-center'>
                      <FontAwesomeIcon icon={['far', 'credit-card']} className='fa-lg  ' />
                    </div>
                  </div>
                </div>
              )}
              {(props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER ||
                props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_NOW) && (
                <div
                  className={
                    'list-group-item border list-group-item-action flex-column align-items-start c-pointer mb-3 mt-2'
                  }
                  onClick={() => handlePaymentTypeSelection(HYPER_WALLET_PAYMENT_TYPES.TYPE_PAYPAL)}
                >
                  <div className='media'>
                    <div className='media-body align-self-center'>
                      <p className='mb-0'>
                        Pay with Paypal{' '}
                        {props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER ? 'paylater' : 'checkout'}
                      </p>
                    </div>
                    <div className='align-self-center mr-3 text-center'>
                      <FontAwesomeIcon icon={['fab', 'paypal']} className='fa-lg  ' />
                    </div>
                  </div>
                </div>
              )}
              {props.paymentCategory === HYPER_WALLET_PAYMENT_CATEGORY.PAY_LATER && (
                <div
                  className={'list-group-item border list-group-item-action flex-column align-items-start c-pointer '}
                  onClick={() => showPaymentTypeSelectionInfoModal(HYPER_WALLET_PAYMENT_TYPES.TYPE_LETUS_SPLIT)}
                >
                  <div className='media'>
                    <div className='media-body align-self-center'>
                      <p className='mb-0'>Letus Split</p>
                    </div>
                    <div className='align-self-center mr-1 text-center'>
                      <img
                        src={LetusSplitLogo}
                        className='rounded img-fluid bg-light'
                        alt='LetusSplit'
                        title='LetusSplit'
                        width='40'
                      />
                    </div>
                  </div>
                </div>
              )}
              <p className='small text-muted mb-0 mt-3'>
                Transactions made through Letus are subject to transaction fees, dependant upon your chosen method of
                payment. To view a list of our transaction fees,{' '}
                <span
                  className='btn-link c-pointer'
                  data-toggle='modal'
                  data-target='#fees'
                  onClick={() => getFeeProfiles()}
                >
                  click here
                </span>
                .
              </p>
            </div>
            <div className='row'>
              <div className='col-md-12 mt-4 d-flex justify-content-center'>
                <button type='button' className={`btn btn-light ${styles.stepButtonHW}`} onClick={props.previous}>
                  Back
                </button>
              </div>
            </div>
          </form>
        </>
      ) : (
        <PaymentSummaryStep
          cards={props.cards}
          addCard={props.addCard}
          removeCard={props.removeCard}
          handleChangeShowSendPaymentStep={handleChangeShowSendPaymentStep}
          selectedPaymentType={props.selectedPaymentType}
          clearPaymentStepState={props.clearPaymentStepState}
          selectedCard={props.selectedCard}
          setSelectedCard={props.setSelectedCard}
          paymentAmount={paymentAmount}
          processingFees={props.processingFees}
          handleChangePaymentAmount={handleChangePaymentAmount}
          paymentCategory={props.paymentCategory}
          calculateFees={props.calculateFees}
          getCCPaymentMethod={props.getCCPaymentMethod}
          hwPageDetails={props.hwPageDetails}
          tenantEmail={props.tenantEmail}
          landlordEmail={props.landlordEmail}
          notifyLandlord={props.notifyLandlord}
          selectedCharge={props.selectedCharge}
          registerUserAndLandlord={props.registerUserAndLandlord}
          setShowSpinner={props.setShowSpinner}
          companyId={props?.hwPageDetails?.companyId}
          agreedToTerms={props.agreedToTerms}
          tenantUnit={props.tenantUnit}
        />
      )}
    </>
  );
};

export default SendPaymentStep;
