import * as constants from "../util/constants";
import axios from "axios";

const generateRequestHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('token_type') + ' ' + localStorage.getItem('access_token')
    };
}

export const getPaymentTransactionById = (transactionId, query) => {
    return axios.post(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/search?recordsPerPage=1&page=1`, {
        orderBy: query.orderBy,
        orderByFields: query.orderByFields,
        conditionList: [
            {
                type: 'STRING',
                logicalOperator: 'AND',
                openBrackets: null,
                closeBrackets: null,
                operator: 'EQUALS',
                fieldName: 'id',
                fieldValue: transactionId
            }
        ],
        joins: query.joins
    }, {
        headers: generateRequestHeaders()
    });
}