import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import * as constants from "../../util/constants";
import Alert from "./Alert";
import ButtonSave from "./ButtonSave";
import ButtonEmailOptOut from "./ButtonEmailOptOut";
import FieldDate from "./FieldDate";
import FieldSelect from "./FieldSelect";
import FieldText from "./FieldText";
import Propertii from "./Propertii";

class SystemAccount extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            systemAccount: {},
            validationList: [],
        };

        this.saveSystemAccount = this.saveSystemAccount.bind(this);
        this.searchSystemAccount = this.searchSystemAccount.bind(this);
    }

    /**
     * Search for a list of all billing accounts of all types on mounting of the component.
     */
    componentDidMount() {

        this.searchSystemAccount();
    }

    /**
     * Handle saving a system account.
     *
     * @param event - The event container.
     */
    saveSystemAccount(event) {

        event.preventDefault();

        axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.state.systemAccount?.type?.substr(5)?.toLowerCase()}/${this.state.systemAccount.id}/update`, {
            status: this.state.systemAccount.status,
            verificationDate: this.state.systemAccount.verificationDate,
            userName: this.state.systemAccount.userName,
            passwordExpiryDate: this.state.systemAccount.passwordExpiryDate
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'saved',
                        message: 'Changes have been saved'
                    }
                }],
            });

            this.searchSystemAccount();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch the appropriate system account by performing a search for all system accounts that match the user ID
     * provided in the props.
     */
    searchSystemAccount() {

        let systemAccountQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'userId',
                    fieldValue: this.props.userId
                }
            ],
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/local_system_account/search`, {
            orderBy: systemAccountQuery.orderBy,
            orderByFields: systemAccountQuery.orderByFields,
            conditionList: systemAccountQuery.conditionList,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                systemAccount: response.data.records[0],
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The edit user system account component.
     */
    render() {

        return(
            <React.Fragment>

                <Alert validationList={this.state.validationList} />

                <form onSubmit={this.saveSystemAccount}>

                    {(this.state.systemAccount && this.state.systemAccount.status === 'UNVERIFIED') &&
                    <div className="card card-warning border-warning">
                        <div className="card-body">
                            <h4 className="card-title">
                                This system account is unverified
                            </h4>
                            <p className="card-text">
                                This user has signed up to use Letus but has not yet confirmed their email address. The user must confirm their email address before proceeding with their account.
                            </p>
                        </div>
                    </div>
                    }

                    <div className="card">
                        <div className="card-header">
                            Login Account
                        </div>

                        {(this.state.systemAccount && this.state.systemAccount.type  === 'TYPE_GOOGLE_OAUTH_ACCOUNT') &&
                        <div className="card-body bg-secondary">
                            <p className="mb-0">
                                This user has indicated that they would like to sign up and log in to their Letus account using their Google account credentials.
                            </p>
                        </div>
                        }

                        {this.state.systemAccount  &&
                        <div className="card-body">

                            <FieldText id="userId" label="User ID" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['userId']}/>

                            <FieldText id="id" label="System Account ID" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['id']}/>

                            <FieldSelect id="status" label="Status" model="systemAccount" disabled={!this.state.systemAccount['verificationDate'] && this.state.systemAccount.type === 'TYPE_LOCAL_SYSTEM_ACCOUNT'} parent={this} value={this.state.systemAccount['status']}>
                                <option disabled>Select a status...</option>
                                <option value="VERIFIED">Verified</option>
                                <option value="UNVERIFIED">Unverified</option>
                                <option value="RESET">Reset</option>
                                <option value="SUSPENDED">Suspended</option>
                            </FieldSelect>

                            <FieldDate id="verificationDate" label="Verification Date" model="systemAccount" disabled={true} parent={this} value={this.state.systemAccount['verificationDate']}/>

                            <FieldText id="userName" label="User Name" model="systemAccount" type="email" disabled={this.state.systemAccount.type === 'TYPE_GOOGLE_OAUTH_ACCOUNT'} parent={this} value={this.state.systemAccount['userName']}/>

                            <FieldDate id="passwordExpiryDate" label="Password Expiry" model="systemAccount" disabled={this.state.systemAccount.type === 'TYPE_GOOGLE_OAUTH_ACCOUNT'} parent={this} value={this.state.systemAccount['passwordExpiryDate']}/>

                        </div>
                        }

                        {!this.state.systemAccount &&
                        <div className="card-body">
                            <div className="row justify-content-center">
                                <div className="col-10">
                                    <div className="text-center text-secondary">
                                        <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x" />
                                    </div>
                                    <div className="text-center text-muted">
                                        <small>
                                            This user does not currently have a system account.
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                    </div>

                    {this.state.systemAccount &&
                    <div className="row">
                        <div className="col text-right">

                            <ButtonEmailOptOut model={this.state.systemAccount} parent={this} />
                            <ButtonSave />

                        </div>

                    </div>
                    }

                </form>

            </React.Fragment>
        )
    };
}

SystemAccount.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(SystemAccount);