import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class Emails extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            searchFilter: '',
            email: {},
            newEmail: '',
            emailList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: []
            },
            emailQuery: {
                orderBy: 'DESC',
                orderByFields: ['createDate'],
                conditionList: []
            },
            validationList: [],
        };

        this.searchEmails = this.searchEmails.bind(this);
        this.filterEmails = this.filterEmails.bind(this);
        this.viewEmail = this.viewEmail.bind(this);
        this.resendEmail = this.resendEmail.bind(this);
    }

    /**
     * On mounting of the component, initialize the list of emails by performing an email search.
     */
    componentDidMount() {

        this.searchEmails(1, 25, this.state.emailQuery);
    }

    /**
     * Handle selecting an email by bringing up the resend email modal.
     *
     * @param emailId - The ID of the email selected.
     */
    viewEmail(emailId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/email/${emailId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                email: response.data,
                newEmail: response.data.email
            }));

            $('#email').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Resend an email.
     *
     * @param event - The event container.
     */
    resendEmail(event) {

        event.preventDefault();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/email/${this.state.email.id}/resend?newEmail=${encodeURIComponent(this.state.newEmail)}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.emails.resent'
                    },
                    values: {
                        email: this.state.newEmail
                    }
                }],
            });

            $('#email').modal('hide');

            this.searchEmails(this.state.emailList.page, this.state.emailList.recordsPerPage, this.state.emailQuery);

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Update the data table of emails.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchEmails(page, recordsPerPage, query) {

        this.setState({
            spinner: true
        });

        axios.post(`${constants.REACT_APP_HOST_API_URL}/email/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                emailList: response.data,
                emailQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle submitting the search filter field by adjusting the email search query and initiating a new search.
     *
     * @param event - The event container.
     * @param searchFilter - The value within the search filter field.
     */
    filterEmails(event, searchFilter) {

        if (event != null) {
            event.preventDefault();
        }

        let emailQuery = {
            orderBy: 'DESC',
            orderByFields: ['createDate'],
            conditionList: []
        };

        if (searchFilter !== '') {

            emailQuery.conditionList.push(
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: searchFilter
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'email',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: searchFilter
                },
            );
        }

        this.setState({
            emailQuery: emailQuery
        });

        this.searchEmails(1, 25, emailQuery);
    }

    /**
     * Render the component.
     *
     * @returns {*} - A list of all emails sent by the system.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Emails" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            Emails
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                            <form onSubmit={(event) => this.filterEmails(event, this.state.searchFilter)}>
                                <div className="media">
                                    <div className="media-body align-self-center mr-3">
                                        <FieldText id="searchFilter" label="Search" labelClass="d-none" fieldColumns="12"
                                                   labelColums="0" placeholder="Filter by ID or recipient email..."
                                                   parent={this} value={this.state.searchFilter} />
                                    </div>
                                    <div className="align-self-center text-right">
                                        <button type="submit" className="btn btn-secondary btn-sm btn-block mb-0">
                                            <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <Table columns={{createDate: 'Date', email: 'Recipient', template: 'Template', userType: 'User Type', lastSentDate: 'Last Sent Date', status: 'Status'}}
                               columnWidths={['20%', '20%', '20%', '10%', '20%', '10%']}
                               headerClass="c-pointer"
                               data={this.state.emailList}
                               query={this.state.emailQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchEmails}>
                            <tbody>
                            {this.state.emailList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewEmail(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.email}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.template}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <FormattedMessage id={"enum.record." + data.userType} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.lastSentDate &&
                                                <Moment format="MMM DD, YYYY HH:mm">
                                                    {data.lastSentDate}
                                                </Moment>
                                                }
                                            </div>
                                        </td>
                                        <td>
                                            <div className="text-nowrap">
                                                <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.email.status." + data.status + ".class"})}`} />
                                                <span className="ml-1"><FormattedMessage id={"enum.email.status." + data.status} /></span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="email" tabIndex="-1" role="dialog" aria-labelledby="email-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.resendEmail}>

                                    <div className="modal-header bg-dark text-white border-bottom-0">
                                        <h5 className="modal-title" id="email-label">
                                            Resend Email
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>

                                    {this.state.email.error &&
                                    <div className="modal-body bg-danger border-top-0">
                                        <p className="mb-0 text-white">
                                            <span className="font-weight-bold">There was an error attempting to send this email: </span>{this.state.email.error}
                                        </p>
                                    </div>
                                    }

                                    <div className="modal-body bg-secondary">
                                        <p className="mb-0">
                                            Enter the new email address of the recipient to resend this email to.
                                        </p>
                                    </div>

                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="newEmail" label="New Email" parent={this} value={this.state.newEmail} />

                                    </div>

                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col-6">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#email").modal("hide")}>Close</button>
                                            </div>
                                            <div className="col-6">
                                                <div className="text-right">
                                                    <button type="submit" className="btn btn-primary btn-lg ml-2">
                                                        Resend
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="resend-email" theme="primary" iconType="fas" iconName="question-circle" title="Resend Email"
                           body="Are you sure you want to resend this email?">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal">
                            <FormattedMessage id="button.close" />
                        </button>
                        <button onClick={() => {this.resendEmail()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Resend Email
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

Emails.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Emails);