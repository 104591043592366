import React from 'react';
import ManagerList from "../../common/Managers";
import Propertii from "../../common/Propertii";

class Managers extends Propertii {

    /**
     * Render the component.
     *
     * @returns {*} - The manager managers component.
     */
    render() {

        return(
            <React.Fragment>

                <ManagerList userType={this.props.userSession.sessionRole.type}
                             companyId={this.props.match.params.companyId}
                             location={this.props.location}
                             editModal={true}
                             editable={true}
                             history={this.props.history}
                             createMessage="admin.companies.managers.created" />

            </React.Fragment>
        )
    };
}

export default Managers;