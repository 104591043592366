import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import lodash from "lodash";
import React from 'react';
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldCompanies from "../../common/FieldCompanies";
import FieldDate from "../../common/FieldDate";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class ApiAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            apiAccount: {},
            apiUser: '',
            apiAccounts: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            spinner: false,
            
            selectedCompaniesList:[],
            companyList: [],

            apiAccountSearchFilter:'',
            query: {
                orderBy: 'ASC',
                orderByFields: ['apiUser'],
                conditionList:[]
            },
            validationList: [],
        };

        this.searchApiAccounts = this.searchApiAccounts.bind(this);
        this.viewApiAccount = this.viewApiAccount.bind(this);
        this.searchCompanies = this.searchCompanies.bind(this);
        this.deleteApiAccount = this.deleteApiAccount.bind(this);
        this.initApiAccount = this.initApiAccount.bind(this);
        this.saveApiAccount = this.saveApiAccount.bind(this);
        this.filterApiAccounts = this.filterApiAccounts.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.handleChangeAccessedCompanyList = this.handleChangeAccessedCompanyList.bind(this);
    }

    /**
     * Initialize the API accounts list by performing a search for all API accounts.
     */
    componentDidMount() {
        this.searchCompanies();
        this.searchApiAccounts(1, 25, this.state.query);
    }

    /**
     * Populates the compnay list with all companies in the Db
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchCompanies() {
        let companyQuery = {
            orderBy: 'ASC',
            orderByFields: ['name'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'landlordId',
                    operator: 'EQUALS',
                    fieldValue: null
                }
            ]
        }
        axios.post(`${constants.REACT_APP_HOST_API_URL}/company/search?recordsPerPage=9999&page=1`, companyQuery, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                companyList: response.data.records
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle selecting an API account by bringing up the API account modal.
     *
     * @param apiAccountId - The ID of the API account selected.
     */
    viewApiAccount(apiAccountId) {
        this.setState(prevState=>({
            ...prevState,
            spinner: true
        }));

        let initialAccessedCompanyList = [];
        
        axios.get(`${constants.REACT_APP_HOST_API_URL}/api_account/${apiAccountId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            // List of company accessed ID's 
            let accessCompanies = [...new Set(response.data.accessCompanies)]; 

            if(accessCompanies.length > 0){

                accessCompanies.forEach((accessedCompanyId)=>{
                    this.state.companyList.forEach((company)=>{
                        if(company.id === accessedCompanyId){
                            initialAccessedCompanyList.push({
                                value:accessedCompanyId,
                                label:company.name
                            });
                        }
                    });
                });

            }

            
            this.setState(prevState => ({
                ...prevState,
                spinner:false,
                apiAccount: response.data,
                selectedCompaniesList:lodash.uniqWith(initialAccessedCompanyList, lodash.isEqual),
                apiUser: response.data.apiUser
            }));

            $('#api-account').modal('show');
        
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an API account object.
     */
    initApiAccount() {
        // Reset selected company field
        if(this.state.selectedCompaniesList.length > 0){
            this.setState(prevState => ({
                ...prevState,
                selectedCompaniesList: []
            }));
        }

        axios.get(`${constants.REACT_APP_HOST_API_URL}/api_account/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                apiAccount: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create or save an existing API account on submission of the API account form.
     *
     * @param event - The event container.
     */
    saveApiAccount(event) {

        event.preventDefault();

        if(this.state.apiAccount.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/create`, this.state.apiAccount, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.accounts.created'
                        }
                    }],
                });

                this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

                $('#api-account').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.apiAccount, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

                $('#api-account').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
        this.searchApiAccounts(1, 25, this.state.query);

    }

    /**
     * Delete an existing API account.
     */
    deleteApiAccount() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/api_account/${this.state.apiAccount.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.accounts.deleted'
                    }
                }],
            });

            this.searchApiAccounts(this.state.apiAccounts.page, this.state.apiAccounts.recordsPerPage, this.state.query);

            $('#api-account').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Perform a search for API accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchApiAccounts(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/api_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList:query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                apiAccounts: response.data,
                query: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle change to the company field of api account. 
     * @param selectedOptions - 
     */
    handleChangeAccessedCompanyList(selectedOptions){
        this.setState(prevState=>({
            ...prevState,
            spinner: true
        }));

        let selectedCompaniesList = [];
        let accessCompaniesList = [];

        if(selectedOptions == null){
            selectedOptions = []
        }

        if(selectedOptions.length === 0) {
            selectedCompaniesList = [];
            accessCompaniesList = [];
        }

        if(selectedOptions.length > 0) {
            selectedOptions.forEach((selectedCompany) => {

                let companyList = this.state.companyList;

                companyList.forEach((company)=>{

                    if(company.id === selectedCompany.value && !lodash.includes(accessCompaniesList, selectedCompany.value)){
                        accessCompaniesList.push(company.id);
                        selectedCompaniesList.push({
                            value:company.id,
                            label:company.name
                        });
                    }
                })
            });
        }
        
        // Use new Set() constructor to remove duplicates since it's an array of strings
        // lodahsh.uniqWith is used to filter duplicates from an array of objects, Set() doesn't work well 

        this.setState(prevState => ({
            ...prevState,
            spinner:false,
            apiAccount:{
                ...prevState.apiAccount,
                accessCompanies:[...new Set(accessCompaniesList)]
            },
            selectedCompaniesList:lodash.uniqWith(selectedCompaniesList, lodash.isEqual)
        }));
    }

    /**
     * Handle submitting the search query for api users field
     * 
     * @param event - The event container 
     */
    filterApiAccounts(event){
        if(event != null) {
            event.preventDefault();
        }
        this.setState(prevState => ({
            ...prevState,
            spinner:true
        }));

        let apiAccountQuery = this.state.query;
        
        if(this.state.apiAccountSearchFilter !== ''){
            apiAccountQuery.conditionList = [{
                type: 'STRING',
                logicalOperator: 'OR',
                fieldName: 'apiUser',
                operator: 'LIKE_IGNORE_CASE',
                fieldValue: this.state.apiAccountSearchFilter
            }]
        }

        this.setState(prevState => ({
            ...prevState,
            spinner:false,
            query: apiAccountQuery,
        }));

        this.searchApiAccounts(1,25,apiAccountQuery);
    }

    /**
     * Clears the search filter for the top search bar and re-runs query for table
     */
    clearFilters(){
       const initialQuery = {
            orderBy: 'ASC',
            orderByFields: ['apiUser'],
            conditionList:[]
        }

        this.setState(prevState => ({
            ...prevState,
            apiAccountSearchFilter: '',
            query: initialQuery
        }));

        this.searchApiAccounts(1,25,initialQuery);
    }
    /**
     * Render the component.
     *
     * @returns {*} - The sortable API accounts list.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="API Accounts" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    API Accounts
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#api-account" className="btn btn-primary btn-sm" onClick={() => this.initApiAccount()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create API Account
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-header gotham border-top py-3 bg-secondary">
                                <form onSubmit={this.filterApiAccounts}>
                                    <div className="media">
                                        <div className="media-body align-self-center mr-3">
                                            <FieldText id="apiAccountSearchFilter" label="Search" labelClass="d-none"
                                                       fieldColumns="12" parent={this} pattern="[^\/]+" required
                                                       onInvalidMessage="Uppercase/lowercase letters and numbers only"
                                                       labelColums="0" placeholder='Search API Account By User' 
                                                       value={this.state.apiAccountSearchFilter} />
                                        </div>
                                        <div className="align-self-center text-right">
                                            <div className="btn-group" role="group" aria-label="Basic example">
                                                <button type="submit" className="btn btn-secondary btn-sm mb-0">
                                                    <FontAwesomeIcon icon={['fas', 'search']} className="fa-fw"/> Search
                                                </button>
                                                <div className="btn btn-secondary btn-sm mb-0" onClick={() => this.clearFilters()}>
                                                    <FontAwesomeIcon icon={['fas', 'eraser']} className="fa-fw"/> Clear
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                        </div>
                        <Table columns={{apiUser: 'API User', apiKey: 'API Key', createDate: 'Create Date', expiryDate: 'Expiry Date'}}
                               columnWidths={['25%', '25%', '25%', '25%']}
                               headerClass="c-pointer"
                               data={this.state.apiAccounts}
                               query={this.state.query}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchApiAccounts}>
                            <tbody>
                            {this.state.apiAccounts.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewApiAccount(data.id)} className="c-pointer">
                                        <td>{data.apiUser}</td>
                                        <td>{data.apiKey}</td>
                                        <td>
                                            {data.createDate &&
                                            <Moment format="MMM DD, YYYY">
                                                {data.createDate}
                                            </Moment>
                                            }
                                            {!data.createDate &&
                                            <span className="text-muted">
                                                N/A
                                            </span>
                                            }
                                        </td>
                                        <td>
                                            {data.expiryDate &&
                                            <Moment format="MMMM DD, YYYY" tz="UTC">
                                                {data.expiryDate}
                                            </Moment>
                                            }
                                            {!data.expiryDate &&
                                            <span className="text-secondary">
                                                <FontAwesomeIcon icon={['fas', 'minus']} className="fa-fw va-b mr-1" />
                                            </span>
                                            }
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="api-account" tabIndex="-1" role="dialog" aria-labelledby="api-account-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveApiAccount}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="api-account-label">
                                            {this.state.apiAccount['createDate'] == null &&
                                            <span className="">
                                                Create API Account
                                            </span>
                                            }
                                            {this.state.apiAccount['createDate'] != null &&
                                            <span className="">
                                                {this.state.apiUser}
                                            </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="apiUser" label="API User" model="apiAccount" parent={this} value={this.state.apiAccount['apiUser']} />

                                        <FieldText id="apiKey" label="API Key" model="apiAccount" parent={this} value={this.state.apiAccount['apiKey']} />

                                        <FieldDate id="expiryDate" label="Expiry Date" model="apiAccount" parent={this} before={new Date()} value={this.state.apiAccount['expiryDate'] || null} />
                                        
                                        <FieldCompanies id="selectedCompaniesList" label="Companies" labelClass="col-form-label-sm align-self-center" 
                                                      value={this.state.selectedCompaniesList} parent={this} options={this.state.companyList}
                                                      handleChange={this.handleChangeAccessedCompanyList} help={
                                                        <div>
                                                            <div data-toggle="popover"
                                                                data-trigger="focus"
                                                                tabIndex="0"
                                                                data-html="true"
                                                                title="Api Account Company Access"
                                                                className="btn-link d-inline c-pointer"
                                                                data-content="This field will automatically add access for sub-companies. If you wish to remove sub-companies, reload this Api Account modal.">
                                                                What is this? (PLEASE READ)
                                                            </div>
                                                            <div className='text-muted form-text'>
                                                                Note: If this field is empty, the acccount will have access to ALL companies.
                                                            </div>
                                                        </div>
                                                    }/>
                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col">

                                                <div className="float-left">

                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#api-account").modal("hide")}>Close</button>

                                                </div>
                                                <div className="float-right">

                                                    {this.state.apiAccount['createDate'] != null &&
                                                    <div data-toggle="modal" data-target="#delete-api-account" className="btn btn-lg btn-primary ml-2" onClick={() => $("#api-account").modal("hide")}>
                                                        Delete
                                                    </div>
                                                    }

                                                    <ButtonSave />

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <Modal id="delete-api-account" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete API Account"
                           body="Are you sure you want to delete this API Account? This change is not reversible.">
                        <button type="button" className="btn btn-outline-danger btn-lg" data-dismiss="modal" onClick={() => $("#api-account").modal("show")}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => this.deleteApiAccount()} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete API Account
                        </button>
                    </Modal>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                </div>

            </div>
        )
    };
}

export default ApiAccounts;