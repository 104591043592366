import React, { Component } from 'react';
import FieldSelect from "../common/FieldSelect";

class FieldSegmentCode extends Component {

    /**
     * Render the component.
     *
     * @returns {*} - A select field component, specialized for company segment codes.
     */
    render() {

        return(
            <FieldSelect id={this.props.id} label={this.props.label} model={this.props.model} parent={this.props.parent} value={this.props.value} required={this.props.required}>
                <option value="">Select a segment code...</option>
                <option value="SHORT_TERM_VACATION">Short-Term/Vacation</option>
                <option value="STUDENT_HOUSING">Student Housing</option>
                <option value="AFFORDABLE">Affordable</option>
                <option value="CONVENTIONAL">Conventional</option>
                <option value="FURNISHED_RENTAL">Furnished Rental</option>
                <option value="COMMERCIAL">Commercial</option>
                <option value="CONDO_STRATA">Condo/Strata</option>
            </FieldSelect>
        )
    };
}

export default FieldSegmentCode;
