export const PAYMENT_TRANSACTION_QUERY = {
    orderBy: 'DESC',
    orderByFields: ['createDate'],
    conditionList: [],
    joins: {
        pl: {
            targetRecordType: 'TYPE_PROPERTY_LEASE',
            joinField: 'billingAccountId',
            alias: 'pl',
            returnFields: ['propertyId', 'companyId', 'unit', 'accountNumber']
        },
        p: {
            targetRecordType: 'TYPE_PROPERTY',
            joinField: 'propertyId',
            alias: 'p',
            returnFields: ['propertyName', 'street1']
        },
        c: {
            targetRecordType: 'TYPE_CUSTOMER',
            joinField: 'userId',
            alias: 'c',
            returnFields: ['firstName', 'lastName', 'email', 'userIdentifier']
        },
        co: {
            targetRecordType: 'TYPE_COMPANY',
            joinField: 'companyId',
            alias: 'co',
            returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
        },
        pc: {
            targetRecordType: 'TYPE_COMPANY',
            joinField: 'co.parentId',
            alias: 'pc',
            returnFields: ['name', 'chargeCodes', 'integrationId', 'landlordId']
        },
        installmentPay:{
            targetRecordType: 'TYPE_INSTALLMENT_PAY',
            joinField: 'paymentMethodId',
            alias: 'installmentPay',
            returnFields: ['id', 'installmentDetails', 'type', 'installments', 'externalId']
        },
        m: {
            targetRecordType: 'TYPE_MANAGER',
            joinField: 'recipientId',
            alias: 'm',
            returnFields: ['id', 'firstName', 'lastName']
        }
    }
}