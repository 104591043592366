import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios/index";
import $ from "jquery";
import qs from "qs";
import React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Link } from "react-router-dom";
import * as Scroll from 'react-scroll';
import modernTower from "../../media/img/backgrounds/modern-building.jpg";
import womanChair from "../../media/img/backgrounds/woman-chair.jpg";
import creditCards from "../../media/img/mockups/credit-cards.png";
import paymentFlow from "../../media/img/mockups/payment-flow.png";
import recurringPayment from "../../media/img/mockups/recurring-payment.png";
import transactions from "../../media/img/mockups/transactions.png";
import americanExpress from "../../media/img/payments/american-express.png";
import bankAccount from "../../media/img/payments/bank-account.png";
import discover from "../../media/img/payments/discover.png";
import mastercard from "../../media/img/payments/mastercard.png";
import unionPay from "../../media/img/payments/unionpay.png";
import visa from "../../media/img/payments/visa.png";
import * as constants from '../../util/constants';
import Alert from "../common/Alert";
import Modal from "../common/Modal";
import Propertii from "../common/Propertii";
import Spinner from "../common/Spinner";

class Landing extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            leaseApplicationPage: {},
            leaseApplication: {},
            existingTenant: false,
            firstName: '',
            lastName: '',
            email: '',
            confirmEmail: '',
            validationList: [],
            selectedTab: '',
            showSignInRecaptcha: false,
            showNewAccountRecaptcha: false,
            reCaptchaId:null,
            reCaptchaNewAccountId:null,
            recaptchaEnabled: true,
            recaptchaNewAccountEnabled: true,
            enableCreateAccountNewAccountButton: true,
        };

        this.startLeaseApplication = this.startLeaseApplication.bind(this);
        this.selectTab = this.selectTab.bind(this);

        this.showPassword = this.showPassword.bind(this);
        this.showConfirmPassword = this.showConfirmPassword.bind(this);

        this.handleSignInChange = this.handleSignInChange.bind(this);
        this.handleNewAccountChange = this.handleNewAccountChange.bind(this);

        this.onReCaptchaChange = this.onReCaptchaChange.bind(this);
        this.onReCaptchaNewAccountChange = this.onReCaptchaNewAccountChange.bind(this);
        this.getReCaptchaSetting = this.getReCaptchaSetting.bind(this);
        this.getReCaptchaNewAccountSetting = this.getReCaptchaNewAccountSetting.bind(this);

        this.submitNewAccount = this.submitNewAccount.bind(this);

        this.submitOAuthNewAccount = this.submitOAuthNewAccount.bind(this);
        this.submitOAuthLogIn = this.submitOAuthLogIn.bind(this);
        
        this.submitLogin = this.submitLogin.bind(this);

        this.loginNewAccount = this.loginNewAccount.bind(this);
        this.clearFormNewAccount = this.clearFormNewAccount.bind(this);
        this.validateFocusSignIn = this.validateFocusSignIn.bind(this);
        this.setupNoindexTag = this.setupNoindexTag.bind(this);
    }

    /**
     * On mounting of the component, fetch the lease application page associated with the page name provided in the URL.
     * The values in the lease application page record will be used to customize the look and feel of the landing page.
     * Afterwards, create a new instance of a lease application object, pre-filling some values with the ones provided
     * in the lease application page record.
     */
    componentDidMount() {

        $('[name="passwordIconEyeSlash"]').hide();
        $('[name="confirmPasswordIconEyeSlash"]').hide();

        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application_page/name/${this.props.match.params.pageName}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let leaseApplicationPage = response.data;
            this.redirectToLeaseApplication(leaseApplicationPage)
            
        }).catch(() => {
            this.props.history.push("");
        });

        this.setupNoindexTag();
    }   

    redirectToLeaseApplication(leaseApplicationPage) {
        let urlParams;
        urlParams = qs.parse(this.props.location.search.slice(1));
        axios.post(`${constants.REACT_APP_HOST_API_URL}/lease_application/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    operator: 'EQUALS',
                    fieldName: 'id',
                    fieldValue: urlParams?.applicationId
                }
            ],
            joins: {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier', 'companyId']
                },
                d: {
                    targetRecordType: 'TYPE_DOCUMENT',
                    joinField: 'id',
                    targetField: 'recordId',
                    alias: 'd',
                    returnFields: ['id', 'name', 'url']
                },
                page: {
                    targetRecordType: "TYPE_LEASE_APPLICATION_PAGE",
                    joinField: "applicationpageid",
                    targetField: "id",
                    alias: "page",
                    returnFields: [
                        "pageName"
                    ]
                }
            }
        },{
            headers: this.generateRequestHeaders(),
        }).then(response => {
            const resumeApplication = response?.data?.records[0]
            if (resumeApplication) {
                this.props.history.push({
                    pathname: '/apply',
                    state: {
                        leaseApplicationPage: leaseApplicationPage,
                        leaseApplication: {
                            ...resumeApplication,
                            leaseApplicationPage: leaseApplicationPage.id,
                            companyId: leaseApplicationPage.companyId
                        },
                    }
                });
            } else {
                this.createNewLeaseApp(leaseApplicationPage, urlParams)
            }
        }).catch(() => {
            this.createNewLeaseApp(leaseApplicationPage, urlParams)
        });
    }

    createNewLeaseApp(leaseApplicationPage, urlParams){
        axios.get(`${constants.REACT_APP_HOST_API_URL}/lease_application/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                leaseApplicationPage: leaseApplicationPage,
                leaseApplication: {
                    ...response.data,
                    leaseApplicationPage: leaseApplicationPage.id,
                    companyId: leaseApplicationPage.companyId
                },
                selectedTab: 'NEWACCOUNT',
                reCaptchaId: null,
                reCaptchaNewAccountId: null,
                nameFromEmail: urlParams.fn || '',
            }));
            this.selectTab('NEWACCOUNT');
            this.getReCaptchaSetting();
            this.getReCaptchaNewAccountSetting();
            this.validateFocusSignIn();

        }).catch(() => {
            this.props.history.push("");
        });
    }

    /**
     * Validate if the SIGN IN tab should be focus
     *
     */
    validateFocusSignIn(){
        const queryString = window.location.search;
        const urlParameters = new URLSearchParams(queryString);
        let focus = urlParameters.get("focus");

        if(focus !== null && focus === 'signin'){
            this.setState(prevState => ({
                ...prevState,
                selectedTab: 'SIGNIN',
            }));    
            this.selectTab('SIGNIN');
        }
    }
    
    /**
     * Start the lease application flow by redirecting the prospective tenant to the lease application form, passing
     * the lease application page and lease application records through the state.
     *
     * @param event - The event container.
     */
    startLeaseApplication(event) {

        event.preventDefault();

        if(this.state.leaseApplication.email !== this.state.leaseApplication.confirmEmail) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'application.email.mismatch'
                    }
                }]
            }));
        }

        else {
            this.props.history.push({
                pathname: '/apply',
                state: {
                    leaseApplicationPage: this.state.leaseApplicationPage,
                    leaseApplication: this.state.leaseApplication,
                }
            });
        }
    }

    /**
     * Select a tab from the list of navigation tabs.
     *
     * @param tabId - The ID of the tab selected.
     */
     selectTab(tabId) {
        this.setState(prevState => ({
            ...prevState,
            validationList: []
        }));

        switch(tabId) {

            case "SIGNIN":

                $('#newaccount').tab('dispose');
                $('#signin').tab('show');

                break;

            case "NEWACCOUNT":

                $('#signin').tab('dispose');
                $('#newaccount').tab('show');

                break;

            default:

                $('#newaccount').tab('dispose');
                $('#signin').tab('show');

                break;

        }
    }

    /**
     * Show/Hide Password
     *
     *
     */
     showPassword() {
      let passwordInput=$('[name="newAccountPassword"]');
      let passwordIconEyeSlash=$('[name="passwordIconEyeSlash"]');
      let passwordIconEye=$('[name="passwordIconEye"]');

      if(passwordInput.attr('type')==='password'){
          passwordInput.attr('type','text');
          passwordIconEyeSlash.show();
          passwordIconEye.hide();
      }else{
         passwordInput.attr('type','password');
         passwordIconEyeSlash.hide();
         passwordIconEye.show();
      }
    }

    /**
     * Show/Hide Confirm Password
     *
     *
     */
     showConfirmPassword() {
        let confirmPasswordInput=$('[name="confirmpassword"]');
        let confirmPasswordIconEyeSlash=$('[name="confirmPasswordIconEyeSlash"]');
        let confirmPasswordIconEye=$('[name="confirmPasswordIconEye"]');
  
        if(confirmPasswordInput.attr('type')==='password'){
            confirmPasswordInput.attr('type','text');
            confirmPasswordIconEyeSlash.show();
            confirmPasswordIconEye.hide();
        }else{
           confirmPasswordInput.attr('type','password');
           confirmPasswordIconEyeSlash.hide();
           confirmPasswordIconEye.show();
        }
      }

      /**
     * Handle Change for Sign In Email, Password
     *
     *
     */
     handleSignInChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () =>{
            
            if(this.state['signInUserName'] !== '' && this.state['signInPassword'] !== '' && typeof(this.state['signInUserName']) !== 'undefined' && typeof(this.state['signInPassword']) !== 'undefined'){
                this.setState(prevState => ({
                    ...prevState,
                    showSignInRecaptcha:true,
                }));
            }else{
                this.setState(prevState => ({
                    ...prevState,
                    showSignInRecaptcha:false,
                    reCaptchaId:null,
                }));
            }
        });
        
    }

    /**
     * Handle Change forNew Account Email, Password, Confirm Password
     *
     *
     */
     handleNewAccountChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        }, () =>{
            
            this.setState(prevState => ({
                ...prevState,
                validationList: []
            }));

            if(this.state['newAccountUsername'] !== '' && this.state['newAccountPassword'] !== '' && this.state['confirmpassword'] !== '' && typeof(this.state['newAccountUsername']) !== 'undefined' && typeof(this.state['newAccountPassword']) !== 'undefined' && typeof(this.state['confirmpassword']) !== 'undefined' && this.state['firstName'] !== '' && this.state['lastName'] !== ''){
                this.setState(prevState => ({
                    ...prevState,
                    showNewAccountRecaptcha:true,
                }));
            }else{
                this.setState(prevState => ({
                    ...prevState,
                    showNewAccountRecaptcha:false,
                    reCaptchaNewAccountId:null,
                }));
            }
        });
        
    }

     /**
     * Handles onReCaptchaChange
     */
      onReCaptchaChange(value) {
        this.setState((prevState) => ({
            ...prevState,
            reCaptchaId: value,
        }));
    }

    /**
     * Handles onReCaptchaChange for New Account
     */
     onReCaptchaNewAccountChange(value) {
        this.setState((prevState) => ({
            ...prevState,
            reCaptchaNewAccountId: value,
        }));
    }

    /**
     * Determines whether recaptcha is enabled or disabled for env. Only should be disabled for QA automation testing
     */
     getReCaptchaSetting(){

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recaptcha_enabled`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data !== null){
                this.setState(prevState => ({
                    ...prevState,
                    recaptchaEnabled: response.data
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });

    }

    /**
     * Determines whether recaptcha is enabled or disabled for env. Only should be disabled for QA automation testing
     */
     getReCaptchaNewAccountSetting(){

        axios.get(`${constants.REACT_APP_HOST_API_URL}/recaptcha_enabled`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            if(response.data !== null){
                this.setState(prevState => ({
                    ...prevState,
                    recaptchaNewAccountEnabled: response.data
                }));
            }

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });

    }

    /**
     * Submit new account
     *
     * @param event - The event container.
     */
     submitNewAccount(event) {

        event.preventDefault();

        if(this.state.newAccountPassword !== this.state.confirmpassword) {
            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    alert: {
                        type: 'danger',
                        message: 'Password and Confirm Password mismatch'
                    },
                    fields:{
                        newAccountPassword: 'Password mismatch',
                        confirmpassword: 'Confirm Password mismatch'
                    }
                }]
            }));
        }
        else {
            const queryString = window.location;
            const urlElements = queryString.pathname.split('/');
            let pageName = urlElements[2];

            axios.post(`${constants.REACT_APP_HOST_API_URL}/signup`, {
                user: {
                    "type": "TYPE_CUSTOMER",
                    "email": this.state.newAccountUsername,
                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "originType": "TYPE_LEASE_APPLICATION_PAGE",
                    "customFields":{
                        "origin": pageName,
                    }
                },
                username: this.state.newAccountUsername,
                password: this.state.newAccountPassword,
                confirmPassword: this.state.confirmpassword,
            }).then(response => {

                   
                $("#info-verify-email").modal('show');
                this.clearFormNewAccount();

            }).catch(error => {
              
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: error.response.data.message
                        },
                        fields:{
                            signInUserName: 'g',
                        }
                    }]
                }));
            });
        }
    }

    /**
     * Clear Form New account when the account is created successfully
     *
     */
     clearFormNewAccount() {
        this.setState(prevState => ({
            ...prevState,
            confirmpassword: '',
            firstName: '',
            lastName: '',
            newAccountUsername: '',
            newAccountPassword: '',
            reCaptchaNewAccountId:null,
            showNewAccountRecaptcha:false,
        }));
        window.grecaptcha.reset();
     }
     
     
     /**
     * Handle logging in via OAuth. Parse the session owner from the returned access token and redirect the user to
     * their appropriate page.
     *
     * @param response - The returned OAuth response.
     */
      submitOAuthLogIn(response) {

        if(encodeURIComponent(response.code)!== 'undefined' && encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`) !== 'undefined'){

            axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`)}`).then(response => {
                // Store token data in local storage
                localStorage.setItem('token_type', response.data.token_type);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('expires_in', response.data.expires_in);
                localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
                

                // Refresh the app state
                this.setState({
                    token_type: localStorage.getItem('token_type'),
                    access_token: localStorage.getItem('access_token'),
                    expires_in: localStorage.getItem('expires_in'),
                    status: localStorage.getItem('status'),
                    screening: localStorage.getItem('screening'),
                    broadcast_messages: localStorage.getItem('broadcast_messages')
                });

                let token = localStorage.getItem('access_token');
                let sessionRole;
               

                // Decipher the token
                if(token !== null) {

                    let base64Url = token.split('.')[1];
                    let base64 = base64Url.replace('-', '+').replace('_', '/');
                    let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));

                    sessionRole = JSON.parse(userSession.sessionRole);
                    sessionStorage.setItem('session_role_id', sessionRole.id);
                }
            
                if (sessionRole && (sessionRole.type === 'TYPE_CUSTOMER' || sessionRole.type === 'TYPE_ADMIN' )) {
                    localStorage.setItem('leaseApplication', JSON.stringify(this.state.leaseApplication));
                    let auxiliar = this.props.location.pathname.split('apply/');
                    let pageName = auxiliar[1]; 
                    window.location.href = `/customer/dashboard?la=1&pageName=` + encodeURIComponent(pageName);
                } else {
                    const pathname = localStorage.getItem('pathname');
                    this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
                    localStorage.removeItem('pathname');
                }


            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            this.setState(prevState=>({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'login.cookies.DISABLED',
                    },
                    values: {
                        errorCause: 'Google Auth',
                    }
                }],
            }));
            window.scrollTo(0, 0);
        }
    }

    /**
     * Handle new account in via OAuth. Parse the session owner from the returned access token and redirect the user to
     * their appropriate page.
     *
     * @param response - The returned OAuth response.
     */
     submitOAuthNewAccount(response) {

        if(encodeURIComponent(response.code)!== 'undefined' && encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`) !== 'undefined'){

            //axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(`${window.location.protocol}//${window.location.hostname}`)}`).then(response => {
                axios.post(`${constants.REACT_APP_HOST_API_URL}/signupoauth`, {
                    "type": "TYPE_CUSTOMER",
                    "requestUri": `${window.location.protocol}//${window.location.hostname}`,
                    "authorizationCode":response.code,
                    "oauthAccountType": "TYPE_GOOGLE_OAUTH_ACCOUNT"
                }).then(response => {
                // Store token data in local storage
                localStorage.setItem('token_type', response.data.token_type);
                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('expires_in', response.data.expires_in);
                localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
                

                // Refresh the app state
                this.setState({
                    token_type: localStorage.getItem('token_type'),
                    access_token: localStorage.getItem('access_token'),
                    expires_in: localStorage.getItem('expires_in'),
                    status: localStorage.getItem('status'),
                    screening: localStorage.getItem('screening'),
                    broadcast_messages: localStorage.getItem('broadcast_messages')
                });

                
                let sessionRole = response.data.type;

                if (sessionRole && (sessionRole === 'TYPE_CUSTOMER' || sessionRole === 'TYPE_ADMIN' )) {
                    localStorage.setItem('leaseApplication', JSON.stringify(this.state.leaseApplication));
                    let auxiliar = this.props.location.pathname.split('apply/');
                    let pageName = auxiliar[1]; 
                    window.location.href = `/customer/dashboard?la=1&pageName=` + encodeURIComponent(pageName);
                } else {
                    const pathname = localStorage.getItem('pathname');
                    this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
                    localStorage.removeItem('pathname');
                }


            }).catch(error => {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: error?.response?.data?.message,
                            code: error?.response?.data?.errorCode
                        },
                        fields:{
                            newAccountPassword: 'g',
                            confirmpassword: 'g'
                        }
                    }]
                }));
            });
        } else {
            this.setState(prevState=>({
                ...prevState,
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'danger',
                        code: 'login.cookies.DISABLED',
                    },
                    values: {
                        errorCause: 'Google Auth',
                    }
                }],
            }));
            window.scrollTo(0, 0);
        }
    }

    /**
     * Handle login form submission. If successful, store token information into the localStorage and update the entire
     * App by calling refreshAppState(). Afterward, redirect the user to their appropriate dashboard, depending on the
     * user's type and user's screening status, if applicable.
     *
     * @param event - The event container.
     */
     submitLogin(event) {

        event.preventDefault();
        this.setState(prevState => ({
            ...prevState,
            validationList: []
        }));

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorize`, {
            auth: {
                username: this.state.signInUserName,
                password: this.state.signInPassword
            }
        }).then(response => {

            // Store token data in local storage
            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));

            // Fetch broadcast messages
            this.searchBroadcastMessages();

            //Refresh App state
            this.setState({
                token_type: localStorage.getItem('token_type'),
                access_token: localStorage.getItem('access_token'),
                expires_in: localStorage.getItem('expires_in'),
                status: localStorage.getItem('status'),
                screening: localStorage.getItem('screening'),
                broadcast_messages: localStorage.getItem('broadcast_messages')
            });

            // Decipher the token
            let access_token = localStorage.getItem('access_token');
            let base64Url = access_token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
            let sessionRole = JSON.parse(userSession.sessionRole);
            sessionStorage.setItem('session_role_id', sessionRole.id);

            if (sessionRole && (sessionRole.type === 'TYPE_CUSTOMER' || sessionRole.type === 'TYPE_ADMIN')) {
                localStorage.setItem('leaseApplication', JSON.stringify(this.state.leaseApplication));
                let auxiliar = this.props.location.pathname.split('apply/');
                let pageName = auxiliar[1]; 
                window.location.href = `/customer/dashboard?la=1&pageName=` + encodeURIComponent(pageName);
               
            } else {
                const pathname = localStorage.getItem('pathname');
                this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
                localStorage.removeItem('pathname');
            }


        }).catch(error => {
            
            if(error.response?.data?.accountStatus === "UNVERIFIED") {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: "The email address has not been verified yet. Please follow the steps in the Verification email we've sent you to verify your account."
                        },
                        fields:{
                            newAccountPassword: 'g',
                            confirmpassword: 'g'
                        }
                    }]
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: error.response.data.message
                        },
                        fields:{
                            newAccountPassword: 'g',
                            confirmpassword: 'g'
                        }
                    }]
                }));
            }
        });
    }
    /**
     * Handle login form submission, for new users.
     *
     * @param event - The event container.
     */
     loginNewAccount(event) {

        event.preventDefault();
        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorize`, {
            auth: {
                username: this.state.newAccountUsername,
                password:this.state.newAccountPassword,
            }
        }).then(response => {

            // Store token data in local storage
            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));

            // Fetch broadcast messages
            this.searchBroadcastMessages();

            //Refresh App state
            this.setState({
                token_type: localStorage.getItem('token_type'),
                access_token: localStorage.getItem('access_token'),
                expires_in: localStorage.getItem('expires_in'),
                status: localStorage.getItem('status'),
                screening: localStorage.getItem('screening'),
                broadcast_messages: localStorage.getItem('broadcast_messages')
            });

            // Decipher the token
            let access_token = localStorage.getItem('access_token');
            let base64Url = access_token.split('.')[1];
            let base64 = base64Url.replace('-', '+').replace('_', '/');
            let userSession = JSON.parse(Buffer.from(base64, 'base64').toString('utf8'));
            let sessionRole = JSON.parse(userSession.sessionRole);

            if (sessionRole && (sessionRole.type === 'TYPE_CUSTOMER' || sessionRole.type === 'TYPE_ADMIN')) {
                this.props.history.push({
                    pathname: `/apply`,
                    state: {
                        email: this.state.newAccountUsername,
                        leaseApplicationPage: this.state.leaseApplicationPage,
                        leaseApplication: this.state.leaseApplication,
                    }
                });
            } else {
                const pathname = localStorage.getItem('pathname');
                this.props.history.push(pathname || this.handleLoginRedirect(sessionRole));
                localStorage.removeItem('pathname');
            }


        }).catch(error => {
            
            if(error.response?.data?.accountStatus === "UNVERIFIED") {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: "The email address has not been verified yet. Please follow the steps in the Verification email we've sent you to verify your account."
                        },
                        fields:{
                            newAccountPassword: 'g',
                            confirmpassword: 'g'
                        }
                    }]
                }));
            } else {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: error.response.data.message
                        },
                        fields:{
                            newAccountPassword: 'g',
                            confirmpassword: 'g'
                        }
                    }]
                }));
            }
        });
        
    }
    
    /**
     * Fetch available broadcast messages for the user being logged in and store the data in the local storage.
     */
     searchBroadcastMessages() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/broadcast_message/active`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('broadcast_messages', JSON.stringify(response.data));

            //Refresh App state
            this.setState({
                token_type: localStorage.getItem('token_type'),
                access_token: localStorage.getItem('access_token'),
                expires_in: localStorage.getItem('expires_in'),
                status: localStorage.getItem('status'),
                screening: localStorage.getItem('screening'),
                broadcast_messages: localStorage.getItem('broadcast_messages')
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    setupNoindexTag() {
        const meta = document.createElement('meta');
        meta.name = `robots`;
        meta.content = `noindex`;
        meta.setAttribute('id', 'meta-searching-index');

        const currentMeta = document.getElementById('meta-searching-index');

        if (currentMeta) {
            document.head.removeChild(currentMeta);
        }
        document.head.appendChild(meta);
    }


    /**
     * Render the component.
     */
    render() {

        let ScrollLink = Scroll.Link;
        const {formatMessage} = this.props.intl;

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner}/>

                <div id="signup" className="jumbotron jumbotron-fluid content-header content-header-landing mb-0" style={{background: `linear-gradient(100deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.background ? this.state.leaseApplicationPage.customFields.background : modernTower}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container main-container">

                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-5 col-sm-12">

                                <h1 className="text-white mb-4">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields ? this.state.leaseApplicationPage.customFields.heroHeading : '{HERO_HEADING}'}
                                </h1>
                                <ul className="fa-ul benefit-list text-white lead mb-5">
                                    {this.state.leaseApplicationPage && 
                                    this.state.leaseApplicationPage.customFields && 
                                    this.state.leaseApplicationPage.customFields.heroPoint1 ? 
                                        <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" />{this.state.leaseApplicationPage.customFields.heroPoint1}</li> 
                                    :   ''}
                                    {this.state.leaseApplicationPage && 
                                    this.state.leaseApplicationPage.customFields && 
                                    this.state.leaseApplicationPage.customFields.heroPoint2 ? 
                                        <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" />{this.state.leaseApplicationPage.customFields.heroPoint2}</li> 
                                    :   ''}
                                    {this.state.leaseApplicationPage && 
                                    this.state.leaseApplicationPage.customFields && 
                                    this.state.leaseApplicationPage.customFields.heroPoint3 ? 
                                        <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" />{this.state.leaseApplicationPage.customFields.heroPoint3}</li> 
                                    :   ''}
                                    {this.state.leaseApplicationPage && 
                                    this.state.leaseApplicationPage.customFields && 
                                    this.state.leaseApplicationPage.customFields.heroPoint4 ? 
                                        <li><FontAwesomeIcon icon={['far', 'check']} className="fa-li mt-2" />{this.state.leaseApplicationPage.customFields.heroPoint4}</li> 
                                    :   ''}
                                </ul>
                                <div className="mb-5">
                                    <img src={visa} className="rounded img-fluid bg-light mr-2" alt="Visa" title="Visa" width="50"/>
                                    <img src={mastercard} className="rounded img-fluid bg-light mr-2" alt="Mastercard" title="Mastercard" width="50"/>
                                    <img src={americanExpress} className="rounded img-fluid bg-light mr-2" alt="American Express" title="American Express" width="50"/>
                                    <img src={discover} className="rounded img-fluid bg-light mr-2" alt="Discover" title="Discover" width="50"/>
                                    <img src={unionPay} className="rounded img-fluid bg-light mr-2" alt="UnionPay" title="UnionPay" width="50"/>
                                    <img src={bankAccount} className="rounded img-fluid bg-light mr-2" alt="Bank Account" title="Bank Account" width="50"/>
                                </div>

                            </div>
                            <div className="col-lg-6 col-md-7 col-sm-12">
                                <div className="jumbotron jumbotron-fluid rounded shadow mx-md-5 px-4 py-5">
                                    {
                                        this.state.leaseApplicationPage?.customFields?.logo ? (
                                            <img src={this.state.leaseApplicationPage?.customFields?.logo} className="rounded img-fluid" alt="Logo" title="Logo" />
                                        ) : (
                                            <h3 className="display-5">
                                                Welcome {this.state.nameFromEmail} to LetUs!
                                            </h3>
                                        )
                                    }

                                    <span className="small text-muted">{this.state.leaseApplicationPage?.customFields?.formSubheading || 'Please create an account to securely apply'}</span>
                                   
                                    <div className="col-12 col-md-12 col-xs-12 pl-md-0 pl-lg-3 pt-4 pb-0">
                                        <ul className="nav nav-tabs border-bottom-0">
                                            <li className="nav-item">
                                                <a className={`nav-link ${this.state.selectedTab === 'NEWACCOUNT' ? 'active' : ''}`} id="newaccount-tab" data-toggle="tab" href="#newaccount" role="tab" aria-controls="newaccount" aria-selected="true" onClick={() => this.selectTab('NEWACCOUNT')}>
                                                    New account
                                                </a>
                                            </li>
                                            {/* <li className="nav-item">
                                                <a className={`nav-link ${this.state.selectedTab === 'SIGNIN' ? 'active' : ''}`} id="signin-tab" data-toggle="tab" href="#signin" role="tab" aria-controls="signin" aria-selected="true" onClick={() => this.selectTab('SIGNIN')}>
                                                    Sign in
                                                </a>
                                            </li> */}
                                        </ul>
                                    </div>
                                    <hr className="mt-0 mb-4" />

                                    <Alert validationList={this.state.validationList} />                              
                                    <div className="tab-content" id="tabs">
                                        <div className={`tab-pane fade-show ${this.state.selectedTab === 'NEWACCOUNT' ? 'active' : ''}`} id="newaccount" role="tabpanel" aria-labelledby="newaccount-tab">
                                            <form onSubmit={this.submitNewAccount}>

                                                <div className="form-group row mb-1">
                                                    <div className="col-sm-12">
                                                        <div className="input-group input-group-lg">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <FontAwesomeIcon icon={['fas', 'user']} />
                                                                </span>
                                                            </div>
                                                            <input name="firstName" type="text" placeholder={formatMessage({ id: "field.firstname"})} maxLength="100" autoFocus={true} value={this.state['firstName']} onChange={this.handleNewAccountChange} className="form-control form-control-lg mb-0 rounded-right" />
                                                            <input name="lastName" type="text" placeholder={formatMessage({ id: "field.lastname"})} maxLength="100" value={this.state['lastName']} onChange={this.handleNewAccountChange} className="form-control form-control-lg mb-0 rounded ml-2" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-1">
                                                    <div className="col-sm-12">
                                                        <div className="input-group input-group-lg">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <FontAwesomeIcon icon={['fas', 'envelope']} />
                                                                </span>
                                                            </div>
                                                            <input name="newAccountUsername" type="email" placeholder={formatMessage({ id: "field.email"})} autoFocus={true} readOnly={this.state.lockUsername} value={this.state['newAccountUsername']} onChange={this.handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right ${this.state.validationList[0] ? (this.state.validationList[0].fields['newAccountUsername'] ? 'is-invalid' : '') : ''}`} />
                                                            {/*this.state.validationList[0] &&
                                                            <div className="invalid-feedback">
                                                                <FormattedMessage id={"danger." + this.state.validationList[0].fields['newAccountUsername']} />
                                                            </div>*/
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-1">
                                                    <div className="col-sm-12">
                                                        <div className="input-group input-group-lg">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <FontAwesomeIcon icon={['fas', 'key']} />
                                                                </span>
                                                            </div>
                                                            <input name="newAccountPassword" type="password" placeholder={formatMessage({ id: "field.password"})} value={this.state['newAccountPassword']} onChange={this.handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                            <div className="input-group-append" onClick={() => {this.showPassword()}}>
                                                                <span className="input-group-text eye-container">
                                                                    <FontAwesomeIcon icon={['fal', 'eye']} name="passwordIconEye"/>
                                                                    <FontAwesomeIcon icon={['fal', 'eye-slash']} name="passwordIconEyeSlash"/>
                                                                </span>
                                                            </div>
                                                            {/*this.state.validationList[0] &&
                                                            <div className="invalid-feedback">
                                                                <FormattedMessage id={this.state.validationList[0].fields['newAccountPassword']} />
                                                            </div>*/
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mb-2">
                                                    <div className="col-sm-12">
                                                        <div className="input-group input-group-lg">
                                                            <div className="input-group-prepend">
                                                                <span className="input-group-text">
                                                                    <FontAwesomeIcon icon={['fas', 'key']} />
                                                                </span>
                                                            </div>
                                                            <input name="confirmpassword" type="password" placeholder={formatMessage({ id: "field.password.confirm"})} value={this.state['confirmpassword']} onChange={this.handleNewAccountChange} className={`form-control form-control-lg mb-0 rounded-right`} />
                                                            <div className="input-group-append" onClick={() => {this.showConfirmPassword()}}>
                                                                <span className="input-group-text eye-container">
                                                                    <FontAwesomeIcon icon={['fal', 'eye']} name="confirmPasswordIconEye"/>
                                                                    <FontAwesomeIcon icon={['fal', 'eye-slash']} name="confirmPasswordIconEyeSlash"/>
                                                                </span>
                                                            </div>
                                                            {/*this.state.validationList[0] &&
                                                            <div className="invalid-feedback">
                                                                <FormattedMessage id={this.state.validationList[0].fields['confirmpassword']} />
                                                            </div>*/
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="form-group row mt-0 mb-0">
                                                    <div className="card com-sm-12  bg-transparent mx-3 mb-0">
                                                        <div className="card-body py-0">
                                                            <small className="extra-small text-muted">
                                                                By clicking Create Account, you acknowledge you have read and agreed to our <Link to="/terms">Terms of Use</Link> and <Link to="/privacy">Privacy Policy</Link>.
                                                            </small>  
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div className="row">
                                                    <div className="col text-center">
                                                        <div className="small mb-1 mt-2 recaptcha-display">
                                                        <ReCAPTCHA
                                                            sitekey={constants.REACT_APP_RECAPTCHA_SITE_KEY}
                                                            onChange={this.onReCaptchaNewAccountChange}
                                                        />    
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col text-right">
                                                        <button type="submit" className="btn btn-lg btn-primary btn-block mb-0" 
                                                        disabled={this.state.reCaptchaNewAccountId !== null && this.state.enableCreateAccountNewAccountButton ? false : true}>
                                                            <FormattedMessage id="button.create.account" />
                                                        </button>
                                                        
                                                    </div>
                                                </div>

                                                <div className="row justify-content-sm-center mt-3">
                                                    <div className="col-md-10 text-center">
                                                        <p className="text-white text-center small text-muted">
                                                            Already have an account?&nbsp; 
                                                            <Link className="text-decoration-none" to="/login">
                                                                <span className="text-primary">Log in now</span>
                                                            </Link>
                                                        </p>
                                                    </div>
                                                </div>

                                                <Modal
                                                    id="password-expired"
                                                    theme="primary"
                                                    iconType="fas"
                                                    iconName="key"
                                                    title="Password Expired"
                                                    body="Your Letus account password has expired. Click the button below to receive an email with a link to reset your password."
                                                >
                                                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                                                        <FormattedMessage id="button.close" />
                                                    </button>
                                                    <button onClick={() => {this.resetPassword()}} className="btn btn-primary btn-lg" data-dismiss="modal">
                                                        Reset My Password
                                                    </button>
                                                </Modal>
                                            </form>
                                        </div>    
                                    </div>
                                </div>

                                <div className="row justify-content-sm-center">
                                    <div className="col-md-10 text-center">
                                        <p className="text-white text-center small">
                                            
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="container">

                    <div className="text-center pt-4">
                        <h1 className="py-4">
                            Letus is made for renters, by renters
                        </h1>
                    </div>

                    <div className="row justify-content-center py-5">

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Payments
                                </h3>
                                <p className="">
                                    With flexible payment options and recurring schedules, making rent payments is a breeze.
                                </p>
                                <ScrollLink to="payments" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'bell']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Notifications
                                </h3>
                                <p className="">
                                    Receive real-time payment updates and detailed payment receipts, direct to your inbox.
                                </p>
                                <ScrollLink to="notifications" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'home-lg']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Leases
                                </h3>
                                <p className="">
                                    Letus has you covered with support from multiple leases to multiple roommates.
                                </p>
                                <ScrollLink to="leases" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                        <div className="col-md text-center mb-5 mb-md-0">
                            <div className="px-3">
                                <FontAwesomeIcon icon={['fal', 'gift']} className="fa-fw mb-5 text-primary" size="6x"/>
                                <h3 className="display-5 mb-2">
                                    Rewards
                                </h3>
                                <p className="">
                                    Take advantage of one of your largest monthly expenses with your favorite rewards card.
                                </p>
                                <ScrollLink to="rewards" smooth={true} duration={1000}>
                                    <div className="btn btn-sm btn-outline-primary">
                                        Learn more
                                    </div>
                                </ScrollLink>
                            </div>
                        </div>

                    </div>

                    <hr className="my-5" id="payments" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Make paying your rent simple and rewarding
                            </h1>
                            <p className="mb-4 lead">
                                Despise those late fees? With Letus, you'll never miss another payment again with monthly auto payments, using a bank account or rewards credit card that suits you best.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'credit-card']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Multiple Payment Methods</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'calendar-check']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Setup Scheduled Payments</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={paymentFlow} alt="Letus offers your tenants the ability to make rent payments with their preferred payment method including Visa Credit, Visa Debit, Mastercard, Mastercard Debit, American Express, or directly from their bank account" className="img-sm-fluid" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="notifications" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Stay in the loop with real time notifications
                            </h1>
                            <p className="mb-4 lead">
                                Never lose track of your payment history again. Reviewing the details of all your past and upcoming rent payments can be done anytime, anywhere from your Letus dashboard.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'bell']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Real-Time Payment Updates</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'receipt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Detailed Payment Receipts</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={recurringPayment} alt="Receive email notifications from all of your tenants' payments, even if a payment was failed or charged back, all while funds are deposited directly into your bank account" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="leases" />

                    <div className="row">
                        <div className="col-md-6 align-self-center">
                            <h1 className="mb-4">
                                Supported for multiple leases and roommates
                            </h1>
                            <p className="mb-4 lead">
                                Whether you're paying for your son or daughter's college dorm, or splitting payments for your dorm with your roommate, Letus has you covered no matter the scenario.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'stopwatch']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Track Upcoming Payments</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'home-heart']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Review Lease Details</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <img src={transactions} alt="Letus's intuitive payments platform and user interface will save you time and make paying rent easier than ever before" className="img-sm-fluid my-3" width="130%"/>
                        </div>
                    </div>

                    <hr className="my-5" id="rewards" />

                    <div className="row">
                        <div className="col-md-6 order-md-2 align-self-center">
                            <h1 className="mb-4">
                                Maximize your rewards potential and build your credit
                            </h1>
                            <p className="mb-4 lead">
                                Make your rent payments with your favorite rewards credit card and enjoy the cash-back or points redemption from one of your largest monthly payments, all while building your credit.
                            </p>
                            <ul className="list-inline small">
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'mobile-alt']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Make Payments Anytime</span>
                                </li>
                                <li className="list-inline-item py-1">
                                    <FontAwesomeIcon icon={['far', 'gift']} className="fa-fw va-b mr-1 text-primary"/>
                                    <span className="mr-2">Earn Credit Card Rewards</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={creditCards} alt="Making rent payments with Letus allows tenants to build their credit history and earn points from their favorite rewards credit card" className="img-sm-fluid float-right" width="130%"/>
                        </div>
                    </div>

                </div>

                <div className="jumbotron jumbotron-fluid mb-0">
                    <div className="container">

                        <div className="text-center">
                            <h3 className="display-4 py-4">
                                Next steps
                            </h3>
                        </div>

                        <div className="row justify-content-center my-5">

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'id-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Create your account
                                    </h3>
                                    <p className="">
                                        Set up your Letus tenant account with your rental lease information and payment details.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0 border-left border-right">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'check-circle']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Get verified
                                    </h3>
                                    <p className="">
                                        Your property manager will verify your information and invite you to complete your account.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-4 text-center mb-5 mb-md-0">
                                <div className="px-3">
                                    <FontAwesomeIcon icon={['fal', 'credit-card']} className="fa-fw mb-5 text-primary" size="6x"/>
                                    <h3 className="display-5 mb-2">
                                        Make your payments
                                    </h3>
                                    <p className="">
                                        Start making one time payments, or never miss a payment again with monthly auto payments.
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div className="jumbotron jumbotron-fluid mb-0" style={{background: `linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('${womanChair}') no-repeat center center scroll`, backgroundSize: "cover"}}>
                    <div className="container text-center">
                        <h1 className="pt-4 mb-4 text-white">
                            A better rental experience begins with Letus
                        </h1>
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-md-center">
                                <p className="lead text-white">
                                    {this.state.leaseApplicationPage && this.state.leaseApplicationPage.customFields && this.state.leaseApplicationPage.customFields.companyName ? this.state.leaseApplicationPage.customFields.companyName : 'Your property manager'} is now accepting online payments with LetUs! Sign up today and enjoy simpler rent payments.
                                </p>
                            </div>
                        </div>
                        <ScrollLink to="signup" smooth={true} duration={1000} className="btn btn-lg btn-primary mt-3 mb-4 px-4 text-white">
                            Get Started
                        </ScrollLink>
                    </div>
                </div>

                <Modal
                    id="info-verify-email"
                    theme="primary"
                    iconType="fas"
                    iconName="exclamation-triangle"
                    title="Verify your email address"
                    body="The email address has not been verified yet. Please follow the steps in the Verification email we've sent you to verify your account."
                >
                    <button type="button" className="btn btn-outline-primary btn-lg" data-dismiss="modal">
                        <FormattedMessage id="button.close" />
                    </button>
                </Modal>

            </div>
        );
    }
}

Landing.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Landing);
