import React from 'react';
import styles from '../css/HyperWalletFaq.module.css';
import { Link } from 'react-router-dom';

const HyperWalletFaq = (props) => {
  return (
    <div className={`${styles.faqAccordionContainer} accordion`}>
      <h4 className={`mb-0 ${styles.faqHeading}`}>Frequently Asked Questions</h4>
      <hr />
      {props.faqs.map((faq, key) => (
        <div key={key}>
          <div
            id={`headingOne${key}`}
            data-toggle='collapse'
            data-target={`#collapse${key}`}
            className={`${styles.faqAccordionHeading}`}
            aria-expanded={!key}
            aria-controls={`collapse${key}`}
          >
            <h5 className='mb-0'>
              <span className={`${styles.faqQues}`}><strong>{faq.ques}</strong></span>
            </h5>
            <span role='button' className='hw-open'>
              +
            </span>
            <span role='button' className='hw-close'>
              x
            </span>
          </div>
          <div id={`collapse${key}`} className={`collapse mt-2 ${!key && 'show'}`} aria-labelledby={`headingOne${key}`}>
            <span className={`${styles.faqAns}`}>{faq.ans}</span>
          </div>
          <hr />
        </div>
      ))}
      {props.showExternalFaq && (
        <div className={`row my-5 mx-1`}>
          <div className='col-xs'>
            <span className={`${styles.hwExternalHelp}`}>
              For other questions please visit{' '}
              <a
                target='_blank'
                className={`${styles.hwExternalHelp}`}
                rel='noopener noreferrer'
                href='https://pay.hyperwallet.com/hw2web/consumer/page/contact.xhtml;jsessionid=d3d5a1ebfe7398303b3f2c7539d1'
              >
                <u>Hyperwallet Support Center</u>
              </a>
            </span>
          </div>
        </div>
      )}
      <div className='container'>
        <div className={`row ${styles.faqFooter} my-5`}>
          <div className='col-xs'>
            <Link
              to='/pay/marketplace'
              className={`btn btn-secondary btn-sm rounded text-uppercase mr-4 ${styles.btnGetStarted}`}
            >
              Try Flexible Pay Now
            </Link>
          </div>
          <div className='col-xs'>
            <a
              className={`btn btn-secondary btn-sm rounded text-uppercase ${styles.btnGetHelp}`}
              target='_blank'
              rel='noopener noreferrer'
              href='https://help.rentmoola.com/hc/en-us'
            >
              Need Help? Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HyperWalletFaq;
