import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import * as moment from "moment";
import 'moment-timezone';
import React from 'react';
import {FormattedMessage, FormattedNumber, injectIntl, intlShape} from "react-intl";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import bankAccount from "../../media/img/payments/bank-account.png";
import cashLogo from "../../media/img/payments/cash.png";
import creditCard from "../../media/img/payments/credit-card.png";
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import FieldAddress from "../common/FieldAddress";
import FieldCountry from "../common/FieldCountry";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import CardBrandIcon from "./CardBrandIcon";
import CardPreview from "./CardPreview";
import FeeProfiles from "./FeeProfiles";
import FieldCardNumber from "./FieldCardNumber";
import FieldCheckbox from "./FieldCheckbox";
import ModalUnionPay from "./ModalUnionPay";
import ReceiptPaymentTransaction from "./ReceiptPaymentTransaction";
import Spinner from "./Spinner";
import FieldCharges from "./FieldCharges";

class Payment extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            blockPayments: {
                blockBankPayment: false,
                blockCreditPayment: false,
                blockPaypalPayment: false,
                blockCashPayment: false,
            },
            billingAccount: {},
            charges: [],
            paymentMethod: {},
            billingAddress: {},

            billingAccountList: [],
            paymentMethodList: [],
            billingAddressList: [],

            openCharges: [],
            currentAmount: 0,
            currentBalance: 0,

            creditCardSecurityCodeLabel: 'CVV',
            bankAccountInstitutionNumber: '',
            bankAccountTransitNumber: '',

            billingAccountPreSelected: false,
            billingAccountSelected: false,
            chargesSelected: false,
            paymentMethodSelected: false,

            showCreditCardForm: false,
            showBankAccountForm: false,
            showBillingAddressForm: false,
            populateBillingAddressForm: false,

            showCashPaymentMethod: false,
            showCreditCardPaymentMethod: false,
            showBankPaymentMethod: false,

            paysafeCashPaymentMethod: {},
            isPaysafeCashSelected: false,
            isPaysafeCashEnabled: false,

            unionPayPaymentMethod: {},
            isUnionPaySelected: false,
            isUnionPayEnabled: false,

            property: {},

            amount: 0,
            feeAmount: 0,

            feeProfileList: [{}],
            feeProfileCountry: '',

            paymentTransaction: {},

            billingAccountFilter: '',

            acceptedPaymentTypes: [],
            customChargeCodes: [],

            billingAccountQuery: {
                orderBy: 'ASC',
                orderByFields: ['createDate'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'userId',
                        operator: 'EQUALS',
                        fieldValue: this.props.userId
                    },
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'status',
                        operator: 'EQUALS',
                        fieldValue: 'ACTIVE'
                    }
                ],
                joins: {
                    p: {
                        targetRecordType: 'TYPE_PROPERTY',
                        joinField: 'propertyId',
                        alias: 'p',
                        returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode', 'propertyIdentifier']
                    },
                    c: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'companyId',
                        alias: 'c',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId', 'manageBalance', 'displayChargeCodes']
                    },
                    pc: {
                        targetRecordType: 'TYPE_COMPANY',
                        joinField: 'c.parentId',
                        alias: 'pc',
                        returnFields: ['name', 'chargeCodes', 'integrationId', 'manageBalance', 'displayChargeCodes']
                    },
                    recurringschedule: {
                        targetRecordType: 'TYPE_RECURRING_SCHEDULE',
                        joinField: 'id',
                        targetField: "billingAccountId",
                        alias: 'recurringschedule',
                        returnFields: ['id', 'customAmount', 'billingAccountId', 'createDate', 'startDate', 'active', 'paymentMethodType', 'paymentMethodId']
                    }
                }
            },

            validationList: [],

            paypal: null,
            chargesToPay: [
                {
                    amount: '',
                    code: '',
                    name: '',
                }
            ],
            subtotal: 0,
            enableSelectCharges: false,

            scheduledPayment: this.props.scheduledPayment,
            companyOwnerList: [],
            source: '',
            saasCompanyId: ''
        };

        this.searchBillingAccounts = this.searchBillingAccounts.bind(this);
        this.searchAcceptedPaymentTypes = this.searchAcceptedPaymentTypes.bind(this);
        this.searchPaymentMethods = this.searchPaymentMethods.bind(this);

        this.filterBillingAccounts = this.filterBillingAccounts.bind(this);

        this.saveBillingAccount = this.saveBillingAccount.bind(this);
        this.saveCharges = this.saveCharges.bind(this);
        this.savePaymentMethod = this.savePaymentMethod.bind(this);

        this.editBillingAccount = this.editBillingAccount.bind(this);
        this.editCharges = this.editCharges.bind(this);
        this.editPaymentMethod = this.editPaymentMethod.bind(this);

        this.getOpenCharges = this.getOpenCharges.bind(this);

        this.createPaymentMethod = this.createPaymentMethod.bind(this);
        this.initBillingAddress = this.initBillingAddress.bind(this);
        this.searchBillingAddresses = this.searchBillingAddresses.bind(this);

        this.handleChangeBillingAddress = this.handleChangeBillingAddress.bind(this);
        this.handleChangePopulateBillingAddress = this.handleChangePopulateBillingAddress.bind(this);
        this.handleChangeBillingAccount = this.handleChangeBillingAccount.bind(this);
        this.handleChangePaymentMethod = this.handleChangePaymentMethod.bind(this);

        this.calculateFees = this.calculateFees.bind(this);
        this.getFeeProfiles = this.getFeeProfiles.bind(this);

        this.submitPayment = this.submitPayment.bind(this);
        this.submitPaysafePayment = this.submitPaysafePayment.bind(this);
        this.submitUnionPayPayment = this.submitUnionPayPayment.bind(this);
        this.getPaysafeCashPaymentMethod = this.getPaysafeCashPaymentMethod.bind(this);
        this.getUnionPayPaymentMethod = this.getUnionPayPaymentMethod.bind(this);

        this.getMakeTenantPaymentsFlag = this.getMakeTenantPaymentsFlag.bind(this);
        this.addCharge = this.addCharge.bind(this);
        this.removeCharge = this.removeCharge.bind(this);

    }

    /**
     * On mounting of the component, initialize the list of available billing accounts for the user to select from. If
     * a billing account has already been pre-determined, apply it to the state immediately and skip the first step of
     * having to search for a billing account.
     */
    componentDidMount() {
        if (this.props.billingAccount?.blockPayments)
            this.setState(prevState => ({
                ...prevState,
                blockPayments: {
                    blockBankPayment: this.props.billingAccount.blockPayments['TYPE_BANK_ACCOUNT']?.blocked,
                    blockCreditPayment: this.props.billingAccount.blockPayments['TYPE_CREDIT_CARD']?.blocked,
                    blockPaypalPayment: this.props.billingAccount.blockPayments['TYPE_PAY_PAL']?.blocked,
                    blockCashPayment: this.props.billingAccount.blockPayments['TYPE_CASH']?.blocked,
                },
            }));
        if (this.props.billingAccount) {

            let enableSelectCharges = this.props.billingAccount?.joins?.c?.manageBalance === 'NO' && this.props.billingAccount?.joins?.c?.displayChargeCodes ? true : false;
            this.setState(prevState => ({
                ...prevState,
                billingAccount: this.props.billingAccount,
                billingAccountPreSelected: true,
                billingAccountSelected: true,
                charges: [{}],
                customChargeCodes: this.props.billingAccount.joins.c.chargeCodes ? this.props.billingAccount.joins.c.chargeCodes : this.props.billingAccount.joins.pc.chargeCodes,
                validationList: [],
                enableSelectCharges: enableSelectCharges,
            }), () => {
                this.getOpenCharges();
                this.searchAcceptedPaymentTypes();
            });
        }

        if (this.props.source === 'SASSPRICING') {

            this.setState(prevState => ({
                ...prevState,
                companyOwnerList: this.props.companyOwnerList,
                source: this.props.source,
                saasCompanyId: this.props.saasCompanyId,
                acceptedPaymentTypes: this.props.acceptedPaymentTypes,
                billingAccountPreSelected: true,
                billingAccountSelected: true,
                charges: [{}],
                customChargeCodes: [{}],
                validationList: [],
            }), () => {
                this.getOpenCharges();
            });
        }

        if (!this.props.billingAccount) {

            this.searchBillingAccounts();

        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.scheduledPayment?.chargesTotal !== prevProps.scheduledPayment?.chargesTotal ||
            this.props.scheduledPayment?.scheduledDate !== prevProps.scheduledPayment?.scheduledDate) {
            this.setState(prevState => ({
                ...prevState,
                scheduledPayment: this.props.scheduledPayment
            }));
        }
    }

    /**
     * Search for all available billing accounts assigned to the user and add them to the list of billing accounts for
     * the user to select from.
     */
    searchBillingAccounts() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/property_lease/search`, {
            orderBy: this.state.billingAccountQuery.orderBy,
            orderByFields: this.state.billingAccountQuery.orderByFields,
            conditionList: this.state.billingAccountQuery.conditionList,
            joins: this.state.billingAccountQuery.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            let enableSelectCharges;
            if (response.data.records[0]?.joins?.pc) {
                enableSelectCharges = response.data.records[0]?.joins?.pc?.manageBalance === 'NO' && response.data.records[0]?.joins?.pc?.displayChargeCodes ? true : false;
            } else {
                enableSelectCharges = response.data.records[0]?.joins?.c?.manageBalance === 'NO' && response.data.records[0]?.joins?.c.displayChargeCodes ? true : false;
            }

            this.setState(prevState => ({
                ...prevState,
                billingAccountList: response.data.records,
                enableSelectCharges: enableSelectCharges,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Get the flag to make tenants payments. This flag is used to determine if the manager is allowed to make payment on behalf of the tenant
     */
    getMakeTenantPaymentsFlag() {
        return axios.get(`${constants.REACT_APP_HOST_API_URL}/manager/${this.props.userSession?.sessionRole?.id}`, {
            headers: this.generateRequestHeaders(),
        })
    }

    /**
     * Search for all available accepted payment types once the billing account for the payment has been established.
     * Based on the accepted payment types, some payment methods on the payment method list may be disabled because
     * the appropriate merchant account is not set up.
     */
    searchAcceptedPaymentTypes() {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/property/${this.state.billingAccount.propertyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                property: response.data
            }));

            let acceptedPaymentTypes;

            acceptedPaymentTypes = response.data.acceptedPaymentTypes;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.billingAccount.companyId}/peek`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                response.data.acceptedPaymentTypes.forEach((data, index) => {
                    if (!acceptedPaymentTypes.includes(data)) {
                        acceptedPaymentTypes.push(data);
                    }
                });

                this.setState(prevState => ({
                    ...prevState,
                    acceptedPaymentTypes: acceptedPaymentTypes,
                }));

                this.getMakeTenantPaymentsFlag().then(responseMakeTenantPaymentsFlag => {
                    const makeTenantPayments = responseMakeTenantPaymentsFlag.data?.makeTenantPayments;

                    this.setState(prevState => ({
                        ...prevState,
                        makeTenantPayments: makeTenantPayments,
                    }));

                    let paymentMethodCount = 0;
                    if (acceptedPaymentTypes.includes('TYPE_CASH') && this.state.property.country === 'US') {
                        this.setState(prevState => ({
                            ...prevState,
                            showCashPaymentMethod: true,
                            isPaysafeCashEnabled: true,
                        }));
                        paymentMethodCount += 1;
                    } else if (acceptedPaymentTypes.includes('TYPE_CASH')) {
                        this.setState(prevState => ({
                            ...prevState,
                            showCashPaymentMethod: true,
                            isPaysafeCashEnabled: false
                        }));
                        paymentMethodCount += 1;
                    }
                    if (makeTenantPayments) {
                        if (acceptedPaymentTypes.includes('TYPE_BANK_ACCOUNT')) {
                            this.setState(prevState => ({
                                ...prevState,
                                showBankPaymentMethod: true,
                            }));
                            paymentMethodCount += 1;
                        }
                        if (acceptedPaymentTypes.includes('TYPE_PAY_PAL')) {
                            paymentMethodCount += 1;
                        }

                        if (acceptedPaymentTypes.length > paymentMethodCount) {
                            this.setState(prevState => ({
                                ...prevState,
                                showCreditCardPaymentMethod: true,
                            }));
                        }

                        if (acceptedPaymentTypes.includes('UNION_PAY') && this.state.property.country === 'CA') {
                            this.setState(prevState => ({
                                ...prevState,
                                showUnionPayMethod: true,
                                isUnionPayEnabled: true,
                            }));
                            paymentMethodCount += 1;
                        }
                    }
                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });

    }

    /**
     * Search for all available payment methods assigned to the user and add them to the list of payment methods for the
     * user to select from. Erase the list of payment methods prior to the search to ensure no duplicates are added.
     */
    searchPaymentMethods() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodList: [],
        }));

        if (this.state.source === 'SASSPRICING') {

            if (this.props.userType === 'TYPE_COMPANY_OWNER') {
                let paymentMethodList = [];
                for (let i = 0; i < this.props.companyOwnerList.length; i++) {
                    axios.get(`${constants.REACT_APP_HOST_API_URL}/COMPANY_OWNER/${this.props.companyOwnerList[i]?.id}/paymentmethods`, {
                        headers: this.generateRequestHeaders()
                    }).then(response => {

                        response.data.forEach((data, index) => {
                            if (!this.state.paymentMethodList.includes(data)) {
                                paymentMethodList.push(data);
                            }
                        });

                        this.setState(prevState => ({
                            ...prevState,
                            paymentMethodList: paymentMethodList,
                        }));

                    }).catch(error => {
                        this.handleValidation(error);
                    });
                }
            } else if (this.props.userType === 'TYPE_ADMIN') {
                const url = window.location.pathname;
                const matchedCompanyIds = url.match(/(?:companies\/)(.*?)(?=\/payment)/g);
                const companyId = matchedCompanyIds[0].substring(10);
                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${companyId}/paymentmethods`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethodList: response.data
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            } else if (this.props.userType === 'TYPE_MANAGER') {
                let effectiveRoleId = this.state.billingAccount.userId;
                if (!effectiveRoleId) { effectiveRoleId = sessionStorage.getItem('session_role_id'); }
                axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.userType.substring(5).toLowerCase()}/${effectiveRoleId}/paymentmethods`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethodList: response.data
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            } else {
                axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.state.billingAccount.userType.substring(5).toLowerCase()}/${this.state.billingAccount.userId}/paymentmethods`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {

                    this.setState(prevState => ({
                        ...prevState,
                        paymentMethodList: response.data
                    }));

                }).catch(error => {
                    this.handleValidation(error);
                });
            }

        } else {

            axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.state.billingAccount.userType.substring(5).toLowerCase()}/${this.state.billingAccount.userId}/paymentmethods`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethodList: this.state.makeTenantPayments ? response.data : [],
                }));

            }).catch(error => {
                this.handleValidation(error);
            });
        }
    }

    /**
     * Filter the list of billing accounts based on a search term. Allows managers to filter through property leases
     * they have access to by tenant or property search terms. If no search filter is provided, the query defaults to
     * the regular query given to customers utilizing this flow.
     *
     * @param event - The event container.
     */
    filterBillingAccounts(event) {

        event.persist();

        this.setState({
            billingAccountFilter: event.target.value,
        });

        let billingAccountQuery = this.state.billingAccountQuery;

        if (event.target.value === '') {

            billingAccountQuery.conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.props.userId
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: 'ACTIVE'
                }
            ];

            billingAccountQuery.joins = {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                },
                u: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'u',
                    returnFields: ['firstName', 'lastName', 'phone', 'email']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId', 'manageBalance', 'displayChargeCodes']
                },
                pc: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'c.parentId',
                    alias: 'pc',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'manageBalance', 'displayChargeCodes']
                },
            }

        }

        if (event.target.value !== '') {

            billingAccountQuery.conditionList = [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'status',
                    operator: 'EQUALS',
                    fieldValue: 'ACTIVE'
                },
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: '(',
                    closeBrackets: null,
                    fieldName: 'id',
                    operator: 'EQUALS',
                    fieldValue: event.target.value
                },
                {
                    type: 'STRING',
                    logicalOperator: 'OR',
                    openBrackets: null,
                    closeBrackets: ')',
                    fieldName: 'u.firstName,u.lastName,u.email,unit,p.propertyName,p.street1,p.postalCode',
                    operator: 'LIKE_IGNORE_CASE',
                    fieldValue: event.target.value
                }
            ];

            billingAccountQuery.joins = {
                p: {
                    targetRecordType: 'TYPE_PROPERTY',
                    joinField: 'propertyId',
                    alias: 'p',
                    returnFields: ['propertyName', 'street1', 'street2', 'city', 'province', 'country', 'postalCode']
                },
                u: {
                    targetRecordType: 'TYPE_CUSTOMER',
                    joinField: 'userId',
                    alias: 'u',
                    returnFields: ['firstName', 'lastName', 'phone', 'email']
                },
                c: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'companyId',
                    alias: 'c',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId', 'manageBalance', 'displayChargeCodes']
                },
                pc: {
                    targetRecordType: 'TYPE_COMPANY',
                    joinField: 'c.parentId',
                    alias: 'pc',
                    returnFields: ['name', 'chargeCodes', 'integrationId', 'manageBalance', 'displayChargeCodes']
                },
                recurringschedule: {
                    targetRecordType: 'TYPE_RECURRING_SCHEDULE',
                    joinField: 'id',
                    targetField: "billingAccountId",
                    alias: 'recurringschedule',
                    returnFields: ['id', 'customAmount', 'billingAccountId', 'createDate', 'startDate', 'active', 'paymentMethodType', 'paymentMethodId']
                }
            };

        }

        this.setState({
            billingAccountQuery: billingAccountQuery,
        });

        this.searchBillingAccounts();
    }

    /**
     * Save the selected billing account. Perform a basic check to see if a billing account has been selected.
     *
     * @param event - The event container.
     */
    saveBillingAccount(event) {

        event.preventDefault();

        if (this.state.billingAccount.id) {

            let customChargeCodes = this.state.billingAccount.joins.c.chargeCodes ? this.state.billingAccount.joins.c.chargeCodes : this.state.billingAccount.joins.pc.chargeCodes;
            let charges = this.state.charges;

            // If the list of charges is empty, add a first blank charge
            if (charges.length === 0) {
                charges.push({});
            }

            this.setState(prevState => ({
                ...prevState,
                billingAccountSelected: true,
                customChargeCodes: customChargeCodes,
                charges: charges,
                validationList: [],
                blockPayments: {
                    blockBankPayment: this.state.billingAccount?.blockPayments['TYPE_BANK_ACCOUNT']?.blocked,
                    blockCreditPayment: this.state.billingAccount?.blockPayments['TYPE_CREDIT_CARD']?.blocked,
                    blockPaypalPayment: this.state.billingAccount?.blockPayments['TYPE_PAY_PAL']?.blocked,
                    blockCashPayment: this.state.billingAccount?.blockPayments['TYPE_CASH']?.blocked,
                },
            }));

            this.getOpenCharges();
            this.searchAcceptedPaymentTypes();
        }

        if (!this.state.billingAccount.id) {

            this.setState(prevState => ({
                ...prevState,
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.lease.null'
                    }
                }]
            }));

            window.scrollTo(0, 0);
        }
    }

    /**
     * Save the selected charges. Calculates the total of all charges, and also checks to see if at least one charge has
     * been fully provided. Splices out any incomplete charges.
     *
     * @param event - The event container.
     */
    saveCharges(event) {

        event.preventDefault();

        if (this.state.enableSelectCharges) {
            const {formatMessage} = this.props.intl;
            //Validate list of charges
            let charges = this.state.chargesToPay.filter(value => value.amount === '' && value.code !== '' && value.name !== '');
            if (charges.length) {
                this.setErrorMessage(formatMessage({id: "danger.common.payment.charges.empty"}));
                this.handleClearValidationList();
                return;
            }

            charges = this.state.chargesToPay.filter(value => value.amount !== '' && value.code === '' && value.name === '');
            if (charges.length) {
                this.setErrorMessage(formatMessage({id: "danger.common.payment.charges.code.empty"}));
                this.handleClearValidationList();
                return;
            }

            charges = this.state.chargesToPay.filter(value => value.amount !== '' && value.code !== '' && value.name !== '');
            if (!charges.length) {
                this.setErrorMessage(formatMessage({id: "danger.common.payment.charges.null"}));
                this.handleClearValidationList();
                return;
            }

            let subtotal = charges.reduce((accumulator, currentValue) => accumulator + Number(currentValue["amount"]), 0);

            this.setState(prevState => ({
                ...prevState,
                chargesToPay: charges,
                subtotal: subtotal,
            }));
        }

        this.setState(prevState => ({
            ...prevState,
            chargesSelected: true,
            validationList: [],
        }));

        this.searchPaymentMethods();
    }

    /**
     * Save the selected payment method. Handle basic validation such as no payment method selected, selecting an
     * existing payment method, or creating a new payment method. After saving, calculate the transaction fees based on
     * the selected charges and payment method information.
     *
     * @param event - The event container.
     */
    savePaymentMethod(event) {

        if (event != null) {
            event.preventDefault();
        }

        if (this.state.possibleUnionPayBin) {

            $('#confirm-unionpay').modal('show');

            return null;
        }

        // Handle no selection
        if (Object.keys(this.state.paymentMethod).length === 0) {

            this.setState({
                validationList: [{
                    alert: {
                        type: 'danger',
                        code: 'common.payment.method.null'
                    }
                }]
            });

            window.scrollTo(0, 0);

            return null;
        }

        let paymentMethod = this.state.paymentMethod;
        let billingAddress = this.state.billingAddress;
        let saveListData = [];

        // Handle existing payment method selection
        if (!this.state.showCreditCardForm && !this.state.showBankAccountForm) {

            this.setState(prevState => ({
                ...prevState,
                paymentMethodSelected: true,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false,
                validationList: []
            }));

            if (this.state.source === 'SASSPRICING') {

                let billingAccountQuery = {
                    orderBy: 'ASC',
                    orderByFields: ['createDate'],
                    conditionList: [
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'u.id',
                            operator: 'EQUALS',
                            fieldValue: this.state.paymentMethod.userId
                        },
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'c.id',
                            operator: 'EQUALS',
                            fieldValue: this.state.saasCompanyId
                        }
                    ],
                    joins: {
                        u: {
                            targetRecordType: 'TYPE_MANAGER',
                            joinField: 'userId',
                            alias: 'u',
                            returnFields: ['firstName', 'lastName', 'phone', 'email']
                        },
                        c: {
                            targetRecordType: 'TYPE_COMPANY',
                            joinField: 'companyId',
                            alias: 'c',
                            returnFields: ['name', 'chargeCodes', 'integrationId', 'parentId', 'manageBalance', 'displayChargeCodes']
                        },
                        pc: {
                            targetRecordType: 'TYPE_COMPANY',
                            joinField: 'c.parentId',
                            alias: 'pc',
                            returnFields: ['name', 'chargeCodes', 'integrationId']
                        },
                        recurringschedule: {
                            targetRecordType: 'TYPE_RECURRING_SCHEDULE',
                            joinField: 'id',
                            targetField: "billingAccountId",
                            alias: 'recurringschedule',
                            returnFields: ['id', 'customAmount', 'billingAccountId', 'createDate', 'startDate', 'active', 'paymentMethodType', 'paymentMethodId']
                        },
                    }
                };

                axios.post(`${constants.REACT_APP_HOST_API_URL}/billing_account/search`, {
                    orderBy: billingAccountQuery.orderBy,
                    orderByFields: billingAccountQuery.orderByFields,
                    conditionList: billingAccountQuery.conditionList,
                    joins: billingAccountQuery.joins
                }, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    let enableSelectCharges;
                    if (response.data.records[0]?.joins?.pc) {
                        enableSelectCharges = response.data.records[0]?.joins?.pc?.manageBalance === 'NO' && response.data.records[0]?.joins?.pc?.displayChargeCodes ? true : false;
                    } else {
                        enableSelectCharges = response.data.records[0]?.joins?.c?.manageBalance === 'NO' && response.data.records[0]?.joins?.c.displayChargeCodes ? true : false;
                    }

                    this.setState(prevState => ({
                        ...prevState,
                        billingAccount: response.data.records[0],
                        enableSelectCharges: enableSelectCharges,
                    }));

                    this.calculateFees();

                }).catch(error => {
                    this.handleValidation(error);
                });

            } else {

                this.calculateFees();
            }

            if (this.state.paymentMethod.type === 'TYPE_PAY_PAL') {
                setTimeout(() => {
                    this.initPaypalContainer();
                }, 50);
            }
        }

        // Add the billingAddress state to the save list queue if the new billing address form is shown
        if (this.state.showBillingAddressForm) {
            saveListData.push(billingAddress);
        }

        // Add the paymentMethod state to the save list queue if the new payment method form is shown
        if (this.state.showCreditCardForm || this.state.showBankAccountForm) {
            saveListData.push(paymentMethod);
        }

        // Handle 'securityCode' to 'cvv' conversions for credit cards
        if (paymentMethod.type === 'TYPE_CREDIT_CARD') {
            paymentMethod.cvv = this.state.paymentMethod.securityCode;
        }

        // Handle institution and transit number conversions to routing number for Canadian banks
        if (paymentMethod.type === 'TYPE_BANK_ACCOUNT' && paymentMethod.country === 'CA') {
            paymentMethod.routingNumber = this.state.bankAccountInstitutionNumber + this.state.bankAccountTransitNumber;
        }

        // Create a new payment method if the new payment method form is shown
        if (this.state.showCreditCardForm || this.state.showBankAccountForm) {

            this.setState(prevState => ({
                ...prevState,
                spinner: true
            }));

            const headers = this.generateRequestHeaders();
            const sessionId = window.ka?.sessionId;
            if (paymentMethod.type === 'TYPE_CREDIT_CARD' && sessionId) {
                headers.blueSnapSessionId = sessionId;
            }
            axios.post(`${constants.REACT_APP_HOST_API_URL}/savelist`, saveListData, {
                headers: headers
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    paymentMethod: response.data[this.state.showBillingAddressForm ? 1 : 0],
                    paymentMethodSelected: true,
                    showCreditCardForm: false,
                    showBankAccountForm: false,
                    showBillingAddressForm: false,
                    spinner: false,
                    validationList: [],
                }));

                this.searchBillingAddresses();
                this.searchPaymentMethods();
                this.calculateFees();

            }).catch(error => {

                this.handleValidation(error);

                window.scrollTo(0, 0);

            });
        }
    }

    /**
     * Edit the selected billing account. Updates the state to reflect the billing account portion of the flow.
     */
    editBillingAccount() {

        this.setState(prevState => ({
            ...prevState,
            billingAccountSelected: false,
            chargesSelected: false,
            paymentMethodSelected: false,
        }));
    }

    /**
     * Edit the selected charges. Updates the state to reflect the charges portion of the flow.
     */
    editCharges() {
        this.setState(prevState => ({
            ...prevState,
            chargesSelected: false,
            paymentMethodSelected: false,
        }));
    }

    /**
     * Edit the selected payment method. Updates the state to reflect the payment method portion of the flow.
     */
    editPaymentMethod() {

        this.setState(prevState => ({
            ...prevState,
            paymentMethodSelected: false,
        }));
    }

    /**
     * Fetch a list of open charges associated with the selected billing account.
     */
    getOpenCharges() {

        let postMonthFilter = new Date();

        postMonthFilter.setDate(1);
        postMonthFilter = postMonthFilter.setMonth(postMonthFilter.getMonth() + 1);

        axios.post(`${constants.REACT_APP_HOST_API_URL}/open_charge/search`, {
            orderBy: 'ASC',
            orderByFields: ['postMonth', 'createDate'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'billingAccountId',
                    operator: 'EQUALS',
                    fieldValue: this.state.billingAccount.id
                },
                {
                    type: 'NUMBER',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'balance',
                    operator: 'NOT_EQUALS',
                    fieldValue: 0
                }
            ],
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let openCharges = response.data.records;
            let currentAmount = 0;
            let currentBalance = 0;

            openCharges.forEach((charge, index) => {
                currentAmount += charge.amount;
                currentBalance += charge.balance;
            });

            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                openCharges: openCharges,
                currentAmount: currentAmount,
                currentBalance: currentBalance,
            }));

        }).catch(error => {
            this.handleValidation(error);
            window.scrollTo(0, 0);
        });
    }

    initPaypalSDK(currency = 'USD', orderId, merchantAccountId) {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&merchant-id=${merchantAccountId}&currency=${currency}&components=buttons&vault=true&disable-funding=credit`;
            script.dataset.orderId = orderId;
            const currentScript = document.getElementById('paypal-script');
            if (currentScript) {
                document.body.removeChild(currentScript);
            }
            script.setAttribute('id', 'paypal-script');
            document.body.appendChild(script);

            const injectScript = () => {
                this.setState(prevState => ({
                    ...prevState,
                    paypal: window.paypal,
                    spinner: false,
                }));

                script.removeEventListener('load', injectScript);
                resolve();
            };

            script.addEventListener('load', injectScript);
            script.addEventListener('error', (error) => {
                this.setState({
                    spinner: false,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            message: 'Something went wrong trying to connect to PayPal. ' +
                                'Please contact customer support immediately by emailing help@let.us or calling 1-888-665-8870.'
                        },
                        showMessage: true,
                    }],
                });
            });
        });
    }

    /**
     * Initiate the Paypal button container
     * Aswell as the callbacks for creation and approval of orders
     */
    initPaypalContainer() {
        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        this.createPaypalOrder()
            .then(response => {
                if (response) {
                    this.initPaypalSDK(response.data.currency, response.data.orderId, response.data.merchantId)
                        .then(() => {
                            const buttons = window.paypal.Buttons({
                                style: {
                                    layout: 'horizontal',
                                    height: 38,
                                },
                                createOrder: (data, actions) => {
                                    return response.data.orderId;
                                },
                                onApprove: (data, actions) => {
                                    this.setState(prevState => ({
                                        ...prevState,
                                        spinner: true
                                    }));

                                    return this.capturePaypalOrder(data.orderID)
                                        .then(response => {
                                            if (response) {
                                                this.setState(prevState => ({
                                                    ...prevState,
                                                    paymentTransaction: response.data,
                                                    spinner: false
                                                }));

                                                $('#receipt-payment-transaction').modal('show');
                                            }
                                        });
                                }
                            });

                            buttons.render('#paypal-button-container');
                        });
                }

                return false;
            });
    }

    /**
     * Create an order with Paypal
     *
     * @returns Promise
     */
    createPaypalOrder() {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/paypal_create_order`, {
            amount: this.state.amount,
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
        }, {
            headers: this.generateRequestHeaders(),
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error);
            });
    }

    /**
     * Capture an order already created by orderId
     *
     * @param orderId - The type of the payment method (i.e. credit_card, bank_account, etc.)
     * @returns Promise
     */
    capturePaypalOrder(orderId) {
        return axios.post(`${constants.REACT_APP_HOST_API_URL}/paypal_capture_order`, {
            amount: this.state.amount,
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
            orderId: orderId,
        }, {
            headers: this.generateRequestHeaders(),
        })
            .then(response => response)
            .catch(error => {
                this.handleValidation(error);
            });
    }

    /**
     * Initialize a new instance of a payment method. Upon submission, perform a search for billing addresses available
     * to the user to populate the billing address field.
     *
     * @param paymentType - The type of the payment method (i.e. credit_card, bank_account, etc.)
     */
    createPaymentMethod(paymentType) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${paymentType}/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentMethod: {
                    ...response.data,
                    userId: this.state.billingAccount.userId,
                    userType: this.state.billingAccount.userType
                },
                showCreditCardForm: paymentType === 'credit_card',
                showBankAccountForm: paymentType === 'bank_account'
            }));

            this.searchBillingAddresses();

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of a billing address, assigning it to the payment method at hand and revealing the
     * billing address fields.
     */
    initBillingAddress() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/address/new`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                showBillingAddressForm: true,
                billingAddress: {
                    ...response.data,
                    addressType: 'BILLING',
                    userType: this.state.billingAccount.userType,
                    userId: this.state.billingAccount.userId
                },
                paymentMethod: {
                    ...prevState.paymentMethod,
                    billingAddressId: response.data.id
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Search for all addresses of type 'BILLING' associated with the user.
     */
    searchBillingAddresses() {
        axios.post(`${constants.REACT_APP_HOST_API_URL}/address/search`, {
            orderBy: 'ASC',
            orderByFields: ['id'],
            conditionList: [
                {
                    type: 'STRING',
                    logicalOperator: 'AND',
                    openBrackets: null,
                    closeBrackets: null,
                    fieldName: 'userId',
                    operator: 'EQUALS',
                    fieldValue: this.state.billingAccount.userId
                }
            ]
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                billingAddressList: response.data.records,
            }));

            if (response.data.records.length === 0) {
                this.initBillingAddress();
            }

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle changes to the billing account selection. Parses the value of the selected billing account as JSON.
     *
     * @param event - The event container.
     */
    handleChangeBillingAccount(event) {

        event.persist();
        const selectedLease = JSON.parse(event.target.value);
        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: selectedLease,
            scheduledPayment: selectedLease.joins?.recurringschedule
        }));

        if (selectedLease.joins?.recurringschedule?.active) {
            this.getUpcomingPayment(selectedLease.joins?.recurringschedule?.id);
        }
    }

    /**
     * Handle changes to the billing address field. If the 'Add new billing address...' option is selected, reveal the
     * billing address creation form.
     *
     * @param event - The event container.
     */
    handleChangeBillingAddress(event) {

        if (event.target.value !== 'NEW') {

            this.setState(({
                paymentMethod: {
                    ...this.state.paymentMethod,
                    billingAddressId: event.target.value
                },
            }));

        } else {

            this.initBillingAddress();

        }
    }

    /**
     * Handle changes to the populate billing address checkbox, allowing the user to populate all the billing address
     * information automatically using the address of the property they are making a payment for.
     *
     * @param event - The event container.
     */
    handleChangePopulateBillingAddress(event) {

        event.persist();

        let residentialAddress;
        let billingAddress;

        // Populate the billing address with the information in the customer's new property
        if (event.target.checked) {

            residentialAddress = this.state.billingAccount.joins.p;
            billingAddress = this.state.billingAddress;

            billingAddress.city = residentialAddress.city;
            billingAddress.country = residentialAddress.country;
            billingAddress.postalCode = residentialAddress.postalCode;
            billingAddress.province = residentialAddress.province;
            billingAddress.street1 = residentialAddress.street1;
            billingAddress.street2 = residentialAddress.street2;
            billingAddress.suite = this.state.billingAccount.unit;
        }

        // Clear the billing address fields if the checkbox is unchecked
        if (!event.target.checked) {

            billingAddress = this.state.billingAddress;

            billingAddress.city = '';
            billingAddress.country = '';
            billingAddress.postalCode = '';
            billingAddress.province = '';
            billingAddress.street1 = '';
            billingAddress.street2 = '';
            billingAddress.suite = '';
        }

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.checked,
            billingAddress: billingAddress
        }));
    }

    /**
     * Handle changes to the selected payment method. Parses the value of the selected payment method as JSON. If the
     * user happens to select an existing payment method while creating a new payment method, hide the new payment
     * method creation and billing address creation forms accordingly.
     *
     * @param event - The event container.
     */
    handleChangePaymentMethod(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            isPaysafeCashSelected: false,
            isUnionPaySelected: false,
            [event.target.name]: JSON.parse(event.target.value)
        }));

        // If selecting an existing method while currently entering a new method, close all new method forms
        if (this.state.showCreditCardForm || this.state.showBankAccountForm) {
            this.setState(prevState => ({
                ...prevState,
                showCreditCardForm: false,
                showBankAccountForm: false,
                showBillingAddressForm: false
            }));
        }
    }

    /**
     * Get upcoming payment details based on the recurringschedule ID.
     * Update the scheduledPayment state with scheduledDate and chargesTotal.
     * @param {*} recurringscheduleId
     */
    getUpcomingPayment(recurringscheduleId) {
        axios.get(`${constants.REACT_APP_HOST_API_URL}/recurring_schedule/${recurringscheduleId}/upcomingpayment`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            const charges = response.data?.charges;
            let chargesTotal = 0;
            charges.forEach(charge => {
                chargesTotal += Number(charge.amount);
            });

            this.setState(prevState => ({
                ...prevState,
                scheduledPayment: {
                    ...prevState.scheduledPayment,
                    scheduledDate: response.data?.scheduledDate,
                    chargesTotal
                },
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Calculate the transaction fees that will be charged to the user prior to making the payment. Transaction fees can
     * only be calculated after the billing account, charge total, and payment method has been provided.
     */
    calculateFees() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/calculatefee`, {
            amount: this.state.enableSelectCharges ? parseFloat(this.state.subtotal) : parseFloat(this.state.amount),
            companyId: this.state.billingAccount.companyId,
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
            paymentMethod: this.state.paymentMethod,
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                feeAmount: response.data
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Fetch a list of all fee profiles related to the company at hand.
     */
    getFeeProfiles() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.state.billingAccount.companyId}/peek`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            let company = response.data;

            axios.get(`${constants.REACT_APP_HOST_API_URL}/fee_profile?companyId=${this.state.billingAccount.companyId}`, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    feeProfileList: response.data,
                    feeProfileCountry: company.country
                }));

                $("#fees").modal("show");

            }).catch(error => {
                this.handleValidation(error);
            });

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Confirm and process the payment.
     */
    submitPayment() {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        let data = {
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
            paymentMethodId: this.state.paymentMethod.id,
            paymentMethodType: this.state.paymentMethod.type,
            amount: this.state.enableSelectCharges ? this.state.subtotal : this.state.amount,
            source: this.state.source,
        };

        if (this.state.enableSelectCharges) {
            data.charges = this.state.chargesToPay;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/processpayment`, data, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                paymentTransaction: response.data,
                spinner: false
            }));

            $('#receipt-payment-transaction').modal('show');

        }).catch(error => {

            if (error.response.status === 504) {
                this.setState(prevState => ({
                    ...prevState,
                    validationList: [{
                        alert: {
                            type: 'danger',
                            code: 'common.payment.timeout'
                        }
                    }]
                }));
            } else {
                const errorData = error?.response?.data;
                if (errorData.message?.includes('Negative amount on PAYMENT transaction')) {
                    // This is not a very good catch, the BE should instead have an appropriate error code for this case
                    if (errorData.fieldErrors?.[0]?.errorCode === 'RM_ERROR_INVALID_AMOUNT' && errorData.fieldErrors?.[0]?.fieldValue) {
                        const minTransactionAmount = parseFloat(this.state.amount) + parseFloat(Math.abs(errorData.fieldErrors?.[0]?.fieldValue));
                        errorData.message = `Transaction amount must be greater then ${minTransactionAmount}`;
                    }
                }
                this.handleValidation(error);
            }

            window.scrollTo(0, 0);

        });
    }

    /**
     * Returns the paymentMethod object for Paysafe Cash
     */
    getPaysafeCashPaymentMethod() {
        let billingAccount = this.state.billingAccount;

        if (billingAccount === {}) {
            return {};
        } else {
            let paysafeCashMethodTemp = {
                billingAddressId: billingAccount.id,
                token: null,
                type: "TYPE_CASH",
                userId: billingAccount.userId,
                userType: billingAccount.userType

            };
            return paysafeCashMethodTemp;
        }
    }

    /**
     * Redirect to Paysafe barcode page
     */
    submitPaysafePayment() {
        const paysafeParams = {
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
            amount: this.state.amount,
            currency: "USD",
            language: "EN",
            prepareOnly: 1
        };

        //Append user email, if it exist!
        if (this.props?.billingAccount?.joins?.u?.email) {
            const email = this.props.billingAccount.joins.u.email;
            paysafeParams.userEmail = email;
        }

        axios.post(`${constants.REACT_APP_HOST_API_URL}/payment/paysafecash`, paysafeParams, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            window.open(response.data);
            this.props.parent.props.history.push({
                pathname: `/manager/tenants/${this.state.billingAccount.id}/transactions`,
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Returns the paymentMethod object for UnionPay
     */
    getUnionPayPaymentMethod() {
        let billingAccount = this.state.billingAccount;

        if (billingAccount === {}) {
            return {};
        } else {
            let unionPayMethodTemp = {
                billingAddressId: billingAccount.id,
                token: null,
                type: "TYPE_CREDIT_CARD",
                cardType: 'CREDIT',
                brand: "UNION_PAY",
                country: 'CN',
                userId: billingAccount.userId,
                userType: billingAccount.userType
            };
            return unionPayMethodTemp;
        }
    }

    /**
     * Redirect to UnionPay page
     */
    submitUnionPayPayment() {
        const unionPayParams = {
            billingAccountId: this.state.billingAccount.id,
            billingAccountType: this.state.billingAccount.type,
            amount: this.state.amount
        };

        axios.post(`${constants.REACT_APP_HOST_API_URL}/geoswift/create_order`, unionPayParams, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            window.open(response.data.url);
            this.props.parent.props.history.push({
                pathname: `/manager/tenants/${this.state.billingAccount.id}/transactions`,
            });
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Add a new blank charge to the list of charges.
     */
    addCharge() {

        this.setState(prevState => ({
            ...prevState,
            chargesToPay: [...prevState.chargesToPay, {
                amount: '',
                code: '',
                name: ''
            }],
        }));
    }

    /**
     * Remove a specific charge from the list of charges.
     *
     * @param index - The array index of the charge to remove.
     */
    removeCharge(index) {

        let charges = this.state.chargesToPay;

        charges.splice(index, 1);

        this.setState(prevState => ({
            ...prevState,
            chargesToPay: charges,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic make a payment component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        $(function () {
            $('[data-toggle="popover"]').popover();
            $('[data-toggle="tooltip"]').tooltip();
        });

        return (
            <React.Fragment>

                <Spinner visible={this.state.spinner}/>

                <div className="row kaxsdc" data-event='load'>
                    <div className="col-md-8">

                        <Alert validationList={this.state.validationList}/>

                        {!this.state.billingAccountPreSelected &&
                            <div className="card">

                                <div className="card-header">
                                    Lease
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">Which lease would you like to make a payment for?</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveBillingAccount}>

                                        {this.state.billingAccountSelected &&
                                            <div className="">

                                                <div className="list-group">
                                                    <div className="list-group-item list-group-item-action c-pointer"
                                                         onClick={() => this.editBillingAccount()}>

                                                        {this.props.userType !== 'TYPE_CUSTOMER' &&
                                                            <div className="">
                                                                {this.state.billingAccount.joins.u.firstName} {this.state.billingAccount.joins.u.lastName}
                                                            </div>
                                                        }

                                                        {this.props.userType === 'TYPE_CUSTOMER' &&
                                                            <div className="">
                                                                {this.state.billingAccount.unit ? this.state.billingAccount.unit + ' - ' : ''}{this.state.billingAccount.joins.p.street1}{this.state.billingAccount.joins.p.street2 ? ', ' + this.state.billingAccount.joins.p.street2 : ''}, {this.state.billingAccount.joins.p.city}, {this.state.billingAccount.joins.p.country === 'CA' ? formatMessage({id: "province." + this.state.billingAccount.joins.p.province}) : formatMessage({id: "state." + this.state.billingAccount.joins.p.province})}, {formatMessage({id: "country." + this.state.billingAccount.joins.p.country})} {this.state.billingAccount.joins.p.postalCode}
                                                            </div>
                                                        }

                                                        {this.props.userType !== 'TYPE_CUSTOMER' &&
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                {this.state.billingAccount.joins &&
                                                                    <React.Fragment>
                                                                        Resides
                                                                        in {this.state.billingAccount.unit} - {this.state.billingAccount.joins.p.street1}{this.state.billingAccount.joins.p.street2 ? ', ' + this.state.billingAccount.joins.p.street2 : ''}, {this.state.billingAccount.joins.p.city}, {this.state.billingAccount.joins.p.country === 'CA' ? formatMessage({id: "province." + this.state.billingAccount.joins.p.province}) : formatMessage({id: "state." + this.state.billingAccount.joins.p.province})}, {formatMessage({id: "country." + this.state.billingAccount.joins.p.country})} {this.state.billingAccount.joins.p.postalCode}
                                                                    </React.Fragment>
                                                                }
                                                            </small>
                                                        }

                                                        {this.props.userType === 'TYPE_CUSTOMER' &&
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                {this.state.billingAccount.joins &&
                                                                    <React.Fragment>
                                                                        Managed
                                                                        by {this.state.billingAccount.joins.c.name}
                                                                    </React.Fragment>
                                                                }
                                                            </small>
                                                        }

                                                    </div>
                                                </div>

                                                <div className="btn btn-outline-primary btn-md btn-block mt-3"
                                                     onClick={() => this.editBillingAccount()}>
                                                    Edit
                                                </div>

                                            </div>
                                        }

                                        {!this.state.billingAccountSelected &&
                                            <React.Fragment>

                                                {this.props.userType !== 'TYPE_CUSTOMER' &&
                                                    <FieldText id="billingAccountFilter" label="Search"
                                                               labelClass="d-none" fieldColumns="12"
                                                               labelColums="0"
                                                               placeholder="Search tenants or properties..."
                                                               parent={this} fieldClass="form-control-md"
                                                               value={this.state.billingAccountFilter}
                                                               handleChange={this.filterBillingAccounts}/>
                                                }

                                                {this.state.billingAccountList.map((data, key) => {
                                                    return (
                                                        <div key={key} className="list-group mb-2">
                                                            <div
                                                                className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                                <input type="radio" name="billingAccount"
                                                                       disabled={data.paymentsBlocked}
                                                                       value={JSON.stringify(data)} id={key}
                                                                       defaultChecked={this.state.billingAccount.id === data.id || false}
                                                                       onChange={this.handleChangeBillingAccount}
                                                                       className="custom-control-input"/>
                                                                <label className="custom-control-label pl-3 c-pointer"
                                                                       htmlFor={key}>

                                                                    {(this.props.userType !== 'TYPE_CUSTOMER' && data.joins) &&
                                                                        <div className="">
                                                                            {data.joins.u.firstName} {data.joins.u.lastName}
                                                                        </div>
                                                                    }

                                                                    {this.props.userType === 'TYPE_CUSTOMER' &&
                                                                        <div className="">
                                                                            {data.unit ? data.unit + ' - ' : ''}{data.joins.p.street1}{data.joins.p.street2 ? ', ' + data.joins.p.street2 : ''}, {data.joins.p.city}, {data.joins.p.country === 'CA' ? formatMessage({id: "province." + data.joins.p.province}) : formatMessage({id: "state." + data.joins.p.province})}, {formatMessage({id: "country." + data.joins.p.country})} {data.joins.p.postalCode}
                                                                        </div>
                                                                    }

                                                                    {this.props.userType !== 'TYPE_CUSTOMER' &&
                                                                        <p className="mb-0 ml-md-0 small">
                                                                            {data.joins &&
                                                                                <span className="text-muted">
                                                                    Resides in {data.unit} - {data.joins.p.street1}{data.joins.p.street2 ? ', ' + data.joins.p.street2 : ''}, {data.joins.p.city}, {data.joins.p.country === 'CA' ? formatMessage({id: "province." + data.joins.p.province}) : formatMessage({id: "state." + data.joins.p.province})}, {formatMessage({id: "country." + data.joins.p.country})} {data.joins.p.postalCode}
                                                                </span>
                                                                            }
                                                                            {(data.paymentsBlocked) &&
                                                                                <span className="text-danger ml-1">
                                                                    (Payments Blocked)
                                                                </span>
                                                                            }
                                                                        </p>
                                                                    }

                                                                    {this.props.userType === 'TYPE_CUSTOMER' &&
                                                                        <p className="mb-0 ml-md-0 small">
                                                                            {data.joins &&
                                                                                <span className="text-muted">
                                                                    Managed by {data.joins.c.name}
                                                                </span>
                                                                            }
                                                                            {(data.paymentsBlocked) &&
                                                                                <span className="text-danger ml-1">
                                                                    (Payments Blocked)
                                                                </span>
                                                                            }
                                                                        </p>
                                                                    }

                                                                </label>
                                                            </div>
                                                        </div>
                                                    );
                                                })}

                                                <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                    Save & Continue
                                                </button>

                                            </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                        }

                        {this.state.billingAccountSelected &&
                            <div className="card">

                                <div className="card-header">
                                    How would you like to pay?
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">Partial or full balance</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveCharges}>

                                        {this.state.chargesSelected &&
                                            <div className="">

                                                <div className="list-group">
                                                    <div className="list-group-item list-group-item-action c-pointer"
                                                         onClick={() => this.editCharges()}>
                                                        <div className="">
                                                            {this.state.enableSelectCharges ?
                                                                <FieldCharges
                                                                    customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null}
                                                                    charges={this.state.chargesToPay}
                                                                    addCharge={this.addCharge}
                                                                    removeCharge={this.removeCharge}
                                                                    size="sm"
                                                                    parent={this}
                                                                    parentStateName="chargesToPay"
                                                                    disabled/>
                                                                :
                                                                <FormattedNumber value={this.state.amount}
                                                                                 style={`currency`} currency="USD"/>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="btn btn-outline-primary btn-md btn-block mt-3"
                                                     onClick={() => this.editCharges()}>
                                                    Edit
                                                </div>

                                            </div>
                                        }

                                        {!this.state.chargesSelected &&
                                            <React.Fragment>

                                                {this.state.enableSelectCharges ?
                                                    <FieldCharges
                                                        customChargeCodes={this.state.customChargeCodes.length > 0 ? this.state.customChargeCodes : null}
                                                        charges={this.state.chargesToPay}
                                                        addCharge={this.addCharge}
                                                        removeCharge={this.removeCharge}
                                                        size="sm"
                                                        parent={this}
                                                        parentStateName="chargesToPay"/>
                                                    :
                                                    <FieldText id="amount" label="Charge Amount"
                                                               labelClass="col-form-label-md d-none" placeholder="0.00"
                                                               iconType="fas" iconName="dollar-sign"
                                                               fieldClass="form-control-md" fieldColumns="12"
                                                               labelColums="0" type="number" min="1.00" max="999999.99"
                                                               step="0.01" pattern="[0-9]+(\.[0-9][0-9]?)?"
                                                               parent={this} value={this.state['amount']}/>
                                                }

                                                <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                    Save & Continue
                                                </button>

                                            </React.Fragment>
                                        }

                                    </form>

                                </div>
                            </div>
                        }

                        {this.state.chargesSelected &&
                            <div className="card">

                                <div className="card-header">
                                    Payment Method
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">How would you like to pay?</p>
                                </div>

                                <div className="card-body">

                                    {this.state.paymentMethodSelected &&
                                        <div className="">
                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer"
                                                     onClick={() => this.editPaymentMethod()}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            <div className="">
                                                                {this.state.isPaysafeCashSelected && this.state.paymentMethod.type === 'TYPE_CASH' &&
                                                                    <React.Fragment>
                                                                        Pay with Paysafe Cash
                                                                    </React.Fragment>
                                                                }
                                                                {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                                    <React.Fragment>
                                                                        Bank Account
                                                                    </React.Fragment>
                                                                }
                                                                {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                                    <React.Fragment>
                                                                        <FormattedMessage
                                                                            id={"enum.creditCard.brand." + this.state.paymentMethod.brand}/>
                                                                    </React.Fragment>
                                                                }
                                                                {this.state.paymentMethod.type === 'TYPE_PAY_PAL' &&
                                                                    <React.Fragment>
                                                                        PayPal
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                            <small className="mb-0 ml-md-0 small text-muted">
                                                                {this.state.isPaysafeCashSelected && this.state.paymentMethod.type === 'TYPE_CASH' &&
                                                                    <React.Fragment>
                                                                        Barcode for Paysafe Cash
                                                                    </React.Fragment>
                                                                }
                                                                {this.state.paymentMethod.type === 'TYPE_BANK_ACCOUNT' &&
                                                                    <React.Fragment>
                                                                        Account number ending
                                                                        in {this.state.paymentMethod.last4}
                                                                    </React.Fragment>
                                                                }
                                                                {this.state.paymentMethod.type === 'TYPE_CREDIT_CARD' &&
                                                                    <React.Fragment>
                                                                        Card number ending
                                                                        in {this.state.paymentMethod.last4}
                                                                    </React.Fragment>
                                                                }
                                                            </small>
                                                        </div>
                                                        {!this.state.isPaysafeCashSelected &&
                                                            <div className="col text-right">
                                                                <div className="float-right mr-2">
                                                                    <CardBrandIcon
                                                                        paymentMethodType={this.state.paymentMethod.type}
                                                                        brand={this.state.paymentMethod.brand}
                                                                        customClasses="w-75"/>
                                                                </div>
                                                            </div>
                                                        }
                                                        {this.state.isPaysafeCashSelected &&
                                                            <div className="col text-right">
                                                                <div className="float-right mr-2">
                                                                    <img src={cashLogo} className="rounded border w-75"
                                                                         alt="cash logo"/>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3"
                                                 onClick={() => this.editPaymentMethod()}>
                                                Edit
                                            </div>

                                        </div>
                                    }

                                    {!this.state.paymentMethodSelected &&
                                        <React.Fragment>

                                            <form onSubmit={this.savePaymentMethod}>
                                                {this.state.paymentMethodList.map((data, key) => {
                                                    if ((this.state.source !== "SASSPRICING" && Object.keys(this.state.billingAccount) && data.type && data.type === "TYPE_BANK_ACCOUNT") ? (this.state.billingAccount?.joins?.p?.country && data.country && this.state.billingAccount?.joins?.p?.country === data.country) : true) {
                                                        if (data.type !== 'TYPE_CASH') {
                                                            return (
                                                                <div key={key} className="list-group mb-2">
                                                                    <div
                                                                        className={'custom-control custom-radio list-group-item list-group-item-action c-pointer ' + ((data.type === 'TYPE_CREDIT_CARD' && this.state.blockPayments.blockCreditPayment) || (data.type === 'TYPE_BANK_ACCOUNT' && this.state.blockPayments.blockBankPayment) || (data.type === 'TYPE_PAY_PAL' && this.state.blockPayments.blockPaypalPayment) ? 'disabled' : '')}
                                                                        data-toggle={data.type === 'TYPE_CREDIT_CARD' ? (!this.state.acceptedPaymentTypes.includes(data.brand) ? 'tooltip' : '') : (!this.state.acceptedPaymentTypes.includes(data.type) ? 'tooltip' : '')}
                                                                        data-placement="top"
                                                                        title={data.type === 'TYPE_CREDIT_CARD' ? (!this.state.acceptedPaymentTypes.includes(data.brand) ? "This payment method is currently not supported by your selected lease" : '') : (!this.state.acceptedPaymentTypes.includes(data.type) ? "This payment method is currently not supported by your selected lease" : "")}>
                                                                        <input type="radio" name="paymentMethod"
                                                                               value={JSON.stringify(data)} id={key}
                                                                               disabled={data.type === 'TYPE_CREDIT_CARD' ? !this.state.acceptedPaymentTypes.includes(data.brand) : !this.state.acceptedPaymentTypes.includes(data.type)}
                                                                               checked={(this.state.paymentMethod.id === data.id && !this.state.isPaysafeCashSelected) || false}
                                                                               onChange={this.handleChangePaymentMethod}
                                                                               className="custom-control-input"/>
                                                                        <label
                                                                            className="custom-control-label pl-3 c-pointer"
                                                                            htmlFor={key}>
                                                                            <div className="row align-items-center">
                                                                                <div className="col-8">
                                                                                    <div className="">
                                                                                        {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                                            <React.Fragment>
                                                                                                Bank Account
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {data.type === 'TYPE_CREDIT_CARD' &&
                                                                                            <React.Fragment>
                                                                                                <FormattedMessage
                                                                                                    id={"enum.creditCard.brand." + data.brand}/>
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </div>
                                                                                    <small
                                                                                        className="mb-0 ml-md-0 small text-muted">
                                                                                        {data.type === 'TYPE_BANK_ACCOUNT' &&
                                                                                            <React.Fragment>
                                                                                                Account number ending
                                                                                                in {data.last4}
                                                                                            </React.Fragment>
                                                                                        }
                                                                                        {data.type === 'TYPE_CREDIT_CARD' &&
                                                                                            <React.Fragment>
                                                                                                Card number ending
                                                                                                in {data.last4}
                                                                                            </React.Fragment>
                                                                                        }
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col text-right">
                                                                                    <div className="float-right mr-2">
                                                                                        <CardBrandIcon
                                                                                            paymentMethodType={data.type}
                                                                                            brand={data.brand}
                                                                                            customClasses="w-75"/>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                        return null;
                                                    }
                                                    return null;
                                                })}
                                                {this.state.isPaysafeCashEnabled &&
                                                    <div key={'paysafe-cash'} className="list-group mb-2"
                                                         onClick={() => {
                                                             this.setState(prevState => ({
                                                                 ...prevState,
                                                                 paymentMethod: this.getPaysafeCashPaymentMethod(),
                                                                 showCreditCardForm: false,
                                                                 showBankAccountForm: false,
                                                                 showBillingAddressForm: false,
                                                                 isPaysafeCashSelected: prevState.isPaysafeCashSelected ? false : true
                                                             }));
                                                         }}>
                                                        {!this.state.blockPayments.blockCashPayment &&
                                                            <div
                                                                className={"custom-control custom-radio list-group-item list-group-item-action c-pointer " + (this.state.blockPayments.blockCashPayment ? 'disabled' : '')}
                                                                id={"paysafe-cash"} data-placement="top">
                                                                <input type="radio" name="paysafeCashPaymentMethod"
                                                                       value={JSON.stringify(this.getPaysafeCashPaymentMethod())}
                                                                       checked={this.state.isPaysafeCashSelected || false}
                                                                       className="custom-control-input"
                                                                       id={"paysafe-cash"}/>
                                                                <label className="custom-control-label pl-3 c-pointer"
                                                                       htmlFor={'paysafe-cash'}>
                                                                    <div className="row align-items-center">
                                                                        <div className="col-8">
                                                                            <div className="">
                                                                                <React.Fragment>
                                                                                    Pay with Paysafe Cash
                                                                                </React.Fragment>
                                                                            </div>
                                                                            <small
                                                                                className="mb-0 ml-md-0 small text-muted">
                                                                                <React.Fragment>
                                                                                    Barcode for Paysafe Cash
                                                                                </React.Fragment>
                                                                            </small>
                                                                        </div>
                                                                        <div className="col text-right">
                                                                            <div className="float-right mr-2">
                                                                                <img src={cashLogo}
                                                                                     className="rounded border w-75"
                                                                                     alt="Add New Bank Account"/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>}
                                                    </div>
                                                }
                                                {this.state.isUnionPayEnabled &&
                                                    <div key={'union-pay'} className="list-group mb-2 disabled"
                                                         onClick={() => {
                                                             !this.state.blockPayments.blockCreditPayment &&
                                                             this.setState(prevState => ({
                                                                 ...prevState,
                                                                 paymentMethod: this.getUnionPayPaymentMethod(),
                                                                 showCreditCardForm: false,
                                                                 showBankAccountForm: false,
                                                                 showBillingAddressForm: false,
                                                                 isUnionPaySelected: prevState.isUnionPaySelected ? false : true
                                                             }));
                                                         }}>
                                                        <div
                                                            className={"custom-control custom-radio list-group-item list-group-item-action c-pointer " + (this.state.blockPayments.blockCreditPayment ? 'disabled' : '')}
                                                            id={"union-pay"} data-placement="top">
                                                            <input type="radio" name="unionPayPaymentMethod"
                                                                   value={JSON.stringify(this.getUnionPayPaymentMethod())}
                                                                   id={'union-pay'}
                                                                   checked={this.state.isUnionPaySelected || false}
                                                                   className="custom-control-input"/>
                                                            <label className="custom-control-label pl-3 c-pointer"
                                                                   htmlFor={'union-pay'}>
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="">
                                                                            <React.Fragment>
                                                                                Pay with Union Pay
                                                                            </React.Fragment>
                                                                        </div>
                                                                        <small
                                                                            className="mb-0 ml-md-0 small text-muted">
                                                                            <React.Fragment>
                                                                                Redirect Url for Union Pay
                                                                            </React.Fragment>
                                                                        </small>
                                                                    </div>
                                                                    <div className="col text-right">
                                                                        <div className="float-right mr-2">
                                                                            <CardBrandIcon
                                                                                paymentMethodType={'TYPE_CREDIT_CARD'}
                                                                                brand={'UNION_PAY'}
                                                                                customClasses="w-75"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                {/* <div key={'paypal'} className="list-group mb-2">
                                            <div
                                                className="custom-control custom-radio list-group-item list-group-item-action c-pointer"
                                                data-toggle={!this.state.acceptedPaymentTypes.includes('TYPE_PAY_PAL') ? 'tooltip' : ''}
                                                data-placement="top"
                                                title={!this.state.acceptedPaymentTypes.includes('TYPE_PAY_PAL') ? "This payment method is currently not supported by your selected lease" : ''}
                                            >
                                                <input
                                                    type="radio"
                                                    name="paymentMethod"
                                                    value={JSON.stringify({
                                                        id: 0,
                                                        type: 'TYPE_PAY_PAL',
                                                    })}
                                                    id={'paypal'}
                                                    disabled={!this.state.acceptedPaymentTypes.includes('TYPE_PAY_PAL')}
                                                    checked={this.state.paymentMethod.id === 0 || false}
                                                    onChange={this.handleChangePaymentMethod}
                                                    className="custom-control-input"
                                                />
                                                <label className="custom-control-label pl-3 c-pointer" htmlFor={'paypal'}>
                                                    <div className="row align-items-center">
                                                        <div className="col-8">
                                                            <div className="">
                                                                <React.Fragment>
                                                                    PayPal
                                                                </React.Fragment>
                                                            </div>
                                                        </div>
                                                        <div className="col text-right">
                                                            <div className="float-right mr-2">
                                                                <CardBrandIcon paymentMethodType={'TYPE_PAY_PAL'} brand={'PAY_PAL'} customClasses="w-75"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div> */}

                                                {(!this.state.showCreditCardForm && (this.state.billingAccount && this.state.billingAccount.userType === "TYPE_CUSTOMER" ? this.state.showCreditCardPaymentMethod : (this.state.source !== 'SASSPRICING'))) &&
                                                    <div className="list-group mb-2">
                                                        <div
                                                            className={'list-group-item list-group-item-action c-pointer ' + (this.state.blockPayments.blockCreditPayment ? 'disabled' : '')}
                                                            onClick={() => this.createPaymentMethod('credit_card')}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Add New Credit or Debit Card
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={creditCard}
                                                                             className="rounded border w-75"
                                                                             alt="Add New Credit or Debit Card"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.showCreditCardForm &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item pb-3">

                                                            <div className="row align-items-center mb-3">
                                                                <div className="col-8">
                                                                    Add New Credit or Debit Card
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={creditCard}
                                                                             className="rounded border w-75"
                                                                             alt="Add New Credit or Debit Card"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <CardPreview paymentMethod={this.state.paymentMethod}
                                                                         cardPreviewFlipped={this.state.cardPreviewFlipped}
                                                                         activePaymentMethodField={this.state.activePaymentMethodField}/>

                                                            <FieldCardNumber id="cardNumber" label="Card Number"
                                                                             required={true} model="paymentMethod"
                                                                             fieldColumns="12" labelColumns="12"
                                                                             fieldClass="form-control-md mb-0"
                                                                             parent={this}
                                                                             value={this.state.paymentMethod.cardNumber}
                                                                             brand={this.state.paymentMethod.brand}
                                                                             handleFocus={() => this.handleFocusPaymentMethodField('cardNumber')}
                                                                             handleBlur={() => this.handleBlurPaymentMethodField('cardNumber')}/>

                                                            <FieldText id="nameOnCard" label="Card Holder Name"
                                                                       required={true} model="paymentMethod"
                                                                       fieldColumns="12" labelColumns="12"
                                                                       fieldClass="form-control-md mb-0" parent={this}
                                                                       value={this.state.paymentMethod['nameOnCard']}
                                                                       handleFocus={() => this.handleFocusPaymentMethodField('nameOnCard')}
                                                                       handleBlur={() => this.handleBlurPaymentMethodField('nameOnCard')}/>

                                                            <div className="row mb-3">

                                                                <div className="col">
                                                                    <FieldSelect id="expiryMonth" label="Month"
                                                                                 required={true} model="paymentMethod"
                                                                                 fieldColumns="12" labelColumns="12"
                                                                                 fieldClass="form-control-md mb-0"
                                                                                 parent={this}
                                                                                 value={this.state.paymentMethod['expiryMonth']}
                                                                                 handleFocus={() => this.handleFocusPaymentMethodField('expiryMonth')}
                                                                                 handleBlur={() => this.handleBlurPaymentMethodField('expiryMonth')}>
                                                                        <option value="">-</option>
                                                                        <option value="1">01 - Jan</option>
                                                                        <option value="2">02 - Feb</option>
                                                                        <option value="3">03 - Mar</option>
                                                                        <option value="4">04 - Apr</option>
                                                                        <option value="5">05 - May</option>
                                                                        <option value="6">06 - Jun</option>
                                                                        <option value="7">07 - Jul</option>
                                                                        <option value="8">08 - Aug</option>
                                                                        <option value="9">09 - Sep</option>
                                                                        <option value="10">10 - Oct</option>
                                                                        <option value="11">11 - Nov</option>
                                                                        <option value="12">12 - Dec</option>
                                                                    </FieldSelect>
                                                                </div>

                                                                <div className="col">
                                                                    <FieldSelect id="expiryYear" label="Year"
                                                                                 required={true} model="paymentMethod"
                                                                                 fieldColumns="12" labelColumns="12"
                                                                                 fieldClass="form-control-md mb-0"
                                                                                 parent={this}
                                                                                 value={this.state.paymentMethod['expiryYear']}
                                                                                 handleFocus={() => this.handleFocusPaymentMethodField('expiryYear')}
                                                                                 handleBlur={() => this.handleBlurPaymentMethodField('expiryYear')}>
                                                                        <option value="">-</option>
                                                                        <option value="2022">2022</option>
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                        <option value="2026">2026</option>
                                                                        <option value="2027">2027</option>
                                                                        <option value="2028">2028</option>
                                                                        <option value="2029">2029</option>
                                                                        <option value="2030">2030</option>
                                                                        <option value="2031">2031</option>
                                                                        <option value="2032">2032</option>
                                                                        <option value="2033">2033</option>
                                                                    </FieldSelect>
                                                                </div>

                                                                <div className="col">
                                                                    <FieldText id="securityCode"
                                                                               label={this.state.creditCardSecurityCodeLabel}
                                                                               required={true} model="paymentMethod"
                                                                               fieldColumns="12" labelColumns="12"
                                                                               fieldClass="form-control-md mb-0"
                                                                               parent={this}
                                                                               value={this.state.paymentMethod.securityCode}
                                                                               handleFocus={() => this.handleFocusPaymentMethodField('securityCode')}
                                                                               handleBlur={() => this.handleBlurPaymentMethodField('securityCode')}/>
                                                                </div>

                                                            </div>

                                                            {!this.state.showBillingAddressForm &&
                                                                <FieldSelect id="billingAddressId"
                                                                             label="Billing Address" required={true}
                                                                             model="paymentMethod" fieldColumns="12"
                                                                             labelColumns="12"
                                                                             fieldClass="form-control-md mb-0"
                                                                             parent={this}
                                                                             value={this.state.paymentMethod['billingAddressId']}
                                                                             handleChange={this.handleChangeBillingAddress}>

                                                                    <option value="">Select from your billing
                                                                        addresses...
                                                                    </option>

                                                                    {this.state.billingAddressList.map((data, key) => {
                                                                        return (
                                                                            <option key={key} value={data.id}>
                                                                                {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                                            </option>
                                                                        );
                                                                    })}

                                                                    <option value="NEW">Add new billing address...
                                                                    </option>

                                                                </FieldSelect>
                                                            }

                                                            {this.state.showBillingAddressForm &&
                                                                <div className="mb-2">

                                                                    <div className="mt-4 mb-3">
                                                                        <FieldCheckbox id="populateBillingAddressForm"
                                                                                       fieldLabel="Billing address same as property lease"
                                                                                       fieldClass="form-control-sm"
                                                                                       fieldColumns="12"
                                                                                       labelClass="col-form-label-sm px-2"
                                                                                       parent={this}
                                                                                       value={this.state.populateBillingAddressForm}
                                                                                       handleChange={this.handleChangePopulateBillingAddress}/>
                                                                    </div>

                                                                    <FieldAddress model="billingAddress"
                                                                                  fieldColumns="12" labelColumns="12"
                                                                                  fieldClass="form-control-md"
                                                                                  suite={true} parent={this}
                                                                                  value={this.state.billingAddress}
                                                                                  isForCreditCard={true}/>

                                                                </div>
                                                            }

                                                        </div>
                                                    </div>
                                                }

                                                {(!this.state.showBankAccountForm && (this.state.billingAccount && this.state.billingAccount.userType === "TYPE_CUSTOMER" ? this.state.showBankPaymentMethod : (this.state.source !== 'SASSPRICING'))) &&
                                                    <div className="list-group mb-2">
                                                        <div
                                                            className={'list-group-item list-group-item-action c-pointer ' + (this.state.blockPayments.blockBankPayment ? 'disabled' : '')}
                                                            onClick={() => this.createPaymentMethod('bank_account')}>
                                                            <div className="row align-items-center">
                                                                <div className="col-8">
                                                                    Add New Bank Account
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={bankAccount}
                                                                             className="rounded border w-75"
                                                                             alt="Add New Bank Account"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {this.state.showBankAccountForm &&
                                                    <div className="list-group mb-2">
                                                        <div className="list-group-item pb-3">

                                                            <div className="row align-items-center mb-3">
                                                                <div className="col-8">
                                                                    Add New Bank Account
                                                                </div>
                                                                <div className="col text-right">
                                                                    <div className="float-right">
                                                                        <img src={bankAccount}
                                                                             className="rounded border w-75"
                                                                             alt="Add New Bank Account"/>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <FieldCountry id="country" label="Bank Country"
                                                                          required={true} model="paymentMethod"
                                                                          labelColumns="12" fieldColumns="12"
                                                                          fieldClass="form-control-md mb-0"
                                                                          parent={this}
                                                                          value={this.state.paymentMethod['country']}
                                                                          billingAccount={this.state.billingAccount}
                                                                          isForTenantBankAcc={Object.keys(this.state.billingAccount) ? true : false}/>

                                                            {this.state.paymentMethod['country'] &&
                                                                <React.Fragment>

                                                                    {this.state.paymentMethod['country'] === 'CA' &&
                                                                        <React.Fragment>

                                                                            <FieldText id="bankAccountInstitutionNumber"
                                                                                       label="Institution Number"
                                                                                       help={
                                                                                           <div data-toggle="popover"
                                                                                                data-trigger="focus"
                                                                                                tabIndex="0"
                                                                                                data-html="true"
                                                                                                title="Institution Number"
                                                                                                className="btn-link d-inline c-pointer"
                                                                                                data-content="The institution number is the 3-digit number that identifies your financial institution. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/institution-number.png' className='img-fluid mt-2' />">
                                                                                               What is this?
                                                                                           </div>
                                                                                       }
                                                                                       labelColumns="12"
                                                                                       fieldColumns="12"
                                                                                       fieldClass="form-control-md mb-0"
                                                                                       type="tel"
                                                                                       parent={this}
                                                                                       required={true}
                                                                                       value={this.state['bankAccountInstitutionNumber']}
                                                                            />

                                                                            <FieldText id="bankAccountTransitNumber"
                                                                                       label="Transit Number"
                                                                                       labelColumns="12"
                                                                                       fieldColumns="12"
                                                                                       fieldClass="form-control-md mb-0"
                                                                                       type="tel" help={
                                                                                <div data-toggle="popover"
                                                                                     data-trigger="focus"
                                                                                     tabIndex="0"
                                                                                     data-html="true"
                                                                                     title="Transit Number"
                                                                                     className="btn-link d-inline c-pointer"
                                                                                     data-content="The transit number is the 5-digit number that identifies your specific bank branch. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/transit-number.png' className='img-fluid mt-2' />">
                                                                                    What is this?
                                                                                </div>
                                                                            }
                                                                                       parent={this}
                                                                                       required={true}
                                                                                       value={this.state['bankAccountTransitNumber']}
                                                                            />

                                                                        </React.Fragment>
                                                                    }

                                                                    {this.state.paymentMethod['country'] === 'US' &&
                                                                        <React.Fragment>

                                                                            <FieldText id="routingNumber"
                                                                                       label="Routing Number"
                                                                                       model="paymentMethod"
                                                                                       labelColumns="12"
                                                                                       fieldColumns="12"
                                                                                       fieldClass="form-control-md mb-0"
                                                                                       type="tel" help={
                                                                                <div data-toggle="popover"
                                                                                     data-trigger="focus"
                                                                                     tabIndex="0"
                                                                                     data-html="true"
                                                                                     title="Routing Number"
                                                                                     className="btn-link d-inline c-pointer"
                                                                                     data-content="The routing number is a 9-digit or 8-digit code that is used to identify where your bank account was opened. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/USD/en/routing-number.png' className='img-fluid mt-2' />">
                                                                                    What is this?
                                                                                </div>
                                                                            }
                                                                                       parent={this}
                                                                                       required={true}
                                                                                       value={this.state.paymentMethod['routingNumber']}
                                                                            />

                                                                        </React.Fragment>
                                                                    }

                                                                    <FieldText id="bankAccountNumber"
                                                                               label="Account Number"
                                                                               model="paymentMethod"
                                                                               labelColumns="12"
                                                                               fieldColumns="12"
                                                                               fieldClass="form-control-md mb-0"
                                                                               type="tel"
                                                                               help={
                                                                                   <div data-toggle="popover"
                                                                                        data-trigger="focus"
                                                                                        tabIndex="0"
                                                                                        data-html="true"
                                                                                        title="Account Number"
                                                                                        className="btn-link d-inline c-pointer"
                                                                                        data-content="The bank account number is the 7 to 12-digit number that is specific to your personal account. <img src='https://dheiziex291vk.cloudfront.net/img/payment/sample-checks/CAD/en/account-number.png' className='img-fluid mt-2' />">
                                                                                       What is this?
                                                                                   </div>
                                                                               }
                                                                               parent={this}
                                                                               required={true}
                                                                               value={this.state.paymentMethod['bankAccountNumber']}
                                                                    />

                                                                    <FieldText id="accountHolder" label="Account Holder"
                                                                               required={true} model="paymentMethod"
                                                                               labelColumns="12" fieldColumns="12"
                                                                               fieldClass="form-control-md mb-0"
                                                                               parent={this}
                                                                               value={this.state.paymentMethod['accountHolder']}/>

                                                                    {!this.state.showBillingAddressForm &&
                                                                        <FieldSelect id="billingAddressId"
                                                                                     label="Billing Address"
                                                                                     required={true}
                                                                                     model="paymentMethod"
                                                                                     fieldColumns="12" labelColumns="12"
                                                                                     fieldClass="form-control-md mb-0"
                                                                                     parent={this}
                                                                                     value={this.state.paymentMethod['billingAddressId']}
                                                                                     handleChange={this.handleChangeBillingAddress}>

                                                                            <option value="">Select from your billing
                                                                                addresses...
                                                                            </option>

                                                                            {this.state.billingAddressList.filter((data) => (data.country === "CA" || data.country === "US")).map((data, key) => {
                                                                                return (
                                                                                    <option key={key} value={data.id}>
                                                                                        {data.suite ? data.suite + ' - ' : ''}{data.street1}{data.street2 ? ', ' + data.street2 : ''}, {data.city}, {data.country === 'CA' ? formatMessage({id: "province." + data.province}) : formatMessage({id: "state." + data.province})}, {formatMessage({id: "country." + data.country})} {data.postalCode}
                                                                                    </option>
                                                                                );
                                                                            })}

                                                                            <option value="NEW">Add new billing
                                                                                address...
                                                                            </option>

                                                                        </FieldSelect>
                                                                    }

                                                                    {this.state.showBillingAddressForm &&
                                                                        <div className="mb-2">

                                                                            <div className="mt-4 mb-3">
                                                                                <FieldCheckbox
                                                                                    id="populateBillingAddressForm"
                                                                                    fieldLabel="Billing address same as property lease"
                                                                                    fieldClass="form-control-sm"
                                                                                    fieldColumns="12"
                                                                                    labelClass="col-form-label-sm px-2"
                                                                                    parent={this}
                                                                                    value={this.state.populateBillingAddressForm}
                                                                                    handleChange={this.handleChangePopulateBillingAddress}/>
                                                                            </div>

                                                                            <FieldAddress model="billingAddress"
                                                                                          fieldColumns="12"
                                                                                          labelColumns="12"
                                                                                          fieldClass="form-control-md"
                                                                                          suite={true} parent={this}
                                                                                          value={this.state.billingAddress}/>

                                                                        </div>
                                                                    }

                                                                </React.Fragment>
                                                            }

                                                        </div>
                                                    </div>
                                                }

                                                <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                    Save & Continue
                                                </button>

                                            </form>

                                        </React.Fragment>
                                    }

                                </div>

                                <div className="card-footer">
                                    <p className="small text-muted mb-0">Transactions made through Letus are subject to
                                        transaction fees, dependant upon your chosen method of payment. To view a list
                                        of our transaction fees, <span className="btn-link c-pointer"
                                                                       data-toggle="modal" data-target="#fees"
                                                                       onClick={() => this.getFeeProfiles()}>click here</span>.
                                    </p>
                                </div>

                            </div>
                        }

                    </div>
                    <div className="col-md-4">
                        {(this.state.scheduledPayment?.active) &&
                            <div className="card card-primary border-primary">
                                <div className="card-body">
                                    <p className="card-text">
                                        This tenant is currently making autopayments
                                        {this.state.scheduledPayment?.scheduledDate && (
                                            <span>
                                            . The next monthly recurring payment will be automatically charged on{" "}
                                                <Moment format="MMM DD, YYYY" tz="UTC">
                                                {this.state.scheduledPayment?.scheduledDate}
                                            </Moment>{" "}
                                            </span>
                                        )}
                                        {this.state.scheduledPayment?.customAmount ||
                                        this.state.scheduledPayment?.chargesTotal ? (
                                            <span>
                                                {" "}
                                                for an amount of{" "}
                                                <FormattedNumber
                                                    value={
                                                        this.state.scheduledPayment?.customAmount ||
                                                        this.state.scheduledPayment?.chargesTotal
                                                    }
                                                    style={`currency`}
                                                    currency="USD"
                                                />
                                                .{" "}
                                            </span>
                                        ) : (
                                            <span>. </span>
                                        )}
                                        If this is correct there is no need to manually make a payment unless you are
                                        paying for any additional charge(s) unrelated to recurring payments.
                                    </p>
                                </div>
                            </div>
                        }
                        <div className="sticky-top">

                            <div className="card">
                                <div className="card-header">
                                    Summary
                                </div>

                                {!this.state.billingAccountSelected &&
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div className="text-center text-secondary">
                                                    <FontAwesomeIcon icon={['far', 'receipt']}
                                                                     className="fa-fw mt-3 mb-4" size="5x"/>
                                                </div>
                                                <div className="text-center text-muted">
                                                    <small>
                                                        Once you select your lease, your amount due will appear here.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {(this.state.source !== "SASSPRICING" && this.state.billingAccountSelected && this.state.currentBalance === 0) &&
                                    <div className="card-body">
                                        <div className="row justify-content-center">
                                            <div className="col-10">
                                                <div className="text-center text-secondary">
                                                    <FontAwesomeIcon icon={['far', 'info-circle']}
                                                                     className="fa-fw mt-3 mb-4" size="5x"/>
                                                </div>
                                                <div className="text-center text-muted">
                                                    <small>
                                                        You have no outstanding charges due for your selected lease!
                                                        Your charges for next month's payments will be available to you
                                                        closer to your next payment due date.
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="card-body card-body-table">

                                    <table className="table">
                                        {(this.state.source !== "SASSPRICING" && this.state.billingAccountSelected) &&
                                            <thead>
                                            <tr>
                                                <th colSpan="2" className="text-center">
                                                    Your Charges
                                                </th>
                                            </tr>
                                            </thead>
                                        }

                                        <tbody>

                                        {(this.state.source !== "SASSPRICING" && this.state.billingAccountSelected) &&
                                            <React.Fragment>

                                                {this.state.openCharges.map((openCharge, key) => {

                                                    return (
                                                        <tr key={key}>
                                                            <td className="">
                                                                <Moment format="MMM YYYY"
                                                                        tz="UTC">{openCharge.postMonth}</Moment> {this.state.customChargeCodes ? openCharge.name : formatMessage({id: `charge.${openCharge.code}`})}
                                                                {(openCharge.amount > 0 && moment(new Date()).isAfter(openCharge.dueDate)) &&
                                                                    <small
                                                                        className="ml-1 text-danger font-weight-bold text-uppercase">
                                                                        Overdue
                                                                    </small>
                                                                }
                                                            </td>
                                                            <td className="text-right">
                                                                <FormattedNumber value={openCharge.balance}
                                                                                 style={`currency`} currency="USD"/>
                                                            </td>
                                                        </tr>
                                                    )

                                                })}

                                                <tr className="font-weight-bold">
                                                    <td className="">
                                                        Total Charges
                                                    </td>
                                                    <td className="text-right">
                                                        <FormattedNumber value={this.state.currentBalance}
                                                                         style={`currency`} currency="USD"/>
                                                    </td>
                                                </tr>

                                            </React.Fragment>
                                        }

                                        </tbody>

                                        {this.state.chargesSelected &&
                                            <thead>
                                            <tr>
                                                <th colSpan="2" className="text-center">
                                                    Your Payment
                                                </th>
                                            </tr>
                                            </thead>
                                        }

                                        <tbody>
                                        {this.state.chargesSelected &&
                                            <React.Fragment>
                                                {this.state.source === "SASSPRICING" && this.state.billingAccount.joins &&
                                                    <tr>
                                                        <td className="">
                                                            Payment To
                                                        </td>
                                                        <td className="text-right">
                                                            {this.state.billingAccount.joins.c.name}
                                                        </td>
                                                    </tr>
                                                }
                                                {this.state.enableSelectCharges ?
                                                    <>
                                                        {this.state.chargesToPay.map(charge => {
                                                            return (
                                                                <tr>
                                                                    <td className="">
                                                                        {charge.name}
                                                                    </td>
                                                                    <td className="text-right">
                                                                        <FormattedNumber value={charge.amount}
                                                                                         style={`currency`}
                                                                                         currency="USD"/>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </>
                                                    :
                                                    <tr>
                                                        <td className="">
                                                            Payment Amount
                                                        </td>
                                                        <td className="text-right">
                                                            <FormattedNumber value={this.state.amount}
                                                                             style={`currency`} currency="USD"/>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr className="font-weight-bold">
                                                    <td className="">
                                                        Subtotal
                                                    </td>
                                                    <td className="text-right">
                                                        <FormattedNumber
                                                            value={this.state.enableSelectCharges ? this.state.subtotal : this.state.amount}
                                                            style={`currency`} currency={`USD`}/>
                                                    </td>
                                                </tr>

                                            </React.Fragment>
                                        }

                                        {(this.state.source !== "SASSPRICING" && this.state.billingAccountSelected && this.state.chargesSelected && this.state.paymentMethodSelected) &&
                                            <React.Fragment>
                                                <tr>
                                                    <td className="">
                                                        Transaction Fee
                                                    </td>
                                                    <td className="text-right">
                                                        <FormattedNumber value={this.state.feeAmount} style={`currency`}
                                                                         currency={`USD`}/>
                                                    </td>
                                                </tr>
                                                <tr className="font-weight-bold">
                                                    <td className="">
                                                        Total
                                                    </td>
                                                    <td className="text-right">
                                                        <FormattedNumber
                                                            value={this.state.enableSelectCharges ? parseFloat(this.state.subtotal) + this.state.feeAmount : parseFloat(this.state.amount) + this.state.feeAmount}
                                                            style={`currency`} currency={`USD`}/>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        }

                                        </tbody>

                                    </table>

                                </div>

                                {(this.state.source !== "SASSPRICING" && this.state.billingAccountSelected && this.state.chargesSelected && this.state.paymentMethodSelected) &&
                                    <div className="card-footer text-center">
                                        <small className="">
                                            <div className="btn-link c-pointer" data-toggle="modal" data-target="#fees"
                                                 onClick={() => this.getFeeProfiles()}>
                                                How are transaction fees calculated?
                                            </div>
                                        </small>
                                    </div>
                                }

                            </div>

                            {(this.state.billingAccountSelected && this.state.chargesSelected && this.state.paymentMethodSelected) &&
                                <div>
                                    {this.state.paymentMethod.type === 'TYPE_PAY_PAL' &&
                                        <div id="paypal-button-container"></div>
                                    }
                                    {this.state.paymentMethod.type !== 'TYPE_PAY_PAL' && !this.state.isPaysafeCashSelected && !this.state.isUnionPaySelected &&
                                        <div className="btn btn-primary btn-lg btn-block py-3 mb-4"
                                             onClick={() => this.submitPayment()}>
                                            <div className="">
                                                <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw"/> Confirm
                                                Payment
                                            </div>
                                        </div>
                                    }
                                    {this.state.isPaysafeCashSelected &&
                                        <button disabled={this.state.isPaysafeCashSelected && this.state.amount > 1495}
                                                className={`btn btn-primary btn-lg btn-block py-3 mb-4 ${this.state.amount > 1500 || this.state.amount < 10 ? 'btn-disabled' : ''}`}
                                                onClick={() => this.submitPaysafePayment()}>
                                            <div className="">
                                                <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw"/> Confirm Bar
                                                Code Generation
                                            </div>
                                        </button>
                                    }
                                    {this.state.isPaysafeCashSelected && this.state.amount < 10 &&
                                        <div className="card-footer text-center footer-warning">
                                            <small className="">
                                                <div className="">
                                                    Minimum payment is $10 for Paysafe Cash
                                                </div>
                                            </small>
                                        </div>
                                    }
                                    {this.state.isPaysafeCashSelected && this.state.amount > 500 && this.state.amount <= 995 &&
                                        <>
                                            <div className="card-footer text-center footer-warning">
                                                <small className="">
                                                    <div className="">
                                                        Paysafe Cash Transactions greater than $500 are only accepted at
                                                        certain locations such as 7Eleven, CVS and Speedway.
                                                    </div>
                                                </small>
                                            </div>
                                            <div className="btn-link text-center"
                                                 data-toggle="popover"
                                                 title="Locations that accept Paysafe Cash Transactions greater than $500"
                                                 role="button"
                                                 data-placement="auto"
                                                 data-content="Dollar General, Family Dollar, Speedway, CVS, 7Eleven, Walgreen's / Duane Reade, WalMart, Go-Mart, Kwik Trip, Sheetz, Stripes, Sunoco">
                                                <small className=""><u>Click here for the full list</u></small>
                                            </div>
                                        </>
                                    }
                                    {this.state.isPaysafeCashSelected && this.state.amount > 995 && this.state.amount <= 1495 &&
                                        <>
                                            <div className="card-footer text-center footer-warning">
                                                <small className="">
                                                    <div className="">
                                                        Paysafe Cash Transactions greater than $995 are only accepted at
                                                        7Eleven. All other locations accept transactions up to 995.
                                                    </div>
                                                </small>
                                            </div>
                                            <div className="btn-link text-center"
                                                 data-toggle="popover"
                                                 title="Locations that accept Paysafe Cash Transactions greater than $500"
                                                 role="button"
                                                 data-placement="auto"
                                                 data-content="Dollar General, Family Dollar, Speedway, CVS, Walgreen's / Duane Reade, WalMart, Go-Mart, Kwik Trip, Sheetz, Stripes, Sunoco">
                                                <small className=""><u>Click here for the full list</u></small>
                                            </div>
                                        </>
                                    }
                                    {this.state.isPaysafeCashSelected && this.state.amount > 1495 &&
                                        <div className="card-footer text-center footer-warning">
                                            <small className="">
                                                <div className="">
                                                    Cannot exceed $1495 for Paysafe Cash payments
                                                </div>
                                            </small>
                                        </div>
                                    }
                                    {this.state.isUnionPaySelected &&
                                        <button disabled={this.state.isUnionPaySelected && this.state.amount < 0}
                                                className={`btn btn-primary btn-lg btn-block py-3 mb-4 ${this.state.amount < 0 ? 'btn-disabled' : ''}`}
                                                onClick={() => this.submitUnionPayPayment()}>
                                            <div className="">
                                                <FontAwesomeIcon icon={['far', 'lock']} className="fa-fw"/> Confirm
                                                Redirect Url Generation
                                            </div>
                                        </button>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>

                <FeeProfiles feeProfileList={this.state.feeProfileList}
                             feeProfileCountry={this.state.feeProfileCountry}/>

                <ModalUnionPay parent={this}/>

                <ReceiptPaymentTransaction paymentTransaction={this.state.paymentTransaction} displayFeeAmount={true}>
                    <Link to={this.props.redirectPath} className="btn btn-primary btn-lg"
                          onClick={() => $("#receipt-payment-transaction").modal("hide")}>
                        Return to my Dashboard
                    </Link>
                </ReceiptPaymentTransaction>

            </React.Fragment>
        )
    };
}

Payment.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Payment);

