import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import $ from "jquery";
import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class FieldText extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        if(this.props.parent.handleChange) {
            this.handleChange = this.props.parent.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
        this.formatCanadaPostalCode = this.formatCanadaPostalCode.bind(this);

        this.passwordRef = React.createRef();

        this.showPassword = this.showPassword.bind(this);
    }

    componentDidMount() {

        if(this.props.type === 'password'){

            $('[name="passwordIconEyeSlash"]').hide();
        }
    }

    /**
     * Format the entered postal code into a standard Canada postal code.
     * @param postalCode 
     */
    formatCanadaPostalCode(postalCode) {
        const postalCodeNoSpace = postalCode.replace(/\s/g, '');
        if(postalCodeNoSpace.length>3) {
            const lastIndex = postalCodeNoSpace.length;
            const formattedPostalCode = postalCodeNoSpace.slice(0,3) + " " + postalCodeNoSpace.slice(3, lastIndex);
            return formattedPostalCode;
        } else {
            return postalCode;
        }

    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param event - The event container.
     */
    handleChange(event) {

        let field;

        if(this.props.model) {
            field = this.props.parent.state[this.props.model];
            // Fix: To accomodate custom ids for automation
            if(event.target.name === 'chargeAmount' || event.target.name === 'creditAmount') {
                field.amount = String(event.target.value || '');
            } else if(this.props.label === "Postal Code" && this.props?.country === "CA") {
                field[event.target.name] = String(this.formatCanadaPostalCode(event.target.value).toUpperCase() || '');
            } else {
                field[event.target.name] = String(event.target.value || '');
            }
        } else {
            field = String(event.target.value || '');
        }
        this.props.parent.setState(({
            [this.props.model ? this.props.model : event.target.name]: field,
        }));
    }

    /**
     * Prevent step operation when key up or down is pressed
     * Key code 40 corresponds to Arrow Down
     * Key code 38 corresponds to Arrow Up
     * @param {*} event 
     */
    handleKeyUpDown(event) {
        if(event) {
            const keyCode = (event.keyCode ? event.keyCode : event.which);
            if (keyCode === 40 || keyCode === 38) {
                event.preventDefault();
            }
        }   
    }

    /**
     * Prevent step operation when key up or down is pressed
     * Key code 40 corresponds to Arrow Down
     * Key code 38 corresponds to Arrow Up
     * @param {*} event
     */
    showPassword(event){

        let passwordInput= this.passwordRef.current;
        let passwordIconEyeSlash=$('[name="passwordIconEyeSlash"]');
        let passwordIconEye=$('[name="passwordIconEye"]');

        if(passwordInput.type ==='password'){
            passwordInput.type = 'text';
            passwordIconEyeSlash.show();
            passwordIconEye.hide();
        }else{
            passwordInput.type = 'password'
            passwordIconEyeSlash.hide();
            passwordIconEye.show();
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A generic text field component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label} {this.props.optional && <small className="text-muted font-italic">(Optional)</small>} {this.props.required && !this.props.leaseApplicationPage && <small className="text-muted font-italic">*</small>}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        {this.props.type === 'password' ?

                        <div className='input-group'>

                            <input
                                id={this.props.id}
                                name={this.props.id}
                                type={this.props.type}
                                ref={this.passwordRef}
                                maxLength={this.props.maxLength}
                                minLength={this.props.minLength}
                                value={this.props.value || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                onFocus={this.props.handleFocus}
                                onBlur={this.props.handleBlur}
                                placeholder={this.props.placeholder}
                                min={this.props.min}
                                max={this.props.max}
                                step={this.props.step}
                                pattern={this.props.pattern}
                                title={this.props.onInvalidMessage}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                autoFocus={this.props.autoFocus}
                                className={`form-control ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' : ''} ${isInvalid ? 'is-invalid' : ''}`}
                                data-container={this.props.dataContainer}
                                data-trigger={this.props.dataTrigger}
                                data-toggle={this.props.dataToggle}
                                data-placement={this.props.dataPlacement}
                                data-content={this.props.dataContent}
                                data-html={this.props.dataHtml}
                                onInvalid={this.props.oninvalid}
                                onInput={this.props.oninput}
                                warning={this.props.warning}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={this.handleKeyUpDown}
                                onKeyUp={this.handleKeyUpDown}
                            />

                            <div className="input-group-append" onClick={this.showPassword}>
                                <span className="input-group-text eye-container">
                                    <FontAwesomeIcon icon={['fal', 'eye']} name="passwordIconEye"/>
                                    <FontAwesomeIcon icon={['fal', 'eye-slash']} name="passwordIconEyeSlash" />
                                </span>
                            </div>

                            {this.props.fieldHelpHyperlink &&
                                <span className="btn btn-link btn-sm btn-label-hyperlink" data-toggle="modal" data-target={`#${this.props.modalId}`} onClick={this.props.handleClickHyperlink}>{this.props.fieldHelpHyperlink}</span>
                            }

                            {this.props.AddnlBtn1Text && this.props.AddnlBtn1Type && this.props.AddnlBtn1Handler &&
                                <button type="button" className={`btn btn-${this.props.AddnlBtn1Type} btn-sm float-right`} onClick={this.props.AddnlBtn1Handler}><small><u>{this.props.AddnlBtn1Text}</u></small></button>
                            }

                            {isInvalid &&
                                <div className="invalid-feedback">
                                    <FormattedMessage id={"danger." + errorCode}/>
                                </div>
                            }
                        </div>
                        :
                        <div className={(this.props.prependIconName && this.props.prependIconType) ? 'input-group' : ''}>

                            {(this.props.prependIconName && this.props.prependIconType) &&
                            <div className={`input-group-prepend ${this.props.prependClass}`}>
                                <span className={`input-group-text ${this.props.prependTextClass}`}>
                                     <FontAwesomeIcon icon={[this.props.prependIconType, this.props.prependIconName]} className="fa-fw small" />
                                </span>
                            </div>
                            }

                            {!this.props.useTextArea ?
                            <input
                                id={this.props.id}
                                name={this.props.id}
                                type={this.props.type}
                                maxLength={this.props.maxLength}
                                minLength={this.props.minLength}
                                value={this.props.value || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                onFocus={this.props.handleFocus}
                                onBlur={this.props.handleBlur}
                                placeholder={this.props.placeholder}
                                min={this.props.min}
                                max={this.props.max}
                                step={this.props.step}
                                pattern={this.props.pattern}
                                title={this.props.onInvalidMessage}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                autoFocus={this.props.autoFocus}
                                className={`form-control ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' : ''} ${isInvalid ? 'is-invalid' : ''}`}
                                data-container={this.props.dataContainer}
                                data-trigger={this.props.dataTrigger}
                                data-toggle={this.props.dataToggle}
                                data-placement={this.props.dataPlacement}
                                data-content={this.props.dataContent}
                                data-html={this.props.dataHtml}
                                onInvalid={this.props.oninvalid}
                                onInput={this.props.oninput}
                                warning={this.props.warning}
                                onWheel={(e) => e.target.blur()}
                                onKeyDown={this.handleKeyUpDown} 
                                onKeyUp={this.handleKeyUpDown}
                            /> 
                            : 
                            <textarea
                                id={this.props.id}
                                name={this.props.id}
                                type={this.props.type}
                                maxLength={this.props.maxLength}
                                minLength={this.props.minLength}
                                value={this.props.value || ""}
                                onChange={this.props.handleChange || this.handleChange}
                                onFocus={this.props.handleFocus}
                                onBlur={this.props.handleBlur}
                                placeholder={this.props.placeholder}
                                min={this.props.min}
                                max={this.props.max}
                                step={this.props.step}
                                pattern={this.props.pattern}
                                title={this.props.onInvalidMessage}
                                disabled={this.props.disabled}
                                required={this.props.required}
                                autoFocus={this.props.autoFocus}
                                className={`form-control ${this.props.fieldClass} ${this.props.disabled ? 'DisabledInputField' : ''} ${isInvalid ? 'is-invalid' : ''}`}
                                data-container={this.props.dataContainer}
                                data-trigger={this.props.dataTrigger}
                                data-toggle={this.props.dataToggle}
                                data-placement={this.props.dataPlacement}
                                data-content={this.props.dataContent}
                                data-html={this.props.dataHtml}
                                onInvalid={this.props.oninvalid}
                                onInput={this.props.oninput}
                                warning={this.props.warning}/>
                            }

                        
                            {this.props.fieldHelpHyperlink &&
                                <span className="btn btn-link btn-sm btn-label-hyperlink" data-toggle="modal" data-target={`#${this.props.modalId}`} onClick={this.props.handleClickHyperlink}>{this.props.fieldHelpHyperlink}</span>
                            }
                        

                            { this.props.AddnlBtn1Text && this.props.AddnlBtn1Type && this.props.AddnlBtn1Handler &&
                                <button type="button" className={`btn btn-${this.props.AddnlBtn1Type} btn-sm float-right`} onClick={this.props.AddnlBtn1Handler}><small><u>{this.props.AddnlBtn1Text}</u></small></button>
                            }

                            {isInvalid &&
                            <div className="invalid-feedback">
                                <FormattedMessage id={"danger." + errorCode}/>
                            </div>
                            }
                        </div>
                        }
                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }
                        {this.props.warning &&
                        <small className="form-text text-muted-warning">
                            {this.props.warning}
                        </small>
                        }
                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldText.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

export default FieldText;
