import React from 'react';
import {FormattedMessage } from "react-intl";

const FieldCheckboxFC = (props) => {

    // const [checkedStatus, setCheckedStatus] = useState(props.value);
    // const updateStatus = () => {
    //     setCheckedStatus(!checkedStatus);
    // }
    
  return (
    <div className="form-group row">
        {props.label &&
        <label className={`col-sm-${props.labelColumns} col-form-label ${props.labelClass}`} htmlFor={props.id}>
            {props.label}
        </label>
        }
        <div className={`col-sm-${props.fieldColumns} align-self-center`}>

            <div className={`custom-control custom-checkbox ${props.alignClass}`}>

                <input
                    id={props.id}
                    name={props.id}
                    type="checkbox"
                    value={props.value || ''}
                    // checked={checkedStatus}
                    checked={props.value || false}
                    // onChange={props.handleChange || updateStatus}
                    onChange={(event) => {
                        props.handleChange(event.target.checked);
                    }}
                    disabled={props.disabled}
                    className={`custom-control-input ${props.fieldClass} ${props.isInvalid ? 'is-invalid' : ''}`}
                    onClick={props.onclick}
                />

                <label className={`custom-control-label ${props.labelClass}`} htmlFor={props.id}>
                    {props.fieldLabel} <span className="btn btn-link btn-sm btn-label-hyperlink" data-toggle="modal" data-target={`#${props.modalId}`} onClick={props.handleClickHyperlink}>{props.fieldLabelHyperlink}</span>
                </label>

            </div>

            {props.help &&
            <small className="form-text text-muted">
                {props.help}
            </small>
            }
            
            {props.warning &&
            <small className="form-text text-muted-warning">
                {props.warning}
            </small>
            }

            {props.isInvalid &&
            <div className="invalid-feedback">
                <FormattedMessage id={"danger." + props.errorCode} />
            </div>
            }

        </div>

    </div>
  );
};

FieldCheckboxFC.defaultProps = {
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    alignClass: ''
};

export default FieldCheckboxFC;
