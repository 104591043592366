import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import * as constants from "../../util/constants";
import Alert from "../common/Alert";
import Banner from "../common/Banner";
import FieldCheckbox from "../common/FieldCheckbox";
import FieldPassword from "../common/FieldPassword";
import FieldText from "../common/FieldText";
import GoogleLoginCustom from "../common/GoogleLoginCustom";
import Modal from "../common/Modal";
import Letus from "../common/Propertii";
import TermsContents from "../common/Terms";

class Customer extends Letus {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            firstName: '',
            lastName: '',

            termsAcknowledged: false,

            authorizationType: '',
            password: '',
            confirmPassword: '',

            termsCompleted: false,
            credentialsCompleted: false,

            verificationCode: '',

            validationList: [],

        };

        this.saveTerms = this.saveTerms.bind(this);
        this.saveCredentials = this.saveCredentials.bind(this);

        this.submitPassword = this.submitPassword.bind(this);
        this.submitOAuth = this.submitOAuth.bind(this);

        this.handleChangeAuthorizationType = this.handleChangeAuthorizationType.bind(this);
    }

    /**
     * On mounting of the component, parse the data from the URL params to provide context to the manager completing
     * their account.
     */
    componentDidMount() {

        this.setState(prevState => ({
            ...prevState,
            firstName: this.props.location.state.firstName,
            lastName: this.props.location.state.lastName,
            verificationCode: this.props.location.state.verificationCode
        }));
    }

    /**
     * Save the terms of use acknowledgement portion of the customer account completion flow.
     *
     * @param event - The event container.
     */
    saveTerms(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: true,
            credentialsCompleted: false,
        }));
    }

    /**
     * Save the account credentials portion of the customer account completion flow.
     *
     * @param event - The event container.
     */
    saveCredentials(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            credentialsCompleted: true,
        }));
    }

    /**
     * Edit the terms of use acknowledgement portion of the customer account completion flow.
     */
    editTerms() {

        this.setState(prevState => ({
            ...prevState,
            termsCompleted: false,
            credentialsCompleted: false,
        }));
    }

    /**
     * Save the account credentials portion of the customer account completion flow.
     */
    editCredentials() {

        this.setState(prevState => ({
            ...prevState,
            credentialsCompleted: false,
        }));
    }

    /**
     * Handle submission of the setup customer account password flow. Redirects the user to their dashboard upon
     * success.
     */
    submitPassword() {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/completeaccount`, {
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            verificationCode: this.state.verificationCode
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
            window.location.href = '/customer/dashboard';

        }).catch(error => { 
            if(error.response?.data?.message?.includes("already exists")) {
                error.response.data.message = "An account already exists with this email. Please log in or reset the password.";
            }
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }


    /**
     * Handle customers signing up via OAuth. Signing up via OAuth allows the user to skip local system account setup
     * and proceed directly to their dashboard.
     *
     * @param response - The returned OAuth response.
     */
    submitOAuth(response) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/authorizeoauth?accountType=TYPE_GOOGLE_OAUTH_ACCOUNT&authorizationCode=${encodeURIComponent(response.code)}&requestUri=${encodeURIComponent(window.location.origin)}&userType=TYPE_CUSTOMER&verificationCode=${encodeURIComponent(this.state.verificationCode)}`).then(response => {

            localStorage.setItem('token_type', response.data.token_type);
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('expires_at', Date.now() + (response.data.expires_in * 1000));
            window.location.href = '/customer/dashboard';

        }).catch(error => {
            if(error.response?.data?.message?.includes("invalid_grant")) {
                this.setState(prevState=>({
                    ...prevState,
                    spinner: false,
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'danger',
                            code: 'login.cookies.DISABLED',
                        },
                        values: {
                            errorCause: 'Google Auth',
                        }
                    }],
                }));
                window.scrollTo(0, 0);
            }
            else {
                if(error.response?.data?.message?.includes("already exists")) {
                    error.response.data.message = "An account already exists with this email. Please log in or reset the password.";
                } 
                this.handleValidation(error);
            }
            window.scrollTo(0, 0);
        });
    }

    /**
     * Handle changes to the selected method of authorization/logging in.
     *
     * @param event - The event container.
     */
    handleChangeAuthorizationType(event) {

        event.persist();

        this.setState(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A public-facing component that handles customers completing their account.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        return(
            <div className="content-block">

                <Banner backgroundImg="login" heading="static.onboarding.heading" subheading="static.onboarding.subheading" align="left" values={{firstName: this.state.firstName}} />

                <div className="container">

                    <div className="row">
                        <div className="col-md-8">

                            <Alert validationList={this.state.validationList} />

                            <div className="card">

                                <div className="card-header">
                                    Terms of Use
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">First, please review and accept the Letus Terms of Use.</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveTerms}>

                                        {this.state.termsCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editTerms()}>
                                                    <div className="">
                                                        Letus Terms of Use
                                                    </div>
                                                    <small className="mb-0 ml-md-0 small text-muted">
                                                        I have read and agree to the Letus Terms of Use
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editTerms()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.termsCompleted &&
                                        <React.Fragment>

                                            <div className="card-body border mb-3 bg-secondary rounded small" style={{height: "250px", overflowY: "scroll"}}>

                                                <TermsContents />

                                            </div>

                                            <FieldCheckbox id="termsAcknowledged" fieldLabel="I have read and agree to the Letus Terms of Use" fieldColumns="12" parent={this} value={this.state.termsAcknowledged} />

                                            {this.state.termsAcknowledged &&
                                            <button type="submit" className="btn btn-primary btn-md btn-block mt-3">
                                                Save & Continue
                                            </button>
                                            }

                                            {!this.state.termsAcknowledged &&
                                            <div className="btn btn-primary btn-md btn-block mt-3 disabled" data-toggle="tooltip" data-placement="top" title="In order to continue with your account setup, you must indicate that you have read and agree to the Letus terms of use above.">
                                                Save & Continue
                                            </div>
                                            }

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>

                            {this.state.termsCompleted &&
                            <div className="card">

                                <div className="card-header">
                                    Your Account
                                </div>

                                <div className="card-body bg-secondary">
                                    <p className="mb-0">How would you like to log in to your Letus account?</p>
                                </div>

                                <div className="card-body">
                                    <form onSubmit={this.saveCredentials}>

                                        {this.state.credentialsCompleted &&
                                        <div className="">

                                            <div className="list-group">
                                                <div className="list-group-item list-group-item-action c-pointer" onClick={() => this.editCredentials()}>
                                                    {this.state.authorizationType === 'PASSWORD' &&
                                                    <React.Fragment>
                                                        <div className="">
                                                            Password
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Use my own secure Letus account password
                                                        </small>
                                                    </React.Fragment>
                                                    }
                                                    {this.state.authorizationType === 'OAUTH' &&
                                                    <React.Fragment>
                                                        <div className="">
                                                            Google
                                                        </div>
                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Log in to my Letus account instantly using my Google account
                                                        </small>
                                                    </React.Fragment>
                                                    }
                                                </div>
                                            </div>

                                            <div className="btn btn-outline-primary btn-md btn-block mt-3" onClick={() => this.editCredentials()}>
                                                Edit
                                            </div>

                                        </div>
                                        }

                                        {!this.state.credentialsCompleted &&
                                        <React.Fragment>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="authorizationType" value="PASSWORD" id="authorizationPassword" defaultChecked={this.state.authorizationType === 'PASSWORD' || false} onChange={this.handleChangeAuthorizationType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="authorizationPassword">

                                                        <div className="">
                                                            Password
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Set up my own secure Letus account password
                                                        </small>

                                                        {this.state.authorizationType === 'PASSWORD' &&
                                                        <div className="mt-3">

                                                            <FieldPassword id="password" label="Password" required={true} labelColumns="0" labelClass="d-none mb-0" placeholder="Password" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state['password']} dataHtml={true} dataPlacement={window.matchMedia( "(max-width: 700px)" ).matches?"top":"top" }/>

                                                            <FieldText id="confirmPassword" label="Confirm Password" required={true} type="password" labelColumns="0" labelClass="d-none mb-0" placeholder="Confirm Password" fieldColumns="12" fieldClass="form-control-md mb-0" parent={this} value={this.state['confirmPassword']} />

                                                        </div>
                                                        }

                                                    </label>
                                                </div>
                                            </div>

                                            <div className="list-group mb-2">
                                                <div className="custom-control custom-radio list-group-item list-group-item-action c-pointer">
                                                    <input type="radio" name="authorizationType" value="OAUTH" id="authorizationOAuth" defaultChecked={this.state.authorizationType === 'OAUTH' || false} onChange={this.handleChangeAuthorizationType} className="custom-control-input" />
                                                    <label className="custom-control-label pl-3 c-pointer" htmlFor="authorizationOAuth">

                                                        <div className="">
                                                            Google
                                                        </div>

                                                        <small className="mb-0 ml-md-0 small text-muted">
                                                            Log in to your Letus account instantly by linking your Google account
                                                        </small>

                                                    </label>
                                                </div>
                                            </div>

                                            <button 
                                                type="submit" 
                                                className="btn btn-primary btn-md btn-block mt-3"
                                                disabled={
                                                    this.state.authorizationType === 'PASSWORD' ?
                                                        (!this.state.password.length || !this.state.confirmPassword.length) || this.state.password !== this.state.confirmPassword :
                                                        !this.state.authorizationType
                                                }
                                            >Save & Continue
                                            </button>

                                        </React.Fragment>
                                        }

                                    </form>
                                </div>
                            </div>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="sticky-top">

                                <div className="card">

                                    <div className="card-header">
                                        Welcome, {this.state.firstName}!
                                    </div>

                                    <div className="card-body">
                                        <p className="mb-0">
                                            Your property manager has created a Letus account on your behalf. To complete your account, please accept the Letus Terms of Use and choose how you would like to log in to your Letus account.
                                        </p>
                                    </div>

                                    <div className="card-footer">
                                        <p className="small text-muted mb-0">
                                            Already have an account? <Link to="/login">Log in</Link> now.
                                        </p>
                                    </div>

                                </div>

                                {(this.state.termsCompleted && this.state.credentialsCompleted) &&
                                <React.Fragment>

                                    {this.state.authorizationType === 'PASSWORD' &&
                                    <div className="btn btn-primary btn-lg btn-block py-3 mb-4" onClick={() => this.submitPassword()}>
                                        <FontAwesomeIcon icon={['far', 'check-circle']} className="fa-fw" /> Complete Account
                                    </div>
                                    }

                                    {this.state.authorizationType === 'OAUTH' &&
                                        <div className='btn btn-primary btn-lg btn-block py-3 mb-4'>
                                            <GoogleLoginCustom submitOAuth={this.submitOAuth}/>
                                        </div>
                                    }

                                </React.Fragment>
                                }

                            </div>

                        </div>
                    </div>

                </div>

                <Modal id="onboarding-complete" theme="primary" iconType="far" iconName="thumbs-up" title={`Welcome, ${this.state.firstName}`}
                       body="Your Letus account password has been successfully set up. Click the button below to proceed to your Letus account dashboard.">
                    <Link to="/customer/payments" className="btn btn-primary btn-lg" onClick={() => $("#onboarding-complete").modal("hide")}>
                        Continue
                    </Link>
                </Modal>

            </div>
        )
    };
}

Customer.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Customer);
