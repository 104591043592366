import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import Breadcrumb from "../../common/Breadcrumb";
import ButtonBack from "../../common/ButtonBack";
import ButtonSave from "../../common/ButtonSave";
import FieldPhone from "../../common/FieldPhone";
import FieldSelect from "../../common/FieldSelect";
import FieldText from "../../common/FieldText";
import Modal from "../../common/Modal";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class Administrators extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {

            admin: {},
            roleList: [],
            firstName: '',
            lastName: '',

            adminList: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },

            adminQuery: {
                orderBy: 'ASC',
                orderByFields: ['firstName'],
                joins: {
                    role: {
                        targetRecordType: 'TYPE_ROLE',
                        joinField: 'roleId',
                        alias: 'role',
                        returnFields: ['name']
                    }
                }
            },

            validationList: [],

        };

        this.viewAdmin = this.viewAdmin.bind(this);
        this.initAdmin = this.initAdmin.bind(this);
        this.searchAdmins = this.searchAdmins.bind(this);
        this.saveAdmin = this.saveAdmin.bind(this);
        this.deleteAdmin = this.deleteAdmin.bind(this);
    }

    /**
     * Initialize the administrators list by calling the search admin function, in addition to initializing the list of
     * roles available in the system to populate the role field.
     */
    componentDidMount() {

        this.searchAdmins(1, 25, this.state.adminQuery);

        axios.post(`${constants.REACT_APP_HOST_API_URL}/role/search`, {
            orderBy: 'ASC',
            orderByFields: ['createDate']
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                roleList: response.data.records,
            }));

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle selecting an administrator by bringing up the edit administrator modal.
     *
     * @param adminId - The ID of the administrator selected.
     */
    viewAdmin(adminId) {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/${adminId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                admin: response.data,
                firstName: response.data.firstName,
                lastName: response.data.lastName
            }));

            $('#admin').modal('show');

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a new instance of an admin.
     */
    initAdmin() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/admin/new`).then(response => {
            this.setState(prevState => ({
                ...prevState,
                admin: response.data,
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Create or update an existing admin.
     *
     * @param event - The event container.
     */
    saveAdmin(event) {

        event.preventDefault();

        if(this.state.admin.createDate == null) {
            axios.post(`${constants.REACT_APP_HOST_API_URL}/setupuser`, {
                user: this.state.admin,
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'admin.system.administrators.created'
                        },
                        values: {
                            email: this.state.admin.email
                        }

                    }]
                });

                this.searchAdmins(1, 25, this.state.adminQuery);

                $("#admin").modal("hide");

            }).catch(error => {
                this.handleValidation(error);
            });
        } else {
            axios.put(`${constants.REACT_APP_HOST_API_URL}/update`, this.state.admin, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: 'saved',
                            message: 'Changes have been saved'
                        }
                    }],
                });

                this.searchAdmins(1, 25, this.state.adminQuery);

                $('#admin').modal('hide');

            }).catch(error => {
                this.handleValidation(error);
            });
        }

        window.scrollTo(0, 0);
    }

    /**
     * Delete the selected admin.
     */
    deleteAdmin() {

        axios.delete(`${constants.REACT_APP_HOST_API_URL}/admin/${this.state.admin.id}/delete`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.system.administrators.deleted'
                    }
                }],
            });

            this.searchAdmins(1, 25, this.state.adminQuery);

            $('#admin').modal('hide');

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Perform a search for admins.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    searchAdmins(page, recordsPerPage, query) {

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/admin/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            joins: query.joins
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                adminList: response.data,
                adminQuery: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    joins: query.joins
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The sortable administrators list.
     */
    render() {

        return(
            <div className="content-block">

                <Spinner visible={this.state.spinner} />

                <div className="container">

                    <Breadcrumb parentPath="/admin/system" parentPage="System" childPage="Administrators" />

                    <Alert validationList={this.state.validationList} validationType="primary" />

                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    Administrators
                                </div>
                                <div className="col text-right">
                                    <div data-toggle="modal" data-target="#admin" className="btn btn-primary btn-sm" onClick={() => this.initAdmin()}>
                                        <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Administrator
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Table columns={{firstName: 'Name', 'role.name': 'Role', email: 'Email', phone: 'Phone', createDate: 'Create Date'}}
                               columnWidths={['20%', '20%', '20%', '20%', '20%']}
                               headerClass="c-pointer"
                               data={this.state.adminList}
                               query={this.state.adminQuery}
                               sortEnabled={true}
                               recordsEnabled={true}
                               paginationEnabled={true}
                               updateFunction={this.searchAdmins}
                               tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                               >
                            <tbody>
                            {this.state.adminList.records.map((data, key) => {
                                return(
                                    <tr key={key} onClick={() => this.viewAdmin(data.id)} className="c-pointer">
                                        <td>
                                            <div className="">
                                                {data.firstName} {data.lastName}
                                            </div>
                                        </td>
                                        <td>
                                            {(data.joins && data.joins.role) &&
                                            <div className="">
                                                {data.joins.role.name}
                                            </div>
                                            }
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.email}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                {data.phone}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="">
                                                <Moment format="MMM DD, YYYY">
                                                    {data.createDate}
                                                </Moment>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </div>

                    <div className="modal fade" id="admin" tabIndex="-1" role="dialog" aria-labelledby="admin-label" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                            <div className="modal-content shadow">
                                <form onSubmit={this.saveAdmin}>
                                    <div className="modal-header bg-dark text-white">
                                        <h5 className="modal-title" id="admin-label">
                                            {this.state.admin['createDate'] == null &&
                                            <span className="">
                                                Create Administrator
                                            </span>
                                            }
                                            {this.state.admin['createDate'] != null &&
                                            <span className="">
                                                {this.state.firstName} {this.state.lastName}
                                            </span>
                                            }
                                        </h5>
                                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                        </button>
                                    </div>
                                    <div className="modal-body">

                                        <Alert validationList={this.state.validationList} validationType="danger" />

                                        <FieldText id="firstName" label="First Name" model="admin" parent={this} value={this.state.admin['firstName']} />

                                        <FieldText id="lastName" label="Last Name" model="admin" parent={this} value={this.state.admin['lastName']} />

                                        <FieldSelect id="roleId" label="Role" model="admin" parent={this} value={this.state.admin['roleId']}>

                                            <option value="">Select a role...</option>

                                            {this.state.roleList &&
                                            <React.Fragment>
                                                {this.state.roleList.map((data, key) => {
                                                    return (
                                                        <option key={key} value={data.id}>{data.name}</option>
                                                    );
                                                })}
                                            </React.Fragment>
                                            }

                                        </FieldSelect>

                                        <FieldText id="email" label="Email" model="admin" type="email" parent={this} value={this.state.admin['email']} />

                                        <FieldPhone id="phone" label="Phone" model="admin" parent={this} value={this.state.admin['phone']} optional={true} />

                                    </div>
                                    <div className="modal-footer bg-secondary rounded-bottom d-block">

                                        <div className="row">
                                            <div className="col">

                                                <div className="float-left">
                                                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#admin").modal("hide")}>Close</button>
                                                </div>

                                                <div className="float-right">

                                                    {this.state.admin.createDate &&
                                                    <div className="btn btn-primary btn-lg" onClick={() => {$("#admin").modal("hide"); $("#delete-admin").modal("show");}}>Delete</div>
                                                    }

                                                    <ButtonSave />

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col text-right">

                            <ButtonBack path="/admin/system" />

                        </div>
                    </div>

                    <Modal id="delete-admin" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Administrator"
                           body="Are you sure you want to delete this administrator?">
                        <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-admin").modal("hide"); $("#admin").modal('show');}}>
                            <FormattedMessage id="button.back" />
                        </button>
                        <button onClick={() => {this.deleteAdmin()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                            Delete Administrator
                        </button>
                    </Modal>

                </div>

            </div>
        )
    };
}

export default Administrators;