import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../../util/constants";
import Alert from "../../common/Alert";
import ButtonSubmit from "../../common/ButtonSubmit";
import FieldText from "../../common/FieldText";
import LogoPaymentProvider from "../../common/LogoPaymentProvider";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import Table from "../../common/Table";

class MerchantAccounts extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            bankAccountNumber: '',
            routingNumber: '',
            institutionNumber: '',
            transitNumber: '',
            landlord: {},
            merchantAccounts: {
                page: '',
                recordsPerPage: '',
                totalPages: '',
                totalRecordCount: '',
                records: [
                    {}
                ]
            },
            query: {
                orderBy: 'ASC',
                orderByFields: ['name'],
                conditionList: [
                    {
                        type: 'STRING',
                        logicalOperator: 'AND',
                        openBrackets: null,
                        closeBrackets: null,
                        fieldName: 'companyId',
                        operator: 'EQUALS',
                        fieldValue: ''
                    }
                ]
            },
            validationList: [],
        };

        this.updateTable = this.updateTable.bind(this);
        this.editMerchantAccount = this.editMerchantAccount.bind(this);
        this.setupBankingInformation = this.setupBankingInformation.bind(this);
    }

    /**
     * Retrieve the company, and if applicable, the parent company. Afterwards, retrieve a list of company owners based
     * on the company ID in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState(prevState => ({
                ...prevState,
                landlord: response.data,
                query: {
                    orderBy: 'ASC',
                    orderByFields: ['name'],
                    conditionList: [
                        {
                            type: 'STRING',
                            logicalOperator: 'AND',
                            openBrackets: null,
                            closeBrackets: null,
                            fieldName: 'companyId',
                            operator: 'EQUALS',
                            fieldValue: response.data.companyId
                        }
                    ]
                }
            }));

            this.updateTable(1, 25, this.state.query);

        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Handle clicking a merchant account by redirecting the user to the edit merchant account component.
     *
     * @param merchantAccountId - The ID of the merchant account clicked on.
     */
    editMerchantAccount(merchantAccountId) {

        this.props.history.push(`/admin/landlords/${this.props.match.params.landlordId}/merchants/${merchantAccountId}`);
    }

    /**
     * Setup the landlord's banking information for the first time via issuing a micro-deposit.
     *
     * @param event - The event container.
     */
    setupBankingInformation(event) {

        event.preventDefault();

        this.setState(prevState => ({
            ...prevState,
            spinner: true
        }));

        axios.post(`${constants.REACT_APP_HOST_API_URL}/landlord/${this.props.match.params.landlordId}/activate`, {
            bankAccountNumber: this.state.bankAccountNumber,
            routingNumber: this.state.institutionNumber + this.state.transitNumber
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {

            this.setState({
                spinner: false,
                validationList: [{
                    fields: {},
                    alert: {
                        type: 'primary',
                        code: 'admin.landlords.merchants.banking.submitted'
                    }
                }],
            });

            $('#setup-banking-information').modal('hide');

            this.updateTable(1, 25, this.state.query);

        }).catch(error => {
            this.handleValidation(error);
        });

        window.scrollTo(0, 0);
    }

    /**
     * Update the data table of merchant accounts.
     *
     * @param page - The page to display.
     * @param recordsPerPage - The amount of records to display on each page.
     * @param query - The search query.
     */
    updateTable(page, recordsPerPage, query) {

        axios.post(`${constants.REACT_APP_HOST_API_URL}/merchant_account/search?recordsPerPage=${recordsPerPage}&page=${page}`, {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList
        }, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                merchantAccounts: response.data,
                query: {
                    orderBy: query.orderBy,
                    orderByFields: query.orderByFields,
                    conditionList: query.conditionList
                }
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Render the component.
     *
     * @returns {*} - The administrator companies dashboard component.
     */
    render() {

        const {formatMessage} = this.props.intl;

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Merchant Accounts
                            </div>
                            <div className="col text-right">
                                {this.state.landlord && this.state.landlord.applicationStatus !== 'COMPLETE' &&
                                <div data-toggle="modal" data-target="#setup-banking-information" className="btn btn-primary btn-sm">
                                    Setup Banking Information
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Table columns={{name: 'Name', accountNumber: 'Number', paymentProviderId: 'Processor', currency: 'Currency', paymentType: 'Type', accountStatus: 'Status'}}
                           columnWidths={['25%', '15%', '15%', '15%', '15%', '15%']}
                           headerClass="c-pointer"
                           data={this.state.merchantAccounts}
                           query={this.state.query}
                           sortEnabled={true}
                           recordsEnabled={true}
                           paginationEnabled={true}
                           updateFunction={this.updateTable}>
                        <tbody>
                        {this.state.merchantAccounts.records.map((data, key) => {
                            return(
                                <tr key={key} onClick={() => this.editMerchantAccount(data.id)} className="c-pointer">
                                    <td>
                                        <div className="">
                                            {data.name}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.accountNumber}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <LogoPaymentProvider paymentProviderId={data.paymentProviderId} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            {data.currency}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="">
                                            <FormattedMessage id={"enum.merchantAccount.paymentType." + data.paymentType} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className="text-nowrap">
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.merchantAccount.accountStatus." + data.accountStatus + ".class"})}`} />
                                            <span className="ml-1"><FormattedMessage id={"enum.merchantAccount.accountStatus." + data.accountStatus} /></span>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                </div>

                <div className="modal fade" id="setup-banking-information" tabIndex="-1" role="dialog" aria-labelledby="setup-banking-information-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.setupBankingInformation}>
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="setup-banking-information-label">
                                        Setup Banking Information
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2"/>
                                    </button>
                                </div>
                                <div className="modal-body bg-secondary">

                                    <p className="mb-0">
                                        Enter the landlord's banking information to determine where funds should be sent to when his or her tenants make payments through Propertii.
                                    </p>

                                </div>
                                <div className="modal-body">

                                    <Alert validationList={this.state.validationList} validationType="danger" />

                                    <FieldText id="institutionNumber" label="Institution Number" labelColumns="4" fieldColumns="8" type="tel" required={true} parent={this} value={this.state['institutionNumber']}/>

                                    <FieldText id="transitNumber" label="Transit Number" labelColumns="4" fieldColumns="8" type="tel" required={true} parent={this} value={this.state['transitNumber']}/>

                                    <FieldText id="bankAccountNumber" label="Account Number" labelColumns="4" fieldColumns="8" type="tel" required={true} parent={this} value={this.state['bankAccountNumber']}/>

                                </div>
                                <div className="modal-footer bg-secondary rounded-bottom d-block">

                                    <div className="row">
                                        <div className="col">
                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#setup-banking-information").modal("hide")}>Close</button>
                                            </div>
                                            <div className="float-right">
                                                <ButtonSubmit />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </React.Fragment>
        )
    };
}

MerchantAccounts.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(MerchantAccounts);