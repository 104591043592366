import axios from 'axios';
import { CreditCardRegexArray } from '../constants/CreditCards';
import * as constants from '../util/constants';
import generateRequestHeaders from '../util/generateRequestHeaders';
/**
 * Return a credit/debit card brand, based on the 'creditCardType' library's validation of an inputted card number,
 * in all uppercase.
 *
 * @param cardNumber - The credit or debit card number to validate.
 * @returns {string|null} - The credit card type, if available, in uppercase (i.e: 'VISA').
 */
export const getCardBrand = (cardNumber) => {
  const creditCard = CreditCardRegexArray.find((creditCard) => creditCard.regex.test(cardNumber));
  let cardBrand = '';
  if (creditCard) {
    cardBrand = creditCard.brand;
  }
  return cardBrand;
};

/**
 * Return a credit/debit card brand and some other information about the card
 * @param {*} cardNumber 
 */
export const getCardBrandByPeek = (cardNumber) => {
  const binNumber = String(cardNumber)?.substring(0, 6);
  return axios.get(
    `${constants.REACT_APP_HOST_API_URL}/BIN_MAPPING/${binNumber}/peek?id=${binNumber}&recordTypeName=BIN_MAPPING`,
    {
      headers: generateRequestHeaders(),
    }
  );
};
