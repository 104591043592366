import $ from "jquery";
import React from 'react';
import { injectIntl, intlShape } from "react-intl";
import Alert from "../../common/Alert";
import Propertii from "../../common/Propertii";
import Spinner from "../../common/Spinner";
import TransactionList from "../../common/Transactions";

class Transactions extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            validationList: [],
        };
    }

    /**
     * Render the component.
     *
     * @returns {*} - The create property component for landlords.
     */
    render() {

        $(function() {
            $('[data-toggle="tooltip"]').tooltip()
        });

        return(
            <React.Fragment>

                <Spinner visible={this.state.spinner} />

                <Alert validationList={this.state.validationList} />

                <TransactionList billingAccountId={this.props.match.params.propertyLeaseId} 
                                 textSearchPlaceholderOverride = "Search Tenant Transactions"
                                 userType="TYPE_LANDLORD" />

            </React.Fragment>
        )
    };
}

Transactions.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Transactions);