import React, {useEffect} from 'react';
import LoginCore from '../LoginCore';
import { getLoginCRText, getPixelTrackingURLByClient } from './loginCRConfig';
import { validateAndCleanClientName } from './loginUtils';
import styles from './styles.module.scss';

/**
 * Custom Credit Reporting Login component 2
 * @param {*} props
 * @returns
 */
const LoginCR = (props) => {
  const clientName = new URLSearchParams(props.location?.search)?.get('client') || 'letus';

  /**
   * Add a pixel tracking script on page load
   */
  useEffect(() => {
    if (window?.location?.hostname === 'let.us') {
      const script = document.createElement('script');
      script.src = getPixelTrackingURLByClient(clientName);
      script.async = true;
      script.id = clientName;
      document.body.appendChild(script);
    }

    return () => {
      const scriptElem = document.getElementById(clientName);
      if (scriptElem) {
        scriptElem.remove();
      }
    };
  }, [clientName]);

  // Get all the text to be displayed on the login page for a client
  const textCR = getLoginCRText(
    validateAndCleanClientName(clientName)?.cleanedClientName,
    props.locale
  );
  return (
    <div className={`${styles.container}`}>
      <div className='row m-0'>
        <div className={`col-md-6 ${styles.colInfo}`}>
          <section>
            {/*<div className={`${styles.offerBubble} m-1`}>*/}
            {/*  <span>{textCR?.offerBubble}</span>*/}
            {/*</div>*/}
            <h1>
              {textCR?.headings?.map((heading, key) => (
                <span key={key}>
                  {heading}
                  <br />
                </span>
              ))}
            </h1>
            <h4>{textCR?.subHeading}</h4>
            <p>{textCR?.section1}</p>
            <h4>{textCR?.bulletPointsTitle}</h4>
            <ol>
              {textCR?.bulletPoints?.map((bulletPoint, key) => (
                <li key={key}>{bulletPoint}</li>
              ))}
            </ol>
            <p className='mt-5'>
              {textCR?.footerMessages?.map((footerMessage, key) => (
                <span key={key}>
                  {footerMessage}
                  <br />
                </span>
              ))}
            </p>
          </section>
        </div>
        <div className={`col-md-6 ${styles.colLogin}`}>
          <LoginCore
            {...props}
            loginFor='CR'
            contentBlockClass='w-100'
            clientLogoHeight={
              validateAndCleanClientName(clientName)?.clientNameSmall === 'letus' ? 80 : undefined
            }
            clientName={validateAndCleanClientName(clientName)?.clientNameSmall}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginCR;
