import React from 'react';
import Propertii from "../common/Propertii";
import qs from "qs";
import {Link} from "react-router-dom";

class Splash extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            userType: '',
            firstName: '',
            lastName: '',
            verificationCode: {},
            validationList: [],
        };

        this.initOnboarding = this.initOnboarding.bind(this);
    }

    /**
     * Parse the URL for the verification code query parameter. If no verification code is provided in the URL, redirect
     * the user to the login component. Otherwise, immediately call the verification endpoint.
     */
    componentDidMount() {

        // Purge the local storage to avoid overlapping sessions once the flow is completed
        localStorage.removeItem('token_type');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('status');
        localStorage.removeItem('screening');
        localStorage.removeItem('broadcast_messages');
        sessionStorage.removeItem('session_role_id');


        // Refresh the overall app state to reflect the localStorage purge
        this.props.refreshAppState();

        const urlParams = qs.parse(this.props.location.search.slice(1));

        this.setState(prevState => ({
            ...prevState,
            userType: urlParams.userType,
            firstName: urlParams.firstName,
            lastName: urlParams.lastName,
            verificationCode: urlParams.verificationCode
        }));
    }

    /**
     * Initialize the onboarding flow when the user clicks the call to action on the onboarding splash page. Redirects
     * the user to the appropriate component to complete their onboarding.
     */
    initOnboarding() {

        switch(this.state.userType) {

            case 'TYPE_ADMIN':
                this.props.history.push({
                    pathname: `/onboarding/${this.props.match.params.userId}/admin`,
                    state: {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        verificationCode: this.state.verificationCode
                    }
                });
                break;

            case 'TYPE_MANAGER':
                this.props.history.push({
                    pathname: `/onboarding/${this.props.match.params.userId}/manager`,
                    state: {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        verificationCode: this.state.verificationCode
                    }
                });
                break;

            case 'TYPE_CUSTOMER':
                this.props.history.push({
                    pathname: `/onboarding/${this.props.match.params.userId}/customer`,
                    state: {
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        verificationCode: this.state.verificationCode
                    }
                });
                break;

            default:
                break;
        }
    }

    /**
     * Render the component.
     *
     * @returns {*} - A splash screen telling the user that they have had an account created on their behalf and they
     * must now complete their account to be able to log in.
     */
    render() {

        return(
            <div className="content-block">

                <div className="content-header mb-0">
                    <div className="container">

                        <div className="row no-gutters">
                            <div className="col-md-6 col-12 align-self-center pr-md-5">
                                <div className="jumbotron jumbotron-fluid bg-transparent mb-0">

                                    <h1 className="display-5 mb-2">
                                        Welcome, {this.state.firstName}!
                                    </h1>

                                    <h3 className="mb-4">
                                        Let's create your Letus account
                                    </h3>

                                    {this.state.userType === 'TYPE_ADMIN' &&
                                    <div className="">
                                        <p className="mb-4">
                                            A Letus administrator account has been created on your behalf. Click the button below to create your password and complete your account.
                                        </p>
                                    </div>
                                    }

                                    {this.state.userType === 'TYPE_MANAGER' &&
                                    <div className="">
                                        <p className="">
                                            A Letus manager account has been created on your behalf, allowing you to manage your properties, tenants, rent payments, and more through Letus' secure online platform.
                                        </p>
                                        <p className="mb-4">
                                            Click the button below to create your password and complete your account.
                                        </p>
                                    </div>
                                    }

                                    {this.state.userType === 'TYPE_CUSTOMER' &&
                                    <div className="">
                                        <p className="">
                                            Your property manager has created a Letus account on your behalf. All that's left is to create your password by clicking the button below.

                                        </p>
                                        <p className="mb-4">
                                            Letus simplifies rent payments through it's secure online platform. No more post-dated checks! Enjoy the convenience of choosing the payment method that works best for you.
                                        </p>
                                    </div>
                                    }

                                    <button className="btn btn-lg btn-primary btn-block" onClick={() => this.initOnboarding()}>
                                        Setup My Password
                                    </button>

                                    <div className="divider">
                                        <span className="small text-muted font-italic text-uppercase">Already have an account?</span>
                                    </div>

                                    <Link to="/login" className="btn btn-lg btn-outline-primary btn-block">
                                        Log In Now
                                    </Link>

                                </div>
                            </div>
                            <div className="col-md-6 d-none d-md-block align-self-center">

                                <div style={{background: "linear-gradient(20deg, rgb(79, 79, 79) 0%, rgba(17, 17, 17, 0.38) 100%) 0% 0% / cover, url('https://dheiziex291vk.cloudfront.net/static/backgrounds/login.jpg') no-repeat center center scroll", backgroundSize: "cover", height: "675px", width: "998px"}}>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>

            </div>
        );
    }

}

export default Splash;
