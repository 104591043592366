import axios from 'axios';
import React from 'react';
import * as constants from '../../util/constants';
import Propertii from './Propertii';

class ButtonResend extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);

    this.state = {
      email: {},
    };

    this.findInviteEmail = this.findInviteEmail.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.resendEmail = this.resendEmail.bind(this);
  }

  componentDidMount() {
    if (this.props.isInivitationResend) {
      this.findInviteEmail();
    }
  }

  findInviteEmail() {
    let inviteEmailQuery = {
      orderBy: 'DESC',
      orderByFields: ['updateDate'],
      conditionList: [
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: "(",
          closeBrackets: null,
          operator: 'EQUALS',
          fieldName: 'userId',
          fieldValue: this.props.customer['id'],
        },
        {
          type: 'STRING',
          logicalOperator: 'AND',
          openBrackets: "(",
          closeBrackets: null,
          operator: 'EQUALS',
          fieldName: 'template',
          fieldValue: 'userSetup',
        },
        {
            type: 'STRING',
            logicalOperator: 'OR',
            openBrackets: null,
            closeBrackets: null,
            operator: 'EQUALS',
            fieldName: 'template',
            fieldValue: 'userCompanySetup',
          },
        {
          type: 'STRING',
          logicalOperator: 'OR',
          openBrackets: null,
          closeBrackets: "))",
          operator: 'EQUALS',
          fieldName: 'template',
          fieldValue: 'userSetupWithLease',
        },
      ],
    };

    axios
      .post(`${constants.REACT_APP_HOST_API_URL}/email/search?recordsPerPage=25&page=1`, inviteEmailQuery, {
        headers: this.generateRequestHeaders(),
      })
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          email: response?.data?.records[0],
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  /**
   * Handle clicking the resend button, resending an email based on .
   */
  handleResend(event) {
    event.preventDefault();

    if (this.props.customer['id'] && this.props.customer['email'] && this.props.isInivitationResend) {
      if(this.state.email?.id) {
        this.resendEmail(this.state.email.id, this.props.customer['email']);
      } else {
        this.sendWelcomeEmail(this.props.customer['email']);
      }
    }
  }

  /**
   * Sends an existing email
   * @param {*} emailId 
   * @param {*} newEmailAddress 
   */
  resendEmail(emailId, newEmailAddress) {
    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/email/${emailId}/resend?newEmail=${encodeURIComponent(newEmailAddress)}`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        response.data.alertCode = 'admin.system.emails.resent';
        this.props.parent.handleSuccessAlert(response, { email: newEmailAddress });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
      .catch((error) => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.props.parent.handleValidation(error);
      });
  }

  /**
   * Sends a welcome email
   * @param {*} customerEmail 
   */
  sendWelcomeEmail(customerEmail) {
    axios
      .get(
        `${constants.REACT_APP_HOST_API_URL}/CUSTOMER/${encodeURIComponent(customerEmail)}/sendwelcomeemail`,
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        response.data.alertCode = 'admin.system.emails.sent';
        this.props.parent.handleSuccessAlert(response, { email: customerEmail });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        // Timeout because there maybe a slight delay for the data to get updated.
        setTimeout(() => {
          this.findInviteEmail();
        }, 2000);
      })
      .catch((error) => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.props.parent.handleValidation(error);
      });
  }

  /**
   * Render the component.
   *
   * @returns {*} - A generic submit button component.
   */
  render() {
    return (
      <React.Fragment>
        <button
          type='button'
          disabled={this.props.disabled}
          className={`btn btn-primary btn-lg ${this.props.customClasses ? this.props.customClasses : 'ml-2'}`}
          onClick={(event) => {
            this.handleResend(event);
          }}
        >
          {this.state.email?.id ? 'Resend' : 'Send'}&nbsp;
          {this.props.resendTitle}
        </button>
      </React.Fragment>
    );
  }
}

export default ButtonResend;
