import React from 'react'
import PropTypes from 'prop-types';

const Header = ({title}) => {
    return <span>{title}</span>;
}

Header.propTypes = {
    title: PropTypes.string,
}

export default Header;