import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import * as constants from "../../util/constants";
import FieldText from "../common/FieldText";
import Propertii from "../common/Propertii";
import Alert from "./Alert";
import ButtonSave from "./ButtonSave";
import FieldRadio from './FieldRadio';
import Modal from "./Modal";
import ModalFC from "./ModalFC";


class CustomFieldList extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            customFields: {},
            customFieldKey: '',
            customFieldValue: '',
            editing: false,
            validationList: [],

            bankAccountTypeForPaysafeCash:''
        };

        this.initCustomField = this.initCustomField.bind(this);
        this.editCustomField = this.editCustomField.bind(this);
        this.saveCustomField = this.saveCustomField.bind(this);
        this.deleteCustomField = this.deleteCustomField.bind(this);
        this.handleChangeBankAccountType = this.handleChangeBankAccountType.bind(this);
        this.handleContinueBlueSnapWarningOnUpdate = this.handleContinueBlueSnapWarningOnUpdate.bind(this);
    }

    /**
     * Get the company based on the ID provided in the route.
     */
    componentDidMount() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                customFields: response.data.customFields
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    /**
     * Initialize a custom field when the user clicks the 'create custom field' button.
     */
    initCustomField() {

        this.setState(prevState => ({
            ...prevState,
            customFieldKey: '',
            customFieldValue: '',
            editing: false
        }));
    }

    /**
     * Edit an existing custom field key/value pair.
     *
     * @param customFieldKey - The key of the custom field.
     */
    editCustomField(customFieldKey) {

        if(customFieldKey === 'merchant_ipn' || customFieldKey === 'merchant_credentials' || customFieldKey === 'blueSnapAutoPosted' ){
            return;
        }

        if(customFieldKey === 'bankAccountType'){
            this.setState(prevState =>({
                ...prevState,
                bankAccountTypeForPaysafeCash: prevState.customFields['bankAccountType']
            }));
        }

        let customFieldValue = this.state.customFields[customFieldKey];

        this.setState(prevState => ({
            ...prevState,
            customFieldKey: customFieldKey,
            customFieldValue: customFieldValue,
            editing: true
        }));

        $("#custom-field").modal("show");
    }

    /**
     * Save and submit a custom field key/value pair.
     *
     * @param event - The event container.
     */
    saveCustomField(event, showWarning=true) {

        if(event) {
            event.persist();
        }

        const functionName = 'saveCustomField';
        if(this.props['paymentProviderId'] === 'BLUESNAP' && showWarning) {
            $("#custom-field").modal("hide");
            $("#blueSnapWarningUpdate").modal("show");
            this.setState((prevState)=>({
                ...prevState,
                modalContinueFuncName: functionName
            }));
        } else {
            let customFields = this.state.customFields;

            customFields[this.state.customFieldKey] = this.state.customFieldValue;

            axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}/update`, {
                customFields: customFields
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    customFields: response.data.customFields,
                }));

                this.props.parent.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.state.editing ? 'saved' : this.props.createMessage
                        }
                    }],
                });

                $("#custom-field").modal("hide");

                axios.get(`${constants.REACT_APP_HOST_API_URL}/company/${this.props.match.params.companyId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        company: response.data,
                        customFields: response.data.customFields,
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });

            window.scrollTo(0, 0);
        }
    }

    /**
     * Delete a custom field key/value pair.
     */
    deleteCustomField(showWarning=true) {

        const functionName = 'deleteCustomField';
        if(this.props['paymentProviderId'] === 'BLUESNAP' && showWarning) {
            $("#delete-custom-field").modal("hide");
            $("#blueSnapWarningUpdate").modal("show");
            this.setState((prevState)=>({
                ...prevState,
                modalContinueFuncName: functionName
            }));
        } else {

            let customFields = this.state.customFields;

            delete customFields[this.state.customFieldKey];

            axios.patch(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}/update`, {
                customFields: customFields
            }, {
                headers: this.generateRequestHeaders()
            }).then(response => {

                this.setState(prevState => ({
                    ...prevState,
                    customFields: response.data.customFields,
                }));

                this.props.parent.setState({
                    validationList: [{
                        fields: {},
                        alert: {
                            type: 'primary',
                            code: this.props.deleteMessage
                        }
                    }],
                });

                $("#custom-field").modal("hide");

                axios.get(`${constants.REACT_APP_HOST_API_URL}/${this.props.recordType}/${this.props.recordId}`, {
                    headers: this.generateRequestHeaders()
                }).then(response => {
                    this.setState(prevState => ({
                        ...prevState,
                        company: response.data,
                        customFields: response.data.customFields
                    }));
                }).catch(error => {
                    this.handleValidation(error);
                });

            }).catch(error => {
                this.handleValidation(error);
            });

            window.scrollTo(0, 0);
        }
    }

    handleChangeBankAccountType(event){
        event.persist();

        let bnkAcntType = event.target.value;
        let customFields = this.state.customFields;
        customFields['bankAccountType'] = bnkAcntType;

        if(bnkAcntType === "SAVINGS" ||  bnkAcntType === "CHEQUING"){
            this.setState(prevState=>({
                ...prevState,
                bankAccountTypeForPaysafeCash:bnkAcntType,
                customFields:customFields,
                customFieldKey: "bankAccountType",
                customFieldValue: bnkAcntType
            }));
        }
    }

    /**
     * Handles the case when continue on the bluesnap warning modal is clicked. 
     */
    handleContinueBlueSnapWarningOnUpdate() {
        this[this.state.modalContinueFuncName](false, false);
        $("#blueSnapWarningUpdate").modal("hide");
    }

    /**
     * Render the component.
     *
     * @returns {*} - The generic custom fields management component.
     */
    render() {

        const prettyJson = require('prettyjson');

        let disableDelete = this.props.isPaysafeCash && (this.state.customFieldKey === "bankAccountType" ||
                            this.state.customFieldKey==="bankAccountHolder" || this.state.customFieldKey==="reportEmail")

        return(
            <React.Fragment>
                
                <Alert validationList={this.state.validationList}/>

                <ModalFC 
                    modalId="blueSnapWarningUpdate"
                    modalTitle="Do you want to continue?"
                    modalBody="This merchant account was already sent to the provider. Any data changes made here will not appear on the provider's side. Are you sure you want to update this merchant account?"
                    saveBtnText="Continue"
                    saveBtnAction={this.handleContinueBlueSnapWarningOnUpdate}
                />

                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">
                                Custom Fields
                            </div>
                            <div className="col text-right">
                                <div data-toggle="modal" data-target="#custom-field" className="btn btn-primary btn-sm" onClick={() => this.initCustomField()}>
                                    <FontAwesomeIcon icon={['fas', 'plus']} className="fa-fw" /> Create Custom Field
                                </div>
                            </div>
                        </div>
                    </div>
                    {Object.entries(this.state.customFields).length === 0 &&
                    <div className="card-body">
                        <div className="row justify-content-center">
                            <div className="col-10">
                                <div className="text-center text-secondary">
                                    <FontAwesomeIcon icon={['fas', 'ghost']} className="fa-fw mb-4" size="5x"/>
                                </div>
                                <div className="text-center text-muted">
                                    <small>
                                        This record does not currently have any custom fields.
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                    {Object.entries(this.state.customFields).length > 0 &&
                    <div className="card-body card-body-table">
                        <table className="table table-hover table-bordered">
                            <thead>
                            <tr>
                                <th width="25%">Key</th>
                                <th width="75%">Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            {Object.keys(this.state.customFields).map((key) => {
                                return(
                                    <React.Fragment>
                                        <tr key={key} onClick={() => this.editCustomField(key)} className="c-pointer">
                                            <td>
                                                {key}
                                            </td>
                                            <td>
                                                <pre className="mb-0 break-all-custom pre-wrap">
                                                    {prettyJson.render(this.state.customFields[key])}
                                                </pre>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    }
                </div>

                <div className="modal fade" id="custom-field" tabIndex="-1" role="dialog" aria-labelledby="custom-field-label" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md" role="document">
                        <div className="modal-content shadow">
                            <form onSubmit={this.saveCustomField} id="customFieldListForm">
                                <div className="modal-header bg-dark text-white">
                                    <h5 className="modal-title" id="custom-field-label">
                                        {!this.state.editing &&
                                        <span className="">Create Custom Field</span>
                                        }
                                        {this.state.editing &&
                                        <span className="">Edit Custom Field</span>
                                        }
                                    </h5>
                                    <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                                        <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw va-b mr-2" />
                                    </button>
                                </div>
                                <div className="modal-body">

                                    <FieldText id="customFieldKey" label="Key" disabled={this.state.editing} parent={this} value={this.state.customFieldKey} />
                                    
                                    {this.state.customFieldKey &&
                                         this.state.customFieldKey !== 'bankAccountType' &&
                                            <FieldText id="customFieldValue" label="Value" parent={this} value={this.state.customFieldValue} 
                                                required={this.props.isPaysafeCash} type={this.state.customFieldKey === "reportEmail" ? "email":""}/>
                                    }
                                    {this.state.customFieldKey && this.state.editing &&
                                        this.state.customFieldKey === 'bankAccountType' &&
                                            <FieldRadio id="bankAccountType" label="Bank Account Type" isPaysafeCashMerchAccnt 
                                                fieldColumns="9" widthAlign="20%" parent={this} required
                                                value={this.state.bankAccountTypeForPaysafeCash} handleChange={(event)=>this.handleChangeBankAccountType(event)}/>
                                    }

                                </div>
                                <div className="modal-footer bg-secondary d-block rounded-bottom">

                                    <div className="row">
                                        <div className="col">

                                            <div className="float-left">
                                                <button type="button" className="btn btn-outline-primary btn-lg" onClick={() => $("#custom-field").modal("hide")}>Close</button>
                                            </div>

                                            <div className="float-right">

                                                {this.state.editing &&
                                                <div className={`btn btn-lg btn-primary ml-2 ${disableDelete ? 'btn-disabled' : ''}`} 
                                                    onClick={() => {$("#custom-field").modal("hide"); $("#delete-custom-field").modal('show');}}>
                                                    Delete
                                                </div>
                                                }

                                                <ButtonSave />
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <Modal id="delete-custom-field" theme="danger" iconType="fas" iconName="exclamation-triangle" title="Delete Custom Field"
                       body="Are you sure you want to delete this custom field?">
                    <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => {$("#delete-custom-field").modal("hide"); $("#custom-field").modal('show');}}>
                        <FormattedMessage id="button.back" />
                    </button>
                    <button onClick={() => {this.deleteCustomField()}} className="btn btn-danger btn-lg" data-dismiss="modal">
                        Delete Custom Field
                    </button>
                </Modal>

            </React.Fragment>
        )
    };
}

CustomFieldList.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(CustomFieldList);