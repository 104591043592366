import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as constants from '../../../util/constants';
import generateRequestHeaders from '../../../util/generateRequestHeaders';
import Alert from '../../common/Alert';
import FieldCheckboxFC from '../../common/FieldCheckboxFC';
import { useValidationList } from '../../common/hooks/useValidationList';

const ServiceLevelConfigNeoFinancial = ({
  companyId,
  propertyId,
  neoBannerStatus,
  handleRefreshCompany,
  handleRefreshProperty,
}) => {

  /******** STATE ******/
  /*********************/
  const [showNeoBanner, setShowNeoBanner] = useState(neoBannerStatus || false);
  const { setAlertMessage, validationList, addErrorToValidationList, clearValidationList } = useValidationList([]);
  const neoFinancialServiceName = 'SERVICE_NEO_FINANCIAL';

  /*********************/
  /*********************/

  /****** ACTIONS ******/
  /*********************/
  const handleRefresh = () => {
    if (propertyId) {
      handleRefreshProperty(propertyId);
    } else {
      handleRefreshCompany(companyId);
    }
  };

  const handleSubmitNeoFinancial = (event) => {
    event.preventDefault();
    axios(`${constants.REACT_APP_HOST_API_URL}/company/services`, {
      method: 'POST',
      headers: generateRequestHeaders(),
      data: {
        companyId: companyId,
        propertyId: propertyId || '',
        serviceName: neoFinancialServiceName,
        serviceSetting: showNeoBanner,
      },
    })
      .then((response) => {
        if (response) {
          clearValidationList();
          setAlertMessage('Saved', 'primary');
        }
        handleRefresh();
      })
      .catch((error) => {
        clearValidationList();
        addErrorToValidationList(error);
        handleRefresh();
      });
  };
  /*********************/
  /*********************/

  return (
    <>
      <Alert validationList={validationList} />
      <div className='card'>
        <div className='card-header'>
          <div className='row align-items-center'>
            <div className='col'>Neo Financial</div>
          </div>
        </div>
        <div className='col my-3'>
          <form onSubmit={handleSubmitNeoFinancial}>
            <FieldCheckboxFC
              id='neoBannerStatus'
              fieldLabel='Enable Banner'
              value={showNeoBanner}
              help={'Only applicable for CA.'}
              handleChange={setShowNeoBanner}
            />
            <div className='text-right'>
              <button
                type='submit'
                className='btn btn-primary btn-md mt-5'
                disabled={neoBannerStatus === showNeoBanner}
              >
                <FontAwesomeIcon icon={['fas', 'check']} className='fa-fw' /> Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

ServiceLevelConfigNeoFinancial.propTypes = {
  companyId: PropTypes.string,
  propertyId: PropTypes.string,
  neoBannerStatus: PropTypes.bool,
  handleRefreshCompany: PropTypes.func,
  handleRefreshProperty: PropTypes.func,
};

export default ServiceLevelConfigNeoFinancial;
