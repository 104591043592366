import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import "moment-timezone";
import React from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import Moment from "react-moment";
import * as constants from "../../util/constants";
import FieldSelect from "../common/FieldSelect";
import FieldText from "../common/FieldText";
import InsuranceInformation from "../common/InsuranceInformation";
import RejectInsurance from "../common/RejectInsurance";
import Alert from "./Alert";
import ButtonClose from "./ButtonClose";
import Propertii from "./Propertii";
import Spinner from "./Spinner";
import Table from "./Table";

class Insurances extends Propertii {
  /**
   * Initialize the component.
   *
   * @param props - The properties of the component.
   */
  constructor(props) {
    super(props);
    this.state = {
      today: moment().format('YYYY-MM-DD[T]hh:mm:ssZZ'),
      dueIn30days: false,
      dateIn30days: null,
      dueIn90days: false,
      dateIn90days: null,
      pastDue: false,
      selectedTab: "",
      insuranceRejection: {
        status: "",
        comment: "",
      },
      insuranceApplicationsSearchFilter: "",
      selectedInsurance: {},
      insuranceDocument: {},
      insuranceApplicationsList: {
        page: "",
        recordsPerPage: "",
        totalPages: "",
        totalRecordCount: "",
        records: [{}],
      },
      tenantInsurance: {
        records: [],
        document: {},
      },
      insuranceStatusFilter: null,
      noInsuranceTenant: false,
      insuranceApplicationsQuery: {
        orderBy: "ASC",
        orderByFields: ["status"],
        joins: {
          customer: {
            targetRecordType: "TYPE_CUSTOMER",
            joinField: "userId",
            alias: "c",
            returnFields: ["firstName", "lastName", "email"],
          },
          additionalProp1: {
            alias: "pl",
            joinField: "leaseid",
            returnFields: ["unit"],
            targetField: "id",
            targetRecordType: "TYPE_PROPERTY_LEASE",
          },
          additionalProp2: {
            alias: "p",
            joinField: "pl.propertyid",
            returnFields: ["street1", "street2", "city", "propertyName"],
            targetField: "id",
            targetRecordType: "TYPE_PROPERTY",
          },
        },
      },
      otherRejectReason: {
        comment: '',
      },
      validationList: [],
    };

    this.selectTab = this.selectTab.bind(this);

    this.clearFilters = this.clearFilters.bind(this);
    this.filterInsuranceApplications = this.filterInsuranceApplications.bind(
      this
    );

    this.searchInsuranceApplications = this.searchInsuranceApplications.bind(
      this
    );
    this.viewInsuranceApplication = this.viewInsuranceApplication.bind(this);
  }

  /**
   * Populate the list of insurance applications on mounting of the component.
   */
  componentDidMount() {
    if (this.props.individualInsurance) {
      this.searchIndividualInsurance(this.props.propertyLease.userId, this.props.propertyLease.id, this.state.insuranceApplicationsQuery)
    }
    this.selectTab("INSURANCE_APPLICATIONS");
  }

  /**
   * Select a tab from the list of navigation tabs.
   *
   * @param tabId - The ID of the tab selected.
   */
  selectTab(tabId) {
    switch (tabId) {
      case "INSURANCE_APPLICATIONS":
        this.searchInsuranceApplications(
          1,
          25,
          this.state.insuranceApplicationsQuery
        );
        break;
      case "TENANTS":
        break;  
      default:
        this.searchInsuranceApplications(
          1,
          25,
          this.state.insuranceApplicationsQuery
        );
        break;
    }
  }

  /**
   * Clear all applicable filters and re-run the filter queries.
   */
  clearFilters() {
    this.setState(
      {
        insuranceApplicationsSearchFilter: "",
        insuranceStatusFilter: null,
        dueIn30days: false,
        dueIn90days: false,
        pastDue: false,
      },
      () => {
        this.filterInsuranceApplications(null, "");
      }
    );
  }

  /**
   * Handle submitting the search filter field for insurance applications.
   *
   * @param event - The event container.
   */
  filterInsuranceApplications(event) {
    if (event != null) {
      event.preventDefault();
    }

    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
    }));

    const insuranceApplicationsQuery = this.state.insuranceApplicationsQuery;

    insuranceApplicationsQuery.conditionList = [];

    if (this.state.insuranceApplicationsSearchFilter !== "") {
      insuranceApplicationsQuery.conditionList.push({
        type: "STRING",
        logicalOperator: "OR",
        openBrackets: "(",
        closeBrackets: ")",
        fieldName: "firstName, lastName, c.email",
        operator: "LIKE_IGNORE_CASE",
        fieldValue: this.state.insuranceApplicationsSearchFilter,
      });
    }

    if(this.state.insuranceStatusFilter !== null && this.state.insuranceStatusFilter !== '') {
      insuranceApplicationsQuery.conditionList.push(
          {
              type: 'STRING',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'approvalStatus',
              operator: 'EQUALS',
              fieldValue: this.state.insuranceStatusFilter
          },
      );
    }

    if(this.state.dueIn30days && this.state.dateIn30days !== '') {
      insuranceApplicationsQuery.conditionList.push(
          {
              type: 'DATE',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'endDate',
              operator: 'GREATER_THAN_OR_EQUALS',
              fieldValue: this.state.today
          },
          {
              type: 'DATE',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'endDate',
              operator: 'LESS_THAN_OR_EQUALS',
              fieldValue: this.state.dateIn30days
          },
      );
    }

    if(this.state.dueIn90days && this.state.dateIn90days !== '') {
      insuranceApplicationsQuery.conditionList.push(
          {
              type: 'DATE',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'endDate',
              operator: 'GREATER_THAN_OR_EQUALS',
              fieldValue: this.state.today
          },
          {
              type: 'DATE',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'endDate',
              operator: 'LESS_THAN_OR_EQUALS',
              fieldValue: this.state.dateIn90days
          },
      );
    }

    if(this.state.pastDue && this.state.today !== '') {
      insuranceApplicationsQuery.conditionList.push(
          {
              type: 'DATE',
              logicalOperator: 'AND',
              openBrackets: null,
              closeBrackets: null,
              fieldName: 'endDate',
              operator: 'LESS_THAN',
              fieldValue: this.state.today
          },
      );
    }

    this.setState({
      insuranceApplicationsQuery: insuranceApplicationsQuery,
    });

    this.searchInsuranceApplications(1, 25, insuranceApplicationsQuery);
  }

  /**
   * Fetch the list of insurance applications.
   *
   * @param page - The page to display.
   * @param recordsPerPage - The amount of records to display on each page.
   * @param query - The search query.
   */
  searchInsuranceApplications(page, recordsPerPage, query) {
    this.setState((prevState) => ({
      ...prevState,
      spinner: true,
      selectedTab: "INSURANCE_APPLICATIONS",
    }));

    axios
      .post(
        `${constants.REACT_APP_HOST_API_URL}/INSURANCE_POLICY/search?recordsPerPage=${recordsPerPage}&page=${page}`,
        {
          orderBy: query.orderBy,
          orderByFields: query.orderByFields,
          conditionList: query.conditionList,
          joins: query.joins,
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          insuranceApplicationsList: {
            ...response.data,
            emptyUnfilteredList:
              (this.state.insuranceApplicationsSearchFilter === "" && this.state.insuranceStatusFilter === "" && !this.state.dueIn30days && !this.state.dueIn90days && !this.state.pastDue) &&
              response.data.records.length === 0,
          },
          insuranceApplicationsQuery: {
            orderBy: query.orderBy,
            orderByFields: query.orderByFields,
            conditionList: query.conditionList,
            joins: query.joins
          }
        }));
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  searchIndividualInsurance(userId, leaseID, query) {
    axios.post(`${constants.REACT_APP_HOST_API_URL}/INSURANCE_POLICY/search`, {
        orderBy: 'ASC',
        orderByFields: ["c.firstName"],
        conditionList: [
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "operator": "EQUALS",
                "fieldName": "userId",
                "fieldValue": userId
            },
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "operator": "EQUALS",
                "fieldName": "leaseId",
                "fieldValue": leaseID
            }
        ],
        joins: query.joins
    },{
        headers: this.generateRequestHeaders()
    }).then(response => {
        if(response.data?.records?.length) {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                tenantInsurance: {
                  ...response.data,
                },
            }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            noInsuranceTenant: true,
          }));
        }
    }).catch(error => {
        this.handleValidation(error);
    });
  }

  searchIndividualInsuranceDocument(id, query) {
    axios.post(`${constants.REACT_APP_HOST_API_URL}/DOCUMENT/search`, {
        orderBy: 'ASC',
        conditionList: [
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "operator": "EQUALS",
                "fieldName": "recordType",
                "fieldValue": "TYPE_INSURANCE_POLICY"
            },
            {
                "type": "STRING",
                "logicalOperator": "AND",
                "operator": "EQUALS",
                "fieldName": "recordId",
                "fieldValue": id
            }
        ]
    },{
        headers: this.generateRequestHeaders()
    }).then(response => {
        if(response.data?.records?.length) {
            this.setState(prevState => ({
                ...prevState,
                spinner: false,
                insuranceDocument: response.data?.records[0],
            }));
        } else {
          this.setState(prevState => ({
            ...prevState,
            spinner: false,
            insuranceDocument: {},
        }));
        }
    }).catch(error => {
        this.handleValidation(error);
    });
  }

  /**
   * Opens the detailed view of insurance application.
   *
   * @param data - Insurance data.
   */
  viewInsuranceApplication(data) {
      this.searchIndividualInsuranceDocument(data.id)
      this.getInsuranceApprovalHistory(data)
      this.setState(prevState => ({
        ...prevState,
        selectedInsurance: {
          ...data,
        }
    }));
    $('#selected-insurance').modal('show');
  }

  openRejectInsuranceModal() {
    $('#selected-insurance').modal('hide');
    $('#reject-insurance').modal('show');
  }

  acceptInsurance(insurance) {
    axios.patch(`${constants.REACT_APP_HOST_API_URL}/INSURANCE_POLICY/${insurance.id}/update`,
        {
          approvalStatus: 'APPROVED',
          approvalStatusComment: '',
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.searchInsuranceApplications(1,25,this.state.insuranceApplicationsQuery);
        this.searchIndividualInsurance(insurance.userId, insurance.leaseId, this.state.insuranceApplicationsQuery);
        this.getInsuranceApprovalHistory(insurance.id);
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          selectedInsurance: {
            ...prevState.selectedInsurance,
            approvalStatus: response.data.approvalStatus,
            approvalStatusComment: response.data.approvalStatusComment,
          }
        }));
        $('#selected-insurance').modal('hide');
      })
      .catch((error) => {
        this.handleValidation(error);
      });
  }

  rejectInsurance(insurance) {
    axios.patch(`${constants.REACT_APP_HOST_API_URL}/INSURANCE_POLICY/${insurance.id}/update`,
        {
          approvalStatus: 'REJECTED',
          approvalStatusComment: this.state.selectedInsurance.approvalStatusComment === 'OTHER' ? this.state.otherRejectReason.comment : this.formatApprovalRejection(this.state.selectedInsurance.approvalStatusComment),
        },
        {
          headers: this.generateRequestHeaders(),
        }
      )
      .then((response) => {
        this.searchInsuranceApplications(1,25,this.state.insuranceApplicationsQuery);
        this.searchIndividualInsurance(insurance.userId, insurance.leaseId, this.state.insuranceApplicationsQuery);
        this.setState((prevState) => ({
          ...prevState,
          spinner: false,
          selectedInsurance: {
            ...prevState.selectedInsurance,
            approvalStatus: response.data.approvalStatus,
            approvalStatusComment: response.data.approvalStatusComment,
          },
          otherRejectReason: {
            comment: ""
          }
        }
        ));
        $('#reject-insurance').modal('hide');})
      .catch((error) => {
        this.handleValidation(error);
      });
  }
  
  formatApprovalStatus(status) {
    switch(status) {
        case "APPROVED":
            return "APPROVED";
        case "REJECTED":
            return "REJECTED";
        case "PENDING":
            return "PENDING";
        case "TERMINATED":
            return "TERMINATED";
        default:
            return "PENDING";
    }
  }

  formatApprovalRejection(reason) {
    switch(reason) {
        case "POLICY_DOCUMENT":
            return "Problem with uploaded policy document";
        case "POLICY_COVERAGE":
            return "Problem with policy coverage";
        case "POLICY_TERMS":
            return "Problem with the terms of the policy";
        default:
            return "";
    }
  }

  getInsuranceApprovalHistory(insurance) {
    this.setState({
        spinner: true
    });
    axios.get(`${constants.REACT_APP_HOST_API_URL}/INSURANCE_POLICY/${insurance.id}/history`, {
      orderBy: 'DESC',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.props.token
        }
    }).then(response => {
        this.setState((prevState) => ({
            spinner: false,
            selectedInsurance: {
              ...prevState.selectedInsurance,
              approvalHistory: response.data
            }
        }));
    }).catch(error => {
        this.handleValidation(error);
    });
  }

  dueIn30days() {
    let thirtyDays = moment().add(30, 'd').format('YYYY-MM-DD[T]hh:mm:ssZZ');
    this.setState(prevState => ({
      dueIn90days: false,
      pastDue: false,
      dueIn30days: !prevState.dueIn30days,
      dateIn30days: thirtyDays
    }),
    () => {
      this.filterInsuranceApplications();
    });
  }

  dueIn90days() {
    let ninetyDays = moment().add(90, 'd').format('YYYY-MM-DD[T]hh:mm:ssZZ');
    this.setState(prevState => ({
      dueIn30days: false,
      pastDue: false,
      dueIn90days: !prevState.dueIn90days,
      dateIn90days: ninetyDays,
    }), () => {
      this.filterInsuranceApplications();
    });
  }

  pastDue() {
    this.setState(prevState => ({
      dueIn30days: false,
      dueIn90days: false,
      pastDue: !prevState.pastDue,
    }), () => {
      this.filterInsuranceApplications();
    });
  }

  /**
   * Render the component.
   *
   */
  render() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    const { formatMessage } = this.props.intl;

    const insuranceApplicationsColumnLabels = {
      "c.firstName": "Tenant",
      "p.propertyName": "Property Name",
      "pl.unit": "Unit",
      "p.street1": "Property Address",
      carrierName: "Insurance Carrier",
      effectiveDate: "Effective Date",
      endDate: "End Date",
      approvalStatus: "Approval Status",
    };

    const insuranceApplicationsColumnLabelsCompressed = {
      "p.propertyName": "Property Name",
      "pl.unit": "Unit",
      "p.street1": "Property Address",
      carrierName: "Carrier",
      effectiveDate: "Effective Date",
      endDate: "End Date",
      status: "Approval Status",
    };

    const columnWidths = ["16%", "16%", "16%", "16%", "16%", "16%", "16%", '16%'];

    const defaultTextSearchPlaceholder =
      "Filter by name, phone, email, unit, property, or confirmation ID...";

    return (
      <React.Fragment>
        <Spinner visible={this.state.spinner} />

        <Alert validationList={this.state.validationList} />

        <div className="card accordion" id="navigation">
          <div
            className={`card-header ${
              this.props.displayAdvanced && !this.props.individualInsurance ? "pb-0" : ""
            }`}
          >
            {!this.props.individualInsurance ? <div className="row">
              <div className="col flex space-between">
                <ul className="nav nav-tabs border-bottom-0">
                  {/* <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.selectedTab === "TENANTS" ? "active" : ""
                      }`}
                      id="tenants-tab"
                      // data-toggle="tab"
                      // href="#tenants"
                      // role="tab"
                      aria-controls="tenants"
                      aria-selected="true"
                      onClick={() => {
                        this.selectTab("TENANTS");
                      }}
                    >
                      Tenants
                    </a>
                  </li> */}
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        this.state.selectedTab === "INSURANCE_APPLICATIONS"
                          ? "active"
                          : ""
                      }`}
                      id="insurance-applications-tab"
                      data-toggle="tab"
                      href="#insurance-applications"
                      role="tab"
                      aria-controls="insurance-applications"
                      aria-selected="true"
                      onClick={() => this.selectTab("INSURANCE_APPLICATIONS")}
                    >
                      Insurance Applications
                    </a>
                  </li>
                </ul>
                <div className="btn-group btn-group-toggle d-xs-none d-xs-show" data-toggle="buttons">
                  <label className={`btn btn-secondary btn-sm mb-0 flex ${this.state.dueIn30days ? 'active' : ''}` }>
                    <input type="radio" name="options" id="option1" autoComplete="off" onClick={() => this.dueIn30days()} /> Due in 30 days
                  </label>
                  <label className={`btn btn-secondary btn-sm mb-0 flex ${this.state.dueIn90days ? 'active' : ''}` }>
                    <input type="radio" name="options" id="option2" autoComplete="off" onClick={() => this.dueIn90days()} /> Due in 90 days
                  </label>
                  <label className={`btn btn-secondary btn-sm mb-0 flex ${this.state.pastDue ? 'active' : ''}` }>
                    <input type="radio" name="options" id="option3" autoComplete="off" onClick={() => this.pastDue()} /> Past Due
                  </label>
                </div>
              </div>
              {/* <div style={filterBtnContainer}>
                <Link
                  to="#"
                  className="btn btn-primary btn-sm mb-0"
                  style={filterBtnStyle}
                >
                  Recent
                </Link>
              </div>
              <div style={filterBtnContainer}>
                <Link
                  to="#"
                  className="btn btn-primary btn-sm mb-0"
                  style={filterBtnStyle}
                >
                  30 days till expiration
                </Link>
              </div>
              <div style={filterBtnContainer}>
                <Link
                  to="#"
                  className="btn btn-primary btn-sm mb-0"
                  style={filterBtnStyle}
                >
                  90 days till expiration
                </Link>
              </div>
              <div style={filterBtnContainer}>
                <Link
                  to="#"
                  className="btn btn-primary btn-sm mb-0"
                  style={filterBtnStyle}
                >
                  Past due
                </Link>
              </div>
              <div style={filterBtnContainer}>
                <Link
                  to="#"
                  className="btn btn-primary btn-sm mb-0"
                  style={filterBtnStyle}
                >
                  Terminated
                </Link>
              </div> */}
            </div> : "Insurance Details"}
          </div>
          {this.state.noInsuranceTenant &&
            <div className="card-body bg-secondary py-5">
                <div className="row justify-content-center">
                    <div className="col-10">
                        <div className="text-center text-muted">
                            <div className="fa-stack fa-2x mb-2">
                                <FontAwesomeIcon icon={['fas', 'square']} className="fa-2x" />
                                <FontAwesomeIcon icon={['far', 'user-shield']} className="fa-stack-1x fa-inverse" />
                            </div>
                        </div>
                        <div className="text-center text-muted">
                            <small>
                                No attached insurance found.
                            </small>
                        </div>
                    </div>
                </div>
            </div>
          }
          <div
            className={this.props.displayAdvanced ? "tab-content" : ""}
            id="tabs"
          >
          {!this.props.individualInsurance && 
          (<div className="card-header gotham border-top py-3 bg-secondary">
              <form onSubmit={this.filterInsuranceApplications}>
                <div className="media">
                  <div className="media-body align-self-center mr-3">
                    <FieldText
                      id="insuranceApplicationsSearchFilter"
                      label="Search"
                      labelClass="d-none"
                      fieldColumns="12"
                      parent={this}
                      labelColums="0"
                      placeholder={
                        this.props.textSearchPlaceholderOverrideTenants
                          ? this.props.textSearchPlaceholderOverrideTenants
                          : defaultTextSearchPlaceholder
                      }
                      value={this.state.insuranceApplicationsSearchFilter}
                    />
                  </div>
                  <div className="media-body align-self-center mr-3">
                        <FieldSelect id="insuranceStatusFilter" labelColumns="0"
                                    fieldColumns="12" parent={this}
                                    value={this.state.insuranceStatusFilter}>
                            <option value="">All Statuses</option>
                            <option value="APPROVED">Approved</option>
                            <option value="PENDING">Pending</option>
                            <option value="REJECTED">Rejected</option>
                            <option value="TERMINATED">Terminated</option>
                        </FieldSelect>
                    </div>
                    <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      type="submit"
                      className="btn btn-secondary btn-sm mb-0"
                    >
                      <FontAwesomeIcon
                        icon={["fas", "search"]}
                        className="fa-fw"
                      />
                      Search
                    </button>
                    <div
                      className="btn btn-secondary btn-sm mb-0"
                      onClick={() => this.clearFilters()}
                    >
                      <FontAwesomeIcon
                        icon={["fas", "eraser"]}
                        className="fa-fw"
                      />{" "}
                      Clear
                    </div>
                  </div>
                </div>
              </form>
            </div>)}
            <div
              className={`${
                this.props.displayAdvanced ? "tab-pane fade-show" : ""
              } ${this.state.selectedTab === "TENANTS" ? "active" : ""}`}
              id="tenants"
              role={this.props.displayAdvanced ? "tabpanel" : ""}
              aria-labelledby="tenants-tab"
            ></div>

            {(this.props.displayAdvanced && !this.state.noInsuranceTenant) && (
              <div
                className={`tab-pane fade-show ${
                  this.state.selectedTab === "INSURANCE_APPLICATIONS"
                    ? "active"
                    : ""
                }`}
                id="insurance-applications"
                role="tabpanel"
                aria-labelledby="insurance-applications-tab"
              >
                {!this.state.insuranceApplicationsList.emptyUnfilteredList && (
                  <Table
                    columns={this.props.individualInsurance ? insuranceApplicationsColumnLabelsCompressed : insuranceApplicationsColumnLabels}
                    columnWidths={columnWidths}
                    headerClass="c-pointer"
                    data={this.props.individualInsurance ? this.state.tenantInsurance : this.state.insuranceApplicationsList}
                    query={this.state.insuranceApplicationsQuery}
                    sortEnabled={this.props.individualInsurance ? false : true}
                    recordsEnabled={true}
                    paginationEnabled={true}
                    parent={this}
                    updateFunction={this.searchInsuranceApplications}
                    tableClass="table-bordered table-hover table-responsive w-100 d-block d-md-table table-layout-fixed"
                  >
                    <tbody>
                      {(this.props.individualInsurance ? this.state.tenantInsurance.records : this.state.insuranceApplicationsList.records).map(
                        (data, key) => {
                          return (
                            <tr
                              key={key}
                              onClick={() => this.viewInsuranceApplication(data)}
                              className="c-pointer"
                            >
                              {!this.props.individualInsurance && <td>
                                <div className="">
                                  {data?.joins?.c?.firstName} {data?.joins?.c?.lastName}
                                </div>
                              </td>}
                              <td>
                                <div className="">
                                  {data?.joins?.p?.propertyName}
                                </div>
                              </td>
                              <td>
                                <div className="">{data?.joins?.pl?.unit}</div>
                              </td>
                              <td>
                                <div className="">
                                  {data?.joins?.p?.street1}
                                </div>
                              </td>
                              <td>
                                <div className="">{data?.carrierName}</div>
                              </td>
                              <td>
                                <div className="">
                                  {data?.effectiveDate ? <Moment format="MMM DD, YYYY" tz="UTC">
                                    {data?.effectiveDate}
                                  </Moment> : "n/a"}
                                </div>
                              </td>

                              <td>
                                <div className="">
                                  <Moment format="MMM DD, YYYY" tz="UTC">
                                    {data?.endDate}
                                  </Moment>
                                </div>
                              </td>

                              <td>
                                {/* <div className="text-nowrap text-blue">
                                  {this.formatApprovalStatus(data?.approvalStatus)}
                                </div> */}
                                <div className="text-nowrap">
                                    <FontAwesomeIcon icon={['fas', 'circle']} className={`fa-fw small ${formatMessage({id: "enum.transactionStatus." + this.formatApprovalStatus(data?.approvalStatus) + ".class"})}`}/>
                                    <span className="ml-1"><FormattedMessage id={"enum.transactionStatus." + this.formatApprovalStatus(data?.approvalStatus)}/></span>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            )}
          </div>
        </div>
        {this.props.displayAdvanced &&
          <InsuranceInformation userType={this.props.userType} displayAdvanced={this.props.displayAdvanced} insurance={this.state.selectedInsurance} document={this.state.insuranceDocument} token={this.props.token}>
            <div className="float-left">
                <ButtonClose/>
            </div>
            { this.state.selectedInsurance?.approvalStatus !== "TERMINATED" &&
              <div className="float-right">
                <div className="dropdown">
                    <div className="btn btn-primary btn-lg dropdown-toggle" role="button" id="insurance-actions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Actions
                    </div>
                    <div className="dropdown-menu" aria-labelledby="insurance-actions">
                      <div className={`dropdown-item c-pointer ${this.state.selectedInsurance.approvalStatus === 'APPROVED' ? 'disabled' : ''}`} onClick={() => this.acceptInsurance(this.state.selectedInsurance)}>
                          <FontAwesomeIcon icon={['fas', 'check']} className="fa-fw" /> Approve
                      </div>
                      <div className={`dropdown-item c-pointer ${this.state.selectedInsurance.approvalStatus === 'REJECTED' ? 'disabled' : ''}`} onClick={() => this.openRejectInsuranceModal(this.state.selectedInsurance)}>
                          <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" /> Reject
                      </div>
                    </div>
                </div>
              </div>
            }
          </InsuranceInformation>
        }
        {this.props.displayAdvanced &&
          <RejectInsurance userType={this.props.userType} displayAdvanced={this.props.displayAdvanced} insurance={this.state.selectedInsurance} document={this.state.insuranceDocument} token={this.props.token} rejectMessage={this.state.insuranceRejection}>
              <div className="modal-body">
                  <FieldSelect id="approvalStatusComment" label="Reason" model={'selectedInsurance'}  parent={this} value={this.state.selectedInsurance['approvalStatusComment']}>
                      <option value="">Select a reason...</option>
                      <option value="POLICY_DOCUMENT">Problem with uploaded policy document</option>
                      <option value="POLICY_COVERAGE">Problem with policy coverage</option>
                      <option value="POLICY_TERMS">Problem with the terms of the policy</option>
                      <option value="OTHER">Other</option>
                  </FieldSelect>
                  {
                      this.state.selectedInsurance.approvalStatusComment === 'OTHER' &&
                          <FieldText id="comment" label="Company Name" labelClass="d-none"
                          fieldColumns="12" labelColums="0" placeholder="Please enter a reason for rejecting the insurance"
                          parent={this} model={'otherRejectReason'}  value={this.state.otherRejectReason['comment']} />
                      
                  }
              </div>

              <div className="modal-footer d-block text-center bg-secondary rounded-bottom">
                  <div className="float-left">
                      <ButtonClose/>
                  </div>
                  <div className="float-right">
                      <div className="dropdown">
                          <button disabled={(this.state.selectedInsurance.approvalStatusComment === 'OTHER' || this.state.selectedInsurance.approvalStatusComment === '' || this.state.selectedInsurance.approvalStatusComment === null) && this.state.otherRejectReason.comment === ''} className={`btn btn-danger btn-lg ` } id="insurance-reject" onClick={() => this.rejectInsurance(this.state.selectedInsurance) }>
                            <FontAwesomeIcon icon={['fas', 'times']} className="fa-fw" /> Reject
                          </button>
                      </div>
                  </div>
              </div>
          </RejectInsurance>
        }
      </React.Fragment>
    );
  }
}

Insurances.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Insurances);
