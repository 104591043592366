import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import VerificationCode from '../../common/VerificationCode';
import axios from "axios";
import * as constants from "../../../util/constants";
import FileDownload from "js-file-download";
import Propertii from "../../common/Propertii";

class HPPVerificationCode extends Propertii {

    /**
     * Initialize the component.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {

        super(props);

        this.state = {
            hppVerificationCode: '',
            validationList: []
        };

        this.searchHPPVerificationCode = this.searchHPPVerificationCode.bind(this);
    }

    /**
     * Load the Hpp Verification Code on mounting of the component.
     */
    componentDidMount() {

        this.searchHPPVerificationCode();
    }

    /**
     * Fetch Hpp Verification Code.
     */
    searchHPPVerificationCode() {

        axios.get(`${constants.REACT_APP_HOST_API_URL}/payment_transaction/verification_code/universal`, {
            headers: this.generateRequestHeaders()
        }).then(response => {
            this.setState(prevState => ({
                ...prevState,
                hppVerificationCode: response.data
            }));
        }).catch(error => {
            this.handleValidation(error);
        });
    }

    render() {

        return (
            <div className='content-block'>
                <div className='container'>
                    <Breadcrumb
                        parentPath='/admin/system'
                        parentPage='System'
                        childPage='HPP Verification Code'
                    />
                    <VerificationCode code={this.state.hppVerificationCode} />
                </div>
            </div>
        );
    }
}
export default HPPVerificationCode;
