import $ from "jquery";
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import ReactSelect from 'react-select';

class FieldProperties extends Component {

    /**
     * Initialize the component. Check to see if a handleChange function already appears in the parent class. If no
     * custom handleChange function appears in the parent class, utilize the default handleChange function below.
     *
     * @param props - The properties of the component.
     */
    constructor(props) {
        super(props);

        if(this.props.handleChange) {
            this.handleChange = this.props.handleChange.bind(this);
        } else {
            this.handleChange = this.handleChange.bind(this);
        }
    }

    /**
     * Handle change events on fields. If a model is provided in the props, we know to update a a JSON object in the
     * state, as opposed to a standalone element.
     *
     * @param selectedOption - The selected option.
     */
    handleChange(selectedOption) {
        // let field;

        // if(this.props.model != null) {
        //     field = this.props.parent.state[this.props.model];
        //     field[this.props.id] = selectedOption ? selectedOption.value : null;
        // }

        // if(this.props.model == null) {
        //     field = selectedOption ? selectedOption.value : null;
        // }

        // this.props.parent.setState(({
        //     [this.props.model ? this.props.model : this.props.id]: field,
        //     selectedProperty: {
        //         value: selectedOption ? selectedOption.value : null,
        //         label: selectedOption ? selectedOption.label : null
        //     }
        // }));
    }

    /**
     * Render the component.
     *
     * @returns {*} - A multi-select properties component.
     */
    render() {

        let isInvalid = false;
        let errorCode = '';

        Object.entries(this.props.parent.state.validationList).forEach(([key, validation]) => {
            if(validation.fields) {
                Object.entries(validation.fields).forEach(([key, fieldError]) => {
                    if(key === this.props.id) {
                        isInvalid = true;
                        errorCode = fieldError;
                    }
                });
            }
        });

        $(function() {
            $('[data-toggle="popover"]').popover()
        });

        let options = [];

        this.props.options.forEach((data, key) => {
            options.push({
                value: data.id,
                label: data.name
            });
        });

        const customStyles = {

            control: (base, state) => ({
                ...base,
                height: 'auto',
                minHeight: 'auto',
                fontSize: '0.875rem',
                borderRadius: '0.2rem',
                boxShadow: 'none',
                color: '#495057',
                border: isInvalid ? '1px solid #dc3545' : state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                borderBottomLeftRadius: state.isFocused ? '0' : '0.2rem',
                borderBottomRightRadius: state.isFocused ? '0' : '0.2rem',
                '&:hover': {
                    border: isInvalid ? '1px solid #dc3545' : state.isFocused ? '1px solid #52c75d' : '1px solid #ced4da',
                },
                marginTop: '6px',
                marginBottom: '6px',
                transition: 'all 0.1s ease-in-out',
            }),

            option: (provided, { data, isDisabled, isFocused, isSelected, isMulti} ) => ({
                ...provided,
                fontSize: '0.875rem',
                padding: '0.25rem 0.5rem',
                background: isSelected ? '#e9ecef' : 'none',
                color: '#495057',
                '&:hover': {
                    background: '#52c75d',
                    color: '#fff'
                }
            }),

            placeholder: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
            }),

            menu: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                marginTop: '0 ',
                zIndex: '999',
                boxShadow: 'none',
                border: '1px solid #52c75d',
                borderTop: '0',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '0',
            }),

            multiValue: (provided, state) => ({
                ...provided,
                backgroundColor: '#e9ecef',
            }),

            multiValueRemove: (provided, state) => ({
                ...provided,
                '&:hover': {
                    backgroundColor: '#52c75d',
                    color: '#ffffff'
                },
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0',
                transition: 'all 0.1s ease-in-out',
                cursor: 'pointer'
            }),

            indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none'
            }),

            dropdownIndicator: (provided, state) => ({
                ...provided,
                position: 'relative',
                top: '-3px',
                color: isInvalid ? '#dc3545' : provided.color,
            }),

            valueContainer: (provided, state) => ({
                ...provided,
                fontSize: '0.875rem',
                marginTop: '6px',
                marginBottom: '6px',
                padding: '0 0.5rem',
                transition: 'all 0.1s ease-in-out',
            }),

            clearIndicator: (provided, state) => ({
                ...provided,
                cursor: 'pointer'
            })

        };

        return(
            <React.Fragment>

                <div className={`form-group row ${this.props.containerClass}`}>

                    <label className={`col-sm-${this.props.labelColumns} col-form-label ${this.props.labelClass}`} htmlFor={this.props.id}>
                        {this.props.label}
                    </label>

                    <div className={`col-sm-${this.props.fieldColumns}`}>

                        <ReactSelect
                            id={this.props.id}
                            name={this.props.id}
                            options={options}
                            value={this.props.value}
                            onChange={this.props.handleChange}
                            isDisabled={this.props.disabled}
                            closeMenuOnSelect={false}
                            isMulti
                            styles={customStyles}
                            placeholder="Search All Companies"
                            className={`basic-multi-select ${isInvalid ? 'is-invalid' : ''}`} />

                        {isInvalid &&
                        <div className="form-text small text-danger">
                            <FormattedMessage id={"danger." + errorCode}/>
                        </div>
                        }

                        {this.props.help &&
                        <small className="form-text text-muted">
                            {this.props.help}
                        </small>
                        }

                    </div>

                </div>

            </React.Fragment>
        )
    };
}

FieldProperties.defaultProps = {
    formGroup: true,
    labelColumns: '3',
    fieldColumns: '9',
    labelClass: 'col-form-label-sm',
    fieldClass: 'form-control-sm',
    type: 'text',
    maxLength: '100'
};

FieldProperties.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(FieldProperties);
